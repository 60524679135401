import {
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  DialogActions,
  Button,
  TextField,
  InputAdornment,
  InputLabel,
} from "@material-ui/core";
import RemoveRedEyeIcon from "@material-ui/icons/RemoveRedEye";
import VisibilityOffIcon from "@material-ui/icons/VisibilityOff";
import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import CloseIcon from "@material-ui/icons/Close";
import { useSelector } from "react-redux";
import { membersActions } from "../../actions";

import {
  BUTTONS,
  DIALOG_BOX,
  LABELS,
  VALIDATION_MESSAGES,
} from "../../constants/constants";
import { validatePassword } from "../../helpers";

const useStyles = makeStyles((theme) => ({
  DialogTitle: {
    background: "#FBC112",
    fontSize: "28px",
    fontWeight: 700,
    textAlign: "center",
  },
  dailogText: {
    textAlign: "center",
    fontSize: "16px",
    fontWeight: 400,
  },
  dailogActions: {
    display: "flex",
    justifyContent: "center",
    marginBottom: "15px",
    marginTop: "15px",
  },
  confirmButton: {
    background: "#000000",
    color: "#FFFFFF",
  },
  closeButton: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(1),
    padding: "0px",
    color: "#000000",
  },
  inputStyle: {
    width: "100%",
  },
  labelStyle: {
    fontSize: "18px",
    marginBottom: "10px",
    marginTop: "10px",
    fontWeight: 600,
    color: "#000000",
  },
  labelStyleConfirm: {
    fontSize: "18px",
    marginBottom: "10px",
    marginTop: "50px",
    fontWeight: 600,
    color: "#000000",
  },
  eyeIconStyle: {
    cursor: "pointer",
  },
  buttonCancel: {
    padding: "14px 30px",
  },
}));

function ChangePassword({ open, onClose }) {
  const classes = useStyles();
  const recruiterCompanyId = useSelector(
    (state) =>
      state.authentication.recruiterStatusDetails.company.recruiter_company_id
  );

  const [password, setPassword] = useState({
    newPassword: null,
    confirmPassword: null,
  });

  const [passwordVisible, setPasswordVisible] = useState({
    newPassword: false,
    confirmPassword: false,
  });

  const [passwordValidate, setPasswordValidate] = useState({
    newPassword: false,
    confirmPassword: false,
    passwordNotMatch: false,
  });

  const [disbaleButton, setDisableButton] = useState(true);

  const [close, setClose] = useState(false);

  const handlePassword = (value, key) => {
    var isValidateNewPassword;
    switch (key) {
      case LABELS.CHANGE_PASSWORD.NEW_PASSWORD:
        isValidateNewPassword = validatePassword(value);
        setPasswordValidate({
          ...passwordValidate,
          newPassword: !isValidateNewPassword,
        });
        setPassword({ ...password, newPassword: value });
        break;
      case LABELS.CHANGE_PASSWORD.CONFIRM_PASSWORD:
        setPasswordValidate({
          ...passwordValidate,
          passwordNotMatch: password.newPassword !== value ? true : false,
        });
        setPassword({ ...password, confirmPassword: value });
        break;
      default:
        break;
    }
    if (isValidateNewPassword === false || password.newPassword !== value) {
      setDisableButton(true);
    } else {
      setDisableButton(false);
    }
  };

  const handlePasswordVisible = (key) => {
    switch (key) {
      case LABELS.CHANGE_PASSWORD.NEW_PASSWORD:
        setPasswordVisible({
          ...passwordVisible,
          newPassword: !passwordVisible.newPassword,
        });
        break;
      case LABELS.CHANGE_PASSWORD.CONFIRM_PASSWORD:
        setPasswordVisible({
          ...passwordVisible,
          confirmPassword: !passwordVisible.confirmPassword,
        });
        break;
      default:
        break;
    }
  };

  const handleConfirmButton = () => {
    const isValidateNewPassword = validatePassword(password.newPassword);
    var isValidateConfirmPassword = validatePassword(password.confirmPassword);

    setPasswordValidate({
      ...passwordValidate,
      newPassword: !isValidateNewPassword,
    });
    setPasswordValidate({
      ...passwordValidate,
      confirmPassword: !isValidateConfirmPassword,
    });

    if (!isValidateNewPassword) {
      return;
    }
    if (password.newPassword !== password.confirmPassword) {
      setPasswordValidate({ ...passwordValidate, passwordNotMatch: true });
      return;
    } else {
      let payload = {
        new_password: password.newPassword,
      };
      membersActions.changePassword(recruiterCompanyId, payload);
      setClose(true);
    }
  };

  const onHandleClose = () => {
    onClose();
    setPassword({ newPassword: null, confirmPassword: null });
    setPasswordVisible({ newPassword: false, confirmPassword: false });
    setPasswordValidate({ newPassword: false, confirmPassword: false });
  };

  useEffect(() => {
    onHandleClose();
    setClose(false);
  }, [close]);

  return (
    <Dialog open={open} maxWidth="sm" fullWidth>
      <DialogTitle className={classes.DialogTitle}>
        {DIALOG_BOX.CHANGE_PASSWORD.DIALOG_TITLES}
      </DialogTitle>
      <IconButton
        aria-label="close"
        className={classes.closeButton}
        onClick={onHandleClose}
      >
        <CloseIcon />
      </IconButton>
      <DialogContent>
        <div>
          <InputLabel
            required
            error={passwordValidate.newPassword}
            className={classes.labelStyle}
          >
            {LABELS.CHANGE_PASSWORD.NEW_PASSWORD}
          </InputLabel>
          <TextField
            id="outlined-basic"
            variant="outlined"
            type={!passwordVisible.newPassword ? "password" : "text"}
            value={password.newPassword}
            onChange={(e) =>
              handlePassword(
                e.target.value,
                LABELS.CHANGE_PASSWORD.NEW_PASSWORD
              )
            }
            className={classes.inputStyle}
            error={passwordValidate.newPassword}
            helperText={
              passwordValidate.newPassword
                ? VALIDATION_MESSAGES.CHANGE_PASSWORD.STRONG_PASSWORD_MESSAGE
                : ""
            }
            InputProps={{
              endAdornment: (
                <InputAdornment
                  position="start"
                  className={classes.eyeIconStyle}
                  onClick={() =>
                    handlePasswordVisible(LABELS.CHANGE_PASSWORD.NEW_PASSWORD)
                  }
                >
                  {!passwordVisible.newPassword ? (
                    <RemoveRedEyeIcon />
                  ) : (
                    <VisibilityOffIcon />
                  )}
                </InputAdornment>
              ),
            }}
          />
        </div>
        <div>
          <InputLabel
            required
            error={passwordValidate.passwordNotMatch}
            className={classes.labelStyleConfirm}
          >
            {LABELS.CHANGE_PASSWORD.CONFIRM_PASSWORD}
          </InputLabel>
          <TextField
            id="outlined-basic"
            variant="outlined"
            type={!passwordVisible.confirmPassword ? "password" : "text"}
            value={password.confirmPassword}
            onChange={(e) =>
              handlePassword(
                e.target.value,
                LABELS.CHANGE_PASSWORD.CONFIRM_PASSWORD
              )
            }
            className={classes.inputStyle}
            error={passwordValidate.passwordNotMatch}
            helperText={
              passwordValidate.passwordNotMatch
                ? VALIDATION_MESSAGES.CHANGE_PASSWORD.PASSWORD_NOT_MATCH
                : ""
            }
            InputProps={{
              endAdornment: (
                <InputAdornment
                  position="start"
                  className={classes.eyeIconStyle}
                  onClick={() =>
                    handlePasswordVisible(
                      LABELS.CHANGE_PASSWORD.CONFIRM_PASSWORD
                    )
                  }
                >
                  {!passwordVisible.confirmPassword ? (
                    <RemoveRedEyeIcon />
                  ) : (
                    <VisibilityOffIcon />
                  )}
                </InputAdornment>
              ),
            }}
          />
        </div>
      </DialogContent>
      <DialogActions className={classes.dailogActions}>
        <Button
          size="large"
          className={classes.buttonCancel}
          disableElevation
          onClick={onHandleClose}
        >
          {BUTTONS.CHANGE_PASSWORD.CANCEL}
        </Button>
        <Button
          disableElevation
          className={classes.confirmButton}
          onClick={handleConfirmButton}
          variant="contained"
          size="large"
          disabled={disbaleButton}
        >
          {BUTTONS.CHANGE_PASSWORD.SUBMIT}
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default ChangePassword;
