import React, { Component, Fragment } from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import {
  Grid,
  Button,
  Tab,
  Tabs,
  Typography,
  Dialog,
  DialogContentText,
  TextField,
  FormControl,
  Radio,
  RadioGroup,
  FormControlLabel,
  Chip,
  Card,
  CardContent,
  Avatar,
  Breadcrumbs,
  Link,
  CardActions,
  CardHeader,
  Fab,
  Paper,
  CircularProgress,
  IconButton,
  Box,
  Divider,
  Table,
  TableBody,
  TableCell,
  TableRow,
  TableContainer,
} from "@material-ui/core";
// import PopupState, { bindTrigger, bindPopover } from 'material-ui-popup-state';
// import { ReactComponent as Logo } from '../../../assets/logo.svg';
import cardHeaderBg from "../../../assets/lite_profile_bg.png";
import { ReactComponent as BorwseEmpty } from "../../../assets/browse_empty.svg";
import { ReactComponent as PendingEmpty } from "../../../assets/pending_empty.svg";
import { ReactComponent as MatchedEmpty } from "../../../assets/matched_empty.svg";
import { ReactComponent as ArchivedEmpty } from "../../../assets/archived_empty.svg";
// import { ReactComponent as MatchedCriteria } from '../../../assets/matched_criteria.svg';
// import { ReactComponent as DownloadProfile } from '../../../assets/download_profile.svg';
import MuiDialogTitle from "@material-ui/core/DialogTitle";
import MuiDialogContent from "@material-ui/core/DialogContent";
import MuiDialogActions from "@material-ui/core/DialogActions";
// import IconButton from '@material-ui/core/IconButton';
import Autocomplete from "@material-ui/lab/Autocomplete";
import CloseIcon from "@material-ui/icons/Close";
// import CheckIcon from '@material-ui/icons/Check';
import { ReactComponent as LikeIcon } from "../../../assets/like.svg";
import { ReactComponent as DislikeIcon } from "../../../assets/dislike.svg";
// import StarsIcon from '@material-ui/icons/Stars';
import LocationOnIcon from "@material-ui/icons/LocationOn";
// import MessageIcon from '@material-ui/icons/Message';
// import AccountBoxIcon from '@material-ui/icons/AccountBox';
// import CancelIcon from '@material-ui/icons/Cancel';
import { history, truncate } from "../../../helpers";
// import SearchIcon from "@material-ui/icons/Search";
import validator from "validator";
import classNames from "classnames";
import moment from "moment";
import { membersActions } from "../../../actions/members.actions";
// import { INDUSTRIES } from '../../../constants/constants';
import { toast } from "react-toastify";
import { GoogleMaps } from "../../core/googleautocomplete";
import { ViewMore } from "../../core/viewmore";
import "./members.css";
import { messagesActions } from "../../../actions/messages.actions";
import GetHelp from "../../core/gethelp";
import { JobDetails } from "../jobs/job.details.component";
import secureLocalStorage from "react-secure-storage";

const styles = (theme) => ({
  root: {
    marginTop: "90px",
    width: "100%",
  },
  paper: {
    border: "1px solid",
    padding: theme.spacing(1),
    backgroundColor: theme.palette.background.paper,
  },
  closeButton: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.default.main,
    padding: "0px",
  },
  appFrame: {
    zIndex: 1,
    overflow: "hidden",
    position: "relative",
    // display: 'flex',
    // width: '100%',
    padding: theme.spacing(7, 6),
    // backgroundColor: '#F7F7F7',
    paddingBottom: "1.5%",
  },
  content: {
    flexGrow: 1,
    marginTop: "0.2%",
    padding: theme.spacing(0, 6),
  },
  bullet: {
    display: "inline-block",
    margin: "0 2px",
    transform: "scale(0.8)",
  },
  title: {
    fontSize: 14,
  },
  pos: {
    marginBottom: 12,
  },
  largeAvatar: {
    width: theme.spacing(7),
    height: theme.spacing(7),
  },
  xLargeAvatar: {
    width: theme.spacing(10),
    height: theme.spacing(10),
    backgroundColor: "#000",
  },
  moduleConfig: {
    float: "left",
    fontSize: "26px",
  },
  demo1: {
    marginTop: "5%",
  },
  floatRight: {
    float: "right",
  },
  floatLeft: {
    float: "left",
  },
  largeTopMargin: {
    marginTop: "10%",
  },
  verySmallTopMargin: {
    marginTop: "1%",
  },
  formControl: {
    margin: theme.spacing(0, 0),
    minWidth: "100%",
  },
  mediumTopMargin: {
    marginTop: "4%",
  },
  displayFlex: {
    display: "flex",
  },
  noPadding: {
    padding: "0 !important",
  },
  fullWidth: {
    width: "100%",
  },
  colorDefault: {
    color: "#000000 !important",
  },
  colorCustom: {
    color: "#303030",
  },
  dialogTitle: {
    color: "#FBC112",
  },
  displayInline: {
    display: "inline",
  },
  noBottomMargin: {
    marginBottom: "0",
  },
  smallTopMargin: {
    marginTop: "3%",
  },
  chip: {
    margin: theme.spacing(0.5),
  },
  input: {
    marginLeft: theme.spacing(1),
    flex: 1,
  },
  iconButton: {
    padding: 10,
  },
  noWrap: {
    whiteSpace: "nowrap",
  },
  subHeader: {
    fontSize: "large",
    marginTop: "4%",
    fontWeight: "600",
    color: "#000000",
  },
  header: {
    fontSize: "x-large",
    marginTop: "4%",
    fontWeight: "600",
    color: "#000000",
  },
  jobCardHeader: {
    fontSize: "x-large",
    fontWeight: "500",
    color: "#000000",
    textAlign: "left",
  },
  jobCardSubHeader: {
    fontSize: "large",
    fontWeight: "500",
    textAlign: "left",
  },
  customCard: {
    backgroundColor: theme.palette.basic.main,
  },
  customCardShadow: {
    boxShadow: "none",
  },
  textAlignEnd: {
    textAlign: "end",
  },
  textAlignCenter: {
    textAlign: "center",
  },
  jobCard: {
    boxShadow:
      "0px 1px 1px 0px rgb(0 0 0 / 20%), 0px 1px 0px 0px rgb(0 0 0 / 14%), 0px 0px 2px 1px rgb(0 0 0 / 12%)",
    cursor: "pointer",
  },
  smallBottomMargin: {
    marginBottom: "2%",
  },
  customBadge: {
    // backgroundColor: "#FBC112",
    cursor: "pointer",
    height: "50px",
    minWidth: "50px",
    borderRadius: "50%",
    border: "1px solid black",
    fontSize: "large",
    right: "unset",
    transform: "unset",
    position: "unset",
  },
  smallFont: {
    fontSize: "14px",
  },

  largeFont: {
    fontSize: "large",
  },

  customBadgeGrid: {
    textAlign: "center",
    overflowWrap: "break-word",
    padding: "2%",
  },
  customLink: {
    cursor: "pointer",
    "&:hover": {
      textDecoration: "underline",
    },
    color: "#048475 !important",
  },
  alignSelfCenter: { alignSelf: "center !important" },
  breadcrumbLink: {
    cursor: "pointer",
    "&:hover": {
      textDecoration: "underline",
    },
    // color: '#048475 !important'
  },
  moreIcon: {
    justifyContent: "space-between",
    cursor: "pointer",
    overflowWrap: "anywhere",
  },
  customMoreIcon: {
    fontSize: "2.5rem",
    // '&:hover':{
    //     transform: 'rotate(90deg)',
    //     transition: 'all .3s ease'
    // }
  },
  customDialog: {
    backgroundColor: "#000000",
    width: "unset",
  },
  justifyContentFlexEnd: {
    justifyContent: "flex-end",
  },
  justifyContentCenter: {
    justifyContent: "center !important",
  },
  responsive: {
    maxWidth: "100% !important",
    height: "auto !important",
    width: "auto !important",
    // alignSelf: 'center !important',
    minWidth: "fit-content !important",
  },
  liteProfileCard: {
    // boxShadow: '0px 1px 1px 0px rgb(0 0 0 / 0%), 0px 1px 0px 0px rgb(0 0 0 / 5%), 0px 0px 2px 1px rgb(0 0 0 / 12%)',
    borderBottom: "10px solid #FBC112",
    maxHeight: "47em",
    height: "47em",
    // borderRadius: '8px'
    borderRadius: "0px",
    // backgroundColor: '#F7F7F7',
    // baclgroundImage: 'url(src/assets/lite_profile_bg.png)'
    // cursor: 'pointer'
  },
  maxHeight40P: { maxHeight: "40px" },
  customCardHeader: {
    maxHeight: "15em",
    height: "15em",
    color: "#000",
    backgroundSize: "cover",
    backgroundImage: `url(${cardHeaderBg})`,
    background: "round",
  }, //height: '180px'
  customDialogHeader: {
    maxHeight: "38%",
    height: "38%",
    color: "#000",
    backgroundSize: "cover",
    backgroundImage: `url(${cardHeaderBg})`,
    background: "round",
  }, //height: '180px'
  // matchedCustomCardHeader: { maxHeight: '40%', height: '40%', color: '#000', backgroundSize: 'cover', backgroundImage: `url(${cardHeaderBg})`, background: 'round' },
  matchedCustomCardHeader: {
    maxHeight: "35%",
    height: "35%",
    color: "#000",
    backgroundSize: "cover",
    backgroundImage: `url(${cardHeaderBg})`,
    background: "round",
  },
  customCardActions: { display: "block", marginTop: "1%" },
  textAlignLeft: { textAlign: "left" },
  textAlignRight: { textAlign: "right" },
  verySmallFont: { fontSize: ".8rem" },
  font10p: { fontSize: "10px" },
  font14p: { fontSize: "12px" },
  alignBaslineMiddle: { verticalAlign: "-webkit-baseline-middle" },
  verySmallMarginLeft: { marginLeft: "3px" },
  smallMarginLeft: { marginLeft: "8px" },
  mediumMarginLeft: { marginLeft: "12px" },
  boldFont: { fontWeight: "bold" },
  liteProfileHighlights: {
    backgroundColor: "#F7F7F7",
    padding: "3%",
    height: "14%",
    maxHeight: "14%",
  },
  liteProfileCardContent: {
    backgroundColor: "#FFFFFF",
    paddingTop: 0,
    paddingBottom: 0,
    paddingLeft: "16px",
    paddingRight: "16px",
    height: "19em",
    maxHeight: "19em",
  },
  customLiteProfileCardContent: {
    backgroundColor: "#FFFFFF",
    paddingTop: 0,
    paddingBottom: 0,
    paddingLeft: "16px",
    paddingRight: "16px",
    height: "23em",
    maxHeight: "23em",
  },
  // liteProfileContent: {textAlign: 'left', paddingLeft: '5%', paddingRight: '5%', maxHeight: '13.5em', height: '13.5em', overflowY: 'auto'},
  liteProfileContent: {
    paddingRight: "5%",
    maxHeight: "17.5em",
    height: "17.5em",
    overflowY: "auto",
    // textIndent: '-1.4em',
    paddingLeft: "5%",
    textAlign: "left",
    fontSize: "smaller",
  },
  customLiteProfileContent: {
    paddingRight: "5%",
    maxHeight: "22.5em",
    height: "22.5em",
    overflowY: "auto",
    // textIndent: '-1.4em',
    paddingLeft: "5%",
    textAlign: "left",
    fontSize: "smaller",
  },
  noBoxShadow: { boxShadow: "none" },
  noBorderBottom: { borderBottom: "none" },
  viewMore: { color: "#302F2C", cursor: "pointer", fontWeight: "bold" },
  matchedCardHeader: { alignItems: "center", justifyContent: "center" },
  alignWebkitCenter: { textAlign: "-webkit-center" },
  justifyContentSpaceEvenly: { justifyContent: "space-evenly" },
  justifyContentSpaceBetween: { justifyContent: "space-between" },
  // bgChangeOnHover: {'&:hover':{backgroundColor: '#FBC112'}},
  verySmallMarginRight: { marginRight: "2%" },
  skillsCardPanel: {
    // '&:hover':{
    overflowY: "auto",
    // }
  },
  filterGoBtn: { width: "-webkit-fill-available", marginBottom: "5%" },
  customChip: {
    width: "95%",
    marginTop: "3%",
    borderRadius: "10px",
    textIndent: "0",
  },
  cursorPointer: {
    cursor: "pointer",
  },
  likeButton: {
    backgroundColor: "#FBC112 !important",
  },
  historyButton: {
    backgroundColor: "#FBC925",
    width: "100%",
    borderRadius: "0px !important",
    fontWeight: "normal !important",
    "&:hover": { backgroundColor: "#FBC925 !important" },
    justifyContent: "left",
    padding: "3%",
  },
  customHighlightsPaper: {
    paddingLeft: "3%",
    paddingRight: "3%",
    paddingBottom: "0",
    boxShadow: "none",
    borderRadius: "10px",
    border: "2px solid #e0e0e0",
    // height: '100%',
    // maxHeight: '100%',
    overflowY: "auto",
  },
  smallLeftPadding: { paddingLeft: "8px" },
  // equalButtons: { width: '150px', margin: '3%' },
  // equalButtons: {width: '45vw', margin: '3%'},
  fixedTitleTableHeight: { height: "130px !important", marginTop: "10%" },
  fixedTableHeight: { height: "115px !important" },
  fixedTitleHeight: { height: "80px !important" },
  defaultButton: {
    backgroundColor: "#FFFFFF",
    border: "#000000 solid 1px",
    marginRight: "1%",
  },
});

const AntTabs = withStyles(() => ({
  root: {
    borderBottom: "1px solid #e8e8e8",
  },
  indicator: {
    backgroundColor: "#FBC112",
  },
}))(Tabs);

const AntTab = withStyles((theme) => ({
  root: {
    textTransform: "none",
    minWidth: 72,
    fontWeight: theme.typography.fontWeightRegular,
    marginRight: theme.spacing(4),
    "&:hover": {
      color: theme.palette.secondary,
      opacity: 1,
    },
    "&$selected": {
      color: theme.palette.secondary,
      fontWeight: theme.typography.fontWeightMedium,
    },
    "&:focus": {
      color: theme.palette.secondary,
    },
  },
  // selected: {
  //     borderBottom: '3px solid #FBC112',
  //     borderRadius: '0px'
  // },
}))((props) => <Tab disableRipple {...props} />);

const DialogTitle = withStyles(styles)((props) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle
      disableTypography
      {...other}
      className={classes.customDialogHeader}
      style={{ padding: "3%" }}
    >
      {/* className={classes.root} */}
      <Typography variant="h5" align="center">
        {children}
      </Typography>
      {onClose ? (
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={onClose}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const DialogContent = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
    paddingLeft: theme.spacing(4),
    paddingRight: theme.spacing(4),
  },
}))(MuiDialogContent);

const DialogActions = withStyles((theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
    backgroundColor: "#fff",
    justifyContent: "flex-end !important",
  },
}))(MuiDialogActions);

let jobTitles = [];
let charCount = 100;

class Members extends Component {
  constructor(props) {
    super(props);
    this.state = this.getInitialState();
  }

  getInitialState = () => ({
    value: JSON.parse(secureLocalStorage.getItem("previousTab")) || 0,
    innerTabValue: 0,
    expanded: "",
    open: false,
    openPreview: false,
    openCloseConfirm: false,
    openCloseFormPopup: false,
    dislikePopupOpen: false,
    likePopupOpen: false,
    openOptions: false,
    openSkillsOptions: false,
    openLocationOptions: false,
    jobTitleOptions: [],
    skillsOptions: [],
    skillsValue: "",
    locationOptions: [],
    // scroll: 'paper',
    jobTitle: "",
    jobTitleValue: secureLocalStorage.getItem("jobTitle")
      ? secureLocalStorage.getItem("jobTitle")
      : "",
    jobLevel: "",
    reportsTo: "",
    industry: secureLocalStorage.getItem("industry")
      ? JSON.parse(secureLocalStorage.getItem("industry"))
      : "",
    functionName: "",
    location: "",
    locationData: "",
    remote: "",
    travelLevel: "",
    skills: null,
    aboutUs: "",
    jobDescription: "",
    howSoonToHire: "",
    isValidJobTitle: true,
    isValidReportsToName: true,
    isValidIndustry: true,
    isValidLocation: true,
    backdrop: false,
    closeComment: "",
    isValidCloseComment: true,
    isValidArchiveReason: true,
    isValidOtherArchiveReason: true,
    archiveReason: "",
    otherArchiveReason: "",
    likeReason: "",
    otherLikeReason: "",
    isValidOtherLikeReason: true,
    anchorEl: null,
    openPopper: false,
    historyPopupOpen: false,
  });

  componentDidMount() {
    const { dispatch } = this.props;
    this.getBrowsedCandidates();
    dispatch(
      membersActions.getPendingData(
        JSON.parse(secureLocalStorage.getItem("recruiterId"))
      )
    );
    dispatch(
      membersActions.getMatchedData(
        JSON.parse(secureLocalStorage.getItem("recruiterId"))
      )
    );
    dispatch(
      membersActions.getArchivedData(
        JSON.parse(secureLocalStorage.getItem("recruiterId"))
      )
    );
    document.body.scrollTop = 0; // For Safari
    document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE and Opera
    // This fix is for corrupted empty state images
    // let emptyStateElem = document.querySelectorAll('[clip-path="url(#clip0)"]');
    // if(emptyStateElem[0]){
    //     emptyStateElem[0].setAttribute('clip-path', 'none');
    // }
  }

  componentDidUpdate(prevProps) {
    if (prevProps.members.jobTitles !== this.props.members.jobTitles) {
      jobTitles = this.props.members.jobTitles;
      this.setState({ jobTitleOptions: jobTitles });
    }

    if (
      prevProps.members?.browsed !== this.props.members?.browsed &&
      !secureLocalStorage.getItem("locationDetails")
    ) {
      if (document.getElementById("google-map-demo-members")) {
        document.getElementById("google-map-demo-members").value = null;
      }
      // window.addEventListener("load", function(event){
      //   if(document.getElementById('google-map-demo-members') !== null){
      //     document.getElementById('google-map-demo-members').value = null;
      //   }
      // });
    }

    // if(prevProps.jobs.skills !== this.props.jobs.skills){
    //     receivedSkills = this.props.jobs.skills;
    //     this.setState({skillsOptions: receivedSkills});
    // }
    var elementList = document.querySelectorAll(
      '[aria-label="ant example lite profile"]'
    );
    if (elementList.length > 0) {
      for (let element in elementList) {
        if (
          elementList[element] !== undefined &&
          elementList[element].style !== undefined
        ) {
          elementList[element].style.justifyContent = "space-evenly";
          elementList[element].style.display = "inherit";
        }
      }
      // element.style.justifyContent = 'space-evenly';
    }
    // This fix is for corrupted empty state images
    // let emptyStateElem = document.querySelectorAll('[clip-path="url(#clip0)"]');
    // if(emptyStateElem[0]){
    //     emptyStateElem[0].setAttribute('clip-path', 'none');
    // }
  }

  componentWillUnmount() {
    secureLocalStorage.setItem("previousTab", JSON.stringify(0));
    secureLocalStorage.removeItem("industry");
    secureLocalStorage.removeItem("jobTitle");
    secureLocalStorage.removeItem("locationDetails");
  }

  capitalize = function (str) {
    if (str !== null && str !== undefined) {
      return str
        .split(" ")
        .map((w) => w.substring(0, 1).toUpperCase() + w.substring(1))
        .join(" ");
    } else return str;
  };

  getTitles = (title) => {
    const { dispatch } = this.props;
    dispatch(membersActions.getJobTitles(title));
  };

  getBrowsedCandidates = () => {
    const { dispatch } = this.props;
    let payload = {
      industry:
        typeof this.state.industry === "string"
          ? this.state.industry
          : this.state.industry.value,
      title: this.state.jobTitleValue,
      location: {
        lat: secureLocalStorage.getItem("locationDetails")
          ? JSON.parse(secureLocalStorage.getItem("locationDetails")).lat
          : 0,
        lng: secureLocalStorage.getItem("locationDetails")
          ? JSON.parse(secureLocalStorage.getItem("locationDetails")).lng
          : 0,
      },
    };
    dispatch(membersActions.getBrowseData(payload));
  };

  handleInputChange = (prop) => (event) => {
    this.setState({ [prop]: event.target.value }, function () {
      switch (prop) {
        case "jobTitle":
          this.getTitles(this.state.jobTitle);
          if (this.state.jobTitle.length === 0) {
            this.setState({ jobTitleOptions: [] });
          }
          // this.setState({jobTitleValue: event.target.value});
          break;
        case "otherArchiveReason":
          this.setState({
            isValidOtherArchiveReason: validator.matches(
              event.target.value,
              /^.{0,100}$/s
            ),
          });
          break;
        case "otherLikeReason":
          this.setState({
            isValidOtherLikeReason: validator.matches(
              event.target.value,
              /^.{0,100}$/s
            ),
          });
          break;
        default:
          break;
      }
    });
  };

  handleTabChange = (event, newValue) => {
    this.setState({ value: newValue }, () => {
      secureLocalStorage.setItem("previousTab", JSON.stringify(newValue));
    });
  };

  handleInnerTabChange = (stateText, newValue) => {
    this.setState({ [stateText]: newValue });
  };

  handleCloseEdit = (event) => {
    event.preventDefault();
    event.stopPropagation();
    this.setState((prevState) => ({ open: !prevState }));
  };

  handleClose = (event) => {
    event.preventDefault();
    event.stopPropagation();
    this.setState((prevState) => ({ openPreview: !prevState }));
  };

  setJobTitle = (value) => {
    if (value !== null && value !== undefined) {
      this.setState({ jobTitle: value });
      this.setState({ jobTitleValue: value._source.Title }, () => {
        JSON.stringify(
          secureLocalStorage.setItem("jobTitle", this.state.jobTitleValue)
        );
      });
    } else {
      this.setState({ jobTitle: value });
      this.setState({ jobTitleValue: "" }, () => {
        JSON.stringify(
          secureLocalStorage.setItem("jobTitle", this.state.jobTitleValue)
        );
      });
    }
  };

  handleDislikeCandidateRecommendation = (candidate) => {
    this.setState({ dislikePopupOpen: true });
    this.setState({ candidateId: candidate._id });
  };

  handleLikeCandidateRecommendation = (candidate) => {
    this.setState({ likePopupOpen: true });
    this.setState({ candidateId: candidate._id });
  };

  handleArchive = () => {
    const { dispatch } = this.props;
    const { otherArchiveReason, archiveReason } = this.state;
    if (archiveReason !== "") {
      if (this.state.isValidOtherArchiveReason) {
        let payload = {
          flag: "Browse",
          is_passed: true,
          candidate_id: this.state.candidateId,
          reason_for_rejection: archiveReason,
          other_feedback: otherArchiveReason === "" ? null : otherArchiveReason,
        };
        dispatch(
          membersActions.dislikeCandidateRecommendation(
            JSON.parse(secureLocalStorage.getItem("recruiterId")),
            payload
          )
        );
        this.handleCloseArchivePopup();
      } else {
        toast.dark("Invalid reason");
      }
    } else {
      toast.dark("Please choose one option");
    }
  };

  handleLike = () => {
    const { dispatch } = this.props;
    const { otherLikeReason, likeReason } = this.state;
    if (likeReason !== "") {
      if (this.state.isValidOtherLikeReason) {
        let payload = {
          flag: "Browse",
          is_passed: false,
          candidate_id: this.state.candidateId,
          recruiter_reason_for_like: likeReason,
          other_feedback: otherLikeReason === "" ? null : otherLikeReason,
        };
        dispatch(
          membersActions.likeCandidateRecommendation(
            JSON.parse(secureLocalStorage.getItem("recruiterId")),
            payload
          )
        );
        this.handleCloseLikePopup();
      } else {
        toast.dark("Invalid reason");
      }
    } else {
      toast.dark("Please choose one option");
    }
  };

  handleCloseArchivePopup = () => {
    this.setState({
      dislikePopupOpen: false,
      archiveReason: "",
      otherArchiveReason: "",
    });
  };

  handleCloseLikePopup = () => {
    this.setState({
      likePopupOpen: false,
      likeReason: "",
      otherLikeReason: "",
    });
  };

  setIndustry = (industry) => {
    this.setState({ industry: industry ? industry : "" }, () => {
      secureLocalStorage.setItem(
        "industry",
        JSON.stringify(this.state.industry)
      );
    });
  };

  goToMembers = () => {
    history.push("/members");
  };

  handleGoClick = (event) => {
    event.preventDefault();
    event.stopPropagation();
    const { industry, jobTitleValue } = this.state;
    if (typeof industry === "string") {
      if (
        industry ||
        jobTitleValue ||
        secureLocalStorage.getItem("locationDetails")
      ) {
        let payload = {
          industry: industry,
          title: jobTitleValue,
          location: {
            lat: secureLocalStorage.getItem("locationDetails")
              ? JSON.parse(secureLocalStorage.getItem("locationDetails")).lat
              : 0,
            lng: secureLocalStorage.getItem("locationDetails")
              ? JSON.parse(secureLocalStorage.getItem("locationDetails")).lng
              : 0,
          },
        };
        const { dispatch } = this.props;
        dispatch(membersActions.getBrowseData(payload));
      } else {
        toast.dark("Please fill up at least one field");
      }
    } else {
      if (
        industry ||
        jobTitleValue ||
        secureLocalStorage.getItem("locationDetails")
      ) {
        let payload = {
          industry: industry,
          title: jobTitleValue,
          // level: jobTitleValue,
          location: {
            lat: secureLocalStorage.getItem("locationDetails")
              ? JSON.parse(secureLocalStorage.getItem("locationDetails")).lat
              : 0,
            lng: secureLocalStorage.getItem("locationDetails")
              ? JSON.parse(secureLocalStorage.getItem("locationDetails")).lng
              : 0,
          },
        };
        const { dispatch } = this.props;
        dispatch(membersActions.getBrowseData(payload));
      } else {
        toast.dark("Please fill up at least one field");
      }
    }
  };

  viewHistory = (recommendation_id) => {
    const { dispatch } = this.props;
    dispatch(membersActions.viewHistory(recommendation_id));
    this.setState({ historyPopupOpen: true });
  };

  handleFullProfileClick = (candidateId, recommendationId) => {
    secureLocalStorage.setItem("fromJobsTab", false);
    const { dispatch } = this.props;
    dispatch(membersActions.getFullProfile(candidateId, recommendationId));
  };

  closeHistoryPopup = () => {
    this.setState({ historyPopupOpen: false });
  };

  resetFilter = () => {
    secureLocalStorage.removeItem("industry");
    secureLocalStorage.removeItem("jobTitle");
    secureLocalStorage.removeItem("locationDetails");
    this.setState(this.getInitialState(), () => {
      this.getBrowsedCandidates();
    });
  };

  render() {
    const { classes } = this.props;
    let browsed = this.props?.members?.browsed;
    let pending = this.props?.members?.pending;
    let matched = this.props?.members?.matched;
    let archived = this.props?.members?.archived;
    let browsedCount = "Browse (" + browsed?.browsed_candidates.length + ")";
    let pendingCount =
      "Pending Reply (" + pending?.interested_candidate_count + ")";
    let matchedCount = "Matched (" + matched?.interested_candidate_count + ")";
    let archivedCount =
      "Archived (" + archived?.interested_candidate_count + ")";
    let historyData = this.props?.members?.historyData?.data;
    let constants = this.props.authentication?.constants;

    function TabPanel(props) {
      const { children, value, index, ...other } = props;
      return (
        <div
          role="tabpanel"
          hidden={value !== index}
          id={`simple-tabpanel-${index}`}
          aria-labelledby={`simple-tab-${index}`}
          {...other}
        >
          {value === index && (
            <Box p={3}>
              <Typography>{children}</Typography>
            </Box>
          )}
        </div>
      );
    }

    TabPanel.propTypes = {
      children: PropTypes.node,
      index: PropTypes.any.isRequired,
      value: PropTypes.any.isRequired,
    };

    function a11yProps(index) {
      return {
        id: `simple-tab-${index}`,
        "aria-controls": `simple-tabpanel-${index}`,
      };
    }

    // const SmartText = ({ text, index, length = 150 }) => {
    //     const [showLess, setShowLess] = React.useState(true);
    //     if (text.length < length) {
    //       return <p>{text}</p>;
    //     }
    //     if(!showLess && document.getElementById('liteProfileContent'+ index)){
    //         document.getElementById('liteProfileContent'+ index).style.overflowY = 'auto';
    //     }
    //     else{
    //         if(document.getElementById('liteProfileContent'+ index)){
    //             document.getElementById('liteProfileContent'+ index).style.overflowY = 'revert';
    //         }
    //     }
    //     // if(!showLess && document.getElementById('liteProfileContentSkills'+ index)){
    //     //     document.getElementById('liteProfileContentSkills'+ index).style.overflowY = 'auto';
    //     // }
    //     // else{
    //     //     if(document.getElementById('liteProfileContentSkills'+ index)){
    //     //         document.getElementById('liteProfileContentSkills'+ index).style.overflowY = 'revert';
    //     //     }
    //     // }
    //     return (
    //       <div>
    //         <p
    //           dangerouslySetInnerHTML={{
    //             __html: showLess ? `${text.slice(0, length)}...` : text,
    //           }}
    //         ></p>
    //         <div className={classes.viewMore} onClick={() => setShowLess(!showLess)}>
    //           &nbsp;View {showLess ? "More" : "Less"}
    //         </div>
    //       </div>
    //     );
    // };

    const InnerTabs = ({ tab, index }) => {
      var stateText = "innerTabVal";
      stateText += index;
      return (
        <>
          <Tabs
            variant="scrollable"
            scrollButtons="auto"
            value={this.state[stateText] ? this.state[stateText] : 0}
            onChange={(event, value) => {
              this.handleInnerTabChange(stateText, value);
            }}
            className={classes.noBorderBottom}
            aria-label="ant example lite profile"
          >
            <Tab label="ACCOMPLISHMENTS" {...a11yProps(index + "0")} />
            <Tab label="COMPETENCIES" {...a11yProps(index + "1")} />
            {candidate?.insights ? <Tab label="INSIGHTS" /> : null}
          </Tabs>
          <TabPanel
            value={this.state[stateText] ? this.state[stateText] : 0}
            index={0}
          >
            <Paper
              id={`liteProfileContent${index}`}
              className={classNames(
                classes.noBoxShadow,
                tab !== "Pending" && tab !== "Archived"
                  ? classes.liteProfileContent
                  : classes.customLiteProfileContent
              )}
            >
              {tab === "Browse" && accomplishmentsTextData[index] ? (
                // <SmartText text={accomplishmentsTextData[index]} index={index} />
                <ViewMore index={index}>
                  {accomplishmentsTextData[index]}
                </ViewMore>
              ) : tab === "Pending" &&
                accomplishmentsTextDataForPending[index] ? (
                // <SmartText text={accomplishmentsTextDataForPending[index]} index={index} />
                <ViewMore index={index}>
                  {accomplishmentsTextDataForPending[index]}
                </ViewMore>
              ) : tab === "Matched" &&
                accomplishmentsTextDataForMatched[index] ? (
                // <SmartText text={accomplishmentsTextDataForMatched[index]} index={index} />
                <ViewMore index={index}>
                  {accomplishmentsTextDataForMatched[index]}
                </ViewMore>
              ) : tab === "Archived" &&
                accomplishmentsTextDataForArchived[index] ? (
                // <SmartText text={accomplishmentsTextDataForArchived[index]} index={index} />
                <ViewMore index={index}>
                  {accomplishmentsTextDataForArchived[index]}
                </ViewMore>
              ) : (
                <Typography
                  className={classes.smallTopMargin}
                  align="center"
                  color="textSecondary"
                >
                  No data available
                </Typography>
              )}
            </Paper>
          </TabPanel>
          <TabPanel
            className={classes.skillsCardPanel}
            value={this.state[stateText] ? this.state[stateText] : 0}
            index={1}
          >
            <Paper
              id={`liteProfileContentSkills${index}`}
              className={classNames(
                classes.noBoxShadow,
                tab !== "Pending" && tab !== "Archived"
                  ? classes.liteProfileContent
                  : classes.customLiteProfileContent
              )}
              style={{ textIndent: "0" }}
            >
              {/* <SmartText text={skillsTextData[index]} index={index} /> */}
              {tab === "Browse" && browsedSkillsData[index].length > 0 ? (
                <div>
                  {browsedSkillsData[index].map((skill) => (
                    <div>
                      <Chip
                        className={classes.customChip}
                        label={this.capitalize(skill)}
                        title={this.capitalize(skill)}
                      ></Chip>
                    </div>
                  ))}
                </div>
              ) : tab === "Pending" &&
                skillsDataForPending[index].length > 0 ? (
                <div style={{ textAlign: "center" }}>
                  {skillsDataForPending[index].map((skill) => (
                    <div>
                      <Chip
                        className={classes.customChip}
                        label={this.capitalize(skill)}
                        title={this.capitalize(skill)}
                      ></Chip>
                    </div>
                  ))}
                </div>
              ) : tab === "Matched" &&
                skillsDataForMatched[index].length > 0 ? (
                <div style={{ textAlign: "center" }}>
                  {skillsDataForMatched[index].map((skill) => (
                    <div>
                      <Chip
                        className={classes.customChip}
                        label={this.capitalize(skill)}
                        title={this.capitalize(skill)}
                      ></Chip>
                    </div>
                  ))}
                </div>
              ) : tab === "Archived" &&
                skillsDataForArchived[index].length > 0 ? (
                <div style={{ textAlign: "center" }}>
                  {skillsDataForArchived[index].map((skill) => (
                    <div>
                      <Chip
                        className={classes.customChip}
                        label={this.capitalize(skill)}
                        title={this.capitalize(skill)}
                      ></Chip>
                    </div>
                  ))}
                </div>
              ) : (
                <Typography
                  className={classes.smallTopMargin}
                  align="center"
                  color="textSecondary"
                >
                  No data available
                </Typography>
              )}
            </Paper>
          </TabPanel>
        </>
      );
    };

    // For browsed 'View More' text 'Recommended' tab
    let recommendedAccomplishmentsData = [];
    let accomplishmentsTextData = [];
    let accomplishmentsText = "";
    for (var i = 0; i < browsed?.browsed_candidates.length; i++) {
      recommendedAccomplishmentsData.push([]);
    }
    for (var member in browsed?.browsed_candidates) {
      for (var accomplishment in browsed?.browsed_candidates[member]?._source
        ?.accomplishments) {
        recommendedAccomplishmentsData[member].push(
          browsed?.browsed_candidates[member]?._source?.accomplishments[
            accomplishment
          ]
        );
      }
    }
    for (let index in recommendedAccomplishmentsData) {
      for (let ind in recommendedAccomplishmentsData[index]) {
        accomplishmentsText +=
          "<div><li>" +
          recommendedAccomplishmentsData[index][ind] +
          "</li></div><br>";
      }
      accomplishmentsTextData.push(accomplishmentsText);
      accomplishmentsText = "";
    }

    // For pending 'View More' text 'Pending' tab
    let pendingAccomplishmentsData = [];
    let accomplishmentsTextDataForPending = [];
    let accomplishmentsTextForPending = "";
    for (var j = 0; j < pending?.interested_candidates.length; j++) {
      pendingAccomplishmentsData.push([]);
    }
    for (var pendingMember in pending?.interested_candidates) {
      for (var pendingAccomplishment in pending?.interested_candidates[
        pendingMember
      ]?.accomplishments) {
        pendingAccomplishmentsData[pendingMember].push(
          pending?.interested_candidates[pendingMember].accomplishments[
            pendingAccomplishment
          ]
        );
      }
    }
    for (let index in pendingAccomplishmentsData) {
      for (let ind in pendingAccomplishmentsData[index]) {
        accomplishmentsTextForPending +=
          "<div><li>" +
          pendingAccomplishmentsData[index][ind] +
          "</li></div><br>";
      }
      accomplishmentsTextDataForPending.push(accomplishmentsTextForPending);
      accomplishmentsTextForPending = "";
    }

    // For matched 'View More' text 'Matched' tab
    let matchedAccomplishmentsData = [];
    let accomplishmentsTextDataForMatched = [];
    let accomplishmentsTextForMatched = "";
    for (var k = 0; k < matched?.interested_candidates.length; k++) {
      matchedAccomplishmentsData.push([]);
    }
    for (var matchedMember in matched?.interested_candidates) {
      for (var matchedAccomplishment in matched?.interested_candidates[
        matchedMember
      ]?.accomplishments) {
        matchedAccomplishmentsData[matchedMember].push(
          matched?.interested_candidates[matchedMember].accomplishments[
            matchedAccomplishment
          ]
        );
      }
    }
    for (let index in matchedAccomplishmentsData) {
      for (let ind in matchedAccomplishmentsData[index]) {
        accomplishmentsTextForMatched +=
          "<div><li>" +
          matchedAccomplishmentsData[index][ind] +
          "</li></div><br>";
      }
      accomplishmentsTextDataForMatched.push(accomplishmentsTextForMatched);
      accomplishmentsTextForMatched = "";
    }

    // For archived 'View More' text 'Archived' tab
    let archivedAccomplishmentsData = [];
    let accomplishmentsTextDataForArchived = [];
    let accomplishmentsTextForArchived = "";
    for (var l = 0; l < archived?.interested_candidates.length; l++) {
      archivedAccomplishmentsData.push([]);
    }
    for (var archivedMember in archived?.interested_candidates) {
      for (var archivedAccomplishment in archived?.interested_candidates[
        archivedMember
      ]?.accomplishments) {
        archivedAccomplishmentsData[archivedMember].push(
          archived?.interested_candidates[archivedMember].accomplishments[
            archivedAccomplishment
          ]
        );
      }
    }
    for (let index in archivedAccomplishmentsData) {
      for (let ind in archivedAccomplishmentsData[index]) {
        accomplishmentsTextForArchived +=
          "<div><li>" +
          archivedAccomplishmentsData[index][ind] +
          "</li></div><br>";
      }
      accomplishmentsTextDataForArchived.push(accomplishmentsTextForArchived);
      accomplishmentsTextForArchived = "";
    }

    // For browsed 'Skills' tab
    let browsedSkillsData = [];
    for (var candidate in browsed?.browsed_candidates) {
      browsedSkillsData.push([]);
      for (var skill in browsed?.browsed_candidates[candidate]?._source
        ?.skills) {
        browsedSkillsData[candidate].push(
          browsed?.browsed_candidates[candidate]._source.skills[skill]
        );
      }
    }

    // For pending 'Skills' tab
    let skillsDataForPending = [];
    for (var candidatePending in pending?.interested_candidates) {
      skillsDataForPending.push([]);
      for (var skillForPending in pending?.interested_candidates[
        candidatePending
      ]?.skills) {
        skillsDataForPending[candidatePending].push(
          pending?.interested_candidates[candidatePending].skills[
            skillForPending
          ]
        );
      }
    }

    // For matched 'Skills' tab
    let skillsDataForMatched = [];
    for (var candidateMatched in matched?.interested_candidates) {
      skillsDataForMatched.push([]);
      for (var skillForMatched in matched?.interested_candidates[
        candidateMatched
      ]?.skills) {
        skillsDataForMatched[candidateMatched].push(
          matched?.interested_candidates[candidateMatched].skills[
            skillForMatched
          ]
        );
      }
    }

    // For archived 'Skills' tab
    let skillsDataForArchived = [];
    for (var candidateArchived in archived?.interested_candidates) {
      skillsDataForArchived.push([]);
      for (var skillForArchived in archived?.interested_candidates[
        candidateArchived
      ]?.skills) {
        skillsDataForArchived[candidateArchived].push(
          archived?.interested_candidates[candidateArchived].skills[
            skillForArchived
          ]
        );
      }
    }

    let loading =
      this.state?.openOptions &&
      this.state?.jobTitleOptions?.length === 0 &&
      this.state?.jobTitle?.length > 0;

    return (
      <div className={classes.root}>
        <div className={classes.appFrame}>
          <Breadcrumbs aria-label="breadcrumb">
            <Link
              color="inherit"
              className={classes.breadcrumbLink}
              onClick={(event) => this.goToMembers(event)}
            >
              Members
            </Link>
            <Link
              color="textPrimary"
              className={classes.breadcrumbLink}
              aria-current="page"
            >
              {this.state.value === 0
                ? "Browse"
                : this.state.value === 1
                ? "Pending Reply"
                : this.state.value === 2
                ? "Matched"
                : this.state.value === 3
                ? "Archived"
                : null}
            </Link>
          </Breadcrumbs>
        </div>
        <main className={classes.content}>
          <AntTabs
            variant="scrollable"
            scrollButtons="auto"
            value={this.state.value}
            onChange={this.handleTabChange}
            aria-label="ant example"
          >
            <AntTab label={browsedCount} />
            <AntTab label={pendingCount} />
            <AntTab label={matchedCount} />
            <AntTab label={archivedCount} />
          </AntTabs>
          {this.state.value === 0 ? (
            <Grid
              container
              spacing={3}
              className={classNames(
                classes.justifyContentSpaceBetween,
                classes.verySmallTopMargin
              )}
            >
              <Grid item xs={12} sm={3} md={3} lg={3} xl={3}>
                <Autocomplete
                  id="members-industry-label"
                  title={JSON.parse(secureLocalStorage.getItem("industry"))}
                  options={constants?.industry}
                  getOptionLabel={(option) => option}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      placeholder="Choose one"
                      required
                      label="Industry"
                      defaultValue={JSON.parse(
                        secureLocalStorage.getItem("industry")
                      )}
                      variant="outlined"
                    />
                  )}
                  onChange={(event, value) => {
                    this.setIndustry(value);
                  }}
                  value={
                    JSON.parse(secureLocalStorage.getItem("industry")) ||
                    this.state.industry
                  }
                ></Autocomplete>
              </Grid>
              <Grid item xs={12} sm={3} md={3} lg={3} xl={3}>
                <Autocomplete
                  id="members-JobTitle"
                  autoComplete
                  value={{
                    _source: {
                      Title:
                        secureLocalStorage.getItem("jobTitle") ||
                        this.state.jobTitleValue ||
                        this.state.jobTitle,
                    },
                  }}
                  open={this.state.openOptions}
                  onChange={(event, value) => {
                    this.setJobTitle(value);
                  }}
                  onOpen={() => {
                    this.setState({ openOptions: true });
                  }}
                  onClose={() => {
                    this.setState({ openOptions: false });
                  }}
                  getOptionSelected={(option, value) =>
                    option?._source?.Title === value.name
                  }
                  getOptionLabel={(option) => option?._source?.Title}
                  options={this.state.jobTitleOptions}
                  // loading={loading}
                  filterOptions={(options) => {
                    let filtered = options;
                    return filtered;
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Title"
                      id="jobTitle"
                      name="jobTitle"
                      required
                      value={this.state.jobTitleValue}
                      variant="outlined"
                      InputProps={{
                        ...params.InputProps,
                        endAdornment: (
                          <React.Fragment>
                            {loading ? (
                              <CircularProgress
                                className={classes.verySmallMarginRight}
                                color="inherit"
                                size={20}
                              />
                            ) : null}
                            {params.InputProps.endAdornment}
                          </React.Fragment>
                        ),
                      }}
                      title={this.state.jobTitleValue}
                      onChange={this.handleInputChange("jobTitle")}
                      placeholder="Enter job title"
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12} sm={3} md={3} lg={3} xl={3}>
                <GoogleMaps
                  isLocationEmpty={
                    secureLocalStorage.getItem("locationDetails")
                      ? secureLocalStorage.getItem("locationDetails")
                      : ""
                  }
                  updateRequire={() => {
                    console.log("updateRequire");
                  }}
                  isRequire={false}
                ></GoogleMaps>
              </Grid>
              <Grid item xs={12} sm={3} md={3} lg={3} xl={3}>
                <Button
                  type="submit"
                  disableElevation
                  color="primary"
                  variant="contained"
                  size="large"
                  className={classes.filterGoBtn}
                  onClick={(event) => {
                    this.handleGoClick(event);
                  }}
                >
                  Go
                </Button>
                {this.state.industry ||
                this.state.jobTitleValue ||
                secureLocalStorage.getItem("locationDetails") ? (
                  <Link
                    className={classes.customLink}
                    onClick={() => {
                      this.resetFilter();
                    }}
                  >
                    Clear All
                  </Link>
                ) : null}
              </Grid>
            </Grid>
          ) : null}

          {this.state.value === 0 ? (
            browsed?.browsed_candidates?.length > 0 ? (
              <Grid
                spacing={6}
                container
                className={classes.verySmallTopMargin}
              >
                {browsed.browsed_candidates.map((candidate, index) => (
                  <Fragment key={index}>
                    <Grid item xs={12} sm={12} md={6} lg={4} xl={4}>
                      <Card className={classes.liteProfileCard}>
                        <CardHeader
                          title={
                            // candidate?.highlight?.title && candidate?.highlight?.title.length > 0
                            // ?
                            // <div>
                            // <MatchedCriteria title='Profile Match' style={{marginRight: '5px'}} />
                            // {candidate._source.title || '-'}
                            // </div>
                            // :
                            candidate._source.title || "-"
                          }
                          subheader={
                            <Fragment>
                              <Grid
                                container
                                className={classes.smallTopMargin}
                              >
                                <Grid
                                  className={classes.smallBottomMargin}
                                  item
                                  xs={12}
                                  sm={12}
                                  md={12}
                                  lg={12}
                                  xl={12}
                                >
                                  <Typography
                                    color="textPrimary"
                                    className={classes.smallBottomMargin}
                                  >
                                    {/* {candidate?.highlight?.location && candidate?.highlight?.location.length > 0
                                      ?
                                      <MatchedCriteria title='Profile Match' style={{marginRight: '5px'}} />
                                      :
                                      null
                                    } */}
                                    <LocationOnIcon
                                      className={classNames(
                                        classes.smallFont,
                                        classes.alignBaslineMiddle,
                                        classes.colorDefault
                                      )}
                                    ></LocationOnIcon>
                                    <span
                                      className={classNames(
                                        classes.alignBaslineMiddle,
                                        classes.verySmallMarginLeft,
                                        classes.font14p
                                      )}
                                    >
                                      {this.capitalize(
                                        candidate._source.current_location
                                          ?.city || "-"
                                      ) +
                                        ", " +
                                        this.capitalize(
                                          candidate._source.current_location
                                            ?.state || "-"
                                        ) +
                                        ", " +
                                        this.capitalize(
                                          candidate._source.current_location
                                            ?.country || "-"
                                        )}
                                    </span>
                                  </Typography>
                                </Grid>
                              </Grid>
                              <TableContainer>
                                <Table aria-label="simple table">
                                  <TableBody>
                                    <TableRow>
                                      <TableCell
                                        width="33.3%"
                                        className={classNames(
                                          classes.colorDefault,
                                          classes.boldFont
                                        )}
                                        align="center"
                                      >
                                        {/* {candidate?.highlight?.industry && candidate?.highlight?.industry.length > 0
                                          ?
                                          <MatchedCriteria title='Profile Match' style={{marginRight: '5px'}} />
                                          :
                                          null
                                        } */}
                                        {candidate._source?.industry || "-"}
                                      </TableCell>
                                      <TableCell
                                        width="33.3%"
                                        className={classNames(
                                          classes.colorDefault,
                                          classes.boldFont
                                        )}
                                        align="center"
                                      >
                                        {candidate._source?.experience || "-"}
                                      </TableCell>
                                      <TableCell
                                        width="33.3%"
                                        className={classNames(
                                          classes.colorDefault,
                                          classes.boldFont
                                        )}
                                        align="center"
                                      >
                                        {candidate._source?.highest_team_size ||
                                          "-"}
                                      </TableCell>
                                    </TableRow>
                                    <TableRow>
                                      <TableCell
                                        width="33.3%"
                                        className={classNames(
                                          classes.colorDefault,
                                          classes.font14p
                                        )}
                                        align="center"
                                      >
                                        INDUSTRY
                                      </TableCell>
                                      <TableCell
                                        width="33.3%"
                                        className={classNames(
                                          classes.colorDefault,
                                          classes.font14p
                                        )}
                                        align="center"
                                      >
                                        EXPERIENCE
                                      </TableCell>
                                      <TableCell
                                        width="33.3%"
                                        className={classNames(
                                          classes.colorDefault,
                                          classes.font14p
                                        )}
                                        align="center"
                                      >
                                        TEAM SIZE
                                      </TableCell>
                                    </TableRow>
                                  </TableBody>
                                </Table>
                              </TableContainer>
                            </Fragment>
                          }
                          className={classes.customCardHeader}
                        ></CardHeader>
                        <div className={classes.liteProfileHighlights}>
                          <Grid container>
                            <Grid item xs={7} sm={7} md={8} lg={8} xl={8}>
                              <Typography
                                component="span"
                                className={classNames(
                                  classes.floatLeft,
                                  classes.boldFont
                                )}
                              >
                                HIGHLIGHTS
                              </Typography>
                            </Grid>
                            <Grid item xs={5} sm={5} md={4} lg={4} xl={4}>
                              <Typography
                                component="span"
                                color="primary"
                                className={classNames(
                                  classes.floatRight,
                                  classes.font14p
                                )}
                              >
                                WORKED IN
                              </Typography>
                            </Grid>
                            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                              <Typography
                                title={
                                  candidate?._source?.countries
                                    ? this.capitalize(
                                        candidate?._source?.countries.join(", ")
                                      )
                                    : this.capitalize(
                                        candidate?._source?.countries
                                      )
                                }
                                component="span"
                                align="right"
                                className={classNames(
                                  classes.floatRight,
                                  classes.font14p
                                )}
                              >
                                {candidate?._source?.countries
                                  ? truncate(
                                      candidate?._source?.countries.join(", "),
                                      30
                                    )
                                  : truncate(candidate?._source?.countries, 30)}
                              </Typography>
                            </Grid>
                          </Grid>
                        </div>
                        <CardContent className={classes.liteProfileCardContent}>
                          <InnerTabs index={index} tab={"Browse"} />
                        </CardContent>
                        <CardActions className={classes.customCardActions}>
                          <Fab
                            className={classes.noBoxShadow}
                            title="Dislike"
                            size="large"
                            aria-label="dislike"
                            onClick={(event) =>
                              this.handleDislikeCandidateRecommendation(
                                candidate,
                                event
                              )
                            }
                          >
                            <DislikeIcon />
                          </Fab>
                          <Fab
                            className={classNames(
                              classes.noBoxShadow,
                              classes.likeButton
                            )}
                            title="Like"
                            size="large"
                            aria-label="like"
                            onClick={(event) =>
                              this.handleLikeCandidateRecommendation(
                                candidate,
                                event
                              )
                            }
                          >
                            <LikeIcon />
                          </Fab>
                        </CardActions>
                      </Card>
                    </Grid>
                  </Fragment>
                ))}
                {/* Archive Confirm Popup */}
                <Dialog
                  open={this.state.dislikePopupOpen}
                  aria-labelledby="alert-dialog-title"
                  aria-describedby="alert-dialog-description"
                  fullWidth
                  disableBackdropClick
                  disableEscapeKeyDown
                >
                  <DialogTitle
                    id="alert-dialog-title"
                    className={classes.customDialog}
                    onClose={this.handleCloseArchivePopup}
                  >
                    Why are you passing on this candidate?
                  </DialogTitle>
                  <DialogContent>
                    <DialogContentText
                      id="alert-dialog-description"
                      className={classNames(classes.largeFont)}
                    >
                      <Typography variant="h6" color="textSecondary">
                        This information will not be shared with the candidate.
                      </Typography>
                      <FormControl
                        required
                        component="fieldset"
                        className={classNames(
                          classes.fullWidth,
                          classes.mediumTopMargin,
                          classes.colorDefault
                        )}
                      >
                        <RadioGroup
                          aria-label="remote"
                          name="remote"
                          value={this.state.archiveReason}
                          onChange={this.handleInputChange("archiveReason")}
                        >
                          <Grid container>
                            <Grid
                              item
                              xs={12}
                              sm={12}
                              md={12}
                              lg={6}
                              xl={6}
                              className={classes.displayFlex}
                            >
                              <FormControlLabel
                                control={<Radio />}
                                label="Experience not relevant"
                                value="Experience not relevant"
                              />
                            </Grid>
                            <Grid
                              item
                              xs={12}
                              sm={12}
                              md={12}
                              lg={6}
                              xl={6}
                              className={classes.displayFlex}
                            >
                              <FormControlLabel
                                control={<Radio />}
                                label="Competencies not relevant"
                                value="Competencies not relevant"
                              />
                            </Grid>
                            <Grid
                              item
                              xs={12}
                              sm={12}
                              md={12}
                              lg={6}
                              xl={6}
                              className={classes.displayFlex}
                            >
                              <FormControlLabel
                                control={<Radio />}
                                label="Industry not preferable"
                                value="Industry not preferable"
                              />
                            </Grid>
                            <Grid
                              item
                              xs={12}
                              sm={12}
                              md={12}
                              lg={6}
                              xl={6}
                              className={classes.displayFlex}
                            >
                              <FormControlLabel
                                control={<Radio />}
                                label="Location not preferable"
                                value="Location not preferable"
                              />
                            </Grid>
                            <Grid
                              item
                              xs={12}
                              sm={12}
                              md={12}
                              lg={6}
                              xl={6}
                              className={classes.displayFlex}
                            >
                              <FormControlLabel
                                value="Other"
                                control={<Radio />}
                                label="Other"
                              />
                            </Grid>
                          </Grid>
                        </RadioGroup>
                      </FormControl>
                      {this.state.archiveReason === "Other" ? (
                        <>
                          <TextField
                            fullWidth
                            label="Reason"
                            autoComplete="otherArchiveReason"
                            margin="normal"
                            id="otherArchiveReason"
                            name="otherArchiveReason"
                            value={this.state.otherArchiveReason}
                            onChange={this.handleInputChange(
                              "otherArchiveReason"
                            )}
                            placeholder="Reason for archiving (optional)"
                            error={
                              this.state.isValidOtherArchiveReason
                                ? false
                                : true
                            }
                            helperText={
                              !this.state.isValidOtherArchiveReason ? "" : ""
                            }
                          />
                          <Typography
                            className={classNames(
                              classes.floatRight,
                              classes.smallFont,
                              classes.verySmallTopMargin
                            )}
                            color="secondary"
                          >
                            {this.state.otherArchiveReason.length +
                              "/" +
                              charCount}{" "}
                            characters
                          </Typography>
                        </>
                      ) : null}
                    </DialogContentText>
                  </DialogContent>
                  <DialogActions>
                    <Button
                      size="large"
                      onClick={this.handleCloseArchivePopup}
                      variant="contained"
                      color="primary"
                      style={{ background: "#D7D9DB", color: "#000" }}
                      disableElevation
                    >
                      Cancel
                    </Button>
                    <Button
                      size="large"
                      variant="contained"
                      color="primary"
                      autoFocus
                      disableElevation
                      onClick={(event) => this.handleArchive(event)}
                    >
                      Remove from Queue
                    </Button>
                  </DialogActions>
                </Dialog>
                {/* Like Confirm Popup */}
                <Dialog
                  open={this.state.likePopupOpen}
                  aria-labelledby="alert-dialog-title"
                  aria-describedby="alert-dialog-description"
                  fullWidth
                  disableBackdropClick
                  disableEscapeKeyDown
                >
                  <DialogTitle
                    id="alert-dialog-title"
                    // className={classes.customDialog}
                    onClose={this.handleCloseLikePopup}
                  >
                    {/* <Logo width='230' className={classes.responsive} /> */}
                    Tell the candidate why you are interested in connecting
                  </DialogTitle>
                  <DialogContent>
                    <DialogContentText
                      id="alert-dialog-description"
                      className={classNames(classes.largeFont)}
                    >
                      {/* Tell the candidate why you are interested in connecting */}
                      <FormControl
                        required
                        component="fieldset"
                        className={classNames(
                          classes.fullWidth,
                          classes.mediumTopMargin,
                          classes.colorDefault
                        )}
                      >
                        <RadioGroup
                          aria-label="likePopup"
                          name="likePopup"
                          value={this.state.likeReason}
                          onChange={this.handleInputChange("likeReason")}
                        >
                          <Grid container>
                            <Grid
                              item
                              xs={12}
                              sm={12}
                              md={12}
                              lg={6}
                              xl={6}
                              className={classes.displayFlex}
                            >
                              <FormControlLabel
                                control={<Radio />}
                                label="Current Job Opening"
                                value="Current Job Opening"
                              />
                            </Grid>
                            <Grid
                              item
                              xs={12}
                              sm={12}
                              md={12}
                              lg={6}
                              xl={6}
                              className={classes.displayFlex}
                            >
                              <FormControlLabel
                                control={<Radio />}
                                label="Future Opening"
                                value="Future Opening"
                              />
                            </Grid>
                            <Grid
                              item
                              xs={12}
                              sm={12}
                              md={12}
                              lg={6}
                              xl={6}
                              className={classes.displayFlex}
                            >
                              <FormControlLabel
                                control={<Radio />}
                                label="Board Advisory Role"
                                value="Board Advisory Role"
                              />
                            </Grid>
                            <Grid
                              item
                              xs={12}
                              sm={12}
                              md={12}
                              lg={6}
                              xl={6}
                              className={classes.displayFlex}
                            >
                              <FormControlLabel
                                value="Other"
                                control={<Radio />}
                                label="Other"
                              />
                            </Grid>
                          </Grid>
                        </RadioGroup>
                      </FormControl>
                      {this.state.likeReason === "Other" ? (
                        <>
                          <TextField
                            fullWidth
                            label="Reason"
                            autoComplete="otherLikeReason"
                            margin="normal"
                            id="otherLikeReason"
                            name="otherLikeReason"
                            value={this.state.otherLikeReason}
                            onChange={this.handleInputChange("otherLikeReason")}
                            placeholder="Reason for liking (optional)"
                            error={
                              this.state.isValidOtherLikeReason ? false : true
                            }
                            helperText={
                              !this.state.isValidOtherLikeReason ? "" : ""
                            }
                          />
                          <Typography
                            className={classNames(
                              classes.floatRight,
                              classes.smallFont,
                              classes.verySmallTopMargin
                            )}
                            color="secondary"
                          >
                            {this.state.otherLikeReason.length +
                              "/" +
                              charCount}{" "}
                            characters
                          </Typography>
                        </>
                      ) : null}
                    </DialogContentText>
                  </DialogContent>
                  <DialogActions>
                    <Button
                      size="large"
                      onClick={this.handleCloseLikePopup}
                      variant="contained"
                      color="primary"
                      style={{ background: "#D7D9DB", color: "#000" }}
                      disableElevation
                    >
                      Cancel
                    </Button>
                    <Button
                      size="large"
                      variant="contained"
                      color="primary"
                      autoFocus
                      disableElevation
                      onClick={(event) => this.handleLike(event)}
                    >
                      Connect
                    </Button>
                  </DialogActions>
                </Dialog>
              </Grid>
            ) : (
              <div className={classes.largeTopMargin}>
                <BorwseEmpty width="230" className={classes.responsive} />
                <Typography variant="h6" className={classes.boldFont}>
                  Enter the filters above that best fit your member search to
                  proceed.
                </Typography>
                <Typography color="textSecondary">
                  You will soon receive a pool of candidates to browse from.
                </Typography>
              </div>
            )
          ) : null}

          {this.state.value === 1 ? (
            pending?.interested_candidates?.length > 0 ? (
              <Grid
                spacing={6}
                container
                className={classes.verySmallTopMargin}
              >
                {pending.interested_candidates.map((candidate, index) => (
                  <Grid item xs={12} sm={12} md={6} lg={4} xl={4}>
                    <Card key={index} className={classes.liteProfileCard}>
                      <CardHeader
                        title={
                          // candidate?.highlight?.title && candidate?.highlight?.title.length > 0
                          // ?
                          // <div>
                          // <MatchedCriteria title='Profile Match' style={{marginRight: '5px'}} />
                          // {candidate.title || '-'}
                          // </div>
                          // :
                          candidate.title || "-"
                        }
                        subheader={
                          <Fragment>
                            <Grid
                              container
                              className={classes.smallBottomMargin}
                            >
                              <Grid
                                className={classes.smallBottomMargin}
                                item
                                xs={12}
                                sm={12}
                                md={12}
                                lg={12}
                                xl={12}
                              >
                                <Typography
                                  color="textPrimary"
                                  className={classes.smallBottomMargin}
                                >
                                  <LocationOnIcon
                                    className={classNames(
                                      classes.smallFont,
                                      classes.alignBaslineMiddle,
                                      classes.colorDefault
                                    )}
                                  ></LocationOnIcon>
                                  <span
                                    className={classNames(
                                      classes.alignBaslineMiddle,
                                      classes.verySmallMarginLeft,
                                      classes.font14p
                                    )}
                                  >
                                    {this.capitalize(
                                      candidate.location?.city || "-"
                                    ) +
                                      ", " +
                                      this.capitalize(
                                        candidate.location?.state || "-"
                                      ) +
                                      ", " +
                                      this.capitalize(
                                        candidate.location?.country || "-"
                                      )}
                                  </span>
                                </Typography>
                              </Grid>
                            </Grid>
                            <TableContainer>
                              <Table aria-label="simple table">
                                <TableBody>
                                  <TableRow>
                                    <TableCell
                                      width="33.3%"
                                      className={classNames(
                                        classes.colorDefault,
                                        classes.boldFont
                                      )}
                                      align="center"
                                    >
                                      {/* {candidate?.highlight?.industry && candidate?.highlight?.industry.length > 0
                                          ?
                                          <MatchedCriteria title='Profile Match' style={{marginRight: '5px'}} />
                                          :
                                          null
                                        } */}
                                      {candidate.industry || "-"}
                                    </TableCell>
                                    <TableCell
                                      width="33.3%"
                                      className={classNames(
                                        classes.colorDefault,
                                        classes.boldFont
                                      )}
                                      align="center"
                                    >
                                      {/* {candidate?.highlight?.experience && candidate?.highlight?.experience.length > 0
                                          ?
                                          <MatchedCriteria title='Profile Match' style={{marginRight: '5px'}} />
                                          :
                                          null
                                        } */}
                                      {candidate.experience || "-"}
                                    </TableCell>
                                    <TableCell
                                      width="33.3%"
                                      className={classNames(
                                        classes.colorDefault,
                                        classes.boldFont
                                      )}
                                      align="center"
                                    >
                                      {/* {candidate?.highlight?.team_size && candidate?.highlight?.team_size.length > 0
                                          ?
                                          <MatchedCriteria title='Profile Match' style={{marginRight: '5px'}} />
                                          :
                                          null
                                        } */}
                                      {candidate.team_size || "-"}
                                    </TableCell>
                                  </TableRow>
                                  <TableRow>
                                    <TableCell
                                      width="33.3%"
                                      className={classNames(
                                        classes.colorDefault,
                                        classes.font14p
                                      )}
                                      align="center"
                                    >
                                      INDUSTRY
                                    </TableCell>
                                    <TableCell
                                      width="33.3%"
                                      className={classNames(
                                        classes.colorDefault,
                                        classes.font14p
                                      )}
                                      align="center"
                                    >
                                      EXPERIENCE
                                    </TableCell>
                                    <TableCell
                                      width="33.3%"
                                      className={classNames(
                                        classes.colorDefault,
                                        classes.font14p
                                      )}
                                      align="center"
                                    >
                                      TEAM SIZE
                                    </TableCell>
                                  </TableRow>
                                </TableBody>
                              </Table>
                            </TableContainer>
                          </Fragment>
                        }
                        className={classes.customCardHeader}
                      ></CardHeader>
                      <div className={classes.liteProfileHighlights}>
                        <Grid container>
                          <Grid item xs={7} sm={7} md={8} lg={8} xl={8}>
                            <Typography
                              component="span"
                              className={classNames(
                                classes.floatLeft,
                                classes.boldFont
                              )}
                            >
                              HIGHLIGHTS
                            </Typography>
                          </Grid>
                          <Grid item xs={5} sm={5} md={4} lg={4} xl={4}>
                            <Typography
                              component="span"
                              color="primary"
                              className={classNames(
                                classes.floatRight,
                                classes.font14p
                              )}
                            >
                              WORKED IN
                            </Typography>
                          </Grid>
                          <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                            <Typography
                              title={this.capitalize(candidate?.map)}
                              component="span"
                              align="right"
                              className={classNames(
                                classes.floatRight,
                                classes.font14p
                              )}
                            >
                              {truncate(candidate?.map, 30)}
                            </Typography>
                          </Grid>
                        </Grid>
                      </div>
                      <CardContent
                        className={classes.customLiteProfileCardContent}
                      >
                        <InnerTabs index={index} tab={"Pending"} />
                      </CardContent>
                      <CardActions
                        className={classes.customCardActions}
                      ></CardActions>
                    </Card>
                    <Button
                      className={classNames(
                        classes.floatLeft,
                        classes.historyButton
                      )}
                      onClick={(event) => {
                        this.viewHistory(candidate?.recommendation_id, event);
                      }}
                    >
                      View History
                    </Button>
                  </Grid>
                ))}
              </Grid>
            ) : (
              <div className={classes.largeTopMargin}>
                <PendingEmpty width="230" className={classes.responsive} />
                <Typography variant="h6" className={classes.boldFont}>
                  No pending candidates yet.
                </Typography>
                <Typography color="textSecondary">
                  Please like a candidate from your Recommendations.
                </Typography>
              </div>
            )
          ) : null}

          {this.state.value === 2 ? (
            matched?.interested_candidates?.length > 0 ? (
              <Grid
                spacing={6}
                container
                className={classes.verySmallTopMargin}
              >
                {matched.interested_candidates.map((candidate, index) => (
                  <Grid item xs={12} sm={12} md={6} lg={4} xl={4}>
                    <Card key={index} className={classes.liteProfileCard}>
                      <CardHeader
                        title={
                          <TableContainer
                            className={classes.fixedTitleTableHeight}
                          >
                            <Table aria-label="simple table">
                              <TableBody>
                                <TableRow>
                                  <TableCell
                                    width="33.3%"
                                    align="left"
                                    style={{ width: "5%" }}
                                  >
                                    <Avatar
                                      src={candidate?.profile_image || "/"}
                                      alt={this.capitalize(
                                        candidate.candidate_fname +
                                          candidate.candidate_lname
                                      )}
                                      variant="circular"
                                      className={classNames(
                                        classes.xLargeAvatar,
                                        classes.cursorPointer
                                      )}
                                      onClick={() =>
                                        this.handleFullProfileClick(
                                          candidate.candidate_id,
                                          candidate.recommendation_id
                                        )
                                      }
                                    ></Avatar>
                                  </TableCell>
                                  <TableCell
                                    width="33.3%"
                                    className={classNames(
                                      classes.colorDefault,
                                      classes.font14p
                                    )}
                                    align="left"
                                  >
                                    <Typography
                                      variant="h5"
                                      className={classNames(
                                        classes.colorDefault,
                                        classes.boldFont
                                      )}
                                    >
                                      {this.capitalize(
                                        (candidate.candidate_fname || "-") +
                                          " " +
                                          candidate.candidate_lname
                                      )}
                                    </Typography>
                                    <Typography
                                      className={classes.colorDefault}
                                    >
                                      {
                                        // candidate?.highlight?.title && candidate?.highlight?.title.length > 0
                                        // ?
                                        // <div>
                                        // <MatchedCriteria title='Profile Match' style={{marginRight: '5px'}} />
                                        // {candidate.title || '-'}
                                        // </div>
                                        // :
                                        candidate.title || "-"
                                      }
                                    </Typography>
                                    <Typography
                                      color="textPrimary"
                                      className={classes.smallBottomMargin}
                                    >
                                      <LocationOnIcon
                                        className={classNames(
                                          classes.smallFont,
                                          classes.alignBaslineMiddle,
                                          classes.colorDefault
                                        )}
                                      ></LocationOnIcon>
                                      <span
                                        className={classNames(
                                          classes.alignBaslineMiddle,
                                          classes.verySmallMarginLeft,
                                          classes.font14p
                                        )}
                                      >
                                        {this.capitalize(
                                          candidate.location?.city || "-"
                                        ) +
                                          ", " +
                                          this.capitalize(
                                            candidate.location?.state || "-"
                                          ) +
                                          ", " +
                                          this.capitalize(
                                            candidate.location?.country || "-"
                                          )}
                                      </span>
                                    </Typography>
                                  </TableCell>
                                </TableRow>
                              </TableBody>
                            </Table>
                          </TableContainer>
                        }
                        subheader={
                          <Fragment>
                            <TableContainer
                              className={classes.fixedTableHeight}
                            >
                              <Table aria-label="simple table">
                                <TableBody>
                                  <TableRow>
                                    <TableCell
                                      width="33.3%"
                                      className={classNames(
                                        classes.colorDefault,
                                        classes.boldFont
                                      )}
                                      align="center"
                                    >
                                      {/* {candidate?.highlight?.industry && candidate?.highlight?.industry.length > 0
                                          ?
                                          <MatchedCriteria title='Profile Match' style={{marginRight: '5px'}} />
                                          :
                                          null
                                        } */}
                                      {candidate.industry || "-"}
                                    </TableCell>
                                    <TableCell
                                      width="33.3%"
                                      className={classNames(
                                        classes.colorDefault,
                                        classes.boldFont
                                      )}
                                      align="center"
                                    >
                                      {/* {candidate?.highlight?.experience && candidate?.highlight?.experience.length > 0
                                          ?
                                          <MatchedCriteria title='Profile Match' style={{marginRight: '5px'}} />
                                          :
                                          null
                                        } */}
                                      {candidate.experience || "-"}
                                    </TableCell>
                                    <TableCell
                                      width="33.3%"
                                      className={classNames(
                                        classes.colorDefault,
                                        classes.boldFont
                                      )}
                                      align="center"
                                    >
                                      {/* {candidate?.highlight?.team_size && candidate?.highlight?.team_size.length > 0
                                          ?
                                          <MatchedCriteria title='Profile Match' style={{marginRight: '5px'}} />
                                          :
                                          null
                                        } */}
                                      {candidate.team_size || "-"}
                                    </TableCell>
                                  </TableRow>
                                  <TableRow>
                                    <TableCell
                                      width="33.3%"
                                      className={classNames(
                                        classes.colorDefault,
                                        classes.font14p
                                      )}
                                      align="center"
                                    >
                                      INDUSTRY
                                    </TableCell>
                                    <TableCell
                                      width="33.3%"
                                      className={classNames(
                                        classes.colorDefault,
                                        classes.font14p
                                      )}
                                      align="center"
                                    >
                                      EXPERIENCE
                                    </TableCell>
                                    <TableCell
                                      width="33.3%"
                                      className={classNames(
                                        classes.colorDefault,
                                        classes.font14p
                                      )}
                                      align="center"
                                    >
                                      TEAM SIZE
                                    </TableCell>
                                  </TableRow>
                                </TableBody>
                              </Table>
                            </TableContainer>
                          </Fragment>
                        }
                        className={classes.matchedCustomCardHeader}
                      ></CardHeader>
                      <div className={classes.liteProfileHighlights}>
                        <Grid container>
                          <Grid item xs={7} sm={7} md={8} lg={8} xl={8}>
                            <Typography
                              component="span"
                              className={classNames(
                                classes.floatLeft,
                                classes.boldFont
                              )}
                            >
                              HIGHLIGHTS
                            </Typography>
                          </Grid>
                          <Grid item xs={5} sm={5} md={4} lg={4} xl={4}>
                            <Typography
                              component="span"
                              color="primary"
                              className={classNames(
                                classes.floatRight,
                                classes.font14p
                              )}
                            >
                              WORKED IN
                            </Typography>
                          </Grid>
                          <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                            <Typography
                              title={this.capitalize(candidate?.map)}
                              component="span"
                              align="right"
                              className={classNames(
                                classes.floatRight,
                                classes.font14p
                              )}
                            >
                              {truncate(candidate?.map, 30)}
                            </Typography>
                          </Grid>
                        </Grid>
                      </div>
                      <CardContent className={classes.liteProfileCardContent}>
                        <InnerTabs index={index} tab={"Matched"} />
                      </CardContent>
                      <CardActions className={classes.justifyContentCenter}>
                        <Button
                          className={classes.equalButtons}
                          size="medium"
                          variant="contained"
                          color="primary"
                          disableElevation
                          onClick={() =>
                            this.handleFullProfileClick(
                              candidate.candidate_id,
                              candidate.recommendation_id
                            )
                          }
                        >
                          View Full Profile
                        </Button>
                        {JobDetails.job_type === "Normal" && (
                          <Button
                            className={classNames(
                              classes.equalButtons,
                              classes.defaultButton
                            )}
                            size="medium"
                            variant="contained"
                            disableElevation
                            onClick={(event) =>
                              messagesActions.createChannel(
                                candidate.candidate_id,
                                event
                              )
                            }
                          >
                            Send Message
                          </Button>
                        )}
                      </CardActions>
                    </Card>
                    <Button
                      className={classNames(
                        classes.floatLeft,
                        classes.historyButton
                      )}
                      onClick={(event) => {
                        this.viewHistory(candidate?.recommendation_id, event);
                      }}
                    >
                      View History
                    </Button>
                  </Grid>
                ))}
              </Grid>
            ) : (
              <div className={classes.largeTopMargin}>
                <MatchedEmpty width="230" className={classes.responsive} />
                <Typography variant="h6" className={classes.boldFont}>
                  You have no matched candidates yet.
                </Typography>
                <Typography color="textSecondary">
                  Once the candidate accepts your invite to connect, you will
                  see them here.
                </Typography>
              </div>
            )
          ) : null}

          {this.state.value === 3 ? (
            archived?.interested_candidates?.length > 0 ? (
              <Grid
                spacing={6}
                container
                className={classes.verySmallTopMargin}
              >
                {archived.interested_candidates.map((candidate, index) => (
                  <Grid item xs={12} sm={12} md={6} lg={4} xl={4}>
                    {candidate.candidate_fname && candidate.candidate_lname ? (
                      <Card key={index} className={classes.liteProfileCard}>
                        <CardHeader
                          title={
                            <TableContainer
                              className={classes.fixedTitleTableHeight}
                            >
                              <Table aria-label="simple table">
                                <TableBody>
                                  <TableRow>
                                    <TableCell
                                      width="33.3%"
                                      align="left"
                                      style={{ width: "5%" }}
                                    >
                                      <Avatar
                                        src={candidate?.profile_image || "/"}
                                        alt={this.capitalize(
                                          candidate.candidate_fname +
                                            candidate.candidate_lname
                                        )}
                                        variant="circular"
                                        className={classNames(
                                          classes.xLargeAvatar,
                                          classes.cursorPointer
                                        )}
                                        onClick={() =>
                                          this.handleFullProfileClick(
                                            candidate.candidate_id,
                                            candidate.recommendation_id
                                          )
                                        }
                                      ></Avatar>
                                    </TableCell>
                                    <TableCell
                                      width="33.3%"
                                      className={classNames(
                                        classes.colorDefault,
                                        classes.font14p
                                      )}
                                      align="left"
                                    >
                                      <Typography
                                        variant="h5"
                                        className={classNames(
                                          classes.colorDefault,
                                          classes.boldFont
                                        )}
                                      >
                                        {this.capitalize(
                                          (candidate.candidate_fname || "-") +
                                            " " +
                                            candidate.candidate_lname
                                        )}
                                      </Typography>
                                      <Typography
                                        className={classes.colorDefault}
                                      >
                                        {
                                          // candidate?.highlight?.title && candidate?.highlight?.title.length > 0
                                          // ?
                                          // <div>
                                          // <MatchedCriteria title='Profile Match' style={{marginRight: '5px'}} />
                                          // {candidate.title || '-'}
                                          // </div>
                                          // :
                                          candidate.title || "-"
                                        }
                                      </Typography>
                                      <Typography
                                        color="textPrimary"
                                        className={classes.smallBottomMargin}
                                      >
                                        <LocationOnIcon
                                          className={classNames(
                                            classes.smallFont,
                                            classes.alignBaslineMiddle,
                                            classes.colorDefault
                                          )}
                                        ></LocationOnIcon>
                                        <span
                                          className={classNames(
                                            classes.alignBaslineMiddle,
                                            classes.verySmallMarginLeft,
                                            classes.font14p
                                          )}
                                        >
                                          {this.capitalize(
                                            candidate.location?.city || "-"
                                          ) +
                                            ", " +
                                            this.capitalize(
                                              candidate.location?.state || "-"
                                            ) +
                                            ", " +
                                            this.capitalize(
                                              candidate.location?.country || "-"
                                            )}
                                        </span>
                                      </Typography>
                                    </TableCell>
                                  </TableRow>
                                </TableBody>
                              </Table>
                            </TableContainer>
                          }
                          subheader={
                            <Fragment>
                              <TableContainer
                                className={classes.fixedTableHeight}
                              >
                                <Table aria-label="simple table">
                                  <TableBody>
                                    <TableRow>
                                      <TableCell
                                        width="33.3%"
                                        className={classNames(
                                          classes.colorDefault,
                                          classes.boldFont
                                        )}
                                        align="center"
                                      >
                                        {/* {candidate?.highlight?.industry && candidate?.highlight?.industry.length > 0
                                          ?
                                          <MatchedCriteria title='Profile Match' style={{marginRight: '5px'}} />
                                          :
                                          null
                                        } */}
                                        {candidate.industry || "-"}
                                      </TableCell>
                                      <TableCell
                                        width="33.3%"
                                        className={classNames(
                                          classes.colorDefault,
                                          classes.boldFont
                                        )}
                                        align="center"
                                      >
                                        {/* {candidate?.highlight?.experience && candidate?.highlight?.experience.length > 0
                                          ?
                                          <MatchedCriteria title='Profile Match' style={{marginRight: '5px'}} />
                                          :
                                          null
                                        } */}
                                        {candidate.experience || "-"}
                                      </TableCell>
                                      <TableCell
                                        width="33.3%"
                                        className={classNames(
                                          classes.colorDefault,
                                          classes.boldFont
                                        )}
                                        align="center"
                                      >
                                        {/* {candidate?.highlight?.team_size && candidate?.highlight?.team_size.length > 0
                                          ?
                                          <MatchedCriteria title='Profile Match' style={{marginRight: '5px'}} />
                                          :
                                          null
                                        } */}
                                        {candidate.team_size || "-"}
                                      </TableCell>
                                    </TableRow>
                                    <TableRow>
                                      <TableCell
                                        width="33.3%"
                                        className={classNames(
                                          classes.colorDefault,
                                          classes.font14p
                                        )}
                                        align="center"
                                      >
                                        INDUSTRY
                                      </TableCell>
                                      <TableCell
                                        width="33.3%"
                                        className={classNames(
                                          classes.colorDefault,
                                          classes.font14p
                                        )}
                                        align="center"
                                      >
                                        EXPERIENCE
                                      </TableCell>
                                      <TableCell
                                        width="33.3%"
                                        className={classNames(
                                          classes.colorDefault,
                                          classes.font14p
                                        )}
                                        align="center"
                                      >
                                        TEAM SIZE
                                      </TableCell>
                                    </TableRow>
                                  </TableBody>
                                </Table>
                              </TableContainer>
                            </Fragment>
                          }
                          className={classes.matchedCustomCardHeader}
                        ></CardHeader>
                        <div className={classes.liteProfileHighlights}>
                          <Grid container>
                            <Grid item xs={7} sm={7} md={8} lg={8} xl={8}>
                              <Typography
                                component="span"
                                className={classNames(
                                  classes.floatLeft,
                                  classes.boldFont
                                )}
                              >
                                HIGHLIGHTS
                              </Typography>
                            </Grid>
                            <Grid item xs={5} sm={5} md={4} lg={4} xl={4}>
                              <Typography
                                component="span"
                                color="primary"
                                className={classNames(
                                  classes.floatRight,
                                  classes.font14p
                                )}
                              >
                                WORKED IN
                              </Typography>
                            </Grid>
                            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                              <Typography
                                title={this.capitalize(candidate?.map)}
                                component="span"
                                align="right"
                                className={classNames(
                                  classes.floatRight,
                                  classes.font14p
                                )}
                              >
                                {truncate(candidate?.map, 30)}
                              </Typography>
                            </Grid>
                          </Grid>
                        </div>
                        <CardContent className={classes.liteProfileCardContent}>
                          <InnerTabs index={index} tab={"Archived"} />
                        </CardContent>
                        <CardActions className={classes.justifyContentCenter}>
                          <Button
                            className={classes.equalButtons}
                            size="medium"
                            variant="contained"
                            color="primary"
                            disableElevation
                            onClick={() =>
                              this.handleFullProfileClick(
                                candidate.candidate_id,
                                candidate.recommendation_id
                              )
                            }
                          >
                            View Full Profile
                          </Button>
                          {JobDetails.job_type === "Normal" && (
                            <Button
                              className={classNames(
                                classes.equalButtons,
                                classes.defaultButton
                              )}
                              size="medium"
                              variant="contained"
                              disableElevation
                              onClick={(event) =>
                                messagesActions.createChannel(
                                  candidate.candidate_id,
                                  event
                                )
                              }
                            >
                              Send Message
                            </Button>
                          )}
                        </CardActions>
                      </Card>
                    ) : (
                      <Card key={index} className={classes.liteProfileCard}>
                        <CardHeader
                          title={
                            // candidate?.highlight?.title && candidate?.highlight?.title.length > 0
                            // ?
                            // <div>
                            // <MatchedCriteria title='Profile Match' style={{marginRight: '5px'}} />
                            // {candidate.title || '-'}
                            // </div>
                            // :
                            candidate.title || "-"
                          }
                          subheader={
                            <Fragment>
                              <Grid
                                container
                                className={classes.smallTopMargin}
                              >
                                <Grid
                                  className={classes.smallBottomMargin}
                                  item
                                  xs={12}
                                  sm={12}
                                  md={12}
                                  lg={12}
                                  xl={12}
                                >
                                  <Typography
                                    color="textPrimary"
                                    className={classes.smallBottomMargin}
                                  >
                                    <LocationOnIcon
                                      className={classNames(
                                        classes.smallFont,
                                        classes.alignBaslineMiddle,
                                        classes.colorDefault
                                      )}
                                    ></LocationOnIcon>
                                    <span
                                      className={classNames(
                                        classes.alignBaslineMiddle,
                                        classes.verySmallMarginLeft,
                                        classes.font14p
                                      )}
                                    >
                                      {this.capitalize(
                                        candidate.location?.city || "-"
                                      ) +
                                        ", " +
                                        this.capitalize(
                                          candidate.location?.state || "-"
                                        ) +
                                        ", " +
                                        this.capitalize(
                                          candidate.location?.country || "-"
                                        )}
                                    </span>
                                  </Typography>
                                </Grid>
                              </Grid>
                              <TableContainer>
                                <Table aria-label="simple table">
                                  <TableBody>
                                    <TableRow>
                                      <TableCell
                                        width="33.3%"
                                        className={classNames(
                                          classes.colorDefault,
                                          classes.boldFont
                                        )}
                                        align="center"
                                      >
                                        {/* {candidate?.highlight?.industry && candidate?.highlight?.industry.length > 0
                                          ?
                                          <MatchedCriteria title='Profile Match' style={{marginRight: '5px'}} />
                                          :
                                          null
                                        } */}
                                        {candidate.industry || "-"}
                                      </TableCell>
                                      <TableCell
                                        width="33.3%"
                                        className={classNames(
                                          classes.colorDefault,
                                          classes.boldFont
                                        )}
                                        align="center"
                                      >
                                        {/* {candidate?.highlight?.experience && candidate?.highlight?.experience.length > 0
                                          ?
                                          <MatchedCriteria title='Profile Match' style={{marginRight: '5px'}} />
                                          :
                                          null
                                        } */}
                                        {candidate.experience || "-"}
                                      </TableCell>
                                      <TableCell
                                        width="33.3%"
                                        className={classNames(
                                          classes.colorDefault,
                                          classes.boldFont
                                        )}
                                        align="center"
                                      >
                                        {/* {candidate?.highlight?.team_size && candidate?.highlight?.team_size.length > 0
                                          ?
                                          <MatchedCriteria title='Profile Match' style={{marginRight: '5px'}} />
                                          :
                                          null
                                        } */}
                                        {candidate.team_size || "-"}
                                      </TableCell>
                                    </TableRow>
                                    <TableRow>
                                      <TableCell
                                        width="33.3%"
                                        className={classNames(
                                          classes.colorDefault,
                                          classes.font14p
                                        )}
                                        align="center"
                                      >
                                        INDUSTRY
                                      </TableCell>
                                      <TableCell
                                        width="33.3%"
                                        className={classNames(
                                          classes.colorDefault,
                                          classes.font14p
                                        )}
                                        align="center"
                                      >
                                        EXPERIENCE
                                      </TableCell>
                                      <TableCell
                                        width="33.3%"
                                        className={classNames(
                                          classes.colorDefault,
                                          classes.font14p
                                        )}
                                        align="center"
                                      >
                                        TEAM SIZE
                                      </TableCell>
                                    </TableRow>
                                  </TableBody>
                                </Table>
                              </TableContainer>
                            </Fragment>
                          }
                          className={classes.customCardHeader}
                        ></CardHeader>
                        <div className={classes.liteProfileHighlights}>
                          <Grid container>
                            <Grid item xs={7} sm={7} md={8} lg={8} xl={8}>
                              <Typography
                                component="span"
                                className={classNames(
                                  classes.floatLeft,
                                  classes.boldFont
                                )}
                              >
                                HIGHLIGHTS
                              </Typography>
                            </Grid>
                            <Grid item xs={5} sm={5} md={4} lg={4} xl={4}>
                              <Typography
                                component="span"
                                color="primary"
                                className={classNames(
                                  classes.floatRight,
                                  classes.font14p
                                )}
                              >
                                WORKED IN
                              </Typography>
                            </Grid>
                            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                              <Typography
                                title={this.capitalize(candidate?.map)}
                                component="span"
                                align="right"
                                className={classNames(
                                  classes.floatRight,
                                  classes.font14p
                                )}
                              >
                                {truncate(candidate?.map, 30)}
                              </Typography>
                            </Grid>
                          </Grid>
                        </div>
                        <CardContent
                          className={classes.customLiteProfileCardContent}
                        >
                          <InnerTabs index={index} tab={"Archived"} />
                        </CardContent>
                        <CardActions className={classes.customCardActions}>
                          {/* <Typography variant='h5' className={classes.dialogTitle}>Archived</Typography> */}
                          {/* <div className={classes.textAlignLeft}>
                              <StarsIcon className={classNames(classes.verySmallFont, classes.alignBaslineMiddle)}></StarsIcon>
                              <Typography className={classNames(classes.verySmallMarginLeft, classes.font10p, classes.alignBaslineMiddle)} component='span'>Profile Match</Typography>
                            </div> */}
                        </CardActions>
                      </Card>
                    )}
                    <Button
                      className={classNames(
                        classes.floatLeft,
                        classes.historyButton
                      )}
                      onClick={(event) => {
                        this.viewHistory(candidate?.recommendation_id, event);
                      }}
                    >
                      View History
                    </Button>
                    {/* <Popper open={this.state.openPopper} anchorEl={this.state.anchorEl} placement='top' transition>
                      {({ TransitionProps }) => (
                        <Fade {...TransitionProps} timeout={350}>
                            <Paper>
                            <Typography className={classes.typography}>The content of the Popper.</Typography>
                            </Paper>
                        </Fade>
                        )}
                    </Popper> */}
                  </Grid>
                ))}
              </Grid>
            ) : (
              <div className={classes.largeTopMargin}>
                <ArchivedEmpty width="230" className={classes.responsive} />
                <Typography variant="h6" className={classes.boldFont}>
                  You have nothing to archive yet.
                </Typography>
                <Typography color="textSecondary">
                  When you decline a candidate, you will see them here.
                </Typography>
              </div>
            )
          ) : null}
          {/* View History Popup */}
          <Dialog
            open={this.state.historyPopupOpen}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            fullWidth
            disableBackdropClick
            disableEscapeKeyDown
          >
            <DialogTitle
              id="alert-dialog-title"
              onClose={() => {
                this.closeHistoryPopup();
              }}
            >
              Recommendation History
            </DialogTitle>
            <DialogContent>
              <DialogContentText id="alert-dialog-description">
                <Typography
                  variant="h5"
                  align="center"
                  className={classes.colorDefault}
                >
                  {historyData?.candidate?.current_title}
                </Typography>
                <Typography
                  color="textPrimary"
                  className={classes.smallBottomMargin}
                  align="center"
                >
                  <LocationOnIcon
                    className={classNames(
                      classes.smallFont,
                      classes.alignBaslineMiddle,
                      classes.colorDefault
                    )}
                  ></LocationOnIcon>
                  <span
                    className={classNames(
                      classes.alignBaslineMiddle,
                      classes.verySmallMarginLeft,
                      classes.font14p
                    )}
                  >
                    {this.capitalize(
                      historyData?.candidate?.location?.city || "-"
                    ) +
                      ", " +
                      this.capitalize(
                        historyData?.candidate?.location?.state || "-"
                      ) +
                      ", " +
                      this.capitalize(
                        historyData?.candidate?.location?.country || "-"
                      )}
                  </span>
                </Typography>
                <Grid
                  container
                  className={classNames(
                    classes.mediumTopMargin,
                    classes.textAlignCenter
                  )}
                >
                  <Grid item xs={4} sm={4} md={3} lg={6} xl={6}>
                    <Typography color="textSecondary">Client</Typography>
                  </Grid>
                  <Grid item xs={8} sm={8} md={9} lg={6} xl={6}>
                    <Typography className={classes.colorDefault}>
                      <Typography className={classes.colorDefault}>
                        {this.capitalize(historyData?.candidate?.client)}
                      </Typography>
                      <Typography className={classes.colorDefault}>
                        {this.capitalize(historyData?.candidate?.client_title) +
                          " at " +
                          this.capitalize(historyData?.candidate?.company_name)}
                      </Typography>
                    </Typography>
                  </Grid>
                </Grid>
                <Divider className={classes.mediumTopMargin}></Divider>
                {historyData?.candidate?.status?.length > 0 ? (
                  <div>
                    <Grid container className={classes.mediumTopMargin}>
                      <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
                        <Typography
                          align="center"
                          className={classNames(
                            classes.colorDefault,
                            classes.boldFont
                          )}
                        >
                          Stage
                        </Typography>
                      </Grid>
                      <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
                        <Typography
                          align="center"
                          className={classNames(
                            classes.colorDefault,
                            classes.boldFont
                          )}
                        >
                          Date
                        </Typography>
                      </Grid>
                    </Grid>
                    <Paper
                      className={classNames(
                        classes.customHighlightsPaper,
                        classes.mediumTopMargin
                      )}
                    >
                      {historyData?.candidate?.status.map((item, index) => (
                        <div>
                          <Grid
                            container
                            style={{ marginTop: "5%", marginBottom: "3%" }}
                          >
                            <Grid item xs={8} sm={8} md={8} lg={8} xl={8}>
                              <Typography
                                className={classes.smallBottomMargin}
                                color="textSecondary"
                              >
                                {item.label}:{" "}
                              </Typography>
                            </Grid>
                            <Grid item xs={4} sm={4} md={4} lg={4} xl={4}>
                              <Typography
                                className={classNames(
                                  classes.smallBottomMargin,
                                  classes.colorDefault
                                )}
                              >
                                {moment(item.completed_on).format(
                                  "MMM DD, YYYY"
                                )}
                              </Typography>
                            </Grid>
                          </Grid>
                          {/* <Grid container style={{ marginBottom: '2%' }}>
                            <Grid item xs={8} sm={8} md={8} lg={8} xl={8}>
                              <Typography
                                className={classes.smallBottomMargin}
                                color='textSecondary'
                              >
                                Comment:{' '}
                              </Typography>
                            </Grid>
                            <Grid item xs={4} sm={4} md={4} lg={4} xl={4}>
                              <Typography
                                className={classNames(
                                  classes.smallBottomMargin,
                                  classes.colorDefault
                                )}
                              >
                                {item.comment || '-'}{' '}
                              </Typography>
                            </Grid>
                          </Grid> */}
                          {index !==
                          historyData?.candidate?.status?.length - 1 ? (
                            <Divider></Divider>
                          ) : null}
                        </div>
                      ))}
                    </Paper>
                  </div>
                ) : (
                  <Typography
                    align="center"
                    className={classes.mediumTopMargin}
                    color="textSecondary"
                  >
                    No history available
                  </Typography>
                )}
              </DialogContentText>
            </DialogContent>
          </Dialog>
          <GetHelp />
        </main>
      </div>
    );
  }
}

Members.propTypes = {
  classes: PropTypes.object.isRequired,
};

function mapStateToProps(state) {
  return state;
}

const connectedMembersPage = withRouter(
  connect(mapStateToProps, null, null, {
    pure: false,
  })(withStyles(styles)(Members))
);

export { connectedMembersPage as Members };
