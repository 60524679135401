const initialState = { addedSeats: {} };

export function myAccount(state = initialState, action) {
  switch (action.type) {
    case "BILLING_DATA":
      return {
        ...state,
        billingData: action.billingData,
      };
    case "ON_STRIPE_CUSTOMER_PORTAL": {
      return {
        ...state,
        customerPortalData: action.customerPortalData,
      };
    }
    case "ADDED_SEATS":
      return {
        ...state,
        addedSeats: action.addedSeats,
      };
    default:
      return state;
  }
}
