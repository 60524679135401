import React from "react";
import { Grid, Typography, Button } from "@material-ui/core";
import FilterListIcon from "@material-ui/icons/FilterList";
import { makeStyles } from "@material-ui/core/styles";
import { List } from "@material-ui/icons";
import AppsIcon from "@material-ui/icons/Apps";
import { useSelector } from "react-redux";
import OpenSelect from "../core/selectState";
import HeaderTabs from "./headerTabs";

const useStyles = makeStyles(() => ({
  mainGrid: {
    display: "flex",
    alignItems: "center",
    justifyContent: "start",
    paddingTop: "10px",
  },
  typoFilters: {
    display: "flex",
    alignItems: "center",
  },
  mainGridView: {
    display: "flex",
    alignItems: "center",
    justifyContent: "end",
  },
  viewTypo: {
    display: "flex",
    alignItems: "center",
    marginRight: "4px",
  },
  selectedColor: {
    color: "#8F8F8F",
  },
  notSelectedColor: {
    color: "#000000",
  },
}));

function AllUpdates({ onTabChanges, toggleView }) {
  const headerCardValue = useSelector(
    (state) => state.jobs.allUpdateHeaderCard
  );
  const jobsStates = useSelector((state) => state.jobs);
  const classes = useStyles();
  return (
    <>
      <Grid container>
        <Grid
          item
          xs={12}
          sm={12}
          md={6}
          lg={6}
          xl={6}
          className={classes.mainGrid}
        >
          <Typography className={classes.typoFilters}>
            <FilterListIcon fontSize="small" />
            &nbsp;
            <Typography component="span" variant="body2" color="textSecondary">
              Filters
            </Typography>
          </Typography>
          <OpenSelect onTabChanges={onTabChanges} />
        </Grid>
        <Grid
          item
          xs={12}
          sm={12}
          md={6}
          lg={6}
          xl={6}
          className={classes.mainGridView}
        >
          <Typography className={classes.viewTypo}>View</Typography>
          <Button
            onClick={() => toggleView(false)}
            className={
              jobsStates.view === "List" || jobsStates.view === undefined
                ? classes.notSelectedColor
                : classes.selectedColor
            }
            startIcon={<List />}
          >
            List
          </Button>
          <Button
            disabled={headerCardValue === "4"}
            className={
              jobsStates.view === "Compare"
                ? classes.notSelectedColor
                : classes.selectedColor
            }
            startIcon={<AppsIcon />}
            onClick={() => toggleView(true)}
          >
            Compare
          </Button>
        </Grid>
      </Grid>
      <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
        <HeaderTabs />
      </Grid>
    </>
  );
}

export default AllUpdates;
