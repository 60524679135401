import React, { useEffect } from "react";
import { Grid, Divider, Typography, Link } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import DownloadIcon from "@material-ui/icons/GetApp";
import EmptyState from "../core/emptyState";

const useStyles = makeStyles((theme) => ({
  overviewsMainDiv: {
    marginTop: "20px",
    padding: "20px",
  },
  headingGrid: {
    textAlign: "left",
    fontWeight: "bolder",
  },
  resumeTopDivider: {
    marginTop: "15px",
    marginBottom: "25px",
  },
  resumeHeader: {
    background: "#59575A",
    padding: "10px 0px 10px 0px",
  },
  resumeTitleGrid: {
    textAlign: "left",
    alignSelf: "center",
  },
  customLink: {
    color: "#ffffff",
    cursor: "pointer",
    textDecoration: "none",
    width: "90% !important",
    paddingLeft: "15px",
  },
  customLink2: {
    color: "#ffffff",
    cursor: "pointer",
    textDecoration: "none !important",
    padding: "10px 15px 10px 15px",
    border: "1px solid #ffffff",
    display: "flex",
    borderRadius: "5PX",
  },
  downloadBotton: {
    display: "flex",
    justifyContent: "end",
    paddingRight: "15px",
    [theme.breakpoints.down("md")]: {
      justifyContent: "left",
      paddingLeft: "15px",
      marginTop: "10px",
    },
  },
  marginRight10: {
    marginRight: "10px",
  },
  mainGrid: {
    marginTop: "20px",
    padding: "20px",
  },
}));

function Resume(props) {
  const { firstName, lastName, resume } = props;
  const EMPTY_ICON_HEIGHT = 120;
  const EMPTY_ICON_WIDTH = 120;
  const classes = useStyles();

  const [ResumeType, setResumeType] = React.useState(null);

  useEffect(() => {
    const ext = resume?.split(".")?.pop();
    if (ext === "pdf") {
      setResumeType(ext);
    } else {
      setResumeType(ext);
    }
  }, [resume]);

  return (
    <Grid container>
      <Grid
        item
        xs={12}
        sm={12}
        md={12}
        lg={12}
        xl={12}
        className={classes.mainGrid}
      >
        <Typography variant="h6" className={classes.headingGrid}>
          Resume/CV
        </Typography>
        <Divider className={classes.resumeTopDivider} />
        {resume ? (
          <Grid container className={classes.resumeHeader}>
            <Grid
              item
              xs={12}
              sm={12}
              md={8}
              lg={8}
              xl={8}
              className={classes.resumeTitleGrid}
            >
              <Typography className={classes.customLink}>
                {`${firstName}_${lastName}.${resume.split(".").pop()}`}
              </Typography>
            </Grid>
            <Grid
              item
              xs={12}
              sm={12}
              md={4}
              lg={4}
              xl={4}
              className={classes.downloadBotton}
            >
              <Link
                href={resume}
                target="_blank"
                rel="noreferrer"
                className={classes.customLink2}
              >
                <DownloadIcon
                  fontSize="small"
                  className={classes.marginRight10}
                />{" "}
                Download
              </Link>
            </Grid>
          </Grid>
        ) : (
          <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
            <EmptyState
              type="resume"
              height={EMPTY_ICON_HEIGHT}
              width={EMPTY_ICON_WIDTH}
            />
          </Grid>
        )}
        {resume && (
          <div>
            {ResumeType === "pdf" ? (
              <iframe
                src={`${resume}#toolbar=0`}
                width="100%"
                height="800px"
                frameBorder="0"
                title="Resume Pdf"
              >
                This is an embedded{" "}
                <a target="_blank" rel="noreferrer" href="http://office.com">
                  Microsoft Office
                </a>{" "}
                document, powered by{" "}
                <a
                  target="_blank"
                  rel="noreferrer"
                  href="http://office.com/webapps"
                >
                  Office Online
                </a>
                .
              </iframe>
            ) : (
              <iframe
                title="Resume Doc,Docx"
                src={`https://view.officeapps.live.com/op/embed.aspx?src=${resume}#toolbar=0`}
                width="100%"
                height="800px"
                frameBorder="0"
              >
                This is an embedded{" "}
                <a target="_blank" rel="noreferrer" href="http://office.com">
                  Microsoft Office
                </a>{" "}
                document, powered by{" "}
                <a
                  target="_blank"
                  href="http://office.com/webapps"
                  rel="noreferrer"
                >
                  Office Online
                </a>
                .
              </iframe>
            )}
          </div>
        )}
      </Grid>
    </Grid>
  );
}

export default Resume;
