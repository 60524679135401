import {
  Grid,
  FormHelperText,
  Select,
  FormControlLabel,
  CircularProgress,
  Chip,
  Slider,
  styled,
  Checkbox,
  Typography,
  IconButton,
  Button,
  TextField,
  Paper,
  FormControl,
  InputLabel,
  MenuItem,
  FormLabel,
  RadioGroup,
  Radio,
  Box,
  Popover,
  Drawer,
} from "@material-ui/core";
import React, { useState, useEffect } from "react";
import PopupState, { bindTrigger, bindPopover } from "material-ui-popup-state";
import CancelIcon from "@material-ui/icons/Cancel";
import AddIcon from "@material-ui/icons/Add";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { useSelector, useDispatch } from "react-redux";
import { EditorState, convertToRaw } from "draft-js";
import draftToHtml from "draftjs-to-html";
import { makeStyles } from "@material-ui/core/styles";
import classNames from "classnames";
import HelpIcon from "@material-ui/icons//Help";
import debounce from "lodash/debounce";
import { toast } from "react-toastify";
import validator from "validator";
import KeyboardArrowLeftIcon from "@material-ui/icons/KeyboardArrowLeft";
import { jobsActions, setLocation, setTitle } from "../../../actions";
import { GoogleMaps } from "../../core/googleautocomplete";
import { trim, seo, history } from "../../../helpers";
import JobPreview from "./jobPreview";
import HelpGuide from "../../core/helpGuide";
import { MESSAGE_FIELD_IS_REQUIRED } from "../../../constants/constants";
import CustomEditer from "../../core/editerinput";
import CustomAddPopUp from "../../core/customAddInput.popup";
import secureLocalStorage from "react-secure-storage";

const useStyles = makeStyles((theme) => ({
  subHeader: {
    fontSize: "large",
    marginTop: "4%",
    fontWeight: "600",
    marginBottom: "1%",
    color: "#000000",
    textAlign: "left",
  },
  customTopMargin: {
    marginTop: "15px !important",
  },
  appFrame: {
    zIndex: 1,
    overflow: "hidden",
    position: "relative",
    paddingTop: "5px",
    paddingLeft: "48px",
    // padding: theme.spacing(0, 2),
    [theme.breakpoints.between("xs", "md")]: {
      padding: theme.spacing(2, 1),
    },
  },
  closeButtons: {
    color: "#B7B6B8",
  },
  ButtonPreviewandJob: {
    width: "89px",
    marginLeft: "10px",
    // [theme.breakpoints.down('md')]: {
    //   marginTop: "10px",
    //   marginLeft: "0px",
    // },
  },
  gridInputMain: {},
  helpGuideGrid: {
    paddingTop: "30px",
    paddingRight: "48px",
    [theme.breakpoints.between("xs", "md")]: {
      paddingTop: "30px",
      paddingRight: "48px",
      paddingLeft: "48px",
    },
    visibility: "hidden",
  },
  fontBolder: { fontWeight: "bolder" },
  checkboxGird: {
    height: "auto",
    overflow: "scroll",
  },
  subHeaderGrid: {
    zIndex: 91,
    display: "flex",
    position: "fixed",
    width: "100%",
    overflow: "hidden",
    backgroundColor: "#ffffff",
    borderBottom: "2px solid #F4F4F5",
    paddingBottom: "10px !important",
  },
  BasicJobFields: {
    fontWeight: "bolder",
    marginTop: "30px",
    paddingLeft: "px",
  },
  mainInputGrid: {
    marginTop: "10px",
    marginBottom: "13px",
  },
  // mediumPaddingLeft: {
  //   paddingLeft: "20px"
  // },
  flexGrid: {
    display: "flex",
  },
  alignItemsCenterGrid: {
    alignItems: "center",
  },
  fullWidth: { width: "100%" },
  alignItemsCenterLeft: {
    alignItems: "left",
  },
  selectedOptionsDiv: {
    zIndex: 91,
    border: "1px solid #B7B6B8",
    position: "fixed",
    borderRadius: "0px 10px 10px 0px",
    alignItems: "start",
    backgroundColor: "#FFFFFF",
    height: "100%",
    overflow: "auto",
  },
  selectOptionHeader: {
    paddingLeft: "5px",
    borderBottom: "2px solid #F4F4F5",
  },
  borderBottomCustom: { borderBottom: "2px solid #F4F4F5" },
  editorGrid: { display: "flex", alignItems: "baseline", width: "110%" },
  customLableGrid: {
    display: "flex",
    textAlign: "left",
    marginTop: "15px !important",
  },
  customLabelGrid: {
    marginTop: "13px",
    marginBottom: "12px",
    display: "flex",
    alignItems: "center",
  },
  addJobTypo: {
    fontSize: "26px",
    paddingLeft: "48px",
    paddingTop: "5px",
  },
  addJobSubhead: {
    paddingLeft: "48px",
    paddingTop: "5px",
    color: "#6D7781",
  },
  alignSelftCenter: {
    alignSelf: "center",
    paddingRight: "48px",
  },
  industryLabel: {
    paddingLeft: "20px",
    display: "flex",
    alignItems: "center",
  },
  customFormControl: {
    width: "100%",
    textAlign: "left",
  },
  paddingNone: { padding: 0 },
  customGridValues: {
    display: "flex",
    textAlign: "left",
    marginTop: "15px",
    alignItems: "center",
  },
  addJobTopDiv: {
    width: "80%",
    position: "relative",
  },
  baseSalaryFormControl: {
    display: "grid",
    textAlign: "left",
    marginTop: "10px",
  },
  helpIcon: {
    marginTop: "5px",
    cursor: "pointer",
  },
  smallPaddingPopover: { padding: "8px" },
  mediumFontWeight: { fontWeight: 500 },
  textAlignLeftCustom: { textAlign: "left" },
  CorporateLabel: { textAlign: "left", fontWeight: 400 },
  sampleCorporationGrid: {
    marginTop: "5px",
    width: "99%",
  },
  chip: {
    // margin: theme.spacing(0.5),
    height: "100%",
    wordBreak: "break-word",
    padding: "5px",
    margin: "5px 4px 8px 0px",
  },
  advanceFieldsTypo: { fontWeight: "bolder", marginTop: "10px" },
  selectAdvanceFieldsTypo: {
    color: "#6D7781",
    marginTop: "10px",
    marginBottom: "10px",
  },
  checkboxTopDiv: {
    display: "flex",
    flexDirection: "column",
    paddingLeft: "5px",
    marginTop: "10px",
    height: "auto",
  },
  smallPadding: { padding: "8px" },
  addAdvancedButton: { background: "#000000", color: "#ffffff" },
  selectedOptMainGrid: {
    marginTop: "30px",
    display: "flex",
    justifyContent: "left",
    marginBottom: "30px",
  },
  paperSelectedOpt: {
    boxShadow: "none",
    left: "20px",
    border: "1px dashed #B7B6B8",
    borderRadius: "10px",
    width: "600px",
    display: "flex",
    flexDirection: "row",
    padding: "25px",
    justifyContent: "space-between",
  },
  spanSelectedOpt: { display: "flex", flexDirection: "column" },
  fontBold: { fontWeight: "bold" },
  smallTopmargin: { marginTop: "13px" },
  headerMarginTop: {
    marginTop: "60px",
  },
  noMarginTop: {
    marginTop: "0px",
    [theme.breakpoints.between("xs", "sm")]: {
      marginTop: "90px !important",
    },
  },
  alignItemsStartGrid: {
    alignItems: "start",
    paddingTop: "40px",
  },
  fullComponentPadding: {
    paddingLeft: "48px",
  },
  customMarginBotton: {
    marginBottom: "15px",
  },
  addAdvancedOptionFixHead: {
    position: "sticky",
    top: 0,
    zIndex: 1,
    background: "#FFFFFF",
  },
}));

// Globle Variable
const reportsToCount = 100;
let data = [];
let marks = [];
let minValue;
let maxValue;
const salaryValueFormat = Intl.NumberFormat("en-US");
const startupStage = [];
const startupSize = [];
const teamSize = [];
let jobTitleOptions = [];
const customFieldCount = 1000;
let payload = {};
const iOSBoxShadow =
  "0 3px 1px rgba(0,0,0,0.1),0 4px 8px rgba(0,0,0,0.13),0 0 0 1px rgba(0,0,0,0.02)";

const SalarySlider = styled(Slider)(({ theme }) => ({
  marginTop: "15px",
  color: "#FBC112",
  width: "100%",
  [theme.breakpoints.between("sm", "md")]: {
    width: "85%",
  },
  [theme.breakpoints.between("xs", "sm")]: {
    width: "70%",
  },
  padding: "10px 0",
  "& .MuiSlider-thumb": {
    height: 22,
    width: 22,
    backgroundColor: "#048475",
    boxShadow: iOSBoxShadow,
    "&:focus, &:hover, &.Mui-active": {
      boxShadow:
        "0 3px 1px rgba(0,0,0,0.1),0 4px 8px rgba(0,0,0,0.3),0 0 0 1px rgba(0,0,0,0.02)",
      "@media (hover: none)": {
        boxShadow: iOSBoxShadow,
      },
    },
  },
  "& .MuiSlider-valueLabel": {
    fontSize: 12,
    fontWeight: "normal",
    top: -20,
    backgroundColor: "unset",
    color: theme.palette.text.primary,
    "&:before": {
      display: "none",
    },
    "& *": {
      background: "transparent",
      color: theme.palette.mode === "dark" ? "#fff" : "#000",
    },
  },
  "& .MuiSlider-IOSSlider": {
    top: -10,
  },
  "& .MuiSlider-track": {
    height: 10,
    border: "none",
    borderRadius: "10px",
  },
  "& .MuiSlider-rail": {
    height: 10,
    opacity: 0.5,
    backgroundColor: "#bfbfbf",
    borderRadius: "10px",
  },
  "& .MuiSlider-mark": {
    backgroundColor: "#bfbfbf",
    height: 15,
    width: 2,
    "& .MuiSlider-markActive": {
      opacity: 1,
      backgroundColor: "currentColor",
    },
  },
  "& .MuiSlider-markLabel": {
    [theme.breakpoints.down("md")]: {
      transform: "rotateZ(45deg) !important",
    },
    [theme.breakpoints.down("sm")]: {
      transform: "rotateZ(45deg) !important",
    },
  },
}));

let offsetHeight;
let IndustryOptions = [];
function JobPost() {
  const dispatch = useDispatch();
  let reportsToTitleOptions = [];
  let companyNamesOptions = [];
  let majorOptions = [];
  let certificationsOptions = [];
  const classes = useStyles();
  const [selectOptions, setSelectOptions] = useState(false);
  const [openReportsToOptions, setOpenReportsToOptions] = useState(false);
  const [openPreview, setOpenPreview] = useState(false);
  const [customMajorPopUp, setCustomMajorPopUp] = useState({
    customMajorPopUp: false,
    customMajorValue: "",
  });

  // const [otherAlternativeIndustry, setOtherAlternativeIndustry] = useState({
  //   otherAlternativeIndustryOpen: false,
  //   otherAlternativeIndustryValue: "",
  //   otherAlternativeIndustryRequired: false
  // });

  const [customCertificationTitlePopUp, setCustomCertificationTitlePopUp] =
    useState({
      customCertificationTitlePopUp: false,
      customCertificationTitle: "",
    });

  jobTitleOptions = useSelector((state) => state?.jobs?.jobTitles);
  reportsToTitleOptions = useSelector((state) => state?.jobs?.reportsToTitles);
  certificationsOptions = useSelector((state) => state?.jobs?.certifications);
  companyNamesOptions = useSelector((state) => state?.jobs?.companyNames);
  const location = useSelector((state) => state?.jobs?.locationDetails);
  majorOptions = useSelector((state) => state?.jobs?.majors);
  const isNavbarVisible = useSelector(
    (state) => state?.shared?.isNavbarVisible
  );
  const constants = useSelector((state) => state?.authentication?.constants);
  const pageTitle = useSelector((state) => state?.pageTitle.pageTitle);
  const [companyNameValueInput, setCompanyNameValueInput] = useState("");
  const [inputDisplay, setInputDisplay] = useState({
    alternativeTitle: false,
    alternativeIndustry: false,
    functionDisplay: false,
    jobLevelDisplay: false,
    reportsToDisplay: false,
    industryDisplay: false,
    hiringManagerNameDisplay: false,
    companyHQLocationDisplay: false,
    minimumYearsofExperienceDisplay: false,
    baseSalaryDisplay: false,
    DegreeDisplay: false,
    degreeRequiredorPreferredDisplay: false,
    majorDisplay: false,
    professionalCertificationsDisplay: false,
    remoteorOnsiteDisplay: false,
    travelRequirementsDisplay: false,
    howSoonDisplay: false,
    corporateStartupstageDisplay: false,
    corporateStartupsizeDisplay: false,
    teamSizeDisplay: false,
    roleDisplay: false,
    aboutUsDisplay: false,
    additionalResponsibilitiesDisplay: false,
    absoluteMUSTHAVEDisplay: false,
    whatsuccessfulCandidatewillBringDisplay: false,
    sampleCorporationDisplay: false,
    CompetenciesDisplay: false,
    confiNormalRoleDisplay: false,
  });

  const [selectAll, setSelectAll] = useState(false);
  const [loading, setLoading] = useState({
    reportsToTitlesLoading: false,
    companyLoading: false,
    majorLoading: false,
    certificationsLoading: false,
    jobTitleLoading: false,
  });

  const [values, setValues] = useState({
    title: "",
    aboutRole: EditorState.createEmpty(),
    functionName: "",
    otherFunctionName: "",
    jobLevel: "",
    reportsToTitle: "",
    hiringManagerName: "",
    industry: "",
    locationHQ: null,
    minimumExperience: "",
    degree: "",
    teamSize: [],
    startupStage: [],
    startupSize: [],
    sampleCompanyValues: null,
    aboutUs: EditorState.createEmpty(),
    additionalResponsibilities: EditorState.createEmpty(),
    absoluteMUSTHAVE: EditorState.createEmpty(),
    whatsuccessfulCandidatewillBring: EditorState.createEmpty(),
    majorValue: "",
    certifications: [],
    certificationsValue: "",
    degreeRequiredOrPreferred: "",
    remote: "",
    travelLevel: "",
    howSoonToHire: "",
    role: "",
    skills: [],
    additionResponsibilitesWithHTML: "",
    absolueMustHavehHTML: "",
    willSuccessfullCandidateBringHTML: "",
    aboutUsWithHTML: "",
    aboutRoleHTML: "",
    confiNormalRoleValue: "Normal",
    alternativeTilte: [],
    alternativeIndustry: [],
  });

  const [openOptions, setOption] = useState(false);
  const [salaryMax, setSalaryMax] = useState({
    salaryType: "",
    salaryConfig: "",
    minimumBaseSalary: null,
  });

  const [customJobTitlePopUp, setCustomJobTitlePopUp] = useState({
    customJobTitlePopUp: false,
    customJobTitle: "",
  });

  const [validate, setValidate] = useState({
    jobTitleRequired: false,
    jobLevelRequired: false,
    ResponsibilitiesRequired: false,
    charactersValidResponsibilities: false,
    aboutCompanyRequired: false,
    aboutCompanyCharactersValid: false,
    qualificationsRequired: false,
    qualificationsCharactersValid: false,
    workloactionRequired: false,
    functionValidateRequired: false,
    isEmptyAboutCompany: false,
    isAboutRoleRequired: false,
    isEmptyAboutRole: false,
    aboutRoleCharactersValid: false,
    isRequiredNonNegotiables: false,
    isValidCharNonNNegotiables: false,
  });

  const [showPopUp, setShowPopUp] = useState(false);
  const [postjob, setPostJob] = useState(false);

  const [customIndustryValue, setCustomIndustryValue] = useState(null);
  const [customIndustryRequired, setCustomIndustryRequired] = useState(false);
  const [openCustomIndusrtyFiled, setOpenCustomIndusrtyTextfiled] =
    useState(false);

  const checkboxes = [
    { label: "Job Title", checked: true, disabled: true },
    { label: "Function", checked: true, disabled: true },
    { label: "Job Level", checked: true, disabled: true },
    { label: "Work Location", checked: true, disabled: true },
    { label: "About Us", checked: true, disabled: true },
    { label: "About Role", checked: true, disabled: true },
    {
      label: "Must Have Requirements/Non-Negotiables",
      checked: true,
      disabled: true,
    },
    { label: "Reports To", checked: inputDisplay.reportsToDisplay },
    {
      label: "Hiring Manager Name",
      checked: inputDisplay.hiringManagerNameDisplay,
    },
    { label: "Alternative Job Title", checked: inputDisplay.alternativeTitle },
    { label: "Industry", checked: inputDisplay.industryDisplay },
    {
      label: "Alternative Industry",
      checked: inputDisplay.alternativeIndustry,
    },
    {
      label: "Company HQ Location",
      checked: inputDisplay.companyHQLocationDisplay,
    },
    {
      label: "Minimum Years of Experience",
      checked: inputDisplay.minimumYearsofExperienceDisplay,
    },
    { label: "Base Salary", checked: inputDisplay.baseSalaryDisplay },
    { label: "Degree", checked: inputDisplay.DegreeDisplay },
    { label: "Major", checked: inputDisplay.majorDisplay },
    {
      label: "Degree Required or Preferred?",
      checked: inputDisplay.degreeRequiredorPreferredDisplay,
    },
    {
      label: "Professional Certifications",
      checked: inputDisplay.professionalCertificationsDisplay,
    },
    {
      label: "Travel Requirements",
      checked: inputDisplay.travelRequirementsDisplay,
    },
    {
      label: "How soon do you want to fill this role?",
      checked: inputDisplay.howSoonDisplay,
    },
    {
      label: "Confidential/Normal Role",
      checked: inputDisplay.confiNormalRoleDisplay,
    },
    {
      label: "Company Size",
      checked: inputDisplay.corporateStartupsizeDisplay,
    },
    {
      label: "Company Type",
      checked: inputDisplay.corporateStartupstageDisplay,
    },
    { label: "Team size", checked: inputDisplay.teamSizeDisplay },
    {
      label: "Sample Corporation",
      checked: inputDisplay.sampleCorporationDisplay,
    },
    { label: "New Role/Existing Role", checked: inputDisplay.roleDisplay },
    {
      label: "Responsibilities",
      checked: inputDisplay.additionalResponsibilitiesDisplay,
    },
    {
      label: "Qualifications",
      checked: inputDisplay.whatsuccessfulCandidatewillBringDisplay,
    },

    { label: "Competencies", checked: inputDisplay.CompetenciesDisplay },
  ];

  const onChangeSelectInputs = (value, event) => {
    const inputValue = event.target.checked;
    switch (value) {
      case "Alternative Job Title":
        setInputDisplay({ ...inputDisplay, alternativeTitle: inputValue });
        setValues({ ...values, alternativeTilte: [] });
        break;
      case "Alternative Industry":
        setInputDisplay({ ...inputDisplay, alternativeIndustry: inputValue });
        setValues({ ...values, alternativeIndustry: [] });
        // setOtherAlternativeIndustry({ otherAlternativeIndustryOpen: false, otherAlternativeIndustryRequired: false, otherAlternativeIndustryValue: '' });
        break;
      case "Function":
        setInputDisplay({ ...inputDisplay, functionDisplay: inputValue });
        setValues({ ...values, functionName: "" });
        break;
      case "Job Level":
        setInputDisplay({ ...inputDisplay, jobLevelDisplay: inputValue });
        setValues({ ...values, jobLevel: "" });
        break;
      case "Reports To":
        setInputDisplay({ ...inputDisplay, reportsToDisplay: inputValue });
        setValues({ ...values, reportsToTitle: "" });
        break;
      case "Hiring Manager Name":
        setInputDisplay({
          ...inputDisplay,
          hiringManagerNameDisplay: inputValue,
        });
        setValues({ ...values, hiringManagerName: "" });
        break;
      case "Industry":
        setInputDisplay({ ...inputDisplay, industryDisplay: inputValue });
        setValues({ ...values, industry: "" });
        setOpenCustomIndusrtyTextfiled(values.industry !== "");
        setCustomIndustryRequired(values.industry !== "");
        break;
      case "Company HQ Location":
        setInputDisplay({
          ...inputDisplay,
          companyHQLocationDisplay: inputValue,
        });
        dispatch(setLocation(""));
        secureLocalStorage.removeItem("locationDetails");
        break;
      case "Minimum Years of Experience":
        setInputDisplay({
          ...inputDisplay,
          minimumYearsofExperienceDisplay: inputValue,
        });
        setValues({ ...values, minimumExperience: "" });
        break;
      case "Base Salary":
        setInputDisplay({ ...inputDisplay, baseSalaryDisplay: inputValue });
        setSalaryMax({
          salaryType: "",
          salaryConfig: "",
          minimumBaseSalary: null,
        });
        break;
      case "Degree":
        setInputDisplay({ ...inputDisplay, DegreeDisplay: inputValue });
        setValues({ ...values, degree: "" });
        break;
      case "Degree Required or Preferred?":
        setInputDisplay({
          ...inputDisplay,
          degreeRequiredorPreferredDisplay: inputValue,
        });
        setValues({ ...values, degreeRequiredOrPreferred: "" });
        break;
      case "Professional Certifications":
        setInputDisplay({
          ...inputDisplay,
          professionalCertificationsDisplay: inputValue,
        });
        setValues({ ...values, certificationsValue: "", certifications: [] });
        break;
      case "Major":
        setInputDisplay({ ...inputDisplay, majorDisplay: inputValue });
        setValues({ ...values, majorValue: "" });
        break;
      case "Work Location":
        setInputDisplay({ ...inputDisplay, remoteorOnsiteDisplay: inputValue });
        setValues({ ...values, remote: "" });
        break;
      case "Travel Requirements":
        setInputDisplay({
          ...inputDisplay,
          travelRequirementsDisplay: inputValue,
        });
        setValues({ ...values, travelLevel: "" });
        break;
      case "How soon do you want to fill this role?":
        setInputDisplay({ ...inputDisplay, howSoonDisplay: inputValue });
        setValues({ ...values, howSoonToHire: "" });
        break;
      case "Confidential/Normal Role":
        setInputDisplay({
          ...inputDisplay,
          confiNormalRoleDisplay: inputValue,
        });
        setValues({ ...values, confiNormalRoleValue: "Normal" });
        break;
      case "Company Size":
        setInputDisplay({
          ...inputDisplay,
          corporateStartupsizeDisplay: inputValue,
        });
        setValues({ ...values, startupSize: [] });
        break;
      case "Company Type":
        setInputDisplay({
          ...inputDisplay,
          corporateStartupstageDisplay: inputValue,
        });
        setValues({ ...values, startupStage: [] });
        break;
      case "Team size":
        setInputDisplay({ ...inputDisplay, teamSizeDisplay: inputValue });
        setValues({ ...values, teamSize: [] });
        break;
      case "New Role/Existing Role":
        setInputDisplay({ ...inputDisplay, roleDisplay: inputValue });
        setValues({ ...values, role: "" });
        break;
      case "About the Company":
        setInputDisplay({ ...inputDisplay, aboutUsDisplay: inputValue });
        setValues({ ...values, aboutUs: EditorState.createEmpty() });
        break;
      case "Responsibilities":
        setInputDisplay({
          ...inputDisplay,
          additionalResponsibilitiesDisplay: inputValue,
        });
        setValues({
          ...values,
          additionalResponsibilities: EditorState.createEmpty(),
        });
        break;
      case "Qualifications":
        setInputDisplay({
          ...inputDisplay,
          whatsuccessfulCandidatewillBringDisplay: inputValue,
        });
        setValues({
          ...values,
          whatsuccessfulCandidatewillBring: EditorState.createEmpty(),
        });
        break;
      case "Must Have Requirements/Non-Negotiables":
        setInputDisplay({
          ...inputDisplay,
          absoluteMUSTHAVEDisplay: inputValue,
        });
        setValues({ ...values, absoluteMUSTHAVE: EditorState.createEmpty() });
        break;
      case "Sample Corporation":
        setInputDisplay({
          ...inputDisplay,
          sampleCorporationDisplay: inputValue,
        });
        setValues({ ...values, sampleCompanyValues: null });
        break;
      case "Competencies":
        setInputDisplay({ ...inputDisplay, CompetenciesDisplay: inputValue });
        setValues({ ...values, skills: [] });
        break;
      default:
        break;
    }
  };

  const handleInputChange = (props) => (event) => {
    const inputValue = event.target.value;
    switch (props) {
      case "jobTitle":
        setLoading({ ...loading, jobTitleLoading: true });
        getJobTitles(inputValue);
        if (inputValue.length === 0) {
          jobTitleOptions = [];
        }
        break;
      case "jobTitleAlternative":
        getJobTitles(inputValue);
        if (inputValue.length === 0) {
          jobTitleOptions = [];
        }
        break;
      case "functionName":
        setValidate({ ...validate, functionValidateRequired: false });
        if (inputValue === "Other") {
          highlightOtherFunctionName();
        }
        setValues({ ...values, functionName: inputValue });
        break;
      case "otherFunctionName":
        setValues({ ...values, otherFunctionName: inputValue });
        break;
      case "jobLevel":
        setValidate({ ...validate, jobLevelRequired: false });
        setValues({ ...values, jobLevel: inputValue });
        break;
      case "reportsToTitle":
        setLoading({ ...loading, reportsToTitlesLoading: true });
        getReportsToTitles(inputValue);
        if (inputValue?.length === 0) {
          reportsToTitleOptions = [];
        }
        break;
      case "Hiring Manager Name":
        setValues({ ...values, hiringManagerName: inputValue });
        break;
      case "industry":
        setValues({ ...values, industry: inputValue });
        setOpenCustomIndusrtyTextfiled(inputValue === "Other");
        setCustomIndustryRequired(
          inputValue === "Other" ? customIndustryValue === null : false
        );
        setCustomIndustryValue(
          inputValue !== "Other" ? null : customIndustryValue
        );
        break;
      // case 'alternativeOtherIndustry':
      //   setOtherAlternativeIndustry({ ...otherAlternativeIndustry, otherAlternativeIndustryValue: inputValue, otherAlternativeIndustryRequired: inputValue?.trim().length === 0 ? true : false })
      //   // setCustomIndustryRequired(inputValue === 'Other' ? customIndustryValue === null ? true : false : false);
      //   // setCustomIndustryValue(inputValue !== 'Other' ? null : customIndustryValue);
      //   break;
      case "minimumExperience":
        setValues({ ...values, minimumExperience: inputValue });
        break;
      case "degree":
        setValues({ ...values, degree: inputValue });
        break;
      case "degreeRequiredOrPreferred?":
        setValues({ ...values, degreeRequiredOrPreferred: inputValue });
        break;
      case "remote":
        setValues({ ...values, remote: inputValue });
        setValidate({ ...validate, workloactionRequired: false });
        break;
      case "travelLevel":
        setValues({ ...values, travelLevel: inputValue });
        break;
      case "howSoonToHire":
        setValues({ ...values, howSoonToHire: inputValue });
        break;
      case "confidentialNormalRole":
        setValues({ ...values, confiNormalRoleValue: inputValue });
        break;
      case "major":
        getMajors(inputValue);
        setLoading({ ...loading, majorLoading: true });
        if (inputValue?.length === 0) {
          majorOptions = [];
        }
        break;
      case "certificationsValue":
        getCertifications(inputValue);
        setLoading({ ...loading, certificationsLoading: true });
        if (inputValue?.length === 0) {
          certificationsOptions = [];
        }
        break;
      case "role":
        setValues({ ...values, role: inputValue });
        break;
      case "Competencies":
        setValues({ ...values, skills: inputValue });
        break;
      case "customIndustryValue":
        setCustomIndustryRequired(inputValue === null);
        setCustomIndustryValue(inputValue);
        break;
      default:
        break;
    }
  };

  const highlightOtherFunctionName = () => {
    const otherFunctionNameTextField = document.getElementById(
      "otherJobFunctionName"
    );
    if (otherFunctionNameTextField) {
      otherFunctionNameTextField.classList.add("fade-in-field");
    }
  };

  const getJobTitles = (title) => {
    // setLoading({ ...loading, jobTitleLoading: true });
    dispatch(jobsActions.getJobTitles(title));
  };

  // for autocomplete loader false
  useEffect(() => {
    setLoading({
      ...loading,
      jobTitleLoading: false,
      majorLoading: false,
      certificationsLoading: false,
    });
  }, [jobTitleOptions, majorOptions, certificationsOptions]);

  const getCertifications = (certification) => {
    dispatch(jobsActions.getCertifications(certification));
  };

  const getMajors = (major) => {
    dispatch(jobsActions.getMajorList(major));
  };

  const getReportsToTitles = (title) => {
    dispatch(jobsActions.getReportsToTitles(title));
    setLoading({ ...loading, companyLoading: false });
  };

  const handleChangeAdditionalresponsibilities = () => {
    const inputValue = values.additionalResponsibilities
      .getCurrentContent()
      .getPlainText("\u0001");
    setValues({
      ...values,
      additionResponsibilitesWithHTML: draftToHtml(
        convertToRaw(values.additionalResponsibilities.getCurrentContent())
      ),
    });
    setValidate({
      ...validate,
      charactersValidResponsibilities: inputValue
        ? !validator.matches(inputValue, /^.{25,5000}$/s)
        : true,
      ResponsibilitiesRequired: validator.isEmpty(inputValue),
    });
  };
  const handleChangewhatsuccessfulCandidatewillBring = () => {
    const inputValue = values.whatsuccessfulCandidatewillBring
      .getCurrentContent()
      .getPlainText("\u0001");
    setValues({
      ...values,
      willSuccessfullCandidateBringHTML: draftToHtml(
        convertToRaw(
          values.whatsuccessfulCandidatewillBring.getCurrentContent()
        )
      ),
    });
    setValidate({
      ...validate,
      qualificationsCharactersValid: inputValue
        ? !validator.matches(inputValue, /^.{25,5000}$/s)
        : true,
      qualificationsRequired: validator.isEmpty(inputValue),
    });
  };
  const handleChangeMustHave = () => {
    const inputValue = values.absoluteMUSTHAVE
      .getCurrentContent()
      .getPlainText("\u0001");

    setValues({
      ...values,
      absolueMustHavehHTML: draftToHtml(
        convertToRaw(values.absoluteMUSTHAVE.getCurrentContent())
      ),
    });
    setValidate({
      ...validate,
      isValidCharNonNNegotiables: inputValue
        ? !validator.matches(inputValue.trimStart(), /^.{25,5000}$/s)
        : true,
      isRequiredNonNegotiables: validator.isEmpty(inputValue.trimStart()),
    });
  };

  const handleChangeAboutRole = () => {
    const inputValue = values.aboutRole
      .getCurrentContent()
      .getPlainText("\u0001");
    setValues({
      ...values,
      aboutRoleHTML: draftToHtml(
        convertToRaw(values.aboutRole.getCurrentContent())
      ),
    });
    setValidate({
      ...validate,
      isAboutRoleRequired: inputValue
        ? !validator.matches(inputValue, /^.{25,5000}$/s)
        : true,
      aboutRoleCharactersValid: validator.isEmpty(inputValue),
      isEmptyAboutRole: convertToRaw(
        values.aboutRole.getCurrentContent()
      ).blocks.every((b) => b.text.trimStart().length < 25),
    });
  };

  const handleChangeAboutUs = () => {
    const inputValue = values.aboutUs
      .getCurrentContent()
      .getPlainText("\u0001");
    setValues({
      ...values,
      aboutUsWithHTML: draftToHtml(
        convertToRaw(values.aboutUs.getCurrentContent())
      ),
    });
    setValidate({
      ...validate,
      aboutCompanyCharactersValid: inputValue
        ? !validator.matches(inputValue.trimStart(), /^.{25,5000}$/s)
        : true,
      aboutCompanyRequired: validator.isEmpty(inputValue.trimStart()),
      isEmptyAboutCompany: convertToRaw(
        values.aboutUs.getCurrentContent()
      ).blocks.every((b) => b.text.trimStart().length < 25),
    });
  };

  const onChangeEditorAboutUS = (value) => {
    setValues({ ...values, aboutUs: value });
  };

  const onChangeEditorAboutRole = (value) => {
    setValues({ ...values, aboutRole: value });
  };

  const onChangeEditorAdditionalresponsibilities = (value) => {
    setValues({ ...values, additionalResponsibilities: value });
    setValidate({
      ...validate,
      ResponsibilitiesRequired: validator.isEmpty(
        value.getCurrentContent().getPlainText("\u0001")
      ),
    });

    if (value.getCurrentContent().getPlainText("\u0001").length < 0) {
      setValidate({
        ...validate,
        ResponsibilitiesRequired: true,
      });
    }

    if (value.getCurrentContent().getPlainText("\u0001").length < 1000) {
      setValidate({
        ...validate,
        charactersValidResponsibilities: false,
      });
    }
  };

  const onChangeEditorwhatsuccessfulCandidatewillBring = (value) => {
    setValues({ ...values, whatsuccessfulCandidatewillBring: value });

    if (value.getCurrentContent().getPlainText("\u0001").length < 0) {
      setValidate({
        ...validate,
        qualificationsRequired: true,
      });
    }

    if (value.getCurrentContent().getPlainText("\u0001").length < 1000) {
      setValidate({
        ...validate,
        qualificationsCharactersValid: false,
      });
    }
  };

  const onChangeEditorMustHave = (value) => {
    setValues({ ...values, absoluteMUSTHAVE: value });
    if (value.getCurrentContent().getPlainText("\u0001").length < 0) {
      setValidate({
        ...validate,
        isRequiredNonNegotiables: true,
      });
    }

    if (value.getCurrentContent().getPlainText("\u0001").length < 1000) {
      setValidate({
        ...validate,
        isValidCharNonNNegotiables: false,
      });
    }
  };

  const removeFieldHighlight = () => {
    const functionNameTextField = document.getElementById("jobFunctionName");
    if (functionNameTextField) {
      functionNameTextField.classList.remove("fade-in-field");
    }
  };

  function Pop(props) {
    const { anchorEl, ...rest } = props;
    const bound = anchorEl.getBoundingClientRect();
    return (
      <div
        {...rest}
        style={{
          position: "absolute",
          zIndex: 99999,
          width: bound.width,
        }}
      />
    );
  }

  useEffect(() => {
    dispatch(setTitle(`${"Jobs"} ${"-"} ${"My Jobs"} ${"-"} ${"Add Job"}`));
    seo({
      title: `${pageTitle}`,
    });
  });

  useEffect(() => {
    selectAllCheckboxHandle();
  });

  useEffect(() => {
    if (constants?.minimum_base_salary) {
      data = [];
      constants?.minimum_base_salary?.map((ele) =>
        data.push({ value: ele.replace(/[^0-9.-]+/g, ""), label: ele })
      );
    }
    marks = data.map((ele) => ele);
    minValue = parseInt(marks[0].value);
    maxValue = parseInt(marks[marks?.length - 1].value);
  });

  const setJobTitle = (value) => {
    if (value !== null && value !== undefined) {
      setValues({ ...values, title: value._source?.Title });
      setValidate({
        ...validate,
        jobTitleRequired: false,
        functionValidateRequired: false,
      });

      if (!constants?.function?.includes(value._source.Function)) {
        setValues({
          ...values,
          functionName: "Other",
          otherFunctionName: value._source.Function,
        });
      } else {
        setValues({
          ...values,
          functionName: value._source.Function,
          otherFunctionName: "",
          title: value._source?.Title,
        });
      }
    } else {
      setValues({ ...values, title: "" });
      setValidate({ ...validate, jobTitleRequired: true });
    }
    highlightFunctionName();
  };

  const updateLocationRequireState = () => {
    console.log("updateLocationRequireState");
  };
  const highlightFunctionName = () => {
    const functionNameTextField = document.getElementById("jobFunctionName");
    if (functionNameTextField) {
      functionNameTextField.classList.add("fade-in-field");
    }
  };

  const handleCheckBox = (Type) => (event) => {
    switch (Type) {
      case "TeamSize":
        if (event.target.checked === true) {
          teamSize.push(event.target.value);
          setValues({ ...values, teamSize: [...teamSize] });
        } else {
          const index = teamSize.findIndex(
            (value) => value === event.target.value
          );
          teamSize.splice(index, 1);
          setValues({ ...values, teamSize: [...teamSize] });
        }
        break;
      case "StartupStage":
        if (event.target.checked === true) {
          startupStage.push(event.target.value);
          setValues({ ...values, startupStage: [...startupStage] });
        } else {
          const index = startupStage.findIndex(
            (value) => value === event.target.value
          );
          startupStage.splice(index, 1);
          setValues({ ...values, startupStage: [...startupStage] });
        }
        break;
      case "StartupSize":
        if (Type === "StartupSize" && event.target.checked === true) {
          startupSize.push(event.target.value);
          setValues({ ...values, startupSize: [...startupSize] });
        } else {
          const index = startupSize.findIndex(
            (value) => value === event.target.value
          );
          startupSize.splice(index, 1);
          setValues({ ...values, startupSize: [...startupSize] });
        }
        break;
      default:
        break;
    }
  };

  const handleExpCompany = debounce((event) => {
    setCompanyNameValueInput(event.target.value);
    if (companyNameValueInput?.length === 0) {
      companyNamesOptions = [];
      setLoading({ ...loading, companyLoading: false });
    } else if (event.target?.value?.length < 100) {
      getCompanyNames(event.target.value);
    } else {
      setLoading({ ...loading, companyLoading: false });
    }
  }, 500);

  const getCompanyNames = (title) => {
    dispatch(jobsActions.getCompanyNames(title));
    setLoading({ ...loading, companyLoading: false });
  };

  const setMajor = (value) => {
    if (value !== null && value !== undefined) {
      setLoading({ ...loading, companyLoading: false });
      setValues({ ...values, majorValue: value });
    } else {
      setLoading({ ...loading, companyLoading: false });
      setValues({ ...values, majorValue: "" });
    }
  };

  const setCertifications = (value) => {
    if (value !== null && value !== undefined && value?.length > 0) {
      setValues({
        ...values,
        certifications: value,
        certificationsValue: value[0]._source.Certificate,
      });
    } else {
      setValues({ ...values, certifications: [], certificationsValue: "" });
    }
  };

  // custom add title all function
  const customTilteClose = () => {
    setCustomJobTitlePopUp({ customJobTitlePopUp: false, customJobTitle: "" });
  };

  const customTitleInput = (event) => {
    setCustomJobTitlePopUp({
      ...customJobTitlePopUp,
      customJobTitle: event.target.value,
    });
  };

  const saveCustomJobTitle = () => {
    setValues({
      ...values,
      title: customJobTitlePopUp?.customJobTitle?.trim(),
      functionName: "Other",
    });
    setValidate({ ...validate, jobTitleRequired: false });
    setCustomJobTitlePopUp({ customJobTitlePopUp: false, customJobTitle: "" });
    const functionNameTextField = document.getElementById("jobFunctionName");
    if (functionNameTextField) {
      functionNameTextField.classList.add("fade-in-field");
    }
  };

  // custom add major all functions
  const customMajorClose = () => {
    setCustomMajorPopUp({ customMajorPopUp: false, customMajorValue: "" });
  };

  const customMajorInput = (event) => {
    setCustomMajorPopUp({
      ...customMajorPopUp,
      customMajorValue: event.target.value,
    });
  };

  const saveCustomMajor = () => {
    setValues({
      ...values,
      majorValue: {
        _source: { Major: trim(customMajorPopUp.customMajorValue) },
      },
    });
    setCustomMajorPopUp({ customMajorPopUp: false, customMajorValue: "" });
  };

  // custom certifications all functions
  const customCertificationClose = () => {
    setCustomCertificationTitlePopUp({
      customCertificationTitlePopUp: false,
      customCertificationTitle: "",
    });
  };

  const customCertificationInput = (event) => {
    setCustomCertificationTitlePopUp({
      ...customCertificationTitlePopUp,
      customCertificationTitle: event.target.value,
    });
  };

  const saveCustomCertificationTitle = (event) => {
    event.preventDefault();
    event.stopPropagation();
    const found = values.certifications.some(
      (el) =>
        el._source.Certificate.toLowerCase() ===
        customCertificationTitlePopUp.customCertificationTitle.toLowerCase()
    );
    if (!found) {
      const certificateList = [...values.certifications];
      certificateList.push({
        _source: {
          Certificate: trim(
            customCertificationTitlePopUp.customCertificationTitle
          ),
        },
      });
      setValues({
        ...values,
        certifications: certificateList,
      });
      setCustomCertificationTitlePopUp({
        customCertificationTitle: "",
        customCertificationTitlePopUp: false,
      });
    } else {
      setCustomCertificationTitlePopUp({
        ...customCertificationTitlePopUp,
        customCertificationTitle: "",
      });
      toast.dark("Certification already added");
    }
  };

  const handleCloseInput = (val) => {
    switch (val) {
      case "Alternative Job Title":
        setInputDisplay({ ...inputDisplay, alternativeTitle: false });
        setValues({ ...values, alternativeTilte: [] });
        break;
      case "Alternative Industry":
        setInputDisplay({ ...inputDisplay, alternativeIndustry: false });
        setValues({ ...values, alternativeIndustry: [] });
        // setOtherAlternativeIndustry({ otherAlternativeIndustryOpen: false, otherAlternativeIndustryRequired: false, otherAlternativeIndustryValue: '' });
        break;
      case "functionName":
        setInputDisplay({ ...inputDisplay, functionDisplay: false });
        setValues({ ...values, functionName: "" });
        break;
      case "Job Level":
        setInputDisplay({ ...inputDisplay, jobLevelDisplay: false });
        setValues({ ...values, jobLevel: "" });
        break;
      case "Reports To":
        setInputDisplay({ ...inputDisplay, reportsToDisplay: false });
        setValues({ ...values, reportsToTitle: "" });
        break;
      case "Hiring Manager Name":
        setInputDisplay({ ...inputDisplay, hiringManagerNameDisplay: false });
        setValues({ ...values, hiringManagerName: "" });
        break;
      case "Industry":
        setInputDisplay({ ...inputDisplay, industryDisplay: false });
        setValues({ ...values, industry: "" });
        setOpenCustomIndusrtyTextfiled(false);
        setCustomIndustryValue(null);
        setCustomIndustryRequired(false);
        break;
      case "Company HQ Location":
        setInputDisplay({ ...inputDisplay, companyHQLocationDisplay: false });
        setValues({ ...values, locationHQ: null });
        break;
      case "Minimum Years of Experience":
        setInputDisplay({
          ...inputDisplay,
          minimumYearsofExperienceDisplay: false,
        });
        setValues({ ...values, minimumExperience: "" });
        break;
      case "Base Salary":
        setInputDisplay({ ...inputDisplay, baseSalaryDisplay: false });
        setSalaryMax({
          salaryType: "",
          salaryConfig: "",
          minimumBaseSalary: null,
        });
        break;
      case "Degree":
        setInputDisplay({ ...inputDisplay, DegreeDisplay: false });
        setValues({ ...values, degree: "" });
        break;
      case "Degree Required or Preferred?":
        setInputDisplay({
          ...inputDisplay,
          degreeRequiredorPreferredDisplay: false,
        });
        setValues({ ...values, degreeRequiredOrPreferred: "" });
        break;
      case "Professional Certifications":
        setInputDisplay({
          ...inputDisplay,
          professionalCertificationsDisplay: false,
        });
        setValues({ ...values, certificationsValue: "", certifications: [] });
        break;
      case "Major":
        setInputDisplay({ ...inputDisplay, majorDisplay: false });
        setValues({ ...values, majorValue: "" });
        break;
      case "Work Location":
        setInputDisplay({ ...inputDisplay, remoteorOnsiteDisplay: false });
        setValues({ ...values, remote: "" });
        break;
      case "Travel Requirements":
        setInputDisplay({ ...inputDisplay, travelRequirementsDisplay: false });
        setValues({ ...values, travelLevel: "" });
        break;
      case "How soon do you want to fill this role?":
        setInputDisplay({ ...inputDisplay, howSoonDisplay: false });
        setValues({ ...values, howSoonToHire: "" });
        break;
      case "Confidential/Normal Role":
        setInputDisplay({ ...inputDisplay, confiNormalRoleDisplay: false });
        setValues({ ...values, confiNormalRoleValue: "Normal" });
        break;
      case "Company Size":
        setInputDisplay({
          ...inputDisplay,
          corporateStartupsizeDisplay: false,
        });
        setValues({ ...values, startupSize: [] });
        break;
      case "Company Type":
        setInputDisplay({
          ...inputDisplay,
          corporateStartupstageDisplay: false,
        });
        setValues({ ...values, startupStage: [] });
        break;
      case "Team size":
        setInputDisplay({ ...inputDisplay, teamSizeDisplay: false });
        setValues({ ...values, teamSize: [] });
        break;
      case "New Role/Existing Role":
        setInputDisplay({ ...inputDisplay, roleDisplay: false });
        setValues({ ...values, role: "" });
        break;
      case "About the Company":
        setInputDisplay({ ...inputDisplay, aboutUsDisplay: false });
        setValues({ ...values, aboutUs: EditorState.createEmpty() });
        break;
      case "Responsibilities":
        setInputDisplay({
          ...inputDisplay,
          additionalResponsibilitiesDisplay: false,
        });
        setValues({
          ...values,
          additionalResponsibilities: EditorState.createEmpty(),
        });
        break;
      case "Qualifications":
        setInputDisplay({
          ...inputDisplay,
          whatsuccessfulCandidatewillBringDisplay: false,
        });
        setValues({
          ...values,
          whatsuccessfulCandidatewillBring: EditorState.createEmpty(),
        });
        break;
      case "Must Have Requirements/Non-Negotiables":
        setInputDisplay({ ...inputDisplay, absoluteMUSTHAVEDisplay: false });
        setValues({ ...values, absoluteMUSTHAVE: EditorState.createEmpty() });
        break;
      case "Sample Corporation":
        setInputDisplay({ ...inputDisplay, sampleCorporationDisplay: false });
        setValues({ ...values, sampleCompanyValues: null });
        break;
      case "Competencies":
        setInputDisplay({ ...inputDisplay, CompetenciesDisplay: false });
        setValues({ ...values, skills: [] });
        break;
      default:
        break;
    }
  };

  useEffect(() => {
    setValues({
      ...values,
      locationHQ: location,
      location:
        (location.city !== undefined ? `${location.city}, ` : "") +
        (location?.state !== undefined ? `${location?.state}, ` : "") +
        (location?.country !== undefined ? location?.country : ""),
    });
  }, [location]);

  const selectAllCheckboxHandle = () => {
    if (
      inputDisplay.CompetenciesDisplay &&
      inputDisplay.DegreeDisplay &&
      inputDisplay.roleDisplay &&
      inputDisplay.baseSalaryDisplay &&
      inputDisplay.companyHQLocationDisplay &&
      inputDisplay.corporateStartupsizeDisplay &&
      inputDisplay.corporateStartupstageDisplay &&
      inputDisplay.degreeRequiredorPreferredDisplay &&
      inputDisplay.hiringManagerNameDisplay &&
      inputDisplay.howSoonDisplay &&
      inputDisplay.industryDisplay &&
      inputDisplay.majorDisplay &&
      inputDisplay.minimumYearsofExperienceDisplay &&
      inputDisplay.professionalCertificationsDisplay &&
      inputDisplay.reportsToDisplay &&
      inputDisplay.additionalResponsibilitiesDisplay &&
      inputDisplay.sampleCorporationDisplay &&
      inputDisplay.teamSizeDisplay &&
      inputDisplay.travelRequirementsDisplay &&
      inputDisplay.absoluteMUSTHAVEDisplay &&
      inputDisplay.whatsuccessfulCandidatewillBringDisplay &&
      inputDisplay.confiNormalRoleDisplay &&
      inputDisplay.alternativeTitle &&
      inputDisplay.alternativeIndustry
    ) {
      setSelectAll(true);
    } else {
      setSelectAll(false);
    }
  };

  const onHandleClosePreview = () => {
    setOpenPreview(false);
  };

  const handleSelectAll = (event) => {
    setInputDisplay({
      ...inputDisplay,
      alternativeIndustry: event.target.checked,
      alternativeTitle: event.target.checked,
      reportsToDisplay: event.target.checked,
      industryDisplay: event.target.checked,
      hiringManagerNameDisplay: event.target.checked,
      companyHQLocationDisplay: event.target.checked,
      minimumYearsofExperienceDisplay: event.target.checked,
      baseSalaryDisplay: event.target.checked,
      DegreeDisplay: event.target.checked,
      degreeRequiredorPreferredDisplay: event.target.checked,
      majorDisplay: event.target.checked,
      professionalCertificationsDisplay: event.target.checked,
      remoteorOnsiteDisplay: event.target.checked,
      travelRequirementsDisplay: event.target.checked,
      howSoonDisplay: event.target.checked,
      corporateStartupstageDisplay: event.target.checked,
      corporateStartupsizeDisplay: event.target.checked,
      teamSizeDisplay: event.target.checked,
      roleDisplay: event.target.checked,
      absoluteMUSTHAVEDisplay: event.target.checked,
      sampleCorporationDisplay: event.target.checked,
      CompetenciesDisplay: event.target.checked,
      whatsuccessfulCandidatewillBringDisplay: event.target.checked,
      additionalResponsibilitiesDisplay: event.target.checked,
      confiNormalRoleDisplay: event.target.checked,
    });

    if (!event.target.checked) {
      setValues({
        ...values,
        reportsToTitle: "",
        hiringManagerName: "",
        industry: "",
        locationHQ: null,
        minimumExperience: "",
        degree: "",
        teamSize: [],
        startupStage: [],
        startupSize: [],
        sampleCompanyValues: null,
        majorValue: "",
        certifications: [],
        certificationsValue: "",
        degreeRequiredOrPreferred: "",
        whatsuccessfulCandidatewillBring: EditorState.createEmpty(),
        additionalResponsibilities: EditorState.createEmpty(),
        travelLevel: "",
        howSoonToHire: "",
        role: "",
        skills: [],
        confiNormalRoleValue: "Normal",
      });
      dispatch(setLocation(""));
      secureLocalStorage.removeItem("locationDetails");
    }
  };

  const handleAddJob = () => {
    handleValidationForSubmitandPreview();
    const certifi = values.certifications?.map(
      (val) => val._source.Certificate
    );

    const samCom = values.sampleCompanyValues?.map((val) => ({
      company_name: val.name,
      domain: val.domain,
      logo: val.logo,
    }));

    const skillsData = values.skills?.map((val) => val.trim());

    const alternativeTitles = values.alternativeTilte.map((value) => {
      if (typeof value === "object") {
        return value?._source?.Title;
      }
      return value;
    });

    // remove empty strings from Alternative titles array and Alternative industry array
    const alternativeTitlesPayload = alternativeTitles?.filter(
      (value) => value?.trim() !== ""
    );
    const alternativeIndustryPayload = values.alternativeIndustry?.filter(
      (value) => value?.trim() !== ""
    );

    const price = Intl.NumberFormat("en-US");
    const salary =
      salaryMax.minimumBaseSalary === null || salaryMax.minimumBaseSalary === ""
        ? null
        : `$${price.format(salaryMax.minimumBaseSalary)}`;
    payload = {
      title: trim(values.title),
      location:
        !values?.locationHQ || Object.keys(values?.locationHQ).length === 0
          ? null
          : values.locationHQ,
      recruiter_id: JSON.parse(
        secureLocalStorage.getItem("recruiterStatusDetails")
      ).recruiter_id,
      function: trim(values.functionName),
      notice_period: values.howSoonToHire ? trim(values.howSoonToHire) : null,
      function_name: values.otherFunctionName
        ? trim(values.otherFunctionName)
        : null,
      industry: values.industry ? trim(values.industry) : null,
      level: values.jobLevel ? trim(values.jobLevel) : null,
      reports_to: values.reportsToTitle
        ? trim(values.reportsToTitle?._source.Title)
        : null,
      about_us: trim(values.aboutUsWithHTML),
      skills: skillsData?.length > 0 ? skillsData : null,
      degree: values.degree ? trim(values.degree) : null,
      major: values.majorValue ? trim(values.majorValue?._source?.Major) : null,
      degree_req_pref: values.degreeRequiredOrPreferred
        ? trim(values.degreeRequiredOrPreferred)
        : null,
      travel_requirements: values.travelLevel ? trim(values.travelLevel) : null,
      remote_or_onsite: values.remote ? trim(values.remote) : null,
      minimum_base_salary: salaryMax.minimumBaseSalary ? trim(salary) : null,
      minimum_years_of_experience: values.minimumExperience
        ? trim(values.minimumExperience)
        : null,
      certifications: certifi?.length > 0 ? certifi : null,
      reports_to_name: values.hiringManagerName
        ? trim(values.hiringManagerName)
        : null,
      salary_config: salaryMax.salaryConfig
        ? salaryMax.salaryConfig === 0
          ? 0
          : salaryMax.salaryConfig
        : null,
      startup_size:
        values.startupSize?.length === 0 ? null : values.startupSize,
      startup_stage:
        values.startupStage?.length === 0 ? null : values.startupStage,
      team_size: values.teamSize?.length === 0 ? null : values.teamSize,
      existing_role: values.role || null,
      sample_companies: samCom?.length === 0 ? null : samCom,
      about_role:
        trim(values.aboutRoleHTML).length === 0
          ? null
          : trim(values.aboutRoleHTML),
      candidate_value_addition: trim(values.willSuccessfullCandidateBringHTML),
      additional_responsibility: trim(values.additionResponsibilitesWithHTML),
      absolute_must_have:
        trim(values.absolueMustHavehHTML).length === 0
          ? null
          : trim(values.absolueMustHavehHTML),
      job_type: values.confiNormalRoleValue,
      other_industry_text:
        customIndustryValue === null ? null : customIndustryValue,
      alternate_titles:
        alternativeTitlesPayload?.length > 0 ? alternativeTitlesPayload : null,
      alternate_industries:
        alternativeIndustryPayload.length > 0
          ? alternativeIndustryPayload
          : null,
    };
    setPostJob(true);
  };

  useEffect(() => {
    offsetHeight = document.getElementById("my_div").offsetHeight;
  });

  useEffect(() => {
    if (postjob) {
      if (
        !validate.isEmptyAboutCompany &&
        !validate.isEmptyAboutRole &&
        !validate.jobTitleRequired &&
        !validate.functionValidateRequired &&
        !validate.jobLevelRequired &&
        !validate.workloactionRequired &&
        !validate.aboutCompanyRequired &&
        !validate.aboutCompanyCharactersValid &&
        !validate.isAboutRoleRequired &&
        !validate.aboutRoleCharactersValid &&
        !customIndustryRequired &&
        !validate.isRequiredNonNegotiables &&
        !validate.isValidCharNonNNegotiables
      ) {
        dispatch(jobsActions.postJob(payload));
        setPostJob(false);
        setShowPopUp(false);
        dispatch(setLocation(""));
      } else {
        toast.dark("Please fill required fields with valid data");
        setPostJob(false);
      }
    }
  }, [postjob]);

  const handleOnBlur = () => {
    jobTitleOptions = [];
  };

  const handleValidationForSubmitandPreview = () => {
    setValidate({
      ...validate,
      jobTitleRequired: values.title ? validator.isEmpty(values.title) : true,
      jobLevelRequired: values.jobLevel
        ? validator.isEmpty(values.jobLevel)
        : true,
      functionValidateRequired: values.functionName
        ? validator.isEmpty(values.functionName)
        : true,
      workloactionRequired:
        values.remote.trim().length > 0
          ? validator.isEmpty(values.remote)
          : true,
      ResponsibilitiesRequired: validator.isEmpty(
        values.additionalResponsibilities
          .getCurrentContent()
          .getPlainText("\u0001")
      ),
      aboutCompanyRequired: validator.isEmpty(
        values.aboutUs.getCurrentContent().getPlainText("\u0001")
      ),
      isAboutRoleRequired: validator.isEmpty(
        values.aboutRole.getCurrentContent().getPlainText("\u0001")
      ),
      qualificationsRequired: validator.isEmpty(
        values.whatsuccessfulCandidatewillBring
          .getCurrentContent()
          .getPlainText("\u0001")
      ),
      charactersValidResponsibilities: !validator.matches(
        values.additionalResponsibilities
          .getCurrentContent()
          .getPlainText("\u0001"),
        /^.{25,5000}$/s
      ),
      isRequiredNonNegotiables: validator.isEmpty(
        values.absoluteMUSTHAVE.getCurrentContent().getPlainText("\u0001")
      ),
      isValidCharNonNNegotiables: !validator.matches(
        values.absoluteMUSTHAVE.getCurrentContent().getPlainText("\u0001"),
        /^.{25,5000}$/s
      ),
      qualificationsCharactersValid: !validator.matches(
        values.whatsuccessfulCandidatewillBring
          .getCurrentContent()
          .getPlainText("\u0001"),
        /^.{25,5000}$/s
      ),
      aboutCompanyCharactersValid: !validator.matches(
        values.aboutUs.getCurrentContent().getPlainText("\u0001"),
        /^.{25,5000}$/s
      ),
      aboutRoleCharactersValid: !validator.matches(
        values.aboutRole.getCurrentContent().getPlainText("\u0001"),
        /^.{25,5000}$/s
      ),
    });
    setCustomIndustryRequired(
      openCustomIndusrtyFiled === true
        ? customIndustryValue === null
          ? true
          : !!validator.isEmpty(customIndustryValue)
        : false
    );
    // setOpenCustomIndusrtyTextfiled({ ...otherAlternativeIndustry, otherAlternativeIndustryRequired: otherAlternativeIndustry.otherAlternativeIndustryOpen && otherAlternativeIndustry.otherAlternativeIndustryValue?.trim().length === 0 ? true : false })
  };

  const handlePrevieOpen = () => {
    handleValidationForSubmitandPreview();
    setShowPopUp(true);
  };

  useEffect(() => {
    if (showPopUp) {
      if (
        !validate.isEmptyAboutCompany &&
        !validate.isEmptyAboutRole &&
        !validate.jobTitleRequired &&
        !validate.functionValidateRequired &&
        !validate.jobLevelRequired &&
        !validate.workloactionRequired &&
        !validate.aboutCompanyRequired &&
        !validate.aboutCompanyCharactersValid &&
        !validate.isAboutRoleRequired &&
        !validate.aboutRoleCharactersValid &&
        !customIndustryRequired &&
        !validate.isRequiredNonNegotiables &&
        !validate.isValidCharNonNNegotiables
      ) {
        setOpenPreview(true);
      } else {
        toast.dark("Please fill required fields with valid data");
      }
    }
    setShowPopUp(false);
  }, [showPopUp]);

  const onClickSelectedOption = () => {
    setSelectOptions(true);
  };

  const goBack = () => {
    history.push("/jobs/my-jobs");
  };

  const handleChangeAlternativeIndustry = (e, newValue) => {
    // setOtherAlternativeIndustry({ otherAlternativeIndustryOpen: newValue.includes('Other') ? true : false, otherAlternativeIndustryRequired: newValue.includes('Other') ? true : false });
    setValues({ ...values, alternativeIndustry: newValue });
  };

  // remove Other from Industry options
  useEffect(() => {
    // to prevent deep copy using slice
    IndustryOptions = constants?.industry?.slice();
    for (let i = 0; i < IndustryOptions.length; i++) {
      if (IndustryOptions[i] === "Other") {
        IndustryOptions.splice(i, 1);
      }
    }
  }, []);

  return (
    <>
      <Grid
        id="select_option_div"
        className={
          isNavbarVisible || isNavbarVisible === undefined
            ? classes.headerMarginTop
            : classes.noMarginTop
        }
      >
        <Grid container className={classes.subHeaderGrid} id="my_div">
          <Grid item xs={12} sm={12} md={12} xl={12} lg={12}>
            <div className={classes.appFrame}>
              <Button
                style={{ float: "left" }}
                startIcon={<KeyboardArrowLeftIcon />}
                onClick={(event) => {
                  goBack(event);
                }}
              >
                Back
              </Button>
            </div>
          </Grid>
          <Grid item xs={9} sm={9} md={9} xl={9} lg={9}>
            <div className={classes.addJobTopDiv}>
              <Typography
                variant="h6"
                align="left"
                className={classes.addJobTypo}
              >
                Add Job
              </Typography>
              <Typography
                component="p"
                className={classes.addJobSubhead}
                align="left"
              >
                The job description will not be visible to candidates until you
                have chosen to connect with them.
              </Typography>
              <Typography
                component="p"
                align="left"
                className={classes.addJobSubhead}
              >
                (<span style={{ color: "#FF0000" }}>*</span>
                Required fields)
              </Typography>
            </div>
          </Grid>
          <Grid
            item
            xs={3}
            sm={3}
            md={3}
            xl={3}
            lg={3}
            className={classes.alignSelftCenter}
          >
            <div style={{ display: "flex", justifyContent: "end" }}>
              <Button
                disableElevation
                className={classes.ButtonPreviewandJob}
                variant="outlined"
                size="medium"
                onClick={handlePrevieOpen}
              >
                Preview
              </Button>
              <Button
                className={classes.ButtonPreviewandJob}
                onClick={() => {
                  handleAddJob();
                }}
                disableElevation
                variant="contained"
                size="medium"
                color="primary"
              >
                Add Job
              </Button>
            </div>
          </Grid>
        </Grid>
        <Grid
          container
          wrap="wrap-reverse"
          style={{ marginTop: `${offsetHeight}px` }}
        >
          <Grid
            items
            xs={12}
            sm={12}
            md={12}
            xl={9}
            lg={9}
            className={classes.fullComponentPadding}
          >
            <Typography align="left" className={classes.BasicJobFields}>
              Basic Job Fields
            </Typography>
            <Grid
              item
              sm={11}
              xs={11}
              md={11}
              xl={11}
              lg={11}
              className={classNames(classes.mainInputGrid, classes.fullWidth)}
            >
              <Autocomplete
                id="auto-complete"
                PopperComponent={Pop}
                autoComplete
                open={openOptions}
                options={jobTitleOptions || []}
                value={values?.title}
                renderOption={(option) => option?._source?.Title}
                onOpen={() => {
                  setOption(true);
                  removeFieldHighlight();
                }}
                onClose={() => {
                  setOption(false);
                }}
                onInputCapture={() => {
                  removeFieldHighlight();
                }}
                onChange={(event, newValue) => {
                  if (typeof newValue === "string") {
                    setTimeout(() => {
                      setCustomJobTitlePopUp({
                        customJobTitlePopUp: true,
                        customJobTitle: newValue,
                      });
                    });
                  } else if (newValue && newValue.inputValue) {
                    setCustomJobTitlePopUp({
                      customJobTitlePopUp: true,
                      customJobTitle: newValue.inputValue,
                    });
                  } else {
                    setJobTitle(newValue);
                  }
                }}
                filterOptions={(options, params) => {
                  const filtered = options;
                  if (params?.inputValue !== "") {
                    filtered.push({
                      inputValue: params.inputValue,
                      _source: {
                        Title: `Add: "${params.inputValue}"`,
                      },
                    });
                  }
                  return filtered;
                }}
                loading={loading.jobTitleLoading}
                getOptionLabel={(option) => {
                  if (typeof option === "string") {
                    return option;
                  }
                  if (option.inputValue) {
                    return option?.inputValue;
                  }
                  return option?._source?.Title ? option?._source?.Title : "";
                }}
                freeSolo
                selectOnFocus
                clearOnBlur
                onBlur={(event) => {
                  handleOnBlur(event);
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Job Title"
                    id="jobTitle"
                    name="jobTitle"
                    InputProps={{
                      ...params.InputProps,
                      endAdornment: (
                        <>
                          {loading.jobTitleLoading ? (
                            <CircularProgress
                              className={classes.verySmallMarginRight}
                              color="inherit"
                              size={20}
                            />
                          ) : null}
                          {params.InputProps.endAdornment}
                        </>
                      ),
                      inputProps: {
                        ...params.inputProps,
                        maxLength: customFieldCount,
                      },
                    }}
                    required
                    error={validate.jobTitleRequired}
                    helperText={
                      validate.jobTitleRequired ? MESSAGE_FIELD_IS_REQUIRED : ""
                    }
                    onChange={handleInputChange("jobTitle")}
                    placeholder="Type to select job title"
                  />
                )}
              />
            </Grid>
            {inputDisplay.alternativeTitle && (
              <Grid container>
                <Grid
                  item
                  sm={11}
                  xs={11}
                  md={11}
                  xl={11}
                  lg={11}
                  className={classNames(
                    classes.mainInputGrid,
                    classes.fullWidth
                  )}
                >
                  <Autocomplete
                    multiple
                    id="tags-standard"
                    freeSolo
                    value={values.alternativeTilte}
                    onChange={(event, newValue) => {
                      setValues({ ...values, alternativeTilte: newValue });
                    }}
                    options={jobTitleOptions}
                    getOptionLabel={(option) => {
                      if (typeof option === "string") {
                        return option;
                      }
                      return option?._source?.Title
                        ? option?._source?.Title
                        : "";
                    }}
                    renderOption={(option) => option?._source?.Title}
                    renderTags={(value, getTagProps) =>
                      value.map((option, index) => {
                        if (
                          typeof option === "string" &&
                          option?.trim()?.length === 0
                        ) {
                          return false;
                        }
                        if (option?._source?.Title) {
                          return (
                            <Chip
                              label={option?._source?.Title?.trim()}
                              {...getTagProps({ index })}
                              className={classes.chip}
                            />
                          );
                        }
                        return (
                          <Chip
                            label={option}
                            {...getTagProps({ index })}
                            className={classes.chip}
                          />
                        );
                      })
                    }
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        onChange={handleInputChange("jobTitleAlternative")}
                        variant="standard"
                        label="Alternative Titles"
                        placeholder="Alternative Titles"
                      />
                    )}
                  />
                </Grid>
                <Grid
                  item
                  xs={1}
                  sm={1}
                  md={1}
                  lg={1}
                  xl={1}
                  className={classNames(
                    classes.flexGrid,
                    classes.alignItemsCenterGrid
                  )}
                >
                  <IconButton
                    onClick={() => handleCloseInput("Alternative Job Title")}
                    className={classes.paddingNone}
                  >
                    <CancelIcon className={classes.closeButtons} />
                  </IconButton>
                </Grid>
              </Grid>
            )}
            <Grid
              item
              sm={11}
              xs={11}
              md={11}
              xl={11}
              lg={11}
              className={classNames(
                classes.mediumPaddingLeft,
                classes.customTopMargin
              )}
            >
              <FormControl
                required
                error={validate.functionValidateRequired}
                className={classNames(
                  classes.fullWidth,
                  classes.textAlignLeftCustom
                )}
              >
                <InputLabel
                  error={validate.functionValidateRequired}
                  id="demo-simple-select-label"
                >
                  Function
                </InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="jobFunctionName"
                  margin="none"
                  value={values.functionName}
                  placeholder="Choose one"
                  onChange={handleInputChange("functionName")}
                >
                  {constants?.function.map((obj, index) => (
                    <MenuItem key={index} value={obj}>
                      {obj}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
              {validate.functionValidateRequired ? (
                <FormHelperText error={validate.functionValidateRequired}>
                  {MESSAGE_FIELD_IS_REQUIRED}
                </FormHelperText>
              ) : (
                ""
              )}
            </Grid>
            {values?.functionName === "Other" ? (
              <Grid
                item
                xs={12}
                sm={12}
                md={12}
                lg={12}
                xl={12}
                className={classNames(
                  classes.mediumPaddingLeft,
                  classes.customTopMargin
                )}
              >
                <TextField
                  className={classes.fullWidth}
                  fullWidth
                  label="Function Name"
                  autoComplete="functionName"
                  id="otherJobFunctionName"
                  name="functionName"
                  value={values.otherFunctionName}
                  margin="normal"
                  onChange={handleInputChange("otherFunctionName")}
                  placeholder="Enter function Name (optional)"
                  inputProps={{ maxLength: 100 }}
                  onInputCapture={() => {
                    removeFieldHighlight();
                  }}
                />
                <Typography color="secondary" align="right">
                  {`${values.otherFunctionName?.length}/${100}`} characters
                </Typography>
              </Grid>
            ) : null}

            <Grid
              item
              sm={11}
              xs={11}
              md={11}
              xl={11}
              lg={11}
              className={classNames(
                classes.mediumPaddingLeft,
                classes.customTopMargin
              )}
            >
              <FormControl
                className={classNames(
                  classes.textAlignLeftCustom,
                  classes.fullWidth
                )}
              >
                <InputLabel
                  error={validate.jobLevelRequired}
                  id="demo-simple-select-label"
                  required
                >
                  Job Level
                </InputLabel>
                <Select
                  margin="none"
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={values?.jobLevel}
                  placeholder="Choose one"
                  error={validate.jobLevelRequired}
                  onChange={handleInputChange("jobLevel")}
                >
                  {constants?.level.map((obj, index) => (
                    <MenuItem key={index} value={obj}>
                      {obj}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
              {validate.jobLevelRequired ? (
                <FormHelperText error={validate.jobLevelRequired}>
                  {MESSAGE_FIELD_IS_REQUIRED}
                </FormHelperText>
              ) : (
                ""
              )}
            </Grid>
            <>
              <Grid container>
                <Grid
                  item
                  xs={11}
                  sm={11}
                  md={11}
                  lg={11}
                  xl={11}
                  className={classes.customGridValues}
                >
                  <FormControl
                    required
                    component="fieldset"
                    error={validate.workloactionRequired}
                    className={classes.fullWidth}
                  >
                    <RadioGroup
                      aria-label="remote"
                      name="remote"
                      value={values.remote}
                      onChange={handleInputChange("remote")}
                    >
                      <Grid container>
                        <Grid xs={12} sm={12} md={12} lg={3} xl={3}>
                          <FormLabel
                            style={{ marginTop: "14px" }}
                            component="legend"
                          >
                            Work Location
                          </FormLabel>
                        </Grid>
                        {constants?.remote_or_onsite.map((option) => (
                          <Grid
                            item
                            xs={12}
                            sm={12}
                            md={12}
                            lg={3}
                            xl={3}
                            className={classes.displayFlex}
                          >
                            <FormControlLabel
                              control={<Radio />}
                              label={
                                <Typography variant="body1">
                                  {option}
                                </Typography>
                              }
                              value={option}
                            />
                          </Grid>
                        ))}
                      </Grid>
                    </RadioGroup>
                  </FormControl>
                </Grid>
                {/* <Grid style={{ marginTop: "0px" }} className={classNames(classes.flexGrid, classes.alignItemsCenterGrid)}>
                    <IconButton onClick={() => handleCloseInput("Work Location")} className={classes.paddingNone}><CancelIcon className={classes.closeButtons} /></IconButton>
                  </Grid> */}
              </Grid>
              {validate.workloactionRequired ? (
                <FormHelperText
                  className={classes.mediumPaddingLeft}
                  error={validate.workloactionRequired}
                >
                  {MESSAGE_FIELD_IS_REQUIRED}
                </FormHelperText>
              ) : (
                ""
              )}
            </>

            {inputDisplay.reportsToDisplay && (
              <Grid container>
                <Grid
                  item
                  xs={11}
                  sm={11}
                  md={11}
                  lg={11}
                  xl={11}
                  className={classes.customTopMargin}
                >
                  <Autocomplete
                    className={classNames(classes.mediumPaddingLeft)}
                    autoComplete
                    id="auto-complete"
                    PopperComponent={Pop}
                    open={openReportsToOptions}
                    freeSolo
                    selectOnFocus
                    clearOnBlur
                    onChange={(event, value) => {
                      setValues({ ...values, reportsToTitle: value });
                    }}
                    onOpen={() => {
                      setOpenReportsToOptions(true);
                    }}
                    onClose={() => {
                      setOpenReportsToOptions(false);
                    }}
                    getOptionSelected={(option, value) =>
                      option._source.Title === value.name
                    }
                    getOptionLabel={(option) => option._source.Title}
                    options={reportsToTitleOptions || []}
                    loading={loading.reportsToTitlesLoading}
                    filterOptions={(options) => {
                      const filtered = options;
                      return filtered;
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Reports To"
                        margin="normal"
                        id="reportsToTitle"
                        name="reportsToTitle"
                        InputProps={{
                          ...params.InputProps,
                          endAdornment: (
                            <>
                              {loading.reportsToTitlesLoading ? (
                                <CircularProgress
                                  className={classes.verySmallMarginRight}
                                  color="inherit"
                                  size={20}
                                />
                              ) : null}
                              {params.InputProps.endAdornment}
                            </>
                          ),
                        }}
                        value={values.reportsToTitle}
                        onChange={handleInputChange("reportsToTitle")}
                        placeholder="Type to select hiring manager's title"
                      />
                    )}
                  />
                  {/* </div> */}
                </Grid>
                <Grid
                  item
                  xs={1}
                  sm={1}
                  md={1}
                  lg={1}
                  xl={1}
                  className={classNames(
                    classes.flexGrid,
                    classes.alignItemsCenterGrid
                  )}
                >
                  <IconButton
                    onClick={() => handleCloseInput("Reports To")}
                    className={classes.paddingNone}
                  >
                    <CancelIcon className={classes.closeButtons} />
                  </IconButton>
                </Grid>
              </Grid>
            )}

            {inputDisplay.hiringManagerNameDisplay && (
              <Grid container>
                <Grid
                  item
                  xs={11}
                  sm={11}
                  md={11}
                  lg={11}
                  xl={11}
                  className={classNames(
                    classes.fullWidth,
                    classes.mediumPaddingLeft,
                    classes.customTopMargin
                  )}
                >
                  <TextField
                    fullWidth
                    label="Hiring Manager Name"
                    autoComplete="reportsTo"
                    id="reportsTo"
                    name="reportsTo"
                    value={values.hiringManagerName}
                    onChange={handleInputChange("Hiring Manager Name")}
                    placeholder="Enter hiring manager's name"
                    inputProps={{ maxLength: reportsToCount }}
                  />
                  <Typography align="right" color="secondary">
                    {`${values.hiringManagerName?.length}/${reportsToCount}`}{" "}
                    characters
                  </Typography>
                </Grid>
                <Grid
                  className={classNames(
                    classes.flexGrid,
                    classes.alignItemsCenterGrid
                  )}
                >
                  <IconButton
                    onClick={() => handleCloseInput("Hiring Manager Name")}
                    className={classes.paddingNone}
                  >
                    <CancelIcon className={classes.closeButtons} />
                  </IconButton>
                </Grid>
              </Grid>
            )}

            {inputDisplay.industryDisplay && (
              <Grid container>
                <Grid
                  item
                  xs={11}
                  sm={11}
                  md={11}
                  lg={11}
                  xl={11}
                  className={classNames(
                    classes.fullWidth,
                    classes.mediumPaddingLeft
                  )}
                >
                  <FormControl className={classes.customFormControl}>
                    <InputLabel id="demo-simple-select-label">
                      Industry
                    </InputLabel>
                    <Select
                      id="industry"
                      margin="dense"
                      value={values.industry}
                      placeholder="Choose one"
                      onChange={handleInputChange("industry")}
                    >
                      {constants?.industry.map((obj, index) => (
                        <MenuItem key={index} value={obj}>
                          {obj}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>
                <Grid
                  item
                  xs={1}
                  sm={1}
                  md={1}
                  lg={1}
                  xl={1}
                  className={classNames(
                    classes.flexGrid,
                    classes.alignItemsCenterGrid
                  )}
                >
                  <IconButton
                    onClick={() => handleCloseInput("Industry")}
                    className={classes.paddingNone}
                  >
                    <CancelIcon className={classes.closeButtons} />
                  </IconButton>
                </Grid>
              </Grid>
            )}

            {inputDisplay.industryDisplay && openCustomIndusrtyFiled && (
              <Grid container>
                <Grid
                  item
                  xs={11}
                  sm={11}
                  md={11}
                  lg={11}
                  xl={11}
                  className={classes.customLabelGrid}
                >
                  <FormControl
                    className={classes.customFormControl}
                    required
                    error={customIndustryRequired}
                  >
                    <TextField
                      required
                      fullWidth
                      label="Other Industry Name"
                      className={classes.customTopBottomMargin}
                      id="customIndustry"
                      name="customIndustry"
                      value={customIndustryValue}
                      onChange={handleInputChange("customIndustryValue")}
                      placeholder="Other Industry Name"
                      inputProps={{ maxLength: 100 }}
                      error={!!customIndustryRequired}
                      helperText={
                        customIndustryRequired === true
                          ? MESSAGE_FIELD_IS_REQUIRED
                          : null
                      }
                    />
                  </FormControl>
                </Grid>
              </Grid>
            )}
            {inputDisplay.alternativeIndustry && (
              <Grid container>
                <Grid
                  item
                  sm={11}
                  xs={11}
                  md={11}
                  xl={11}
                  lg={11}
                  className={classNames(
                    classes.mainInputGrid,
                    classes.fullWidth
                  )}
                >
                  <Autocomplete
                    multiple
                    id="tags-standard"
                    value={values.alternativeIndustry}
                    filterSelectedOptions
                    freeSolo
                    onChange={(event, newValue) => {
                      handleChangeAlternativeIndustry(event, newValue);
                    }}
                    options={IndustryOptions}
                    renderOption={(option) => option}
                    renderTags={(value, getTagProps) =>
                      value.map((option, index) => {
                        if (option?.trim()?.length === 0) {
                          return false;
                        }
                        return (
                          <Chip
                            label={option}
                            {...getTagProps({ index })}
                            className={classes.chip}
                          />
                        );
                      })
                    }
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        variant="standard"
                        label="Alternative Industry"
                        placeholder="Alternative Industry"
                      />
                    )}
                  />
                </Grid>
                <Grid
                  item
                  xs={1}
                  sm={1}
                  md={1}
                  lg={1}
                  xl={1}
                  className={classNames(
                    classes.flexGrid,
                    classes.alignItemsCenterGrid
                  )}
                >
                  <IconButton
                    onClick={() => handleCloseInput("Alternative Industry")}
                    className={classes.paddingNone}
                  >
                    <CancelIcon className={classes.closeButtons} />
                  </IconButton>
                </Grid>
              </Grid>
            )}
            {/* {otherAlternativeIndustry.otherAlternativeIndustryOpen &&
              <Grid container>
                <Grid item xs={11} sm={11} md={11} lg={11} xl={11} className={classes.customLabelGrid}>
                  <FormControl className={classes.customFormControl} required error={customIndustryRequired}>
                    <TextField
                      required
                      fullWidth
                      label="Other Alternative Industry"
                      className={classes.customTopBottomMargin}
                      id='customIndustry'
                      name='customIndustry'
                      value={otherAlternativeIndustry.otherAlternativeIndustryValue}
                      onChange={handleInputChange('alternativeOtherIndustry')}
                      placeholder="Other Alternative Industry"
                      inputProps={{ maxLength: 100 }}
                      error={otherAlternativeIndustry.otherAlternativeIndustryRequired ? true : false}
                      helperText={otherAlternativeIndustry.otherAlternativeIndustryRequired === true ? MESSAGE_FIELD_IS_REQUIRED : null}
                    />
                  </FormControl>
                </Grid>
              </Grid>
            } */}

            {inputDisplay.companyHQLocationDisplay && (
              <Grid container>
                <Grid
                  item
                  xs={11}
                  sm={11}
                  md={11}
                  lg={11}
                  xl={11}
                  className={classNames(classes.customLabelGrid)}
                >
                  <GoogleMaps updateRequire={updateLocationRequireState} />
                </Grid>
                <Grid
                  className={classNames(
                    classes.flexGrid,
                    classes.alignItemsCenterGrid
                  )}
                >
                  <IconButton
                    onClick={() => handleCloseInput("Company HQ Location")}
                    className={classes.paddingNone}
                  >
                    <CancelIcon className={classes.closeButtons} />
                  </IconButton>
                </Grid>
              </Grid>
            )}

            {inputDisplay.minimumYearsofExperienceDisplay && (
              <Grid container>
                <Grid
                  item
                  xs={11}
                  sm={11}
                  md={11}
                  lg={11}
                  xl={11}
                  className={classes.customLabelGrid}
                >
                  <FormControl className={classes.customFormControl}>
                    <InputLabel id="experience-label">
                      Minimum Years of Experience
                    </InputLabel>
                    <Select
                      labelId="experience-label"
                      id="experience"
                      value={values.minimumExperience}
                      placeholder="Choose one"
                      onChange={handleInputChange("minimumExperience")}
                    >
                      {constants?.years_of_experience.map((obj, index) => (
                        <MenuItem key={index} value={obj}>
                          {obj}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>
                <Grid
                  className={classNames(
                    classes.flexGrid,
                    classes.alignItemsCenterGrid
                  )}
                >
                  <IconButton
                    onClick={() =>
                      handleCloseInput("Minimum Years of Experience")
                    }
                    className={classes.paddingNone}
                  >
                    <CancelIcon className={classes.closeButtons} />
                  </IconButton>
                </Grid>
              </Grid>
            )}

            {inputDisplay.baseSalaryDisplay && (
              <Grid container>
                <Grid
                  item
                  xs={11}
                  sm={11}
                  md={11}
                  lg={11}
                  xl={11}
                  className={classNames(
                    classes.flexGrid,
                    classes.alignItemsCenterGrid
                  )}
                >
                  <Grid
                    item
                    xs={12}
                    sm={12}
                    md={12}
                    lg={12}
                    xl={12}
                    className={classes.baseSalaryFormControl}
                  >
                    <FormControl
                      component="fieldset"
                      className={classNames(
                        classes.fullWidth,
                        classes.verySmallTopMargin,
                        classes.colorDefault
                      )}
                    >
                      <Typography
                        component="div"
                        className={classNames(
                          classes.flexGrid,
                          classes.alignItemsCenterGrid
                        )}
                      >
                        <FormLabel
                          className={classes.customFormLabel}
                          id="salary-label"
                          component="legend"
                        >
                          Base Salary
                        </FormLabel>
                        &nbsp;
                        <Typography component="span">
                          <PopupState
                            variant="popover"
                            popupId="demo-popup-popover"
                          >
                            {(popupState) => (
                              <>
                                <HelpIcon
                                  {...bindTrigger(popupState)}
                                  className={classes.helpIcon}
                                  titleAccess="Help"
                                />
                                <Popover
                                  {...bindPopover(popupState)}
                                  anchorOrigin={{
                                    vertical: "top",
                                    horizontal: "left",
                                  }}
                                  transformOrigin={{
                                    vertical: "top",
                                    horizontal: "left",
                                  }}
                                  className={classes.smallPadding}
                                >
                                  <Box sx={{ width: 600, height: 300 }}>
                                    <Typography
                                      variant="body1"
                                      className={classNames(
                                        classes.smallPaddingPopover,
                                        classes.mediumFontWeight
                                      )}
                                    >
                                      <b> Help Text </b>
                                    </Typography>
                                    <Typography
                                      variant="body2"
                                      className={classNames(
                                        classes.salaryHelpText
                                      )}
                                    >
                                      <b>Share with candidates : </b>
                                    </Typography>
                                    <Typography
                                      className={classes.smallPaddingPopover}
                                    >
                                      Selecting this option displays base salary
                                      information to every candidate you're
                                      interested in.
                                    </Typography>
                                    <Typography
                                      variant="body2"
                                      className={classNames(
                                        classes.salaryHelpText
                                      )}
                                    >
                                      <b>Candidate matching only : </b>
                                    </Typography>
                                    <Typography
                                      className={classes.smallPaddingPopover}
                                    >
                                      Selecting this option includes the
                                      designated base salary within
                                      SucceedSmart's candidate search criteria
                                      without displaying the salary information
                                      to selected candidates.
                                    </Typography>
                                    <Typography
                                      variant="body2"
                                      className={classNames(
                                        classes.salaryHelpText
                                      )}
                                    >
                                      <b>Exclude salary : </b>
                                    </Typography>
                                    <Typography
                                      className={classes.smallPaddingPopover}
                                    >
                                      Selecting this option removes the use of a
                                      salary to match prospective candidates for
                                      this role and also removes the use of a
                                      salary from your candidate search
                                      parameters.
                                    </Typography>
                                  </Box>
                                </Popover>
                              </>
                            )}
                          </PopupState>
                        </Typography>
                      </Typography>
                      <RadioGroup
                        aria-label="salary-label"
                        id="salary"
                        value={salaryMax.salaryType}
                        onChange={(event, newValue) => {
                          switch (newValue) {
                            case "Exclude salary":
                              setSalaryMax({
                                ...salaryMax,
                                salaryConfig: 0,
                                salaryType: newValue,
                                minimumBaseSalary: null,
                              });
                              break;
                            case "Candidate matching only":
                              setSalaryMax({
                                ...salaryMax,
                                salaryConfig: 1,
                                salaryType: newValue,
                                minimumBaseSalary:
                                  salaryMax.minimumBaseSalary !== null
                                    ? salaryMax.minimumBaseSalary
                                    : "100000",
                              });
                              break;
                            case "Share with candidates":
                              setSalaryMax({
                                ...salaryMax,
                                salaryConfig: 2,
                                salaryType: newValue,
                                minimumBaseSalary:
                                  salaryMax.minimumBaseSalary !== null
                                    ? salaryMax.minimumBaseSalary
                                    : "100000",
                              });
                              break;
                            default:
                              break;
                          }
                        }}
                      >
                        <Grid container>
                          <Grid item xs={12} sm={12} md={12} lg={4} xl={4}>
                            <FormControlLabel
                              label="Share with candidates"
                              value="Share with candidates"
                              control={<Radio />}
                            />
                          </Grid>
                          <Grid item xs={12} sm={12} md={12} lg={4} xl={4}>
                            <FormControlLabel
                              label="Candidate matching only"
                              value="Candidate matching only"
                              control={<Radio />}
                            />
                          </Grid>
                          <Grid
                            item
                            xs={12}
                            sm={12}
                            md={12}
                            lg={4}
                            xl={4}
                            style={{ display: "flex", alignItems: "center" }}
                          >
                            <FormControlLabel
                              label="Exclude salary"
                              value="Exclude salary"
                              control={<Radio />}
                            />
                          </Grid>
                        </Grid>
                      </RadioGroup>
                    </FormControl>
                    {salaryMax?.salaryType === undefined ||
                    salaryMax?.salaryType === "Share with candidates" ||
                    salaryMax?.salaryType === "Candidate matching only" ? (
                      <Grid className={classes.salaryOption}>
                        <Grid container spacing={0}>
                          <Grid item xs={3} sm={3} md={3} lg={3} xl={3}>
                            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                              <Typography
                                component="div"
                                style={{ fontWeight: 500, color: "black" }}
                              >
                                {salaryMax.minimumBaseSalary !== null
                                  ? `$ ${salaryValueFormat.format(
                                      salaryMax.minimumBaseSalary
                                    )}`
                                  : `$ ${salaryValueFormat.format(100000)}`}
                              </Typography>
                            </Grid>
                            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                              <Typography
                                component="div"
                                style={{ fontWeight: 500, color: "black" }}
                              >
                                Selected Salary
                              </Typography>
                            </Grid>
                          </Grid>
                          <Grid
                            item
                            xs={9}
                            sm={9}
                            md={9}
                            lg={9}
                            xl={9}
                            className={classes.displayFlex}
                          >
                            <SalarySlider
                              aria-label="Always visible"
                              size="medium"
                              value={salaryMax.minimumBaseSalary}
                              onChange={(event, newValue) => {
                                if (newValue) {
                                  setSalaryMax({
                                    ...salaryMax,
                                    minimumBaseSalary: newValue,
                                  });
                                }
                              }}
                              marks={marks}
                              step={10000}
                              min={minValue}
                              max={maxValue}
                              valueLabelDisplay="on"
                            />
                          </Grid>
                        </Grid>
                      </Grid>
                    ) : (
                      ""
                    )}
                  </Grid>
                </Grid>
                <Grid
                  className={classNames(
                    classes.flexGrid,
                    classes.alignItemsCenterGrid
                  )}
                >
                  <IconButton
                    onClick={() => handleCloseInput("Base Salary")}
                    className={classes.paddingNone}
                  >
                    <CancelIcon className={classes.closeButtons} />
                  </IconButton>
                </Grid>
              </Grid>
            )}

            {inputDisplay.DegreeDisplay && (
              <Grid container>
                <Grid
                  item
                  xs={11}
                  sm={11}
                  md={11}
                  lg={11}
                  xl={11}
                  className={classes.customLabelGrid}
                >
                  <FormControl className={classes.customFormControl}>
                    <InputLabel id="degree-label">Degree</InputLabel>
                    <Select
                      labelId="degree-label"
                      id="degree"
                      value={values.degree}
                      placeholder="Choose one"
                      onChange={handleInputChange("degree")}
                    >
                      {constants?.degree.map((obj, index) => (
                        <MenuItem key={index} value={obj}>
                          {obj}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>
                <Grid
                  className={classNames(
                    classes.flexGrid,
                    classes.alignItemsCenterGrid
                  )}
                >
                  <IconButton
                    onClick={() => handleCloseInput("Degree")}
                    className={classes.paddingNone}
                  >
                    <CancelIcon className={classes.closeButtons} />
                  </IconButton>
                </Grid>
              </Grid>
            )}

            {/* Major */}
            {inputDisplay.majorDisplay && (
              <Grid container>
                <Grid
                  item
                  xs={11}
                  sm={11}
                  md={11}
                  lg={11}
                  xl={11}
                  className={classNames(
                    classes.flexGrid,
                    classes.alignItemsCenterGrid
                  )}
                >
                  <div
                    className={classNames(
                      classes.fullWidth,
                      classes.mediumPaddingLeft
                    )}
                  >
                    <Autocomplete
                      id="auto-complete-major"
                      PopperComponent={Pop}
                      autoComplete
                      value={values.majorValue}
                      onChange={(event, value) => {
                        if (typeof value === "string") {
                          // timeout to avoid instant validation of the dialog's form.
                          setTimeout(() => {
                            setCustomMajorPopUp({
                              customMajorPopUp: true,
                              customMajorValue: value,
                            });
                          });
                        } else if (value && value?.inputValue) {
                          setCustomMajorPopUp({
                            customMajorPopUp: true,
                            customMajorValue: value.inputValue,
                          });
                        } else {
                          setMajor(value);
                        }
                      }}
                      getOptionSelected={(option, value) =>
                        option?._source?.Major === value?._source?.Major
                      }
                      options={majorOptions || []}
                      getOptionLabel={(option) => {
                        if (typeof option === "string") {
                          return option;
                        }
                        if (option.inputValue) {
                          return option.inputValue;
                        }
                        return option?._source?.Major;
                      }}
                      loading={loading.majorLoading}
                      selectOnFocus
                      clearOnBlur
                      filterOptions={(options, params) => {
                        const filtered = options;
                        if (params.inputValue !== "") {
                          filtered.push({
                            inputValue: params.inputValue,
                            _source: {
                              Major: `Add: "${params.inputValue}"`,
                            },
                          });
                        }
                        return filtered;
                      }}
                      renderOption={(option) => option?._source?.Major}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label="Major"
                          id="major"
                          name="major"
                          InputProps={{
                            ...params.InputProps,
                            endAdornment: (
                              <>
                                {loading.majorLoading ? (
                                  <CircularProgress color="inherit" size={20} />
                                ) : null}
                                {params.InputProps.endAdornment}
                              </>
                            ),
                          }}
                          onChange={handleInputChange("major")}
                          placeholder="Type to select major (optional)"
                        />
                      )}
                    />
                  </div>
                </Grid>
                <Grid
                  className={classNames(
                    classes.flexGrid,
                    classes.alignItemsCenterGrid
                  )}
                >
                  <IconButton
                    onClick={() => handleCloseInput("Major")}
                    className={classes.paddingNone}
                  >
                    <CancelIcon className={classes.closeButtons} />
                  </IconButton>
                </Grid>
              </Grid>
            )}

            {inputDisplay.degreeRequiredorPreferredDisplay && (
              <Grid container>
                <Grid
                  item
                  xs={11}
                  sm={11}
                  md={11}
                  lg={11}
                  xl={11}
                  className={classNames(classes.customLableGrid)}
                >
                  <FormControl
                    component="fieldset"
                    className={classes.fullWidth}
                  >
                    <RadioGroup
                      aria-label="degreeRequired"
                      name="degreeRequired"
                      value={values.degreeRequiredOrPreferred}
                      onChange={handleInputChange("degreeRequiredOrPreferred?")}
                    >
                      <Grid container>
                        <Grid item xs={12} sm={12} md={12} lg={4} xl={4}>
                          <FormLabel
                            component="legend"
                            style={{ marginTop: "14px" }}
                          >
                            Degree Required/Preferred?
                          </FormLabel>
                        </Grid>
                        <Grid
                          item
                          xs={12}
                          sm={12}
                          md={12}
                          lg={4}
                          xl={4}
                          className={classes.displayFlex}
                        >
                          <FormControlLabel
                            control={<Radio />}
                            label={
                              <Typography variant="body1">Required</Typography>
                            }
                            value="Required"
                          />
                        </Grid>
                        <Grid
                          item
                          xs={12}
                          sm={12}
                          md={12}
                          lg={4}
                          xl={4}
                          className={classes.displayFlex}
                        >
                          <FormControlLabel
                            control={<Radio />}
                            label={
                              <Typography variant="body1">Preferred</Typography>
                            }
                            value="Preferred"
                          />
                        </Grid>
                      </Grid>
                    </RadioGroup>
                  </FormControl>
                </Grid>
                <Grid
                  className={classNames(
                    classes.flexGrid,
                    classes.alignItemsCenterGrid
                  )}
                >
                  <IconButton
                    onClick={() =>
                      handleCloseInput("Degree Required or Preferred?")
                    }
                    className={classes.paddingNone}
                  >
                    <CancelIcon className={classes.closeButtons} />
                  </IconButton>
                </Grid>
              </Grid>
            )}

            {inputDisplay.professionalCertificationsDisplay && (
              <Grid container>
                <Grid
                  item
                  xs={11}
                  sm={11}
                  md={11}
                  lg={11}
                  xl={11}
                  className={classNames(
                    classes.flexGrid,
                    classes.alignItemsCenterGrid,
                    classes.customTopMargin
                  )}
                >
                  <div
                    className={classNames(
                      classes.fullWidth,
                      classes.mediumPaddingLeft
                    )}
                  >
                    <Autocomplete
                      id="tags-outlined"
                      PopperComponent={Pop}
                      multiple
                      autoComplete
                      filterSelectedOptions
                      value={values.certifications}
                      onChange={(event, value) => {
                        if (typeof value === "string") {
                          setTimeout(() => {
                            setCustomCertificationTitlePopUp({
                              ...customCertificationTitlePopUp,
                              customCertificationTitlePopUp: true,
                              customCertificationTitle: "",
                            });
                          });
                        } else if (
                          value &&
                          value[value?.length - 1]?.inputValue
                        ) {
                          setCustomCertificationTitlePopUp({
                            customCertificationTitlePopUp: true,
                            customCertificationTitle:
                              value[value?.length - 1].inputValue,
                          });
                        } else {
                          setCertifications(value);
                        }
                      }}
                      getOptionSelected={(option, value) =>
                        option?._source?.Certificate ===
                        value?._source?.Certificate
                      }
                      getOptionLabel={(option) => {
                        if (typeof option === "string") {
                          return option;
                        }
                        if (option.inputValue) {
                          return option.inputValue;
                        }
                        return option?._source?.Certificate;
                      }}
                      options={certificationsOptions || []}
                      loading={loading.certificationsLoading}
                      selectOnFocus
                      clearOnBlur
                      filterOptions={(options, params) => {
                        const filtered = options;
                        if (params.inputValue !== "") {
                          filtered.push({
                            inputValue: params.inputValue,
                            _source: {
                              Certificate: `Add: "${params.inputValue}"`,
                            },
                          });
                        }
                        return filtered;
                      }}
                      renderOption={(option) => option?._source?.Certificate}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          id="certifications"
                          name="certifications"
                          label="Professional Certifications"
                          value={values.certificationsValue}
                          className={classes.customSmallTopMargin}
                          InputProps={{
                            ...params.InputProps,
                            endAdornment: (
                              <>
                                {loading.certificationsLoading ? (
                                  <CircularProgress
                                    className={classes.verySmallMarginRight}
                                    color="inherit"
                                    size={20}
                                  />
                                ) : null}
                                {params.InputProps.endAdornment}
                              </>
                            ),
                            inputProps: {
                              ...params.inputProps,
                              maxLength: 100,
                            },
                          }}
                          onChange={handleInputChange("certificationsValue")}
                          placeholder={
                            values.certifications === "" ||
                            values.certifications?.length < 1
                              ? "Type to select from options"
                              : ""
                          }
                        />
                      )}
                    />
                  </div>
                </Grid>
                <Grid
                  className={classNames(
                    classes.flexGrid,
                    classes.alignItemsCenterGrid
                  )}
                >
                  <IconButton
                    onClick={() =>
                      handleCloseInput("Professional Certifications")
                    }
                    className={classes.paddingNone}
                  >
                    <CancelIcon className={classes.closeButtons} />
                  </IconButton>
                </Grid>
              </Grid>
            )}

            {/* {inputDisplay.remoteorOnsiteDisplay && */}
            {/* <>
                <Grid container>
                  <Grid item xs={11} sm={11} md={11} lg={11} xl={11} className={classes.customGridValues}>
                    <FormControl required component="fieldset" error={validate.workloactionRequired} className={classes.fullWidth}>
                      <RadioGroup aria-label="remote" name="remote" value={values.remote}
                        onChange={handleInputChange('remote')} >
                        <Grid container>
                          <Grid xs={12} sm={12} md={12} lg={3} xl={3}><FormLabel style={{ marginTop: "14px" }} component="legend">Work Location</FormLabel></Grid>
                          {constants?.remote_or_onsite.map((option, index) => (
                            <Grid item xs={12} sm={12} md={12} lg={3} xl={3} className={classes.displayFlex}>
                              <FormControlLabel control={<Radio />} label={<Typography variant="body1">{option}</Typography>} value={option} />
                            </Grid>
                          ))}
                        </Grid>
                      </RadioGroup>
                    </FormControl>
                  </Grid>
                  <Grid style={{ marginTop: "0px" }} className={classNames(classes.flexGrid, classes.alignItemsCenterGrid)}>
                    <IconButton onClick={() => handleCloseInput("Work Location")} className={classes.paddingNone}><CancelIcon className={classes.closeButtons} /></IconButton>
                  </Grid>
                </Grid>
                {validate.workloactionRequired ? <FormHelperText className={classes.mediumPaddingLeft} error={validate.workloactionRequired}>{MESSAGE_FIELD_IS_REQUIRED}</FormHelperText> : ""}
              </> */}
            {/* } */}

            {inputDisplay.travelRequirementsDisplay && (
              <Grid container>
                <Grid
                  item
                  xs={11}
                  sm={11}
                  md={11}
                  lg={11}
                  xl={11}
                  className={classes.customGridValues}
                >
                  <FormControl
                    component="fieldset"
                    className={classes.fullWidth}
                  >
                    <RadioGroup
                      aria-label="travelLevel"
                      name="travelLevel"
                      value={values.travelLevel}
                      onChange={handleInputChange("travelLevel")}
                    >
                      <Grid container>
                        <Grid xs={12} sm={12} md={12} lg={3} xl={3}>
                          <FormLabel
                            component="legend"
                            style={{ marginTop: "14px" }}
                          >
                            Travel Requirements
                          </FormLabel>
                        </Grid>
                        {constants?.travel_requirements.map((option) => (
                          <Grid
                            item
                            xs={12}
                            sm={12}
                            md={12}
                            lg={3}
                            xl={3}
                            className={classes.displayFlex}
                          >
                            <FormControlLabel
                              className={classes.noWrap}
                              control={<Radio />}
                              label={
                                <Typography variant="body1">
                                  {option}
                                </Typography>
                              }
                              value={option}
                            />
                          </Grid>
                        ))}
                      </Grid>
                    </RadioGroup>
                  </FormControl>
                </Grid>
                <Grid
                  className={classNames(
                    classes.flexGrid,
                    classes.alignItemsCenterGrid
                  )}
                >
                  <IconButton
                    onClick={() => handleCloseInput("Travel Requirements")}
                    className={classes.paddingNone}
                  >
                    <CancelIcon className={classes.closeButtons} />
                  </IconButton>
                </Grid>
              </Grid>
            )}

            {inputDisplay.howSoonDisplay && (
              <Grid container>
                <Grid
                  item
                  xs={11}
                  sm={11}
                  md={11}
                  lg={11}
                  xl={11}
                  className={classNames(classes.customLableGrid)}
                >
                  <FormControl
                    component="fieldset"
                    className={classNames(
                      classes.fullWidth,
                      classes.customTopMargin
                    )}
                  >
                    <FormLabel component="span">
                      How soon do you want to fill this role?
                    </FormLabel>
                    <RadioGroup
                      aria-label="howSoonToHire"
                      name="howSoonToHire"
                      value={values.howSoonToHire}
                      onChange={handleInputChange("howSoonToHire")}
                    >
                      <Grid container>
                        {constants?.jobs_notice_period.map((option) => (
                          <Grid item xs={12} sm={12} md={12} lg={4} xl={4}>
                            <FormControlLabel
                              className={classes.noWrap}
                              control={<Radio />}
                              label={option}
                              value={option}
                            />
                          </Grid>
                        ))}
                      </Grid>
                    </RadioGroup>
                  </FormControl>
                </Grid>
                <Grid
                  className={classNames(
                    classes.flexGrid,
                    classes.alignItemsCenterGrid
                  )}
                >
                  <IconButton
                    onClick={() =>
                      handleCloseInput(
                        "How soon do you want to fill this role?"
                      )
                    }
                    className={classes.paddingNone}
                  >
                    <CancelIcon className={classes.closeButtons} />
                  </IconButton>
                </Grid>
              </Grid>
            )}

            {inputDisplay.confiNormalRoleDisplay && (
              <Grid container>
                <Grid
                  item
                  xs={11}
                  sm={11}
                  md={11}
                  lg={11}
                  xl={11}
                  className={classNames(classes.customLableGrid)}
                >
                  <FormControl
                    component="fieldset"
                    className={classNames(
                      classes.fullWidth,
                      classes.customTopMargin
                    )}
                  >
                    <FormLabel component="span">
                      Confidential/Normal Role
                    </FormLabel>
                    <RadioGroup
                      aria-label="Confidential/Normal Role"
                      name="Confidential/Normal Role"
                      value={values.confiNormalRoleValue}
                      onChange={handleInputChange("confidentialNormalRole")}
                    >
                      <Grid container>
                        <Grid item xs={12} sm={12} md={12} lg={4} xl={4}>
                          <FormControlLabel
                            control={<Radio />}
                            label={
                              <Typography variant="body1">
                                Confidential{" "}
                              </Typography>
                            }
                            value="Confidential"
                          />
                        </Grid>
                        <Grid item xs={12} sm={12} md={12} lg={4} xl={4}>
                          <FormControlLabel
                            control={<Radio />}
                            label={
                              <Typography variant="body1">Normal</Typography>
                            }
                            value="Normal"
                          />
                        </Grid>
                      </Grid>
                    </RadioGroup>
                  </FormControl>
                </Grid>
                <Grid
                  className={classNames(
                    classes.flexGrid,
                    classes.alignItemsCenterGrid
                  )}
                >
                  <IconButton
                    onClick={() => handleCloseInput("Confidential/Normal Role")}
                    className={classes.paddingNone}
                  >
                    <CancelIcon className={classes.closeButtons} />
                  </IconButton>
                </Grid>
              </Grid>
            )}
            {(inputDisplay.teamSizeDisplay ||
              inputDisplay.corporateStartupsizeDisplay ||
              inputDisplay.corporateStartupstageDisplay ||
              inputDisplay.roleDisplay) && (
              <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                <Typography align="left" className={classes.BasicJobFields}>
                  Corporate/Startup Information
                </Typography>
              </Grid>
            )}

            {inputDisplay.corporateStartupsizeDisplay && (
              <>
                <Grid container>
                  <Grid
                    item
                    xs={11}
                    sm={11}
                    md={11}
                    lg={11}
                    xl={11}
                    className={classes.customLableGrid}
                  >
                    <div className={classes.fullWidth}>
                      <FormLabel component="legend">Company Size</FormLabel>
                    </div>
                  </Grid>
                  <Grid
                    className={classNames(
                      classes.flexGrid,
                      classes.alignItemsCenterGrid
                    )}
                  >
                    <IconButton
                      onClick={() => handleCloseInput("Company Size")}
                      className={classes.paddingNone}
                    >
                      <CancelIcon className={classes.closeButtons} />
                    </IconButton>
                  </Grid>
                </Grid>
                <Grid container>
                  {constants?.company_size.map((option) => (
                    <Grid
                      item
                      xs={4}
                      sm={4}
                      md={4}
                      lg={4}
                      xl={4}
                      className={classNames(
                        classes.mediumPaddingLeft,
                        classes.textAlignLeftCustom
                      )}
                    >
                      <FormControlLabel
                        key={option}
                        control={
                          <Checkbox
                            onChange={handleCheckBox("StartupSize")}
                            name={option}
                            value={option}
                            iconStyle={{ fill: "#048475" }}
                            style={{ color: "#048475" }}
                          />
                        }
                        label={
                          <Typography
                            variant="body2"
                            className={classes.CorporateLabel}
                          >
                            {option}
                          </Typography>
                        }
                      />
                    </Grid>
                  ))}
                </Grid>
              </>
            )}

            {inputDisplay.corporateStartupstageDisplay && (
              <>
                <Grid container>
                  <Grid
                    item
                    xs={11}
                    sm={11}
                    md={11}
                    lg={11}
                    xl={11}
                    className={classes.customLableGrid}
                  >
                    <div className={classes.fullWidth}>
                      <FormLabel component="legend">Company Type</FormLabel>
                    </div>
                  </Grid>
                  <Grid
                    className={classNames(
                      classes.flexGrid,
                      classes.alignItemsCenterGrid
                    )}
                  >
                    <IconButton
                      onClick={() => handleCloseInput("Company Type")}
                      className={classes.paddingNone}
                    >
                      <CancelIcon className={classes.closeButtons} />
                    </IconButton>
                  </Grid>
                </Grid>
                <Grid container>
                  {constants?.size_and_stages.map((option) => (
                    <Grid
                      md={4}
                      xl={4}
                      className={classNames(
                        classes.mediumPaddingLeft,
                        classes.textAlignLeftCustom
                      )}
                    >
                      <FormControlLabel
                        key={option}
                        control={
                          <Checkbox
                            onChange={handleCheckBox("StartupStage")}
                            name={option}
                            value={option}
                            iconStyle={{ fill: "#048475" }}
                            style={{ color: "#048475" }}
                          />
                        }
                        label={
                          <Typography
                            variant="body2"
                            className={classes.CorporateLabel}
                          >
                            {option}
                          </Typography>
                        }
                      />
                    </Grid>
                  ))}
                </Grid>
              </>
            )}

            {inputDisplay.teamSizeDisplay && (
              <>
                <Grid container>
                  <Grid
                    item
                    xs={11}
                    sm={11}
                    md={11}
                    lg={11}
                    xl={11}
                    className={classes.customLableGrid}
                  >
                    <div className={classes.fullWidth}>
                      <FormLabel component="legend">Team size</FormLabel>
                    </div>
                  </Grid>
                  <Grid
                    className={classNames(
                      classes.flexGrid,
                      classes.alignItemsCenterGrid
                    )}
                  >
                    <IconButton
                      onClick={() => handleCloseInput("Team size")}
                      className={classes.paddingNone}
                    >
                      <CancelIcon className={classes.closeButtons} />
                    </IconButton>
                  </Grid>
                </Grid>
                <Grid container>
                  {constants?.team_size.map((option) => (
                    <Grid
                      md={4}
                      xl={4}
                      className={classNames(
                        classes.mediumPaddingLeft,
                        classes.textAlignLeftCustom
                      )}
                    >
                      <FormControlLabel
                        key={option}
                        control={
                          <Checkbox
                            onChange={handleCheckBox("TeamSize")}
                            name={option}
                            value={option}
                            iconStyle={{ fill: "#048475" }}
                            style={{ color: "#048475" }}
                          />
                        }
                        label={
                          <Typography
                            variant="body2"
                            className={classes.CorporateLabel}
                          >
                            {option}
                          </Typography>
                        }
                      />
                    </Grid>
                  ))}
                </Grid>
              </>
            )}

            {inputDisplay.roleDisplay && (
              <Grid container>
                <Grid
                  item
                  xs={11}
                  sm={11}
                  md={11}
                  lg={11}
                  xl={11}
                  className={classes.customGridValues}
                >
                  <FormControl
                    component="fieldset"
                    className={classes.fullWidth}
                  >
                    <RadioGroup
                      aria-label="role"
                      name="role"
                      value={values.role}
                      onChange={handleInputChange("role")}
                      row
                    >
                      <Grid container>
                        <Grid item xs={12} sm={12} md={12} lg={4} xl={4}>
                          <FormLabel
                            ormLabel
                            component="legend"
                            style={{ marginTop: "14px" }}
                          >
                            New Role/Existing Role
                          </FormLabel>
                        </Grid>
                        <Grid item xs={12} sm={12} md={12} lg={4} xl={4}>
                          <FormControlLabel
                            control={<Radio />}
                            label={
                              <Typography variant="body1">New Role</Typography>
                            }
                            value="New Role"
                          />
                        </Grid>
                        <Grid item xs={12} sm={12} md={12} lg={4} xl={4}>
                          <FormControlLabel
                            control={<Radio />}
                            label={
                              <Typography variant="body1">
                                Existing Role
                              </Typography>
                            }
                            value="Existing Role"
                          />
                        </Grid>
                      </Grid>
                    </RadioGroup>
                  </FormControl>
                </Grid>
                <Grid
                  className={classNames(
                    classes.flexGrid,
                    classes.alignItemsCenterGrid
                  )}
                >
                  <IconButton
                    onClick={() => handleCloseInput("New Role/Existing Role")}
                    className={classes.paddingNone}
                  >
                    <CancelIcon className={classes.closeButtons} />
                  </IconButton>
                </Grid>
              </Grid>
            )}

            {inputDisplay.sampleCorporationDisplay && (
              <>
                <Grid container>
                  <Grid
                    item
                    xs={11}
                    sm={11}
                    md={11}
                    lg={11}
                    xl={11}
                    className={classNames(
                      classes.customLableGrid,
                      classes.customTopMargin
                    )}
                  >
                    <div className={classes.fullWidth}>
                      <FormLabel
                        component="legend"
                        className={classes.smallTopmargin}
                      >
                        Sample Corporation
                      </FormLabel>
                    </div>
                  </Grid>
                  <Grid
                    className={classNames(
                      classes.flexGrid,
                      classes.alignItemsCenterGrid,
                      classes.customTopMargin
                    )}
                  >
                    <IconButton
                      onClick={() => handleCloseInput("Sample Corporation")}
                      className={classes.paddingNone}
                    >
                      <CancelIcon className={classes.closeButtons} />
                    </IconButton>
                  </Grid>
                </Grid>
                <Grid
                  item
                  xs={11}
                  sm={11}
                  md={11}
                  lg={11}
                  xl={11}
                  className={classNames(
                    classes.sampleCorporationGrid,
                    classes.smallTopmargin
                  )}
                >
                  <Autocomplete
                    autoComplete
                    filterSelectedOptions
                    multiple
                    id="tags-standard"
                    options={companyNamesOptions || []}
                    getOptionLabel={(option) => option.name}
                    loading={loading.companyLoading}
                    getOptionSelected={(option, value) =>
                      option.name === value.name
                    }
                    getOptionDisabled={() =>
                      values.sampleCompanyValues?.length === 10
                    }
                    selectOnFocus
                    clearOnBlur
                    renderTags={(value, getTagProps) =>
                      value.map((option, index) => (
                        <Chip
                          variant="default"
                          label={option.name}
                          {...getTagProps({ index })}
                          className={classes.chip}
                        />
                      ))
                    }
                    onChange={(event, value) => {
                      setValues({ ...values, sampleCompanyValues: value });
                    }}
                    filterOptions={(options) => {
                      const filtered = options;
                      return filtered;
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        placeholder="Type company name and select"
                        variant="outlined"
                        onChange={(event) => {
                          setLoading({ ...loading, companyLoading: true });
                          handleExpCompany(event);
                        }}
                        InputProps={{
                          ...params.InputProps,
                          endAdornment: (
                            <>
                              {loading.companyLoading ? (
                                <CircularProgress
                                  className={classes.verySmallMarginRight}
                                  color="inherit"
                                  size={20}
                                />
                              ) : null}
                              {params.InputProps.endAdornment}
                            </>
                          ),
                          inputProps: { ...params.inputProps, maxLength: 1000 },
                        }}
                        value={companyNameValueInput}
                      />
                    )}
                  />
                </Grid>
              </>
            )}

            <Grid
              item
              sm={11}
              xs={11}
              md={11}
              xl={11}
              lg={11}
              className={classes.mediumPaddingLeft}
            >
              <div className={classes.fullWidth}>
                <CustomEditer
                  maxLength={5000}
                  label="About Us"
                  labelAlign="left"
                  required
                  handleInputChange={handleChangeAboutUs}
                  onEditerChange={onChangeEditorAboutUS}
                  editerState={values.aboutUs}
                  isRequired={validate.aboutCompanyRequired}
                  isCharValidate={validate.aboutCompanyCharactersValid}
                />
              </div>
            </Grid>

            <Grid
              item
              sm={11}
              xs={11}
              md={11}
              xl={11}
              lg={11}
              className={classes.mediumPaddingLeft}
            >
              <CustomEditer
                maxLength={5000}
                required
                label="About Role"
                labelAlign="left"
                handleInputChange={handleChangeAboutRole}
                onEditerChange={onChangeEditorAboutRole}
                editerState={values.aboutRole}
                isRequired={validate.isAboutRoleRequired}
                isCharValidate={validate.aboutRoleCharactersValid}
              />
            </Grid>

            {inputDisplay.additionalResponsibilitiesDisplay && (
              <Grid container>
                <Grid
                  item
                  sm={11}
                  xs={11}
                  md={11}
                  xl={11}
                  lg={11}
                  className={classes.editorGrid}
                >
                  <div className={classes.fullWidth}>
                    <CustomEditer
                      maxLength={5000}
                      label="Responsibilities"
                      labelAlign="left"
                      // required={true}
                      handleInputChange={handleChangeAdditionalresponsibilities}
                      onEditerChange={onChangeEditorAdditionalresponsibilities}
                      editerState={values.additionalResponsibilities}
                      // isRequired={validate.ResponsibilitiesRequired}
                      // isCharValidate={validate.charactersValidResponsibilities}
                    />
                  </div>
                </Grid>
                <Grid
                  className={classNames(
                    classes.flexGrid,
                    classes.alignItemsStartGrid
                  )}
                >
                  <IconButton
                    onClick={() => handleCloseInput("Responsibilities")}
                    className={classes.paddingNone}
                  >
                    {" "}
                    <IconButton
                      onClick={() => handleCloseInput("Job Level")}
                      className={classes.paddingNone}
                    >
                      <CancelIcon className={classes.closeButtons} />
                    </IconButton>
                  </IconButton>
                </Grid>
              </Grid>
            )}

            {inputDisplay.whatsuccessfulCandidatewillBringDisplay && (
              <Grid container>
                <Grid
                  item
                  sm={11}
                  xs={11}
                  md={11}
                  xl={11}
                  lg={11}
                  className={classes.editorGrid}
                >
                  <div className={classes.fullWidth}>
                    <CustomEditer
                      maxLength={5000}
                      label="Qualifications"
                      labelAlign="left"
                      // required={true}
                      handleInputChange={
                        handleChangewhatsuccessfulCandidatewillBring
                      }
                      onEditerChange={
                        onChangeEditorwhatsuccessfulCandidatewillBring
                      }
                      editerState={values.whatsuccessfulCandidatewillBring}
                      // isRequired={validate.qualificationsRequired}
                      // isCharValidate={validate.qualificationsCharactersValid}
                    />
                  </div>
                </Grid>
                <Grid
                  className={classNames(
                    classes.flexGrid,
                    classes.alignItemsStartGrid
                  )}
                >
                  <IconButton
                    onClick={() => handleCloseInput("Qualifications")}
                    className={classes.paddingNone}
                  >
                    {" "}
                    <IconButton
                      onClick={() => handleCloseInput("Job Level")}
                      className={classes.paddingNone}
                    >
                      <CancelIcon className={classes.closeButtons} />
                    </IconButton>
                  </IconButton>
                </Grid>
              </Grid>
            )}
            {/* {inputDisplay.absoluteMUSTHAVEDisplay && ( */}
            <Grid container>
              <Grid
                item
                sm={11}
                xs={11}
                md={11}
                xl={11}
                lg={11}
                className={classes.editorGrid}
              >
                <div className={classes.fullWidth}>
                  <CustomEditer
                    maxLength={5000}
                    label="Must Have Requirements/Non-Negotiables"
                    labelAlign="left"
                    handleInputChange={handleChangeMustHave}
                    onEditerChange={onChangeEditorMustHave}
                    editerState={values.absoluteMUSTHAVE}
                    required={true}
                    isRequired={validate.isRequiredNonNegotiables}
                    isCharValidate={validate.isValidCharNonNNegotiables}
                  />
                </div>
              </Grid>
              {/* <Grid
                  className={classNames(
                    classes.flexGrid,
                    classes.alignItemsStartGrid
                  )}
                >
                  <IconButton
                    onClick={() =>
                      handleCloseInput("Must Have Requirements/Non-Negotiables")
                    }
                    className={classes.paddingNone}
                  >
                    {" "}
                    <IconButton
                      onClick={() => handleCloseInput("Job Level")}
                      className={classes.paddingNone}
                    >
                      <CancelIcon className={classes.closeButtons} />
                    </IconButton>
                  </IconButton>
                </Grid> */}
            </Grid>
            {/* )} */}

            {inputDisplay.CompetenciesDisplay && (
              <>
                <Grid container>
                  <Grid
                    item
                    xs={11}
                    sm={11}
                    md={11}
                    lg={11}
                    xl={11}
                    className={classes.customLableGrid}
                  >
                    <div className={classes.fullWidth}>
                      <FormLabel component="legend">Competencies</FormLabel>
                    </div>
                  </Grid>
                  <Grid
                    className={classNames(
                      classes.flexGrid,
                      classes.alignItemsCenterGrid,
                      classes.smallTopmargin
                    )}
                  >
                    <IconButton
                      onClick={() => handleCloseInput("Competencies")}
                      className={classes.paddingNone}
                    >
                      <CancelIcon className={classes.closeButtons} />
                    </IconButton>
                  </Grid>
                </Grid>
                <Grid
                  item
                  xs={11}
                  sm={11}
                  md={11}
                  lg={11}
                  xl={11}
                  className={classNames(
                    classes.mediumPaddingLeft,
                    classes.smallTopmargin,
                    classes.customMarginBotton
                  )}
                >
                  <Autocomplete
                    multiple
                    freeSolo
                    selectOnFocus
                    clearOnBlur
                    id="tags-outlined"
                    name="skills"
                    autoComplete
                    value={values.skills}
                    onChange={(event, newValue) => {
                      let valueExists = false;
                      valueExists = values?.skills?.some(
                        (value) =>
                          value?.toLocaleLowerCase() ===
                          event?.target.value?.toLocaleLowerCase()
                      );
                      if (newValue?.length <= 50 && !valueExists) {
                        setValues({ ...values, skills: newValue });
                      }
                      if (newValue?.length === 0) {
                        setValues({ ...values, skills: [] });
                      }
                    }}
                    getOptionDisabled={() => values.skills?.length === 50}
                    renderTags={(value, getTagProps) =>
                      value.map((option, index) => {
                        if (
                          option?.length > 1000 ||
                          option.trim().length === 0
                        ) {
                          return false;
                        }
                        return (
                          <Chip
                            label={option.trim()}
                            {...getTagProps({ index })}
                            className={classes.chip}
                          />
                        );
                      })
                    }
                    options={[]}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        id="skills"
                        name="skills"
                        required
                        variant="outlined"
                        InputProps={{
                          ...params.InputProps,
                          inputProps: { ...params.inputProps, maxLength: 1000 },
                        }}
                        placeholder={
                          values?.skills?.length < 1
                            ? "Add desired competency for this role here and press enter"
                            : ""
                        }
                      />
                    )}
                  />
                </Grid>
              </>
            )}

            {!selectAll && (
              <Grid
                items
                xs={11}
                sm={11}
                md={11}
                xl={11}
                lg={11}
                className={classes.selectedOptMainGrid}
              >
                <Paper className={classes.paperSelectedOpt}>
                  <span className={classes.spanSelectedOpt}>
                    <Typography align="left" className={classes.fontBold}>
                      Add Advanced Fields
                    </Typography>
                    <Typography
                      align="left"
                      variant="body2"
                      style={{ color: "#6D7781" }}
                    >
                      Select advanced job fields to add
                    </Typography>
                  </span>
                  <span>
                    <Button
                      onClick={() => {
                        onClickSelectedOption();
                      }}
                      variant="contained"
                      color="default"
                      disableElevation
                      className={classes.addAdvancedButton}
                      startIcon={<AddIcon />}
                    >
                      Add Advanced Fields
                    </Button>
                  </span>
                </Paper>
              </Grid>
            )}
          </Grid>
          <Grid
            item
            xs={12}
            sm={12}
            md={12}
            xl={3}
            lg={3}
            className={classes.helpGuideGrid}
          >
            <HelpGuide />
          </Grid>
        </Grid>
      </Grid>

      {/* {selectOptions && */}

      <div
        className={classes.selectedOptionsDiv}
        style={{
          top:
            isNavbarVisible || isNavbarVisible === undefined ? "90px" : "0px",
        }}
      >
        <Drawer
          open={selectOptions}
          ModalProps={{ onBackdropClick: () => setSelectOptions(false) }}
          style={{
            marginTop:
              isNavbarVisible || isNavbarVisible === undefined ? "90px" : "0px",
          }}
        >
          <Grid container className={classes.addAdvancedOptionFixHead}>
            <Grid
              item
              xs={10}
              sm={10}
              md={10}
              xl={10}
              lg={10}
              className={classes.selectOptionHeader}
            >
              <Typography align="left" className={classes.advanceFieldsTypo}>
                Advanced Fields
              </Typography>
              <Typography
                variant="body2"
                align="left"
                className={classes.selectAdvanceFieldsTypo}
              >
                Select advanced fields to add
              </Typography>
            </Grid>
            <Grid
              item
              xs={2}
              sm={2}
              md={2}
              xl={2}
              lg={2}
              className={classes.borderBottomCustom}
            >
              <IconButton
                onClick={() => {
                  setSelectOptions(false);
                }}
              >
                <CancelIcon style={{ color: "#000000" }} />
              </IconButton>
            </Grid>
          </Grid>
          <Grid container>
            <Grid item xs={12} sm={12} md={12} xl={12} lg={12}>
              <div className={classes.checkboxTopDiv}>
                <FormControlLabel
                  value="start"
                  control={
                    <Checkbox
                      checked={selectAll}
                      style={{ color: "#000000" }}
                      onChange={(e) => {
                        handleSelectAll(e);
                      }}
                    />
                  }
                  label={
                    <Typography className={classes.fontBolder}>
                      Select All
                    </Typography>
                  }
                  labelPlacement="end"
                />
                {checkboxes.map((val) => (
                  <FormControlLabel
                    value="start"
                    className={classes.textAlignLeftCustom}
                    control={
                      <Checkbox
                        checked={val.checked}
                        disabled={val.disabled}
                        style={{ color: "#000000" }}
                        onChange={(e) => onChangeSelectInputs(val.label, e)}
                      />
                    }
                    label={val.label}
                    labelPlacement="end"
                  />
                ))}
              </div>
            </Grid>
          </Grid>
        </Drawer>
      </div>
      {openPreview && (
        <JobPreview
          openPreview={openPreview}
          onClose={onHandleClosePreview}
          jobTitleValue={values.title}
          jobLevel={values.jobLevel}
          reportsToTitleValue={values.reportsToTitle?._source?.Title}
          reportsTo={values.hiringManagerName}
          industry={values.industry}
          functionName={values.functionName}
          otherFunctionName={values.otherFunctionName}
          minimumBaseSalary={salaryMax.minimumBaseSalary}
          salary_config={salaryMax.salaryConfig}
          minimumExperience={values.minimumExperience}
          degree={values.degree}
          degreeRequiredOrPreferred={values.degreeRequiredOrPreferred}
          remote={values.remote}
          travelLevel={values.travelLevel}
          howSoonToHire={values.howSoonToHire}
          startupSize={values.startupSize}
          startupStage={values.startupStage}
          teamSize={values.teamSize}
          sampleCompanyValues={values.sampleCompanyValues}
          majorValue={values.majorValue}
          editorStateJobDescription={values.aboutRole}
          editorStateAboutUs={values.aboutUs}
          additionresponsibilities={values.additionalResponsibilities}
          editorcandidateWillBring={values.whatsuccessfulCandidatewillBring}
          editerAbsoluteMustHave={values.absoluteMUSTHAVE}
          skillsDataList={values.skills}
          location={values?.location}
          role={values.role}
          certification={values.certifications}
          jobType={values.confiNormalRoleValue}
          addJob={handleAddJob}
          customIndustryValue={customIndustryValue}
          alternativeTitle={values.alternativeTilte}
          alternateIndustries={values.alternativeIndustry}
        />
      )}

      <CustomAddPopUp
        placeholder="Enter job title"
        onSave={saveCustomJobTitle}
        label="New Job Title"
        value={customJobTitlePopUp.customJobTitle}
        open={customJobTitlePopUp.customJobTitlePopUp}
        onClose={customTilteClose}
        onChange={customTitleInput}
        title="Add Job Title"
        customFieldCount={1000}
      />
      <CustomAddPopUp
        placeholder="Enter Major"
        onSave={saveCustomMajor}
        label="Major"
        value={customMajorPopUp.customMajorValue}
        open={customMajorPopUp.customMajorPopUp}
        onClose={customMajorClose}
        onChange={customMajorInput}
        title="Add Major"
        customFieldCount={200}
      />
      <CustomAddPopUp
        placeholder="Enter Professional Certification"
        onSave={saveCustomCertificationTitle}
        label="New Professional Certification"
        value={customCertificationTitlePopUp.customCertificationTitle}
        open={customCertificationTitlePopUp.customCertificationTitlePopUp}
        onClose={customCertificationClose}
        onChange={customCertificationInput}
        title="Add Professional Certification"
        customFieldCount={1000}
      />
    </>
  );
}
export default JobPost;
