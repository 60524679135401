import axios from "axios";
import config from "../config/config";
import store from "../store/store";
import { authActions } from "../actions";
import { VERSION } from "../constants/constants";
import secureLocalStorage from "react-secure-storage";
import { history } from "../helpers";
import { toast } from "react-toastify";

export const apiService = {
  get,
  post,
  put,
  deleteDetail,
  thirdPartyApiGet,
  unProtectedApiGet,
  shareProfileApiGet,
  getBlob,
};

function getBlob(apiEndpoint) {
  return axios
    .get(config.baseUrl + apiEndpoint, getBlobOptions())
    .then((response) => {
      return response;
    })
    .catch((error) => {
      if (error.response.status !== 412) {
        toast.dark(error);
      }
    });
}

function get(apiEndpoint) {
  return axios
    .get(config.baseUrl + apiEndpoint, getOptions())
    .then((response) => response)
    .catch((error) => {
      if (
        error.response.status === 401 &&
        error.response.data.message === "TOKEN_EXPIRED" &&
        secureLocalStorage.getItem("r_token")
      ) {
        return axios
          .post(`${config.baseUrl}/api/v1/recruiter/refresh_token/`, {
            refresh_token: JSON.parse(secureLocalStorage.getItem("r_token")),
          }) // return axios.post(config.baseUrl+apiEndpoint, payload, getOptions())
          .then((response) => {
            if (response.data && response.data.access_token) {
              secureLocalStorage.setItem(
                "a_token",
                JSON.stringify(response.data.access_token)
              );
              return get(apiEndpoint).then((response) => response);
            }
          })
          .catch((error) => {
            if (
              error.response.status === 401 &&
              (error.response.data.message === "REFRESH_TOKEN_EXPIRED" ||
                error.response.data.message === "REFRESH_TOKEN_INVALID")
            ) {
              store.dispatch(authActions.signout());
            }
          });
      }
      if (
        error.response.status === 401 &&
        error.response.data.message === "TOKEN_INVALID" &&
        secureLocalStorage.getItem("r_token")
      ) {
        store.dispatch(authActions.signout());
      }
      if (error.response.status === 429) {
        history.push("/bad-request");
      }

      return error.response;
    });
}

function post(apiEndpoint, payload) {
  return axios
    .post(config.baseUrl + apiEndpoint, payload, getOptions())
    .then((response) => response)
    .catch((error) => {
      if (
        error.response.status === 401 &&
        error.response.data.message === "TOKEN_EXPIRED" &&
        secureLocalStorage.getItem("r_token")
      ) {
        return axios
          .post(`${config.baseUrl}/api/v1/recruiter/refresh_token/`, {
            refresh_token: JSON.parse(secureLocalStorage.getItem("r_token")),
          })
          .then((response) => {
            if (response.data && response.data.access_token) {
              secureLocalStorage.setItem(
                "a_token",
                JSON.stringify(response.data.access_token)
              );
              return post(apiEndpoint, payload).then((response) => response);
            }
          })
          .catch((error) => {
            if (
              error.response.status === 401 &&
              (error.response.data.message === "REFRESH_TOKEN_EXPIRED" ||
                error.response.data.message === "REFRESH_TOKEN_INVALID")
            ) {
              store.dispatch(authActions.signout());
            }
          });
      }
      if (
        error.response.status === 401 &&
        error.response.data.message === "TOKEN_INVALID" &&
        secureLocalStorage.getItem("r_token")
      ) {
        store.dispatch(authActions.signout());
      }
      if (error.response.status === 429) {
        history.push("/bad-request");
      }

      console.log(error.response);
      return error.response;
    });
}
function put(apiEndpoint, payload) {
  return axios
    .put(config.baseUrl + apiEndpoint, payload, getOptions())
    .then((response) => response)
    .catch((error) => {
      if (
        error.response.status === 401 &&
        error.response.data.message === "TOKEN_EXPIRED" &&
        secureLocalStorage.getItem("r_token")
      ) {
        return axios
          .post(`${config.baseUrl}/api/v1/recruiter/refresh_token/`, {
            refresh_token: JSON.parse(secureLocalStorage.getItem("r_token")),
          }) // return axios.post(config.baseUrl+apiEndpoint, payload, getOptions())
          .then((response) => {
            if (response.data && response.data.access_token) {
              secureLocalStorage.setItem(
                "a_token",
                JSON.stringify(response.data.access_token)
              );
              return put(apiEndpoint, payload).then((response) => response);
            }
          })
          .catch((error) => {
            if (
              error.response.status === 401 &&
              (error.response.data.message === "REFRESH_TOKEN_EXPIRED" ||
                error.response.data.message === "REFRESH_TOKEN_INVALID")
            ) {
              store.dispatch(authActions.signout());
            }
          });
      }
      if (
        error.response.status === 401 &&
        error.response.data.message === "TOKEN_INVALID" &&
        secureLocalStorage.getItem("r_token")
      ) {
        store.dispatch(authActions.signout());
      }
      if (error.response.status === 429) {
        history.push("/bad-request");
      }
      return error.response;
    });
}
function deleteDetail(apiEndpoint) {
  return axios
    .delete(config.baseUrl + apiEndpoint, getOptions())
    .then((response) => response)
    .catch((error) => {
      if (
        error.response.status === 401 &&
        error.response.data.message === "TOKEN_EXPIRED" &&
        secureLocalStorage.getItem("r_token")
      ) {
        return axios
          .post(`${config.baseUrl}/api/v1/recruiter/refresh_token/`, {
            refresh_token: JSON.parse(secureLocalStorage.getItem("r_token")),
          }) // return axios.post(config.baseUrl+apiEndpoint, payload, getOptions())
          .then((response) => {
            if (response.data && response.data.access_token) {
              secureLocalStorage.setItem(
                "a_token",
                JSON.stringify(response.data.access_token)
              );
              return deleteDetail(apiEndpoint).then((response) => response);
            }
          })
          .catch((error) => {
            if (
              error.response.status === 401 &&
              (error.response.data.message === "REFRESH_TOKEN_EXPIRED" ||
                error.response.data.message === "REFRESH_TOKEN_INVALID")
            ) {
              store.dispatch(authActions.signout());
            }
          });
      }
      if (
        error.response.status === 401 &&
        error.response.data.message === "TOKEN_INVALID" &&
        secureLocalStorage.getItem("r_token")
      ) {
        store.dispatch(authActions.signout());
      }
      if (error.response.status === 429) {
        history.push("/bad-request");
      }

      console.log(error);
      return error.response;
    });
}

function getOptions() {
  const options = {};
  if (secureLocalStorage.getItem("a_token")) {
    options.headers = {
      Authorization: `Bearer ${JSON.parse(
        secureLocalStorage.getItem("a_token")
      )}`,
      "ss-cor-source": `HULK_${VERSION}`,
    };
  } else {
    options.headers = { "ss-cor-source": `HULK_${VERSION}` };
  }
  return options;
}

function getBlobOptions() {
  let options = {};
  if (secureLocalStorage.getItem("a_token")) {
    options.responseType = "blob";
    options.headers = {
      Authorization: `Bearer ${JSON.parse(
        secureLocalStorage.getItem("a_token")
      )}`,
      "ss-cor-source": `HULK_${VERSION}`,
    };
  } else {
    options.headers = { "ss-cor-source": `HULK_${VERSION}` };
  }
  return options;
}

function thirdPartyApiGet(apiEndpoint) {
  return axios
    .get(apiEndpoint)
    .then((response) => response)
    .catch((error) => {
      console.log(error);
    });
}

function unProtectedApiGet(apiEndpoint) {
  return axios
    .get(config.baseUrl + apiEndpoint)
    .then((response) => response)
    .catch((error) => {
      console.log(error);
    });
}

function shareProfileApiGet(apiEndpoint, password) {
  return axios
    .get(config.baseUrl + apiEndpoint, getOptionsShareProfile(password))
    .then((response) => response)
    .catch((error) => {
      toast.dark(error.response.data.message);
      return error;
    });
}

function getOptionsShareProfile(password) {
  const options = {};
  options.headers = {
    Authorization: `basic ${password}`,
    "ss-cor-source": `HULK_${VERSION}`,
  };

  return options;
}
