import React, { useEffect, useState } from "react";
import { loadStripe } from "@stripe/stripe-js";
import {
  CardElement,
  Elements,
  useElements,
  useStripe,
} from "@stripe/react-stripe-js";
import { withStyles } from "@material-ui/core/styles";
// import AppBar from '../../core/appbar';
import {
  Grid,
  Typography,
  Button,
  Divider,
  Tab,
  Tabs,
  Link,
} from "@material-ui/core";
// Checkbox, FormControlLabel, Popover
import ToggleButton from "@material-ui/lab/ToggleButton";
import ToggleButtonGroup from "@material-ui/lab/ToggleButtonGroup";
import { useDispatch, useSelector } from "react-redux";
import LockIcon from "@material-ui/icons/Lock";
import HelpIcon from "@material-ui/icons/Help";
import config from "../../../config/config";
import { paymentActions } from "../../../actions/payment.actions";
import GetHelp from "../../core/gethelp";

// import { ReactComponent as RoutingNumberImage } from '../../../assets/routing_number.svg';
// import { ReactComponent as AccountNumberImage } from '../../../assets/account_number.svg';
import "./payment.css";
import { getStripeKey } from "../../../actions";
// import secureLocalStorage from "react-secure-storage";

// import classNames from "classnames";
// import { toast } from "react-toastify";

// const CARD_OPTIONS = {
//   iconStyle: "solid",
//   style: {
//     base: {
//       iconColor: "#c4f0ff",
//       color: "#fff",
//       fontWeight: 500,
//       fontFamily: "Roboto, Open Sans, Segoe UI, sans-serif",
//       fontSize: "16px",
//       fontSmoothing: "antialiased",
//       ":-webkit-autofill": {
//         color: "#fce883"
//       },
//       "::placeholder": {
//         color: "#87bbfd"
//       }
//     },
//     invalid: {
//       iconColor: "#ffc7ee",
//       color: "#ffc7ee"
//     }
//   }
// };

const CARD_OPTIONS = {
  hidePostalCode: true,
};

function CardField({ onChange }) {
  return (
    <div className="FormRow">
      {/* <CardElement options={CARD_OPTIONS} onChange={onChange} /> */}
      <CardElement options={CARD_OPTIONS} onChange={onChange} />
    </div>
  );
}

function CardNameField({
  label,
  id,
  type,
  placeholder,
  required,
  autoComplete,
  value,
  onChange,
  // pattern,
  title,
  // oninput,
  // oninvalid
}) {
  return (
    <div className="FormRow">
      <label htmlFor={id} className="CustomFormRowLabel">
        {label}
      </label>
      <input
        className="FormRowInput"
        id={id}
        type={type}
        placeholder={placeholder}
        required={required}
        autoComplete={autoComplete}
        value={value}
        onChange={onChange}
        // pattern={pattern}
        title={title}
      />
      <HelpIcon
        style={{ visibility: "hidden", marginRight: "10px", cursor: "pointer" }}
      />
    </div>
  );
}

// const AccountNameField = ({
//   label,
//   id,
//   type,
//   placeholder,
//   required,
//   autoComplete,
//   value,
//   onChange,
//   pattern,
//   title
// }) => (
//   <div className="FormRow">
//     <label htmlFor={id} className="FormRowLabel">
//       {label}
//     </label>
//     <input
//       className="FormRowInput"
//       id={id}
//       type={type}
//       placeholder={placeholder}
//       required={required}
//       autoComplete={autoComplete}
//       value={value}
//       onChange={onChange}
//       pattern={pattern}
//       title={title}
//     />
//     <HelpIcon style={{visibility: 'hidden', marginRight: '10px', cursor: 'pointer'}} />
//   </div>
// );

// const RoutingNumberField = ({
//   label,
//   id,
//   type,
//   placeholder,
//   required,
//   autoComplete,
//   value,
//   onChange,
//   pattern,
//   title
// }) => {
//   const [anchorEl, setAnchorEl] = React.useState(null);

//   const handleClick = (event) => {
//     setAnchorEl(event.currentTarget);
//   };

//   const handleClose = () => {
//     setAnchorEl(null);
//   };

//   const open = Boolean(anchorEl);
//   // const id = open ? 'simple-popover' : undefined;

//   return(
//   <div className="FormRow">
//     <Popover
//         id={id}
//         open={open}
//         anchorEl={anchorEl}
//         onClose={handleClose}
//         anchorOrigin={{
//           vertical: 'top',
//           horizontal: 'right',
//         }}
//         transformOrigin={{
//           vertical: 'top',
//           horizontal: 'center',
//         }}
//       >
//         <RoutingNumberImage />
//     </Popover>
//     <label htmlFor={id} className="FormRowLabel">
//       {label}
//     </label>
//     <input
//       className="FormRowInput"
//       id={id}
//       type={type}
//       placeholder={placeholder}
//       required={required}
//       autoComplete={autoComplete}
//       value={value}
//       onChange={onChange}
//       pattern={pattern}
//       title={title}
//     />
//     <HelpIcon titleAccess='Help' style={{marginRight: '10px', cursor: 'pointer'}} onClick={handleClick} />
//   </div>
// )
// };

// const AccountNumberField = ({
//   label,
//   id,
//   type,
//   placeholder,
//   required,
//   autoComplete,
//   value,
//   onChange,
//   pattern,
//   title
// }) => {
//   const [anchorEl, setAnchorEl] = React.useState(null);

//   const handleClick = (event) => {
//     setAnchorEl(event.currentTarget);
//   };

//   const handleClose = () => {
//     setAnchorEl(null);
//   };

//   const open = Boolean(anchorEl);
//   return(
//   <div className="FormRow">
//     <Popover
//         id={id}
//         open={open}
//         anchorEl={anchorEl}
//         onClose={handleClose}
//         anchorOrigin={{
//           vertical: 'top',
//           horizontal: 'center',
//         }}
//         transformOrigin={{
//           vertical: 'top',
//           horizontal: 'center',
//         }}
//       >
//         <AccountNumberImage />
//     </Popover>
//     <label htmlFor={id} className="FormRowLabel">
//       {label}
//     </label>
//     <input
//       className="FormRowInput"
//       id={id}
//       type={type}
//       placeholder={placeholder}
//       required={required}
//       autoComplete={autoComplete}
//       value={value}
//       onChange={onChange}
//       pattern={pattern}
//       title={title}
//     />
//     <HelpIcon titleAccess='Help' style={{marginRight: '10px', cursor: 'pointer'}} onClick={handleClick} />
//   </div>
// )
// };

function SubmitButton({ processing, error, children, disabled }) {
  return (
    <Button
      className={`SubmitButton ${error ? "SubmitButton--error" : ""}`}
      type="submit"
      disabled={processing || disabled}
      variant="contained"
      color="primary"
      size="large"
      disableElevation
    >
      {processing ? "Processing..." : children}
    </Button>
  );
}

function ErrorMessage({ children }) {
  return (
    <div className="ErrorMessage" role="alert">
      {/* <svg width="16" height="16" viewBox="0 0 17 17">
      <path
        fill="#FFF"
        d="M8.5,17 C3.80557963,17 0,13.1944204 0,8.5 C0,3.80557963 3.80557963,0 8.5,0 C13.1944204,0 17,3.80557963 17,8.5 C17,13.1944204 13.1944204,17 8.5,17 Z"
      />
      <path
        fill="rgb(220, 0, 78)"
        d="M8.5,7.29791847 L6.12604076,4.92395924 C5.79409512,4.59201359 5.25590488,4.59201359 4.92395924,4.92395924 C4.59201359,5.25590488 4.59201359,5.79409512 4.92395924,6.12604076 L7.29791847,8.5 L4.92395924,10.8739592 C4.59201359,11.2059049 4.59201359,11.7440951 4.92395924,12.0760408 C5.25590488,12.4079864 5.79409512,12.4079864 6.12604076,12.0760408 L8.5,9.70208153 L10.8739592,12.0760408 C11.2059049,12.4079864 11.7440951,12.4079864 12.0760408,12.0760408 C12.4079864,11.7440951 12.4079864,11.2059049 12.0760408,10.8739592 L9.70208153,8.5 L12.0760408,6.12604076 C12.4079864,5.79409512 12.4079864,5.25590488 12.0760408,4.92395924 C11.7440951,4.59201359 11.2059049,4.59201359 10.8739592,4.92395924 L8.5,7.29791847 L8.5,7.29791847 Z"
      />
    </svg> */}
      {children}
    </div>
  );
}

// const ResetButton = ({ onClick }) => (
//   <button type="button" className="ResetButton" onClick={onClick}>
//     <svg width="32px" height="32px" viewBox="0 0 32 32">
//       <path
//         fill="#FFF"
//         d="M15,7.05492878 C10.5000495,7.55237307 7,11.3674463 7,16 C7,20.9705627 11.0294373,25 16,25 C20.9705627,25 25,20.9705627 25,16 C25,15.3627484 24.4834055,14.8461538 23.8461538,14.8461538 C23.2089022,14.8461538 22.6923077,15.3627484 22.6923077,16 C22.6923077,19.6960595 19.6960595,22.6923077 16,22.6923077 C12.3039405,22.6923077 9.30769231,19.6960595 9.30769231,16 C9.30769231,12.3039405 12.3039405,9.30769231 16,9.30769231 L16,12.0841673 C16,12.1800431 16.0275652,12.2738974 16.0794108,12.354546 C16.2287368,12.5868311 16.5380938,12.6540826 16.7703788,12.5047565 L22.3457501,8.92058924 L22.3457501,8.92058924 C22.4060014,8.88185624 22.4572275,8.83063012 22.4959605,8.7703788 C22.6452866,8.53809377 22.5780351,8.22873685 22.3457501,8.07941076 L22.3457501,8.07941076 L16.7703788,4.49524351 C16.6897301,4.44339794 16.5958758,4.41583275 16.5,4.41583275 C16.2238576,4.41583275 16,4.63969037 16,4.91583275 L16,7 L15,7 L15,7.05492878 Z M16,32 C7.163444,32 0,24.836556 0,16 C0,7.163444 7.163444,0 16,0 C24.836556,0 32,7.163444 32,16 C32,24.836556 24.836556,32 16,32 Z"
//       />
//     </svg>
//   </button>
// );

// function usePrevious(value) {
//   const ref = useRef();
//   useEffect(() => {
//     ref.current = value;
//   });
//   return ref.current;
// }

function CheckoutForm() {
  const stripe = useStripe();
  const elements = useElements();
  const dispatch = useDispatch();
  const [error, setError] = useState(null);
  const [cardComplete, setCardComplete] = useState(false);
  const [processing, setProcessing] = useState(false);
  const [paymentMethod, setPaymentMethod] = useState(null);
  const [tabValue, setTabValue] = useState(0);
  const [billingDetails, setBillingDetails] = useState({
    // email: "",
    // phone: "",
    name: "",
  });
  // const [bankAccountDetails, setBankAccountDetails] = useState({
  //   routingNumber: '',
  //   accountNumber: '',
  //   accountHolder: ''
  // });
  const [subscription, setSubscriptionType] = React.useState("Annual");
  const [monthlySubscriptionAmount, setMonthlySubscriptionAmount] =
    React.useState("800");
  const [annualSubscriptionAmount, setAnnualSubscriptionAmount] =
    React.useState("500");
  // const [acceptTnC, setAcceptTnc] = React.useState(false);
  // const [userDetails, setUserDetails] = React.useState(JSON.parse(secureLocalStorage.getItem('userDetails')));
  // const prevSubscription = usePrevious(subscription);

  const AntTabs = withStyles(() => ({
    root: {
      borderBottom: "1px solid #e8e8e8",
      margin: "5%",
      marginBottom: "3% !important",
      justifyContent: "space-around",
    },
    indicator: {
      backgroundColor: "#FBC112",
    },
  }))(Tabs);

  const AntTab = withStyles((theme) => ({
    root: {
      textTransform: "none",
      minWidth: 72,
      fontWeight: theme.typography.fontWeightRegular,
      marginRight: theme.spacing(4),
      "&:hover": {
        color: theme.palette.secondary,
        opacity: 1,
      },
      "&$selected": {
        color: theme.palette.secondary,
        fontWeight: theme.typography.fontWeightMedium,
      },
      "&:focus": {
        color: theme.palette.secondary,
      },
    },
    // selected: {
    //     borderBottom: '3px solid #FBC112',
    //     borderRadius: '0px'
    // },
  }))((props) => <Tab disableRipple {...props} />);

  const handleSubscription = (event, newAlignment) => {
    // if(newAlignment === null){
    //   setSubscriptionType(prevSubscription);
    //   return;
    // }
    setSubscriptionType(newAlignment);
  };

  const handleTabChange = (event, tab) => {
    setTabValue(tab);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    if (!stripe || !elements) {
      // Stripe.js has not loaded yet. Make sure to disable
      // form submission until Stripe.js has loaded.
      return;
    }

    if (error) {
      elements.getElement("card").focus();
      return;
    }

    if (cardComplete) {
      setProcessing(true);
    }

    const paymentResponse = await stripe.createPaymentMethod({
      type: "card",
      card: elements.getElement(CardElement),
      billing_details: billingDetails,
    });

    setProcessing(false);

    if (paymentResponse.error) {
      setError(paymentResponse.error);
    } else {
      setPaymentMethod(paymentResponse.paymentMethod);
      setMonthlySubscriptionAmount();
      setAnnualSubscriptionAmount();
      console.log(paymentMethod);
      dispatch(
        paymentActions.savePaymentDetails(
          paymentResponse.paymentMethod,
          subscription,
          subscription === "Monthly"
            ? monthlySubscriptionAmount
            : annualSubscriptionAmount
        )
      );
    }
  };

  // const handleSubmitBankDetails = event => {
  //   event.preventDefault();
  //   setProcessing(true);
  //   // const paymentDetails = this.props.payment;
  //   console.log('bankAccountDetails...', bankAccountDetails);
  //   //Bank Account
  //   stripe.createToken('bank_account', {
  //     country: 'US',
  //     currency: 'usd',
  //     routing_number: bankAccountDetails.routingNumber,
  //     account_number: bankAccountDetails.accountNumber,
  //     account_holder_name: bankAccountDetails.accountHolder,
  //     account_holder_type: 'company',
  //     role: userDetails['custom:current_title'],
  //   })
  //   .then(function(result) {
  //     // Handle result.error or result.token
  //     setProcessing(false);
  //     console.log('result...', result);
  //     if(result.error){
  //       toast.dark(result.error.message);
  //       return;
  //     }
  //     else{
  //       dispatch(paymentActions.savePaymentDetails(result.token, subscription, (subscription === 'Monthly' ? monthlySubscriptionAmount : annualSubscriptionAmount)));
  //     }
  //   })
  //   .catch((err) => {
  //     console.log(err);
  //   });;
  // };

  // const reset = () => {
  //   setError(null);
  //   setCardComplete(false);
  //   setProcessing(false);
  //   setPaymentMethod(null);
  //   setBillingDetails({
  //     email: "",
  //     phone: "",
  //     name: ""
  //   });
  // };

  return (
    //   paymentMethod ? (
    //     <div className="Result">
    //       <div className="ResultTitle" role="alert">
    //         Payment successful
    //       </div>
    //       <div className="ResultMessage">
    //         Thanks for trying Stripe Elements. No money was charged, but we
    //         generated a PaymentMethod: {paymentMethod.id}
    //       </div>
    //       <ResetButton onClick={reset} />
    //     </div>
    //   ) :
    //   (
    <div>
      <Grid container style={{ marginTop: "150px", justifyContent: "center" }}>
        <Grid item style={{ marginRight: "2%", marginLeft: "2%" }}>
          <Typography variant="h4" style={{ marginBottom: "2%" }}>
            Set Up Payment
          </Typography>
          <Typography color="textSecondary" style={{ marginBottom: "2%" }}>
            You will not be billed until after your 1 month free trial has
            ended.
          </Typography>
          <Divider />
          <Typography
            style={{ marginBottom: "4%", marginTop: "10%" }}
            variant="h5"
          >
            Billing Frequency
          </Typography>
          <ToggleButtonGroup
            value={subscription}
            exclusive
            onChange={handleSubscription}
            aria-label="subscription-type"
            color="primary"
            style={{ marginBottom: "7%" }}
          >
            <ToggleButton
              value="Monthly"
              aria-label="monthly"
              disabled={subscription === "Monthly"}
            >
              MONTHLY
            </ToggleButton>
            <ToggleButton
              value="Annual"
              aria-label="annual"
              disabled={subscription === "Annual" || subscription === null}
            >
              ANNUAL
            </ToggleButton>
          </ToggleButtonGroup>
          <Typography
            variant="h4"
            style={{
              marginBottom: "1%",
              color: "#048475",
              fontWeight: "bold",
            }}
          >
            {`$${
              subscription === "Monthly"
                ? monthlySubscriptionAmount
                : annualSubscriptionAmount
            }`}
          </Typography>
          <Divider style={{ marginBottom: "1%" }} />
          <Typography color="textSecondary" style={{ marginBottom: "10%" }}>
            per user{" "}
            {subscription === "Monthly"
              ? "monthly"
              : "per month, if billed annually "}
          </Typography>
        </Grid>
      </Grid>

      <Grid container style={{ justifyContent: "center" }}>
        <Grid item xs={12} sm={6} md={6} lg={4} xl={4}>
          <AntTabs
            variant="scrollable"
            scrollButtons="auto"
            value={tabValue}
            onChange={handleTabChange}
            aria-label="ant example"
          >
            <AntTab label="Credit/Debit Card" />
            {/* <AntTab label='Bank Account' /> */}
          </AntTabs>
          {
            tabValue === 0 ? (
              <form className="Form" onSubmit={handleSubmit}>
                <fieldset className="FormGroup">
                  <CardNameField
                    label="Name"
                    id="name"
                    type="text"
                    placeholder="Cardholder name"
                    required
                    autoComplete="name"
                    value={billingDetails.name}
                    onChange={(e) => {
                      setBillingDetails({
                        ...billingDetails,
                        name: e.target.value,
                      });
                    }}
                    // pattern="^(\w\w+)\s(\w+)$"
                    title="Enter cardholder name."
                    // oninput="setCustomValidity('')"
                    // oninvalid="setCustomValidity('Please enter full name.')"
                  />
                  {/* <NameField
                label="Email"
                id="email"
                type="email"
                placeholder="janedoe@gmail.com"
                required
                autoComplete="email"
                value={billingDetails.email}
                onChange={(e) => {
                  setBillingDetails({ ...billingDetails, email: e.target.value });
                }}
              />
              <NameField
                label="Phone"
                id="phone"
                type="tel"
                placeholder="(941) 555-0123"
                required
                autoComplete="tel"
                value={billingDetails.phone}
                onChange={(e) => {
                  setBillingDetails({ ...billingDetails, phone: e.target.value });
                }}
              /> */}
                  {/* </fieldset>
            <fieldset className="FormGroup"> */}
                  <CardField
                    onChange={(e) => {
                      setError(e.error);
                      setCardComplete(e.complete);
                    }}
                  />
                </fieldset>
                {error && <ErrorMessage>{error.message}</ErrorMessage>}
                <div
                  style={{
                    display: "flex",
                    justifyContent: "left",
                    marginBottom: "3%",
                    marginLeft: "15px",
                  }}
                >
                  <LockIcon color="secondary" />
                  <Typography color="textSecondary">
                    This is a secure SSL encrypted payment.
                  </Typography>
                </div>
                {/* <Card style={{marginTop: '0%', marginLeft: '3%', marginRight: '3%', marginBottom: '3%', backgroundColor: '#E7E7E7', textAlign: 'left', padding: '3%', boxShadow: 'none'}}>
              <Typography variant='h6'>
              Want to add additional users?
              </Typography>
              <Typography color='textSecondary' style={{marginTop: '1%'}}>
              Once your account is approved, you will be able to add up to four additional users from the "My Account" tab.
              </Typography>
            </Card> */}
                {/* {error && <ErrorMessage>{error.message}</ErrorMessage>} */}
                <SubmitButton
                  processing={processing}
                  error={error}
                  disabled={!stripe}
                >
                  Continue
                </SubmitButton>
                {/* <Button variant='contained' color='secondary' onClick={reset}>Reset</Button> */}
              </form>
            ) : null
            //   <form className="Form" onSubmit={handleSubmitBankDetails}>
            //   <fieldset className="FormGroup">
            //     <AccountNameField
            //       label="Account Holder"
            //       id="accountHolder"
            //       type="text"
            //       placeholder="Account Holder's Full Name"
            //       required
            //       autoComplete="accountHolder"
            //       value={bankAccountDetails.accountHolder}
            //       onChange={(e) => {
            //         setBankAccountDetails({ ...bankAccountDetails, accountHolder: e.target.value });
            //       }}
            //       pattern="^(\w\w+)\s(\w+)$"
            //       title="Please enter full name."
            //     />
            //     <RoutingNumberField
            //       label="Routing Number"
            //       id="routingNumber"
            //       type="text"
            //       placeholder="Routing Number: 9 Digits"
            //       required
            //       autoComplete="routingNumber"
            //       value={bankAccountDetails.routingNumber}
            //       onChange={(e) => {
            //         setBankAccountDetails({ ...bankAccountDetails, routingNumber: e.target.value });
            //       }}
            //       pattern="^\d{9}$"
            //       title="Please enter 9 digits routing number."
            //     />
            //     <AccountNumberField
            //       label="Account Number"
            //       id="accountNumber"
            //       type="tel"
            //       placeholder="Account Number: 12 Digits"
            //       required
            //       autoComplete="tel"
            //       value={bankAccountDetails.accountNumber}
            //       onChange={(e) => {
            //         setBankAccountDetails({ ...bankAccountDetails, accountNumber: e.target.value });
            //       }}
            //       pattern="^\d{12}$"
            //       title="Please enter 12 digits account number."
            //     />
            //   </fieldset>
            //   {error && <ErrorMessage>{error.message}</ErrorMessage>}
            //   <div style={{display: 'flex', justifyContent: 'left', marginBottom: '3%', textAlign: 'left'}}>
            //     {/* <LockIcon color='secondary'></LockIcon> */}
            //     <Typography color='textSecondary' style={{ marginLeft: '3%', marginRight: '3%'}}>
            //       ACH payments take upto 5 business days to receive acknowledgement
            //       of success or failure. All payments are accepted in USD.
            //     </Typography>
            //   </div>
            //   <div style={{display: 'flex', justifyContent: 'left', marginBottom: '3%', marginLeft: '15px', textAlign: 'left', alignItems: 'flex-start'}}>
            //     {/* <LockIcon color='secondary'></LockIcon> */}
            //     <FormControlLabel
            //         control={<Checkbox style={{color: '#048475'}} checked={acceptTnC} onClick={event => {setAcceptTnc(!acceptTnC)}} />}
            //         label={<Typography style={{ textAlign: 'left', marginTop: '1%'}}>
            //       I authorize ACH Plugin to electronically debit my account and, if necessary, electronically credit my account to correct erroneous debits.
            //     </Typography>}
            //     style={{alignItems: 'flex-start'}}
            //     />

            //   </div>
            //   <Card style={{marginTop: '0%', marginLeft: '3%', marginRight: '3%', marginBottom: '3%', backgroundColor: '#E7E7E7', textAlign: 'left', padding: '3%', boxShadow: 'none'}}>
            //     <Typography variant='h6'>
            //       Want to add additional users?
            //     </Typography>
            //     <Typography color='textSecondary' style={{marginTop: '1%'}}>
            //       You'll be able to add up to 4 seats from your 'My Account' tab once you're in.
            //     </Typography>
            //   </Card>
            //   {/* {error && <ErrorMessage>{error.message}</ErrorMessage>} */}
            //   <SubmitButton processing={processing} error={error} disabled={!stripe || error || !acceptTnC}>
            //     Continue
            //   </SubmitButton>
            //   {/* <Button variant='contained' color='secondary' onClick={reset}>Reset</Button> */}
            // </form>
          }
          <Divider style={{ marginTop: "2%" }} />
          <Typography
            color="textSecondary"
            style={{ marginTop: "1%", marginBottom: "5%" }}
          >
            By clicking "Continue", you agree to our{" "}
            <Link
              href={config.terms_url}
              target="_blank"
              className="termOfUseLink"
            >
              Terms of Use, cancellation terms, and payment terms
            </Link>{" "}
            in which your payment will renew at the end of your billing cycle.
            You can cancel your account at any time by contacting our support
            team.
          </Typography>
        </Grid>
      </Grid>
      <div style={{ marginRight: "2%" }}>
        <GetHelp />
      </div>
    </div>
  );
}

// const ELEMENTS_OPTIONS = {
//   fonts: [
//     {
//       cssSrc: "https://fonts.googleapis.com/css?family=Roboto"
//     }
//   ]
// };

// Make sure to call `loadStripe` outside of a component’s render to avoid
// recreating the `Stripe` object on every render.
// const stripePromise = loadStripe("pk_test_6pRNASCoBOKtIshFeQd4XMUh");

export function Payment() {
  let dispatch = useDispatch();
  const decryptedStripeKey = useSelector(
    (state) => state.shared.decryptedStripeKey
  );
  const stripePromise = loadStripe(decryptedStripeKey);

  useEffect(() => {
    dispatch(getStripeKey());
  }, []);

  return (
    <Grid container>
      <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
        {/* <AppBar /> */}
        <Elements stripe={stripePromise}>
          <CheckoutForm />
        </Elements>
      </Grid>
    </Grid>
  );
}
