import { Grid, Typography, Divider } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { ViewMore } from "../core/viewmore";
import Logo from "../../assets/Logo_Mark.png";

const useStyles = makeStyles(() => ({
  mainGrid: {
    marginTop: "15px",
  },
  mainDiv: {
    size: "14px",
    fontWeight: 500,
    color: "#000000",
    display: "flex",
    alignItems: "center",
  },
  contentData: {
    color: "#666666",
    fontWeight: 400,
    marginTop: "5px",
    paddingLeft: "5px",
    textAlign: "justify",
    overflowX: "auto",
  },
  logoStyle: { background: "#FEF5DB", padding: "7px", borderRadius: "15px" },
  title: {
    marginLeft: "5px",
  },
  marginCustom: {
    marginTop: "20px",
    marginBottom: "20px",
  },
}));

function AdminNotes({ notesValue, isFullProfile }) {
  const classes = useStyles();
  const [noDataAvailable, setNoDataAvailable] = useState(true);
  useEffect(() => {
    checkData();
  }, []);

  const checkData = () => {
    notesValue?.status?.length !== 0 &&
      notesValue?.status?.map((value) =>
        (value?.label === "Recommended to Client" ||
          value?.label === "Suggested via Admin" ||
          value?.label === "Disqualified") &&
        value?.comment?.trim()?.length !== 0
          ? setNoDataAvailable(false)
          : ""
      );
  };

  return (
    <div>
      {notesValue?.status?.length !== 0 &&
        notesValue?.status?.map((value) =>
          (value?.label === "Recommended to Client" ||
            value?.label === "Suggested via Admin" ||
            value?.label === "Disqualified") &&
          value?.comment?.trim()?.length !== 0 ? (
            <Grid container>
              <Grid
                sm={12}
                md={12}
                xs={12}
                lg={12}
                xl={12}
                className={classes.mainGrid}
              >
                <div className={classes.mainDiv}>
                  <img className={classes.logoStyle} src={Logo} alt="logo" />
                  <span className={classes.title}>
                    <b>Talent Advisor Notes</b>
                  </span>
                </div>
                <Typography className={classes.contentData}>
                  <ViewMore length={150}>{value?.comment}</ViewMore>
                </Typography>
              </Grid>
              <Grid sm={12} md={12} xs={12} lg={12} xl={12}>
                <Divider className={classes.marginCustom} />
              </Grid>
            </Grid>
          ) : (
            ""
          )
        )}
      {noDataAvailable && isFullProfile && (
        <>
          <Divider className={classes.marginCustom} />
          <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
            <Typography align="center" color="secondary">
              No data available
            </Typography>
          </Grid>
        </>
      )}
    </div>
  );
}

export default AdminNotes;
