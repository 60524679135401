import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Grid, Divider, Typography } from "@material-ui/core";
import EmptyState from "../core/emptyState";
import { ViewMore } from "../core/viewmore";

const useStyles = makeStyles(() => ({
  dividerOne: {
    textAlign: "left",
    marginTop: "20px",
  },
  AccandComTopGrid: {
    textAlign: "left",
    marginTop: "20px",
  },
  bolderFont: {
    fontWeight: "bolder",
  },
  marginTop3: {
    marginTop: "3%",
  },
}));

function Accomplishments(props) {
  const EMPTY_ICON_HEIGHT = 120;
  const EMPTY_ICON_WIDTH = 120;
  const { accomplishment } = props;
  const classes = useStyles();

  let customAccomplishmentText = "";
  for (const item in accomplishment) {
    customAccomplishmentText += `<div><ul><li>${accomplishment[item]}</li></ul></div>`;
  }

  return (
    <Grid container>
      <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
        <Divider className={classes.dividerOne} />
      </Grid>
      <Grid
        item
        xs={12}
        sm={12}
        md={12}
        lg={12}
        xl={12}
        className={classes.AccandComTopGrid}
      >
        <Typography variant="h6" className={classes.bolderFont}>
          Accomplishments
        </Typography>
        <Divider className={classes.dividerOne} />
        {accomplishment?.length > 0 ? (
          <ViewMore direction="left">{customAccomplishmentText}</ViewMore>
        ) : (
          <Grid
            item
            xs={12}
            sm={12}
            md={12}
            lg={12}
            xl={12}
            className={classes.marginTop3}
          >
            <EmptyState
              type="accomplishments"
              height={EMPTY_ICON_HEIGHT}
              width={EMPTY_ICON_WIDTH}
            />
          </Grid>
        )}
      </Grid>
    </Grid>
  );
}

export default Accomplishments;
