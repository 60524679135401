import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import { Grid, Chip, Avatar, Divider, Link, Tooltip } from "@material-ui/core";
import DownloadIcon from "@material-ui/icons/GetApp";
import moment from "moment";
import Timeline from "@material-ui/lab/Timeline";
import TimelineItem from "@material-ui/lab/TimelineItem";
import TimelineSeparator from "@material-ui/lab/TimelineSeparator";
import TimelineConnector from "@material-ui/lab/TimelineConnector";
import TimelineOppositeContent from "@material-ui/lab/TimelineOppositeContent";
import TimelineDot from "@material-ui/lab/TimelineDot";
import FlagIcon from "@material-ui/icons/Flag";
import { getImgObjURL, openLinkInNewTab } from "../../../helpers";
import CertificationIcon from "../../../assets/certification.png";
import EducationIcon from "../../../assets/education.png";
import { ViewMore } from "../../core/viewmore";
import { EmployesLogo } from "../../core/employers.logo";
import EmptyState from "../../core/emptyState";

const useStyles = makeStyles((theme) => ({
  customLink: {
    color: "#ffffff",
    cursor: "pointer",
    textDecoration: "none",
    width: "90% !important",
    paddingLeft: "15px",
  },
  customLink2: {
    color: "#ffffff",
    cursor: "pointer",
    textDecoration: "none !important",
    padding: "10px 15px 10px 15px",
    border: "1px solid #ffffff",
    display: "flex",
    borderRadius: "5PX",
  },
  downloadBotton: {
    display: "flex",
    justifyContent: "end",
    paddingRight: "15px",
    [theme.breakpoints.down("md")]: {
      justifyContent: "left",
      paddingLeft: "15px",
      marginTop: "10px",
    },
  },
  expCompanyCardHead: { fontWeight: "bold", marginBottom: "2%" },
  expCompanyCard: {
    //   width: '100%',
    paddingTop: "16px",
    paddingBottom: "16px",
    textAlign: "left",
  },
  boxSizing: {
    boxSizing: "content-box",
  },
  mainGridList: {
    textAlign: "left",
    marginTop: "10px",
  },
  dividerOne: {
    textAlign: "left",
    marginTop: "20px",
  },
  marginTop20: {
    marginTop: "20px",
  },
  overviewsMainDiv: {
    marginTop: "20px",
    padding: "20px",
  },
  fontWeight400: {
    fontWeight: "400",
  },
  bolderFont: {
    fontWeight: "bolder",
  },
  compiTopDivider: {
    borderBottom: "1px solid #D7D9DB",
    width: "inherit",
    marginTop: "3%",
    flexShrink: 1,
  },
  compiBotDivider: {
    borderBottom: "1px solid #D7D9DB",
    width: "inherit",
    marginTop: "3%",
    marginBottom: "3%",
    flexShrink: 1,
  },
  chipStyle: {
    marginRight: "8px",
    marginBottom: "8px",
    textIndent: "0",
    overflowY: "auto",
    height: "auto",
    padding: "8px",
    marginTop: "8px",
    backgroundColor: "#FBC112",
    color: "#000000",
    marginLeft: "0px",
  },
  AccandComTopGrid: {
    textAlign: "left",
    marginTop: "20px",
  },
  headingGrid: {
    textAlign: "left",
    fontWeight: "bolder",
  },
  marginTop3: {
    marginTop: "3%",
  },
  dividerTopTab: {
    marginTop: "15px",
    marginBottom: "25px",
  },
  fontSize16: {
    fontSize: "16px",
  },
  padding20: {
    padding: "20px",
  },
  experienceTopGrid: {
    display: "flex",
    position: "relative",
  },
  avatarFlag: {
    border: "1.5px solid #fcfaf5",
    height: "62px",
    width: "62px",
    "&:hover": {
      "-webkit-transform": "scale(1.5)",
      "-ms-transform": "scale(1.5)",
      transform: "scale(1.5)",
    },
    transition: "transform .5s",
    cursor: "pointer",
  },
  falgIconStyle: {
    color: "#FBC112",
    cursor: "pointer",
    height: "30px",
  },
  flagAva: {
    border: "1.5px solid #fcfaf5",
    height: "62px",
    width: "62px",
    padding: "15px",
    background: "#F4F4F5",
  },
  timelineStyle: {
    padding: "0px",
    margin: "0px",
    alignItems: "center",
  },
  timelineItemStyle: {
    height: "calc(100% - 24px)",
  },
  timelineDotStyle: {
    boxShadow: "none",
    backgroundColor: "#048475",
    marginTop: "12px",
  },
  listStyleNone: {
    listStyle: "none",
  },
  timelineDotVisibile: {
    boxShadow: "none",
    backgroundColor: "#048475",
    marginTop: "12px",
    visibility: "hidden",
  },
  timelineContentStyle: {
    padding: "3px 13px 0px 0px",
    width: "130px",
  },
  TimelineConnectorStyle: {
    height: "inherit",
    backgroundColor: "#048475",
  },
  headingText: {
    position: "absolute",
    right: 55,
    top: 17,
  },
  minHeight0: {
    minHeight: "0px",
  },
  textAlignLeftCustom: {
    textAlign: "left",
  },
  professHeading: {
    position: "absolute",
    right: 55,
    top: 17,
  },
  divDegree: {
    textAlign: "left",
    fontWeight: "bold",
  },
  resumeHeader: {
    background: "#59575A",
    padding: "10px 0px 10px 0px",
  },
  resumeTitleGrid: {
    textAlign: "left",
    alignSelf: "center",
  },
  accomplishmentColor: {
    color: "#14131F",
  },
  mediumTopMargin: {
    marginTop: "8px",
  },
  resumeTopDivider: {
    marginTop: "15px",
    marginBottom: "25px",
  },
  marginRight10: {
    marginRight: "10px",
  },
  certifName: {
    marginTop: "8px",
    fontWeight: "bold",
  },
  certifaDate: {
    textAlign: "left",
    marginTop: "8px",
  },
  noPadding: {
    padding: "0px",
  },
  marginBottom30: {
    marginBottom: "40px",
  },
  customFlex: {
    display: "flex",
  },
  timelineGrid: {
    paddingLeft: "25px",
    marginTop: "0px !important",
  },
  paddingLeft42: {
    paddingLeft: "42px",
    paddingTop: 0,
  },
  fontWeight700: {
    fontWeight: 700,
  },
  gapTopGrid: {
    paddingLeft: "18px",
    display: "flex",
    alignItems: "center",
  },
  customFontBold: {
    fontWeight: "bold",
  },
  paddingLeft15: {
    paddingLeft: "15px",
    display: "flex",
    alignItems: "center",
  },
  marginDivider: {
    marginBottom: "10px",
  },
  marginLeftDot: {
    marginLeft: "2px",
  },
  gapDivider: {
    marginTop: "20px",
    marginBottom: "20px",
  },
  paddingMedium: {
    paddingLeft: "15px",
  },
  root: {
    paddingRight: "24px",
    marginTop: "1.5%",
    marginBottom: "2.5%",
  },
  title: {
    fontWeight: "bold",
  },
  icon: {
    margin: "0px 10px 5px 0px",
  },
  logoArrayRoot: {
    // dirty hack because the content is scrolling
    paddingRight: "30px",
    display: "flex",
    // overflow: "scroll",
    justifyContent: "flex-start",
    alignItems: "center",
  },
  companyAvatar: {
    border: "2.5px solid #fcfaf5",
    "&:hover": {
      "-webkit-transform": "scale(1.4)",
      "-ms-transform": "scale(1.4)",
      transform: "scale(1.2)",
    },
    transition: "transform .5s",
  },
  marginLeft0: {
    marginLeft: "0px",
  },
  marginLeft15: {
    marginTop: "5px",
    marginRight: "15px",
  },
  marginTopNone: {
    marginTop: 0,
  },
}));

export default function CustomizedAccordions(props) {
  const {
    customAccomplishmentText,
    type,
    coffeQuestion,
    experience,
    education,
    certification,
    resume,
    firstName,
    lastName,
    boardAdvisorExperience,
  } = props;
  const [ResumeType, setResumeType] = React.useState(null);
  const [filteredCompaniesLogoArray, setFilteredCompaniesLogoArray] = useState(
    []
  );

  const classes = useStyles();
  let countExp = 0;
  const countEdu = 0;

  experience?.map((value) => {
    if (value.experience_type !== "3") {
      countExp += 1;
    }
    return countExp;
  });

  const capitalize = function (str) {
    if (str !== null && str !== undefined) {
      return str
        .split(" ")
        .map((w) => w.substring(0, 1).toUpperCase() + w.substring(1))
        .join(" ");
    }
    return str;
  };

  useEffect(() => {
    const ext = resume?.split(".")?.pop();
    if (ext === "pdf") {
      setResumeType(ext);
    } else {
      setResumeType(ext);
    }
  }, [resume]);

  const removeDuplicateLogoEntries = (logoInfoObjArray) => {
    const logoArray = [];
    const len = logoInfoObjArray?.length;
    if (len < 1) return logoArray;
    for (let index = 0; index < len; index++) {
      const company = logoInfoObjArray[index];
      const {
        company_logo,
        industry,
        company_size,
        domain,
        other_industry_text,
      } = company;
      if (
        company_logo &&
        !arrayIncludesKeyVal(logoArray, "company_logo", company_logo)
      ) {
        logoArray.push({
          company_logo,
          industry,
          company_size,
          domain,
          other_industry_text,
        });
      }
    }
    return logoArray;
  };

  const arrayIncludesKeyVal = (arrOfObj, key, value) => {
    const len = arrOfObj.length;
    for (let index = 0; index < len; index++) {
      const element = arrOfObj[index];
      if (element[key] === value) return true;
    }
    return false;
  };

  const EMPTY_ICON_HEIGHT = 120;
  const EMPTY_ICON_WIDTH = 120;

  useEffect(() => {
    const filterLogoArray = async () => {
      const companiesLogoArray = removeDuplicateLogoEntries(
        props?.data?.logo_info
      );
      // identify if blob is png
      const logoArray = [];
      const len = companiesLogoArray?.length ?? 0;
      for (let i = 0; i < len; i++) {
        const e = companiesLogoArray[i];
        const {
          company_size,
          company_logo,
          industry,
          domain,
          other_industry_text,
        } = e;
        if (company_logo) {
          const imageObjUrl = await getImgObjURL(company_logo);
          if (imageObjUrl) {
            const obj = {
              company_size,
              imageObjUrl: company_logo,
              industry,
              domain,
              other_industry_text,
            };
            logoArray.push(obj);
          }
        }
      }
      setFilteredCompaniesLogoArray(logoArray);
    };
    filterLogoArray();
    //  eslint-disable-next-line
  }, [props?.data?.logo_info]);
  return (
    <div className={classes.marginTop20}>
      {type === "overviews" ? (
        <div className={classes.overviewsMainDiv}>
          <Grid container spacing={2}>
            <Grid
              item
              xs={6}
              sm={6}
              md={6}
              lg={6}
              xl={6}
              className={classes.mainGridList}
            >
              <Typography
                variant="body2"
                color="textSecondary"
                className={classes.fontWeight400}
              >
                Industries
              </Typography>
              <Tooltip
                arrow
                placement="bottom"
                title={
                  props?.data?.industries?.length > 0
                    ? props?.data?.industries?.join(", ")
                    : ""
                }
              >
                <Typography>
                  {props?.data?.industries.length > 0
                    ? props?.data?.industries?.join(", ")
                    : "-"}
                </Typography>
              </Tooltip>
            </Grid>
            <Grid
              item
              xs={6}
              sm={6}
              md={5}
              lg={6}
              xl={6}
              className={classes.mainGridList}
            >
              <Typography variant="body2" color="textSecondary">
                Highest Level Held
              </Typography>
              <Typography variant="body1">
                {props.data?.company?.level ? props.data?.company?.level : "-"}
              </Typography>
            </Grid>
            <Grid
              item
              xs={6}
              sm={6}
              md={6}
              lg={6}
              xl={6}
              className={classes.mainGridList}
            >
              <Typography variant="body2" color="textSecondary">
                Total Years of Experience
              </Typography>
              <Typography variant="body">
                {props?.data?.experience || "-"}
              </Typography>
            </Grid>
            <Grid
              item
              xs={6}
              sm={6}
              md={6}
              lg={6}
              xl={6}
              className={classes.mainGridList}
            >
              <Typography variant="body2" color="textSecondary">
                Largest Team Size Managed
              </Typography>
              <Typography variant="body1">
                {props?.data?.company?.team_managed || "-"}
              </Typography>
            </Grid>
            <Grid
              item
              xs={6}
              sm={6}
              md={6}
              lg={6}
              xl={6}
              className={classes.mainGridList}
            >
              <Typography variant="body2" color="textSecondary">
                Countries Worked In
              </Typography>
              {props.data?.company?.map ? (
                <ViewMore length="200">{props.data?.company?.map}</ViewMore>
              ) : (
                "-"
              )}
            </Grid>
            <Grid
              item
              xs={6}
              sm={6}
              md={6}
              lg={6}
              xl={6}
              className={classes.mainGridList}
            >
              <Typography align="left" variant="body2" color="textSecondary">
                Employers
              </Typography>
              <EmployesLogo logoArray={filteredCompaniesLogoArray} />
            </Grid>
            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
              <Divider className={classes.dividerOne} />
            </Grid>
            <Grid
              item
              xs={12}
              sm={12}
              md={12}
              lg={12}
              xl={12}
              className={classes.AccandComTopGrid}
            >
              <Typography variant="h6" className={classes.bolderFont}>
                Accomplishments
              </Typography>
              <Divider className={classes.dividerOne} />
              {props?.data?.accomplishment.length > 0 ? (
                <ViewMore direction="left">{customAccomplishmentText}</ViewMore>
              ) : (
                <Grid
                  item
                  xs={12}
                  sm={12}
                  md={12}
                  lg={12}
                  xl={12}
                  className={classes.marginTop3}
                >
                  <EmptyState
                    type="accomplishments"
                    height={EMPTY_ICON_HEIGHT}
                    width={EMPTY_ICON_WIDTH}
                  />
                </Grid>
              )}
            </Grid>
            <Divider className={classes.compiTopDivider} />
            <Grid
              item
              xs={12}
              sm={12}
              md={12}
              lg={12}
              xl={12}
              className={classes.AccandComTopGrid}
            >
              <Typography variant="h6" className={classes.bolderFont}>
                Competencies
              </Typography>
              <Divider className={classes.compiBotDivider} />
              {props?.data?.skills.length > 0 ? (
                props.data?.skills.map((skill, index) => (
                  <Chip
                    className={classes.chipStyle}
                    key={index}
                    label={<ViewMore length="200">{skill}</ViewMore>}
                    title={skill}
                  />
                ))
              ) : (
                <EmptyState
                  type="competencies"
                  height={EMPTY_ICON_HEIGHT}
                  width={EMPTY_ICON_WIDTH}
                />
              )}
            </Grid>
          </Grid>
        </div>
      ) : (
        ""
      )}

      {/* Coffe Question */}
      {type === "coffee" ? (
        <div className={classes.overviewsMainDiv}>
          <Typography variant="h6" className={classes.headingGrid}>
            Coffee Questions
          </Typography>
          <Divider className={classes.dividerTopTab} />
          <Grid container>
            {coffeQuestion?.length !== 0 ? (
              coffeQuestion?.map((qa, index) => (
                <>
                  <Grid
                    item
                    xs={2}
                    sm={2}
                    md={1}
                    xl={1}
                    lg={1}
                    style={{ marginTop: index !== 0 ? "35px" : "25px" }}
                  >
                    <Typography variant="body1">{index + 1}.</Typography>
                  </Grid>
                  <Grid
                    item
                    xs={10}
                    sm={10}
                    md={11}
                    xl={11}
                    lg={11}
                    style={{ marginTop: index !== 0 ? "35px" : "25px" }}
                  >
                    <Typography align="left">
                      <div>
                        <strong>{qa.question}</strong>
                      </div>
                    </Typography>
                    <div align="left">
                      <ViewMore
                        className={classes.fontSize16}
                        length="200"
                        expanded="true"
                      >
                        {qa?.option
                          ? `${qa?.option} - ${qa?.answer}`
                          : qa.answer}
                      </ViewMore>
                    </div>
                  </Grid>
                </>
              ))
            ) : (
              <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                <EmptyState
                  type="coffeequestions"
                  height={EMPTY_ICON_HEIGHT}
                  width={EMPTY_ICON_WIDTH}
                />
              </Grid>
            )}
          </Grid>
        </div>
      ) : (
        ""
      )}

      {experience && (
        <>
          <div className={classes.padding20}>
            <Typography variant="h6" className={classes.headingGrid}>
              Experiences
            </Typography>
            <Divider className={classes.dividerTopTab} />
            {experience.length === 0 ? (
              <EmptyState
                type="experience"
                height={EMPTY_ICON_HEIGHT}
                width={EMPTY_ICON_WIDTH}
              />
            ) : (
              <Grid container>
                {experience?.map((companyExp, ind) => (
                  <>
                    {companyExp[0].experience_type === "0" ? (
                      <>
                        {companyExp?.length === 1 ? (
                          <Grid container>
                            <Grid
                              item
                              xs={12}
                              sm={12}
                              md={12}
                              lg={12}
                              xl={12}
                              className={classes.gapTopGrid}
                            >
                              <span>
                                <Tooltip
                                  title="Employment Gap"
                                  aria-label="Gap"
                                >
                                  <Avatar
                                    variant="square"
                                    className={classes.flagAva}
                                  >
                                    <FlagIcon
                                      className={classes.falgIconStyle}
                                    />
                                  </Avatar>
                                </Tooltip>
                              </span>
                              <span>
                                <ul className={classes.paddingMedium}>
                                  <li className={classes.listStyleNone}>
                                    <Tooltip
                                      title="Title"
                                      arrow
                                      placement="left"
                                    >
                                      <Typography
                                        component="div"
                                        align="left"
                                        variant="h6"
                                        className={classes.customFontBold}
                                      >
                                        {capitalize(companyExp[0]?.title)}
                                      </Typography>
                                    </Tooltip>
                                    <Tooltip
                                      title="Reason"
                                      arrow
                                      placement="left"
                                    >
                                      <Typography
                                        align="left"
                                        variant="body2"
                                        className={classes.fontWeight700}
                                      >
                                        {companyExp[0]?.reason}
                                      </Typography>
                                    </Tooltip>
                                    <Typography
                                      color="textSecondary"
                                      align="left"
                                    >
                                      Total Gap - {companyExp[0]?.experience}
                                    </Typography>
                                  </li>
                                </ul>
                              </span>
                            </Grid>
                            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                              <Divider className={classes.gapDivider} />
                            </Grid>
                          </Grid>
                        ) : (
                          companyExp?.map((value) => (
                            <Grid container>
                              <Grid
                                item
                                xs={12}
                                sm={12}
                                md={12}
                                lg={12}
                                xl={12}
                                className={classes.gapTopGrid}
                              >
                                <span>
                                  <Tooltip
                                    title="Employment Gap"
                                    aria-label="Gap"
                                  >
                                    <Avatar
                                      variant="square"
                                      className={classes.flagAva}
                                    >
                                      <FlagIcon
                                        className={classes.falgIconStyle}
                                      />
                                    </Avatar>
                                  </Tooltip>
                                </span>
                                <span>
                                  <ul className={classes.paddingMedium}>
                                    <li className={classes.listStyleNone}>
                                      <Tooltip
                                        title="Title"
                                        arrow
                                        placement="left"
                                      >
                                        <Typography
                                          component="div"
                                          align="left"
                                          variant="h6"
                                          className={classes.customFontBold}
                                        >
                                          {capitalize(value?.title)}
                                        </Typography>
                                      </Tooltip>
                                      <Tooltip
                                        title="Reason"
                                        arrow
                                        placement="left"
                                      >
                                        <Typography
                                          align="left"
                                          variant="body2"
                                          className={classes.fontWeight700}
                                        >
                                          {value?.reason}
                                        </Typography>
                                      </Tooltip>
                                      <Typography
                                        color="textSecondary"
                                        align="left"
                                      >
                                        Total Gap - {value?.experience}
                                      </Typography>
                                    </li>
                                  </ul>
                                </span>
                              </Grid>
                              <Grid
                                item
                                xs={12}
                                sm={12}
                                md={12}
                                lg={12}
                                xl={12}
                              >
                                <Divider className={classes.gapDivider} />
                              </Grid>
                            </Grid>
                          ))
                        )}
                      </>
                    ) : (
                      <Grid
                        item
                        xs={12}
                        sm={12}
                        md={12}
                        lg={12}
                        xl={12}
                        className={classes.paddingLeft15}
                      >
                        <span>
                          <Tooltip
                            title={
                              companyExp[0]?.domain ? (
                                <>
                                  {companyExp[0]?.domain ? (
                                    <Typography>
                                      Domain: {companyExp[0]?.domain}
                                    </Typography>
                                  ) : (
                                    ""
                                  )}
                                </>
                              ) : (
                                ""
                              )
                            }
                          >
                            <Avatar
                              src={companyExp[0]?.logo || "/"}
                              alt={companyExp[0]?.company_name}
                              variant="square"
                              onClick={() => {
                                openLinkInNewTab(companyExp[0]?.domain);
                              }}
                              className={classes.avatarFlag}
                            />
                          </Tooltip>
                        </span>
                        <span>
                          <ul className={classes.paddingMedium}>
                            <li className={classes.listStyleNone}>
                              <Tooltip title="Company" arrow placement="left">
                                <Typography
                                  component="div"
                                  align="left"
                                  variant="h6"
                                  className={classes.customFontBold}
                                >
                                  {capitalize(companyExp[0]?.company_name)}
                                </Typography>
                              </Tooltip>
                              <div className={classes.customFlex}>
                                <Typography
                                  align="left"
                                  variant="body2"
                                  className={classes.fontWeight700}
                                >
                                  {companyExp[0]?.company_size
                                    ? `${companyExp[0]?.company_size}  Employees`
                                    : " "}
                                  {companyExp[0]?.company_size &&
                                  companyExp[0]?.team_managed
                                    ? " , "
                                    : ""}
                                  {companyExp[0]?.team_managed
                                    ? ` Team Size Managed : ${companyExp[0]?.team_managed}`
                                    : ""}
                                </Typography>
                                <Tooltip
                                  title="Location"
                                  arrow
                                  placement="left"
                                >
                                  <Typography
                                    align="left"
                                    variant="body2"
                                    className={classes.fontWeight700}
                                  >
                                    {(companyExp[0]?.team_managed ||
                                      companyExp[0]?.company_size) &&
                                    (companyExp[0]?.location?.city ||
                                      companyExp[0]?.location?.state ||
                                      companyExp[0]?.location?.country) ? (
                                      <span className={classes.marginLeftDot}>
                                        {" "}
                                        .{" "}
                                      </span>
                                    ) : null}
                                    {companyExp[0]?.location?.city
                                      ? companyExp[0]?.location?.city
                                      : ""}
                                    {companyExp[0]?.location?.city &&
                                    companyExp[0]?.location?.state
                                      ? ", "
                                      : null}
                                    {companyExp[0]?.location?.state
                                      ? companyExp[0]?.location?.state
                                      : ""}
                                    {companyExp[0]?.location?.country &&
                                    companyExp[0]?.location?.state
                                      ? ", "
                                      : null}
                                    {companyExp[0]?.location?.country
                                      ? companyExp[0]?.location?.country
                                      : ""}
                                  </Typography>
                                </Tooltip>
                              </div>
                              <Typography align="left" color="textSecondary">
                                Total Years of Experience -{" "}
                                {companyExp[0]?.total_experience}
                              </Typography>
                            </li>
                          </ul>
                        </span>
                      </Grid>
                    )}
                    {companyExp[0].experience_type !== "0" && (
                      <Grid
                        item
                        xs={12}
                        sm={12}
                        md={12}
                        lg={12}
                        xl={12}
                        className={classes.timelineGrid}
                      >
                        {companyExp.length === 1 && (
                          <Timeline
                            align="left"
                            className={classes.marginTopNone}
                          >
                            <TimelineItem>
                              <TimelineDot
                                className={classes.timelineDotVisibile}
                              />
                              <TimelineOppositeContent
                                className={classes.paddingLeft42}
                              >
                                {companyExp.length === 1 &&
                                  companyExp.map((exp) => (
                                    <>
                                      <div className={classes.customFlex}>
                                        <Tooltip
                                          title="Title"
                                          arrow
                                          placement="left"
                                        >
                                          <Typography
                                            align="left"
                                            variant="body2"
                                            component="span"
                                            className={classes.fontWeight700}
                                          >
                                            {capitalize(exp?.title)}
                                          </Typography>
                                        </Tooltip>
                                        <Tooltip
                                          title="Industries"
                                          arrow
                                          placement="left"
                                        >
                                          <Typography
                                            align="left"
                                            variant="body2"
                                            component="span"
                                            className={classes.fontWeight700}
                                          >
                                            {exp?.industry
                                              ? `, ${
                                                  exp?.industry === "Other"
                                                    ? exp?.other_industry_text
                                                    : capitalize(exp?.industry)
                                                }`
                                              : null}
                                          </Typography>
                                        </Tooltip>
                                      </div>
                                      <Tooltip
                                        title="Duration"
                                        arrow
                                        placement="left"
                                      >
                                        <Typography
                                          align="left"
                                          color="textSecondary"
                                        >
                                          {" "}
                                          {exp?.starts_at === null
                                            ? ""
                                            : moment(exp?.starts_at).format(
                                                "MMMM YYYY"
                                              )}{" "}
                                          -{" "}
                                          {exp?.ends_at === "Present"
                                            ? "Present"
                                            : exp?.ends_at === null
                                            ? ""
                                            : moment(exp?.ends_at).format(
                                                "MMMM YYYY"
                                              )}{" "}
                                        </Typography>
                                      </Tooltip>
                                      <Tooltip
                                        title="Description"
                                        arrow
                                        placement="left"
                                      >
                                        <Typography
                                          align="left"
                                          className={
                                            classes.accomplishmentColor
                                          }
                                        >
                                          <span
                                            dangerouslySetInnerHTML={{
                                              __html: exp?.description
                                                ? exp?.description
                                                : "",
                                            }}
                                          />
                                        </Typography>
                                      </Tooltip>
                                    </>
                                  ))}
                              </TimelineOppositeContent>
                            </TimelineItem>
                          </Timeline>
                        )}
                        {companyExp.length > 1 && (
                          <Timeline
                            align="right"
                            className={classes.marginTopNone}
                          >
                            {companyExp.map((exp, ind) => (
                              <TimelineItem>
                                <TimelineOppositeContent
                                  className={classes.paddingLeft42}
                                >
                                  <div className={classes.customFlex}>
                                    <Tooltip
                                      title="Title"
                                      arrow
                                      placement="left"
                                    >
                                      <Typography
                                        align="left"
                                        variant="body2"
                                        component="span"
                                        className={classes.fontWeight700}
                                      >
                                        {capitalize(exp?.title)}
                                      </Typography>
                                    </Tooltip>
                                    <Tooltip
                                      title="Industries"
                                      arrow
                                      placement="left"
                                    >
                                      <Typography
                                        align="left"
                                        variant="body2"
                                        component="span"
                                        className={classes.fontWeight700}
                                      >
                                        {exp?.industry
                                          ? `, ${
                                              exp?.industry === "Other"
                                                ? exp?.other_industry_text
                                                : capitalize(exp?.industry)
                                            }`
                                          : null}
                                      </Typography>
                                    </Tooltip>
                                  </div>
                                  <div className={classes.customFlex}>
                                    <Tooltip
                                      title="Duration"
                                      arrow
                                      placement="left"
                                    >
                                      <Typography
                                        color="textSecondary"
                                        align="left"
                                      >
                                        {exp?.starts_at === null
                                          ? ""
                                          : moment(exp?.starts_at).format(
                                              "MMMM YYYY"
                                            )}{" "}
                                        -{" "}
                                        {exp?.ends_at === "Present"
                                          ? "Present"
                                          : exp?.ends_at === null
                                          ? ""
                                          : moment(exp?.ends_at).format(
                                              "MMMM YYYY"
                                            )}{" "}
                                      </Typography>
                                    </Tooltip>
                                    <Tooltip
                                      title="Years of Experience"
                                      arrow
                                      placement="right"
                                    >
                                      <Typography
                                        color="textSecondary"
                                        align="left"
                                      >
                                        {exp?.experience
                                          ? `, ${exp?.experience}`
                                          : ""}
                                      </Typography>
                                    </Tooltip>
                                  </div>
                                  <Tooltip
                                    title="Description"
                                    arrow
                                    placement="left"
                                  >
                                    <Typography
                                      align="left"
                                      className={classes.accomplishmentColor}
                                    >
                                      <span
                                        dangerouslySetInnerHTML={{
                                          __html: exp?.description
                                            ? exp?.description
                                            : "",
                                        }}
                                      />
                                    </Typography>
                                  </Tooltip>
                                </TimelineOppositeContent>
                                <TimelineSeparator>
                                  <TimelineDot
                                    className={classes.timelineDotStyle}
                                  />
                                  {companyExp?.length !== ind + 1 ? (
                                    <TimelineConnector
                                      className={classes.TimelineConnectorStyle}
                                    />
                                  ) : null}
                                </TimelineSeparator>
                              </TimelineItem>
                            ))}
                          </Timeline>
                        )}
                        {experience.length !== ind + 1 && (
                          <Divider className={classes.marginDivider} />
                        )}
                      </Grid>
                    )}
                  </>
                ))}
              </Grid>
            )}
          </div>
          <div className={classes.padding20}>
            <Typography variant="h6" className={classes.headingGrid}>
              Board Advisor Role/Volunteer Experiences
            </Typography>
            <Divider className={classes.dividerTopTab} />
            {boardAdvisorExperience.length === 0 ? (
              <EmptyState
                type="boradAdvisorVolunteer"
                height={EMPTY_ICON_HEIGHT}
                width={EMPTY_ICON_WIDTH}
              />
            ) : (
              <Grid container>
                {boardAdvisorExperience?.map((companyExp, ind) => (
                  <>
                    <Grid
                      item
                      xs={12}
                      sm={12}
                      md={12}
                      lg={12}
                      xl={12}
                      className={classes.paddingLeft15}
                    >
                      <span>
                        <Tooltip
                          title={
                            companyExp[0]?.domain ? (
                              <>
                                {companyExp[0]?.domain ? (
                                  <Typography>
                                    Domain: {companyExp[0]?.domain}
                                  </Typography>
                                ) : (
                                  ""
                                )}
                              </>
                            ) : (
                              ""
                            )
                          }
                        >
                          <Avatar
                            src={companyExp[0]?.logo || "/"}
                            alt={companyExp[0]?.company_name}
                            variant="square"
                            onClick={() => {
                              openLinkInNewTab(companyExp[0]?.domain);
                            }}
                            className={classes.avatarFlag}
                          />
                        </Tooltip>
                      </span>
                      <span>
                        <ul className={classes.paddingMedium}>
                          <li className={classes.listStyleNone}>
                            <Tooltip title="Company" arrow placement="left">
                              <Typography
                                component="div"
                                align="left"
                                variant="h6"
                                className={classes.customFontBold}
                              >
                                {capitalize(companyExp[0]?.company_name)}
                              </Typography>
                            </Tooltip>
                            <div className={classes.customFlex}>
                              <Typography
                                align="left"
                                variant="body2"
                                className={classes.fontWeight700}
                              >
                                {companyExp[0]?.company_size
                                  ? `${companyExp[0]?.company_size}  Employees`
                                  : " "}
                                {companyExp[0]?.company_size &&
                                companyExp[0]?.team_managed
                                  ? " , "
                                  : ""}
                                {companyExp[0]?.team_managed
                                  ? ` Team Size Managed : ${companyExp[0]?.team_managed}`
                                  : ""}
                              </Typography>
                              <Tooltip title="Location" arrow placement="left">
                                <Typography
                                  align="left"
                                  variant="body2"
                                  className={classes.fontWeight700}
                                >
                                  {(companyExp[0]?.team_managed ||
                                    companyExp[0]?.company_size) &&
                                  (companyExp[0]?.location?.city ||
                                    companyExp[0]?.location?.state ||
                                    companyExp[0]?.location?.country) ? (
                                    <span className={classes.marginLeftDot}>
                                      {" "}
                                      .{" "}
                                    </span>
                                  ) : null}
                                  {companyExp[0]?.location?.city
                                    ? companyExp[0]?.location?.city
                                    : ""}
                                  {companyExp[0]?.location?.city &&
                                  companyExp[0]?.location?.state
                                    ? ", "
                                    : null}
                                  {companyExp[0]?.location?.state
                                    ? companyExp[0]?.location?.state
                                    : ""}
                                  {companyExp[0]?.location?.country &&
                                  companyExp[0]?.location?.state
                                    ? ", "
                                    : null}
                                  {companyExp[0]?.location?.country
                                    ? companyExp[0]?.location?.country
                                    : ""}
                                </Typography>
                              </Tooltip>
                            </div>
                            <Typography align="left" color="textSecondary">
                              Total Years of Experience -{" "}
                              {companyExp[0]?.total_experience}
                            </Typography>
                          </li>
                        </ul>
                      </span>
                    </Grid>
                    <Grid
                      item
                      xs={12}
                      sm={12}
                      md={12}
                      lg={12}
                      xl={12}
                      className={classes.timelineGrid}
                    >
                      {companyExp.length === 1 && (
                        <Timeline
                          align="left"
                          className={classes.marginTopNone}
                        >
                          <TimelineItem>
                            <TimelineDot
                              className={classes.timelineDotVisibile}
                            />
                            <TimelineOppositeContent
                              className={classes.paddingLeft42}
                            >
                              {companyExp.length === 1 &&
                                companyExp.map((exp) => (
                                  <>
                                    <div className={classes.customFlex}>
                                      <Tooltip
                                        title="Title"
                                        arrow
                                        placement="left"
                                      >
                                        <Typography
                                          align="left"
                                          variant="body2"
                                          component="span"
                                          className={classes.fontWeight700}
                                        >
                                          {capitalize(exp?.title)}
                                        </Typography>
                                      </Tooltip>
                                      <Tooltip
                                        title="Industries"
                                        arrow
                                        placement="left"
                                      >
                                        <Typography
                                          align="left"
                                          variant="body2"
                                          component="span"
                                          className={classes.fontWeight700}
                                        >
                                          {exp?.industry
                                            ? `, ${
                                                exp?.industry === "Other"
                                                  ? exp?.other_industry_text
                                                  : capitalize(exp?.industry)
                                              }`
                                            : null}
                                        </Typography>
                                      </Tooltip>
                                    </div>
                                    <Tooltip
                                      title="Duration"
                                      arrow
                                      placement="left"
                                    >
                                      <Typography
                                        color="textSecondary"
                                        align="left"
                                      >
                                        {exp?.starts_at === null
                                          ? ""
                                          : moment(exp?.starts_at).format(
                                              "MMMM YYYY"
                                            )}{" "}
                                        -{" "}
                                        {exp?.ends_at === "Present"
                                          ? "Present"
                                          : exp?.ends_at === null
                                          ? ""
                                          : moment(exp?.ends_at).format(
                                              "MMMM YYYY"
                                            )}
                                      </Typography>
                                    </Tooltip>
                                    <Tooltip
                                      title="Description"
                                      arrow
                                      placement="left"
                                    >
                                      <Typography
                                        align="left"
                                        className={classes.accomplishmentColor}
                                      >
                                        <span
                                          dangerouslySetInnerHTML={{
                                            __html: exp?.description
                                              ? exp?.description
                                              : "",
                                          }}
                                        />
                                      </Typography>
                                    </Tooltip>
                                  </>
                                ))}
                            </TimelineOppositeContent>
                          </TimelineItem>
                        </Timeline>
                      )}
                      {companyExp.length > 1 && (
                        <Timeline
                          align="right"
                          className={classes.marginTopNone}
                        >
                          {companyExp.map((exp, ind) => (
                            <TimelineItem>
                              <TimelineOppositeContent
                                className={classes.paddingLeft42}
                              >
                                <div sclassName={classes.customFlex}>
                                  <Tooltip title="Title" arrow placement="left">
                                    <Typography
                                      align="left"
                                      variant="body2"
                                      component="span"
                                      className={classes.fontWeight700}
                                    >
                                      {capitalize(exp?.title)}
                                    </Typography>
                                  </Tooltip>
                                  <Tooltip
                                    title="Industries"
                                    component="span"
                                    arrow
                                    placement="left"
                                  >
                                    <Typography
                                      align="left"
                                      variant="body2"
                                      className={classes.fontWeight700}
                                    >
                                      {exp?.industry
                                        ? `, ${
                                            exp?.industry === "Other"
                                              ? exp?.other_industry_text
                                              : capitalize(exp?.industry)
                                          }`
                                        : null}
                                    </Typography>
                                  </Tooltip>
                                </div>
                                <div className={classes.customFlex}>
                                  <Tooltip
                                    title="Duration"
                                    arrow
                                    placement="left"
                                  >
                                    <Typography
                                      color="textSecondary"
                                      align="left"
                                    >
                                      {exp?.starts_at === null
                                        ? ""
                                        : moment(exp?.starts_at).format(
                                            "MMMM YYYY"
                                          )}{" "}
                                      -{" "}
                                      {exp?.ends_at === "Present"
                                        ? "Present"
                                        : exp?.ends_at === null
                                        ? ""
                                        : moment(exp?.ends_at).format(
                                            "MMMM YYYY"
                                          )}
                                    </Typography>
                                  </Tooltip>
                                  <Tooltip
                                    title="Years of Experience"
                                    arrow
                                    placement="left"
                                  >
                                    <Typography
                                      color="textSecondary"
                                      align="left"
                                    >
                                      {exp?.experience
                                        ? `, ${exp?.experience}`
                                        : ""}
                                    </Typography>
                                  </Tooltip>
                                </div>
                                <Tooltip
                                  title="Description"
                                  arrow
                                  placement="left"
                                >
                                  <Typography
                                    align="left"
                                    className={classes.accomplishmentColor}
                                  >
                                    <span
                                      dangerouslySetInnerHTML={{
                                        __html: exp?.description
                                          ? exp?.description
                                          : "",
                                      }}
                                    />
                                  </Typography>
                                </Tooltip>
                              </TimelineOppositeContent>
                              <TimelineSeparator>
                                <TimelineDot
                                  className={classes.timelineDotStyle}
                                />
                                {companyExp?.length !== ind + 1 ? (
                                  <TimelineConnector
                                    className={classes.TimelineConnectorStyle}
                                  />
                                ) : null}
                              </TimelineSeparator>
                            </TimelineItem>
                          ))}
                        </Timeline>
                      )}
                      {boardAdvisorExperience.length !== ind + 1 && (
                        <Divider className={classes.marginDivider} />
                      )}
                    </Grid>
                  </>
                ))}
              </Grid>
            )}
          </div>
        </>
      )}

      {type === "education" ? (
        <div className={classes.overviewsMainDiv}>
          <Typography variant="h6" className={classes.headingGrid}>
            Education
          </Typography>
          <Typography color="textSecondary" className={classes.headingText}>
            {countEdu ? `${countEdu} Added` : "No data available"}
          </Typography>
          <Divider className={classes.dividerTopTab} />
          <Grid container>
            {education?.length > 0 ? (
              education?.map((edu, index) => (
                <>
                  <Grid
                    item
                    sm={12}
                    xs={12}
                    md={1}
                    lg={1}
                    xl={1}
                    style={{
                      alignSelf: "center",
                      marginTop: index !== 0 ? "35px" : "25px",
                    }}
                  >
                    <Avatar
                      src={EducationIcon}
                      variant="square"
                      alt="Education Icon"
                    />
                  </Grid>
                  <Grid
                    item
                    sm={12}
                    xs={12}
                    md={11}
                    lg={11}
                    xl={11}
                    style={{ marginTop: index !== 0 ? "35px" : "25px" }}
                  >
                    <Typography component="div" className={classes.divDegree}>
                      {(capitalize(edu?.degree_name) || "") +
                        (capitalize(edu?.field_of_study)
                          ? ` - ${edu?.field_of_study}`
                          : "")}
                    </Typography>
                    <Typography
                      component="div"
                      className={classes.textAlignLeftCustom}
                    >
                      {capitalize(edu?.school) || ""}
                    </Typography>
                    <Typography
                      component="div"
                      color="textSecondary"
                      className={classes.textAlignLeftCustom}
                    >
                      {edu?.starts_at !== "" &&
                      edu?.starts_at !== null &&
                      edu?.ends_at !== "" &&
                      edu?.ends_at !== null
                        ? `${moment(edu?.starts_at).format(
                            "MM/YYYY"
                          )} - ${moment(edu?.ends_at).format("MM/YYYY")}`
                        : edu?.starts_at !== "" &&
                          edu?.starts_at !== null &&
                          (edu?.ends_at === "" || edu?.ends_at === null)
                        ? `${moment(edu?.starts_at).format("MM/YYYY")} - `
                        : (edu?.starts_at === "" || edu?.starts_at === null) &&
                          edu?.ends_at !== "" &&
                          edu?.ends_at !== null
                        ? ` - ${moment(edu?.ends_at).format("MM/YYYY")}`
                        : ""}
                    </Typography>
                  </Grid>
                </>
              ))
            ) : (
              <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                <EmptyState
                  type="education"
                  height={EMPTY_ICON_HEIGHT}
                  width={EMPTY_ICON_WIDTH}
                />
              </Grid>
            )}
          </Grid>
        </div>
      ) : (
        ""
      )}
      {type === "certification" ? (
        <div className={classes.overviewsMainDiv}>
          <Typography variant="h6" className={classes.headingGrid}>
            Professional Certifications
          </Typography>
          <Typography className={classes.professHeading} color="textSecondary">
            {countEdu ? `${countEdu} Added` : "No data available"}
          </Typography>
          <Divider className={classes.dividerTopTab} />
          <Grid container>
            {certification?.length > 0 ? (
              certification?.map((certification, index) => (
                <>
                  <Grid
                    item
                    sm={12}
                    xs={12}
                    md={1}
                    lg={1}
                    xl={1}
                    style={{
                      alignSelf: "center",
                      marginTop: index !== 0 ? "35px" : "25px",
                    }}
                  >
                    <Avatar
                      src={CertificationIcon}
                      variant="square"
                      alt="Education Icon"
                    />
                  </Grid>
                  <Grid
                    item
                    sm={12}
                    xs={12}
                    md={11}
                    lg={11}
                    xl={11}
                    style={{ marginTop: index !== 0 ? "35px" : "25px" }}
                  >
                    <Typography
                      component="div"
                      align="left"
                      className={classes.certifName}
                    >
                      {capitalize(certification?.name) || ""}
                    </Typography>
                    <Typography
                      component="div"
                      align="left"
                      className={classes.mediumTopMargin}
                    >
                      {capitalize(certification?.orgnization) || ""}
                    </Typography>
                    <Typography
                      component="div"
                      align="left"
                      color="textSecondary"
                      className={classes.certifaDate}
                    >
                      {certification?.starts_at !== "" &&
                      certification?.starts_at !== null &&
                      certification?.ends_at !== "" &&
                      certification?.ends_at !== null
                        ? `${moment(certification?.starts_at).format(
                            "MM/YYYY"
                          )} - ${moment(certification?.ends_at).format(
                            "MM/YYYY"
                          )}`
                        : certification?.starts_at !== "" &&
                          certification?.starts_at !== null &&
                          (certification?.ends_at === "" ||
                            certification?.ends_at === null)
                        ? `${moment(certification?.starts_at).format(
                            "MM/YYYY"
                          )} - `
                        : (certification?.starts_at === "" ||
                            certification?.starts_at === null) &&
                          certification?.ends_at !== "" &&
                          certification?.ends_at !== null
                        ? ` - ${moment(certification?.ends_at).format(
                            "MM/YYYY"
                          )}`
                        : ""}
                    </Typography>
                  </Grid>
                </>
              ))
            ) : (
              <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                <EmptyState
                  type="professionalCertificates"
                  height={EMPTY_ICON_HEIGHT}
                  width={EMPTY_ICON_WIDTH}
                />
              </Grid>
            )}
          </Grid>
        </div>
      ) : (
        ""
      )}
      {type === "resume" ? (
        <div className={classes.overviewsMainDiv}>
          <Typography variant="h6" className={classes.headingGrid}>
            Resume/CV
          </Typography>
          <Divider className={classes.resumeTopDivider} />
          {resume ? (
            <Grid container className={classes.resumeHeader}>
              <Grid
                item
                xs={12}
                sm={12}
                md={8}
                lg={8}
                xl={8}
                className={classes.resumeTitleGrid}
              >
                <Typography className={classes.customLink}>
                  {`${firstName}_${lastName}.${resume.split(".").pop()}`}
                </Typography>
              </Grid>
              <Grid
                item
                xs={12}
                sm={12}
                md={4}
                lg={4}
                xl={4}
                className={classes.downloadBotton}
              >
                <Link
                  href={resume}
                  target="_blank"
                  rel="noreferrer"
                  className={classes.customLink2}
                >
                  <DownloadIcon
                    fontSize="small"
                    className={classes.marginRight10}
                  />{" "}
                  Download
                </Link>
              </Grid>
            </Grid>
          ) : (
            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
              <EmptyState
                type="resume"
                height={EMPTY_ICON_HEIGHT}
                width={EMPTY_ICON_WIDTH}
              />
            </Grid>
          )}
          {resume && (
            <div>
              {ResumeType === "pdf" ? (
                <iframe
                  src={`${resume}#toolbar=0`}
                  width="100%"
                  height="800px"
                  frameBorder="0"
                  title="Resume Pdf"
                >
                  This is an embedded{" "}
                  <a target="_blank" rel="noreferrer" href="http://office.com">
                    Microsoft Office
                  </a>{" "}
                  document, powered by{" "}
                  <a
                    target="_blank"
                    rel="noreferrer"
                    href="http://office.com/webapps"
                  >
                    Office Online
                  </a>
                  .
                </iframe>
              ) : (
                <iframe
                  title="Resume Doc,Docx"
                  src={`https://view.officeapps.live.com/op/embed.aspx?src=${resume}#toolbar=0`}
                  width="100%"
                  height="800px"
                  frameBorder="0"
                >
                  This is an embedded{" "}
                  <a target="_blank" rel="noreferrer" href="http://office.com">
                    Microsoft Office
                  </a>{" "}
                  document, powered by{" "}
                  <a
                    target="_blank"
                    href="http://office.com/webapps"
                    rel="noreferrer"
                  >
                    Office Online
                  </a>
                  .
                </iframe>
              )}
            </div>
          )}
        </div>
      ) : (
        ""
      )}
    </div>
  );
}
