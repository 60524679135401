const initialState = { jobTitles: [], shareProfileVisible: false };

export function members(state = initialState, action) {
  switch (action.type) {
    case "MEMBERS_JOB_TITLE_DATA":
      return {
        ...state,
        jobTitles: action.jobTitles,
      };
    case "LOCATION_DATA":
      return {
        ...state,
        locationsData: action.locationsData,
      };
    case "GOT_BROWSED_DATA":
      return {
        ...state,
        browsed: action.browsed,
      };
    case "GOT_PENDING_BROWSE_DATA":
      return {
        ...state,
        pending: action.pending,
      };
    case "GOT_MATCHED_BROWSE_DATA":
      return {
        ...state,
        matched: action.matched,
      };
    case "GOT_ARCHIVED_BROWSE_DATA":
      return {
        ...state,
        archived: action.archived,
      };
    case "LIKE_BROWSE_RESPONSE":
      return {
        ...state,
        likeCandidateResponse: action.likeCandidateResponse,
      };
    case "DISLIKE_BROWSE_RESPONSE":
      return {
        ...state,
        dislikeCandidateResponse: action.dislikeCandidateResponse,
      };
    case "FULL_PROFILE_DATA":
      return {
        ...state,
        fullProfileData: action.fullProfileData,
        fullProfileRecommendationId: action.fullProfileRecommendationId,
        fullProfileCandidateId: action.fullProfileCandidateId,
        isMatchedArchived: action.isMatchedArchived,
        jobStatus: action.jobStatus,
      };
    case "HISTORY_DATA":
      return {
        ...state,
        historyData: action.historyData,
      };
    case "SHARE_PROFILE_LINK_DATA":
      return {
        ...state,
        shareProfileLinkData: action.shareProfileLinkData,
      };
    case "SEND_SCHEDULING_LINK_PREVIEW":
      return {
        ...state,
        sendSchedulingLinkEmailPreview: action.sendSchedulingLinkEmailPreview,
      };
    case "SHARED_PROFILE_DATA":
      return {
        ...state,
        sharedProfileData: action.sharedProfileData,
      };
    case "SHARED_PROFILE_VISIBLE":
      return {
        ...state,
        shareProfileVisible: action.shareProfileVisible,
      };
    default:
      return state;
  }
}
