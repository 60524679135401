import {
  Tooltip,
  MenuItem,
  Popper,
  Paper,
  Fade,
  MenuList,
  ClickAwayListener,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Table,
  TableBody,
  Button,
  IconButton,
  Typography,
  Divider,
  Grid,
  Avatar,
} from "@material-ui/core";
import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import VisibilityIcon from "@material-ui/icons/Visibility";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import classNames from "classnames";
import SortIcon from "@material-ui/icons/Sort";
import { convertToRaw } from "draft-js";
import draftToHtml from "draftjs-to-html";
import { toast } from "react-toastify";
import { jobsActions, membersActions, messagesActions } from "../../../actions";
import ViewLiteProfilePopUp from "./viewLiteProfilePopUp";
import { ReactComponent as ThumbsUpIcon } from "../../../assets/thumb_up.svg";
import { ReactComponent as MarkAsHiredIcon } from "../../../assets/markAsHired.svg";
import { ReactComponent as RemovedFromIcon } from "../../../assets/removeFromQueue.svg";
import ArrowRightAltIcon from "@material-ui/icons/ArrowRightAlt";
import { ReactComponent as RequestInterviewIcon } from "../../../assets/requestInterview.svg";
import { setAllUpdateHeaderCard } from "../../../actions/jobs.actions";
import EmptyState from "../../core/emptyState";
// import { ReactComponent as CalendlyNewIcon } from '../../../assets/CalendlyNew.svg';
import LikeComponent from "../../customComponents/likeComponent";
import SchedulingPopup from "../../customComponents/scheduling.popup.component.js";
import PreviewEmaildailog from "../../core/previewEmail.dailog";
import MoveCandidateinConversation from "../../customComponents/moveCandidateinConversation";
import MarkedHired from "../../customComponents/makedHired";
import { capitalize } from "../../../helpers";
import secureLocalStorage from "react-secure-storage";
import RemoveFromQueue from "../../customComponents/removeFromQueue";
import { LABELS, REDIRECT_TO_COMPONENT } from "../../../constants/constants";
import { ViewMore } from "../../core/viewmore";

const useStyles = makeStyles((theme) => ({
  tableMain: {
    borderRadius: "9px 9px 0px 0px !important",
    border: "1px solid #FDDB76",
  },
  tableHeadRow: {
    borderBottom: "1px solid #FDDB76",
    background: "#FEF5DB",
    fontWeight: 700,
  },
  BgColorRowOdd: { background: "#FFFFFF" },
  BgColorRowEven: { background: "#F8F6EF" },
  viewButton: {
    background: "#020203",
    color: "#FFFFFF",
    padding: "4px 8px 4px 8px",
    "&:hover": {
      backgroundColor: "#020203",
    },
    [theme.breakpoints.down("md")]: {
      fontSize: "0.6rem",
    },
  },
  scheduleMeetingButton: {
    background: "#020203",
    color: "#FFFFFF",
    padding: "4px 8px",
    "&:hover": {
      backgroundColor: "#020203",
    },
  },
  LikeButton: {
    color: "#FFFFFF",
    marginLeft: "10px",
    borderRadius: "10%",
    padding: "6px",
    border: "1px solid #000000",
  },
  displayFlex: {
    display: "flex",
  },
  TableHeadingMain: {
    fontWeight: "700",
    marginBottom: "8px",
    marginTop: "10px",
  },
  TableHeading: {
    fontWeight: "700",
  },
  mainDivMargin: {
    marginTop: "2%",
  },
  timeCellsCss: {
    display: "flex",
    flexDirection: "column",
  },
  timeInnerSpan: {
    fontSize: "14px",
    color: "#666666",
  },
  newChip: {
    marginLeft: "4px",
    background: "#B7B6B8",
    fontSize: "8px",
    borderRadius: "5px",
    padding: "2px",
    fontWeight: 400,
  },
  marginLeft4: {
    marginLeft: "4px",
  },
  nameMainSpan: {
    display: "flex",
    alignItems: "center",
    cursor: "pointer",
  },
  avatarSmall: {
    height: 24,
    width: 24,
  },
  marginTop35: {
    marginTop: "35px",
  },
  backDrop: {
    filter: "blur(.2rem)",
  },
  interestedButton: {
    background: "#020203",
    color: "#FFFFFF",
    padding: "4px 15px 4px 15px",
    marginLeft: "7px",
    "&:hover": {
      backgroundColor: "#020203",
    },
  },
  candidateCellWidth: { width: "23%" },
  candidateTitleCellWidth: { width: "15%" },
  daySinceRecCellWidth: { width: "20%" },
  statusCellWidth: { width: "20%" },
  actionTableCellWidth: { width: "22%" },
  moreFont: {
    fontSize: "14px",
    fontWeight: 500,
    color: "#14131F",
  },
  borderBottom: {
    borderBottom: "1px solid #D7D9DB",
  },
  viewAllMainDiv: {
    display: "flex",
    alignItems: "center",
    marginTop: "16px",
  },
  viewAllTypo: {
    fontSize: "12px",
  },
  viewAllButton: {
    marginLeft: "8px",
    padding: "2px 15px",
    border: "1px solid #000000",
    "&:hover": {
      backgroundColor: "#000000 !important",
      color: "#ffffff",
    },
  },
  zIndex9999: {
    zIndex: 9999,
  },
  popperMainDiv: {
    display: "contents",
    alignItems: "left",
  },
  alignContentCenter: {
    alignContent: "center",
  },
  newActions: {
    width: "95% !important",
    display: "flex",
    alignItems: "center",
    flexWrap: "nowrap",
    justifyContent: "left",
  },
  width10: {
    width: "10% !important",
  },
  width20: {
    width: "20%",
  },
  smallMarginLeft: {
    marginLeft: "4px",
    padding: "8px 15px !important",
  },
  hiredButton: {
    border: "#41B97C solid 1px",
    color: "#41B97C",
    marginLeft: "7px",
    "&:hover": {
      color: "#41B97C",
    },
  },
  moveToInterview: {
    background: "#020203",
    color: "#FFFFFF",
    marginLeft: "7px",
    "&:hover": {
      backgroundColor: "#020203",
    },
    [theme.breakpoints.down("md")]: {
      fontSize: "0.6rem",
      marginLeft: 0,
    },
    [theme.breakpoints.down("sm")]: {
      fontSize: "0.6rem",
      marginLeft: 0,
    },
  },
  moveToRemoveFromQueue: {
    border: "#B00020 solid 1px",
    color: "#B00020",
    marginLeft: "7px",
    "&:hover": {
      border: "#B00020 solid 1px",
      color: "#B00020",
    },
    [theme.breakpoints.down("md")]: {
      fontSize: "0.6rem",
      marginLeft: 0,
    },
    [theme.breakpoints.down("sm")]: {
      fontSize: "0.6rem",
      marginLeft: 0,
    },
  },
  requestInterview: {
    background: "#020203",
    color: "#FFFFFF",
    marginLeft: "7px",
    "&:hover": {
      backgroundColor: "#020203",
    },
  },
  customTextAlignCenter: {
    textAlign: "center",
  },
  shortlistedActions: {
    display: "flex",
    flexWrap: "nowrap",
    justifyContent: "left",
    [theme.breakpoints.down("md")]: {
      fontSize: "0.6rem",
      display: "grid",
      gap: "4px",
    },
    [theme.breakpoints.down("sm")]: {
      fontSize: "0.6rem",
      display: "grid",
      gap: "4px",
    },
  },
  commentOlStyle: {
    paddingInlineStart: "13px !important",
  },
  actionInConversations: {
    display: "flex",
    flexWrap: "wrap",
    justifyContent: "start",
    gap: "8px",
  },
  emailStyle: {
    fontSize: "13px",
  },
  inConverstionCandidateName: {
    display: "flex",
    alignItems: "center",
    gap: "5px",
    cursor: "pointer",
  },
  customflex: {
    display: "flex",
  },
  cursorPointer: {
    cursor: "pointer",
  },
}));

function ListViewJobs() {
  const dispatch = useDispatch();
  const id = useSelector((state) => state?.jobs?.jobDetails?.id);
  const allUpdateData = useSelector((state) => state.jobs?.allUpdateData);
  const headerCardValue = useSelector(
    (state) => state.jobs?.allUpdateHeaderCard
  );
  const sendEmailValues = useSelector(
    (state) => state.jobs?.schedulingLinkValues
  );
  const accessPermission = useSelector(
    (state) => state.shared?.accessPermission
  );
  const jobType = useSelector((state) => state.jobs.jobDetails?.job_type);
  const jobStatus = useSelector((state) => state.jobs.jobDetails?.job_status);

  const classes = useStyles();
  const [isOpenViewLiteProfile, setIsOpenViewLiteProfile] = useState(false);
  const [jobRecId, setJobRecId] = useState(null);
  const [openLikePopup, setLikePopup] = useState(false);
  const [recommendationId, setRecommendationId] = useState(null);
  const [candidateRommendationId, setcandidateRommendationId] = useState(null);
  const [openInterviewDropdown, setOpenInterviewDropdown] = useState(false);
  const [scheduleInterviewType, setScheduleInterviewType] = useState("");
  const [anchorEl2, setAnchorEl2] = useState(null);
  const [openScheduleInterview, setOpenScheduleInterview] = useState(false);
  const [openPreview, setOpenPreview] = useState(false);
  const [recIdForSechedulingInterview, setRecIdForSechedulingInterview] =
    useState("");
  const [openMoveCandidateConversation, setOpenMoveCandidateConversation] =
    useState(false);
  const [recIdConversation, setRecIdConversation] = useState(false);
  const [dislikePopup, setDisLikePopup] = useState(false);
  const [recIdRemoveFromQueue, setRecIdRemoveFromQueue] = useState("");
  const [openMarkedHired, setOpenMarkedHired] = useState(false);
  const [makedHiredRecId, setMakedHiredRecId] = useState("");
  const [recCandidateActiveStatus, setRecCandidateActiveStatus] = useState("");
  const [sortValue, setSortValue] = useState([]);
  const [candidateHired, setCandidateHired] = useState(false);
  const [isSort, setIsSort] = useState(false);

  const showWarning = () => {
    toast.dark(
      "This operation is not allowed when you are logged in as SucceedSmart admin"
    );
  };

  useEffect(() => {
    if (id) {
      switch (headerCardValue) {
        case "All":
          dispatch(jobsActions.getAllUpdatesData(id, "All"));
          break;
        case "1":
          dispatch(jobsActions.getAllUpdatesData(id, "Recommended"));
          break;
        case "2":
          dispatch(jobsActions.getAllUpdatesData(id, "Matched"));
          break;
        case "3":
          dispatch(jobsActions.getAllUpdatesData(id, "Conversation"));
          break;
        case "4":
          dispatch(jobsActions.getAllUpdatesData(id, "Archive"));
          break;
        default:
          break;
      }
    }
  }, [id, headerCardValue, dispatch]);

  const handleLiteProfileView = (rec_id, status) => {
    setIsOpenViewLiteProfile(!isOpenViewLiteProfile);
    setJobRecId(rec_id);
    setRecCandidateActiveStatus(status);
  };

  const handleFullProfile = (
    candidateId,
    recommendationId,
    isMatchedArchived
  ) => {
    window.open(
      `/members/${candidateId}/${recommendationId}/${isMatchedArchived}`
    );
  };

  // const handleOpenMore = (e, CandidateId, recommendationId, markHired) => {
  //   setAnchorEl(e.currentTarget);
  //   setOpenMore(!openMore);
  //   setShowMoreButtonConverstion(markHired);
  //   setIdForViewFullProfile({
  //     ...idForViewFullProfile,
  //     candidateId: CandidateId,
  //     recommendationId,
  //   });
  // };

  const handleViewAll = (value) => {
    dispatch(setAllUpdateHeaderCard(value));
  };

  // dislike all functions
  const handleOpenRemoveFromQueue = (recId) => {
    setDisLikePopup(true);
    setRecIdRemoveFromQueue(recId);
    // setOpenMoreForShortlisted(false);
  };

  const handleCloseRemoveFromQueue = () => {
    setDisLikePopup(false);
  };

  // Like candidate functions
  const onHandleLike = (rec_id) => {
    setLikePopup(true);
    setRecommendationId(rec_id);
  };

  const onCLoseLike = () => {
    setLikePopup(false);
  };

  // schedule interview Preview and send email(All function)
  const onClickSheInterview = (e, recId, candidateId, is_hired) => {
    setOpenInterviewDropdown(!openInterviewDropdown);
    setAnchorEl2(e.currentTarget);
    setRecommendationId(recId);
    setcandidateRommendationId(candidateId);
    setCandidateHired(is_hired);
  };

  const sendScheduleSchedulingLinkCandidate = (
    scheduleInterviewType,
    recId
  ) => {
    setOpenScheduleInterview(true);
    setScheduleInterviewType(scheduleInterviewType);
    setOpenInterviewDropdown(false);
    setRecIdForSechedulingInterview(recId);
  };

  const closeScheduleInterview = () => {
    dispatch(jobsActions.schedulingLinkValues({}));
    setOpenScheduleInterview(false);
    setScheduleInterviewType("");
  };

  const editPreview = () => {
    setOpenScheduleInterview(true);
    setOpenPreview(false);
  };

  const onClosePreview = () => {
    dispatch(jobsActions.schedulingLinkValues({}));
    setOpenPreview(false);
  };

  const handlePreview = (values, note) => {
    dispatch(jobsActions.schedulingLinkValues(values));
    if (scheduleInterviewType === "email to coordinator") {
      const payloadEmailtoCoordinator = {
        option: scheduleInterviewType,
        first_name: values?.firstname,
        last_name: values?.lastname,
        email: values?.emailTocoordinator,
        is_preview: true,
      };
      setOpenPreview(true);
      setOpenScheduleInterview(false);
      handleEmailToCoordinator(payloadEmailtoCoordinator);
    } else {
      const payloadEmailTOCandidate = {
        note,
        is_preview: true,
      };
      handleEmailToCandidate(payloadEmailTOCandidate);
      setOpenPreview(true);
      setOpenScheduleInterview(false);
    }
  };

  const sendEmail = () => {
    if (scheduleInterviewType === "email to coordinator") {
      const payload = {
        option: scheduleInterviewType,
        first_name: sendEmailValues?.firstname,
        last_name: sendEmailValues?.lastname,
        email: sendEmailValues?.emailTocoordinator,
        is_preview: false,
      };
      handleEmailToCoordinator(payload);
    } else {
      const payload = {
        note: draftToHtml(
          convertToRaw(sendEmailValues.editorNotesState.getCurrentContent())
        ),
        is_preview: false,
      };
      handleEmailToCandidate(payload);
    }
    dispatch(jobsActions.schedulingLinkValues({}));
    setOpenPreview(false);
  };

  const handleEmailToCandidate = (payloadEmailTOCandidate) => {
    dispatch(
      membersActions.postSchedulingEmailToCandidate(
        recIdForSechedulingInterview,
        payloadEmailTOCandidate
      )
    );
  };

  const handleEmailToCoordinator = (payloadEmailtoCoordinator) => {
    dispatch(
      membersActions.postSchedulingEmailToCoordinator(
        recIdForSechedulingInterview,
        payloadEmailtoCoordinator
      )
    );
  };

  const openMoveInConversation = (recId) => {
    setOpenMoveCandidateConversation(true);
    setRecIdConversation(recId);
  };
  const onCloseMarkedHired = () => {
    setOpenMarkedHired(false);
  };

  const onhandleCloseMoveCandidateConversation = () => {
    setOpenMoveCandidateConversation(false);
  };

  const handleOpenMarkedHired = (id) => {
    setOpenMarkedHired(true);
    setMakedHiredRecId(id);
  };
  const handleOtherLiteProfileViewCLose = () => {
    setIsOpenViewLiteProfile(false);
  };

  const sendMessage = (candidateId) => {
    if (accessPermission) {
      dispatch(messagesActions.createChannel(candidateId));
    } else {
      toast.dark("Only job owner or editor can send messages to candidate");
    }
  };

  const EMPTY_ICON_HEIGHT = 140;
  const EMPTY_ICON_WIDTH = 140;

  useEffect(() => {
    setSortValue(allUpdateData?.recommended?.new_job_recommendations);
  }, [allUpdateData]);

  const onSortingValue = () => {
    setSortValue((prev) =>
      prev?.sort((a) =>
        isSort
          ? a.active_status === "Disqualified"
            ? -1
            : 1
          : a.active_status === "Disqualified"
          ? 1
          : -1
      )
    );
    setIsSort(!isSort);
  };

  return (
    <>
      {(headerCardValue === "All" || headerCardValue === "1") && (
        <>
          <Typography component="div">
            <Typography align="left" className={classes.TableHeadingMain}>
              Talent Pipeline
            </Typography>
            {allUpdateData?.recommended?.new_job_recommendations?.length ===
            0 ? (
              <EmptyState
                type="Recommended"
                height={EMPTY_ICON_HEIGHT}
                width={EMPTY_ICON_WIDTH}
              />
            ) : (
              <TableContainer className={classes.tableMain}>
                <Table sx={{ minWidth: 700 }} aria-label="a dense table">
                  <TableHead>
                    <TableRow className={classes.tableHeadRow}>
                      <TableCell className={classes.TableHeading}>
                        Candidate
                      </TableCell>
                      <TableCell className={classes.TableHeading}>
                        Current Title
                      </TableCell>
                      <TableCell className={classes.TableHeading}>
                        Time in Queue
                      </TableCell>
                      <TableCell
                        className={classNames(
                          classes.TableHeading,
                          classes.customflex
                        )}
                      >
                        <span> Status </span>
                        {headerCardValue !== "All" && (
                          <>
                            <IconButton onClick={onSortingValue} size="small">
                              <SortIcon />
                            </IconButton>
                          </>
                        )}
                      </TableCell>
                      <TableCell className={classNames(classes.TableHeading)}>
                        Action
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody className={classes.alignContentCenter}>
                    {sortValue?.map((value, ind) => (
                      <TableRow
                        className={classNames(
                          ind % 2 === 0
                            ? classes.BgColorRowOdd
                            : classes.BgColorRowEven
                        )}
                      >
                        <TableCell width="26%">
                          <span
                            onClick={() =>
                              handleLiteProfileView(
                                value?.recommendation_id,
                                value?.new_recommendation_status
                              )
                            }
                            className={classes.nameMainSpan}
                          >
                            <Avatar
                              className={classNames(
                                classes.avatarSmall,
                                classes.backDrop
                              )}
                              src={value?.profile_image}
                              alt={value?.candidate_fname}
                            />{" "}
                            <span
                              className={classNames(
                                classes.marginLeft4,
                                classes.backDrop
                              )}
                            >
                              {`${value?.candidate_fname} ${value?.candidate_lname}`}
                            </span>
                            {value?.recommendation_status ===
                              "Action Required" && (
                              <span className={classes.newChip}>New</span>
                            )}
                          </span>
                        </TableCell>
                        <TableCell>
                          {" "}
                          {value?.title ? value?.title : "-"}
                        </TableCell>
                        <TableCell width={"10%"}>
                          <span className={classes.timeCellsCss}>
                            <Tooltip
                              placement="left"
                              arrow
                              title={moment(value?.recommended_on).format(
                                "MM-DD-YYYY, hh:mm A"
                              )}
                            >
                              <span className={classes.timeInnerSpan}>
                                {moment(value?.recommended_on)
                                  .startOf("minutes")
                                  .fromNow()}
                              </span>
                            </Tooltip>
                          </span>
                        </TableCell>
                        <TableCell width={"25%"}>
                          {value?.new_recommendation_status ===
                          "Viewed.Action Pending"
                            ? "Action Required"
                            : value?.new_recommendation_status
                            ? value?.new_recommendation_status
                            : "-"}
                        </TableCell>
                        <TableCell className={classes.newActions}>
                          <span className={classes.displayFlex}>
                            <Button
                              size="small"
                              onClick={() =>
                                handleLiteProfileView(
                                  value?.recommendation_id,
                                  value?.new_recommendation_status
                                )
                              }
                              className={classes.viewButton}
                              startIcon={<VisibilityIcon />}
                            >
                              View
                            </Button>
                            {value?.new_recommendation_status !==
                              "Pending with Candidate" &&
                              accessPermission &&
                              jobStatus !== "Close" && (
                                <Tooltip title="Mark Interested" arrow>
                                  <IconButton
                                    size="small"
                                    className={classes.LikeButton}
                                    onClick={() =>
                                      onHandleLike(value?.recommendation_id)
                                    }
                                  >
                                    <ThumbsUpIcon />
                                  </IconButton>
                                </Tooltip>
                              )}
                          </span>
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            )}
          </Typography>
          {headerCardValue === "All" &&
            allUpdateData?.recommended?.new_job_recommendations?.length > 0 && (
              <div className={classes.viewAllMainDiv}>
                <Typography className={classes.viewAllTypo}>
                  Click to see Talent Pipeline
                </Typography>{" "}
                <Button
                  onClick={() => handleViewAll("1")}
                  variant="outlined"
                  size="small"
                  className={classes.viewAllButton}
                >
                  View All
                </Button>
              </div>
            )}
          <Grid
            item
            sm={12}
            xs={12}
            md={12}
            xl={12}
            lg={12}
            className={classes.marginTop35}
          >
            <Divider />
          </Grid>
        </>
      )}

      {(headerCardValue === "All" || headerCardValue === "2") && (
        <>
          <Typography component="div" className={classes.mainDivMargin}>
            <Typography align="left" className={classes.TableHeadingMain}>
              Shortlisted Candidates
            </Typography>
            {allUpdateData?.shortlisted_candidates?.like_back_by_candidates
              ?.length === 0 ? (
              <EmptyState
                type="Matched"
                height={EMPTY_ICON_HEIGHT}
                width={EMPTY_ICON_WIDTH}
              />
            ) : (
              <TableContainer className={classes.tableMain}>
                <Table aria-label="a dense table">
                  <TableHead>
                    <TableRow className={classes.tableHeadRow}>
                      <TableCell width={15} className={classes.TableHeading}>
                        Candidate
                      </TableCell>
                      <TableCell width={15} className={classes.TableHeading}>
                        Current Title
                      </TableCell>
                      {/* <TableCell width={15} className={classes.TableHeading}>
                        Email
                      </TableCell> */}
                      <TableCell width={15} className={classes.TableHeading}>
                        Phone
                      </TableCell>
                      <TableCell width={15} className={classes.TableHeading}>
                        Time in Queue
                      </TableCell>
                      <TableCell
                        width={35}
                        className={classNames(classes.TableHeading)}
                      >
                        Action
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {allUpdateData?.shortlisted_candidates?.like_back_by_candidates?.map(
                      (value, ind) => (
                        <TableRow
                          className={classNames(
                            ind % 2 === 0
                              ? classes.BgColorRowOdd
                              : classes.BgColorRowEven
                          )}
                        >
                          <TableCell width="18%">
                            <div
                              onClick={() => {
                                handleFullProfile(
                                  value?.candidate_id,
                                  value?.recommendation_id,
                                  false
                                );
                              }}
                              className={classes.inConverstionCandidateName}
                            >
                              <div>
                                <Avatar
                                  className={classes.avatarSmall}
                                  src={value?.profile_image}
                                  alt={value?.candidate_fname}
                                />
                              </div>
                              <div>
                                <div>
                                  <span className={classes.marginLeft4}>
                                    {capitalize(
                                      `${value?.candidate_fname} ${value?.candidate_lname}`
                                    )}
                                  </span>
                                  {value?.new_recommendation_status ===
                                    "Meeting to be Scheduled" && (
                                    <span className={classes.newChip}>New</span>
                                  )}
                                </div>
                                <div>
                                  <Typography
                                    color="secondary"
                                    className={classNames(
                                      classes.marginLeft4,
                                      classes.emailStyle
                                    )}
                                  >
                                    {value?.email
                                      ? capitalize(value?.email)
                                      : "-"}
                                  </Typography>
                                </div>
                              </div>
                            </div>
                          </TableCell>
                          <TableCell width={"16%"}>
                            {" "}
                            {value?.title ? capitalize(value?.title) : "-"}
                          </TableCell>
                          <TableCell width={"13%"}>
                            {" "}
                            {value?.phone_no ? value?.phone_no : "-"}
                          </TableCell>
                          <TableCell width={"13%"}>
                            <span className={classes.timeCellsCss}>
                              <Tooltip
                                placement="left"
                                arrow
                                title={moment(value?.recommended_on).format(
                                  "MM-DD-YYYY, hh:mm A"
                                )}
                              >
                                <span className={classes.timeInnerSpan}>
                                  {moment(value?.recommended_on)
                                    .startOf("minutes")
                                    .fromNow()}
                                </span>
                              </Tooltip>
                            </span>
                          </TableCell>
                          <TableCell className={classes.shortlistedActions}>
                            <Button
                              onClick={() => {
                                handleFullProfile(
                                  value?.candidate_id,
                                  value?.recommendation_id,
                                  false
                                );
                              }}
                              size="small"
                              className={classes.viewButton}
                              startIcon={<VisibilityIcon />}
                            >
                              View
                            </Button>
                            {accessPermission && jobStatus !== "Close" && (
                              <>
                                <Button
                                  size="small"
                                  onClick={() =>
                                    openMoveInConversation(
                                      value.recommendation_id
                                    )
                                  }
                                  className={classes.moveToInterview}
                                  startIcon={<ArrowRightAltIcon />}
                                >
                                  Move to Interview Stage
                                </Button>
                                <Button
                                  size="small"
                                  onClick={() =>
                                    handleOpenRemoveFromQueue(
                                      value.recommendation_id
                                    )
                                  }
                                  className={classes.moveToRemoveFromQueue}
                                  startIcon={<RemovedFromIcon />}
                                >
                                  Remove from Queue
                                </Button>
                              </>
                            )}
                          </TableCell>
                        </TableRow>
                      )
                    )}
                  </TableBody>
                </Table>
              </TableContainer>
            )}
          </Typography>
          {headerCardValue === "All" &&
            allUpdateData?.shortlisted_candidates?.like_back_by_candidates
              ?.length > 0 && (
              <div className={classes.viewAllMainDiv}>
                <Typography className={classes.viewAllTypo}>
                  Click to see all Shortlisted Candidates
                </Typography>{" "}
                <Button
                  onClick={() => handleViewAll("2")}
                  variant="outlined"
                  size="small"
                  className={classes.viewAllButton}
                >
                  View All
                </Button>
              </div>
            )}
          <Grid
            item
            sm={12}
            xs={12}
            md={12}
            xl={12}
            lg={12}
            className={classes.marginTop35}
          >
            <Divider />
          </Grid>
        </>
      )}

      {(headerCardValue === "All" || headerCardValue === "3") && (
        <>
          <Typography component="div" className={classes.mainDivMargin}>
            <Typography align="left" className={classes.TableHeadingMain}>
              Candidate in Conversation
            </Typography>
            {allUpdateData?.in_conversation_candidates
              ?.in_conversation_candidates?.length === 0 ? (
              <EmptyState
                type="Conversation"
                height={EMPTY_ICON_HEIGHT}
                width={EMPTY_ICON_WIDTH}
              />
            ) : (
              <TableContainer className={classes.tableMain}>
                <Table aria-label="a dense table">
                  <TableHead>
                    <TableRow className={classes.tableHeadRow}>
                      <TableCell width={15} className={classes.TableHeading}>
                        Candidate
                      </TableCell>
                      <TableCell width={15} className={classes.TableHeading}>
                        Current Title
                      </TableCell>
                      <TableCell width={15} className={classes.TableHeading}>
                        Phone
                      </TableCell>
                      <TableCell width={15} className={classes.TableHeading}>
                        Time in Queue
                      </TableCell>
                      <TableCell
                        width={15}
                        className={classNames(classes.TableHeading)}
                      >
                        Action
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {allUpdateData?.in_conversation_candidates?.in_conversation_candidates?.map(
                      (value, ind) => (
                        <TableRow
                          className={classNames(
                            ind % 2 === 0
                              ? classes.BgColorRowOdd
                              : classes.BgColorRowEven
                          )}
                        >
                          <TableCell width="15%">
                            <div
                              onClick={() => {
                                handleFullProfile(
                                  value?.candidate_id,
                                  value?.recommendation_id,
                                  false
                                );
                              }}
                              className={classes.inConverstionCandidateName}
                            >
                              <div>
                                <Avatar
                                  className={classes.avatarSmall}
                                  src={value?.profile_image}
                                  alt={value?.candidate_fname}
                                />{" "}
                              </div>
                              <div>
                                <div>
                                  <span className={classes.marginLeft4}>
                                    {capitalize(
                                      `${value?.candidate_fname} ${value?.candidate_lname}`
                                    )}
                                  </span>
                                  {value?.is_hired && (
                                    <span className={classes.newChip}>
                                      Hired
                                    </span>
                                  )}
                                </div>
                                <div>
                                  <Typography
                                    color="secondary"
                                    className={classNames(
                                      classes.marginLeft4,
                                      classes.emailStyle
                                    )}
                                  >
                                    {value?.email
                                      ? capitalize(value?.email)
                                      : "-"}
                                  </Typography>
                                </div>
                              </div>
                            </div>
                          </TableCell>
                          <TableCell width="16%">
                            {value?.title ? capitalize(value?.title) : ""}
                          </TableCell>
                          <TableCell width="12%">
                            {" "}
                            {value?.phone_no ? value?.phone_no : "-"}
                          </TableCell>
                          <TableCell width="10%">
                            <span className={classes.timeCellsCss}>
                              <Tooltip
                                placement="left"
                                arrow
                                title={moment(value?.recommended_on).format(
                                  "MM-DD-YYYY, hh:mm A"
                                )}
                              >
                                <span className={classes.timeInnerSpan}>
                                  {moment(value?.recommended_on)
                                    .startOf("minutes")
                                    .fromNow()}
                                </span>
                              </Tooltip>
                            </span>
                          </TableCell>
                          <TableCell
                            className={classes.actionInConversations}
                            // align="center"
                          >
                            <Button
                              onClick={() => {
                                handleFullProfile(
                                  value?.candidate_id,
                                  value?.recommendation_id,
                                  false
                                );
                              }}
                              size="small"
                              className={classes.viewButton}
                              startIcon={<VisibilityIcon />}
                            >
                              View
                            </Button>
                            {((accessPermission &&
                              jobStatus !== "Close" &&
                              jobType !== "Confidential") ||
                              (!value.is_hired &&
                                jobStatus !== "Close" &&
                                accessPermission)) && (
                              <>
                                <Button
                                  className={classes.requestInterview}
                                  onClick={(e) =>
                                    onClickSheInterview(
                                      e,
                                      value?.recommendation_id,
                                      value?.candidate_id,
                                      value.is_hired
                                    )
                                  }
                                  size="small"
                                  startIcon={<RequestInterviewIcon />}
                                >
                                  Request Interview
                                </Button>
                                <div className={classes.popperMainDiv}>
                                  <Popper
                                    open={openInterviewDropdown}
                                    anchorEl={anchorEl2}
                                    className={classes.zIndex9999}
                                    placement="left-end"
                                    transition
                                  >
                                    {({ TransitionProps }) => (
                                      <Fade {...TransitionProps}>
                                        <Paper>
                                          <ClickAwayListener
                                            onClickAway={() =>
                                              setOpenInterviewDropdown(false)
                                            }
                                          >
                                            <MenuList>
                                              {!candidateHired && (
                                                <>
                                                  <MenuItem
                                                    className={
                                                      classes.borderBottom
                                                    }
                                                  >
                                                    <Typography
                                                      className={
                                                        classes.moreFont
                                                      }
                                                      onClick={() =>
                                                        !secureLocalStorage.getItem(
                                                          "g_u"
                                                        )
                                                          ? sendScheduleSchedulingLinkCandidate(
                                                              "email to candidate",
                                                              recommendationId
                                                            )
                                                          : showWarning()
                                                      }
                                                    >
                                                      Request candidate for
                                                      interview
                                                    </Typography>
                                                  </MenuItem>
                                                  <MenuItem
                                                    className={
                                                      classes.borderBottom
                                                    }
                                                  >
                                                    <Typography
                                                      className={
                                                        classes.moreFont
                                                      }
                                                      onClick={() =>
                                                        !secureLocalStorage.getItem(
                                                          "g_u"
                                                        )
                                                          ? sendScheduleSchedulingLinkCandidate(
                                                              "email to coordinator",
                                                              recommendationId
                                                            )
                                                          : showWarning()
                                                      }
                                                    >
                                                      Send email to coordinator
                                                    </Typography>
                                                  </MenuItem>
                                                </>
                                              )}
                                              {jobType !== "Confidential" && (
                                                <MenuItem
                                                  onClick={(event) => {
                                                    event.preventDefault();
                                                    !secureLocalStorage.getItem(
                                                      "g_u"
                                                    )
                                                      ? sendMessage(
                                                          candidateRommendationId
                                                        )
                                                      : showWarning();
                                                  }}
                                                  className={
                                                    classes.borderBottom
                                                  }
                                                >
                                                  <Typography
                                                    className={classes.moreFont}
                                                  >
                                                    Send message to candidate
                                                  </Typography>
                                                </MenuItem>
                                              )}
                                            </MenuList>
                                          </ClickAwayListener>
                                        </Paper>
                                      </Fade>
                                    )}
                                  </Popper>
                                </div>
                              </>
                            )}
                            {!value.is_hired &&
                              accessPermission &&
                              jobStatus !== "Close" && (
                                <>
                                  <Button
                                    size="small"
                                    onClick={() => {
                                      handleOpenMarkedHired(
                                        value?.recommendation_id
                                      );
                                    }}
                                    className={classes.hiredButton}
                                    startIcon={<MarkAsHiredIcon />}
                                  >
                                    Mark as Hired
                                  </Button>
                                  <Button
                                    size="small"
                                    onClick={() =>
                                      handleOpenRemoveFromQueue(
                                        value.recommendation_id
                                      )
                                    }
                                    className={classes.moveToRemoveFromQueue}
                                    startIcon={<RemovedFromIcon />}
                                  >
                                    Remove from Queue
                                  </Button>
                                </>
                              )}
                          </TableCell>
                        </TableRow>
                      )
                    )}
                  </TableBody>
                </Table>
              </TableContainer>
            )}
          </Typography>
          {headerCardValue === "All" &&
            allUpdateData?.in_conversation_candidates
              ?.in_conversation_candidates?.length > 0 && (
              <div className={classes.viewAllMainDiv}>
                <Typography className={classes.viewAllTypo}>
                  Click to see all Candidates in conversations
                </Typography>{" "}
                <Button
                  onClick={() => handleViewAll("3")}
                  variant="outlined"
                  size="small"
                  className={classes.viewAllButton}
                >
                  View All
                </Button>
              </div>
            )}
        </>
      )}

      {headerCardValue === "4" && (
        <Typography component="div" className={classes.mainDivMargin}>
          <Typography align="left" className={classes.TableHeadingMain}>
            Removed from Queue
          </Typography>
          {allUpdateData?.archived_candidates?.archived_candidates?.length ===
          0 ? (
            <EmptyState
              type="Removed"
              height={EMPTY_ICON_HEIGHT}
              width={EMPTY_ICON_WIDTH}
            />
          ) : (
            <TableContainer className={classes.tableMain}>
              <Table aria-label="a dense table">
                <TableHead>
                  <TableRow className={classes.tableHeadRow}>
                    <TableCell width={20} className={classes.TableHeading}>
                      Candidate
                    </TableCell>
                    <TableCell width={20} className={classes.TableHeading}>
                      Current Title
                    </TableCell>
                    <TableCell width={20} className={classes.TableHeading}>
                      Status
                    </TableCell>
                    <TableCell width={20} className={classes.TableHeading}>
                      Reason for Removing from Queue
                    </TableCell>
                    <TableCell width={20} className={classes.TableHeading}>
                      Action
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {allUpdateData?.archived_candidates?.archived_candidates?.map(
                    (value, ind) => (
                      <TableRow
                        className={classNames(
                          ind % 2 === 0
                            ? classes.BgColorRowOdd
                            : classes.BgColorRowEven
                        )}
                      >
                        <TableCell>
                          <span
                            onClick={() => {
                              !value.is_interested_to_archive
                                ? handleLiteProfileView(
                                    value?.recommendation_id,
                                    value?.new_recommendation_status
                                  )
                                : handleFullProfile(
                                    value?.candidate_id,
                                    value?.recommendation_id,
                                    false
                                  );
                            }}
                            className={classes.nameMainSpan}
                          >
                            <Avatar
                              className={classNames(
                                classes.avatarSmall,
                                !value.is_interested_to_archive &&
                                  classes.backDrop
                              )}
                              src={value?.profile_image}
                              alt={value?.candidate_fname}
                            />{" "}
                            <span
                              className={classNames(
                                classes.marginLeft4,
                                !value.is_interested_to_archive &&
                                  classes.backDrop
                              )}
                            >
                              {`${value?.candidate_fname} ${value?.candidate_lname}`}
                            </span>
                          </span>
                        </TableCell>
                        <TableCell>
                          {" "}
                          {value?.title ? value?.title : "-"}
                        </TableCell>
                        <TableCell> {value?.active_status}</TableCell>
                        <TableCell>
                          {typeof value.reason === "object" && value.reason ? (
                            <ol className={classes.commentOlStyle}>
                              {value.reason?.experience_issue && (
                                <li>
                                  {
                                    LABELS.REMOVE_FROM_QUEUE
                                      .EXPERIENCE_NOT_RELEVANT
                                  }
                                  <ViewMore
                                    length={100}
                                  >{` : ${value.reason.experience_issue}`}</ViewMore>
                                </li>
                              )}
                              {value.reason?.industry_issue && (
                                <li>
                                  {
                                    LABELS.REMOVE_FROM_QUEUE
                                      .INDUSTRY_NOT_ALIGNED
                                  }
                                  <ViewMore
                                    length={100}
                                  >{` : ${value.reason.industry_issue}`}</ViewMore>
                                </li>
                              )}
                              {value.reason?.other_feedback && (
                                <li>
                                  {LABELS.REMOVE_FROM_QUEUE.OTHER}
                                  <ViewMore
                                    length={100}
                                  >{` : ${value.reason.other_feedback}`}</ViewMore>
                                </li>
                              )}
                            </ol>
                          ) : (
                            <>
                              <ViewMore length={100}>
                                {value?.reason ? value.reason : "-"}
                              </ViewMore>
                            </>
                          )}
                        </TableCell>

                        <TableCell className={classes.width10}>
                          <Button
                            onClick={() => {
                              !value.is_interested_to_archive
                                ? handleLiteProfileView(
                                    value?.recommendation_id,
                                    value?.new_recommendation_status
                                  )
                                : handleFullProfile(
                                    value?.candidate_id,
                                    value?.recommendation_id,
                                    false
                                  );
                            }}
                            size="small"
                            className={classes.viewButton}
                            startIcon={<VisibilityIcon />}
                          >
                            View
                          </Button>
                        </TableCell>
                      </TableRow>
                    )
                  )}
                </TableBody>
              </Table>
            </TableContainer>
          )}
        </Typography>
      )}

      {/* Other Components */}
      {isOpenViewLiteProfile && (
        <ViewLiteProfilePopUp
          candidateStatus={recCandidateActiveStatus}
          handleOtherLiteProfileViewCLose={handleOtherLiteProfileViewCLose}
          jobId={id}
          open={isOpenViewLiteProfile}
          jobRecommendedId={jobRecId}
          handleLiteProfileView={handleLiteProfileView}
        />
      )}

      {/* Like popup  */}

      <LikeComponent
        onCLoseLike={onCLoseLike}
        open={openLikePopup}
        jobId={id}
        recommendationId={recommendationId}
      />

      {/* Interview popup */}
      {openScheduleInterview && (
        <SchedulingPopup
          handlePreview={handlePreview}
          openScheduleInterview={openScheduleInterview}
          scheduleInterviewType={scheduleInterviewType}
          closeScheduleInterview={closeScheduleInterview}
        />
      )}

      {/* Interview Preview popup */}
      {openPreview ? (
        <PreviewEmaildailog
          open={openPreview}
          editPreview={editPreview}
          onSend={sendEmail}
          onClose={onClosePreview}
        />
      ) : null}
      <MarkedHired
        open={openMarkedHired}
        onClose={onCloseMarkedHired}
        makedHiredRecId={makedHiredRecId}
        jobId={id}
      />
      <RemoveFromQueue
        close={handleCloseRemoveFromQueue}
        open={dislikePopup}
        jobId={id}
        recommendationId={recIdRemoveFromQueue}
        redirectfrom={REDIRECT_TO_COMPONENT.ALL_CANDIDATES}
      />
      <MoveCandidateinConversation
        jobId={id}
        recIdConversation={recIdConversation}
        open={openMoveCandidateConversation}
        onClose={onhandleCloseMoveCandidateConversation}
      />
    </>
  );
}

export default ListViewJobs;
