const initialState = {
  getStreamUserTokenData: null,
};

export function messages(state = initialState, action) {
  switch (action.type) {
    case "CURRENT_USER_GET_STREAM_TOKEN":
      return {
        ...state,
        getStreamUserTokenData: action.getStreamUserTokenData,
      };
    default:
      return state;
  }
}
