import React, { Component, Fragment } from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import { history } from "../../../helpers";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import { connect } from "react-redux";
import Typography from "@material-ui/core/Typography";
import { withRouter } from "react-router-dom";
import { Badge } from "@material-ui/core";
import ProductInfoImage from "../../../assets/product_info.svg";
import secureLocalStorage from "react-secure-storage";

const styles = (theme) => ({
  root: {
    marginTop: "90px",
    color: "#000000",
    margin: theme.spacing(2, 4),
  },
  image: {
    backgroundImage: `url(${ProductInfoImage})`, //'url(https://source.unsplash.com/random)',
    backgroundRepeat: "no-repeat",
    backgroundColor:
      theme.palette.type === "light"
        ? theme.palette.grey[50]
        : theme.palette.grey[900],
    backgroundSize: "cover",
  },
  paper: {
    marginTop: "3%",
  },
  customPaper: {
    margin: theme.spacing(3, 10),

    color: theme.palette.default.main,
  },
  customSubPaper: {
    display: "inline-flex",
    width: "28%",
    textAlign: "justify",
  },
  customContent: {
    margin: theme.spacing(3, 10),
    textAlign: "justify",
  },
  customForm: {
    margin: theme.spacing(3, 10),
    alignItems: "center",
  },
  customBadge: {
    backgroundColor: "#FBC112",
    height: "50px",
    minWidth: "50px",
    borderRadius: "50%",
    fontSize: "large",
  },
  mediumLineHeight: {
    lineHeight: "4.5",
  },
  forgotLink: {
    cursor: "pointer",
    "&:hover": {
      textDecoration: "underline",
    },
  },
  verySmallTopMargin: {
    marginTop: "1%",
  },
  smallTopMargin: {
    marginTop: "2%",
  },
  mediumTopMargin: {
    marginTop: "4%",
  },
  customFlex: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  xxLargeFont: {
    fontSize: "xx-large",
  },
  bottomSmall: {
    marginBottom: "9%",
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(5, 0, 2),
    borderRadius: "4px !important",
  },
  alignRight: {
    textAlign: "right",
  },
  margin: {
    marginTop: theme.spacing(2),
  },
  withoutLabel: {
    marginTop: theme.spacing(3),
  },
  textField: {
    width: "100%",
  },
  disabledSignIn: {
    cursor: "not-allowed",
    borderRadius: "4px !important",
  },
  toolbar: {
    padding: "1% !important",
    left: "1.5% !important",
  },
  responsive: {
    maxWidth: "100% !important",
    height: "auto !important",
    width: "auto !important",
    alignSelf: "center !important",
    minWidth: "fit-content !important",
  },
  customDivider: {
    borderBottom: "1px solid " + theme.palette.secondary.main,
    width: "inherit",
    marginTop: "10%",
    flexShrink: 1,
  },
  alignLeft: {
    textAlign: "left",
  },
  paddedContent: {
    marginLeft: "30vw",
    marginRight: "30vw",
    marginTop: "2%",
    [theme.breakpoints.down("md")]: {
      margin: "20px 16px",
    },
    [theme.breakpoints.down("sm")]: {
      margin: "10px 16px",
    },
  },
  inlineContent: {
    display: "flex",
    alignItems: "center",
    paddingLeft: "1.3vw",
  },
  justifyContent: {
    textAlign: "justify",
  },
});

class SignUpConfirm extends Component {
  componentDidMount() {
    document.getElementById("continueBtn").focus();
  }

  handleContinueClick = (event) => {
    event.preventDefault();
    event.stopPropagation();
    let recruiterDetails = this.props.authentication?.recruiterDetails;
    let recruiterStatusDetails = JSON.parse(
      secureLocalStorage.getItem("recruiterStatusDetails")
    );
    if (
      (recruiterDetails &&
        !recruiterDetails.domain_exists &&
        !recruiterDetails.is_from_added_seat) ||
      (recruiterStatusDetails && recruiterStatusDetails.is_owner)
    ) {
      history.push("/company-info");
    } else if (recruiterDetails && recruiterDetails.is_from_added_seat) {
      // && recruiterDetails.domain_exists
      history.push("/signin");
    } else {
      history.push("/no-access");
    }
  };

  render() {
    const { classes } = this.props;
    return (
      <Fragment>
        {/* <AppBar /> */}
        <Grid container component="main" className={classes.root}>
          <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
            <div className={classes.paper}>
              <Typography
                component="span"
                align="center"
                className={classes.xxLargeFont}
              >
                Code verified. Please create your account.
              </Typography>
            </div>
            <Grid className={classes.alignLeft}>
              <div className={classes.paddedContent}>
                <Typography component="span">
                  Your account will go through an approval process to gain
                  access to the SucceedSmart platform.
                </Typography>
                <div className={classes.inlineContent}>
                  <Grid item xs={3} sm={2} md={1} lg={1} xl={1}>
                    <Typography
                      component="span"
                      className={classes.mediumLineHeight}
                    >
                      <Badge
                        classes={{ badge: classes.customBadge }}
                        badgeContent={1}
                      ></Badge>
                    </Typography>
                  </Grid>
                  <Grid item xs={9} sm={10} md={11} lg={11} xl={11}>
                    <Typography> {"Enter company information."} </Typography>
                  </Grid>
                </div>
                <div className={classes.inlineContent}>
                  <Grid item xs={3} sm={2} md={1} lg={1} xl={1}>
                    <Typography
                      component="span"
                      className={classes.mediumLineHeight}
                    >
                      <Badge
                        classes={{ badge: classes.customBadge }}
                        badgeContent={2}
                      ></Badge>
                    </Typography>
                  </Grid>
                  <Grid item xs={9} sm={10} md={11} lg={11} xl={11}>
                    <Typography>
                      {" "}
                      {"Sign the SucceedSmart contract via DocuSign."}{" "}
                    </Typography>
                  </Grid>
                </div>
                <div className={classes.inlineContent}>
                  <Grid item xs={3} sm={2} md={1} lg={1} xl={1}>
                    <Typography
                      component="span"
                      className={classes.mediumLineHeight}
                    >
                      <Badge
                        classes={{ badge: classes.customBadge }}
                        badgeContent={3}
                      ></Badge>
                    </Typography>
                  </Grid>
                  <Grid item xs={9} sm={10} md={11} lg={11} xl={11}>
                    <Typography>
                      {" "}
                      {
                        "Choose the right SucceedSmart plan for your hiring needs. Your plan includes a one month free trial."
                      }{" "}
                    </Typography>
                  </Grid>
                </div>
                <Typography
                  className={classes.verySmallTopMargin}
                  component="div"
                >
                  We’ll take your information and verify your company. Every
                  company must go through a verification process before
                  accessing our exclusive platform and verified candidates.
                </Typography>
              </div>
            </Grid>
            <Button
              size="large"
              id="continueBtn"
              className={classes.smallTopMargin}
              style={{ marginBottom: "15px" }}
              type="submit"
              disableElevation
              variant="contained"
              color="primary"
              onClick={(event) => {
                this.handleContinueClick(event);
              }}
            >
              Continue
            </Button>
          </Grid>
        </Grid>
      </Fragment>
    );
  }
}

SignUpConfirm.propTypes = {
  classes: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => {
  return state;
};

const connectedSignUpConfirmPage = withRouter(
  connect(mapStateToProps, null, null, {
    pure: false,
  })(withStyles(styles)(SignUpConfirm))
);

export { connectedSignUpConfirmPage as SignUpConfirm };
