import React from "react";
import { Grid, Avatar, Divider, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import moment from "moment";
import EmptyState from "../core/emptyState";
import EducationIcon from "../../assets/education.png";
import { capitalize } from "../../helpers";

const useStyles = makeStyles(() => ({
  headingGrid: {
    textAlign: "left",
    fontWeight: "bolder",
  },
  headingText: {
    position: "absolute",
    right: 55,
    top: 17,
  },
  divDegree: {
    textAlign: "left",
    fontWeight: "bold",
  },
  textAlignLeftCustom: {
    textAlign: "left",
  },
  padding20: {
    padding: "20px",
  },
  mainGrid: {
    marginTop: "20px",
    padding: "20px",
  },
}));

function Education(props) {
  const { education } = props;
  const classes = useStyles();
  const EMPTY_ICON_HEIGHT = 120;
  const EMPTY_ICON_WIDTH = 120;

  return (
    <Grid container>
      <Grid
        item
        sm={12}
        xs={12}
        md={12}
        lg={12}
        xl={12}
        className={classes.mainGrid}
      >
        <Typography variant="h6" className={classes.headingGrid}>
          Education
        </Typography>
        <Divider className={classes.dividerTopTab} />
        <Grid container>
          {education?.length > 0 ? (
            education?.map((edu, index) => (
              <>
                <Grid
                  item
                  sm={12}
                  xs={12}
                  md={1}
                  lg={1}
                  xl={1}
                  style={{
                    alignSelf: "center",
                    marginTop: index !== 0 ? "35px" : "25px",
                  }}
                >
                  <Avatar
                    src={EducationIcon}
                    variant="square"
                    alt="Education Icon"
                  />
                </Grid>
                <Grid
                  item
                  sm={12}
                  xs={12}
                  md={11}
                  lg={11}
                  xl={11}
                  style={{ marginTop: index !== 0 ? "35px" : "25px" }}
                >
                  <Typography component="div" className={classes.divDegree}>
                    {(capitalize(edu?.degree_name) || "") +
                      (capitalize(edu?.field_of_study)
                        ? ` - ${edu?.field_of_study}`
                        : "")}
                  </Typography>
                  <Typography
                    component="div"
                    className={classes.textAlignLeftCustom}
                  >
                    {capitalize(edu?.school) || ""}
                  </Typography>
                  <Typography
                    component="div"
                    color="textSecondary"
                    className={classes.textAlignLeftCustom}
                  >
                    {edu?.starts_at !== "" &&
                    edu?.starts_at !== null &&
                    edu?.ends_at !== "" &&
                    edu?.ends_at !== null
                      ? `${moment(edu?.starts_at).format("MM/YYYY")} - ${moment(
                          edu?.ends_at
                        ).format("MM/YYYY")}`
                      : edu?.starts_at !== "" &&
                        edu?.starts_at !== null &&
                        (edu?.ends_at === "" || edu?.ends_at === null)
                      ? `${moment(edu?.starts_at).format("MM/YYYY")} - `
                      : (edu?.starts_at === "" || edu?.starts_at === null) &&
                        edu?.ends_at !== "" &&
                        edu?.ends_at !== null
                      ? ` - ${moment(edu?.ends_at).format("MM/YYYY")}`
                      : ""}
                  </Typography>
                </Grid>
              </>
            ))
          ) : (
            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
              <EmptyState
                type="education"
                height={EMPTY_ICON_HEIGHT}
                width={EMPTY_ICON_WIDTH}
              />
            </Grid>
          )}
        </Grid>
      </Grid>
    </Grid>
  );
}

export default Education;
