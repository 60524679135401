import React, { Component } from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import {
  Grid,
  Typography,
  Button,
  Dialog,
  IconButton,
} from "@material-ui/core";
import MuiDialogTitle from "@material-ui/core/DialogTitle";
import MuiDialogContent from "@material-ui/core/DialogContent";
import MuiDialogActions from "@material-ui/core/DialogActions";
import CloseIcon from "@material-ui/icons/Close";
import cardHeaderBg from "../../../assets/lite_profile_bg.png";
import { paymentActions } from "../../../actions";
import { authActions } from "../../../actions";
import { history } from "../../../helpers";
import GetHelp from "../../core/gethelp";
import classNames from "classnames";
import moment from "moment";
import { grey } from "@material-ui/core/colors";
import secureLocalStorage from "react-secure-storage";

const styles = (theme) => ({
  root: {
    // minWidth: 275,
    marginTop: "90px",
    width: "100%",
  },
  buttonGroup: {
    "& > *": {
      margin: theme.spacing(1),
    },
  },
  paper: {
    border: "1px solid",
    padding: theme.spacing(1),
    backgroundColor: theme.palette.background.paper,
  },
  closeButton: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.default.main,
    padding: "0px",
  },
  appFrame: {
    zIndex: 1,
    overflow: "hidden",
    position: "relative",
    // display: 'flex',
    // width: '100%',
    padding: theme.spacing(7, 6),
    // backgroundColor: '#F7F7F7',
    paddingBottom: "1.5%",
  },
  content: {
    flexGrow: 1,
    marginTop: "0.2%",
    padding: theme.spacing(0, 6),
  },
  bullet: {
    display: "inline-block",
    margin: "0 2px",
    transform: "scale(0.8)",
  },
  title: {
    fontSize: 14,
  },
  pos: {
    marginBottom: 12,
  },
  largeAvatar: {
    width: theme.spacing(7),
    height: theme.spacing(7),
  },
  xLargeAvatar: {
    width: theme.spacing(10),
    height: theme.spacing(10),
    backgroundColor: "#000",
  },
  moduleConfig: {
    float: "left",
    fontSize: "26px",
  },
  demo1: {
    marginTop: "5%",
  },
  floatRight: {
    float: "right",
  },
  floatLeft: {
    float: "left",
  },
  largeTopMargin: {
    marginTop: "10%",
  },
  verySmallTopMargin: {
    marginTop: "1%",
  },
  formControl: {
    margin: theme.spacing(0, 0),
    minWidth: "100%",
  },
  mediumTopMargin: {
    marginTop: "4%",
  },
  displayFlex: {
    display: "flex",
  },
  noPadding: {
    padding: "0 !important",
  },
  fullWidth: {
    width: "100%",
  },
  colorDefault: {
    color: "#000000 !important",
  },
  colorCustom: {
    color: "#303030",
  },
  dialogTitle: {
    color: "#FBC112",
  },
  displayInline: {
    display: "inline",
  },
  noBottomMargin: {
    marginBottom: "0",
  },
  smallTopMargin: {
    marginTop: "3%",
  },
  chip: {
    margin: theme.spacing(0.5),
  },
  input: {
    marginLeft: theme.spacing(1),
    flex: 1,
  },
  iconButton: {
    padding: 10,
  },
  noWrap: {
    whiteSpace: "nowrap",
  },
  subHeader: {
    fontSize: "large",
    marginTop: "4%",
    fontWeight: "600",
    color: "#000000",
  },
  header: {
    fontSize: "x-large",
    marginTop: "4%",
    fontWeight: "600",
    color: "#000000",
  },
  jobCardHeader: {
    fontSize: "x-large",
    fontWeight: "500",
    color: "#000000",
    textAlign: "left",
  },
  jobCardSubHeader: {
    fontSize: "large",
    fontWeight: "500",
    textAlign: "left",
  },
  customCard: {
    backgroundColor: theme.palette.basic.main,
  },
  customCardShadow: {
    boxShadow: "none",
  },
  textAlignEnd: {
    textAlign: "end",
  },
  textAlignCenter: {
    textAlign: "center",
  },
  jobCard: {
    boxShadow:
      "0px 1px 1px 0px rgb(0 0 0 / 20%), 0px 1px 0px 0px rgb(0 0 0 / 14%), 0px 0px 2px 1px rgb(0 0 0 / 12%)",
    cursor: "pointer",
  },
  smallBottomMargin: {
    marginBottom: "2%",
  },
  square: {
    color: theme.palette.getContrastText(grey[300]),
    backgroundColor: grey[300],
    border: "2px solid #FBC112",
    width: "7em",
    height: "4em",
  },
  smallFont: {
    fontSize: "14px",
  },

  largeFont: {
    fontSize: "x-large",
  },

  customBadgeGrid: {
    textAlign: "center",
    overflowWrap: "break-word",
    padding: "2%",
  },
  customLink: {
    cursor: "pointer",
    "&:hover": {
      textDecoration: "underline",
    },
    color: "#048475 !important",
  },
  alignSelfCenter: { alignSelf: "center !important" },
  breadcrumbLink: {
    cursor: "pointer",
    "&:hover": {
      textDecoration: "underline",
    },
    // color: '#048475 !important'
  },
  moreIcon: {
    justifyContent: "space-between",
    cursor: "pointer",
    overflowWrap: "anywhere",
  },
  customMoreIcon: {
    fontSize: "2.5rem",
    // '&:hover':{
    //     transform: 'rotate(90deg)',
    //     transition: 'all .3s ease'
    // }
  },
  customDialog: {
    backgroundColor: "#000000",
    width: "unset",
  },
  justifyContentFlexEnd: {
    justifyContent: "flex-end",
  },
  justifyContentCenter: {
    justifyContent: "center !important",
  },
  responsive: {
    maxWidth: "100% !important",
    height: "auto !important",
    width: "auto !important",
    // alignSelf: 'center !important',
    minWidth: "fit-content !important",
  },
  liteProfileCard: {
    // boxShadow: '0px 1px 1px 0px rgb(0 0 0 / 0%), 0px 1px 0px 0px rgb(0 0 0 / 5%), 0px 0px 2px 1px rgb(0 0 0 / 12%)',
    borderBottom: "10px solid #FBC112",
    maxHeight: "47em",
    height: "47em",
    // borderRadius: '8px'
    borderRadius: "0px",
    // backgroundColor: '#F7F7F7',
    // baclgroundImage: 'url(src/assets/lite_profile_bg.png)'
    // cursor: 'pointer'
  },
  maxHeight40P: { maxHeight: "40px" },
  customCardActions: { display: "block", marginTop: "1%" },
  textAlignLeft: { textAlign: "left" },
  textAlignRight: { textAlign: "right" },
  verySmallFont: { fontSize: ".8rem" },
  font10p: { fontSize: "10px" },
  font14p: { fontSize: "12px" },
  alignBaslineMiddle: { verticalAlign: "-webkit-baseline-middle" },
  verySmallMarginLeft: { marginLeft: "3px" },
  smallMarginLeft: { marginLeft: "8px" },
  mediumMarginLeft: { marginLeft: "12px" },
  boldFont: { fontWeight: "bold" },
  noBoxShadow: { boxShadow: "none" },
  noBorderBottom: { borderBottom: "none" },
  viewMore: { color: "#302F2C", cursor: "pointer", fontWeight: "bold" },
  alignWebkitCenter: { textAlign: "-webkit-center" },
  justifyContentSpaceEvenly: { justifyContent: "space-evenly" },
  justifyContentSpaceBetween: { justifyContent: "space-between" },
  // bgChangeOnHover: {'&:hover':{backgroundColor: '#FBC112'}},
  verySmallMarginRight: { marginRight: "2%" },
  customChip: { width: "100%", marginTop: "3%", borderRadius: "10px" },
  cursorPointer: {
    cursor: "pointer",
  },
  customHighlightsPaper: {
    paddingLeft: "3%",
    paddingRight: "3%",
    paddingBottom: "0",
    boxShadow: "none",
    borderRadius: "10px",
    border: "2px solid #e0e0e0",
    // height: '100%',
    // maxHeight: '100%',
    overflowY: "auto",
  },
  smallLeftPadding: { paddingLeft: "8px" },
  equalButtons: { width: "150px", margin: "3%" },
  // equalButtons: {width: '45vw', margin: '3%'},
  fixedTitleTableHeight: { height: "130px !important", marginTop: "10%" },
  fixedTableHeight: { height: "115px !important" },
  fixedTitleHeight: { height: "80px !important" },
  defaultButton: {
    backgroundColor: "#FFFFFF",
    border: "#000000 solid 1px",
    marginRight: "1%",
  },
  halfWidth: { width: "100%" },
  customDialogHeader: {
    maxHeight: "38%",
    height: "38%",
    color: "#000",
    backgroundSize: "cover",
    backgroundImage: `url(${cardHeaderBg})`,
    background: "round",
  }, //height: '180px'
});

const DialogTitle = withStyles(styles)((props) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography {...other}>
      {/* className={classes.root} */}
      className={classes.customDialogHeader} style={{ padding: "3%" }}
      <Typography variant="h5" align="center">
        {children}
      </Typography>
      {onClose ? (
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={onClose}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const DialogContent = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
    paddingLeft: theme.spacing(4),
    paddingRight: theme.spacing(4),
  },
}))(MuiDialogContent);

const DialogActions = withStyles((theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
    backgroundColor: "#fff",
    justifyContent: "flex-end !important",
  },
}))(MuiDialogActions);

class ConfirmAuthentication extends Component {
  constructor(props) {
    super(props);
    this.state = this.getInitialState();
  }

  getInitialState = () => ({
    value: 0,
    open: false,
    // authenticationInitiated: false,
    userDetails: JSON.parse(secureLocalStorage.getItem("userDetails")),
  });

  componentDidMount() {
    document.body.scrollTop = 0; // For Safari
    document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE and Opera
  }

  // componentDidMount(){
  //   if(this.props.payment.open === false && this.props.payment.authenticationInitiated === true){
  //     this.setState({authenticationInitiated: true});
  //   }
  // }

  componentDidUpdate(prevProps) {
    if (prevProps.payment.open !== this.props.payment.open) {
      this.setState({ open: this.props.payment.open });
    }
    // if(prevProps.payment.authenticationInitiated !== this.props.payment.authenticationInitiated){
    //   this.setState({authenticationInitiated: this.props.payment.authenticationInitiated});
    // }
  }

  capitalize = function (str) {
    if (str !== null && str !== undefined) {
      return str
        .split(" ")
        .map((w) => w.substring(0, 1).toUpperCase() + w.substring(1))
        .join(" ");
    } else return str;
  };

  handleCancel = () => {
    history.push("/payment");
  };

  handleFinalSubmitClick = () => {
    const { dispatch } = this.props;
    let payload = {
      email: JSON.parse(secureLocalStorage.getItem("userDetails")).email,
    };
    dispatch(paymentActions.finalPayment(payload));
  };

  handlePaymentSuccess = () => {
    const { dispatch } = this.props;
    let payload = {
      email: this.state.userDetails.email,
    };
    dispatch(authActions.getRecruiterOnboardingStatus(payload));
  };

  render() {
    const { classes } = this.props;
    const paymentData = this.props.payment;
    // let companyData = JSON.parse(secureLocalStorage.getItem('recruiterStatusDetails')).company;
    return (
      <>
        {/* <AppBar /> */}
        <div className={classes.root}>
          <div className={classes.appFrame}></div>
          <main className={classes.content}>
            <Grid container className={classes.justifyContentCenter}>
              <Grid item xs={12} sm={12} md={10} lg={6} xl={6}>
                <Grid container className={classes.justifyContentCenter}>
                  <Grid item xs={12} sm={8} md={6} lg={6} xl={6}>
                    <Typography
                      className={classes.smallBottomMargin}
                      variant="h4"
                      component="div"
                    >
                      {"Authentication Complete"}
                    </Typography>
                    <Typography
                      component="div"
                      color="textSecondary"
                      className={classes.mediumTopMargin}
                    >
                      Authentication was successful. Please click the 'Submit'
                      button below to pay for subscription.
                    </Typography>
                  </Grid>
                </Grid>
                <Grid
                  container
                  className={classNames(
                    classes.justifyContentCenter,
                    classes.smallTopMargin
                  )}
                >
                  <Grid item xs={12} sm={12} md={10} lg={7} xl={7}>
                    <h4 className="customPaymentHeader">
                      <span>Trial Details</span>
                    </h4>
                  </Grid>
                </Grid>

                <Grid container className={classes.justifyContentCenter}>
                  <Grid
                    item
                    xs={12}
                    sm={8}
                    md={6}
                    lg={6}
                    xl={6}
                    className={classes.displayFlex}
                  >
                    <Grid
                      item
                      xs={6}
                      sm={6}
                      md={6}
                      lg={4}
                      xl={4}
                      className={classNames(
                        classes.textAlignLeft,
                        classes.smallBottomMargin
                      )}
                    >
                      <Typography component="span" color="textSecondary">
                        Trial Period
                      </Typography>
                    </Grid>
                    <Grid
                      item
                      xs={6}
                      sm={6}
                      md={6}
                      lg={8}
                      xl={8}
                      className={classNames(
                        classes.textAlignEnd,
                        classes.smallBottomMargin
                      )}
                    >
                      <Typography component="span">1 month</Typography>
                    </Grid>
                  </Grid>
                </Grid>

                <Grid container className={classes.justifyContentCenter}>
                  <Grid
                    item
                    xs={12}
                    sm={8}
                    md={6}
                    lg={6}
                    xl={6}
                    className={classes.displayFlex}
                  >
                    <Grid
                      item
                      xs={6}
                      sm={6}
                      md={6}
                      lg={4}
                      xl={4}
                      className={classNames(
                        classes.textAlignLeft,
                        classes.smallBottomMargin
                      )}
                    >
                      <Typography component="span" color="textSecondary">
                        Start Date
                      </Typography>
                    </Grid>
                    <Grid
                      item
                      xs={6}
                      sm={6}
                      md={6}
                      lg={8}
                      xl={8}
                      className={classNames(
                        classes.textAlignEnd,
                        classes.smallBottomMargin
                      )}
                    >
                      <Typography component="span">
                        {moment().toDate().toDateString()}
                      </Typography>
                    </Grid>
                  </Grid>
                </Grid>

                <Grid container className={classes.justifyContentCenter}>
                  <Grid item xs={12} sm={12} md={6} lg={7} xl={7}>
                    <h4 className="customPaymentHeader">
                      <span>Subscription Details</span>
                    </h4>
                  </Grid>
                </Grid>

                <Grid container className={classes.justifyContentCenter}>
                  <Grid
                    item
                    xs={12}
                    sm={8}
                    md={6}
                    lg={6}
                    xl={6}
                    className={classes.displayFlex}
                  >
                    <Grid
                      item
                      xs={6}
                      sm={6}
                      md={6}
                      lg={4}
                      xl={4}
                      className={classNames(
                        classes.textAlignLeft,
                        classes.smallBottomMargin
                      )}
                    >
                      <Typography component="span" color="textSecondary">
                        Plan Type
                      </Typography>
                    </Grid>
                    <Grid
                      item
                      xs={6}
                      sm={6}
                      md={6}
                      lg={8}
                      xl={8}
                      className={classNames(
                        classes.textAlignEnd,
                        classes.smallBottomMargin
                      )}
                    >
                      <Typography component="span">
                        {paymentData?.paymentPlan}
                      </Typography>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid container className={classes.justifyContentCenter}>
                  <Grid
                    item
                    xs={12}
                    sm={8}
                    md={6}
                    lg={6}
                    xl={6}
                    className={classes.displayFlex}
                  >
                    <Grid
                      item
                      xs={6}
                      sm={6}
                      md={6}
                      lg={4}
                      xl={4}
                      className={classNames(
                        classes.textAlignLeft,
                        classes.smallBottomMargin
                      )}
                    >
                      <Typography component="span" color="textSecondary">
                        Billed On
                      </Typography>
                    </Grid>
                    <Grid
                      item
                      xs={6}
                      sm={6}
                      md={6}
                      lg={8}
                      xl={8}
                      className={classNames(
                        classes.textAlignEnd,
                        classes.smallBottomMargin
                      )}
                    >
                      <Typography component="span">
                        {moment().add(1, "M").toDate().toDateString()}
                      </Typography>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid container className={classes.justifyContentCenter}>
                  <Grid
                    item
                    xs={12}
                    sm={8}
                    md={6}
                    lg={6}
                    xl={6}
                    className={classes.displayFlex}
                  >
                    <Grid
                      item
                      xs={6}
                      sm={6}
                      md={6}
                      lg={4}
                      xl={4}
                      className={classNames(
                        classes.textAlignLeft,
                        classes.smallBottomMargin
                      )}
                    >
                      <Typography component="span" color="textSecondary">
                        Payment Method
                      </Typography>
                    </Grid>
                    <Grid
                      item
                      xs={6}
                      sm={6}
                      md={6}
                      lg={8}
                      xl={8}
                      className={classNames(
                        classes.textAlignEnd,
                        classes.smallBottomMargin
                      )}
                    >
                      <Typography component="span">
                        {paymentData?.paymentInfo.type === "card"
                          ? this.capitalize(
                              paymentData?.paymentInfo.card.brand
                            ) +
                            " " +
                            this.capitalize(paymentData?.paymentInfo.type) +
                            " ending in ****" +
                            paymentData?.paymentInfo.card.last4
                          : "Bank Account"}
                      </Typography>
                    </Grid>
                  </Grid>
                </Grid>

                {paymentData?.paymentInfo.type === "bank_account" ? (
                  <>
                    <Grid container className={classes.justifyContentCenter}>
                      <Grid
                        item
                        xs={12}
                        sm={8}
                        md={6}
                        lg={6}
                        xl={6}
                        className={classes.displayFlex}
                      >
                        <Grid
                          item
                          xs={6}
                          sm={6}
                          md={6}
                          lg={4}
                          xl={4}
                          className={classNames(
                            classes.textAlignLeft,
                            classes.smallBottomMargin
                          )}
                        >
                          <Typography component="span" color="textSecondary">
                            Bank Name
                          </Typography>
                        </Grid>
                        <Grid
                          item
                          xs={6}
                          sm={6}
                          md={6}
                          lg={8}
                          xl={8}
                          className={classNames(
                            classes.textAlignEnd,
                            classes.smallBottomMargin
                          )}
                        >
                          <Typography component="span">
                            {this.capitalize(
                              paymentData?.paymentInfo.bank_account.bank_name
                            )}
                          </Typography>
                        </Grid>
                      </Grid>
                    </Grid>

                    <Grid container className={classes.justifyContentCenter}>
                      <Grid
                        item
                        xs={12}
                        sm={8}
                        md={6}
                        lg={6}
                        xl={6}
                        className={classes.displayFlex}
                      >
                        <Grid
                          item
                          xs={6}
                          sm={6}
                          md={6}
                          lg={4}
                          xl={4}
                          className={classNames(
                            classes.textAlignLeft,
                            classes.smallBottomMargin
                          )}
                        >
                          <Typography component="span" color="textSecondary">
                            Name on Account
                          </Typography>
                        </Grid>
                        <Grid
                          item
                          xs={6}
                          sm={6}
                          md={6}
                          lg={8}
                          xl={8}
                          className={classNames(
                            classes.textAlignEnd,
                            classes.smallBottomMargin
                          )}
                        >
                          <Typography component="span">
                            {this.capitalize(
                              paymentData?.paymentInfo.bank_account.name
                            )}
                          </Typography>
                        </Grid>
                      </Grid>
                    </Grid>

                    <Grid container className={classes.justifyContentCenter}>
                      <Grid
                        item
                        xs={12}
                        sm={8}
                        md={6}
                        lg={6}
                        xl={6}
                        className={classes.displayFlex}
                      >
                        <Grid
                          item
                          xs={6}
                          sm={6}
                          md={6}
                          lg={4}
                          xl={4}
                          className={classNames(
                            classes.textAlignLeft,
                            classes.smallBottomMargin
                          )}
                        >
                          <Typography component="span" color="textSecondary">
                            Routing Number
                          </Typography>
                        </Grid>
                        <Grid
                          item
                          xs={6}
                          sm={6}
                          md={6}
                          lg={8}
                          xl={8}
                          className={classNames(
                            classes.textAlignEnd,
                            classes.smallBottomMargin
                          )}
                        >
                          <Typography component="span">
                            {
                              paymentData?.paymentInfo.bank_account
                                .routing_number
                            }
                          </Typography>
                        </Grid>
                      </Grid>
                    </Grid>

                    <Grid container className={classes.justifyContentCenter}>
                      <Grid
                        item
                        xs={12}
                        sm={8}
                        md={6}
                        lg={6}
                        xl={6}
                        className={classes.displayFlex}
                      >
                        <Grid
                          item
                          xs={6}
                          sm={6}
                          md={6}
                          lg={4}
                          xl={4}
                          className={classNames(
                            classes.textAlignLeft,
                            classes.smallBottomMargin
                          )}
                        >
                          <Typography component="span" color="textSecondary">
                            Account Number
                          </Typography>
                        </Grid>
                        <Grid
                          item
                          xs={6}
                          sm={6}
                          md={6}
                          lg={8}
                          xl={8}
                          className={classNames(
                            classes.textAlignEnd,
                            classes.smallBottomMargin
                          )}
                        >
                          <Typography component="span">
                            {"**** " +
                              paymentData?.paymentInfo.bank_account.last4}
                          </Typography>
                        </Grid>
                      </Grid>
                    </Grid>
                  </>
                ) : null}
                {/* this.capitalize(paymentData?.paymentInfo.bank_account.bank_name) + ' ' + this.capitalize(paymentData?.paymentInfo.type) + ' ending in ****' + paymentData?.paymentInfo.bank_account.last4 */}
                <Grid container className={classes.justifyContentCenter}>
                  <Grid
                    item
                    xs={12}
                    sm={8}
                    md={6}
                    lg={6}
                    xl={6}
                    className={classes.displayFlex}
                  >
                    <Grid
                      item
                      xs={6}
                      sm={6}
                      md={6}
                      lg={5}
                      xl={5}
                      className={classNames(
                        classes.textAlignLeft,
                        classes.smallBottomMargin
                      )}
                    >
                      <Typography component="span" color="textSecondary">
                        Total Amount Billed ({paymentData?.paymentPlan})
                      </Typography>
                    </Grid>
                    <Grid
                      item
                      xs={6}
                      sm={6}
                      md={6}
                      lg={7}
                      xl={7}
                      className={classNames(
                        classes.textAlignEnd,
                        classes.smallBottomMargin
                      )}
                    >
                      <Typography component="span">
                        {"$" + paymentData?.totalAmount}
                      </Typography>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>

            <Grid
              container
              className={classNames(
                classes.justifyContentCenter,
                classes.smallBottomMargin,
                classes.smallTopMargin
              )}
            >
              <Grid item xs={12} sm={6} md={10} lg={12} xl={12}>
                <div
                  className={classNames(
                    classes.displayFlex,
                    classes.justifyContentCenter,
                    classes.buttonGroup
                  )}
                >
                  <Button
                    variant="contained"
                    color="primary"
                    disableElevation
                    onClick={(event) => {
                      this.handleCancel(event);
                    }}
                  >
                    Cancel
                  </Button>
                  {/* <Button variant='contained' color='primary' disableElevation onClick={event => {this.handleSubmitClick(event)}}>Next</Button> */}
                  <Button
                    variant="contained"
                    color="primary"
                    disableElevation
                    onClick={(event) => {
                      this.handleFinalSubmitClick(event);
                    }}
                  >
                    Submit
                  </Button>
                </div>
              </Grid>
            </Grid>

            {/* 'Payment Completed' dialog */}
            <Dialog
              disableBackdropClick
              disableEscapeKeyDown
              aria-labelledby="customized-dialog-title"
              open={this.state.open}
            >
              <DialogTitle>Thank You!</DialogTitle>
              <DialogContent>
                <div className={classes.dialogPaper}>
                  <Typography component="span" className={classes.xxLargeFont}>
                    Your payment has been approved.{" "}
                  </Typography>
                </div>
              </DialogContent>
              <DialogActions className={classes.dialogActions}>
                <Button
                  variant="contained"
                  color="primary"
                  className={classes.submit}
                  disableElevation
                  size="large"
                  onClick={(event) => {
                    this.handlePaymentSuccess(event);
                  }}
                >
                  Continue
                </Button>
              </DialogActions>
            </Dialog>

            {/* 'Authentication Completed' dialog */}
            <Dialog
              disableBackdropClick
              disableEscapeKeyDown
              aria-labelledby="customized-dialog-title"
              open={this.state.authenticationInitiated}
            >
              <DialogTitle>Thank You!</DialogTitle>
              <DialogContent>
                <div className={classes.dialogPaper}>
                  <Typography component="span" className={classes.xxLargeFont}>
                    Authentication completed!{" "}
                  </Typography>
                </div>
              </DialogContent>
              <DialogActions className={classes.dialogActions}>
                <Button
                  variant="contained"
                  color="primary"
                  className={classes.submit}
                  disableElevation
                  size="large"
                  onClick={(event) => {
                    this.handlePaymentSuccess(event);
                  }}
                >
                  Continue
                </Button>
              </DialogActions>
            </Dialog>
            <GetHelp />
          </main>
        </div>
      </>
    );
  }
}

ConfirmAuthentication.propTypes = {
  classes: PropTypes.object.isRequired,
};

function mapStateToProps(state) {
  return state;
}

const connectedConfirmAuthPage = withRouter(
  connect(mapStateToProps, null, null, {
    pure: false,
  })(withStyles(styles)(ConfirmAuthentication))
);

export { connectedConfirmAuthPage as ConfirmAuthentication };
