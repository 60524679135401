import React, { useState } from "react";
import {
  Grid,
  Button,
  Typography,
  DialogTitle,
  IconButton,
  Dialog,
  DialogContent,
  DialogActions,
  TextField,
  FormControlLabel,
} from "@material-ui/core";
import validator from "validator";
import classNames from "classnames";
import { makeStyles } from "@material-ui/core/styles";
import IndeterminateCheckBoxIcon from "@material-ui/icons/IndeterminateCheckBox";
import AddBoxIcon from "@material-ui/icons/AddBox";
import { toast } from "react-toastify";
import { useDispatch } from "react-redux";
import CloseIcon from "@material-ui/icons/Close";
import cardHeaderBg from "../../assets/lite_profile_bg.png";
import { MESSAGE_FIELD_IS_REQUIRED } from "../../constants/constants";
import { notAllowWhiteSpaceAtStart, trim } from "../../helpers";
import { membersActions } from "../../actions/members.actions";
import secureLocalStorage from "react-secure-storage";

const useStyles = makeStyles((theme) => ({
  customCardHeader: {
    maxHeight: "38%",
    height: "38%",
    color: "#000",
    backgroundSize: "cover",
    backgroundImage: `url(${cardHeaderBg})`,
    background: "round",
  },
  customShareSection: {
    boxShadow:
      "0px 1px 1px 0px rgb(0 0 0 / 0%), 0px 1px 5px 0px rgb(0 0 0 / 20%), 0px 0px 1px 1px rgb(0 0 0 / 12%)",
    borderRadius: "10px",
    padding: "3%",
  },
  mediumTopMargin: {
    marginTop: "5%",
  },
  customBottomMargin: { marginBottom: "4%" },
  floatRight: {
    float: "right",
  },
  colorDefault: {
    color: "#000000 !important",
  },
  closeButton: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.default.main,
    padding: "0px",
  },
  verySmallTopMargin: {
    marginTop: "1%",
  },
}));

const charCount = 50;
const reasonCharCount = 100;

function ShareProfile({ open, onClose, candidateId, recommendationId }) {
  const classes = useStyles();
  const [fields, setFields] = useState([
    {
      fNameValue: "",
      lNameValue: "",
      emailValue: "",
      titleValue: "",
      isValidEmail: true,
      isValidFirstName: true,
      isValidLastName: true,
      isValidTitle: true,
      isEmailRequired: false,
      isFirstNameRequired: false,
      isLastNameRequired: false,
      isTitleRequired: false,
    },
  ]);
  const dispatch = useDispatch();
  const handleChange = (i, event) => {
    const values = [...fields];
    const inputValue = notAllowWhiteSpaceAtStart(event.target.value);
    switch (event.currentTarget.name) {
      case "firstName":
        values[i].fNameValue = inputValue;
        values[i].isValidFirstName = inputValue
          ? validator.matches(inputValue, /^.{2,50}$/)
          : true;
        values[i].isFirstNameRequired = validator.isEmpty(inputValue);
        break;
      case "lastName":
        values[i].lNameValue = inputValue;
        values[i].isValidLastName = inputValue
          ? validator.matches(inputValue, /^.{2,50}$/)
          : true;
        values[i].isLastNameRequired = validator.isEmpty(inputValue);
        break;
      case "email":
        values[i].emailValue = inputValue;
        values[i].isValidEmail = inputValue
          ? validator.isEmail(inputValue)
          : true;
        values[i].isEmailRequired = validator.isEmpty(inputValue);

        if (
          inputValue.includes("@yahoo.com") ||
          inputValue.includes("@gmail.com") ||
          inputValue.includes("@live.com") ||
          inputValue.includes("@rediffmail.com") ||
          inputValue.includes("@hotmail.com") ||
          inputValue.includes("@ymail.com") ||
          inputValue.includes("@outlook.com") ||
          inputValue.includes("@yopmail.com")
        ) {
          values[i].isValidEmail = false;
        }
        break;
      case "title":
        values[i].titleValue = inputValue;
        values[i].isTitleRequired = validator.isEmpty(inputValue);
        values[i].isValidTitle = inputValue
          ? validator.matches(inputValue, /^.{2,100}$/)
          : true;
        break;
      default:
        break;
    }
    setFields(values);
  };

  const handleRemove = (i) => {
    const values = [...fields];
    values.splice(i, 1);
    setFields(values);
  };

  const initStateValue = () => {
    setFields([
      {
        fNameValue: "",
        lNameValue: "",
        emailValue: "",
        titleValue: "",
        isValidEmail: true,
        isValidFirstName: true,
        isValidLastName: true,
        isValidTitle: true,
        isEmailRequired: false,
        isFirstNameRequired: false,
        isLastNameRequired: false,
        isTitleRequired: false,
      },
    ]);
  };

  const handleAdd = () => {
    const values = [...fields];
    values.push({
      fNameValue: "",
      lNameValue: "",
      emailValue: "",
      titleValue: "",
      isValidEmail: true,
      isValidFirstName: true,
      isValidLastName: true,
      isValidTitle: true,
      isEmailRequired: false,
      isFirstNameRequired: false,
      isLastNameRequired: false,
      isTitleRequired: false,
    });
    setFields(values);
  };

  const handleSend = (event) => {
    event.preventDefault();
    const checkArray = [];
    for (const item in fields) {
      if (
        fields[item].fNameValue === "" ||
        fields[item].fNameValue === null ||
        fields[item].lNameValue === "" ||
        fields[item].lNameValue === null ||
        fields[item].emailValue === "" ||
        fields[item].emailValue === null ||
        fields[item].titleValue === "" ||
        fields[item].titleValue === null ||
        !fields[item].isValidFirstName ||
        !fields[item].isValidLastName ||
        !fields[item].isValidEmail ||
        !fields[item].isValidTitle
      ) {
        const value = [...fields];
        value[item].isFirstNameRequired = validator.isEmpty(
          fields[item].fNameValue
        );
        value[item].isLastNameRequired = validator.isEmpty(
          fields[item].lNameValue
        );
        value[item].isEmailRequired = validator.isEmpty(
          fields[item].emailValue
        );
        value[item].isTitleRequired = validator.isEmpty(
          fields[item].titleValue
        );
        setFields(value);
      } else {
        const payloadObject = {
          first_name: trim(fields[item].fNameValue),
          last_name: trim(fields[item].lNameValue),
          email: trim(fields[item].emailValue),
          title: trim(fields[item].titleValue),
        };
        checkArray.push(payloadObject);
      }
    }
    if (fields.length === checkArray.length) {
      const payload = {
        recommendation_id: recommendationId,
        candidate_id: candidateId,
        shareWith: checkArray,
      };
      dispatch(
        membersActions.shareProfileLink(
          payload,
          JSON.parse(secureLocalStorage.getItem("recruiterStatusDetails"))
            .recruiter_id
        )
      );
      initStateValue();
      onClose();
    } else {
      toast.dark("Please fill up all the details with correct information");
    }
  };
  return (
    <Dialog
      disableBackdropClick
      disableEscapeKeyDown
      aria-labelledby="customized-dialog-title"
      open={open}
    >
      <DialogTitle className={classes.customCardHeader}>
        <Typography variant="h5" align="center" style={{ padding: "10px" }}>
          Share candidate profile with members
        </Typography>
        <Typography align="center" color="textSecondary">
          Complete the information below to send an invitation to a maximum of
          three members to view a candidate profile.{" "}
        </Typography>
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={() => {
            onClose();
            initStateValue();
          }}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        {fields?.map((field, idx) => (
          <div key={`${field}-${idx}`}>
            <Grid
              container
              spacing={2}
              className={classNames(
                classes.customShareSection,
                classes.mediumTopMargin,
                classes.customBottomMargin
              )}
            >
              {fields?.length > 1 ? (
                <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                  <FormControlLabel
                    className={classes.floatRight}
                    control={
                      <IconButton
                        className={classes.colorDefault}
                        onClick={() => handleRemove(idx)}
                      >
                        <IndeterminateCheckBoxIcon />
                      </IconButton>
                    }
                    label={<Typography>Remove</Typography>}
                  />
                </Grid>
              ) : null}
              <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
                <TextField
                  autoFocus
                  fullWidth
                  label="First Name"
                  autoComplete="firstName"
                  margin="dense"
                  required
                  inputProps={{ maxLength: charCount }}
                  id="firstName"
                  name="firstName"
                  value={field.fNameValue || ""}
                  onChange={(e) => handleChange(idx, e)}
                  error={!field.isValidFirstName || field.isFirstNameRequired}
                  helperText={
                    !field.isValidFirstName
                      ? "min 2 characters required"
                      : field.isFirstNameRequired
                      ? MESSAGE_FIELD_IS_REQUIRED
                      : ""
                  }
                />
                <Typography
                  className={classNames(
                    classes.floatRight,
                    classes.smallFont,
                    classes.verySmallTopMargin
                  )}
                  color="secondary"
                >
                  {`${fields[idx]?.fNameValue?.length}/${charCount}`} characters
                </Typography>
              </Grid>
              <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
                <TextField
                  fullWidth
                  label="Last Name"
                  autoComplete="lastName"
                  margin="dense"
                  required
                  inputProps={{ maxLength: charCount }}
                  id="lastName"
                  name="lastName"
                  value={field.lNameValue || ""}
                  onChange={(e) => handleChange(idx, e)}
                  error={!field.isValidLastName || field.isLastNameRequired}
                  helperText={
                    !field.isValidLastName
                      ? "min 2 characters required"
                      : field.isLastNameRequired
                      ? MESSAGE_FIELD_IS_REQUIRED
                      : ""
                  }
                />
                <Typography
                  className={classNames(
                    classes.floatRight,
                    classes.smallFont,
                    classes.verySmallTopMargin
                  )}
                  color="secondary"
                >
                  {`${fields[idx]?.lNameValue?.length}/${charCount}`} characters
                </Typography>
              </Grid>
              <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                <TextField
                  fullWidth
                  label="Title"
                  autoComplete="title"
                  margin="dense"
                  required
                  id="title"
                  name="title"
                  value={field.titleValue || ""}
                  onChange={(e) => handleChange(idx, e)}
                  inputProps={{ maxLength: reasonCharCount }}
                  error={!field.isValidTitle || field.isTitleRequired}
                  helperText={
                    !field.isValidTitle
                      ? "min 2 characters required"
                      : field.isTitleRequired
                      ? MESSAGE_FIELD_IS_REQUIRED
                      : ""
                  }
                />
                <Typography
                  className={classNames(
                    classes.floatRight,
                    classes.smallFont,
                    classes.verySmallTopMargin
                  )}
                  color="secondary"
                >
                  {`${fields[idx]?.titleValue?.length}/${reasonCharCount}`}{" "}
                  characters
                </Typography>
              </Grid>
              <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                <TextField
                  fullWidth
                  label="Corporate Email Address"
                  autoComplete="email"
                  margin="dense"
                  required
                  id="email"
                  name="email"
                  value={field.emailValue || ""}
                  onChange={(e) => handleChange(idx, e)}
                  error={!field.isValidEmail || field.isEmailRequired}
                  helperText={
                    !field.isValidEmail
                      ? "Invalid Email"
                      : field.isEmailRequired
                      ? MESSAGE_FIELD_IS_REQUIRED
                      : ""
                  }
                />
              </Grid>
            </Grid>
          </div>
        ))}
        {fields?.length < 3 ? (
          <FormControlLabel
            control={
              <IconButton onClick={() => handleAdd()}>
                <AddBoxIcon className={classes.colorDefault} />
              </IconButton>
            }
            label={<Typography>Add Another</Typography>}
            className={classes.verySmallTopMargin}
          />
        ) : null}
        {fields.length === 3 ? (
          <Typography color="textSecondary">
            You can share member profile with a maximum of three contacts at a
            time.
          </Typography>
        ) : null}
      </DialogContent>
      <DialogActions className={classes.dialogActions}>
        <Button
          variant="contained"
          color="primary"
          className={classes.submit}
          disableElevation
          size="large"
          onClick={handleSend}
        >
          Send
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default ShareProfile;
