import React from "react";
import { Grid, Avatar, Divider, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import moment from "moment";
import EmptyState from "../core/emptyState";
import { capitalize } from "../../helpers";
import CertificationIcon from "../../assets/certification.png";

const useStyles = makeStyles(() => ({
  headingGrid: {
    textAlign: "left",
    fontWeight: "bolder",
  },
  headingText: {
    position: "absolute",
    right: 55,
    top: 17,
  },
  divDegree: {
    textAlign: "left",
    fontWeight: "bold",
  },
  textAlignLeftCustom: {
    textAlign: "left",
  },
  padding20: {
    padding: "20px",
  },
  mainGrid: {
    marginTop: "20px",
    padding: "20px",
  },
}));

function Certification(props) {
  const { certification } = props;
  const classes = useStyles();
  const EMPTY_ICON_HEIGHT = 120;
  const EMPTY_ICON_WIDTH = 120;

  return (
    <Grid container>
      <Grid
        item
        sm={12}
        xs={12}
        md={12}
        lg={12}
        xl={12}
        className={classes.mainGrid}
      >
        <Typography variant="h6" className={classes.headingGrid}>
          Professional Certifications
        </Typography>
        <Divider className={classes.dividerTopTab} />
        <Grid container>
          {certification?.length > 0 ? (
            certification?.map((certification, index) => (
              <>
                <Grid
                  item
                  sm={12}
                  xs={12}
                  md={1}
                  lg={1}
                  xl={1}
                  style={{
                    alignSelf: "center",
                    marginTop: index !== 0 ? "35px" : "25px",
                  }}
                >
                  <Avatar
                    src={CertificationIcon}
                    variant="square"
                    alt="Education Icon"
                  />
                </Grid>
                <Grid
                  item
                  sm={12}
                  xs={12}
                  md={11}
                  lg={11}
                  xl={11}
                  style={{ marginTop: index !== 0 ? "35px" : "25px" }}
                >
                  <Typography
                    component="div"
                    align="left"
                    className={classes.certifName}
                  >
                    {capitalize(certification?.name) || ""}
                  </Typography>
                  <Typography
                    component="div"
                    align="left"
                    className={classes.mediumTopMargin}
                  >
                    {capitalize(certification?.orgnization) || ""}
                  </Typography>
                  <Typography
                    component="div"
                    align="left"
                    color="textSecondary"
                    className={classes.certifaDate}
                  >
                    {certification?.starts_at !== "" &&
                    certification?.starts_at !== null &&
                    certification?.ends_at !== "" &&
                    certification?.ends_at !== null
                      ? `${moment(certification?.starts_at).format(
                          "MM/YYYY"
                        )} - ${moment(certification?.ends_at).format(
                          "MM/YYYY"
                        )}`
                      : certification?.starts_at !== "" &&
                        certification?.starts_at !== null &&
                        (certification?.ends_at === "" ||
                          certification?.ends_at === null)
                      ? `${moment(certification?.starts_at).format(
                          "MM/YYYY"
                        )} - `
                      : (certification?.starts_at === "" ||
                          certification?.starts_at === null) &&
                        certification?.ends_at !== "" &&
                        certification?.ends_at !== null
                      ? ` - ${moment(certification?.ends_at).format("MM/YYYY")}`
                      : ""}
                  </Typography>
                </Grid>
              </>
            ))
          ) : (
            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
              <EmptyState
                type="professionalCertificates"
                height={EMPTY_ICON_HEIGHT}
                width={EMPTY_ICON_WIDTH}
              />
            </Grid>
          )}
        </Grid>
      </Grid>
    </Grid>
  );
}

export default Certification;
