import { Tooltip, Typography } from "@material-ui/core";
import React from "react";
import { makeStyles, withStyles } from "@material-ui/core/styles";

function CustomTooltip(props) {
  const { title, content, tooltipOn, textColor, backgroundColor } = props;

  // style of component
  const useStyles = makeStyles(() => ({
    titleStyle: {
      fontSize: "12px",
      fontWeight: 700,
    },
    contentStyle: {
      fontSize: "12px",
    },
  }));

  const CustomTooltip = withStyles((theme) => ({
    tooltip: {
      backgroundColor: backgroundColor || "#000000",
      color: textColor || "#ffffff",
      boxShadow: theme.shadows[1],
      fontSize: 11,
    },
  }))(Tooltip);

  const classes = useStyles();
  return (
    <CustomTooltip
      arrow
      title={
        <>
          <Typography className={classes.titleStyle}>{title}</Typography>
          <Typography className={classes.contentStyle}>{content}</Typography>
        </>
      }
    >
      {tooltipOn}
    </CustomTooltip>
  );
}

export default CustomTooltip;
