import { combineReducers } from "redux";
import { authentication, loggedIn } from "./auth.reducer";
import { jobs } from "./jobs.reducer";
import { members } from "./members.reducers";
import { loader } from "./loader.reducer";
import { messages } from "./messages.reducer";
import { payment } from "./payment.reducer";
import { myAccount } from "./myAccount.reducer";
import { shared } from "./shared.reducer";
import { pageTitle } from "./pageTitle.reducer";
import resetPasswordStep from "./setResetPasswordStep.reducer";
import emailForPasswordReset from "./saveEmailForPasswordReset.reducer";

const appReducer = combineReducers({
  authentication,
  jobs,
  members,
  loader,
  loggedIn,
  messages,
  payment,
  myAccount,
  shared,
  pageTitle,
  resetPasswordStep,
  emailForPasswordReset,
});

const rootReducer = (state, action) => {
  if (action.type === "SIGNOUT_SUCCESS") {
    return appReducer(undefined, action);
  }
  return appReducer(state, action);
};

export default rootReducer;
