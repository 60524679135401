import React, { Component } from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import Typography from "@material-ui/core/Typography";
import SsChat from "./getStreamChat";
import GetHelp from "../../core/gethelp";
import { seo } from "../../../helpers";
import { setTitle } from "../../../actions";

const styles = (theme) => ({
  root: {
    marginTop: "50px",
    width: "100%",
  },
  appFrame: {
    zIndex: 1,
    overflow: "hidden",
    position: "relative",
    display: "flex",
    // width: '100%',
    padding: "48px",
    [theme.breakpoints.down("md")]: {
      padding: theme.spacing(2, 5),
    },
    [theme.breakpoints.down("sm")]: {
      padding: theme.spacing(2, 2),
    },
  },
  content: {
    flexGrow: 1,
    paddingRight: theme.spacing(6),
    paddingLeft: theme.spacing(6),
    [theme.breakpoints.down("md")]: {
      padding: theme.spacing(2, 2),
    },
    [theme.breakpoints.down("sm")]: {
      padding: theme.spacing(0, 1),
    },
  },
  moduleConfig: {
    float: "left",
    fontSize: "26px",
  },
});

class Messages extends Component {
  componentDidMount() {
    document.body.scrollTop = 0; // For Safari
    document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE and Opera
    const { dispatch } = this.props;
    dispatch(setTitle(`${"Messages"}`));
  }

  componentDidUpdate(prevProps) {
    if (prevProps.pageTitle !== this.props.pageTitle) {
      const { pageTitle } = this.props?.pageTitle ?? this.props;
      seo({
        title: `${pageTitle}`,
      });
    }
  }

  render() {
    const { classes } = this.props;

    return (
      <div className={classes.root}>
        <div className={classes.appFrame}>
          <Typography className={classes.moduleConfig}>Messages</Typography>
        </div>
        <main className={classes.content}>
          <SsChat />
          <GetHelp />
        </main>
      </div>
    );
  }
}

Messages.propTypes = {
  classes: PropTypes.object.isRequired,
};

function mapStateToProps(state) {
  return state;
}
const connectedMessagesPage = withRouter(
  connect(mapStateToProps, null, null, {
    pure: false,
  })(withStyles(styles)(Messages))
);
export { connectedMessagesPage as Messages };
