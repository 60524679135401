import React, { Component, Fragment } from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import PreLoginContent from "../../core/preLoginContent";
import Footer from "../../core/footer";
import {
  Paper,
  Grid,
  Button,
  TextField,
  Typography,
  InputAdornment,
  IconButton,
  Divider,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  CircularProgress,
  FormHelperText,
  Dialog,
} from "@material-ui/core";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import ProductInfoImage from "../../../assets/product_info.svg";
import Visibility from "@material-ui/icons/Visibility";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import classNames from "classnames";
import validator from "validator";
import { Auth } from "aws-amplify";
import { toast } from "react-toastify";
import { authActions, loading, jobsActions } from "../../../actions";
import config from "../../../config/config";
import { ROLES, MESSAGE_FIELD_IS_REQUIRED } from "../../../constants/constants";
import { history } from "../../../helpers";
import { notAllowWhiteSpaceAtStart, trim } from "../../../helpers";
import MuiDialogTitle from "@material-ui/core/DialogTitle";
import MuiDialogContent from "@material-ui/core/DialogContent";
import MuiDialogActions from "@material-ui/core/DialogActions";
import CloseIcon from "@material-ui/icons/Close";
import cardHeaderBg from "../../../assets/lite_profile_bg.png";
import secureLocalStorage from "react-secure-storage";
import PhoneNumber from "../../core/phoneNumber";

// const filter = createFilterOptions();

const styles = (theme) => ({
  root: {
    height: "calc(100vh - 90px)", //'100vh',
    marginTop: "90px",
    // marginTop: `calc(100% - ${drawerWidth}px)`
  },
  boldFont: {
    fontWeight: "bold",
  },
  customLink: {
    color: "#048475 !important",
    cursor: "pointer",
    "&:hover": {
      textDecoration: "underline",
    },
  },
  image: {
    backgroundImage: `url(${ProductInfoImage})`, //'url(https://source.unsplash.com/random)',
    backgroundRepeat: "no-repeat",
    //   backgroundColor:
    //     theme.palette.type === 'light' ? theme.palette.grey[50] : theme.palette.grey[900],
    backgroundColor: "#FBC112",
    //   backgroundSize: 'cover',
    backgroundSize: "contain",
    backgroundPosition: "center",
  },
  paper: {
    margin: theme.spacing(2, 2),
    display: "flex",
    //   flexDirection: 'column',
    alignItems: "flex-start",
    //   marginTop: '8%',
    justifyContent: "flex-start",
    [theme.breakpoints.down("md")]: {
      margin: theme.spacing(3, 2),
      justifyContent: "flex-start",
    },
    [theme.breakpoints.down("sm")]: {
      margin: theme.spacing(2, 2),
      justifyContent: "flex-start",
    },
  },
  signInBtn: {
    float: "left",
    width: "14em",
    backgroundColor: "#FBC112",
    color: "#000000",
  },
  spacing: {
    padding: "3% 3% 7% 3%",
    [theme.breakpoints.between("xs", "md")]: {
      padding: "5% 5% 15% 5%",
    },
    backgroundColor: "#F8F6EF", //'#E5E5E5'
  },
  smallFont: {
    fontSize: "0.75rem",
  },
  customPaper: {
    margin: theme.spacing(1, 2),
    [theme.breakpoints.down("md")]: {
      margin: theme.spacing(3, 2),
    },
    [theme.breakpoints.down("sm")]: {
      margin: theme.spacing(2, 2),
    },
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-start",
  },
  newCustomPaper: {
    borderRadius: "4px",
    padding: "3%",
  },
  customForm: {
    margin: theme.spacing(6, 2),
    [theme.breakpoints.down("md")]: {
      margin: theme.spacing(3, 2),
    },
    [theme.breakpoints.down("sm")]: {
      margin: theme.spacing(2, 2),
    },
    display: "flex",
    // flexDirection: 'column',
    alignItems: "center",
    // marginTop: '18%'
    justifyContent: "flex-start",
  },
  xxLargeFont: {
    fontSize: "xx-large",
    [theme.breakpoints.down("md")]: {
      fontSize: "22px",
    },
    [theme.breakpoints.down("sm")]: {
      fontSize: "22px",
    },
  },
  bottomSmall: {
    marginBottom: "9%",
  },
  formControl: {
    textAlign: "left",
    minWidth: "100%",
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(5, 0, 2),
    borderRadius: "4px !important",
  },
  alignRight: {
    textAlign: "right",
  },
  margin: {
    marginTop: theme.spacing(2),
  },
  withoutLabel: {
    marginTop: theme.spacing(3),
  },
  textField: {
    width: "100%",
  },
  disabledSignIn: {
    cursor: "not-allowed",
    borderRadius: "4px !important",
  },
  toolbar: {
    padding: "1% !important",
    left: "1.5% !important",
  },
  responsive: {
    maxWidth: "100% !important",
    height: "auto !important",
    width: "auto !important",
    alignSelf: "center !important",
    minWidth: "fit-content !important",
  },
  content: {
    // flexGrow: 1,
    // padding: theme.spacing(3),
  },
  customDivider: {
    borderBottom: "1px solid " + theme.palette.secondary.main,
    width: "inherit",
    marginTop: "5%",
    flexShrink: 1,
  },
  smallTopMargin: {
    marginTop: "2%",
  },
  verySmallTopMargin: {
    marginTop: "1%",
  },
  smallBottomMargin: {
    marginTop: "3%",
  },
  floatRight: {
    float: "right",
  },
  floatLeft: {
    float: "left",
  },

  // for side box added by KP
  detailsBox: {
    background: "#FBC112",
    height: "100%",
    padding: "120px 70px",
    [theme.breakpoints.down("md")]: {
      padding: "50px 30px",
    },
    [theme.breakpoints.down("sm")]: {
      padding: "32px 16px",
    },
  },
  listItem: {
    display: "flex",
    alignItems: "center",
    marginTop: "20px",
    marginBottom: "20px",
    [theme.breakpoints.between("xs", "lg")]: {
      marginTop: "10px",
      marginBottom: "10px",
    },
    background: "#FB9012",
    padding: "10px",
    borderRadius: "8px",
    fontSize: "18px",
  },
  itemCircle: {
    height: "64px",
    width: "64px",
    borderRadius: "50%",
    background: "#ffffff",
    padding: 0,
    "& img": {
      height: "64px",
      width: "64px",
    },
  },
  sideTitle: {
    fontSize: "24px",
    [theme.breakpoints.between("xs", "lg")]: {
      fontSize: "18px",
    },
    lineHeight: "185%",
    color: "#000000",
    marginBottom: "10px",
    textAlign: "left",
  },
  customTopBottomMargin: {
    marginTop: "1.5%",
    marginBottom: "1.7%",
    minWidth: "100%",
  },
  customDialogHeader: {
    maxHeight: "38%",
    height: "38%",
    color: "#000",
    backgroundSize: "cover",
    backgroundImage: `url(${cardHeaderBg})`,
    background: "round",
  },
  closeButton: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.default.main,
    padding: "0px",
  },
});

const DialogTitle = withStyles(styles)((props) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle
      disableTypography
      className={classes.customDialogHeader}
      style={{ padding: "3%" }}
      {...other}
    >
      <Typography variant="h5" align="center">
        {children}
      </Typography>
      {onClose ? (
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={onClose}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const DialogContent = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
    paddingLeft: theme.spacing(6),
    paddingRight: theme.spacing(6),
    [theme.breakpoints.down("md")]: {
      padding: theme.spacing(2),
    },
    [theme.breakpoints.down("sm")]: {
      padding: theme.spacing(2),
    },
  },
}))(MuiDialogContent);

const DialogActions = withStyles((theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
    backgroundColor: "#fff",
    justifyContent: "center !important",
  },
}))(MuiDialogActions);

let jobTitles = [];
let charCount = 50;
let otherTitleCharCount = 100;
let passwordCharCount = 15;
let passwordMinCharCount = 8;
let charCountOne = 1;
let jobTitleCount = 100;

class SignUp extends Component {
  constructor(props) {
    super(props);
    this.state = {
      email: "",
      password: "",
      firstName: "",
      lastName: "",
      currentTitle: "",
      otherTitle: "",
      jobTitle: "",
      jobTitleValue: "",
      phone: "",
      showPassword: false,
      isValidEmail: true,
      isValidFirstName: true,
      isValidLastName: true,
      isValidOtherTitle: true,
      isValidPhone: true,
      isValidPassword: true,
      openOptions: false,
      jobTitleOptions: [],
      errors: {
        cognito: null,
      },
      isFirstNameRequired: false,
      isLastNameRequired: false,
      isCurrentRoleRequired: false,
      isOtherTitleRequired: false,
      isJobTitleValueRequired: false,
      isEmailRequired: false,
      isPhoneRequired: false,
      isPasswordRequired: false,
      customJobTitlePopUp: false,
      customJobTitle: "",
    };
  }

  componentDidUpdate(prevProps) {
    if (prevProps.jobs.jobTitles !== this.props.jobs.jobTitles) {
      jobTitles = this.props.jobs.jobTitles;
      this.setState({ jobTitleOptions: jobTitles, jobTitleLoading: false });
    }
  }

  handleClickShowPassword = () => {
    this.setState({ showPassword: !this.state.showPassword });
  };

  handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  setJobTitle = (value) => {
    if (value !== null && value !== undefined) {
      this.setState({
        jobTitleValue: value._source.Title,
        isJobTitleValueRequired: false,
      });
    } else {
      this.setState({ jobTitleValue: "", isJobTitleValueRequired: true });
    }
  };

  handleOnBlur = () => {
    this.setState({ jobTitleOptions: [] });
  };

  getJobTitles = (title) => {
    this.setState({ jobTitleLoading: true });
    const { dispatch } = this.props;
    dispatch(jobsActions.getJobTitles(title));
    dispatch(loading(false));
  };

  handleChange = (prop) => (event) => {
    const inputValue = notAllowWhiteSpaceAtStart(event.target.value);
    this.setState({ [prop]: inputValue }, () => {
      switch (prop) {
        case "firstName":
          this.setState({
            isValidFirstName: inputValue
              ? validator.matches(inputValue, /^.{2,50}$/)
              : true,
            isFirstNameRequired: validator.isEmpty(inputValue),
          });
          break;
        case "lastName":
          this.setState({
            isValidLastName: inputValue
              ? validator.matches(inputValue, /^.{2,50}$/)
              : true,
            isLastNameRequired: validator.isEmpty(inputValue),
          });
          break;
        case "otherTitle":
          this.setState({
            isValidOtherTitle: inputValue
              ? validator.matches(inputValue, /^.{2,100}$/)
              : true,
            isOtherTitleRequired: validator.isEmpty(inputValue),
          });
          break;
        case "jobTitle":
          this.getJobTitles(this.state.jobTitle);
          if (this.state.jobTitle.length === 0) {
            this.setState({ jobTitleOptions: [], jobTitleLoading: false });
          }
          break;
        case "email":
          this.setState(
            {
              isValidEmail: inputValue ? validator.isEmail(inputValue) : true,
              isEmailRequired: validator.isEmpty(inputValue),
            },
            function () {
              if (
                this.state.email.includes("@yahoo.com") ||
                this.state.email.includes("@gmail.com") ||
                this.state.email.includes("@live.com") ||
                this.state.email.includes("@rediffmail.com") ||
                this.state.email.includes("@hotmail.com") ||
                this.state.email.includes("@ymail.com") ||
                this.state.email.includes("@outlook.com") ||
                this.state.email.includes("@yopmail.com")
              ) {
                this.setState({ isValidEmail: false });
              }
            }
          );
          break;
        case "password":
          this.setState({
            isValidPassword: inputValue
              ? validator.matches(
                  inputValue,
                  /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[^a-zA-Z0-9]).{6,15}$/,
                  "i"
                )
              : true,
            isPasswordRequired: validator.isEmpty(inputValue),
          });
          break;
        case "currentTitle":
          this.setState({
            isCurrentRoleRequired: validator.isEmpty(inputValue),
          });
          break;
        default:
          return;
      }
    });
  };

  redirectToSignIn = () => {
    history.push("/signin");
  };

  openTermsAndConditions = () => {
    window.open(config.terms_url, "_blank");
  };

  signUp = async (event) => {
    const { dispatch } = this.props;
    dispatch(loading(true));
    event.preventDefault();
    event.stopPropagation();
    const {
      email,
      password,
      firstName,
      lastName,
      currentTitle,
      otherTitle,
      jobTitleValue,
      phone,
    } = this.state;

    if (
      email &&
      password &&
      firstName &&
      lastName &&
      currentTitle &&
      phone &&
      jobTitleValue
    ) {
      if (currentTitle === "Other" && otherTitle === "") {
        this.setState({
          isOtherTitleRequired: validator.isEmpty(otherTitle),
        });
        dispatch(loading(false));
        toast.dark("Please fill up all the details with correct information");
        return;
      }
      try {
        const signUpResponse = await Auth.signUp({
          username: email?.toLowerCase(),
          password,
          attributes: {
            email: email?.toLowerCase(),
            "custom:first_name": trim(firstName),
            "custom:last_name": trim(lastName),
            "custom:current_title":
              this.state.otherTitle !== ""
                ? trim(otherTitle)
                : trim(currentTitle),
            "custom:phone": phone,
          },
        });
        secureLocalStorage.setItem(
          "userDetails",
          JSON.stringify({
            email: email?.toLowerCase(),
            "custom:first_name": trim(firstName),
            "custom:last_name": trim(lastName),
            "custom:current_title":
              this.state.otherTitle !== ""
                ? trim(otherTitle)
                : trim(currentTitle),
            "custom:phone": phone,
          })
        );
        console.log(signUpResponse);
        secureLocalStorage.setItem(
          "userId",
          JSON.stringify(signUpResponse.user.username)
        );
        secureLocalStorage.setItem(
          "userSub",
          JSON.stringify(signUpResponse.userSub)
        );
        secureLocalStorage.setItem("onboardingStatus", "signedUp");
        let payload = {
          first_name: trim(firstName),
          last_name: trim(lastName),
          contact: phone,
          designation:
            this.state.otherTitle !== ""
              ? trim(otherTitle)
              : trim(currentTitle),
          title: trim(jobTitleValue),
          terms_and_conditions: true,
          cognito_details: {
            username: trim(email?.toLowerCase()),
            userSub: JSON.parse(secureLocalStorage.getItem("userSub")),
          },
        };
        //Call set recruiter API here
        dispatch(authActions.setRecruiter(payload));
        dispatch(loading(false));
      } catch (error) {
        let err = null;
        !error.message ? (err = { message: error }) : (err = error);
        console.log("Error Signing Up:", err);
        this.setState({
          errors: {
            ...this.state.errors,
            cognito: error,
          },
        });
        dispatch(loading(false));
        toast.dark(error.message);
      }
    } else {
      dispatch(loading(false));
      this.setState({
        isFirstNameRequired: validator.isEmpty(firstName),
        isLastNameRequired: validator.isEmpty(lastName),
        isCurrentRoleRequired: validator.isEmpty(currentTitle),
        isJobTitleValueRequired: validator.isEmpty(jobTitleValue),
        isEmailRequired: validator.isEmpty(email),
        isPhoneRequired: validator.isEmpty(phone),
        isPasswordRequired: validator.isEmpty(password),
      });
      if (currentTitle === "Other" && otherTitle === "") {
        this.setState({
          isOtherTitleRequired: validator.isEmpty(otherTitle),
        });
      }
      toast.dark("Please fill up all the details with correct information");
    }
  };

  saveCustomJobTitle = (event) => {
    event.preventDefault();
    event.stopPropagation();
    this.setState({
      jobTitleValue: trim(this.state.customJobTitle),
      customJobTitlePopUp: false,
      isJobTitleValueRequired: false,
    });
  };

  onChangePhoneValue = (value, validate, required) => {
    this.setState({
      phone: value,
      isPhoneRequired: required,
      isValidPhone: validate,
    });
  };

  render() {
    const { classes } = this.props;
    return (
      <Fragment>
        {/* Custom job title pop up */}
        <Dialog
          open={this.state.customJobTitlePopUp}
          fullWidth
          aria-labelledby="scroll-dialog-title"
          aria-describedby="scroll-dialog-description"
          disableBackdropClick
          disableEscapeKeyDown
          maxWidth="xs"
        >
          <DialogTitle
            id="customized-dialog-title"
            onClose={() => {
              this.setState({ customJobTitlePopUp: false });
            }}
          >
            Add Job Title
          </DialogTitle>
          <DialogContent>
            <TextField
              fullWidth
              label="New Job Title"
              className={classes.customTopBottomMargin}
              id="newJobTitle"
              name="newJobTitle"
              value={this.state.customJobTitle}
              onChange={this.handleChange("customJobTitle")}
              placeholder="Enter job title"
              inputProps={{
                maxLength: 100,
              }}
              // error ={this.state.isValidReportsToName ? false : true }
              // helperText={!this.state.isValidReportsToName ? '' : 'max 100 characters'}
            />
            <Typography
              className={classNames(classes.floatRight, classes.smallFont)}
              color="secondary"
            >
              {this.state.customJobTitle.length + "/" + jobTitleCount}{" "}
              characters
            </Typography>
          </DialogContent>
          <DialogActions className={classes.dialogActions}>
            <Button
              onClick={() =>
                this.setState({
                  customJobTitlePopUp: false,
                  jobTitle: "",
                  jobTitleValue: "",
                })
              }
              className={classes.justifyRight}
              style={{ background: "#D7D9DB", color: "#000" }}
              variant="contained"
              // color="primary"
              disableElevation
              size="medium"
            >
              Cancel
            </Button>
            <Button
              className={classes.justifyRight}
              variant="contained"
              color="primary"
              disableElevation
              size="medium"
              disabled={this.state.customJobTitle === ""}
              onClick={(event) => {
                this.saveCustomJobTitle(event);
              }}
            >
              Save
            </Button>
          </DialogActions>
        </Dialog>
        <Grid container component="main" className={classes.root}>
          <PreLoginContent />
          <Grid
            item
            xs={12}
            sm={12}
            md={6}
            lg={5}
            xl={5}
            className={classes.spacing}
            elevation={6}
            square
          >
            <Grid
              component={Paper}
              elevation={0}
              className={classes.newCustomPaper}
            >
              <div className={classes.paper}>
                <Typography
                  component="span"
                  align="left"
                  className={classNames(classes.xxLargeFont, classes.boldFont)}
                >
                  Create Account
                </Typography>
              </div>
              {/* <div className={classes.customPaper} >
                                <Typography align="left" color="primary">Start your one-month free trial today.</Typography>
                            </div> */}
              <form className={classes.customForm} noValidate>
                <Grid
                  container
                  spacing={4}
                  item
                  xs={12}
                  sm={12}
                  md={12}
                  lg={12}
                  xl={12}
                >
                  <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
                    <TextField
                      autoFocus
                      fullWidth
                      label="First Name"
                      autoComplete="firstName"
                      required
                      inputProps={{ maxLength: charCount }}
                      id="firstName"
                      name="firstName"
                      value={this.state.firstName}
                      onChange={this.handleChange("firstName")}
                      error={
                        !this.state.isValidFirstName ||
                        this.state.isFirstNameRequired
                      }
                      helperText={
                        !this.state.isValidFirstName
                          ? "min 2 characters required."
                          : this.state.isFirstNameRequired
                          ? MESSAGE_FIELD_IS_REQUIRED
                          : "Max 50 characters are allowed."
                      }
                    />
                    {/* <Typography className={classNames(classes.floatRight, classes.smallFont, classes.verySmallTopMargin)} color='secondary'>{this.state.firstName.length + '/' + charCount} characters</Typography> */}
                  </Grid>
                  <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
                    <TextField
                      fullWidth
                      label="Last Name"
                      autoComplete="lastName"
                      required
                      inputProps={{ maxLength: charCount }}
                      id="lastName"
                      name="lastName"
                      value={this.state.lastName}
                      onChange={this.handleChange("lastName")}
                      error={
                        !this.state.isValidLastName ||
                        this.state.isLastNameRequired
                      }
                      helperText={
                        !this.state.isValidLastName
                          ? "min 2 characters required."
                          : this.state.isLastNameRequired
                          ? MESSAGE_FIELD_IS_REQUIRED
                          : "Max 50 characters are allowed."
                      }
                    />
                    {/* <Typography className={classNames(classes.floatRight, classes.smallFont, classes.verySmallTopMargin)} color='secondary'>{this.state.lastName.length + '/' + charCount} characters</Typography> */}
                  </Grid>
                  <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                    <FormControl
                      className={classes.formControl}
                      required
                      error={this.state.isCurrentRoleRequired}
                    >
                      <InputLabel id="demo-simple-select-label">
                        Current Role
                      </InputLabel>
                      <Select
                        // margin='normal'
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        value={this.state.currentTitle}
                        placeholder="Choose one"
                        onChange={this.handleChange("currentTitle")}
                      >
                        {ROLES.map((obj, index) => (
                          <MenuItem key={index} value={obj.value}>
                            {obj.text}
                          </MenuItem>
                        ))}
                      </Select>
                      {this.state.isCurrentRoleRequired ? (
                        <FormHelperText>
                          {MESSAGE_FIELD_IS_REQUIRED}
                        </FormHelperText>
                      ) : null}
                    </FormControl>
                    {this.state.currentTitle === "Other" ? (
                      <>
                        <TextField
                          fullWidth
                          label="Role"
                          margin="normal"
                          required
                          autoFocus
                          id="otherTitle"
                          name="otherTitle"
                          value={this.state.otherTitle}
                          onChange={this.handleChange("otherTitle")}
                          inputProps={{ maxLength: otherTitleCharCount }}
                          placeholder="Your role"
                          error={
                            !this.state.isValidOtherTitle ||
                            this.state.isOtherTitleRequired
                          }
                          helperText={
                            !this.state.isValidOtherTitle
                              ? "min 2 characters required."
                              : this.state.isOtherTitleRequired
                              ? MESSAGE_FIELD_IS_REQUIRED
                              : "Max 100 characters are allowed."
                          }
                        />
                        {/* <Typography className={classNames(classes.floatRight, classes.smallFont, classes.verySmallTopMargin)} color='secondary'>{otherTitleCharCount - this.state.otherTitle.length + '/' + otherTitleCharCount} characters</Typography> */}
                      </>
                    ) : null}
                  </Grid>
                  <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                    {/* <FormControl className={classes.formControl}> */}
                    <Autocomplete
                      id="auto-complete"
                      autoComplete
                      open={this.state.openOptions}
                      value={this.state.jobTitleValue}
                      onChange={(event, newValue) => {
                        if (typeof newValue === "string") {
                          // timeout to avoid instant validation of the dialog's form.
                          setTimeout(() => {
                            this.setState({
                              customJobTitlePopUp: true,
                              customJobTitle: newValue,
                            });
                          });
                        } else if (newValue && newValue.inputValue) {
                          this.setState({
                            customJobTitlePopUp: true,
                            customJobTitle: newValue.inputValue,
                          });
                        } else {
                          this.setJobTitle(newValue);
                        }
                      }}
                      onOpen={() => {
                        this.setState({ openOptions: true });
                      }}
                      onClose={() => {
                        this.setState({ openOptions: false });
                      }}
                      getOptionSelected={(option, value) =>
                        option._source.Title === value.name
                      }
                      getOptionLabel={(option) => {
                        if (typeof option === "string") {
                          return option;
                        }
                        if (option.inputValue) {
                          return option.inputValue;
                        }
                        return option?._source?.Title
                          ? option?._source?.Title
                          : "";
                      }}
                      options={this.state.jobTitleOptions}
                      onBlur={(event) => {
                        this.handleOnBlur(event);
                      }}
                      loading={this.state.jobTitleLoading}
                      freeSolo
                      selectOnFocus
                      clearOnBlur
                      filterOptions={(options, params) => {
                        let filtered = options;
                        // const filtered = filter(options, params);
                        if (params.inputValue !== "") {
                          filtered.push({
                            inputValue: params.inputValue,
                            _source: {
                              Title: `Add: "${params.inputValue}"`,
                            },
                          });
                        }
                        return filtered;
                      }}
                      renderOption={(option) => option?._source?.Title}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label="Job Title"
                          id="jobTitle"
                          name="jobTitle"
                          // margin='normal'
                          InputProps={{
                            ...params.InputProps,
                            endAdornment: (
                              <React.Fragment>
                                {this.state.jobTitleLoading ? (
                                  <CircularProgress
                                    className={classes.verySmallMarginRight}
                                    color="inherit"
                                    size={20}
                                  />
                                ) : null}
                                {params.InputProps.endAdornment}
                              </React.Fragment>
                            ),
                          }}
                          required
                          value={this.state.jobTitle}
                          error={this.state.isJobTitleValueRequired}
                          helperText={
                            this.state.isJobTitleValueRequired
                              ? MESSAGE_FIELD_IS_REQUIRED
                              : null
                          }
                          onChange={this.handleChange("jobTitle")}
                          placeholder="Type to select job title"
                        />
                      )}
                    />
                    {/* </FormControl> */}
                  </Grid>
                  <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                    <TextField
                      fullWidth
                      label="Corporate Email Address"
                      autoComplete="email"
                      required
                      id="email"
                      name="email"
                      value={this.state.email?.toLowerCase()}
                      onChange={this.handleChange("email")}
                      error={
                        !this.state.isValidEmail || this.state.isEmailRequired
                      }
                      helperText={
                        !this.state.isValidEmail
                          ? "Invalid Email. Only a business/corporate email address is allowed"
                          : this.state.isEmailRequired
                          ? MESSAGE_FIELD_IS_REQUIRED
                          : "Only a corporate email address is allowed"
                      }
                    />
                  </Grid>
                  <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                    <PhoneNumber
                      onChangeValue={this.onChangePhoneValue}
                      isPersonalInfoEditable={true}
                      value={this.state.phone}
                    />
                  </Grid>
                  <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                    <FormControl className={classes.formControl}>
                      <TextField
                        fullWidth
                        label="Password"
                        required
                        name="password"
                        type={this.state.showPassword ? "text" : "password"}
                        id="password"
                        value={this.state.password}
                        autoComplete="current-password"
                        error={
                          !this.state.isValidPassword ||
                          this.state.isPasswordRequired
                        }
                        helperText={
                          !this.state.isValidPassword
                            ? `Password must contain minimum ` +
                              passwordMinCharCount +
                              ` and maximum ` +
                              passwordCharCount +
                              ` characters, at least ` +
                              charCountOne +
                              ` lowercase letter, ` +
                              charCountOne +
                              ` uppercase letter, ` +
                              charCountOne +
                              ` numeric digit and ` +
                              charCountOne +
                              ` special character.`
                            : this.state.isPasswordRequired
                            ? MESSAGE_FIELD_IS_REQUIRED
                            : ""
                        }
                        onChange={this.handleChange("password")}
                        InputProps={{
                          endAdornment: (
                            <InputAdornment
                              position="end"
                              title="Show/Hide Password"
                            >
                              <IconButton
                                tabIndex={-1}
                                aria-label="toggle password visibility"
                                onClick={this.handleClickShowPassword}
                                onMouseDown={this.handleMouseDownPassword}
                                edge="end"
                              >
                                {this.state.showPassword ? (
                                  <VisibilityOff />
                                ) : (
                                  <Visibility />
                                )}
                              </IconButton>
                            </InputAdornment>
                          ),
                        }}
                        inputProps={{ maxLength: passwordCharCount }}
                      />
                    </FormControl>
                    <Typography
                      className={classNames(
                        classes.floatRight,
                        classes.smallFont,
                        classes.verySmallTopMargin
                      )}
                      color="secondary"
                    >
                      {this.state.password.length + "/" + passwordCharCount}{" "}
                      characters
                    </Typography>
                  </Grid>
                  <Grid
                    className={classes.smallTopMargin}
                    item
                    xs={12}
                    sm={12}
                    md={12}
                    lg={12}
                    xl={12}
                  >
                    <Button
                      type="submit"
                      size="large"
                      disableElevation
                      variant="contained"
                      color="primary"
                      className={classNames(
                        classes.floatLeft,
                        classes.signInBtn
                      )}
                      disabled={
                        !this.state.isValidFirstName ||
                        !this.state.isValidLastName ||
                        !this.state.isValidOtherTitle ||
                        !this.state.isValidEmail ||
                        !this.state.isValidPhone ||
                        !this.state.isValidPassword
                      }
                      onClick={(event) => {
                        this.signUp(event);
                      }}
                    >
                      Create Account
                    </Button>
                  </Grid>
                  <Divider className={classes.customDivider} />
                  <Grid
                    className={classes.smallTopMargin}
                    item
                    xs={12}
                    sm={12}
                    md={12}
                    lg={12}
                    xl={12}
                  >
                    <Typography
                      className={classes.toolbarContent}
                      align="center"
                    >
                      {"By creating an account, you agree to SucceedSmart's"}{" "}
                      <span
                        style={{ display: "inline-block" }}
                        className={classes.customLink}
                        onClick={(event) => {
                          this.openTermsAndConditions(event);
                        }}
                      >
                        Terms of use
                      </span>
                      {". Already a SucceedSmart customer? "}&nbsp;
                      <span
                        style={{ display: "inline-block" }}
                        className={classes.customLink}
                        onClick={(event) => {
                          this.redirectToSignIn(event);
                        }}
                      >
                        Sign in
                      </span>
                    </Typography>
                  </Grid>
                </Grid>
              </form>
            </Grid>
          </Grid>
          <Footer />
          {/* <Grid item xs={false} sm={12} md={5} lg={5} xl={5}>
                        <Box p={1} align='left' className={classes.detailsBox}>
                            <div>
                            <Typography className={classes.sideTitle}>SucceedSmart offers companies an affordable, transparent, and speedy executive hiring process, featuring three main benefits:</Typography>
                            </div>
                            <Grid container  direction='column' >
                            <Box className={classes.listItem}>
                                <Container component="span" className={classes.itemCircle}>
                                    <img src={sideImg1}  alt="Leverage diversity through privacy" />
                                </Container>
                                <Container component="div">Leverage diversity through privacy</Container>
                            </Box>

                            <Box className={classes.listItem}>
                                <Container component="span" className={classes.itemCircle}>
                                <img src={sideImg2}  alt="Budget-friendly pricing" />
                                </Container>
                                <Container component="div">Budget-friendly pricing</Container>
                            </Box>

                            <Box className={classes.listItem}>
                                <Container component="span" className={classes.itemCircle}>
                                <img src={sideImg3}  alt="Improved productivity" />
                                </Container>
                                <Container component="div">Improved productivity</Container>
                            </Box>
                            </Grid>
                        </Box>
                    </Grid> */}
        </Grid>
      </Fragment>
    );
  }
}

SignUp.propTypes = {
  classes: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => {
  return state;
};

const connectedSignUpPage = withRouter(
  connect(mapStateToProps, null, null, {
    pure: false,
  })(withStyles(styles)(SignUp))
);

export { connectedSignUpPage as SignUp };
