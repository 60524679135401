import React, { useState, useEffect } from "react";
import { Grid, Divider, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import EmptyState from "../core/emptyState";

const useStyles = makeStyles(() => ({
  videoMainDiv: {
    marginTop: "20px",
    padding: "20px",
  },
  candidateVideoDivider1: {
    marginTop: "15px",
    marginBottom: "25px",
  },
  candidateVideoDivider2: {
    borderBottom: "1px solid #D7D9DB",
    width: "inherit",
    marginTop: "3%",
    flexShrink: 1,
  },
  bolderFont: {
    fontWeight: "bolder",
  },
  mainVideo: {
    width: "-webkit-fill-available",
  },
  videosList: {
    margin: "20px 0px 0px 20px",
  },
  videoPadding: {
    padding: "5px",
  },
  alignSelfCenter: { alignSelf: "center !important" },
}));

function CandidateVideos(props) {
  const { candidateVideos } = props;
  const classes = useStyles();
  const EMPTY_ICON_HEIGHT = 120;
  const EMPTY_ICON_WIDTH = 120;

  const [videoAttributes, setVideoAttributes] = useState({
    poster: "",
    videoTitle: "",
    videoSrc: "",
    selectedVideo: 0,
  });

  useEffect(() => {
    if (candidateVideos?.length > 0) {
      setVideoAttributes({
        videoTitle: candidateVideos[0]?.topic,
        videoSrc: candidateVideos[0]?.video_url,
        poster: candidateVideos?.thumbnail_url,
        selectedVideo: 0,
      });
    }
  }, [candidateVideos]);

  return (
    <Grid container>
      <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
        <div className={classes.videoMainDiv}>
          <Typography variant="h6" align="left" className={classes.bolderFont}>
            Candidate Videos
          </Typography>
          <Divider className={classes.candidateVideoDivider1} />
          {candidateVideos.length > 0 ? (
            <>
              <Grid container>
                <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                  <video
                    preload="auto"
                    disablePictureInPicture
                    controls
                    title={videoAttributes.videoTitle}
                    id="insightVideo"
                    // width="100%"
                    height="300"
                    src={videoAttributes.videoSrc}
                    frameBorder="0"
                    poster={videoAttributes.poster}
                    allow="accelerometer; encrypted-media; gyroscope;" // picture-in-picture
                    allowFullScreen={false}
                    controlsList="nodownload"
                    className={classes.mainVideo}
                  />
                  <div>{videoAttributes.videoTitle}</div>
                </Grid>
              </Grid>
              <Divider className={classes.candidateVideoDivider2} />
              <Grid container>
                <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                  {candidateVideos?.map((video, index) => (
                    <>
                      {index !== 0 && (
                        <Divider className={classes.videosList} />
                      )}
                      <Grid
                        item
                        xs={12}
                        sm={12}
                        md={12}
                        lg={12}
                        xl={12}
                        style={{
                          marginTop: index !== 0 ? "35px" : "35px",
                          display: "flex",
                          alignItems: "center",
                          cursor: "pointer",
                          border:
                            videoAttributes.selectedVideo === index
                              ? "2px solid #FBC112"
                              : "",
                        }}
                        onClick={() => {
                          setVideoAttributes({
                            poster: video?.thumbnail_url,
                            videoTitle: video.topic,
                            videoSrc: video.video_url,
                            selectedVideo: index,
                          });
                        }}
                      >
                        <video
                          className={classes.videoPadding}
                          preload="auto"
                          height="200px"
                          disablePictureInPicture
                          controls={false}
                          title={video.topic}
                          id={`insightVideo${index}`}
                          width="30%"
                          src={video.video_url}
                          frameBorder="0"
                          poster={video?.thumbnail_url}
                          allow="accelerometer; encrypted-media; gyroscope;" // picture-in-picture
                          allowFullScreen={false}
                          controlsList="nodownload"
                        />
                        <div className={classes.videoPadding}>
                          <Typography
                            variant="h6"
                            className={classes.alignSelfCenter}
                            align="left"
                          >
                            {video.topic}
                          </Typography>
                        </div>
                      </Grid>
                    </>
                  ))}
                </Grid>
              </Grid>
            </>
          ) : (
            <EmptyState
              type="candidateVideos"
              height={EMPTY_ICON_HEIGHT}
              width={EMPTY_ICON_WIDTH}
            />
          )}
        </div>
      </Grid>
    </Grid>
  );
}

export default CandidateVideos;
