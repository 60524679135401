import React, { Component, Fragment } from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import { TextField } from "@material-ui/core";
import { connect } from "react-redux";
import { history, seo } from "../../../helpers";
import Typography from "@material-ui/core/Typography";
import { withRouter } from "react-router-dom";
// import Link from '@material-ui/core/Link';
import ProductInfoImage from "../../../assets/product_info.svg";
import validator from "validator";
import { Auth } from "aws-amplify";
import { toast } from "react-toastify";
// import { Divider } from '@material-ui/core';
import classNames from "classnames";
import Footer from "../../core/footer";
import { loading, saveEmailForPasswordReset } from "../../../actions";
import PreLoginContent from "../../core/preLoginContent";
// import stepperImg from '../../../assets/forgot_password_steps.png';
import { ReactComponent as StepperImg } from "../../../assets/forgot_password_steps.svg";
import { setTitle } from "../../../actions";
const styles = (theme) => ({
  root: {
    height: "calc(100vh - 90px)",
    marginTop: "90px",
  },
  signInBtn: {
    float: "left",
    width: "14em",
    backgroundColor: "#FBC112",
    color: "#000000",
  },
  image: {
    backgroundImage: `url(${ProductInfoImage})`, //'url(https://source.unsplash.com/random)',
    backgroundRepeat: "no-repeat",
    backgroundColor:
      theme.palette.type === "light"
        ? theme.palette.grey[50]
        : theme.palette.grey[900],
    backgroundSize: "cover",
  },
  paper: {
    margin: theme.spacing(2, 4),
    [theme.breakpoints.down("md")]: {
      margin: theme.spacing(3, 4),
    },
    [theme.breakpoints.down("sm")]: {
      margin: theme.spacing(2, 4),
    },
    display: "flex",
    alignItems: "flex-start",
    marginTop: "5%",
  },
  spacing: {
    padding: "3% 3% 7% 3%",
    [theme.breakpoints.between("xs", "sm")]: {
      padding: "5% 5% 15% 5%",
    },
    // [theme.breakpoints.between('md', 'md')]: {
    //   padding: '25% 5%'
    // },
    backgroundColor: "#F8F6EF", //'#E5E5E5'
  },
  customPaper: {
    margin: theme.spacing(1, 4),
    [theme.breakpoints.down("md")]: {
      margin: theme.spacing(3, 4),
    },
    [theme.breakpoints.down("sm")]: {
      margin: theme.spacing(2, 2),
    },
    display: "flex",
    alignItems: "flex-start",
    flexDirection: "column",
    justifyContent: "flex-start",
    // borderRadius: '4px',
    // padding: '1%'
  },
  customForm: {
    // margin: theme.spacing(6, 4),
    // [theme.breakpoints.down('md')]: {
    //   margin: theme.spacing(3, 4),
    // },
    // [theme.breakpoints.down('sm')]: {
    //   margin: theme.spacing(2, 4),
    // },
    // display: 'flex',
    // alignItems: 'flex-start'
    width: "100%",
    padding: "32px",
    [theme.breakpoints.down("md")]: {
      padding: "32px",
    },
    [theme.breakpoints.down("sm")]: {
      padding: "16px",
    },
  },
  forgotLink: {
    cursor: "pointer",
    "&:hover": {
      textDecoration: "underline",
    },
  },
  smallTopMargin: {
    marginTop: "3%",
  },
  customFlex: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  xxLargeFont: {
    fontSize: "xx-large",
    [theme.breakpoints.down("md")]: {
      fontSize: "22px",
    },
    [theme.breakpoints.down("sm")]: {
      fontSize: "22px",
    },
  },
  bottomSmall: {
    marginBottom: "9%",
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(5, 0, 2),
    borderRadius: "4px !important",
  },
  alignRight: {
    textAlign: "right !important",
  },
  margin: {
    marginTop: theme.spacing(2),
  },
  withoutLabel: {
    marginTop: theme.spacing(3),
  },
  textField: {
    width: "100%",
  },
  disabledSignIn: {
    cursor: "not-allowed",
    borderRadius: "4px !important",
  },
  toolbar: {
    padding: "1% !important",
    left: "1.5% !important",
  },
  responsive: {
    maxWidth: "100% !important",
    height: "auto !important",
    width: "auto !important",
    alignSelf: "center !important",
    minWidth: "fit-content !important",
  },
  customDivider: {
    borderBottom: "1px solid " + theme.palette.secondary.main,
    width: "inherit",
    marginTop: "10%",
    flexShrink: 1,
  },
  floatRight: {
    float: "right",
  },
  floatLeft: {
    float: "left",
  },
  customLink: {
    color: "#048475 !important",
  },

  // for side box added by KP
  detailsBox: {
    background: "#FBC112",
    height: "100%",
    padding: "120px 70px",
    [theme.breakpoints.down("md")]: {
      padding: "50px 30px",
    },
    [theme.breakpoints.down("sm")]: {
      padding: "32px 16px",
    },
  },
  listItem: {
    display: "flex",
    alignItems: "center",
    marginTop: "20px",
    marginBottom: "20px",
    [theme.breakpoints.between("xs", "lg")]: {
      marginTop: "10px",
      marginBottom: "10px",
    },
    background: "#FB9012",
    padding: "10px",
    borderRadius: "8px",
    fontSize: "18px",
  },
  itemCircle: {
    height: "64px",
    width: "64px",
    borderRadius: "50%",
    background: "#ffffff",
    padding: 0,
    "& img": {
      height: "64px",
      width: "64px",
    },
  },
  boldFont: {
    fontWeight: "bold",
  },
  sideTitle: {
    fontSize: "24px",
    [theme.breakpoints.between("xs", "lg")]: {
      fontSize: "18px",
    },
    lineHeight: "185%",
    color: "#000000",
    marginBottom: "10px",
    textAlign: "left",
  },
});

class ForgotPassword extends Component {
  constructor(props) {
    super(props);
    this.state = {
      email: "",
      isValidEmail: true,
      errors: {
        cognito: null,
      },
    };
  }

  componentDidMount() {
    document.body.scrollTop = 0; // For Safari
    document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE and Opera
    const urlSearchParams = new URLSearchParams(window.location.search);
    // const params = Object.fromEntries(urlSearchParams.entries());
    const params = Object.fromEntries(urlSearchParams);
    if (
      params.email?.toLowerCase() !== null &&
      params.email?.toLowerCase() !== undefined &&
      params.email?.toLowerCase() !== ""
    ) {
      this.setState({ email: params.email?.toLowerCase() });
    }
    const { dispatch } = this.props;
    dispatch(setTitle(`Forgot Password`));
  }

  componentDidUpdate() {
    const { pageTitle } = this.props.pageTitle;
    seo({
      title: `${pageTitle}`,
    });
  }

  handleChange = (prop) => (event) => {
    switch (prop) {
      case "email":
        this.setState(
          { isValidEmail: validator.isEmail(event.target.value) },
          function () {
            if (
              this.state.email.includes("@yahoo.com") ||
              this.state.email.includes("@gmail.com") ||
              this.state.email.includes("@live.com") ||
              this.state.email.includes("@rediffmail.com") ||
              this.state.email.includes("@hotmail.com") ||
              this.state.email.includes("@ymail.com") ||
              this.state.email.includes("@outlook.com") ||
              this.state.email.includes("@yopmail.com")
            ) {
              this.setState({ isValidEmail: false });
            }
          }
        );
        break;
      default:
        return;
    }
    this.setState({ [prop]: event.target.value });
  };

  forgotPassword = async (event) => {
    const { email } = this.state;
    const { dispatch } = this.props;
    dispatch(loading(true));
    dispatch(saveEmailForPasswordReset(email?.toLowerCase()));
    event.preventDefault();
    event.stopPropagation();
    if (email) {
      try {
        const forgotPasswordResponse = await Auth.forgotPassword(
          email?.toLowerCase()
        );
        console.log(forgotPasswordResponse);
        toast.dark(
          "A verification code has been sent to your email address. Please verify your email to reset password."
        );
        history.push("/reset-password");
        dispatch(loading(false));
      } catch (error) {
        let err = null;
        !error.message ? (err = { message: error }) : (err = error);
        console.log("error in password reset:", err);
        this.setState({
          errors: {
            ...this.state.errors,
            cognito: error,
          },
        });
        dispatch(loading(false));
        toast.dark(error.message);
      }
    } else {
      dispatch(loading(false));
      toast.dark("Please enter Corporate Email Address");
    }
  };

  render() {
    const { classes } = this.props;
    return (
      <Fragment>
        <Grid container component="main" className={classes.root}>
          <PreLoginContent />
          <Grid
            item
            xs={12}
            sm={12}
            md={6}
            lg={5}
            xl={5}
            className={classes.spacing}
            elevation={6}
            square
          >
            <Grid
              component={Paper}
              elevation={0}
              className={classes.customPaper}
            >
              <div style={{ width: "100%" }}>
                <StepperImg style={{ width: "80%", marginTop: "30px" }} />
              </div>
              <div className={classes.paper}>
                <Typography
                  component="span"
                  align="left"
                  className={classNames(classes.xxLargeFont, classes.boldFont)}
                >
                  Request Verification Code
                </Typography>
              </div>
              <div className={classes.customPaper}>
                <Typography component="span" align="left" color="primary">
                  To get a verification code, first confirm the email address
                  associated with your account.
                </Typography>
              </div>
              <form className={classes.customForm} noValidate>
                <Grid container item xs={12} sm={12} md={12} lg={12} xl={12}>
                  <Grid
                    item
                    spacing={2}
                    xs={12}
                    sm={12}
                    md={12}
                    lg={12}
                    xl={12}
                  >
                    <TextField
                      fullWidth
                      label="Corporate Email Address"
                      autoComplete="email"
                      margin="normal"
                      required
                      id="email"
                      name="email"
                      value={this.state.email?.toLowerCase()}
                      onChange={this.handleChange("email")}
                      error={this.state.isValidEmail ? false : true}
                      autoFocus
                      helperText={
                        !this.state.isValidEmail ? "Invalid Email" : ""
                      }
                    />
                  </Grid>
                  <Grid
                    className={classes.smallTopMargin}
                    item
                    xs={12}
                    sm={12}
                    md={12}
                    lg={12}
                    xl={12}
                  >
                    <Button
                      size="large"
                      type="submit"
                      disableElevation
                      variant="contained"
                      color="primary"
                      className={classNames(
                        classes.floatLeft,
                        classes.signInBtn
                      )}
                      disabled={!this.state.isValidEmail}
                      onClick={(event) => {
                        this.forgotPassword(event);
                      }}
                    >
                      Continue
                    </Button>
                  </Grid>
                  {/* <Divider className={classes.customDivider} />
                        <Grid className={classes.smallTopMargin} item xs={12} sm={12} md={12} lg={12} xl={12}>
                            <Typography className={classes.toolbarContent} align='center'>{'New to SucceedSmart?'}&nbsp;
                                <Link href="/signup" className={classes.customLink}>Create Corporate Account</Link>
                            </Typography>
                        </Grid> */}
                </Grid>
              </form>
            </Grid>
          </Grid>
          <Footer />
        </Grid>
      </Fragment>
    );
  }
}

ForgotPassword.propTypes = {
  classes: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => {
  return state;
};

const connectedForgotPasswordPage = withRouter(
  connect(mapStateToProps, null, null, {
    pure: false,
  })(withStyles(styles)(ForgotPassword))
);

export { connectedForgotPasswordPage as ForgotPassword };
