const initialState = {
  menuToggle: false,
  calendlyLink: "",
  showDiversity: false,
  jobsPageTab: 0,
  closedJobPageNo: 1,
  jobsPagePaginationPage: 1,
  functionFilter: null,
};

export function shared(state = initialState, action) {
  switch (action.type) {
    case "NAV_BAR_MENU_TOGGLE":
      return {
        ...state,
        menuToggle: action.menuToggle,
      };
    case "SELECTED_NAVBAR_INDEX":
      return {
        ...state,
        selectedNavbarIndex: action.selectedNavbarIndex,
      };
    case "JOBS_PAGE_TAB":
      return {
        ...state,
        jobsPageTab: action.jobsPageTab,
      };
    case "JOBS_PAGE_PAGINATION_PAGE":
      return {
        ...state,
        jobsPagePaginationPage: action.jobsPagePaginationPage,
      };
    case "ACCESS_PERMISSION":
      return {
        ...state,
        accessPermission: action.accessPermission,
      };
    case "SET_CALENDLY_LINK":
      return {
        ...state,
        calendlyLink: action.calendlyLink,
      };
    case "SET_DIVERSITY_FLAG":
      return {
        ...state,
        showDiversity: action.showDiversity,
      };
    case "NAVBAR_VISIBILITY":
      return {
        ...state,
        isNavbarVisible: action.isNavbarVisible,
      };
    case "CLOSED_JOB_PAGE_VALUE":
      return {
        ...state,
        closedJobPageNo: action.closedJobPageNo,
      };
    case "FUNCTION_FILTER":
      return {
        ...state,
        functionFilter: action.functionFilter,
      };
    case "BAD_REQUEST":
      return {
        ...state,
        badRequest: action.badRequest,
      };
    case "SET_DECRYPTED_GOOGLE_KEY":
      return {
        ...state,
        decryptedGoogleKey: action.decryptedGoogleKey,
      };
    case "SET_DECRYPTED_CLOUD_NAME":
      return {
        ...state,
        decryptedCloudName: action.decryptedCloudName,
      };
    case "SET_DECRYPTED_STRIPE_KEY":
      return {
        ...state,
        decryptedStripeKey: action.decryptedStripeKey,
      };
    case "SET_DECRYPTED_UPLOAD_PRESET":
      return {
        ...state,
        decryptedUploadPreset: action.decryptedUploadPreset,
      };
    default:
      return state;
  }
}
