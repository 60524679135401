import React, { useEffect, useState } from "react";
import {
  Grid,
  Button,
  Typography,
  Dialog,
  IconButton,
  Divider,
  DialogActions,
  DialogTitle,
  DialogContent,
  TextField,
  Paper,
  Tooltip,
  Popper,
  Fade,
} from "@material-ui/core";
import classNames from "classnames";
import moment from "moment";
import DeleteIcon from "@material-ui/icons/Delete";
import { makeStyles } from "@material-ui/core/styles";
import { useSelector, useDispatch } from "react-redux";
import { toast } from "react-toastify";
import CloseIcon from "@material-ui/icons/Close";
import { useParams } from "react-router";
import { jobsActions } from "../../../actions/jobs.actions";
import cardHeaderBg from "../../../assets/lite_profile_bg.png";
import { ViewMore } from "../../core/viewmore";

const useStyles = makeStyles((theme) => ({
  smallTopMargin: {
    marginTop: "2%",
  },
  customCardForComments: {
    backgroundColor: "#F7F7F7",
    borderRadius: "10px",
    padding: "20px",
  },
  customPopper: {
    padding: theme.spacing(2),
    [theme.breakpoints.between("xs", "sm")]: {
      padding: theme.spacing(1),
      width: "min-content !important",
    },
  },
  smallBottomMargin: {
    marginBottom: "2%",
  },
  customDialogHeader: {
    maxHeight: "38%",
    height: "38%",
    color: "#000",
    backgroundSize: "cover",
    backgroundImage: `url(${cardHeaderBg})`,
    background: "round",
    textAlign: "center",
  },
  dialogActions: {
    margin: 0,
    padding: theme.spacing(2),
    backgroundColor: "#fff",
    justifyContent: "center !important",
  },
  displayFlex: {
    display: "flex",
  },
  justifyContentCenter: {
    justifyContent: "center !important",
  },
  textAlignCenter: {
    textAlign: "center",
  },
  customEqualButtons: {
    width: "8em",
    margin: "10px !important",
    borderRadius: "3px !important",
  },
  closeButton: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.default.main,
    padding: "0px",
  },
}));

function Commentpopup(props) {
  const { isOpen, closeCandidateCommentsPopup } = props;
  const classes = useStyles();
  const dispatch = useDispatch();

  const { candidateComments } = useSelector((state) => state?.jobs);
  const { fullProfileRecommendationId } = useSelector((state) => state.members);
  const { id } = useSelector((state) => state.jobs.jobDetails);
  const { recruiter_id } = useSelector(
    (state) => state.authentication?.recruiterStatusDetails
  );
  const [comment, setComment] = useState("");

  const query = useParams();

  const [values, setValues] = useState({
    openPopper: false,
    selectedComment: null,
    anchorEl: null,
    arrowRef: null,
  });

  const handleCommentChange = (event) => {
    event.preventDefault();
    setComment(event.target.value);
  };

  useEffect(() => {
    dispatch(jobsActions.getCandidateComments(fullProfileRecommendationId, id));
  }, [fullProfileRecommendationId, id, dispatch]);

  const handleSubmitComment = (event) => {
    event.preventDefault();
    if (
      comment?.trim()?.length === 0 ||
      comment === undefined ||
      comment === null
    ) {
      setComment("");
      toast.dark("Please add a note to submit");
      return;
    }
    const payload = {
      comment,
      recommendation: fullProfileRecommendationId,
    };
    dispatch(jobsActions.addCandidateComment(payload, id));
    setValues({ openPopper: false });
    closeCandidateCommentsPopup();
  };

  const openConfirmDeleteCommentPopper = (id, event) => {
    setValues({
      ...values,
      openPopper: true,
      selectedComment: id,
      anchorEl: event.currentTarget,
    });
  };

  const handleArrowRef = (node) => {
    setValues({
      arrowRef: node,
    });
  };

  const handleDeleteComment = (event) => {
    event.preventDefault();
    dispatch(
      jobsActions.deleteCandidateComment(
        values.selectedComment,
        id,
        query?.recommendationId
      )
    );
    setValues({ ...values, openPopper: false });
    closeCandidateCommentsPopup();
  };

  const closeConfirmDeleteCommentPopper = () => {
    setValues({ ...values, openPopper: false });
  };

  return (
    <Dialog
      disableBackdropClick
      disableEscapeKeyDown
      aria-labelledby="customized-dialog-title"
      maxWidth="md"
      fullWidth
      open={isOpen}
    >
      <DialogTitle className={classes.customDialogHeader}>
        <Typography variant="h5" style={{ padding: "10px" }}>
          Client Internal Notes
        </Typography>
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={closeCandidateCommentsPopup}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        <Grid container spacing={4} className={classes.smallTopMargin}>
          <Grid item xs={12} lg={12} xl={12} sm={12} md={12}>
            <TextField
              label="Client Internal Note"
              value={comment}
              variant="outlined"
              inputProps={{ maxLength: 1000 }}
              multiline
              rows={5}
              rowsMax={5}
              placeholder="Add Client Internal Note (max 1000 characters)"
              fullWidth
              onChange={(event) => {
                handleCommentChange(event);
              }}
            />
            <Typography
              color="textSecondary"
              align="right"
              component="div"
              style={{ fontSize: "small", marginTop: "8px" }}
            >
              {`${comment?.length}/1000 characters`}
            </Typography>
          </Grid>
          <Grid item xs={12} lg={12} xl={12} sm={12} md={12}>
            <Divider className={classes.customDivider} />
          </Grid>
          <Grid
            item
            xs={12}
            lg={12}
            xl={12}
            sm={12}
            md={12}
            style={{ textAlign: "left", paddingTop: 0 }}
          >
            {candidateComments?.comments?.length ? (
              candidateComments?.comments.map((comment, index) => (
                <div
                  className={classNames(
                    classes.customCardForComments,
                    index !== comment.length ? classes.smallBottomMargin : ""
                  )}
                  key={`${comment}-${index}`}
                >
                  <Grid container alignItems="center">
                    <Grid item xs={12} sm={12} md={12} lg={9} xl={9}>
                      <Typography color="textPrimary" component="div">
                        <strong>
                          {comment?.recruiter?.name || "Unknown Admin"}
                        </strong>
                      </Typography>
                      <Typography color="textPrimary" component="div">
                        <ViewMore>{comment?.comment}</ViewMore>
                      </Typography>
                    </Grid>
                    <Grid item xs={12} sm={12} md={12} lg={3} xl={3}>
                      <Typography
                        component="div"
                        color="secondary"
                        align="right"
                      >
                        <Tooltip
                          arrow
                          title={moment(comment?.created_at)
                            .format("MM-DD-YYYY hh:mm A")
                            .toString()}
                        >
                          <i>{moment(comment?.created_at).fromNow()}</i>
                        </Tooltip>{" "}
                        &nbsp;
                        {comment?.recruiter?.recruiter_id === recruiter_id && (
                          <Tooltip
                            arrow
                            title="Delete client internal note"
                            placement="top"
                          >
                            <IconButton
                              onClick={(event) => {
                                openConfirmDeleteCommentPopper(
                                  comment?.id,
                                  event
                                );
                              }}
                            >
                              <DeleteIcon />
                            </IconButton>
                          </Tooltip>
                        )}
                        <Popper
                          open={values?.openPopper}
                          anchorEl={values?.anchorEl}
                          placement="bottom-end"
                          transition
                          style={{ zIndex: 9999 }}
                          modifiers={{
                            flip: {
                              enabled: true,
                            },
                            arrow: {
                              enabled: true,
                              //    element: this.state.arrowRef,
                            },
                            preventOverflow: {
                              enabled: "true",
                              boundariesElement: "scrollParent",
                            },
                          }}
                        >
                          {({ TransitionProps }) => (
                            <Fade {...TransitionProps} timeout={350}>
                              <Paper style={{ borderRadius: "10px" }}>
                                {true && (
                                  // <span className={classes.arrow} ref={this.handleArrowRef} />
                                  <span ref={() => handleArrowRef} />
                                )}
                                <Grid className={classes.customPopper}>
                                  <Grid
                                    items
                                    xs={12}
                                    sm={12}
                                    md={12}
                                    lg={12}
                                    xl={12}
                                    className={classes.smallBottomMargin}
                                  >
                                    <Typography component="div">
                                      Are you sure you want to delete this note?
                                    </Typography>
                                  </Grid>
                                  <Grid
                                    className={classNames(
                                      classes.displayFlex,
                                      classes.justifyContentCenter,
                                      classes.textAlignCenter
                                    )}
                                    items
                                    xs={12}
                                    sm={12}
                                    md={12}
                                    lg={12}
                                    xl={12}
                                  >
                                    <Button
                                      size="small"
                                      className={classes.customEqualButtons}
                                      onClick={(event) => {
                                        event.preventDefault();
                                        closeConfirmDeleteCommentPopper();
                                      }}
                                      style={{
                                        background: "#D7D9DB",
                                        color: "#000",
                                      }}
                                      variant="contained"
                                      color="primary"
                                      disableElevation
                                    >
                                      Cancel
                                    </Button>
                                    <Button
                                      className={classes.customEqualButtons}
                                      size="small"
                                      variant="contained"
                                      color="primary"
                                      onClick={(event) => {
                                        handleDeleteComment(event);
                                      }}
                                      autoFocus
                                      disableElevation
                                    >
                                      Yes, Delete
                                    </Button>
                                  </Grid>
                                </Grid>
                              </Paper>
                            </Fade>
                          )}
                        </Popper>
                      </Typography>
                    </Grid>
                  </Grid>
                </div>
              ))
            ) : (
              <Typography className={classes.noDataSmall} color="textSecondary">
                No client internal notes found
              </Typography>
            )}
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions className={classes.dialogActions}>
        <Button
          variant="contained"
          color="primary"
          disableElevation
          size="large"
          disabled={
            comment?.trim()?.length === 0 ||
            comment === undefined ||
            comment === null
          }
          onClick={handleSubmitComment}
        >
          Submit
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default Commentpopup;
