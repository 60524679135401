import React, { useState, useEffect } from "react";
import {
  Button,
  Card,
  Grid,
  Paper,
  Tooltip,
  Typography,
  IconButton,
  TextareaAutosize,
  Popper,
  Fade,
} from "@material-ui/core";
import moment from "moment";
import classNames from "classnames";
import { makeStyles } from "@material-ui/core/styles";
import Delete from "@material-ui/icons/Delete";
import { toast } from "react-toastify";
import { useDispatch, useSelector } from "react-redux";
import { ViewMore } from "../core/viewmore";
import {
  addCandidateComment,
  getCandidateComments,
  deleteCandidateComment,
} from "../../actions/jobs.actions";

const useStyles = makeStyles((theme) => ({
  customPopper: {
    padding: theme.spacing(2),
    [theme.breakpoints.between("xs", "sm")]: {
      padding: theme.spacing(1),
      width: "min-content !important",
    },
  },
  smallBottomMargin: {
    marginBottom: "2%",
  },
  displayFlex: {
    display: "flex",
  },
  justifyContentCenter: {
    justifyContent: "center !important",
  },
  textAlignCenter: {
    textAlign: "center",
  },
  customEqualButtons: {
    width: "8em",
    margin: "10px !important",
    borderRadius: "3px !important",
  },
  mainDiv: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    marginTop: "12px",
  },
  textAreaStyle: {
    height: "100px !important",
    overflow: "hidden",
    marginTop: "12px",
    marginBottom: "4px",
    width: "100%",
    resize: "none",
    padding: "8px",
    fontFamily: "Public Sans",
    borderRadius: "5px",
    overflowY: "auto",
  },
  submitButtonDiv: {
    textAlign: "left",
    marginTop: "8px",
  },
  submitButton: {
    textAlign: "left",
    minWidth: "8em",
  },
  mainComment: {
    minHeight: "200px",
    margin: "32px 0px 0px 0px",
  },
  cardMainDiv: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
  },
  nametypo: {
    fontWeight: 700,
    fontSize: "12px",
  },
  timeTypo: {
    fontWeight: 400,
    fontSize: 13,
  },
  commentTypoMainDiv: {
    display: "flex",
    justifyContent: "space-between",
    paddingTop: "3px",
    alignItems: "flex-start",
  },
  commentTypo: {
    fontWeight: 400,
    fontSize: 13,
    lineHeight: "150%",
    padding: "10px",
    overflow: "hidden",
  },
  popperStyle: {
    zIndex: 9999,
  },
  paperStyle: {
    borderRadius: "10px",
  },
  cardStyle: {
    boxShadow: "none",
    padding: "8px 8px",
  },
  marginTop5: {
    marginTop: "5px",
  },
  cancleButton: {
    background: "#D7D9DB",
    color: "#000",
  },
}));

function Comment({ jobRecommendedId }) {
  const classes = useStyles();
  const dispatch = useDispatch();

  const candidateComments = useSelector(
    (state) => state?.jobs.candidateComments?.comments
  );
  const id = useSelector((state) => state?.jobs?.jobDetails?.id);
  const job_status = useSelector(
    (state) => state?.jobs?.jobDetails?.job_status
  );
  const [comment, setComment] = useState("");
  const [openPopper, setOpenPopper] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const [arrowRef, setArrowRef] = useState(null);
  const [selectedComment, setSelectedComment] = useState("");

  const handleSubmitComment = () => {
    if (checkJobStatus() === 1) {
      closeConfirmDeleteCommentPopper();
      return;
    }
    if (
      comment?.trim()?.length === 0 ||
      comment === undefined ||
      comment === null
    ) {
      setComment("");
      toast.dark("Please add a note to submit");
      return;
    }
    const payload = {
      comment,
      recommendation: jobRecommendedId,
    };
    dispatch(addCandidateComment(payload, id));
    setComment("");
  };

  useEffect(() => {
    dispatch(getCandidateComments(jobRecommendedId, id));
  }, [jobRecommendedId, id, dispatch]);

  const handleArrowRef = (node) => {
    setArrowRef(node);
  };

  const closeConfirmDeleteCommentPopper = () => {
    setOpenPopper(false);
  };

  const openConfirmDeleteCommentPopper = (commentId, event) => {
    if (checkJobStatus() === 1) {
      closeConfirmDeleteCommentPopper();
      return;
    }
    setOpenPopper(true);
    setAnchorEl(event.currentTarget);
    setSelectedComment(commentId);
  };

  const checkJobStatus = () => {
    switch (job_status) {
      case "Close":
      case "Closed":
      case "close":
      case "closed":
        toast.dark("This job is already closed");
        return 1;
      default:
        return 0;
    }
  };

  const handleDeleteComment = () => {
    dispatch(deleteCandidateComment(selectedComment, id, jobRecommendedId));
    closeConfirmDeleteCommentPopper();
  };

  return (
    <>
      <div className={classes.mainDiv}>
        <Typography>
          <b>Client Internal Notes</b>
        </Typography>
      </div>
      <TextareaAutosize
        className={classes.textAreaStyle}
        value={comment}
        maxLength={1000}
        placeholder="Enter Client Internal Note"
        onChange={(event) => setComment(event.target.value)}
      />
      <div className={classes.submitButtonDiv}>
        <Button
          variant="outlined"
          size="medium"
          color="primary"
          disableElevation
          className={classes.submitButton}
          disabled={comment === ""}
          onClick={() => handleSubmitComment()}
        >
          Submit
        </Button>
      </div>
      <div className={classes.mainComment}>
        {candidateComments?.length > 0 ? (
          candidateComments?.map((comment) => (
            <Card
              elevation={1}
              className={classNames(classes.cardStyle, classes.marginTop5)}
            >
              <div className={classes.cardMainDiv}>
                <Typography className={classes.nametypo}>
                  {comment?.recruiter?.name || "Unknown Admin"}
                </Typography>
                <Tooltip
                  arrow
                  title={moment(comment?.created_at)
                    .format("MM-DD-YYYY hh:mm A")
                    .toString()}
                >
                  <Typography className={classes.timeTypo}>
                    {moment(comment?.created_at).fromNow()}
                  </Typography>
                </Tooltip>
              </div>
              <div className={classes.commentTypoMainDiv}>
                <Typography className={classes.commentTypo} align="left">
                  <ViewMore length={30}>{comment?.comment}</ViewMore>
                </Typography>
                <Tooltip arrow title="Delete note" placement="top">
                  <IconButton
                    onClick={(event) => {
                      openConfirmDeleteCommentPopper(comment?.id, event);
                    }}
                  >
                    <Delete />
                  </IconButton>
                </Tooltip>
                <Popper
                  open={openPopper}
                  anchorEl={anchorEl}
                  placement="left"
                  transition
                  className={classes.popperStyle}
                  modifiers={{
                    flip: {
                      enabled: true,
                    },
                    arrow: {
                      enabled: true,
                      element: arrowRef,
                    },
                    preventOverflow: {
                      enabled: "true",
                      boundariesElement: "scrollParent",
                    },
                  }}
                >
                  {({ TransitionProps }) => (
                    <Fade {...TransitionProps} timeout={350}>
                      <Paper className={classes.paperStyle}>
                        {true && (
                          // <span className={classes.arrow} ref={this.handleArrowRef} />
                          <span ref={handleArrowRef} />
                        )}
                        <Grid className={classes.customPopper}>
                          <Grid
                            items
                            xs={12}
                            sm={12}
                            md={12}
                            lg={12}
                            xl={12}
                            className={classes.smallBottomMargin}
                          >
                            <Typography component="div">
                              Are you sure you want to delete this note?
                            </Typography>
                          </Grid>
                          <Grid
                            className={classNames(
                              classes.displayFlex,
                              classes.justifyContentCenter,
                              classes.textAlignCenter
                            )}
                            items
                            xs={12}
                            sm={12}
                            md={12}
                            lg={12}
                            xl={12}
                          >
                            <Button
                              size="small"
                              className={classNames(
                                classes.customEqualButtons,
                                classes.cancleButton
                              )}
                              onClick={(event) => {
                                event.preventDefault();
                                closeConfirmDeleteCommentPopper();
                              }}
                              variant="contained"
                              color="primary"
                              disableElevation
                            >
                              Cancel
                            </Button>
                            <Button
                              className={classes.customEqualButtons}
                              size="small"
                              variant="contained"
                              color="primary"
                              onClick={(event) => {
                                event.preventDefault();
                                handleDeleteComment();
                              }}
                              autoFocus
                              disableElevation
                            >
                              Yes, Delete
                            </Button>
                          </Grid>
                        </Grid>
                      </Paper>
                    </Fade>
                  )}
                </Popper>
              </div>
            </Card>
          ))
        ) : (
          <Typography className={classes.noDataSmall} color="textSecondary">
            No client internal notes found
          </Typography>
        )}
      </div>
    </>
  );
}

export default Comment;
