import React, { useState } from "react";
import {
  Dialog,
  DialogContent,
  Button,
  TextField,
  Typography,
  Avatar,
  InputAdornment,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import ArrowRightAltIcon from "@material-ui/icons/ArrowRightAlt";
import RemoveRedEyeIcon from "@material-ui/icons/RemoveRedEye";
import VisibilityOffIcon from "@material-ui/icons/VisibilityOff";
import { ReactComponent as HeaderLogo } from "../../assets/shareProfilePupupLogo.svg";
import { useDispatch } from "react-redux";
import { getSharedFullProfile } from "../../actions";
import { encryptData } from "../../helpers";
import { DIALOG_BOX } from "../../constants/constants";
import { master_key } from "../../config/config";

const useStyles = makeStyles(() => ({
  dialogBackground: {
    background: "#FEF5DB",
  },
  parentDiv: {
    alignItems: "center",
    display: "flex",
    justifyContent: "center",
    marginTop: "10px",
    marginBottom: "30px",
  },
  avatar: {
    width: "80px",
    height: "80px",
  },
  submitButton: {
    marginLeft: "10px",
    background: "#000000",
    color: "#ffffff",
    maxHeight: "60px",
  },
  inputBackground: {
    "& .MuiOutlinedInput-root": {
      backgroundColor: "#ffffff",
    },
  },
  subHeading: {
    marginBottom: "20px",
    fontSize: "16px",
  },
  inputParentDiv: {
    display: "flex",
    justifyContent: "center",
    alignItems: "flex-start",
    marginBottom: "24px",
  },
  eyeIconStyle: {
    cursor: "pointer",
  },
  "& .MuiFormHelperText-contained": {
    background: "red !important",
  },
}));

const ShareProfileCredential = ({ candidate_id }) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [password, setPassword] = useState(null);
  const [passwordVisible, setPasswordVisible] = useState(false);

  const handlePassword = (value) => {
    setPassword(value);
  };

  const onSubmit = () => {
    let encryptedPassword = encryptData(master_key, `:${password}`);
    dispatch(getSharedFullProfile(candidate_id, encryptedPassword));
  };

  const handlePasswordVisible = () => {
    setPasswordVisible(!passwordVisible);
  };

  return (
    <Dialog open={true}>
      <DialogContent className={classes.dialogBackground}>
        <div className={classes.parentDiv}>
          <Avatar className={classes.avatar}>
            <HeaderLogo />
          </Avatar>
        </div>
        <Typography align="center" className={classes.subHeading}>
          {DIALOG_BOX.SHARE_PROFILE_PASSWORD.DIALOG_SUBTITLES}
        </Typography>
        <div className={classes.inputParentDiv}>
          <TextField
            id="outlined-basic"
            variant="outlined"
            type={!passwordVisible ? "password" : "text"}
            value={password}
            onChange={(e) => handlePassword(e.target.value)}
            className={classes.inputBackground}
            InputProps={{
              endAdornment: (
                <InputAdornment
                  position="start"
                  className={classes.eyeIconStyle}
                  onClick={() => handlePasswordVisible()}
                >
                  {!passwordVisible ? (
                    <RemoveRedEyeIcon />
                  ) : (
                    <VisibilityOffIcon />
                  )}
                </InputAdornment>
              ),
            }}
          />
          <Button
            size="large"
            disableElevation
            disabled={password?.trim().length === 0}
            variant="contained"
            className={classes.submitButton}
            onClick={onSubmit}
          >
            <ArrowRightAltIcon />
          </Button>
        </div>
      </DialogContent>
    </Dialog>
  );
};

export default ShareProfileCredential;
