import React from "react";
import { Grid, Divider, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import EmptyState from "../core/emptyState";
import { ViewMore } from "../core/viewmore";

const useStyles = makeStyles(() => ({
  headingGrid: {
    textAlign: "left",
    fontWeight: "bolder",
  },
  dividerTopTab: {
    marginTop: "15px",
    marginBottom: "25px",
  },
  fontSize16: {
    fontSize: "16px",
  },
  mainGrid: {
    marginTop: "20px",
    padding: "20px",
  },
}));

function CoffeeQuestion(props) {
  const { coffeQuestion } = props;
  const classes = useStyles();
  const EMPTY_ICON_HEIGHT = 120;
  const EMPTY_ICON_WIDTH = 120;

  return (
    <Grid container>
      <Grid
        item
        xs={12}
        sm={12}
        md={12}
        xl={12}
        lg={12}
        className={classes.mainGrid}
      >
        <Typography variant="h6" className={classes.headingGrid}>
          Coffee Questions
        </Typography>
        <Divider className={classes.dividerTopTab} />
        <Grid container>
          {coffeQuestion?.length !== 0 ? (
            coffeQuestion?.map((qa, index) => (
              <>
                <Grid
                  item
                  xs={2}
                  sm={2}
                  md={1}
                  xl={1}
                  lg={1}
                  style={{ marginTop: index !== 0 ? "35px" : "25px" }}
                >
                  <Typography variant="body1">{index + 1}.</Typography>
                </Grid>
                <Grid
                  item
                  xs={10}
                  sm={10}
                  md={11}
                  xl={11}
                  lg={11}
                  style={{ marginTop: index !== 0 ? "35px" : "25px" }}
                >
                  <Typography align="left">
                    <div>
                      <strong>{qa.question}</strong>
                    </div>
                  </Typography>
                  <div align="left">
                    <ViewMore
                      className={classes.fontSize16}
                      length="200"
                      expanded="true"
                    >
                      {qa?.option ? `${qa?.option} - ${qa?.answer}` : qa.answer}
                    </ViewMore>
                  </div>
                </Grid>
              </>
            ))
          ) : (
            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
              <EmptyState
                type="coffeequestions"
                height={EMPTY_ICON_HEIGHT}
                width={EMPTY_ICON_WIDTH}
              />
            </Grid>
          )}
        </Grid>
      </Grid>
    </Grid>
  );
}

export default CoffeeQuestion;
