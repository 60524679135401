import React from "react";
import TextField from "@material-ui/core/TextField";
import Autocomplete from "@material-ui/lab/Autocomplete";
import LocationOnIcon from "@material-ui/icons/LocationOn";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
import parse from "autosuggest-highlight/parse";
import throttle from "lodash/throttle";
import { useDispatch, useSelector } from "react-redux";
import { MESSAGE_FIELD_IS_REQUIRED } from "../../constants/constants";
import { getGoogleKey, setLocation } from "../../actions";
import secureLocalStorage from "react-secure-storage";
import { useEffect } from "react";

function loadScript(src, position, id) {
  if (!position) {
    return;
  }
  const script = document.createElement("script");
  script.setAttribute("async", "");
  script.setAttribute("id", id);
  script.src = src;
  position.appendChild(script);
}

const autocompleteService = { current: null };
const placesService = { current: null };
let map;

const useStyles = makeStyles((theme) => ({
  icon: {
    color: theme.palette.text.secondary,
    marginRight: theme.spacing(2),
  },
}));

export function GoogleMaps(props) {
  const classes = useStyles();
  const [value, setValue] = React.useState(null);
  const [inputValue, setInputValue] = React.useState("");
  const [options, setOptions] = React.useState([]);
  const loaded = React.useRef(false);
  const dispatch = useDispatch();
  let decryptedGoogleKey = useSelector(
    (state) => state.shared.decryptedGoogleKey
  );
  useEffect(() => {
    dispatch(getGoogleKey());
  }, []);

  if (typeof window !== "undefined" && !loaded.current && decryptedGoogleKey) {
    if (!document.querySelector("#google-maps")) {
      loadScript(
        `https://maps.googleapis.com/maps/api/js?key=${decryptedGoogleKey}&libraries=places`,
        document.querySelector("head"),
        "google-maps"
      );
      // &type={cities)
    }
    loaded.current = true;
  }

  const fetch = React.useMemo(
    () =>
      throttle((request, callback) => {
        // hit api only after entering 3 letters
        if (request.input.length > 2) {
          autocompleteService.current.getPlacePredictions(request, callback);
        }
      }, 200),
    []
  );

  React.useEffect(() => {
    let active = true;

    if (
      !autocompleteService.current &&
      window.google &&
      !placesService.current
    ) {
      autocompleteService.current =
        new window.google.maps.places.AutocompleteService();
      map = new window.google.maps.Map(document.getElementById("google-maps"));
    }

    if (!autocompleteService.current) {
      return undefined;
    }

    if (inputValue === "") {
      setOptions(value ? [value] : []);
      return undefined;
    }
    fetch(
      {
        input: inputValue,
        // componentRestrictions: { country: "us" },
        types: ["(cities)"],
      },
      (results) => {
        if (active) {
          let newOptions = [];

          if (value) {
            if (window.google) {
              const service = new window.google.maps.places.PlacesService(map);
              const req = {
                placeId: value.place_id,
              };

              service.getDetails(req, (place, status) => {
                if (
                  status === window.google.maps.places.PlacesServiceStatus.OK
                ) {
                  const locationDetails = {};
                  locationDetails.place_id = value.place_id;
                  locationDetails.lat = place.geometry.location.lat();
                  locationDetails.lng = place.geometry.location.lng();
                  locationDetails.address_line_1 = null;
                  locationDetails.address_line_2 = null;
                  locationDetails.city = place.name;
                  // locationDetails.address = place.formatted_address;
                  for (let i = 0; i < place.address_components.length; i++) {
                    const addressType = place.address_components[i].types[0];
                    switch (addressType) {
                      case "country":
                        locationDetails.country =
                          place.address_components[i].short_name === "US"
                            ? `${place.address_components[i].short_name}A`
                            : place.address_components[i].short_name;
                        break;
                      case "administrative_area_level_1":
                        locationDetails.state =
                          place.address_components[i].short_name;
                        break;
                      // case 'locality':
                      //   locationDetails.city = place.address_components[i].short_name;
                      //   break;
                      // case 'administrative_area_level_2':
                      //   locationDetails.city = place.address_components[i].short_name;
                      //   break;
                      // case 'locality':
                      //   locationDetails.locality = place.address_components[i].short_name;
                      //   break;
                      // case 'sublocality_level_1':
                      //   locationDetails.sublocality1 = place.address_components[i].short_name;
                      //   break;
                      // case 'sublocality_level_2':
                      //     locationDetails.sublocality2 = place.address_components[i].short_name;
                      //     break;
                      default:
                        break;
                    }
                  }
                  secureLocalStorage.setItem(
                    "locationDetails",
                    JSON.stringify(locationDetails)
                  );
                  dispatch(setLocation(locationDetails));
                }
              });
            }
            newOptions = [value];
          }

          if (results) {
            newOptions = [...newOptions, ...results];
          }
          setOptions(newOptions);
        }
      }
    );

    return () => {
      active = false;
    };
  }, [value, inputValue, fetch, dispatch]);

  return (
    <Autocomplete
      fullWidth
      title={
        (JSON.parse(secureLocalStorage.getItem("locationDetails"))?.city !==
        undefined
          ? `${
              JSON.parse(secureLocalStorage.getItem("locationDetails"))?.city
            }, `
          : "") +
        (JSON.parse(secureLocalStorage.getItem("locationDetails"))?.state !==
        undefined
          ? `${
              JSON.parse(secureLocalStorage.getItem("locationDetails"))?.state
            }, `
          : "") +
        (JSON.parse(secureLocalStorage.getItem("locationDetails"))?.country !==
        undefined
          ? JSON.parse(secureLocalStorage.getItem("locationDetails"))?.country
          : "")
      }
      id={`google-map-demo${window.location.pathname.replace("/", "-")}`}
      getOptionLabel={(option) =>
        typeof option === "string" ? option : option.description
      }
      filterOptions={(x) => x}
      options={options}
      autoComplete
      includeInputInList
      filterSelectedOptions
      value={
        value ||
        (JSON.parse(secureLocalStorage.getItem("locationDetails"))?.city
          ? `${
              JSON.parse(secureLocalStorage.getItem("locationDetails"))?.city
            }, `
          : "") +
          (JSON.parse(secureLocalStorage.getItem("locationDetails"))?.state
            ? `${
                JSON.parse(secureLocalStorage.getItem("locationDetails"))?.state
              }, `
            : "") +
          (JSON.parse(secureLocalStorage.getItem("locationDetails"))?.country
            ? JSON.parse(secureLocalStorage.getItem("locationDetails"))?.country
            : "")
      }
      onChange={(event, newValue) => {
        if (newValue) {
          setOptions(newValue ? [newValue, ...options] : options);
          setValue(newValue);
          newValue ? props?.updateRequire(false) : props?.updateRequire(true);
        } else {
          setValue(newValue);
          secureLocalStorage.removeItem("locationDetails");
        }
      }}
      onInputChange={(event, newInputValue) => {
        setInputValue(newInputValue);
        if (newInputValue === "") {
          props?.updateRequire(true);
          secureLocalStorage.removeItem("locationDetails");
        }
      }}
      onBlur={() => {
        if (window.location.pathname === "/members") {
          setOptions([]);
          setValue(null);
        }
      }}
      renderInput={(params) => (
        <TextField
          {...params}
          required={props.isRequire}
          variant={
            window.location.pathname === "/members" ? "outlined" : "standard"
          }
          label={
            window.location.pathname !== "/members"
              ? "Company HQ Location"
              : "Location"
          }
          placeholder={
            window.location.pathname !== "/members"
              ? "Enter company HQ location(US location only)"
              : "Location"
          }
          error={props.isRequire}
          helperText={props.isRequire ? MESSAGE_FIELD_IS_REQUIRED : null}
          value={
            value ||
            (JSON.parse(secureLocalStorage.getItem("locationDetails"))?.city
              ? `${
                  JSON.parse(secureLocalStorage.getItem("locationDetails"))
                    ?.city
                }, `
              : "") +
              (JSON.parse(secureLocalStorage.getItem("locationDetails"))?.state
                ? `${
                    JSON.parse(secureLocalStorage.getItem("locationDetails"))
                      ?.state
                  }, `
                : "") +
              (JSON.parse(secureLocalStorage.getItem("locationDetails"))
                ?.country
                ? JSON.parse(secureLocalStorage.getItem("locationDetails"))
                    ?.country
                : "")
          }
          fullWidth
        />
      )}
      renderOption={(option) => {
        const matches =
          option.structured_formatting.main_text_matched_substrings;
        const parts = parse(
          option.structured_formatting.main_text,
          matches.map((match) => [match.offset, match.offset + match.length])
        );

        return (
          <Grid container alignItems="center">
            <Grid item>
              <LocationOnIcon className={classes.icon} />
            </Grid>
            <Grid item xs>
              {parts.map((part, index) => (
                <span
                  key={index}
                  style={{ fontWeight: part.highlight ? 700 : 400 }}
                >
                  {part.text}
                </span>
              ))}
              <Typography variant="body2" color="textSecondary">
                {option.structured_formatting.secondary_text}
              </Typography>
            </Grid>
          </Grid>
        );
      }}
    />
  );
}
