import { toast } from "react-toastify";
import CryptoJS from "crypto-js";
import { REMOVE_DASH_REGEX } from "../constants/constants";

export const notAllowWhiteSpaceAtStart = (value) => {
  if (typeof value === "string") {
    return value.replace(/^\s*\s*$/, "");
    // return value.trim();
    // return validator.trim(value)
  }
  return value;
};

export const trim = (value) => {
  if (typeof value === "string") {
    return value.replace(/\s+/g, " ").trimEnd();
  }
  return value;
};

export const truncate = (str, n = 30) => {
  if (str !== null && str !== undefined) {
    return str.length > n ? `${str.substr(0, n - 1)}...` : str;
  }
};

export const removeTags = (value) => {
  const regex = /(<em>|<em [^>]+>|<em>|<\/em>)/g;
  if (value !== null && value !== undefined) {
    const emptyObject =
      Object.keys(value).length === 0 && value.constructor === Object;
    if (value && !emptyObject) {
      if (value.constructor === Object || value.constructor === Array) {
        return;
      }
      return value.replace(regex, "");
    }
    value = "";
    return value.replace(regex, "");
  }
};

export const removeUnderscores = (value) => {
  const regex = /_/g;
  if (value !== null && value !== undefined) {
    const emptyObject =
      Object.keys(value).length === 0 && value.constructor === Object;
    if (value && !emptyObject) {
      if (value.constructor === Object || value.constructor === Array) {
        return;
      }
      return value.replace(regex, " ");
    }
    value = "";
    return value.replace(regex, " ");
  }
};

// for remove (-)
export const modifyString = (inputvalue) => {
  return inputvalue.replace(REMOVE_DASH_REGEX, "");
};

export function seo(data = {}) {
  data.title = data.title || "SucceedSmart Corporate Portal";
  data.metaDescription =
    data.metaDescription || "A reimagined platform for executive search";
  document.title = data.title;
  document
    .querySelector('meta[name="description"]')
    .setAttribute("content", data.metaDescription);
}

export const capitalize = (str) => {
  if (str !== null && str !== undefined && typeof str === "string") {
    return str
      .split(" ")
      .map((w) => w.substring(0, 1).toUpperCase() + w.substring(1))
      .join(" ");
  }
  return str;
};

/**
 *
 * @param {array<obj>} arrOfObj
 * @param {string} key
 * @param {string} value
 * @returns true of any obj[key] === value otherwise false
 */
export const arrayIncludesKeyVal = (arrOfObj, key, value) => {
  const len = arrOfObj.length;
  for (let index = 0; index < len; index++) {
    const element = arrOfObj[index];
    if (element[key] === value) return true;
  }
  return false;
};

export const getImgObjURL = async (url) => {
  try {
    const res = await fetch(url);
    const imageBlob = await res.blob();
    if (!imageBlob.type.startsWith("image/")) return null;
    const imageObjectURL = URL.createObjectURL(imageBlob);
    return imageObjectURL;
  } catch (e) {
    console.log(e);
    return null;
  }
};

export const openLinkInNewTab = (domain) => {
  if (domain) {
    if (!/^https?:\/\//i.test(domain)) {
      window.open(`http://${domain}`, "_blank");
    } else {
      window.open(domain, "_blank");
    }
  } else {
    toast.dark("Domain not found");
  }
};

export function getBrowserVisibilityProp() {
  if (typeof document.hidden !== "undefined") {
    // Opera 12.10 and Firefox 18 and later support
    return "visibilitychange";
  }
  if (typeof document.msHidden !== "undefined") {
    return "msvisibilitychange";
  }
  if (typeof document.webkitHidden !== "undefined") {
    return "webkitvisibilitychange";
  }
}

export function getBrowserDocumentHiddenProp() {
  if (typeof document.hidden !== "undefined") {
    return "hidden";
  }
  if (typeof document.msHidden !== "undefined") {
    return "msHidden";
  }
  if (typeof document.webkitHidden !== "undefined") {
    return "webkitHidden";
  }
}

export function decryptData(securekey, value) {
  const encryptedData = value;
  const Base64Key = securekey;
  const key = CryptoJS.enc.Base64.parse(Base64Key);
  const dataBytes = CryptoJS.enc.Base64.parse(encryptedData);
  const iv = CryptoJS.lib.WordArray.create(dataBytes.words.slice(0, 4));
  const ciphertext = CryptoJS.lib.WordArray.create(dataBytes.words.slice(4));
  const decryptedBytes = CryptoJS.AES.decrypt({ ciphertext }, key, { iv });
  const decryptedText = CryptoJS.enc.Utf8.stringify(decryptedBytes);
  return decryptedText;
}

export function encryptData(securekey, value) {
  const plaintext = value;
  const Base64Key = securekey;
  const key = CryptoJS.enc.Base64.parse(Base64Key);
  const iv = CryptoJS.lib.WordArray.random(16); // Generate a random IV (Initialization Vector)

  const encryptedBytes = CryptoJS.AES.encrypt(plaintext, key, { iv });
  const ciphertext = encryptedBytes.ciphertext;

  const ivWords = iv.words;
  const ciphertextWords = ciphertext.words;

  const encryptedDataWords = ivWords.concat(ciphertextWords);

  const encryptedData = CryptoJS.enc.Base64.stringify(
    CryptoJS.lib.WordArray.create(encryptedDataWords)
  );
  return encryptedData;
}

export const copyToClipboard = (data) => {
  navigator.clipboard
    .writeText(data)
    .then(() => {
      toast.dark("Profile information copied successfully");
    })
    .catch((error) => {
      console.error("Unable to copy data: ", error);
    });
};
export const validatePassword = (password) => {
  var pattern = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[^a-zA-Z0-9]).{8,}$/;

  if (pattern.test(password)) {
    return true;
  } else {
    return false;
  }
};

export function getIsDocumentHidden() {
  return !document[getBrowserDocumentHiddenProp()];
}
