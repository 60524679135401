import {
  Grid,
  Card,
  Avatar,
  Typography,
  Box,
  Tabs,
  Tab,
} from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import classNames from "classnames";
import { makeStyles } from "@material-ui/core/styles";
import ProfileHighlights from "../../customComponents/profileHighlights";
import Experience from "../../customComponents/experience";
import Accomplishments from "../../customComponents/accomplishments";
import Competencies from "../../customComponents/competencies";
import Boa from "../../customComponents/boa";
import Education from "../../customComponents/education";
import Certification from "../../customComponents/certification";
import AdminNotes from "../../customComponents/adminNotes";
import Resume from "../../customComponents/resume";
import CoffeeQuestion from "../../customComponents/coffeeQuestion";
import CandidateVideos from "../../customComponents/candidateVideos";
import { capitalize } from "../../../helpers";
import ShareProfileCredential from "../../customComponents/shareProfileCredential";
import { membersActions } from "../../../actions";
import { useParams } from "react-router-dom/cjs/react-router-dom.min";

const useStyles = makeStyles((theme) => ({
  typography: {
    fontFamily: "Public Sans",
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(0, 6),
    [theme.breakpoints.down("md")]: {
      padding: theme.spacing(1),
    },
    [theme.breakpoints.down("sm")]: {
      padding: theme.spacing(1),
    },
  },
  mainCard: {
    padding: "15px",
    borderRadius: "10px !important",
  },
  nameGrid: {
    padding: "5px 15px 5px 25px",
    display: "flex",
    alignItems: "center",
  },
  paddingMedium: {
    paddingLeft: "15px",
  },
  colorDefault: {
    color: "#000000 !important",
  },
  textAlignLeft: { textAlign: "left" },
  boldFont: { fontWeight: "bold" },
  fullNameTypo: {
    marginTop: "15px",
    color: "#000000",
  },
  customBoldFont: { fontWeight: "500" },
  verySmallTopMargin: {
    marginTop: "1%",
  },
  customTabs: {
    textTransform: "inherit",
    minWidth: "fit-content !important",
    margin: "0px 8px",
  },
  stickyBar: {
    position: "fixed",
    top: 0,
    width: "70% !important",
    zIndex: 1,
    background: "#ffffff",
    [theme.breakpoints.down("sm")]: {
      marginTop: "90px",
      width: "100% !important",
    },
  },
  stickyBarRemove: {
    position: "sticky",
  },
  overviewsMainDiv: {
    marginTop: "20px",
    padding: "20px",
  },
  mainRoot: {
    marginTop: "100px",
    width: "100%",
    marginBottom: "100px",
  },
  mainRootWithBlur: {
    marginTop: "100px",
    width: "100%",
    marginBottom: "100px",
    filter: "blur(1.2rem)",
  },
}));

function SharedFullProfile() {
  let sticky;
  const classes = useStyles();
  let { candidate_id } = useParams();

  const candidateDetails = useSelector(
    (state) => state?.members?.sharedProfileData
  );
  let dispatch = useDispatch();
  const isNavbarVisible = useSelector(
    (state) => state?.shared?.isNavbarVisible
  );

  const isPopupVisible = useSelector(
    (state) => state.members.shareProfileVisible
  );

  const [tabValue, setTabValue] = useState(0);

  useEffect(() => {
    document.body.scrollTop = 0; // For Safari
    document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE and Opera
    dispatch(membersActions.shareProfileVisible(true));
  }, []);

  const handleTabChange = (e, value) => {
    setTabValue(value);
  };

  useEffect(() => {
    function scrollFunction() {
      const navbar = document.getElementById("tabbar");
      sticky = navbar?.offsetTop;
    }
    // When the user scrolls down 20px from the top of the document, show the button
    window.onscroll = function () {
      scrollFunction();
    };
  });

  return (
    <div
      className={!isPopupVisible ? classes.mainRoot : classes.mainRootWithBlur}
    >
      <Grid container>
        <Grid item xs={12} sm={12} md={12} xl={12} lg={12}>
          <main className={classes.content}>
            <Card id="headerCard" className={classes.mainCard} elevation={3}>
              <Grid container>
                <Grid
                  item
                  xs={12}
                  sm={12}
                  md={12}
                  lg={12}
                  xl={12}
                  className={classes.nameGrid}
                >
                  <span>
                    <Avatar
                      alt={capitalize(
                        `${candidateDetails?.first_name}${candidateDetails?.last_name}`
                      )}
                      src={
                        candidateDetails?.profile_image ||
                        candidateDetails?.first_name
                      }
                      // className={classNames(classes.largeAvatar, classes.noImageAvatar)}
                      className="customMdAvatar"
                    />
                  </span>
                  <span className={classes.paddingMedium}>
                    <Typography
                      variant="h5"
                      className={classNames(
                        classes.colorDefault,
                        classes.textAlignLeft,
                        classes.boldFont,
                        classes.fullNameTypo
                      )}
                    >
                      {capitalize(
                        `${candidateDetails?.first_name} ${candidateDetails?.last_name}`
                      )}
                    </Typography>
                    <Typography
                      variant="body1"
                      className={classNames(
                        classes.colorDefault,
                        classes.textAlignLeft,
                        classes.customBoldFont,
                        classes.verySmallTopMargin
                      )}
                    >
                      {`${capitalize(
                        candidateDetails?.company?.title
                      )} at ${capitalize(
                        candidateDetails?.company?.company_name
                      )}`}
                    </Typography>
                    <Typography
                      variant="body1"
                      className={classNames(
                        classes.colorDefault,
                        classes.textAlignLeft,
                        classes.customBoldFont,
                        // classes.smallBottomMargin,
                        classes.verySmallTopMargin
                      )}
                    >
                      {(candidateDetails?.location?.city
                        ? `${capitalize(candidateDetails?.location?.city)}, `
                        : "") +
                        (candidateDetails?.location?.state
                          ? `${capitalize(candidateDetails?.location?.state)}, `
                          : "") +
                        (candidateDetails?.location?.country
                          ? capitalize(candidateDetails?.location?.country)
                          : "")}
                    </Typography>
                  </span>
                </Grid>
                <Box sx={{ maxWidth: { xs: "100%", sm: "100%" } }}>
                  <Tabs
                    textColor="#000000"
                    variant="scrollable"
                    TabIndicatorProps={{
                      style: { background: "#FBC112", height: "4px" },
                    }}
                    scrollButtons="auto"
                    aria-label="scrollable auto tabs example"
                    value={tabValue}
                    onChange={(e, value) => handleTabChange(e, value)}
                    id="tabbar"
                    className={
                      window.pageYOffset >= sticky &&
                      (isNavbarVisible === undefined ||
                        isNavbarVisible === false)
                        ? classes.stickyBar
                        : classes.stickyBarRemove
                    }
                  >
                    <Tab
                      style={{ fontWeight: tabValue === 0 ? "bold" : "" }}
                      label="Profile Highlights"
                      className={classes.customTabs}
                    />
                    <Tab
                      style={{ fontWeight: tabValue === 1 ? "bold" : "" }}
                      label="Experience"
                      className={classes.customTabs}
                    />
                    <Tab
                      style={{ fontWeight: tabValue === 2 ? "bold" : "" }}
                      label="Education"
                      className={classes.customTabs}
                    />
                    <Tab
                      style={{ fontWeight: tabValue === 3 ? "bold" : "" }}
                      label="Coffee Questions"
                      className={classes.customTabs}
                    />
                    <Tab
                      style={{ fontWeight: tabValue === 4 ? "bold" : "" }}
                      label="Candidate Videos"
                      className={classes.customTabs}
                    />
                    <Tab
                      style={{ fontWeight: tabValue === 5 ? "bold" : "" }}
                      label="Resume"
                      className={classes.customTabs}
                    />
                    <Tab
                      style={{ fontWeight: tabValue === 6 ? "bold" : "" }}
                      label="Notes"
                      className={classes.customTabs}
                    />
                  </Tabs>
                </Box>
                {tabValue === 0 && (
                  <>
                    <div className={classes.overviewsMainDiv}>
                      <ProfileHighlights
                        industries={candidateDetails?.industries}
                        level={candidateDetails?.company?.level}
                        experience={candidateDetails?.experience}
                        teamManaged={candidateDetails?.company?.team_managed}
                        countriesWorkedIn={candidateDetails?.company?.map}
                        filteredCompaniesLogoArrays={
                          candidateDetails?.logo_info
                        }
                      />
                    </div>
                    <Accomplishments
                      accomplishment={candidateDetails?.accomplishment}
                    />
                    <Competencies skills={candidateDetails?.skills} />
                  </>
                )}
                {tabValue === 1 && (
                  <>
                    <Experience
                      experience={candidateDetails?.candidate_experience}
                    />
                    <Boa
                      boardAdvisorExperience={
                        candidateDetails?.board_advisor_experience
                      }
                    />
                  </>
                )}
                {tabValue === 2 && (
                  <>
                    <Education
                      education={candidateDetails?.candidate_education}
                    />
                    <Certification
                      certification={candidateDetails?.certifications}
                    />
                  </>
                )}

                {tabValue === 3 && (
                  <CoffeeQuestion
                    coffeQuestion={candidateDetails?.coffee_questions}
                  />
                )}
                {tabValue === 4 && (
                  <CandidateVideos
                    candidateVideos={candidateDetails?.insight_videos}
                  />
                )}

                {tabValue === 5 && (
                  <Resume
                    firstName={candidateDetails?.first_name}
                    lastName={candidateDetails?.last_name}
                    resume={candidateDetails.resume}
                  />
                )}

                {tabValue === 6 && (
                  <Grid container>
                    <Grid item sm={12} xs={12} md={12} lg={12} xl={12}>
                      <AdminNotes notesValue={candidateDetails} isFullProfile />
                    </Grid>
                  </Grid>
                )}
              </Grid>
            </Card>
          </main>
        </Grid>
      </Grid>
      {isPopupVisible && <ShareProfileCredential candidate_id={candidate_id} />}
    </div>
  );
}

export default SharedFullProfile;
