import React from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import {
  // Drawer,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Badge,
  Dialog,
  DialogContentText,
  Button,
  Typography,
  Collapse,
} from "@material-ui/core";
import { authActions, loading, setTitle } from "../../actions";
import { connect } from "react-redux";
import MuiDialogTitle from "@material-ui/core/DialogTitle";
import MuiDialogContent from "@material-ui/core/DialogContent";
import MuiDialogActions from "@material-ui/core/DialogActions";
import DashboardIcon from "@material-ui/icons/Dashboard";
import BusinessCenterIcon from "@material-ui/icons/BusinessCenter";
// import PersonIcon from '@material-ui/icons/Person';
// import AccountTreeIcon from '@material-ui/icons/AccountTree';
import ExpandLess from "@material-ui/icons/ExpandLess";
import ExpandMore from "@material-ui/icons/ExpandMore";
// import GroupIcon from '@material-ui/icons/Group';
import MessageIcon from "@material-ui/icons/Message";
import ExitToAppIcon from "@material-ui/icons/ExitToApp";
import AccountBoxIcon from "@material-ui/icons/AccountBox";
import IconButton from "@material-ui/core/IconButton";
// import MenuIcon from "@material-ui/icons/Menu";
import CloseIcon from "@material-ui/icons/Close";
// import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
// import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import { ReactComponent as Logo } from "../../assets/logo.svg";
// import { ReactComponent as LogoIcon } from '../../assets/logo_icon.svg';
import cardHeaderBg from "../../assets/lite_profile_bg.png";
import classNames from "classnames";
// import clsx from "clsx";
import { history } from "../../helpers";
import { Auth } from "aws-amplify";
import { StreamChat } from "stream-chat";
import { sharedActions } from "../../actions";
import { VERSION } from "../../constants/constants";
import secureLocalStorage from "react-secure-storage";

const drawerWidth = 300;
const drawerHeight = "100vh";

const styles = (theme) => ({
  // root: {
  //     flexGrow: 1,
  //     "&$selected": {
  //         color: "white"
  //     }
  // },
  root: { display: "flex" },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: "nowrap",
  },
  drawerOpen: {
    // [theme.breakpoints.between("md", "xl")]: {
    //   width: drawerWidth
    // },
    // width: theme.spacing(9) + 1,
    width: drawerWidth,
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
    backgroundColor: "black",
    color: "white",
    // overflowX: "hidden",
    overflow: "visible",
  },
  nested: {
    paddingLeft: theme.spacing(14),
    borderTop: "1px solid #303030",
  },
  drawerClose: {
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    // overflowX: "hidden",
    overflow: "visible",
    width: theme.spacing(7) + 1,
    [theme.breakpoints.up("sm")]: {
      width: theme.spacing(9) + 1,
    },
    backgroundColor: "black",
    color: "white",
  },
  toolbar: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
  },
  customToolbar: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-evenly",
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
  },
  pointer: {
    cursor: "pointer",
  },
  responsive: {
    marginLeft: "15%",
    maxWidth: "100%",
    height: "auto",
    width: "auto",
    alignSelf: "center !important",
    minWidth: "fit-content !important",
  },
  customResponsive: {
    maxWidth: "100%",
    height: "auto",
    width: "auto",
    alignSelf: "center !important",
    minWidth: "fit-content !important",
  },
  customDivider: {
    border: "none",
    height: "1px",
    margin: "0",
    flexShrink: "0",
    backgroundColor: "#FBC112 !important",
  },
  appFrame: {
    zIndex: 1,
    overflow: "hidden",
    position: "relative",
    display: "flex",
    width: "100%",
  },
  // appBar: {
  //     width: `calc(100% - ${drawerWidth}px)`,
  // },
  // 'appBar-left': {
  //      marginLeft: drawerWidth,
  // },
  // 'appBar-right': {
  //      marginRight: drawerWidth,
  // },
  drawerPaper: {
    position: "relative",
    width: drawerWidth,
    minHeight: drawerHeight,
    backgroundColor: "black",
    color: "white",
    borderRight: "none",
  },
  // toolbar: theme.mixins.toolbar,
  content: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.default,
    padding: theme.spacing(3),
  },
  topSmall: {
    marginTop: "10%",
  },
  bottomMedium: {
    marginBottom: "10%",
  },
  customTopSmall: {
    marginTop: "40%",
  },
  customBottomMedium: {
    marginBottom: "40%",
  },
  marginLeftSmall: {
    marginLeft: "8%",
  },
  sideBar: {
    paddingLeft: "15%",
  },
  customSideBar: {
    paddingLeft: "30%",
  },
  customFontSize: { fontSize: "1.15rem !important" },
  customBadge: {
    borderRadius: "10px",
    fontSize: "x-small",
    fontWeight: "bold",
  },
  // customNav: {bottom: '20px', position: 'fixed', width: 'inherit'},
  customDialogHeader: {
    maxHeight: "38%",
    height: "38%",
    color: "#000",
    backgroundSize: "cover",
    backgroundImage: `url(${cardHeaderBg})`,
    background: "round",
  },
  largeFont: {
    fontSize: "large",
  },
  closeButton: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.default.main,
    padding: "0px",
  },
  // navCustomContainer: {
  //   display: 'flex',
  //   justifyContent: 'space-between',
  //   // width: 'inherit',
  //   /* height: calc(100% - 125px); */
  //   overflow: 'auto',
  //   height: 'calc(100% - 90px)',
  //   flexDirection: 'column',
  //   '&::-webkit-scrollbar': {
  //     display: 'none'
  //   },
  //   [theme.breakpoints.down('md')]: {
  //     height: 'calc(100% - 80px)',
  //   },
  //   [theme.breakpoints.down('sm')]: {
  //     height: 'calc(100% - 80px)',
  //   }
  // }
  navCustomContainer: {
    display: "flex",
    justifyContent: "space-between",
    // width: 'inherit',
    // /* height: calc(100% - 125px); */
    overflow: "auto",
    height: "calc(100% - 10px)", //'calc(100% - 90px)',
    flexDirection: "column",
    "&::-webkit-scrollbar": {
      display: "none",
    },
    [theme.breakpoints.down("md")]: {
      height: "calc(100% - 10px)",
    },
    [theme.breakpoints.down("sm")]: {
      height: "calc(100% - 10px)",
    },
  },
});

const DialogTitle = withStyles(styles)((props) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle
      disableTypography
      {...other}
      className={classes.customDialogHeader}
      style={{ padding: "3%" }}
    >
      {/* className={classes.root} */}
      <Typography variant="h5" align="center">
        {children}
      </Typography>
      {onClose ? (
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={onClose}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const DialogContent = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
    paddingLeft: theme.spacing(4),
    paddingRight: theme.spacing(4),
  },
}))(MuiDialogContent);

const DialogActions = withStyles((theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
    backgroundColor: "#fff",
    justifyContent: "center !important",
  },
}))(MuiDialogActions);

class Navigation extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      anchor: "left",
      selectedIndex: this.props.shared?.selectedNavbarIndex || 0,
      openConfirmSigOutPopup: false,
      open: false,
      isDefaultSubMenu: true,
      errors: {
        cognito: null,
      },
    };
  }

  updateSelectedIndexByUrlState = (path) => {
    if (path === "/dashboard") {
      this.setState({ selectedIndex: 0 });
    } else if (path.match("^/jobs/my-jobs.*$") || path === "/jobs/my-jobs") {
      this.setState({ selectedIndex: 1 });
    } else if (
      path.match("^/jobs/shared-jobs.*$") ||
      path === "/jobs/shared-jobs"
    ) {
      this.setState({ selectedIndex: 11 });
    } else if (path.match("^/members.*$")) {
      this.setState({ selectedIndex: this.props.shared?.selectedNavbarIndex }); //this.setState({ selectedIndex: 2 });
    } else if (path.match("^/messages.*$") || path === "/messages") {
      this.setState({ selectedIndex: 3 });
    } else if (path.match("^/my-account.*$")) {
      this.setState({ selectedIndex: 4 });
    }
    const { dispatch } = this.props;
    dispatch(sharedActions.setSelectedNavbarIndex(this.state.selectedIndex));
  };

  componentDidMount() {
    history.listen((location) => {
      this.updateSelectedIndexByUrlState(location.pathname);
    });

    if (window.location.pathname) {
      this.updateSelectedIndexByUrlState(window.location.pathname);
    }
    // const { dispatch } = this.props;
    // if (this.props?.shared?.menuToggle === undefined) {
    //  dispatch(sharedActions.navBarMenuToggle(true));
    // }
  }

  confirmSignout = () => {
    this.setState({ openConfirmSigOutPopup: true });
  };

  handleClose = () => {
    this.setState({ openConfirmSigOutPopup: false });
  };

  signout = async (event) => {
    event.preventDefault();
    const { dispatch } = this.props;
    dispatch(loading(true));
    try {
      await Auth.signOut();
      dispatch(authActions.signout());
      const { getStreamUserTokenData } = this.props.messages;
      if (getStreamUserTokenData) {
        const client = StreamChat.getInstance(
          getStreamUserTokenData.data.apiKey,
          { timeout: 6000 }
        );
        client.disconnectUser();
      }
      dispatch(loading(false));
      this.handleClose();
    } catch (error) {
      let err = null;
      !error.message ? (err = { message: error }) : (err = error);
      console.log("error signing out:", err);
      this.setState({
        errors: {
          ...this.state.errors,
          cognito: error,
        },
      });
      dispatch(loading(false));
    }
  };

  goToHome = () => {
    history.push("/");
    this.setState({ selectedIndex: 0 });
  };

  handleTabsAndRedirection = (tabValue, pageValue, dispatch) =>
    new Promise((resolve, reject) => {
      dispatch(sharedActions.jobsPageTabChange(tabValue));
      dispatch(sharedActions.jobsPagePaginationPageChange(pageValue));
      dispatch(sharedActions.closedJobsPage(pageValue));
      resolve();
      reject();
    });

  handleListItemClick = (index) => {
    const { dispatch } = this.props;
    switch (index) {
      case 0:
        history.push("/dashboard");
        break;
      case 1:
        dispatch(setTitle(`Jobs`));
        dispatch(
          sharedActions.navBarMenuToggle(!this.props?.shared?.menuToggle)
        );
        break;
      case 2:
        history.push("/members");
        break;
      case 3:
        history.push("/messages");
        break;
      case 4:
        history.push("/my-account");
        break;
      case 10:
        this.setState({ isDefaultSubMenu: true });
        if (window.location.pathname !== "/jobs/my-jobs") {
          this.handleTabsAndRedirection(0, 1, dispatch).then(() => {
            history.push("/jobs/my-jobs");
          });
        }
        break;
      case 11:
        this.setState({ isDefaultSubMenu: false });
        if (window.location.pathname !== "/jobs/shared-jobs") {
          this.handleTabsAndRedirection(0, 1, dispatch).then(() => {
            history.push("/jobs/shared-jobs");
          });
        }
        break;
      default:
        break;
    }
  };

  handleClick = () => {
    this.setState((prevState) => ({ open: !prevState.open }));
  };

  handleSubMenuClick = (flag) => {
    const { dispatch } = this.props;
    dispatch(sharedActions.navBarMenuToggle(flag));
  };

  render() {
    const { classes } = this.props;

    return (
      <>
        <div style={{ height: "100%" }}>
          <div container className={classes.navCustomContainer}>
            <List component="nav" style={{ marginBottom: "20px" }}>
              <Logo
                width="200"
                height="36"
                className={classNames(
                  classes.bottomMedium,
                  classes.topSmall,
                  classes.responsive,
                  classes.pointer
                )}
                onClick={this.goToHome}
              />
              <ListItem
                button
                component="a"
                selected={this.state.selectedIndex === 0}
                onClick={(event) => {
                  event.preventDefault();
                  this.handleListItemClick(0);
                }}
                className={classes.sideBar}
              >
                <ListItemIcon>
                  <DashboardIcon />
                </ListItemIcon>
                <ListItemText
                  className={classes.customFontSize}
                  primary="Dashboard"
                />
              </ListItem>
              <ListItem
                button
                component="a"
                className={classes.sideBar}
                selected={
                  this.state.selectedIndex === 1 ||
                  this.state.selectedIndex === 11
                }
                onClick={(event) => {
                  event.preventDefault();
                  this.handleListItemClick(1);
                }}
              >
                <ListItemIcon>
                  <BusinessCenterIcon />
                </ListItemIcon>
                <ListItemText
                  className={classes.customFontSize}
                  primary="Jobs"
                />
                {this.props?.shared?.menuToggle ? (
                  <ExpandLess style={{ color: "unset" }} />
                ) : (
                  <ExpandMore style={{ color: "unset" }} />
                )}
              </ListItem>
              <Collapse
                in={this.props?.shared?.menuToggle}
                timeout="auto"
                unmountOnExit
              >
                <List component="div" disablePadding>
                  <ListItem
                    button
                    className={classes.nested}
                    selected={this.state.selectedIndex === 1}
                    onClick={(event) => {
                      event.preventDefault();
                      this.handleListItemClick(10);
                    }}
                  >
                    {/* <ListItemIcon>
                      <PersonIcon />
                    </ListItemIcon> */}
                    <ListItemText primary="My Jobs" />
                  </ListItem>
                  <ListItem
                    button
                    className={classes.nested}
                    selected={this.state.selectedIndex === 11}
                    onClick={(event) => {
                      event.preventDefault();
                      this.handleListItemClick(11);
                    }}
                  >
                    {/* <ListItemIcon>
                      <AccountTreeIcon />
                    </ListItemIcon> */}
                    <ListItemText primary="Shared Jobs" />
                  </ListItem>
                </List>
              </Collapse>
              {/* <ListItem button component='a' className={classes.sideBar} selected={this.state.selectedIndex === 2}
                onClick={event => {event.preventDefault(); this.handleListItemClick(2)}}>
                  <ListItemIcon>
                      <GroupIcon />
                  </ListItemIcon>
                  <ListItemText className={classes.customFontSize} primary="Browse Members"/>
              </ListItem> */}
              {!secureLocalStorage.getItem("g_u") && (
                <ListItem
                  button
                  component="a"
                  className={classes.sideBar}
                  selected={this.state.selectedIndex === 3}
                  onClick={() => this.handleListItemClick(3)}
                >
                  <ListItemIcon>
                    <MessageIcon />
                  </ListItemIcon>
                  <ListItemText
                    className={classes.customFontSize}
                    primary="Messages"
                  />
                </ListItem>
              )}
            </List>
            <div>
              <List component="nav" className={classes.customNav}>
                <ListItem
                  button
                  component="a"
                  className={classes.sideBar}
                  selected={this.state.selectedIndex === 4}
                  onClick={(event) => {
                    event.preventDefault();
                    this.handleListItemClick(4);
                  }}
                >
                  <ListItemIcon>
                    <AccountBoxIcon />
                  </ListItemIcon>
                  {secureLocalStorage.getItem("recruiterStatusDetails") &&
                  JSON.parse(
                    secureLocalStorage.getItem("recruiterStatusDetails")
                  ).is_owner ? (
                    <Badge
                      id="ownerBadge"
                      anchorOrigin={{ vertical: "top", horizontal: "right" }}
                      color="primary"
                      badgeContent={"COMPANY ADMIN"}
                      style={{ padding: "0 4px" }}
                    >
                      <ListItemText
                        className={classes.customFontSize}
                        primary="My Account"
                      />
                    </Badge>
                  ) : (
                    <ListItemText
                      className={classes.customFontSize}
                      primary="My Account"
                    />
                  )}
                </ListItem>
                <ListItem
                  button
                  onClick={() => {
                    this.confirmSignout();
                  }}
                  className={classes.sideBar}
                >
                  <ListItemIcon>
                    <ExitToAppIcon />
                  </ListItemIcon>
                  <ListItemText
                    className={classes.customFontSize}
                    primary="Sign Out"
                  />
                </ListItem>
              </List>
              <Typography
                color="secondary"
                align="right"
                style={{ padding: "15px 15px 0px 0px" }}
              >
                {VERSION}
              </Typography>
            </div>
          </div>
        </div>

        <Dialog
          open={this.state.openConfirmSigOutPopup}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
          fullWidth
          disableBackdropClick
          disableEscapeKeyDown
        >
          <DialogTitle id="alert-dialog-title" onClose={this.handleClose}>
            Confirm Sign Out
          </DialogTitle>
          <DialogContent>
            <DialogContentText
              id="alert-dialog-description"
              className={classNames(classes.largeFont)}
            >
              <Typography align="center">
                Are you sure you want to sign out?
              </Typography>
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button
              size="large"
              disableElevation
              onClick={this.handleClose}
              style={{ background: "#D7D9DB", color: "#000" }}
              variant="contained"
              color="primary"
            >
              Cancel
            </Button>
            <Button
              size="large"
              disableElevation
              onClick={this.signout}
              variant="contained"
              color="primary"
              autoFocus
            >
              Confirm
            </Button>
          </DialogActions>
        </Dialog>
      </>
    );
  }
}

Navigation.propTypes = {
  classes: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => {
  return state;
};
export default connect(mapStateToProps)(
  withStyles(styles, { withTheme: true })(Navigation)
);
