import React from "react";
import { FormLabel, Typography, Grid, FormHelperText } from "@material-ui/core";
import { toast } from "react-toastify";
import { Editor } from "react-draft-wysiwyg";
import { EditorState, Modifier } from "draft-js";
import { makeStyles } from "@material-ui/core/styles";
import classNames from "classnames";
import "./editer.css";
import { MESSAGE_FIELD_IS_REQUIRED } from "../../constants/constants";

function CustomEditer({
  editerState,
  required,
  onEditerChange,
  handleInputChange,
  maxLength,
  label,
  placeholder,
  labelAlign,
  isRequired,
  isCharValidate,
}) {
  const useStyles = makeStyles(() => ({
    subHeader: {
      fontSize: "medium",
      marginTop: "4%",
      fontWeight: "600",
      marginBottom: "1%",
      color: "#000000",
    },
    verySmallBottomMargin: {
      marginBottom: "1em",
    },
    mediumTopMargin: {
      marginTop: "4%",
    },
    floatRight: {
      float: "right",
    },
    labelAlign: {
      textAlign: labelAlign,
    },
    "&.rdw-text-align-dropdown": {
      zIndex: "1 !important",
    },
  }));

  const classes = useStyles();

  return (
    <>
      <div
        className={classNames(
          classes.mediumTopMargin,
          classes.verySmallBottomMargin,
          classes.labelAlign
        )}
        style={{ zIndex: 1 }}
      >
        <FormLabel className={classes.subHeader}>
          {label} {required ? <span style={{ color: "#FF0000" }}>*</span> : ""}
        </FormLabel>
      </div>
      <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
        <Editor
          placeholder={placeholder}
          editorState={editerState}
          onEditorStateChange={onEditerChange}
          toolbar={{
            options: [
              "inline",
              "blockType",
              "fontSize",
              "fontFamily",
              "colorPicker",
              "list",
              "textAlign",
              "link",
              "remove",
              "history",
            ],
            inline: {
              options: ["bold", "italic", "underline", "strikethrough"],
              inDropdown: true,
            },
            list: {
              options: ["unordered", "ordered"],
              inDropdown: true,
            },
            textAlign: { inDropdown: true },
            link: { inDropdown: true, defaultTargetOption: "_blank" },
            history: { inDropdown: true },
          }}
          onChange={handleInputChange}
          handleBeforeInput={(val) => {
            const textLength = editerState
              ?.getCurrentContent()
              ?.getPlainText().length;
            if (val && textLength >= maxLength) {
              toast.dark(
                `Allowed character limit is ${maxLength} characters only`
              );
              return "handled";
            }
            return "not-handled";
          }}
          handlePastedText={(input) => {
            const inputLength = editerState
              .getCurrentContent()
              .getPlainText().length;
            const remainingLength = maxLength - inputLength;
            if (input.length + inputLength >= maxLength) {
              const newContent = Modifier.insertText(
                editerState.getCurrentContent(),
                editerState.getSelection(),
                input.slice(0, remainingLength)
              );
              onEditerChange(
                EditorState.push(editerState, newContent, "insert-characters")
              );
              toast.dark(
                `Allowed character limit is ${maxLength} characters only`
              );
              return true;
            }
            return false;
          }}
        />
      </Grid>
      {isRequired ? (
        <FormHelperText error>{MESSAGE_FIELD_IS_REQUIRED}</FormHelperText>
      ) : isCharValidate ? (
        <FormHelperText error={isCharValidate}>
          Min 25 charachers required
        </FormHelperText>
      ) : (
        ""
      )}
      {required ? (
        <Typography
          className={classNames(classes.floatRight, classes.verySmallTopMargin)}
          color="secondary"
        >
          {`${
            editerState?.getCurrentContent()?.getPlainText()?.trimStart()
              ?.length
          }/${maxLength}`}{" "}
          characters
        </Typography>
      ) : (
        <Typography
          className={classNames(classes.floatRight, classes.verySmallTopMargin)}
          color="secondary"
        >
          {`${
            editerState?.getCurrentContent()?.getPlainText()?.length
          }/${maxLength}`}{" "}
          characters
        </Typography>
      )}
    </>
  );
}

export default CustomEditer;
