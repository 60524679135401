import React, { Component, Fragment } from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import {
  Grid,
  Button,
  Typography,
  Dialog,
  DialogContentText,
  TextField,
  InputLabel,
  Select,
  MenuItem,
  FormControl,
  Radio,
  RadioGroup,
  FormControlLabel,
  Chip,
  Card,
  CardContent,
  Avatar,
  Breadcrumbs,
  Link,
  Divider,
  Menu,
  ListItemIcon,
  ListItemText,
  FormHelperText,
  FormLabel,
  Tooltip,
  Popper,
  Fade,
  Slider,
  styled,
  Popover,
  Box,
  Checkbox,
  IconButton,
  Paper,
  CircularProgress,
} from "@material-ui/core";
import cardHeaderBg from "../../../assets/lite_profile_bg.png";
import MuiAccordion from "@material-ui/core/Accordion";
import MuiAccordionSummary from "@material-ui/core/AccordionSummary";
import MuiAccordionDetails from "@material-ui/core/AccordionDetails";
import MuiDialogTitle from "@material-ui/core/DialogTitle";
import MuiDialogContent from "@material-ui/core/DialogContent";
import MuiDialogActions from "@material-ui/core/DialogActions";
import Autocomplete from "@material-ui/lab/Autocomplete";
import CloseIcon from "@material-ui/icons/Close";
import GroupAddIcon from "@material-ui/icons/GroupAdd";
import VisibilityIcon from "@material-ui/icons/Visibility";
import AddBoxIcon from "@material-ui/icons/AddBox";
import IndeterminateCheckBoxIcon from "@material-ui/icons/IndeterminateCheckBox";
import EditIcon from "@material-ui/icons/Edit";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import DeleteIcon from "@material-ui/icons/Delete";
import LocationOnIcon from "@material-ui/icons/LocationOn";
import PopupState, {
  bindTrigger,
  bindMenu,
  bindPopover,
} from "material-ui-popup-state";
import { history } from "../../../helpers";
import validator from "validator";
import classNames from "classnames";
import { jobsActions } from "../../../actions/jobs.actions";
import { membersActions } from "../../../actions/members.actions";
import { loading, sharedActions } from "../../../actions";
import moment from "moment";
import { ViewMore } from "../../core/viewmore";
import { toast } from "react-toastify";
import { GoogleMaps } from "../../core/googleautocomplete";
import { messagesActions } from "./../../../actions/messages.actions";
import { MESSAGE_FIELD_IS_REQUIRED } from "../../../constants/constants";
import GetHelp from "../../core/gethelp";
import {
  notAllowWhiteSpaceAtStart,
  trim,
  seo,
  getBrowserVisibilityProp,
  getIsDocumentHidden,
} from "../../../helpers";
import { setTitle } from "../../../actions";
import { EditorState, convertToRaw, ContentState, Modifier } from "draft-js";
import { Editor } from "react-draft-wysiwyg";
import draftToHtml from "draftjs-to-html";
import htmlToDraft from "html-to-draftjs";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import HelpIcon from "@material-ui/icons//Help";
import debounce from "lodash/debounce";
import { isEqual } from "lodash";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
// import SchedulingPopup from './scheduling.popup.component';
import PreviewEmaildailog from "../../core/previewEmail.dailog";
import ShareProfile from "../../core/share.profile";
import AllUpdates from "../../customComponents/allUpdate";
import CompareViewJobs from "./compareViewJobs";
import ListViewJobs from "./listViewJobs";
import { setAllUpdateHeaderCard } from "../../../actions/jobs.actions";
import secureLocalStorage from "react-secure-storage";

const styles = (theme) => ({
  root: {
    width: "100%",
    marginTop: "61px",
  },
  customRoot: {
    width: "100%",
    marginTop: "0px",
  },
  moreFont: {
    fontWeight: "bold",
  },
  borderBottom: { borderBottom: "1px solid #E5E5E5" },
  underline: {
    textDecoration: "underline",
  },
  cursorDefault: {
    cursor: "default",
  },
  scrollOnHover: {
    // maxHeight: '400px',
    // height: '400px',
    overflow: "hidden",
    "&:hover": {
      overflowY: "auto",
    },
  },
  noDataSmall: {
    textAlign: "center",
    color: theme.palette.text.secondary,
  },
  customCollaboratorChip: {
    margin: "5px",
    [theme.breakpoints.between("xs", "md")]: {
      height: "auto",
    },
  },
  customPopper: {
    padding: theme.spacing(2),
    [theme.breakpoints.between("xs", "sm")]: {
      padding: theme.spacing(1),
      width: "min-content !important",
    },
  },
  customBg: { backgroundColor: "#EDEDED" },
  customSection: {
    boxShadow:
      "0px 1px 1px 0px rgb(0 0 0 / 0%), 0px 1px 5px 0px rgb(0 0 0 / 20%), 0px 0px 1px 1px rgb(0 0 0 / 12%)",
    borderRadius: "10px",
    padding: "3%",
  },
  customBottomMargin: { marginBottom: "2%" },
  widthFitContent: { width: "fit-content !important" },
  arrow: {
    position: "absolute",
    fontSize: "7px",
    width: "3em",
    height: "3em",

    "&:before": {
      content: '""',
      margin: "auto",
      display: "block",
      width: 0,
      height: 0,
      borderStyle: "solid",
    },
  },
  paper: {
    border: "1px solid",
    padding: theme.spacing(1),
    backgroundColor: theme.palette.background.paper,
  },
  heading: {
    fontSize: theme.typography.pxToRem(14),
    flexBasis: "33.33%",
    flexShrink: 0,
  },
  secondaryHeading: {
    fontSize: theme.typography.pxToRem(14),
    color: theme.palette.text.primary,
  },
  actionButtons: {
    position: "absolute",
    bottom: "-20px",
    right: "20px",
    display: "flex",
    justifyContent: "space-around",
    width: "200px",
    alignItems: "center",
    [theme.breakpoints.between("xs", "md")]: {
      marginTop: theme.spacing(7),
    },
    zIndex: "10",
  },
  customActionButtons: {
    position: "absolute",
    bottom: "-20px",
    right: "20px",
    display: "flex",
    justifyContent: "space-around",
    width: "200px",
    alignItems: "center",
    [theme.breakpoints.between("xs", "md")]: {
      marginTop: theme.spacing(7),
    },
    zIndex: "10",
  },
  customActionButtonsPending: {
    position: "absolute",
    bottom: "-20px",
    right: "20px",
    display: "flex",
    justifyContent: "end",
    width: "200px",
    alignItems: "center",
    [theme.breakpoints.between("xs", "md")]: {
      marginTop: theme.spacing(7),
    },
    zIndex: "10",
  },
  customPaddingInlineStart: { paddingInlineStart: "24px !important" },
  whiteBg: { backgroundColor: "#FFFFFF !important" },
  closeButton: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.default.main,
    padding: "0px",
  },
  // chatIcon: {
  //     // backgroundColor: '#ffffff',
  //     // color: '#303030',
  //     '&:hover': {
  //         //  backgroundColor: '#000000 !important',
  //         //  color: '#ffffff !important'
  //         // border: '1px solid lightgrey !important'
  // },
  // },
  appFrame: {
    zIndex: 1,
    overflow: "hidden",
    position: "relative",
    // display: 'flex',
    // width: '100%',
    padding: theme.spacing(2, 6),
    backgroundColor: "#FEF5DB",
    paddingBottom: "5px",
    [theme.breakpoints.down("md")]: {
      padding: theme.spacing(3, 2),
    },
    [theme.breakpoints.down("sm")]: {
      padding: theme.spacing(3, 2),
    },
  },
  content: {
    flexGrow: 1,
    marginTop: "0.2%",
    padding: theme.spacing(0, 6),
    [theme.breakpoints.down("md")]: {
      padding: theme.spacing(0, 2),
    },
    [theme.breakpoints.down("sm")]: {
      padding: theme.spacing(0, 2),
    },
  },
  bullet: {
    display: "inline-block",
    margin: "0 2px",
    transform: "scale(0.8)",
  },
  title: {
    fontSize: 14,
  },
  pos: {
    marginBottom: 12,
  },
  largeAvatar: {
    width: theme.spacing(7),
    height: theme.spacing(7),
  },
  xLargeAvatar: {
    width: theme.spacing(10),
    height: theme.spacing(10),
    [theme.breakpoints.between("xs", "lg")]: {
      width: theme.spacing(7),
      height: theme.spacing(7),
    },
    backgroundColor: "#000",
  },
  xLargeCompanyLogoAvatar: {
    width: theme.spacing(12),
    height: theme.spacing(12),
  },
  moduleConfig: {
    float: "left",
    fontSize: "26px",
  },
  demo1: {
    marginTop: "5%",
  },
  floatRight: {
    float: "right",
  },
  floatLeft: {
    float: "left",
  },
  largeTopMargin: {
    marginTop: "10%",
  },
  verySmallTopMargin: {
    marginTop: "1%",
  },
  formControl: {
    margin: theme.spacing(2, 0),
    minWidth: "100%",
  },
  customFormControl: {
    marginBottom: "1.7%", //theme.spacing(1.3, 0),
    minWidth: "100%",
  },
  customTopBottomMargin: {
    marginTop: "1.8%",
    marginBottom: "1.7%",
    minWidth: "100%",
  },
  mediumTopMargin: {
    marginTop: "4%",
  },
  alignSelfCenter: {
    alignSelf: "center",
  },
  displayFlex: {
    display: "flex",
  },
  customDisplayFlex: {
    display: "flex",
    [theme.breakpoints.between("xs", "sm")]: {
      display: "block",
    },
  },
  noPadding: {
    padding: "0 !important",
    [theme.breakpoints.between("xs", "lg")]: {
      padding: "8px !important",
    },
  },
  customNoPadding: { padding: "4px !important" },
  noMargin: {
    margin: "0 !important",
  },
  fullWidth: {
    width: "100%",
  },
  colorDefault: {
    color: "#000000 !important",
  },
  colorCustom: {
    color: "#303030",
  },
  dialogTitle: {
    color: "#FBC112",
  },
  displayInline: {
    display: "inline",
  },
  noBottomMargin: {
    marginBottom: "0",
  },
  smallTopMargin: {
    marginTop: "2%",
  },
  customSmallTopMargin: {
    marginTop: "2%",
  },
  chip: {
    margin: theme.spacing(0.5),
  },
  input: {
    marginLeft: theme.spacing(1),
    flex: 1,
  },
  iconButton: {
    padding: 10,
  },
  noWrap: {
    whiteSpace: "nowrap",
  },
  subHeader: {
    fontSize: "large",
    marginTop: "4%",
    fontWeight: "bold",
    color: "#000000",
  },
  mediumHeader: {
    fontSize: "medium",
    marginTop: "2%",
    fontWeight: "bold",
    color: "#000000",
  },
  header: {
    fontSize: "x-large",
    marginTop: "4%",
    fontWeight: "bold",
    color: "#red",
  },
  jobCardHeader: {
    fontSize: "x-large",
    fontWeight: "500",
    color: "#000000",
    textAlign: "left",
  },
  jobCardSubHeader: {
    fontSize: "large",
    fontWeight: "500",
    textAlign: "left",
  },
  customCard: {
    backgroundColor: "#F7F7F7",
    borderRadius: "10px", // theme.palette.basic.main
  },
  customCardForComments: {
    backgroundColor: "#F7F7F7",
    borderRadius: "10px",
    padding: "20px",
  },
  customCardShadow: {
    boxShadow: "none",
  },
  textAlignEnd: {
    textAlign: "end",
  },
  textAlignCenter: {
    textAlign: "center",
  },
  jobCard: {
    boxShadow:
      "0px 1px 1px 0px rgb(0 0 0 / 20%), 0px 1px 0px 0px rgb(0 0 0 / 14%), 0px 0px 2px 1px rgb(0 0 0 / 12%)",
    cursor: "pointer",
  },
  smallBottomMargin: {
    marginBottom: "2%",
  },
  mediumBottomMargin: {
    marginBottom: "5%",
  },
  customBadge: {
    marginLeft: "8px",
    borderRadius: "3px",
    width: "5em",
    fontSize: "smaller",
    height: "24px",
    backgroundColor: "#616161",
    color: "#FFFFFF",
  },
  smallFont: {
    fontSize: "14px",
  },
  largeFont: {
    fontSize: "large",
  },
  customBadgeGrid: {
    textAlign: "center",
    overflowWrap: "break-word",
    padding: "2%",
  },
  customLink: {
    cursor: "pointer",
    "&:hover": {
      textDecoration: "underline",
    },
    color: "#048475 !important",
  },
  breadcrumbLink: {
    cursor: "pointer",
    "&:hover": {
      textDecoration: "underline",
    },
    // color: '#048475 !important'
  },
  moreIcon: {
    justifyContent: "space-between",
    cursor: "pointer",
    overflowWrap: "anywhere",
  },
  customMoreIcon: {
    fontSize: "2.5rem",
    // '&:hover':{
    //     transform: 'rotate(90deg)',
    //     transition: 'all .3s ease'
    // }
  },
  customDialog: {
    backgroundColor: "#000000",
    width: "unset",
  },
  justifyContentFlexEnd: {
    justifyContent: "flex-end",
  },
  justifyContentCenter: {
    justifyContent: "center !important",
  },
  responsive: {
    maxWidth: "100% !important",
    height: "auto !important",
    width: "auto !important",
    // alignSelf: 'center !important',
    minWidth: "fit-content !important",
  },
  liteProfileCard: {
    // boxShadow: '0px 1px 1px 0px rgb(0 0 0 / 0%), 0px 1px 0px 0px rgb(0 0 0 / 5%), 0px 0px 2px 1px rgb(0 0 0 / 12%)',
    boxShadow:
      "0px 0px 0px 1px rgb(0 0 0 / 5%), 0px 0px 0px 0px rgb(0 0 0 / 5%), 0px 0px 0px 1px rgb(0 0 0 / 5%)",
    // borderBottom: '10px solid #FBC112',
    maxHeight: "55em",
    height: "55em",
    // borderRadius: '8px'
    borderRadius: "5px",
    // backgroundColor: '#F7F7F7',
    // baclgroundImage: 'url(src/assets/lite_profile_bg.png)'
    // cursor: 'pointer'
  },
  expandedLiteProfileCard: {
    // boxShadow: '0px 1px 1px 0px rgb(0 0 0 / 0%), 0px 1px 0px 0px rgb(0 0 0 / 5%), 0px 0px 2px 1px rgb(0 0 0 / 12%)',
    boxShadow:
      "0px 0px 0px 1px rgb(0 0 0 / 5%), 0px 0px 0px 0px rgb(0 0 0 / 5%), 0px 0px 0px 1px rgb(0 0 0 / 5%)",
    // borderBottom: '10px solid #FBC112',
    // maxHeight: '55em',
    // height: '55em',
    // borderRadius: '8px'
    // borderRadius: '5px'
    // backgroundColor: '#F7F7F7',
    // baclgroundImage: 'url(src/assets/lite_profile_bg.png)'
    // cursor: 'pointer'
  },
  liteProfileCardForMatched: {
    // boxShadow: '0px 1px 1px 0px rgb(0 0 0 / 0%), 0px 1px 0px 0px rgb(0 0 0 / 5%), 0px 0px 2px 1px rgb(0 0 0 / 12%)',
    boxShadow:
      "0px 0px 0px 1px rgb(0 0 0 / 5%), 0px 0px 0px 0px rgb(0 0 0 / 5%), 0px 0px 0px 1px rgb(0 0 0 / 5%)",
    //  borderBottom: '10px solid #FBC112',
    maxHeight: "63em",
    height: "63em",
    // borderRadius: '8px'
    borderRadius: "5px",
    // backgroundColor: '#F7F7F7',
    // baclgroundImage: 'url(src/assets/lite_profile_bg.png)'
    // cursor: 'pointer'
  },
  expandedLiteProfileCardForMatched: {
    // boxShadow: '0px 1px 1px 0px rgb(0 0 0 / 0%), 0px 1px 0px 0px rgb(0 0 0 / 5%), 0px 0px 2px 1px rgb(0 0 0 / 12%)',
    boxShadow:
      "0px 0px 0px 1px rgb(0 0 0 / 5%), 0px 0px 0px 0px rgb(0 0 0 / 5%), 0px 0px 0px 1px rgb(0 0 0 / 5%)",
    //  borderBottom: '10px solid #FBC112',
    // maxHeight: '63em',
    // height: '63em',
    // borderRadius: '8px'
    // borderRadius: '5px'
    // backgroundColor: '#F7F7F7',
    // baclgroundImage: 'url(src/assets/lite_profile_bg.png)'
    // cursor: 'pointer'
  },
  maxHeight40P: { maxHeight: "40px" },
  customCardHeader: {
    maxHeight: "370px",
    height: "370px",
    color: "#000",
    backgroundSize: "cover",
    backgroundImage: `url(${cardHeaderBg})`,
    background: "round",
    position: "relative",
    textAlign: "left",
    display: "flex",
    alignItems: "center",
    padding: "16px",
  }, //height: '180px'
  expandedCustomCardHeader: {
    color: "#000",
    backgroundSize: "cover",
    backgroundImage: `url(${cardHeaderBg})`,
    background: "round",
    position: "relative",
    textAlign: "left",
    display: "flex",
    alignItems: "center",
    padding: "24px",
  }, //height: '180px'
  customDiversityCardHeader: {
    maxHeight: "370px",
    height: "370px",
    color: "#000",
    backgroundSize: "cover",
    backgroundImage: `url(${cardHeaderBg})`,
    background: "round",
    position: "relative",
    textAlign: "left",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    padding: "16px",
  }, //height: '180px'
  expandedCustomDiversityCardHeader: {
    color: "#000",
    backgroundSize: "cover",
    backgroundImage: `url(${cardHeaderBg})`,
    background: "round",
    position: "relative",
    textAlign: "left",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    padding: "24px",
  }, //height: '180px'
  // matchedCustomCardHeader: { maxHeight: '40%', height: '40%', color: '#000', backgroundSize: 'cover', backgroundImage: `url(${cardHeaderBg})`, background: 'round' },
  matchedCustomCardHeader: {
    maxHeight: "370px",
    height: "370px",
    [theme.breakpoints.between("md", "lg")]: {
      maxHeight: "450px",
      height: "450px",
      padding: "8px",
    },
    [theme.breakpoints.between("xs", "sm")]: {
      maxHeight: "450px",
      height: "450px",
      padding: "8px",
    },
    color: "#000",
    backgroundSize: "cover",
    backgroundImage: `url(${cardHeaderBg})`,
    background: "round",
    position: "relative",
    padding: "16px",
  },
  expandedMatchedCustomCardHeader: {
    padding: "24px",
    [theme.breakpoints.between("md", "lg")]: {
      maxHeight: "370px",
      height: "370px",
      padding: "4px",
    },
    [theme.breakpoints.between("xs", "sm")]: {
      maxHeight: "auto",
      height: "auto",
      padding: "4px",
      minHeight: "370px",
    },
    color: "#000",
    backgroundSize: "cover",
    backgroundImage: `url(${cardHeaderBg})`,
    background: "round",
    position: "relative",
  },
  customCardActions: { display: "block" },
  textAlignLeft: { textAlign: "left" },
  textAlignRight: { textAlign: "right" },
  verySmallFont: { fontSize: ".8rem" },
  font10p: { fontSize: "10px" },
  font12p: { fontSize: "12px" },
  font14p: { fontSize: "14px" },
  font16p: { fontSize: "16px" },
  alignBaslineMiddle: { verticalAlign: "-webkit-baseline-middle" },
  verySmallMarginLeft: { marginLeft: "3px" },
  smallMarginLeft: { marginLeft: "8px" },
  smallMarginRight: { marginRight: "8px" },
  mediumMarginLeft: { marginLeft: "12px" },
  boldFont: { fontWeight: "bold" },
  customPaddingForCardContent: {
    paddingLeft: "24px",
    paddingRight: "24px",
    overflowWrap: "break-word",
    textAlign: "justify",
  },
  customPaddingForCardContentBoard: {
    paddingLeft: "24px",
    paddingRight: "24px",
    overflowWrap: "break-word",
    textAlign: "left",
  },
  liteProfileHighlights: {
    backgroundColor: "#F7F7F7",
    padding: "10px",
    height: "100px",
    maxHeight: "14%",
  },
  liteProfileCardContent: {
    backgroundColor: "#FFFFFF",
    paddingTop: 0,
    paddingBottom: 0,
    paddingLeft: "0px",
    paddingRight: "0px",
    height: "29.5em",
    maxHeight: "29.5em",
    "&:hover": { overflowY: "auto" },
    overflowY: "hidden",
    overflowWrap: "break-word",
  },
  expandedLiteProfileCardContent: {
    backgroundColor: "#FFFFFF",
    paddingTop: 0,
    paddingBottom: 0,
    paddingLeft: "0px",
    paddingRight: "0px",
    "&:hover": { overflowY: "auto" },
    overflowY: "hidden",
    overflowWrap: "break-word",
  },
  liteProfileCardContentForMatched: {
    overflowWrap: "break-word",
    backgroundColor: "#FFFFFF",
    paddingTop: 0,
    paddingBottom: 0,
    paddingLeft: "0px",
    paddingRight: "0px",
    height: "34.2em",
    maxHeight: "34.2em",
    "&:hover": { overflowY: "auto" },
    overflowY: "hidden",
    [theme.breakpoints.between("xs", "lg")]: {
      height: "29.2em",
      maxHeight: "29.2em",
    },
  },
  expandedLiteProfileCardContentForMatched: {
    overflowWrap: "break-word",
    backgroundColor: "#FFFFFF",
    paddingTop: 0,
    paddingBottom: 0,
    paddingLeft: "0px",
    paddingRight: "0px",
    "&:hover": { overflowY: "auto" },
    overflowY: "hidden",
  },
  liteProfileCardContentForArchived: {
    overflowWrap: "break-word",
    backgroundColor: "#FFFFFF",
    paddingTop: 0,
    paddingBottom: 0,
    paddingLeft: "0px",
    paddingRight: "0px",
    height: "37.7em",
    maxHeight: "37.7em",
    "&:hover": { overflowY: "auto" },
    overflowY: "hidden",
    [theme.breakpoints.between("xs", "lg")]: {
      height: "32.7em",
      maxHeight: "32.7em",
    },
  },
  expandedLiteProfileCardContentForArchived: {
    overflowWrap: "break-word",
    backgroundColor: "#FFFFFF",
    paddingTop: 0,
    paddingBottom: 0,
    paddingLeft: "0px",
    paddingRight: "0px",
    "&:hover": { overflowY: "auto" },
    overflowY: "hidden",
  },
  customLiteProfileCardContent: {
    overflowWrap: "break-word",
    backgroundColor: "#FFFFFF",
    paddingTop: 0,
    paddingBottom: 0,
    paddingLeft: "0px",
    paddingRight: "0px",
    height: "30em",
    maxHeight: "30em",
    "&:hover": { overflowY: "auto" },
    overflowY: "hidden",
  },
  expandedCustomLiteProfileCardContent: {
    overflowWrap: "break-word",
    backgroundColor: "#FFFFFF",
    paddingTop: 0,
    paddingBottom: 0,
    paddingLeft: "0px",
    paddingRight: "0px",
    "&:hover": { overflowY: "auto" },
    overflowY: "hidden",
  },
  // liteProfileContent: {textAlign: 'left', paddingLeft: '5%', paddingRight: '5%', maxHeight: '13.5em', height: '13.5em', overflowY: 'auto'},
  liteProfileContent: {
    maxHeight: "17.5em",
    height: "17.5em",
    overflowY: "auto",
    // textIndent: '-1.4em',
    textAlign: "left",
    fontSize: "smaller",
  },
  customLiteProfileContent: {
    paddingRight: "5%",
    maxHeight: "22.5em",
    height: "22.5em",
    overflowY: "auto",
    // textIndent: '-1.4em',
    paddingLeft: "5%",
    textAlign: "left",
    fontSize: "smaller",
  },
  noBoxShadow: { boxShadow: "none" },
  noBorderBottom: { borderBottom: "none" },
  viewMore: { color: "#302F2C", cursor: "pointer", fontWeight: "bold" },
  matchedCardHeader: { alignItems: "center", justifyContent: "center" },
  alignWebkitCenter: { textAlign: "-webkit-center" },
  justifyContentSpaceEvenly: { justifyContent: "space-evenly" },
  justifyContentSpaceBetween: { justifyContent: "space-between" },
  // bgChangeOnHover: {'&:hover':{backgroundColor: '#FBC112'}},
  verySmallMarginRight: { marginRight: "2%" },
  skillsCardPanel: {
    // '&:hover':{
    overflowY: "auto",
    // }
  },
  filterGoBtn: { width: "-webkit-fill-available" },
  cursorPointer: {
    cursor: "pointer",
  },
  likeButton: {
    // // backgroundColor: '#FBC112 !important'
    backgroundColor: "#000000 !important",
    // // color: '#FBC112 !important',
    // '&:hover': {
    // backgroundColor: '#FFFFFF !important',
    // color: '#000000 !important',
    // fill: '#000000 !important'
    // border: '1px solid lightgrey !important'
    // },
  },
  historyButton: {
    backgroundColor: "#000000", //'#FBC925',
    color: "#ffffff",
    width: "100%",
    borderRadius: "0px 0px 5px 5px !important",
    fontWeight: "normal !important",
    "&:hover": {
      backgroundColor: "#FBC925 !important",
      color: "#000000 !important",
    },
    justifyContent: "center",
    padding: "3%",
    boxShadow:
      "0px 0px 0px 1px rgb(0 0 0 / 5%), 0px 0px 0px 0px rgb(0 0 0 / 5%), 0px 0px 0px 1px rgb(0 0 0 / 5%)",
  },
  customHighlightsPaper: {
    paddingLeft: "3%",
    paddingRight: "3%",
    paddingBottom: "0",
    boxShadow: "none",
    borderRadius: "10px",
    border: "2px solid #e0e0e0",
    // height: '100%',
    // maxHeight: '100%',
    overflowY: "auto",
  },
  smallLeftPadding: { paddingLeft: "8px" },
  equalButtons: {
    width: "unset",
    margin: "0, 0",
    borderRadius: "3px !important",
  },
  customEqualButtons: {
    width: "8em",
    margin: "10px !important",
    borderRadius: "3px !important",
  },
  // equalButtons: {width: '45vw', margin: '3%'},
  // fixedTitleTableHeight: {minHeight: '130px', marginTop: '10%'},
  fixedTitleTableHeight: {
    minHeight: "130px",
    display: "flex",
    alignItems: "center",
  },
  fixedTableHeight: { height: "120px !important" },
  // fixedTitleHeight: {height: '80px !important'},
  defaultButton: {
    backgroundColor: "#FFFFFF",
    border: "#000000 solid 1px",
    marginRight: "1%",
  },
  customDialogHeader: {
    maxHeight: "38%",
    height: "38%",
    color: "#000",
    backgroundSize: "cover",
    backgroundImage: `url(${cardHeaderBg})`,
    background: "round",
  }, //height: '180px'
  noImageAvatar: {
    color: "#FFFFFF",
    fontSize: "large",
    backgroundColor: "#000000",
  },
  cardTitle: {
    whiteSpace: "no-wrap",
  },
  breadcrumbCustom: {
    [theme.breakpoints.down("md")]: {
      fontSize: "0.8rem",
    },
    [theme.breakpoints.down("sm")]: {
      fontSize: "0.8rem",
    },
  },
  countryList: {
    maxHeight: "60px",
    "&::-webkit-scrollbar": {
      display: "none",
    },
  },
  noDataNote: {
    height: "calc(100vh - 300px)",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  diversityIcon: {
    fontSize: "18px",
    position: "relative",
    bottom: "40px",
    color: "#FFFFFF",
    left: "4px",
  },
  customDiversityIcon: {
    fontSize: "18px",
    position: "relative",
    bottom: "45px",
    color: "#FFFFFF",
    left: "4px",
  },
  expCompanyCard: {
    width: "100%",
    paddingTop: "16px",
    paddingBottom: "16px",
    textAlign: "left",
  },
  expCompanyCardHeadValue: {
    fontWeight: "400",
    color: "#000000",
    fontSize: "16px",
  },
  expCompanyCardHead: { fontWeight: "bold", marginBottom: "5%" },
  salaryOption: {
    marginTop: "16px",
    marginBottom: "16px",
  },
  salaryHelpText: {
    padding: "8px",
    fontWeight: 500,
  },
  expGapAvatar: {
    marginLeft: "auto",
    marginRight: "auto",
    marginTop: "20px",
  },
  expGapFlag: {
    color: "#FBC112",
    cursor: "pointer",
  },
  mainGridTitle: {
    display: "flex",
    alignItems: "center",
    marginTop: "10px",
    height: "auto",
    [theme.breakpoints.between("xs", "sm")]: {
      display: "block",
      marginTop: "55px",
    },
  },

  typoTitle: { alignSelf: "center", width: "75%" },
  viewProfileDiv: {
    width: "15%",
    display: "flex",
    justifyContent: "end",
    [theme.breakpoints.between("xs", "sm")]: {
      width: "75%",
      display: "block",
    },
  },
  viewProfileButton: {
    backgroundColor: "#000000",
    "&:hover": {
      backgroundColor: "rgb(215, 217, 219) !important",
      color: "#000000 !important",
    },
    [theme.breakpoints.between("xs", "sm")]: {
      display: "none",
    },
    width: "133px",
    padding: "5px 10px 5px 10px",
  },
  IconProfileButton: {
    [theme.breakpoints.between("md", "xl")]: {
      display: "none",
    },
    backgroundColor: "#000000",
    marginTop: "3px",
    "&:hover": {
      backgroundColor: "#000000 !important",
      color: "#ffffff !important",
    },
  },
  marginBottom30: {
    marginBottom: "40px",
  },
  customFlex: {
    display: "flex",
  },
  timelineDotVisibile: {
    boxShadow: "none",
    backgroundColor: "#048475",
    marginTop: "12px",
    visibility: "hidden",
  },
  timelineGrid: {
    paddingLeft: "25px",
    marginTop: "0px !important",
  },
  paddingLeft50: {
    paddingLeft: "45px",
    // padding:"10px"
  },
  fontWeight700: {
    fontWeight: 700,
  },
  gapTopGrid: {
    paddingLeft: "18px",
    display: "flex",
    alignItems: "center",
  },
  customFontBold: {
    fontWeight: "bold",
  },
  paddingLeft15: {
    paddingLeft: "16px",
    display: "flex",
    alignItems: "center",
  },
  marginLeftDot: {
    marginLeft: "2px",
  },
  avatarFlag: {
    height: "62px",
    width: "62px",
    // "&:hover": {
    //     "-webkit-transform": "scale(1.5)",
    //     "-ms-transform": "scale(1.5)",
    //     "transform": "scale(1.5)",
    // },
    // transition: "transform .5s",
  },
  falgIconStyle: {
    color: "#FBC112",
    cursor: "pointer",
  },
  flagAva: {
    border: "1.5px solid #fcfaf5",
    height: "100%",
    width: "100%",
    padding: "15px",
    background: "#F4F4F5",
  },
  timelineStyle: {
    padding: "0px",
    margin: "0px",
    alignItems: "center",
  },
  timelineItemStyle: {
    height: "calc(100% - 24px)",
  },
  timelineDotStyle: {
    boxShadow: "none",
    backgroundColor: "#048475",
    marginTop: "12px",
  },
  timelineContentStyle: {
    padding: "3px 13px 0px 0px",
    width: "130px",
  },
  TimelineConnectorStyle: {
    height: "inherit",
    backgroundColor: "#048475",
  },
  padding52: {
    paddingLeft: "52px",
  },
  listStyleNone: {
    listStyle: "none",
  },
  timeLine: {
    marginTop: "0px",
  },
  paddingMedium: {
    paddingLeft: "15px",
  },
  marginDivider: {
    marginBottom: "10px",
  },
  gapDivider: {
    marginTop: "10px",
    marginBottom: "10px",
  },
  meadiumPaddingLeft: {
    paddingLeft: "15px",
  },
  dailogTitleStyle: {
    textAlign: "center !important",
  },
});

const iOSBoxShadow =
  "0 3px 1px rgba(0,0,0,0.1),0 4px 8px rgba(0,0,0,0.13),0 0 0 1px rgba(0,0,0,0.02)";

const SalarySlider = styled(Slider)(({ theme }) => ({
  color: "#FBC112",
  width: "100%",
  [theme.breakpoints.between("sm", "md")]: {
    width: "85%",
  },
  [theme.breakpoints.between("xs", "sm")]: {
    width: "70%",
  },
  padding: "10px 0",
  "& .MuiSlider-thumb": {
    height: 22,
    width: 22,
    backgroundColor: "#048475",
    boxShadow: iOSBoxShadow,
    "&:focus, &:hover, &.Mui-active": {
      boxShadow:
        "0 3px 1px rgba(0,0,0,0.1),0 4px 8px rgba(0,0,0,0.3),0 0 0 1px rgba(0,0,0,0.02)",
      "@media (hover: none)": {
        boxShadow: iOSBoxShadow,
      },
    },
  },
  "& .MuiSlider-valueLabel": {
    fontSize: 12,
    fontWeight: "normal",
    top: -20,
    backgroundColor: "unset",
    color: theme.palette.text.primary,
    "&:before": {
      display: "none",
    },
    "& *": {
      background: "transparent",
      color: theme.palette.mode === "dark" ? "#fff" : "#000",
    },
  },
  "& .MuiSlider-IOSSlider": {
    top: -10,
  },
  "& .MuiSlider-track": {
    height: 10,
    border: "none",
    borderRadius: "10px",
  },
  "& .MuiSlider-rail": {
    height: 10,
    opacity: 0.5,
    backgroundColor: "#bfbfbf",
    borderRadius: "10px",
  },
  "& .MuiSlider-mark": {
    backgroundColor: "#bfbfbf",
    height: 15,
    width: 2,
    "& .MuiSlider-markActive": {
      opacity: 1,
      backgroundColor: "currentColor",
    },
  },
  "& .MuiSlider-markLabel": {
    [theme.breakpoints.down("md")]: {
      transform: "rotateZ(45deg) !important",
    },
    [theme.breakpoints.down("sm")]: {
      transform: "rotateZ(45deg) !important",
    },
  },
}));

const Accordion = withStyles(() => ({
  root: {
    cursor: "default",
    backgroundColor: "#FEF5DB",
    boxShadow: "none",
    "& .MuiAccordionSummary-root.Mui-expanded": {
      minHeight: "0 !important",
    },
    "&:not(:last-child)": {
      borderBottom: 0,
    },
    "&:before": {
      display: "none",
    },
  },
}))(MuiAccordion);

const AccordionSummary = withStyles(() => ({
  root: {
    backgroundColor: "#FEF5DB",
    margin: "0 0 0",
    "&$expanded": {
      margin: "0 0 0",
    },
    padding: 0,
  },
  content: {
    display: "unset",
    textAlign: "left",
    margin: "5px 0 10px !important",
    "&$expanded": {
      margin: "20px 0 10px !important",
    },
  },
}))(MuiAccordionSummary);

const AccordionDetails = withStyles(() => ({
  root: {
    display: "unset",
    textAlign: "left",
    backgroundColor: "#FEF5DB !important",
    border: "none",
    padding: 0,
  },
}))(MuiAccordionDetails);

const Pop = (props) => {
  const { anchorEl, ...rest } = props;
  const bound = anchorEl.getBoundingClientRect();
  return (
    <div
      {...rest}
      style={{
        position: "absolute",
        zIndex: 99999,
        width: bound.width,
      }}
    />
  );
};

const DialogTitle = withStyles(styles)((props) => {
  const { children, classes, onClose, expandedView, ...other } = props;
  return (
    <MuiDialogTitle
      disableTypography
      {...other}
      className={!expandedView ? classes.customDialogHeader : ""}
      style={{ padding: !expandedView ? "3%" : "0%" }}
    >
      <Typography variant="h5" align="center">
        {children}
      </Typography>
      {onClose ? (
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={onClose}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const DialogContent = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
    paddingLeft: theme.spacing(6),
    paddingRight: theme.spacing(6),
    [theme.breakpoints.down("md")]: {
      padding: theme.spacing(2),
    },
    [theme.breakpoints.down("sm")]: {
      padding: theme.spacing(2),
    },
  },
}))(MuiDialogContent);

const DialogActions = withStyles((theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
    backgroundColor: "#fff",
    justifyContent: "center !important",
  },
}))(MuiDialogActions);

let charCount = 5000;
let reasonCount = 100;
let customFieldCount = 1000;
let likeMessageCount = 1000;
let customFieldCountForMajor = 200;
let jobTitles = [];
let reportsToTitles = [];
let degrees = [];
let receivedCertifications = [];
let receivedSkills = [];
let majors = [];
let collaboratorIds = [];
let startupStage = [];
let startupSize = [];
let teamSize = [];
let companyNames = [];
let companyNamesArray = [];
let PayloadSampleComArray = [];
let IndustryOptions = [];

class JobDetails extends Component {
  constructor(props) {
    super(props);
    const receivedAboutUs = this.props?.jobs?.jobDetails?.about_us;
    const receivedJobDescription = this.props?.jobs?.jobDetails?.about_role
      ? this.props?.jobs?.jobDetails?.about_role
      : this.props?.jobs?.jobDetails?.about_job
      ? this.props?.jobs?.jobDetails?.about_job
      : "";
    const receivedBenefitsAndPerks = this.props?.jobs?.jobDetails
      ?.benefits_and_perks
      ? this.props?.jobs?.jobDetails?.benefits_and_perks
      : "";
    const additionresponsibilities = this.props?.jobs?.jobDetails
      ?.additional_responsibility
      ? this.props?.jobs?.jobDetails?.additional_responsibility
      : "";
    const candidateWillBring = this.props?.jobs?.jobDetails
      ?.candidate_value_addition
      ? this.props?.jobs?.jobDetails?.candidate_value_addition
      : "";
    const candidateMustHave = this.props?.jobs?.jobDetails?.absolute_must_have
      ? this.props?.jobs?.jobDetails?.absolute_must_have
      : "";
    this.state = this.getInitialState();
    this.setEditorStates(
      receivedAboutUs,
      receivedJobDescription,
      receivedBenefitsAndPerks,
      additionresponsibilities,
      candidateWillBring,
      candidateMustHave
    );
    // this.state = {
    //                 // visibility: getIsDocumentHidden()
    //            }
    const onVisibilityChange = () => {
      this.setState({ visibility: getIsDocumentHidden() });
    };
    this.onTabChange = onVisibilityChange;
  }

  setEditorStates = (
    receivedAboutUs,
    receivedJobDescription,
    receivedBenefitsAndPerks,
    additionresponsibilities,
    candidateWillBring,
    candidateMustHave
  ) => {
    const draftAboutUs = receivedAboutUs ? htmlToDraft(receivedAboutUs) : null;
    const draftJobDescription = receivedJobDescription
      ? htmlToDraft(receivedJobDescription)
      : null;
    const draftAdditionresponsibilities = additionresponsibilities
      ? htmlToDraft(additionresponsibilities)
      : null;
    const drafCandidateWillBring = candidateWillBring
      ? htmlToDraft(candidateWillBring)
      : null;
    const drafCandidateMustHave = candidateMustHave
      ? htmlToDraft(candidateMustHave)
      : null;
    const draftBenefitsAndPerks = receivedBenefitsAndPerks
      ? htmlToDraft(receivedBenefitsAndPerks)
      : null;
    // if (draftAboutUs && draftJobDescription && draftAdditionresponsibilities && drafCandidateWillBring && drafCandidateMustHave) {
    const contentStateAboutUs = draftAboutUs
      ? ContentState.createFromBlockArray(draftAboutUs.contentBlocks)
      : null;
    const contentStateJobDescription = draftJobDescription
      ? ContentState.createFromBlockArray(draftJobDescription.contentBlocks)
      : null;
    const contentStateAdditionresponsibilities = draftAdditionresponsibilities
      ? ContentState.createFromBlockArray(
          draftAdditionresponsibilities.contentBlocks
        )
      : null;
    const contentCandidateBring = drafCandidateWillBring
      ? ContentState.createFromBlockArray(drafCandidateWillBring.contentBlocks)
      : null;
    const contentAbsoluteMustHave = drafCandidateMustHave
      ? ContentState.createFromBlockArray(drafCandidateMustHave.contentBlocks)
      : null;
    const contentStateBenefitsAndPerks = draftBenefitsAndPerks
      ? ContentState.createFromBlockArray(draftBenefitsAndPerks.contentBlocks)
      : null;
    const editorStateAboutUs = contentStateAboutUs
      ? EditorState.createWithContent(contentStateAboutUs)
      : EditorState.createEmpty();
    const editorStateJobDescription = contentStateJobDescription
      ? EditorState.createWithContent(contentStateJobDescription)
      : EditorState.createEmpty();
    const editorStateAdditionresponsibilities =
      contentStateAdditionresponsibilities
        ? EditorState.createWithContent(contentStateAdditionresponsibilities)
        : EditorState.createEmpty();
    const editorcandidateWillBring = contentCandidateBring
      ? EditorState.createWithContent(contentCandidateBring)
      : EditorState.createEmpty();
    const editorAbsoluteMustHave = contentAbsoluteMustHave
      ? EditorState.createWithContent(contentAbsoluteMustHave)
      : EditorState.createEmpty();
    const editorStateBenefitsAndPerks = contentStateBenefitsAndPerks
      ? EditorState.createWithContent(contentStateBenefitsAndPerks)
      : EditorState.createEmpty();
    this.setState({
      editorStateAboutUs: editorStateAboutUs,
      editorStateJobDescription: editorStateJobDescription,
      additionresponsibilities: editorStateAdditionresponsibilities,
      editorcandidateWillBring: editorcandidateWillBring,
      editorAbsoluteMustHave: editorAbsoluteMustHave,
      editorStateBenefitsAndPerks: editorStateBenefitsAndPerks,
      aboutUsWithHTML: draftToHtml(
        convertToRaw(editorStateAboutUs.getCurrentContent())
      ),
      jobDescriptionWithHTML: draftToHtml(
        convertToRaw(editorStateJobDescription.getCurrentContent())
      ),
      additionResponsibilitesWithHTML: draftToHtml(
        convertToRaw(editorStateAdditionresponsibilities.getCurrentContent())
      ),
      benefitsAndPerksWithHTML: draftToHtml(
        convertToRaw(editorStateBenefitsAndPerks.getCurrentContent())
      ),
      willSuccessfullCandidateBringHTML: draftToHtml(
        convertToRaw(editorcandidateWillBring.getCurrentContent())
      ),
      absolueMustHavehHTML: draftToHtml(
        convertToRaw(editorAbsoluteMustHave.getCurrentContent())
      ),
    });
    // }
  };

  getInitialState = () => ({
    // editorStateAboutUs: editorStateAboutUs,
    // editorStateJobDescription: editorStateJobDescription,
    // additionresponsibilities: editorStateAdditionresponsibilities,
    // editorcandidateWillBring: editorcandidateWillBring,
    // editorAbsoluteMustHave: editorAbsoluteMustHave,
    // editorStateBenefitsAndPerks: editorStateBenefitsAndPerks,
    value: 0,
    innerTabValue: 0,
    expanded: "",
    liteProfileAccordionExpanded: "",
    open: false,
    openPreview: false,
    openAddCollaboratorsPopup: false,
    openCloseConfirm: false,
    openCloseFormPopup: false,
    dislikePopupOpen: false,
    likePopupOpen: false,
    openOptions: false,
    openDegreeOptions: false,
    openReportsToOptions: false,
    openSkillsOptions: false,
    openCertificationsOptions: false,
    openLocationOptions: false,
    jobTitleOptions: [],
    degreeOptions: [],
    reportsToTitleOptions: [],
    skillsOptions: [],
    certificationsOptions: [],
    locationOptions: [],
    scroll: "paper",
    jobTitle: "",
    jobTitleValue: "",
    degreeValue: "",
    reportsToTitleValue: "",
    jobLevel: "",
    reportsTo: "",
    industry: "",
    functionName: "",
    isValidOtherFunctionName: true,
    location: "",
    locationData: "",
    remote: "",
    travelLevel: "",
    skills: [],
    aboutUs: "",
    additionResponsibilitesState: "",
    willSuccessfullState: "",
    absolueMustHaveState: "",
    benefitsAndPerksState: "",
    jobDescription: "",
    howSoonToHire: "",
    benefitsAndPerks: "",
    isWillSuccessfullCandidateBringRequired: false,
    isWillSuccessfullCandidateBringDescription: true,
    isAbosultMustRequire: false,
    isValidisAbosultMustnDescription: true,
    isValidJobTitle: true,
    isValidReportsToName: true,
    isValidIndustry: true,
    isValidLocation: true,
    backdrop: false,
    closeComment: "",
    isValidCloseComment: true,
    isValidArchiveReason: true,
    isValidOtherArchiveReason: true,
    archiveReason: "",
    otherArchiveReason: "",
    certifications: [],
    degree: "",
    minimumBaseSalary: "100000",
    minimumExperience: "",
    reportsToTitle: "",
    historyPopupOpen: false,
    jobTitleLoading: false,
    jobTitleLoadingAlternative: false,
    degreeLoading: false,
    reportsToTitlesLoading: false,
    skillsLoading: false,
    isJobTitleRequire: false,
    isJobLevelRequire: false,
    isReportsToRequire: false,
    isHiringManagerNameRequire: false,
    isIndustryRequire: false,
    isFunctionRequire: false,
    isLocationRequire: false,
    isYearOfExpRequire: false,
    isSalaryRequired: false,
    isDegreeRequire: false,
    isCertificationsRequire: false,
    isAdditionResponsibilitesRequire: false,
    isAdditionResponsibilitesDescription: true,
    isRemoteOnSiteRequire: false,
    isTravelRequirementRequire: false,
    isAboutUsRequire: false,
    isPositionDescriptionRequire: false,
    isSkillsRequire: false,
    isSoonLookingToHireRequire: false,
    isBenefitAndPerkRequire: false,
    isValidBenefitsAndPerks: true,
    isValidAboutUs: true,
    isValidPositionDescription: true,
    isValidAbsoluteMustHaves: true,
    isValidAdditionresponsibilities: true,
    isValidCandidateWillBring: true,
    customJobTitlePopUp: false,
    customJobTitle: "",
    customCertificationTitlePopUp: false,
    customCertificationTitle: "",
    otherFunctionName: null,
    likeMessage: "",
    customMajorPopUp: false,
    customMajorValue: "",
    majorValue: null,
    major: "",
    majorOptions: [],
    openMajorOptions: false,
    degreeRequiredOrPreferred: null,
    isDegreeRequiredOrPreferredRequire: false,
    role: "",
    fields: [
      {
        collaboratorValue: "",
        accessLevelValue: "",
        isCollaboratorRequired: false,
        isAccessLevelRequired: false,
        error: false,
      },
    ],
    openConfirmRemoveCollaboratorPopup: false,
    openCandidateCommentsPopup: false,
    comment: "",
    arrowRef: null,
    expandedView: true,
    isValidSkill: true,
    salary_type: "",
    salary_config: "",
    startupStage: [],
    startupSize: [],
    teamSize: [],
    companyOptions: [],
    sampleCompany: [],
    sampleCompanyValueInput: "",
    sampleCompanyValues: null,
    companyNamesArray: [],
    isBoardView: false,
    paneLayoutCandidateList: [],
    teamSizeReceived: [],
    startupSizeReceived: [],
    startupStageReceived: [],
    receivedCompanies: [],
    baseSalary: null,
    majorValueRecived: null,
    locationFromAPi: null,
    openScheduleInterview: false,
    scheduleInterviewType: "",
    openEmailPreview: false,
    openMore: false,
    openShareProfile: false,
    shareProfile_rec_id: null,
    shareProfile_candidate_id: null,
    contect_no: "",
    isInterestedToArchive: "",
    jobStatusState: "",
    candidateIDforTab: "",
    confidenNormalValue: "",
    customIndustryValue: null,
    customIndustryRequired: false,
    openCustomIndustryField: false,
    tabState: false,
    alternativeJobTitleOptions: [],
    alternativeJobTitle: "",
    alternativeTiltes: [],
    alternativeIndustry: [],
  });

  componentDidMount() {
    const { dispatch } = this.props;
    const params = new URLSearchParams(window.location.search);
    let jobId = this.props?.match?.params?.id;
    dispatch(jobsActions.getJobDetails(jobId));
    const View = params.get("View");
    const Tab = params.get("Tab");
    if (View && Tab) {
      dispatch(jobsActions.setView(View));
      switch (Tab) {
        case "All_candidate":
          dispatch(setAllUpdateHeaderCard("All"));
          break;
        case "Talent_pipeline":
          dispatch(setAllUpdateHeaderCard("1"));
          break;
        case "Shortlisted_candidates":
          dispatch(setAllUpdateHeaderCard("2"));
          break;
        case "Inconversation_candidates":
          dispatch(setAllUpdateHeaderCard("3"));
          break;
        case "Remove_from_queue":
          dispatch(setAllUpdateHeaderCard("4"));
          break;
        default:
          break;
      }
    }

    const { pageTitle } = this.props.pageTitle;
    const { jobDetails, activeTab, allUpdateHeaderCard } = this.props.jobs;
    const { selectedNavbarIndex } = this.props.shared;
    if (activeTab.jobDetailTab) {
      this.setState({ value: activeTab.jobDetailTab });
    } else {
      dispatch(jobsActions.setJobTab({ jobDetailTab: 4 }));
    }
    switch (allUpdateHeaderCard) {
      case "All":
        dispatch(
          setTitle(
            `${`Jobs`} ${`-`} ${
              selectedNavbarIndex === 1
                ? "My Jobs"
                : selectedNavbarIndex === 11
                ? "Shared Jobs"
                : "My Jobs"
            } ${`-`}  ${
              jobDetails?.job_status === "Close"
                ? "Closed Postions"
                : "Open Positions"
            } ${`-`} ${jobDetails?.title} ${`-`} ${
              jobDetails?.recruiter?.first_name
            } ${jobDetails?.recruiter?.last_name} ${`-`} ${`All Candidates`}`
          )
        );
        break;
      case "1":
        dispatch(
          setTitle(
            `${`Jobs`} ${`-`} ${
              selectedNavbarIndex === 1
                ? "My Jobs"
                : selectedNavbarIndex === 11
                ? "Shared Jobs"
                : "My Jobs"
            } ${`-`}  ${
              jobDetails?.job_status === "Close"
                ? "Closed Postions"
                : "Open Positions"
            } ${`-`} ${jobDetails?.title} ${`-`} ${
              jobDetails?.recruiter?.first_name
            } ${jobDetails?.recruiter?.last_name} ${`-`} ${`New Candidates`}`
          )
        );
        break;
      case "2":
        dispatch(
          setTitle(
            `${`Jobs`} ${`-`} ${
              selectedNavbarIndex === 1
                ? "My Jobs"
                : selectedNavbarIndex === 11
                ? "Shared Jobs"
                : "My Jobs"
            } ${`-`}  ${
              jobDetails?.job_status === "Close"
                ? "Closed Postions"
                : "Open Positions"
            } ${`-`} ${jobDetails?.title} ${`-`} ${
              jobDetails?.recruiter?.first_name
            } ${
              jobDetails?.recruiter?.last_name
            } ${`-`} ${`Shortlisted Candidates`}`
          )
        );
        break;
      case "3":
        dispatch(
          setTitle(
            `${`Jobs`} ${`-`} ${
              selectedNavbarIndex === 1
                ? "My Jobs"
                : selectedNavbarIndex === 11
                ? "Shared Jobs"
                : "My Jobs"
            } ${`-`}  ${
              jobDetails?.job_status === "Close"
                ? "Closed Postions"
                : "Open Positions"
            } ${`-`} ${jobDetails?.title} ${`-`} ${
              jobDetails?.recruiter?.first_name
            } ${jobDetails?.recruiter?.last_name} ${`-`} ${`In Conversations`}`
          )
        );
        break;
      case "4":
        dispatch(
          setTitle(
            `${`Jobs`} ${`-`} ${
              selectedNavbarIndex === 1
                ? "My Jobs"
                : selectedNavbarIndex === 11
                ? "Shared Jobs"
                : "My Jobs"
            } ${`-`}  ${
              jobDetails?.job_status === "Close"
                ? "Closed Postions"
                : "Open Positions"
            } ${`-`} ${jobDetails?.title} ${`-`} ${
              jobDetails?.recruiter?.first_name
            } ${
              jobDetails?.recruiter?.last_name
            } ${`-`} ${`Removed from Queue`}`
          )
        );
        break;
      default:
        break;
    }

    document.body.scrollTop = 0; // For Safari
    document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE and Opera

    seo({
      title: `${pageTitle}`,
    });

    const visibilityChange = getBrowserVisibilityProp();
    document.addEventListener(visibilityChange, this.onTabChange, false);
    if (this.state.visibility) {
      this.onFocus();
    }
  }

  componentDidUpdate(prevProps, prevState) {
    const { pageTitle } = this.props.pageTitle;
    const { dispatch } = this.props;
    const { jobDetails, activeTab } = this.props.jobs;
    const { selectedNavbarIndex } = this.props.shared;
    if (prevProps.jobs.jobDetails !== this.props.jobs.jobDetails) {
      if (
        this.props.jobs?.jobDetails?.actioners?.owner?.id ===
          this.props?.authentication?.recruiterStatusDetails?.recruiter_id ||
        this.checkRecruiterPermission() === 2
      ) {
        dispatch(sharedActions.setAccessPermission(true));
      } else {
        dispatch(sharedActions.setAccessPermission(false));
      }
    }
    if (prevProps.jobs.jobTitles !== this.props.jobs.jobTitles) {
      jobTitles = this.props.jobs.jobTitles;
      this.setState({
        jobTitleOptions: jobTitles,
        alternativeJobTitleOptions: jobTitles,
        jobTitleLoading: false,
        jobTitleLoadingAlternative: false,
      });
    }
    if (prevProps.jobs.reportsToTitles !== this.props.jobs.reportsToTitles) {
      reportsToTitles = this.props.jobs.reportsToTitles;
      this.setState({
        reportsToTitleOptions: reportsToTitles,
        reportsToTitlesLoading: false,
      });
    }
    if (prevProps.jobs.skills !== this.props.jobs.skills) {
      receivedSkills = this.props.jobs.skills;
      this.setState({ skillsOptions: receivedSkills, skillsLoading: false });
    }
    if (prevProps.jobs.degrees !== this.props.jobs.degrees) {
      degrees = this.props.jobs.degrees;
      this.setState({ degreeOptions: degrees, degreeLoading: false });
    }
    if (prevProps.jobs.certifications !== this.props.jobs.certifications) {
      receivedCertifications = this.props.jobs.certifications;
      this.setState({
        certificationsOptions: receivedCertifications,
        certificationsLoading: false,
      });
    }
    var elementList = document.querySelectorAll(
      '[aria-label="ant example lite profile"]'
    );
    if (elementList?.length > 0) {
      for (let element in elementList) {
        if (
          elementList[element] !== undefined &&
          elementList[element].style !== undefined
        ) {
          elementList[element].style.justifyContent = "space-evenly";
          elementList[element].style.display = "inherit";
        }
      }
    }

    if (prevProps.jobs.majors !== this.props.jobs.majors) {
      majors = this.props.jobs.majors;
      this.setState({ majorOptions: majors, majorLoading: false });
    }

    if (prevProps.jobs.companyNames !== this.props.jobs.companyNames) {
      companyNames = this.props.jobs.companyNames;
      this.setState({ companyOptions: companyNames, companyLoading: false });
    }
    if (
      prevProps.jobs?.collaboratorRecruiters !==
      this.props?.jobs?.collaboratorRecruiters
    ) {
      this.setState(
        { collaboratorRecruiters: this.props?.jobs?.collaboratorRecruiters },
        () => {
          if (this.state.collaboratorRecruiters?.length > 0) {
            this.setState({ openAddCollaboratorsPopup: true });
          } else {
            this.setState({ openAddCollaboratorsPopup: false });
            toast.dark("No collaborator available to add");
          }
        }
      );
    }

    if (prevProps.pageTitle?.pageTitle !== this.props?.pageTitle?.pageTitle) {
      switch (activeTab.jobDetailTab) {
        case 0:
          dispatch(
            setTitle(
              `${`Jobs`} ${`-`} ${
                selectedNavbarIndex === 1
                  ? "My Jobs"
                  : selectedNavbarIndex === 11
                  ? "Shared Jobs"
                  : "My Jobs"
              } ${`-`}  ${
                jobDetails?.job_status === "Close"
                  ? "Closed Postions"
                  : "Open Positions"
              } ${`-`} ${jobDetails?.title} ${`-`} ${
                jobDetails?.recruiter?.first_name
              } ${jobDetails?.recruiter?.last_name} ${`-`} ${`Recommended`}`
            )
          );
          break;
        case 1:
          dispatch(
            setTitle(
              `${`Jobs`} ${`-`} ${
                selectedNavbarIndex === 1
                  ? "My Jobs"
                  : selectedNavbarIndex === 11
                  ? "Shared Jobs"
                  : "My Jobs"
              } ${`-`} ${
                jobDetails?.job_status === "Close"
                  ? "Closed Postions"
                  : "Open Positions"
              } ${`-`} ${jobDetails?.title} ${`-`} ${
                jobDetails?.recruiter?.first_name
              } ${jobDetails?.recruiter?.last_name} ${`-`} ${`Pending`}`
            )
          );
          break;
        case 2:
          dispatch(
            setTitle(
              `${`Jobs`} ${`-`} ${
                selectedNavbarIndex === 1
                  ? "My Jobs"
                  : selectedNavbarIndex === 11
                  ? "Shared Jobs"
                  : "My Jobs"
              } ${`-`}  ${
                jobDetails?.job_status === "Close"
                  ? "Closed Postions"
                  : "Open Positions"
              } ${`-`} ${jobDetails?.title} ${`-`} ${
                jobDetails?.recruiter?.first_name
              } ${jobDetails?.recruiter?.last_name} ${`-`} ${`Matched`}`
            )
          );
          break;
        case 3:
          dispatch(
            setTitle(
              `${`Jobs`} ${`-`} ${
                selectedNavbarIndex === 1
                  ? "My Jobs"
                  : selectedNavbarIndex === 11
                  ? "Shared Jobs"
                  : "My Jobs"
              } ${`-`}  ${
                jobDetails?.job_status === "Close"
                  ? "Closed Postions"
                  : "Open Positions"
              } ${`-`} ${jobDetails?.title} ${`-`} ${
                jobDetails?.recruiter?.first_name
              } ${
                jobDetails?.recruiter?.last_name
              } ${`-`} ${`Removed from queue`}`
            )
          );
          break;
        default:
          break;
      }
    }

    if (
      prevProps?.jobs?.locationDetails !== this.props?.jobs?.locationDetails
    ) {
      secureLocalStorage.setItem(
        "locationDetails",
        JSON.stringify(this.props?.jobs?.locationDetails)
      );
    }

    if (this.props?.jobs?.recommended !== prevProps?.jobs?.recommended) {
      let recommended = this.props?.jobs?.recommended;
      let isRecommended = recommended?.interested_candidate?.some(
        (pen) => pen.candidate_id === this.state.candidateIDforTab
      );
      if (isRecommended) {
        this.handleTabChange(null, 0);
        dispatch(
          setTitle(
            `${`Jobs`} ${`-`} ${
              selectedNavbarIndex === 1
                ? "My Jobs"
                : selectedNavbarIndex === 11
                ? "Shared Jobs"
                : "My Jobs"
            } ${`-`} ${
              jobDetails?.job_status === "Close"
                ? "Closed Postions"
                : "Open Positions"
            } ${`-`} ${jobDetails?.title} ${`-`} ${
              jobDetails?.recruiter?.first_name
            } ${jobDetails?.recruiter?.last_name} ${`-`} ${`Recommended`}`
          )
        );
      }
    }

    if (this.props?.jobs?.pending !== prevProps?.jobs?.pending) {
      let pending = this.props?.jobs?.pending;
      let isPending = pending?.interested_candidate?.some(
        (pen) => pen.candidate_id === this.state.candidateIDforTab
      );
      if (isPending) {
        this.handleTabChange(null, 1);
        dispatch(
          setTitle(
            `${`Jobs`} ${`-`} ${
              selectedNavbarIndex === 1
                ? "My Jobs"
                : selectedNavbarIndex === 11
                ? "Shared Jobs"
                : "My Jobs"
            } ${`-`} ${
              jobDetails?.job_status === "Close"
                ? "Closed Postions"
                : "Open Positions"
            } ${`-`} ${jobDetails?.title} ${`-`} ${
              jobDetails?.recruiter?.first_name
            } ${jobDetails?.recruiter?.last_name} ${`-`} ${`Pending`}`
          )
        );
      }
    }

    if (this.props?.jobs?.matched !== prevProps?.jobs?.matched) {
      let matched = this.props?.jobs?.matched;
      let isMatch = matched?.interested_candidate?.some(
        (pen) => pen.candidate_id === this.state.candidateIDforTab
      );
      if (isMatch) {
        this.handleTabChange(null, 2);
        dispatch(
          setTitle(
            `${`Jobs`} ${`-`} ${
              selectedNavbarIndex === 1
                ? "My Jobs"
                : selectedNavbarIndex === 11
                ? "Shared Jobs"
                : "My Jobs"
            } ${`-`} ${
              jobDetails?.job_status === "Close"
                ? "Closed Postions"
                : "Open Positions"
            } ${`-`} ${jobDetails?.title} ${`-`} ${
              jobDetails?.recruiter?.first_name
            } ${jobDetails?.recruiter?.last_name} ${`-`} ${`Matched`}`
          )
        );
      }
    }

    if (this.props?.jobs?.archived !== prevProps?.jobs?.archived) {
      let archived = this.props?.jobs?.archived;
      let isArchived = archived?.interested_candidate?.some(
        (pen) => pen.candidate_id === this.state.candidateIDforTab
      );

      if (isArchived) {
        this.handleTabChange(null, 3);
        dispatch(
          setTitle(
            `${`Jobs`} ${`-`} ${
              selectedNavbarIndex === 1
                ? "My Jobs"
                : selectedNavbarIndex === 11
                ? "Shared Jobs"
                : "My Jobs"
            } ${`-`} ${
              jobDetails?.job_status === "Close"
                ? "Closed Postions"
                : "Open Positions"
            } ${`-`} ${jobDetails?.title} ${`-`} ${
              jobDetails?.recruiter?.first_name
            } ${
              jobDetails?.recruiter?.last_name
            } ${`-`} ${`Removed from queue`}`
          )
        );
      }
    }

    if (prevState.visibility !== this.state.visibility) {
      this.onFocus();
    }

    seo({
      title: `${pageTitle}`,
    });
  }

  componentWillUnmount() {
    secureLocalStorage.removeItem("locationDetails");
    const { dispatch } = this.props;
    dispatch(jobsActions.clearAllJobDetails());
    const visibilityChange = getBrowserVisibilityProp();
    document.removeEventListener(visibilityChange, this.onTabChange);
  }

  onFocus = () => {
    const { dispatch } = this.props;
    let headerCardValue = this.props?.jobs.allUpdateHeaderCard;
    let jobId = this.props?.match?.params?.id;
    switch (headerCardValue) {
      case "All":
        dispatch(jobsActions.getAllUpdatesData(jobId, "All"));
        break;
      case "1":
        dispatch(jobsActions.getAllUpdatesData(jobId, "Recommended"));
        break;
      case "2":
        dispatch(jobsActions.getAllUpdatesData(jobId, "Matched"));
        break;
      case "3":
        dispatch(jobsActions.getAllUpdatesData(jobId, "Conversation"));
        break;
      default:
        break;
    }
  };

  onAboutUsChange = (editorStateAboutUs) => {
    if (
      editorStateAboutUs.getCurrentContent().getPlainText("\u0001")?.length > 0
    ) {
      this.setState({ isAboutUsRequire: false });
    }
    if (
      editorStateAboutUs.getCurrentContent().getPlainText("\u0001")?.length >
      5000
    ) {
      this.setState({ isValidAboutUs: false });
    }
    this.setState({ editorStateAboutUs });
  };

  onJobDescriptionChange = (editorStateJobDescription) => {
    if (
      editorStateJobDescription.getCurrentContent().getPlainText("\u0001")
        ?.length > 0
    ) {
      this.setState({ isPositionDescriptionRequire: false });
    }
    if (
      editorStateJobDescription.getCurrentContent().getPlainText("\u0001")
        .length > 5000
    ) {
      this.setState({ isValidPositionDescription: false });
    }
    this.setState({ editorStateJobDescription });
  };

  onAdditionresponsibilities = (additionresponsibilities) => {
    if (
      additionresponsibilities.getCurrentContent().getPlainText("\u0001")
        .length > 0
    ) {
      this.setState({ isAdditionResponsibilitesRequire: false });
    }

    if (
      additionresponsibilities.getCurrentContent().getPlainText("\u0001")
        .length > 5000
    ) {
      this.setState({ isAdditionResponsibilitesDescription: false });
    }
    this.setState({ additionresponsibilities });
  };

  onCandidateWillBring = (editorcandidateWillBring) => {
    if (
      editorcandidateWillBring.getCurrentContent().getPlainText("\u0001")
        .length > 0
    ) {
      this.setState({ isWillSuccessfullCandidateBringRequired: false });
    }

    if (
      editorcandidateWillBring.getCurrentContent().getPlainText("\u0001")
        .length > 5000
    ) {
      this.setState({ isWillSuccessfullCandidateBringDescription: false });
    }
    this.setState({ editorcandidateWillBring });
  };

  onAbsoluteMustHave = (editorAbsoluteMustHave) => {
    if (
      editorAbsoluteMustHave.getCurrentContent().getPlainText("\u0001").length >
      0
    ) {
      this.setState({ isAbosultMustRequire: false });
    }

    if (
      editorAbsoluteMustHave.getCurrentContent().getPlainText("\u0001").length >
      5000
    ) {
      this.setState({ isValidisAbosultMustnDescription: false });
    }
    this.setState({ editorAbsoluteMustHave });
  };

  onBenefitsAndPerksChange = (editorStateBenefitsAndPerks) => {
    if (
      editorStateBenefitsAndPerks.getCurrentContent().getPlainText("\u0001")
        ?.length > 0
    ) {
      this.setState({ isBenefitAndPerkRequire: false });
    }

    if (
      editorStateBenefitsAndPerks.getCurrentContent().getPlainText("\u0001")
        .length > 5000
    ) {
      this.setState({ isValidBenefitsAndPerks: false });
    }
    this.setState({ editorStateBenefitsAndPerks });
  };

  capitalize = function (str) {
    if (str !== null && str !== undefined && typeof str === "string") {
      return str
        .split(" ")
        .map((w) => w.substring(0, 1).toUpperCase() + w.substring(1))
        .join(" ");
    } else return str;
  };

  handleOnBlur = (event) => {
    event.preventDefault();
    this.setState({ jobTitleOptions: [], jobTitle: "" });
  };

  handleMajorBlur = (event) => {
    event.preventDefault();
    this.setState({ majorOptions: [], major: "" });
  };

  handleReportsToBlur = (event) => {
    event.preventDefault();
    this.setState({ reportsToTitleOptions: [], reportsToTitle: "" });
  };

  // handleSkillsBlur = event => {
  //     event.preventDefault();
  //     this.setState({skillsOptions: [], skillsValue: null});
  // }

  handleCertificationsBlur = (event) => {
    event.preventDefault();
    this.setState({ certificationsOptions: [], certificationsValue: null });
  };

  handleAccordionChange = (panel) => (event, newExpanded) => {
    event.preventDefault();
    this.setState({ expanded: newExpanded ? panel : false });
  };

  handleRole = (event) => {
    this.setState({ role: event.target.value });
  };

  // handleLiteProfileAccordionChange = (panel) => (event, newExpanded) => {
  //     event.preventDefault();
  //     this.handleInnerAccordionChange(panel, newExpanded);
  // };

  getTitles = (title) => {
    this.setState({ jobTitleLoading: true });
    const { dispatch } = this.props;
    dispatch(jobsActions.getJobTitles(title));
  };

  getAlternativeTitles = (title) => {
    const { dispatch } = this.props;
    this.setState({ jobTitleLoadingAlternative: true }, () => {
      dispatch(jobsActions.getJobTitles(title));
    });
  };

  getReportsToTitles = (title) => {
    this.setState({ reportsToTitlesLoading: true });
    const { dispatch } = this.props;
    dispatch(jobsActions.getReportsToTitles(title));
    dispatch(loading(false));
  };

  getSkills = (skillInput) => {
    this.setState({ skillsLoading: true });
    const { dispatch } = this.props;
    dispatch(jobsActions.getSkills(skillInput));
  };

  getCertifications = (certification) => {
    this.setState({ certificationsLoading: true });
    const { dispatch } = this.props;
    dispatch(jobsActions.getCertifications(certification));
    dispatch(loading(false));
  };

  selectCheckbox = (Type) => (event) => {
    teamSize = [...this.state.teamSize];
    startupStage = [...this.state.startupStage];
    startupSize = [...this.state.startupSize];
    switch (Type) {
      case "TeamSize":
        if (event.target.checked === true) {
          teamSize.push(event.target.value);
          this.setState({ teamSize: teamSize });
        } else {
          let index = teamSize.findIndex((value) => {
            return value === event.target.value;
          });
          teamSize.splice(index, 1);
          this.setState({ teamSize: teamSize });
        }
        break;
      case "StartupStage":
        if (event.target.checked === true) {
          startupStage.push(event.target.value);
          this.setState({ startupStage: startupStage });
        } else {
          let index = startupStage.findIndex((value) => {
            return value === event.target.value;
          });
          startupStage.splice(index, 1);
          this.setState({ startupStage: startupStage });
        }
        break;
      case "StartupSize":
        if (Type === "StartupSize" && event.target.checked === true) {
          startupSize.push(event.target.value);
          this.setState({ startupSize: startupSize });
        } else {
          let index = startupSize.findIndex((value) => {
            return value === event.target.value;
          });
          startupSize.splice(index, 1);
          this.setState({ startupSize: startupSize });
        }
        break;
      default:
        break;
    }
  };

  handleInputChange = (prop) => (event) => {
    let inputValue = "";
    switch (prop) {
      case "aboutUs":
        inputValue = this.state.editorStateAboutUs
          .getCurrentContent()
          .getPlainText("\u0001");
        break;
      case "jobDescription":
        inputValue = this.state.editorStateJobDescription
          .getCurrentContent()
          .getPlainText("\u0001");
        break;
      case "additionResponsibilitesState":
        inputValue = this.state.additionresponsibilities
          .getCurrentContent()
          .getPlainText("\u0001");
        break;
      case "willSuccessfullState":
        inputValue = this.state.editorcandidateWillBring
          .getCurrentContent()
          .getPlainText("\u0001");
        break;
      case "absolueMustHaveState":
        inputValue = this.state.editorAbsoluteMustHave
          .getCurrentContent()
          .getPlainText("\u0001");
        break;
      case "benefitsAndPerks":
        inputValue = this.state.editorStateBenefitsAndPerks
          .getCurrentContent()
          .getPlainText("\u0001");
        break;
      default:
        inputValue = notAllowWhiteSpaceAtStart(event.target.value);
        break;
    }

    this.setState({ [prop]: inputValue }, function () {
      switch (prop) {
        case "jobTitle":
          this.getTitles(this.state.jobTitle);
          if (this.state.jobTitle.length === 0) {
            this.setState({ jobTitleOptions: [] });
          }
          break;
        case "alternativeJobTitle":
          this.getAlternativeTitles(this.state.alternativeJobTitle);
          if (this.state.jobTitle.length === 0) {
            this.setState({ alternativeJobTitleOptions: [] });
          }
          break;
        case "reportsToTitle":
          this.getReportsToTitles(this.state.reportsToTitle);
          if (this.state.reportsToTitle.length === 0) {
            this.setState({ reportsToTitleOptions: [] });
          }
          break;
        case "skills":
          if (this.state.skills.length === 0) {
            this.setState({ skillsOptions: [] });
          }
          break;
        case "howSoonToHire":
          this.setState({
            isSoonLookingToHireRequire: validator.isEmpty(inputValue),
          });
          break;
        case "certifications":
          this.getCertifications(this.state.certifications);
          if (this.state.certifications.length === 0) {
            this.setState({ certificationsOptions: [] });
          }
          break;
        case "reportsTo":
          this.setState({
            isValidReportsToName: validator.matches(inputValue, /^.{0,100}$/),
          });
          break;
        case "otherArchiveReason":
          this.setState({
            isValidOtherArchiveReason: validator.matches(
              inputValue,
              /^.{25,100}$/s
            ),
          });
          break;
        case "functionName":
          if (this.state.functionName !== "Other") {
            this.setState({ otherFunctionName: null });
          }
          this.setState({
            isFunctionRequire: validator.isEmpty(inputValue),
          });
          if (inputValue === "Other") {
            this.highlightOtherFunctionName();
          }
          break;
        case "otherFunctionName":
          this.setState({
            isValidOtherFunctionName: validator.matches(
              inputValue,
              /^.{0,100}$/s
            ),
          });
          break;
        case "jobLevel":
          this.setState({
            isJobLevelRequire: validator.isEmpty(inputValue),
          });
          break;
        case "industry":
          this.setState(
            {
              isIndustryRequire: validator.isEmpty(inputValue),
            },
            () => {
              this.setState({
                openCustomIndustryField: inputValue === "Other" ? true : false,
                customIndustryRequired:
                  inputValue === "Other"
                    ? this.state.customIndustryValue === null
                      ? true
                      : false
                    : false,
                customIndustryValue:
                  inputValue !== "Other"
                    ? null
                    : this.state.customIndustryValue,
              });
            }
          );
          break;
        case "customIndustryValue":
          this.setState({
            customIndustryRequired: validator.isEmpty(inputValue),
          });
          break;
        case "minimumExperience":
          this.setState({
            isYearOfExpRequire: validator.isEmpty(inputValue),
          });
          break;
        case "major":
          this.getMajors(this.state.major);
          if (this.state.major.length === 0) {
            this.setState({ majorOptions: [] });
          }
          break;
        case "remote":
          this.setState({
            isRemoteOnSiteRequire: validator.isEmpty(inputValue),
          });
          break;
        case "travelLevel":
          this.setState({
            isTravelRequirementRequire: validator.isEmpty(inputValue),
          });
          break;
        case "aboutUs":
          this.setState({
            aboutUsWithHTML: draftToHtml(
              convertToRaw(this.state.editorStateAboutUs.getCurrentContent())
            ),
            isAboutUsRequire: validator.isEmpty(inputValue),
            isValidAboutUs: inputValue
              ? validator.matches(inputValue, /^.{25,5000}$/s)
              : true,
            isEmptyAboutUs: convertToRaw(
              this.state.editorStateAboutUs.getCurrentContent()
            ).blocks.every((b) => b.text.trimStart().length < 25),
          });
          break;
        case "jobDescription":
          this.setState({
            jobDescriptionWithHTML: draftToHtml(
              convertToRaw(
                this.state.editorStateJobDescription.getCurrentContent()
              )
            ),
            isPositionDescriptionRequire: validator.isEmpty(inputValue),
            isValidPositionDescription: inputValue
              ? validator.matches(inputValue, /^.{25,10000}$/s)
              : true,
          });
          break;
        case "willSuccessfullState":
          this.setState({
            willSuccessfullCandidateBringHTML: draftToHtml(
              convertToRaw(
                this.state.editorcandidateWillBring.getCurrentContent()
              )
            ),
            isWillSuccessfullCandidateBringRequired:
              validator.isEmpty(inputValue),
            isValidCandidateWillBring: inputValue
              ? validator.matches(inputValue, /^.{25,10000}$/s)
              : true,
          });
          break;
        case "additionResponsibilitesState":
          this.setState({
            additionResponsibilitesWithHTML: draftToHtml(
              convertToRaw(
                this.state.additionresponsibilities.getCurrentContent()
              )
            ),
            isAdditionResponsibilitesRequire: validator.isEmpty(inputValue),
            isValidAdditionresponsibilities: inputValue
              ? validator.matches(inputValue, /^.{25,10000}$/s)
              : true,
          });
          break;
        case "absolueMustHaveState":
          this.setState({
            absolueMustHavehHTML: draftToHtml(
              convertToRaw(
                this.state.editorAbsoluteMustHave.getCurrentContent()
              )
            ),
            isAbosultMustRequire: validator.isEmpty(inputValue),
            isValidAbsoluteMustHaves: inputValue
              ? validator.matches(inputValue, /^.{25,10000}$/s)
              : true,
          });
          break;
        case "benefitsAndPerks":
          this.setState({
            benefitsAndPerksWithHTML: draftToHtml(
              convertToRaw(
                this.state.editorStateBenefitsAndPerks.getCurrentContent()
              )
            ),
            isBenefitAndPerkRequire: validator.isEmpty(inputValue),
            isValidBenefitsAndPerks: inputValue
              ? validator.matches(inputValue, /^.{0,5000}$/s)
              : true,
          });
          break;
        case "degreeRequiredOrPreferred":
          this.setState({
            isDegreeRequiredOrPreferredRequire: validator.isEmpty(inputValue),
          });
          break;
        default:
          return;
      }
    });
  };

  handleTabChange = (event, newValue, updateTabData) => {
    if (event) {
      event.preventDefault();
    }
    if (updateTabData) {
      this.handleTabData();
    }
    const { jobDetails } = this.props.jobs;
    const { dispatch } = this.props;
    const { selectedNavbarIndex, jobsPageTab } = this.props.shared;
    dispatch(jobsActions.setJobTab({ jobDetailTab: newValue }));
    if (this.state.value !== newValue) {
      this.setState({ value: newValue }, () => {
        switch (newValue) {
          case 0:
            dispatch(
              setTitle(
                `${`Jobs`} ${`-`} ${
                  selectedNavbarIndex === 1
                    ? "My Jobs"
                    : selectedNavbarIndex === 11
                    ? "Shared Jobs"
                    : "My Jobs"
                } ${`-`} ${
                  jobsPageTab === 0 ? "Open Positions" : "Closed Postions"
                } ${`-`} ${jobDetails?.title} ${`-`} ${
                  jobDetails?.recruiter?.first_name
                } ${jobDetails?.recruiter?.last_name} ${`-`} ${`Recommended`}`
              )
            );
            break;
          case 1:
            dispatch(
              setTitle(
                `${`Jobs`} ${`-`} ${
                  selectedNavbarIndex === 1
                    ? "My Jobs"
                    : selectedNavbarIndex === 11
                    ? "Shared Jobs"
                    : "My Jobs"
                } ${`-`} ${
                  jobsPageTab === 0 ? "Open Positions" : "Closed Postions"
                } ${`-`} ${jobDetails?.title} ${`-`} ${
                  jobDetails?.recruiter?.first_name
                } ${jobDetails?.recruiter?.last_name} ${`-`} ${`Pending`}`
              )
            );
            break;
          case 2:
            dispatch(
              setTitle(
                `${`Jobs`} ${`-`} ${
                  selectedNavbarIndex === 1
                    ? "My Jobs"
                    : selectedNavbarIndex === 11
                    ? "Shared Jobs"
                    : "My Jobs"
                } ${`-`} ${
                  jobsPageTab === 0 ? "Open Positions" : "Closed Postions"
                } ${`-`} ${jobDetails?.title} ${`-`} ${
                  jobDetails?.recruiter?.first_name
                } ${jobDetails?.recruiter?.last_name} ${`-`} ${`Matched`}`
              )
            );
            break;
          case 3:
            dispatch(
              setTitle(
                `${`Jobs`} ${`-`} ${
                  selectedNavbarIndex === 1
                    ? "My Jobs"
                    : selectedNavbarIndex === 11
                    ? "Shared Jobs"
                    : "My Jobs"
                } ${`-`} ${
                  jobsPageTab === 0 ? "Open Positions" : "Closed Postions"
                } ${`-`}  ${jobDetails?.title} ${`-`} ${
                  jobDetails?.recruiter?.first_name
                } ${
                  jobDetails?.recruiter?.last_name
                } ${`-`} ${`Removed from queue`}`
              )
            );
            break;
          default:
            break;
        }
      });
    }
  };

  handleCloseEdit = (event) => {
    event.preventDefault();
    event.stopPropagation();
    this.setState((prevState) => ({ open: !prevState }));
  };

  handleClose = (event) => {
    event.preventDefault();
    event.stopPropagation();
    this.setState((prevState) => ({ openPreview: !prevState }));
  };

  getAllCollaborators = (jobId) => {
    const { dispatch } = this.props;
    dispatch(jobsActions.getCollaboratorRecruiters(jobId));
  };

  handleAddCollaboratorsClick = (jobDetails) => {
    if (
      this.props.jobs?.jobDetails?.actioners?.owner?.id ===
        this.props?.authentication?.recruiterStatusDetails?.recruiter_id ||
      this.checkRecruiterPermission() === 2
    ) {
      this.getAllCollaborators(jobDetails?.id);
    } else {
      toast.dark("Only job owner or editor can add collaborators");
    }
  };

  handleCloseCollaboratorPopup = (event) => {
    if (event) {
      event.preventDefault();
    }
    collaboratorIds = [];
    this.setState({ openAddCollaboratorsPopup: false }, () => {
      this.setState({
        fields: [
          {
            collaboratorValue: "",
            accessLevelValue: "",
            isCollaboratorRequired: false,
            isAccessLevelRequired: false,
            error: false,
          },
        ],
      });
    });
  };

  handleTransferOwnershipClick = () => {
    console.log("Transfer Ownership - this feature is yet to be developed");
  };

  handleChange = (i, event) => {
    const values = [...this.state.fields];
    const inputValue = event.target.value;
    switch (event.target.name) {
      case "collaborator":
        if (collaboratorIds.includes(inputValue)) {
          toast.dark("This recruiter is already selected as a collaborator");
          values[i].collaboratorValue = "";
          values[i].error = true;
          return;
        } else {
          values[i].collaboratorValue = inputValue;
          values[i].error = false;
        }
        values[i].isCollaboratorRequired = validator.isEmpty(inputValue);
        collaboratorIds[i] = values[i].collaboratorValue;
        break;
      case "accessLevel":
        values[i].accessLevelValue = inputValue;
        values[i].isAccessLevelRequired = validator.isEmpty(inputValue);
        break;
      default:
        break;
    }
    this.setState({ fields: values });
  };

  handleAddCollaborator = () => {
    const values = [...this.state.fields];
    values.push({
      collaboratorValue: "",
      accessLevelValue: "",
      isCollaboratorRequired: false,
      isAccessLevelRequired: false,
      error: false,
    });
    this.setState({ fields: values });
  };

  handleRemoveCollaborator(i) {
    const values = [...this.state.fields];
    if (collaboratorIds.includes(values[i].collaboratorValue)) {
      let foundIndex = collaboratorIds.indexOf(values[i].collaboratorValue);
      collaboratorIds.splice(foundIndex, 1);
    }
    values.splice(i, 1);
    this.setState({ fields: values });
  }

  handleSubmit = (event) => {
    event.preventDefault();
    let checkArray = [];
    for (let item in this.state.fields) {
      if (
        this.state.fields[item].collaboratorValue === "" ||
        this.state.fields[item].collaboratorValue === null ||
        this.state.fields[item].accessLevelValue === "" ||
        this.state.fields[item].accessLevelValue === null
      ) {
        const value = [...this.state.fields];
        value[item].isCollaboratorRequired = validator.isEmpty(
          this.state.fields[item].collaboratorValue
        );
        value[item].isAccessLevelRequired = validator.isEmpty(
          this.state.fields[item].accessLevelValue
        );
        this.setState({
          isValidFormData: false,
          fields: value,
        });
      } else {
        this.setState({ isValidFormData: true });
        let payloadObject = {
          recruiter_id: trim(this.state.fields[item].collaboratorValue),
          permission: trim(this.state.fields[item].accessLevelValue),
        };
        checkArray.push(payloadObject);
      }
    }
    if (this.state.fields?.length === checkArray?.length) {
      const { dispatch } = this.props;
      let payload = {
        job_id: this.props?.match?.params?.id,
        collaborators: checkArray,
      };
      dispatch(jobsActions.addCollaborators(payload));
      this.handleCloseCollaboratorPopup();
    } else {
      toast.dark("Please fill up all the details with correct information");
      return;
    }
  };

  setSkills = (value) => {
    if (value !== null && value !== undefined && value?.length > 0) {
      this.setState({
        skillsReceived: value,
        skills: value,
        isSkillsRequire: false,
      });
    } else {
      this.setState({ skillsReceived: [], skills: [], isSkillsRequire: true });
    }
  };

  highlightFunctionName = () => {
    let functionNameTextField = document.getElementById("editJobFunctionName");
    if (functionNameTextField) {
      functionNameTextField.classList.add("fade-in-field");
    }
  };

  highlightOtherFunctionName = () => {
    let otherFunctionNameTextField = document.getElementById(
      "editJobOtherFunctionName"
    );
    if (otherFunctionNameTextField) {
      otherFunctionNameTextField.classList.add("fade-in-field");
    }
  };

  removeFieldHighlight = () => {
    let functionNameTextField = document.getElementById("editJobFunctionName");
    if (functionNameTextField) {
      functionNameTextField.classList.remove("fade-in-field");
    }
  };

  setJobTitle = (value) => {
    if (value !== null && value !== undefined) {
      this.setState({
        jobTitle: value,
        jobTitleValue: value._source.Title,
        isJobTitleRequire: false,
      });
      if (
        !this.props.authentication?.constants?.function?.includes(
          value._source.Function
        )
      ) {
        this.setState({
          functionName: "Other",
          otherFunctionName: value._source.Function,
        });
      } else {
        this.setState({
          functionName: value._source.Function,
          otherFunctionName: null,
        });
      }
      this.highlightFunctionName();
    } else {
      this.setState({
        jobTitle: value,
        jobTitleValue: "",
        isJobTitleRequire: true,
      });
      this.removeFieldHighlight();
    }
  };

  setReportsToTitle = (value) => {
    if (value !== null && value !== undefined) {
      this.setState({
        reportsToTitle: value,
        reportsToTitleValue: value._source.Title,
        isReportsToRequire: false,
      });
    } else {
      this.setState({
        reportsToTitle: value,
        reportsToTitleValue: "",
        isReportsToRequire: true,
      });
    }
  };

  setDegree = (value) => {
    if (value !== null && value !== undefined) {
      this.setState({
        degree: value,
        degreeValue: value._source.Degree,
        isDegreeRequire: false,
      });
    } else {
      this.setState({ degree: value, degreeValue: "", isDegreeRequire: true });
    }
  };

  setCertifications = (value) => {
    if (value !== null && value !== undefined && value?.length > 0) {
      this.setState(
        {
          certificationsReceived: value,
          certifications: value?._source?.Certificate,
        },
        () => {
          let certificationsData = this.state.certificationsReceived?.map(
            (value) => ({ _source: value._source })
          );
          this.setState({ certificationSelected: certificationsData });
        }
      );
    } else {
      this.setState({
        certificationsReceived: [],
        certifications: "",
        certificationSelected: [],
      });
    }
  };

  handleViewJob = (jobDetails) => {
    this.setEditorStates(
      jobDetails?.about_us,
      jobDetails?.about_role,
      jobDetails?.benefits_and_perks,
      jobDetails?.additional_responsibility,
      jobDetails?.candidate_value_addition,
      jobDetails?.absolute_must_have
    );
    this.setState(
      {
        openPreview: true,
        jobTitle: jobDetails?.title,
        alternativeTiltes: jobDetails.alternate_titles
          ? jobDetails.alternate_titles
          : [],
        alternativeIndustry: jobDetails.alternate_industries
          ? jobDetails.alternate_industries
          : [],
        jobTitleValue: jobDetails?.title,
        jobLevel: jobDetails?.level,
        reportsTo: jobDetails?.reports_to_name,
        industry: jobDetails?.industry,
        functionName: jobDetails?.function,
        otherFunctionName: jobDetails?.function_name,
        locationData: jobDetails?.location,
        location: jobDetails?.location,
        remote: jobDetails?.remote_or_onsite,
        travelLevel: jobDetails?.travel_requirements,
        skills: jobDetails?.skills,
        skillsList: this.state.skills,
        aboutUs: jobDetails.about_us,
        additionResponsibilitesState: jobDetails?.additional_responsibility,
        willSuccessfullState: jobDetails.candidate_value_addition,
        absolueMustHaveState: jobDetails?.absolute_must_have,
        benefitsAndPerksState: jobDetails.benefits_and_perks,
        jobDescription: jobDetails?.about_job
          ? jobDetails?.about_job
          : jobDetails?.about_role,
        howSoonToHire: jobDetails.notice_period,
        reportsToTitle: jobDetails?.reports_to,
        reportsToTitleValue: jobDetails?.reports_to,
        minimumExperience: jobDetails?.minimum_years_of_experience,
        degree: jobDetails?.degree,
        degreeValue: jobDetails?.degree,
        majorValue: jobDetails?.major
          ? { _source: { Major: jobDetails?.major } }
          : "",
        degreeRequiredOrPreferred: jobDetails.degree_req_pref || "",
        certifications: jobDetails.certifications,
        benefitsAndPerks:
          jobDetails?.benefits_and_perks === "<p></p>"
            ? null
            : jobDetails?.benefits_and_perks,
        salary_config: jobDetails?.salary_config,
        startupStage: jobDetails?.startup_stage,
        startupSize: jobDetails?.startup_size,
        teamSize: jobDetails?.team_size,
        role: jobDetails?.existing_role,
        sampleCompany: jobDetails?.sample_companies,
        confidenNormalValue: jobDetails?.job_type,
        customIndustryValue:
          jobDetails?.industry === "Other"
            ? jobDetails?.other_industry_text === null
              ? null
              : jobDetails?.other_industry_text
            : null,
        customIndustryRequired:
          jobDetails?.industry === "Other"
            ? jobDetails?.other_industry_text === null
              ? true
              : false
            : false,
        openCustomIndustryField:
          jobDetails?.industry === "Other" ? true : false,
      },
      () => this.state.benefitsAndPerks
    );

    let salary = jobDetails?.minimum_base_salary?.replace(/[^0-9.-]+/g, "");
    switch (jobDetails?.salary_config) {
      case 0:
        this.setState({
          salary_type: "Exclude salary",
          minimumBaseSalary: null,
        });
        break;
      case 1:
        this.setState({
          salary_type: "Candidate matching only",
          minimumBaseSalary: salary,
        });
        break;
      case 2:
        this.setState({
          salary_type: "Share with candidates",
          minimumBaseSalary: salary,
        });
        break;
      default:
        this.setState({
          salary_type: "Exclude salary",
          minimumBaseSalary: null,
        });
        break;
    }
  };

  handleEditClick = (jobDetails) => {
    this.handleEditJob(jobDetails);
    this.setState({ openPreview: false });
  };

  handleEditJob = (jobDetails) => {
    //remove Other from Industry options
    let constants = this.props.authentication?.constants;
    // to prevent deep copy using slice
    IndustryOptions = constants?.industry.slice();
    for (var i = 0; i < IndustryOptions.length; i++) {
      if (IndustryOptions[i] === "Other") {
        IndustryOptions.splice(i, 1);
      }
    }

    this.setEditorStates(
      jobDetails?.about_us,
      jobDetails?.about_role,
      jobDetails?.benefits_and_perks,
      jobDetails?.additional_responsibility,
      jobDetails?.candidate_value_addition,
      jobDetails?.absolute_must_have
    );
    if (
      this.props.jobs?.jobDetails?.actioners?.owner?.id ===
        this.props?.authentication?.recruiterStatusDetails?.recruiter_id ||
      this.checkRecruiterPermission() === 2
    ) {
      let address =
        jobDetails?.location?.city !== null ? jobDetails?.location : null;
      if (address !== undefined && address !== null) {
        secureLocalStorage.setItem(
          "locationDetails",
          JSON.stringify(jobDetails?.location)
        );
        this.setState({ locationFromAPi: jobDetails?.location });
      } else {
        this.setState({ locationFromAPi: null });
      }
      let skillsReceivedList = [];
      let certificationsReceivedList = [];
      for (let skill in jobDetails?.skills) {
        skillsReceivedList.push(jobDetails?.skills[skill]);
      }

      for (let certification in jobDetails?.certifications) {
        certificationsReceivedList.push({
          _source: {
            Certificate: jobDetails.certifications[certification],
          },
        });
      }
      if (this.props?.jobs?.jobDetails?.sample_companies) {
        let values = this.props?.jobs?.jobDetails?.sample_companies;
        companyNamesArray = values.map((value) => {
          return {
            name: value.company_name,
            logo: value.logo,
            domain: value.domain,
          };
        });
      } else {
        companyNamesArray = [];
      }
      this.setState(
        {
          skillsReceived: skillsReceivedList,
          skillsReceivedOld: skillsReceivedList,
          certificationsReceived: certificationsReceivedList,
          certificationsReceivedOld: certificationsReceivedList,
          certificationSelected: certificationsReceivedList,
        },
        () => {
          this.setState(
            {
              open: true,
              backdrop: false,
              alternativeTiltes: jobDetails.alternate_titles
                ? jobDetails.alternate_titles
                : [],
              alternativeIndustry: jobDetails.alternate_industries
                ? jobDetails.alternate_industries
                : [],
              jobTitle: jobDetails?.title,
              reportsToTitleValue: jobDetails.reports_to,
              jobTitleValue: jobDetails?.title,
              jobLevel: jobDetails?.level,
              reportsToTitle: jobDetails?.reports_to,
              reportsTo: jobDetails.reports_to_name,
              industry: jobDetails?.industry,
              functionName: jobDetails?.function,
              otherFunctionName: jobDetails?.function_name
                ? jobDetails?.function_name
                : null,
              locationData: jobDetails?.location,
              location: jobDetails?.location,
              remote: jobDetails?.remote_or_onsite,
              travelLevel: jobDetails?.travel_requirements,
              skills: jobDetails?.skills,
              skillsList: this.state.skills,
              aboutUs: jobDetails?.about_us,
              jobDescription: jobDetails?.about_job
                ? jobDetails?.about_job
                : jobDetails?.about_role,
              willSuccessfullState: jobDetails?.candidate_value_addition
                ? jobDetails?.candidate_value_addition
                : "",
              additionResponsibilitesState:
                jobDetails?.additional_responsibility
                  ? jobDetails?.additional_responsibility
                  : "",
              absolueMustHaveState: jobDetails?.absolute_must_have
                ? jobDetails?.absolute_must_have
                : "",
              howSoonToHire: jobDetails?.notice_period,
              confidenNormalValue: jobDetails?.job_type,
              minimumExperience: jobDetails?.minimum_years_of_experience,
              degree: jobDetails?.degree,
              majorValue: jobDetails?.major
                ? { _source: { Major: jobDetails.major } }
                : null,
              majorValueSelected: jobDetails?.major
                ? { _source: { Major: jobDetails?.major } }
                : null,
              degreeRequiredOrPreferred: jobDetails?.degree_req_pref || null,
              certifications: jobDetails?.certifications,
              certificationsList: this.state.certifications,
              benefitsAndPerks: jobDetails?.benefits_and_perks
                ? jobDetails?.benefits_and_perks
                : null,
              role: jobDetails?.existing_role,
              isJobTitleRequire: false,
              isJobLevelRequire: false,
              isReportsToRequire: false,
              isHiringManagerNameRequire: false,
              isIndustryRequire: false,
              isFunctionRequire: false,
              isLocationRequire: false,
              isYearOfExpRequire: false,
              isSalaryRequired: false,
              isDegreeRequire: false,
              isCertificationsRequire: false,
              isRemoteOnSiteRequire: false,
              isTravelRequirementRequire: false,
              isAboutUsRequire: false,
              isPositionDescriptionRequire: false,
              isAdditionResponsibilitesRequire: false,
              isWillSuccessfullCandidateBringRequired: false,
              isAbosultMustRequire: false,
              isSkillsRequire: false,
              isSoonLookingToHireRequire: false,
              isBenefitAndPerkRequire: false,
              isDegreeRequiredOrPreferredRequire: false,
              salary_config: jobDetails?.salary_config,
              companyNamesArray: companyNamesArray,
              receivedCompanies: companyNamesArray,
              teamSize:
                jobDetails?.team_size !== null ? jobDetails?.team_size : [],
              startupSize:
                jobDetails?.startup_size !== null
                  ? jobDetails?.startup_size
                  : [],
              startupStage:
                jobDetails?.startup_stage !== null
                  ? jobDetails?.startup_stage
                  : [],
              customIndustryValue:
                jobDetails?.industry === "Other"
                  ? jobDetails?.other_industry_text === null
                    ? null
                    : jobDetails?.other_industry_text
                  : null,
              customIndustryRequired:
                jobDetails?.industry === "Other"
                  ? jobDetails?.other_industry_text === null
                    ? true
                    : false
                  : false,
              openCustomIndustryField:
                jobDetails?.industry === "Other" ? true : false,
            },
            () => {
              this.setState({
                majorValueRecived: this.state.majorValueSelected,
                startupStageReceived: this.state.startupStage,
                teamSizeReceived: this.state.teamSize,
                startupSizeReceived: this.state.startupSize,
                isLocationRequire: this.state.locationData ? true : false,
              });
            }
          );
        }
      );
      let salary = jobDetails?.minimum_base_salary?.replace(/[^0-9.-]+/g, "");
      this.setState({ baseSalary: salary ? salary : null });
      switch (jobDetails?.salary_config) {
        case 0:
          this.setState({
            salary_type: "Exclude salary",
            minimumBaseSalary: null,
          });
          break;
        case 1:
          this.setState({
            salary_type: "Candidate matching only",
            minimumBaseSalary: salary,
          });
          break;
        case 2:
          this.setState({
            salary_type: "Share with candidates",
            minimumBaseSalary: salary,
          });
          break;
        default:
          this.setState({ salary_type: "", minimumBaseSalary: null });
          break;
      }
    } else {
      toast.dark("Only job owner or editor can edit this job");
    }
  };

  handleSaveClick = (jobId) => {
    const { dispatch } = this.props;
    let skillsData = [];
    let certificationsData = [];
    let selectedSalaryConfig = null;
    let alternativeTitles = this.state.alternativeTiltes.map((value) => {
      if (typeof value === "object") {
        return value?._source?.Title;
      } else {
        return value;
      }
    });

    // remove empty strings from Alternative titles array and Alternative industry array
    let alternativeTitlesPayload = alternativeTitles?.filter(
      (value) => value?.trim() !== ""
    );
    let alternativeIndustryPayload = this.state.alternativeIndustry?.filter(
      (value) => value?.trim() !== ""
    );

    this.setState(
      {
        isValidAboutUs: this.state.editorStateAboutUs
          ? validator.matches(
              this.state.editorStateAboutUs
                .getCurrentContent()
                .getPlainText("\u0001"),
              /^.{25,5000}$/s
            )
          : true,
        // isValidPositionDescription: this.state.editorStateJobDescription ? validator.matches(this.state.editorStateJobDescription.getCurrentContent().getPlainText('\u0001'), /^.{25,5000}$/s) : true,
        isValidBenefitsAndPerks: this.state.editorStateBenefitsAndPerks
          ? validator.matches(
              this.state.editorStateBenefitsAndPerks
                .getCurrentContent()
                .getPlainText("\u0001"),
              /^.{0,5000}$/s
            )
          : true,
        isValidAdditionresponsibilities: this.state.additionresponsibilities
          ? validator.matches(
              this.state.additionresponsibilities
                .getCurrentContent()
                .getPlainText("\u0001"),
              /^.{25,5000}$/s
            )
          : true,
        isValidAbsoluteMustHaves: this.state.editorAbsoluteMustHave
          ? validator.matches(
              this.state.editorAbsoluteMustHave
                .getCurrentContent()
                .getPlainText("\u0001"),
              /^.{25,5000}$/s
            )
          : true,
        isValidCandidateWillBring: this.state.editorcandidateWillBring
          ? validator.matches(
              this.state.editorcandidateWillBring
                .getCurrentContent()
                .getPlainText("\u0001"),
              /^.{25,5000}$/s
            )
          : true,
        isEmptyAboutUs: convertToRaw(
          this.state.editorStateAboutUs.getCurrentContent()
        ).blocks.every((b) => b.text.trimStart().length < 25),
      },
      () => {
        switch (this.state.salary_type) {
          case "Exclude salary":
            selectedSalaryConfig = 0;
            break;
          case "Candidate matching only":
            selectedSalaryConfig = 1;
            break;
          case "Share with candidates":
            selectedSalaryConfig = 2;
            break;
          default:
            break;
        }

        this.setState(
          {
            locationData: JSON.parse(
              secureLocalStorage.getItem("locationDetails")
            ),
            salary_config: selectedSalaryConfig,
          },
          () => {
            const {
              jobTitleValue,
              jobLevel,
              reportsToTitleValue,
              reportsTo,
              industry,
              functionName,
              otherFunctionName,
              locationData,
              remote,
              travelLevel,
              aboutUsWithHTML,
              jobDescriptionWithHTML,
              editorStateJobDescription,
              minimumExperience,
              minimumBaseSalary,
              degree,
              certifications,
              benefitsAndPerksWithHTML,
              editorStateBenefitsAndPerks,
              isValidPositionDescription,
              majorValue,
              degreeRequiredOrPreferred,
              salary_config,
              absolueMustHavehHTML,
              additionResponsibilitesWithHTML,
              willSuccessfullCandidateBringHTML,
              role,
              howSoonToHire,
              teamSize,
              startupSize,
              startupStage,
              sampleCompanyValues,
              editorStateAboutUs,
              isValidAboutUs,
              isEmptyAboutUs,
              customIndustryValue,
              customIndustryRequired,
              isAbosultMustRequire,
              isValidAbsoluteMustHaves,
            } = this.state;
            for (let item in this.state.skillsReceived) {
              skillsData.push(this.state?.skillsReceived?.[item]);
            }

            if (typeof this.state.certifications === "object") {
              for (let certification in certifications) {
                certificationsData.push(
                  certifications[certification]?._source?.Certificate ||
                    certifications[certification]
                );
              }
            } else {
              for (let item in this.state.certificationsReceived) {
                certificationsData.push(
                  this.state?.certificationsReceived?.[item]?._source
                    .Certificate
                );
              }
            }

            if (sampleCompanyValues) {
              PayloadSampleComArray = sampleCompanyValues.map((value) => {
                return {
                  company_name: value.name,
                  logo: value.logo,
                  domain: value.domain,
                };
              });
            } else {
              let array = companyNamesArray.map((value) => {
                return {
                  company_name: value.name,
                  logo: value.logo,
                  domain: value.domain,
                };
              });
              PayloadSampleComArray = array;
            }
            if (
              jobTitleValue &&
              jobDescriptionWithHTML &&
              editorStateJobDescription
                .getCurrentContent()
                .getPlainText("\u0001").length > 0 &&
              editorStateAboutUs.getCurrentContent().getPlainText("\u0001")
                .length > 0 &&
              isValidAboutUs &&
              !isEmptyAboutUs &&
              isValidPositionDescription &&
              !customIndustryRequired &&
              !isAbosultMustRequire &&
              isValidAbsoluteMustHaves
            ) {
              let price = Intl.NumberFormat("en-US");
              let salary =
                minimumBaseSalary === null || minimumBaseSalary === ""
                  ? null
                  : `${`$`}${price.format(minimumBaseSalary)}`;
              this.setState({
                location:
                  this.state?.locationData?.city +
                  ", " +
                  this.state?.locationData?.state +
                  ", " +
                  this.state?.locationData?.country,
              });
              let payload = {
                title: jobTitleValue ? trim(jobTitleValue) : null,
                location: locationData,
                remote_or_onsite:
                  remote !== "" || remote !== null ? trim(remote) : null,
                about_role: trim(jobDescriptionWithHTML),
                candidate_value_addition: trim(
                  willSuccessfullCandidateBringHTML
                ),
                additional_responsibility: trim(
                  additionResponsibilitesWithHTML
                ),
                absolute_must_have: trim(absolueMustHavehHTML),
                recruiter_id: JSON.parse(
                  secureLocalStorage.getItem("recruiterId")
                ),
                function: functionName ? trim(functionName) : null,
                function_name: otherFunctionName
                  ? trim(otherFunctionName)
                  : null,
                notice_period: howSoonToHire ? trim(howSoonToHire) : null,
                industry: industry ? trim(industry) : null,
                level: jobLevel ? trim(jobLevel) : null,
                travel_requirements: travelLevel ? trim(travelLevel) : null,
                reports_to: reportsToTitleValue
                  ? trim(reportsToTitleValue)
                  : null,
                reports_to_name: reportsTo ? trim(reportsTo) : null,
                about_us: trim(aboutUsWithHTML),
                benefits_and_perks:
                  editorStateBenefitsAndPerks
                    .getCurrentContent()
                    .getPlainText("\u0001").length > 0 &&
                  benefitsAndPerksWithHTML !== "<p></p>"
                    ? trim(benefitsAndPerksWithHTML)
                    : null,
                degree: degree ? trim(degree) : null,
                major: majorValue?._source?.Major
                  ? trim(majorValue?._source?.Major)
                  : null,
                degree_req_pref: degreeRequiredOrPreferred
                  ? trim(degreeRequiredOrPreferred)
                  : null,
                certifications:
                  certificationsData.length > 0 ? certificationsData : null,
                minimum_base_salary: salary ? trim(salary) : null,
                minimum_years_of_experience: minimumExperience
                  ? trim(minimumExperience)
                  : null,
                skills: skillsData.length > 0 ? skillsData : null,
                salary_config:
                  salary_config === "" ||
                  salary_config === null ||
                  salary_config === undefined
                    ? null
                    : salary_config,
                startup_size: startupSize.length === 0 ? null : startupSize,
                startup_stage: startupStage.length === 0 ? null : startupStage,
                team_size: teamSize.length === 0 ? null : teamSize,
                existing_role: role,
                sample_companies:
                  PayloadSampleComArray?.length === 0
                    ? null
                    : PayloadSampleComArray,
                job_type: this.state.confidenNormalValue,
                other_industry_text:
                  customIndustryValue === null ? null : customIndustryValue,
                alternate_titles:
                  alternativeTitlesPayload?.length > 0
                    ? alternativeTitlesPayload
                    : null,
                alternate_industries:
                  alternativeIndustryPayload?.length > 0
                    ? alternativeIndustryPayload
                    : null,
              };
              dispatch(jobsActions.updateJob(payload, jobId));
              this.setState({ open: false, openPreview: false });
            } else {
              toast.dark("Please fill up all the details");
              this.setState({
                isJobTitleRequire: validator.isEmpty(jobTitleValue),
                isPositionDescriptionRequire: editorStateJobDescription
                  ? validator.isEmpty(
                      editorStateJobDescription
                        .getCurrentContent()
                        .getPlainText("\u0001")
                    )
                  : true,
                isAboutUsRequire: editorStateAboutUs
                  ? validator.isEmpty(
                      editorStateAboutUs
                        .getCurrentContent()
                        .getPlainText("\u0001")
                    )
                  : true,
                // customIndustryRequired: (customIndustryValue === null || customIndustryValue === undefined || customIndustryValue === '') ? true : false,
              });
            }
          }
        );
      }
    );
  };

  handleCloseJob = (closeComment, jobId) => {
    if (this.state.closeComment === "") {
      toast.dark("Please fill up required field");
    } else {
      if (this.state.isValidCloseComment) {
        const { dispatch } = this.props;
        let payload = {
          job_close_comment: closeComment,
        };
        dispatch(jobsActions.closeJob(payload, jobId));
        this.handleCloseFormPopupClose();
      } else {
        toast.dark("Invalid reason");
      }
    }
  };

  handleCloseConfirmPopupOpen = () => {
    if (
      this.props.jobs?.jobDetails?.actioners?.owner?.id ===
        this.props?.authentication?.recruiterStatusDetails?.recruiter_id ||
      this.checkRecruiterPermission() === 2
    ) {
      this.setState({ openCloseConfirm: true });
    } else {
      toast.dark("Only job owner or editor can close this job");
    }
  };

  handleCloseConfirmPopupClose = () => {
    this.setState({ openCloseConfirm: false });
  };

  handleCloseFormPopupClose = () => {
    this.setState({ openCloseFormPopup: false });
  };

  openCloseFormDialog = () => {
    this.handleCloseConfirmPopupClose();
    this.setState({ openCloseFormPopup: true });
  };

  handleDislikeCandidateRecommendation = (jobDetails, candidate) => {
    if (
      this.props.jobs?.jobDetails?.actioners?.owner?.id ===
        this.props?.authentication?.recruiterStatusDetails?.recruiter_id ||
      this.checkRecruiterPermission() === 2
    ) {
      if (
        jobDetails?.job_status === "Closed" ||
        jobDetails?.job_status === "closed" ||
        jobDetails?.job_status === "Close" ||
        jobDetails?.job_status === "close"
      ) {
        toast.dark("This job is already closed");
        return;
      }
      this.closeExpandedLiteProfile();
      this.setState({ dislikePopupOpen: true });
      this.setState({
        jobId: jobDetails.id,
        recommendationId: candidate?.recommendation_id,
      });
    } else {
      toast.dark(
        "Only job owner or editor can dislike a candidate recommendation"
      );
    }
  };

  handleLikeCandidateRecommendation = (jobDetails, candidate) => {
    if (
      this.props.jobs?.jobDetails?.actioners?.owner?.id ===
        this.props?.authentication?.recruiterStatusDetails?.recruiter_id ||
      this.checkRecruiterPermission() === 2
    ) {
      if (
        jobDetails?.job_status === "Closed" ||
        jobDetails?.job_status === "closed" ||
        jobDetails?.job_status === "Close" ||
        jobDetails?.job_status === "close"
      ) {
        toast.dark("This job is already closed");
        return;
      }
      this.closeExpandedLiteProfile();
      this.setState({ likePopupOpen: true });
      this.setState({
        jobId: jobDetails?.id,
        recommendationId: candidate?.recommendation_id,
      });
    } else {
      toast.dark("Only job owner or editor can like candidate recommendation");
    }
  };

  handleArchive = () => {
    const { dispatch } = this.props;
    const { otherArchiveReason, archiveReason } = this.state;
    if (archiveReason !== "") {
      if (archiveReason === "Other" && !otherArchiveReason) {
        toast.dark("Please enter the reason for archiving");
      } else {
        if (this.state.isValidOtherArchiveReason) {
          let payload = {
            is_passed: false,
            reason_for_rejection: archiveReason, //(archiveReason === 'Other') ? otherArchiveReason : archiveReason,
            other_feedback:
              archiveReason === "Other" && otherArchiveReason === ""
                ? null
                : archiveReason !== "Other"
                ? null
                : otherArchiveReason,
          };
          dispatch(
            jobsActions.dislikeCandidateRecommendation(
              this.state.jobId,
              this.state.recommendationId,
              payload
            )
          );
          this.setState({ dislikePopupOpen: false });
        } else {
          toast.dark("Invalid reason");
        }
      }
    } else {
      toast.dark("Please choose one option");
    }
  };

  handleLike = () => {
    const { dispatch } = this.props;
    let payload = {
      is_passed: true,
      like_comment: this.state.likeMessage,
    };
    dispatch(
      jobsActions.likeCandidateRecommendation(
        this.state.jobId,
        this.state.recommendationId,
        payload
      )
    );
    this.handleCloseLikePopup();
  };

  handleCloseArchivePopup = () => {
    this.setState({
      dislikePopupOpen: false,
      archiveReason: "",
      otherArchiveReason: "",
    });
  };

  handleCloseLikePopup = () => {
    this.setState({ likePopupOpen: false, likeMessage: "" });
  };

  goToJobs = () => {
    window.location.pathname.match("^/jobs/my-jobs.*$")
      ? history.push("/jobs/my-jobs")
      : history.push("/jobs/shared-jobs");
  };

  handleFullProfileClick = (
    jobDetails,
    candidateId,
    recommendationId,
    isMatchedArchived
  ) => {
    if (
      jobDetails?.job_status === "Closed" ||
      jobDetails?.job_status === "closed" ||
      jobDetails?.job_status === "Close" ||
      jobDetails?.job_status === "close"
    ) {
      toast.dark("This job is already closed");
      return;
    }
    secureLocalStorage.setItem("fromJobsTab", true);
    const { dispatch } = this.props;
    if (
      this.props.jobs?.jobDetails?.actioners?.owner?.id ===
        this.props?.authentication?.recruiterStatusDetails?.recruiter_id ||
      this.checkRecruiterPermission() === 2
    ) {
      dispatch(sharedActions.setAccessPermission(true));
    } else {
      dispatch(sharedActions.setAccessPermission(false));
    }
    history.push(
      `/members/${candidateId}/${recommendationId}/${isMatchedArchived}`
    );
  };

  viewHistory = (recommendation_id, event) => {
    event.preventDefault();
    const { dispatch } = this.props;
    dispatch(membersActions.viewHistory(recommendation_id));
    this.closeExpandedLiteProfile();
    this.setState({ historyPopupOpen: true });
  };

  closeHistoryPopup = () => {
    this.setState({ historyPopupOpen: false });
  };

  updateLocationRequireState = (value) => {
    this.setState({ isLocationRequire: value });
  };

  saveCustomJobTitle = (event) => {
    event.preventDefault();
    event.stopPropagation();
    this.setState({
      jobTitleValue: trim(this.state.customJobTitle),
      customJobTitlePopUp: false,
      isJobTitleRequire: false,
      functionName: "Other",
      otherFunctionName: null,
    });
  };

  saveCustomCertificationTitle = (event) => {
    event.preventDefault();
    event.stopPropagation();
    const found = this.state.certificationsReceived.some(
      (el) =>
        el._source.Certificate.toLowerCase() ===
        this.state.customCertificationTitle.toLowerCase()
    );
    if (!found) {
      const certificateList = [...this.state.certificationsReceived];
      certificateList.push({
        _source: { Certificate: trim(this.state.customCertificationTitle) },
      });
      this.setState({
        certificationsReceived: certificateList,
        customCertificationTitlePopUp: false,
      });
    } else {
      this.setState({ customCertificationTitle: "" });
      toast.dark("Certification already added.");
    }
  };

  createChannel = (jobDetails, candidateId, event) => {
    // if(jobDetails.job_status === 'Closed' || jobDetails.job_status === 'closed' || jobDetails.job_status === 'Close' || jobDetails.job_status === 'close'){
    //     toast.dark('This job is already closed');
    //     return;
    // }
    if (
      this.props.jobs?.jobDetails?.actioners?.owner?.id ===
        this.props?.authentication?.recruiterStatusDetails?.recruiter_id ||
      this.checkRecruiterPermission() === 2
    ) {
      const { dispatch } = this.props;
      dispatch(messagesActions.createChannel(candidateId, event));
    } else {
      toast.dark("Only job owner or editor can send messages to the candidate");
    }
  };

  setMajor = (value) => {
    if (value !== null && value !== undefined) {
      this.setState({ majorValue: value }, () => {
        this.setState({
          majorValueSelected: { _source: { Major: value?._source.Major } },
        });
      }); //isMajorRequire: false
    } else {
      this.setState({ majorValue: null, majorValueSelected: null }); //isMajorRequire: true
    }
  };

  getMajors = (major) => {
    this.setState({ majorLoading: true });
    const { dispatch } = this.props;
    dispatch(jobsActions.getMajorList(major));
    dispatch(loading(false));
  };

  saveCustomMajor = (event) => {
    event.preventDefault();
    event.stopPropagation();
    this.setState({
      majorValue: { _source: { Major: trim(this.state.customMajorValue) } },
      customMajorPopUp: false,
    });
  };

  handleDeleteCollaboratorClick = (collaborator) => {
    if (
      this.props?.authentication?.recruiterStatusDetails?.recruiter_id ===
      collaborator?.id
    ) {
      toast.dark(
        "You can not remove yourself from collaborators. Please contact the job owner."
      );
      return;
    }
    if (this.checkJobStatus() === 1) {
      return;
    }
    if (
      this.props.jobs?.jobDetails?.actioners?.owner?.id ===
        this.props?.authentication?.recruiterStatusDetails?.recruiter_id ||
      this.checkRecruiterPermission() === 2
    ) {
      this.setState({
        openConfirmRemoveCollaboratorPopup: true,
        deleteCollaboratorPermissionId: collaborator.permission_id,
      });
    } else {
      toast.dark("Only job owner or editor can delete the collaborator");
    }
  };

  handleDeleteCollaborator = (collaboratorPermissionId, jobId) => {
    const { dispatch } = this.props;
    dispatch(jobsActions.deleteCollaborator(collaboratorPermissionId, jobId));
    this.closeConfirmDeleteCollaboratorPopup();
  };

  closeConfirmDeleteCollaboratorPopup = () => {
    this.setState({ openConfirmRemoveCollaboratorPopup: false });
  };

  openCandidateCommentsPopup = (candidate) => {
    const { dispatch } = this.props;
    this.setState({
      selectedRecommendationId: candidate?.recommendation_id,
      comment: "",
    });
    dispatch(
      jobsActions.getCandidateComments(
        candidate?.recommendation_id,
        this.props.match.params.id
      )
    );
    this.closeExpandedLiteProfile();
    this.setState({ openCandidateCommentsPopup: true });
  };

  closeCandidateCommentsPopup = () => {
    this.setState({ openCandidateCommentsPopup: false });
    this.closeConfirmDeleteCommentPopper();
  };

  handleCommentChange = (event) => {
    this.setState({ comment: event.target.value });
  };

  checkJobStatus = () => {
    switch (this.props.jobs?.jobDetails?.job_status) {
      case "Close":
      case "Closed":
      case "close":
      case "closed":
        toast.dark("This job is already closed");
        return 1;
      default:
        return 0;
    }
  };

  handleSubmitComment = () => {
    if (this.checkJobStatus() === 1) {
      this.closeCandidateCommentsPopup();
      this.closeConfirmDeleteCommentPopper();
      return;
    }
    if (
      this.state.comment.trim().length === 0 ||
      this.state.comment === undefined ||
      this.state.comment === null
    ) {
      this.setState({ comment: "" });
      toast.dark("Please add a note to submit");
      return;
    }
    const { dispatch } = this.props;
    let payload = {
      comment: this.state.comment,
      recommendation: this.state.selectedRecommendationId,
    };
    dispatch(
      jobsActions.addCandidateComment(payload, this.props.match.params.id)
    );
    this.closeCandidateCommentsPopup();
    this.closeConfirmDeleteCommentPopper();
  };

  handleDeleteComment = () => {
    const { dispatch } = this.props;
    dispatch(
      jobsActions.deleteCandidateComment(
        this.state.selectedComment,
        this.props.match.params.id,
        this.props?.match.params?.recommendationId
      )
    );
    this.closeCandidateCommentsPopup();
    this.closeConfirmDeleteCommentPopper();
  };

  openConfirmDeleteCommentPopper = (commentId, event) => {
    if (this.checkJobStatus() === 1) {
      this.closeCandidateCommentsPopup();
      this.closeConfirmDeleteCommentPopper();
      return;
    }
    this.setState({
      openPopper: true,
      anchorEl: event.currentTarget,
      selectedComment: commentId,
    });
  };

  handleArrowRef = (node) => {
    this.setState({
      arrowRef: node,
    });
  };

  closeConfirmDeleteCommentPopper = () => {
    this.setState({ openPopper: false });
  };

  checkRecruiterPermission = () => {
    for (let collaborator in this.props.jobs?.jobDetails?.actioners
      ?.collaborators) {
      if (
        this.props.jobs?.jobDetails?.actioners?.collaborators[collaborator]
          .id ===
        this.props.authentication?.recruiterStatusDetails?.recruiter_id
      ) {
        return this.props.jobs?.jobDetails?.actioners?.collaborators[
          collaborator
        ].permission;
      }
    }
  };

  openExpandedLiteProfile = (tab, index, candidate) => {
    this.setState({
      expandedView: true,
      selectedTab: tab,
      selectedIndex: index,
      selectedCandidate: candidate,
    });
  };

  closeExpandedLiteProfile = () => {
    this.setState({ expandedView: false });
  };

  toggleView = (isBoardView) => {
    const { dispatch } = this.props;
    dispatch(jobsActions.setView(isBoardView ? "Compare" : "List"));
  };

  handleExpCompany = debounce((event) => {
    this.setState({ sampleCompanyValueInput: event.target.value });
    if (this.state.sampleCompanyValueInput.length === 0) {
      this.setState({ companyOptions: [], companyLoading: false });
    } else if (event.target.value.length < 100) {
      this.getCompanyNames(event.target.value);
    } else {
      this.setState({ companyLoading: false });
    }
  }, 500);

  getCompanyNames = (title) => {
    const { dispatch } = this.props;
    dispatch(jobsActions.getCompanyNames(title));
    dispatch(loading(false));
  };

  /**
   *
   * @param {Array} experienceArray array of experiences of a single company
   * if any date was null; date will be picked fom available dates
   * @returns {Array} [startYear, endYear] start(oldest) and end(latest) year of all experiences <br>
   * @returns "Present" if date is current date
   * @returns as is if equals "Present"
   * @returns "-" if could not find any date
   */
  getStartAndEndYrFromExperiences = (experienceArray) => {
    let startYr, endYr;
    const presentString = "Present";
    const ddmmyyyy = "DD/MM/YYYY";
    for (let index = 0; index < experienceArray.length; index++) {
      const experience = experienceArray[index];
      const startDate = experience.starts_at;
      const endDate = experience.ends_at;
      if (!startDate) {
        startYr = startYr ?? "-";
        if (!endDate) {
          endYr = endYr ?? "-";
          return [startYr, endYr];
        }
      }
      if (!endDate) {
        endYr = endYr ?? "-";
      }
      const startMoment = moment(startDate);
      const endMoment = moment(endDate);
      switch (moment(new Date()).format(ddmmyyyy)) {
        case startMoment.format(ddmmyyyy):
          return [presentString, presentString];
        case endMoment.format(ddmmyyyy):
          endYr = presentString;
          break;
        default:
          break;
      }
      if (startDate === presentString) {
        if (endDate === presentString) {
          return [startDate, endDate];
        }
        startYr = startDate;
      }
      if (endDate === presentString) {
        endYr = endDate;
      }
      let currStartYr = startMoment.format("YYYY");
      let currEndYr = endMoment.format("YYYY");
      startYr = startYr ?? currStartYr;
      // find lowest year
      if (startYr !== presentString && startDate && currStartYr < startYr) {
        startYr = currStartYr;
      }
      endYr = endYr ?? currEndYr;
      // find latest year
      if (endYr !== presentString && endDate && currEndYr > endYr) {
        endYr = currEndYr;
      }
    }
    return [startYr ?? "-", endYr ?? "-"];
  };

  closeScheduleInterview = () => {
    const { dispatch } = this.props;
    dispatch(jobsActions.schedulingLinkValues({}));
    this.setState({
      openScheduleInterview: false,
      scheduleInterviewType: null,
    });
  };

  sendScheduleSchedulingLinkCandidate = (scheduleInterviewType, rec_id) => {
    this.setState({
      openScheduleInterview: true,
      scheduleInterviewType: scheduleInterviewType,
      shareProfile_rec_id: rec_id,
      expandedView: false,
    });
  };

  handlePreview = (values, note) => {
    const { dispatch } = this.props;
    dispatch(jobsActions.schedulingLinkValues(values));
    if (this.state.scheduleInterviewType === "email to coordinator") {
      let payload = {
        option: this.state.scheduleInterviewType,
        first_name: values?.firstname,
        last_name: values?.lastname,
        email: values?.emailTocoordinator,
        is_preview: true,
      };

      this.setState(
        { openScheduleInterview: false, openEmailPreview: true },
        () => {
          dispatch(
            membersActions.postSchedulingEmailToCoordinator(
              this.state?.shareProfile_rec_id,
              payload
            )
          );
        }
      );
    } else {
      let payload = {
        note: note,
        is_preview: true,
      };
      this.setState(
        { openScheduleInterview: false, openEmailPreview: true },
        () => {
          dispatch(
            membersActions.postSchedulingEmailToCandidate(
              this.state?.shareProfile_rec_id,
              payload
            )
          );
        }
      );
    }
  };

  editPreview = () => {
    this.setState({ openScheduleInterview: true, openEmailPreview: false });
  };

  onClosePreview = () => {
    const { dispatch } = this.props;
    dispatch(jobsActions.schedulingLinkValues({}));
    this.setState({ openEmailPreview: false });
  };

  showWarning = () => {
    toast.dark(
      "This operation is not allowed when you are logged in as SucceedSmart admin"
    );
  };

  sendEmail = () => {
    const { dispatch } = this.props;
    let sendEmailValues = this.props.jobs.schedulingLinkValues;
    if (this.state.scheduleInterviewType === "email to coordinator") {
      let payload = {
        option: this.state.scheduleInterviewType,
        first_name: sendEmailValues?.firstname,
        last_name: sendEmailValues?.lastname,
        email: sendEmailValues?.emailTocoordinator,
        is_preview: false,
      };
      dispatch(
        membersActions.postSchedulingEmailToCoordinator(
          this.state?.shareProfile_rec_id,
          payload
        )
      );
    } else {
      let payload = {
        note: draftToHtml(
          convertToRaw(sendEmailValues.editorNotesState.getCurrentContent())
        ),
        is_preview: false,
      };
      dispatch(
        membersActions.postSchedulingEmailToCandidate(
          this.state?.shareProfile_rec_id,
          payload
        )
      );
    }
    dispatch(jobsActions.schedulingLinkValues({}));
    this.setState({ openEmailPreview: false });
  };

  handleCloseShareProfile = () => {
    this.setState({ openShareProfile: false });
  };

  handleTabData = () => {
    this.setState({ tabState: true });
  };

  render() {
    const { classes } = this.props;
    const { isNavbarVisible } = this.props.shared;
    const {
      editorStateAboutUs,
      editorStateJobDescription,
      additionresponsibilities,
      editorStateBenefitsAndPerks,
      editorcandidateWillBring,
      editorAbsoluteMustHave,
    } = this.state;
    let jobDetails = this.props?.jobs?.jobDetails;
    let recommended = this.props?.jobs?.recommended;
    let pending = this.props?.jobs?.pending;
    let matched = this.props?.jobs?.matched;
    let archived = this.props?.jobs?.archived;
    let constants = this.props.authentication?.constants;
    let collaboratorRecruiters = this.props?.jobs?.collaboratorRecruiters;
    let historyData = this.props?.members?.historyData?.data;
    let candidateComments = this.props?.jobs?.candidateComments?.comments;
    let data = [];
    let marks = [];
    let sampleCorporations = [];

    jobDetails?.sample_companies?.map((value) => {
      sampleCorporations.push(value?.company_name);
      return sampleCorporations;
    });
    let salaryValueFormat = Intl.NumberFormat("en-US");
    if (constants?.minimum_base_salary) {
      constants?.minimum_base_salary?.map((ele) =>
        data.push({ value: ele.replace(/[^0-9.-]+/g, ""), label: ele })
      );
    }
    marks = data.map((ele) => ele);
    let minValue = parseInt(marks[0]?.value);
    let maxValue = parseInt(marks[marks?.length - 1]?.value);

    // For recommended 'MATCHED BASED ON' text 'Recommended' tab
    let recommendedMatchBasedOnData = [];
    let matchBasedOnTextData = [];
    let matchBasedOnText = "";
    for (let i = 0; i < recommended?.interested_candidate?.length; i++) {
      recommendedMatchBasedOnData.push([]);
    }
    for (let member in recommended?.interested_candidate) {
      for (let match in recommended?.interested_candidate[member]
        ?.match_based_on) {
        if (
          recommended?.interested_candidate[member]?.match_based_on[match] !==
            null &&
          recommended?.interested_candidate[member]?.match_based_on[match] !==
            "" &&
          recommended?.interested_candidate[member]?.match_based_on[match] !==
            undefined
        ) {
          recommendedMatchBasedOnData[member].push(
            recommended?.interested_candidate[member]?.match_based_on[match]
          );
        } else {
          delete recommended?.interested_candidate[member]?.match_based_on[
            match
          ];
        }
        // recommendedMatchBasedOnData[member].push(recommended?.interested_candidate[member]?.match_based_on[match]);
      }
    }
    for (let index in recommendedMatchBasedOnData) {
      for (let ind in recommendedMatchBasedOnData[index]) {
        matchBasedOnText +=
          "<div><ul><li>" +
          recommendedMatchBasedOnData[index][ind] +
          "</li></ul></div><br>";
      }
      matchBasedOnTextData.push(matchBasedOnText);
      matchBasedOnText = "";
    }

    // For pending 'MATCHED BASED ON' text 'Pending' tab
    let pendingMatchBasedOnData = [];
    let matchBasedOnTextDataForPending = [];
    let matchBasedOnTextForPending = "";
    for (let i = 0; i < pending?.interested_candidate?.length; i++) {
      pendingMatchBasedOnData.push([]);
    }
    for (let member in pending?.interested_candidate) {
      for (let match in pending?.interested_candidate[member]?.match_based_on) {
        if (
          pending?.interested_candidate[member]?.match_based_on[match] !==
            null &&
          pending?.interested_candidate[member]?.match_based_on[match] !== "" &&
          pending?.interested_candidate[member]?.match_based_on[match] !==
            undefined
        ) {
          pendingMatchBasedOnData[member].push(
            pending?.interested_candidate[member]?.match_based_on[match]
          );
        } else {
          delete pending?.interested_candidate[member]?.match_based_on[match];
        }
        // pendingMatchBasedOnData[member].push(pending?.interested_candidate[member]?.match_based_on[match]);
      }
    }
    for (let index in pendingMatchBasedOnData) {
      for (let ind in pendingMatchBasedOnData[index]) {
        matchBasedOnTextForPending +=
          "<div><ul><li>" +
          pendingMatchBasedOnData[index][ind] +
          "</li></ul></div><br>";
      }
      matchBasedOnTextDataForPending.push(matchBasedOnTextForPending);
      matchBasedOnTextForPending = "";
    }

    // For matched 'MATCHED BASED ON' text 'Matched' tab
    let matchBasedOnDataForMatched = [];
    let matchBasedOnTextDataForMatched = [];
    let matchBasedOnTextForMatched = "";
    for (let i = 0; i < matched?.interested_candidate?.length; i++) {
      matchBasedOnDataForMatched.push([]);
    }
    for (let member in matched?.interested_candidate) {
      for (let match in matched?.interested_candidate[member]?.match_based_on) {
        if (
          matched?.interested_candidate[member]?.match_based_on[match] !==
            null &&
          matched?.interested_candidate[member]?.match_based_on[match] !== "" &&
          matched?.interested_candidate[member]?.match_based_on[match] !==
            undefined
        ) {
          matchBasedOnDataForMatched[member].push(
            matched?.interested_candidate[member]?.match_based_on[match]
          );
        } else {
          delete matched?.interested_candidate[member]?.match_based_on[match];
        }
        // matchBasedOnDataForMatched[member].push(matched?.interested_candidate[member]?.match_based_on[match]);
      }
    }
    for (let index in matchBasedOnDataForMatched) {
      for (let ind in matchBasedOnDataForMatched[index]) {
        matchBasedOnTextForMatched +=
          "<div><ul><li>" +
          matchBasedOnDataForMatched[index][ind] +
          "</li></ul></div><br>";
      }
      matchBasedOnTextDataForMatched.push(matchBasedOnTextForMatched);
      matchBasedOnTextForMatched = "";
    }

    // For archived 'MATCHED BASED ON' text 'Removed from queue' tab
    let matchBasedOnDataForArchived = [];
    let matchBasedOnTextDataForArchived = [];
    let matchBasedOnTextForArchived = "";
    for (let i = 0; i < archived?.interested_candidate?.length; i++) {
      matchBasedOnDataForArchived.push([]);
    }
    for (let member in archived?.interested_candidate) {
      for (let match in archived?.interested_candidate[member]
        ?.match_based_on) {
        if (
          archived?.interested_candidate[member]?.match_based_on[match] !==
            null &&
          archived?.interested_candidate[member]?.match_based_on[match] !==
            "" &&
          archived?.interested_candidate[member]?.match_based_on[match] !==
            undefined
        ) {
          matchBasedOnDataForArchived[member].push(
            archived?.interested_candidate[member]?.match_based_on[match]
          );
        } else {
          delete archived?.interested_candidate[member]?.match_based_on[match];
        }
      }
    }
    for (let index in matchBasedOnDataForArchived) {
      for (let ind in matchBasedOnDataForArchived[index]) {
        matchBasedOnTextForArchived +=
          "<div><ul><li>" +
          matchBasedOnDataForArchived[index][ind] +
          "</li></ul></div><br>";
      }
      matchBasedOnTextDataForArchived.push(matchBasedOnTextForArchived);
      matchBasedOnTextForArchived = "";
    }

    // For recommended 'ADDITIONAL SUMMRY POINTS' text 'Recommended' tab
    let recommendedAdditionalInfoData = [];
    let additionalInfoTextData = [];
    let additionalInfoText = "";
    for (let i = 0; i < recommended?.interested_candidate?.length; i++) {
      recommendedAdditionalInfoData.push([]);
    }
    for (let member in recommended?.interested_candidate) {
      for (let match in recommended?.interested_candidate[member]
        .additional_info) {
        if (
          recommended?.interested_candidate[member].additional_info[match] !==
            null &&
          recommended?.interested_candidate[member].additional_info[match] !==
            "" &&
          recommended?.interested_candidate[member].additional_info[match] !==
            undefined
        ) {
          recommendedAdditionalInfoData[member].push(
            recommended?.interested_candidate[member].additional_info[match]
          );
        } else {
          delete recommended?.interested_candidate[member].additional_info[
            match
          ];
        }
      }
    }
    for (let index in recommendedAdditionalInfoData) {
      for (let ind in recommendedAdditionalInfoData[index]) {
        additionalInfoText +=
          "<div><ul><li>" +
          recommendedAdditionalInfoData[index][ind] +
          "</li></ul></div><br>";
      }
      additionalInfoTextData.push(additionalInfoText);
      additionalInfoText = "";
    }

    // For pending 'ADDITIONAL SUMMARY POINTS' text 'Pending' tab
    let pendingAdditionalInfoData = [];
    let additionalInfoTextDataForPending = [];
    let additionalInfoTextForPending = "";
    for (let i = 0; i < pending?.interested_candidate?.length; i++) {
      pendingAdditionalInfoData.push([]);
    }
    for (let member in pending?.interested_candidate) {
      for (let match in pending?.interested_candidate[member].additional_info) {
        if (
          pending?.interested_candidate[member].additional_info[match] !==
            null &&
          pending?.interested_candidate[member].additional_info[match] !== "" &&
          pending?.interested_candidate[member].additional_info[match] !==
            undefined
        ) {
          pendingAdditionalInfoData[member].push(
            pending?.interested_candidate[member].additional_info[match]
          );
        } else {
          delete pending?.interested_candidate[member].additional_info[match];
        }
      }
    }
    for (let index in pendingAdditionalInfoData) {
      for (let ind in pendingAdditionalInfoData[index]) {
        additionalInfoTextForPending +=
          "<div><ul><li>" +
          pendingAdditionalInfoData[index][ind] +
          "</li></ul></div><br>";
      }
      additionalInfoTextDataForPending.push(additionalInfoTextForPending);
      additionalInfoTextForPending = "";
    }

    // For matched 'ADDITIONAL SUMMARY POINTS' text 'Matched' tab
    let additionalInfoDataForMatched = [];
    let additionalInfoTextDataForMatched = [];
    let additionalInfoTextForMatched = "";
    for (let i = 0; i < matched?.interested_candidate?.length; i++) {
      additionalInfoDataForMatched.push([]);
    }
    for (let member in matched?.interested_candidate) {
      for (let match in matched?.interested_candidate[member].additional_info) {
        if (
          matched?.interested_candidate[member].additional_info[match] !==
            null &&
          matched?.interested_candidate[member].additional_info[match] !== "" &&
          matched?.interested_candidate[member].additional_info[match] !==
            undefined
        ) {
          additionalInfoDataForMatched[member].push(
            matched?.interested_candidate[member].additional_info[match]
          );
        } else {
          delete matched?.interested_candidate[member].additional_info[match];
        }
      }
    }
    for (let index in additionalInfoDataForMatched) {
      for (let ind in additionalInfoDataForMatched[index]) {
        additionalInfoTextForMatched +=
          "<div><ul><li>" +
          additionalInfoDataForMatched[index][ind] +
          "</li></ul></div><br>";
      }
      additionalInfoTextDataForMatched.push(additionalInfoTextForMatched);
      additionalInfoTextForMatched = "";
    }

    // For archived 'ADDITIONAL SUMMARY POINTS' text 'Removed from queue' tab
    let additionalInfoDataForArchived = [];
    let additionalInfoTextDataForArchived = [];
    let additionalInfoTextForArchived = "";
    for (let i = 0; i < archived?.interested_candidate?.length; i++) {
      additionalInfoDataForArchived.push([]);
    }
    for (let member in archived?.interested_candidate) {
      for (let match in archived?.interested_candidate[member]
        .additional_info) {
        if (
          archived?.interested_candidate[member].additional_info[match] !==
            null &&
          archived?.interested_candidate[member].additional_info[match] !==
            "" &&
          archived?.interested_candidate[member].additional_info[match] !==
            undefined
        ) {
          additionalInfoDataForArchived[member].push(
            archived?.interested_candidate[member].additional_info[match]
          );
        } else {
          delete archived?.interested_candidate[member].additional_info[match];
        }
      }
    }
    for (let index in additionalInfoDataForArchived) {
      for (let ind in additionalInfoDataForArchived[index]) {
        additionalInfoTextForArchived +=
          "<div><ul><li>" +
          additionalInfoDataForArchived[index][ind] +
          "</li></ul></div><br>";
      }
      additionalInfoTextDataForArchived.push(additionalInfoTextForArchived);
      additionalInfoTextForArchived = "";
    }

    // For recommended 'View More' text 'Recommended' tab
    let recommendedAccomplishmentsData = [];
    let accomplishmentsTextData = [];
    let accomplishmentsText = "";
    for (let i = 0; i < recommended?.interested_candidate?.length; i++) {
      recommendedAccomplishmentsData.push([]);
    }
    for (let member in recommended?.interested_candidate) {
      for (let accomplishment in recommended?.interested_candidate[member]
        .accomplishments) {
        recommendedAccomplishmentsData[member].push(
          recommended?.interested_candidate[member].accomplishments[
            accomplishment
          ]
        );
      }
    }
    for (let index in recommendedAccomplishmentsData) {
      for (let ind in recommendedAccomplishmentsData[index]) {
        accomplishmentsText +=
          "<ul><li>" +
          recommendedAccomplishmentsData[index][ind] +
          "</li></ul>";
      }
      accomplishmentsTextData.push(accomplishmentsText);
      accomplishmentsText = "";
    }

    // For pending 'View More' text 'Pending' tab
    let pendingAccomplishmentsData = [];
    let accomplishmentsTextDataForPending = [];
    let accomplishmentsTextForPending = "";
    for (let j = 0; j < pending?.interested_candidate?.length; j++) {
      pendingAccomplishmentsData.push([]);
    }
    for (let pendingMember in pending?.interested_candidate) {
      for (let pendingAccomplishment in pending?.interested_candidate[
        pendingMember
      ].accomplishments) {
        pendingAccomplishmentsData[pendingMember].push(
          pending?.interested_candidate[pendingMember].accomplishments[
            pendingAccomplishment
          ]
        );
      }
    }
    for (let index in pendingAccomplishmentsData) {
      for (let ind in pendingAccomplishmentsData[index]) {
        accomplishmentsTextForPending +=
          "<ul><li>" + pendingAccomplishmentsData[index][ind] + "</li></ul>";
      }
      accomplishmentsTextDataForPending.push(accomplishmentsTextForPending);
      accomplishmentsTextForPending = "";
    }

    // For matched 'View More' text 'Matched' tab
    let matchedAccomplishmentsData = [];
    let accomplishmentsTextDataForMatched = [];
    let accomplishmentsTextForMatched = "";
    for (let k = 0; k < matched?.interested_candidate?.length; k++) {
      matchedAccomplishmentsData.push([]);
    }
    for (let matchedMember in matched?.interested_candidate) {
      for (let matchedAccomplishment in matched?.interested_candidate[
        matchedMember
      ].accomplishments) {
        matchedAccomplishmentsData[matchedMember].push(
          matched?.interested_candidate[matchedMember].accomplishments[
            matchedAccomplishment
          ]
        );
      }
    }
    for (let index in matchedAccomplishmentsData) {
      for (let ind in matchedAccomplishmentsData[index]) {
        accomplishmentsTextForMatched +=
          "<ul><li>" + matchedAccomplishmentsData[index][ind] + "</li></ul>";
      }
      accomplishmentsTextDataForMatched.push(accomplishmentsTextForMatched);
      accomplishmentsTextForMatched = "";
    }

    // For archived 'View More' text 'Removed from queue' tab
    let archivedAccomplishmentsData = [];
    let accomplishmentsTextDataForArchived = [];
    let accomplishmentsTextForArchived = "";
    for (var l = 0; l < archived?.interested_candidate?.length; l++) {
      archivedAccomplishmentsData.push([]);
    }
    for (let archivedMember in archived?.interested_candidate) {
      for (let archivedAccomplishment in archived?.interested_candidate[
        archivedMember
      ].accomplishments) {
        archivedAccomplishmentsData[archivedMember].push(
          archived?.interested_candidate[archivedMember].accomplishments[
            archivedAccomplishment
          ]
        );
      }
    }
    for (let index in archivedAccomplishmentsData) {
      for (let ind in archivedAccomplishmentsData[index]) {
        accomplishmentsTextForArchived +=
          "<ul><li>" + archivedAccomplishmentsData[index][ind] + "</li></ul>";
      }
      accomplishmentsTextDataForArchived.push(accomplishmentsTextForArchived);
      accomplishmentsTextForArchived = "";
    }

    // For recommended 'Skills' tab
    let recommendedSkillsData = [];
    for (let candidate in recommended?.interested_candidate) {
      recommendedSkillsData.push([]);
      for (let skill in recommended?.interested_candidate[candidate].skills) {
        recommendedSkillsData[candidate].push(
          recommended?.interested_candidate[candidate].skills[skill]
        );
      }
    }

    // For pending 'Skills' tab
    let skillsDataForPending = [];
    for (let candidatePending in pending?.interested_candidate) {
      skillsDataForPending.push([]);
      for (let skillForPending in pending?.interested_candidate[
        candidatePending
      ].skills) {
        skillsDataForPending[candidatePending].push(
          pending?.interested_candidate[candidatePending].skills[
            skillForPending
          ]
        );
      }
    }

    // For matched 'Skills' tab
    let skillsDataForMatched = [];
    for (let candidateMatched in matched?.interested_candidate) {
      skillsDataForMatched.push([]);
      for (let skillForMatched in matched?.interested_candidate[
        candidateMatched
      ].skills) {
        skillsDataForMatched[candidateMatched].push(
          matched?.interested_candidate[candidateMatched].skills[
            skillForMatched
          ]
        );
      }
    }

    // For archived 'Skills' tab
    let skillsDataForArchived = [];
    for (let candidateArchived in archived?.interested_candidate) {
      skillsDataForArchived.push([]);
      for (let skillForArchived in archived?.interested_candidate[
        candidateArchived
      ].skills) {
        skillsDataForArchived[candidateArchived].push(
          archived?.interested_candidate[candidateArchived].skills[
            skillForArchived
          ]
        );
      }
    }
    return (
      <div
        className={
          isNavbarVisible || isNavbarVisible === undefined
            ? classes.root
            : classes.customRoot
        }
      >
        <div className={classes.appFrame}>
          <Breadcrumbs
            aria-label="breadcrumb"
            className={classes.breadcrumbCustom}
          >
            {/* <Link color="inherit" className={classes.breadcrumbLink} onClick={event => {event.preventDefault(); this.goToJobs()}} >
                            Jobs
                        </Link> */}
            <Link
              color="inherit"
              className={classes.breadcrumbLink}
              onClick={(event) => {
                event.preventDefault();
                this.goToJobs();
              }}
            >
              {window?.location.pathname.match("^/jobs/my-jobs.*$")
                ? "Jobs > My Jobs"
                : "Jobs > Shared Jobs"}
            </Link>
            <Link
              color="inherit"
              style={{ textDecoration: "none", cursor: "not-allowed" }}
            >
              {jobDetails?.title}
            </Link>
            <Link
              color="textPrimary"
              className={classes.breadcrumbLink}
              aria-current="page"
            >
              {this?.props.jobs.allUpdateHeaderCard === "All"
                ? "All Candidates"
                : this?.props.jobs.allUpdateHeaderCard === "1"
                ? "Talent Pipeline"
                : this?.props.jobs.allUpdateHeaderCard === "2"
                ? "Shortlisted Candidates"
                : this?.props.jobs.allUpdateHeaderCard === "3"
                ? "Candidates in Conversation"
                : "Removed from Queue"}
            </Link>
          </Breadcrumbs>
          <Grid container>
            <div
              className={classNames(
                classes.displayFlex,
                classes.moreIcon,
                classes.largeMarginLeft
              )}
            >
              <Accordion
                square
                expanded={this.state.expanded === "panel1"}
                onChange={this.handleAccordionChange("panel1")}
              >
                <AccordionSummary
                  aria-controls="panel1d-content"
                  id="jobDetailsAccordion"
                  expandIcon={
                    <IconButton
                      title="Expand/collapse detailed view"
                      style={{
                        backgroundColor: "#303030",
                        color: "#FFFFFF",
                        padding: 0,
                      }}
                    >
                      <ExpandMoreIcon />
                    </IconButton>
                  }
                  className={classes.widthFitContent}
                >
                  <div
                    className={classes.displayFlex}
                    style={{ alignItems: "center" }}
                  >
                    <Typography variant="h6" style={{ paddingBottom: "0px" }}>
                      <b>{jobDetails?.title}</b>
                    </Typography>
                    <Typography component="div">
                      {jobDetails?.job_status === "Closed" ||
                      jobDetails?.job_status === "closed" ||
                      jobDetails?.job_status === "Close" ||
                      jobDetails?.job_status === "close" ? (
                        <Chip
                          className={classes.customBadge}
                          label="Closed"
                          title="This job is closed"
                        />
                      ) : null}
                    </Typography>
                  </div>
                </AccordionSummary>
                <AccordionDetails>
                  <Typography
                    className={classNames(
                      classes.smallMarginRight,
                      classes.font14p,
                      classes.displayFlex
                    )}
                  >
                    <LocationOnIcon
                      className={classes.largeFont}
                      style={{ marginRight: "5px" }}
                    />
                    {(jobDetails?.location?.city
                      ? jobDetails?.location?.city
                      : "") +
                      (jobDetails?.location?.state ? ", " : "") +
                      (jobDetails?.location?.state
                        ? jobDetails?.location?.state
                        : "") +
                      (jobDetails?.location?.country ? ", " : "") +
                      (jobDetails?.location?.country
                        ? jobDetails?.location?.country
                        : "") +
                      (jobDetails?.location?.city ||
                      jobDetails?.location?.state ||
                      jobDetails?.location?.country
                        ? ""
                        : "-")}
                  </Typography>
                  <div
                    className={classes.customDisplayFlex}
                    style={{ marginTop: "10px", alignItems: "center" }}
                  >
                    <Typography className={classes.secondaryHeading}>
                      Job Owner:{" "}
                      <Chip
                        color="secondary"
                        size="small"
                        className={classes.customCollaboratorChip}
                        avatar={
                          <Avatar
                            alt={jobDetails?.actioners?.owner?.name}
                            src="/"
                            style={{
                              backgroundColor: "#303030",
                              color: "#FFFFFF",
                            }}
                          />
                        }
                        label={jobDetails?.actioners?.owner.name}
                      />
                    </Typography>{" "}
                    &nbsp; &nbsp;
                    <Typography className={classes.secondaryHeading}>
                      Collaborators:{" "}
                      {jobDetails?.actioners?.collaborators?.length > 0 ? (
                        jobDetails?.actioners?.collaborators?.map(
                          (collaborator, index) => (
                            <Chip
                              className={classes.customCollaboratorChip}
                              size="small"
                              color="secondary"
                              key={collaborator?.id || index}
                              avatar={
                                <Avatar
                                  alt={collaborator?.name}
                                  src="/"
                                  style={{
                                    backgroundColor: "#303030",
                                    color: "#FFFFFF",
                                  }}
                                />
                              }
                              label={collaborator?.name}
                              onDelete={() => {
                                this.handleDeleteCollaboratorClick(
                                  collaborator
                                );
                              }}
                            />
                          )
                        )
                      ) : (
                        <Typography
                          component="span"
                          className={classes.font14p}
                          color="textSecondary"
                        >
                          {"No collaborators added"}
                        </Typography>
                      )}
                    </Typography>
                  </div>
                  <Grid container spacing={1}>
                    <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                      <img
                        src={jobDetails?.company?.logo}
                        alt={jobDetails?.company?.company_name}
                        className={classNames(
                          classes.largeAvatar,
                          jobDetails?.company?.logo === ""
                            ? classes.noImageAvatar
                            : null
                        )}
                        style={{ objectFit: "contain" }}
                      ></img>
                      <Typography className={classes.verySmallTopMargin}>
                        {this.capitalize(jobDetails?.company?.company_name)}
                      </Typography>
                    </Grid>
                    <Grid
                      className={classes.verySmallTopMargin}
                      item
                      xs={12}
                      sm={12}
                      md={12}
                      lg={3}
                      xl={3}
                    >
                      <Typography color="textSecondary">Job Level</Typography>
                      <Typography className={classes.colorCustom}>
                        {jobDetails?.level || "-"}
                      </Typography>
                    </Grid>
                    <Grid
                      className={classes.verySmallTopMargin}
                      item
                      xs={12}
                      sm={12}
                      md={12}
                      lg={3}
                      xl={3}
                    >
                      <Typography color="textSecondary">Reports To</Typography>
                      <Typography className={classes.colorCustom}>
                        {jobDetails?.reports_to || "-"}
                      </Typography>
                    </Grid>
                    <Grid
                      className={classes.verySmallTopMargin}
                      item
                      xs={12}
                      sm={12}
                      md={12}
                      lg={3}
                      xl={3}
                    >
                      <Typography color="textSecondary">
                        Hiring Manager Name
                      </Typography>
                      <Typography className={classes.colorCustom}>
                        {jobDetails?.reports_to_name || "-"}
                      </Typography>
                    </Grid>
                    <Grid
                      className={classes.verySmallTopMargin}
                      item
                      xs={12}
                      sm={12}
                      md={12}
                      lg={3}
                      xl={3}
                    >
                      <Typography color="textSecondary">
                        Alternative Titles
                      </Typography>
                      <Typography className={classes.colorCustom}>
                        {jobDetails?.alternate_titles?.join(", ") || "-"}
                      </Typography>
                    </Grid>
                    <Grid
                      className={classes.verySmallTopMargin}
                      item
                      xs={12}
                      sm={12}
                      md={12}
                      lg={3}
                      xl={3}
                    >
                      <Typography color="textSecondary">Industry</Typography>
                      <Typography className={classes.colorCustom}>
                        {jobDetails?.industry === "Other"
                          ? jobDetails?.other_industry_text === null
                            ? "Other"
                            : jobDetails?.other_industry_text
                          : jobDetails?.industry
                          ? jobDetails?.industry
                          : "-"}
                      </Typography>
                    </Grid>
                    <Grid
                      className={classes.verySmallTopMargin}
                      item
                      xs={12}
                      sm={12}
                      md={12}
                      lg={3}
                      xl={3}
                    >
                      <Typography color="textSecondary">
                        Alternative Industries
                      </Typography>
                      <Typography className={classes.colorCustom}>
                        {jobDetails?.alternate_industries?.join(", ") || "-"}
                      </Typography>
                    </Grid>
                    <Grid
                      className={classes.verySmallTopMargin}
                      item
                      xs={12}
                      sm={12}
                      md={12}
                      lg={3}
                      xl={3}
                    >
                      <Typography color="textSecondary">Function</Typography>
                      <Typography className={classes.colorCustom}>
                        {jobDetails?.function === "Other"
                          ? jobDetails?.function +
                            " - " +
                            (jobDetails?.function_name
                              ? jobDetails?.function_name
                              : "-")
                          : jobDetails?.function || "-"}
                      </Typography>
                    </Grid>
                    <Grid
                      className={classes.verySmallTopMargin}
                      item
                      xs={12}
                      sm={12}
                      md={12}
                      lg={3}
                      xl={3}
                    >
                      <Typography color="textSecondary">
                        Company HQ Location
                      </Typography>
                      <Typography className={classes.colorCustom}>
                        {(jobDetails?.location?.city
                          ? jobDetails?.location?.city
                          : "") +
                          (jobDetails?.location?.state ? ", " : "") +
                          (jobDetails?.location?.state
                            ? jobDetails?.location?.state
                            : "") +
                          (jobDetails?.location?.country ? ", " : "") +
                          (jobDetails?.location?.country
                            ? jobDetails?.location?.country
                            : "") +
                          (jobDetails?.location?.city ||
                          jobDetails?.location?.state ||
                          jobDetails?.location?.country
                            ? ""
                            : "-")}
                      </Typography>
                    </Grid>
                    <Grid
                      className={classes.verySmallTopMargin}
                      item
                      xs={12}
                      sm={12}
                      md={12}
                      lg={3}
                      xl={3}
                    >
                      <Typography color="textSecondary">
                        Work Location
                      </Typography>
                      <Typography className={classes.colorCustom}>
                        {jobDetails?.remote_or_onsite || "-"}
                      </Typography>
                    </Grid>
                    <Grid
                      className={classes.verySmallTopMargin}
                      item
                      xs={12}
                      sm={12}
                      md={12}
                      lg={3}
                      xl={3}
                    >
                      <Typography color="textSecondary">
                        Travel Requirements
                      </Typography>
                      <Typography className={classes.colorCustom}>
                        {jobDetails?.travel_requirements || "-"}
                      </Typography>
                    </Grid>
                    <Grid
                      className={classes.verySmallTopMargin}
                      item
                      xs={12}
                      sm={12}
                      md={12}
                      lg={3}
                      xl={3}
                    >
                      <Typography color="textSecondary">
                        Company Size
                      </Typography>
                      <Typography className={classes.colorCustom}>
                        {jobDetails?.startup_size?.join(", ") || "-"}
                      </Typography>
                    </Grid>
                    <Grid
                      className={classes.verySmallTopMargin}
                      item
                      xs={12}
                      sm={12}
                      md={12}
                      lg={3}
                      xl={3}
                    >
                      <Typography color="textSecondary">
                        Company Type
                      </Typography>
                      <Typography className={classes.colorCustom}>
                        {jobDetails?.startup_stage?.join(", ") || "-"}
                      </Typography>
                    </Grid>
                    <Grid
                      className={classes.verySmallTopMargin}
                      item
                      xs={12}
                      sm={12}
                      md={12}
                      lg={3}
                      xl={3}
                    >
                      <Typography color="textSecondary">Team Size</Typography>
                      <Typography className={classes.colorCustom}>
                        {jobDetails?.team_size?.join(", ") || "-"}
                      </Typography>
                    </Grid>
                    <Grid
                      className={classes.verySmallTopMargin}
                      item
                      xs={12}
                      sm={12}
                      md={12}
                      lg={3}
                      xl={3}
                    >
                      <Typography color="textSecondary">
                        How soon do you want to fill this role?
                      </Typography>
                      <Typography className={classes.colorCustom}>
                        {jobDetails?.notice_period || "-"}
                      </Typography>
                    </Grid>

                    <Grid
                      className={classes.verySmallTopMargin}
                      item
                      xs={12}
                      sm={12}
                      md={12}
                      lg={3}
                      xl={3}
                    >
                      <Typography color="textSecondary">
                        New Role/Existing Role
                      </Typography>
                      <Typography className={classes.colorCustom}>
                        {jobDetails?.existing_role || "-"}
                      </Typography>
                    </Grid>
                    <Grid
                      className={classes.verySmallTopMargin}
                      item
                      xs={12}
                      sm={12}
                      md={12}
                      lg={3}
                      xl={3}
                    >
                      <Typography color="textSecondary">
                        Minimum Years of Experience
                      </Typography>
                      <Typography className={classes.colorCustom}>
                        {jobDetails?.minimum_years_of_experience || "-"}
                      </Typography>
                    </Grid>
                    <Grid
                      className={classes.verySmallTopMargin}
                      item
                      xs={12}
                      sm={12}
                      md={12}
                      lg={3}
                      xl={3}
                    >
                      <Typography color="textSecondary">Base Salary</Typography>
                      <Typography className={classes.colorCustom}>
                        {jobDetails?.minimum_base_salary === null &&
                        jobDetails?.salary_config === 0
                          ? "Exclude salary"
                          : jobDetails?.minimum_base_salary === null
                          ? "-"
                          : jobDetails?.salary_config === 1
                          ? `${
                              jobDetails?.minimum_base_salary
                            } ${`(Not visible to candidates)`}`
                          : jobDetails?.minimum_base_salary}
                      </Typography>
                    </Grid>
                    <Grid
                      className={classes.verySmallTopMargin}
                      item
                      xs={12}
                      sm={12}
                      md={12}
                      lg={3}
                      xl={3}
                    >
                      <Typography color="textSecondary">
                        Degree / Major
                      </Typography>
                      <Typography className={classes.colorCustom}>
                        {jobDetails?.degree ? jobDetails?.degree : "-"} /{" "}
                        {jobDetails?.major ? jobDetails?.major : "-"}
                        {jobDetails?.degree_req_pref
                          ? ` (${jobDetails?.degree_req_pref})`
                          : ""}
                      </Typography>
                    </Grid>
                    <Grid
                      className={classes.verySmallTopMargin}
                      item
                      xs={12}
                      sm={12}
                      md={12}
                      lg={3}
                      xl={3}
                      style={{ position: "relative" }}
                    >
                      <Typography color="textSecondary">
                        Professional Certifications
                      </Typography>
                      <Typography className={classes.colorCustom}>
                        <ViewMore>
                          {jobDetails?.certifications
                            ? jobDetails?.certifications?.join(", ") || "-"
                            : "-"}
                        </ViewMore>
                      </Typography>
                    </Grid>
                    <Grid
                      className={classes.verySmallTopMargin}
                      item
                      xs={12}
                      sm={12}
                      md={12}
                      lg={3}
                      xl={3}
                    >
                      <Typography color="textSecondary">
                        Confidential/Normal Role
                      </Typography>
                      <Typography className={classes.colorCustom}>
                        {jobDetails?.job_type || "-"}
                      </Typography>
                    </Grid>
                    <Grid
                      className={classes.verySmallTopMargin}
                      item
                      xs={12}
                      sm={12}
                      md={12}
                      lg={3}
                      xl={3}
                    >
                      <Typography color="textSecondary">
                        Hidden Fields
                      </Typography>
                      <Typography className={classes.colorCustom}>
                        Company Name, Industry, Job Location
                      </Typography>
                    </Grid>
                    <Grid
                      className={classes.verySmallTopMargin}
                      item
                      xs={12}
                      sm={12}
                      md={12}
                      lg={12}
                      xl={12}
                    >
                      <Typography color="textSecondary">
                        Sample Corporations
                      </Typography>
                      <Typography
                        component="span"
                        className={classes.colorCustom}
                      >
                        {sampleCorporations.length > 0
                          ? sampleCorporations.join(", ")
                          : "-"}
                      </Typography>
                    </Grid>
                    <Grid
                      className={classes.verySmallTopMargin}
                      item
                      xs={12}
                      sm={12}
                      md={12}
                      lg={12}
                      xl={12}
                    >
                      <Typography color="textSecondary">
                        Competencies
                      </Typography>
                      <Typography className={classes.colorCustom}>
                        {this.capitalize(jobDetails?.skills?.join(", ")) || "-"}
                      </Typography>
                    </Grid>
                    <Grid
                      className={classes.verySmallTopMargin}
                      item
                      xs={12}
                      sm={12}
                      md={12}
                      lg={12}
                      xl={12}
                    >
                      <Typography color="textSecondary">About Us</Typography>
                      <ViewMore>
                        {jobDetails?.about_us &&
                        jobDetails?.about_us !== "<p></p>"
                          ? jobDetails?.about_us
                          : "-"}
                      </ViewMore>
                    </Grid>

                    <Grid
                      className={classes.verySmallTopMargin}
                      item
                      xs={12}
                      sm={12}
                      md={12}
                      lg={12}
                      xl={12}
                    >
                      <Typography color="textSecondary">About Role</Typography>
                      <ViewMore>
                        {jobDetails?.about_role &&
                        jobDetails?.about_role !== "<p></p>"
                          ? jobDetails?.about_role
                          : "-"}
                      </ViewMore>
                    </Grid>
                    <Grid
                      className={classes.verySmallTopMargin}
                      item
                      xs={12}
                      sm={12}
                      md={12}
                      lg={12}
                      xl={12}
                    >
                      <Typography color="textSecondary">
                        Responsibilities
                      </Typography>
                      <ViewMore style={{ marginTop: "0px" }}>
                        {jobDetails?.additional_responsibility &&
                        jobDetails?.additional_responsibility !== "<p></p>"
                          ? jobDetails?.additional_responsibility
                          : "-"}
                      </ViewMore>
                    </Grid>
                    <Grid
                      className={classes.verySmallTopMargin}
                      item
                      xs={12}
                      sm={12}
                      md={12}
                      lg={12}
                      xl={12}
                    >
                      <Typography color="textSecondary">
                        Qualifications
                      </Typography>
                      <ViewMore>
                        {jobDetails?.candidate_value_addition &&
                        jobDetails?.candidate_value_addition !== "<p></p>"
                          ? jobDetails?.candidate_value_addition
                          : "-"}
                      </ViewMore>
                    </Grid>

                    <Grid
                      className={classes.verySmallTopMargin}
                      item
                      xs={12}
                      sm={12}
                      md={12}
                      lg={12}
                      xl={12}
                    >
                      <Typography color="textSecondary">
                        Must Have Requirements/Non-Negotiables
                      </Typography>
                      <ViewMore>
                        {jobDetails?.absolute_must_have &&
                        jobDetails?.absolute_must_have !== "<p></p>"
                          ? jobDetails?.absolute_must_have
                          : "-"}
                      </ViewMore>
                    </Grid>
                    {jobDetails?.benefits_and_perks &&
                    jobDetails?.benefits_and_perks !== "<p></p>" ? (
                      <>
                        <Grid
                          className={classes.verySmallTopMargin}
                          item
                          xs={12}
                          sm={12}
                          md={12}
                          lg={12}
                          xl={12}
                        >
                          <Typography color="textSecondary">
                            Executive Perks
                          </Typography>
                          <ViewMore>
                            {jobDetails?.benefits_and_perks || "-"}
                          </ViewMore>
                        </Grid>{" "}
                      </>
                    ) : (
                      ""
                    )}
                  </Grid>
                </AccordionDetails>
              </Accordion>
              <PopupState variant="popover" popupId="demo-popup-menu">
                {(popupState) => (
                  <React.Fragment>
                    <div
                      className={classNames(classes.maxHeight40P)}
                      {...bindTrigger(popupState)}
                    >
                      <Button
                        endIcon={<KeyboardArrowDownIcon />}
                        style={{
                          marginTop: "5px",
                          width: "8em",
                          background: "#000000",
                          color: "#FFFFFF",
                        }}
                        disableElevation
                        variant="outlined"
                        color="primary"
                        size="medium"
                      >
                        Actions
                      </Button>
                    </div>
                    <Menu
                      {...bindMenu(popupState)}
                      anchorOrigin={{
                        vertical: "top",
                        horizontal: "left",
                      }}
                      transformOrigin={{
                        vertical: "top",
                        horizontal: "right",
                      }}
                      style={{ marginTop: "29px" }}
                    >
                      <MenuItem
                        disabled={
                          jobDetails?.job_status === "Close" ||
                          jobDetails?.job_status === "Closed" ||
                          jobDetails?.job_status === "close" ||
                          jobDetails?.job_status === "closed"
                        }
                        onClick={() => {
                          popupState.close();
                          jobDetails?.job_status !== "Close" &&
                          jobDetails?.job_status !== "Closed" &&
                          jobDetails?.job_status !== "close" &&
                          jobDetails?.job_status !== "closed"
                            ? this.handleAddCollaboratorsClick(jobDetails)
                            : popupState.close();
                        }}
                      >
                        <ListItemText primary="Add Collaborators" />
                        <ListItemIcon className={classes.justifyContentFlexEnd}>
                          <GroupAddIcon color="primary" fontSize="small" />
                        </ListItemIcon>
                      </MenuItem>
                      {/* <MenuItem disabled={jobDetails?.job_status === 'Close' || jobDetails?.job_status === 'Closed' || jobDetails?.job_status === 'close' || jobDetails?.job_status === 'closed' || true} onClick={() => { popupState.close(); (jobDetails?.job_status !== 'Close' && jobDetails?.job_status !== 'Closed' && jobDetails?.job_status !== 'close' && jobDetails?.job_status !== 'closed') ? this.handleTransferOwnershipClick() : popupState.close()}}>
                                        <ListItemText primary="Transfer Ownership" />
                                        <ListItemIcon className={classes.justifyContentFlexEnd}>
                                            <KeyboardTabIcon color="primary" fontSize="small" />
                                        </ListItemIcon>
                                    </MenuItem> */}
                      <MenuItem
                        onClick={() => {
                          popupState.close();
                          this.handleViewJob(jobDetails);
                        }}
                      >
                        <ListItemText primary="View Job" />
                        <ListItemIcon className={classes.justifyContentFlexEnd}>
                          <VisibilityIcon color="primary" fontSize="small" />
                        </ListItemIcon>
                      </MenuItem>
                      <MenuItem
                        disabled={
                          jobDetails?.job_status === "Close" ||
                          jobDetails?.job_status === "Closed" ||
                          jobDetails?.job_status === "close" ||
                          jobDetails?.job_status === "closed"
                        }
                        onClick={() => {
                          popupState.close();
                          jobDetails?.job_status !== "Close" &&
                          jobDetails?.job_status !== "Closed" &&
                          jobDetails?.job_status !== "close" &&
                          jobDetails?.job_status !== "closed"
                            ? this.handleEditJob(jobDetails)
                            : popupState.close();
                        }}
                      >
                        <ListItemText primary="Edit Job" />
                        <ListItemIcon className={classes.justifyContentFlexEnd}>
                          <EditIcon color="primary" fontSize="small" />
                        </ListItemIcon>
                      </MenuItem>
                      <Divider />
                      <MenuItem
                        disabled={
                          jobDetails?.job_status === "Close" ||
                          jobDetails?.job_status === "Closed" ||
                          jobDetails?.job_status === "close" ||
                          jobDetails?.job_status === "closed"
                        }
                        onClick={() => {
                          popupState.close();
                          jobDetails?.job_status !== "Close" &&
                          jobDetails?.job_status !== "Closed" &&
                          jobDetails?.job_status !== "close" &&
                          jobDetails?.job_status !== "closed"
                            ? this.handleCloseConfirmPopupOpen()
                            : popupState.close();
                        }}
                      >
                        <ListItemText primary="Close Job" />
                        <ListItemIcon className={classes.justifyContentFlexEnd}>
                          <CloseIcon color="primary" fontSize="small" />
                        </ListItemIcon>
                      </MenuItem>
                    </Menu>
                  </React.Fragment>
                )}
              </PopupState>
            </div>
          </Grid>
        </div>

        <main className={classes.content}>
          <AllUpdates
            onTabChanges={this.handleTabChange}
            toggleView={this.toggleView}
          />

          {this.props.jobs.view === "Compare" && (
            <CompareViewJobs recommended={recommended} />
          )}

          {(this.props.jobs.view === "List" ||
            this.props.jobs.view === undefined) && <ListViewJobs />}
          <div>
            {/* Archive Confirm Popup */}
            <Dialog
              open={this.state.dislikePopupOpen}
              aria-labelledby="alert-dialog-title"
              aria-describedby="alert-dialog-description"
              fullWidth
              disableBackdropClick
              disableEscapeKeyDown
            >
              <DialogTitle
                id="alert-dialog-title"
                onClose={this.handleCloseArchivePopup}
              >
                Why are you passing on this candidate?
                {/* <Typography color='secondary'>(Verbiage for options will change)</Typography> */}
              </DialogTitle>
              <DialogContent style={{ paddingLeft: "8%", paddingRight: "8%" }}>
                <Typography variant="h6" color="textSecondary">
                  This information will not be shared with the candidate.
                </Typography>
                <DialogContentText
                  id="alert-dialog-description"
                  className={classNames(classes.dialogTitle, classes.largeFont)}
                >
                  <FormControl
                    required
                    component="fieldset"
                    className={classNames(
                      classes.fullWidth,
                      classes.mediumTopMargin,
                      classes.colorDefault
                    )}
                  >
                    <RadioGroup
                      aria-label="remote"
                      name="remote"
                      value={this.state.archiveReason}
                      onChange={this.handleInputChange("archiveReason")}
                    >
                      <Grid container>
                        <Grid
                          item
                          xs={12}
                          sm={12}
                          md={12}
                          lg={6}
                          xl={6}
                          className={classes.displayFlex}
                        >
                          <FormControlLabel
                            control={<Radio />}
                            label="Experience not relevant"
                            value="Experience not relevant"
                          />
                        </Grid>
                        <Grid
                          item
                          xs={12}
                          sm={12}
                          md={12}
                          lg={6}
                          xl={6}
                          className={classes.displayFlex}
                        >
                          <FormControlLabel
                            control={<Radio />}
                            label="Competencies not relevant"
                            value="Competencies not relevant"
                          />
                        </Grid>
                        <Grid
                          item
                          xs={12}
                          sm={12}
                          md={12}
                          lg={6}
                          xl={6}
                          className={classes.displayFlex}
                        >
                          <FormControlLabel
                            control={<Radio />}
                            label="Industry not preferable"
                            value="Industry not preferable"
                          />
                        </Grid>
                        <Grid
                          item
                          xs={12}
                          sm={12}
                          md={12}
                          lg={6}
                          xl={6}
                          className={classes.displayFlex}
                        >
                          <FormControlLabel
                            control={<Radio />}
                            label="Location not preferable"
                            value="Location not preferable"
                          />
                        </Grid>
                        <Grid
                          item
                          xs={12}
                          sm={12}
                          md={12}
                          lg={6}
                          xl={6}
                          className={classes.displayFlex}
                        >
                          <FormControlLabel
                            value="Other"
                            control={<Radio />}
                            label="Other"
                          />
                        </Grid>
                      </Grid>
                    </RadioGroup>
                  </FormControl>
                  {this.state.archiveReason === "Other" ? (
                    <>
                      <TextField
                        fullWidth
                        label="Reason"
                        autoComplete="otherArchiveReason"
                        margin="normal"
                        required
                        id="otherArchiveReason"
                        name="otherArchiveReason"
                        value={this.state.otherArchiveReason}
                        inputProps={{ maxLength: reasonCount }}
                        onChange={this.handleInputChange("otherArchiveReason")}
                        placeholder="Reason for archiving"
                        error={
                          this.state.isValidOtherArchiveReason ? false : true
                        }
                        helperText={
                          !this.state.isValidOtherArchiveReason
                            ? "Min 25 characters required"
                            : "Max 100 characters"
                        }
                      />
                      <Typography
                        className={classNames(
                          classes.floatRight,
                          classes.smallFont,
                          classes.customVerySmallTopMargin
                        )}
                        color="secondary"
                      >
                        {this.state.otherArchiveReason?.length +
                          "/" +
                          reasonCount}{" "}
                        characters
                      </Typography>
                    </>
                  ) : null}
                </DialogContentText>
              </DialogContent>
              <DialogActions>
                <Button
                  disableElevation
                  size="large"
                  onClick={this.handleCloseArchivePopup}
                  style={{ background: "#D7D9DB", color: "#000" }}
                  variant="contained"
                  color="primary"
                >
                  Cancel
                </Button>
                <Button
                  disableElevation
                  size="large"
                  variant="contained"
                  color="primary"
                  onClick={() => this.handleArchive()}
                  autoFocus
                  disabled={
                    this.state.archiveReason === "Other" &&
                    !this.state.isValidOtherArchiveReason
                  }
                >
                  Confirm
                </Button>
              </DialogActions>
            </Dialog>

            {/* Like Confirm Popup */}
            <Dialog
              open={this.state.likePopupOpen}
              aria-labelledby="alert-dialog-title"
              aria-describedby="alert-dialog-description"
              fullWidth
              disableBackdropClick
              disableEscapeKeyDown
            >
              <DialogTitle
                id="alert-dialog-title"
                onClose={this.handleCloseLikePopup}
              >
                Confirm Connection Request
              </DialogTitle>
              <DialogContent>
                {this.props?.jobs?.jobDetails?.job_type === "Normal" && (
                  <>
                    <DialogContentText
                      id="alert-dialog-description"
                      align="left"
                    >
                      Increase your match rate by including a brief personalized
                      message. The candidate will only be able to preview the
                      message until they accept the connection.
                    </DialogContentText>
                    <TextField
                      fullWidth
                      label="Message"
                      autoComplete="likeMessage"
                      margin="normal"
                      id="likeMessage"
                      name="likeMessage"
                      value={this.state.likeMessage}
                      inputProps={{ maxLength: likeMessageCount }}
                      onChange={this.handleInputChange("likeMessage")}
                      placeholder="Type your message here (optional)"
                      // error ={this.state.isValidLikeMessage ? false : true }
                      // helperText={!this.state.isValidLikeMessage ? '' : ''}
                    />
                    <Typography
                      className={classNames(
                        classes.floatRight,
                        classes.smallFont,
                        classes.customVerySmallTopMargin
                      )}
                      color="secondary"
                    >
                      {this.state.likeMessage?.length + "/" + likeMessageCount}{" "}
                      characters
                    </Typography>
                  </>
                )}
              </DialogContent>
              <DialogActions>
                <Button
                  size="large"
                  onClick={this.handleCloseLikePopup}
                  style={{ background: "#D7D9DB", color: "#000" }}
                  variant="contained"
                  color="primary"
                  disableElevation
                >
                  Cancel
                </Button>
                <Button
                  size="large"
                  variant="contained"
                  color="primary"
                  onClick={() => this.handleLike()}
                  autoFocus
                  disableElevation
                >
                  Connect
                </Button>
              </DialogActions>
            </Dialog>

            {/* Remove Collaborator Confirm Popup */}
            <Dialog
              open={this.state.openConfirmRemoveCollaboratorPopup}
              aria-labelledby="remove-collaborator-dialog-title"
              aria-describedby="remove-collaborator-dialog-description"
              fullWidth
              disableBackdropClick
              disableEscapeKeyDown
            >
              <DialogTitle
                id="remove-collaborator-dialog-title"
                onClose={this.closeConfirmDeleteCollaboratorPopup}
              >
                Remove Collaborator
              </DialogTitle>
              <DialogContent>
                <DialogContentText id="alert-dialog-description" align="left">
                  Are you sure you want to remove this collaborator? Once
                  removed, this collaborator can no longer comment/edit this
                  job.
                </DialogContentText>
              </DialogContent>
              <DialogActions>
                <Button
                  size="large"
                  onClick={this.closeConfirmDeleteCollaboratorPopup}
                  style={{ background: "#D7D9DB", color: "#000" }}
                  variant="contained"
                  color="primary"
                  disableElevation
                >
                  Cancel
                </Button>
                <Button
                  size="large"
                  variant="contained"
                  color="primary"
                  onClick={(event) => {
                    event.preventDefault();
                    this.handleDeleteCollaborator(
                      this.state.deleteCollaboratorPermissionId,
                      this.props.match.params.id
                    );
                  }}
                  autoFocus
                  disableElevation
                >
                  Yes, Remove
                </Button>
              </DialogActions>
            </Dialog>

            {/* Custom job title pop up */}
            <Dialog
              open={this.state.customJobTitlePopUp}
              fullWidth
              aria-labelledby="scroll-dialog-title"
              aria-describedby="scroll-dialog-description"
              disableBackdropClick
              disableEscapeKeyDown
              maxWidth="xs"
            >
              <DialogTitle
                id="customized-dialog-title"
                onClose={() => {
                  this.setState({ customJobTitlePopUp: false });
                }}
              >
                Add Job Title
              </DialogTitle>
              <DialogContent>
                <TextField
                  fullWidth
                  label="New Job Title"
                  className={classes.customTopBottomMargin}
                  id="newJobTitle"
                  name="newJobTitle"
                  value={this.state.customJobTitle}
                  onChange={this.handleInputChange("customJobTitle")}
                  placeholder="Enter job title"
                  inputProps={{
                    maxLength: customFieldCount,
                  }}
                />
                <Typography
                  className={classNames(classes.floatRight, classes.smallFont)}
                  color="secondary"
                >
                  {this.state.customJobTitle?.length + "/" + customFieldCount}{" "}
                  characters
                </Typography>
              </DialogContent>
              <DialogActions
                className={classNames(
                  classes.dialogActions,
                  classes.textAlignCenter
                )}
              >
                <Button
                  onClick={() =>
                    this.setState({
                      customJobTitlePopUp: false,
                    })
                  }
                  className={classes.justifyRight}
                  style={{ background: "#D7D9DB", color: "#000" }}
                  variant="contained"
                  disableElevation
                  size="medium"
                >
                  Cancel
                </Button>
                <Button
                  className={classes.justifyRight}
                  variant="contained"
                  color="primary"
                  disableElevation
                  size="medium"
                  disabled={this.state.customJobTitle === ""}
                  onClick={(event) => {
                    this.saveCustomJobTitle(event);
                  }}
                >
                  Save
                </Button>
              </DialogActions>
            </Dialog>

            {/* Custom certification title pop up */}
            <Dialog
              open={this.state.customCertificationTitlePopUp}
              fullWidth
              aria-labelledby="scroll-dialog-title"
              aria-describedby="scroll-dialog-description"
              disableBackdropClick
              disableEscapeKeyDown
              maxWidth="xs"
            >
              <DialogTitle
                id="customized-dialog-title"
                onClose={() => {
                  this.setState({ customCertificationTitlePopUp: false });
                }}
              >
                Add Professional Certification
              </DialogTitle>
              <DialogContent>
                <TextField
                  fullWidth
                  label="New Professional Certification"
                  className={classes.customTopBottomMargin}
                  id="newProfCertification"
                  name="newProfCertification"
                  value={this.state.customCertificationTitle}
                  onChange={this.handleInputChange("customCertificationTitle")}
                  placeholder="Enter professional certification"
                  inputProps={{
                    maxLength: customFieldCount,
                  }}
                />
                <Typography
                  className={classNames(classes.floatRight, classes.smallFont)}
                  color="secondary"
                >
                  {this.state.customCertificationTitle?.length +
                    "/" +
                    customFieldCount}{" "}
                  characters
                </Typography>
              </DialogContent>
              <DialogActions
                className={classNames(
                  classes.dialogActions,
                  classes.textAlignCenter
                )}
              >
                <Button
                  onClick={() =>
                    this.setState({
                      customCertificationTitlePopUp: false,
                    })
                  }
                  className={classes.justifyRight}
                  style={{ background: "#D7D9DB", color: "#000" }}
                  variant="contained"
                  disableElevation
                  size="medium"
                >
                  Cancel
                </Button>
                <Button
                  className={classes.justifyRight}
                  variant="contained"
                  color="primary"
                  disableElevation
                  size="medium"
                  disabled={this.state.customCertificationTitle === ""}
                  onClick={(event) => {
                    this.saveCustomCertificationTitle(event);
                  }}
                >
                  Save
                </Button>
              </DialogActions>
            </Dialog>

            {/* Custom major pop up */}
            <Dialog
              open={this.state.customMajorPopUp}
              fullWidth
              aria-labelledby="scroll-dialog-title"
              aria-describedby="scroll-dialog-description"
              disableBackdropClick
              disableEscapeKeyDown
              maxWidth="xs"
            >
              <DialogTitle
                id="customized-dialog-title"
                onClose={() => {
                  this.setState({ customMajorPopUp: false });
                }}
              >
                Add Major
              </DialogTitle>
              <DialogContent>
                <TextField
                  fullWidth
                  label="Major"
                  className={classes.customTopBottomMargin}
                  id="newMajor"
                  name="newMajor"
                  value={this.state.customMajorValue}
                  onChange={this.handleInputChange("customMajorValue")}
                  placeholder="Enter major"
                  inputProps={{
                    maxLength: customFieldCountForMajor,
                  }}
                />
                <Typography
                  className={classNames(classes.floatRight, classes.smallFont)}
                  color="secondary"
                >
                  {this.state.customMajorValue?.length +
                    "/" +
                    customFieldCountForMajor}{" "}
                  characters
                </Typography>
              </DialogContent>
              <DialogActions
                className={classNames(
                  classes.dialogActions,
                  classes.textAlignCenter
                )}
              >
                <Button
                  onClick={() =>
                    this.setState({
                      customMajorPopUp: false,
                    })
                  }
                  className={classes.justifyRight}
                  style={{ background: "#D7D9DB", color: "#000" }}
                  variant="contained"
                  // color="primary"
                  disableElevation
                  size="medium"
                >
                  Cancel
                </Button>
                <Button
                  className={classes.justifyRight}
                  variant="contained"
                  color="primary"
                  disableElevation
                  size="medium"
                  disabled={this.state.customMajorValue === ""}
                  onClick={(event) => {
                    this.saveCustomMajor(event);
                  }}
                >
                  Save
                </Button>
              </DialogActions>
            </Dialog>

            {/* Add Collaborators Popup */}
            <Dialog
              disableBackdropClick
              disableEscapeKeyDown
              aria-labelledby="customized-dialog-title"
              maxWidth="sm"
              fullWidth
              open={this.state.openAddCollaboratorsPopup}
            >
              <DialogTitle onClose={this.handleCloseCollaboratorPopup}>
                Add Collaborators
              </DialogTitle>
              <DialogContent>
                {this.state?.fields?.map((field, idx) => {
                  return (
                    <div key={`${field}-${idx}`}>
                      <Grid
                        container
                        spacing={2}
                        className={classNames(
                          classes.customSection,
                          classes.mediumTopMargin,
                          classes.customBottomMargin
                        )}
                      >
                        {this.state.fields.length > 1 ? (
                          <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                            <FormControlLabel
                              className={classes.floatRight}
                              control={
                                <IconButton
                                  className={classes.colorDefault}
                                  onClick={() =>
                                    this.handleRemoveCollaborator(idx)
                                  }
                                >
                                  <IndeterminateCheckBoxIcon />
                                </IconButton>
                              }
                              label={<Typography>Remove</Typography>}
                            ></FormControlLabel>
                          </Grid>
                        ) : null}
                        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                          <FormControl
                            className={classes.customFormControl}
                            required
                            error={field.isCollaboratorRequired || field.error}
                          >
                            <InputLabel id="collaboratorLabel">
                              Collaborator
                            </InputLabel>
                            <Select
                              fullWidth
                              id="collaborator"
                              name="collaborator"
                              margin="dense"
                              value={field.collaboratorValue}
                              placeholder="Choose one"
                              onChange={(e) => this.handleChange(idx, e)}
                            >
                              {collaboratorRecruiters?.map(
                                (recruiter, index) => (
                                  <MenuItem
                                    key={index}
                                    value={recruiter.id}
                                    selected={collaboratorIds.includes(
                                      recruiter.id
                                    )}
                                  >
                                    {recruiter.first_name +
                                      " " +
                                      recruiter.last_name}
                                  </MenuItem>
                                )
                              )}
                            </Select>
                            {field.isCollaboratorRequired ? (
                              <FormHelperText>
                                {MESSAGE_FIELD_IS_REQUIRED}
                              </FormHelperText>
                            ) : field.error ? (
                              <FormHelperText>
                                {"Please select other recruiter"}
                              </FormHelperText>
                            ) : null}
                          </FormControl>
                        </Grid>
                        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                          <FormControl
                            required
                            component="fieldset"
                            className={classNames(
                              classes.fullWidth,
                              classes.mediumTopMargin,
                              classes.colorDefault
                            )}
                            error={field.isAccessLevelRequired}
                          >
                            <FormLabel component="legend">
                              Choose Access Level for Collaborator
                            </FormLabel>
                            <RadioGroup
                              aria-label="accessLevel"
                              name="accessLevel"
                              value={field.accessLevelValue}
                              className={classes.verySmallTopMargin}
                              onChange={(e) => this.handleChange(idx, e)}
                            >
                              <Grid container>
                                <Grid
                                  item
                                  xs={12}
                                  sm={12}
                                  md={12}
                                  lg={12}
                                  xl={12}
                                  className={classes.displayFlex}
                                >
                                  <FormControlLabel
                                    control={<Radio />}
                                    label="Commenter"
                                    value="1"
                                  />
                                </Grid>
                                <Grid
                                  item
                                  xs={12}
                                  sm={12}
                                  md={12}
                                  lg={12}
                                  xl={12}
                                  className={classes.displayFlex}
                                >
                                  <FormControlLabel
                                    control={<Radio />}
                                    label="Editor"
                                    value="2"
                                  />
                                </Grid>
                              </Grid>
                            </RadioGroup>
                            {field.isAccessLevelRequired ? (
                              <FormHelperText>
                                {MESSAGE_FIELD_IS_REQUIRED}
                              </FormHelperText>
                            ) : null}
                          </FormControl>
                        </Grid>
                      </Grid>
                    </div>
                  );
                })}

                {this.state.fields?.length < collaboratorRecruiters?.length ? (
                  <FormControlLabel
                    control={
                      <IconButton onClick={() => this.handleAddCollaborator()}>
                        <AddBoxIcon className={classes.colorDefault} />
                      </IconButton>
                    }
                    label={<Typography>Add Another</Typography>}
                    className={classes.verySmallTopMargin}
                  ></FormControlLabel>
                ) : null}
                {this.state.fields?.length ===
                collaboratorRecruiters?.length ? (
                  <Typography color="textSecondary">{`You can add maximum of ${collaboratorRecruiters?.length} collaborators`}</Typography>
                ) : null}
              </DialogContent>
              <DialogActions className={classes.dialogActions}>
                <Button
                  variant="contained"
                  color="primary"
                  className={classes.submit}
                  disableElevation
                  size="large"
                  onClick={
                    collaboratorRecruiters?.length > 0
                      ? this.handleSubmit
                      : this.handleCloseCollaboratorPopup
                  }
                >
                  {collaboratorRecruiters?.length > 0 ? "Submit" : "Close"}
                </Button>
              </DialogActions>
            </Dialog>

            {/* Candidate Comments Popup */}
            <Dialog
              disableBackdropClick
              disableEscapeKeyDown
              aria-labelledby="customized-dialog-title"
              maxWidth="md"
              fullWidth
              open={this.state.openCandidateCommentsPopup}
            >
              <DialogTitle onClose={this.closeCandidateCommentsPopup}>
                Internal notes
              </DialogTitle>
              <DialogContent>
                <Grid container spacing={4} className={classes.smallTopMargin}>
                  <Grid item xs={12} lg={12} xl={12} sm={12} md={12}>
                    <TextField
                      label="Internal notes"
                      value={this.state.comment}
                      variant="outlined"
                      inputProps={{ maxLength: 1000 }}
                      multiline
                      rows={5}
                      rowsMax={5}
                      placeholder="Add comment (max 1000 characters)"
                      fullWidth
                      onChange={(event) => {
                        this.handleCommentChange(event);
                      }}
                    />
                    <Typography
                      color="textSecondary"
                      align="right"
                      component="div"
                      style={{ fontSize: "small", marginTop: "8px" }}
                    >
                      {this.state.comment?.length + "/1000 characters"}
                    </Typography>
                  </Grid>
                  <Grid item xs={12} lg={12} xl={12} sm={12} md={12}>
                    <Divider className={classes.customDivider}></Divider>
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    lg={12}
                    xl={12}
                    sm={12}
                    md={12}
                    style={{ textAlign: "left", paddingTop: 0 }}
                  >
                    {candidateComments?.length ? (
                      candidateComments.map((comment, index) => (
                        <div
                          className={classNames(
                            classes.customCardForComments,
                            index !== candidateComments.length
                              ? classes.smallBottomMargin
                              : ""
                          )}
                          key={`${comment}-${index}`}
                        >
                          <Grid container alignItems="center">
                            <Grid item xs={12} sm={12} md={12} lg={9} xl={9}>
                              <Typography color="textPrimary" component="div">
                                <strong>
                                  {comment?.recruiter?.name || "Unknown Admin"}
                                </strong>
                              </Typography>
                              <Typography color="textPrimary" component="div">
                                <ViewMore>{comment?.comment}</ViewMore>
                              </Typography>
                            </Grid>
                            <Grid item xs={12} sm={12} md={12} lg={3} xl={3}>
                              <Typography
                                component="div"
                                color="secondary"
                                align="right"
                              >
                                <Tooltip
                                  arrow
                                  title={moment(comment?.created_at)
                                    .format("MM-DD-YYYY hh:mm A")
                                    .toString()}
                                >
                                  <i>{moment(comment?.created_at).fromNow()}</i>
                                </Tooltip>{" "}
                                &nbsp;
                                {comment?.recruiter?.recruiter_id ===
                                  this.props.authentication
                                    ?.recruiterStatusDetails?.recruiter_id && (
                                  <Tooltip
                                    arrow
                                    title="Delete note"
                                    placement="top"
                                  >
                                    <IconButton
                                      onClick={(event) => {
                                        this.openConfirmDeleteCommentPopper(
                                          comment?.id,
                                          event
                                        );
                                      }}
                                    >
                                      <DeleteIcon />
                                    </IconButton>
                                  </Tooltip>
                                )}
                                <Popper
                                  open={this.state.openPopper}
                                  anchorEl={this.state.anchorEl}
                                  placement="bottom-end"
                                  transition
                                  style={{ zIndex: 9999 }}
                                  modifiers={{
                                    flip: {
                                      enabled: true,
                                    },
                                    arrow: {
                                      enabled: true,
                                      element: this.state.arrowRef,
                                    },
                                    preventOverflow: {
                                      enabled: "true",
                                      boundariesElement: "scrollParent",
                                    },
                                  }}
                                >
                                  {({ TransitionProps }) => (
                                    <Fade {...TransitionProps} timeout={350}>
                                      <Paper style={{ borderRadius: "10px" }}>
                                        {true && (
                                          // <span className={classes.arrow} ref={this.handleArrowRef} />
                                          <span ref={this.handleArrowRef} />
                                        )}
                                        <Grid className={classes.customPopper}>
                                          <Grid
                                            items
                                            xs={12}
                                            sm={12}
                                            md={12}
                                            lg={12}
                                            xl={12}
                                            className={
                                              classes.smallBottomMargin
                                            }
                                          >
                                            <Typography component="div">
                                              Are you sure you want to delete
                                              this note?
                                            </Typography>
                                          </Grid>
                                          <Grid
                                            className={classNames(
                                              classes.displayFlex,
                                              classes.justifyContentCenter,
                                              classes.textAlignCenter
                                            )}
                                            items
                                            xs={12}
                                            sm={12}
                                            md={12}
                                            lg={12}
                                            xl={12}
                                          >
                                            <Button
                                              size="small"
                                              className={
                                                classes.customEqualButtons
                                              }
                                              onClick={(event) => {
                                                event.preventDefault();
                                                this.closeConfirmDeleteCommentPopper();
                                              }}
                                              style={{
                                                background: "#D7D9DB",
                                                color: "#000",
                                              }}
                                              variant="contained"
                                              color="primary"
                                              disableElevation
                                            >
                                              Cancel
                                            </Button>
                                            <Button
                                              className={
                                                classes.customEqualButtons
                                              }
                                              size="small"
                                              variant="contained"
                                              color="primary"
                                              onClick={(event) => {
                                                event.preventDefault();
                                                this.handleDeleteComment();
                                              }}
                                              autoFocus
                                              disableElevation
                                            >
                                              Yes, Delete
                                            </Button>
                                          </Grid>
                                        </Grid>
                                      </Paper>
                                    </Fade>
                                  )}
                                </Popper>
                              </Typography>
                            </Grid>
                          </Grid>
                        </div>
                      ))
                    ) : (
                      <Typography
                        className={classes.noDataSmall}
                        color="textSecondary"
                      >
                        No internal notes found
                      </Typography>
                    )}
                  </Grid>
                </Grid>
              </DialogContent>
              <DialogActions className={classes.dialogActions}>
                <Button
                  variant="contained"
                  color="primary"
                  disableElevation
                  size="large"
                  disabled={
                    this.state.comment === "" ||
                    this.state.comment === undefined ||
                    this.state.comment === null
                  }
                  onClick={(event) => {
                    event.preventDefault();
                    this.handleSubmitComment();
                  }}
                >
                  {"Submit"}
                </Button>
              </DialogActions>
            </Dialog>

            {/* Edit Popup */}
            <Dialog
              open={this.state.open}
              scroll={this.state.scroll}
              fullWidth
              aria-labelledby="scroll-dialog-title"
              aria-describedby="scroll-dialog-description"
              disableBackdropClick
              disableEscapeKeyDown
              maxWidth="md"
            >
              <DialogTitle
                id="customized-dialog-title"
                className={classes.dialogTitle}
                onClose={(event) => {
                  this.handleCloseEdit(event);
                }}
              >
                Edit Job
              </DialogTitle>
              <DialogContent dividers={this.state.scroll === "paper"}>
                <DialogContentText
                  component="div"
                  id="scroll-dialog-description"
                >
                  <Typography component="span" color="secondary">
                    The job description will not be visible to candidates until
                    you have chosen to connect with them.
                  </Typography>
                  <Grid
                    item
                    xs={12}
                    sm={12}
                    md={12}
                    lg={12}
                    xl={12}
                    style={{ position: "relative" }}
                  >
                    <div className={classes.subHeader}>Basics</div>
                    <Autocomplete
                      id="auto-complete"
                      autoComplete
                      PopperComponent={Pop}
                      defaultValue={{
                        _source: {
                          Title:
                            this.state.jobTitleValue || this.state.jobTitle,
                        },
                      }}
                      value={this.state.jobTitleValue}
                      onBlur={(event) => {
                        this.handleOnBlur(event);
                      }}
                      open={this.state.openOptions}
                      onInput={(event, value) => {
                        this.setJobTitle(value);
                      }}
                      onChange={(event, newValue) => {
                        if (typeof newValue === "string") {
                          // timeout to avoid instant validation of the dialog's form.
                          setTimeout(() => {
                            this.setState({
                              customJobTitlePopUp: true,
                              customJobTitle: newValue,
                            });
                          });
                        } else if (newValue && newValue.inputValue) {
                          this.setState({
                            customJobTitlePopUp: true,
                            customJobTitle: newValue.inputValue,
                          });
                        } else {
                          this.setJobTitle(newValue);
                        }
                      }}
                      onOpen={() => {
                        this.setState({ openOptions: true });
                        this.removeFieldHighlight();
                      }}
                      onClose={() => {
                        this.setState({ openOptions: false });
                      }}
                      getOptionSelected={(option, value) =>
                        option?._source?.Title === value.name
                      }
                      getOptionLabel={(option) => {
                        if (typeof option === "string") {
                          return option;
                        }
                        if (option.inputValue) {
                          return option.inputValue;
                        }
                        return option?._source?.Title
                          ? option?._source?.Title
                          : "";
                      }}
                      freeSolo
                      selectOnFocus
                      clearOnBlur
                      filterOptions={(options, params) => {
                        let filtered = options;
                        // const filtered = filter(options, params);
                        if (params.inputValue !== "") {
                          filtered.push({
                            inputValue: params.inputValue,
                            _source: {
                              Title: `Add: "${params.inputValue}"`,
                            },
                          });
                        }
                        return filtered;
                      }}
                      renderOption={(option) => option?._source?.Title}
                      options={this.state.jobTitleOptions}
                      loading={this.state.jobTitleLoading}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label="Job Title"
                          id="jobTitle"
                          name="jobTitle"
                          required
                          value={this.state.jobTitle}
                          margin="normal"
                          InputProps={{
                            ...params.InputProps,
                            endAdornment: (
                              <React.Fragment>
                                {this.state.jobTitleLoading ? (
                                  <CircularProgress
                                    className={classes.verySmallMarginRight}
                                    color="inherit"
                                    size={20}
                                  />
                                ) : null}
                                {params.InputProps.endAdornment}
                              </React.Fragment>
                            ),
                          }}
                          error={this.state.isJobTitleRequire}
                          helperText={
                            this.state.isJobTitleRequire
                              ? MESSAGE_FIELD_IS_REQUIRED
                              : ""
                          }
                          onChange={this.handleInputChange("jobTitle")}
                          placeholder="Type to select job title"
                        />
                      )}
                    />
                  </Grid>

                  <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                    <Autocomplete
                      multiple
                      id="tags-standard"
                      freeSolo={true}
                      value={this.state.alternativeTiltes}
                      onChange={(event, newValue) => {
                        this.setState({ alternativeTiltes: newValue });
                      }}
                      options={this.state.alternativeJobTitleOptions}
                      getOptionLabel={(option) => {
                        if (typeof option === "string") {
                          return option;
                        }
                        return option?._source?.Title
                          ? option?._source?.Title
                          : "";
                      }}
                      loading={this.state.jobTitleLoadingAlternative}
                      renderOption={(option) => option?._source?.Title}
                      renderTags={(value, getTagProps) =>
                        value.map((option, index) => {
                          if (
                            typeof option === "string" &&
                            option?.trim()?.length === 0
                          ) {
                            return false;
                          }
                          if (option?._source?.Title) {
                            return (
                              <Chip
                                label={option?._source?.Title?.trim()}
                                {...getTagProps({ index })}
                                className={classes.chip}
                              />
                            );
                          } else {
                            return (
                              <Chip
                                label={option}
                                {...getTagProps({ index })}
                                className={classes.chip}
                              />
                            );
                          }
                        })
                      }
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          onChange={this.handleInputChange(
                            "alternativeJobTitle"
                          )}
                          variant="standard"
                          label="Alternative Titles"
                          InputProps={{
                            ...params.InputProps,
                            endAdornment: (
                              <React.Fragment>
                                {this.state.jobTitleLoadingAlternative ? (
                                  <CircularProgress
                                    className={classes.verySmallMarginRight}
                                    color="inherit"
                                    size={20}
                                  />
                                ) : null}
                                {params.InputProps.endAdornment}
                              </React.Fragment>
                            ),
                          }}
                          placeholder="Alternative Titles"
                        />
                      )}
                    />
                  </Grid>

                  <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                    <FormControl
                      className={classes.customFormControl}
                      style={{ marginTop: "1%" }}
                    >
                      <InputLabel required id="demo-simple-select-label">
                        Function
                      </InputLabel>
                      <Select
                        labelId="demo-simple-select-label"
                        id="editJobFunctionName"
                        margin="none"
                        value={this.state.functionName}
                        placeholder="Choose one"
                        onChange={this.handleInputChange("functionName")}
                      >
                        {constants?.function.map((obj, index) => (
                          <MenuItem key={index} value={obj}>
                            {obj}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </Grid>
                  {this.state.functionName === "Other" ? (
                    <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                      <TextField
                        fullWidth
                        label="Function Name"
                        autoComplete="functionName"
                        id="editJobOtherFunctionName"
                        name="functionName"
                        value={this.state.otherFunctionName}
                        className={classes.smallBottomMargin}
                        inputProps={{ maxLength: reasonCount }}
                        onChange={this.handleInputChange("otherFunctionName")}
                        placeholder="Enter function name (optional)"
                      />
                      <Typography
                        className={classNames(
                          classes.floatRight,
                          classes.smallFont,
                          classes.customVerySmallTopMargin
                        )}
                        color="secondary"
                      >
                        {this.state.otherFunctionName?.length +
                          "/" +
                          reasonCount}{" "}
                        characters
                      </Typography>
                    </Grid>
                  ) : null}
                  <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                    <FormControl
                      className={classes.formControl}
                      style={{ marginTop: "5px" }}
                    >
                      <InputLabel required id="demo-simple-select-label">
                        Job Level
                      </InputLabel>
                      <Select
                        margin="dense"
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        value={this.state.jobLevel}
                        placeholder="Choose one"
                        onChange={this.handleInputChange("jobLevel")}
                      >
                        {constants?.level.map((obj, index) => (
                          <MenuItem key={index} value={obj}>
                            {obj}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    sm={12}
                    md={12}
                    lg={12}
                    xl={12}
                    style={{ position: "relative" }}
                  >
                    <Autocomplete
                      autoComplete
                      PopperComponent={Pop}
                      defaultValue={{
                        _source: {
                          Title:
                            this.state.reportsToTitleValue ||
                            this.state.reportsToTitle,
                        },
                      }}
                      open={this.state.openReportsToOptions}
                      onInput={(event, value) => {
                        this.setReportsToTitle(value);
                      }}
                      onChange={(event, value) => {
                        this.setReportsToTitle(value);
                      }}
                      onOpen={() => {
                        this.setState({ openReportsToOptions: true });
                      }}
                      onClose={() => {
                        this.setState({ openReportsToOptions: false });
                      }}
                      onBlur={(event) => {
                        this.handleReportsToBlur(event);
                      }}
                      getOptionSelected={(option, value) =>
                        option._source.Title === value.name
                      }
                      getOptionLabel={(option) => option._source.Title}
                      options={this.state.reportsToTitleOptions}
                      loading={this.state.reportsToTitlesLoading}
                      filterOptions={(options) => {
                        let filtered = options;
                        return filtered;
                      }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label="Reports To"
                          id="reportsToTitle"
                          name="reportsToTitle"
                          InputProps={{
                            ...params.InputProps,
                            endAdornment: (
                              <React.Fragment>
                                {this.state.reportsToTitlesLoading ? (
                                  <CircularProgress
                                    className={classes.verySmallMarginRight}
                                    color="inherit"
                                    size={20}
                                  />
                                ) : null}
                                {params.InputProps.endAdornment}
                              </React.Fragment>
                            ),
                            inputProps: {
                              ...params.inputProps,
                              maxLength: 100,
                            },
                          }}
                          onChange={this.handleInputChange("reportsToTitle")}
                          placeholder="Hiring manager's title"
                        />
                      )}
                    />
                  </Grid>
                  <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                    <TextField
                      fullWidth
                      label="Hiring Manager Name"
                      autoComplete="reportsTo"
                      className={classes.customTopBottomMargin}
                      id="reportsTo"
                      name="reportsTo"
                      value={this.state.reportsTo}
                      onChange={this.handleInputChange("reportsTo")}
                      placeholder="Enter hiring manager's name"
                      error={this.state.isValidReportsToName ? false : true}
                      helperText={!this.state.isValidReportsToName ? "" : ""}
                    />

                    <Typography
                      className={classNames(
                        classes.floatRight,
                        classes.smallFont,
                        classes.customVerySmallTopMargin
                      )}
                      color="secondary"
                    >
                      {this.state.reportsTo
                        ? this.state.reportsTo?.length
                        : 0 + "/" + reasonCount}{" "}
                      characters
                    </Typography>
                  </Grid>
                  <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                    <FormControl className={classes.customFormControl}>
                      <InputLabel id="demo-simple-select-label">
                        Industry
                      </InputLabel>
                      <Select
                        fullWidth
                        id="industry"
                        margin="dense"
                        value={this.state.industry}
                        placeholder="Choose one"
                        onChange={this.handleInputChange("industry")}
                      >
                        {constants?.industry.map((obj, index) => (
                          <MenuItem key={index} value={obj}>
                            {obj}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </Grid>
                  {this.state.openCustomIndustryField && (
                    <Grid
                      item
                      xs={11}
                      sm={11}
                      md={11}
                      lg={11}
                      xl={11}
                      className={classes.customLabelGrid}
                    >
                      <FormControl
                        className={classes.customFormControl}
                        required
                        error={this.state.customIndustryRequired}
                      >
                        <TextField
                          required
                          fullWidth
                          label="Other Industry Name"
                          className={classes.customTopBottomMargin}
                          id="customIndustry"
                          name="customIndustry"
                          value={this.state.customIndustryValue}
                          onChange={this.handleInputChange(
                            "customIndustryValue"
                          )}
                          placeholder="Other Industry Name"
                          inputProps={{ maxLength: 100 }}
                          error={
                            this.state.customIndustryRequired ? true : false
                          }
                          helperText={
                            this.state.customIndustryRequired === true
                              ? MESSAGE_FIELD_IS_REQUIRED
                              : null
                          }
                        />
                      </FormControl>
                    </Grid>
                  )}

                  <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                    <Autocomplete
                      multiple
                      id="tags-standard"
                      value={this.state.alternativeIndustry}
                      freeSolo={true}
                      filterSelectedOptions={true}
                      onChange={(event, newValue) => {
                        this.setState({ alternativeIndustry: newValue });
                      }}
                      options={IndustryOptions || []}
                      renderOption={(option) => option}
                      renderTags={(value, getTagProps) =>
                        value.map((option, index) => {
                          if (option?.trim()?.length === 0) {
                            return false;
                          }
                          return (
                            <Chip
                              label={option}
                              {...getTagProps({ index })}
                              className={classes.chip}
                            />
                          );
                        })
                      }
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          variant="standard"
                          label="Alternative Industries"
                          placeholder="Alternative Industries"
                        />
                      )}
                    />
                  </Grid>

                  <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                    <GoogleMaps
                      className={classes.customTopBottomMargin}
                      updateRequire={this.updateLocationRequireState}
                    ></GoogleMaps>
                  </Grid>
                  <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                    <FormControl className={classes.formControl}>
                      <InputLabel id="experience-label">
                        Minimum Years of Experience
                      </InputLabel>
                      <Select
                        labelId="experience-label"
                        id="experience"
                        value={this.state.minimumExperience}
                        placeholder="Choose one"
                        onChange={this.handleInputChange("minimumExperience")}
                      >
                        {constants?.years_of_experience.map((obj, index) => (
                          <MenuItem key={index} value={obj}>
                            {obj}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                    <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                      <FormControl
                        component="fieldset"
                        className={classNames(
                          classes.fullWidth,
                          classes.verySmallTopMargin,
                          classes.colorDefault
                        )}
                      >
                        <Typography
                          component={"div"}
                          style={{ display: "flex", alignItems: "center" }}
                        >
                          <FormLabel
                            className={classes.customFormLabel}
                            id="salary-label"
                            component="legend"
                          >
                            Base Salary
                          </FormLabel>
                          &nbsp;
                          <Typography component={"span"}>
                            <PopupState
                              variant="popover"
                              popupId="demo-popup-popover"
                            >
                              {(popupState) => (
                                <Fragment>
                                  <HelpIcon
                                    {...bindTrigger(popupState)}
                                    style={{
                                      marginTop: "5px",
                                      cursor: "pointer",
                                    }}
                                    titleAccess="Help"
                                  ></HelpIcon>
                                  <Popover
                                    {...bindPopover(popupState)}
                                    anchorOrigin={{
                                      vertical: "top",
                                      horizontal: "left",
                                    }}
                                    transformOrigin={{
                                      vertical: "top",
                                      horizontal: "left",
                                    }}
                                    style={{ padding: "8px" }}
                                  >
                                    <Box sx={{ width: 600, height: 300 }}>
                                      <Typography
                                        variant="body1"
                                        style={{
                                          padding: "8px",
                                          fontWeight: 500,
                                        }}
                                      >
                                        <b> Help Text </b>
                                      </Typography>
                                      <Typography
                                        variant="body2"
                                        className={classNames(
                                          classes.salaryHelpText
                                        )}
                                      >
                                        <b>Share with candidates : </b>
                                      </Typography>
                                      <Typography style={{ padding: "8px" }}>
                                        Selecting this option displays base
                                        salary information to every candidate
                                        you’re interested in.
                                      </Typography>
                                      <Typography
                                        variant="body2"
                                        className={classNames(
                                          classes.salaryHelpText
                                        )}
                                      >
                                        <b>Candidate matching only : </b>
                                      </Typography>
                                      <Typography style={{ padding: "8px" }}>
                                        Selecting this option includes the
                                        designated base salary within
                                        SucceedSmart’s candidate search criteria
                                        without displaying the salary
                                        information to selected candidates.
                                      </Typography>
                                      <Typography
                                        variant="body2"
                                        className={classNames(
                                          classes.salaryHelpText
                                        )}
                                      >
                                        <b>Exclude salary : </b>
                                      </Typography>
                                      <Typography style={{ padding: "8px" }}>
                                        Selecting this option removes the use of
                                        a salary to match prospective candidates
                                        for this role and also removes the use
                                        of a salary from your candidate search
                                        parameters.
                                      </Typography>
                                    </Box>
                                  </Popover>
                                </Fragment>
                              )}
                            </PopupState>
                          </Typography>
                        </Typography>
                        <RadioGroup
                          aria-label="salary-label"
                          id="salary"
                          value={this.state.salary_type}
                          onChange={(event, newValue) => {
                            switch (newValue) {
                              case "Exclude salary":
                                this.setState({
                                  salary_config: 0,
                                  salary_type: newValue,
                                  minimumBaseSalary: null,
                                });
                                break;
                              case "Candidate matching only":
                                this.setState({
                                  salary_config: 1,
                                  salary_type: newValue,
                                  minimumBaseSalary:
                                    this.state.minimumBaseSalary !== null
                                      ? this.state.minimumBaseSalary
                                      : "100000",
                                });
                                break;
                              case "Share with candidates":
                                this.setState({
                                  salary_config: 2,
                                  salary_type: newValue,
                                  minimumBaseSalary:
                                    this.state.minimumBaseSalary !== null
                                      ? this.state.minimumBaseSalary
                                      : "100000",
                                });
                                break;
                              default:
                                break;
                            }
                          }}
                        >
                          <Grid container>
                            <Grid
                              item
                              xs={12}
                              sm={12}
                              md={12}
                              lg={4}
                              xl={4}
                              className={classes.displayFlex}
                            >
                              <FormControlLabel
                                label="Share with candidates"
                                value="Share with candidates"
                                control={<Radio />}
                              />
                            </Grid>
                            <Grid
                              item
                              xs={12}
                              sm={12}
                              md={12}
                              lg={4}
                              xl={4}
                              className={classes.displayFlex}
                            >
                              <FormControlLabel
                                label="Candidate matching only"
                                value="Candidate matching only"
                                control={<Radio />}
                              />
                            </Grid>
                            <Grid
                              item
                              xs={12}
                              sm={12}
                              md={12}
                              lg={4}
                              xl={4}
                              className={classes.displayFlex}
                            >
                              <FormControlLabel
                                label="Exclude salary"
                                value="Exclude salary"
                                control={<Radio />}
                              />
                            </Grid>
                          </Grid>
                        </RadioGroup>
                      </FormControl>
                      {this.state.salary_type === "Share with candidates" ||
                      this.state.salary_type === "Candidate matching only" ? (
                        <Grid className={classes.salaryOption}>
                          <Grid container spacing={0}>
                            <Grid item xs={3} sm={3} md={3} lg={3} xl={3}>
                              <Grid
                                item
                                xs={12}
                                sm={12}
                                md={12}
                                lg={12}
                                xl={12}
                              >
                                <Typography
                                  component="div"
                                  style={{ fontWeight: 500, color: "black" }}
                                >
                                  {this.state?.minimumBaseSalary !== null
                                    ? `$ ${salaryValueFormat.format(
                                        this.state.minimumBaseSalary
                                      )}`
                                    : `$ ${salaryValueFormat.format(100000)}`}
                                </Typography>
                              </Grid>
                              <Grid
                                item
                                xs={12}
                                sm={12}
                                md={12}
                                lg={12}
                                xl={12}
                              >
                                <Typography
                                  component="div"
                                  style={{ fontWeight: 500, color: "black" }}
                                >
                                  Selected Salary
                                </Typography>
                              </Grid>
                            </Grid>
                            <Grid
                              item
                              xs={9}
                              sm={9}
                              md={9}
                              lg={9}
                              xl={9}
                              className={classes.displayFlex}
                            >
                              <SalarySlider
                                aria-label="Always visible"
                                size="medium"
                                value={this.state.minimumBaseSalary}
                                onChange={(event, newValue) => {
                                  if (newValue) {
                                    this.setState({
                                      minimumBaseSalary: newValue,
                                    });
                                  }
                                }}
                                marks={marks}
                                step={10000}
                                min={minValue}
                                max={maxValue}
                                valueLabelDisplay="on"
                              />
                            </Grid>
                          </Grid>
                        </Grid>
                      ) : (
                        ""
                      )}
                    </Grid>
                  </Grid>
                  <Grid container spacing={2}>
                    <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                      <FormControl className={classes.customFormControl}>
                        <InputLabel id="degree-label">Degree</InputLabel>
                        <Select
                          labelId="degree-label"
                          id="degree"
                          value={this.state.degree}
                          placeholder="Choose one"
                          onChange={this.handleInputChange("degree")}
                        >
                          {constants?.degree.map((obj, index) => (
                            <MenuItem key={index} value={obj}>
                              {obj}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    </Grid>
                    <Grid
                      item
                      xs={12}
                      sm={12}
                      md={6}
                      lg={6}
                      xl={6}
                      style={{ position: "relative" }}
                    >
                      <Autocomplete
                        id="auto-complete-major"
                        PopperComponent={Pop}
                        autoComplete
                        open={this.state.openMajorOptions}
                        value={this.state.majorValue}
                        onChange={(event, value) => {
                          if (typeof value === "string") {
                            setTimeout(() => {
                              this.setState({
                                customMajorPopUp: true,
                                customMajorValue: value,
                              });
                            });
                          } else if (value && value?.inputValue) {
                            this.setState({
                              customMajorPopUp: true,
                              customMajorValue: value.inputValue,
                            });
                          } else {
                            this.setMajor(value);
                          }
                        }}
                        onOpen={() => {
                          this.setState({ openMajorOptions: true });
                        }}
                        onClose={() => {
                          this.setState({ openMajorOptions: false });
                        }}
                        getOptionSelected={(option, value) =>
                          option?._source?.Major === value?._source?.Major
                        }
                        options={this.state.majorOptions}
                        onBlur={(event) => {
                          this.handleMajorBlur(event);
                        }}
                        getOptionLabel={(option) => {
                          if (typeof option === "string") {
                            return option;
                          }
                          if (option.inputValue) {
                            return option.inputValue;
                          }
                          return option?._source?.Major;
                        }}
                        loading={this.state.majorLoading}
                        selectOnFocus
                        clearOnBlur
                        filterOptions={(options, params) => {
                          let filtered = options;
                          // const filtered = filter(options, params);
                          if (params.inputValue !== "") {
                            filtered.push({
                              inputValue: params.inputValue,
                              _source: {
                                Major: `Add: "${params.inputValue}"`,
                              },
                            });
                          }
                          return filtered;
                        }}
                        renderOption={(option) => option?._source?.Major}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            label="Major"
                            id="major"
                            name="major"
                            InputProps={{
                              ...params.InputProps,
                              endAdornment: (
                                <React.Fragment>
                                  {this.state.majorLoading ? (
                                    <CircularProgress
                                      className={classes.verySmallMarginRight}
                                      color="inherit"
                                      size={20}
                                    />
                                  ) : null}
                                  {params.InputProps.endAdornment}
                                </React.Fragment>
                              ),
                            }}
                            value={this.state.major}
                            onChange={this.handleInputChange("major")}
                            placeholder="Type to select major (optional)"
                          />
                        )}
                      />
                    </Grid>
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    sm={12}
                    md={12}
                    lg={12}
                    xl={12}
                    className={classes.displayFlex}
                  >
                    <FormControl
                      component="fieldset"
                      className={classNames(
                        classes.fullWidth,
                        classes.mediumTopMargin,
                        classes.colorDefault
                      )}
                    >
                      <RadioGroup
                        aria-label="degreeRequired"
                        name="degreeRequired"
                        value={this.state.degreeRequiredOrPreferred}
                        onChange={this.handleInputChange(
                          "degreeRequiredOrPreferred"
                        )}
                      >
                        <Grid container>
                          <Grid item xs={12} sm={12} md={12} lg={4} xl={4}>
                            <FormLabel
                              component="legend"
                              style={{ marginTop: "14px" }}
                            >
                              Degree Required/Preferred?
                            </FormLabel>
                          </Grid>
                          <Grid item xs={12} sm={12} md={12} lg={4} xl={4}>
                            <FormControlLabel
                              control={<Radio />}
                              label="Required"
                              value="Required"
                            />
                          </Grid>
                          <Grid item xs={12} sm={12} md={12} lg={4} xl={4}>
                            <FormControlLabel
                              control={<Radio />}
                              label="Preferred"
                              value="Preferred"
                            />
                          </Grid>
                        </Grid>
                      </RadioGroup>
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                    <Autocomplete
                      id="tags-outlined-certifications"
                      multiple
                      autoComplete
                      filterSelectedOptions
                      value={this.state.certificationsReceived}
                      open={this.state.openCertificationsOptions}
                      onChange={(event, value) => {
                        if (typeof value === "string") {
                          // timeout to avoid instant validation of the dialog's form.
                          setTimeout(() => {
                            this.setState({
                              customCertificationTitlePopUp: true,
                              customCertificationTitle: value,
                            });
                          });
                        } else if (
                          value &&
                          value[value?.length - 1]?.inputValue
                        ) {
                          this.setState({
                            customCertificationTitlePopUp: true,
                            customCertificationTitle:
                              value[value?.length - 1].inputValue,
                          });
                        } else {
                          this.setCertifications(value);
                        }
                      }}
                      onOpen={() => {
                        this.setState({ openCertificationsOptions: true });
                      }}
                      onClose={() => {
                        this.setState({ openCertificationsOptions: false });
                      }}
                      getOptionSelected={(option, value) =>
                        option._source?.Certificate ===
                        value._source.Certificate
                      }
                      getOptionLabel={(option) => {
                        if (typeof option === "string") {
                          return option;
                        }
                        if (option.inputValue) {
                          return option.inputValue;
                        }
                        return option?._source?.Certificate;
                      }}
                      selectOnFocus
                      clearOnBlur
                      filterOptions={(options, params) => {
                        let filtered = options;
                        // const filtered = filter(options, params);
                        if (params.inputValue !== "") {
                          filtered.push({
                            inputValue: params.inputValue,
                            _source: {
                              Certificate: `Add: "${params.inputValue}"`,
                            },
                          });
                        }
                        return filtered;
                      }}
                      renderOption={(option) => option?._source?.Certificate}
                      options={this.state.certificationsOptions}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label="Professional Certifications"
                          onBlur={(event) => {
                            this.handleCertificationsBlur(event);
                          }}
                          margin="normal"
                          id="certifications"
                          name="certifications"
                          value={this.state.certifications}
                          loading={this.state.certificationsLoading}
                          InputProps={{
                            ...params.InputProps,
                            endAdornment: (
                              <React.Fragment>
                                {this.state.certificationsLoading ? (
                                  <CircularProgress
                                    className={classes.verySmallMarginRight}
                                    color="inherit"
                                    size={20}
                                  />
                                ) : null}
                                {params.InputProps.endAdornment}
                              </React.Fragment>
                            ),
                            inputProps: {
                              ...params.inputProps,
                              maxLength: customFieldCount,
                            },
                          }}
                          onChange={this.handleInputChange("certifications")}
                          placeholder={
                            (this.state.certificationsValue === null ||
                              this.state.certificationsValue === "" ||
                              this.state.certifications === null) &&
                            this.state.certifications?.length === 0
                              ? "Type to select from options"
                              : ""
                          }
                        />
                      )}
                    />
                  </Grid>
                  <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                    <FormControl
                      component="fieldset"
                      className={classNames(
                        classes.fullWidth,
                        classes.mediumTopMargin,
                        classes.colorDefault
                      )}
                    >
                      <RadioGroup
                        aria-label="remote"
                        name="remote"
                        value={this.state.remote}
                        onChange={this.handleInputChange("remote")}
                      >
                        <Grid container>
                          <Grid xs={12} sm={12} md={12} lg={3} xl={3}>
                            <FormLabel
                              style={{ marginTop: "14px" }}
                              component="legend"
                              required
                            >
                              Work Location
                            </FormLabel>
                          </Grid>
                          {constants?.remote_or_onsite.map((option) => (
                            <Grid
                              item
                              xs={12}
                              sm={12}
                              md={12}
                              lg={3}
                              xl={3}
                              className={classes.displayFlex}
                            >
                              <FormControlLabel
                                control={<Radio />}
                                label={option}
                                value={option}
                              />
                            </Grid>
                          ))}
                        </Grid>
                      </RadioGroup>
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                    <FormControl
                      component="fieldset"
                      className={classNames(
                        classes.fullWidth,
                        classes.mediumTopMargin,
                        classes.colorDefault
                      )}
                    >
                      <RadioGroup
                        aria-label="travelLevel"
                        name="travelLevel"
                        value={this.state.travelLevel}
                        onChange={this.handleInputChange("travelLevel")}
                      >
                        <Grid container>
                          <Grid xs={12} sm={12} md={12} lg={3} xl={3}>
                            <FormLabel
                              component="legend"
                              style={{ marginTop: "14px" }}
                            >
                              Travel Requirements
                            </FormLabel>
                          </Grid>
                          {constants?.travel_requirements.map((option) => (
                            <Grid
                              item
                              xs={12}
                              sm={12}
                              md={12}
                              lg={3}
                              xl={3}
                              className={classes.displayFlex}
                            >
                              <FormControlLabel
                                className={classes.noWrap}
                                control={<Radio />}
                                label={option}
                                value={option}
                              />
                            </Grid>
                          ))}
                        </Grid>
                      </RadioGroup>
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                    <div className={classes.subHeader}>
                      Corporate/Startup Information
                    </div>
                  </Grid>
                  <Grid container>
                    <Grid
                      item
                      xs={12}
                      sm={12}
                      md={12}
                      lg={12}
                      xl={12}
                      className={classNames(
                        classes.fullWidth,
                        classes.mediumTopMargin,
                        classes.colorDefault
                      )}
                    >
                      <FormLabel component="legend">Company Size</FormLabel>
                    </Grid>
                    {constants?.company_size.map((option) => (
                      <Grid md={4} xl={4}>
                        <FormControlLabel
                          key={option}
                          control={
                            <Checkbox
                              checked={
                                this.state.startupSize?.indexOf(option) > -1
                              }
                              onChange={this.selectCheckbox("StartupSize")}
                              name={option}
                              value={option}
                              iconStyle={{ fill: "#048475" }}
                              style={{ color: "#048475" }}
                            />
                          }
                          label={
                            <Typography
                              variant="body2"
                              style={{ color: "black", fontWeight: 400 }}
                            >
                              {option}
                            </Typography>
                          }
                        />
                      </Grid>
                    ))}
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    sm={12}
                    md={12}
                    lg={12}
                    xl={12}
                    className={classNames(
                      classes.fullWidth,
                      classes.mediumTopMargin,
                      classes.colorDefault
                    )}
                  >
                    <FormLabel component="legend">Company Type</FormLabel>
                  </Grid>
                  <Grid container>
                    {constants?.size_and_stages.map((option) => (
                      <Grid md={4} xl={4}>
                        <FormControlLabel
                          key={option}
                          control={
                            <Checkbox
                              onChange={this.selectCheckbox("StartupStage")}
                              checked={
                                this.state.startupStage?.indexOf(option) > -1
                              }
                              name={option}
                              value={option}
                              iconStyle={{ fill: "#048475" }}
                              style={{ color: "#048475" }}
                            />
                          }
                          label={
                            <Typography
                              variant="body2"
                              style={{ color: "black", fontWeight: 400 }}
                            >
                              {option}
                            </Typography>
                          }
                        />
                      </Grid>
                    ))}
                  </Grid>
                  <Grid
                    className={classNames(
                      classes.fullWidth,
                      classes.mediumTopMargin,
                      classes.colorDefault
                    )}
                    item
                    xs={12}
                    sm={12}
                    md={12}
                    lg={12}
                    xl={12}
                  >
                    <FormLabel component="legend">Team size</FormLabel>
                  </Grid>
                  <Grid container>
                    {constants?.team_size.map((option) => (
                      <Grid md={4} xl={4}>
                        <FormControlLabel
                          key={option}
                          control={
                            <Checkbox
                              checked={
                                this.state.teamSize?.indexOf(option) > -1
                              }
                              onChange={this.selectCheckbox("TeamSize")}
                              name={option}
                              value={option}
                              iconStyle={{ fill: "#048475" }}
                              style={{ color: "#048475" }}
                            />
                          }
                          label={
                            <Typography
                              variant="body2"
                              style={{ color: "black", fontWeight: 400 }}
                            >
                              {option}
                            </Typography>
                          }
                        />
                      </Grid>
                    ))}
                  </Grid>
                  <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                    <FormControl
                      component="fieldset"
                      className={classNames(
                        classes.fullWidth,
                        classes.mediumTopMargin,
                        classes.colorDefault
                      )}
                    >
                      <RadioGroup
                        aria-label="role"
                        name="role"
                        value={this.state.role}
                        onChange={this.handleRole}
                        row
                      >
                        <Grid container>
                          <Grid item xs={12} sm={12} md={12} lg={4} xl={4}>
                            <FormLabel
                              component="legend"
                              style={{ marginTop: "14px" }}
                            >
                              New Role/Existing Role
                            </FormLabel>
                          </Grid>
                          <Grid item xs={12} sm={12} md={12} lg={4} xl={4}>
                            <FormControlLabel
                              control={<Radio />}
                              label={
                                <Typography variant="body1">
                                  New Role
                                </Typography>
                              }
                              value={"New Role"}
                            />
                          </Grid>
                          <Grid item xs={12} sm={12} md={12} lg={4} xl={4}>
                            <FormControlLabel
                              control={<Radio />}
                              label={
                                <Typography variant="body1">
                                  Existing Role
                                </Typography>
                              }
                              value={"Existing Role"}
                            />
                          </Grid>
                        </Grid>
                      </RadioGroup>
                    </FormControl>
                  </Grid>
                  <Grid
                    className={classNames(
                      classes.mediumTopMargin,
                      classes.smallBottomMargin
                    )}
                  >
                    <FormLabel>Sample Corporations</FormLabel>
                  </Grid>
                  <Grid item xs={12} sm={12}>
                    <Autocomplete
                      autoComplete
                      filterSelectedOptions
                      multiple
                      id="tags-standard"
                      defaultValue={this.state?.companyNamesArray}
                      options={this.state.companyOptions}
                      getOptionLabel={(option) => option.name}
                      loading={this.state.companyLoading}
                      getOptionSelected={(option, value) =>
                        option.name === value.name
                      }
                      getOptionDisabled={() =>
                        this.state.sampleCompanyValues?.length === 10
                          ? true
                          : false
                      }
                      selectOnFocus
                      clearOnBlur
                      renderTags={(value, getTagProps) =>
                        value.map((option, index) => (
                          <Chip
                            variant="default"
                            label={option.name}
                            {...getTagProps({ index })}
                            className={classes.chip}
                            style={{
                              height: "100%",
                              wordBreak: "break-word",
                              padding: "5px",
                              margin: "5px 4px 8px 0px",
                            }}
                          />
                        ))
                      }
                      onChange={(event, value) => {
                        this.setState({
                          sampleCompanyValues: value,
                          receivedCompanies: value,
                        });
                      }}
                      filterOptions={(options) => {
                        let filtered = options;
                        return filtered;
                      }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          variant={"outlined"}
                          onChange={(event) => {
                            this.setState({ companyLoading: true });
                            this.handleExpCompany(event);
                          }}
                          placeholder={"Type company name and select"}
                          InputProps={{
                            ...params.InputProps,
                            endAdornment: (
                              <React.Fragment>
                                {this.state.companyLoading ? (
                                  <CircularProgress
                                    className={classes.verySmallMarginRight}
                                    color="inherit"
                                    size={20}
                                  />
                                ) : null}
                                {params.InputProps.endAdornment}
                              </React.Fragment>
                            ),
                            inputProps: {
                              ...params.inputProps,
                              maxLength: 1000,
                            },
                          }}
                          value={this.state.sampleCompanyValueInput}
                        />
                      )}
                    />
                  </Grid>
                  <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                    <FormControl
                      component="fieldset"
                      className={classNames(
                        classes.fullWidth,
                        classes.mediumTopMargin,
                        classes.colorDefault
                      )}
                    >
                      <FormLabel
                        component="span"
                        className={classes.justifyContent}
                      >
                        How soon do you want to fill this role?
                      </FormLabel>
                      <RadioGroup
                        aria-label="howSoonToHire"
                        name="howSoonToHire"
                        value={this.state.howSoonToHire}
                        onChange={this.handleInputChange("howSoonToHire")}
                      >
                        <Grid container>
                          {constants?.jobs_notice_period.map((option) => (
                            <Grid
                              item
                              xs={12}
                              sm={12}
                              md={12}
                              lg={4}
                              xl={4}
                              className={classes.displayFlex}
                            >
                              <FormControlLabel
                                className={classes.noWrap}
                                control={<Radio />}
                                label={option}
                                value={option}
                              />
                            </Grid>
                          ))}
                        </Grid>
                      </RadioGroup>
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                    <FormControl
                      component="fieldset"
                      className={classNames(
                        classes.fullWidth,
                        classes.mediumTopMargin,
                        classes.colorDefault
                      )}
                    >
                      <FormLabel
                        component="span"
                        className={classes.justifyContent}
                      >
                        Confidential/Normal Role
                      </FormLabel>
                      <RadioGroup
                        aria-label=" Confidential/Normal Role"
                        name=" Confidential/Normal Role"
                        value={this.state.confidenNormalValue}
                        onChange={this.handleInputChange("confidenNormalValue")}
                      >
                        <Grid container>
                          <Grid
                            item
                            xs={12}
                            sm={12}
                            md={12}
                            lg={4}
                            xl={4}
                            className={classes.displayFlex}
                          >
                            <FormControlLabel
                              className={classes.noWrap}
                              control={<Radio />}
                              label={"Confidential"}
                              value={"Confidential"}
                            />
                          </Grid>
                          <Grid
                            item
                            xs={12}
                            sm={12}
                            md={12}
                            lg={4}
                            xl={4}
                            className={classes.displayFlex}
                          >
                            <FormControlLabel
                              className={classes.noWrap}
                              control={<Radio />}
                              label={"Normal"}
                              value={"Normal"}
                            />
                          </Grid>
                        </Grid>
                      </RadioGroup>
                    </FormControl>
                  </Grid>
                  <div
                    className={classNames(
                      classes.mediumTopMargin,
                      classes.noBottomMargin
                    )}
                  >
                    <FormLabel className={classes.subHeader} required>
                      About Us
                    </FormLabel>
                  </div>
                  <Grid
                    item
                    xs={12}
                    sm={12}
                    md={12}
                    lg={12}
                    xl={12}
                    className={classes.customSmallTopMargin}
                  >
                    <Editor
                      toolbar={{
                        options: [
                          "inline",
                          "blockType",
                          "fontSize",
                          "fontFamily",
                          "list",
                          "textAlign",
                          "colorPicker",
                          "link",
                          "remove",
                          "history",
                        ],
                        inline: {
                          options: [
                            "bold",
                            "italic",
                            "underline",
                            "strikethrough",
                          ],
                          inDropdown: true,
                        },
                        list: {
                          options: ["unordered", "ordered"],
                          inDropdown: true,
                        },
                        textAlign: { inDropdown: true },
                        link: { inDropdown: true },
                        history: { inDropdown: true },
                      }}
                      editorState={editorStateAboutUs}
                      onEditorStateChange={this.onAboutUsChange}
                      onChange={this.handleInputChange("aboutUs")}
                      placeholder="Give a brief description about your company"
                      handleBeforeInput={(val) => {
                        const textLength = editorStateAboutUs
                          .getCurrentContent()
                          .getPlainText()?.length;
                        if (val && textLength >= charCount) {
                          toast.dark(
                            `Allowed character limit is ${charCount} characters only`
                          );
                          return "handled";
                        }
                        return "not-handled";
                      }}
                      handlePastedText={(input) => {
                        const inputLength = editorStateAboutUs
                          .getCurrentContent()
                          .getPlainText()?.length;
                        let remainingLength = charCount - inputLength;
                        if (input?.length + inputLength >= charCount) {
                          const newContent = Modifier.insertText(
                            editorStateAboutUs.getCurrentContent(),
                            editorStateAboutUs.getSelection(),
                            input.slice(0, remainingLength)
                          );
                          this.onAboutUsChange(
                            EditorState.push(
                              editorStateAboutUs,
                              newContent,
                              "insert-characters"
                            )
                          );
                          toast.dark(
                            `Allowed character limit is ${charCount} characters only`
                          );
                          return true;
                        } else {
                          return false;
                        }
                      }}
                    />
                    <div>
                      <Typography
                        className={classNames(
                          classes.floatRight,
                          classes.verySmallTopMargin
                        )}
                        color="secondary"
                      >
                        {editorStateAboutUs
                          ?.getCurrentContent()
                          .getPlainText()
                          .trimStart().length +
                          "/" +
                          charCount}{" "}
                        characters
                      </Typography>
                    </div>
                    {this.state.isAboutUsRequire ? (
                      <FormHelperText error={this.state.isAboutUsRequire}>
                        {MESSAGE_FIELD_IS_REQUIRED}
                      </FormHelperText>
                    ) : !this.state.isValidAboutUs ||
                      this.state.isEmptyAboutUs ? (
                      <FormHelperText
                        error={
                          !this.state.isValidAboutUs ||
                          this.state.isEmptyAboutUs
                        }
                      >
                        {"Min 25 charachers required"}
                      </FormHelperText>
                    ) : null}
                  </Grid>
                  <div
                    className={classNames(
                      classes.mediumTopMargin,
                      classes.verySmallBottomMargin
                    )}
                  >
                    <FormLabel className={classes.subHeader}>
                      Job Description
                    </FormLabel>
                  </div>
                  <div
                    className={classNames(
                      classes.smallTopMargin,
                      classes.noBottomMargin
                    )}
                  >
                    <FormLabel className={classes.mediumHeader} required>
                      About Role
                    </FormLabel>
                  </div>
                  <Grid
                    item
                    xs={12}
                    sm={12}
                    md={12}
                    lg={12}
                    xl={12}
                    className={classes.customSmallTopMargin}
                  >
                    <Editor
                      toolbar={{
                        options: [
                          "inline",
                          "blockType",
                          "fontSize",
                          "fontFamily",
                          "list",
                          "textAlign",
                          "colorPicker",
                          "link",
                          "remove",
                          "history",
                        ],
                        inline: {
                          options: [
                            "bold",
                            "italic",
                            "underline",
                            "strikethrough",
                          ],
                          inDropdown: true,
                        },
                        list: {
                          options: ["unordered", "ordered"],
                          inDropdown: true,
                        },
                        textAlign: { inDropdown: true },
                        link: { inDropdown: true },
                        history: { inDropdown: true },
                      }}
                      editorState={editorStateJobDescription}
                      onEditorStateChange={this.onJobDescriptionChange}
                      onChange={this.handleInputChange("jobDescription")}
                      placeholder="Give a brief description about the role"
                      handleBeforeInput={(val) => {
                        const textLength = editorStateJobDescription
                          .getCurrentContent()
                          .getPlainText().length;
                        if (val && textLength >= charCount) {
                          toast.dark(
                            `Allowed character limit is ${charCount} characters only`
                          );
                          return "handled";
                        }
                        return "not-handled";
                      }}
                      handlePastedText={(input) => {
                        const inputLength = editorStateJobDescription
                          .getCurrentContent()
                          .getPlainText().length;
                        let remainingLength = charCount - inputLength;
                        if (input.length + inputLength >= charCount) {
                          const newContent = Modifier.insertText(
                            editorStateJobDescription.getCurrentContent(),
                            editorStateJobDescription.getSelection(),
                            input.slice(0, remainingLength)
                          );
                          this.onJobDescriptionChange(
                            EditorState.push(
                              editorStateJobDescription,
                              newContent,
                              "insert-characters"
                            )
                          );
                          toast.dark(
                            `Allowed character limit is ${charCount} characters only`
                          );
                          return true;
                        } else {
                          return false;
                        }
                      }}
                    />
                    {this.state.isPositionDescriptionRequire ? (
                      <FormHelperText
                        error={this.state.isPositionDescriptionRequire}
                      >
                        {MESSAGE_FIELD_IS_REQUIRED}
                      </FormHelperText>
                    ) : !this.state.isValidPositionDescription ? (
                      <FormHelperText
                        error={!this.state.isValidPositionDescription}
                      >
                        {"Min 25 charachers required"}
                      </FormHelperText>
                    ) : null}
                    <Typography
                      className={classNames(
                        classes.floatRight,
                        classes.verySmallTopMargin
                      )}
                      color="secondary"
                    >
                      {editorStateJobDescription
                        ?.getCurrentContent()
                        .getPlainText().length +
                        "/" +
                        charCount}{" "}
                      characters
                    </Typography>
                  </Grid>
                  <div
                    className={classNames(
                      classes.mediumTopMargin,
                      classes.noBottomMargin
                    )}
                  >
                    <FormLabel className={classes.mediumHeader}>
                      Responsibilities
                    </FormLabel>
                  </div>
                  <Grid
                    item
                    xs={12}
                    sm={12}
                    md={12}
                    lg={12}
                    xl={12}
                    className={classes.customSmallTopMargin}
                  >
                    <Editor
                      toolbar={{
                        options: [
                          "inline",
                          "blockType",
                          "fontSize",
                          "fontFamily",
                          "list",
                          "textAlign",
                          "colorPicker",
                          "link",
                          "remove",
                          "history",
                        ],
                        inline: {
                          options: [
                            "bold",
                            "italic",
                            "underline",
                            "strikethrough",
                          ],
                          inDropdown: true,
                        },
                        list: {
                          options: ["unordered", "ordered"],
                          inDropdown: true,
                        },
                        textAlign: { inDropdown: true },
                        link: { inDropdown: true },
                        history: { inDropdown: true },
                      }}
                      editorState={additionresponsibilities}
                      onEditorStateChange={this.onAdditionresponsibilities}
                      onChange={this.handleInputChange(
                        "additionResponsibilitesState"
                      )}
                      placeholder={
                        "Give a brief description about additional responsibilities"
                      }
                      handleBeforeInput={(val) => {
                        const textLength = additionresponsibilities
                          .getCurrentContent()
                          .getPlainText().length;
                        if (val && textLength >= charCount) {
                          toast.dark(
                            `Allowed character limit is ${charCount} characters only`
                          );
                          return "handled";
                        }
                        return "not-handled";
                      }}
                      handlePastedText={(input) => {
                        const inputLength = additionresponsibilities
                          .getCurrentContent()
                          .getPlainText().length;
                        let remainingLength = charCount - inputLength;
                        if (input.length + inputLength >= charCount) {
                          const newContent = Modifier.insertText(
                            additionresponsibilities.getCurrentContent(),
                            additionresponsibilities.getSelection(),
                            input.slice(0, remainingLength)
                          );
                          this.onJobDescriptionChange(
                            EditorState.push(
                              additionresponsibilities,
                              newContent,
                              "insert-characters"
                            )
                          );
                          toast.dark(
                            `Allowed character limit is ${charCount} characters only`
                          );
                          return true;
                        } else {
                          return false;
                        }
                      }}
                    />
                    <Typography
                      className={classNames(
                        classes.floatRight,
                        classes.verySmallTopMargin
                      )}
                      color="secondary"
                    >
                      {additionresponsibilities
                        ?.getCurrentContent()
                        .getPlainText().length +
                        "/" +
                        charCount}{" "}
                      characters
                    </Typography>
                  </Grid>
                  <div
                    className={classNames(
                      classes.mediumTopMargin,
                      classes.noBottomMargin
                    )}
                  >
                    <FormLabel className={classes.mediumHeader}>
                      Qualifications
                    </FormLabel>
                  </div>
                  <Grid
                    item
                    xs={12}
                    sm={12}
                    md={12}
                    lg={12}
                    xl={12}
                    className={classes.customSmallTopMargin}
                  >
                    <Editor
                      toolbar={{
                        options: [
                          "inline",
                          "blockType",
                          "fontSize",
                          "fontFamily",
                          "list",
                          "textAlign",
                          "colorPicker",
                          "link",
                          "remove",
                          "history",
                        ],
                        inline: {
                          options: [
                            "bold",
                            "italic",
                            "underline",
                            "strikethrough",
                          ],
                          inDropdown: true,
                        },
                        list: {
                          options: ["unordered", "ordered"],
                          inDropdown: true,
                        },
                        textAlign: { inDropdown: true },
                        link: { inDropdown: true },
                        history: { inDropdown: true },
                      }}
                      editorState={editorcandidateWillBring}
                      onEditorStateChange={this.onCandidateWillBring}
                      onChange={this.handleInputChange("willSuccessfullState")}
                      placeholder={"Qualifications"}
                      handleBeforeInput={(val) => {
                        const textLength = editorcandidateWillBring
                          .getCurrentContent()
                          .getPlainText().length;
                        if (val && textLength >= charCount) {
                          toast.dark(
                            `Allowed character limit is ${charCount} characters only`
                          );
                          return "handled";
                        }
                        return "not-handled";
                      }}
                      handlePastedText={(input) => {
                        const inputLength = editorcandidateWillBring
                          .getCurrentContent()
                          .getPlainText().length;
                        let remainingLength = charCount - inputLength;
                        if (input.length + inputLength >= charCount) {
                          const newContent = Modifier.insertText(
                            editorcandidateWillBring.getCurrentContent(),
                            editorcandidateWillBring.getSelection(),
                            input.slice(0, remainingLength)
                          );
                          this.onCandidateWillBring(
                            EditorState.push(
                              editorcandidateWillBring,
                              newContent,
                              "insert-characters"
                            )
                          );
                          toast.dark(
                            `Allowed character limit is ${charCount} characters only`
                          );
                          return true;
                        } else {
                          return false;
                        }
                      }}
                    />
                    <Typography
                      className={classNames(
                        classes.floatRight,
                        classes.verySmallTopMargin
                      )}
                      color="secondary"
                    >
                      {editorcandidateWillBring
                        ?.getCurrentContent()
                        .getPlainText().length +
                        "/" +
                        charCount}{" "}
                      characters
                    </Typography>
                  </Grid>
                  <div
                    className={classNames(
                      classes.mediumTopMargin,
                      classes.noBottomMargin
                    )}
                  >
                    <FormLabel className={classes.mediumHeader} required>
                      Must Have Requirements/Non-Negotiables
                    </FormLabel>
                  </div>
                  <Grid
                    item
                    xs={12}
                    sm={12}
                    md={12}
                    lg={12}
                    xl={12}
                    className={classes.customSmallTopMargin}
                  >
                    <Editor
                      toolbar={{
                        options: [
                          "inline",
                          "blockType",
                          "fontSize",
                          "fontFamily",
                          "list",
                          "textAlign",
                          "colorPicker",
                          "link",
                          "remove",
                          "history",
                        ],
                        inline: {
                          options: [
                            "bold",
                            "italic",
                            "underline",
                            "strikethrough",
                          ],
                          inDropdown: true,
                        },
                        list: {
                          options: ["unordered", "ordered"],
                          inDropdown: true,
                        },
                        textAlign: { inDropdown: true },
                        link: { inDropdown: true },
                        history: { inDropdown: true },
                      }}
                      editorState={editorAbsoluteMustHave}
                      onEditorStateChange={this.onAbsoluteMustHave}
                      onChange={this.handleInputChange("absolueMustHaveState")}
                      placeholder="Must Have Requirements/Non-Negotiables"
                      handleBeforeInput={(val) => {
                        const textLength = editorAbsoluteMustHave
                          .getCurrentContent()
                          .getPlainText().length;
                        if (val && textLength >= charCount) {
                          toast.dark(
                            `Allowed character limit is ${charCount} characters only`
                          );
                          return "handled";
                        }
                        return "not-handled";
                      }}
                      handlePastedText={(input) => {
                        const inputLength = editorAbsoluteMustHave
                          .getCurrentContent()
                          .getPlainText().length;
                        let remainingLength = charCount - inputLength;
                        if (input.length + inputLength >= charCount) {
                          const newContent = Modifier.insertText(
                            editorAbsoluteMustHave.getCurrentContent(),
                            editorAbsoluteMustHave.getSelection(),
                            input.slice(0, remainingLength)
                          );
                          this.onAbsoluteMustHave(
                            EditorState.push(
                              editorAbsoluteMustHave,
                              newContent,
                              "insert-characters"
                            )
                          );
                          toast.dark(
                            `Allowed character limit is ${charCount} characters only`
                          );
                          return true;
                        } else {
                          return false;
                        }
                      }}
                    />
                    {this.state.isAbosultMustRequire ? (
                      <FormHelperText error={this.state.isAbosultMustRequire}>
                        {MESSAGE_FIELD_IS_REQUIRED}
                      </FormHelperText>
                    ) : !this.state.isValidAbsoluteMustHaves ? (
                      <FormHelperText
                        error={!this.state.isValidAbsoluteMustHaves}
                      >
                        {"Min 25 charachers required"}
                      </FormHelperText>
                    ) : null}

                    <Typography
                      className={classNames(
                        classes.floatRight,
                        classes.verySmallTopMargin
                      )}
                      color="secondary"
                    >
                      {editorAbsoluteMustHave
                        ?.getCurrentContent()
                        .getPlainText().length +
                        "/" +
                        charCount}{" "}
                      characters
                    </Typography>
                  </Grid>
                  {jobDetails?.benefits_and_perks &&
                    jobDetails?.benefits_and_perks !== "<p></p>" && (
                      <>
                        <div
                          className={classNames(
                            classes.mediumTopMargin,
                            classes.noBottomMargin
                          )}
                        >
                          <FormLabel className={classes.subHeader}>
                            Executive Perks
                          </FormLabel>
                        </div>
                        <Grid
                          item
                          xs={12}
                          sm={12}
                          md={12}
                          lg={12}
                          xl={12}
                          className={classNames(
                            classes.customSmallTopMargin,
                            classes.smallBottomMargin
                          )}
                        >
                          <Editor
                            toolbar={{
                              options: [
                                "inline",
                                "blockType",
                                "fontSize",
                                "fontFamily",
                                "list",
                                "textAlign",
                                "colorPicker",
                                "link",
                                "remove",
                                "history",
                              ],
                              inline: {
                                options: [
                                  "bold",
                                  "italic",
                                  "underline",
                                  "strikethrough",
                                ],
                                inDropdown: true,
                              },
                              list: {
                                options: ["unordered", "ordered"],
                                inDropdown: true,
                              },
                              textAlign: { inDropdown: true },
                              link: { inDropdown: true },
                              history: { inDropdown: true },
                            }}
                            editorState={editorStateBenefitsAndPerks}
                            onEditorStateChange={this.onBenefitsAndPerksChange}
                            onChange={this.handleInputChange(
                              "benefitsAndPerks"
                            )}
                            placeholder="Add any benefits or perks for this role here"
                            handleBeforeInput={(val) => {
                              const textLength = editorStateBenefitsAndPerks
                                .getCurrentContent()
                                .getPlainText().length;
                              if (val && textLength >= charCount) {
                                toast.dark(
                                  `Allowed character limit is ${charCount} characters only`
                                );
                                return "handled";
                              }
                              return "not-handled";
                            }}
                            handlePastedText={(input) => {
                              const inputLength = editorStateBenefitsAndPerks
                                .getCurrentContent()
                                .getPlainText().length;
                              let remainingLength = charCount - inputLength;
                              if (input.length + inputLength >= charCount) {
                                const newContent = Modifier.insertText(
                                  editorStateBenefitsAndPerks.getCurrentContent(),
                                  editorStateBenefitsAndPerks.getSelection(),
                                  input.slice(0, remainingLength)
                                );
                                this.onBenefitsAndPerksChange(
                                  EditorState.push(
                                    editorStateBenefitsAndPerks,
                                    newContent,
                                    "insert-characters"
                                  )
                                );
                                toast.dark(
                                  `Allowed character limit is ${charCount} characters only`
                                );
                                return true;
                              } else {
                                return false;
                              }
                            }}
                          />
                          <Typography
                            className={classNames(
                              classes.floatRight,
                              classes.verySmallTopMargin
                            )}
                            color="secondary"
                          >
                            {editorStateBenefitsAndPerks
                              ?.getCurrentContent()
                              .getPlainText().length +
                              "/" +
                              charCount}{" "}
                            characters
                          </Typography>
                        </Grid>
                      </>
                    )}
                  <div
                    className={classNames(
                      classes.mediumTopMargin,
                      classes.noBottomMargin
                    )}
                  >
                    <FormLabel className={classes.subHeader}>
                      Competencies
                    </FormLabel>
                  </div>
                  <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                    <Autocomplete
                      multiple
                      freeSolo
                      selectOnFocus
                      clearOnBlur
                      id="tags-outlined"
                      autoComplete
                      filterSelectedOptions
                      value={this.state.skillsReceived}
                      onChange={(event, newValue) => {
                        let valueExists = false;
                        valueExists = this.state?.skillsReceived?.some(
                          (value) =>
                            value?.toLowerCase() ===
                            event?.target.value?.toLowerCase()
                        );
                        if (newValue?.length <= 50 && !valueExists) {
                          this.setState({
                            skillsReceived: newValue,
                            isSkillsRequire: false,
                          });
                        }
                        if (newValue?.length === 0) {
                          this.setState({
                            isSkillsRequire: true,
                            isValidSkill: true,
                          });
                        }
                        newValue.map((ele) => {
                          if (ele?.length > 1000) {
                            this.setState({ isValidSkill: false });
                            return [];
                          } else {
                            this.setState({ isValidSkill: true });
                            return [];
                          }
                        });
                      }}
                      getOptionDisabled={() =>
                        this.state.skillsReceived?.length === 50 ? true : false
                      }
                      renderTags={(value, getTagProps) =>
                        value.map((option, index) => {
                          if (
                            option?.length > 1000 ||
                            option.trim().length === 0
                          ) {
                            return [];
                          }
                          return (
                            <Chip
                              label={
                                <>
                                  <ViewMore length="30">
                                    {option.trim()}
                                  </ViewMore>
                                </>
                              }
                              {...getTagProps({ index })}
                              className={classes.chip}
                              style={{
                                height: "100%",
                                wordBreak: "break-word",
                                padding: "5px",
                                margin: "5px 4px 8px 0px",
                              }}
                            />
                          );
                        })
                      }
                      options={[]}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          id="outlined-multiline-static"
                          name="newSkill"
                          className={classes.customSmallTopMargin}
                          variant="outlined"
                          InputProps={{
                            ...params.InputProps,
                            inputProps: {
                              ...params.inputProps,
                              maxLength: 1000,
                            },
                          }}
                          placeholder={
                            this.state.skills.length < 1
                              ? "Add desired competency for this role here and press enter"
                              : ""
                          }
                        />
                      )}
                    />
                  </Grid>
                </DialogContentText>
              </DialogContent>
              <DialogActions className={classes.dialogActions}>
                <Button
                  className={classes.justifyRight}
                  variant="contained"
                  color="primary"
                  size="large"
                  disableElevation
                  disabled={
                    jobDetails?.title === this.state.jobTitleValue &&
                    (jobDetails?.about_job
                      ? jobDetails?.about_job
                      : jobDetails?.about_role === this.state.jobDescription) &&
                    isEqual(jobDetails?.function, this.state.functionName) &&
                    isEqual(
                      jobDetails?.function_name,
                      this.state.otherFunctionName
                    ) &&
                    jobDetails?.level === this.state.jobLevel &&
                    jobDetails?.reports_to === this.state.reportsToTitleValue &&
                    jobDetails?.reports_to_name === this.state.reportsTo &&
                    isEqual(jobDetails?.industry, this.state.industry) &&
                    jobDetails?.minimum_years_of_experience ===
                      this.state.minimumExperience &&
                    this.state.baseSalary === this.state.minimumBaseSalary &&
                    jobDetails?.salary_config === this.state.salary_config &&
                    jobDetails?.degree === this.state.degree &&
                    isEqual(
                      this.state.majorValueSelected,
                      this.state.majorValueRecived
                    ) &&
                    jobDetails?.remote_or_onsite === this.state.remote &&
                    jobDetails?.travel_requirements ===
                      this.state.travelLevel &&
                    jobDetails?.degree_req_pref ===
                      this.state.degreeRequiredOrPreferred &&
                    jobDetails?.existing_role === this.state.role &&
                    jobDetails?.notice_period === this.state.howSoonToHire &&
                    jobDetails?.about_us === this.state.aboutUs &&
                    isEqual(
                      this.state.certificationsReceivedOld,
                      this.state.certificationSelected
                    ) &&
                    isEqual(
                      this.state.skillsReceivedOld,
                      this.state.skillsReceived
                    ) &&
                    isEqual(
                      this.state.startupStageReceived,
                      this.state.startupStage
                    ) &&
                    isEqual(this.state.teamSizeReceived, this.state.teamSize) &&
                    isEqual(
                      this.state.startupSizeReceived,
                      this.state.startupSize
                    ) &&
                    isEqual(
                      this.state.companyNamesArray,
                      this.state.receivedCompanies
                    ) &&
                    jobDetails?.candidate_value_addition ===
                      this.state.willSuccessfullState &&
                    jobDetails?.additional_responsibility ===
                      this.state.additionResponsibilitesState &&
                    jobDetails?.absolute_must_have ===
                      this.state.absolueMustHaveState &&
                    jobDetails?.job_type === this.state.confidenNormalValue &&
                    isEqual(
                      this.state.locationFromAPi,
                      JSON.parse(secureLocalStorage.getItem("locationDetails"))
                    ) &&
                    isEqual(
                      this.state.customIndustryValue,
                      jobDetails?.other_industry_text
                    ) &&
                    isEqual(
                      this.state.alternativeTiltes,
                      jobDetails?.alternate_titles
                        ? jobDetails?.alternate_titles
                        : []
                    ) &&
                    isEqual(
                      this.state.alternativeIndustry,
                      jobDetails?.alternate_industries
                        ? jobDetails?.alternate_industries
                        : []
                    )
                  }
                  onClick={(event) => {
                    event.preventDefault();
                    this.handleSaveClick(jobDetails?.id);
                  }}
                >
                  Save
                </Button>
              </DialogActions>
            </Dialog>

            {/* View Popup */}
            <Dialog
              open={this.state.openPreview}
              scroll={this.state.scroll}
              fullWidth
              aria-labelledby="scroll-dialog-title"
              aria-describedby="scroll-dialog-description"
              disableBackdropClick
              disableEscapeKeyDown
              maxWidth="md"
            >
              <DialogTitle
                id="customized-dialog-title"
                onClose={(event) => {
                  this.handleClose(event);
                }}
                className={classes.noPadding}
              >
                Job Preview
              </DialogTitle>
              <DialogContent dividers={this.state.scroll === "paper"}>
                <DialogContentText
                  component="div"
                  id="scroll-dialog-description"
                >
                  <img
                    src={jobDetails?.company?.logo}
                    alt={jobDetails?.company?.company_name}
                    variant="circular"
                    className={classNames(
                      classes.xLargeCompanyLogoAvatar,
                      jobDetails?.company?.logo === ""
                        ? classes.noImageAvatar
                        : null
                    )}
                    style={{ objectFit: "contain" }}
                  ></img>
                  <Typography
                    className={classNames(
                      classes.smallTopMargin,
                      classes.header
                    )}
                  >
                    {this.state.jobTitleValue}
                  </Typography>
                  <Typography component="div" color="primary">
                    {this.capitalize(jobDetails?.company?.company_name) || "-"}
                  </Typography>
                  <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                    <div className={classes.subHeader}>Basics</div>
                    <Card
                      className={classNames(
                        classes.fullWidth,
                        classes.customSmallTopMargin,
                        classes.customCardShadow
                      )}
                    >
                      <CardContent className={classes.customCard}>
                        <Grid container>
                          <Grid item xs={12} sm={12} md={12} lg={7} xl={7}>
                            <Typography
                              className={classes.mediumBottomMargin}
                              color="textSecondary"
                            >
                              Job Level
                            </Typography>
                          </Grid>
                          <Grid
                            className={classes.textAlignEnd}
                            item
                            xs={12}
                            sm={12}
                            md={12}
                            lg={5}
                            xl={5}
                          >
                            {this.state.jobLevel ? this.state.jobLevel : "-"}
                          </Grid>
                          <Grid item xs={12} sm={12} md={12} lg={7} xl={7}>
                            <Typography
                              className={classes.mediumBottomMargin}
                              color="textSecondary"
                            >
                              Reports To
                            </Typography>
                          </Grid>
                          <Grid
                            className={classes.textAlignEnd}
                            item
                            xs={12}
                            sm={12}
                            md={12}
                            lg={5}
                            xl={5}
                          >
                            {this.state.reportsToTitle
                              ? this.state.reportsToTitle
                              : "-"}
                          </Grid>
                          {this.state.reportsTo ? (
                            <>
                              <Grid item xs={12} sm={12} md={12} lg={7} xl={7}>
                                <Typography
                                  className={classes.mediumBottomMargin}
                                  color="textSecondary"
                                >
                                  Hiring Manager Name
                                </Typography>
                              </Grid>
                              <Grid
                                className={classes.textAlignEnd}
                                item
                                xs={12}
                                sm={12}
                                md={12}
                                lg={5}
                                xl={5}
                              >
                                {this.state.reportsTo
                                  ? this.state.reportsTo
                                  : "-"}
                              </Grid>
                            </>
                          ) : null}

                          <Grid item xs={12} sm={12} md={12} lg={7} xl={7}>
                            <Typography
                              className={classes.mediumBottomMargin}
                              color="textSecondary"
                            >
                              Industry
                            </Typography>
                          </Grid>
                          <Grid
                            className={classes.textAlignEnd}
                            item
                            xs={12}
                            sm={12}
                            md={12}
                            lg={5}
                            xl={5}
                          >
                            {this.state.industry === "Other"
                              ? !this.state.customIndustryValue
                                ? "Other"
                                : this.state.customIndustryValue
                              : this.state.industry
                              ? this.state.industry
                              : "-"}
                          </Grid>
                          <Grid item xs={12} sm={12} md={12} lg={7} xl={7}>
                            <Typography
                              className={classes.mediumBottomMargin}
                              color="textSecondary"
                            >
                              Function
                            </Typography>
                          </Grid>
                          <Grid
                            className={classes.textAlignEnd}
                            item
                            xs={12}
                            sm={12}
                            md={12}
                            lg={5}
                            xl={5}
                          >
                            {this.state.functionName !== "Other"
                              ? this.state.functionName
                              : (this.state.functionName
                                  ? this.state.functionName
                                  : "-") +
                                " - " +
                                (this.state.otherFunctionName
                                  ? this.state.otherFunctionName
                                  : "")}
                          </Grid>
                          <Grid item xs={12} sm={12} md={12} lg={7} xl={7}>
                            <Typography
                              className={classes.mediumBottomMargin}
                              color="textSecondary"
                            >
                              Company HQ Location
                            </Typography>
                          </Grid>
                          <Grid
                            className={classes.textAlignEnd}
                            item
                            xs={12}
                            sm={12}
                            md={12}
                            lg={5}
                            xl={5}
                          >
                            {(this.state.location?.city
                              ? this.state.location?.city
                              : "") +
                              (this.state.location?.state ? ", " : "") +
                              (this.state.location?.state
                                ? this.state.location?.state
                                : "") +
                              (this.state.location?.country ? ", " : "") +
                              (this.state.location?.country
                                ? this.state.location?.country
                                : "") +
                              (this.state.location?.city ||
                              this.state.location?.state ||
                              this.state.location?.country
                                ? ""
                                : "-")}
                          </Grid>
                          <Grid item xs={12} sm={12} md={12} lg={7} xl={7}>
                            <Typography
                              className={classes.mediumBottomMargin}
                              color="textSecondary"
                            >
                              Minimum Years of Experience
                            </Typography>
                          </Grid>
                          <Grid
                            className={classes.textAlignEnd}
                            item
                            xs={12}
                            sm={12}
                            md={12}
                            lg={5}
                            xl={5}
                          >
                            {this.state.minimumExperience
                              ? this.state.minimumExperience
                              : "-"}
                          </Grid>
                          <Grid item xs={12} sm={12} md={12} lg={7} xl={7}>
                            <Typography
                              className={classes.mediumBottomMargin}
                              color="textSecondary"
                            >
                              Base Salary
                            </Typography>
                          </Grid>
                          <Grid
                            className={classes.textAlignEnd}
                            item
                            xs={12}
                            sm={12}
                            md={12}
                            lg={5}
                            xl={5}
                          >
                            {this.state.minimumBaseSalary === null &&
                            this.state.salary_config === 0
                              ? "Exclude salary"
                              : this.state.minimumBaseSalary === null
                              ? "-"
                              : this.state.salary_config === 1
                              ? `$${salaryValueFormat.format(
                                  this.state.minimumBaseSalary
                                )} ${`(Not visible to candidates)`}`
                              : `$${salaryValueFormat.format(
                                  this.state.minimumBaseSalary
                                )}`}
                          </Grid>
                          <Grid item xs={12} sm={12} md={12} lg={7} xl={7}>
                            <Typography
                              className={classes.mediumBottomMargin}
                              color="textSecondary"
                            >
                              Degree
                            </Typography>
                          </Grid>
                          <Grid
                            className={classes.textAlignEnd}
                            item
                            xs={12}
                            sm={12}
                            md={12}
                            lg={5}
                            xl={5}
                          >
                            {(this.state.degree ? this.state.degree : "") +
                              (this.state?.majorValue
                                ? "/" + this.state.majorValue?._source?.Major
                                : "") +
                              (this.state.degreeRequiredOrPreferred
                                ? " (" +
                                  this.state.degreeRequiredOrPreferred +
                                  ")"
                                : "-") || "-"}
                          </Grid>
                          <Grid item xs={12} sm={12} md={12} lg={7} xl={7}>
                            <Typography
                              className={classes.mediumBottomMargin}
                              color="textSecondary"
                            >
                              Work Location
                            </Typography>
                          </Grid>
                          <Grid
                            className={classes.textAlignEnd}
                            item
                            xs={12}
                            sm={12}
                            md={12}
                            lg={5}
                            xl={5}
                          >
                            {this.state.remote ? this.state.remote : "-"}
                          </Grid>
                          <Grid item xs={12} sm={12} md={12} lg={7} xl={7}>
                            <Typography
                              className={classes.mediumBottomMargin}
                              color="textSecondary"
                            >
                              Travel Requirements
                            </Typography>
                          </Grid>
                          <Grid
                            className={classes.textAlignEnd}
                            item
                            xs={12}
                            sm={12}
                            md={12}
                            lg={5}
                            xl={5}
                          >
                            {this.state.travelLevel
                              ? this.state.travelLevel
                              : "-"}
                          </Grid>
                          <Grid item xs={12} sm={12} md={12} lg={7} xl={7}>
                            <Typography
                              color="textSecondary"
                              className={classes.mediumBottomMargin}
                            >
                              Company Type
                            </Typography>
                          </Grid>
                          <Grid
                            className={classes.textAlignEnd}
                            item
                            xs={12}
                            sm={12}
                            md={12}
                            lg={5}
                            xl={5}
                          >
                            {this.state.startupStage
                              ? this.state.startupStage.join(", ")
                              : "-"}
                          </Grid>
                          <Grid item xs={12} sm={12} md={12} lg={7} xl={7}>
                            <Typography
                              color="textSecondary"
                              className={classes.mediumBottomMargin}
                            >
                              Company Size
                            </Typography>
                          </Grid>
                          <Grid
                            className={classes.textAlignEnd}
                            item
                            xs={12}
                            sm={12}
                            md={12}
                            lg={5}
                            xl={5}
                          >
                            {this.state.startupSize
                              ? this.state.startupSize.join(", ")
                              : "-"}
                          </Grid>
                          <Grid item xs={12} sm={12} md={12} lg={7} xl={7}>
                            <Typography
                              color="textSecondary"
                              className={classes.mediumBottomMargin}
                            >
                              Team Size
                            </Typography>
                          </Grid>
                          <Grid
                            className={classes.textAlignEnd}
                            item
                            xs={12}
                            sm={12}
                            md={12}
                            lg={5}
                            xl={5}
                          >
                            {this.state.teamSize
                              ? this.state.teamSize.join(", ")
                              : "-"}
                          </Grid>
                          <Grid item xs={12} sm={12} md={12} lg={7} xl={7}>
                            <Typography
                              color="textSecondary"
                              className={classes.mediumBottomMargin}
                            >
                              New Role/Existing Role
                            </Typography>
                          </Grid>
                          <Grid
                            className={classes.textAlignEnd}
                            item
                            xs={12}
                            sm={12}
                            md={12}
                            lg={5}
                            xl={5}
                          >
                            {this.state.role ? this.state.role : "-"}
                          </Grid>
                          <Grid item xs={12} sm={12} md={12} lg={7} xl={7}>
                            <Typography
                              color="textSecondary"
                              className={classes.mediumBottomMargin}
                            >
                              How soon do you want to fill this role?
                            </Typography>
                          </Grid>
                          <Grid
                            className={classes.textAlignEnd}
                            item
                            xs={12}
                            sm={12}
                            md={12}
                            lg={5}
                            xl={5}
                          >
                            {this.state.howSoonToHire
                              ? this.state.howSoonToHire
                              : "-"}
                          </Grid>
                          <Grid item xs={12} sm={12} md={12} lg={7} xl={7}>
                            <Typography
                              color="textSecondary"
                              className={classes.mediumBottomMargin}
                            >
                              Confidential/Normal Role
                            </Typography>
                          </Grid>
                          <Grid
                            className={classes.textAlignEnd}
                            item
                            xs={12}
                            sm={12}
                            md={12}
                            lg={5}
                            xl={5}
                          >
                            {this.state.confidenNormalValue
                              ? this.state.confidenNormalValue
                              : "-"}
                          </Grid>
                          <Grid item xs={12} sm={12} md={12} lg={7} xl={7}>
                            <Typography
                              className={classes.mediumBottomMargin}
                              color="textSecondary"
                            >
                              Alternative Titles
                            </Typography>
                          </Grid>
                          <Grid
                            className={classes.textAlignEnd}
                            item
                            xs={12}
                            sm={12}
                            md={12}
                            lg={5}
                            xl={5}
                          >
                            <Typography>
                              {" "}
                              {jobDetails?.alternate_titles?.join(", ") || "-"}
                            </Typography>
                          </Grid>
                          <Grid item xs={12} sm={12} md={12} lg={7} xl={7}>
                            <Typography
                              className={classes.mediumBottomMargin}
                              color="textSecondary"
                            >
                              Alternative Industries
                            </Typography>
                          </Grid>
                          <Grid
                            className={classes.textAlignEnd}
                            item
                            xs={12}
                            sm={12}
                            md={12}
                            lg={5}
                            xl={5}
                          >
                            <Typography>
                              {" "}
                              {jobDetails?.alternate_industries?.join(", ") ||
                                "-"}
                            </Typography>
                          </Grid>
                        </Grid>
                      </CardContent>
                    </Card>
                  </Grid>

                  <div
                    className={classNames(
                      classes.subHeader,
                      classes.mediumTopMargin,
                      classes.noBottomMargin
                    )}
                  >
                    Sample Corporations
                  </div>
                  <Grid
                    item
                    xs={12}
                    sm={12}
                    md={12}
                    lg={12}
                    xl={12}
                    className={classes.verySmallTopMargin}
                  >
                    {this.state.sampleCompany?.map((company, index) => {
                      return (
                        <div className={classes.displayInline} key={index}>
                          <Chip
                            label={
                              <>
                                <ViewMore length="30">
                                  {company.company_name}
                                </ViewMore>
                              </>
                            }
                            style={{
                              height: "100%",
                              padding: "5px",
                              margin: "5px 4px 8px 0px",
                              lineBreak: "anywhere",
                            }}
                          />
                        </div>
                      );
                    })}
                    {this.state?.sampleCompany === null ? (
                      <Typography>-</Typography>
                    ) : (
                      ""
                    )}
                  </Grid>

                  <div
                    className={classNames(
                      classes.subHeader,
                      classes.mediumTopMargin,
                      classes.noBottomMargin
                    )}
                  >
                    Professional Certifications
                  </div>
                  {this.state.certifications?.length > 0 ? (
                    <>
                      <Grid
                        item
                        xs={12}
                        sm={12}
                        md={12}
                        lg={12}
                        xl={12}
                        className={classes.verySmallTopMargin}
                      >
                        {this.state.openPreview &&
                          this.state.certifications?.map((val, index) => {
                            return (
                              <div
                                className={classes.displayInline}
                                key={index}
                              >
                                <Chip
                                  label={
                                    <>
                                      <ViewMore length="30">{val}</ViewMore>
                                    </>
                                  }
                                  style={{
                                    height: "100%",
                                    padding: "5px",
                                    margin: "5px 4px 8px 0px",
                                    lineBreak: "anywhere",
                                  }}
                                />
                              </div>
                            );
                          })}
                      </Grid>
                    </>
                  ) : (
                    "-"
                  )}

                  <div
                    className={classNames(
                      classes.subHeader,
                      classes.mediumTopMargin,
                      classes.noBottomMargin
                    )}
                  >
                    About Us
                  </div>
                  <Grid
                    item
                    xs={12}
                    sm={12}
                    md={12}
                    lg={12}
                    xl={12}
                    className={classes.customSmallTopMargin}
                  >
                    <Editor
                      toolbarHidden
                      readOnly
                      editorState={editorStateAboutUs}
                    />
                  </Grid>
                  <div className={classNames(classes.mediumTopMargin)}>
                    <FormLabel className={classes.subHeader}>
                      Job Description
                    </FormLabel>
                  </div>
                  <div
                    className={classNames(
                      classes.mediumHeader,
                      classes.mediumTopMargin,
                      classes.noBottomMargin
                    )}
                  >
                    About Role
                  </div>
                  <Grid
                    item
                    xs={12}
                    sm={12}
                    md={12}
                    lg={12}
                    xl={12}
                    className={classes.customSmallTopMargin}
                  >
                    <Editor
                      toolbarHidden
                      readOnly
                      editorState={editorStateJobDescription}
                    />
                  </Grid>

                  <div
                    className={classNames(
                      classes.mediumHeader,
                      classes.mediumTopMargin,
                      classes.noBottomMargin
                    )}
                  >
                    Responsibilities
                  </div>
                  <Grid
                    item
                    xs={12}
                    sm={12}
                    md={12}
                    lg={12}
                    xl={12}
                    className={classes.customSmallTopMargin}
                  >
                    <Editor
                      toolbarHidden
                      readOnly
                      editorState={additionresponsibilities}
                    />
                  </Grid>

                  <div
                    className={classNames(
                      classes.mediumHeader,
                      classes.mediumTopMargin,
                      classes.noBottomMargin
                    )}
                  >
                    Qualifications
                  </div>
                  <Grid
                    item
                    xs={12}
                    sm={12}
                    md={12}
                    lg={12}
                    xl={12}
                    className={classes.customSmallTopMargin}
                  >
                    <Editor
                      toolbarHidden
                      readOnly
                      editorState={editorcandidateWillBring}
                    />
                  </Grid>
                  <div
                    className={classNames(
                      classes.mediumHeader,
                      classes.mediumTopMargin,
                      classes.noBottomMargin
                    )}
                  >
                    Must Have Requirements/Non-Negotiables
                  </div>
                  <Grid
                    item
                    xs={12}
                    sm={12}
                    md={12}
                    lg={12}
                    xl={12}
                    className={classes.customSmallTopMargin}
                  >
                    <Editor
                      toolbarHidden
                      readOnly
                      editorState={editorAbsoluteMustHave}
                    />
                  </Grid>

                  {this.state.benefitsAndPerks ? (
                    <>
                      <div
                        className={classNames(
                          classes.mediumHeader,
                          classes.mediumTopMargin,
                          classes.noBottomMargin
                        )}
                      >
                        Executive Perks
                      </div>
                      <Grid
                        item
                        xs={12}
                        sm={12}
                        md={12}
                        lg={12}
                        xl={12}
                        className={classes.customSmallTopMargin}
                      >
                        <Editor
                          toolbarHidden
                          readOnly
                          editorState={editorStateBenefitsAndPerks}
                        />
                      </Grid>
                    </>
                  ) : null}

                  <div
                    className={classNames(
                      classes.subHeader,
                      classes.mediumTopMargin,
                      classes.noBottomMargin
                    )}
                  >
                    Competencies
                  </div>
                  <Grid
                    item
                    xs={12}
                    sm={12}
                    md={12}
                    lg={12}
                    xl={12}
                    className={classes.verySmallTopMargin}
                  >
                    {typeof this.state?.skills !== "string"
                      ? this?.state?.skills?.length > 0
                        ? this.state?.skills?.map((skill, index) => {
                            return (
                              <div
                                className={classes.displayInline}
                                key={index}
                              >
                                <Chip
                                  label={
                                    <>
                                      <ViewMore length="30">{skill}</ViewMore>
                                    </>
                                  }
                                  className={classes.chip}
                                  style={{
                                    height: "100%",
                                    padding: "5px",
                                    margin: "5px 4px 8px 0px",
                                  }}
                                />
                              </div>
                            );
                          })
                        : "-"
                      : null}
                  </Grid>
                </DialogContentText>
              </DialogContent>
              <DialogActions className={classes.dialogActions}>
                <Button
                  className={classNames(
                    classes.defaultButton,
                    classes.justifyRight
                  )}
                  variant="contained"
                  disableElevation
                  size="large"
                  onClick={(event) => {
                    event.preventDefault();
                    this.handleEditClick(jobDetails);
                  }}
                  disabled={
                    jobDetails?.job_status === "Close" ||
                    jobDetails?.job_status === "Closed" ||
                    jobDetails?.job_status === "close" ||
                    jobDetails?.job_status === "closed"
                  }
                >
                  Edit
                </Button>
              </DialogActions>
            </Dialog>

            {/* Close Confirm Popup */}
            <Dialog
              open={this.state.openCloseConfirm}
              aria-labelledby="alert-dialog-title"
              aria-describedby="alert-dialog-description"
              fullWidth
              disableBackdropClick
              disableEscapeKeyDown
            >
              <DialogTitle>Confirm Close Job</DialogTitle>
              <DialogContent>
                <DialogContentText
                  id="alert-dialog-description"
                  className={classNames(
                    classes.largeFont,
                    classes.dailogTitleStyle
                  )}
                >
                  Are you sure you want to close your job posting?
                </DialogContentText>
              </DialogContent>
              <DialogActions>
                <Button
                  size="large"
                  disableElevation
                  onClick={this.handleCloseConfirmPopupClose}
                  style={{ background: "#D7D9DB", color: "#000" }}
                  variant="contained"
                  color="primary"
                >
                  Cancel
                </Button>
                <Button
                  size="large"
                  disableElevation
                  onClick={this.openCloseFormDialog}
                  variant="contained"
                  color="primary"
                  autoFocus
                >
                  Confirm
                </Button>
              </DialogActions>
            </Dialog>

            {/* Close Popup */}
            <Dialog
              open={this.state.openCloseFormPopup}
              aria-labelledby="form-dialog-title"
              fullWidth
              disableBackdropClick
              disableEscapeKeyDown
            >
              <DialogTitle
                id="form-dialog-title"
                onClose={this.handleCloseFormPopupClose}
              >
                Close Job
              </DialogTitle>
              <DialogContent>
                <DialogContentText
                  className={classNames(
                    classes.largeFont,
                    classes.dailogTitleStyle
                  )}
                >
                  Why do you want to close your job posting?
                </DialogContentText>
                <TextField
                  autoFocus
                  required
                  margin="dense"
                  id="answer"
                  label="Answer"
                  type="text"
                  fullWidth
                  value={this.state.closeComment}
                  onChange={this.handleInputChange("closeComment")}
                  placeholder="Your reason"
                  error={this.state.isValidCloseComment ? false : true}
                  helperText={
                    !this.state.isValidCloseComment
                      ? "Answer should contain letters only"
                      : ""
                  }
                />
              </DialogContent>
              <DialogActions>
                <Button
                  size="large"
                  disableElevation
                  onClick={this.handleCloseFormPopupClose}
                  style={{ background: "#D7D9DB", color: "#000" }}
                  variant="contained"
                  color="primary"
                >
                  Cancel
                </Button>
                <Button
                  size="large"
                  disableElevation
                  onClick={() =>
                    this.handleCloseJob(this.state.closeComment, jobDetails.id)
                  }
                  variant="contained"
                  color="primary"
                >
                  Close Job
                </Button>
              </DialogActions>
            </Dialog>

            {/* View History Popup */}
            <Dialog
              open={this.state.historyPopupOpen}
              aria-labelledby="alert-dialog-title"
              aria-describedby="alert-dialog-description"
              fullWidth
              disableBackdropClick
              disableEscapeKeyDown
            >
              <DialogTitle
                id="alert-dialog-title"
                onClose={(event) => {
                  event.preventDefault();
                  this.closeHistoryPopup();
                }}
              >
                Candidate Recommendation History
              </DialogTitle>
              <DialogContent>
                <DialogContentText id="alert-dialog-description">
                  {/* {historyData?.candidate?.first_name && historyData?.candidate?.last_name &&
                                    <Typography variant='h5' align='center' className={classes.colorDefault}>
                                        {historyData?.candidate?.first_name + ' ' + historyData?.candidate?.last_name}
                                    </Typography>
                                } */}
                  <Grid container>
                    <Typography
                      align="left"
                      className={classNames(
                        classes.colorDefault,
                        classes.smallTopMargin
                      )}
                      style={{
                        display: "flex",
                        justifyContent: "flex-start",
                        alignItems: "center",
                      }}
                    >
                      <b>Candidate Title:</b> &nbsp;{" "}
                      {historyData?.candidate?.current_title}
                    </Typography>
                    <Typography
                      color="textPrimary"
                      align="left"
                      className={classes.smallTopMargin}
                      style={{
                        display: "flex",
                        justifyContent: "flex-start",
                        alignItems: "center",
                      }}
                    >
                      <b>Candidate Location:</b> &nbsp;
                      {/* <LocationOnIcon className={classNames(classes.smallFont, classes.alignBaslineMiddle, classes.colorDefault)}></LocationOnIcon> */}
                      <span
                        className={classNames(
                          classes.alignBaslineMiddle,
                          classes.verySmallMarginLeft
                        )}
                      >
                        {this.capitalize(
                          historyData?.candidate?.location?.city || "-"
                        ) +
                          ", " +
                          this.capitalize(
                            historyData?.candidate?.location?.state || "-"
                          ) +
                          ", " +
                          this.capitalize(
                            historyData?.candidate?.location?.country || "-"
                          )}
                      </span>
                    </Typography>
                  </Grid>
                  <Grid container className={classes.smallTopMargin}>
                    <Typography
                      className={classNames(
                        classes.colorDefault,
                        classes.textAlignLeft
                      )}
                    >
                      <b>Client:</b> &nbsp;{" "}
                      {this.capitalize(historyData?.candidate?.client)}
                    </Typography>
                  </Grid>
                  {/* <Typography className={classes.colorDefault}>
                                        {this.props.authentication?.recruiterStatusDetails?.designation ?
                                            this.capitalize(this.props.authentication?.recruiterStatusDetails?.designation) + ' at ' + this.capitalize(this.props.authentication?.recruiterStatusDetails?.company?.company_name) : '-'}
                                    </Typography> */}
                  <Divider className={classes.mediumTopMargin}></Divider>
                  {historyData?.candidate?.status?.length > 0 ? (
                    <div>
                      <Grid container className={classes.mediumTopMargin}>
                        <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
                          <Typography
                            align="center"
                            className={classNames(
                              classes.colorDefault,
                              classes.boldFont
                            )}
                          >
                            Stage
                          </Typography>
                        </Grid>
                        <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
                          <Typography
                            align="center"
                            className={classNames(
                              classes.colorDefault,
                              classes.boldFont
                            )}
                          >
                            Date
                          </Typography>
                        </Grid>
                      </Grid>
                      <Paper
                        className={classNames(
                          classes.customHighlightsPaper,
                          classes.mediumTopMargin
                        )}
                      >
                        {historyData?.candidate?.status.map((item, index) => (
                          <div>
                            <Grid
                              container
                              style={{ marginTop: "5%", marginBottom: "3%" }}
                            >
                              <Grid item xs={8} sm={8} md={8} lg={8} xl={8}>
                                <Typography
                                  className={classes.smallBottomMargin}
                                  color="textSecondary"
                                >
                                  {item?.label}:{" "}
                                </Typography>
                                <Typography
                                  component="div"
                                  className={classes.smallBottomMargin}
                                  color="textSecondary"
                                >
                                  {item?.action ? item?.action : null}{" "}
                                </Typography>
                              </Grid>
                              <Grid item xs={4} sm={4} md={4} lg={4} xl={4}>
                                <Typography
                                  className={classNames(
                                    classes.smallBottomMargin,
                                    classes.colorDefault
                                  )}
                                >
                                  {moment(item?.completed_on).format(
                                    "MMM DD, YYYY"
                                  )}
                                </Typography>
                              </Grid>
                            </Grid>
                            {index !==
                            historyData?.candidate?.status?.length - 1 ? (
                              <Divider></Divider>
                            ) : null}
                          </div>
                        ))}
                      </Paper>
                    </div>
                  ) : (
                    <Typography
                      align="center"
                      className={classes.mediumTopMargin}
                      color="textSecondary"
                    >
                      No history available
                    </Typography>
                  )}
                </DialogContentText>
              </DialogContent>
            </Dialog>
          </div>
          <GetHelp />
          {/* {this.state.openScheduleInterview ? <> <SchedulingPopup handlePreview={this.handlePreview} closeScheduleInterview={this.closeScheduleInterview} openScheduleInterview={this.state.openScheduleInterview} scheduleInterviewType={this.state.scheduleInterviewType} /> </> : null} */}
          {this.state.openEmailPreview ? (
            <PreviewEmaildailog
              open={this.state.openEmailPreview}
              editPreview={this.editPreview}
              onSend={this.sendEmail}
              onClose={this.onClosePreview}
            />
          ) : null}
          <ShareProfile
            candidateId={this.state.shareProfile_candidate_id}
            recommendationId={this.state.shareProfile_rec_id}
            open={this.state.openShareProfile}
            onClose={this.handleCloseShareProfile}
          />
        </main>
      </div>
    );
  }
}

JobDetails.propTypes = {
  classes: PropTypes.object.isRequired,
};

function mapStateToProps(state) {
  return state;
}

const connectedJobDetailsPage = withRouter(
  connect(mapStateToProps, null, null, {
    pure: false,
  })(withStyles(styles)(JobDetails))
);

export { connectedJobDetailsPage as JobDetails };
