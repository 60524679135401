import React, { Component, Fragment } from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
// import AppBar from '../../core/appbar';
import Grid from "@material-ui/core/Grid";
import { connect } from "react-redux";
import Typography from "@material-ui/core/Typography";
import { withRouter } from "react-router-dom";
import { Link } from "@material-ui/core";
import classNames from "classnames";
import ProductInfoImage from "../../../assets/product_info.svg";
import config from "../../../config/config";

// class Copyright extends Component {
//     render() {
//         return (
//             <Typography variant="body2" color="textSecondary" align="center">
//               {'Copyright © '}
//               <Link color="inherit" href="https://material-ui.com/">
//                 succeedsmart
//               </Link>{' '}
//               {new Date().getFullYear()}
//               {'.'}
//             </Typography>
//         );
//     }
// }

const styles = (theme) => ({
  root: {
    marginTop: "90px",
    color: "#000000",
  },
  image: {
    backgroundImage: `url(${ProductInfoImage})`, // 'url(https://source.unsplash.com/random)',
    backgroundRepeat: "no-repeat",
    backgroundColor:
      theme.palette.type === "light"
        ? theme.palette.grey[50]
        : theme.palette.grey[900],
    backgroundSize: "cover",
  },
  paper: {
    margin: theme.spacing(0, 10),
    marginTop: "3%",
  },
  customPaper: {
    margin: theme.spacing(3, 10),

    color: theme.palette.default.main,
  },
  customSubPaper: {
    display: "inline-flex",
    width: "28%",
    textAlign: "justify",
  },
  customContent: {
    margin: theme.spacing(3, 10),
    textAlign: "justify",
  },
  customForm: {
    margin: theme.spacing(3, 10),
    alignItems: "center",
  },
  customBadge: {
    backgroundColor: "#FBC112",
    height: "50px",
    minWidth: "50px",
    borderRadius: "50%",
    fontSize: "large",
  },
  mediumLineHeight: {
    lineHeight: "4.5",
  },
  forgotLink: {
    cursor: "pointer",
    "&:hover": {
      textDecoration: "underline",
    },
  },
  verySmallTopMargin: {
    marginTop: "1%",
  },
  smallTopMargin: {
    marginTop: "2%",
  },
  mediumTopMargin: {
    marginTop: "4%",
  },
  customFlex: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  xxLargeFont: {
    fontSize: "xx-large",
  },
  bottomSmall: {
    marginBottom: "9%",
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  fontBold: {
    fontWeight: "bold",
  },
  submit: {
    margin: theme.spacing(5, 0, 2),
    borderRadius: "4px !important",
  },
  alignRight: {
    textAlign: "right",
  },
  margin: {
    marginTop: theme.spacing(2),
  },
  withoutLabel: {
    marginTop: theme.spacing(3),
  },
  textField: {
    width: "100%",
  },
  disabledSignIn: {
    cursor: "not-allowed",
    borderRadius: "4px !important",
  },
  toolbar: {
    padding: "1% !important",
    left: "1.5% !important",
  },
  responsive: {
    maxWidth: "100% !important",
    height: "auto !important",
    width: "auto !important",
    alignSelf: "center !important",
    minWidth: "fit-content !important",
  },
  customDivider: {
    borderBottom: `1px solid ${theme.palette.secondary.main}`,
    width: "inherit",
    marginTop: "10%",
    flexShrink: 1,
  },
  alignLeft: {
    textAlign: "left !important",
  },
  paddedContent: {
    marginLeft: "30vw",
    marginRight: "30vw",
    marginTop: "2%",
  },
  inlineContent: {
    display: "flex",
    alignItems: "center",
    paddingLeft: "1.3vw",
  },
  justifyContent: {
    textAlign: "justify",
  },
  customLink: {
    cursor: "pointer",
    "&:hover": {
      textDecoration: "underline",
    },
    color: "#048475 !important",
  },
});

class DomainExists extends Component {
  render() {
    const { classes } = this.props;
    return (
      <>
        {/* <AppBar /> */}
        <Grid container component="main" className={classes.root}>
          <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
            <Grid className={classes.alignLeft}>
              <div className={classes.paddedContent}>
                <Typography
                  component="span"
                  align="left"
                  className={classNames(classes.xxLargeFont, classes.alignLeft)}
                >
                  Hold on!
                </Typography>
                <Typography component="div" className={classes.mediumTopMargin}>
                  It looks like someone from your corporation is already
                  onboard.
                </Typography>
                <Typography
                  component="div"
                  className={classNames(
                    classes.mediumTopMargin,
                    classes.fontBold
                  )}
                >
                  Next Steps:
                </Typography>
                <Typography className={classes.mediumTopMargin} component="div">
                  Please write an email to member@succeedsmart.com for further
                  instructions.
                </Typography>
                <Typography className={classes.mediumTopMargin} component="div">
                  Have a question?{" "}
                  <Link
                    href={config.zendesk_url}
                    target="_blank"
                    className={classes.customLink}
                  >
                    Contact support
                  </Link>
                </Typography>
              </div>
            </Grid>
          </Grid>
        </Grid>
      </>
    );
  }
}

DomainExists.propTypes = {
  classes: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => state;

const connectedDomainExistsPage = withRouter(
  connect(mapStateToProps, null, null, {
    pure: false,
  })(withStyles(styles)(DomainExists))
);

export { connectedDomainExistsPage as DomainExists };
