import { Grid, Typography, Tooltip } from "@material-ui/core";
import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { ViewMore } from "../core/viewmore";
import { getImgObjURL } from "../../helpers";
import { EmployesLogo } from "../core/employers.logo";

const useStyles = makeStyles(() => ({
  mainGridList: {
    textAlign: "left",
    marginTop: "10px",
  },
  fontWeight400: {
    fontWeight: "400",
  },
}));

function ProfileHighlights(props) {
  const {
    industries,
    level,
    experience,
    teamManaged,
    countriesWorkedIn,
    filteredCompaniesLogoArrays,
  } = props;
  const classes = useStyles();

  const [filteredCompaniesLogoArray, setFilteredCompaniesLogoArray] = useState(
    []
  );

  const removeDuplicateLogoEntries = (logoInfoObjArray) => {
    const logoArray = [];
    const len = logoInfoObjArray?.length;
    if (len < 1) return logoArray;
    for (let index = 0; index < len; index++) {
      const company = logoInfoObjArray[index];
      const {
        company_logo,
        industry,
        company_size,
        domain,
        other_industry_text,
      } = company;
      if (
        company_logo &&
        !arrayIncludesKeyVal(logoArray, "company_logo", company_logo)
      ) {
        logoArray.push({
          company_logo,
          industry,
          company_size,
          domain,
          other_industry_text,
        });
      }
    }
    return logoArray;
  };

  const arrayIncludesKeyVal = (arrOfObj, key, value) => {
    const len = arrOfObj.length;
    for (let index = 0; index < len; index++) {
      const element = arrOfObj[index];
      if (element[key] === value) return true;
    }
    return false;
  };

  useEffect(() => {
    const filterLogoArray = async () => {
      const companiesLogoArray = removeDuplicateLogoEntries(
        filteredCompaniesLogoArrays
      );
      // identify if blob is png
      const logoArray = [];
      const len = companiesLogoArray?.length ?? 0;
      for (let i = 0; i < len; i++) {
        const e = companiesLogoArray[i];
        const {
          company_size,
          company_logo,
          industry,
          domain,
          other_industry_text,
        } = e;
        if (company_logo) {
          const imageObjUrl = await getImgObjURL(company_logo);
          if (imageObjUrl) {
            const obj = {
              company_size,
              imageObjUrl: company_logo,
              industry,
              domain,
              other_industry_text,
            };
            logoArray.push(obj);
          }
        }
      }
      setFilteredCompaniesLogoArray(logoArray);
    };
    filterLogoArray();
    //  eslint-disable-next-line
  }, [filteredCompaniesLogoArrays]);

  return (
    <Grid container spacing={2}>
      <Grid
        item
        xs={6}
        sm={6}
        md={6}
        lg={6}
        xl={6}
        className={classes.mainGridList}
      >
        <Typography
          variant="body2"
          color="textSecondary"
          className={classes.fontWeight400}
        >
          Industries
        </Typography>
        <Tooltip
          arrow
          placement="bottom"
          title={industries?.length > 0 ? industries?.join(", ") : ""}
        >
          <Typography>
            {industries?.length > 0 ? industries?.join(", ") : "-"}
          </Typography>
        </Tooltip>
      </Grid>
      <Grid
        item
        xs={6}
        sm={6}
        md={5}
        lg={6}
        xl={6}
        className={classes.mainGridList}
      >
        <Typography variant="body2" color="textSecondary">
          Highest Level Held
        </Typography>
        <Typography variant="body1">{level || "-"}</Typography>
      </Grid>
      <Grid
        item
        xs={6}
        sm={6}
        md={6}
        lg={6}
        xl={6}
        className={classes.mainGridList}
      >
        <Typography variant="body2" color="textSecondary">
          Total Years of Experience
        </Typography>
        <Typography variant="body">{experience || "-"}</Typography>
      </Grid>
      <Grid
        item
        xs={6}
        sm={6}
        md={6}
        lg={6}
        xl={6}
        className={classes.mainGridList}
      >
        <Typography variant="body2" color="textSecondary">
          Largest Team Size Managed
        </Typography>
        <Typography variant="body1">{teamManaged || "-"}</Typography>
      </Grid>
      <Grid
        item
        xs={6}
        sm={6}
        md={6}
        lg={6}
        xl={6}
        className={classes.mainGridList}
      >
        <Typography variant="body2" color="textSecondary">
          Countries Worked In
        </Typography>
        {countriesWorkedIn ? (
          <ViewMore length="200">{countriesWorkedIn}</ViewMore>
        ) : (
          "-"
        )}
      </Grid>
      <Grid
        item
        xs={6}
        sm={6}
        md={6}
        lg={6}
        xl={6}
        className={classes.mainGridList}
      >
        <Typography align="left" variant="body2" color="textSecondary">
          Employers
        </Typography>
        <EmployesLogo logoArray={filteredCompaniesLogoArray} />
      </Grid>
    </Grid>
  );
}

export default ProfileHighlights;
