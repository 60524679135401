import { toast } from "react-toastify";
import { apiService } from "../services";
import { updateRecruiterStatus } from "./auth.actions";
import { showResponseToast } from "../helpers/toastHelpers";
import { decryptData } from "../helpers";
import { master_key } from "../config/config";

export const sharedActions = {
  setAccessPermission,
  setSelectedNavbarIndex,
  navBarMenuToggle,
  jobsPageTabChange,
  jobsPagePaginationPageChange,
  addUpdateCalendlyLink,
  getCalendlyLink,
  addUpdateDiversity,
  setNavbarStatus,
  closedJobsPage,
  functionFilter,
};

function getCalendlyLink(recruiterId) {
  return (dispatch) => {
    const apiEndpoint = `/api/v1/recruiter/${recruiterId}/calendaly_url/`;
    apiService
      .get(apiEndpoint)
      .then((response) => {
        if (response && (response.status === 200 || response.status === 201)) {
          dispatch(changeCalendlyLink(response.data.calendaly_url));
        } else if (response.status !== 401) {
          toast.dark("Something went haywire");
          // history.push('/jobs/my-jobs');
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };
}

function addUpdateCalendlyLink(recruiterId, link) {
  const payload = {
    calendaly_url: link,
  };
  return (dispatch) => {
    const apiEndpoint = `/api/v1/recruiter/${recruiterId}/calendaly_url/`;
    apiService
      .put(apiEndpoint, payload)
      .then((response) => {
        if (response && (response.status === 200 || response.status === 201)) {
          dispatch(changeCalendlyLink(link));
        }
        showResponseToast(
          response,
          "Calendly link updated successfully",
          "Failure while updating calendly link"
        );
      })
      .catch((err) => {
        console.log(err);
      });
  };
}

function addUpdateDiversity(recruiterId, diversityFlag, data) {
  const payload = {
    diversity: diversityFlag,
  };
  return (dispatch) => {
    const apiEndpoint = `/api/v1/recruiter/${recruiterId}/enable_disable_diversity/`;
    apiService
      .put(apiEndpoint, payload)
      .then((response) => {
        if (response && (response.status === 200 || response.status === 201)) {
          dispatch(updateRecruiterStatus(data));
        }
        showResponseToast(
          response,
          "Diversity updated successfully",
          "Failure while updating Diversity"
        );
      })
      .catch((err) => {
        console.log(err);
      });
  };
}

export function getGoogleKey() {
  return (dispatch) => {
    const apiEndpoint = `/api/auth/keys_manager/google_api_key/`;
    apiService.get(apiEndpoint).then((res) => {
      let value = decryptData(master_key, res.data.key);
      dispatch(setGoogleKey(value));
    });
  };
}

function setGoogleKey(decryptedGoogleKey) {
  return {
    type: "SET_DECRYPTED_GOOGLE_KEY",
    decryptedGoogleKey: decryptedGoogleKey,
  };
}

export function getCloudName() {
  return (dispatch) => {
    const apiEndpoint = `/api/auth/keys_manager/cloudinary_api_key/`;
    apiService.get(apiEndpoint).then((res) => {
      let presetValue = decryptData(
        master_key,
        res.data.cloudinary_upload_present
      );
      let cloudValue = decryptData(master_key, res.data.cloud_name);

      dispatch(setCloudName(cloudValue));
      dispatch(setUploadPreset(presetValue));
    });
  };
}

function setCloudName(decryptedCloudName) {
  return {
    type: "SET_DECRYPTED_CLOUD_NAME",
    decryptedCloudName: decryptedCloudName,
  };
}

function setUploadPreset(decryptedUploadPreset) {
  return {
    type: "SET_DECRYPTED_UPLOAD_PRESET",
    decryptedUploadPreset: decryptedUploadPreset,
  };
}

export function getStripeKey() {
  return (dispatch) => {
    const apiEndpoint = `/api/auth/keys_manager/stripe_api_key/`;
    apiService.get(apiEndpoint).then((res) => {
      let value = decryptData(master_key, res.data.stripe_key);
      dispatch(setStripeKey(value));
    });
  };
}

function setStripeKey(decryptedStripeKey) {
  return {
    type: "SET_DECRYPTED_STRIPE_KEY",
    decryptedStripeKey: decryptedStripeKey,
  };
}

export function setNavbarStatus(visibility) {
  return (dispatch) => {
    dispatch(setNavbarVisibility(visibility));
  };
}

function changeCalendlyLink(link) {
  return {
    type: "SET_CALENDLY_LINK",
    calendlyLink: link || "",
  };
}

function navBarMenuToggle(flag) {
  return {
    type: "NAV_BAR_MENU_TOGGLE",
    menuToggle: flag,
  };
}

function setSelectedNavbarIndex(index) {
  return {
    type: "SELECTED_NAVBAR_INDEX",
    selectedNavbarIndex: index,
  };
}

function jobsPageTabChange(value) {
  return {
    type: "JOBS_PAGE_TAB",
    jobsPageTab: value,
  };
}

function jobsPagePaginationPageChange(value) {
  return {
    type: "JOBS_PAGE_PAGINATION_PAGE",
    jobsPagePaginationPage: value,
  };
}

function closedJobsPage(value) {
  return {
    type: "CLOSED_JOB_PAGE_VALUE",
    closedJobPageNo: value,
  };
}

function setAccessPermission(value) {
  return {
    type: "ACCESS_PERMISSION",
    accessPermission: value,
  };
}

export function setNavbarVisibility(visibility) {
  return {
    type: "NAVBAR_VISIBILITY",
    isNavbarVisible: visibility,
  };
}

function functionFilter(value) {
  return {
    type: "FUNCTION_FILTER",
    functionFilter: value,
  };
}

export function badRequest(value) {
  return {
    type: "BAD_REQUEST",
    badRequest: value,
  };
}
