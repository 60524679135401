import React from "react";
import { Route, Redirect } from "react-router-dom";
import secureLocalStorage from "react-secure-storage";

export const PrivateRoute = ({ component: Component, ...rest }) => (
  <Route
    {...rest}
    render={(props) =>
      secureLocalStorage.getItem("auth") &&
      window.location.pathname !== "/signup" &&
      window.location.pathname !== "/signin" &&
      window.location.pathname !== "/confirm-signup" &&
      window.location.pathname !== "/forgot-password" &&
      window.location.pathname !== "/reset-password" &&
      window.location.pathname !== "/signup-success" &&
      window.location.pathname !== "/company-info" &&
      window.location.pathname !== "/sign-contract" &&
      window.location.pathname !== "/application-status" &&
      window.location.pathname !== "/no-access" &&
      window.location.pathname !== "/domain-check" &&
      window.location.pathname !== "/contract-signed" &&
      window.location.pathname !== "/contract-incomplete" &&
      window.location.pathname !== "/payment" &&
      window.location.pathname !== "/confirm-payment" ? (
        <Component {...props} />
      ) : !secureLocalStorage.getItem("auth") &&
        (window.location.pathname === "/signup" ||
          window.location.pathname === "/signin" ||
          window.location.pathname === "/confirm-signup" ||
          window.location.pathname === "/forgot-password" ||
          window.location.pathname === "/reset-password" ||
          window.location.pathname === "/signup-success" ||
          window.location.pathname === "/company-info" ||
          window.location.pathname === "/sign-contract" ||
          window.location.pathname === "/application-status" ||
          window.location.pathname === "/no-access" ||
          window.location.pathname === "/domain-check" ||
          window.location.pathname === "/contract-signed" ||
          window.location.pathname === "/contract-incomplete") ? (
        !secureLocalStorage.getItem("onboardingStatus") ? (
          window.location.pathname === "/signup" ||
          window.location.pathname === "/signin" ||
          window.location.pathname === "/forgot-password" ||
          window.location.pathname === "/reset-password" ? (
            <Component {...props} />
          ) : (
            <Redirect to={{ pathname: "/", state: { from: props.location } }} />
          )
        ) : secureLocalStorage.getItem("onboardingStatus") === "signedUp" &&
          (window.location.pathname === "/signup" ||
            window.location.pathname === "/signin" ||
            window.location.pathname === "/forgot-password" ||
            window.location.pathname === "/reset-password" ||
            window.location.pathname === "/signup-success" ||
            window.location.pathname === "/company-info" ||
            window.location.pathname === "/sign-contract" ||
            window.location.pathname === "/application-status" ||
            window.location.pathname === "/no-access" ||
            window.location.pathname === "/domain-check" ||
            window.location.pathname === "/contract-signed" ||
            window.location.pathname === "/contract-incomplete") ? (
          <Redirect
            to={{
              pathname: "/confirm-signup",
              state: { from: props.location },
            }}
          />
        ) : secureLocalStorage.getItem("onboardingStatus") === "otpVerified" &&
          (window.location.pathname === "/signup" ||
            window.location.pathname === "/confirm-signup" ||
            window.location.pathname === "/signin" ||
            window.location.pathname === "/forgot-password" ||
            window.location.pathname === "/reset-password" ||
            window.location.pathname === "/sign-contract" ||
            window.location.pathname === "/application-status" ||
            window.location.pathname === "/no-access" ||
            window.location.pathname === "/domain-check" ||
            window.location.pathname === "/contract-signed" ||
            window.location.pathname === "/contract-incomplete") ? (
          JSON.parse(secureLocalStorage.getItem("recruiterDetails"))
            .is_from_added_seat ? (
            <Component {...props} />
          ) : (
            <Redirect to={{ pathname: "/signup-success" }} />
          )
        ) : secureLocalStorage.getItem("onboardingStatus") === "companyAdded" &&
          (window.location.pathname === "/signup" ||
            window.location.pathname === "/confirm-signup" ||
            window.location.pathname === "/signin" ||
            window.location.pathname === "/forgot-password" ||
            window.location.pathname === "/reset-password" ||
            window.location.pathname === "/company-info" ||
            window.location.pathname === "/signup-success" ||
            window.location.pathname === "/application-status" ||
            window.location.pathname === "/no-access" ||
            window.location.pathname === "/domain-check" ||
            window.location.pathname === "/contract-signed" ||
            window.location.pathname === "/contract-incomplete") ? (
          <Redirect
            to={{ pathname: "/sign-contract", state: { from: props.location } }}
          />
        ) : secureLocalStorage.getItem("onboardingStatus") ===
            "contractSigned" &&
          (window.location.pathname === "/signup" ||
            window.location.pathname === "/confirm-signup" ||
            window.location.pathname === "/signin" ||
            window.location.pathname === "/forgot-password" ||
            window.location.pathname === "/reset-password" ||
            window.location.pathname === "/company-info" ||
            window.location.pathname === "/signup-success" ||
            window.location.pathname === "/application-status" ||
            window.location.pathname === "/no-access" ||
            window.location.pathname === "/domain-check" ||
            window.location.pathname === "/sign-contract" ||
            window.location.pathname === "/contract-incomplete") ? (
          <Redirect
            to={{
              pathname: "/contract-signed",
              state: { from: props.location },
            }}
          />
        ) : secureLocalStorage.getItem("onboardingStatus") === "signedUp" &&
          window.location.pathname !== "/signup" &&
          window.location.pathname !== "/signin" ? (
          <Component {...props} />
        ) : secureLocalStorage.getItem("onboardingStatus") === "otpVerified" &&
          window.location.pathname !== "/signup" &&
          window.location.pathname !== "/signin" &&
          window.location.pathname !== "/confirm-signup" ? (
          <Component {...props} />
        ) : secureLocalStorage.getItem("onboardingStatus") === "companyAdded" &&
          window.location.pathname !== "/signup" &&
          window.location.pathname !== "/signin" &&
          window.location.pathname !== "/confirm-signup" &&
          window.location.pathname !== "/signup-success" &&
          window.location.pathname !== "/company-info" ? (
          <Component {...props} />
        ) : secureLocalStorage.getItem("onboardingStatus") ===
            "contractSigned" &&
          window.location.pathname !== "/signup" &&
          window.location.pathname !== "/signin" &&
          window.location.pathname !== "/confirm-signup" &&
          window.location.pathname !== "/signup-success" &&
          window.location.pathname !== "/company-info" &&
          window.location.pathname !== "sign-contract" ? (
          <Component {...props} />
        ) : (
          window.history.forward()
        )
      ) : secureLocalStorage.getItem("auth") &&
        secureLocalStorage.getItem("onboardingStatus") !== "complete" &&
        (window.location.pathname === "/signup" ||
          window.location.pathname === "/signin" ||
          window.location.pathname === "/confirm-signup" ||
          window.location.pathname === "/forgot-password" ||
          window.location.pathname === "/reset-password" ||
          window.location.pathname === "/signup-success" ||
          window.location.pathname === "/company-info" ||
          window.location.pathname === "/sign-contract" ||
          window.location.pathname === "/application-status" ||
          window.location.pathname === "/no-access" ||
          window.location.pathname === "/domain-check" ||
          window.location.pathname === "/contract-signed" ||
          window.location.pathname === "/contract-incomplete") ? (
        <Component {...props} />
      ) : (window.location.pathname === "/payment" ||
          window.location.pathname === "/confirm-payment") &&
        secureLocalStorage.getItem("auth") &&
        JSON.parse(secureLocalStorage.getItem("recruiterStatusDetails"))
          .payment_status === false &&
        (JSON.parse(secureLocalStorage.getItem("recruiterStatusDetails"))
          .onboarding_status === "complete" ||
          JSON.parse(secureLocalStorage.getItem("recruiterStatusDetails"))
            .onboarding_status === "Complete") ? (
        <Component {...props} />
      ) : secureLocalStorage.getItem("onboardingStatus") === "contractSigned" &&
        window.location.pathname === "/signin" ? (
        <Component {...props} />
      ) : (
        <Redirect to={{ pathname: "/", state: { from: props.location } }} />
      )
    }
  />
);
