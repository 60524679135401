import { toast } from "react-toastify";
import { somethingWentHaywire } from "../constants/constants";

export const showHaywireToast = () => {
  toast.dark(somethingWentHaywire);
};

export const showResponseToast = (response, successMessage, failureMessage) => {
  const responseMessage =
    response?.data?.message === "TOKEN_EXPIRED"
      ? "Session expired. Please login again"
      : response?.data?.message;

  responseMessage !== "TOKEN_INVALID" &&
    toast.dark(
      responseMessage ??
        `${
          response?.status === 200 ||
          response?.status === 201 ||
          response?.status === 204
            ? successMessage
            : failureMessage
        }`
    );
  // since there are chained calls in the calling function
  return response;
};

export const showErrorToast = (error, customMessage) => {
  toast.dark(
    customMessage ?? error?.response?.data?.message ?? somethingWentHaywire
  );
  return error;
};
