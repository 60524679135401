import React, { useEffect, useState } from "react";
import {
  IconButton,
  Button,
  DialogContent,
  DialogActions,
  Typography,
  Dialog,
  DialogTitle,
  Grid,
  Chip,
  Avatar,
  Card,
  CardContent,
} from "@material-ui/core";
import classNames from "classnames";
import { Editor } from "react-draft-wysiwyg";
import { makeStyles } from "@material-ui/core/styles";
import CloseIcon from "@material-ui/icons/Close";
import { ViewMore } from "../../core/viewmore";
import cardHeaderBg from "../../../assets/lite_profile_bg.png";
import secureLocalStorage from "react-secure-storage";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    marginTop: "90px",
  },
  smallTopMargin: {
    marginTop: "3%",
  },
  customDialogHeader: {
    maxHeight: "38%",
    height: "38%",
    color: "#000",
    backgroundSize: "cover",
    backgroundImage: `url(${cardHeaderBg})`,
    background: "round",
  },
  header: {
    fontSize: "x-large",
    marginTop: "4%",
    fontWeight: "600",
    color: "#000000",
  },
  noImageAvatar: {
    color: "#FFFFFF",
    fontSize: "large",
    backgroundColor: "#000000",
  },
  xLargeAvatar: {
    width: theme.spacing(12),
    height: theme.spacing(12),
  },
  subHeader: {
    fontSize: "large",
    marginTop: "4%",
    fontWeight: "600",
    marginBottom: "1%",
    color: "#000000",
  },
  customSmallTopMargin: {
    marginTop: "2%",
  },
  customCardShadow: {
    boxShadow: "none",
  },
  customCard: {
    backgroundColor: "#F7F7F7",
    borderRadius: "10px",
  },
  mediumBottomMargin: {
    marginBottom: "5%",
  },
  textAlignEnd: {
    textAlign: "end",
  },
  displayInline: {
    display: "inline",
  },
  closeButton: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.default.main,
    padding: "0px",
  },
  customEditButton: {
    backgroundColor: "#FFFFFF",
    border: "#000000 solid 1px",
    marginRight: "1%",
  },
  dialogActions: { justifyContent: "center !important" },
}));
const salaryValueFormat = Intl.NumberFormat("en-US");

function JobPreview(props) {
  const {
    jobType,
    openPreview,
    onClose,
    jobTitleValue,
    jobLevel,
    reportsToTitleValue,
    reportsTo,
    industry,
    functionName,
    otherFunctionName,
    location,
    minimumExperience,
    minimumBaseSalary,
    salary_config,
    degree,
    majorValue,
    degreeRequiredOrPreferred,
    remote,
    travelLevel,
    howSoonToHire,
    startupSize,
    startupStage,
    teamSize,
    role,
    sampleCompanyValues,
    editorStateJobDescription,
    editorStateAboutUs,
    additionresponsibilities,
    editorcandidateWillBring,
    editerAbsoluteMustHave,
    skillsDataList,
    addJob,
    certification,
    customIndustryValue,
    alternativeTitle,
    alternateIndustries,
  } = props;

  const classes = useStyles();
  const scroll = "paper";
  const [alternateTitles, setAlternateTitle] = useState([]);
  const capitalize = function (str) {
    if (str !== null && str !== undefined) {
      return str
        .split(" ")
        .map((w) => w.substring(0, 1).toUpperCase() + w.substring(1))
        .join(" ");
    }
    return str;
  };

  const filterTitles = () => {
    const alternativeTitles = alternativeTitle?.map((value) => {
      if (typeof value === "object") {
        return value?._source?.Title;
      }
      return value;
    });
    setAlternateTitle(alternativeTitles);
  };

  useEffect(() => {
    filterTitles();
  }, []);

  return (
    <Dialog
      hideBackdrop
      open={openPreview}
      scroll={scroll}
      fullWidth
      aria-labelledby="scroll-dialog-title"
      aria-describedby="scroll-dialog-description"
      disableBackdropClick
      disableEscapeKeyDown
      maxWidth="md"
    >
      <DialogTitle
        id="customized-dialog-title"
        className={classes.customDialogHeader}
      >
        <Typography variant="h5" align="center">
          Job Preview
        </Typography>
        <IconButton
          aria-label="close"
          onClick={onClose}
          className={classes.closeButton}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent dividers={scroll === "paper"}>
        <Avatar
          src={
            JSON.parse(secureLocalStorage.getItem("recruiterStatusDetails"))
              .company.logo ||
            this.capitalize(
              JSON.parse(secureLocalStorage.getItem("recruiterStatusDetails"))
                .company.company_name
            )
          }
          variant="circular"
          className={classNames(
            classes.xLargeAvatar,
            JSON.parse(secureLocalStorage.getItem("recruiterStatusDetails"))
              .company.logo === "" ||
              !JSON.parse(secureLocalStorage.getItem("recruiterStatusDetails"))
                .company.logo
              ? classes.noImageAvatar
              : null
          )}
        />
        <Typography
          className={classNames(classes.smallTopMargin, classes.header)}
        >
          {jobTitleValue}
        </Typography>
        <Typography component="div" color="primary">
          {capitalize(
            JSON.parse(secureLocalStorage.getItem("recruiterStatusDetails"))
              .company.company_name
          )}
        </Typography>
        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
          <div className={classes.subHeader}>Basics</div>
          <Card
            className={classNames(
              classes.root,
              classes.customSmallTopMargin,
              classes.customCardShadow
            )}
          >
            <CardContent className={classes.customCard}>
              <Grid container>
                <Grid item xs={12} sm={12} md={12} lg={7} xl={7}>
                  <Typography
                    className={classes.mediumBottomMargin}
                    color="textSecondary"
                  >
                    Job Level
                  </Typography>
                </Grid>
                <Grid
                  className={classes.textAlignEnd}
                  item
                  xs={12}
                  sm={12}
                  md={12}
                  lg={5}
                  xl={5}
                >
                  {jobLevel || "-"}
                </Grid>
                <Grid item xs={12} sm={12} md={12} lg={7} xl={7}>
                  <Typography
                    className={classes.mediumBottomMargin}
                    color="textSecondary"
                  >
                    Reports To
                  </Typography>
                </Grid>
                <Grid
                  className={classes.textAlignEnd}
                  item
                  xs={12}
                  sm={12}
                  md={12}
                  lg={5}
                  xl={5}
                >
                  {reportsToTitleValue || "-"}
                </Grid>
                {reportsTo?.length > 0 ? (
                  <>
                    <Grid item xs={12} sm={12} md={12} lg={7} xl={7}>
                      <Typography
                        className={classes.mediumBottomMargin}
                        color="textSecondary"
                      >
                        Hiring Manager Name
                      </Typography>
                    </Grid>
                    <Grid
                      className={classes.textAlignEnd}
                      item
                      xs={12}
                      sm={12}
                      md={12}
                      lg={5}
                      xl={5}
                    >
                      {reportsTo || "-"}
                    </Grid>
                  </>
                ) : null}
                <Grid item xs={12} sm={12} md={12} lg={7} xl={7}>
                  <Typography
                    className={classes.mediumBottomMargin}
                    color="textSecondary"
                  >
                    Alternative Titles
                  </Typography>
                </Grid>
                <Grid
                  className={classes.textAlignEnd}
                  item
                  xs={12}
                  sm={12}
                  md={12}
                  lg={5}
                  xl={5}
                >
                  {alternateTitles?.join(", ") || "-"}
                </Grid>
                <Grid item xs={12} sm={12} md={12} lg={7} xl={7}>
                  <Typography
                    className={classes.mediumBottomMargin}
                    color="textSecondary"
                  >
                    Industry
                  </Typography>
                </Grid>
                <Grid
                  className={classes.textAlignEnd}
                  item
                  xs={12}
                  sm={12}
                  md={12}
                  lg={5}
                  xl={5}
                >
                  {industry === "Other"
                    ? !customIndustryValue
                      ? "Other"
                      : customIndustryValue
                    : industry || "-"}
                </Grid>
                <Grid item xs={12} sm={12} md={12} lg={7} xl={7}>
                  <Typography
                    className={classes.mediumBottomMargin}
                    color="textSecondary"
                  >
                    Alternative Industry
                  </Typography>
                </Grid>
                <Grid
                  className={classes.textAlignEnd}
                  item
                  xs={12}
                  sm={12}
                  md={12}
                  lg={5}
                  xl={5}
                >
                  {alternateIndustries?.join(", ") || "-"}
                </Grid>
                <Grid item xs={12} sm={12} md={12} lg={7} xl={7}>
                  <Typography
                    className={classes.mediumBottomMargin}
                    color="textSecondary"
                  >
                    Function
                  </Typography>
                </Grid>
                <Grid
                  className={classes.textAlignEnd}
                  item
                  xs={12}
                  sm={12}
                  md={12}
                  lg={5}
                  xl={5}
                >
                  {functionName !== "Other"
                    ? functionName || "-"
                    : `${functionName} - ${otherFunctionName || "-"}`}
                </Grid>
                <Grid item xs={12} sm={12} md={12} lg={7} xl={7}>
                  <Typography
                    className={classes.mediumBottomMargin}
                    color="textSecondary"
                  >
                    Company HQ Location
                  </Typography>
                </Grid>
                <Grid
                  className={classes.textAlignEnd}
                  item
                  xs={12}
                  sm={12}
                  md={12}
                  lg={5}
                  xl={5}
                >
                  {location || "-"}
                </Grid>
                <Grid item xs={12} sm={12} md={12} lg={7} xl={7}>
                  <Typography
                    className={classes.mediumBottomMargin}
                    color="textSecondary"
                  >
                    Minimum Years of Experience
                  </Typography>
                </Grid>
                <Grid
                  className={classes.textAlignEnd}
                  item
                  xs={12}
                  sm={12}
                  md={12}
                  lg={5}
                  xl={5}
                >
                  {minimumExperience || "-"}
                </Grid>
                <Grid item xs={12} sm={12} md={12} lg={7} xl={7}>
                  <Typography
                    className={classes.mediumBottomMargin}
                    color="textSecondary"
                  >
                    Base Salary
                  </Typography>
                </Grid>

                <Grid
                  className={classes.textAlignEnd}
                  item
                  xs={12}
                  sm={12}
                  md={12}
                  lg={5}
                  xl={5}
                >
                  {minimumBaseSalary === null
                    ? "-"
                    : salary_config === 0
                    ? "Exclude salary"
                    : salary_config === 1
                    ? `$${salaryValueFormat?.format(
                        minimumBaseSalary
                      )} ${"(Not visible to candidates)"}`
                    : `$${salaryValueFormat.format(minimumBaseSalary)}`}
                </Grid>
                <Grid item xs={12} sm={12} md={12} lg={7} xl={7}>
                  <Typography
                    className={classes.mediumBottomMargin}
                    color="textSecondary"
                  >
                    Degree/Major
                  </Typography>
                </Grid>
                <Grid
                  className={classes.textAlignEnd}
                  item
                  xs={12}
                  sm={12}
                  md={12}
                  lg={5}
                  xl={5}
                >
                  {(degree ? `${degree}/` : "-/") +
                    (majorValue
                      ? majorValue?._source?.Major || majorValue
                      : "-") +
                    (degreeRequiredOrPreferred
                      ? ` (${degreeRequiredOrPreferred})`
                      : "")}
                </Grid>
                <Grid item xs={12} sm={12} md={12} lg={7} xl={7}>
                  <Typography
                    className={classes.mediumBottomMargin}
                    color="textSecondary"
                  >
                    Degree Required/Preferred?
                  </Typography>
                </Grid>
                <Grid
                  className={classes.textAlignEnd}
                  item
                  xs={12}
                  sm={12}
                  md={12}
                  lg={5}
                  xl={5}
                >
                  <Typography className={classes.mediumBottomMargin}>
                    {" "}
                    {degreeRequiredOrPreferred || "-"}
                  </Typography>
                </Grid>

                <Grid item xs={12} sm={12} md={12} lg={7} xl={7}>
                  <Typography
                    className={classes.mediumBottomMargin}
                    color="textSecondary"
                  >
                    Work Location
                  </Typography>
                </Grid>
                <Grid
                  className={classes.textAlignEnd}
                  item
                  xs={12}
                  sm={12}
                  md={12}
                  lg={5}
                  xl={5}
                >
                  {remote || "-"}
                </Grid>
                <Grid item xs={12} sm={12} md={12} lg={7} xl={7}>
                  <Typography
                    className={classes.mediumBottomMargin}
                    color="textSecondary"
                  >
                    Travel Requirements
                  </Typography>
                </Grid>
                <Grid
                  className={classes.textAlignEnd}
                  item
                  xs={12}
                  sm={12}
                  md={12}
                  lg={5}
                  xl={5}
                >
                  {travelLevel || "-"}
                </Grid>

                <Grid item xs={12} sm={12} md={12} lg={7} xl={7}>
                  <Typography
                    className={classes.mediumBottomMargin}
                    color="textSecondary"
                  >
                    How soon do you want to fill this role?
                  </Typography>
                </Grid>
                <Grid
                  className={classes.textAlignEnd}
                  item
                  xs={12}
                  sm={12}
                  md={12}
                  lg={5}
                  xl={5}
                >
                  {howSoonToHire || "-"}
                </Grid>

                <Grid item xs={12} sm={12} md={12} lg={7} xl={7}>
                  <Typography
                    className={classes.mediumBottomMargin}
                    color="textSecondary"
                  >
                    Confidential/Normal Role
                  </Typography>
                </Grid>
                <Grid
                  className={classes.textAlignEnd}
                  item
                  xs={12}
                  sm={12}
                  md={12}
                  lg={5}
                  xl={5}
                >
                  {jobType || "-"}
                </Grid>
                <Grid item xs={12} sm={12} md={12} lg={7} xl={7}>
                  <Typography
                    color="textSecondary"
                    className={classes.mediumBottomMargin}
                  >
                    Company Size
                  </Typography>
                </Grid>
                <Grid
                  className={classes.textAlignEnd}
                  item
                  xs={12}
                  sm={12}
                  md={12}
                  lg={5}
                  xl={5}
                >
                  {startupSize?.join(", ") || "-"}
                </Grid>
                <>
                  <Grid item xs={12} sm={12} md={12} lg={7} xl={7}>
                    <Typography
                      color="textSecondary"
                      className={classes.mediumBottomMargin}
                    >
                      Company Type
                    </Typography>
                  </Grid>
                  <Grid
                    className={classes.textAlignEnd}
                    item
                    xs={12}
                    sm={12}
                    md={12}
                    lg={5}
                    xl={5}
                  >
                    {startupStage?.join(", ") || "-"}
                  </Grid>
                </>

                <>
                  <Grid item xs={12} sm={12} md={12} lg={7} xl={7}>
                    <Typography
                      color="textSecondary"
                      className={classes.mediumBottomMargin}
                    >
                      Team Size
                    </Typography>
                  </Grid>
                  <Grid
                    className={classes.textAlignEnd}
                    item
                    xs={12}
                    sm={12}
                    md={12}
                    lg={5}
                    xl={5}
                  >
                    {teamSize?.join(", ") || "-"}
                  </Grid>
                </>

                <>
                  {" "}
                  <Grid item xs={12} sm={12} md={12} lg={7} xl={7}>
                    <Typography
                      color="textSecondary"
                      className={classes.mediumBottomMargin}
                    >
                      New Role/Existing Role
                    </Typography>
                  </Grid>
                  <Grid
                    className={classes.textAlignEnd}
                    item
                    xs={12}
                    sm={12}
                    md={12}
                    lg={5}
                    xl={5}
                  >
                    {role || "-"}
                  </Grid>
                </>
              </Grid>
            </CardContent>
          </Card>
        </Grid>

        <div
          className={classNames(
            classes.subHeader,
            classes.mediumTopMargin,
            classes.noBottomMargin
          )}
        >
          Sample Corporation
        </div>
        {sampleCompanyValues?.length > 0 ? (
          <Grid
            item
            xs={12}
            sm={12}
            md={12}
            lg={12}
            xl={12}
            className={classes.verySmallTopMargin}
          >
            {openPreview
              ? sampleCompanyValues?.map((company, index) => (
                  <div className={classes.displayInline} key={index}>
                    <Chip
                      label={<ViewMore length="30">{company.name}</ViewMore>}
                      style={{
                        height: "100%",
                        padding: "5px",
                        margin: "5px 4px 8px 0px",
                        lineBreak: "anywhere",
                      }}
                    />
                  </div>
                ))
              : null}
          </Grid>
        ) : (
          "-"
        )}
        <div
          className={classNames(
            classes.subHeader,
            classes.mediumTopMargin,
            classes.noBottomMargin
          )}
        >
          Professional Certifications
        </div>
        {certification?.length > 0 ? (
          <Grid
            item
            xs={12}
            sm={12}
            md={12}
            lg={12}
            xl={12}
            className={classes.verySmallTopMargin}
          >
            {openPreview
              ? certification?.map((val, index) => (
                  <div className={classes.displayInline} key={index}>
                    <Chip
                      label={
                        <ViewMore length="30">
                          {val?._source?.Certificate}
                        </ViewMore>
                      }
                      style={{
                        height: "100%",
                        padding: "5px",
                        margin: "5px 4px 8px 0px",
                        lineBreak: "anywhere",
                      }}
                    />
                  </div>
                ))
              : null}
          </Grid>
        ) : (
          "-"
        )}

        <div
          className={classNames(
            classes.subHeader,
            classes.mediumTopMargin,
            classes.noBottomMargin
          )}
        >
          About Us
        </div>
        <Grid
          item
          xs={12}
          sm={12}
          md={12}
          lg={12}
          xl={12}
          className={classes.customSmallTopMargin}
        >
          <Editor toolbarHidden readOnly editorState={editorStateAboutUs} />
        </Grid>
        <div
          className={classNames(
            classes.subHeader,
            classes.mediumTopMargin,
            classes.noBottomMargin
          )}
        >
          About Role
        </div>
        <Grid
          item
          xs={12}
          sm={12}
          md={12}
          lg={12}
          xl={12}
          className={classes.customSmallTopMargin}
        >
          <Editor
            toolbarHidden
            readOnly
            editorState={editorStateJobDescription}
          />
        </Grid>
        <div
          className={classNames(
            classes.subHeader,
            classes.mediumTopMargin,
            classes.noBottomMargin
          )}
        >
          Responsibilities
        </div>
        <Grid
          item
          xs={12}
          sm={12}
          md={12}
          lg={12}
          xl={12}
          className={classes.customSmallTopMargin}
        >
          <Editor
            toolbarHidden
            readOnly
            editorState={additionresponsibilities}
          />
        </Grid>
        <div
          className={classNames(
            classes.subHeader,
            classes.mediumTopMargin,
            classes.noBottomMargin
          )}
        >
          Qualifications
        </div>
        <Grid
          item
          xs={12}
          sm={12}
          md={12}
          lg={12}
          xl={12}
          className={classes.customSmallTopMargin}
        >
          <Editor
            toolbarHidden
            readOnly
            editorState={editorcandidateWillBring}
          />
        </Grid>
        <div
          className={classNames(
            classes.subHeader,
            classes.mediumTopMargin,
            classes.noBottomMargin
          )}
        >
          Must Have Requirements/Non-Negotiables
        </div>
        <Grid
          item
          xs={12}
          sm={12}
          md={12}
          lg={12}
          xl={12}
          className={classes.customSmallTopMargin}
        >
          <Editor toolbarHidden readOnly editorState={editerAbsoluteMustHave} />
        </Grid>
        {skillsDataList?.length > 0 && (
          <div
            className={classNames(
              classes.subHeader,
              classes.mediumTopMargin,
              classes.noBottomMargin
            )}
          >
            Competencies
          </div>
        )}
        <Grid
          item
          xs={12}
          sm={12}
          md={12}
          lg={12}
          xl={12}
          className={classes.verySmallTopMargin}
        >
          {openPreview
            ? skillsDataList?.map((skill, index) => (
                <div className={classes.displayInline} key={index}>
                  <Chip
                    label={<ViewMore length="30">{skill.trim()}</ViewMore>}
                    style={{
                      height: "100%",
                      padding: "5px",
                      margin: "5px 4px 8px 0px",
                      lineBreak: "anywhere",
                    }}
                  />
                </div>
              ))
            : null}
        </Grid>
      </DialogContent>
      <DialogActions className={classes.dialogActions}>
        <Button
          className={classNames(classes.justifyRight, classes.customEditButton)}
          variant="contained"
          disableElevation
          size="large"
          onClick={onClose}
        >
          Edit
        </Button>
        <Button
          className={classes.justifyRight}
          variant="contained"
          color="primary"
          disableElevation
          size="large"
          onClick={addJob}
        >
          Add Job
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default JobPreview;
