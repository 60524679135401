import React, { Component } from "react";
import axios from "axios";
import "./App.css";
import { Router, Switch, Route, Redirect } from "react-router-dom";
import AppBar from "./components/core/appbar";
// import { Home } from './components/views/home';
import { Dashboard } from "./components/views/dashboard";
import { Messages } from "./components/views/messages";
import { Jobs, JobDetails } from "./components/views/jobs";
import JobPost from "./components/views/jobs/job.post.new";
// import { Members, MemberFullProfile } from './components/views/members';
import { MemberFullProfile } from "./components/views/members";
import { MyAccount, ChangePassword } from "./components/views/myAccount";
import {
  Payment,
  ConfirmPayment,
  ConfirmAuthentication,
} from "./components/views/payment";
import {
  SignUp,
  VerifyOtp,
  SignUpConfirm,
  CompanyInfo,
  DomainExists,
  SignContract,
  ContractCompleted,
  ContractIncomplete,
} from "./components/views/signup";
import {
  SignIn,
  ForgotPassword,
  ResetPassword,
  WaitingForApproval,
  NoAccess,
  VerifyEmail,
  GhostLogin,
} from "./components/views/signin";
import { deepLink, getSharedFullProfile } from "./actions";
import { history } from "./helpers";
import { PrivateRoute } from "./components";
import { loading, loggingIn, sharedActions } from "./actions";
import AppLoader from "./components/core/apploader";
import { connect } from "react-redux";
import Toaster from "./components/core/toaster";
import "stream-chat-react/dist/css/index.css";
import { toast } from "react-toastify";
import store from "./store/store";
import { GHOST_TOAST_CONF } from "./constants/constants";
import SharedFullProfile from "./components/views/members/sharedFullProfile";
import secureLocalStorage from "react-secure-storage";
import BadRequest from "./components/customComponents/badRequest";

class App extends Component {
  constructor(props) {
    super(props);
    const self = this;
    var pendingAPICallsCount = 0;
    axios.interceptors.request.use(
      function (config) {
        pendingAPICallsCount++;
        // show loader
        if (
          config.url.indexOf("skill_search") !== -1 ||
          config.url.indexOf("search_index") !== -1 ||
          config.url.indexOf("certificate_search") !== -1 ||
          config.url.indexOf("degree_search") !== -1 ||
          config.url.indexOf("major_search") !== -1 ||
          config.url.indexOf("companies/suggest") !== -1 ||
          config.url.indexOf("retrieve_password") !== -1
        ) {
          self.props.loading(false);
        } else {
          self.props.loading(true);
        }
        return config;
      },
      function (error) {
        return Promise.reject(error);
      }
    );

    axios.interceptors.response.use(
      function (response) {
        pendingAPICallsCount--;
        // hide loader
        if (pendingAPICallsCount === 0) {
          self.props.loading(false);
        }
        return response;
      },
      function (error) {
        pendingAPICallsCount--;
        // hide loader
        if (pendingAPICallsCount === 0) {
          self.props.loading(false);
        }
        return Promise.reject(error);
      }
    );
    this.currentPathname = null;
    this.currentSearch = null;
    this.state = {
      completedApproved: false,
      prevScrollPos: window.pageYOffset,
      visible: true,
    };
  }

  handleScroll = () => {
    const { prevScrollPos } = this.state;
    const currentScrollPos = window.pageYOffset;
    const visible = prevScrollPos > currentScrollPos;
    this.setState({ prevScrollPos: currentScrollPos, visible }, () => {
      this.saveNavbarVisibility();
      let navBar = document.getElementById("navBar");
      if (navBar) {
        if (this.state.visible) {
          navBar.style.display = "block";
        } else {
          navBar.style.display = "none";
        }
      }
    });
  };

  saveNavbarVisibility = () => {
    store.dispatch(sharedActions.setNavbarStatus(this.state.visible));
  };

  componentDidMount() {
    if (secureLocalStorage.getItem("g_u")) {
      toast.dismiss("ghost");
      toast.success("Logged in as SucceedSmart admin", GHOST_TOAST_CONF);
    }
    window.addEventListener("scroll", this.handleScroll);
  }

  componentWillUnmount() {
    window.removeEventListener("scroll", this.handleScroll);
  }

  render() {
    this.props.loggingIn(
      JSON.parse(secureLocalStorage.getItem("auth")) ? true : false
    );

    const pathName = (window.location.pathname + window.location.search).substr(
      1
    );
    if (
      pathName &&
      pathName !== "signin" &&
      !pathName.match("^/members.*$") &&
      pathName !== "member-profile" &&
      pathName !== "bad-request" &&
      !pathName.includes("share-profile")
    ) {
      const params = new URLSearchParams(window.location.search);
      if (params.get("Tab")) {
        store.dispatch(deepLink(window.location.pathname));
      } else {
        store.dispatch(deepLink(pathName));
      }
    }

    const paramsForShareProfile = new URLSearchParams(window.location.search);
    let arrayOfPathname = window.location.pathname.split("/");
    let signature = paramsForShareProfile.get("signature");
    if (signature && arrayOfPathname[2]) {
      store.dispatch(getSharedFullProfile(arrayOfPathname[2], signature));
    }

    return (
      <div className="App">
        {!pathName.match("^/members.*$") && <AppBar />}
        {/* <ShareProfileCredential /> */}
        {this.props.loader ? <AppLoader /> : null}
        <Toaster />
        <Router {...this.props} history={history}>
          <React.Fragment>
            <Switch>
              <PrivateRoute exact path="/jobs/add-job" component={JobPost} />
              {/* <PrivateRoute
                exact
                path='/dashboard'
                component={
                  this.props.gotRecruiterStatus !== null &&
                  JSON.parse(this.props.gotRecruiterStatus).approval_status &&
                  (JSON.parse(this.props.gotRecruiterStatus).onboarding_status === 'complete' || JSON.parse(this.props.gotRecruiterStatus).onboarding_status === 'Complete')
                  && JSON.parse(this.props.gotRecruiterStatus).payment_status === true
                  && JSON.parse(this.props.gotRecruiterStatus).is_disabled === false
                    ? Dashboard
                    :
                    this.props.gotRecruiterStatus !== null && JSON.parse(this.props.gotRecruiterStatus).approval_status && (JSON.parse(this.props.gotRecruiterStatus).onboarding_status === 'complete' || JSON.parse(this.props.gotRecruiterStatus).onboarding_status === 'Complete') && JSON.parse(this.props.gotRecruiterStatus).payment_status === false
                    && JSON.parse(this.props.gotRecruiterStatus).is_disabled === false 
                    ?
                    Payment
                    :
                    this.props.gotRecruiterStatus !== null && JSON.parse(this.props.gotRecruiterStatus).approval_status && (JSON.parse(this.props.gotRecruiterStatus).onboarding_status === 'complete' || JSON.parse(this.props.gotRecruiterStatus).onboarding_status === 'Complete')
                    && JSON.parse(this.props.gotRecruiterStatus).is_disabled === true
                    ?
                    NoAccess
                    :
                    JSON.parse(secureLocalStorage.getItem('recruiterStatusDetails'))?.onboarding_status === null
                    ?
                    NoAccess
                    :
                    secureLocalStorage.getItem('onboardingStatus') === 'contractSigned' && this.props.gotRecruiterStatus !== null && JSON.parse(this.props.gotRecruiterStatus).onboarding_status === 'contract_sign'
                    ?
                    ContractIncomplete
                    :
                    WaitingForApproval
                }
              /> */}
              <PrivateRoute
                exact
                path="/jobs/my-jobs"
                component={
                  this.props.gotRecruiterStatus !== null &&
                  JSON.parse(this.props.gotRecruiterStatus).approval_status &&
                  (JSON.parse(this.props.gotRecruiterStatus)
                    .onboarding_status === "complete" ||
                    JSON.parse(this.props.gotRecruiterStatus)
                      .onboarding_status === "Complete") &&
                  JSON.parse(this.props.gotRecruiterStatus).payment_status ===
                    true &&
                  JSON.parse(this.props.gotRecruiterStatus).is_disabled ===
                    false
                    ? Jobs
                    : this.props.gotRecruiterStatus !== null &&
                      JSON.parse(this.props.gotRecruiterStatus)
                        .approval_status &&
                      (JSON.parse(this.props.gotRecruiterStatus)
                        .onboarding_status === "complete" ||
                        JSON.parse(this.props.gotRecruiterStatus)
                          .onboarding_status === "Complete") &&
                      JSON.parse(this.props.gotRecruiterStatus)
                        .payment_status === false &&
                      JSON.parse(this.props.gotRecruiterStatus).is_disabled ===
                        false
                    ? Payment
                    : this.props.gotRecruiterStatus !== null &&
                      JSON.parse(this.props.gotRecruiterStatus)
                        .approval_status &&
                      (JSON.parse(this.props.gotRecruiterStatus)
                        .onboarding_status === "complete" ||
                        JSON.parse(this.props.gotRecruiterStatus)
                          .onboarding_status === "Complete") &&
                      JSON.parse(this.props.gotRecruiterStatus).is_disabled ===
                        true
                    ? NoAccess
                    : JSON.parse(
                        secureLocalStorage.getItem("recruiterStatusDetails")
                      )?.onboarding_status === null
                    ? NoAccess
                    : secureLocalStorage.getItem("onboardingStatus") ===
                        "contractSigned" &&
                      this.props.gotRecruiterStatus !== null &&
                      JSON.parse(this.props.gotRecruiterStatus)
                        .onboarding_status === "contract_sign"
                    ? ContractIncomplete
                    : WaitingForApproval
                }
              />
              <PrivateRoute
                exact
                path="/jobs/my-jobs/:id"
                component={
                  this.props.gotRecruiterStatus !== null &&
                  JSON.parse(this.props.gotRecruiterStatus).approval_status &&
                  (JSON.parse(this.props.gotRecruiterStatus)
                    .onboarding_status === "complete" ||
                    JSON.parse(this.props.gotRecruiterStatus)
                      .onboarding_status === "Complete") &&
                  JSON.parse(this.props.gotRecruiterStatus).payment_status ===
                    true &&
                  JSON.parse(this.props.gotRecruiterStatus).is_disabled ===
                    false
                    ? JobDetails
                    : this.props.gotRecruiterStatus !== null &&
                      JSON.parse(this.props.gotRecruiterStatus)
                        .approval_status &&
                      (JSON.parse(this.props.gotRecruiterStatus)
                        .onboarding_status === "complete" ||
                        JSON.parse(this.props.gotRecruiterStatus)
                          .onboarding_status === "Complete") &&
                      JSON.parse(this.props.gotRecruiterStatus)
                        .payment_status === false &&
                      JSON.parse(this.props.gotRecruiterStatus).is_disabled ===
                        false
                    ? Payment
                    : this.props.gotRecruiterStatus !== null &&
                      JSON.parse(this.props.gotRecruiterStatus)
                        .approval_status &&
                      (JSON.parse(this.props.gotRecruiterStatus)
                        .onboarding_status === "complete" ||
                        JSON.parse(this.props.gotRecruiterStatus)
                          .onboarding_status === "Complete") &&
                      JSON.parse(this.props.gotRecruiterStatus).is_disabled ===
                        true
                    ? NoAccess
                    : JSON.parse(
                        secureLocalStorage.getItem("recruiterStatusDetails")
                      )?.onboarding_status === null
                    ? NoAccess
                    : secureLocalStorage.getItem("onboardingStatus") ===
                        "contractSigned" &&
                      this.props.gotRecruiterStatus !== null &&
                      JSON.parse(this.props.gotRecruiterStatus)
                        .onboarding_status === "contract_sign"
                    ? ContractIncomplete
                    : WaitingForApproval
                }
              />
              <PrivateRoute
                exact
                path="/jobs/shared-jobs"
                component={
                  this.props.gotRecruiterStatus !== null &&
                  JSON.parse(this.props.gotRecruiterStatus).approval_status &&
                  (JSON.parse(this.props.gotRecruiterStatus)
                    .onboarding_status === "complete" ||
                    JSON.parse(this.props.gotRecruiterStatus)
                      .onboarding_status === "Complete") &&
                  JSON.parse(this.props.gotRecruiterStatus).payment_status ===
                    true &&
                  JSON.parse(this.props.gotRecruiterStatus).is_disabled ===
                    false
                    ? Jobs
                    : this.props.gotRecruiterStatus !== null &&
                      JSON.parse(this.props.gotRecruiterStatus)
                        .approval_status &&
                      (JSON.parse(this.props.gotRecruiterStatus)
                        .onboarding_status === "complete" ||
                        JSON.parse(this.props.gotRecruiterStatus)
                          .onboarding_status === "Complete") &&
                      JSON.parse(this.props.gotRecruiterStatus)
                        .payment_status === false &&
                      JSON.parse(this.props.gotRecruiterStatus).is_disabled ===
                        false
                    ? Payment
                    : this.props.gotRecruiterStatus !== null &&
                      JSON.parse(this.props.gotRecruiterStatus)
                        .approval_status &&
                      (JSON.parse(this.props.gotRecruiterStatus)
                        .onboarding_status === "complete" ||
                        JSON.parse(this.props.gotRecruiterStatus)
                          .onboarding_status === "Complete") &&
                      JSON.parse(this.props.gotRecruiterStatus).is_disabled ===
                        true
                    ? NoAccess
                    : JSON.parse(
                        secureLocalStorage.getItem("recruiterStatusDetails")
                      )?.onboarding_status === null
                    ? NoAccess
                    : secureLocalStorage.getItem("onboardingStatus") ===
                        "contractSigned" &&
                      this.props.gotRecruiterStatus !== null &&
                      JSON.parse(this.props.gotRecruiterStatus)
                        .onboarding_status === "contract_sign"
                    ? ContractIncomplete
                    : WaitingForApproval
                }
              />
              <PrivateRoute
                exact
                path="/jobs/shared-jobs/:id"
                component={
                  this.props.gotRecruiterStatus !== null &&
                  JSON.parse(this.props.gotRecruiterStatus).approval_status &&
                  (JSON.parse(this.props.gotRecruiterStatus)
                    .onboarding_status === "complete" ||
                    JSON.parse(this.props.gotRecruiterStatus)
                      .onboarding_status === "Complete") &&
                  JSON.parse(this.props.gotRecruiterStatus).payment_status ===
                    true &&
                  JSON.parse(this.props.gotRecruiterStatus).is_disabled ===
                    false
                    ? JobDetails
                    : this.props.gotRecruiterStatus !== null &&
                      JSON.parse(this.props.gotRecruiterStatus)
                        .approval_status &&
                      (JSON.parse(this.props.gotRecruiterStatus)
                        .onboarding_status === "complete" ||
                        JSON.parse(this.props.gotRecruiterStatus)
                          .onboarding_status === "Complete") &&
                      JSON.parse(this.props.gotRecruiterStatus)
                        .payment_status === false &&
                      JSON.parse(this.props.gotRecruiterStatus).is_disabled ===
                        false
                    ? Payment
                    : this.props.gotRecruiterStatus !== null &&
                      JSON.parse(this.props.gotRecruiterStatus)
                        .approval_status &&
                      (JSON.parse(this.props.gotRecruiterStatus)
                        .onboarding_status === "complete" ||
                        JSON.parse(this.props.gotRecruiterStatus)
                          .onboarding_status === "Complete") &&
                      JSON.parse(this.props.gotRecruiterStatus).is_disabled ===
                        true
                    ? NoAccess
                    : JSON.parse(
                        secureLocalStorage.getItem("recruiterStatusDetails")
                      )?.onboarding_status === null
                    ? NoAccess
                    : secureLocalStorage.getItem("onboardingStatus") ===
                        "contractSigned" &&
                      this.props.gotRecruiterStatus !== null &&
                      JSON.parse(this.props.gotRecruiterStatus)
                        .onboarding_status === "contract_sign"
                    ? ContractIncomplete
                    : WaitingForApproval
                }
              />
              {/* <PrivateRoute
                exact
                path='/members'
                component={
                  this.props.gotRecruiterStatus !== null &&
                  JSON.parse(this.props.gotRecruiterStatus).approval_status &&
                  (JSON.parse(this.props.gotRecruiterStatus).onboarding_status === 'complete' || JSON.parse(this.props.gotRecruiterStatus).onboarding_status === 'Complete')
                  && JSON.parse(this.props.gotRecruiterStatus).payment_status === true
                  && JSON.parse(this.props.gotRecruiterStatus).is_disabled === false
                    ? Members
                    :
                    this.props.gotRecruiterStatus !== null && JSON.parse(this.props.gotRecruiterStatus).approval_status && (JSON.parse(this.props.gotRecruiterStatus).onboarding_status === 'complete' || JSON.parse(this.props.gotRecruiterStatus).onboarding_status === 'Complete') && JSON.parse(this.props.gotRecruiterStatus).payment_status === false
                    && JSON.parse(this.props.gotRecruiterStatus).is_disabled === false 
                    ?
                    Payment
                    :
                    this.props.gotRecruiterStatus !== null && JSON.parse(this.props.gotRecruiterStatus).approval_status && (JSON.parse(this.props.gotRecruiterStatus).onboarding_status === 'complete' || JSON.parse(this.props.gotRecruiterStatus).onboarding_status === 'Complete')
                    && JSON.parse(this.props.gotRecruiterStatus).is_disabled === true
                    ?
                    NoAccess
                    :
                    JSON.parse(secureLocalStorage.getItem('recruiterStatusDetails'))?.onboarding_status === null
                    ?
                    NoAccess
                    :
                    secureLocalStorage.getItem('onboardingStatus') === 'contractSigned' && this.props.gotRecruiterStatus !== null && JSON.parse(this.props.gotRecruiterStatus).onboarding_status === 'contract_sign'
                    ?
                    ContractIncomplete
                    :
                    WaitingForApproval
                }
              /> */}
              <PrivateRoute
                exact
                path="/members/:id/:recommendationId/:matchedArchived"
                component={
                  this.props.gotRecruiterStatus !== null &&
                  JSON.parse(this.props.gotRecruiterStatus).approval_status &&
                  (JSON.parse(this.props.gotRecruiterStatus)
                    .onboarding_status === "complete" ||
                    JSON.parse(this.props.gotRecruiterStatus)
                      .onboarding_status === "Complete") &&
                  JSON.parse(this.props.gotRecruiterStatus).payment_status ===
                    true &&
                  JSON.parse(this.props.gotRecruiterStatus).is_disabled ===
                    false
                    ? MemberFullProfile
                    : this.props.gotRecruiterStatus !== null &&
                      JSON.parse(this.props.gotRecruiterStatus)
                        .approval_status &&
                      (JSON.parse(this.props.gotRecruiterStatus)
                        .onboarding_status === "complete" ||
                        JSON.parse(this.props.gotRecruiterStatus)
                          .onboarding_status === "Complete") &&
                      JSON.parse(this.props.gotRecruiterStatus)
                        .payment_status === false &&
                      JSON.parse(this.props.gotRecruiterStatus).is_disabled ===
                        false
                    ? Payment
                    : this.props.gotRecruiterStatus !== null &&
                      JSON.parse(this.props.gotRecruiterStatus)
                        .approval_status &&
                      (JSON.parse(this.props.gotRecruiterStatus)
                        .onboarding_status === "complete" ||
                        JSON.parse(this.props.gotRecruiterStatus)
                          .onboarding_status === "Complete") &&
                      JSON.parse(this.props.gotRecruiterStatus).is_disabled ===
                        true
                    ? NoAccess
                    : JSON.parse(
                        secureLocalStorage.getItem("recruiterStatusDetails")
                      )?.onboarding_status === null
                    ? NoAccess
                    : secureLocalStorage.getItem("onboardingStatus") ===
                        "contractSigned" &&
                      this.props.gotRecruiterStatus !== null &&
                      JSON.parse(this.props.gotRecruiterStatus)
                        .onboarding_status === "contract_sign"
                    ? ContractIncomplete
                    : WaitingForApproval
                }
              />
              <PrivateRoute
                exact
                path="/signup"
                component={
                  JSON.parse(
                    secureLocalStorage.getItem("recruiterStatusDetails")
                  )?.onboarding_status === null
                    ? NoAccess
                    : SignUp
                }
              />
              <PrivateRoute
                exact
                path="/signin"
                component={
                  JSON.parse(
                    secureLocalStorage.getItem("recruiterStatusDetails")
                  )?.onboarding_status === null
                    ? NoAccess
                    : SignIn
                }
              />
              <PrivateRoute
                exact
                path="/confirm-signup"
                component={
                  JSON.parse(
                    secureLocalStorage.getItem("recruiterStatusDetails")
                  )?.onboarding_status === null
                    ? NoAccess
                    : VerifyOtp
                }
              />
              <PrivateRoute
                exact
                path="/signup-success"
                component={
                  JSON.parse(
                    secureLocalStorage.getItem("recruiterStatusDetails")
                  )?.onboarding_status === null
                    ? NoAccess
                    : SignUpConfirm
                }
              />
              <PrivateRoute
                exact
                path="/forgot-password"
                component={
                  JSON.parse(
                    secureLocalStorage.getItem("recruiterStatusDetails")
                  )?.onboarding_status === null
                    ? NoAccess
                    : ForgotPassword
                }
              />
              <PrivateRoute
                exact
                path="/reset-password"
                component={
                  JSON.parse(
                    secureLocalStorage.getItem("recruiterStatusDetails")
                  )?.onboarding_status === null
                    ? NoAccess
                    : ResetPassword
                }
              />
              <PrivateRoute
                exact
                path="/verify-email"
                component={
                  JSON.parse(
                    secureLocalStorage.getItem("recruiterStatusDetails")
                  )?.onboarding_status === null
                    ? NoAccess
                    : VerifyEmail
                }
              />
              <PrivateRoute
                exact
                path="/change-password"
                component={
                  JSON.parse(
                    secureLocalStorage.getItem("recruiterStatusDetails")
                  )?.onboarding_status === null
                    ? NoAccess
                    : ChangePassword
                }
              />
              <PrivateRoute
                exact
                path="/payment"
                component={
                  this.props.gotRecruiterStatus !== null &&
                  JSON.parse(this.props.gotRecruiterStatus).payment_status ===
                    true &&
                  JSON.parse(this.props.gotRecruiterStatus).is_disabled ===
                    false
                    ? Dashboard
                    : this.props.gotRecruiterStatus !== null &&
                      JSON.parse(this.props.gotRecruiterStatus)
                        .payment_status === false &&
                      JSON.parse(this.props.gotRecruiterStatus).is_disabled ===
                        false
                    ? Payment
                    : NoAccess
                }
              />
              <PrivateRoute
                exact
                path="/confirm-payment"
                component={
                  JSON.parse(
                    secureLocalStorage.getItem("recruiterStatusDetails")
                  )?.onboarding_status === null
                    ? NoAccess
                    : ConfirmPayment
                }
              />
              <PrivateRoute
                exact
                path="/payment-auth-status"
                component={
                  JSON.parse(
                    secureLocalStorage.getItem("recruiterStatusDetails")
                  )?.onboarding_status === null
                    ? NoAccess
                    : ConfirmAuthentication
                }
              />
              <PrivateRoute
                exact
                path="/company-info"
                component={
                  JSON.parse(
                    secureLocalStorage.getItem("recruiterStatusDetails")
                  )?.onboarding_status === null
                    ? NoAccess
                    : CompanyInfo
                }
              />
              <PrivateRoute
                exact
                path="/application-status"
                component={
                  JSON.parse(
                    secureLocalStorage.getItem("recruiterStatusDetails")
                  )?.onboarding_status === null
                    ? NoAccess
                    : secureLocalStorage.getItem("onboardingStatus") ===
                        "contractSigned" &&
                      this.props.gotRecruiterStatus !== null &&
                      JSON.parse(this.props.gotRecruiterStatus)
                        .onboarding_status === "contract_sign"
                    ? ContractIncomplete
                    : WaitingForApproval
                }
              />
              <PrivateRoute
                exact
                path="/no-access"
                component={
                  JSON.parse(
                    secureLocalStorage.getItem("recruiterStatusDetails")
                  )?.onboarding_status === null
                    ? NoAccess
                    : secureLocalStorage.getItem("onboardingStatus") ===
                        "contractSigned" &&
                      this.props.gotRecruiterStatus !== null &&
                      JSON.parse(this.props.gotRecruiterStatus)
                        .onboarding_status === "contract_sign"
                    ? ContractIncomplete
                    : NoAccess
                }
              />
              <PrivateRoute
                exact
                path="/domain-check"
                component={
                  JSON.parse(
                    secureLocalStorage.getItem("recruiterStatusDetails")
                  )?.onboarding_status === null
                    ? NoAccess
                    : DomainExists
                }
              />
              <PrivateRoute
                exact
                path="/sign-contract"
                component={
                  JSON.parse(
                    secureLocalStorage.getItem("recruiterStatusDetails")
                  )?.onboarding_status === null
                    ? NoAccess
                    : SignContract
                }
              />
              <PrivateRoute
                exact
                path="/contract-signed"
                component={
                  JSON.parse(
                    secureLocalStorage.getItem("recruiterStatusDetails")
                  )?.onboarding_status === null
                    ? NoAccess
                    : ContractCompleted
                }
              />
              <PrivateRoute
                exact
                path="/contract-incomplete"
                component={
                  JSON.parse(
                    secureLocalStorage.getItem("recruiterStatusDetails")
                  )?.onboarding_status === null
                    ? NoAccess
                    : ContractIncomplete
                }
              />
              <PrivateRoute
                exact
                path="/messages"
                component={
                  this.props.gotRecruiterStatus !== null &&
                  JSON.parse(this.props.gotRecruiterStatus).approval_status &&
                  (JSON.parse(this.props.gotRecruiterStatus)
                    .onboarding_status === "complete" ||
                    JSON.parse(this.props.gotRecruiterStatus)
                      .onboarding_status === "Complete") &&
                  JSON.parse(this.props.gotRecruiterStatus).payment_status ===
                    true &&
                  JSON.parse(this.props.gotRecruiterStatus).is_disabled ===
                    false &&
                  !secureLocalStorage.getItem("g_u")
                    ? Messages
                    : this.props.gotRecruiterStatus !== null &&
                      JSON.parse(this.props.gotRecruiterStatus)
                        .approval_status &&
                      (JSON.parse(this.props.gotRecruiterStatus)
                        .onboarding_status === "complete" ||
                        JSON.parse(this.props.gotRecruiterStatus)
                          .onboarding_status === "Complete") &&
                      JSON.parse(this.props.gotRecruiterStatus)
                        .payment_status === false &&
                      JSON.parse(this.props.gotRecruiterStatus).is_disabled ===
                        false
                    ? Payment
                    : (this.props.gotRecruiterStatus !== null &&
                        JSON.parse(this.props.gotRecruiterStatus)
                          .approval_status &&
                        (JSON.parse(this.props.gotRecruiterStatus)
                          .onboarding_status === "complete" ||
                          JSON.parse(this.props.gotRecruiterStatus)
                            .onboarding_status === "Complete") &&
                        JSON.parse(this.props.gotRecruiterStatus)
                          .is_disabled === true) ||
                      secureLocalStorage.getItem("g_u")
                    ? NoAccess
                    : JSON.parse(
                        secureLocalStorage.getItem("recruiterStatusDetails")
                      )?.onboarding_status === null ||
                      secureLocalStorage.getItem("g_u")
                    ? NoAccess
                    : secureLocalStorage.getItem("onboardingStatus") ===
                        "contractSigned" &&
                      this.props.gotRecruiterStatus !== null &&
                      JSON.parse(this.props.gotRecruiterStatus)
                        .onboarding_status === "contract_sign"
                    ? ContractIncomplete
                    : WaitingForApproval
                }
              />
              <PrivateRoute
                exact
                path="/my-account"
                component={
                  this.props.gotRecruiterStatus !== null &&
                  JSON.parse(this.props.gotRecruiterStatus).approval_status &&
                  (JSON.parse(this.props.gotRecruiterStatus)
                    .onboarding_status === "complete" ||
                    JSON.parse(this.props.gotRecruiterStatus)
                      .onboarding_status === "Complete") &&
                  JSON.parse(this.props.gotRecruiterStatus).payment_status ===
                    true &&
                  JSON.parse(this.props.gotRecruiterStatus).is_disabled ===
                    false
                    ? MyAccount
                    : this.props.gotRecruiterStatus !== null &&
                      JSON.parse(this.props.gotRecruiterStatus)
                        .approval_status &&
                      (JSON.parse(this.props.gotRecruiterStatus)
                        .onboarding_status === "complete" ||
                        JSON.parse(this.props.gotRecruiterStatus)
                          .onboarding_status === "Complete") &&
                      JSON.parse(this.props.gotRecruiterStatus)
                        .payment_status === false &&
                      JSON.parse(this.props.gotRecruiterStatus).is_disabled ===
                        false
                    ? Payment
                    : this.props.gotRecruiterStatus !== null &&
                      JSON.parse(this.props.gotRecruiterStatus)
                        .approval_status &&
                      (JSON.parse(this.props.gotRecruiterStatus)
                        .onboarding_status === "complete" ||
                        JSON.parse(this.props.gotRecruiterStatus)
                          .onboarding_status === "Complete") &&
                      JSON.parse(this.props.gotRecruiterStatus).is_disabled ===
                        true
                    ? NoAccess
                    : JSON.parse(
                        secureLocalStorage.getItem("recruiterStatusDetails")
                      )?.onboarding_status === null
                    ? NoAccess
                    : secureLocalStorage.getItem("onboardingStatus") ===
                        "contractSigned" &&
                      this.props.gotRecruiterStatus !== null &&
                      JSON.parse(this.props.gotRecruiterStatus)
                        .onboarding_status === "contract_sign"
                    ? ContractIncomplete
                    : WaitingForApproval
                }
              />
              <Route
                exact
                path="/jobs"
                render={() => <Redirect to={{ pathname: "/jobs/my-jobs" }} />}
              />
              <Route exact path="/auth/:token" component={GhostLogin} />
              <Route exact path="/bad-request" component={BadRequest} />
              <Route
                path="/share-profile/:candidate_id"
                component={SharedFullProfile}
              />
              <Route
                path="/"
                render={() => (
                  <Redirect
                    to={{
                      pathname: JSON.parse(secureLocalStorage.getItem("auth"))
                        ? "/jobs"
                        : "/signin",
                    }}
                  />
                )}
              />
              <Route
                path="*"
                render={() => <Redirect to={{ pathname: "/signin" }} />}
                // component={Home}
              />
            </Switch>
          </React.Fragment>
        </Router>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    loggedIn: secureLocalStorage.getItem("auth") ? true : false,
    loader: state.loader,
    gotRecruiterStatus: secureLocalStorage.getItem("recruiterStatusDetails"),
  };
};

export default connect(mapStateToProps, {
  loading,
  loggingIn,
})(App);
