import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  IconButton,
  Typography,
} from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import CloseIcon from "@material-ui/icons/Close";
import LinearProgress from "@material-ui/core/LinearProgress";
import { useDispatch, useSelector } from "react-redux";
import LikeCanidate from "../../assets/like_candidate.svg";
import { jobsActions } from "../../actions/jobs.actions";

const useStyles = makeStyles((theme) => ({
  DialogTitle: {
    background: "#FBC112",
    fontSize: "28px",
    fontWeight: 700,
    textAlign: "center",
  },
  dailogText: {
    textAlign: "center",
    fontSize: "16px",
    fontWeight: 400,
  },
  dailogActions: {
    display: "flex",
    justifyContent: "center",
    marginBottom: "15px",
  },
  confirmButton: {
    background: "#000000",
    color: "#FFFFFF",
  },
  closeButton: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(1),
    padding: "0px",
    background: "#000000",
    color: "#FFFFFF",
    "&:hover": {
      background: "#000000",
      color: "#FFFFFF",
    },
  },
  dailogCofimTitle: {
    fontSize: "28px",
    fontWeight: 700,
    textAlign: "center",
  },
  colorPrimary: {
    background: "#41B97C",
  },
  confirmDailogActions: {
    display: "flex",
    justifyContent: "center",
    flexDirection: "column",
    alignItems: "center",
    marginBottom: "10px",
  },
  confirmTypo: {
    padding: "20px 70px",
  },
  linearProgressWidth: {
    width: "100%",
    marginTop: "10px",
  },
  subTypopopup: { fontSize: "12px", fontWeight: 400, marginTop: "10px" },
}));
function LikeComponent({
  open,
  jobId,
  recommendationId,
  onCLoseLike,
  handleOtherLiteProfileViewCLose,
  isLiteProfile,
}) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const headerCardValue = useSelector(
    (state) => state.jobs.allUpdateHeaderCard
  );

  const [openMessagePopup, setMessagePopup] = useState(false);
  const [progress, setProgress] = React.useState(0);

  const handleConfirmButton = () => {
    onCLoseLike();
    const payload = {
      is_passed: true,
    };
    dispatch(
      jobsActions.likeCandidateRecommendation(jobId, recommendationId, payload)
    );
    setMessagePopup(true);
  };

  useEffect(() => {
    if (openMessagePopup) {
      autoClosePopup();
    }
  }, [openMessagePopup]);

  const autoClosePopup = () => {
    setTimeout(() => {
      setMessagePopup(false);
      switch (headerCardValue) {
        case "All":
          dispatch(jobsActions.getAllUpdatesData(jobId, "All"));
          break;
        case "1":
          dispatch(jobsActions.getAllUpdatesData(jobId, "Recommended"));
          break;
        case "2":
          dispatch(jobsActions.getAllUpdatesData(jobId, "Matched"));
          break;
        case "3":
          dispatch(jobsActions.getAllUpdatesData(jobId, "Conversation"));
          break;
        default:
          break;
      }
      if (isLiteProfile) {
        handleOtherLiteProfileViewCLose();
      }
    }, 3000);
  };

  useEffect(() => {
    const timer = setInterval(() => {
      setProgress((oldProgress) => {
        if (oldProgress === 100) {
          return 0;
        }
        const diff = Math.random() * 10;
        return Math.min(oldProgress + diff, 100);
      });
    }, 300);

    return () => {
      clearInterval(timer);
    };
  }, []);

  return (
    <>
      <Dialog open={open}>
        <DialogTitle className={classes.DialogTitle}>
          Confirm marked interested?
        </DialogTitle>
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={onCLoseLike}
        >
          <CloseIcon />
        </IconButton>
        <DialogContent>
          <DialogContentText className={classes.dailogText}>
            Please confirm if you want to mark this candidate as interested?
          </DialogContentText>
        </DialogContent>
        <DialogActions className={classes.dailogActions}>
          <Button onClick={onCLoseLike}>Cancel</Button>
          <Button
            disableElevation
            className={classes.confirmButton}
            onClick={handleConfirmButton}
            variant="contained"
          >
            Yes, Confirm!
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog open={openMessagePopup}>
        <DialogTitle className={classes.dailogCofimTitle}>
          Candidate is marked as interested
        </DialogTitle>
        <DialogContent className={classes.confirmDailogActions}>
          <img height={100} width={100} alt="Marked hired" src={LikeCanidate} />
          <div className={classes.linearProgressWidth}>
            <LinearProgress
              className={classes.colorPrimary}
              variant="indeterminate"
              value={progress}
            />
          </div>
          <Typography className={classes.subTypopopup}>
            This popup message will automatically close in 3 seconds
          </Typography>
        </DialogContent>
      </Dialog>
    </>
  );
}

export default LikeComponent;
