import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Button,
  IconButton,
} from "@material-ui/core";
import React from "react";
import classNames from "classnames";
import { makeStyles } from "@material-ui/core/styles";
import { useSelector } from "react-redux";
import CloseIcon from "@material-ui/icons/Close";
import cardHeaderBg from "../../assets/lite_profile_bg.png";

function PreviewEmaildailog({ open, editPreview, onSend, onClose }) {
  const useStyles = makeStyles((theme) => ({
    closeButton: {
      position: "absolute",
      right: theme.spacing(1),
      top: theme.spacing(1),
      color: theme.palette.default.main,
      padding: "0px",
    },
    textAlignCenter: {
      textAlign: "center",
    },
    scheduledialogActionsButton: {
      width: "8rem",
      marginTop: "8px",
      marginBottom: "18px",
    },
    scheduledialogActions: {
      justifyContent: "center !important",
    },
    smallTopMargin: {
      marginTop: "2%",
    },
    smallBottomMargin: {
      marginBottom: "2%",
    },
    customCardHeader: {
      maxHeight: "38%",
      height: "38%",
      color: "#000",
      backgroundSize: "cover",
      backgroundImage: `url(${cardHeaderBg})`,
      background: "round",
    },
  }));
  const classes = useStyles();
  const emailPreview = useSelector(
    (state) => state.members.sendSchedulingLinkEmailPreview
  );
  return (
    <Dialog
      open={open}
      fullWidth
      maxWidth="md"
      aria-labelledby="form-dialog-title"
    >
      <DialogTitle
        id="form-dialog-title"
        className={classNames(
          classes.textAlignCenter,
          classes.customCardHeader
        )}
      >
        Preview: {emailPreview?.email_subject}
        <IconButton
          aria-label="close"
          onClick={onClose}
          className={classes.closeButton}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        <div dangerouslySetInnerHTML={{ __html: emailPreview?.email_body }} />
      </DialogContent>
      <DialogActions className={classes.scheduledialogActions}>
        <Button
          className={classNames(
            classes.scheduledialogActionsButton,
            classes.textAlignCenter
          )}
          disableElevation
          variant="outlined"
          color="primary"
          onClick={editPreview}
        >
          Edit
        </Button>
        <Button
          onClick={onSend}
          className={classNames(
            classes.scheduledialogActionsButton,
            classes.textAlignCenter
          )}
          disableElevation
          variant="contained"
          color="primary"
        >
          Send
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default PreviewEmaildailog;
