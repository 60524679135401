import React, { Component } from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import Typography from "@material-ui/core/Typography";
import { Card, Divider, Grid, Paper } from "@material-ui/core";
import CardContent from "@material-ui/core/CardContent";
import classNames from "classnames";
import { history, seo } from "../../../helpers";
import { ReactComponent as RecommendedEmpty } from "../../../assets/dashboard_recommendation.svg";
import { ReactComponent as MatchedCriteria } from "../../../assets/dashboard_matched_criteria.svg";
import { ReactComponent as LikeDislikeIcon } from "../../../assets/dashboard_like_dislike.svg";
import { ReactComponent as JobPostsLikes } from "../../../assets/briefcase.svg";
import { ReactComponent as ProfileAccount } from "../../../assets/profile_account.svg";
import { ReactComponent as TechSupport } from "../../../assets/support.svg";
import { ReactComponent as GetStartedIcon } from "../../../assets/shuttle.svg";
import { ReactComponent as MeetingIcon } from "../../../assets/meeting.svg";
import { authActions, sharedActions } from "../../../actions";
import { setTitle } from "../../../actions";

const styles = (theme) => ({
  root: {
    marginTop: "90px",
    width: "100%",
    // [theme.breakpoints.between("xs", "sm")]: {
    //   marginTop: '90px'
    // }
  },
  appFrame: {
    zIndex: 1,
    overflow: "hidden",
    position: "relative",
    display: "flex",
    // width: '100%',
    padding: theme.spacing(6),
  },
  content: {
    flexGrow: 1,
    // marginTop: '0.2%',
    padding: theme.spacing(6),
    [theme.breakpoints.down("md")]: {
      padding: theme.spacing(2),
    },
    [theme.breakpoints.down("sm")]: {
      padding: theme.spacing(2),
    },
  },
  bullet: {
    display: "inline-block",
    margin: "0 2px",
    transform: "scale(0.8)",
  },
  title: {
    fontSize: 14,
  },
  pos: {
    marginBottom: 12,
  },
  moduleConfig: {
    float: "left",
    fontSize: "26px",
  },
  customCard: {
    backgroundColor: "#F7F7F7",
    boxShadow:
      "0px 0px 0px 0px rgb(0 0 0 / 20%), 0px 2px 1px 0px rgb(0 0 0 / 5%), 0px 0px 3px 0px rgb(0 0 0 / 0%)",
    borderTop: "7px solid #FBC112",
    // height: 'calc(100% - 50px)'
  },
  newCustomCard: {
    backgroundColor: "#F7F7F7",
    boxShadow:
      "0px 0px 0px 0px rgb(0 0 0 / 20%), 0px 2px 1px 0px rgb(0 0 0 / 5%), 0px 0px 3px 0px rgb(0 0 0 / 0%)",
    borderTop: "7px solid #FBC112",
    // height: 'calc(100% - 50px)'
  },
  videoCard: {
    // backgroundColor: '#000000',
    // display: 'flex',
    // justifyContent: 'center',
    // alignItems: 'center'
  },
  smallBottomMargin: {
    marginBottom: "20px",
  },
  smallTopMargin: {
    marginTop: "20px",
  },
  verySmallBottomMargin: {
    marginBottom: "10px",
  },
  verySmallTopMargin: {
    marginTop: "10px",
  },
  boldFont: {
    fontWeight: "bold",
  },
  customDivider: {
    color: "#E8E8E8",
    width: "100%",
    marginTop: "2%",
    marginBottom: "2%",
  },
  alignSelfCenter: {
    alignSelf: "center",
  },
  customAlignment: {
    [theme.breakpoints.between("xs", "sm")]: {
      textAlign: "center",
    },
    textAlign: "left",
  },
  customPaper: {
    boxShadow: "none",
    padding: "30px",
    height: "100%",
    overflowWrap: "anywhere",
    cursor: "pointer",
    "&:hover": {
      textDecoration: "underline",
    },
  },
  sideBlock: {
    padding: theme.spacing(1),
    // display: 'flex',
    // flexDirection: 'column'
  },
  quickTips: {
    // flex: '1'
    // padding: '25px 0 25px 0',
    // [theme.breakpoints.between('xs', 'md')]: {
    //   padding: '0 0 0 0'
    // }
  },
  sideDetails: {
    minHeight: "65px",
    maxHeight: "110px",
    fontSize: "0.85rem",
  },
  sideIcon: {
    with: "52px !important",
  },
  fullHeight: {
    height: "100%",
  },
});

class Dashboard extends Component {
  componentDidMount() {
    const { dispatch } = this.props;
    dispatch(setTitle(`${`Dashboard`}`));
    document.body.scrollTop = 0; // For Safari
    document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE and Opera
  }

  componentDidUpdate() {
    const { pageTitle } = this.props.pageTitle;
    seo({
      title: `${pageTitle}`,
    });
  }

  goToFaq = () => {
    const { dispatch } = this.props;
    dispatch(authActions.getHelp());
  };

  goToJobs = () => {
    const { dispatch } = this.props;
    dispatch(sharedActions.jobsPageTabChange(0));
    history.push("/jobs/my-jobs");
  };

  goToMyAccount = () => {
    history.push("/my-account");
  };

  goToTechSupport = () => {
    window.open(
      " https://support.succeedsmart.com/hc/en-us/requests/new",
      "_blank"
    );
  };

  render() {
    const { classes } = this.props;
    let constants = this.props.authentication?.constants;
    return (
      <div className={classes.root}>
        <main className={classes.content}>
          <Grid container spacing={4} className={classes.smallBottomMargin}>
            <Grid item xs={12} sm={12} md={12} lg={8} xl={8}>
              <Typography
                variant="h4"
                align="left"
                className={classNames(
                  classes.verySmallBottomMargin,
                  classes.boldFont
                )}
              >
                Welcome to SucceedSmart
              </Typography>
              <Card
                container
                alignItems="center"
                className={classNames(classes.customCard, classes.videoCard)}
              >
                <CardContent className={classes.fullHeight}>
                  <video
                    preload="auto"
                    controls
                    title={constants?.corporate_demo_videos?.topic}
                    id="dashboardVideo"
                    width="100%"
                    height="auto"
                    src={constants?.corporate_demo_videos?.video_url}
                    frameBorder="0"
                    // allow="accelerometer, autoplay; encrypted-media; gyroscope; picture-in-picture"
                    allow="accelerometer; encrypted-media; gyroscope;"
                    allowFullScreen
                    controlsList="nodownload"
                    disablePictureInPicture
                    poster={constants?.corporate_demo_videos?.thumbnail_url}
                    // style={{objectFit: 'fill'}}
                  />
                </CardContent>
              </Card>
            </Grid>
            <Grid item xs={12} sm={12} md={12} lg={4} xl={4}>
              <Typography
                variant="h4"
                align="left"
                className={classNames(
                  classes.verySmallBottomMargin,
                  classes.boldFont
                )}
              >
                Quick Tips
              </Typography>
              <Card className={classes.newCustomCard}>
                <CardContent className={classes.sideBlock}>
                  <Grid container className={classes.quickTips}>
                    <Grid
                      item
                      xs={12}
                      sm={12}
                      md={3}
                      lg={3}
                      xl={3}
                      className={classes.alignSelfCenter}
                    >
                      <RecommendedEmpty className={classes.sideIcon} />
                    </Grid>
                    <Grid
                      item
                      xs={12}
                      sm={12}
                      md={9}
                      lg={9}
                      xl={9}
                      className={classes.customAlignment}
                    >
                      <Typography variant="h6">{"Recommendations"}</Typography>
                      <Typography
                        color="textSecondary"
                        className={classes.sideDetails}
                      >
                        {
                          "Our AI-technology will recommend best-fit candidates for your job requisitions."
                        }
                      </Typography>
                    </Grid>
                  </Grid>
                  <Divider
                    className={classNames(
                      classes.smallTopMargin,
                      classes.smallBottomMargin,
                      classes.customDivider
                    )}
                  ></Divider>
                  <Grid container className={classes.quickTips}>
                    <Grid
                      item
                      xs={12}
                      sm={12}
                      md={3}
                      lg={3}
                      xl={3}
                      className={classes.alignSelfCenter}
                    >
                      {/* <MatchedEmpty className={classes.sideIcon} /> */}
                      <MatchedCriteria className={classes.sideIcon} />
                    </Grid>
                    <Grid
                      item
                      xs={12}
                      sm={12}
                      md={9}
                      lg={9}
                      xl={9}
                      className={classes.customAlignment}
                    >
                      <Typography variant="h6">{"Matches"}</Typography>
                      <Typography
                        color="textSecondary"
                        className={classes.sideDetails}
                      >
                        {
                          "A green star indicates that a specific criteria of a candidate profile has an exact match to a specific criteria of your job requisition."
                        }
                      </Typography>
                      {/* {"A green star indicates that a candidate has accepted your connection request. You are now matched and will be able to directly communicate with one another."} */}
                    </Grid>
                  </Grid>
                  <Divider
                    className={classNames(
                      classes.smallTopMargin,
                      classes.smallBottomMargin,
                      classes.customDivider
                    )}
                  ></Divider>
                  <Grid container className={classes.quickTips}>
                    <Grid
                      item
                      xs={12}
                      sm={12}
                      md={3}
                      lg={3}
                      xl={3}
                      className={classes.alignSelfCenter}
                    >
                      <LikeDislikeIcon
                        width="96%"
                        className={classes.sideIcon}
                      />
                    </Grid>
                    <Grid
                      item
                      xs={12}
                      sm={12}
                      md={9}
                      lg={9}
                      xl={9}
                      className={classes.customAlignment}
                    >
                      <Typography variant="h6">
                        {"Connection Requests"}
                      </Typography>
                      <Typography
                        color="textSecondary"
                        className={classes.sideDetails}
                      >
                        Use the thumbs up icon to request or accept a candidate
                        connection and use the thumbs down icon to pass on a
                        candidate when the fit isn&apos;t right.
                      </Typography>
                    </Grid>
                  </Grid>
                  <Divider
                    className={classNames(
                      classes.smallTopMargin,
                      classes.smallBottomMargin,
                      classes.customDivider
                    )}
                  ></Divider>
                  <Grid container className={classes.quickTips}>
                    <Grid
                      item
                      xs={12}
                      sm={12}
                      md={3}
                      lg={3}
                      xl={3}
                      className={classes.alignSelfCenter}
                    >
                      <MeetingIcon className={classes.sideIcon} />
                      {/* <Typography color="textSecondary">{"new icon requested..."}</Typography> */}
                    </Grid>
                    <Grid
                      item
                      xs={12}
                      sm={12}
                      md={9}
                      lg={9}
                      xl={9}
                      className={classes.customAlignment}
                    >
                      <Typography variant="h6">{"Meetings"}</Typography>
                      <Typography
                        color="textSecondary"
                        className={classes.sideDetails}
                      >
                        {
                          "Easily schedule meetings with candidates via Calendly in our messaging tool."
                        }
                      </Typography>
                    </Grid>
                  </Grid>
                </CardContent>
              </Card>
            </Grid>
          </Grid>
          <Typography
            variant="h4"
            align="left"
            className={classNames(
              classes.verySmallBottomMargin,
              classes.boldFont
            )}
          >
            {"Control Center"}
          </Typography>
          <Card className={classes.customCard}>
            <CardContent>
              <Grid container spacing={3}>
                <Grid item xs={12} sm={12} md={6} lg={3} xl={3}>
                  <Paper
                    className={classes.customPaper}
                    onClick={(event) => {
                      event.preventDefault();
                      this.goToFaq();
                    }}
                  >
                    <GetStartedIcon />
                    <Typography variant="h6" className={classes.boldFont}>
                      {"Get Started"}
                    </Typography>
                    <Typography color="textSecondary">
                      {"Get an overview of the SucceedSmart platform."}
                    </Typography>
                  </Paper>
                </Grid>
                <Grid item xs={12} sm={12} md={6} lg={3} xl={3}>
                  <Paper
                    className={classes.customPaper}
                    onClick={(event) => {
                      event.preventDefault();
                      this.goToJobs();
                    }}
                  >
                    <JobPostsLikes />
                    {/* <Typography color="textSecondary">{"new icon requested..."}</Typography> */}
                    <Typography variant="h6" className={classes.boldFont}>
                      {"Job Postings & Candidates"}
                    </Typography>
                    <Typography color="textSecondary">
                      {
                        "Post a job requisition and connect with future-ready leaders."
                      }
                    </Typography>
                  </Paper>
                </Grid>
                <Grid item xs={12} sm={12} md={6} lg={3} xl={3}>
                  <Paper
                    className={classes.customPaper}
                    onClick={(event) => {
                      event.preventDefault();
                      this.goToMyAccount();
                    }}
                  >
                    <ProfileAccount />
                    <Typography variant="h6" className={classes.boldFont}>
                      {"My Account"}
                    </Typography>
                    <Typography color="textSecondary">
                      {"Access your profile, settings and payment information."}
                    </Typography>
                  </Paper>
                </Grid>
                <Grid item xs={12} sm={12} md={6} lg={3} xl={3}>
                  <Paper
                    className={classes.customPaper}
                    onClick={(event) => {
                      event.preventDefault();
                      this.goToTechSupport();
                    }}
                  >
                    <TechSupport />
                    <Typography variant="h6" className={classes.boldFont}>
                      {"Support"}
                    </Typography>
                    <Typography color="textSecondary">
                      {"Have questions or need support?"}
                    </Typography>
                  </Paper>
                </Grid>
              </Grid>
            </CardContent>
          </Card>
        </main>
      </div>
    );
  }
}

Dashboard.propTypes = {
  classes: PropTypes.object.isRequired,
};

function mapStateToProps(state) {
  return state;
}
const connectedDashboardPage = withRouter(
  connect(mapStateToProps, null, null, {
    pure: false,
  })(withStyles(styles)(Dashboard))
);
export { connectedDashboardPage as Dashboard };
