import { toast } from "react-toastify";
import { apiService } from "../services";
import { history } from "../helpers";
import secureLocalStorage from "react-secure-storage";

export const messagesActions = {
  getCurrentUserGetStreamToken,
  createChannel,
};

function getCurrentUserGetStreamToken(payload) {
  return (dispatch) => {
    const apiEndpoint = "/api/v1/message/get_stream/auth/";
    apiService
      .post(apiEndpoint, payload)
      .then((response) => {
        if (response && (response.status === 200 || response.status === 201)) {
          dispatch(changeCurrentUserGetStreamToken(response.data));
          //  history.push(`/members/${candidateId}`);
        } else if (response.status !== 401) {
          toast.dark("Something went haywire");
          // history.push('/members');
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };
}

function createChannel(candidateId) {
  const recruiterId =
    JSON.parse(secureLocalStorage.getItem("recruiterId")) || null;

  if (recruiterId && candidateId) {
    const payload = {};
    payload.user_id1 = recruiterId;
    payload[recruiterId] = "recruiter";
    payload.user_id2 = candidateId;
    payload[candidateId] = "candidate";
    return () => {
      const apiEndpoint = "/api/v1/message/get_stream/channel/";
      apiService
        .post(apiEndpoint, payload)
        .then((response) => {
          if (response.data && response.data?.data?.cid) {
            history.push(`/messages/?cid=${response.data.data.cid}`);
          } else if (response.status !== 401) {
            toast.dark("Something went haywire");
            // history.push('/members');
          }
        })
        .catch((err) => {
          console.log(err);
        });
    };
  }
}

function changeCurrentUserGetStreamToken(data) {
  return {
    type: "CURRENT_USER_GET_STREAM_TOKEN",
    getStreamUserTokenData: data,
  };
}
