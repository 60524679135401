import { React, Component } from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import classNames from "classnames";
// import { withRouter } from 'react-router-dom';
import Typography from "@material-ui/core/Typography";
import { Grid } from "@material-ui/core";
import { connect } from "react-redux";
// import { ReactComponent as DiversityIcon } from '../../assets/Icon_diversity.svg';
// import { ReactComponent as ProductivityIcon } from '../../assets/Icon_productivity.svg';
// import { ReactComponent as BudgetIcon } from '../../assets/Icon_budget.svg';
// import { loggingIn } from '../../actions';

const styles = (theme) => ({
  root: {
    height: "calc(100vh - 90px)", // '100vh',
    marginTop: "90px",
  },
  boldFont: {
    fontWeight: "bold",
  },
  customLogoSpacing: {
    textAlign: "start",
    marginBottom: "40px",
  },
  // image: {
  //   backgroundImage: `url(${ProductInfoImage})`, //'url(https://source.unsplash.com/random)',
  //   backgroundRepeat: 'no-repeat',
  //   backgroundColor:
  //     theme.palette.type === 'light' ? theme.palette.grey[50] : theme.palette.grey[900],
  //   backgroundSize: 'cover'
  // },
  paper: {
    margin: theme.spacing(2, 10),

    [theme.breakpoints.down("md")]: {
      margin: theme.spacing(3, 5),
    },
    [theme.breakpoints.down("sm")]: {
      margin: theme.spacing(2, 2),
    },
    display: "flex",
    alignItems: "center",
    marginTop: "8%",
  },
  customPaper: {
    // margin: theme.spacing(2, 10),

    // [theme.breakpoints.down('md')]: {
    //   margin: theme.spacing(3, 5),
    // },
    // [theme.breakpoints.down('sm')]: {
    //   margin: theme.spacing(2, 2),
    // },
    // display: 'flex',
    // alignItems: 'center',
    // marginTop: '8%',
    // justifyContent: 'center',
    borderRadius: "4px",
    padding: "50px 40px 30px 40px",
  },
  customForm: {
    margin: theme.spacing(6, 0),
    [theme.breakpoints.down("md")]: {
      margin: theme.spacing(2, 0),
    },
    [theme.breakpoints.down("sm")]: {
      margin: theme.spacing(2, 0),
    },
    // display: 'flex',
    // alignItems: 'center',
    // justifyContent: 'center'
  },
  forgotLink: {
    cursor: "pointer",
    "&:hover": {
      textDecoration: "underline",
    },
    color: "#048475 !important",
  },
  smallTopMargin: {
    marginTop: "2%",
  },
  customFlex: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  xxLargeFont: {
    fontSize: "xx-large",
  },
  bottomSmall: {
    marginBottom: "9%",
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(5, 0, 2),
    borderRadius: "4px !important",
  },
  alignRight: {
    textAlign: "right",
  },
  alignLeft: {
    textAlign: "left",
  },
  margin: {
    marginTop: theme.spacing(2),
  },
  withoutLabel: {
    marginTop: theme.spacing(3),
  },
  customSpacing: {
    padding: "3% 3% 1% 3%",
    [theme.breakpoints.between("xs", "md")]: {
      padding: "5%",
    },
    backgroundColor: "#F8F6EF", // '#E5E5E5'
  },
  spacing: {
    padding: "3% 3% 1% 3%",
    [theme.breakpoints.between("xs", "md")]: {
      padding: "2%",
    },
    backgroundColor: "#F8F6EF", // '#E5E5E5'
  },
  textField: {
    width: "100%",
  },
  disabledSignIn: {
    cursor: "not-allowed",
    borderRadius: "4px !important",
  },
  toolbar: {
    padding: "1% !important",
    left: "1.5% !important",
  },
  responsive: {
    maxWidth: "100% !important",
    height: "auto !important",
    width: "auto !important",
    alignSelf: "center !important",
    minWidth: "fit-content !important",
  },
  customDivider: {
    borderBottom: `1px solid ${theme.palette.secondary.main}`,
    width: "inherit",
    marginTop: "3%",
    flexShrink: 1,
  },
  customLink: {
    color: "#048475 !important",
    cursor: "pointer",
    "&:hover": {
      textDecoration: "underline",
    },
  },
  alignSelfCenter: {
    alignSelf: "center",
  },

  // for side box added by KP
  detailsBox: {
    background: "#FBC112",
    height: "100%",
    padding: "120px 70px",
    [theme.breakpoints.down("md")]: {
      padding: "50px 30px",
    },
    [theme.breakpoints.down("sm")]: {
      padding: "32px 16px",
    },
  },
  listItem: {
    display: "flex",
    alignItems: "center",
    marginTop: "20px",
    // marginBottom: '20px',
    [theme.breakpoints.between("xs", "lg")]: {
      marginTop: "10px",
      marginBottom: "10px",
    },
    // background: '#FB9012',
    padding: "10px 0px 10px 0px",
    borderRadius: "8px",
    fontSize: "18px",
  },
  itemCircle: {
    // height: '64px',
    // width: '64px',
    // borderRadius: '50%',
    width: "auto",
    height: "57px",
    // background: "#ffffff",
    padding: 0,
    // '& img': {
    //   height: '64px',
    //   width: '64px',
    // }
  },
  sideTitle: {
    fontSize: "24px",
    [theme.breakpoints.between("xs", "lg")]: {
      fontSize: "18px",
    },
    lineHeight: "185%",
    color: "#000000",
    marginBottom: "10px",
    textAlign: "left",
  },
  fsSmall: {
    fontSize: "0.8rem",
  },
  ssBlack: { color: "#14131F" },
  font18p: { fontSize: "18px" },
  marginBottom30p: { marginBottom: "30px" },
  signInBtn: {
    float: "left",
    width: "14em",
    backgroundColor: "#FBC112",
    color: "#000000",
  },
  bulletedText: {
    textAlign: "left",
    marginTop: "3%",
    [theme.breakpoints.down("md")]: {
      marginTop: "7%",
    },
    [theme.breakpoints.down("sm")]: {
      marginTop: "7%",
    },
  },
});

class PreLoginContent extends Component {
  render() {
    const { classes } = this.props;
    return (
      <Grid
        item
        xs={12}
        sm={12}
        md={6}
        lg={7}
        xl={7}
        className={classes.customSpacing}
      >
        {/* <div className={classes.customLogoSpacing}>
              <Logo />
            </div> */}
        <Typography
          align="left"
          variant="h2"
          className={classNames(classes.boldFont, classes.margin)}
        >
          Welcome
        </Typography>
        <Typography
          align="left"
          component="div"
          className={classNames(
            classes.ssBlack,
            classes.margin,
            classes.font18p
          )}
        >
          {/* SucceedSmart offers corporations an affordable, transparent, and speedy executive hiring process, featuring three main benefits: */}
          Start taking advantage of an executive search platform built for the
          needs of your corporation and the future-ready executives you’re
          looking to hire. SucceedSmart offers a wealth of benefits, including:
        </Typography>
        <Grid container>
          <ul className={classes.bulletedText}>
            <li
              className={classNames(classes.font18p, classes.marginBottom30p)}
            >
              A competitive edge through diversity
            </li>
            <li
              className={classNames(classes.font18p, classes.marginBottom30p)}
            >
              Improved time-to-hire
            </li>
            <li
              className={classNames(classes.font18p, classes.marginBottom30p)}
            >
              Wave goodbye to non-solicit clauses
            </li>
            <li
              className={classNames(classes.font18p, classes.marginBottom30p)}
            >
              Affordable and budget-friendly
            </li>
            <li className={classes.font18p}>Complete candidate pool access</li>
          </ul>
          {/* <Box className={classes.listItem}>
                <Container component="span" className={classes.itemCircle}>
                  <DiversityIcon />
                </Container>
                <Container component="div" className={classes.alignLeft}>
                  A competitive edge through diversity
                </Container>
              </Box>
              <Box className={classes.listItem}>
                <Container component="span" className={classes.itemCircle}>
                  <ProductivityIcon />
                </Container>
                <Container component="div" className={classes.alignLeft}>
                  Improved time-to-hire
                </Container>
              </Box>
              <Box className={classes.listItem}>
                <Container component="span" className={classes.itemCircle}>
                  <DiversityIcon />
                </Container>
                <Container component="div" className={classes.alignLeft}>Wave goodbye to non-solicit clauses</Container>
              </Box>
              <Box className={classes.listItem}>
                <Container component="span" className={classes.itemCircle}>
                  <BudgetIcon />
                </Container>
                <Container component="div" className={classes.alignLeft}>
                  Affordable and budget-friendly
                </Container>
              </Box>
              <Box className={classes.listItem}>
                <Container component="span" className={classes.itemCircle}>
                  <DiversityIcon />
                </Container>
                <Container component="div" className={classes.alignLeft}>Complete candidate pool access</Container>
              </Box> */}
        </Grid>
      </Grid>
    );
  }
}

PreLoginContent.propTypes = {
  classes: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => state;

// const connectedFooterPage = withRouter(connect(mapStateToProps, null, null, {
//     pure: false
//   })(withStyles(styles)(PreLoginContent)));

// export default withStyles(styles)(PreLoginContent);
export default connect(mapStateToProps, null, null, { pure: false })(
  withStyles(styles)(PreLoginContent)
);
// export default connect(mapStateToProps, null, null, {loggingIn})(withStyles(styles)(PreLoginContent))
// export { connectedFooterPage as PreLoginContent };
