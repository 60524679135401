import React, { Component, Fragment } from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import {
  TextField,
  Typography,
  FormControl,
  Radio,
  RadioGroup,
  FormControlLabel,
} from "@material-ui/core";
import ProductInfoImage from "../../../assets/product_info.svg";
import classNames from "classnames";
import { authActions } from "../../../actions";
import validator from "validator";
import { grey } from "@material-ui/core/colors";
import { toast } from "react-toastify";
import { MESSAGE_FIELD_IS_REQUIRED } from "../../../constants/constants";
import secureLocalStorage from "react-secure-storage";

const styles = (theme) => ({
  root: {
    marginTop: "90px",
    color: "#000000",
  },
  image: {
    backgroundImage: `url(${ProductInfoImage})`, //'url(https://source.unsplash.com/random)',
    backgroundRepeat: "no-repeat",
    backgroundColor:
      theme.palette.type === "light"
        ? theme.palette.grey[50]
        : theme.palette.grey[900],
    backgroundSize: "cover",
  },
  paper: {
    margin: theme.spacing(0, 10),
    marginTop: "3%",
  },
  customPaper: {
    margin: theme.spacing(3, 10),

    color: theme.palette.default.main,
  },
  customSubPaper: {
    display: "inline-flex",
    width: "28%",
    textAlign: "justify",
  },
  customContent: {
    margin: theme.spacing(3, 10),
    textAlign: "justify",
  },
  customForm: {
    margin: theme.spacing(3, 10),
    alignItems: "center",
  },
  customBadge: {
    backgroundColor: "#FBC112",
    height: "50px",
    minWidth: "50px",
    borderRadius: "50%",
    fontSize: "large",
  },
  mediumLineHeight: {
    lineHeight: "4.5",
  },
  forgotLink: {
    cursor: "pointer",
    "&:hover": {
      textDecoration: "underline",
    },
  },
  verySmallTopMargin: {
    marginTop: "1%",
  },
  smallTopMargin: {
    marginTop: "2%",
  },
  mediumTopMargin: {
    marginTop: "4%",
  },
  largeTopMargin: {
    marginTop: "6%",
  },
  basicsHeader: {
    backgroundColor: "#FBC112",
    padding: "1%",
    fontWeight: "bold",
  },
  fontBold: {
    fontWeight: "bold",
  },
  customFlex: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  xxLargeFont: {
    fontSize: "xx-large",
  },
  bottomSmall: {
    marginBottom: "9%",
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(5, 0, 2),
    borderRadius: "4px !important",
  },
  alignRight: {
    textAlign: "right",
  },
  margin: {
    marginTop: theme.spacing(2),
  },
  withoutLabel: {
    marginTop: theme.spacing(3),
  },
  textField: {
    width: "100%",
  },
  disabledSignIn: {
    cursor: "not-allowed",
    borderRadius: "4px !important",
  },
  toolbar: {
    padding: "1% !important",
    left: "1.5% !important",
  },
  responsive: {
    maxWidth: "100% !important",
    height: "auto !important",
    width: "auto !important",
    alignSelf: "center !important",
    minWidth: "fit-content !important",
  },
  customDivider: {
    borderBottom: "1px solid " + theme.palette.secondary.main,
    width: "inherit",
    marginTop: "10%",
    flexShrink: 1,
  },
  alignLeft: {
    textAlign: "left",
  },
  paddedContent: {
    marginLeft: "30vw",
    marginRight: "30vw",
    marginTop: "2%",
    [theme.breakpoints.down("md")]: {
      margin: "20px 16px",
    },
    [theme.breakpoints.down("sm")]: {
      margin: "10px 16px",
    },
  },
  inlineContent: {
    display: "flex",
    alignItems: "center",
    paddingLeft: "1.3vw",
  },
  justifyContent: {
    textAlign: "justify",
  },
  square: {
    color: theme.palette.getContrastText(grey[300]),
    backgroundColor: grey[300],
    border: "3px dotted #FBC112",
    width: "7em",
    height: "4em",
  },
  uploadLink: {
    marginLeft: "2em",
    cursor: "pointer",
    "&:hover": {
      textDecoration: "underline",
    },
    color: "#048475",
  },
  uploadInstruction: {
    marginLeft: "2em",
    color: "grey",
  },
  displayFlex: {
    display: "flex",
  },
  formControl: {
    margin: theme.spacing(2, 0),
    minWidth: "100%",
  },
  fullWidth: {
    width: "100%",
  },
  floatRight: {
    float: "right",
  },
  smallFont: {
    fontSize: "0.75rem",
  },
});

let roleCharCount = 100;

class SignContract extends Component {
  constructor(props) {
    super(props);
    this.state = this.getInitialState();
  }

  getInitialState = () => ({
    signerEmail: "",
    signerName: "",
    title: "",
    message: "",
    type: "embedded",
    isValidSignerEmail: true,
    isValidSignerName: true,
    isValidTitle: true,
    recruiterId: JSON.parse(secureLocalStorage.getItem("recruiterId")),
    disabled: false,

    isSignerNameRequired: false,
    isTitleRequired: false,
    isSignerEmailRequired: false,
  });

  handleSignContractClick = (event) => {
    // this.setState({disabled: true});
    event.preventDefault();
    event.stopPropagation();
    if (this.state.type === "embedded") {
      this.setState(
        {
          signerName:
            JSON.parse(secureLocalStorage.getItem("userDetails"))[
              "custom:first_name"
            ] +
            " " +
            JSON.parse(secureLocalStorage.getItem("userDetails"))[
              "custom:last_name"
            ],
          signerEmail: JSON.parse(secureLocalStorage.getItem("userDetails"))
            .email,
          title: JSON.parse(secureLocalStorage.getItem("userDetails"))[
            "custom:current_title"
          ],
        },
        () => {
          const { recruiterId, signerEmail, signerName, title, message, type } =
            this.state;
          let payload = {
            recruiter_id: recruiterId,
            email: signerEmail,
            title: title,
            full_name: signerName,
            message: type === "email" ? message : null,
            type: type,
          };
          if (signerEmail && signerName && title && type) {
            const { dispatch } = this.props;
            dispatch(authActions.setContractDetails(payload));
          } else {
            toast.dark("Please fill up all the details");
            this.setState({
              isSignerNameRequired: validator.isEmpty(signerName),
              isTitleRequired: validator.isEmpty(title),
              isSignerEmailRequired: validator.isEmpty(signerEmail),
            });
          }
        }
      );
    } else {
      const { recruiterId, signerEmail, signerName, title, message, type } =
        this.state;
      let payload = {
        recruiter_id: recruiterId,
        email: signerEmail,
        title: title,
        full_name: signerName,
        message: type === "email" ? message : null,
        type: type,
      };
      if (signerEmail && signerName && title && type) {
        const { dispatch } = this.props;
        dispatch(authActions.setContractDetails(payload));
      } else {
        toast.dark("Please fill up all the details");
        this.setState({
          isSignerNameRequired: validator.isEmpty(signerName),
          isTitleRequired: validator.isEmpty(title),
          isSignerEmailRequired: validator.isEmpty(signerEmail),
        });
      }
    }
  };

  handleInputChange = (prop) => (event) => {
    this.setState({ [prop]: event.target.value }, function () {
      switch (prop) {
        case "signerSelection":
          this.setState({ type: event.target.value });
          break;
        case "title":
          this.setState({
            isValidTitle: event.target.value
              ? validator.matches(event.target.value, /^.{2,100}$/s)
              : true,
            isTitleRequired: validator.isEmpty(event.target.value),
          });
          break;
        case "signerName":
          this.setState({
            isValidSignerName: event.target.value
              ? validator.matches(event.target.value, /^.{2,100}$/s)
              : true,
            isSignerNameRequired: validator.isEmpty(event.target.value),
          });
          break;
        case "signerEmail":
          this.setState(
            {
              isValidSignerEmail: event.target.value
                ? validator.isEmail(event.target.value)
                : true,
              isSignerEmailRequired: validator.isEmpty(event.target.value),
            },
            function () {
              if (
                this.state.signerEmail.includes("@yahoo.com") ||
                this.state.signerEmail.includes("@gmail.com") ||
                this.state.signerEmail.includes("@live.com") ||
                this.state.signerEmail.includes("@rediffmail.com") ||
                this.state.signerEmail.includes("@hotmail.com") ||
                this.state.signerEmail.includes("@ymail.com") ||
                this.state.signerEmail.includes("@outlook.com") ||
                this.state.signerEmail.includes("@yopmail.com")
              ) {
                this.setState({ isValidSignerEmail: false });
              }
            }
          );
          break;
        default:
          break;
      }
    });
  };
  render() {
    const { classes } = this.props;
    return (
      <Fragment>
        {/* <AppBar /> */}
        <Grid container component="main" className={classes.root}>
          <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
            <Grid className={classes.alignLeft}>
              <div className={classes.paddedContent}>
                <Typography
                  component="span"
                  align="center"
                  className={classes.xxLargeFont}
                >
                  The Contract
                </Typography>

                <Typography
                  component="div"
                  className={classNames(
                    classes.justifyContent,
                    classes.mediumTopMargin
                  )}
                >
                  Who will sign the document?*
                </Typography>

                <FormControl
                  component="fieldset"
                  className={classNames(
                    classes.fullWidth,
                    classes.mediumTopMargin
                  )}
                >
                  <RadioGroup
                    aria-label="signerSelection"
                    value={this.state.type}
                    name="signerSelection"
                    onChange={this.handleInputChange("signerSelection")}
                  >
                    <Grid container>
                      <Grid
                        item
                        xs={12}
                        sm={12}
                        md={12}
                        lg={6}
                        xl={6}
                        className={classes.displayFlex}
                      >
                        <FormControlLabel
                          control={<Radio />}
                          label="Self"
                          value="embedded"
                        />
                      </Grid>
                      <Grid
                        item
                        xs={12}
                        sm={12}
                        md={12}
                        lg={6}
                        xl={6}
                        className={classes.displayFlex}
                      >
                        <FormControlLabel
                          control={<Radio />}
                          label="Another Individual"
                          value="email"
                        />
                      </Grid>
                    </Grid>
                  </RadioGroup>
                </FormControl>

                <Typography
                  component="div"
                  className={classNames(
                    classes.justifyContent,
                    classes.mediumTopMargin
                  )}
                >
                  To ensure who should receive the contract, please provide
                  information of the signer needed for this document.
                </Typography>

                <Grid
                  item
                  xs={12}
                  sm={12}
                  md={12}
                  lg={12}
                  xl={12}
                  className={classes.smallTopMargin}
                >
                  <TextField
                    fullWidth
                    label="Signer Name"
                    margin="normal"
                    required
                    id="signerName"
                    name="signerName"
                    value={
                      this.state.type === "embedded"
                        ? (secureLocalStorage.getItem("userDetails")
                            ? JSON.parse(
                                secureLocalStorage.getItem("userDetails")
                              )["custom:first_name"]
                            : null) +
                          " " +
                          (secureLocalStorage.getItem("userDetails")
                            ? JSON.parse(
                                secureLocalStorage.getItem("userDetails")
                              )["custom:last_name"]
                            : null)
                        : this.state.signerName
                    }
                    inputProps={{
                      readOnly: this.state.type === "embedded",
                      maxLength: roleCharCount,
                    }}
                    onChange={this.handleInputChange("signerName")}
                    placeholder="John Doe"
                    error={
                      this.state.type === "email" &&
                      (!this.state.isValidSignerName ||
                        this.state.isSignerNameRequired)
                    }
                    helperText={
                      this.state.type === "email" &&
                      !this.state.isValidSignerName
                        ? "min 2 characters required"
                        : this.state.type === "email" &&
                          this.state.isSignerNameRequired
                        ? MESSAGE_FIELD_IS_REQUIRED
                        : this.state.type === "email"
                        ? ""
                        : ""
                    }
                  />
                  {this.state.type === "email" && (
                    <Typography
                      className={classNames(
                        classes.floatRight,
                        classes.smallFont,
                        classes.verySmallTopMargin
                      )}
                      color="secondary"
                    >
                      {this.state.signerName.length + "/" + roleCharCount}{" "}
                      characters
                    </Typography>
                  )}
                </Grid>
                <Grid
                  item
                  xs={12}
                  sm={12}
                  md={12}
                  lg={12}
                  xl={12}
                  className={classes.smallTopMargin}
                >
                  <TextField
                    fullWidth
                    label="Title"
                    margin="normal"
                    required
                    id="role"
                    name="role"
                    value={
                      this.state.type === "embedded"
                        ? secureLocalStorage.getItem("userDetails")
                          ? JSON.parse(
                              secureLocalStorage.getItem("userDetails")
                            )["custom:current_title"]
                          : null
                        : this.state.title
                    }
                    inputProps={{
                      readOnly: this.state.type === "embedded",
                      maxLength: roleCharCount,
                    }}
                    onChange={this.handleInputChange("title")}
                    placeholder="HR Manager"
                    error={
                      this.state.type === "email" &&
                      (!this.state.isValidTitle || this.state.isTitleRequired)
                    }
                    helperText={
                      this.state.type === "email" && !this.state.isValidTitle
                        ? "min 2 characters required"
                        : this.state.type === "email" &&
                          this.state.isTitleRequired
                        ? MESSAGE_FIELD_IS_REQUIRED
                        : this.state.type !== "embedded"
                        ? ""
                        : null
                    }
                  />
                  {this.state.type === "email" && (
                    <Typography
                      className={classNames(
                        classes.floatRight,
                        classes.smallFont,
                        classes.verySmallTopMargin
                      )}
                      color="secondary"
                    >
                      {this.state.title.length + "/" + roleCharCount} characters
                    </Typography>
                  )}
                </Grid>
                <Grid
                  item
                  xs={12}
                  sm={12}
                  md={12}
                  lg={12}
                  xl={12}
                  className={classes.smallTopMargin}
                >
                  <TextField
                    fullWidth
                    label="Signer Email Address"
                    autoComplete="signerEmail"
                    margin="normal"
                    required
                    id="signerEmail"
                    name="signerEmail"
                    value={
                      this.state.type === "embedded"
                        ? secureLocalStorage.getItem("userDetails")
                          ? JSON.parse(
                              secureLocalStorage.getItem("userDetails")
                            )["email"]
                          : null
                        : this.state.signerEmail
                    }
                    onChange={this.handleInputChange("signerEmail")}
                    placeholder="abc@pqr.com"
                    inputProps={{ readOnly: this.state.type === "embedded" }}
                    error={
                      this.state.type === "email" &&
                      (!this.state.isValidSignerEmail ||
                        this.state.isSignerEmailRequired)
                    }
                    helperText={
                      this.state.type === "email" &&
                      !this.state.isValidSignerEmail
                        ? "Invalid Email (Signer Email Address must be a Corporate Email Address)"
                        : this.state.type === "email" &&
                          this.state.isSignerEmailRequired
                        ? MESSAGE_FIELD_IS_REQUIRED
                        : "You will get the contract details on the above email"
                    }
                  />
                </Grid>
                {this.state.type === "email" ? (
                  <Grid
                    item
                    xs={12}
                    sm={12}
                    md={12}
                    lg={12}
                    xl={12}
                    className={classes.largeTopMargin}
                  >
                    <TextField
                      id="outlined-multiline-static"
                      label="Message"
                      placeholder="Type message here."
                      fullWidth
                      multiline
                      rows={4}
                      value={this.state.message}
                      variant="outlined"
                      onChange={this.handleInputChange("message")}
                    />
                  </Grid>
                ) : null}

                <Button
                  size="large"
                  className={classNames(
                    classes.largeTopMargin,
                    classes.floatRight
                  )}
                  style={{ marginBottom: "15px" }}
                  type="submit"
                  disableElevation
                  variant="contained"
                  color="primary"
                  onClick={(event) => {
                    this.handleSignContractClick(event);
                  }}
                  disabled={
                    (!this.state.isValidSignerEmail ||
                      !this.state.isValidSignerName ||
                      !this.state.isValidTitle) &&
                    this.state.type === "email"
                  }
                >
                  Submit
                </Button>
              </div>
            </Grid>
          </Grid>
        </Grid>
      </Fragment>
    );
  }
}

SignContract.propTypes = {
  classes: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => {
  return state;
};

const connectedSignContractPage = withRouter(
  connect(mapStateToProps, null, null, {
    pure: false,
  })(withStyles(styles)(SignContract))
);

export { connectedSignContractPage as SignContract };
