import React, { useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { useSelector, useDispatch } from "react-redux";
import { Typography, TextField, Grid, Tooltip } from "@material-ui/core";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { setAllUpdateHeaderCard } from "../../actions/jobs.actions";
import { loading, setView } from "../../actions";

const useStyles = makeStyles((theme) => ({
  button: {
    display: "block",
    marginTop: theme.spacing(2),
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 180,
  },
  selectStyle: { border: "1px solid #F4F4F5" },
  customChip: {
    backgroundColor: "#048475",
    color: "#ffffff",
    fontSize: "10px",
    padding: "4px",
    borderRadius: "8px",
  },
  marginLeft4: {
    marginLeft: "4px",
  },
  endAdornmentTypo: {
    paddingRight: "40px",
    display: "flex",
    alignItems: "center",
    cursor: "pointer",
  },
  AutocompleteStyle: {
    width: "240px",
    marginLeft: "10px",
    height: "40px",
    cursor: "pointer !important",
    "& :hover": {
      cursor: "pointer !important",
    },
  },
  dotSpan: {
    height: "10px",
    width: "10px",
    background: "#048475",
    borderRadius: "15px",
  },
  onHover: {
    color: "red !important",
  },
  labelStyle: {
    fontSize: "14px",
    fontWeight: 400,
  },
  menuListStyle: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
  },
}));

export default function OpenSelect() {
  const classes = useStyles();
  const activeTab = useSelector((state) => state.jobs.allUpdateHeaderCard);
  const selectChipsValue = useSelector((state) => state?.jobs?.allUpdateData);
  const [inputValue, setInputValue] = React.useState("");
  const dispatch = useDispatch();

  const menuItems = [
    {
      label: "All Candidates",
      value: "All",
      chipValue: selectChipsValue?.all_updates
        ? selectChipsValue?.all_updates
        : 0,
    },
    {
      label: "Talent Pipeline",
      value: "1",
      chipValue: selectChipsValue?.recommended?.new_recommended_count
        ? selectChipsValue?.recommended?.new_recommended_count
        : 0,
    },
    {
      label: "Shortlisted Candidates",
      value: "2",
      chipValue: selectChipsValue?.shortlisted_candidates?.new_matched
        ? selectChipsValue?.shortlisted_candidates?.new_matched
        : 0,
    },
    {
      label: "In Conversation",
      value: "3",
      chipValue: 0,
    },
    {
      label: "Removed from Queue",
      value: "4",
      chipValue: 0,
    },
  ];

  const [value, setValue] = React.useState([]);

  const defaultSelect = () => {
    switch (activeTab) {
      case "All":
        setValue(menuItems?.[0]);
        break;
      case "1":
        setValue(menuItems?.[1]);
        break;
      case "2":
        setValue(menuItems?.[2]);
        break;
      case "3":
        setValue(menuItems?.[3]);
        break;
      case "4":
        setValue(menuItems?.[4]);
        break;
      default:
        break;
    }
  };

  useEffect(() => {
    defaultSelect();
  }, [activeTab, selectChipsValue]);

  const onChangeValueAuto = (value) => {
    dispatch(loading(true));
    setValue(value);
    dispatch(setAllUpdateHeaderCard(value?.value));
    dispatch(loading(false));
    if (value?.value === "4") {
      dispatch(setView("List"));
    }
  };

  return (
    <div>
      <Tooltip arrow title={inputValue}>
        <Autocomplete
          size="small"
          value={value}
          autoComplete
          disableClearable
          onChange={(event, newValue) => {
            onChangeValueAuto(newValue);
          }}
          inputValue={inputValue}
          onInputChange={(event, newInputValue) => {
            setInputValue(newInputValue);
          }}
          id="controllable-states-demo"
          getOptionLabel={(option) => option.label}
          options={menuItems.map((option) => option)}
          renderOption={(option) => (
            <Grid container>
              <Grid
                item
                sm={12}
                xs={12}
                md={12}
                xl={12}
                lg={12}
                className={classes.menuListStyle}
              >
                <Typography component="div" className={classes.labelStyle}>
                  {option?.label}
                </Typography>
                {option?.chipValue !== 0 && (
                  <Typography component="div" className={classes.customChip}>
                    {" "}
                    {`${option?.chipValue} New`}
                  </Typography>
                )}
              </Grid>
            </Grid>
          )}
          className={classes.AutocompleteStyle}
          renderInput={(params) => (
            <TextField
              className="candidate_filter"
              onChange={() => false}
              size="small"
              {...params}
              variant="outlined"
              InputProps={{
                readOnly: true,
                ...params.InputProps,
                endAdornment: (
                  <Typography
                    component="span"
                    className={classes.endAdornmentTypo}
                  >
                    {value?.chipValue > 0 && (
                      <span className={classes.dotSpan} />
                    )}
                    {params.InputProps.endAdornment}
                  </Typography>
                ),
              }}
            />
          )}
        />
      </Tooltip>
    </div>
  );
}
