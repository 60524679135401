import React, { Component } from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import {
  List,
  ListItem,
  ListItemIcon,
  Grid,
  Button,
  Typography,
  Avatar,
  IconButton,
  Dialog,
  DialogContentText,
  FormControl,
  RadioGroup,
  Radio,
  TextField,
  FormControlLabel,
  Card,
  Tab,
  Tabs,
  Box,
  Tooltip,
  Menu,
  MenuItem,
  ListItemText,
  Divider,
  CardHeader,
  CardContent,
} from "@material-ui/core";
import cardHeaderBg from "../../../assets/lite_profile_bg.png";
import AddBoxIcon from "@material-ui/icons/AddBox";
import IndeterminateCheckBoxIcon from "@material-ui/icons/IndeterminateCheckBox";
import MuiDialogTitle from "@material-ui/core/DialogTitle";
import MuiDialogContent from "@material-ui/core/DialogContent";
import MuiDialogActions from "@material-ui/core/DialogActions";
import { copyToClipboard, decryptData, history } from "../../../helpers";
import validator from "validator";
import classNames from "classnames";
import { membersActions } from "../../../actions/members.actions";
import { toast } from "react-toastify";
import "./members.css";
import { messagesActions } from "./../../../actions/messages.actions";
import CloseIcon from "@material-ui/icons/Close";
import MessageIcon from "@material-ui/icons/ChatBubble";
import EventIcon from "@material-ui/icons/Event";
import {
  LABELS,
  MESSAGE_FIELD_IS_REQUIRED,
} from "../../../constants/constants";
import GetHelp from "../../core/gethelp";
import KeyboardArrowLeftIcon from "@material-ui/icons/KeyboardArrowLeft";
import { notAllowWhiteSpaceAtStart, trim } from "../../../helpers";
import PopupState, { bindTrigger, bindMenu } from "material-ui-popup-state";
import { seo } from "../../../helpers";
import { setTitle } from "../../../actions";
import CustomizedAccordions from "./accordion.js";
import ChatIcon from "@material-ui/icons/Chat";
import Commentpopup from "./Commentpopup.component";
import SchedulingPopup from "../../customComponents/scheduling.popup.component.js";
import { jobsActions } from "../../../actions";
import PreviewEmaildailog from "../../core/previewEmail.dailog";
import { convertToRaw } from "draft-js";
import draftToHtml from "draftjs-to-html";
import { ReactComponent as RemovedFromIcon } from "../../../assets/delete_sweep.svg";
import { ReactComponent as RequestInterviewIcon } from "../../../assets/request_interview_dark.svg";
import { ReactComponent as MarkAsHiredIcon } from "../../../assets/marked_hired_dark.svg";
import ShareIcon from "@material-ui/icons/Share";
import MoveCandidateinConversation from "../../customComponents/moveCandidateinConversation";
import AdminNotes from "../../customComponents/adminNotes";
import EmptyState from "../../core/emptyState";
import secureLocalStorage from "react-secure-storage";
import { ReactComponent as MoveToInterviewIcon } from "../../../assets/moveToInterviewComparefullProfile.svg";
import MarkedHired from "../../customComponents/makedHired";
import RemoveFromQueue from "../../customComponents/removeFromQueue";
import { master_key } from "../../../config/config";

const styles = (theme) => ({
  typography: {
    fontFamily: "Public Sans",
  },
  root: {
    marginTop: "90px",
    width: "100%",
  },

  stickyBar: {
    position: "fixed",
    top: 0,
    width: "70% !important",
    zIndex: 1,
    background: "#ffffff",
    [theme.breakpoints.down("sm")]: {
      marginTop: "90px",
      width: "100% !important",
    },
  },
  stickyCard: {
    position: "sticky",
    top: 0,
    width: "100%",
    zIndex: 1,
    background: "#ffffff",
    borderRadius: "10px !important",
    [theme.breakpoints.down("sm")]: {
      position: "sticky",
      display: "none",
    },
  },
  stickyBarRemove: {
    position: "sticky",
  },
  stickyBarRemoveCard: {
    borderRadius: "10px !important",
    position: "sticky",
    [theme.breakpoints.down("sm")]: {
      display: "none",
    },
  },
  headerActions: {
    display: "none",
    [theme.breakpoints.down("sm")]: {
      display: "inline",
      paddingLeft: "20px !important",
    },
  },
  cardActionGrid: {
    padding: "0px 48px 0px 0px",
    [theme.breakpoints.down("md")]: {
      padding: "10px 48px 0px 0px",
    },
  },
  scheduledialogActions: {
    justifyContent: "center !important",
  },
  customPaperStyle: {
    boxShadow: "none",
    textAlign: "center",
    padding: "16px",
    height: "370px",
    overflowX: "none",
    "&:hover": { overflowX: "auto" },
  },
  scheduledialogActionsButton: {
    width: "8rem",
  },
  customTabs: {
    textTransform: "inherit",
    minWidth: "fit-content !important",
    margin: "0px 8px",
  },
  customScrollToTopButton: {
    position: "fixed",
    display: "none",
    right: 10,
    bottom: 60,
    zIndex: "1",
    backgroundColor: "#000000 !important",
    color: "#FFFFFF !important",
  },
  viewresume: {
    color: "rgb(4, 132, 117)",
    textAlign: "left",
    display: "flex",
    marginTop: "5px",
    marginBottom: "15px",
    backgroundColor: "#F5F5F5",
    padding: "35px",
    cursor: "pointer",
  },
  paper: {
    border: "1px solid",
    padding: theme.spacing(1),
    backgroundColor: theme.palette.background.paper,
  },
  closeButton: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.default.main,
    padding: "0px",
  },
  appFrame: {
    zIndex: 1,
    overflow: "hidden",
    position: "relative",
    padding: theme.spacing(2, 6),
    [theme.breakpoints.between("xs", "md")]: {
      padding: theme.spacing(2, 1),
    },
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(0, 6),
    [theme.breakpoints.down("md")]: {
      padding: theme.spacing(1),
    },
    [theme.breakpoints.down("sm")]: {
      padding: theme.spacing(1),
    },
  },
  customPad: {
    [theme.breakpoints.down("sm")]: {
      padding: theme.spacing(1),
    },
    padding: "32px 16px",
  },
  bullet: {
    display: "inline-block",
    margin: "0 2px",
    transform: "scale(0.8)",
  },
  title: {
    fontSize: 14,
  },
  customPadding: {
    [theme.breakpoints.down("sm")]: {
      // textAlign: '-webkit-center',
      padding: "20px 20px 20px 20px",
    },
    padding: "40px 40px 40px 0px",
  },
  experienceFont: {
    fontSize: "18px",
    [theme.breakpoints.between("xs", "lg")]: {
      fontSize: "16px",
    },
  },
  pos: {
    marginBottom: 12,
  },
  customHighlightsHeader: { padding: "8px 16px", marginTop: "2%" },

  largeAvatar: {
    width: theme.spacing(24),
    height: theme.spacing(24),
    // backgroundColor: '#000000',
    // color: '#ffffff'
  },
  largeVideoAvatar: {
    cursor: "pointer",
    width: "auto",
    height: theme.spacing(15),
    [theme.breakpoints.down("md")]: {
      height: theme.spacing(8),
    },
    [theme.breakpoints.down("sm")]: {
      height: theme.spacing(8),
    },
  },
  xLargeAvatar: {
    width: theme.spacing(25),
    height: theme.spacing(25),
  },
  moduleConfig: {
    float: "left",
    fontSize: "26px",
  },
  demo1: {
    marginTop: "5%",
  },
  floatRight: {
    float: "right",
  },
  floatLeft: {
    float: "left",
  },
  largeTopMargin: {
    marginTop: "10%",
  },
  verySmallTopMargin: {
    marginTop: "1%",
  },
  formControl: {
    margin: theme.spacing(0, 0),
    minWidth: "100%",
  },
  mediumTopMargin: {
    marginTop: "5%",
  },
  mediumBottomMargin: {
    marginBottom: "8%",
  },
  displayFlex: {
    display: "flex",
  },
  customDisplayFlex: {
    display: "flex",
    [theme.breakpoints.down("xs")]: {
      flexDirection: "column",
      textAlign: "left",
    },
  },
  noPadding: {
    padding: "0 !important",
  },
  noTopPadding: { paddingTop: "0 !important" },
  noBottomPadding: { paddingBottom: "0 !important" },
  fullWidth: {
    width: "100%",
  },
  colorDefault: {
    color: "#000000 !important",
  },
  colorCustom: {
    color: "#303030",
  },
  dialogTitle: {
    color: "#FBC112",
    fontSize: "2rem",
    [theme.breakpoints.down("md")]: {
      fontSize: "1.3rem",
    },
    [theme.breakpoints.down("sm")]: {
      fontSize: "1.3rem",
    },
  },
  displayInline: {
    display: "inline",
  },
  noBottomMargin: {
    marginBottom: "0",
  },
  smallTopMargin: {
    marginTop: "3%",
    [theme.breakpoints.down("sm")]: {
      marginTop: "6%",
    },
  },
  chip: {
    margin: theme.spacing(0.5),
  },
  input: {
    marginLeft: theme.spacing(1),
    flex: 1,
  },
  iconButton: {
    padding: 10,
  },
  noWrap: {
    whiteSpace: "nowrap",
  },
  subHeader: {
    fontSize: "large",
    marginTop: "4%",
    fontWeight: "600",
    color: "#000000",
  },
  header: {
    fontSize: "x-large",
    marginTop: "4%",
    fontWeight: "600",
    color: "#000000",
  },
  jobCardHeader: {
    fontSize: "x-large",
    fontWeight: "500",
    color: "#000000",
    textAlign: "left",
  },
  jobCardSubHeader: {
    fontSize: "large",
    fontWeight: "500",
    textAlign: "left",
  },
  customCard: {
    backgroundColor: theme.palette.basic.main,
  },
  customCardShadow: {
    boxShadow: "none",
  },
  textAlignEnd: {
    textAlign: "end",
  },
  textAlignStart: {
    textAlign: "start",
  },
  jobCard: {
    boxShadow:
      "0px 1px 1px 0px rgb(0 0 0 / 20%), 0px 1px 0px 0px rgb(0 0 0 / 14%), 0px 0px 2px 1px rgb(0 0 0 / 12%)",
    cursor: "pointer",
  },
  smallBottomMargin: {
    marginBottom: "2%",
    [theme.breakpoints.down("sm")]: {
      marginBottom: "6%",
    },
  },
  verySmallBottomMargin: { marginBottom: "1%" },
  customBadge: {
    // backgroundColor: "#FBC112",
    cursor: "pointer",
    height: "50px",
    minWidth: "50px",
    borderRadius: "50%",
    border: "1px solid black",
    fontSize: "large",
    right: "unset",
    transform: "unset",
    position: "unset",
  },
  smallFont: {
    fontSize: "14px",
  },
  largeFont: {
    fontSize: "large",
  },
  xxLargeFont: {
    fontSize: "xx-large",
    [theme.breakpoints.down("md")]: {
      fontSize: "1rem",
    },
    [theme.breakpoints.down("sm")]: {
      fontSize: "1rem",
    },
  },
  customBadgeGrid: {
    textAlign: "center",
    overflowWrap: "break-word",
    padding: "2%",
  },
  customLink: {
    cursor: "pointer",
    "&:hover": {
      textDecoration: "underline",
    },
    color: "#048475 !important",
  },
  breadcrumbLink: {
    cursor: "pointer",
    "&:hover": {
      textDecoration: "underline",
    },
    // color: '#048475 !important'
  },
  moreIcon: {
    justifyContent: "space-between",
    cursor: "pointer",
    overflowWrap: "anywhere",
  },
  customMoreIcon: {
    fontSize: "2.5rem",
    // '&:hover':{
    //     transform: 'rotate(90deg)',
    //     transition: 'all .3s ease'
    // }
  },
  customDialog: {
    backgroundColor: "#000000",
    width: "unset",
  },
  justifyContentFlexEnd: {
    justifyContent: "flex-end",
    [theme.breakpoints.up("xs")]: {
      justifyContent: "flex-start ",
    },
  },
  responsive: {
    maxWidth: "100% !important",
    height: "auto !important",
    width: "auto !important",
    // alignSelf: 'center !important',
    minWidth: "fit-content !important",
  },
  liteProfileCard: {
    boxShadow:
      "0px 1px 1px 0px rgb(0 0 0 / 0%), 0px 1px 0px 0px rgb(0 0 0 / 5%), 0px 0px 2px 1px rgb(0 0 0 / 12%)",
    borderBottom: "10px solid #FBC112",
    maxHeight: "100%",
    height: "100%",
    borderRadius: "8px",
    // backgroundColor: '#F7F7F7',
    // baclgroundImage: 'url(src/assets/lite_profile_bg.png)'
    // cursor: 'pointer'
  },
  maxHeight40P: { maxHeight: "40px" },
  customCardHeader: {
    maxHeight: "38%",
    height: "38%",
    color: "#000",
    backgroundSize: "cover",
    backgroundImage: `url(${cardHeaderBg})`,
    background: "round",
    padding: "3%",
  }, //height: '180px'
  matchedCustomCardHeader: {
    maxHeight: "40%",
    height: "40%",
    color: "#000",
    backgroundSize: "cover",
    backgroundImage: `url(${cardHeaderBg})`,
    background: "round",
  },
  customCardActions: { display: "block", marginTop: "1%" },
  textAlignLeft: { textAlign: "left" },
  textAlignCenter: { textAlign: "center" },
  textAlignRight: { textAlign: "right" },
  verySmallFont: { fontSize: ".8rem" },
  font10p: { fontSize: "10px" },
  font14p: { fontSize: "12px" },
  alignBaslineMiddle: { verticalAlign: "-webkit-baseline-middle" },
  verySmallMarginLeft: { marginLeft: "3px" },
  mediumMarginLeft: { marginLeft: "12px" },
  boldFont: { fontWeight: "bold" },
  customBoldFont: { fontWeight: "500" },
  // liteProfileContent: {
  //   textAlign: 'left',
  //   paddingLeft: '5%',
  //   paddingRight: '5%',
  //   maxHeight: '20vh',
  //   height: '20vh',
  //   overflowY: 'auto',
  // },
  noBoxShadow: { boxShadow: "none" },
  noBorderBottom: { borderBottom: "none" },
  viewMore: { color: "#302F2C", cursor: "pointer", fontWeight: "bold" },
  matchedCardHeader: { alignItems: "center", justifyContent: "center" },
  alignWebkitCenter: { textAlign: "-webkit-center" },
  justifyContentSpaceEvenly: { justifyContent: "space-evenly" },
  justifyContentSpaceBetween: { justifyContent: "space-between" },
  bgChangeOnHover: { "&:hover": { backgroundColor: "#FBC112" } },
  verySmallMarginRight: { marginRight: "2%" },
  smallMarginRight: { marginRight: "3%" },
  mediumMarginRight: { marginRight: "25px" },
  skillsCardPanel: {
    // '&:hover':{
    overflowY: "auto",
    // }
  },
  filterGoBtn: { width: "-webkit-fill-available" },
  customPaper: {
    padding: "16px 16px",
    // boxShadow: '0px 0px 0px 0px rgb(0 0 0 / 0%), 0px 0px 0px 0px rgb(0 0 0 / 0%), 0px 0px 10px 0px rgb(0 0 0 / 15%)',
    // boxShadow: '0px 1px 1px 0px rgb(0 0 0 / 0%), 0px 1px 5px 0px rgb(0 0 0 / 20%), 0px 0px 1px 1px rgb(0 0 0 / 12%)',
    // boxShadow: '0px 2px 8px rgba(0, 0, 0, 0.12);'
    // borderRadius: '10px',
    overflowWrap: "anywhere",
    borderRadius: "5px",
    boxShadow:
      "0px 0px 0px 1px rgb(0 0 0 / 5%), 0px 0px 0px 0px rgb(0 0 0 / 5%), 0px 0px 0px 1px rgb(0 0 0 / 5%)",
  },
  newCustomPaper: {
    padding: "0%",
    borderRadius: "5px",
    overflowWrap: "anywhere",
    boxShadow:
      "0px 0px 0px 1px rgb(0 0 0 / 5%), 0px 0px 0px 0px rgb(0 0 0 / 5%), 0px 0px 10px 0px rgb(0 0 0 / 5%)",
    position: "sticky",
    top: "90px",
    [theme.breakpoints.between("xs", "sm")]: {
      top: "90px",
    },
    zIndex: 1,
  },
  newCustomPaperWhenNavbarHidden: {
    padding: "0%",
    borderRadius: "5px",
    overflowWrap: "anywhere",
    boxShadow:
      "0px 0px 0px 1px rgb(0 0 0 / 5%), 0px 0px 0px 0px rgb(0 0 0 / 5%), 0px 0px 10px 0px rgb(0 0 0 / 5%)",
    position: "sticky",
    top: "0px",
    [theme.breakpoints.between("xs", "sm")]: {
      top: "0px",
    },
    zIndex: 1,
  },
  customPaperExperience: {
    padding: "20px 12px",
    height: "100%",
    [theme.breakpoints.down("md")]: {
      padding: "12px",
    },
    [theme.breakpoints.down("sm")]: {
      padding: "12px",
    },
    "& .title": {
      fontSize: "18px",
      fontWeight: "600",
      [theme.breakpoints.down("md")]: {
        fontSize: "16px",
      },
      [theme.breakpoints.down("sm")]: {
        fontSize: "16px",
      },
    },
    borderRadius: "5px",
    // display: 'flex',
    // justifyContent: 'center',
    // alignItems: 'center',
    width: "100%",
    textAlign: "left",
  },
  paperExperience: {
    padding: "20px 12px",
    height: "100%",
    [theme.breakpoints.down("md")]: {
      padding: "12px",
    },
    [theme.breakpoints.down("sm")]: {
      padding: "12px",
    },
    "& .title": {
      fontSize: "18px",
      fontWeight: "600",
      [theme.breakpoints.down("md")]: {
        fontSize: "16px",
      },
      [theme.breakpoints.down("sm")]: {
        fontSize: "16px",
      },
    },
  },
  customHighlightsPaper: {
    paddingLeft: "4%",
    paddingRight: "4%",
    paddingTop: "4%",
    paddingBottom: "4%",
    // boxShadow: 'none',
    // borderRadius: '10px',
    // border: '#FBC112 solid 2px',
    // height: '20.5em', //'100%',
    height: "100%",
    // maxHeight: '100%',
    maxHeight: "400px",
    overflowY: "hidden",
    "&:hover": {
      overflowY: "auto",
    },
    borderRadius: "5px",
    // boxShadow: '0px 0px 0px 0px rgb(0 0 0 / 0%), 0px 0px 0px 0px rgb(0 0 0 / 0%), 0px 0px 10px 0px rgb(0 0 0 / 20%)',
    // boxShadow: '0px 0px 0px 0px rgb(0 0 0 / 0%), 0px 0px 0px 0px rgb(0 0 0 / 0%), 0px 0px 10px 0px rgb(0 0 0 / 20%)'
    boxShadow:
      "0px 0px 0px 1px rgb(0 0 0 / 5%), 0px 0px 0px 0px rgb(0 0 0 / 5%), 0px 0px 0px 1px rgb(0 0 0 / 5%)",
  },
  customButton: {
    backgroundColor: "#FFFFFF",
    // fontSize: 'large',
    border: "#048475 solid 2px",
    color: "#048475",
    width: "100px",
    float: "right",
  },
  customMsgButton: {
    width: "100px",
    marginBottom: "8px",
    // fontSize: 'large'
  },
  customScheduleButton: {
    // fontSize: 'large',
    marginRight: "25px",
    border: "#000 solid 1px",
    backgroundColor: "#FFFFFF",
    width: "200px",
    marginBottom: "8px",
  },
  customShareButton: {
    width: "100px",
    // fontSize: 'large',
    border: "#000 solid 1px",
    backgroundColor: "#FFFFFF",
    marginBottom: "8px",
  },
  highlightsCardTypography: { fontSize: "medium" },
  marginBottom10pc: { marginBottom: "10%" },
  // customChip: { width: '100%', marginTop: '3%', borderRadius: '10px'},
  customChip: {
    margin: "1%",
    borderRadius: "10px",
    float: "left",
    overflowY: "auto",
    height: "auto",
    padding: "5px",
  },
  customBottomMargin: { marginBottom: "4%" },
  insightsPaper: { paddingLeft: "3%", boxShadow: "none" },
  coffeeQuesPaper: { textAlign: "left", width: "82.5%" },
  noImageAvatar: {
    color: "#FFFFFF",
    fontSize: "xxx-large",
    backgroundColor: "#FBC112",
  },
  currentVideo: { border: "#FBC112 solid 3px" },
  videoTitle: {
    background: "#F7F7F7",
    borderRadius: "20px",
    paddingLeft: "3% !important",
    /* padding-right: 3%, */
    /* padding-top: 1%, */
    /* padding-bottom: 1%, */
    padding: "1%",
  },
  alignSelfCenter: { alignSelf: "center !important" },
  customShareSection: {
    boxShadow:
      "0px 1px 1px 0px rgb(0 0 0 / 0%), 0px 1px 5px 0px rgb(0 0 0 / 20%), 0px 0px 1px 1px rgb(0 0 0 / 12%)",
    borderRadius: "10px",
    padding: "3%",
  },
  divWithCircle: {
    display: "flex",
    justifyContent: "space-between",
    backgroundColor: "#F7F7F7",
    borderRadius: "10px",
    padding: "1%",
    position: "relative",
    width: "calc(100% - 30px)",
  },

  insightsDetails: {
    width: "100%", // 'calc(100% - 60px)',
    // [theme.breakpoints.down('md')]: {
    //   width: 'calc(100% - 40px)',
    // },
    [theme.breakpoints.down("sm")]: {
      // width: 'unset',
      padding: "10px 8px",
      // margin: '15px 0 30px'
    },
  },

  sideIconBox: {
    position: "absolute",
    right: "-30px",
    background: "#f7f7f7",
    top: "-15px",
    borderRadius: "50%",
    height: "80px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    [theme.breakpoints.down("md")]: {
      right: "-30px",
      top: "-15px",
      height: "50px",
      width: "50px",
    },
    [theme.breakpoints.down("sm")]: {
      ight: "-30px",
      top: "-15px",
      height: "50px",
      width: "50px",
    },
  },
  sideIcon: {
    width: "50% !important",
  },

  smallTitle: {
    [theme.breakpoints.down("sm")]: {
      fontWeight: 500,
      fontSize: "1.25rem",
      lineHeight: "120%",
    },
    fontWeight: 500,
    fontSize: "1.5rem",
    lineHeight: "120%",
  },
  boxTitle: {
    fontWeight: 500,
    fontSize: "1.5rem",
    lineHeight: "120%",
    [theme.breakpoints.down("md")]: {
      fontSize: "1rem",
    },
    [theme.breakpoints.down("sm")]: {
      fontSize: "1rem",
    },
  },
  btnArchive: {
    [theme.breakpoints.down("sm")]: {
      position: "relative",
      top: "-55px",
    },
  },
  expGapFlag: {
    color: "#FBC112",
    cursor: "pointer",
  },
  customPaddingRight: { paddingRight: "48px" },
  mainCard: {
    padding: "15px",
    borderRadius: "10px !important",
  },
  cardAction: {
    borderRadius: "10px !important",
  },
  customFloatLeft: {
    float: "left",
  },
  AvatarGrid: {
    padding: "15px 15px 15px 25px",
  },
  nameGrid: {
    padding: "5px 15px 5px 25px",
    display: "flex",
    alignItems: "center",
  },
  fullNameTypo: {
    marginTop: "15px",
    color: "#000000",
  },
  eventIconButton: {
    margin: "5px",
    color: "#000000",
  },
  removeFromQueButton: {
    margin: "5px",
    minWidth: "10em",
  },
  videoMainDiv: {
    marginTop: "20px",
    padding: "20px",
  },
  bolderFont: {
    fontWeight: "bolder",
  },
  videoHeadingTypo: {
    position: "absolute",
    right: 55,
    top: 17,
  },
  candidateVideoDivider1: {
    marginTop: "15px",
    marginBottom: "25px",
  },
  candidateVideoDivider2: {
    borderBottom: "1px solid #D7D9DB",
    width: "inherit",
    marginTop: "3%",
    flexShrink: 1,
  },
  videosList: {
    margin: "20px 0px 0px 20px",
  },
  videoPadding: {
    padding: "5px",
  },
  passingCanContent: {
    paddingLeft: "8%",
    paddingRight: "8%",
  },
  ArchiveClose: {
    background: "#D7D9DB",
    color: "#000",
    width: "10rem",
  },
  paddingShareCan: {
    padding: "10px",
  },
  mainVideo: {
    width: "-webkit-fill-available",
  },
  actionCardHeader: {
    textAlign: "left",
    borderBottom: "1px solid #D6D5D6",
    paddingLeft: "28px",
    fontWeight: "bolder",
    color: "##666666",
  },
  paddingMedium: {
    paddingLeft: "15px",
  },
  confirmButton: { width: "10rem" },
  dailogActions: { justifyContent: "center" },
});

let charCount = 50;
let reasonCharCount = 100;

const DialogTitle = withStyles(styles)((props) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle
      disableTypography
      {...other}
      className={classes.customCardHeader}
    >
      {/* className={classes.root} */}
      <Typography variant="h5" align="center">
        {children}
      </Typography>
      {onClose ? (
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={onClose}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const DialogContent = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
    paddingLeft: theme.spacing(4),
    paddingRight: theme.spacing(4),
  },
}))(MuiDialogContent);

const DialogActions = withStyles((theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
    backgroundColor: "#fff",
    justifyContent: "flex-end",
  },
}))(MuiDialogActions);

let sticky;
// let stickyCard;
class MemberFullProfile extends Component {
  constructor(props) {
    super(props);
    this.state = this.getInitialState();
  }

  componentDidMount() {
    const { dispatch } = this.props;
    document.body.scrollTop = 0; // For Safari
    document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE and Opera
    const id = this.props.match.params.id;
    const recommendationId = this.props.match.params.recommendationId;
    const matchedArchived =
      this.props.match.params.matchedArchived === "false" ? false : true;
    dispatch(
      membersActions.getFullProfile(id, recommendationId, matchedArchived)
    );
  }

  componentDidUpdate = (prevProps) => {
    if (
      prevProps?.members?.fullProfileData
        ?.recruiter_matched_candidate_details !==
      this.props?.members?.fullProfileData?.recruiter_matched_candidate_details
    ) {
      const fullProfileData =
        this.props?.members?.fullProfileData
          ?.recruiter_matched_candidate_details;
      const { pageTitle } = this.props.pageTitle;
      const { dispatch } = this.props;
      const { selectedNavbarIndex } = this.props.shared;
      const { jobDetails } = this.props?.jobs ?? this.props;

      if (fullProfileData?.insight_videos.length > 0) {
        this.setState({
          videoTitle: fullProfileData?.insight_videos[0]?.topic,
          videoSrc: fullProfileData?.insight_videos[0]?.video_url,
          poster: fullProfileData?.insight_videos[0]?.thumbnail_url,
        });
      }
      // Restrict the right click on the video playing window, so that user can not save the video.
      for (
        let i = 0;
        i <
        this.props?.members?.fullProfileData
          ?.recruiter_matched_candidate_details?.insight_videos?.length;
        i++
      ) {
        if (document.getElementById(`insightVideo${i}`)) {
          document.getElementById(`insightVideo${i}`).oncontextmenu =
            function () {
              return false;
            };
        }
      }

      switch (selectedNavbarIndex) {
        case 1:
          dispatch(
            setTitle(
              `${`Jobs`} ${`-`} ${`My Jobs`} ${`-`} ${`Open Positions - `} ${
                jobDetails?.title
              } ${`-`} ${`Matched`} ${`-`} ${fullProfileData?.first_name} ${
                fullProfileData?.last_name
              }`
            )
          );
          break;
        case 11:
          dispatch(
            setTitle(
              `${`Jobs`} ${`-`} ${`Shared Jobs`} ${`-`} ${`Open Positions - `} ${
                jobDetails?.title
              } ${`-`} ${`Matched`} ${`-`} ${fullProfileData?.first_name} ${
                fullProfileData?.last_name
              }`
            )
          );
          break;
        default:
          break;
      }

      seo({
        title: `${pageTitle}`,
      });
    }

    function scrollFunction() {
      var navbar = document.getElementById("tabbar");
      sticky = navbar?.offsetTop;
    }

    // When the user scrolls down 20px from the top of the document, show the button
    window.onscroll = function () {
      scrollFunction();
    };
  };

  componentWillUnmount() {
    JSON.parse(secureLocalStorage.getItem("state")).members = {};
  }

  getInitialState = () => ({
    current: 1,
    value: JSON.parse(secureLocalStorage.getItem("previousTab")) || 0,
    innerTabValue: 0,
    expanded: "",
    open: false,
    dislikePopupOpen: false,
    archiveReason: "",
    otherArchiveReason: "",
    isValidOtherArchiveReason: true,
    firstName: "",
    lastName: "",
    email: "",
    title: "",
    fields: [
      {
        fNameValue: "",
        lNameValue: "",
        emailValue: "",
        titleValue: "",
        isValidEmail: true,
        isValidFirstName: true,
        isValidLastName: true,
        isValidTitle: true,
        isEmailRequired: false,
        isFirstNameRequired: false,
        isLastNameRequired: false,
        isTitleRequired: false,
      },
    ],
    isValidFirstName: true,
    isValidLastName: true,
    isValidEmail: true,
    isValidTitle: true,
    activeItemIndex: 0,
    openScheduleInterview: false,
    scheduleInterviewType: "",
    linkToCandidate: "",
    emailTocandidate: "",
    emailTocoordinator: "",
    isValidLinkRequired: false,
    isValidLinkEmail: true,
    isValidLinkUrl: true,
    expandeds: "panel1",
    tabValue: 0,
    scheduleFullName: "",
    isValidFullNameRequired: false,
    isValidFullName: true,
    commentPopup: false,
    openPreview: false,
    poster: "",
    videoTitle: "",
    videoSrc: "/",
    selectedVideo: 0,
    openMoveCandidateConversation: false,
    fullProfileData:
      this.props?.members?.fullProfileData?.recruiter_matched_candidate_details,
    inConversationButton: true,
    removefromQueueButton: true,
    openMarkedHired: false,
  });

  capitalize = function (str) {
    if (str !== null && str !== undefined) {
      return str
        .split(" ")
        .map((w) => w.substring(0, 1).toUpperCase() + w.substring(1))
        .join(" ");
    } else return str;
  };

  goBack = (event) => {
    event.preventDefault();
    if (window.location.pathname.match("^/members.*$")) {
      if (this.props.shared?.selectedNavbarIndex === 3) {
        history.go(-3);
      } else {
        history.push(
          this.props.shared?.selectedNavbarIndex === 1
            ? `/jobs/my-jobs/${this.props.jobs?.jobDetails?.id}?candidate=${this.props?.members?.fullProfileCandidateId}`
            : `/jobs/shared-jobs/${this.props.jobs?.jobDetails?.id}?candidate=${this.props?.members?.fullProfileCandidateId}`
        );
      }
    }
    return;
  };

  handleOpenMarkedHired = () => {
    this.setState({ openMarkedHired: true });
  };

  onCloseMarkedHired = () => {
    this.setState({ openMarkedHired: false });
  };

  onhandleCloseMoveCandidateConversation = () => {
    this.setState({ openMoveCandidateConversation: false });
  };

  onhandleOpenMoveCandidateConversation = () => {
    this.setState({ openMoveCandidateConversation: true });
  };

  handleClose = () => {
    this.setState({ open: false }, () => {
      this.setState({
        fields: [
          {
            fNameValue: "",
            lNameValue: "",
            emailValue: "",
            titleValue: "",
            isValidEmail: true,
            isValidFirstName: true,
            isValidLastName: true,
            isValidTitle: true,
            isEmailRequired: false,
            isFirstNameRequired: false,
            isLastNameRequired: false,
            isTitleRequired: false,
          },
        ],
      });
    });
  };

  handleShareProfileClick = async () => {
    if (this.props.shared?.accessPermission) {
      let password = await jobsActions.getCompanyPassword(
        this.props.authentication.recruiterStatusDetails?.company
          ?.recruiter_company_id
      );
      const baseUrl = window.location.origin;
      let decryptedPassword = await decryptData(
        master_key,
        password?.data?.data?.password
      );

      let candidateDetails =
        this.props?.members?.fullProfileData
          ?.recruiter_matched_candidate_details;
      const candidateDataInText = `${LABELS.SHARE_PROFILE.COMPANY_NAME}: ${
        candidateDetails?.company?.company_name || "-"
      }\n${LABELS.SHARE_PROFILE.JOB_TITLE}: ${
        candidateDetails?.company?.title || "-"
      }\n${LABELS.SHARE_PROFILE.CANDIDATE_NAME}: ${
        candidateDetails.first_name
      } ${candidateDetails.last_name}\n${
        LABELS.SHARE_PROFILE.CANDIDATE_PROFILE_LINK
      }: ${baseUrl}/share-profile/${
        this.props.match.params.recommendationId
      }\n${LABELS.SHARE_PROFILE.PASSWORD}: ${decryptedPassword}\n\n`;
      copyToClipboard(candidateDataInText);
    } else {
      toast.dark(
        "Only Job Owner or Editor can share profile with other recruiters"
      );
    }
  };

  handleChange = (i, event) => {
    const values = [...this.state.fields];
    const inputValue = notAllowWhiteSpaceAtStart(event.target.value);
    switch (event.currentTarget.name) {
      case "firstName":
        values[i].fNameValue = inputValue;
        values[i].isValidFirstName = inputValue
          ? validator.matches(inputValue, /^.{2,50}$/)
          : true;
        values[i].isFirstNameRequired = validator.isEmpty(inputValue);
        break;
      case "lastName":
        values[i].lNameValue = inputValue;
        values[i].isValidLastName = inputValue
          ? validator.matches(inputValue, /^.{2,50}$/)
          : true;
        values[i].isLastNameRequired = validator.isEmpty(inputValue);
        break;
      case "email":
        values[i].emailValue = inputValue;
        values[i].isValidEmail = inputValue
          ? validator.isEmail(inputValue)
          : true;
        values[i].isEmailRequired = validator.isEmpty(inputValue);

        if (
          inputValue.includes("@yahoo.com") ||
          inputValue.includes("@gmail.com") ||
          inputValue.includes("@live.com") ||
          inputValue.includes("@rediffmail.com") ||
          inputValue.includes("@hotmail.com") ||
          inputValue.includes("@ymail.com") ||
          inputValue.includes("@outlook.com") ||
          inputValue.includes("@yopmail.com")
        ) {
          values[i].isValidEmail = false;
        }
        break;
      case "title":
        values[i].titleValue = inputValue;
        values[i].isTitleRequired = validator.isEmpty(inputValue);
        values[i].isValidTitle = inputValue
          ? validator.matches(inputValue, /^.{2,100}$/)
          : true;
        break;
      default:
        break;
    }
    this.setState({ fields: values });
  };

  handleAdd = () => {
    const values = [...this.state.fields];
    values.push({
      fNameValue: "",
      lNameValue: "",
      emailValue: "",
      titleValue: "",
      isValidEmail: true,
      isValidFirstName: true,
      isValidLastName: true,
      isValidTitle: true,
      isEmailRequired: false,
      isFirstNameRequired: false,
      isLastNameRequired: false,
      isTitleRequired: false,
    });
    this.setState({ fields: values });
  };

  handleRemove(i) {
    const values = [...this.state.fields];
    values.splice(i, 1);
    this.setState({ fields: values });
  }

  handleSend = (event) => {
    event.preventDefault();
    let checkArray = [];
    for (let item in this.state.fields) {
      if (
        this.state.fields[item].fNameValue === "" ||
        this.state.fields[item].fNameValue === null ||
        this.state.fields[item].lNameValue === "" ||
        this.state.fields[item].lNameValue === null ||
        this.state.fields[item].emailValue === "" ||
        this.state.fields[item].emailValue === null ||
        this.state.fields[item].titleValue === "" ||
        this.state.fields[item].titleValue === null ||
        !this.state.fields[item].isValidFirstName ||
        !this.state.fields[item].isValidLastName ||
        !this.state.fields[item].isValidEmail ||
        !this.state.fields[item].isValidTitle
      ) {
        const value = [...this.state.fields];
        value[item].isFirstNameRequired = validator.isEmpty(
          this.state.fields[item].fNameValue
        );
        value[item].isLastNameRequired = validator.isEmpty(
          this.state.fields[item].lNameValue
        );
        value[item].isEmailRequired = validator.isEmpty(
          this.state.fields[item].emailValue
        );
        value[item].isTitleRequired = validator.isEmpty(
          this.state.fields[item].titleValue
        );
        this.setState({
          isValidFormData: false,
          fields: value,
        });
      } else {
        this.setState({ isValidFormData: true });
        let payloadObject = {
          first_name: trim(this.state.fields[item].fNameValue),
          last_name: trim(this.state.fields[item].lNameValue),
          email: trim(this.state.fields[item].emailValue),
          title: trim(this.state.fields[item].titleValue),
        };
        checkArray.push(payloadObject);
      }
    }
    if (this.state.fields.length === checkArray.length) {
      const { dispatch } = this.props;
      let payload = {
        recommendation_id: this.props?.members?.fullProfileRecommendationId,
        candidate_id: this.props?.members?.fullProfileCandidateId,
        shareWith: checkArray,
      };
      dispatch(
        membersActions.shareProfileLink(
          payload,
          JSON.parse(secureLocalStorage.getItem("recruiterStatusDetails"))
            .recruiter_id
        )
      );
      this.handleClose();
    } else {
      toast.dark("Please fill up all the details with correct information");
      return;
    }
  };

  handleDislikeCandidateRecommendation = (candidateId) => {
    if (this.props.shared?.accessPermission) {
      this.setState({ dislikePopupOpen: true });
      this.setState({ candidateId: candidateId });
    } else {
      toast.dark(
        "Only job owner or editor can archive candidate recommendation"
      );
    }
  };

  handleCloseArchivePopup = () => {
    this.setState({
      dislikePopupOpen: false,
      archiveReason: "",
      otherArchiveReason: "",
    });
  };

  handleArchive = () => {
    const { dispatch } = this.props;
    const { otherArchiveReason, archiveReason } = this.state;
    if (archiveReason !== "") {
      if (archiveReason === "Other" && !otherArchiveReason) {
        toast.dark("Please enter the reason for archiving");
        this.setState({ isValidOtherArchiveReason: true });
      } else if (this.state.isValidOtherArchiveReason) {
        toast.dark("Invalid reason");
        return;
      } else {
        let payload = {
          is_passed: false,
          reason_for_rejection: archiveReason,
          other_feedback:
            archiveReason === "Other" && otherArchiveReason === ""
              ? null
              : archiveReason !== "Other"
              ? null
              : otherArchiveReason,
        };
        dispatch(
          membersActions.archiveFromFullProfile(
            this.props?.members?.fullProfileRecommendationId,
            payload
          )
        );
        this.setState({
          removefromQueueButton: false,
          inConversationButton: false,
        });
        dispatch(
          membersActions.getFullProfile(
            this.props.members?.fullProfileCandidateId,
            this.props.members?.fullProfileRecommendationId,
            true
          )
        );
        this.handleCloseArchivePopup();
      }
    } else {
      toast.dark("Please choose one option");
    }
  };

  handleInputChange = (prop) => (event) => {
    this.setState({ [prop]: event.target.value }, function () {
      switch (prop) {
        case "archiveReason":
          this.setState({
            isValidOtherArchiveReason: false,
            archiveReason: event.target.value,
            otherArchiveReason: null,
          });
          break;
        case "otherArchiveReason":
          this.setState({
            isValidOtherArchiveReason: validator.isEmpty(event.target.value)
              ? true
              : event.target?.value?.length < 25
              ? true
              : false,
          });
          break;
        default:
          break;
      }
    });
  };

  setActiveItemIndex = (event) => {
    this.setState({ activeItemIndex: event });
  };

  showWarning = () => {
    toast.dark(
      "This operation is not allowed when you are logged in as SucceedSmart admin"
    );
  };

  sendMessage = () => {
    if (this.props.shared?.accessPermission) {
      const { dispatch } = this.props;
      dispatch(messagesActions.createChannel(this.props.match.params.id));
    } else {
      toast.dark("Only job owner or editor can send messages to candidate");
    }
  };

  sendScheduleSchedulingLinkCandidate = (scheduleInterviewType) => {
    this.setState({
      openScheduleInterview: true,
      scheduleInterviewType: scheduleInterviewType,
    });
  };

  closeScheduleInterview = () => {
    const { dispatch } = this.props;
    dispatch(jobsActions.schedulingLinkValues({}));
    this.setState({
      openScheduleInterview: false,
      scheduleInterviewType: null,
    });
  };

  handleCloseSchedulingLinkPopup = () => {
    this.setState({
      openScheduleInterview: false,
      scheduleInterviewType: "",
      linkToCandidate: "",
      emailTocandidate: "",
      emailTocoordinator: "",
      isValidLinkRequired: false,
      isValidLinkEmail: true,
      isValidLinkUrl: true,
      isValidFullNameRequired: false,
      scheduleFullName: "",
    });
  };

  handleScheduleInterview = (event, type) => {
    let input = "";
    switch (this.state.scheduleInterviewType) {
      case "link to candidate":
        input = event.target.value;
        this.setState({
          linkToCandidate: event.target.value,
          isValidLinkRequired: validator.isEmpty(input),
          isValidLinkUrl: validator.matches(
            input,
            /^((ftp|http|https):\/\/)?(www.)?(?!.*(ftp|http|https|www.))[a-zA-Z0-9_-]+(\.[a-zA-Z]+)+((\/)[\w#-=?!@">`~]+)*(\/\w+\?[a-zA-Z0-9_]+=\w+(&[a-zA-Z0-9_]+=\w+))?$/gs
          ),
        });
        break;
      case "email to candidate":
        input = event.target.value;
        this.setState({
          emailTocandidate: event.target.value,
          isValidLinkRequired: validator.isEmpty(input),
          isValidLinkUrl: validator.matches(
            input,
            /^((ftp|http|https):\/\/)?(www.)?(?!.*(ftp|http|https|www.))[a-zA-Z0-9_-]+(\.[a-zA-Z]+)+((\/)[\w#-=?!@">`~]+)*(\/\w+\?[a-zA-Z0-9_]+=\w+(&[a-zA-Z0-9_]+=\w+))?$/gs
          ),
        });
        break;
      case "email to coordinator":
        if (type === "coordinator") {
          input = event.target.value;
          this.setState({
            emailTocoordinator: event.target.value,
            isValidLinkEmail: validator.isEmail(input),
            isValidLinkRequired: validator.isEmpty(input),
          });
        } else {
          input = event.target.value;
          this.setState({
            scheduleFullName: event.target.value,
            isValidFullNameRequired: validator.isEmpty(input),
            isValidFullName: input
              ? validator.matches(input, /^.{2,100}$/)
              : true,
          });
        }

        if (
          input.includes("@yahoo.com") ||
          input.includes("@gmail.com") ||
          input.includes("@live.com") ||
          input.includes("@rediffmail.com") ||
          input.includes("@hotmail.com") ||
          input.includes("@ymail.com") ||
          input.includes("@outlook.com") ||
          input.includes("@yopmail.com")
        ) {
          this.setState({ isValidLinkEmail: true });
        }
        break;
      default:
        break;
    }
  };

  handleSendSchedulingLink = () => {
    const { dispatch } = this.props;
    let input = "";
    let inputFullName = "";
    switch (this.state.scheduleInterviewType) {
      case "link to candidate":
        input = this.state.linkToCandidate;
        this.setState({
          linkToCandidate: input,
          isValidLinkRequired: validator.isEmpty(input),
          isValidLinkUrl: validator.matches(
            input,
            /^((ftp|http|https):\/\/)?(www.)?(?!.*(ftp|http|https|www.))[a-zA-Z0-9_-]+(\.[a-zA-Z]+)+((\/)[\w#-=?!@">`~]+)*(\/\w+\?[a-zA-Z0-9_]+=\w+(&[a-zA-Z0-9_]+=\w+))?$/gs
          ),
        });
        break;
      case "email to candidate":
        input = this.state.emailTocandidate;
        this.setState({
          emailTocandidate: input,
          isValidLinkRequired: validator.isEmpty(input),
          isValidLinkUrl: validator.matches(
            input,
            /^((ftp|http|https):\/\/)?(www.)?(?!.*(ftp|http|https|www.))[a-zA-Z0-9_-]+(\.[a-zA-Z]+)+((\/)[\w#-=?!@">`~]+)*(\/\w+\?[a-zA-Z0-9_]+=\w+(&[a-zA-Z0-9_]+=\w+))?$/gs
          ),
        });
        break;
      case "email to coordinator":
        input = this.state.emailTocoordinator;
        inputFullName = this.state.scheduleFullName;
        this.setState({
          emailTocoordinator: input,
          isValidLinkEmail: validator.isEmail(input),
          isValidLinkRequired: validator.isEmpty(input),
          scheduleFullName: inputFullName,
          isValidFullNameRequired: validator.isEmpty(inputFullName),
          isValidFullName: inputFullName
            ? validator.matches(inputFullName, /^.{2,100}$/)
            : true,
        });
        break;
      default:
        break;
    }

    if (
      (this.state.isValidLinkRequired ||
        !this.state.isValidLinkUrl ||
        this.state.linkToCandidate.length === 0) &&
      (this.state.isValidLinkRequired ||
        !this.state.isValidLinkUrl ||
        this.state.emailTocandidate.length === 0) &&
      (this.state.emailTocoordinator.length === 0 ||
        this.state.isValidLinkRequired ||
        !this.state.isValidLinkEmail ||
        this.state.scheduleFullName.length === 0 ||
        !this.state.scheduleFullName ||
        this.state?.scheduleFullName.length > 100)
    ) {
      toast.dark("Please fill up all the details");
      return;
    } else {
      let payload = {
        candidate_id: this.props.members.fullProfileCandidateId,
        option: this.state.scheduleInterviewType,
        email: this.state.emailTocoordinator,
        name: this.state.scheduleFullName,
        link:
          this.state.emailTocandidate.length > 0
            ? this.state.emailTocandidate
            : this.state.linkToCandidate,
      };
      dispatch(
        membersActions.postSchedulingLinkToInterview(
          this.props.members.fullProfileRecommendationId,
          payload
        )
      );
      this.setState({
        openScheduleInterview: false,
        scheduleInterviewType: "",
        linkToCandidate: "",
        emailTocandidate: "",
        emailTocoordinator: "",
        isValidLinkRequired: false,
        isValidLinkEmail: true,
        isValidLinkUrl: true,
        scheduleFullName: "",
        isValidFullNameRequired: false,
      });
    }
  };

  handleChangePanel = (panel) => (event, newExpanded) => {
    this.setState({ expandeds: newExpanded ? panel : false });
  };

  handleTabChange = (e, value) => {
    this.setState({ tabValue: value });
  };

  handleCommentClick = () => {
    this.setState({ commentPopup: true });
  };

  closeCandidateCommentsPopup = () => {
    this.setState({ commentPopup: false });
  };

  handlePreview = (values, note) => {
    const { dispatch } = this.props;
    dispatch(jobsActions.schedulingLinkValues(values));
    if (this.state.scheduleInterviewType === "email to coordinator") {
      let payload = {
        option: this.state.scheduleInterviewType,
        first_name: values?.firstname,
        last_name: values?.lastname,
        email: values?.emailTocoordinator,
        is_preview: true,
      };

      this.setState({ openScheduleInterview: false, openPreview: true }, () => {
        dispatch(
          membersActions.postSchedulingEmailToCoordinator(
            this.props.members.fullProfileRecommendationId,
            payload
          )
        );
      });
    } else {
      let payload = {
        note: note,
        is_preview: true,
      };
      this.setState({ openScheduleInterview: false, openPreview: true }, () => {
        dispatch(
          membersActions.postSchedulingEmailToCandidate(
            this.props.members.fullProfileRecommendationId,
            payload
          )
        );
      });
    }
  };

  editPreview = () => {
    this.setState({ openScheduleInterview: true, openPreview: false });
  };

  onClosePreview = () => {
    const { dispatch } = this.props;
    dispatch(jobsActions.schedulingLinkValues({}));
    this.setState({ openPreview: false });
  };

  hideButtonsforRemovedCandidate = () => {
    this.setState({
      removefromQueueButton: false,
      inConversationButton: false,
    });
  };

  sendEmail = () => {
    const { dispatch } = this.props;
    let sendEmailValues = this.props.jobs.schedulingLinkValues;
    if (this.state.scheduleInterviewType === "email to coordinator") {
      let payload = {
        option: this.state.scheduleInterviewType,
        first_name: sendEmailValues?.firstname,
        last_name: sendEmailValues?.lastname,
        email: sendEmailValues?.emailTocoordinator,
        is_preview: false,
      };
      dispatch(
        membersActions.postSchedulingEmailToCoordinator(
          this.props.members.fullProfileRecommendationId,
          payload
        )
      );
    } else {
      let payload = {
        note: draftToHtml(
          convertToRaw(sendEmailValues.editorNotesState.getCurrentContent())
        ),
        is_preview: false,
      };
      dispatch(
        membersActions.postSchedulingEmailToCandidate(
          this.props.members.fullProfileRecommendationId,
          payload
        )
      );
    }
    dispatch(jobsActions.schedulingLinkValues({}));
    this.setState({ openPreview: false });
  };

  removeConversationButton = () => {
    this.setState({ inConversationButton: false });
  };

  unmountRemoveFromQueueButton = () => {
    this.setState({ removefromQueueButton: false });
  };

  downloadPdf = () => {
    let firstName =
      this.props.members.fullProfileData.recruiter_matched_candidate_details
        .first_name;
    let lastName =
      this.props.members.fullProfileData.recruiter_matched_candidate_details
        .last_name;

    membersActions.downloadPDF(
      this.props.members.fullProfileRecommendationId,
      `${firstName} ${lastName}`
    );
  };

  render() {
    const { classes } = this.props;
    let candidateDetails =
      this.props?.members?.fullProfileData?.recruiter_matched_candidate_details;
    let customAccomplishmentText = "";
    for (let item in candidateDetails?.accomplishment) {
      customAccomplishmentText += `<div><ul><li>${candidateDetails?.accomplishment[item]}</li></ul></div>`;
    }
    let countInsight = candidateDetails?.insight_videos?.length
      ? candidateDetails?.insight_videos?.length
      : "";

    const EMPTY_ICON_HEIGHT = 140;
    const EMPTY_ICON_WIDTH = 140;

    return (
      <div className={classes.root}>
        <div className={classes.appFrame}>
          <Button
            className={classes.customFloatLeft}
            startIcon={<KeyboardArrowLeftIcon />}
            onClick={(event) => {
              this.goBack(event);
            }}
          >
            Back
          </Button>
        </div>
        <Grid container wrap="wrap-reverse">
          <Grid item xs={12} sm={12} md={9} xl={9} lg={9}>
            <main className={classes.content}>
              <Card id="headerCard" className={classes.mainCard} elevation={3}>
                <Grid container>
                  <Grid
                    item
                    xs={12}
                    sm={12}
                    md={12}
                    lg={12}
                    xl={12}
                    className={classes.nameGrid}
                  >
                    <span>
                      <Avatar
                        alt={this.capitalize(
                          candidateDetails?.first_name +
                            "" +
                            candidateDetails?.last_name
                        )}
                        src={
                          candidateDetails?.profile_image ||
                          candidateDetails?.first_name
                        }
                        // className={classNames(classes.largeAvatar, classes.noImageAvatar)}
                        className="customMdAvatar"
                      />
                    </span>
                    <span className={classes.paddingMedium}>
                      <Typography
                        variant="h5"
                        className={classNames(
                          classes.colorDefault,
                          classes.textAlignLeft,
                          classes.boldFont,
                          classes.fullNameTypo
                        )}
                      >
                        {this.capitalize(
                          candidateDetails?.first_name +
                            " " +
                            candidateDetails?.last_name
                        )}
                      </Typography>
                      <Typography
                        variant="body1"
                        className={classNames(
                          classes.colorDefault,
                          classes.textAlignLeft,
                          classes.customBoldFont,
                          classes.verySmallTopMargin
                        )}
                      >
                        {this.capitalize(candidateDetails?.company?.title) +
                          " at " +
                          this.capitalize(
                            candidateDetails?.company?.company_name
                          )}
                      </Typography>
                      <Typography
                        variant="body1"
                        className={classNames(
                          classes.colorDefault,
                          classes.textAlignLeft,
                          classes.customBoldFont,
                          // classes.smallBottomMargin,
                          classes.verySmallTopMargin
                        )}
                      >
                        {(candidateDetails?.location?.city
                          ? this.capitalize(candidateDetails?.location?.city) +
                            ", "
                          : "") +
                          (candidateDetails?.location?.state
                            ? this.capitalize(
                                candidateDetails?.location?.state
                              ) + ", "
                            : "") +
                          (candidateDetails?.location?.country
                            ? this.capitalize(
                                candidateDetails?.location?.country
                              )
                            : "")}
                      </Typography>
                    </span>
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    sm={12}
                    md={6}
                    lg={6}
                    xl={6}
                    className={classes.headerActions}
                  >
                    <div
                      className={classNames(
                        classes.displayFlex,
                        classes.justifyContentFlexEnd,
                        classes.verySmallTopMargin
                      )}
                    >
                      <PopupState
                        variant="popover"
                        popupId="demo-popup-menu"
                        disab
                      >
                        {(popupState) => (
                          <React.Fragment>
                            <div {...bindTrigger(popupState)}>
                              <Tooltip
                                arrow
                                placement="bottom"
                                title="Schedule Meeting"
                              >
                                <IconButton
                                  className={classes.eventIconButton}
                                  disableElevation
                                  variant="contained"
                                  size="small"
                                  disabled={secureLocalStorage.getItem("g_u")}
                                >
                                  <EventIcon />
                                </IconButton>
                              </Tooltip>
                            </div>
                            <Menu
                              {...bindMenu(popupState)}
                              anchorOrigin={{
                                vertical: "center",
                                horizontal: "left",
                              }}
                              transformOrigin={{
                                vertical: "top",
                                horizontal: "right",
                              }}
                            >
                              <MenuItem
                                onClick={() => {
                                  popupState.close();
                                  !secureLocalStorage.getItem("g_u")
                                    ? this.sendScheduleSchedulingLinkCandidate(
                                        "email to candidate"
                                      )
                                    : this.showWarning();
                                  popupState.close();
                                }}
                              >
                                <ListItemText primary="Send email to candidate to schedule interview" />
                              </MenuItem>
                              <MenuItem
                                onClick={() => {
                                  popupState.close();
                                  !secureLocalStorage.getItem("g_u")
                                    ? this.sendScheduleSchedulingLinkCandidate(
                                        "email to coordinator"
                                      )
                                    : this.showWarning();
                                  popupState.close();
                                }}
                              >
                                <ListItemText primary="Send email to coordinator to schedule interview" />
                              </MenuItem>
                              <Divider />
                            </Menu>
                          </React.Fragment>
                        )}
                      </PopupState>
                      <div>
                        <Tooltip arrow placement="bottom" title="Send Message">
                          <IconButton
                            variant="contained"
                            size="small"
                            color="primary"
                            className={classes.eventIconButton}
                            disableElevation
                            disabled={secureLocalStorage.getItem("g_u")}
                            onClick={(event) => {
                              event.preventDefault();
                              !secureLocalStorage.getItem("g_u")
                                ? this.sendMessage()
                                : this.showWarning();
                            }}
                          >
                            <MessageIcon />
                          </IconButton>
                        </Tooltip>
                      </div>
                      <div>
                        <Tooltip
                          arrow
                          placement="bottom"
                          title="Client Internal Notes"
                        >
                          <IconButton
                            variant="contained"
                            size="small"
                            disableElevation
                            className={classes.eventIconButton}
                            onClick={(event) => {
                              this.handleCommentClick(event);
                            }}
                          >
                            <ChatIcon />
                          </IconButton>
                        </Tooltip>
                      </div>
                      <div>
                        {/* <Tooltip
                          arrow
                          placement="bottom"
                          title="Copy Profile Information"
                        >
                          <IconButton
                            variant="contained"
                            size="small"
                            disableElevation
                            className={classes.eventIconButton}
                            onClick={(event) => {
                              this.handleShareProfileClick(event);
                            }}
                          >
                            <CopyIcon />
                          </IconButton>
                        </Tooltip> */}

                        <PopupState
                          variant="popover"
                          popupId="demo-popup-menu"
                          disab
                        >
                          {(popupState) => (
                            <React.Fragment>
                              <div {...bindTrigger(popupState)}>
                                <ListItem button>
                                  <ListItemIcon>
                                    <ShareIcon />
                                  </ListItemIcon>
                                </ListItem>
                              </div>
                              <Menu
                                {...bindMenu(popupState)}
                                anchorOrigin={{
                                  vertical: "center",
                                  horizontal: "left",
                                }}
                                transformOrigin={{
                                  vertical: "top",
                                  horizontal: "right",
                                }}
                              >
                                <MenuItem>
                                  <ListItemText
                                    primary="Copy Profile URL & Password"
                                    onClick={(event) => {
                                      this.handleShareProfileClick(event);
                                      popupState.close();
                                    }}
                                  />
                                </MenuItem>
                                <MenuItem>
                                  <ListItemText
                                    onClick={this.downloadPdf}
                                    primary="Download PDF"
                                  />
                                </MenuItem>
                                <Divider />
                              </Menu>
                            </React.Fragment>
                          )}
                        </PopupState>
                      </div>
                      <div>
                        {!candidateDetails?.is_hired &&
                          this.props?.shared?.accessPermission &&
                          this.props?.jobs.jobDetails?.job_status !== "Close" &&
                          candidateDetails?.new_recommendation_status !==
                            "Removed from Queue" &&
                          this.state.removefromQueueButton && (
                            <IconButton
                              variant="outlined"
                              size="medium"
                              color="primary"
                              // className={classes.removeFromQueButton}
                              disableElevation
                              onClick={(event) => {
                                event.preventDefault();
                                this.handleDislikeCandidateRecommendation(
                                  this.props?.members?.fullProfileCandidateId
                                );
                              }}
                            >
                              <RemovedFromIcon />
                            </IconButton>
                          )}
                      </div>
                    </div>
                  </Grid>
                </Grid>
                <Box sx={{ maxWidth: { xs: "100%", sm: "100%" } }}>
                  <Tabs
                    textColor="#000000"
                    variant="scrollable"
                    TabIndicatorProps={{
                      style: { background: "#FBC112", height: "4px" },
                    }}
                    scrollButtons="auto"
                    aria-label="scrollable auto tabs example"
                    value={this.state.tabValue}
                    onChange={(e, value) => this.handleTabChange(e, value)}
                    id="tabbar"
                    className={
                      window.pageYOffset >= sticky &&
                      (this.props.shared.isNavbarVisible === undefined ||
                        this.props.shared.isNavbarVisible === false)
                        ? classes.stickyBar
                        : classes.stickyBarRemove
                    }
                  >
                    <Tab
                      style={{
                        fontWeight: this.state.tabValue === 0 ? "bold" : "",
                      }}
                      label="Profile Highlights"
                      className={classes.customTabs}
                    />
                    <Tab
                      style={{
                        fontWeight: this.state.tabValue === 1 ? "bold" : "",
                      }}
                      label="Experience"
                      className={classes.customTabs}
                    />
                    <Tab
                      style={{
                        fontWeight: this.state.tabValue === 2 ? "bold" : "",
                      }}
                      label="Education"
                      className={classes.customTabs}
                    />
                    <Tab
                      style={{
                        fontWeight: this.state.tabValue === 3 ? "bold" : "",
                      }}
                      label="Coffee Questions"
                      className={classes.customTabs}
                    />
                    <Tab
                      style={{
                        fontWeight: this.state.tabValue === 4 ? "bold" : "",
                      }}
                      label="Candidate Videos"
                      className={classes.customTabs}
                    />
                    <Tab
                      style={{
                        fontWeight: this.state.tabValue === 5 ? "bold" : "",
                      }}
                      label="Resume"
                      className={classes.customTabs}
                    />
                    <Tab
                      style={{
                        fontWeight: this.state.tabValue === 6 ? "bold" : "",
                      }}
                      label="Notes"
                      className={classes.customTabs}
                    />
                  </Tabs>
                </Box>
                {this.state.tabValue === 0 && (
                  <CustomizedAccordions
                    data={candidateDetails}
                    customAccomplishmentText={customAccomplishmentText}
                    type={"overviews"}
                  />
                )}
                {this.state.tabValue === 4 && (
                  <div className={classes.videoMainDiv}>
                    <Typography
                      variant="h6"
                      align="left"
                      className={classes.bolderFont}
                    >
                      Candidate Videos
                    </Typography>
                    <Typography
                      color="textSecondary"
                      className={classes.videoHeadingTypo}
                    >
                      {countInsight
                        ? countInsight + " Added"
                        : "No data available"}
                    </Typography>
                    <Divider className={classes.candidateVideoDivider1} />
                    <>
                      {candidateDetails?.insight_videos.length > 0 ? (
                        <>
                          <Grid container>
                            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                              <video
                                preload="auto"
                                disablePictureInPicture
                                controls={true}
                                title={this.state.videoTitle}
                                id={"insightVideo"}
                                // width="100%"
                                height="300"
                                src={this.state.videoSrc}
                                frameBorder="0"
                                poster={this.state.poster}
                                // allow="accelerometer, autoplay; encrypted-media; gyroscope; picture-in-picture"
                                allow="accelerometer; encrypted-media; gyroscope;" //picture-in-picture
                                allowFullScreen={false}
                                controlsList="nodownload"
                                className={classes.mainVideo}
                              ></video>
                              <div>{this.state.videoTitle}</div>
                            </Grid>
                          </Grid>
                          <Divider className={classes.candidateVideoDivider2} />
                          <Grid container>
                            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                              {candidateDetails.insight_videos.map(
                                (video, index) => (
                                  <>
                                    {index !== 0 && (
                                      <Divider className={classes.videosList} />
                                    )}
                                    <Grid
                                      item
                                      xs={12}
                                      sm={12}
                                      md={12}
                                      lg={12}
                                      xl={12}
                                      style={{
                                        marginTop:
                                          index !== 0 ? "35px" : "35px",
                                        display: "flex",
                                        alignItems: "center",
                                        cursor: "pointer",
                                        border:
                                          this.state.selectedVideo === index
                                            ? "2px solid #FBC112"
                                            : "",
                                      }}
                                      onClick={() => {
                                        this.setState({
                                          poster: video?.thumbnail_url,
                                          videoTitle: video.topic,
                                          videoSrc: video.video_url,
                                          selectedVideo: index,
                                        });
                                      }}
                                    >
                                      <video
                                        className={classes.videoPadding}
                                        preload="auto"
                                        height={"200px"}
                                        disablePictureInPicture
                                        controls={false}
                                        title={video.topic}
                                        id={`insightVideo${index}`}
                                        width="30%"
                                        src={video.video_url}
                                        frameBorder="0"
                                        poster={video?.thumbnail_url}
                                        // allow="accelerometer, autoplay; encrypted-media; gyroscope; picture-in-picture"
                                        allow="accelerometer; encrypted-media; gyroscope;" //picture-in-picture
                                        allowFullScreen={false}
                                        controlsList="nodownload"
                                      ></video>
                                      <div className={classes.videoPadding}>
                                        <Typography
                                          variant="h6"
                                          className={classes.alignSelfCenter}
                                          align="left"
                                        >
                                          {video.topic}
                                        </Typography>
                                      </div>
                                    </Grid>
                                  </>
                                )
                              )}
                            </Grid>
                          </Grid>
                        </>
                      ) : (
                        <EmptyState
                          type={"candidateVideos"}
                          height={EMPTY_ICON_HEIGHT}
                          width={EMPTY_ICON_WIDTH}
                        />
                      )}
                    </>
                  </div>
                )}
                <CustomizedAccordions
                  leadership={candidateDetails?.insight_videos}
                  type={"leadership"}
                />
                {this.state.tabValue === 3 && (
                  <CustomizedAccordions
                    coffeQuestion={candidateDetails?.coffee_questions}
                    type={"coffee"}
                  />
                )}
                {this.state.tabValue === 1 && (
                  <CustomizedAccordions
                    experience={candidateDetails?.candidate_experience}
                    boardAdvisorExperience={
                      candidateDetails?.board_advisor_experience
                    }
                    type={"experience"}
                  />
                )}
                {this.state.tabValue === 2 && (
                  <>
                    <CustomizedAccordions
                      education={candidateDetails?.candidate_education}
                      type={"education"}
                    />
                    <CustomizedAccordions
                      certification={candidateDetails?.certifications}
                      type={"certification"}
                    />
                  </>
                )}
                {this.state.tabValue === 5 && (
                  <CustomizedAccordions
                    firstName={candidateDetails?.first_name}
                    lastName={candidateDetails?.last_name}
                    resume={
                      this.props?.members?.fullProfileData
                        ?.recruiter_matched_candidate_details?.resume
                    }
                    type={"resume"}
                  />
                )}
                {this.state.tabValue === 6 && (
                  <Typography>
                    <AdminNotes
                      notesValue={candidateDetails}
                      isFullProfile={true}
                    />
                  </Typography>
                )}
                <Dialog
                  // open={this.state.dislikePopupOpen}
                  aria-labelledby="alert-dialog-title"
                  aria-describedby="alert-dialog-description"
                  fullWidth
                  disableBackdropClick
                  disableEscapeKeyDown
                >
                  <DialogTitle
                    id="alert-dialog-title"
                    onClose={this.handleCloseArchivePopup}
                  >
                    Why are you passing on this candidate?
                  </DialogTitle>
                  <DialogContent className={classes.passingCanContent}>
                    <DialogContentText
                      id="alert-dialog-description"
                      className={classNames(classes.largeFont)}
                    >
                      <Typography variant="h6" color="textSecondary">
                        This information will not be shared with the candidate.
                      </Typography>
                      <FormControl
                        required
                        component="fieldset"
                        className={classNames(
                          classes.fullWidth,
                          classes.mediumTopMargin,
                          classes.colorDefault
                        )}
                      >
                        <RadioGroup
                          aria-label="remote"
                          name="remote"
                          value={this.state.archiveReason}
                          onChange={this.handleInputChange("archiveReason")}
                        >
                          <Grid container>
                            <Grid
                              item
                              xs={12}
                              sm={12}
                              md={12}
                              lg={6}
                              xl={6}
                              className={classes.displayFlex}
                            >
                              <FormControlLabel
                                control={<Radio />}
                                label="Experience not relevant"
                                value="Experience not relevant"
                              />
                            </Grid>
                            <Grid
                              item
                              xs={12}
                              sm={12}
                              md={12}
                              lg={6}
                              xl={6}
                              className={classes.displayFlex}
                            >
                              <FormControlLabel
                                control={<Radio />}
                                label="Competencies not relevant"
                                value="Competencies not relevant"
                              />
                            </Grid>
                            <Grid
                              item
                              xs={12}
                              sm={12}
                              md={12}
                              lg={6}
                              xl={6}
                              className={classes.displayFlex}
                            >
                              <FormControlLabel
                                control={<Radio />}
                                label="Industry not preferable"
                                value="Industry not preferable"
                              />
                            </Grid>
                            <Grid
                              item
                              xs={12}
                              sm={12}
                              md={12}
                              lg={6}
                              xl={6}
                              className={classes.displayFlex}
                            >
                              <FormControlLabel
                                control={<Radio />}
                                label="Location not preferable"
                                value="Location not preferable"
                              />
                            </Grid>
                            <Grid
                              item
                              xs={12}
                              sm={12}
                              md={12}
                              lg={6}
                              xl={6}
                              className={classes.displayFlex}
                            >
                              <FormControlLabel
                                value="Other"
                                control={<Radio />}
                                label="Other"
                              />
                            </Grid>
                          </Grid>
                        </RadioGroup>
                      </FormControl>
                      {this.state.archiveReason === "Other" ? (
                        <>
                          <TextField
                            fullWidth
                            required
                            label="Reason"
                            autoComplete="otherArchiveReason"
                            margin="normal"
                            id="otherArchiveReason"
                            name="otherArchiveReason"
                            value={this.state.otherArchiveReason}
                            onChange={this.handleInputChange(
                              "otherArchiveReason"
                            )}
                            placeholder="Reason for archiving"
                            error={this.state.isValidOtherArchiveReason}
                            helperText={
                              this.state.isValidOtherArchiveReason
                                ? "Min 25 characters required"
                                : "Max 100 characters"
                            }
                            inputProps={{ maxLength: reasonCharCount }}
                          />
                          <Typography
                            className={classNames(
                              classes.floatRight,
                              classes.smallFont,
                              classes.verySmallTopMargin
                            )}
                            color="secondary"
                          >
                            {this.state.otherArchiveReason?.length > 0
                              ? this.state.otherArchiveReason?.length +
                                "/" +
                                reasonCharCount
                              : 0 + "/" + reasonCharCount}{" "}
                            characters
                          </Typography>
                        </>
                      ) : null}
                    </DialogContentText>
                  </DialogContent>
                  <DialogActions className={classes.dailogActions}>
                    <Button
                      size="medium"
                      onClick={this.handleCloseArchivePopup}
                      variant="contained"
                      color="primary"
                      className={classes.ArchiveClose}
                      disableElevation
                    >
                      Cancel
                    </Button>
                    <Button
                      size="medium"
                      variant="contained"
                      color="primary"
                      className={classes.confirmButton}
                      disableElevation
                      disabled={
                        this.state.archiveReason === "Other" &&
                        this.state.isValidOtherArchiveReason
                      }
                      onClick={(event) => this.handleArchive(event)}
                    >
                      Remove from Queue
                    </Button>
                  </DialogActions>
                </Dialog>
                <Dialog
                  disableBackdropClick
                  disableEscapeKeyDown
                  aria-labelledby="customized-dialog-title"
                  open={this.state.open}
                >
                  <DialogTitle onClose={this.handleClose}>
                    <Typography
                      variant="h5"
                      className={classes.paddingShareCan}
                    >
                      Copy Profile Information
                    </Typography>
                    <Typography color="textSecondary">
                      Complete the information below to send an invitation to a
                      maximum of three people to view the candidate profile.
                    </Typography>
                  </DialogTitle>
                  <DialogContent>
                    {this.state.fields.map((field, idx) => {
                      return (
                        <div key={`${field}-${idx}`}>
                          <Grid
                            container
                            spacing={2}
                            className={classNames(
                              classes.customShareSection,
                              classes.mediumTopMargin,
                              classes.customBottomMargin
                            )}
                          >
                            {this.state.fields.length > 1 ? (
                              <Grid
                                item
                                xs={12}
                                sm={12}
                                md={12}
                                lg={12}
                                xl={12}
                              >
                                <FormControlLabel
                                  className={classes.floatRight}
                                  control={
                                    <IconButton
                                      className={classes.colorDefault}
                                      onClick={() => this.handleRemove(idx)}
                                    >
                                      <IndeterminateCheckBoxIcon />
                                    </IconButton>
                                  }
                                  label={<Typography>Remove</Typography>}
                                ></FormControlLabel>
                              </Grid>
                            ) : null}
                            <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
                              <TextField
                                autoFocus
                                fullWidth
                                label="First Name"
                                autoComplete="firstName"
                                margin="dense"
                                required
                                inputProps={{ maxLength: charCount }}
                                id="firstName"
                                name="firstName"
                                value={field.fNameValue || ""}
                                onChange={(e) => this.handleChange(idx, e)}
                                error={
                                  !field.isValidFirstName ||
                                  field.isFirstNameRequired
                                }
                                helperText={
                                  !field.isValidFirstName
                                    ? "min 2 characters required"
                                    : field.isFirstNameRequired
                                    ? MESSAGE_FIELD_IS_REQUIRED
                                    : ""
                                }
                              />
                              <Typography
                                className={classNames(
                                  classes.floatRight,
                                  classes.smallFont,
                                  classes.verySmallTopMargin
                                )}
                                color="secondary"
                              >
                                {this.state.fields[idx]?.fNameValue?.length +
                                  "/" +
                                  charCount}{" "}
                                characters
                              </Typography>
                            </Grid>
                            <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
                              <TextField
                                fullWidth
                                label="Last Name"
                                autoComplete="lastName"
                                margin="dense"
                                required
                                inputProps={{ maxLength: charCount }}
                                id="lastName"
                                name="lastName"
                                value={field.lNameValue || ""}
                                onChange={(e) => this.handleChange(idx, e)}
                                error={
                                  !field.isValidLastName ||
                                  field.isLastNameRequired
                                }
                                helperText={
                                  !field.isValidLastName
                                    ? "min 2 characters required"
                                    : field.isLastNameRequired
                                    ? MESSAGE_FIELD_IS_REQUIRED
                                    : ""
                                }
                              />
                              <Typography
                                className={classNames(
                                  classes.floatRight,
                                  classes.smallFont,
                                  classes.verySmallTopMargin
                                )}
                                color="secondary"
                              >
                                {this.state.fields[idx]?.lNameValue?.length +
                                  "/" +
                                  charCount}{" "}
                                characters
                              </Typography>
                            </Grid>
                            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                              <TextField
                                fullWidth
                                label="Title"
                                autoComplete="title"
                                margin="dense"
                                required
                                id="title"
                                name="title"
                                value={field.titleValue || ""}
                                onChange={(e) => this.handleChange(idx, e)}
                                inputProps={{ maxLength: reasonCharCount }}
                                error={
                                  !field.isValidTitle || field.isTitleRequired
                                }
                                helperText={
                                  !field.isValidTitle
                                    ? "min 2 characters required"
                                    : field.isTitleRequired
                                    ? MESSAGE_FIELD_IS_REQUIRED
                                    : ""
                                }
                              />
                              <Typography
                                className={classNames(
                                  classes.floatRight,
                                  classes.smallFont,
                                  classes.verySmallTopMargin
                                )}
                                color="secondary"
                              >
                                {this.state.fields[idx]?.titleValue?.length +
                                  "/" +
                                  reasonCharCount}{" "}
                                characters
                              </Typography>
                            </Grid>
                            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                              <TextField
                                fullWidth
                                label="Corporate Email Address"
                                autoComplete="email"
                                margin="dense"
                                required
                                id="email"
                                name="email"
                                value={field.emailValue || ""}
                                onChange={(e) => this.handleChange(idx, e)}
                                error={
                                  !field.isValidEmail || field.isEmailRequired
                                }
                                helperText={
                                  !field.isValidEmail
                                    ? "Invalid Email"
                                    : field.isEmailRequired
                                    ? MESSAGE_FIELD_IS_REQUIRED
                                    : ""
                                }
                              />
                            </Grid>
                          </Grid>
                        </div>
                      );
                    })}
                    {this.state.fields.length < 3 ? (
                      <FormControlLabel
                        control={
                          <IconButton onClick={() => this.handleAdd()}>
                            <AddBoxIcon className={classes.colorDefault} />
                          </IconButton>
                        }
                        label={<Typography>Add Another</Typography>}
                        className={classes.verySmallTopMargin}
                      ></FormControlLabel>
                    ) : null}
                    {this.state.fields.length === 3 ? (
                      <Typography color="textSecondary">
                        You can share member profile with a maximum of three
                        contacts at a time.
                      </Typography>
                    ) : null}
                  </DialogContent>
                  <DialogActions className={classes.dialogActions}>
                    <Button
                      variant="contained"
                      color="primary"
                      className={classes.submit}
                      disableElevation
                      size="large"
                      onClick={this.handleSend}
                    >
                      Send
                    </Button>
                  </DialogActions>
                </Dialog>
                {this.state.openScheduleInterview ? (
                  <>
                    {" "}
                    <SchedulingPopup
                      handlePreview={this.handlePreview}
                      closeScheduleInterview={this.closeScheduleInterview}
                      openScheduleInterview={this.state.openScheduleInterview}
                      scheduleInterviewType={this.state.scheduleInterviewType}
                    />{" "}
                  </>
                ) : null}
                {this.state.openPreview ? (
                  <PreviewEmaildailog
                    open={this.state.openPreview}
                    editPreview={this.editPreview}
                    onSend={this.sendEmail}
                    onClose={this.onClosePreview}
                  />
                ) : null}
                {this.state.commentPopup ? (
                  <Commentpopup
                    isOpen={this.state.commentPopup}
                    closeCandidateCommentsPopup={
                      this.closeCandidateCommentsPopup
                    }
                  />
                ) : null}
              </Card>
            </main>
          </Grid>
          <Grid
            item
            xs={12}
            sm={12}
            md={3}
            xl={3}
            lg={3}
            className={classes.cardActionGrid}
          >
            <Card
              id="sticky-card"
              elevation={3}
              className={
                window.pageYOffset >= sticky &&
                (this.props.shared.isNavbarVisible === undefined ||
                  this.props.shared.isNavbarVisible === false)
                  ? classes.stickyCard
                  : classes.stickyBarRemoveCard
              }
            >
              <CardHeader
                className={classes.actionCardHeader}
                title="Actions"
              />
              <CardContent>
                <List>
                  <PopupState variant="popover" popupId="demo-popup-menu" disab>
                    {(popupState) => (
                      <React.Fragment>
                        <div {...bindTrigger(popupState)}>
                          {!candidateDetails?.is_hired &&
                            candidateDetails?.new_recommendation_status ===
                              "In Conversation" &&
                            this.props?.shared?.accessPermission &&
                            this.props?.jobs.jobDetails?.job_status !==
                              "Close" &&
                            this.state.inConversationButton && (
                              <ListItem button>
                                <ListItemIcon>
                                  <RequestInterviewIcon />
                                </ListItemIcon>
                                <ListItemText primary="Request Interview" />
                              </ListItem>
                            )}
                        </div>
                        <Menu
                          {...bindMenu(popupState)}
                          anchorOrigin={{
                            vertical: "center",
                            horizontal: "left",
                          }}
                          transformOrigin={{
                            vertical: "top",
                            horizontal: "right",
                          }}
                        >
                          <MenuItem
                            onClick={() => {
                              popupState.close();
                              !secureLocalStorage.getItem("g_u")
                                ? this.sendScheduleSchedulingLinkCandidate(
                                    "email to candidate"
                                  )
                                : this.showWarning();
                              popupState.close();
                            }}
                          >
                            <ListItemText primary="Request candidate for interview" />
                          </MenuItem>
                          <MenuItem
                            onClick={() => {
                              popupState.close();
                              !secureLocalStorage.getItem("g_u")
                                ? this.sendScheduleSchedulingLinkCandidate(
                                    "email to coordinator"
                                  )
                                : this.showWarning();
                              popupState.close();
                            }}
                          >
                            <ListItemText primary="Send email to coordinator" />
                          </MenuItem>
                          <Divider />
                        </Menu>
                      </React.Fragment>
                    )}
                  </PopupState>
                  {candidateDetails?.job_type === "Normal" &&
                    this.props?.shared?.accessPermission &&
                    this.props?.jobs.jobDetails?.job_status !== "Close" &&
                    candidateDetails?.new_recommendation_status ===
                      "In Conversation" &&
                    this.state.inConversationButton && (
                      <ListItem
                        button
                        onClick={(event) => {
                          event.preventDefault();
                          !secureLocalStorage.getItem("g_u")
                            ? this.sendMessage()
                            : this.showWarning();
                        }}
                      >
                        <ListItemIcon>
                          <MessageIcon />
                        </ListItemIcon>
                        <ListItemText primary="Send a Message" />
                      </ListItem>
                    )}
                  {!candidateDetails?.is_hired &&
                    candidateDetails?.new_recommendation_status ===
                      "In Conversation" &&
                    this.props?.shared?.accessPermission &&
                    this.props?.jobs.jobDetails?.job_status !== "Close" &&
                    this.state.inConversationButton && (
                      <ListItem
                        button
                        onClick={(event) => {
                          event.preventDefault();
                          !secureLocalStorage.getItem("g_u")
                            ? this.handleOpenMarkedHired()
                            : this.showWarning();
                        }}
                      >
                        <ListItemIcon>
                          <MarkAsHiredIcon />
                        </ListItemIcon>
                        <ListItemText primary="Mark as Hired" />
                      </ListItem>
                    )}
                  <ListItem
                    button
                    onClick={(event) => {
                      this.handleCommentClick(event);
                    }}
                  >
                    <ListItemIcon>
                      <ChatIcon />
                    </ListItemIcon>
                    <ListItemText primary="Client Internal Notes" />
                  </ListItem>
                  <PopupState variant="popover" popupId="demo-popup-menu" disab>
                    {(popupState) => (
                      <React.Fragment>
                        <div {...bindTrigger(popupState)}>
                          <ListItem button>
                            <ListItemIcon>
                              <ShareIcon />
                            </ListItemIcon>
                            <ListItemText primary="Share Profile" />
                          </ListItem>
                        </div>
                        <Menu
                          {...bindMenu(popupState)}
                          anchorOrigin={{
                            vertical: "center",
                            horizontal: "left",
                          }}
                          transformOrigin={{
                            vertical: "top",
                            horizontal: "right",
                          }}
                        >
                          <MenuItem>
                            <ListItemText
                              primary="Copy Profile URL & Password"
                              onClick={(event) => {
                                this.handleShareProfileClick(event);
                                popupState.close();
                              }}
                            />
                          </MenuItem>
                          <MenuItem>
                            <ListItemText
                              onClick={this.downloadPdf}
                              primary="Download PDF"
                            />
                          </MenuItem>
                          <Divider />
                        </Menu>
                      </React.Fragment>
                    )}
                  </PopupState>

                  {!candidateDetails?.is_hired &&
                    this.props?.shared?.accessPermission &&
                    this.props?.jobs.jobDetails?.job_status !== "Close" &&
                    candidateDetails?.new_recommendation_status !==
                      "In Conversation" &&
                    candidateDetails?.new_recommendation_status !==
                      "Removed from Queue" &&
                    this.state.inConversationButton && (
                      <ListItem
                        button
                        onClick={this.onhandleOpenMoveCandidateConversation}
                      >
                        <ListItemIcon>
                          <MoveToInterviewIcon />
                        </ListItemIcon>
                        <ListItemText primary="Move to Interview Stage" />
                      </ListItem>
                    )}
                  {!candidateDetails?.is_hired &&
                    this.props?.shared?.accessPermission &&
                    this.props?.jobs.jobDetails?.job_status !== "Close" &&
                    candidateDetails?.new_recommendation_status !==
                      "Removed from Queue" &&
                    this.state.removefromQueueButton && (
                      <ListItem
                        button
                        onClick={(event) => {
                          event.preventDefault();
                          this.handleDislikeCandidateRecommendation(
                            this.props?.members?.fullProfileCandidateId
                          );
                        }}
                      >
                        <ListItemIcon>
                          <RemovedFromIcon />
                        </ListItemIcon>
                        <ListItemText primary="Remove from Queue" />
                      </ListItem>
                    )}
                </List>
              </CardContent>
            </Card>
          </Grid>
        </Grid>
        <MarkedHired
          open={this.state.openMarkedHired}
          onClose={() => this.onCloseMarkedHired()}
          makedHiredRecId={this.props.match.params.recommendationId}
          jobId={this.props.jobs?.jobDetails?.id}
        />
        {this.state.dislikePopupOpen && (
          <RemoveFromQueue
            open={this.state.dislikePopupOpen}
            close={this.handleCloseArchivePopup}
            recommendationId={this.props.match.params.recommendationId}
            jobId={this.props.jobs?.jobDetails?.id}
            redirectfrom={"full profile"}
            hideButtons={this.hideButtonsforRemovedCandidate}
          />
        )}
        <div className={classes.customPaddingRight}>
          <GetHelp />
        </div>
        {
          <MoveCandidateinConversation
            removeConversationButton={this.removeConversationButton}
            unmountRemoveFromQueueButton={this.unmountRemoveFromQueueButton}
            isFullProfile={true}
            candidatedId={this.props.match.params.id}
            recIdConversation={this.props.match.params.recommendationId}
            open={this.state.openMoveCandidateConversation}
            onClose={this.onhandleCloseMoveCandidateConversation}
          />
        }
      </div>
    );
  }
}

MemberFullProfile.propTypes = {
  classes: PropTypes.object.isRequired,
};

function mapStateToProps(state) {
  return state;
}

const connectedMemberFullProfilePage = withRouter(
  connect(mapStateToProps, null, null, {
    pure: false,
  })(withStyles(styles)(MemberFullProfile))
);

export { connectedMemberFullProfilePage as MemberFullProfile };
