import React, { useEffect } from "react";
import {
  Dialog,
  DialogContent,
  DialogTitle,
  Paper,
  Typography,
  DialogContentText,
  IconButton,
  Divider,
  Grid,
  makeStyles,
} from "@material-ui/core";
import classNames from "classnames";
import moment from "moment";
import { useDispatch, useSelector } from "react-redux";
import CloseIcon from "@material-ui/icons/Close";
import { capitalize } from "../../helpers";
import { viewHistory } from "../../actions/members.actions";
import { ViewMore } from "../core/viewmore";
import { LABELS } from "../../constants/constants";

const useStyles = makeStyles((theme) => ({
  colorDefault: {
    color: "#000000 !important",
  },
  verySmallTopMargin: {
    marginTop: "1%",
  },
  alignBaslineMiddle: { verticalAlign: "-webkit-baseline-middle" },
  verySmallMarginLeft: { marginLeft: "3px" },
  textAlignLeft: { textAlign: "left" },
  mediumTopMargin: {
    marginTop: "4%",
  },
  boldFont: { fontWeight: "bold" },
  customHighlightsPaper: {
    paddingLeft: "3%",
    paddingRight: "3%",
    paddingBottom: "0",
    boxShadow: "none",
    borderRadius: "10px",
    border: "2px solid #e0e0e0",
    overflowY: "auto",
  },
  smallBottomMargin: {
    marginBottom: "2%",
  },
  DialogTitle: {
    background: "#FBC112",
    fontSize: "28px",
    fontWeight: 700,
    textAlign: "center",
  },
  closeButton: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(1),
    padding: "0px",
    background: "#000000",
    color: "#FFFFFF",
    "&:hover": {
      background: "#000000",
      color: "#FFFFFF",
    },
  },
  mainTypo: {
    display: "flex",
    justifyContent: "flex-start",
    alignItems: "center",
  },
  historyList: {
    marginTop: "5%",
    marginBottom: "3%",
  },
}));
function ViewRecommendedhistoryPopup({ open, recommendationId, onClose }) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const historyData = useSelector((state) => state?.members?.historyData?.data);

  useEffect(() => {
    if (recommendationId) {
      dispatch(viewHistory(recommendationId));
    }
  }, [recommendationId, dispatch]);

  return (
    <Dialog
      open={open}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      fullWidth
      disableBackdropClick
      disableEscapeKeyDown
    >
      <DialogTitle className={classes.DialogTitle}>
        Candidate Recommendation History
      </DialogTitle>
      <IconButton
        aria-label="close"
        className={classes.closeButton}
        onClick={onClose}
      >
        <CloseIcon />
      </IconButton>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          <Grid container>
            <Typography
              align="left"
              className={classNames(
                classes.colorDefault,
                classes.smallTopMargin,
                classes.mainTypo
              )}
            >
              <b>Candidate Title:</b>&nbsp;
              {historyData?.candidate?.current_title}
            </Typography>
            <Typography
              color="textPrimary"
              align="left"
              className={classNames(classes.smallTopMargin, classes.mainTypo)}
            >
              <b> Candidate Location: </b> &nbsp;
              <span
                className={classNames(
                  classes.alignBaslineMiddle,
                  classes.verySmallMarginLeft
                )}
              >
                {historyData?.candidate?.location?.city
                  ? historyData?.candidate?.location?.city
                  : ""}
                {historyData?.candidate?.location?.state
                  ? `, ${historyData?.candidate?.location?.state}`
                  : ""}
                {historyData?.candidate?.location?.country
                  ? `, ${historyData?.candidate?.location?.country}`
                  : ""}

                {!historyData?.candidate?.location?.country &&
                !historyData?.candidate?.location?.country &&
                !historyData?.candidate?.location?.country
                  ? "-"
                  : ""}
              </span>
            </Typography>
          </Grid>
          <Grid container className={classes.smallTopMargin}>
            <Typography
              className={classNames(
                classes.colorDefault,
                classes.textAlignLeft
              )}
            >
              <b>Client:</b> {capitalize(historyData?.candidate?.client)}
            </Typography>
          </Grid>

          <Divider className={classes.mediumTopMargin} />
          {historyData?.candidate?.status?.length > 0 ? (
            <div>
              <Grid container className={classes.mediumTopMargin}>
                <Grid item xs={8} sm={4} md={4} lg={4} xl={4}>
                  <Typography
                    align="left"
                    className={classNames(
                      classes.colorDefault,
                      classes.boldFont
                    )}
                  >
                    Stage
                  </Typography>
                </Grid>
                <Grid item xs={4} sm={4} md={4} lg={4} xl={4}>
                  <Typography
                    align="left"
                    className={classNames(
                      classes.colorDefault,
                      classes.boldFont
                    )}
                  >
                    Date
                  </Typography>
                </Grid>
                <Grid item xs={4} sm={4} md={4} lg={4} xl={4}>
                  <Typography
                    align="left"
                    className={classNames(
                      classes.colorDefault,
                      classes.boldFont
                    )}
                  >
                    Comment
                  </Typography>
                </Grid>
              </Grid>
              <Paper
                className={classNames(
                  classes.customHighlightsPaper,
                  classes.mediumTopMargin
                )}
              >
                {historyData?.candidate?.status.map((item, index) => (
                  <div>
                    <Grid container className={classes.historyList}>
                      <Grid item xs={4} sm={4} md={4} lg={4} xl={4}>
                        <Typography
                          className={classes.smallBottomMargin}
                          color="textSecondary"
                        >
                          {item?.label}:{" "}
                        </Typography>
                        <Typography
                          component="div"
                          className={classes.smallBottomMargin}
                          color="textSecondary"
                        >
                          {item?.action ? item?.action : null}{" "}
                        </Typography>
                      </Grid>
                      <Grid item xs={4} sm={4} md={4} lg={4} xl={4}>
                        <Typography
                          className={classNames(
                            classes.smallBottomMargin,
                            classes.colorDefault
                          )}
                        >
                          {moment(item?.completed_on).format("MMM DD, YYYY")}
                        </Typography>
                      </Grid>
                      <Grid item xs={4} sm={4} md={4} lg={4} xl={4}>
                        <Typography
                          className={classNames(
                            classes.smallBottomMargin,
                            classes.colorDefault
                          )}
                        >
                          {typeof item.comment === "object" && item.comment ? (
                            <ol>
                              {item.comment.experience_issue && (
                                <li>
                                  {
                                    LABELS.REMOVE_FROM_QUEUE
                                      .EXPERIENCE_NOT_RELEVANT
                                  }
                                  <ViewMore length={100}>
                                    {` : ${item.comment.experience_issue}`}
                                  </ViewMore>
                                </li>
                              )}
                              {item.comment.industry_issue && (
                                <li>
                                  {
                                    LABELS.REMOVE_FROM_QUEUE
                                      .INDUSTRY_NOT_ALIGNED
                                  }
                                  <ViewMore length={100}>
                                    {` : ${item.comment.industry_issue}`}
                                  </ViewMore>
                                </li>
                              )}
                              {item.comment.other_feedback && (
                                <li>
                                  {LABELS.REMOVE_FROM_QUEUE.OTHER}
                                  <ViewMore length={100}>
                                    {` : ${item.comment.other_feedback}`}
                                  </ViewMore>
                                </li>
                              )}
                            </ol>
                          ) : (
                            <ViewMore length={100}>
                              {item.comment ? item.comment : "-"}
                            </ViewMore>
                          )}
                        </Typography>
                      </Grid>
                    </Grid>
                    {index !== historyData?.candidate?.status?.length - 1 ? (
                      <Divider />
                    ) : null}
                  </div>
                ))}
              </Paper>
            </div>
          ) : (
            <Typography
              align="center"
              className={classes.mediumTopMargin}
              color="textSecondary"
            >
              No history available
            </Typography>
          )}
        </DialogContentText>
      </DialogContent>
    </Dialog>
  );
}

export default ViewRecommendedhistoryPopup;
