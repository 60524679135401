export const loading = (bool) =>
  bool
    ? {
        type: "SHOW_LOADER",
        data: bool,
      }
    : {
        type: "HIDE_LOADER",
        data: bool,
      };
