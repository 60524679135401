import {
  Dialog,
  DialogContent,
  DialogContentText,
  DialogTitle,
  IconButton,
  DialogActions,
  Button,
} from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import CloseIcon from "@material-ui/icons/Close";
import { useDispatch, useSelector } from "react-redux";
import { isHired } from "../../actions";
import { jobsActions } from "../../actions/jobs.actions";

const useStyles = makeStyles((theme) => ({
  DialogTitle: {
    background: "#FBC112",
    fontSize: "28px",
    fontWeight: 700,
    textAlign: "center",
  },
  dailogText: {
    textAlign: "center",
    fontSize: "16px",
    fontWeight: 400,
  },
  dailogActions: {
    display: "flex",
    justifyContent: "center",
    marginBottom: "15px",
  },
  confirmButton: {
    background: "#000000",
    color: "#FFFFFF",
  },
  closeButton: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(1),
    padding: "0px",
    color: "#000000",
  },
}));

function MarkedHired({ open, onClose, makedHiredRecId, jobId }) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const headerCardValue = useSelector(
    (state) => state.jobs.allUpdateHeaderCard
  );

  const [renderApis, setRenderApi] = useState(false);

  const handleConfirmButton = () => {
    const payload = {
      is_hired: true,
    };
    isHired(makedHiredRecId, payload);
    setRenderApi(true);
  };

  useEffect(() => {
    if (renderApis) {
      autoClosePopup();
    }
  }, [renderApis]);

  const autoClosePopup = () => {
    onClose();
    setTimeout(() => {
      switch (headerCardValue) {
        case "All":
          dispatch(jobsActions.getAllUpdatesData(jobId, "All"));
          break;
        case "1":
          dispatch(jobsActions.getAllUpdatesData(jobId, "Recommended"));
          break;
        case "2":
          if (window.location.pathname.match("^/members.*$")) {
            window.location.reload();
          }
          dispatch(jobsActions.getAllUpdatesData(jobId, "Matched"));
          break;
        case "3":
          if (window.location.pathname.match("^/members.*$")) {
            window.location.reload();
          }
          dispatch(jobsActions.getAllUpdatesData(jobId, "Conversation"));
          break;
        default:
          break;
      }
    }, 1500);
  };

  return (
    <Dialog open={open}>
      <DialogTitle className={classes.DialogTitle}>Confirm hired?</DialogTitle>
      <IconButton
        aria-label="close"
        className={classes.closeButton}
        onClick={onClose}
      >
        <CloseIcon />
      </IconButton>
      <DialogContent>
        <DialogContentText className={classes.dailogText}>
          Please confirm if you want to mark this candidate as hired?{" "}
        </DialogContentText>
      </DialogContent>
      <DialogActions className={classes.dailogActions}>
        <Button onClick={onClose}>Cancel</Button>
        <Button
          disableElevation
          className={classes.confirmButton}
          onClick={handleConfirmButton}
          variant="contained"
        >
          Yes, Confirm!
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default MarkedHired;
