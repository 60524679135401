import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Modal from "@material-ui/core/Modal";
import Backdrop from "@material-ui/core/Backdrop";
import Fade from "@material-ui/core/Fade";
import { useSelector, useDispatch } from "react-redux";
import { Button } from "@material-ui/core";
import { ReactComponent as RightArrowIcon } from "../../assets/RightArrowIcon.svg";
import { WelcomePopup } from "../../actions";

const useStyles = makeStyles(() => ({
  paper: {
    width: "100%",
    backgroundColor: "#FBC112ed",
    position: "absolute",
    top: 0,
    padding: "20px",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
  },
  ButtonStyle: {
    background: "#000000",
    color: "#ffffff",
    "&:hover": {
      background: "#000000",
      color: "#ffffff",
    },
  },
}));

export default function WelcomeModal() {
  const classes = useStyles();
  const dispatch = useDispatch();
  const RecruiterDeatils = useSelector(
    (state) => state.authentication.recruiterStatusDetails
  );
  const welcomePopup = useSelector(
    (state) => state.authentication.welcomePopup
  );

  const handleClose = () => {
    dispatch(WelcomePopup(false));
  };

  const handleContinue = () => {
    dispatch(WelcomePopup(false));
  };

  return (
    <div>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className={classes.modal}
        open={welcomePopup}
        onClose={handleClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={welcomePopup}>
          <div className={classes.paper}>
            <span>
              <h1>{`Hello ${RecruiterDeatils?.first_name} ${RecruiterDeatils?.last_name}, Welcome to SucceedSmart!`}</h1>
            </span>
            <span>
              <Button
                onClick={handleContinue}
                endIcon={<RightArrowIcon />}
                className={classes.ButtonStyle}
                size="large"
                variant="contained"
              >
                Continue
              </Button>
            </span>
          </div>
        </Fade>
      </Modal>
    </div>
  );
}
