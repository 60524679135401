import React, { Component, Fragment } from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import {
  Grid,
  Button,
  Tab,
  Tabs,
  Typography,
  Dialog,
  DialogContentText,
  TextField,
  InputLabel,
  Select,
  MenuItem,
  FormControl,
  Radio,
  RadioGroup,
  FormControlLabel,
  FormLabel,
  Chip,
  Card,
  CardContent,
  Avatar,
  CircularProgress,
  FormHelperText,
  Slider,
  styled,
  Popover,
  Box,
  Checkbox,
} from "@material-ui/core";
import { ReactComponent as NoDataIcon } from "../../../assets/noDataIcon.svg";
import MuiDialogTitle from "@material-ui/core/DialogTitle";
import FilterListIcon from "@material-ui/icons/FilterList";
import MuiDialogContent from "@material-ui/core/DialogContent";
import MuiDialogActions from "@material-ui/core/DialogActions";
import IconButton from "@material-ui/core/IconButton";
import Autocomplete from "@material-ui/lab/Autocomplete";
import CloseIcon from "@material-ui/icons/Close";
import validator from "validator";
import classNames from "classnames";
import debounce from "lodash/debounce";
import cardHeaderBg from "../../../assets/lite_profile_bg.png";
import {
  jobsActions,
  sharedActions,
  loading,
  setAllUpdateHeaderCard,
} from "../../../actions";
import { toast } from "react-toastify";
import { GoogleMaps } from "../../core/googleautocomplete";
import { MESSAGE_FIELD_IS_REQUIRED } from "../../../constants/constants";
import {
  notAllowWhiteSpaceAtStart,
  trim,
  getBrowserVisibilityProp,
  getIsDocumentHidden,
} from "../../../helpers";
import GetHelp from "../../core/gethelp";
import { ViewMore } from "../../core/viewmore";
import { history, seo } from "../../../helpers";
import { setTitle } from "../../../actions";
import { EditorState, convertToRaw, Modifier } from "draft-js";
import { Editor } from "react-draft-wysiwyg";
import draftToHtml from "draftjs-to-html";
import HelpIcon from "@material-ui/icons//Help";
import PopupState, { bindTrigger, bindPopover } from "material-ui-popup-state";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import OpenJobCloseJob from "../../customComponents/openJobCloseJob";
import WelcomeModal from "../../core/welcomeModal";
import secureLocalStorage from "react-secure-storage";

// const filter = createFilterOptions();

const styles = (theme) => ({
  root: {
    width: "100%",
    marginTop: "90px",
  },
  paper: {
    border: "1px solid",
    padding: theme.spacing(1),
    backgroundColor: theme.palette.background.paper,
  },
  radioCheckBox: {
    color: "#048475",
  },
  customHeader: {
    fontSize: "15px",
    fontWeight: 600,
  },
  closeButton: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.default.main,
    padding: "0px",
  },
  appFrame: {
    zIndex: 1,
    overflow: "hidden",
    position: "relative",
    padding: theme.spacing(0, 6, 0, 6),
    [theme.breakpoints.down("md")]: {
      padding: theme.spacing(2, 2),
    },
    [theme.breakpoints.down("sm")]: {
      padding: theme.spacing(2, 2),
    },
  },
  largerFont: { fontSize: "larger" },
  content: {
    flexGrow: 1,
    marginTop: "0.2%",
    padding: theme.spacing(0, 6),
    [theme.breakpoints.down("md")]: {
      padding: theme.spacing(0, 2),
    },
    [theme.breakpoints.down("sm")]: {
      padding: theme.spacing(0, 2),
    },
  },
  bullet: {
    display: "inline-block",
    margin: "0 2px",
    transform: "scale(0.8)",
  },
  title: {
    fontSize: 14,
  },
  pos: {
    marginBottom: 12,
  },
  largeAvatar: {
    width: theme.spacing(7),
    height: theme.spacing(7),
  },
  xLargeAvatar: {
    width: theme.spacing(12),
    height: theme.spacing(12),
  },
  moduleConfig: {
    float: "left",
    fontSize: "26px",
  },
  demo1: {
    marginTop: "5%",
  },
  floatRight: {
    float: "right",
  },
  largeTopMargin: {
    marginTop: "10%",
  },
  verySmallTopMargin: {
    marginTop: "1em",
  },
  customSmallTopMargin: {
    marginTop: "2%",
  },
  formControl: {
    margin: theme.spacing(2, 0),
    minWidth: "100%",
  },
  customFormControl: {
    marginBottom: "1.7%",
    minWidth: "100%",
  },
  customTopBottomMargin: {
    marginTop: "1.5%",
    marginBottom: "1.7%",
    minWidth: "100%",
  },
  customVerySmallTopMargin: {
    marginTop: "1%",
  },
  mediumTopMargin: {
    marginTop: "4%",
  },
  displayFlex: {
    display: "flex",
    alignItems: "center",
  },
  customDisplayFlex: {
    display: "flex",
    alignItems: "center",
    paddingBottom: "3px",
    [theme.breakpoints.down("xs")]: {
      justifyContent: "space-evenly",
    },
  },

  customDisplayFlexForPermissions: {
    display: "flex",
    alignItems: "center",
    paddingBottom: "3px",
    [theme.breakpoints.down("xs")]: {
      alignItems: "flex-start",
    },
  },
  customDisplayFlexForLocation: {
    display: "flex",
    alignItems: "center",
    paddingBottom: "3px",
  },
  noPadding: {
    padding: "0 !important",
  },
  fullWidth: {
    width: "100%",
  },
  colorDefault: {
    color: "#000000",
  },
  dialogTitle: {
    color: "#FBC112",
  },
  displayInline: {
    display: "inline",
  },
  noBottomMargin: {
    marginBottom: "0 !important",
  },
  smallTopMargin: {
    marginTop: "3%",
  },
  chip: {
    margin: theme.spacing(0.5),
  },
  input: {
    marginLeft: theme.spacing(1),
    flex: 1,
  },
  iconButton: {
    padding: 10,
  },
  noWrap: {
    whiteSpace: "nowrap",
  },
  subHeader: {
    fontSize: "large",
    marginTop: "4%",
    fontWeight: "600",
    marginBottom: "1%",
    color: "#000000",
  },
  header: {
    fontSize: "x-large",
    marginTop: "4%",
    fontWeight: "600",
    color: "#000000",
  },
  jobCardHeader: {
    fontSize: "x-large",
    fontWeight: "500",
    color: "#000000",
    textAlign: "left",
  },
  jobCardSubHeader: {
    fontSize: "medium",
    fontWeight: "500",
    textAlign: "left",
  },
  customCard: {
    backgroundColor: "#F7F7F7",
    borderRadius: "10px",
  },
  customCardShadow: {
    boxShadow: "none",
  },
  customEditButton: {
    backgroundColor: "#FFFFFF",
    border: "#000000 solid 1px",
    marginRight: "1%",
  },
  textAlignEnd: {
    textAlign: "end",
  },
  jobCard: {
    boxShadow:
      "0px 0px 0px 1px rgb(0 0 0 / 5%), 0px 0px 0px 0px rgb(0 0 0 / 5%), 0px 0px 0px 1px rgb(0 0 0 / 5%)",
    borderRadius: "5px",
    cursor: "pointer",
    backgroundColor: "#F8F6EF",
  },
  smallBottomMargin: {
    marginBottom: "3%",
  },
  verySmallBottomMargin: {
    marginBottom: "1em",
  },
  mediumBottomMargin: {
    marginBottom: "5%",
  },
  customBadge: {
    cursor: "pointer",
    height: "50px",
    minWidth: "50px",
    borderRadius: "10px",
    fontSize: "large",
    right: "unset",
    transform: "unset",
    position: "unset",
    fontWeight: "bold",
    backgroundColor: "#FBC112",
  },
  customBadgefor0: {
    cursor: "pointer",
    height: "50px",
    minWidth: "50px",
    borderRadius: "10px",
    fontSize: "large",
    right: "unset",
    transform: "unset",
    position: "unset",
    fontWeight: "bold",
    backgroundColor: "#ffffff",
  },
  customChip: {
    backgroundColor: "#048475",
    color: "#FFFFFF",
    width: "100px",
    [theme.breakpoints.down("xs")]: {
      marginTop: "5px",
    },
  },
  smallFont: {
    fontSize: "14px",
  },
  customBadgeGrid: {
    textAlign: "center",
    overflowWrap: "break-word",
    padding: "2%",
  },
  verySmallMarginRight: { marginRight: "2%" },
  customDialogHeader: {
    maxHeight: "38%",
    height: "38%",
    color: "#000",
    backgroundSize: "cover",
    backgroundImage: `url(${cardHeaderBg})`,
    background: "round",
  },
  noImageAvatar: {
    color: "#FFFFFF",
    fontSize: "large",
    backgroundColor: "#000000",
  },
  noDataNote: {
    height: "calc(100vh - 270px)",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  salaryOption: {
    fontWeight: 600,
    marginBottom: "16px",
  },
  salaryHelpText: {
    padding: "8px",
    fontWeight: 500,
  },
  typoFilters: {
    display: "flex",
    alignItems: "center",
    marginRight: "3px",
  },
  filterStyle: {
    width: "100%",
    "& :hover": {
      cursor: "pointer",
    },
  },
  filterMainGrid: {
    borderBottom: "1px solid #ebebeb",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-around",
  },
});

const iOSBoxShadow =
  "0 3px 1px rgba(0,0,0,0.1),0 4px 8px rgba(0,0,0,0.13),0 0 0 1px rgba(0,0,0,0.02)";

const SalarySlider = styled(Slider)(({ theme }) => ({
  color: "#FBC112",
  width: "100%",
  [theme.breakpoints.between("sm", "md")]: {
    width: "85%",
  },
  [theme.breakpoints.between("xs", "sm")]: {
    width: "70%",
  },
  padding: "10px 0",
  "& .MuiSlider-thumb": {
    height: 22,
    width: 22,
    backgroundColor: "#048475",
    boxShadow: iOSBoxShadow,
    "&:focus, &:hover, &.Mui-active": {
      boxShadow:
        "0 3px 1px rgba(0,0,0,0.1),0 4px 8px rgba(0,0,0,0.3),0 0 0 1px rgba(0,0,0,0.02)",
      "@media (hover: none)": {
        boxShadow: iOSBoxShadow,
      },
    },
  },
  "& .MuiSlider-valueLabel": {
    fontSize: 12,
    fontWeight: "normal",
    top: -20,
    backgroundColor: "unset",
    color: theme.palette.text.primary,
    "&:before": {
      display: "none",
    },
    "& *": {
      background: "transparent",
      color: theme.palette.mode === "dark" ? "#fff" : "#000",
    },
  },
  "& .MuiSlider-IOSSlider": {
    top: -10,
  },
  "& .MuiSlider-track": {
    height: 10,
    border: "none",
    borderRadius: "10px",
  },
  "& .MuiSlider-rail": {
    height: 10,
    opacity: 0.5,
    backgroundColor: "#bfbfbf",
    borderRadius: "10px",
  },
  "& .MuiSlider-mark": {
    backgroundColor: "#bfbfbf",
    height: 15,
    width: 2,
    "& .MuiSlider-markActive": {
      opacity: 1,
      backgroundColor: "currentColor",
    },
  },
  "& .MuiSlider-markLabel": {
    [theme.breakpoints.down("md")]: {
      transform: "rotateZ(45deg) !important",
    },
    [theme.breakpoints.down("sm")]: {
      transform: "rotateZ(45deg) !important",
    },
  },
}));

const Pop = (props) => {
  const { anchorEl, ...rest } = props;
  const bound = anchorEl.getBoundingClientRect();
  return (
    <div
      {...rest}
      style={{
        position: "absolute",
        zIndex: 99999,
        width: bound.width,
      }}
    />
  );
};

const AntTabs = withStyles(() => ({
  root: {
    borderBottom: "1px solid #e8e8e8",
  },
  indicator: {
    backgroundColor: "#FBC112",
  },
}))(Tabs);

const AntTab = withStyles((theme) => ({
  root: {
    textTransform: "none",
    minWidth: 72,
    fontWeight: theme.typography.fontWeightRegular,
    marginRight: theme.spacing(4),
    "&:hover": {
      color: theme.palette.secondary,
      opacity: 1,
    },
    "&$selected": {
      color: theme.palette.secondary,
      fontWeight: theme.typography.fontWeightMedium,
    },
    "&:focus": {
      color: theme.palette.secondary,
    },
  },
}))((props) => <Tab disableRipple {...props} />);

const DialogTitle = withStyles(styles)((props) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle
      disableTypography
      className={classes.customDialogHeader}
      style={{ padding: "3%" }}
      {...other}
    >
      <Typography variant="h5" align="center">
        {children}
      </Typography>
      {onClose ? (
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={onClose}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});
const DialogContent = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
    paddingLeft: theme.spacing(6),
    paddingRight: theme.spacing(6),
    [theme.breakpoints.down("md")]: {
      padding: theme.spacing(2),
    },
    [theme.breakpoints.down("sm")]: {
      padding: theme.spacing(2),
    },
  },
}))(MuiDialogContent);

const DialogActions = withStyles((theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
    backgroundColor: "#fff",
    justifyContent: "center !important",
  },
}))(MuiDialogActions);

let charCount = 5000;
let descriptionCharCount = 5000;
let reportsToCount = 100;
let customFieldCount = 1000;
let jobTitles = [];
let reportsToTitles = [];
let majors = [];
let certifications = [];
let receivedSkills = [];
let startupStage = [];
let startupSize = [];
let teamSize = [];
let companyNames = [];

class Jobs extends Component {
  constructor(props) {
    super(props);
    this.autocompleteInput = React.createRef();
    this.autocomplete = null;
    this.handlePlaceChanged = this.handlePlaceChanged.bind(this);
    this.state = this.getInitialState();
    this.state = {
      visibility: getIsDocumentHidden(),
    };
    const onVisibilityChange = () => {
      this.setState({ visibility: getIsDocumentHidden() });
    };
    this.onTabChange = onVisibilityChange;
  }

  getInitialState = () => ({
    companyNameValueInput: "",
    editorStateAboutUs: EditorState.createEmpty(),
    editorStateJobDescription: EditorState.createEmpty(),
    editorcandidateWillBring: EditorState.createEmpty(),
    additionresponsibilities: EditorState.createEmpty(),
    editerAbsoluteMustHave: EditorState.createEmpty(),
    value: this.props?.shared?.jobsPageTab || 0,
    tabValue: 0,
    page: this.props?.shared?.jobsPagePaginationPage || 1,
    pageForClose: this.props?.shared?.closedJobPageNo || 1,
    open: false,
    openPreview: false,
    openOptions: false,
    openReportsToOptions: false,
    openMajorOptions: false,
    openSkillsOptions: false,
    openCertificationOptions: false,
    openLocationOptions: false,
    jobTitleOptions: [],
    majorOptions: [],
    certificationsOptions: [],
    reportsToTitleOptions: [],
    skillsOptions: [],
    locationOptions: [],
    scroll: "paper",
    jobTitle: "",
    jobTitleValue: "",
    majorValue: "",
    reportsToTitleValue: "",
    certificationsValue: "",
    jobLevel: "",
    reportsTo: "",
    industry: "",
    functionName: "",
    isValidOtherFunctionName: true,
    location: "",
    locationData: "",
    remote: "",
    travelLevel: "",
    skills: [],
    aboutUs: "",
    aboutUsWithHTML: "",
    jobDescription: "",
    jobDescriptionWithHTML: "",
    Additionresponsibilities: "",
    absolueMustHave: "",
    Successfulcandidatewillbring: "",
    additionResponsibilitesWithHTML: "",
    absolueMustHavehHTML: "",
    willSuccessfullCandidateBringHTML: "",
    howSoonToHire: "",
    isValidJobTitle: true,
    isValidReportsToName: true,
    isValidIndustry: true,
    isValidLocation: true,
    degree: "",
    degreeRequiredOrPreferred: "",
    certifications: [],
    minimumBaseSalary: null,
    minimumExperience: "",
    jobTitleLoading: false,
    reportsToTitlesLoading: false,
    skillsLoading: false,
    isJobTitleRequire: false,
    isJobLevelRequire: false,
    isReportsToRequire: false,
    isHiringManagerNameRequire: false,
    isIndustryRequire: false,
    isFunctionRequire: false,
    isLocationRequire: false,
    isYearOfExpRequire: false,
    isSalaryRequired: false,
    isDegreeRequire: false,
    isCertificationsRequire: false,
    isRemoteOnSiteRequire: false,
    isDegreeRequiredOrPreferredRequire: false,
    isTravelRequirementRequire: false,
    isAboutUsRequire: false,
    isPositionDescriptionRequire: false,
    isAdditionResponsibilitesRequire: false,
    isAdditionResponsibilitesDescription: true,
    isWillSuccessfullCandidateBringRequired: false,
    isWillSuccessfullCandidateBringDescription: true,
    isValidisAbosultMustnDescription: true,
    isAbosultMustRequire: false,
    isSkillsRequire: false,
    isSoonLookingToHireRequire: false,
    isBenefitAndPerkRequire: false,
    isValidAboutUs: true,
    isValidPositionDescription: true,
    isValidAdditionresponsibilities: true,
    isValidCandidateWillBring: true,
    isValidAbsoluteMustHaves: true,
    customJobTitlePopUp: false,
    customJobTitle: "",
    customCertificationTitlePopUp: false,
    customCertificationTitle: "",
    skillCustomPopUp: false,
    skillCustomValue: "",
    otherFunctionName: "",
    customMajorPopUp: false,
    customMajorValue: "",
    isValidSkill: true,
    salary_type: "",
    salary_config: "",
    teamSize: [],
    startupStage: [],
    startupSize: [],
    role: "",
    companyOptions: [],
    companyLoading: false,
    sampleCompanyValues: null,
    functionFilterValue: "",
    filterOpen: false,
  });

  callJobsListingApis = (value) => {
    const { dispatch } = this.props;
    dispatch(sharedActions.jobsPagePaginationPageChange(1));
    dispatch(sharedActions.closedJobsPage(1));
    this.setState({
      value: this.props?.shared?.jobsPageTab,
      page: this.props?.shared?.jobsPagePaginationPage,
      pageForClose: this.props.shared.closedJobPageNo,
    });
    switch (value) {
      case "/jobs/my-jobs":
        dispatch(
          jobsActions.getOpenJobList(
            JSON.parse(secureLocalStorage.getItem("recruiterStatusDetails"))
              .recruiter_id,
            this.props?.shared?.functionFilter
          )
        );
        dispatch(
          jobsActions.getClosedJobList(
            JSON.parse(secureLocalStorage.getItem("recruiterStatusDetails"))
              .recruiter_id,
            this.props?.shared?.functionFilter
          )
        );
        return;
      case "/jobs/shared-jobs":
        dispatch(
          jobsActions.getOpenSharedJobList(this.props?.shared?.functionFilter)
        );
        dispatch(
          jobsActions.getClosedSharedJobList(this.props?.shared?.functionFilter)
        );
        return;
      default:
        return;
    }
  };

  componentDidMount() {
    const { pageTitle } = this.props.pageTitle;
    const { dispatch } = this.props;
    const { selectedNavbarIndex, jobsPageTab, jobsPagePaginationPage } =
      this.props.shared;
    document.body.scrollTop = 0; // For Safari
    document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE and Opera

    // this commented code is kept for the refference
    // this.listen = history.listen((location) => {
    //     this.callJobsListingApis(location.pathname);
    // });

    // this.callJobsListingApis(window.location.pathname);
    // dispatch(setAllUpdateHeaderCard('All'));

    dispatch(
      sharedActions.jobsPageTabChange(jobsPageTab === undefined ? 1 : 0)
    );
    this.setState({ value: 0 });
    dispatch(
      sharedActions.jobsPagePaginationPageChange(
        jobsPagePaginationPage ? jobsPagePaginationPage : 1
      )
    );
    switch (jobsPageTab) {
      case 0:
        dispatch(
          setTitle(
            `${`Jobs`} ${`-`} ${
              selectedNavbarIndex === 1 ? " My Jobs" : "Shared Jobs"
            } ${`-`} ${`Open Positions`}`
          )
        );
        break;
      case 1:
        dispatch(
          setTitle(
            `${`Jobs`} ${`-`} ${
              selectedNavbarIndex === 1 ? " My Jobs" : "Shared Jobs"
            } ${`-`} ${`Closed Postions`}`
          )
        );
        break;
      default:
        break;
    }
    const visibilityChange = getBrowserVisibilityProp();
    document.addEventListener(visibilityChange, this.onTabChange, false);
    if (this.state.visibility) {
      this.onFocus();
    }

    seo({
      title: `${pageTitle}`,
    });
  }

  onFocus = () => {
    const { dispatch } = this.props;
    this.listen = history.listen((location) => {
      this.callJobsListingApis(location.pathname);
    });

    this.callJobsListingApis(window.location.pathname);
    dispatch(setAllUpdateHeaderCard("All"));
  };

  componentDidUpdate(prevProps, prevState) {
    const { dispatch } = this.props;
    const { pageTitle } = this.props.pageTitle;
    const { selectedNavbarIndex, jobsPageTab } = this.props.shared;
    if (prevProps.jobs.jobTitles !== this.props.jobs.jobTitles) {
      jobTitles = this.props.jobs.jobTitles;
      this.setState({ jobTitleOptions: jobTitles, jobTitleLoading: false });
    }

    if (prevProps.jobs.reportsToTitles !== this.props.jobs.reportsToTitles) {
      reportsToTitles = this.props.jobs.reportsToTitles;
      this.setState({
        reportsToTitleOptions: reportsToTitles,
        reportsToTitlesLoading: false,
      });
    }

    if (prevProps.jobs.majors !== this.props.jobs.majors) {
      majors = this.props.jobs.majors;
      this.setState({ majorOptions: majors, majorLoading: false });
    }

    if (prevProps.jobs.certifications !== this.props.jobs.certifications) {
      certifications = this.props.jobs.certifications;
      this.setState({
        certificationsOptions: certifications,
        certificationsLoading: false,
      });
    }

    if (prevProps.jobs.skills !== this.props.jobs.skills) {
      receivedSkills = this.props.jobs.skills;
      this.setState({ skillsOptions: receivedSkills, skillsLoading: false });
    }

    if (prevProps.jobs.companyNames !== this.props.jobs.companyNames) {
      companyNames = this.props.jobs.companyNames;
      this.setState({ companyOptions: companyNames, companyLoading: false });
    }

    if (
      prevProps.shared.selectedNavbarIndex !==
      this.props.shared.selectedNavbarIndex
    ) {
      switch (jobsPageTab) {
        case 0:
          dispatch(
            setTitle(
              `${`Jobs`} ${`-`} ${
                selectedNavbarIndex === 1 ? " My Jobs" : "Shared Jobs"
              } ${`-`} ${`Open Positions`}`
            )
          );
          break;
        case 1:
          dispatch(
            setTitle(
              `${`Jobs`} ${`-`} ${
                selectedNavbarIndex === 1 ? " My Jobs" : "Shared Jobs"
              } ${`-`} ${`Closed Postions`}`
            )
          );
          break;
        default:
          break;
      }
    }
    if (
      prevProps.shared.jobsPagePaginationPage !==
      this.props?.shared?.jobsPagePaginationPage
    ) {
      this.setState({ page: this.props?.shared?.jobsPagePaginationPage });
    }
    if (
      prevProps.shared.closedJobPageNo !== this.props?.shared?.closedJobPageNo
    ) {
      this.setState({ pageForClose: this.props?.shared?.closedJobPageNo });
    }
    if (prevState.visibility !== this.state.visibility) {
      this.onFocus();
    }
    seo({
      title: `${pageTitle}`,
    });
  }

  componentWillUnmount() {
    secureLocalStorage.removeItem("locationDetails");
    this.listen();
    const visibilityChange = getBrowserVisibilityProp();
    document.removeEventListener(visibilityChange, this.onTabChange);
  }

  onAboutUsChange = (editorStateAboutUs) => {
    if (
      editorStateAboutUs.getCurrentContent().getPlainText("\u0001").length > 0
    ) {
      this.setState({ isAboutUsRequire: false });
    }
    if (
      editorStateAboutUs.getCurrentContent().getPlainText("\u0001").length >
      5000
    ) {
      this.setState({ isValidAboutUs: false });
    }
    this.setState({ editorStateAboutUs });
  };

  onJobDescriptionChange = (editorStateJobDescription) => {
    if (
      editorStateJobDescription.getCurrentContent().getPlainText("\u0001")
        .length > 0
    ) {
      this.setState({ isPositionDescriptionRequire: false });
    }
    if (
      editorStateJobDescription.getCurrentContent().getPlainText("\u0001")
        .length > 5000
    ) {
      this.setState({ isValidPositionDescription: false });
    }
    this.setState({ editorStateJobDescription });
  };

  onAdditionresponsibilities = (additionresponsibilities) => {
    if (
      additionresponsibilities.getCurrentContent().getPlainText("\u0001")
        .length > 0
    ) {
      this.setState({ isAdditionResponsibilitesRequire: false });
    }
    if (
      additionresponsibilities.getCurrentContent().getPlainText("\u0001")
        .length > 5000
    ) {
      this.setState({ isAdditionResponsibilitesDescription: false });
    }
    this.setState({ additionresponsibilities });
  };

  onCandidateWillBring = (editorcandidateWillBring) => {
    if (
      editorcandidateWillBring.getCurrentContent().getPlainText("\u0001")
        .length > 0
    ) {
      this.setState({ isWillSuccessfullCandidateBringRequired: false });
    }
    if (
      editorcandidateWillBring.getCurrentContent().getPlainText("\u0001")
        .length > 5000
    ) {
      this.setState({ isWillSuccessfullCandidateBringDescription: false });
    }
    this.setState({ editorcandidateWillBring });
  };

  onAbsoluteMustHave = (editerAbsoluteMustHave) => {
    if (
      editerAbsoluteMustHave.getCurrentContent().getPlainText("\u0001").length >
      0
    ) {
      this.setState({ isAbosultMustRequire: false });
    }
    if (
      editerAbsoluteMustHave.getCurrentContent().getPlainText("\u0001").length >
      5000
    ) {
      this.setState({ isValidisAbosultMustnDescription: false });
    }
    this.setState({ editerAbsoluteMustHave });
  };

  capitalize = function (str) {
    if (str !== null && str !== undefined) {
      return str
        .split(" ")
        .map((w) => w.substring(0, 1).toUpperCase() + w.substring(1))
        .join(" ");
    } else return str;
  };

  handlePageChangeForOpen = (event, value) => {
    this.setState({ page: value }, function () {
      const { dispatch } = this.props;
      dispatch(sharedActions.jobsPagePaginationPageChange(value));
      switch (this.props?.shared?.selectedNavbarIndex) {
        case 1:
          dispatch(
            jobsActions.getOpenJobList(
              JSON.parse(secureLocalStorage.getItem("recruiterStatusDetails"))
                .recruiter_id,
              this.props.shared.functionFilter
            )
          );
          dispatch(sharedActions.jobsPagePaginationPageChange(value));
          break;
        case 11:
          dispatch(jobsActions.getOpenSharedJobList());
          dispatch(
            sharedActions.jobsPagePaginationPageChange(
              value,
              this.props.shared.functionFilter
            )
          );
          break;
        default:
          break;
      }
    });
  };

  handlePageChangeForClose = (event, value) => {
    this.setState({ pageForClose: value }, function () {
      const { dispatch } = this.props;
      dispatch(sharedActions.closedJobsPage(value));
      switch (this.props?.shared?.selectedNavbarIndex) {
        case 1:
          dispatch(
            jobsActions.getClosedJobList(
              JSON.parse(secureLocalStorage.getItem("recruiterStatusDetails"))
                .recruiter_id
            )
          );
          dispatch(sharedActions.jobsPagePaginationPageChange(value));
          break;
        case 11:
          dispatch(jobsActions.getClosedSharedJobList());
          dispatch(sharedActions.jobsPagePaginationPageChange(value));
          break;
        default:
          break;
      }
    });
  };

  getJobTitles = (title) => {
    this.setState({ jobTitleLoading: true });
    const { dispatch } = this.props;
    dispatch(jobsActions.getJobTitles(title));
    dispatch(loading(false));
  };

  getReportsToTitles = (title) => {
    this.setState({ reportsToTitlesLoading: true });
    const { dispatch } = this.props;
    dispatch(jobsActions.getReportsToTitles(title));
    dispatch(loading(false));
  };

  getMajors = (major) => {
    this.setState({ majorLoading: true });
    const { dispatch } = this.props;
    dispatch(jobsActions.getMajorList(major));
    dispatch(loading(false));
  };

  getCertifications = (certification) => {
    this.setState({ certificationsLoading: true });
    const { dispatch } = this.props;
    dispatch(jobsActions.getCertifications(certification));
    dispatch(loading(false));
  };

  getSkills = (skillInput) => {
    this.setState({ skillsLoading: true });
    const { dispatch } = this.props;
    dispatch(jobsActions.getSkills(skillInput));
  };

  handlePlaceChanged() {
    const place = this.autocomplete.getPlace();
    this.props.onPlaceLoaded(place);
  }

  handleInputChange = (prop) => (event) => {
    let inputValue = "";
    switch (prop) {
      case "aboutUs":
        inputValue = this.state.editorStateAboutUs
          .getCurrentContent()
          .getPlainText("\u0001");
        break;
      case "jobDescription":
        inputValue = this.state.editorStateJobDescription
          .getCurrentContent()
          .getPlainText("\u0001");
        break;
      case "Successfulcandidatewillbring":
        inputValue = this.state.editorcandidateWillBring
          .getCurrentContent()
          .getPlainText("\u0001");
        break;
      case "Additionresponsibilities":
        inputValue = this.state.additionresponsibilities
          .getCurrentContent()
          .getPlainText("\u0001");
        break;
      case "absolueMustHave":
        inputValue = this.state.editerAbsoluteMustHave
          .getCurrentContent()
          .getPlainText("\u0001");
        break;
      default:
        inputValue = notAllowWhiteSpaceAtStart(event.target.value);
        break;
    }
    this.setState({ [prop]: inputValue }, function () {
      switch (prop) {
        case "jobTitle":
          this.getJobTitles(this.state.jobTitle);
          if (this.state.jobTitle.length === 0) {
            this.setState({ jobTitleOptions: [] });
          }
          break;
        case "reportsToTitle":
          this.getReportsToTitles(this.state.reportsToTitle);
          if (this.state.reportsToTitle.length === 0) {
            this.setState({ reportsToTitleOptions: [] });
          }
          break;
        case "reportsTo":
          this.setState({
            isValidReportsToName: validator.matches(inputValue, /^.{0,100}$/),
          });
          break;
        case "otherFunctionName":
          this.setState({
            isValidOtherFunctionName: validator.matches(
              inputValue,
              /^.{0,100}$/s
            ),
          });
          break;
        case "jobLevel":
          this.setState({
            isJobLevelRequire: validator.isEmpty(inputValue),
          });
          break;
        case "industry":
          this.setState({
            isIndustryRequire: validator.isEmpty(inputValue),
          });
          break;
        case "functionName":
          this.setState({
            isFunctionRequire: validator.isEmpty(inputValue),
          });
          if (inputValue === "Other") {
            this.highlightOtherFunctionName();
          }
          break;
        case "minimumExperience":
          this.setState({
            isYearOfExpRequire: validator.isEmpty(inputValue),
          });
          break;
        case "minimumBaseSalary":
          this.setState({
            isSalaryRequired: validator.isEmpty(inputValue),
          });
          break;
        case "major":
          this.getMajors(this.state.major);
          if (this.state.major.length === 0) {
            this.setState({ majorOptions: [] });
          }
          break;
        case "degree":
          this.setState({
            isDegreeRequire: validator.isEmpty(inputValue),
          });
          break;
        case "certificationsValue":
          this.getCertifications(this.state.certificationsValue);
          if (this.state.certificationsValue.length === 0) {
            this.setState({ certificationsOptions: [] });
          }
          break;
        case "remote":
          this.setState({
            isRemoteOnSiteRequire: validator.isEmpty(inputValue),
          });
          break;
        case "degreeRequiredOrPreferred":
          this.setState({
            isDegreeRequiredOrPreferredRequire: validator.isEmpty(inputValue),
          });
          break;
        case "travelLevel":
          this.setState({
            isTravelRequirementRequire: validator.isEmpty(inputValue),
          });
          break;
        case "howSoonToHire":
          this.setState({
            isSoonLookingToHireRequire: validator.isEmpty(inputValue),
          });
          break;
        case "aboutUs":
          this.setState({
            aboutUsWithHTML: draftToHtml(
              convertToRaw(this.state.editorStateAboutUs.getCurrentContent())
            ),
            isAboutUsRequire: validator.isEmpty(inputValue),
            isValidAboutUs: inputValue
              ? validator.matches(inputValue, /^.{25,5000}$/s)
              : true,
          });
          break;
        case "jobDescription":
          this.setState({
            jobDescriptionWithHTML: draftToHtml(
              convertToRaw(
                this.state.editorStateJobDescription.getCurrentContent()
              )
            ),
            isPositionDescriptionRequire: validator.isEmpty(inputValue),
            isValidPositionDescription: inputValue
              ? validator.matches(inputValue, /^.{25,5000}$/s)
              : true,
          });
          break;
        case "Successfulcandidatewillbring":
          this.setState({
            willSuccessfullCandidateBringHTML: draftToHtml(
              convertToRaw(
                this.state.editorcandidateWillBring.getCurrentContent()
              )
            ),
            isWillSuccessfullCandidateBringRequired:
              validator.isEmpty(inputValue),
            isWillSuccessfullCandidateBringDescription: inputValue
              ? validator.matches(inputValue, /^.{25,5000}$/s)
              : true,
          });
          break;
        case "Additionresponsibilities":
          this.setState({
            additionResponsibilitesWithHTML: draftToHtml(
              convertToRaw(
                this.state.additionresponsibilities.getCurrentContent()
              )
            ),
            isAdditionResponsibilitesRequire: validator.isEmpty(inputValue),
            isAdditionResponsibilitesDescription: inputValue
              ? validator.matches(inputValue, /^.{25,5000}$/s)
              : true,
          });
          break;
        case "absolueMustHave":
          this.setState({
            absolueMustHavehHTML: draftToHtml(
              convertToRaw(
                this.state.editerAbsoluteMustHave.getCurrentContent()
              )
            ),
            isAbosultMustRequire: validator.isEmpty(inputValue),
            isValidisAbosultMustnDescription: inputValue
              ? validator.matches(inputValue, /^.{25,5000}$/s)
              : true,
          });
          break;
        default:
          return;
      }
    });
  };

  handleTabChange = (event, newValue) => {
    const { dispatch } = this.props;
    const { selectedNavbarIndex } = this.props.shared;

    dispatch(sharedActions.jobsPagePaginationPageChange(1));
    dispatch(sharedActions.closedJobsPage(1));
    dispatch(sharedActions.functionFilter(null));
    this.setState({ value: newValue }, function () {
      const { dispatch } = this.props;
      dispatch(sharedActions.jobsPageTabChange(this.state.value));
    });
    switch (newValue) {
      case 0:
        dispatch(
          setTitle(
            `${`Jobs`} ${`-`} ${
              selectedNavbarIndex === 1 ? " My Jobs" : "Shared Jobs"
            } ${`-`} ${`Open Positions`}`
          )
        );
        if (selectedNavbarIndex === 1) {
          dispatch(
            jobsActions.getOpenJobList(
              JSON.parse(secureLocalStorage.getItem("recruiterStatusDetails"))
                .recruiter_id
            )
          );
        }
        if (selectedNavbarIndex === 11) {
          dispatch(jobsActions.getOpenSharedJobList());
        }
        break;
      case 1:
        dispatch(
          setTitle(
            `${`Jobs`} ${`-`} ${
              selectedNavbarIndex === 1 ? " My Jobs" : "Shared Jobs"
            } ${`-`} ${`Closed Postions`}`
          )
        );
        if (selectedNavbarIndex === 1) {
          dispatch(
            jobsActions.getClosedJobList(
              JSON.parse(secureLocalStorage.getItem("recruiterStatusDetails"))
                .recruiter_id
            )
          );
        }
        if (selectedNavbarIndex === 11) {
          dispatch(jobsActions.getClosedSharedJobList());
        }
        break;
      default:
        break;
    }
  };

  handleClose = (event) => {
    event.preventDefault();
    event.stopPropagation();
    secureLocalStorage.removeItem("locationDetails");
    this.setState((prevState) => ({ open: !prevState }));
    this.setState((prevState) => ({ openPreview: !prevState }));
    this.setState(this.getInitialState());
  };

  highlightFunctionName = () => {
    let functionNameTextField = document.getElementById("jobFunctionName");
    if (functionNameTextField) {
      functionNameTextField.classList.add("fade-in-field");
    }
  };

  highlightOtherFunctionName = () => {
    let otherFunctionNameTextField = document.getElementById(
      "otherJobFunctionName"
    );
    if (otherFunctionNameTextField) {
      otherFunctionNameTextField.classList.add("fade-in-field");
    }
  };

  removeFieldHighlight = () => {
    let functionNameTextField = document.getElementById("jobFunctionName");
    if (functionNameTextField) {
      functionNameTextField.classList.remove("fade-in-field");
    }
  };

  setJobTitle = (value) => {
    if (value !== null && value !== undefined) {
      this.setState({
        jobTitleValue: value._source.Title,
        isJobTitleRequire: false,
      });
      if (
        !this.props.authentication?.constants?.function?.includes(
          value._source.Function
        )
      ) {
        this.setState({
          functionName: "Other",
          otherFunctionName: value._source.Function,
          isFunctionRequire: false,
        });
      } else {
        this.setState({
          functionName: value._source.Function,
          otherFunctionName: "",
          isFunctionRequire: false,
        });
      }
    } else {
      this.setState({ jobTitleValue: "", isJobTitleRequire: true });
    }
    this.highlightFunctionName();
  };

  setReportsToTitle = (value) => {
    if (value !== null && value !== undefined) {
      this.setState({
        reportsToTitleValue: value._source.Title,
        isReportsToRequire: false,
      });
    } else {
      this.setState({ reportsToTitleValue: "", isReportsToRequire: true });
    }
  };

  setMajor = (value) => {
    if (value !== null && value !== undefined) {
      this.setState({ majorValue: value });
    } else {
      this.setState({ majorValue: "" });
    }
  };

  setCertifications = (value) => {
    if (value !== null && value !== undefined && value.length > 0) {
      this.setState({
        certifications: value,
        certificationsValue: value[0]._source.Certificate,
        isCertificationsRequire: false,
      });
    } else {
      this.setState({
        certifications: [],
        certificationsValue: "",
        isCertificationsRequire: true,
      });
    }
  };

  postJob = () => {
    secureLocalStorage.removeItem("locationDetails");
    history.push("/jobs/add-job/");
  };

  handleCheckBox = (Type) => (event) => {
    switch (Type) {
      case "TeamSize":
        if (event.target.checked === true) {
          teamSize.push(event.target.value);
          this.setState({ teamSize: [...teamSize] });
        } else {
          let index = teamSize.findIndex((value) => {
            return value === event.target.value;
          });
          teamSize.splice(index, 1);
          this.setState({ teamSize: [...teamSize] });
        }
        break;
      case "StartupStage":
        if (event.target.checked === true) {
          startupStage.push(event.target.value);
          this.setState({ startupStage: [...startupStage] });
        } else {
          let index = startupStage.findIndex((value) => {
            return value === event.target.value;
          });
          startupStage.splice(index, 1);
          this.setState({ startupStage: [...startupStage] });
        }
        break;
      case "StartupSize":
        if (Type === "StartupSize" && event.target.checked === true) {
          startupSize.push(event.target.value);
          this.setState({ startupSize: [...startupSize] });
        } else {
          let index = startupSize.findIndex((value) => {
            return value === event.target.value;
          });
          startupSize.splice(index, 1);
          this.setState({ startupSize: [...startupSize] });
        }
        break;
      default:
        break;
    }
  };

  handleRole = (event) => {
    this.setState({ role: event.target.value });
  };

  handlePostJob = (event) => {
    let salary = null;
    let resultArray = [];
    let price = Intl.NumberFormat("en-US");
    salary =
      this.state.minimumBaseSalary === null ||
      this.state.minimumBaseSalary === ""
        ? null
        : `$${price.format(this.state.minimumBaseSalary)}`;
    const {
      sampleCompanyValues,
      howSoonToHire,
      teamSize,
      startupSize,
      startupStage,
      absolueMustHavehHTML,
      willSuccessfullCandidateBringHTML,
      additionResponsibilitesWithHTML,
      role,
      jobTitleValue,
      reportsToTitleValue,
      jobLevel,
      reportsTo,
      industry,
      functionName,
      otherFunctionName,
      locationData,
      aboutUsWithHTML,
      jobDescriptionWithHTML,
      skills,
      minimumExperience,
      degree,
      majorValue,
      degreeRequiredOrPreferred,
      certifications,
      remote,
      travelLevel,
      salary_config,
    } = this.state;
    const { dispatch } = this.props;
    let skillsData = [];

    for (let skill in skills) {
      skillsData.push(skills[skill]);
    }

    let certificationsData = [];
    for (let certificate in certifications) {
      certificationsData.push(
        certifications[certificate]?._source?.Certificate
      );
    }

    if (sampleCompanyValues) {
      resultArray = sampleCompanyValues.map((value) => {
        return {
          company_name: value.name,
          logo: value.logo,
          domain: value.domain,
        };
      });
    }
    this.setState({ skills: skillsData, certifications: certificationsData });
    let payload = {
      title: trim(jobTitleValue),
      location: locationData,
      recruiter_id: JSON.parse(
        secureLocalStorage.getItem("recruiterStatusDetails")
      ).recruiter_id,
      function: trim(functionName),
      notice_period: howSoonToHire ? trim(howSoonToHire) : null,
      function_name: otherFunctionName ? trim(otherFunctionName) : null,
      industry: industry ? trim(industry) : null,
      level: jobLevel ? trim(jobLevel) : null,
      reports_to: reportsToTitleValue ? trim(reportsToTitleValue) : null,
      about_us: trim(aboutUsWithHTML),
      skills: skillsData.length > 0 ? skillsData : null,
      degree: degree ? trim(degree) : null,
      major: majorValue ? trim(majorValue?._source?.Major) : null,
      degree_req_pref: degreeRequiredOrPreferred
        ? trim(degreeRequiredOrPreferred)
        : null,
      travel_requirements: travelLevel ? trim(travelLevel) : null,
      remote_or_onsite: remote ? trim(remote) : null,
      minimum_base_salary: salary ? trim(salary) : null,
      minimum_years_of_experience: minimumExperience
        ? null
        : trim(minimumExperience),
      certifications:
        certificationsData?.length > 0 ? certificationsData : null,
      reports_to_name: reportsTo ? trim(reportsTo) : null,
      salary_config: salary_config
        ? salary_config === 0
          ? 0
          : salary_config
        : null,
      startup_size: startupSize?.length === 0 ? null : startupSize,
      startup_stage: startupStage?.length === 0 ? null : startupStage,
      team_size: teamSize?.length === 0 ? null : teamSize,
      existing_role: role || null,
      sample_companies: resultArray?.length === 0 ? null : resultArray,
      about_role: trim(jobDescriptionWithHTML),
      candidate_value_addition: trim(willSuccessfullCandidateBringHTML),
      additional_responsibility: trim(additionResponsibilitesWithHTML),
      absolute_must_have: trim(absolueMustHavehHTML),
    };
    dispatch(jobsActions.postJob(payload));
    this.handleClose(event);
  };

  handlePreviewClick = () => {
    let skillsData = [];
    let certificationsData = [];
    if (typeof this.state.skills === "object") {
      for (let skill in this.state.skills) {
        skillsData.push(this.state.skills?.[skill] || this.state.skills);
      }
      this.setState({ skillsDataList: skillsData });
    }
    if (typeof this.state.certifications === "object") {
      for (let certification in this.state.certifications) {
        certificationsData.push(
          this.state.certifications?.[certification]?._source?.Certificate ||
            this.state.certifications
        );
      }
      this.setState({ certificationsDataList: certificationsData });
    }
    this.setState({
      isValidAboutUs: this.state.aboutUs
        ? validator.matches(this.state.aboutUs, /^.{25,5000}$/s)
        : true,
      isValidAdditionresponsibilities: this.state.Additionresponsibilities
        ? validator.matches(
            this.state.Additionresponsibilities,
            /^.{25,5000}$/s
          )
        : true,
      isValidAbsoluteMustHaves: this.state.absolueMustHave
        ? validator.matches(this.state.absolueMustHave, /^.{25,5000}$/s)
        : true,
      isValidCandidateWillBring: this.state.Successfulcandidatewillbring
        ? validator.matches(
            this.state.Successfulcandidatewillbring,
            /^.{25,5000}$/s
          )
        : true,
    });

    this.setState(
      {
        locationData: JSON.parse(secureLocalStorage.getItem("locationDetails")),
      },
      function () {
        const {
          jobTitleValue,
          editorStateJobDescription,
          isValidPositionDescription,
        } = this.state;
        if (
          jobTitleValue &&
          editorStateJobDescription.getCurrentContent().getPlainText("\u0001")
            .length > 0 &&
          isValidPositionDescription
        ) {
          this.setState({
            location:
              (this.state.locationData?.city !== undefined
                ? this.state.locationData?.city + ", "
                : "") +
              (this.state.locationData?.state !== undefined
                ? this.state.locationData?.state + ", "
                : "") +
              (this.state.locationData?.country !== undefined
                ? this.state.locationData?.country
                : ""),
          });
          this.setState({ openPreview: true });
        } else {
          toast.dark("Please fill up required fields with valid data");
          this.setState({
            isJobTitleRequire: validator.isEmpty(jobTitleValue),
            isPositionDescriptionRequire: validator.isEmpty(
              editorStateJobDescription
                .getCurrentContent()
                .getPlainText("\u0001")
            ),
          });
        }
      }
    );
  };

  handleEditClick = () => {
    this.setState({ open: true });
    this.setState({ openPreview: false });
  };

  handleJobClick = (id) => {
    window.location.pathname === "/jobs/shared-jobs"
      ? history.push(`/jobs/shared-jobs/${id}`)
      : window.location.pathname === "/jobs/my-jobs"
      ? history.push(`/jobs/my-jobs/${id}`)
      : window.location.pathname.match("^/jobs/my-jobs.*$")
      ? history.push(`/jobs/my-jobs/${id}`)
      : history.push(`/jobs/shared-jobs/${id}`);
  };

  handleOnBlur = () => {
    this.setState({ jobTitleOptions: [], jobTitle: "" });
  };

  handleReportsToBlur = () => {
    this.setState({ reportsToTitleOptions: [], reportsToTitle: "" });
  };

  handleMajorBlur = () => {
    this.setState({ majorOptions: [], major: "" });
  };

  handleCertificationsBlur = () => {
    this.setState({ certificationsOptions: [], certificationsValue: null });
  };

  badgeClick = (badgeValue, job) => {
    const { dispatch } = this.props;
    dispatch(jobsActions.setJobTab({ jobDetailTab: badgeValue }));
    this.handleJobClick(
      window.location.pathname === "/jobs/my-jobs" ? job?.id : job?.job?.id,
      badgeValue
    );
  };

  updateLocationRequireState = (value) => {
    this.setState({ isLocationRequire: value });
  };

  saveCustomJobTitle = (event) => {
    event.preventDefault();
    event.stopPropagation();
    this.setState({
      jobTitleValue: trim(this.state.customJobTitle),
      customJobTitlePopUp: false,
      isJobTitleRequire: false,
      functionName: "Other",
      otherFunctionName: "",
      isFunctionRequire: false,
    });
    let functionNameTextField = document.getElementById("jobFunctionName");
    if (functionNameTextField) {
      functionNameTextField.classList.add("fade-in-field");
    }
  };

  saveCustomCertificationTitle = (event) => {
    event.preventDefault();
    event.stopPropagation();
    const found = this.state.certifications.some(
      (el) =>
        el._source.Certificate.toLowerCase() ===
        this.state.customCertificationTitle.toLowerCase()
    );
    if (!found) {
      const certificateList = [...this.state.certifications];
      certificateList.push({
        _source: { Certificate: trim(this.state.customCertificationTitle) },
      });
      this.setState({
        certifications: certificateList,
        customCertificationTitlePopUp: false,
      });
    } else {
      this.setState({ customCertificationTitle: "" });
      toast.dark("Certification already added");
    }
  };

  saveCustomMajor = (event) => {
    event.preventDefault();
    event.stopPropagation();
    this.setState({
      majorValue: { _source: { Major: trim(this.state.customMajorValue) } },
      customMajorPopUp: false,
    });
  };

  handleExpCompany = debounce((event) => {
    this.setState({ companyNameValueInput: event.target.value });
    if (this.state.companyNameValueInput.length === 0) {
      this.setState({ companyOptions: [], companyLoading: false });
    } else if (event.target.value.length < 100) {
      this.getCompanyNames(event.target.value);
    } else {
      this.setState({ companyLoading: false });
    }
  }, 500);

  getCompanyNames = (title) => {
    const { dispatch } = this.props;
    dispatch(jobsActions.getCompanyNames(title));
    dispatch(loading(false));
  };

  onChangeFilter = (value) => {
    const { dispatch } = this.props;
    dispatch(sharedActions.functionFilter(value));
    this.setState({ functionFilterValue: value }, () => {
      this.callJobsListingApis(window.location.pathname, value);
    });
  };
  handleOpen = () => {
    this.setState({ filterOpen: true });
  };
  render() {
    const { classes } = this.props;
    const {
      editerAbsoluteMustHave,
      editorStateAboutUs,
      editorStateJobDescription,
      additionresponsibilities,
      editorcandidateWillBring,
    } = this.state;
    let openJobs = this.props.jobs?.openJobList?.results;
    let closedJobs = this.props.jobs?.closedJobList?.results;
    let totalOpenJobs = this.props.jobs?.openJobList?.count;
    let totalClosedJobs = this.props.jobs?.closedJobList?.count;
    let constants = this.props.authentication?.constants;
    let data = [];
    let marks = [];
    let salaryValueFormat = Intl.NumberFormat("en-US");
    if (constants?.minimum_base_salary) {
      constants?.minimum_base_salary?.map((ele) =>
        data.push({ value: ele.replace(/[^0-9.-]+/g, ""), label: ele })
      );
    }
    marks = data.map((ele) => ele);
    let minValue = parseInt(marks[0]?.value);
    let maxValue = parseInt(marks[marks.length - 1]?.value);

    return (
      <div className={classes.root}>
        <div className={classes.appFrame}>
          <Typography className={classes.moduleConfig}>
            {window.location.pathname === "/jobs/my-jobs"
              ? "My Jobs"
              : "Shared Jobs"}
          </Typography>
          <Button
            type="submit"
            disableElevation
            variant="contained"
            color="primary"
            className={classes.floatRight}
            onClick={(event) => {
              this.postJob(event);
            }}
            size="medium"
          >
            Add New Job
          </Button>
        </div>
        <main className={classes.content}>
          <Grid container>
            <Grid xs={9} sm={9} md={9} lg={9} xl={9}>
              <AntTabs
                variant="scrollable"
                scrollButtons="auto"
                value={this.state.value}
                onChange={this.handleTabChange}
                aria-label="ant example"
              >
                <AntTab label="Open Positions" />
                <AntTab label="Closed Positions" />
              </AntTabs>
            </Grid>
            <Grid
              xs={3}
              sm={3}
              md={3}
              lg={3}
              xl={3}
              className={classes.filterMainGrid}
            >
              <Typography component={"span"} className={classes.typoFilters}>
                <FilterListIcon fontSize="small" />
                <Typography
                  component="span"
                  variant="body2"
                  color="textSecondary"
                >
                  Filters
                </Typography>
              </Typography>
              <Autocomplete
                size="small"
                autoComplete
                className={classes.filterStyle}
                value={this.props?.shared?.functionFilter}
                onChange={(e, value) => {
                  this.onChangeFilter(value);
                }}
                id="combo-box-demo"
                options={this.props?.authentication?.constants?.function}
                renderInput={(params) => (
                  <TextField
                    size="small"
                    {...params}
                    placeholder="Select Function"
                    variant="outlined"
                  />
                )}
              />
            </Grid>
          </Grid>
          {this.state.value === 0 ? (
            openJobs && openJobs.length > 0 ? (
              <OpenJobCloseJob
                JobsValues={openJobs}
                totalOpenJobs={totalOpenJobs}
                handlePageChangeForOpen={this.handlePageChangeForOpen}
                page={this.state.page}
                handleJobClick={this.handleJobClick}
              />
            ) : (
              <>
                <div className={classes.noDataNote}>
                  <div>
                    <NoDataIcon></NoDataIcon>
                    <Typography>Hiring? Post your job requisition.</Typography>
                    <Typography>
                      Accelerate your executive search by posting your open job
                      requisitions and matching with best-fit candidates.
                    </Typography>
                  </div>
                </div>
              </>
            )
          ) : closedJobs && closedJobs.length > 0 ? (
            <OpenJobCloseJob
              JobsValues={closedJobs}
              totalOpenJobs={totalClosedJobs}
              handlePageChangeForOpen={this.handlePageChangeForClose}
              page={this.state.pageForClose}
              handleJobClick={this.handleJobClick}
            />
          ) : (
            <>
              <div className={classes.noDataNote}>
                <div>
                  <NoDataIcon></NoDataIcon>
                  <Typography>Hiring? Post your job requisition.</Typography>
                  <Typography>
                    Accelerate your executive search by posting your open job
                    requisitions and matching with best-fit candidates.
                  </Typography>
                </div>
              </div>
            </>
          )}
          <div>
            <WelcomeModal />
          </div>
          <div>
            {/* Custom job title pop up */}
            <Dialog
              open={this.state.customJobTitlePopUp}
              fullWidth
              aria-labelledby="scroll-dialog-title"
              aria-describedby="scroll-dialog-description"
              disableBackdropClick
              disableEscapeKeyDown
              maxWidth="xs"
            >
              <DialogTitle
                id="customized-dialog-title"
                onClose={() => {
                  this.setState({ customJobTitlePopUp: false });
                }}
              >
                Add Job Title
              </DialogTitle>
              <DialogContent>
                <TextField
                  fullWidth
                  label="New Job Title"
                  className={classes.customTopBottomMargin}
                  id="newJobTitle"
                  name="newJobTitle"
                  value={this.state.customJobTitle}
                  onChange={this.handleInputChange("customJobTitle")}
                  placeholder="Enter job title"
                  inputProps={{
                    maxLength: customFieldCount,
                  }}
                />
                <Typography
                  className={classNames(classes.floatRight, classes.smallFont)}
                  color="secondary"
                >
                  {this.state.customJobTitle?.length + "/" + customFieldCount}{" "}
                  characters
                </Typography>
              </DialogContent>
              <DialogActions className={classes.dialogActions}>
                <Button
                  onClick={() =>
                    this.setState({
                      customJobTitlePopUp: false,
                      jobTitle: "",
                      jobTitleValue: "",
                    })
                  }
                  className={classes.justifyRight}
                  style={{ background: "#D7D9DB", color: "#000" }}
                  variant="contained"
                  disableElevation
                  size="medium"
                >
                  Cancel
                </Button>
                <Button
                  className={classes.justifyRight}
                  variant="contained"
                  color="primary"
                  disableElevation
                  size="medium"
                  disabled={this.state.customJobTitle === ""}
                  onClick={(event) => {
                    this.saveCustomJobTitle(event);
                  }}
                >
                  Save
                </Button>
              </DialogActions>
            </Dialog>

            {/* Custom certification title pop up */}
            <Dialog
              open={this.state.customCertificationTitlePopUp}
              fullWidth
              aria-labelledby="scroll-dialog-title"
              aria-describedby="scroll-dialog-description"
              disableBackdropClick
              disableEscapeKeyDown
              maxWidth="xs"
            >
              <DialogTitle
                id="customized-dialog-title"
                onClose={() => {
                  this.setState({ customCertificationTitlePopUp: false });
                }}
              >
                Add Professional Certification
              </DialogTitle>
              <DialogContent>
                <TextField
                  fullWidth
                  label="New Professional Certification"
                  className={classes.customTopBottomMargin}
                  id="newProfCertification"
                  name="newProfCertification"
                  value={this.state.customCertificationTitle}
                  onChange={this.handleInputChange("customCertificationTitle")}
                  placeholder="Enter professional certification"
                  inputProps={{
                    maxLength: customFieldCount,
                  }}
                />
                <Typography
                  className={classNames(classes.floatRight, classes.smallFont)}
                  color="secondary"
                >
                  {this.state.customCertificationTitle?.length +
                    "/" +
                    customFieldCount}{" "}
                  characters
                </Typography>
              </DialogContent>
              <DialogActions className={classes.dialogActions}>
                <Button
                  onClick={() =>
                    this.setState({
                      customCertificationTitlePopUp: false,
                    })
                  }
                  className={classes.justifyRight}
                  style={{ background: "#D7D9DB", color: "#000" }}
                  variant="contained"
                  disableElevation
                  size="medium"
                >
                  Cancel
                </Button>
                <Button
                  className={classes.justifyRight}
                  variant="contained"
                  color="primary"
                  disableElevation
                  size="medium"
                  disabled={this.state.customCertificationTitle === ""}
                  onClick={(event) => {
                    this.saveCustomCertificationTitle(event);
                  }}
                >
                  Save
                </Button>
              </DialogActions>
            </Dialog>

            {/* Custom major pop up */}
            <Dialog
              open={this.state.customMajorPopUp}
              fullWidth
              aria-labelledby="scroll-dialog-title"
              aria-describedby="scroll-dialog-description"
              disableBackdropClick
              disableEscapeKeyDown
              maxWidth="xs"
            >
              <DialogTitle
                id="customized-dialog-title"
                onClose={() => {
                  this.setState({ customMajorPopUp: false });
                }}
              >
                Add Major
              </DialogTitle>
              <DialogContent>
                <TextField
                  fullWidth
                  label="Major"
                  className={classes.customTopBottomMargin}
                  id="newMajor"
                  name="newMajor"
                  value={this.state.customMajorValue}
                  onChange={this.handleInputChange("customMajorValue")}
                  placeholder="Enter major"
                  inputProps={{
                    maxLength: customFieldCount,
                  }}
                />
                <Typography
                  className={classNames(classes.floatRight, classes.smallFont)}
                  color="secondary"
                >
                  {this.state.customMajorValue?.length + "/" + customFieldCount}{" "}
                  characters
                </Typography>
              </DialogContent>
              <DialogActions className={classes.dialogActions}>
                <Button
                  onClick={() =>
                    this.setState({
                      customMajorPopUp: false,
                    })
                  }
                  className={classes.justifyRight}
                  style={{ background: "#D7D9DB", color: "#000" }}
                  variant="contained"
                  disableElevation
                  size="medium"
                >
                  Cancel
                </Button>
                <Button
                  className={classes.justifyRight}
                  variant="contained"
                  color="primary"
                  disableElevation
                  size="medium"
                  disabled={this.state.customMajorValue === ""}
                  onClick={(event) => {
                    this.saveCustomMajor(event);
                  }}
                >
                  Save
                </Button>
              </DialogActions>
            </Dialog>

            {/* Job Post dialog */}
            <Dialog
              open={this.state.open}
              scroll={this.state.scroll}
              fullWidth
              aria-labelledby="scroll-dialog-title"
              aria-describedby="scroll-dialog-description"
              disableBackdropClick
              disableEscapeKeyDown
              maxWidth="md"
            >
              <DialogTitle
                id="customized-dialog-title"
                // className={classes.dialogTitle}
                onClose={(event) => {
                  this.handleClose(event);
                }}
              >
                Job Post
              </DialogTitle>
              <DialogContent dividers={this.state.scroll === "paper"}>
                <DialogContentText
                  component="div"
                  id="scroll-dialog-description"
                >
                  <Typography component="div" color="secondary">
                    The job description will not be visible to candidates until
                    you have chosen to connect with them.
                  </Typography>
                  <br></br>
                  <Typography component="div">
                    (<span className="required">*</span> Required fields)
                  </Typography>
                  <Grid
                    item
                    xs={12}
                    sm={12}
                    md={12}
                    lg={12}
                    xl={12}
                    style={{ position: "relative" }}
                  >
                    <div className={classes.subHeader}>Basics</div>
                    <Autocomplete
                      id="auto-complete"
                      autoComplete
                      PopperComponent={Pop}
                      value={this.state.jobTitleValue}
                      open={this.state.openOptions}
                      onChange={(event, newValue) => {
                        if (typeof newValue === "string") {
                          setTimeout(() => {
                            this.setState({
                              customJobTitlePopUp: true,
                              customJobTitle: newValue,
                            });
                          });
                        } else if (newValue && newValue.inputValue) {
                          this.setState({
                            customJobTitlePopUp: true,
                            customJobTitle: newValue.inputValue,
                          });
                        } else {
                          this.setJobTitle(newValue);
                        }
                      }}
                      onOpen={() => {
                        this.setState({ openOptions: true });
                        this.removeFieldHighlight();
                      }}
                      onClose={() => {
                        this.setState({ openOptions: false });
                      }}
                      onInputCapture={() => {
                        this.removeFieldHighlight();
                      }}
                      getOptionSelected={(option, value) =>
                        option?._source?.Title === value?._source?.Title
                      }
                      getOptionLabel={(option) => {
                        if (typeof option === "string") {
                          return option;
                        }
                        if (option.inputValue) {
                          return option.inputValue;
                        }
                        return option?._source?.Title
                          ? option?._source?.Title
                          : "";
                      }}
                      options={this.state.jobTitleOptions}
                      onBlur={(event) => {
                        this.handleOnBlur(event);
                      }}
                      loading={this.state.jobTitleLoading}
                      freeSolo
                      selectOnFocus
                      clearOnBlur
                      filterOptions={(options, params) => {
                        let filtered = options;
                        // const filtered = filter(options, params);
                        if (params.inputValue !== "") {
                          filtered.push({
                            inputValue: params.inputValue,
                            _source: {
                              Title: `Add: "${params.inputValue}"`,
                            },
                          });
                        }
                        return filtered;
                      }}
                      renderOption={(option) => option?._source?.Title}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label="Job Title"
                          style={{ marginBottom: "1.7%" }}
                          id="jobTitle"
                          name="jobTitle"
                          InputProps={{
                            ...params.InputProps,
                            endAdornment: (
                              <React.Fragment>
                                {this.state.jobTitleLoading ? (
                                  <CircularProgress
                                    className={classes.verySmallMarginRight}
                                    color="inherit"
                                    size={20}
                                  />
                                ) : null}
                                {params.InputProps.endAdornment}
                              </React.Fragment>
                            ),
                            inputProps: {
                              ...params.inputProps,
                              maxLength: customFieldCount,
                            },
                          }}
                          required
                          value={this.state.jobTitle}
                          error={this.state.isJobTitleRequire}
                          helperText={
                            this.state.isJobTitleRequire
                              ? MESSAGE_FIELD_IS_REQUIRED
                              : ""
                          }
                          onChange={this.handleInputChange("jobTitle")}
                          placeholder="Type to select job title"
                        />
                      )}
                    />
                  </Grid>

                  <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                    <FormControl style={{ minWidth: "100%" }}>
                      <InputLabel id="demo-simple-select-label">
                        Function
                      </InputLabel>
                      <Select
                        labelId="demo-simple-select-label"
                        id="jobFunctionName"
                        margin="none"
                        value={this.state.functionName}
                        placeholder="Choose one"
                        onChange={this.handleInputChange("functionName")}
                      >
                        {constants?.function.map((obj, index) => (
                          <MenuItem key={index} value={obj}>
                            {obj}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </Grid>
                  {this.state.functionName === "Other" ? (
                    <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                      <TextField
                        fullWidth
                        label="Function Name"
                        autoComplete="functionName"
                        id="otherJobFunctionName"
                        name="functionName"
                        value={this.state.otherFunctionName}
                        margin="normal"
                        onChange={this.handleInputChange("otherFunctionName")}
                        placeholder="Enter function Name (optional)"
                        inputProps={{ maxLength: reportsToCount }}
                        onInputCapture={() => {
                          this.removeFieldHighlight();
                        }}
                      />
                      <Typography
                        className={classNames(
                          classes.floatRight,
                          classes.smallFont,
                          classes.customVerySmallTopMargin
                        )}
                        color="secondary"
                      >
                        {this.state.otherFunctionName?.length +
                          "/" +
                          reportsToCount}{" "}
                        characters
                      </Typography>
                    </Grid>
                  ) : null}
                  <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                    <FormControl
                      className={
                        this.state.functionName !== "Other"
                          ? classes.formControl
                          : null
                      }
                      style={{ minWidth: "100%" }}
                    >
                      <InputLabel id="demo-simple-select-label">
                        Job Level
                      </InputLabel>
                      <Select
                        margin="none"
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        value={this.state.jobLevel}
                        placeholder="Choose one"
                        onChange={this.handleInputChange("jobLevel")}
                      >
                        {constants?.level.map((obj, index) => (
                          <MenuItem key={index} value={obj}>
                            {obj}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    sm={12}
                    md={12}
                    lg={12}
                    xl={12}
                    style={{ position: "relative" }}
                  >
                    <Autocomplete
                      autoComplete
                      PopperComponent={Pop}
                      open={this.state.openReportsToOptions}
                      onChange={(event, value) => {
                        this.setReportsToTitle(value);
                      }}
                      onOpen={() => {
                        this.setState({ openReportsToOptions: true });
                      }}
                      onClose={() => {
                        this.setState({ openReportsToOptions: false });
                      }}
                      getOptionSelected={(option, value) =>
                        option._source.Title === value.name
                      }
                      getOptionLabel={(option) => option._source.Title}
                      options={this.state.reportsToTitleOptions}
                      loading={this.state.reportsToTitlesLoading}
                      onBlur={(event) => {
                        this.handleReportsToBlur(event);
                      }}
                      filterOptions={(options) => {
                        let filtered = options;
                        return filtered;
                      }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label="Reports To"
                          margin="normal"
                          id="reportsToTitle"
                          name="reportsToTitle"
                          InputProps={{
                            ...params.InputProps,
                            endAdornment: (
                              <React.Fragment>
                                {this.state.reportsToTitlesLoading ? (
                                  <CircularProgress
                                    className={classes.verySmallMarginRight}
                                    color="inherit"
                                    size={20}
                                  />
                                ) : null}
                                {params.InputProps.endAdornment}
                              </React.Fragment>
                            ),
                          }}
                          value={this.state.reportsToTitle}
                          onChange={this.handleInputChange("reportsToTitle")}
                          placeholder="Type to select hiring manager's title"
                        />
                      )}
                    />
                  </Grid>
                  <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                    <TextField
                      fullWidth
                      label="Hiring Manager Name"
                      autoComplete="reportsTo"
                      className={classes.customTopBottomMargin}
                      id="reportsTo"
                      name="reportsTo"
                      value={this.state.reportsTo}
                      onChange={this.handleInputChange("reportsTo")}
                      placeholder="Enter hiring manager's name"
                      inputProps={{ maxLength: reportsToCount }}
                      error={this.state.isValidReportsToName ? false : true}
                      helperText={!this.state.isValidReportsToName ? "" : ""}
                    />
                    <Typography
                      className={classNames(
                        classes.floatRight,
                        classes.smallFont
                      )}
                      color="secondary"
                    >
                      {this.state.reportsTo?.length + "/" + reportsToCount}{" "}
                      characters
                    </Typography>
                  </Grid>
                  <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                    <FormControl className={classes.customFormControl}>
                      <InputLabel id="demo-simple-select-label">
                        Industry
                      </InputLabel>
                      <Select
                        fullWidth
                        id="industry"
                        margin="dense"
                        value={this.state.industry}
                        placeholder="Choose one"
                        onChange={this.handleInputChange("industry")}
                      >
                        {constants?.industry.map((obj, index) => (
                          <MenuItem key={index} value={obj}>
                            {obj}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                    <GoogleMaps
                      className={classes.customFormControl}
                      isRequire={this.state.isLocationRequire}
                      updateRequire={this.updateLocationRequireState}
                    ></GoogleMaps>
                  </Grid>
                  <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                    <FormControl className={classes.formControl}>
                      <InputLabel id="experience-label">
                        Minimum Years of Experience
                      </InputLabel>
                      <Select
                        labelId="experience-label"
                        id="experience"
                        value={this.state.minimumExperience}
                        placeholder="Choose one"
                        onChange={this.handleInputChange("minimumExperience")}
                      >
                        {constants?.years_of_experience.map((obj, index) => (
                          <MenuItem key={index} value={obj}>
                            {obj}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                    <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                      <FormControl
                        component="fieldset"
                        className={classNames(
                          classes.fullWidth,
                          classes.verySmallTopMargin,
                          classes.colorDefault
                        )}
                      >
                        <Typography
                          component={"div"}
                          style={{ display: "flex", alignItems: "center" }}
                        >
                          <FormLabel
                            className={classes.customFormLabel}
                            id="salary-label"
                            component="legend"
                          >
                            Base Salary
                          </FormLabel>
                          &nbsp;
                          <Typography component={"span"}>
                            <PopupState
                              variant="popover"
                              popupId="demo-popup-popover"
                            >
                              {(popupState) => (
                                <Fragment>
                                  <HelpIcon
                                    {...bindTrigger(popupState)}
                                    style={{
                                      marginTop: "5px",
                                      cursor: "pointer",
                                    }}
                                    titleAccess="Help"
                                  ></HelpIcon>
                                  <Popover
                                    {...bindPopover(popupState)}
                                    anchorOrigin={{
                                      vertical: "top",
                                      horizontal: "left",
                                    }}
                                    transformOrigin={{
                                      vertical: "top",
                                      horizontal: "left",
                                    }}
                                    style={{ padding: "8px" }}
                                  >
                                    <Box sx={{ width: 600, height: 300 }}>
                                      <Typography
                                        variant="body1"
                                        style={{
                                          padding: "8px",
                                          fontWeight: 500,
                                        }}
                                      >
                                        <b> Help Text </b>
                                      </Typography>
                                      <Typography
                                        variant="body2"
                                        className={classNames(
                                          classes.salaryHelpText
                                        )}
                                      >
                                        <b>Share with candidates : </b>
                                      </Typography>
                                      <Typography style={{ padding: "8px" }}>
                                        Selecting this option displays base
                                        salary information to every candidate
                                        you’re interested in.
                                      </Typography>
                                      <Typography
                                        variant="body2"
                                        className={classNames(
                                          classes.salaryHelpText
                                        )}
                                      >
                                        <b>Candidate matching only : </b>
                                      </Typography>
                                      <Typography style={{ padding: "8px" }}>
                                        Selecting this option includes the
                                        designated base salary within
                                        SucceedSmart’s candidate search criteria
                                        without displaying the salary
                                        information to selected candidates.
                                      </Typography>
                                      <Typography
                                        variant="body2"
                                        className={classNames(
                                          classes.salaryHelpText
                                        )}
                                      >
                                        <b>Exclude salary : </b>
                                      </Typography>
                                      <Typography style={{ padding: "8px" }}>
                                        Selecting this option removes the use of
                                        a salary to match prospective candidates
                                        for this role and also removes the use
                                        of a salary from your candidate search
                                        parameters.
                                      </Typography>
                                    </Box>
                                  </Popover>
                                </Fragment>
                              )}
                            </PopupState>
                          </Typography>
                        </Typography>
                        <RadioGroup
                          aria-label="salary-label"
                          id="salary"
                          value={this.state.salary_type}
                          onChange={(event, newValue) => {
                            switch (newValue) {
                              case "Exclude salary":
                                this.setState({
                                  salary_config: 0,
                                  salary_type: newValue,
                                  minimumBaseSalary: null,
                                  isSalaryRequired: false,
                                });
                                break;
                              case "Candidate matching only":
                                this.setState({
                                  salary_config: 1,
                                  salary_type: newValue,
                                  isSalaryRequired: false,
                                  minimumBaseSalary:
                                    this.state.minimumBaseSalary !== null
                                      ? this.state.minimumBaseSalary
                                      : "100000",
                                });
                                break;
                              case "Share with candidates":
                                this.setState({
                                  salary_config: 2,
                                  salary_type: newValue,
                                  isSalaryRequired: false,
                                  minimumBaseSalary:
                                    this.state.minimumBaseSalary !== null
                                      ? this.state.minimumBaseSalary
                                      : "100000",
                                });
                                break;
                              default:
                                break;
                            }
                          }}
                        >
                          <Grid container>
                            <Grid item xs={12} sm={12} md={12} lg={4} xl={4}>
                              <FormControlLabel
                                label="Share with candidates"
                                value="Share with candidates"
                                control={<Radio />}
                              />
                            </Grid>
                            <Grid item xs={12} sm={12} md={12} lg={4} xl={4}>
                              <FormControlLabel
                                label="Candidate matching only"
                                value="Candidate matching only"
                                control={<Radio />}
                              />
                            </Grid>
                            <Grid
                              item
                              xs={12}
                              sm={12}
                              md={12}
                              lg={4}
                              xl={4}
                              style={{ display: "flex", alignItems: "center" }}
                            >
                              <FormControlLabel
                                label="Exclude salary"
                                value="Exclude salary"
                                control={<Radio />}
                              />
                            </Grid>
                          </Grid>
                        </RadioGroup>
                      </FormControl>
                      {this.state.salary_type === "Share with candidates" ||
                      this.state.salary_type === "Candidate matching only" ? (
                        <Grid className={classes.salaryOption}>
                          <Grid container spacing={0}>
                            <Grid item xs={3} sm={3} md={3} lg={3} xl={3}>
                              <Grid
                                item
                                xs={12}
                                sm={12}
                                md={12}
                                lg={12}
                                xl={12}
                              >
                                <Typography
                                  component="div"
                                  style={{ fontWeight: 500, color: "black" }}
                                >
                                  {this.state?.minimumBaseSalary !== null
                                    ? `$ ${salaryValueFormat.format(
                                        this.state.minimumBaseSalary
                                      )}`
                                    : `$ ${salaryValueFormat.format(100000)}`}
                                </Typography>
                              </Grid>
                              <Grid
                                item
                                xs={12}
                                sm={12}
                                md={12}
                                lg={12}
                                xl={12}
                              >
                                <Typography
                                  component="div"
                                  style={{ fontWeight: 500, color: "black" }}
                                >
                                  Selected Salary
                                </Typography>
                              </Grid>
                            </Grid>
                            <Grid
                              item
                              xs={9}
                              sm={9}
                              md={9}
                              lg={9}
                              xl={9}
                              className={classes.displayFlex}
                            >
                              <SalarySlider
                                aria-label="Always visible"
                                size="medium"
                                value={this.state.minimumBaseSalary}
                                onChange={(event, newValue) => {
                                  if (newValue) {
                                    this.setState({
                                      minimumBaseSalary: newValue,
                                    });
                                  }
                                }}
                                marks={marks}
                                step={10000}
                                min={minValue}
                                max={maxValue}
                                valueLabelDisplay="on"
                              />
                            </Grid>
                          </Grid>
                        </Grid>
                      ) : (
                        ""
                      )}
                    </Grid>
                  </Grid>
                  <Grid container spacing={2}>
                    <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                      <FormControl className={classes.customFormControl}>
                        <InputLabel id="degree-label">Degree</InputLabel>
                        <Select
                          labelId="degree-label"
                          id="degree"
                          value={this.state.degree}
                          placeholder="Choose one"
                          onChange={this.handleInputChange("degree")}
                        >
                          {constants?.degree.map((obj, index) => (
                            <MenuItem key={index} value={obj}>
                              {obj}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    </Grid>
                    <Grid
                      item
                      xs={12}
                      sm={12}
                      md={6}
                      lg={6}
                      xl={6}
                      style={{ position: "relative" }}
                    >
                      <Autocomplete
                        id="auto-complete-major"
                        PopperComponent={Pop}
                        autoComplete
                        open={this.state.openMajorOptions}
                        value={this.state.majorValue}
                        onChange={(event, value) => {
                          if (typeof value === "string") {
                            // timeout to avoid instant validation of the dialog's form.
                            setTimeout(() => {
                              this.setState({
                                customMajorPopUp: true,
                                customMajorValue: value,
                              });
                            });
                          } else if (value && value?.inputValue) {
                            this.setState({
                              customMajorPopUp: true,
                              customMajorValue: value.inputValue,
                            });
                          } else {
                            this.setMajor(value);
                          }
                        }}
                        onOpen={() => {
                          this.setState({ openMajorOptions: true });
                        }}
                        onClose={() => {
                          this.setState({ openMajorOptions: false });
                        }}
                        getOptionSelected={(option, value) =>
                          option?._source?.Major === value?._source?.Major
                        }
                        options={this.state.majorOptions}
                        onBlur={(event) => {
                          this.handleMajorBlur(event);
                        }}
                        getOptionLabel={(option) => {
                          if (typeof option === "string") {
                            return option;
                          }
                          if (option.inputValue) {
                            return option.inputValue;
                          }
                          return option?._source?.Major;
                        }}
                        loading={this.state.majorLoading}
                        selectOnFocus
                        clearOnBlur
                        filterOptions={(options, params) => {
                          let filtered = options;
                          // const filtered = filter(options, params);
                          if (params.inputValue !== "") {
                            filtered.push({
                              inputValue: params.inputValue,
                              _source: {
                                Major: `Add: "${params.inputValue}"`,
                              },
                            });
                          }
                          return filtered;
                        }}
                        renderOption={(option) => option?._source?.Major}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            label="Major"
                            id="major"
                            name="major"
                            InputProps={{
                              ...params.InputProps,
                              endAdornment: (
                                <React.Fragment>
                                  {this.state.majorLoading ? (
                                    <CircularProgress
                                      className={classes.verySmallMarginRight}
                                      color="inherit"
                                      size={20}
                                    />
                                  ) : null}
                                  {params.InputProps.endAdornment}
                                </React.Fragment>
                              ),
                            }}
                            value={this.state.major}
                            onChange={this.handleInputChange("major")}
                            placeholder="Type to select major (optional)"
                          />
                        )}
                      />
                    </Grid>
                  </Grid>

                  <Grid
                    item
                    xs={12}
                    sm={12}
                    md={12}
                    lg={12}
                    xl={12}
                    className={classes.displayFlex}
                  >
                    <FormControl
                      component="fieldset"
                      className={classNames(
                        classes.fullWidth,
                        classes.mediumTopMargin,
                        classes.colorDefault
                      )}
                    >
                      <RadioGroup
                        aria-label="degreeRequired"
                        name="degreeRequired"
                        value={this.state.degreeRequiredOrPreferred}
                        onChange={this.handleInputChange(
                          "degreeRequiredOrPreferred"
                        )}
                      >
                        <Grid container>
                          <Grid item xs={12} sm={12} md={12} lg={4} xl={4}>
                            <FormLabel
                              component="legend"
                              style={{ marginTop: "14px" }}
                            >
                              Degree Required/Preferred?
                            </FormLabel>
                          </Grid>
                          <Grid
                            item
                            xs={12}
                            sm={12}
                            md={12}
                            lg={4}
                            xl={4}
                            className={classes.displayFlex}
                          >
                            <FormControlLabel
                              control={<Radio />}
                              label={
                                <Typography variant="body1">
                                  Required
                                </Typography>
                              }
                              value="Required"
                            />
                          </Grid>
                          <Grid
                            item
                            xs={12}
                            sm={12}
                            md={12}
                            lg={4}
                            xl={4}
                            className={classes.displayFlex}
                          >
                            <FormControlLabel
                              control={<Radio />}
                              label={
                                <Typography variant="body1">
                                  Preferred
                                </Typography>
                              }
                              value="Preferred"
                            />
                          </Grid>
                        </Grid>
                      </RadioGroup>
                    </FormControl>
                  </Grid>

                  <Grid
                    item
                    xs={12}
                    sm={12}
                    md={12}
                    lg={12}
                    xl={12}
                    style={{ position: "relative" }}
                  >
                    <Autocomplete
                      id="tags-outlined"
                      PopperComponent={Pop}
                      multiple
                      autoComplete
                      filterSelectedOptions
                      open={this.state.openCertificationOptions}
                      value={this.state.certifications}
                      onChange={(event, value) => {
                        if (typeof value === "string") {
                          setTimeout(() => {
                            this.setState({
                              customCertificationTitlePopUp: true,
                              customCertificationTitle: value,
                            });
                          });
                        } else if (
                          value &&
                          value[value.length - 1]?.inputValue
                        ) {
                          this.setState({
                            customCertificationTitlePopUp: true,
                            customCertificationTitle:
                              value[value.length - 1].inputValue,
                          });
                        } else {
                          this.setCertifications(value);
                        }
                      }}
                      onOpen={() => {
                        this.setState({ openCertificationOptions: true });
                      }}
                      onClose={() => {
                        this.setState({ openCertificationOptions: false });
                      }}
                      getOptionSelected={(option, value) =>
                        option?._source?.Certificate ===
                        value?._source?.Certificate
                      }
                      onBlur={(event) => {
                        this.handleCertificationsBlur(event);
                      }}
                      onSelect={(event) => {
                        this.handleCertificationsBlur(event);
                      }}
                      getOptionLabel={(option) => {
                        if (typeof option === "string") {
                          return option;
                        }
                        if (option.inputValue) {
                          return option.inputValue;
                        }
                        return option?._source?.Certificate;
                      }}
                      options={this.state.certificationsOptions}
                      loading={this.state.certificationsLoading}
                      selectOnFocus
                      clearOnBlur
                      filterOptions={(options, params) => {
                        let filtered = options;
                        // const filtered = filter(options, params);
                        if (params.inputValue !== "") {
                          filtered.push({
                            inputValue: params.inputValue,
                            _source: {
                              Certificate: `Add: "${params.inputValue}"`,
                            },
                          });
                        }
                        return filtered;
                      }}
                      renderOption={(option) => option?._source?.Certificate}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          id="certifications"
                          name="certifications"
                          label="Professional Certifications"
                          value={this.state.certificationsValue}
                          className={classes.customSmallTopMargin}
                          InputProps={{
                            ...params.InputProps,
                            endAdornment: (
                              <React.Fragment>
                                {this.state.certificationsLoading ? (
                                  <CircularProgress
                                    className={classes.verySmallMarginRight}
                                    color="inherit"
                                    size={20}
                                  />
                                ) : null}
                                {params.InputProps.endAdornment}
                              </React.Fragment>
                            ),
                            inputProps: {
                              ...params.inputProps,
                              maxLength: 100,
                            },
                          }}
                          onChange={this.handleInputChange(
                            "certificationsValue"
                          )}
                          placeholder={
                            this.state.certificationsValue === "" ||
                            this.state.certifications.length < 1
                              ? "Type to select from options"
                              : ""
                          }
                        />
                      )}
                    />
                  </Grid>

                  <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                    <FormControl
                      component="fieldset"
                      className={classNames(
                        classes.fullWidth,
                        classes.mediumTopMargin,
                        classes.colorDefault
                      )}
                    >
                      <RadioGroup
                        aria-label="remote"
                        name="remote"
                        value={this.state.remote}
                        onChange={this.handleInputChange("remote")}
                      >
                        <Grid container>
                          <Grid xs={12} sm={12} md={12} lg={3} xl={3}>
                            <FormLabel
                              style={{ marginTop: "14px" }}
                              component="legend"
                            >
                              Work Location?
                            </FormLabel>
                          </Grid>
                          {constants?.remote_or_onsite.map((option) => (
                            <Grid
                              item
                              xs={12}
                              sm={12}
                              md={12}
                              lg={3}
                              xl={3}
                              className={classes.displayFlex}
                            >
                              <FormControlLabel
                                control={<Radio />}
                                label={
                                  <Typography variant="body1">
                                    {option}
                                  </Typography>
                                }
                                value={option}
                              />
                            </Grid>
                          ))}
                        </Grid>
                      </RadioGroup>
                    </FormControl>
                  </Grid>

                  <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                    <FormControl
                      component="fieldset"
                      className={classNames(
                        classes.fullWidth,
                        classes.mediumTopMargin,
                        classes.colorDefault
                      )}
                    >
                      <RadioGroup
                        aria-label="travelLevel"
                        name="travelLevel"
                        value={this.state.travelLevel}
                        onChange={this.handleInputChange("travelLevel")}
                      >
                        <Grid container>
                          <Grid xs={12} sm={12} md={12} lg={3} xl={3}>
                            <FormLabel
                              component="legend"
                              style={{ marginTop: "14px" }}
                            >
                              Travel Requirements
                            </FormLabel>
                          </Grid>
                          {constants?.travel_requirements.map((option) => (
                            <Grid
                              item
                              xs={12}
                              sm={12}
                              md={12}
                              lg={3}
                              xl={3}
                              className={classes.displayFlex}
                            >
                              <FormControlLabel
                                className={classes.noWrap}
                                control={<Radio />}
                                label={
                                  <Typography variant="body1">
                                    {option}
                                  </Typography>
                                }
                                value={option}
                              />
                            </Grid>
                          ))}
                        </Grid>
                      </RadioGroup>
                    </FormControl>
                  </Grid>

                  <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                    <FormControl
                      component="fieldset"
                      className={classNames(
                        classes.fullWidth,
                        classes.mediumTopMargin,
                        classes.colorDefault
                      )}
                    >
                      <FormLabel
                        component="span"
                        className={classes.justifyContent}
                      >
                        How soon do you want to fill this role?
                      </FormLabel>
                      <RadioGroup
                        aria-label="howSoonToHire"
                        name="howSoonToHire"
                        value={this.state.howSoonToHire}
                        onChange={this.handleInputChange("howSoonToHire")}
                      >
                        <Grid container>
                          {constants?.jobs_notice_period.map((option) => (
                            <Grid
                              item
                              xs={12}
                              sm={12}
                              md={12}
                              lg={4}
                              xl={4}
                              className={classes.displayFlex}
                            >
                              <FormControlLabel
                                className={classes.noWrap}
                                control={<Radio />}
                                label={option}
                                value={option}
                              />
                            </Grid>
                          ))}
                        </Grid>
                      </RadioGroup>
                    </FormControl>
                  </Grid>

                  <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                    <div className={classes.subHeader}>
                      Corporate/Startup Information
                    </div>
                  </Grid>
                  <Grid container>
                    <Grid
                      item
                      xs={12}
                      sm={12}
                      md={12}
                      lg={12}
                      xl={12}
                      style={{ marginTop: "6px" }}
                      className={classNames(
                        classes.fullWidth,
                        classes.colorDefault
                      )}
                    >
                      <FormLabel component="legend">Company Size</FormLabel>
                    </Grid>
                    {constants?.company_size.map((option) => (
                      <Grid md={4} xl={4}>
                        <FormControlLabel
                          key={option}
                          control={
                            <Checkbox
                              onChange={this.handleCheckBox("StartupSize")}
                              name={option}
                              value={option}
                              iconStyle={{ fill: "#048475" }}
                              style={{ color: "#048475" }}
                            />
                          }
                          label={
                            <Typography
                              variant="body2"
                              style={{ color: "black", fontWeight: 400 }}
                            >
                              {option}
                            </Typography>
                          }
                        />
                      </Grid>
                    ))}
                  </Grid>

                  <Grid
                    item
                    xs={12}
                    sm={12}
                    md={12}
                    lg={12}
                    xl={12}
                    className={classNames(
                      classes.fullWidth,
                      classes.mediumTopMargin,
                      classes.colorDefault
                    )}
                  >
                    <FormLabel component="legend">Company Type</FormLabel>
                  </Grid>
                  <Grid container>
                    {constants?.size_and_stages.map((option) => (
                      <Grid md={4} xl={4}>
                        <FormControlLabel
                          key={option}
                          control={
                            <Checkbox
                              onChange={this.handleCheckBox("StartupStage")}
                              name={option}
                              value={option}
                              iconStyle={{ fill: "#048475" }}
                              style={{ color: "#048475" }}
                            />
                          }
                          label={
                            <Typography
                              variant="body2"
                              style={{ color: "black", fontWeight: 400 }}
                            >
                              {option}
                            </Typography>
                          }
                        />
                      </Grid>
                    ))}
                  </Grid>

                  <Grid
                    className={classNames(
                      classes.fullWidth,
                      classes.mediumTopMargin,
                      classes.colorDefault
                    )}
                    item
                    xs={12}
                    sm={12}
                    md={12}
                    lg={12}
                    xl={12}
                  >
                    <FormLabel component="legend">Team size</FormLabel>
                  </Grid>
                  <Grid container>
                    {constants?.team_size.map((option) => (
                      <Grid md={4} xl={4}>
                        <FormControlLabel
                          key={option}
                          control={
                            <Checkbox
                              onChange={this.handleCheckBox("TeamSize")}
                              name={option}
                              value={option}
                              iconStyle={{ fill: "#048475" }}
                              style={{ color: "#048475" }}
                            />
                          }
                          label={
                            <Typography
                              variant="body2"
                              style={{ color: "black", fontWeight: 400 }}
                            >
                              {option}
                            </Typography>
                          }
                        />
                      </Grid>
                    ))}
                  </Grid>

                  <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                    <FormControl
                      component="fieldset"
                      className={classNames(
                        classes.fullWidth,
                        classes.mediumTopMargin,
                        classes.colorDefault
                      )}
                    >
                      <RadioGroup
                        aria-label="role"
                        name="role"
                        value={this.state.role}
                        onChange={this.handleRole}
                        row
                      >
                        <Grid container>
                          <Grid item xs={12} sm={12} md={12} lg={4} xl={4}>
                            <FormLabel
                              component="legend"
                              style={{ marginTop: "14px" }}
                            >
                              New Role/Existing Role
                            </FormLabel>
                          </Grid>
                          <Grid item xs={12} sm={12} md={12} lg={4} xl={4}>
                            <FormControlLabel
                              control={<Radio />}
                              label={
                                <Typography variant="body1">
                                  New Role
                                </Typography>
                              }
                              value={"New Role"}
                            />
                          </Grid>
                          <Grid item xs={12} sm={12} md={12} lg={4} xl={4}>
                            <FormControlLabel
                              control={<Radio />}
                              label={
                                <Typography variant="body1">
                                  Existing Role
                                </Typography>
                              }
                              value={"Existing Role"}
                            />
                          </Grid>
                        </Grid>
                      </RadioGroup>
                    </FormControl>
                  </Grid>

                  <Grid
                    className={classNames(
                      classes.mediumTopMargin,
                      classes.verySmallBottomMargin
                    )}
                  >
                    <FormLabel>Sample Corporation</FormLabel>
                  </Grid>
                  <Grid item xs={12} sm={12}>
                    <Autocomplete
                      autoComplete
                      filterSelectedOptions
                      multiple
                      id="tags-standard"
                      options={this.state.companyOptions}
                      getOptionLabel={(option) => option.name}
                      loading={this.state.companyLoading}
                      getOptionSelected={(option, value) =>
                        option.name === value.name
                      }
                      getOptionDisabled={() =>
                        this.state.sampleCompanyValues?.length === 10
                          ? true
                          : false
                      }
                      selectOnFocus
                      clearOnBlur
                      renderTags={(value, getTagProps) =>
                        value.map((option, index) => (
                          <Chip
                            variant="default"
                            label={option.name}
                            {...getTagProps({ index })}
                            className={classes.chip}
                            style={{
                              height: "100%",
                              wordBreak: "break-word",
                              padding: "5px",
                              margin: "5px 4px 8px 0px",
                            }}
                          />
                        ))
                      }
                      onChange={(event, value) => {
                        this.setState({ sampleCompanyValues: value });
                      }}
                      filterOptions={(options) => {
                        let filtered = options;
                        return filtered;
                      }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          placeholder={"Type company name and select"}
                          variant={"outlined"}
                          onChange={(event) => {
                            this.setState({ companyLoading: true });
                            this.handleExpCompany(event);
                          }}
                          InputProps={{
                            ...params.InputProps,
                            endAdornment: (
                              <React.Fragment>
                                {this.state.companyLoading ? (
                                  <CircularProgress
                                    className={classes.verySmallMarginRight}
                                    color="inherit"
                                    size={20}
                                  />
                                ) : null}
                                {params.InputProps.endAdornment}
                              </React.Fragment>
                            ),
                            inputProps: {
                              ...params.inputProps,
                              maxLength: 1000,
                            },
                          }}
                          value={this.state.companyNameValueInput}
                        />
                      )}
                    />
                  </Grid>
                  <div className={classNames(classes.mediumTopMargin)}>
                    <FormLabel className={classes.subHeader}>
                      About Us
                    </FormLabel>
                  </div>
                  <Grid
                    item
                    xs={12}
                    sm={12}
                    md={12}
                    lg={12}
                    xl={12}
                    className={classes.customSmallTopMargin}
                  >
                    <Editor
                      toolbar={{
                        options: [
                          "inline",
                          "blockType",
                          "fontSize",
                          "fontFamily",
                          "list",
                          "textAlign",
                          "colorPicker",
                          "link",
                          "remove",
                          "history",
                        ],
                        inline: {
                          options: [
                            "bold",
                            "italic",
                            "underline",
                            "strikethrough",
                          ],
                          inDropdown: true,
                        },
                        list: {
                          options: ["unordered", "ordered"],
                          inDropdown: true,
                        },
                        textAlign: { inDropdown: true },
                        link: { inDropdown: true },
                        history: { inDropdown: true },
                      }}
                      placeholder={
                        "Give a brief description about your company"
                      }
                      editorState={editorStateAboutUs}
                      onEditorStateChange={this.onAboutUsChange}
                      onChange={this.handleInputChange("aboutUs")}
                      handleBeforeInput={(val) => {
                        const textLength = editorStateAboutUs
                          .getCurrentContent()
                          .getPlainText().length;
                        if (val && textLength >= charCount) {
                          toast.dark(
                            `Allowed character limit is ${charCount} characters only`
                          );
                          return "handled";
                        }
                        return "not-handled";
                      }}
                      handlePastedText={(input) => {
                        const inputLength = editorStateAboutUs
                          .getCurrentContent()
                          .getPlainText().length;
                        let remainingLength = charCount - inputLength;
                        if (input.length + inputLength >= charCount) {
                          const newContent = Modifier.insertText(
                            editorStateAboutUs.getCurrentContent(),
                            editorStateAboutUs.getSelection(),
                            input.slice(0, remainingLength)
                          );
                          this.onAboutUsChange(
                            EditorState.push(
                              editorStateAboutUs,
                              newContent,
                              "insert-characters"
                            )
                          );
                          toast.dark(
                            `Allowed character limit is ${charCount} characters only`
                          );
                          return true;
                        } else {
                          return false;
                        }
                      }}
                    />
                    <div>
                      {" "}
                      <Typography
                        className={classNames(
                          classes.floatRight,
                          classes.verySmallTopMargin,
                          classes.verySmallBottomMargin
                        )}
                        color="secondary"
                      >
                        {editorStateAboutUs?.getCurrentContent()?.getPlainText()
                          ?.length +
                          "/" +
                          charCount}{" "}
                        characters
                      </Typography>
                    </div>
                  </Grid>
                  <div
                    className={classNames(
                      classes.mediumTopMargin,
                      classes.verySmallBottomMargin
                    )}
                  >
                    <FormLabel className={classes.subHeader}>
                      Job Description
                    </FormLabel>
                  </div>
                  <div
                    className={classNames(
                      classes.noBottomMargin,
                      classes.noBottomMargin
                    )}
                  >
                    <FormLabel className={classes.customHeader} required>
                      About the Role
                    </FormLabel>
                  </div>
                  <Grid
                    item
                    xs={12}
                    sm={12}
                    md={12}
                    lg={12}
                    xl={12}
                    className={classes.customSmallTopMargin}
                  >
                    <Editor
                      toolbar={{
                        options: [
                          "inline",
                          "blockType",
                          "fontSize",
                          "fontFamily",
                          "list",
                          "textAlign",
                          "colorPicker",
                          "link",
                          "remove",
                          "history",
                        ],
                        inline: {
                          options: [
                            "bold",
                            "italic",
                            "underline",
                            "strikethrough",
                          ],
                          inDropdown: true,
                        },
                        list: {
                          options: ["unordered", "ordered"],
                          inDropdown: true,
                        },
                        textAlign: { inDropdown: true },
                        link: { inDropdown: true },
                        history: { inDropdown: true },
                      }}
                      editorState={editorStateJobDescription}
                      onEditorStateChange={this.onJobDescriptionChange}
                      onChange={this.handleInputChange("jobDescription")}
                      handleBeforeInput={(val) => {
                        const textLength = editorStateJobDescription
                          .getCurrentContent()
                          .getPlainText().length;
                        if (val && textLength >= descriptionCharCount) {
                          toast.dark(
                            `Allowed character limit is ${descriptionCharCount} characters only`
                          );
                          return "handled";
                        }
                        return "not-handled";
                      }}
                      placeholder="Give a brief description about the role"
                      handlePastedText={(input) => {
                        const inputLength = editorStateJobDescription
                          .getCurrentContent()
                          .getPlainText().length;
                        let remainingLength =
                          descriptionCharCount - inputLength;
                        if (
                          input.length + inputLength >=
                          descriptionCharCount
                        ) {
                          const newContent = Modifier.insertText(
                            editorStateJobDescription.getCurrentContent(),
                            editorStateJobDescription.getSelection(),
                            input.slice(0, remainingLength)
                          );
                          this.onJobDescriptionChange(
                            EditorState.push(
                              editorStateJobDescription,
                              newContent,
                              "insert-characters"
                            )
                          );
                          toast.dark(
                            `Allowed character limit is ${descriptionCharCount} characters only`
                          );
                          return true;
                        } else {
                          return false;
                        }
                      }}
                    />
                    {this.state.isPositionDescriptionRequire ? (
                      <FormHelperText
                        error={this.state.isPositionDescriptionRequire}
                      >
                        {MESSAGE_FIELD_IS_REQUIRED}
                      </FormHelperText>
                    ) : !this.state.isValidPositionDescription ? (
                      <FormHelperText
                        error={!this.state.isValidPositionDescription}
                      >
                        {"Min 25 charachers required"}
                      </FormHelperText>
                    ) : null}
                    <Typography
                      className={classNames(
                        classes.floatRight,
                        classes.verySmallTopMargin
                      )}
                      color="secondary"
                    >
                      {editorStateJobDescription
                        ?.getCurrentContent()
                        ?.getPlainText()?.length +
                        "/" +
                        descriptionCharCount}{" "}
                      characters
                    </Typography>
                  </Grid>
                  <div
                    className={classNames(
                      classes.mediumTopMargin,
                      classes.noBottomMargin
                    )}
                  >
                    <FormLabel className={classes.customHeader}>
                      Additional responsibilities include
                    </FormLabel>
                  </div>
                  <Grid
                    item
                    xs={12}
                    sm={12}
                    md={12}
                    lg={12}
                    xl={12}
                    className={classes.customSmallTopMargin}
                  >
                    <Editor
                      toolbar={{
                        options: [
                          "inline",
                          "blockType",
                          "fontSize",
                          "fontFamily",
                          "list",
                          "textAlign",
                          "colorPicker",
                          "link",
                          "remove",
                          "history",
                        ],
                        inline: {
                          options: [
                            "bold",
                            "italic",
                            "underline",
                            "strikethrough",
                          ],
                          inDropdown: true,
                        },
                        list: {
                          options: ["unordered", "ordered"],
                          inDropdown: true,
                        },
                        textAlign: { inDropdown: true },
                        link: { inDropdown: true },
                        history: { inDropdown: true },
                      }}
                      editorState={additionresponsibilities}
                      onEditorStateChange={this.onAdditionresponsibilities}
                      onChange={this.handleInputChange(
                        "Additionresponsibilities"
                      )}
                      handleBeforeInput={(val) => {
                        const textLength = additionresponsibilities
                          .getCurrentContent()
                          .getPlainText().length;
                        if (val && textLength >= descriptionCharCount) {
                          toast.dark(
                            `Allowed character limit is ${descriptionCharCount} characters only`
                          );
                          return "handled";
                        }
                        return "not-handled";
                      }}
                      placeholder="Give a brief description about additional responsibilities"
                      handlePastedText={(input) => {
                        const inputLength = additionresponsibilities
                          .getCurrentContent()
                          .getPlainText().length;
                        let remainingLength =
                          descriptionCharCount - inputLength;
                        if (
                          input.length + inputLength >=
                          descriptionCharCount
                        ) {
                          const newContent = Modifier.insertText(
                            additionresponsibilities.getCurrentContent(),
                            additionresponsibilities.getSelection(),
                            input.slice(0, remainingLength)
                          );
                          this.onAdditionresponsibilities(
                            EditorState.push(
                              additionresponsibilities,
                              newContent,
                              "insert-characters"
                            )
                          );
                          toast.dark(
                            `Allowed character limit is ${descriptionCharCount} characters only`
                          );
                          return true;
                        } else {
                          return false;
                        }
                      }}
                    />
                    <Typography
                      className={classNames(
                        classes.floatRight,
                        classes.verySmallTopMargin
                      )}
                      color="secondary"
                    >
                      {additionresponsibilities
                        ?.getCurrentContent()
                        ?.getPlainText()?.length +
                        "/" +
                        descriptionCharCount}{" "}
                      characters
                    </Typography>
                  </Grid>

                  <div
                    className={classNames(
                      classes.mediumTopMargin,
                      classes.noBottomMargin
                    )}
                  >
                    <FormLabel className={classes.customHeader}>
                      What a successful candidate will bring?
                    </FormLabel>
                  </div>
                  <Grid
                    item
                    xs={12}
                    sm={12}
                    md={12}
                    lg={12}
                    xl={12}
                    className={classes.customSmallTopMargin}
                  >
                    <Editor
                      toolbar={{
                        options: [
                          "inline",
                          "blockType",
                          "fontSize",
                          "fontFamily",
                          "list",
                          "textAlign",
                          "colorPicker",
                          "link",
                          "remove",
                          "history",
                        ],
                        inline: {
                          options: [
                            "bold",
                            "italic",
                            "underline",
                            "strikethrough",
                          ],
                          inDropdown: true,
                        },
                        list: {
                          options: ["unordered", "ordered"],
                          inDropdown: true,
                        },
                        textAlign: { inDropdown: true },
                        link: { inDropdown: true },
                        history: { inDropdown: true },
                      }}
                      editorState={editorcandidateWillBring}
                      onEditorStateChange={this.onCandidateWillBring}
                      onChange={this.handleInputChange(
                        "Successfulcandidatewillbring"
                      )}
                      placeholder={"What a successful candidate will bring?"}
                      handleBeforeInput={(val) => {
                        const textLength = editorcandidateWillBring
                          .getCurrentContent()
                          .getPlainText().length;
                        if (val && textLength >= descriptionCharCount) {
                          toast.dark(
                            `Allowed character limit is ${descriptionCharCount} characters only`
                          );
                          return "handled";
                        }
                        return "not-handled";
                      }}
                      handlePastedText={(input) => {
                        const inputLength = editorcandidateWillBring
                          .getCurrentContent()
                          .getPlainText().length;
                        let remainingLength =
                          descriptionCharCount - inputLength;
                        if (
                          input.length + inputLength >=
                          descriptionCharCount
                        ) {
                          const newContent = Modifier.insertText(
                            editorcandidateWillBring.getCurrentContent(),
                            editorcandidateWillBring.getSelection(),
                            input.slice(0, remainingLength)
                          );
                          this.onCandidateWillBring(
                            EditorState.push(
                              editorcandidateWillBring,
                              newContent,
                              "insert-characters"
                            )
                          );
                          toast.dark(
                            `Allowed character limit is ${descriptionCharCount} characters only`
                          );
                          return true;
                        } else {
                          return false;
                        }
                      }}
                    />
                    <Typography
                      className={classNames(
                        classes.floatRight,
                        classes.verySmallTopMargin
                      )}
                      color="secondary"
                    >
                      {editorcandidateWillBring
                        ?.getCurrentContent()
                        ?.getPlainText()?.length +
                        "/" +
                        descriptionCharCount}{" "}
                      characters
                    </Typography>
                  </Grid>
                  <div
                    className={classNames(
                      classes.mediumTopMargin,
                      classes.noBottomMargin
                    )}
                  >
                    <FormLabel className={classes.customHeader}>
                      Absolute MUST HAVEs(non-negotiables)
                    </FormLabel>
                  </div>
                  <Grid
                    item
                    xs={12}
                    sm={12}
                    md={12}
                    lg={12}
                    xl={12}
                    className={classes.customSmallTopMargin}
                  >
                    <Editor
                      toolbar={{
                        options: [
                          "inline",
                          "blockType",
                          "fontSize",
                          "fontFamily",
                          "list",
                          "textAlign",
                          "colorPicker",
                          "link",
                          "remove",
                          "history",
                        ],
                        inline: {
                          options: [
                            "bold",
                            "italic",
                            "underline",
                            "strikethrough",
                          ],
                          inDropdown: true,
                        },
                        list: {
                          options: ["unordered", "ordered"],
                          inDropdown: true,
                        },
                        textAlign: { inDropdown: true },
                        link: { inDropdown: true },
                        history: { inDropdown: true },
                      }}
                      editorState={editerAbsoluteMustHave}
                      onEditorStateChange={this.onAbsoluteMustHave}
                      onChange={this.handleInputChange("absolueMustHave")}
                      placeholder={"Absolute MUST HAVEs(non-negotiables)"}
                      handleBeforeInput={(val) => {
                        const textLength = editerAbsoluteMustHave
                          .getCurrentContent()
                          .getPlainText()?.length;
                        if (val && textLength >= descriptionCharCount) {
                          toast.dark(
                            `Allowed character limit is ${descriptionCharCount} characters only`
                          );
                          return "handled";
                        }
                        return "not-handled";
                      }}
                      handlePastedText={(input) => {
                        const inputLength = editerAbsoluteMustHave
                          .getCurrentContent()
                          .getPlainText().length;
                        let remainingLength =
                          descriptionCharCount - inputLength;
                        if (
                          input.length + inputLength >=
                          descriptionCharCount
                        ) {
                          const newContent = Modifier.insertText(
                            editerAbsoluteMustHave.getCurrentContent(),
                            editerAbsoluteMustHave.getSelection(),
                            input.slice(0, remainingLength)
                          );
                          this.onAbsoluteMustHave(
                            EditorState.push(
                              editerAbsoluteMustHave,
                              newContent,
                              "insert-characters"
                            )
                          );
                          toast.dark(
                            `Allowed character limit is ${descriptionCharCount} characters only`
                          );
                          return true;
                        } else {
                          return false;
                        }
                      }}
                    />
                    <Typography
                      className={classNames(
                        classes.floatRight,
                        classes.verySmallTopMargin
                      )}
                      color="secondary"
                    >
                      {editerAbsoluteMustHave
                        ?.getCurrentContent()
                        ?.getPlainText()?.length +
                        "/" +
                        descriptionCharCount}{" "}
                      characters
                    </Typography>
                  </Grid>
                  <div
                    className={classNames(
                      classes.mediumTopMargin,
                      classes.noBottomMargin
                    )}
                  >
                    <FormLabel className={classes.subHeader}>
                      Competencies
                    </FormLabel>
                  </div>
                  <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                    <Autocomplete
                      multiple
                      freeSolo
                      selectOnFocus
                      clearOnBlur
                      id="tags-outlined"
                      name="skills"
                      autoComplete
                      filterSelectedOptions
                      value={this.state.skills}
                      onChange={(event, newValue) => {
                        event.preventDefault();
                        event.stopPropagation();
                        if (newValue?.length <= 50) {
                          this.setState({
                            skills: newValue,
                            isSkillsRequire: false,
                          });
                        }
                        if (newValue?.length === 0) {
                          this.setState({
                            isSkillsRequire: true,
                            isValidSkill: true,
                            skills: [],
                          });
                        }
                        newValue.map((ele) => {
                          if (ele.length > 1000) {
                            this.setState({ isValidSkill: false });
                            return [];
                          } else {
                            this.setState({ isValidSkill: true });
                            return [];
                          }
                        });
                      }}
                      getOptionDisabled={() =>
                        this.state.skills?.length === 50 ? true : false
                      }
                      renderTags={(value, getTagProps) =>
                        value.map((option, index) => {
                          if (option.length > 1000) {
                            return [];
                          }
                          return (
                            <Chip
                              label={option}
                              {...getTagProps({ index })}
                              className={classes.chip}
                              style={{
                                height: "100%",
                                wordBreak: "break-word",
                                padding: "5px",
                                margin: "5px 4px 8px 0px",
                              }}
                            />
                          );
                        })
                      }
                      options={[]}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          id="skills"
                          name="skills"
                          required
                          className={classes.customSmallTopMargin}
                          variant="outlined"
                          InputProps={{
                            ...params.InputProps,
                            inputProps: {
                              ...params.inputProps,
                              maxLength: 1000,
                            },
                          }}
                          placeholder={
                            this.state.skills?.length < 1
                              ? "Add desired competency for this role here and press enter"
                              : ""
                          }
                        />
                      )}
                    />
                  </Grid>
                </DialogContentText>
              </DialogContent>
              <DialogActions className={classes.dialogActions}>
                <Button
                  className={classes.justifyRight}
                  variant="contained"
                  color="primary"
                  disableElevation
                  size="large"
                  onClick={(event) => {
                    this.handlePreviewClick(event);
                  }}
                >
                  Preview
                </Button>
              </DialogActions>
            </Dialog>

            {/* Preview Dialog */}
            <Dialog
              hideBackdrop
              open={this.state.openPreview}
              scroll={this.state.scroll}
              fullWidth
              aria-labelledby="scroll-dialog-title"
              aria-describedby="scroll-dialog-description"
              disableBackdropClick
              disableEscapeKeyDown
              maxWidth="md"
            >
              <DialogTitle
                id="customized-dialog-title"
                onClose={(event) => {
                  this.handleClose(event);
                }}
              >
                Job Preview
              </DialogTitle>
              <DialogContent dividers={this.state.scroll === "paper"}>
                <DialogContentText
                  component="div"
                  id="scroll-dialog-description"
                >
                  <Avatar
                    src={
                      JSON.parse(
                        secureLocalStorage.getItem("recruiterStatusDetails")
                      ).company.logo ||
                      this.capitalize(
                        JSON.parse(
                          secureLocalStorage.getItem("recruiterStatusDetails")
                        ).company.company_name
                      )
                    }
                    variant="circular"
                    className={classNames(
                      classes.xLargeAvatar,
                      JSON.parse(
                        secureLocalStorage.getItem("recruiterStatusDetails")
                      ).company.logo === "" ||
                        !JSON.parse(
                          secureLocalStorage.getItem("recruiterStatusDetails")
                        ).company.logo
                        ? classes.noImageAvatar
                        : null
                    )}
                  ></Avatar>
                  <Typography
                    className={classNames(
                      classes.smallTopMargin,
                      classes.header
                    )}
                  >
                    {this.state.jobTitleValue}
                  </Typography>
                  <Typography component="div" color="primary">
                    {this.capitalize(
                      JSON.parse(
                        secureLocalStorage.getItem("recruiterStatusDetails")
                      ).company.company_name
                    )}
                  </Typography>
                  <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                    <div className={classes.subHeader}>Basics</div>
                    <Card
                      className={classNames(
                        classes.root,
                        classes.customSmallTopMargin,
                        classes.customCardShadow
                      )}
                    >
                      <CardContent className={classes.customCard}>
                        <Grid container>
                          <Grid item xs={12} sm={12} md={12} lg={7} xl={7}>
                            <Typography
                              className={classes.mediumBottomMargin}
                              color="textSecondary"
                            >
                              Job Level
                            </Typography>
                          </Grid>
                          <Grid
                            className={classes.textAlignEnd}
                            item
                            xs={12}
                            sm={12}
                            md={12}
                            lg={5}
                            xl={5}
                          >
                            {this.state.jobLevel || "-"}
                          </Grid>
                          <Grid item xs={12} sm={12} md={12} lg={7} xl={7}>
                            <Typography
                              className={classes.mediumBottomMargin}
                              color="textSecondary"
                            >
                              Reports To
                            </Typography>
                          </Grid>
                          <Grid
                            className={classes.textAlignEnd}
                            item
                            xs={12}
                            sm={12}
                            md={12}
                            lg={5}
                            xl={5}
                          >
                            {this.state.reportsToTitleValue || "-"}
                          </Grid>
                          {this.state.reportsTo?.length > 0 ? (
                            <>
                              <Grid item xs={12} sm={12} md={12} lg={7} xl={7}>
                                <Typography
                                  className={classes.mediumBottomMargin}
                                  color="textSecondary"
                                >
                                  Hiring Manager Name
                                </Typography>
                              </Grid>
                              <Grid
                                className={classes.textAlignEnd}
                                item
                                xs={12}
                                sm={12}
                                md={12}
                                lg={5}
                                xl={5}
                              >
                                {this.state.reportsTo || "-"}
                              </Grid>
                            </>
                          ) : null}

                          <Grid item xs={12} sm={12} md={12} lg={7} xl={7}>
                            <Typography
                              className={classes.mediumBottomMargin}
                              color="textSecondary"
                            >
                              Industry
                            </Typography>
                          </Grid>
                          <Grid
                            className={classes.textAlignEnd}
                            item
                            xs={12}
                            sm={12}
                            md={12}
                            lg={5}
                            xl={5}
                          >
                            {this.state.industry || "-"}
                          </Grid>
                          <Grid item xs={12} sm={12} md={12} lg={7} xl={7}>
                            <Typography
                              className={classes.mediumBottomMargin}
                              color="textSecondary"
                            >
                              Function
                            </Typography>
                          </Grid>
                          <Grid
                            className={classes.textAlignEnd}
                            item
                            xs={12}
                            sm={12}
                            md={12}
                            lg={5}
                            xl={5}
                          >
                            {this.state.functionName !== "Other"
                              ? this.state.functionName || "-"
                              : this.state.functionName +
                                " - " +
                                (this.state.otherFunctionName || "-")}
                          </Grid>
                          <Grid item xs={12} sm={12} md={12} lg={7} xl={7}>
                            <Typography
                              className={classes.mediumBottomMargin}
                              color="textSecondary"
                            >
                              Company HQ Location
                            </Typography>
                          </Grid>
                          <Grid
                            className={classes.textAlignEnd}
                            item
                            xs={12}
                            sm={12}
                            md={12}
                            lg={5}
                            xl={5}
                          >
                            {this.state.location || "-"}
                          </Grid>
                          <Grid item xs={12} sm={12} md={12} lg={7} xl={7}>
                            <Typography
                              className={classes.mediumBottomMargin}
                              color="textSecondary"
                            >
                              Minimum Years of Experience
                            </Typography>
                          </Grid>
                          <Grid
                            className={classes.textAlignEnd}
                            item
                            xs={12}
                            sm={12}
                            md={12}
                            lg={5}
                            xl={5}
                          >
                            {this.state.minimumExperience || "-"}
                          </Grid>
                          <Grid item xs={12} sm={12} md={12} lg={7} xl={7}>
                            <Typography
                              className={classes.mediumBottomMargin}
                              color="textSecondary"
                            >
                              Base Salary
                            </Typography>
                          </Grid>

                          <Grid
                            className={classes.textAlignEnd}
                            item
                            xs={12}
                            sm={12}
                            md={12}
                            lg={5}
                            xl={5}
                          >
                            {this.state.minimumBaseSalary === null
                              ? "-"
                              : this.state.salary_config === 0
                              ? "Exclude salary"
                              : this.state.salary_config === 1
                              ? `$${salaryValueFormat.format(
                                  this.state.minimumBaseSalary
                                )} ${`(Not visible to candidates)`}`
                              : `$${salaryValueFormat.format(
                                  this.state.minimumBaseSalary
                                )}`}
                          </Grid>
                          <Grid item xs={12} sm={12} md={12} lg={7} xl={7}>
                            <Typography
                              className={classes.mediumBottomMargin}
                              color="textSecondary"
                            >
                              Degree/Major
                            </Typography>
                          </Grid>
                          <Grid
                            className={classes.textAlignEnd}
                            item
                            xs={12}
                            sm={12}
                            md={12}
                            lg={5}
                            xl={5}
                          >
                            {(this.state.degree
                              ? this.state.degree + "/"
                              : "-/") +
                              (this.state.majorValue
                                ? this.state.majorValue?._source?.Major ||
                                  this.state.majorValue
                                : "-") +
                              (this.state.degreeRequiredOrPreferred
                                ? " (" +
                                  this.state.degreeRequiredOrPreferred +
                                  ")"
                                : "")}
                          </Grid>
                          <Grid item xs={12} sm={12} md={12} lg={7} xl={7}>
                            <Typography
                              className={classes.mediumBottomMargin}
                              color="textSecondary"
                            >
                              Degree Required/Preferred?
                            </Typography>
                          </Grid>
                          <Grid
                            className={classes.textAlignEnd}
                            item
                            xs={12}
                            sm={12}
                            md={12}
                            lg={5}
                            xl={5}
                          >
                            <Typography className={classes.mediumBottomMargin}>
                              {" "}
                              {this.state.degreeRequiredOrPreferred || "-"}
                            </Typography>
                          </Grid>

                          <Grid item xs={12} sm={12} md={12} lg={7} xl={7}>
                            <Typography
                              className={classes.mediumBottomMargin}
                              color="textSecondary"
                            >
                              Work Location
                            </Typography>
                          </Grid>
                          <Grid
                            className={classes.textAlignEnd}
                            item
                            xs={12}
                            sm={12}
                            md={12}
                            lg={5}
                            xl={5}
                          >
                            {this.state.remote || "-"}
                          </Grid>
                          <Grid item xs={12} sm={12} md={12} lg={7} xl={7}>
                            <Typography
                              className={classes.mediumBottomMargin}
                              color="textSecondary"
                            >
                              Travel Requirements
                            </Typography>
                          </Grid>
                          <Grid
                            className={classes.textAlignEnd}
                            item
                            xs={12}
                            sm={12}
                            md={12}
                            lg={5}
                            xl={5}
                          >
                            {this.state.travelLevel || "-"}
                          </Grid>

                          <Grid item xs={12} sm={12} md={12} lg={7} xl={7}>
                            <Typography
                              className={classes.mediumBottomMargin}
                              color="textSecondary"
                            >
                              How soon do you want to fill this role?
                            </Typography>
                          </Grid>
                          <Grid item xs={12} sm={12} md={12} lg={7} xl={7}>
                            <Typography
                              className={classes.mediumBottomMargin}
                              color="textSecondary"
                            >
                              How soon do you want to fill this role?
                            </Typography>
                          </Grid>
                          <Grid
                            className={classes.textAlignEnd}
                            item
                            xs={12}
                            sm={12}
                            md={12}
                            lg={5}
                            xl={5}
                          >
                            {this.state.howSoonToHire || "-"}
                          </Grid>
                          {this.state.startupSize?.length > 0 ? (
                            <>
                              <Grid item xs={12} sm={12} md={12} lg={7} xl={7}>
                                <Typography
                                  color="textSecondary"
                                  className={classes.mediumBottomMargin}
                                >
                                  Company Size{" "}
                                </Typography>
                              </Grid>
                              <Grid
                                className={classes.textAlignEnd}
                                item
                                xs={12}
                                sm={12}
                                md={12}
                                lg={5}
                                xl={5}
                              >
                                {this.state.startupSize?.join(", ") || "-"}
                              </Grid>
                            </>
                          ) : null}
                          {this.state.startupStage?.length > 0 ? (
                            <>
                              <Grid item xs={12} sm={12} md={12} lg={7} xl={7}>
                                <Typography
                                  color="textSecondary"
                                  className={classes.mediumBottomMargin}
                                >
                                  Company Type
                                </Typography>
                              </Grid>
                              <Grid
                                className={classes.textAlignEnd}
                                item
                                xs={12}
                                sm={12}
                                md={12}
                                lg={5}
                                xl={5}
                              >
                                {this.state.startupStage?.join(", ") || "-"}
                              </Grid>
                            </>
                          ) : null}

                          {this.state.teamSize?.length > 0 ? (
                            <>
                              <Grid item xs={12} sm={12} md={12} lg={7} xl={7}>
                                <Typography
                                  color="textSecondary"
                                  className={classes.mediumBottomMargin}
                                >
                                  Team Size
                                </Typography>
                              </Grid>
                              <Grid
                                className={classes.textAlignEnd}
                                item
                                xs={12}
                                sm={12}
                                md={12}
                                lg={5}
                                xl={5}
                              >
                                {this.state.teamSize?.join(", ") || "-"}
                              </Grid>
                            </>
                          ) : null}

                          {this.state.role ? (
                            <>
                              {" "}
                              <Grid item xs={12} sm={12} md={12} lg={7} xl={7}>
                                <Typography
                                  color="textSecondary"
                                  className={classes.mediumBottomMargin}
                                >
                                  New Role/Existing Role
                                </Typography>
                              </Grid>
                              <Grid
                                className={classes.textAlignEnd}
                                item
                                xs={12}
                                sm={12}
                                md={12}
                                lg={5}
                                xl={5}
                              >
                                {this.state.role || "-"}
                              </Grid>
                            </>
                          ) : null}
                        </Grid>
                      </CardContent>
                    </Card>
                  </Grid>

                  {this.state?.sampleCompanyValues?.length > 0 ? (
                    <>
                      <div
                        className={classNames(
                          classes.subHeader,
                          classes.mediumTopMargin,
                          classes.noBottomMargin
                        )}
                      >
                        Sample Corporation
                      </div>
                      <Grid
                        item
                        xs={12}
                        sm={12}
                        md={12}
                        lg={12}
                        xl={12}
                        className={classes.verySmallTopMargin}
                      >
                        {this.state.openPreview
                          ? this.state.sampleCompanyValues?.map(
                              (company, index) => {
                                return (
                                  <div
                                    className={classes.displayInline}
                                    key={index}
                                  >
                                    <Chip
                                      label={
                                        <>
                                          <ViewMore length="30">
                                            {company.name}
                                          </ViewMore>
                                        </>
                                      }
                                      style={{
                                        height: "100%",
                                        padding: "5px",
                                        margin: "5px 4px 8px 0px",
                                        lineBreak: "anywhere",
                                      }}
                                    />
                                  </div>
                                );
                              }
                            )
                          : null}
                      </Grid>
                    </>
                  ) : null}
                  <div
                    className={classNames(
                      classes.subHeader,
                      classes.mediumTopMargin,
                      classes.noBottomMargin
                    )}
                  >
                    About Role*
                  </div>
                  <Grid
                    item
                    xs={12}
                    sm={12}
                    md={12}
                    lg={12}
                    xl={12}
                    className={classes.customSmallTopMargin}
                  >
                    <Editor
                      toolbarHidden
                      readOnly
                      editorState={editorStateJobDescription}
                    />
                  </Grid>
                  <div
                    className={classNames(
                      classes.subHeader,
                      classes.mediumTopMargin,
                      classes.noBottomMargin
                    )}
                  >
                    About Us
                  </div>
                  <Grid
                    item
                    xs={12}
                    sm={12}
                    md={12}
                    lg={12}
                    xl={12}
                    className={classes.customSmallTopMargin}
                  >
                    <Editor
                      toolbarHidden
                      readOnly
                      editorState={editorStateAboutUs}
                    />
                  </Grid>
                  <div
                    className={classNames(
                      classes.subHeader,
                      classes.mediumTopMargin,
                      classes.noBottomMargin
                    )}
                  >
                    Additional responsibilities include
                  </div>
                  <Grid
                    item
                    xs={12}
                    sm={12}
                    md={12}
                    lg={12}
                    xl={12}
                    className={classes.customSmallTopMargin}
                  >
                    <Editor
                      toolbarHidden
                      readOnly
                      editorState={additionresponsibilities}
                    />
                  </Grid>
                  <div
                    className={classNames(
                      classes.subHeader,
                      classes.mediumTopMargin,
                      classes.noBottomMargin
                    )}
                  >
                    What a Successful candidate will bring?
                  </div>
                  <Grid
                    item
                    xs={12}
                    sm={12}
                    md={12}
                    lg={12}
                    xl={12}
                    className={classes.customSmallTopMargin}
                  >
                    <Editor
                      toolbarHidden
                      readOnly
                      editorState={editorcandidateWillBring}
                    />
                  </Grid>
                  <div
                    className={classNames(
                      classes.subHeader,
                      classes.mediumTopMargin,
                      classes.noBottomMargin
                    )}
                  >
                    Absolute MUST HAVEs (non-negotiables)
                  </div>
                  <Grid
                    item
                    xs={12}
                    sm={12}
                    md={12}
                    lg={12}
                    xl={12}
                    className={classes.customSmallTopMargin}
                  >
                    <Editor
                      toolbarHidden
                      readOnly
                      editorState={editerAbsoluteMustHave}
                    />
                  </Grid>

                  <div
                    className={classNames(
                      classes.subHeader,
                      classes.mediumTopMargin,
                      classes.noBottomMargin
                    )}
                  >
                    Competencies
                  </div>
                  <Grid
                    item
                    xs={12}
                    sm={12}
                    md={12}
                    lg={12}
                    xl={12}
                    className={classes.verySmallTopMargin}
                  >
                    {this.state.openPreview
                      ? this.state.skillsDataList?.map((skill, index) => {
                          return (
                            <div className={classes.displayInline} key={index}>
                              <Chip
                                label={
                                  <>
                                    <ViewMore length="30">{skill}</ViewMore>
                                  </>
                                }
                                style={{
                                  height: "100%",
                                  padding: "5px",
                                  margin: "5px 4px 8px 0px",
                                  lineBreak: "anywhere",
                                }}
                              />
                            </div>
                          );
                        })
                      : null}
                  </Grid>
                </DialogContentText>
              </DialogContent>
              <DialogActions className={classes.dialogActions}>
                <Button
                  className={classNames(
                    classes.justifyRight,
                    classes.customEditButton
                  )}
                  variant="contained"
                  disableElevation
                  size="large"
                  onClick={(event) => {
                    this.handleEditClick(event);
                  }}
                >
                  Edit
                </Button>
                <Button
                  className={classes.justifyRight}
                  variant="contained"
                  color="primary"
                  disableElevation
                  size="large"
                  onClick={(event) => {
                    this.handlePostJob(event);
                  }}
                >
                  Post Job
                </Button>
              </DialogActions>
            </Dialog>
          </div>
          <GetHelp />
        </main>
      </div>
    );
  }
}

Jobs.propTypes = {
  classes: PropTypes.object.isRequired,
};

function mapStateToProps(state) {
  return state;
}

const connectedJobsPage = withRouter(
  connect(mapStateToProps, null, null, {
    pure: false,
  })(withStyles(styles)(Jobs))
);

export { connectedJobsPage as Jobs };
