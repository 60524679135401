const initialState = { paymentDetails: {} };

export function payment(state = initialState, action) {
  switch (action.type) {
    case "SET_PAYMENT_INFO":
      return {
        ...state,
        paymentInfo: action.paymentInfo,
        paymentPlan: action.paymentPlan,
        totalAmount: action.totalAmount,
      };
    case "RECORDED_PAYMENT":
      return {
        ...state,
        recordedPayment: action.recordedPayment,
        open: action.open,
        // authenticationInitiated: action.authenticationInitiated
      };
    // case 'AUTHENTICATION_INITIATED':
    //     return {
    //         ...state,
    //         open: action.open,
    //         authenticationInitiated: action.authenticationInitiated
    //     }
    case "UPDATED_PAYMENT_DETAILS":
      return {
        ...state,
        updatedPaymentDetails: action.updatedPaymentDetails,
        editPayment: false,
      };
    // case 'RESET_FLAGS':
    //     return{
    //         ...state,
    //         open: action.open
    //     }

    default:
      return state;
  }
}
