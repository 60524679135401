import {
  Avatar,
  Tooltip,
  Grid,
  Typography,
  withStyles,
} from "@material-ui/core";
import React, { useEffect, useState } from "react";
import {
  getImgObjURL,
  arrayIncludesKeyVal,
  openLinkInNewTab,
} from "../../helpers";

export const EmployersLogo = withStyles({
  root: {
    overflowWrap: "break-word",
    paddingRight: "24px",
    marginTop: "1%",
    paddingLeft: "24px",
    marginBottom: "40px",
  },
  title: {
    fontWeight: "bold",
  },
  icon: {
    margin: "0px 10px 5px 0px",
  },
  logoArrayRoot: {
    marginLeft: "10px",
    paddingRight: "30px",
  },
  companyAvatar: {
    border: "2.5px solid #fcfaf5",
    "&:hover": {
      "-webkit-transform": "scale(1.5)",
      "-ms-transform": "scale(1.5)",
      transform: "scale(1.5)",
    },
    transition: "transform .5s",
    cursor: "pointer",
  },
})((props) => {
  const { classes, companiesLogoArray } = props;
  const [filteredCompaniesLogoArray, setFilteredCompaniesLogoArray] = useState(
    []
  );

  const removeDuplicateLogoEntries = (logoInfoObjArray) => {
    const logoArray = [];
    const len = logoInfoObjArray?.length;
    if (len < 1) return logoArray;
    for (let index = 0; index < len; index++) {
      const company = logoInfoObjArray[index];
      const {
        company_logo,
        industry,
        company_size,
        domain,
        other_industry_text,
      } = company;
      if (
        company_logo &&
        !arrayIncludesKeyVal(logoArray, "company_logo", company_logo)
      ) {
        logoArray.push({
          company_logo,
          industry,
          company_size,
          domain,
          other_industry_text,
        });
      }
    }
    return logoArray;
  };

  useEffect(() => {
    const filterLogoArray = async () => {
      // identify if blob is png
      const LOGO_ARRAY = removeDuplicateLogoEntries(companiesLogoArray);
      const logoArray = [];
      const len = LOGO_ARRAY?.length ?? 0;
      for (let i = 0; i < len; i++) {
        const e = LOGO_ARRAY[i];
        const {
          company_size,
          company_logo,
          industry,
          domain,
          other_industry_text,
        } = e;
        if (company_logo) {
          const imageObjUrl = await getImgObjURL(company_logo);
          if (imageObjUrl) {
            const obj = {
              company_size,
              imageObjUrl: company_logo,
              industry,
              domain,
              other_industry_text,
            };
            logoArray.push(obj);
          }
        }
      }
      setFilteredCompaniesLogoArray(logoArray);
    };
    filterLogoArray();
  }, [companiesLogoArray]);

  return (
    <Grid className={classes.root} container>
      <Grid className={classes.logoArrayRoot} spacing={3} container>
        {filteredCompaniesLogoArray?.map((e) => {
          const {
            company_size,
            imageObjUrl,
            industry,
            domain,
            other_industry_text,
          } = e;
          return (
            <Grid item>
              <Tooltip
                title={
                  company_size || industry || domain ? (
                    <>
                      {company_size ? (
                        <Typography>
                          Company Size:
                          {company_size}
                        </Typography>
                      ) : (
                        ""
                      )}
                      {industry ? (
                        <Typography>
                          Industry:{" "}
                          {industry === "Other"
                            ? other_industry_text
                            : industry}
                        </Typography>
                      ) : (
                        ""
                      )}
                      {domain ? (
                        <Typography>
                          Domain:
                          {domain}
                        </Typography>
                      ) : (
                        ""
                      )}
                    </>
                  ) : (
                    ""
                  )
                }
              >
                <Avatar
                  className={classes.companyAvatar}
                  src={imageObjUrl}
                  alt={imageObjUrl}
                  variant="square"
                  onClick={() => {
                    openLinkInNewTab(domain);
                  }}
                  // remove this to show the empty image in case of faulty url
                  onError={(e) => {
                    try {
                      e.currentTarget.style.display = "none";
                    } catch (_) {
                      console.log("Error...");
                    }
                  }}
                />
              </Tooltip>
            </Grid>
          );
        })}{" "}
      </Grid>
    </Grid>
  );
});
