import { toast } from "react-toastify";
import { apiService } from "../services";
import { history } from "../helpers";
import { loading } from ".";
import { showResponseToast } from "../helpers/toastHelpers";
import store from "../store/store";
import secureLocalStorage from "react-secure-storage";

export const jobsActions = {
  getJobTitles,
  getReportsToTitles,
  postJob,
  getOpenJobList,
  getOpenSharedJobList,
  getClosedJobList,
  getClosedSharedJobList,
  getJobDetails,
  updateJob,
  closeJob,
  getSkills,
  getMajorList,
  getCertifications,
  getRecommendedData,
  getPendingData,
  getMatchedData,
  getArchivedData,
  likeCandidateRecommendation,
  dislikeCandidateRecommendation,
  clearAllJobDetails,
  getCollaboratorRecruiters,
  addCollaborators,
  deleteCollaborator,
  getCandidateComments,
  addCandidateComment,
  deleteCandidateComment,
  setJobTab,
  setView,
  getCompanyNames,
  schedulingLinkValues,
  getAllUpdatesData,
  getViewLiteProfileFromAllUpdate,
  getCompanyPassword,
};

export function isHired(job_reco_id, payload) {
  const apiEndpoint = `/api/v3/job_recommendation/${job_reco_id}/hired_candidate/`;
  apiService
    .put(apiEndpoint, payload)
    .then((response) => {
      if (response && (response.status === 200 || response.status === 201)) {
        showResponseToast(
          response,
          "Candidate liked successfully",
          "Failure while liking candidate"
        );
      }
    })
    .catch((err) => {
      console.log(err);
    });
}

export function movedInConversation(payload) {
  const apiEndpoint = "/api/v3/job_recommendation/move_candidate/";
  apiService
    .put(apiEndpoint, payload)
    .then((response) => {
      if (response && (response.status === 200 || response.status === 201)) {
        showResponseToast(
          response,
          "Candidate liked successfully",
          "Failure while liking candidate"
        );
      }
    })
    .catch((err) => {
      console.log(err);
    });
}

function getAllUpdatesData(job_id, flag) {
  return (dispatch) => {
    const apiEndpoint = `/api/v3/job_recommendation/${job_id}/get_all_job_updates/?flag=${flag}`;
    apiService
      .get(apiEndpoint)
      .then((response) => {
        if (response && (response.status === 200 || response.status === 201)) {
          dispatch(getAllUpdateData(response.data.data));
        } else if (response.status !== 401) {
          toast.dark("Something went haywire");
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };
}

function getViewLiteProfileFromAllUpdate(job_reco_id) {
  return (dispatch) => {
    const apiEndpoint = `/api/v3/job_recommendation/${job_reco_id}/view_candidate_lite_profile/`;
    apiService
      .get(apiEndpoint)
      .then((response) => {
        if (response && (response.status === 200 || response.status === 201)) {
          dispatch(getLiteProfileData(response.data.data));
        } else if (response.status !== 401) {
          toast.dark("Something went haywire");
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };
}

function getAllUpdateData(data) {
  return {
    type: "ALL_UPDATE_DATA",
    allUpdateData: data,
  };
}

function getLiteProfileData(data) {
  return {
    type: "ALL_UPDATE_LITE_PROFILE_DATA",
    liteProfileData: data,
  };
}

function getJobTitles(input) {
  return (dispatch) => {
    const apiEndpoint = `/api/v1/search_index/?title=${input}`;
    apiService
      .get(apiEndpoint)
      .then((response) => {
        if (response && (response.status === 200 || response.status === 201)) {
          dispatch(getJobTitlesData(response.data.data));
        } else if (response.status !== 401) {
          toast.dark("Something went haywire");
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };
}

function getCompanyNames(input) {
  return (dispatch) => {
    const apiEndpoint = `https://autocomplete.clearbit.com/v1/companies/suggest?query=${input}`;
    apiService.thirdPartyApiGet(apiEndpoint).then((response) => {
      if (response && (response.status === 200 || response.status === 201)) {
        dispatch(getCompanyNamesData(response.data));
      } else if (response.status !== 401) {
        toast.dark("Something went haywire");
      }
    });
  };
}

function getCompanyNamesData(data) {
  return {
    type: "COMPANY_NAME_DATA",
    companyNames: data,
  };
}

function getMajorList(input) {
  return (dispatch) => {
    const apiEndpoint = `/api/v1/major_search/?major=${input}`;
    apiService
      .get(apiEndpoint)
      .then((response) => {
        if (response && (response.status === 200 || response.status === 201)) {
          dispatch(getMajorsData(response.data.data));
        } else if (response.status !== 401) {
          toast.dark("Unable to fetch options for major");
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };
}

function clearAllJobDetails() {
  return (dispatch) => {
    dispatch(getRecommendedDetails({}));
    dispatch(getPendingDetails({}));
    dispatch(getMatchedDetails({}));
    dispatch(getArchivedDetails({}));
  };
}

function getCollaboratorRecruiters(jobId) {
  return (dispatch) => {
    const apiEndpoint = `/api/v2/recruiter/${jobId}/get_recruiters/`;
    apiService
      .get(apiEndpoint)
      .then((response) => {
        if (response && (response.status === 200 || response.status === 201)) {
          //    if(response.data.data.collaborators.length === 0){
          //        toast.dark('No collaborator available to add');
          //    }
          dispatch(
            getCollaboratorRecruitersData(response.data.data.collaborators)
          );
        } else if (response.status !== 401) {
          toast.dark("Something went haywire");
          window.location.pathname.match("^/jobs/my-jobs.*$")
            ? history.push(`/jobs/my-jobs/${jobId}`)
            : history.push(`/jobs/shared-jobs/${jobId}`);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };
}

function getCertifications(input) {
  return (dispatch) => {
    const apiEndpoint = `/api/v1/certificate_search/?certificate=${input}`;
    apiService
      .get(apiEndpoint)
      .then((response) => {
        if (response && (response.status === 200 || response.status === 201)) {
          dispatch(getCertificationsData(response.data.data));
        } else if (response.status !== 401) {
          toast.dark("Unable to fetch options for certifications");
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };
}

function getReportsToTitles(input) {
  return (dispatch) => {
    const apiEndpoint = `/api/v1/search_index/?title=${input}`;
    apiService
      .get(apiEndpoint)
      .then((response) => {
        if (response && (response.status === 200 || response.status === 201)) {
          dispatch(getReportsToTitlesData(response.data.data));
        } else if (response.status !== 401) {
          toast.dark("Something went haywire");
          // history.push('/jobs/my-jobs');
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };
}

function getJobDetails(jobId) {
  return (dispatch) => {
    const apiEndpoint = `/api/v1/job_details/${jobId}/`;
    apiService
      .get(apiEndpoint)
      .then((response) => {
        if (response && (response.status === 200 || response.status === 201)) {
          dispatch(getJobDetailsData(response.data));
        } else if (response.status !== 401) {
          toast.dark("Something went haywire");
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };
}

function postJob(payload) {
  return (dispatch) => {
    //    let apiEndpoint = '/api/v1/job/create_new/';
    const apiEndpoint = "/api/v2/recruiter/add_job/";
    apiService
      .post(apiEndpoint, payload)
      .then((response) => {
        if (
          response &&
          (response.status === 200 || response.status === 201) &&
          !response.data.error
        ) {
          dispatch(getJobPostData(response.data.data));
          // dispatch(getOpenJobList(JSON.parse(secureLocalStorage.getItem('recruiterId'))));
          //    window.location.pathname === '/jobs/my-jobs' ? history.push(`/jobs/my-jobs`) : history.push(`/jobs/shared-jobs`);
          history.push("/jobs/my-jobs");
        }
        //  else if (response.status !== 401) {
        //    window.location.pathname === '/jobs/my-jobs' ? history.push(`/jobs/my-jobs`) : history.push(`/jobs/shared-jobs`);
        //  }
        showResponseToast(
          response,
          "Job posted Successfully",
          "Failure while posting job"
        );
      })
      .catch((err) => {
        console.log(err);
      });
  };
}

function addCollaborators(payload) {
  return (dispatch) => {
    const apiEndpoint = "/api/v2/recruiter/add_collaborators/";
    apiService
      .post(apiEndpoint, payload)
      .then((response) => {
        if (
          response &&
          (response.status === 200 || response.status === 201) &&
          !response.data.error
        ) {
          dispatch(getAddedCollaboratorsData(response.data.data));
          dispatch(getJobDetails(payload.job_id));
        }
        showResponseToast(
          response,
          "Collaborator added successfully",
          "Failure while adding collaborator"
        );
        window.location.pathname.match("^/jobs/my-jobs.*$")
          ? history.push(`/jobs/my-jobs/${payload.job_id}`)
          : history.push(`/jobs/shared-jobs/${payload.job_id}`);
      })
      .catch((err) => {
        console.log(err);
      });
  };
}

function deleteCollaborator(collaboratorPermissionId, jobId) {
  return (dispatch) => {
    const apiEndpoint = `/api/v2/recruiter/delete_collaborator/${collaboratorPermissionId}/`;
    apiService
      .deleteDetail(apiEndpoint)
      .then((response) => {
        if (
          response &&
          (response.status === 200 ||
            response.status === 201 ||
            response.status === 204) &&
          !response.data.error
        ) {
          // dispatch(getDeletedCollaboratorsData(response.data.data));
          dispatch(getJobDetails(jobId));
        }
        showResponseToast(
          response,
          "Collaborator deleted successfully",
          "Failure while deleting collaborator"
        );
        window.location.pathname.match("^/jobs/my-jobs.*$")
          ? history.push(`/jobs/my-jobs/${jobId}`)
          : history.push(`/jobs/shared-jobs/${jobId}`);
      })
      .catch((err) => {
        console.log(err);
      });
  };
}

export function getCandidateComments(recommendationId, jobId) {
  return (dispatch) => {
    const apiEndpoint = `/api/v2/recommendation/comments/?recommendation=${recommendationId}`;
    apiService
      .get(apiEndpoint)
      .then((response) => {
        if (response && (response.status === 200 || response.status === 201)) {
          dispatch(listCandidateComments(response.data.data));
        } else if (response.status !== 401) {
          toast.dark("Unable to fetch internal notes");
          dispatch(listCandidateComments([]));
          window.location.pathname.match("^/jobs/my-jobs.*$")
            ? history.push(`/jobs/my-jobs/${jobId}`)
            : history.push(`/jobs/shared-jobs/${jobId}`);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };
}

export function addCandidateComment(payload, jobId) {
  return (dispatch) => {
    const apiEndpoint = "/api/v2/recommendation/comments/";
    apiService
      .post(apiEndpoint, payload)
      .then((response) => {
        if (
          response &&
          (response.status === 200 || response.status === 201) &&
          !response.data.error
        ) {
          dispatch(getCandidateComments(payload.recommendation, jobId));
          if (!window.location.pathname.match("^/members.*$")) {
            dispatch(getJobDetails(jobId));
          }
        } else if (response.status !== 401) {
          window.location.pathname.match("^/jobs/my-jobs.*$")
            ? history.push(`/jobs/my-jobs/${jobId}`)
            : history.push(`/jobs/shared-jobs/${jobId}`);
        }
        showResponseToast(
          response,
          "Note added successfully",
          "Failure while adding note"
        );
      })
      .catch((err) => {
        console.log(err);
      });
  };
}

export function deleteCandidateComment(commentId, jobId, recID) {
  return (dispatch) => {
    const apiEndpoint = `/api/v2/recommendation/comments/${commentId}/`;
    apiService
      .deleteDetail(apiEndpoint)
      .then((response) => {
        if (
          response &&
          (response.status === 200 ||
            response.status === 201 ||
            response.status === 204) &&
          !response.data.error
        ) {
          dispatch(getCandidateComments(recID, jobId));
          if (!window.location.pathname.match("^/members.*$")) {
            dispatch(getJobDetails(jobId));
          }
        } else if (response.status !== 401) {
          window.location.pathname.match("^/jobs/my-jobs.*$")
            ? history.push(`/jobs/my-jobs/${jobId}`)
            : history.push(`/jobs/shared-jobs/${jobId}`);
        }
        showResponseToast(
          response,
          "Note deleted successfully",
          "Failure while deleting note"
        );
      })
      .catch((err) => {
        console.log(err);
      });
  };
}

function getOpenJobList(recruiterId, filter) {
  return (dispatch) => {
    let apiEndpoint;
    const state = store.getState();
    if (
      state.shared.jobsPagePaginationPage === 0 ||
      state.shared.jobsPagePaginationPage === undefined ||
      state.shared.jobsPagePaginationPage === 1 ||
      state.shared.jobsPagePaginationPage === null
    ) {
      apiEndpoint = `/api/v1/list_recruiter_jobs/?recruiter_id=${recruiterId}&status=Open${
        filter ? `&function=${filter}` : ""
      }`;
    } else {
      apiEndpoint = `/api/v1/list_recruiter_jobs/?recruiter_id=${recruiterId}&status=Open&page=${
        state.shared.jobsPagePaginationPage
      }${filter ? `&function=${filter}` : ""}`;
    }
    apiService
      .get(apiEndpoint)
      .then((response) => {
        if (response && (response.status === 200 || response.status === 201)) {
          dispatch(listOpenJobs(response.data));
        } else if (response.status !== 401) {
          toast.dark("Something went haywire");
          // history.push('/jobs/my-jobs');
          history.go(-1);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };
}

function getOpenSharedJobList(filter) {
  return (dispatch) => {
    let apiEndpoint;
    const state = store.getState();
    if (
      state.shared.jobsPagePaginationPage === 0 ||
      state.shared.jobsPagePaginationPage === undefined ||
      state.shared.jobsPagePaginationPage === 1 ||
      state.shared.jobsPagePaginationPage === null
    ) {
      apiEndpoint = `/api/v2/recruiter/list_shared_jobs/?status=Open${
        filter ? `&function=${filter}` : ""
      }`;
    } else {
      apiEndpoint = `/api/v2/recruiter/list_shared_jobs/?status=Open&page=${
        state.shared.jobsPagePaginationPage
      }${filter ? `&function=${filter}` : ""}`;
    }
    apiService
      .get(apiEndpoint)
      .then((response) => {
        if (response && (response.status === 200 || response.status === 201)) {
          dispatch(listOpenJobs(response.data));
        } else if (response.status !== 401) {
          toast.dark("Something went haywire");
          // history.push('/jobs/my-jobs');
          history.go(-1);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };
}

function getClosedJobList(recruiterId, filter) {
  return (dispatch) => {
    let apiEndpoint;
    const state = store.getState();
    if (
      state.shared.closedJobPageNo === 0 ||
      state.shared.closedJobPageNo === undefined ||
      state.shared.closedJobPageNo === 1 ||
      state.shared.closedJobPageNo === null
    ) {
      apiEndpoint = `/api/v1/list_recruiter_jobs/?recruiter_id=${recruiterId}&status=Close${
        filter ? `&function=${filter}` : ""
      }`;
    } else {
      apiEndpoint = `/api/v1/list_recruiter_jobs/?recruiter_id=${recruiterId}&status=Close&page=${
        state.shared.closedJobPageNo
      }${filter ? `&function=${filter} ` : ""}`;
    }
    apiService
      .get(apiEndpoint)
      .then((response) => {
        if (response && (response.status === 200 || response.status === 201)) {
          dispatch(listCloseJobs(response.data));
        } else if (response.status !== 401) {
          toast.dark("Something went haywire");
          // window.location.pathname === '/jobs/my-jobs' ? history.push(`/jobs/my-jobs`) : history.push(`/jobs/shared-jobs`);
          history.go(-1);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };
}

function getClosedSharedJobList(filter) {
  return (dispatch) => {
    let apiEndpoint;
    const state = store.getState();
    if (
      state.shared.closedJobPageNo === 0 ||
      state.shared.closedJobPageNo === undefined ||
      state.shared.closedJobPageNo === 1 ||
      state.shared.closedJobPageNo === null
    ) {
      apiEndpoint = `/api/v2/recruiter/list_shared_jobs/?status=Close${
        filter ? `&function=${filter}` : ""
      }`;
    } else {
      apiEndpoint = `/api/v2/list_shared_jobs/?status=Close&page=${
        state.shared.closedJobPageNo
      }${filter ? `&function=${filter}` : ""}`;
    }
    apiService
      .get(apiEndpoint)
      .then((response) => {
        if (response && (response.status === 200 || response.status === 201)) {
          dispatch(listCloseJobs(response.data));
        } else if (response.status !== 401) {
          toast.dark("Something went haywire");
          // window.location.pathname === '/jobs/my-jobs' ? history.push('/jobs/my-jobs') : history.push('/jobs/shared-jobs')
          history.go(-1);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };
}

function updateJob(payload, jobId) {
  return (dispatch) => {
    //    let apiEndpoint = '/api/v1/job/' + jobId + '/edit/';
    const apiEndpoint = `/api/v2/recruiter/${jobId}/edit_job/`;
    apiService
      .put(apiEndpoint, payload)
      .then((response) => {
        if (response && (response.status === 200 || response.status === 201)) {
          dispatch(getUpdatedJobData(response.data));
          dispatch(getJobDetails(jobId));
          window.location.pathname.match("^/jobs/my-jobs.*$")
            ? history.push(`/jobs/my-jobs/${jobId}`)
            : history.push(`/jobs/shared-jobs/${jobId}`);
        } else if (response.status !== 401) {
          window.location.pathname.match("^/jobs/my-jobs.*$")
            ? history.push(`/jobs/my-jobs/${jobId}`)
            : history.push(`/jobs/shared-jobs/${jobId}`);
        }
        showResponseToast(
          response,
          "Job updated successfully",
          "Failure while updating job"
        );
      })
      .catch((err) => {
        console.log(err);
      });
  };
}

function closeJob(payload, jobId) {
  return (dispatch) => {
    const apiEndpoint = `/api/v1/job/${jobId}/close/`;
    apiService
      .put(apiEndpoint, payload)
      .then((response) => {
        if (response && (response.status === 200 || response.status === 201)) {
          dispatch(onCloseJob(response.data));
          dispatch(getJobDetails(jobId));
          // window.location.pathname.match('^/jobs/my-jobs.*$') ? history.push(`/jobs/my-jobs/${jobId}`) : history.push(`/jobs/shared-jobs/${jobId}`);
        } else if (response.status !== 401) {
          window.location.pathname.match("^/jobs/my-jobs.*$")
            ? history.push(`/jobs/my-jobs/${jobId}`)
            : history.push(`/jobs/shared-jobs/${jobId}`);
        }
        showResponseToast(
          response,
          "Job closed successfully",
          "Failure while closing job"
        );
      })
      .catch((err) => {
        console.log(err);
      });
  };
}

function getRecommendedData(jobId) {
  return (dispatch) => {
    let apiEndpoint;
    window.location.pathname.match("^/jobs/my-jobs.*$")
      ? (apiEndpoint = `/api/v1/job_recommendtion/${jobId}/get_recruiter_candidates/?flag=Recommended&recruiter_id=${
          JSON.parse(secureLocalStorage.getItem("recruiterStatusDetails"))
            .recruiter_id
        }`)
      : (apiEndpoint = `/api/v2/recruiter/${jobId}/get_job_recommendations/?flag=Recommended`);
    apiService
      .get(apiEndpoint)
      .then((response) => {
        if (response && (response.status === 200 || response.status === 201)) {
          dispatch(
            getRecommendedDetails(
              window.location.pathname.match("^/jobs/my-jobs.*$")
                ? response.data
                : response.data.data
            )
          );
          // dispatch(jobsActions.getPendingData(jobId));
        } else if (response.status !== 401) {
          toast.dark("Something went haywire");
          // window.location.pathname.match('^/jobs/my-jobs.*$') ? history.push(`/jobs/my-jobs`) : history.push(`/jobs/shared-jobs`);
          history.go(-1);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };
}

function getPendingData(jobId) {
  return (dispatch) => {
    let apiEndpoint;
    window.location.pathname.match("^/jobs/my-jobs.*$")
      ? (apiEndpoint = `/api/v1/job_recommendtion/${jobId}/get_recruiter_candidates/?flag=Pending&recruiter_id=${
          JSON.parse(secureLocalStorage.getItem("recruiterStatusDetails"))
            .recruiter_id
        }`)
      : (apiEndpoint = `/api/v2/recruiter/${jobId}/get_job_recommendations/?flag=Pending`);
    apiService
      .get(apiEndpoint)
      .then((response) => {
        if (response && (response.status === 200 || response.status === 201)) {
          dispatch(
            getPendingDetails(
              window.location.pathname.match("^/jobs/my-jobs.*$")
                ? response.data
                : response.data.data
            )
          );
          //    dispatch(jobsActions.getMatchedData(jobId));
        } else if (response.status !== 401) {
          toast.dark("Something went haywire");
          // window.location.pathname.match('^/jobs/my-jobs.*$') ? history.push(`/jobs/my-jobs`) : history.push(`/jobs/shared-jobs`);
          history.go(-1);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };
}

function getMatchedData(jobId) {
  return (dispatch) => {
    let apiEndpoint;
    window.location.pathname.match("^/jobs/my-jobs.*$")
      ? (apiEndpoint = `/api/v1/job_recommendtion/${jobId}/get_recruiter_candidates/?flag=Matched&recruiter_id=${
          JSON.parse(secureLocalStorage.getItem("recruiterStatusDetails"))
            .recruiter_id
        }`)
      : (apiEndpoint = `/api/v2/recruiter/${jobId}/get_job_recommendations/?flag=Matched`);
    apiService
      .get(apiEndpoint)
      .then((response) => {
        if (response && (response.status === 200 || response.status === 201)) {
          dispatch(
            getMatchedDetails(
              window.location.pathname.match("^/jobs/my-jobs.*$")
                ? response.data
                : response.data.data
            )
          );
          //    dispatch(jobsActions.getArchivedData(jobId));
        } else if (response.status !== 401) {
          toast.dark("Something went haywire");
          // window.location.pathname.match('^/jobs/my-jobs.*$') ? history.push(`/jobs/my-jobs`) : history.push(`/jobs/shared-jobs`);
          history.go(-1);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };
}

function getArchivedData(jobId) {
  loading(true);
  return (dispatch) => {
    let apiEndpoint;
    window.location.pathname.match("^/jobs/my-jobs.*$")
      ? (apiEndpoint = `/api/v1/job_recommendtion/${jobId}/get_recruiter_candidates/?flag=Archive&recruiter_id=${
          JSON.parse(secureLocalStorage.getItem("recruiterStatusDetails"))
            .recruiter_id
        }`)
      : (apiEndpoint = `/api/v2/recruiter/${jobId}/get_job_recommendations/?flag=Archive`);
    apiService
      .get(apiEndpoint)
      .then((response) => {
        if (response && (response.status === 200 || response.status === 201)) {
          dispatch(
            getArchivedDetails(
              window.location.pathname.match("^/jobs/my-jobs.*$")
                ? response.data
                : response.data.data
            )
          );
        } else if (response.status !== 401) {
          toast.dark("Something went haywire");
          // window.location.pathname.match('^/jobs/my-jobs.*$') ? history.push(`/jobs/my-jobs`) : history.push(`/jobs/shared-jobs`);
          history.go(-1);
        }
        loading(false);
      })
      .catch((err) => {
        console.log(err);
        loading(false);
      });
  };
}

function getSkills(input) {
  return (dispatch) => {
    const apiEndpoint = `/api/v1/skill_search/?skill=${input}`;
    apiService
      .get(apiEndpoint)
      .then((response) => {
        if (response && (response.status === 200 || response.status === 201)) {
          dispatch(getSkillsData(response.data.data));
        } else if (response.status !== 401) {
          toast.dark("Something went haywire");
          // history.push('/jobs/my-jobs');
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };
}

function likeCandidateRecommendation(jobId, jobRecommendationId, payload) {
  return () => {
    const apiEndpoint = `/api/v2/recruiter/${jobRecommendationId}/like_dislike_candidate_request/`;
    apiService
      .post(apiEndpoint, payload)
      .then((response) => {
        if (response && (response.status === 200 || response.status === 201)) {
          showResponseToast(
            response,
            "Candidate liked successfully",
            "Failure while liking candidate"
          );
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };
}

function getCompanyPassword(companyId) {
  let apiEndpoint = `/api/v2/admin/recruiter/${companyId}/retrieve_password/`;
  return apiService.get(apiEndpoint).catch((err) => {
    console.log(err);
  });
}

export function dislikeCandidateRecommendation(
  jobId,
  jobRecommendationId,
  payload
) {
  return () => {
    const apiEndpoint = `/api/v2/recruiter/${jobRecommendationId}/like_dislike_candidate_request/`;
    apiService
      .post(apiEndpoint, payload)
      .then((response) => {
        if (response && (response.status === 200 || response.status === 201)) {
          showResponseToast(
            response,
            "Candidate disliked successfully",
            "Failure while disliking candidate"
          );
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };
}

function getCollaboratorRecruitersData(data) {
  return {
    type: "COLLABORATOR_RECRUITERS_DATA",
    collaboratorRecruiters: data,
  };
}

function getJobTitlesData(data) {
  return {
    type: "JOB_TITLE_DATA",
    jobTitles: data,
  };
}

function getMajorsData(data) {
  return {
    type: "MAJORS_DATA",
    majors: data,
  };
}

function getCertificationsData(data) {
  return {
    type: "CERTIFICATIONS_DATA",
    certifications: data,
  };
}

function getReportsToTitlesData(data) {
  return {
    type: "REPORTS_TO_TITLE_DATA",
    reportsToTitles: data,
  };
}

export function getJobDetailsData(data) {
  return {
    type: "JOB_DETAILS_DATA",
    jobDetails: data,
  };
}

function getJobPostData(data) {
  return {
    type: "JOB_POST_SUCCESS",
    jobPost: data,
  };
}

function getAddedCollaboratorsData(data) {
  return {
    type: "ADDED_COLLABORATORS_DATA",
    addedCollaborators: data,
  };
}

// function getDeletedCollaboratorsData(data){
//     return {
//         type: 'DELETED_COLLABORATORS_DATA',
//         deletedCollaborators: data
//     }
// }

function listOpenJobs(data) {
  return {
    type: "OPEN_JOB_LIST_SUCCESS",
    openJobList: data,
  };
}

function listCloseJobs(data) {
  return {
    type: "CLOSE_JOB_LIST_SUCCESS",
    closedJobList: data,
  };
}

function listCandidateComments(data) {
  return {
    type: "CANDIDATE_COMMENTS",
    candidateComments: data,
  };
}

function getUpdatedJobData(data) {
  return {
    type: "JOB_UPDATE_SUCCESS",
    jobDetails: data,
  };
}

function getRecommendedDetails(data) {
  return {
    type: "GOT_RECOMMENDED_DATA",
    recommended: data,
  };
}

function getPendingDetails(data) {
  return {
    type: "GOT_PENDING_DATA",
    pending: data,
  };
}

function getMatchedDetails(data) {
  return {
    type: "GOT_MATCHED_DATA",
    matched: data,
  };
}

function getArchivedDetails(data) {
  return {
    type: "GOT_ARCHIVED_DATA",
    archived: data,
  };
}

function onCloseJob(data) {
  return {
    type: "JOB_CLOSE_SUCCESS",
    closeJobDetails: data,
  };
}

function getSkillsData(data) {
  return {
    type: "SKILLS_DATA",
    skills: data,
  };
}

// function getDislikeCandidateRecommendationData(data) {
//     return {
//         type: 'DISLIKE_RESPONSE',
//         dislikeCandidateResponse: data
//     }
// };

function setJobTab(activeTab) {
  return (dispatch) => {
    dispatch(changeJobTab(activeTab));
  };
}

export function changeJobTab(activeTab) {
  return {
    type: "SET_JOB_ACTIVE_TAB",
    activeTab,
  };
}

export function setView(view) {
  return (dispatch) => {
    dispatch(changeView(view));
  };
}

export function changeView(view) {
  return {
    type: "SET_JOB_PAGE_VIEW",
    view,
  };
}

export function schedulingLinkValues(schedulingLinkValues) {
  return {
    type: "SCHEDULING_LINK_VALUES",
    schedulingLinkValues,
  };
}

export function setLocation(location) {
  return {
    type: "SET_LOCATION",
    locationData: location,
  };
}

export function setAllUpdateHeaderCard(cardValue) {
  return {
    type: "ALL_UPDATE_HEADER_CARD",
    allUpdateHeaderCard: cardValue,
  };
}
