import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import classNames from "classnames";
import CloseIcon from "@material-ui/icons/Close";
import {
  IconButton,
  Button,
  DialogContent,
  DialogActions,
  Typography,
  Dialog,
  TextField,
  DialogTitle,
} from "@material-ui/core";
import cardHeaderBg from "../../assets/lite_profile_bg.png";

const useStyles = makeStyles((theme) => ({
  customDialogHeader: {
    maxHeight: "38%",
    height: "38%",
    color: "#000",
    backgroundSize: "cover",
    backgroundImage: `url(${cardHeaderBg})`,
    background: "round",
  },
  closeButton: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.default.main,
    padding: "0px",
  },
  dialogActions: {
    margin: 0,
    padding: theme.spacing(2),
    backgroundColor: "#fff",
    justifyContent: "center !important",
  },
  floatRight: {
    float: "right",
  },
  smallFont: {
    fontSize: "14px",
  },
}));

function CustomAddPopUp({
  open,
  title,
  label,
  onClose,
  value,
  onChange,
  onSave,
  placeholder,
  customFieldCount,
}) {
  const classes = useStyles();
  return (
    <Dialog
      open={open}
      fullWidth
      aria-labelledby="scroll-dialog-title"
      aria-describedby="scroll-dialog-description"
      disableBackdropClick
      disableEscapeKeyDown
      maxWidth="xs"
    >
      <DialogTitle
        id="customized-dialog-title"
        className={classes.customDialogHeader}
      >
        <Typography variant="h5" align="center">
          {title}
        </Typography>
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={onClose}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        <TextField
          fullWidth
          label={label}
          className={classes.customTopBottomMargin}
          id="newMajor"
          name="newMajor"
          value={value}
          onChange={onChange}
          placeholder={placeholder}
          inputProps={{
            maxLength: customFieldCount,
          }}
        />
        <Typography
          className={classNames(classes.floatRight, classes.smallFont)}
          color="secondary"
        >
          {`${value?.length}/${customFieldCount}`} characters
        </Typography>
      </DialogContent>
      <DialogActions className={classes.dialogActions}>
        <Button
          onClick={onClose}
          style={{ background: "#D7D9DB", color: "#000" }}
          variant="contained"
          disableElevation
          size="medium"
        >
          Cancel
        </Button>
        <Button
          variant="contained"
          color="primary"
          disableElevation
          size="medium"
          disabled={value?.length === 0}
          onClick={onSave}
        >
          Save
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default CustomAddPopUp;
