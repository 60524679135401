import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Grid, Divider, Typography, Chip } from "@material-ui/core";
import EmptyState from "../core/emptyState";
import { ViewMore } from "../core/viewmore";

const useStyles = makeStyles(() => ({
  compiTopDivider: {
    borderBottom: "1px solid #D7D9DB",
    width: "inherit",
    marginTop: "3%",
    flexShrink: 1,
  },
  compiBotDivider: {
    borderBottom: "1px solid #D7D9DB",
    width: "inherit",
    marginTop: "3%",
    marginBottom: "3%",
  },
  chipStyle: {
    marginRight: "8px",
    marginBottom: "8px",
    textIndent: "0",
    overflowY: "auto",
    height: "auto",
    padding: "8px",
    marginTop: "8px",
    backgroundColor: "#FBC112",
    color: "#000000",
    marginLeft: "0px",
  },
  AccandComTopGrid: {
    textAlign: "left",
    marginTop: "20px",
  },
  bolderFont: {
    fontWeight: "bolder",
  },
}));

function Competencies(props) {
  const { skills } = props;
  const EMPTY_ICON_HEIGHT = 120;
  const EMPTY_ICON_WIDTH = 120;
  const classes = useStyles();

  return (
    <>
      <Divider className={classes.compiTopDivider} />
      <Grid
        item
        xs={12}
        sm={12}
        md={12}
        lg={12}
        xl={12}
        className={classes.AccandComTopGrid}
      >
        <Typography variant="h6" className={classes.bolderFont}>
          Competencies
        </Typography>
        <Divider className={classes.compiBotDivider} />
        {skills?.length > 0 ? (
          skills?.map((skill, index) => (
            <Chip
              className={classes.chipStyle}
              key={index}
              label={<ViewMore length="200">{skill}</ViewMore>}
              title={skill}
            />
          ))
        ) : (
          <EmptyState
            type="competencies"
            height={EMPTY_ICON_HEIGHT}
            width={EMPTY_ICON_WIDTH}
          />
        )}
      </Grid>
    </>
  );
}
export default Competencies;
