import { toast } from "react-toastify";
import { apiService } from "../services";
import { showResponseToast } from "../helpers/toastHelpers";
import secureLocalStorage from "react-secure-storage";

export const myAccountActions = {
  getAllBillingData,
  addSeats,
  redirectToStripePortal,
  getSeats,
};

function getAllBillingData(companyId) {
  return (dispatch) => {
    const apiEndpoint = `/api/v1/payment/${companyId}/history/?recruiter_id=${
      JSON.parse(secureLocalStorage.getItem("recruiterStatusDetails"))
        .recruiter_id
    }`;
    apiService
      .get(apiEndpoint)
      .then((response) => {
        if (response && (response.status === 200 || response.status === 201)) {
          dispatch(onGettingBillingData(response.data));
        } else if (response.status !== 401) {
          toast.dark("Something went haywire");
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };
}

function getSeats() {
  return (dispatch) => {
    const apiEndpoint = "/api/v2/recruiter/seats/";
    apiService
      .get(apiEndpoint)
      .then((response) => {
        if (response && (response.status === 200 || response.status === 201)) {
          dispatch(onGettingSeats(response.data.data));
        } else if (response.status !== 401) {
          toast.dark("Something went haywire");
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };
}

function redirectToStripePortal() {
  return (dispatch) => {
    const apiEndpoint = `/api/v1/payment/${
      JSON.parse(secureLocalStorage.getItem("recruiterStatusDetails"))
        .recruiter_id
    }/create_customer_portal_session/`;
    apiService
      .get(apiEndpoint)
      .then((response) => {
        if (response && (response.status === 200 || response.status === 201)) {
          dispatch(onRedirectingToStripePortal(response.data));
          toast.dark("Redirecting you to Stripe Billing platform");
          window.open(response.data.url, "_self");
        } else if (response.status !== 401) {
          toast.dark("Something went haywire");
          // window.location.reload();
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };
}

function addSeats(payload, recruiterId) {
  return (dispatch) => {
    const apiEndpoint = `/api/v1/recruiter/${recruiterId}/add_seats/`;
    apiService
      .post(apiEndpoint, payload)
      .then((response) => {
        if (response && (response.status === 200 || response.status === 201)) {
          dispatch(onAddedSeats(response.data));
          dispatch(
            getAllBillingData(
              JSON.parse(secureLocalStorage.getItem("recruiterStatusDetails"))
                .company.id
            )
          );
        }
        showResponseToast(
          response,
          "Seat added successfully",
          "Failure while adding seat"
        );
      })
      .catch((err) => {
        console.log(err);
      });
  };
}

function onGettingBillingData(data) {
  return {
    type: "BILLING_DATA",
    billingData: data,
  };
}

function onGettingSeats(data) {
  return {
    type: "BILLING_DATA",
    billingData: data,
  };
}

function onRedirectingToStripePortal(data) {
  return {
    type: "ON_STRIPE_CUSTOMER_PORTAL",
    customerPortalData: data,
  };
}

function onAddedSeats(data) {
  return {
    type: "ADDED_SEATS",
    addedSeats: data,
  };
}
