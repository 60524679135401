import {
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  FormGroup,
  IconButton,
  Typography,
  TextField,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import React, { useState, useEffect } from "react";
import CloseIcon from "@material-ui/icons/Close";
import {
  BUTTONS,
  DIALOG_BOX,
  EXPERIENCE_NOT_RELEVANT,
  GET_ALL_UPDATE_DATA_FLAG_AND_VALUE,
  INDUSTRY_NOT_RELEVANT,
  LABELS,
  REDIRECT_TO_COMPONENT,
  VALIDATION_MESSAGES,
  MAX_LENGTH_1000,
  MISSING_KEY_SKILLS,
  MESSAGE_FIELD_IS_REQUIRED,
  VALIDATION_CHECK,
} from "../../constants/constants";
import { Autocomplete } from "@material-ui/lab";
import { removeFormQueue } from "../../actions";
import { jobsActions } from "../../actions/jobs.actions";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";

const useStyles = makeStyles((theme) => ({
  colorDefault: {
    color: "#000000 !important",
  },
  fullWidth: {
    width: "100%",
  },
  mediumTopMargin: {
    marginTop: "4%",
  },
  dialogTitleTypo: {
    color: "#FBC112",
  },
  largeFont: {
    fontSize: "large",
  },
  DialogTitle: {
    background: "#FBC112",
    fontSize: "28px",
    fontWeight: 700,
    textAlign: "center",
  },
  dailogContetnt: { paddingLeft: "8%", paddingRight: "8%" },
  dailogActions: {
    display: "flex",
    justifyContent: "center",
    marginBottom: "15px",
  },
  closeButton: { background: "#D7D9DB", color: "#000", width: "10rem" },
  ConfirmButton: { width: "10rem" },
  closeButtonHeader: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(1),
    padding: "0px",
    color: "#000000",
  },
  display: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
  },
  AutocompleteStyle: {
    marginTop: "10px",
    marginBottom: "20px",
    height: "40px",
    cursor: "pointer !important",
    "& :hover": {
      cursor: "pointer !important",
    },
  },
  checkedColor: {
    color: "#048475 !important",
  },
  meduimMarginBottom: {
    marginBottom: "10px",
  },
  customFloatRight: {
    float: "right",
  },
  largeMarginBottom: {
    marginBottom: "15px",
  },
  autoCompleteOptionsStyle: {
    width: "100%",
    borderBottom: "1px solid #D2D2D2",
    display: "flex",
    alignItems: "center",
    padding: "5px",
    paddingLeft: "0px",
  },
}));

const RemoveFromQueue = ({
  open,
  close,
  recommendationId,
  jobId,
  hideButtons,
  handleOtherLiteProfileViewCLose,
  redirectfrom,
}) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const headerCardValue = useSelector(
    (state) => state.jobs.allUpdateHeaderCard
  );

  const [renderApis, setRenderApi] = useState(false);
  const [experienceSelectVisible, setExperienceSelectVisible] = useState(false);
  const [industrySelectVisible, setIndustrySelectVisible] = useState(false);
  const [otherVisible, setOtherVisible] = useState(false);
  const [experienceMissingSkillVisible, setExperienceMissingSkillVisible] =
    useState(false);
  const [industryMissingSkillVisible, setIndustryMissingSkillVisible] =
    useState(false);

  const [values, setValues] = useState({
    experienceNotRelevant: null,
    industryNotRelevant: null,
    other: null,
    otherExperience: null,
    otherIndustry: null,
  });

  const [validate, setValidate] = useState({
    experienceNotRelevant: false,
    industryNotRelevant: false,
    other: false,
    experienceOther: false,
    industryOther: false,
  });

  const [checkValidation, setCheckValidation] = useState({
    checkFlag: false,
    for: null,
  });

  const setInitState = () => {
    setExperienceSelectVisible(false);
    setIndustrySelectVisible(false);
    setOtherVisible(false);
    setExperienceMissingSkillVisible(false);
    setIndustryMissingSkillVisible(false);
    setValues({
      experienceNotRelevant: null,
      industryNotRelevant: null,
      other: null,
      otherExperience: null,
      otherIndustry: null,
    });
    setValidate({
      experienceNotRelevant: false,
      industryNotRelevant: false,
      other: false,
      experienceOther: false,
      industryOther: false,
    });
    setCheckValidation({
      checkFlag: false,
      for: null,
    });
  };
  const onChangeExperience = (value) => {
    if (value.value === MISSING_KEY_SKILLS) {
      setExperienceMissingSkillVisible(true);
      setValidate({ ...validate, experienceOther: true });
    } else {
      setExperienceMissingSkillVisible(false);
      setValidate({ ...validate, experienceOther: false });
    }
    setValues({ ...values, experienceNotRelevant: value.value });
    setCheckValidation({
      checkFlag: true,
      for: VALIDATION_CHECK.REMOVE_FROM_QUEUE.EXPERIENCE_NOT_RELEVANT,
    });
  };

  const onChangeIndustry = (value) => {
    if (value.value === MISSING_KEY_SKILLS) {
      setIndustryMissingSkillVisible(true);
      setValidate({ ...validate, industryOther: true });
    } else {
      setIndustryMissingSkillVisible(false);
      setValidate({ ...validate, industryOther: false });
    }
    setValues({ ...values, industryNotRelevant: value.value });
    setCheckValidation({
      checkFlag: true,
      for: VALIDATION_CHECK.REMOVE_FROM_QUEUE.INDUSTRY_NOT_RELEVANT,
    });
  };

  const onChangeExperienceEleborate = (value) => {
    let trimedValue = value.trimStart();
    setValues({ ...values, otherExperience: trimedValue });
    setCheckValidation({
      checkFlag: true,
      for: VALIDATION_CHECK.REMOVE_FROM_QUEUE.EXPERIENCE_NOT_RELEVENT_OTHER,
    });
  };

  const onChangeIndustryElaborate = (value) => {
    let trimedValue = value.trimStart();
    setValues({ ...values, otherIndustry: trimedValue });
    setCheckValidation({
      checkFlag: true,
      for: VALIDATION_CHECK.REMOVE_FROM_QUEUE.INDUSTRY_NOT_RELEVANT_OTHER,
    });
  };

  const onChangeOtherElaborate = (value) => {
    let trimedValue = value.trimStart();
    setValues({ ...values, other: trimedValue });
    setCheckValidation({
      checkFlag: true,
      for: VALIDATION_CHECK.REMOVE_FROM_QUEUE.OTHER,
    });
  };

  useEffect(() => {
    handleCheckValidation(checkValidation.for);
    setCheckValidation({ checkFlag: false, for: null });
  }, [checkValidation.checkFlag]);

  const handleRemovefromQueue = async () => {
    const valid = await handleCheckValidation(
      VALIDATION_CHECK.REMOVE_FROM_QUEUE.SUBMIT
    );
    if (!valid) {
      if (!experienceSelectVisible && !industrySelectVisible && !otherVisible) {
        toast.dark(
          VALIDATION_MESSAGES.REMOVE_FROM_QUEUE.PLEASE_SELECT_ONE_OPTION
        );
      }
      return;
    }
    const payload = {
      other_feedback: values.other,
      industry_issue: values.otherIndustry
        ? values.otherIndustry
        : values.industryNotRelevant,
      experience_issue: values.otherExperience
        ? values.otherExperience
        : values.experienceNotRelevant,
    };
    await removeFormQueue(recommendationId, payload, headerCardValue);
    setRenderApi(true);
  };

  useEffect(() => {
    if (renderApis) {
      autoClosePopup();
    }
  }, [renderApis]);

  const autoClosePopup = () => {
    onHandleClose();
    switch (headerCardValue) {
      case GET_ALL_UPDATE_DATA_FLAG_AND_VALUE.ALL.CARDVALUE:
        dispatch(
          jobsActions.getAllUpdatesData(
            jobId,
            GET_ALL_UPDATE_DATA_FLAG_AND_VALUE.ALL.VALUE
          )
        );
        break;
      case GET_ALL_UPDATE_DATA_FLAG_AND_VALUE.RECOMMENDED.CARDVALUE:
        dispatch(
          jobsActions.getAllUpdatesData(
            jobId,
            GET_ALL_UPDATE_DATA_FLAG_AND_VALUE.RECOMMENDED.VALUE
          )
        );
        break;
      case GET_ALL_UPDATE_DATA_FLAG_AND_VALUE.MATCHED.CARDVALUE:
        dispatch(
          jobsActions.getAllUpdatesData(
            jobId,
            GET_ALL_UPDATE_DATA_FLAG_AND_VALUE.MATCHED.VALUE
          )
        );
        break;
      case GET_ALL_UPDATE_DATA_FLAG_AND_VALUE.CONVERSATION.CARDVALUE:
        dispatch(
          jobsActions.getAllUpdatesData(
            jobId,
            GET_ALL_UPDATE_DATA_FLAG_AND_VALUE.CONVERSATION.VALUE
          )
        );
        break;
      default:
        break;
    }
    if (redirectfrom === REDIRECT_TO_COMPONENT.LITE_PROFILE) {
      handleOtherLiteProfileViewCLose();
    }
    if (redirectfrom === REDIRECT_TO_COMPONENT.FULL_PROFILE) {
      hideButtons();
    }
  };

  const onHandleClose = () => {
    close();
    setInitState();
    setRenderApi(false);
  };

  const handleCheckUncheckExperienceVisible = (flag) => {
    setExperienceSelectVisible(flag);
    if (!flag) {
      setExperienceMissingSkillVisible(false);
      setValues({
        ...values,
        experienceNotRelevant: null,
        otherExperience: null,
      });
    }
  };

  const handleCheckUncheckIndustryVisible = (flag) => {
    setIndustrySelectVisible(flag);
    if (!flag) {
      setIndustryMissingSkillVisible(false);
      setValues({
        ...values,
        industryNotRelevant: null,
        otherIndustry: null,
      });
    }
  };

  const handleCheckUncheckOtherVisible = (flag) => {
    setOtherVisible(flag);
    if (!flag) {
      setValues({
        ...values,
        other: null,
      });
    }
  };

  const handleCheckValidation = (flag) => {
    let valid = true;
    const updatedValidate = { ...validate };

    if (!experienceSelectVisible && !industrySelectVisible && !otherVisible) {
      valid = false;
    }

    switch (flag) {
      case VALIDATION_CHECK.REMOVE_FROM_QUEUE.EXPERIENCE_NOT_RELEVANT:
        if (values.experienceNotRelevant === null && experienceSelectVisible) {
          updatedValidate.experienceNotRelevant = true;
          valid = false;
        } else {
          updatedValidate.experienceNotRelevant = false;
        }
        break;
      case VALIDATION_CHECK.REMOVE_FROM_QUEUE.INDUSTRY_NOT_RELEVANT:
        if (values.industryNotRelevant === null && industrySelectVisible) {
          updatedValidate.industryNotRelevant = true;
          valid = false;
        } else {
          updatedValidate.industryNotRelevant = false;
        }
        break;
      case VALIDATION_CHECK.REMOVE_FROM_QUEUE.OTHER:
        if (
          (values?.other?.length === 0 || values.other === null) &&
          otherVisible
        ) {
          updatedValidate.other = true;
          valid = false;
        } else {
          updatedValidate.other = false;
        }
        break;
      case VALIDATION_CHECK.REMOVE_FROM_QUEUE.EXPERIENCE_NOT_RELEVENT_OTHER:
        if (
          (values?.otherExperience?.length === 0 ||
            values.otherExperience === null) &&
          experienceMissingSkillVisible
        ) {
          updatedValidate.experienceOther = false;
          valid = false;
        } else {
          updatedValidate.experienceOther = true;
        }
        break;
      case VALIDATION_CHECK.REMOVE_FROM_QUEUE.INDUSTRY_NOT_RELEVANT_OTHER:
        if (
          (values?.otherIndustry?.length === 0 ||
            values.otherIndustry === null) &&
          industryMissingSkillVisible
        ) {
          updatedValidate.industryOther = false;
          valid = false;
        } else {
          updatedValidate.industryOther = true;
        }
        break;

      case VALIDATION_CHECK.REMOVE_FROM_QUEUE.SUBMIT:
        if (values.experienceNotRelevant === null && experienceSelectVisible) {
          updatedValidate.experienceNotRelevant = true;
          valid = false;
        } else {
          updatedValidate.experienceNotRelevant = false;
        }

        if (values.industryNotRelevant === null && industrySelectVisible) {
          updatedValidate.industryNotRelevant = true;
          valid = false;
        } else {
          updatedValidate.industryNotRelevant = false;
        }

        if (
          (values?.other?.length === 0 || values.other === null) &&
          otherVisible
        ) {
          updatedValidate.other = true;
          valid = false;
        } else {
          updatedValidate.other = false;
        }

        if (
          (values?.otherExperience?.length === 0 ||
            values.otherExperience === null) &&
          experienceMissingSkillVisible
        ) {
          updatedValidate.experienceOther = false;
          valid = false;
        } else {
          updatedValidate.experienceOther = true;
        }

        if (
          (values?.otherIndustry?.length === 0 ||
            values.otherIndustry === null) &&
          industryMissingSkillVisible
        ) {
          updatedValidate.industryOther = false;
          valid = false;
        } else {
          updatedValidate.industryOther = true;
        }
        break;
      default:
        break;
    }
    setValidate(updatedValidate);
    return valid;
  };

  return (
    <div>
      <Dialog
        open={open}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        fullWidth
        disableEscapeKeyDown
      >
        <DialogTitle id="alert-dialog-title" className={classes.DialogTitle}>
          {DIALOG_BOX.REMOVE_FROM_QUEUE.DIALOG_TITLES}
        </DialogTitle>
        <IconButton
          aria-label="close"
          onClick={onHandleClose}
          className={classes.closeButtonHeader}
        >
          <CloseIcon />
        </IconButton>
        <DialogContent>
          <Typography
            ariant="h6"
            color="textSecondary"
            className={classes.largeMarginBottom}
          >
            {DIALOG_BOX.REMOVE_FROM_QUEUE.DIALOG_SUBTITLES}
          </Typography>
          <FormGroup>
            <FormControlLabel
              control={
                <Checkbox
                  className={
                    experienceSelectVisible ? classes.checkedColor : ""
                  }
                  onChange={(e) =>
                    handleCheckUncheckExperienceVisible(e.target.checked)
                  }
                />
              }
              label={LABELS.REMOVE_FROM_QUEUE.EXPERIENCE_NOT_RELEVANT}
            />
            {experienceSelectVisible && (
              <Autocomplete
                size="small"
                autoComplete
                disableClearable
                onChange={(event, newValue) => {
                  onChangeExperience(newValue);
                }}
                id="controllable-states-demo"
                getOptionLabel={(option) => option.value}
                options={EXPERIENCE_NOT_RELEVANT}
                renderOption={(option) => (
                  <div className={classes.autoCompleteOptionsStyle}>
                    {option.value}
                  </div>
                )}
                className={classes.AutocompleteStyle}
                renderInput={(params) => (
                  <TextField
                    className="candidate_filter"
                    onChange={() => false}
                    size="small"
                    InputProps={{
                      readOnly: true,
                      ...params.InputProps,
                    }}
                    {...params}
                    placeholder="Select..."
                    variant="outlined"
                    error={validate.experienceNotRelevant}
                    helperText={
                      validate.experienceNotRelevant &&
                      MESSAGE_FIELD_IS_REQUIRED
                    }
                  />
                )}
              />
            )}
            {experienceMissingSkillVisible && (
              <TextField
                required
                id="standard-basic"
                label={LABELS.REMOVE_FROM_QUEUE.PLEASE_ELABORATE}
                variant="standard"
                className={classes.meduimMarginBottom}
                inputProps={{ maxLength: MAX_LENGTH_1000 }}
                error={!validate.experienceOther}
                helperText={
                  <Typography>
                    {!validate.experienceOther
                      ? MESSAGE_FIELD_IS_REQUIRED
                      : VALIDATION_MESSAGES.REMOVE_FROM_QUEUE.MAX_1000_CHAR}
                    <span className={classes.customFloatRight}>
                      {values.otherExperience?.length
                        ? values.otherExperience?.length
                        : 0}
                      /{MAX_LENGTH_1000} characters
                    </span>
                  </Typography>
                }
                onChange={(e) => onChangeExperienceEleborate(e.target.value)}
              />
            )}
            <FormControlLabel
              control={
                <Checkbox
                  className={industrySelectVisible ? classes.checkedColor : ""}
                  onChange={(e) =>
                    handleCheckUncheckIndustryVisible(e.target.checked)
                  }
                />
              }
              label={LABELS.REMOVE_FROM_QUEUE.INDUSTRY_NOT_ALIGNED}
            />
            {industrySelectVisible && (
              <Autocomplete
                size="small"
                autoComplete
                disableClearable
                onChange={(event, newValue) => {
                  onChangeIndustry(newValue);
                }}
                id="controllable-states-demo"
                getOptionLabel={(option) => option.value}
                options={INDUSTRY_NOT_RELEVANT}
                renderOption={(option) => (
                  <div className={classes.autoCompleteOptionsStyle}>
                    {option.value}
                  </div>
                )}
                className={classes.AutocompleteStyle}
                renderInput={(params) => (
                  <TextField
                    className="candidate_filter"
                    onChange={() => false}
                    size="small"
                    InputProps={{
                      readOnly: true,
                      ...params.InputProps,
                    }}
                    {...params}
                    placeholder="Select..."
                    variant="outlined"
                    error={validate.industryNotRelevant}
                    helperText={
                      validate.industryNotRelevant && MESSAGE_FIELD_IS_REQUIRED
                    }
                  />
                )}
              />
            )}
            {industryMissingSkillVisible && (
              <TextField
                required
                id="standard-basic"
                label={LABELS.REMOVE_FROM_QUEUE.PLEASE_ELABORATE}
                variant="standard"
                onChange={(e) => onChangeIndustryElaborate(e.target.value)}
                className={classes.meduimMarginBottom}
                inputProps={{ maxLength: MAX_LENGTH_1000 }}
                error={!validate.industryOther}
                helperText={
                  <Typography>
                    {!validate.industryOther
                      ? MESSAGE_FIELD_IS_REQUIRED
                      : VALIDATION_MESSAGES.REMOVE_FROM_QUEUE.MAX_1000_CHAR}
                    <span className={classes.customFloatRight}>
                      {values.otherIndustry?.length
                        ? values.otherIndustry?.length
                        : 0}
                      /{MAX_LENGTH_1000} characters
                    </span>
                  </Typography>
                }
              />
            )}

            <FormControlLabel
              control={
                <Checkbox
                  className={otherVisible ? classes.checkedColor : ""}
                  onChange={(e) =>
                    handleCheckUncheckOtherVisible(e.target.checked)
                  }
                />
              }
              label={LABELS.REMOVE_FROM_QUEUE.OTHER}
            />
            {otherVisible && (
              <>
                <TextField
                  id="standard-basic"
                  label={LABELS.REMOVE_FROM_QUEUE.PLEASE_ELABORATE}
                  variant="standard"
                  className={classes.meduimMarginBottom}
                  onChange={(e) => onChangeOtherElaborate(e.target.value)}
                  error={validate.other}
                  helperText={
                    <Typography>
                      {validate.other
                        ? MESSAGE_FIELD_IS_REQUIRED
                        : VALIDATION_MESSAGES.REMOVE_FROM_QUEUE.MAX_1000_CHAR}
                      <span className={classes.customFloatRight}>
                        {values.other?.length ? values.other?.length : 0}/
                        {MAX_LENGTH_1000}
                        characters
                      </span>
                    </Typography>
                  }
                  inputProps={{ maxLength: MAX_LENGTH_1000 }}
                />
              </>
            )}
          </FormGroup>
        </DialogContent>
        <DialogActions className={classes.dailogActions}>
          <Button
            disableElevation
            size="medium"
            onClick={onHandleClose}
            variant="text"
            color="primary"
          >
            {BUTTONS.REMOVE_FROM_QUEUE.CANCEL}
          </Button>
          <Button
            disableElevation
            className={classes.ConfirmButton}
            size="medium"
            variant="contained"
            color="primary"
            autoFocus
            onClick={handleRemovefromQueue}
          >
            {BUTTONS.REMOVE_FROM_QUEUE.SUBMIT}
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default RemoveFromQueue;
