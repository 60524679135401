import React from "react";
import { Grid, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import recommendedImg from "../../assets/ic_no_new_candidates.svg";
import matchedImg from "../../assets/ic_no_shortlisted.svg";
import inconversationImg from "../../assets/ic_no_candidates_in_conversation.svg";
import removeQueImg from "../../assets/ic_removed_from_queue.svg";
import employersImg from "../../assets/ic_no_employer.svg";
import boardAdvisorVolunteerImg from "../../assets/ic_no_bod_volunteer.svg";
import workHistoryImg from "../../assets/ic_no_work_history.svg";
import accomplishments from "../../assets/ic_no_accomplishments.svg";
import competenciesImg from "../../assets/ic_no_competencies.svg";
import educationImg from "../../assets/ic_no_education.svg";
import matchesBasedOnImg from "../../assets/ic_no_match_based_on.svg";
import professionalCertificatesImg from "../../assets/ic_no_professional_cert.svg";
import coffeequestionImg from "../../assets/ic_no_coffee_question.svg";
import candidateVideosImg from "../../assets/ic_no_candidate_video.svg";
import resumeImg from "../../assets/ic_no_resume.svg";

const useStyles = makeStyles(() => ({
  title: {
    fontSize: "16px",
    color: "#000000",
    fontWeight: 700,
  },
  content: {
    fontSize: "14px",
    fontWeight: 400,
  },
  centerImage: {
    textAlign: "center",
  },
}));

function EmptyState({ type, height, width }) {
  const classes = useStyles();
  return (
    <Grid container className={classes.centerImage}>
      <Grid sm={12} xs={12} md={12} lg={12} xl={12}>
        {type === "Recommended" ? (
          <>
            <img
              height={height}
              width={width}
              alt="no candidate in talent pipeline"
              src={recommendedImg}
            />
            <Typography className={classes.title}>{'No Candidate in Talent Pipeline'}</Typography>
          </>
        ) : (
          <></>
        )}
        {/* No Shortlisted Candidates */}
        {type === "Matched" ? (
          <>
            <img
              height={height}
              width={width}
              alt="no shortlisted candidates."
              src={matchedImg}
            />
            <Typography className={classes.title}>
              No Shortlisted Candidates
            </Typography>
          </>
        ) : (
          <></>
        )}
        {/* No Candidates in Convesation */}
        {type === "Conversation" ? (
          <>
            <img
              height={height}
              width={width}
              alt="no candidates in conversation."
              src={inconversationImg}
            />
            <Typography className={classes.title}>
              No Candidates in Conversation
            </Typography>
          </>
        ) : (
          <></>
        )}
        {/* No Candidates Removed From Que */}
        {type === "Removed" ? (
          <>
            <img
              height={height}
              width={width}
              alt="You have no candidates in Removed from Queue."
              src={removeQueImg}
            />
            <Typography className={classes.title}>
              You have no candidates in Removed from Queue.
            </Typography>
            <Typography className={classes?.content}>
              Once you mark candidate in Removed from Queue you will see them
              here.
            </Typography>
          </>
        ) : (
          <></>
        )}
        {/* No Employers */}
        {type === "employers" ? (
          <>
            <img
              height={height}
              width={width}
              alt="no employer"
              src={employersImg}
            />
            <Typography className={classes.title}>No Employer</Typography>
          </>
        ) : (
          <></>
        )}
        {/* No Accomplishments */}
        {type === "accomplishments" ? (
          <>
            <img
              height={height}
              width={width}
              alt="no accomplishments"
              src={accomplishments}
            />
            <Typography className={classes.title}>
              No Accomplishments
            </Typography>
          </>
        ) : (
          <></>
        )}
        {/* No Competencies */}
        {type === "competencies" ? (
          <>
            <img
              height={height}
              width={width}
              alt="no Competencies"
              src={competenciesImg}
            />
            <Typography className={classes.title}>No Competencies</Typography>
          </>
        ) : (
          <></>
        )}
        {/* No Work History / Experience */}
        {type === "workHistory" || type === "experience" ? (
          <>
            <img
              height={height}
              width={width}
              alt="no work history"
              src={workHistoryImg}
            />
            <Typography className={classes.title}>
              No {type === "workHistory" ? "Work History" : "Experience"}
            </Typography>
          </>
        ) : (
          <></>
        )}
        {/* No Board Advisor Role/Volunteer Experiences */}
        {type === "boradAdvisorVolunteer" ? (
          <>
            <img
              height={height}
              width={width}
              alt="no board adviosor / no volunteer exp"
              src={boardAdvisorVolunteerImg}
            />
            <Typography className={classes.title}>
              No Board Advisor/Volunteer
            </Typography>
          </>
        ) : (
          <></>
        )}
        {/* No Education */}
        {type === "education" ? (
          <>
            <img
              height={height}
              width={width}
              alt="no education"
              src={educationImg}
            />
            <Typography className={classes.title}>No Education</Typography>
          </>
        ) : (
          <></>
        )}
        {/* No Matches Based on */}
        {type === "matchesBasedOn" ? (
          <>
            <img
              height={height}
              width={width}
              alt="no match based on"
              src={matchesBasedOnImg}
            />
            <Typography className={classes.title}>No Match Based On</Typography>
          </>
        ) : (
          <></>
        )}
        {/* No Professional Certificates */}
        {type === "professionalCertificates" ? (
          <>
            <img
              height={height}
              width={width}
              alt="no professional certificates"
              src={professionalCertificatesImg}
            />
            <Typography className={classes.title}>
              No Professional Certificates
            </Typography>
          </>
        ) : (
          <></>
        )}
        {/* No Coffee Questions */}
        {type === "coffeequestions" ? (
          <>
            <img
              height={height}
              width={width}
              alt="no coffee questions"
              src={coffeequestionImg}
            />
            <Typography className={classes.title}>
              No Coffee Question
            </Typography>
          </>
        ) : (
          <></>
        )}
        {/* No Candidate Videos */}
        {type === "candidateVideos" ? (
          <>
            <img
              height={height}
              width={width}
              alt="no candidate videos"
              src={candidateVideosImg}
            />
            <Typography className={classes.title}>
              No Candidate Video
            </Typography>
          </>
        ) : (
          <></>
        )}
        {/* No Resume */}
        {type === "resume" ? (
          <>
            <img
              height={height}
              width={width}
              alt="no resume"
              src={resumeImg}
            />
            <Typography className={classes.title}>No Resume</Typography>
          </>
        ) : (
          <></>
        )}
      </Grid>
    </Grid>
  );
}

export default EmptyState;
