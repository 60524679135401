import {
  Divider,
  Grid,
  Typography,
  Button,
  IconButton,
  Avatar,
  Tooltip,
  MenuItem,
  Popper,
  Paper,
  Fade,
  MenuList,
  ClickAwayListener,
} from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import classNames from "classnames";
import VisibilityIcon from "@material-ui/icons/Visibility";
import LinkedInIcon from "@material-ui/icons/LinkedIn";
import GetAppIcon from "@material-ui/icons/GetApp";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import { toast } from "react-toastify";
import { convertToRaw } from "draft-js";
import draftToHtml from "draftjs-to-html";
import Checkbox from "@material-ui/core/Checkbox";
import {
  jobsActions,
  membersActions,
  messagesActions,
} from "../../../actions/index.js";
import {
  RECOMMENDED_HEADER,
  MATCHED_HEADER,
  REDIRECT_TO_COMPONENT,
} from "../../../constants/constants.js";
import ViewLiteProfilePopUp from "./viewLiteProfilePopUp.js";
import { ReactComponent as ThumbsUpIcon } from "../../../assets/thumb_up.svg";
import { ReactComponent as RemovedFromIcon } from "../../../assets/removeFromQueue.svg";
import ArrowRightAltIcon from "@material-ui/icons/ArrowRightAlt";
import { ReactComponent as RequestInterviewIcon } from "../../../assets/requestInterview.svg";
import { ReactComponent as MarkAsHiredIcon } from "../../../assets/markAsHired.svg";
import EmptyState from "../../core/emptyState.js";
import LikeComponent from "../../customComponents/likeComponent.js";
import SchedulingPopup from "../../customComponents/scheduling.popup.component.js";
import PreviewEmaildailog from "../../core/previewEmail.dailog.js";
import MarkedHired from "../../customComponents/makedHired.js";
import MoveCandidateinConversation from "../../customComponents/moveCandidateinConversation.js";
import { ReactComponent as MatchedCriteria } from "../../../assets/matched_criteria.svg";
import { setAllUpdateHeaderCard } from "../../../actions/jobs.actions.js";
import secureLocalStorage from "react-secure-storage";
import RemoveFromQueue from "../../customComponents/removeFromQueue.js";

const useStyles = makeStyles(() => ({
  table: {
    textAlign: "left",
    borderSpacing: "6px 0px",
    overflow: "scroll",
  },
  tableHeading: {
    position: "sticky",
    left: "0",
    zIndex: "2",
    background: "white",
    minWidth: "203px",
    height: "50px",
    padding: "10px",
  },
  colorOddHeading: {
    background: "#FEF5DB",
    fontSize: "16px",
  },
  colorEvenHeading: {
    background: "#FBFAF7",
    fontSize: "16px",
  },
  colorOddCell: {
    background: "#FBFAF7",
    padding: "8px",
    minWidth: "350px",
    maxWidth: "350px",
    fontSize: "16px",
    boxSizing: "content-box",
  },
  colorEvenCell: {
    background: "##F8F6EF",
    padding: "8px",
    minWidth: "350px",
    maxWidth: "350px",
    fontSize: "16px",
    boxSizing: "content-box",
  },
  TableHeadingMain: {
    fontWeight: "700",
    marginBottom: "8px",
    marginTop: "20px",
    marginLeft: "6px",
    fontSize: "16px",
  },
  TableHeadingMain2: {
    fontWeight: "700",
    marginBottom: "8px",
    marginTop: "20px",
    marginLeft: "10px",
    fontSize: "16px",
  },
  mainDivMargin: {
    marginTop: "2%",
  },
  viewButton: {
    background: "#020203",
    color: "#FFFFFF",
    padding: "4px 8px 4px 8px",
    "&:hover": {
      backgroundColor: "#020203",
    },
  },
  LinkedinButton: {
    color: "#FFFFFF",
    background: "#020203",
    marginLeft: "10px",
    borderRadius: "10%",
    padding: "4px",
    width: "6em",
    "&:hover": {
      backgroundColor: "#020203",
    },
  },
  displayFlex: {
    display: "flex",
  },
  overFlowScroll: {
    overflowX: "unset",
    "&:hover": {
      overflowX: "scroll",
    },
  },
  marginTopCustom: {
    marginTop: "35px",
  },
  timeCellsCss: {
    display: "flex",
    flexDirection: "column",
  },
  timeInnerSpan: {
    fontSize: "14px",
    color: "#666666",
  },
  newChip: {
    marginLeft: "4px",
    background: "#B7B6B8",
    fontSize: "8px",
    borderRadius: "5px",
    padding: "5px",
    fontWeight: 400,
  },
  marginLeft4: {
    marginLeft: "4px",
  },
  nameMainSpan: {
    display: "flex",
    alignItems: "center",
    cursor: "pointer",
    width: "100%",
    wordBreak: "break-all",
  },
  avatarSmall: {
    height: 24,
    width: 24,
  },
  backDrop: {
    filter: "blur(.2rem)",
  },
  LikeButton: {
    color: "#FFFFFF",
    marginLeft: "10px",
    borderRadius: "10%",
    padding: "6px",
    border: "1px solid #000000",
  },
  dropDownMain: {
    display: "flex",
    alignItems: "left",
  },
  zIndex9999: {
    zIndex: 9999,
  },
  smallMarginLeft: {
    marginLeft: "4px",
    padding: "12px 17px !important",
  },
  marginRight5: {
    marginRight: "5px",
  },
  diversityCheckBox: {
    color: "#048475 !important",
    pointerEvents: "none",
  },
  viewAllMainDiv: {
    display: "flex",
    alignItems: "center",
    marginTop: "16px",
  },
  viewAllButton: {
    marginLeft: "8px",
    padding: "2px 15px",
    border: "1px solid #000000",
    "&:hover": {
      backgroundColor: "#000000 !important",
      color: "#ffffff",
    },
  },
  viewAllTypo: {
    fontSize: "12px",
  },
  moveToRemoveFromQueue: {
    backgroundColor: "white",
    border: "#B00020 solid 1px",
    padding: "4px 6px 4px 6px",
    color: "#B00020",
    marginLeft: "7px",
    borderRadius: "3px",
    cursor: "pointer",
  },
  requestInterview: {
    background: "#020203",
    color: "#FFFFFF",
    marginLeft: "9px",
    "&:hover": {
      backgroundColor: "#020203",
    },
  },
  moveToInterview: {
    background: "#020203",
    color: "#FFFFFF",
    marginLeft: "9px",
    "&:hover": {
      backgroundColor: "#020203",
    },
  },
  actionButtonsFlex: {
    display: "flex",
    alignItems: "center",
  },
  alignItemsCenter: {
    alignItems: "center",
  },
  markHiredButton: {
    backgroundColor: "white",
    border: "#28ca42 solid 1px",
    padding: "4px 6px 4px 6px",
    marginLeft: "9px",
    borderRadius: "3px",
    cursor: "pointer",
  },
}));

function CompareViewJobs() {
  const dispatch = useDispatch();
  const classes = useStyles();
  const id = useSelector((state) => state?.jobs?.jobDetails?.id);
  const allUpdateData = useSelector((state) => state.jobs?.allUpdateData);
  const headerCardValue = useSelector(
    (state) => state.jobs.allUpdateHeaderCard
  );
  const sendEmailValues = useSelector(
    (state) => state.jobs?.schedulingLinkValues
  );
  const accessPermission = useSelector(
    (state) => state.shared?.accessPermission
  );
  const jobType = useSelector((state) => state.jobs.jobDetails?.job_type);
  const jobStatus = useSelector((state) => state.jobs.jobDetails?.job_status);

  const [isOpenViewLiteProfile, setIsOpenViewLiteProfile] = useState(false);
  const [jobRecId, setJobRecId] = useState(null);
  const [anchorEl2, setAnchorEl2] = useState(null);
  const [openLikePopup, setLikePopup] = useState(false);
  const [recommendationId, setRecommendationId] = useState(null);
  const [openScheduleInterview, setOpenScheduleInterview] = useState(false);
  const [openInterviewDropdown, setOpenInterviewDropdown] = useState(false);
  const [scheduleInterviewType, setScheduleInterviewType] = useState("");
  const [openPreview, setOpenPreview] = useState(false);
  const [recIdForSechedulingInterview, setRecIdForSechedulingInterview] =
    useState("");
  const [openMarkedHired, setOpenMarkedHired] = useState(false);
  const [makedHiredRecId, setMakedHiredRecId] = useState("");
  const [dislikePopup, setDisLikePopup] = useState(false);
  const [candidateRommendationId, setcandidateRommendationId] = useState(null);
  const [recIdRemoveFromQueue, setRecIdRemoveFromQueue] = useState("");
  const [openMoveCandidateConversation, setOpenMoveCandidateConversation] =
    useState(false);
  const [recIdConversation, setRecIdConversation] = useState(false);
  const [recCandidateActiveStatus, setRecCandidateActiveStatus] = useState("");
  const [candidateHired, setCandidateHired] = useState(false);

  useEffect(() => {
    if (id) {
      switch (headerCardValue) {
        case "All":
          dispatch(jobsActions.getAllUpdatesData(id, "All"));
          break;
        case "1":
          dispatch(jobsActions.getAllUpdatesData(id, "Recommended"));
          break;
        case "2":
          dispatch(jobsActions.getAllUpdatesData(id, "Matched"));
          break;
        case "3":
          dispatch(jobsActions.getAllUpdatesData(id, "Conversation"));
          break;

        default:
          break;
      }
    }
  }, [id, headerCardValue, dispatch]);

  const downLoadResume = (uri) => {
    if (uri) {
      window.open(uri, "_blank");
    } else {
      toast.dark("Resume not available");
    }
  };

  const opneLinkedIn = (uri) => {
    const regex =
      /^(http(s)?:\/\/)?([\w]+\.)?linkedin\.com\/(pub|in|profile)/gm;
    const found = uri.match(regex);
    if (found) {
      if (!/^https?:\/\//i.test(uri)) {
        window.open(`http://${uri}`, "_blank");
      } else {
        window.open(uri, "_blank");
      }
    } else {
      toast.dark("LinkedIn URL not valid");
    }
  };

  const handleLiteProfileView = (rec_id, status) => {
    setIsOpenViewLiteProfile(!isOpenViewLiteProfile);
    setJobRecId(rec_id);
    setRecCandidateActiveStatus(status);
  };

  const handleOtherLiteProfileViewCLose = () => {
    setIsOpenViewLiteProfile(false);
  };

  const handleFullProfile = (
    candidateId,
    recommendationId,
    isMatchedArchived
  ) => {
    window.open(
      `/members/${candidateId}/${recommendationId}/${isMatchedArchived}`
    );
  };

  const showWarning = () => {
    toast.dark(
      "This operation is not allowed when you are logged in as SucceedSmart admin"
    );
  };

  // move inconversation all function
  const openMoveInConversation = (recId) => {
    setOpenMoveCandidateConversation(true);
    setRecIdConversation(recId);
  };

  const onhandleCloseMoveCandidateConversation = () => {
    setOpenMoveCandidateConversation(false);
  };

  // dislike all functions
  const handleOpenRemoveFromQueue = (recId) => {
    setDisLikePopup(true);
    setRecIdRemoveFromQueue(recId);
  };

  const handleCloseRemoveFromQueue = () => {
    setDisLikePopup(false);
  };

  // hired all functions
  const onCloseMarkedHired = () => {
    setOpenMarkedHired(false);
  };

  const handleOpenMarkedHired = (id) => {
    setOpenMarkedHired(true);
    setMakedHiredRecId(id);
  };

  // Like candidate functions
  const onHandleLike = (rec_id) => {
    setLikePopup(true);
    setRecommendationId(rec_id);
  };

  const onCLoseLike = () => {
    setLikePopup(false);
  };

  // schedule interview Preview and send email(All function)
  const onClickSheInterview = (e, recId, candidateId, is_hired) => {
    setOpenInterviewDropdown(!openInterviewDropdown);
    setAnchorEl2(e.currentTarget);
    setRecommendationId(recId);
    setCandidateHired(is_hired);
    setcandidateRommendationId(candidateId);
  };

  const sendScheduleSchedulingLinkCandidate = (
    scheduleInterviewType,
    recId
  ) => {
    setOpenScheduleInterview(true);
    setScheduleInterviewType(scheduleInterviewType);
    setOpenInterviewDropdown(false);
    setRecIdForSechedulingInterview(recId);
  };

  const closeScheduleInterview = () => {
    dispatch(jobsActions.schedulingLinkValues({}));
    setOpenScheduleInterview(false);
    setScheduleInterviewType("");
  };

  const editPreview = () => {
    setOpenScheduleInterview(true);
    setOpenPreview(false);
  };

  const onClosePreview = () => {
    dispatch(jobsActions.schedulingLinkValues({}));
    setOpenPreview(false);
  };

  const handlePreview = (values, note) => {
    dispatch(jobsActions.schedulingLinkValues(values));
    if (scheduleInterviewType === "email to coordinator") {
      const payloadEmailtoCoordinator = {
        option: scheduleInterviewType,
        first_name: values?.firstname,
        last_name: values?.lastname,
        email: values?.emailTocoordinator,
        is_preview: true,
      };
      setOpenPreview(true);
      setOpenScheduleInterview(false);
      handleEmailToCoordinator(payloadEmailtoCoordinator);
    } else {
      const payloadEmailTOCandidate = {
        note,
        is_preview: true,
      };
      handleEmailToCandidate(payloadEmailTOCandidate);
      setOpenPreview(true);
      setOpenScheduleInterview(false);
    }
  };

  const sendEmail = () => {
    if (scheduleInterviewType === "email to coordinator") {
      const payload = {
        option: scheduleInterviewType,
        first_name: sendEmailValues?.firstname,
        last_name: sendEmailValues?.lastname,
        email: sendEmailValues?.emailTocoordinator,
        is_preview: false,
      };
      handleEmailToCoordinator(payload);
    } else {
      const payload = {
        note: draftToHtml(
          convertToRaw(sendEmailValues.editorNotesState.getCurrentContent())
        ),
        is_preview: false,
      };
      handleEmailToCandidate(payload);
    }
    dispatch(jobsActions.schedulingLinkValues({}));
    setOpenPreview(false);
  };

  const handleEmailToCandidate = (payloadEmailTOCandidate) => {
    dispatch(
      membersActions.postSchedulingEmailToCandidate(
        recIdForSechedulingInterview,
        payloadEmailTOCandidate
      )
    );
  };

  const handleEmailToCoordinator = (payloadEmailtoCoordinator) => {
    dispatch(
      membersActions.postSchedulingEmailToCoordinator(
        recIdForSechedulingInterview,
        payloadEmailtoCoordinator
      )
    );
  };

  const sendMessage = (candidateId) => {
    if (accessPermission) {
      dispatch(messagesActions.createChannel(candidateId));
    } else {
      toast.dark("Only job owner or editor can send messages to candidate");
    }
  };

  const handleViewAll = (value) => {
    dispatch(setAllUpdateHeaderCard(value));
  };

  return (
    <Grid container>
      {(headerCardValue === "All" || headerCardValue === "1") && (
        <>
          <Typography align="left" className={classes.TableHeadingMain}>
            Talent Pipeline
          </Typography>
          <Grid
            item
            sm={12}
            xs={12}
            md={12}
            xl={12}
            lg={12}
            className={classes.overFlowScroll}
          >
            {allUpdateData?.recommended?.new_job_recommendations.length ===
            0 ? (
              <EmptyState type="Recommended" />
            ) : (
              <table className={classes.table}>
                {RECOMMENDED_HEADER?.map((val, ind) => (
                  <tr>
                    <th
                      className={classNames(
                        classes.tableHeading,
                        ind % 2 === 0
                          ? classes.colorOddHeading
                          : classes.colorEvenHeading
                      )}
                    >
                      {val.value}
                    </th>
                    {ind === 0 &&
                      allUpdateData?.recommended?.new_job_recommendations?.map(
                        (value) => (
                          <td
                            onClick={() =>
                              handleLiteProfileView(
                                value?.recommendation_id,
                                value?.new_recommendation_status
                              )
                            }
                            className={classNames(classes.colorOddCell)}
                          >
                            <span className={classes.nameMainSpan}>
                              <Avatar
                                className={classNames(
                                  classes.avatarSmall,
                                  classes.backDrop
                                )}
                                src={value?.profile_image}
                                alt={value?.candidate_fname}
                              />{" "}
                              <span
                                className={classNames(
                                  classes.marginLeft4,
                                  classes.backDrop
                                )}
                              >
                                {`${value?.candidate_fname} ${value?.candidate_lname}`}
                              </span>
                              {value?.recommendation_status ===
                                "Action Required" && (
                                <span className={classes.newChip}>New</span>
                              )}
                            </span>
                          </td>
                        )
                      )}

                    {ind === 1 &&
                      allUpdateData?.recommended?.new_job_recommendations?.map(
                        (value) => (
                          <td className={classes.colorEvenCell}>
                            {value?.match_based_on?.title === value?.title && (
                              <MatchedCriteria
                                color="#00DCAF"
                                title="Profile Match"
                                className={classes.marginRight5}
                              />
                            )}
                            {value?.title ? value?.title : "-"}
                          </td>
                        )
                      )}
                    {ind === 2 &&
                      allUpdateData?.recommended?.new_job_recommendations?.map(
                        (value) => (
                          <td className={classes.colorOddCell}>
                            {value?.location.city ? value?.location.city : ""}
                            {value?.location.city && value?.location.state
                              ? ", "
                              : ""}
                            {value?.location.state ? value?.location.state : ""}
                            {(value?.location.city || value?.location.state) &&
                            value?.location.country
                              ? ", "
                              : ""}
                            {value?.location.country
                              ? value?.location.country
                              : ""}
                            {value?.location.city ||
                            value?.location.state ||
                            value?.location.country
                              ? ""
                              : "-"}
                          </td>
                        )
                      )}
                    {ind === 3 &&
                      allUpdateData?.recommended?.new_job_recommendations?.map(
                        (value) => (
                          <td className={classes.colorEvenCell}>
                            <span className={classes.timeCellsCss}>
                              <Tooltip
                                placement="left"
                                arrow
                                title={moment(value?.recommended_on).format(
                                  "MM/DD/YYYY, hh:mm A"
                                )}
                              >
                                <span className={classes.timeInnerSpan}>
                                  {moment(value?.recommended_on)
                                    .startOf("minutes")
                                    .fromNow()}
                                </span>
                              </Tooltip>
                            </span>
                          </td>
                        )
                      )}
                    {ind === 4 &&
                      allUpdateData?.recommended?.new_job_recommendations?.map(
                        (value) => (
                          <td className={classes.colorOddCell}>
                            {value?.industries?.map((industry, ind) => (
                              <span>
                                {value?.match_based_on?.industry?.includes(
                                  industry
                                ) && (
                                  <MatchedCriteria
                                    color="#00DCAF"
                                    title="Profile Match"
                                    className={classes.marginRight5}
                                  />
                                )}
                                {industry || "-"}
                                {value?.industries?.length !== ind + 1
                                  ? ", "
                                  : ""}
                              </span>
                            ))}
                          </td>
                        )
                      )}
                    {ind === 5 &&
                      allUpdateData?.recommended?.new_job_recommendations?.map(
                        (value) => (
                          <td className={classes.colorEvenCell}>
                            {value?.salary ? value?.salary : "-"}
                          </td>
                        )
                      )}
                    {ind === 6 &&
                      allUpdateData?.recommended?.new_job_recommendations?.map(
                        (value) => (
                          <td className={classes.colorOddCell}>
                            {value?.open_to_relocation === "Yes" ? "YES" : "NO"}
                          </td>
                        )
                      )}
                    {ind === 7 &&
                      allUpdateData?.recommended?.new_job_recommendations?.map(
                        (value) => (
                          <td className={classes.colorEvenCell}>
                            {value?.is_diversity ? (
                              <Checkbox
                                readOnly
                                checked
                                iconStyle={{ fill: "#048475" }}
                                style={{ color: "#048475" }}
                              />
                            ) : (
                              "-"
                            )}
                          </td>
                        )
                      )}
                    {ind === 8 &&
                      allUpdateData?.recommended?.new_job_recommendations?.map(
                        (value) => (
                          <td className={classes.colorOddCell}>
                            {value?.new_recommendation_status
                              ? value?.new_recommendation_status
                              : "-"}
                          </td>
                        )
                      )}
                    {ind === 9 &&
                      allUpdateData?.recommended?.new_job_recommendations?.map(
                        (value) => (
                          <td className={classes.colorEvenCell}>
                            <span className={classes.displayFlex}>
                              <Button
                                size="small"
                                onClick={() =>
                                  handleLiteProfileView(
                                    value?.recommendation_id,
                                    value?.new_recommendation_status
                                  )
                                }
                                className={classes.viewButton}
                                startIcon={<VisibilityIcon />}
                              >
                                View
                              </Button>
                              {value?.new_recommendation_status !==
                                "Pending with Candidate" &&
                                accessPermission &&
                                jobStatus !== "Close" && (
                                  <Tooltip title="Mark Interested" arrow>
                                    <IconButton
                                      size="small"
                                      className={classes.LikeButton}
                                      onClick={() =>
                                        onHandleLike(value?.recommendation_id)
                                      }
                                    >
                                      <ThumbsUpIcon />
                                    </IconButton>
                                  </Tooltip>
                                )}
                            </span>
                          </td>
                        )
                      )}
                  </tr>
                ))}
              </table>
            )}
          </Grid>
          {headerCardValue === "All" &&
            allUpdateData?.recommended?.new_job_recommendations?.length > 0 && (
              <div className={classes.viewAllMainDiv}>
                <Typography className={classes.viewAllTypo}>
                  Click to see Talent Pipeline
                </Typography>{" "}
                <Button
                  onClick={() => handleViewAll("1")}
                  variant="outlined"
                  size="small"
                  className={classes.viewAllButton}
                >
                  View All
                </Button>
              </div>
            )}
          <Grid
            item
            sm={12}
            xs={12}
            md={12}
            xl={12}
            lg={12}
            className={classes.marginTopCustom}
          >
            <Divider />
          </Grid>
        </>
      )}

      {(headerCardValue === "All" || headerCardValue === "2") && (
        <>
          <Typography align="left" className={classes.TableHeadingMain2}>
            Shortlisted Candidates
          </Typography>
          <Grid
            item
            sm={12}
            xs={12}
            md={12}
            xl={12}
            lg={12}
            className={classes.overFlowScroll}
          >
            {allUpdateData?.shortlisted_candidates?.like_back_by_candidates
              ?.length === 0 ? (
              <EmptyState type="Matched" />
            ) : (
              <table className={classes.table}>
                {MATCHED_HEADER?.map((val, ind) => (
                  <tr>
                    {(!accessPermission || jobStatus === "Close") &&
                    ind === 11 ? (
                      ""
                    ) : (
                      <th
                        className={classNames(
                          classes.tableHeading,
                          ind % 2 === 0
                            ? classes.colorOddHeading
                            : classes.colorEvenHeading
                        )}
                      >
                        {val.value}
                      </th>
                    )}
                    {ind === 0 &&
                      allUpdateData?.shortlisted_candidates?.like_back_by_candidates?.map(
                        (value) => (
                          <td
                            onClick={() => {
                              handleFullProfile(
                                value?.candidate_id,
                                value?.recommendation_id,
                                false
                              );
                            }}
                            className={classes.colorOddCell}
                          >
                            <span className={classes.nameMainSpan}>
                              <Avatar
                                className={classes.avatarSmall}
                                src={value?.profile_image}
                                alt={value?.candidate_fname}
                              />{" "}
                              <span className={classes.marginLeft4}>
                                {`${value?.candidate_fname} ${value?.candidate_lname}`}
                              </span>
                              {value?.new_recommendation_status ===
                                "Meeting to be Scheduled" && (
                                <span className={classes.newChip}>New</span>
                              )}
                            </span>
                          </td>
                        )
                      )}
                    {ind === 1 &&
                      allUpdateData?.shortlisted_candidates?.like_back_by_candidates?.map(
                        (value) => (
                          <td className={classes.colorEvenCell}>
                            {value?.match_based_on?.title === value?.title && (
                              <MatchedCriteria
                                color="#00DCAF"
                                title="Profile Match"
                                className={classes.marginRight5}
                              />
                            )}
                            {value?.title ? value?.title : "-"}
                          </td>
                        )
                      )}
                    {ind === 2 &&
                      allUpdateData?.shortlisted_candidates?.like_back_by_candidates?.map(
                        (value) => (
                          <td className={classes.colorOddCell}>
                            {value?.location.city ? value?.location.city : ""}
                            {value?.location.city && value?.location.state
                              ? ", "
                              : ""}
                            {value?.location.state ? value?.location.state : ""}
                            {(value?.location.city || value?.location.state) &&
                            value?.location.country
                              ? ", "
                              : ""}
                            {value?.location.country
                              ? value?.location.country
                              : ""}
                            {value?.location.city ||
                            value?.location.state ||
                            value?.location.country
                              ? ""
                              : "-"}
                          </td>
                        )
                      )}
                    {ind === 3 &&
                      allUpdateData?.shortlisted_candidates?.like_back_by_candidates?.map(
                        (value) => (
                          <td className={classes.colorEvenCell}>
                            <span className={classes.timeCellsCss}>
                              <Tooltip
                                placement="left"
                                arrow
                                title={moment(value?.recommended_on).format(
                                  "MM/DD/YYYY, hh:mm A"
                                )}
                              >
                                <span className={classes.timeInnerSpan}>
                                  {moment(value?.recommended_on)
                                    .startOf("minutes")
                                    .fromNow()}
                                </span>
                              </Tooltip>
                            </span>
                          </td>
                        )
                      )}
                    {ind === 4 &&
                      allUpdateData?.shortlisted_candidates?.like_back_by_candidates?.map(
                        (value) => (
                          <td className={classes.colorEvenCell}>
                            <span className={classes.timeCellsCss}>
                              <span className={classes.timeInnerSpan}>
                                {value?.liked_back_on
                                  ? moment(value?.liked_back_on)
                                      .startOf("minutes")
                                      .fromNow()
                                  : "-"}
                              </span>
                            </span>
                          </td>
                        )
                      )}
                    {ind === 5 &&
                      allUpdateData?.shortlisted_candidates?.like_back_by_candidates?.map(
                        (value) => (
                          <td className={classes.colorOddCell}>
                            {value?.industries?.map((industry, ind) => (
                              <span>
                                {value?.match_based_on?.industry?.includes(
                                  industry
                                ) && (
                                  <MatchedCriteria
                                    color="#00DCAF"
                                    title="Profile Match"
                                    className={classes.marginRight5}
                                  />
                                )}
                                {industry || "-"}
                                {value?.industries?.length !== ind + 1
                                  ? ", "
                                  : ""}
                              </span>
                            ))}
                          </td>
                        )
                      )}
                    {ind === 6 &&
                      allUpdateData?.shortlisted_candidates?.like_back_by_candidates?.map(
                        (value) => (
                          <td className={classes.colorEvenCell}>
                            {value?.salary ? value?.salary : "-"}
                          </td>
                        )
                      )}
                    {ind === 7 &&
                      allUpdateData?.shortlisted_candidates?.like_back_by_candidates?.map(
                        (value) => (
                          <td className={classes.colorOddCell}>
                            {value?.open_to_relocation === "Yes" ? "YES" : "NO"}
                          </td>
                        )
                      )}
                    {ind === 8 &&
                      allUpdateData?.shortlisted_candidates?.like_back_by_candidates?.map(
                        (value) => (
                          <td className={classes.colorEvenCell}>
                            {value?.is_diversity ? (
                              <Checkbox
                                readOnly
                                checked
                                iconStyle={{ fill: "#048475" }}
                                className={classes.diversityCheckBox}
                              />
                            ) : (
                              "-"
                            )}
                          </td>
                        )
                      )}

                    {ind === 9 &&
                      allUpdateData?.shortlisted_candidates?.like_back_by_candidates?.map(
                        (value) => (
                          <td className={classes.colorOddCell}>
                            <span className={classes.displayFlex}>
                              <Button
                                onClick={() => {
                                  downLoadResume(
                                    value?.resume,
                                    `${value?.candidate_fname} ${value?.candidate_lname}`
                                  );
                                }}
                                size="small"
                                className={classes.viewButton}
                                startIcon={<GetAppIcon />}
                              >
                                Download
                              </Button>
                              <Button
                                onClick={() => {
                                  opneLinkedIn(value?.linkedin_url);
                                }}
                                size="small"
                                className={classes.LinkedinButton}
                                startIcon={<LinkedInIcon />}
                              >
                                Open
                              </Button>
                            </span>
                          </td>
                        )
                      )}
                    {/* {ind === 10 &&
                      allUpdateData?.shortlisted_candidates?.like_back_by_candidates?.map(
                        (value) => (
                          <td className={classes.colorEvenCell}>
                            <Button
                              onClick={() => {
                                handleFullProfile(
                                  value?.candidate_id,
                                  value?.recommendation_id,
                                  false
                                );
                              }}
                              size="small"
                              className={classes.viewButton}
                              startIcon={<VisibilityIcon />}
                            >
                              View
                            </Button>
                          </td>
                        )
                      )} */}
                    {ind === 10 &&
                      allUpdateData?.shortlisted_candidates?.like_back_by_candidates?.map(
                        (value) => (
                          <td className={classNames(classes.colorEvenCell)}>
                            <span
                              className={classNames(
                                classes.displayFlex,
                                classes.alignItemsCenter
                              )}
                            >
                              <Button
                                onClick={() => {
                                  handleFullProfile(
                                    value?.candidate_id,
                                    value?.recommendation_id,
                                    false
                                  );
                                }}
                                size="small"
                                className={classes.viewButton}
                                startIcon={<VisibilityIcon />}
                              >
                                View
                              </Button>
                              {accessPermission && jobStatus !== "Close" && (
                                <>
                                  <Tooltip
                                    title="Move to Interview Stage"
                                    arrow
                                  >
                                    <Button
                                      size="small"
                                      onClick={() =>
                                        openMoveInConversation(
                                          value.recommendation_id
                                        )
                                      }
                                      className={classes.moveToInterview}
                                      startIcon={<ArrowRightAltIcon />}
                                    >
                                      Move to Interview Stage
                                    </Button>
                                  </Tooltip>
                                  <Tooltip title="Remove from Queue" arrow>
                                    <span
                                      onClick={() =>
                                        handleOpenRemoveFromQueue(
                                          value.recommendation_id
                                        )
                                      }
                                      className={classes.moveToRemoveFromQueue}
                                    >
                                      <RemovedFromIcon />
                                    </span>
                                  </Tooltip>
                                </>
                              )}
                            </span>
                          </td>
                        )
                      )}
                  </tr>
                ))}
              </table>
            )}
          </Grid>
          {headerCardValue === "All" &&
            allUpdateData?.shortlisted_candidates?.like_back_by_candidates
              ?.length > 0 && (
              <div className={classes.viewAllMainDiv}>
                <Typography className={classes.viewAllTypo}>
                  Click to see all Shortlisted Candidates
                </Typography>{" "}
                <Button
                  onClick={() => handleViewAll("2")}
                  variant="outlined"
                  size="small"
                  className={classes.viewAllButton}
                >
                  View All
                </Button>
              </div>
            )}
          <Grid
            item
            sm={12}
            xs={12}
            md={12}
            xl={12}
            lg={12}
            className={classes.marginTopCustom}
          >
            <Divider />
          </Grid>
        </>
      )}

      {(headerCardValue === "All" || headerCardValue === "3") && (
        <>
          <Typography align="left" className={classes.TableHeadingMain2}>
            Candidate in conversation
          </Typography>
          <Grid
            item
            sm={12}
            xs={12}
            md={12}
            xl={12}
            lg={12}
            className={classes.overFlowScroll}
          >
            {allUpdateData?.in_conversation_candidates
              ?.in_conversation_candidates?.length === 0 ? (
              <EmptyState type="Conversation" />
            ) : (
              <table className={classes.table}>
                {MATCHED_HEADER?.map((val, ind) => (
                  <tr>
                    <th
                      className={classNames(
                        classes.tableHeading,
                        ind % 2 === 0
                          ? classes.colorOddHeading
                          : classes.colorEvenHeading
                      )}
                    >
                      {val.value}
                    </th>

                    {ind === 0 &&
                      allUpdateData?.in_conversation_candidates?.in_conversation_candidates?.map(
                        (value) => (
                          <td
                            onClick={() => {
                              handleFullProfile(
                                value?.candidate_id,
                                value?.recommendation_id,
                                false
                              );
                            }}
                            className={classes.colorOddCell}
                          >
                            <span className={classes.nameMainSpan}>
                              <Avatar
                                className={classes.avatarSmall}
                                src={value?.profile_image}
                                alt={value?.candidate_fname}
                              />{" "}
                              <span className={classes.marginLeft4}>
                                {`${value?.candidate_fname} ${value?.candidate_lname}`}
                              </span>
                              {value?.is_hired && (
                                <span className={classes.newChip}>Hired</span>
                              )}
                            </span>
                          </td>
                        )
                      )}
                    {ind === 1 &&
                      allUpdateData?.in_conversation_candidates?.in_conversation_candidates?.map(
                        (value) => (
                          <td className={classes.colorEvenCell}>
                            {value?.match_based_on?.title === value?.title && (
                              <MatchedCriteria
                                color="#00DCAF"
                                title="Profile Match"
                                className={classes.marginRight5}
                              />
                            )}
                            {value?.title ? value?.title : "-"}
                          </td>
                        )
                      )}
                    {ind === 2 &&
                      allUpdateData?.in_conversation_candidates?.in_conversation_candidates?.map(
                        (value) => (
                          <td className={classes.colorOddCell}>
                            {value?.location.city ? value?.location.city : ""}
                            {value?.location.city && value?.location.state
                              ? ", "
                              : ""}
                            {value?.location.state ? value?.location.state : ""}
                            {(value?.location.city || value?.location.state) &&
                            value?.location.country
                              ? ", "
                              : ""}
                            {value?.location.country
                              ? value?.location.country
                              : ""}
                            {value?.location.city ||
                            value?.location.state ||
                            value?.location.country
                              ? ""
                              : "-"}
                          </td>
                        )
                      )}
                    {ind === 3 &&
                      allUpdateData?.in_conversation_candidates?.in_conversation_candidates?.map(
                        (value) => (
                          <td className={classes.colorEvenCell}>
                            <span className={classes.timeCellsCss}>
                              <Tooltip
                                placement="left"
                                arrow
                                title={moment(value?.recommended_on).format(
                                  "MM-DD-YYYY, hh:mm A"
                                )}
                              >
                                <span className={classes.timeInnerSpan}>
                                  {moment(value?.recommended_on)
                                    .startOf("minutes")
                                    .fromNow()}
                                </span>
                              </Tooltip>
                            </span>
                          </td>
                        )
                      )}
                    {ind === 4 &&
                      allUpdateData?.in_conversation_candidates?.in_conversation_candidates?.map(
                        (value) => (
                          <td className={classes.colorEvenCell}>
                            <span className={classes.timeCellsCss}>
                              <span className={classes.timeInnerSpan}>
                                {value?.liked_back_on ? (
                                  <Tooltip
                                    placement="left"
                                    arrow
                                    title={moment(value?.liked_back_on).format(
                                      "MM-DD-YYYY, hh:mm A"
                                    )}
                                  >
                                    <span className={classes.timeInnerSpan}>
                                      {moment(value?.liked_back_on)
                                        .startOf("minutes")
                                        .fromNow()}
                                    </span>
                                  </Tooltip>
                                ) : (
                                  "-"
                                )}
                              </span>
                            </span>
                          </td>
                        )
                      )}
                    {ind === 5 &&
                      allUpdateData?.in_conversation_candidates?.in_conversation_candidates?.map(
                        (value) => (
                          <td className={classes.colorOddCell}>
                            {value?.industries?.map((industry, ind) => (
                              <span>
                                {value?.match_based_on?.industry?.includes(
                                  industry
                                ) && (
                                  <MatchedCriteria
                                    color="#00DCAF"
                                    title="Profile Match"
                                    className={classes.marginRight5}
                                  />
                                )}
                                {industry || "-"}
                                {value?.industries?.length !== ind + 1
                                  ? ", "
                                  : ""}
                              </span>
                            ))}
                          </td>
                        )
                      )}
                    {ind === 6 &&
                      allUpdateData?.in_conversation_candidates?.in_conversation_candidates?.map(
                        (value) => (
                          <td className={classes.colorEvenCell}>
                            {value?.salary ? value?.salary : "-"}
                          </td>
                        )
                      )}
                    {ind === 7 &&
                      allUpdateData?.in_conversation_candidates?.in_conversation_candidates?.map(
                        (value) => (
                          <td className={classes.colorOddCell}>
                            {value?.open_to_relocation === "Yes" ? "YES" : "NO"}
                          </td>
                        )
                      )}
                    {ind === 8 &&
                      allUpdateData?.in_conversation_candidates?.in_conversation_candidates?.map(
                        (value) => (
                          <td className={classes.colorEvenCell}>
                            {value?.is_diversity ? (
                              <Checkbox
                                readOnly
                                checked
                                iconStyle={{ fill: "#048475" }}
                                className={classes.diversityCheckBox}
                              />
                            ) : (
                              "-"
                            )}
                          </td>
                        )
                      )}

                    {ind === 9 &&
                      allUpdateData?.in_conversation_candidates?.in_conversation_candidates?.map(
                        (value) => (
                          <td className={classes.colorOddCell}>
                            <span className={classes.displayFlex}>
                              <Button
                                onClick={() => {
                                  downLoadResume(
                                    value?.resume,
                                    `${value?.candidate_fname} ${value?.candidate_lname}`
                                  );
                                }}
                                size="small"
                                className={classes.viewButton}
                                startIcon={<GetAppIcon />}
                              >
                                Download
                              </Button>
                              <Button
                                onClick={() => {
                                  opneLinkedIn(value?.linkedin_url);
                                }}
                                size="small"
                                className={classes.LinkedinButton}
                                startIcon={<LinkedInIcon />}
                              >
                                Open
                              </Button>
                            </span>
                          </td>
                        )
                      )}
                    {ind === 10 &&
                      allUpdateData?.in_conversation_candidates?.in_conversation_candidates?.map(
                        (value) => (
                          <td className={classes.colorEvenCell}>
                            <span
                              className={classNames(
                                classes.displayFlex,
                                classes.alignItemsCenter
                              )}
                            >
                              <Button
                                onClick={() => {
                                  handleFullProfile(
                                    value?.candidate_id,
                                    value?.recommendation_id,
                                    false
                                  );
                                }}
                                size="small"
                                className={classes.viewButton}
                                startIcon={<VisibilityIcon />}
                              >
                                View
                              </Button>
                              {accessPermission && jobStatus !== "Close" && (
                                <>
                                  {jobType !== "Confidential" ||
                                  !value.is_hired ? (
                                    <Tooltip title="Request Interview" arrow>
                                      <Button
                                        className={classes.requestInterview}
                                        onClick={(e) =>
                                          onClickSheInterview(
                                            e,
                                            value?.recommendation_id,
                                            value?.candidate_id,
                                            value?.is_hired
                                          )
                                        }
                                        size="small"
                                        startIcon={<RequestInterviewIcon />}
                                      >
                                        Request Interview
                                      </Button>
                                    </Tooltip>
                                  ) : null}
                                  <div className={classes.dropDownMain}>
                                    <Popper
                                      open={openInterviewDropdown}
                                      anchorEl={anchorEl2}
                                      className={classes.zIndex9999}
                                      placement="left-end"
                                      transition
                                    >
                                      {({ TransitionProps }) => (
                                        <Fade {...TransitionProps}>
                                          <Paper>
                                            <ClickAwayListener
                                              onClickAway={() =>
                                                setOpenInterviewDropdown(false)
                                              }
                                            >
                                              <MenuList>
                                                {!candidateHired && (
                                                  <>
                                                    <MenuItem
                                                      className={
                                                        classes.borderBottom
                                                      }
                                                    >
                                                      <Typography
                                                        className={
                                                          classes.moreFont
                                                        }
                                                        onClick={() =>
                                                          !secureLocalStorage.getItem(
                                                            "g_u"
                                                          )
                                                            ? sendScheduleSchedulingLinkCandidate(
                                                                "email to candidate",
                                                                recommendationId
                                                              )
                                                            : showWarning()
                                                        }
                                                      >
                                                        Request candidate for
                                                        interview
                                                      </Typography>
                                                    </MenuItem>
                                                    <MenuItem
                                                      className={
                                                        classes.borderBottom
                                                      }
                                                    >
                                                      <Typography
                                                        className={
                                                          classes.moreFont
                                                        }
                                                        onClick={() =>
                                                          !secureLocalStorage.getItem(
                                                            "g_u"
                                                          )
                                                            ? sendScheduleSchedulingLinkCandidate(
                                                                "email to coordinator",
                                                                recommendationId
                                                              )
                                                            : showWarning()
                                                        }
                                                      >
                                                        Send email to
                                                        coordinator
                                                      </Typography>
                                                    </MenuItem>
                                                  </>
                                                )}
                                                {jobType !== "Confidential" && (
                                                  <MenuItem
                                                    onClick={(event) => {
                                                      event.preventDefault();
                                                      !secureLocalStorage.getItem(
                                                        "g_u"
                                                      )
                                                        ? sendMessage(
                                                            candidateRommendationId
                                                          )
                                                        : showWarning();
                                                    }}
                                                    className={
                                                      classes.borderBottom
                                                    }
                                                  >
                                                    <Typography
                                                      className={
                                                        classes.moreFont
                                                      }
                                                    >
                                                      Send message to candidate
                                                    </Typography>
                                                  </MenuItem>
                                                )}
                                              </MenuList>
                                            </ClickAwayListener>
                                          </Paper>
                                        </Fade>
                                      )}
                                    </Popper>
                                  </div>
                                  {!value.is_hired && (
                                    <>
                                      <Tooltip title="Mark as Hired" arrow>
                                        <span
                                          onClick={() => {
                                            handleOpenMarkedHired(
                                              value.recommendation_id
                                            );
                                          }}
                                          className={classes.markHiredButton}
                                        >
                                          <MarkAsHiredIcon />
                                        </span>
                                      </Tooltip>
                                      <Tooltip title="Remove from Queue" arrow>
                                        <span
                                          onClick={() =>
                                            handleOpenRemoveFromQueue(
                                              value.recommendation_id
                                            )
                                          }
                                          className={
                                            classes.moveToRemoveFromQueue
                                          }
                                        >
                                          <RemovedFromIcon />
                                        </span>
                                      </Tooltip>
                                    </>
                                  )}
                                </>
                              )}
                            </span>
                          </td>
                        )
                      )}
                  </tr>
                ))}
              </table>
            )}
          </Grid>
          {headerCardValue === "All" &&
            allUpdateData?.in_conversation_candidates
              ?.in_conversation_candidates?.length > 0 && (
              <div className={classes.viewAllMainDiv}>
                <Typography className={classes.viewAllTypo}>
                  Click to see all Candidates in conversations
                </Typography>{" "}
                <Button
                  onClick={() => handleViewAll("3")}
                  variant="outlined"
                  size="small"
                  className={classes.viewAllButton}
                >
                  View All
                </Button>
              </div>
            )}
        </>
      )}

      {/* Other Components */}
      {isOpenViewLiteProfile && (
        <ViewLiteProfilePopUp
          candidateStatus={recCandidateActiveStatus}
          handleOtherLiteProfileViewCLose={handleOtherLiteProfileViewCLose}
          jobId={id}
          open={isOpenViewLiteProfile}
          jobRecommendedId={jobRecId}
          handleLiteProfileView={handleLiteProfileView}
        />
      )}

      {/* Like popup  */}

      <LikeComponent
        onCLoseLike={onCLoseLike}
        open={openLikePopup}
        jobId={id}
        recommendationId={recommendationId}
      />

      {/* Interview popup */}
      {openScheduleInterview && (
        <SchedulingPopup
          handlePreview={handlePreview}
          openScheduleInterview={openScheduleInterview}
          scheduleInterviewType={scheduleInterviewType}
          closeScheduleInterview={closeScheduleInterview}
        />
      )}

      {/* Interview Preview popup */}
      {openPreview ? (
        <PreviewEmaildailog
          open={openPreview}
          editPreview={editPreview}
          onSend={sendEmail}
          onClose={onClosePreview}
        />
      ) : null}

      <MarkedHired
        open={openMarkedHired}
        onClose={onCloseMarkedHired}
        makedHiredRecId={makedHiredRecId}
        jobId={id}
      />
      <RemoveFromQueue
        close={handleCloseRemoveFromQueue}
        open={dislikePopup}
        jobId={id}
        recommendationId={recIdRemoveFromQueue}
        redirectfrom={REDIRECT_TO_COMPONENT.ALL_CANDIDATES}
      />
      <MoveCandidateinConversation
        jobId={id}
        recIdConversation={recIdConversation}
        open={openMoveCandidateConversation}
        onClose={onhandleCloseMoveCandidateConversation}
      />
    </Grid>
  );
}

export default CompareViewJobs;
