import { React, Component } from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
// import { withRouter } from 'react-router-dom';
import Typography from "@material-ui/core/Typography";
import Link from "@material-ui/core/Link";
import { connect } from "react-redux";
import { VERSION } from "../../constants/constants";

const styles = (theme) => ({
  footer: {
    [theme.breakpoints.between("xs", "sm")]: {
      height: "40px",
    },
    [theme.breakpoints.between("md", "lg")]: {
      height: "60px",
    },
    flexDirection: "row",
    [theme.breakpoints.between("xs", "sm")]: {
      flexDirection: "column",
    },
    backgroundColor: "#000",
    color: "#fff",
    width: "100%",
    height: "80px",
    display: "flex",
    alignItems: "center",
    bottom: 0,
    position: "fixed",
    padding: "0px 48px",
    justifyContent: "space-between",
  },
});

class Footer extends Component {
  render() {
    const { classes } = this.props;
    return (
      <Typography variant="body2" className={classes.footer}>
        <div>
          {`© Copyright ${new Date().getFullYear()},`} &nbsp;
          <Link
            color="inherit"
            href="https://succeedsmart.com/"
            target="_blank"
          >
            Succeedsmart. All rights reserved. &nbsp;
          </Link>{" "}
        </div>
        <div>
          Version:
          {VERSION}
        </div>
      </Typography>
    );
  }
}

Footer.propTypes = {
  classes: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => state;

// const connectedFooterPage = withRouter(connect(mapStateToProps, null, null, {
//     pure: false
//   })(withStyles(styles)(Footer)));

// export default withStyles(styles)(Footer);
export default connect(mapStateToProps, null, null, { pure: false })(
  withStyles(styles)(Footer)
);
// export default connect(mapStateToProps, null, null, {loggingIn})(withStyles(styles)(Footer))
// export { connectedFooterPage as Footer };
