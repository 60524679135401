import { PhoneNumberUtil } from "google-libphonenumber";
import {
  InputAdornment,
  MenuItem,
  Select,
  TextField,
  Typography,
} from "@material-ui/core";
import React, { useState } from "react";
import {
  defaultCountries,
  FlagImage,
  parseCountry,
  usePhoneInput,
} from "react-international-phone";
import { makeStyles } from "@material-ui/core/styles";
import { MESSAGE_FIELD_IS_REQUIRED } from "../../constants/constants";
import { modifyString } from "../../helpers/functions";

const useStyles = makeStyles(() => ({
  textField: {
    width: "100%",
  },
  inputAdornment: {
    marginRight: "2px",
    marginLeft: "0px",
  },
  flagImage: {
    display: "flex",
    height: "15px",
  },
  smallMarginRight: {
    marginRight: "8px",
  },
  select: {
    border: "none",
  },
  flagIcon: {
    height: "15px",
    marginRight: "8px",
  },
}));

const phoneUtil = PhoneNumberUtil.getInstance();

const isPhoneValid = (phone) => {
  try {
    return phoneUtil.isValidNumber(phoneUtil.parseAndKeepRawInput(phone));
  } catch (error) {
    return false;
  }
};

const PhoneNumber = ({
  value,
  onChangeValue,
  isPersonalInfoEditable,
  ...restProps
}) => {
  const { inputValue, handlePhoneValueChange, inputRef, country, setCountry } =
    usePhoneInput({
      defaultCountry: "us",
      value,
      countries: defaultCountries,
      onChange: (data) => {
        onChange(data.phone, data.inputValue);
      },
    });

  const [validate, setValidate] = useState(true);
  const [numberRequired, setNumberRequired] = useState(false);
  const classes = useStyles();

  const onChange = (phone, inputvalue) => {
    let modifiedNumber = modifyString(inputvalue);

    !modifiedNumber.length ? setNumberRequired(true) : setNumberRequired(false);
    let validate = isPhoneValid(phone);
    setValidate(validate);
    onChangeValue(modifiedNumber, validate, numberRequired);
  };

  return (
    <TextField
      required
      className={classes.textField}
      variant="standard"
      label="PHONE NUMBER"
      color="primary"
      placeholder={`Phone number`}
      value={inputValue}
      onChange={handlePhoneValueChange}
      type="tel"
      inputRef={inputRef}
      error={!validate || numberRequired}
      helperText={
        isPersonalInfoEditable &&
        (numberRequired
          ? MESSAGE_FIELD_IS_REQUIRED
          : !validate
          ? "Invalid phone number"
          : "")
      }
      InputProps={{
        readOnly: !isPersonalInfoEditable ? true : false,
        startAdornment: (
          <InputAdornment position="start" className={classes.inputAdornment}>
            <Select
              className={classes.select}
              disabled={!isPersonalInfoEditable}
              MenuProps={{
                transformOrigin: {
                  vertical: "top",
                  horizontal: "left",
                },
              }}
              sx={{
                width: "max-content",
                // Remove default outline (display only on focus)
                fieldset: {
                  display: "none",
                },
                '&.Mui-focused:has(div[aria-expanded="false"])': {
                  fieldset: {
                    display: "block",
                  },
                },
                // Update default spacing
                ".MuiSelect-select": {
                  padding: "8px",
                  paddingRight: "24px !important",
                },
                ".MuiSelect-selectMenu": {
                  height: "0px",
                },
                svg: {
                  right: 0,
                },
              }}
              value={country.iso2}
              onChange={(e) => setCountry(e.target.value)}
              renderValue={(value) => (
                <FlagImage iso2={value} className={classes.flagImage} />
              )}
            >
              {defaultCountries.map((c) => {
                const country = parseCountry(c);
                return (
                  <MenuItem key={country.iso2} value={country.iso2}>
                    <FlagImage
                      iso2={country.iso2}
                      className={classes.flagIcon}
                    />
                    <Typography className={classes.smallMarginRight}>
                      {country.name}
                    </Typography>
                    <Typography color="gray">+{country.dialCode}</Typography>
                  </MenuItem>
                );
              })}
            </Select>
          </InputAdornment>
        ),
      }}
      {...restProps}
    />
  );
};

export default PhoneNumber;
