import {
  Dialog,
  DialogContent,
  Typography,
  IconButton,
  Grid,
  Button,
  Divider,
} from "@material-ui/core";
import React, { useEffect, useState } from "react";
import CloseIcon from "@material-ui/icons/Close";
import { makeStyles } from "@material-ui/core/styles";
import { useDispatch, useSelector } from "react-redux";
import { Check, Close } from "@material-ui/icons";
import { jobsActions } from "../../../actions";
import LiteProfile from "../../customComponents/liteProfile";
import Comment from "../../customComponents/comment";
import LikeComponent from "../../customComponents/likeComponent";
import AdminNotes from "../../customComponents/adminNotes";
import RemoveFromQueue from "../../customComponents/removeFromQueue";
import { REDIRECT_TO_COMPONENT } from "../../../constants/constants";

const useStyles = makeStyles((theme) => ({
  closeButton: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.default.main,
    padding: "0px",
  },

  mediumBottomMargin: {
    marginBottom: "5%",
  },
  customButtonDisplayInterested: {
    display: "flex",
    alignItems: "start",
    justifyContent: "left",
    flexDirection: "inherit",
    marginTop: "8px",
  },

  customMinWidth: {
    minWidth: "8em",
    [theme.breakpoints.between("xs", "sm")]: {
      minWidth: "10em",
    },
  },
  profileBorderColor: {
    borderRight: "1px solid rgba(0, 0, 0, 0.12)",
  },
  actionsMainDiv: {
    padding: "10px",
    marginTop: "30px",
  },
  buttonsDiv: {
    display: "flex",
    justifyContent: "flex-start",
    marginTop: "10px",
    gap: "5px",
  },
  marginTop20: {
    marginTop: "20px",
  },
}));

function ViewLiteProfilePopUp({
  jobRecommendedId,
  open,
  jobId,
  handleOtherLiteProfileViewCLose,
  candidateStatus,
}) {
  const dispatch = useDispatch();
  const classes = useStyles();
  const liteProfileData = useSelector(
    (state) => state.jobs?.liteProfileData?.lite_profile
  );
  const headerCardValue = useSelector(
    (state) => state.jobs.allUpdateHeaderCard
  );
  const accessPermission = useSelector(
    (state) => state.shared?.accessPermission
  );
  const jobStatus = useSelector((state) => state.jobs.jobDetails?.job_status);
  const [openLikePopup, setLikePopup] = useState(false);
  const [dislikePopup, setDisLikePopup] = useState(false);

  useEffect(() => {
    dispatch(jobsActions.getViewLiteProfileFromAllUpdate(jobRecommendedId));
  }, [jobRecommendedId, dispatch]);

  const ClosehandleLiteProfileView = () => {
    switch (headerCardValue) {
      case "All":
        dispatch(jobsActions.getAllUpdatesData(jobId, "All"));
        break;
      case "1":
        dispatch(jobsActions.getAllUpdatesData(jobId, "Recommended"));
        break;
      case "2":
        dispatch(jobsActions.getAllUpdatesData(jobId, "Matched"));
        break;
      case "3":
        dispatch(jobsActions.getAllUpdatesData(jobId, "Conversation"));
        break;
      default:
        break;
    }
    handleOtherLiteProfileViewCLose();
  };

  // Like candidate functions
  const onHandleLike = () => {
    setLikePopup(true);
  };

  const onCLoseLike = () => {
    setLikePopup(false);
  };

  // DisLike candidate functions
  const handleOpenClosePopup = () => {
    setDisLikePopup(true);
  };

  const handleCloseLikePopup = () => {
    setDisLikePopup(false);
  };

  return (
    <>
      <Dialog maxWidth="lg" fullWidth open={open}>
        <DialogContent style={{ padding: 0 }}>
          <Grid container>
            <Grid
              item
              xs={8}
              sm={8}
              md={8}
              lg={8}
              xl={8}
              className={classes.profileBorderColor}
            >
              <LiteProfile
                liteProfileData={liteProfileData}
                ClosehandleLiteProfileView={ClosehandleLiteProfileView}
              />
            </Grid>
            <Grid item xs={4} sm={4} md={4} lg={4} xl={4}>
              <IconButton
                aria-label="close"
                className={classes.closeButton}
                onClick={ClosehandleLiteProfileView}
              >
                <CloseIcon />
              </IconButton>
              <div className={classes.actionsMainDiv}>
                {candidateStatus !== "Pending with Candidate" &&
                  candidateStatus !== "Removed from Queue" &&
                  accessPermission &&
                  jobStatus !== "Close" && (
                    <>
                      <Typography align="left">
                        Interested in this Candidate?
                      </Typography>
                      <div className={classes.buttonsDiv}>
                        <Button
                          className={classes.customMinWidth}
                          variant="contained"
                          color="primary"
                          disableElevation
                          startIcon={<Close />}
                          onClick={handleOpenClosePopup}
                        >
                          Not Interested
                        </Button>
                        <Button
                          className={classes.customMinWidth}
                          variant="contained"
                          color="primary"
                          disableElevation
                          startIcon={<Check />}
                          onClick={onHandleLike}
                        >
                          Interested
                        </Button>
                      </div>
                      <Divider className={classes.marginTop20} />
                    </>
                  )}
                <AdminNotes
                  notesValue={liteProfileData}
                  isFullProfile={false}
                />
                <Comment jobRecommendedId={jobRecommendedId} />
              </div>
            </Grid>
          </Grid>
        </DialogContent>
      </Dialog>
      <LikeComponent
        isLiteProfile
        handleOtherLiteProfileViewCLose={handleOtherLiteProfileViewCLose}
        onCLoseLike={onCLoseLike}
        open={openLikePopup}
        jobId={jobId}
        recommendationId={jobRecommendedId}
      />
      <RemoveFromQueue
        handleOtherLiteProfileViewCLose={handleOtherLiteProfileViewCLose}
        close={handleCloseLikePopup}
        open={dislikePopup}
        jobId={jobId}
        recommendationId={jobRecommendedId}
        redirectfrom={REDIRECT_TO_COMPONENT.LITE_PROFILE}
      />
    </>
  );
}
export default ViewLiteProfilePopUp;
