import { Divider, Typography } from "@material-ui/core";
import React, { useEffect } from "react";
import "./componentstyle.css";
import { makeStyles } from "@material-ui/core/styles";
import { useDispatch, useSelector } from "react-redux";
import { ReactComponent as InfoIcon } from "../../assets/info.svg";
import { ReactComponent as InfoIconHired } from "../../assets/info_hired.svg";
import { setAllUpdateHeaderCard } from "../../actions/jobs.actions";
import CustomTooltip from "../core/CustomTooltip";
import { history } from "../../helpers";

const useStyles = makeStyles((theme) => ({
  mainHeaderDiv: {
    display: "grid",
    width: "100%",
    gap: "2%",
    marginTop: "15px",
    gridTemplateColumns: "auto auto auto auto",
    overflowX: "unset",
    zIndex: 1,
    padding: "10px",
    [theme.breakpoints.down("md")]: {
      gap: "7% !important",
      overflowX: "scroll",
      width: "100%",
    },
  },
  divMainSpan: {
    display: "flex",
    paddingLeft: "8px",
    paddingTop: "4px",
    alignItems: "center",
  },
  titleTypo: {
    fontSize: "14px",
    color: "#666666",
    marginLeft: "5px",
    fontWeight: 700,
  },
  titleTypoHired: {
    fontSize: "14px",
    marginLeft: "5px",
    fontWeight: 700,
    color: "#FFFFFF",
  },
  countTypo: {
    fontSize: "28px",
    paddingLeft: "36px",
    fontWeight: 700,
    lineHeight: 1.8,
  },
  countTypo1: {
    fontSize: "28px",
    paddingLeft: "28px",
    fontWeight: 700,
    lineHeight: 1.8,
  },
  lastSubtitle: {
    fontSize: "22px",
    paddingLeft: "38px",
    fontWeight: 700,
    marginTop: "10px",
  },
  lastSubtitleHired: {
    fontSize: "22px",
    paddingLeft: "38px",
    fontWeight: 700,
    marginTop: "10px",
    color: "#FFFFFF",
  },
  clickLink: {
    paddingLeft: "20px",
    fontSize: "12px",
  },
  clickLink1: {
    paddingLeft: "8px",
    fontSize: "12px",
  },
  hiredLastTabLink: {
    paddingLeft: "28px",
    fontSize: "12px",
    paddingTop: "12px",
  },
  divMainSpan1: {
    display: "flex",
    paddingLeft: "17px",
    paddingTop: "4px",
    alignItems: "center",
  },
  chipStyle: {
    background: "#048475",
    color: "#FFFFFF",
    padding: "1px 6px",
    zIndex: 2,
    position: "absolute",
    top: -10,
    right: 10,
    borderRadius: "5px",
    fontSize: "12px",
  },
  TabUnderline: {
    height: "4px",
    marginTop: "10px",
    background: "#048475",
  },
}));

function HeaderTabs() {
  const classes = useStyles();
  const dispatch = useDispatch();
  const params = new URLSearchParams(window.location.search);
  const Tab = params.get("Tab");
  const headerCardValue = useSelector(
    (state) => state?.jobs?.allUpdateHeaderCard
  );
  const allUpdateData = useSelector((state) => state?.jobs?.allUpdateData);
  const view = useSelector((state) =>
    state?.jobs?.view ? state?.jobs.view : "List"
  );
  const onChangeHeaderTab = (cardValue) => {
    dispatch(setAllUpdateHeaderCard(cardValue));
  };

  useEffect(() => {
    setUrl(headerCardValue);
  }, [view, headerCardValue]);

  const setUrl = () => {
    history.push({
      pathname: window.location.pathname,
      search: `?View=${view || "List"}&&Tab=${
        headerCardValue === "All"
          ? "All_candidate"
          : headerCardValue === "1"
          ? "Talent_pipeline"
          : headerCardValue === "2"
          ? "Shortlisted_candidates"
          : headerCardValue === "3"
          ? "Inconversation_candidates"
          : "Remove_from_queue"
      }`,
    });
  };

  useEffect(() => {
    handleBrowserBackButton();
  }, [Tab]);

  const handleBrowserBackButton = () => {
    if (Tab) {
      switch (Tab) {
        case "All_candidate":
          dispatch(setAllUpdateHeaderCard("All"));
          break;
        case "Talent_pipeline":
          dispatch(setAllUpdateHeaderCard("1"));
          break;
        case "Shortlisted_candidates":
          dispatch(setAllUpdateHeaderCard("2"));
          break;
        case "Inconversation_candidates":
          dispatch(setAllUpdateHeaderCard("3"));
          break;
        case "Remove_from_queue":
          dispatch(setAllUpdateHeaderCard("4"));
          break;
        default:
          break;
      }
    } else {
      history.push({
        pathname: window.location.pathname,
        search: `?View=${view || "List"}&&Tab=${
          headerCardValue === "All"
            ? "All_candidate"
            : headerCardValue === "1"
            ? "Talent_pipeline"
            : headerCardValue === "2"
            ? "Shortlisted_candidates"
            : headerCardValue === "3"
            ? "Inconversation_candidates"
            : "Remove_from_queue"
        }`,
      });
    }
  };

  return (
    <div className={classes.mainHeaderDiv}>
      <div>
        <div onClick={() => onChangeHeaderTab("1", "first")} id="first">
          <span className={classes.divMainSpan}>
            <CustomTooltip
              title="Talent Pipeline"
              content="Relevant candidate profiles for your initial review."
              tooltipOn={<InfoIcon />}
            />
            <Typography className={classes.titleTypo}>
              Talent Pipeline
            </Typography>
          </span>
          <Typography align="left" className={classes.countTypo1}>
            {allUpdateData?.recommended?.total_recommendation_count}
          </Typography>
          <Typography align="left" className={classes.clickLink1}>
            {"Review & Like Anonymous Profiles >"}
          </Typography>
          {allUpdateData?.recommended?.new_recommended_count > 0 && (
            <span className={classes.chipStyle}>
              {`${allUpdateData?.recommended?.new_recommended_count} New`}
            </span>
          )}
          {headerCardValue === "1" && (
            <Divider className={classes.TabUnderline} />
          )}
        </div>
      </div>
      <div>
        <div onClick={() => onChangeHeaderTab("2")} id="second">
          <span className={classes.divMainSpan1}>
            <CustomTooltip
              title="Shortlisted Candidates"
              content="The most qualified candidates selected by your team."
              tooltipOn={<InfoIcon />}
            />
            <Typography className={classes.titleTypo}>
              Shortlisted Candidates
            </Typography>
          </span>
          <Typography align="left" className={classes.countTypo}>
            {
              allUpdateData?.shortlisted_candidates
                ?.total_shortlisted_candidates
            }
          </Typography>
          <Typography align="left" className={classes.clickLink}>
            {"Review Full Profiles >"}{" "}
          </Typography>
          {allUpdateData?.shortlisted_candidates?.new_matched > 0 && (
            <span className={classes.chipStyle}>
              {`${allUpdateData?.shortlisted_candidates?.new_matched} New`}
            </span>
          )}
          {headerCardValue === "2" && (
            <Divider className={classes.TabUnderline} />
          )}
        </div>
      </div>
      <div>
        <div onClick={() => onChangeHeaderTab("3")} id="third">
          <span className={classes.divMainSpan1}>
            <CustomTooltip
              title="Candidates in Conversation"
              content="Candidates asked to participate in the interview process."
              tooltipOn={<InfoIcon />}
            />
            <Typography className={classes.titleTypo}>
              Candidates in Conversation
            </Typography>
          </span>
          <Typography align="left" className={classes.countTypo}>
            {
              allUpdateData?.in_conversation_candidates
                ?.total_in_conversation_candidates
            }
          </Typography>
          <Typography align="left" className={classes.clickLink}>
            {"Request Interviews / Send Messages >"}{" "}
          </Typography>
          {headerCardValue === "3" && (
            <Divider className={classes.TabUnderline} />
          )}
        </div>
      </div>
      <div id={allUpdateData?.hired ? "last_green" : "last"}>
        <span className={classes.divMainSpan1}>
          <CustomTooltip
            title="Search Status"
            content="The status of the candidate search process."
            tooltipOn={!allUpdateData?.hired ? <InfoIcon /> : <InfoIconHired />}
          />
          <Typography
            className={
              !allUpdateData?.hired ? classes.titleTypo : classes.titleTypoHired
            }
          >
            Search Status
          </Typography>
        </span>
        <Typography
          align="left"
          className={
            !allUpdateData?.hired
              ? classes.lastSubtitle
              : classes.lastSubtitleHired
          }
        >
          {allUpdateData?.hired ? "Hired" : "Search in progress"}
        </Typography>
      </div>
    </div>
  );
}

export default HeaderTabs;
