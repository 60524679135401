import React, { Component } from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import {
  Grid,
  Typography,
  Tab,
  Tabs,
  Divider,
  TextField,
  Link,
  Button,
  Avatar,
  Chip,
  TableContainer,
  Table,
  TableBody,
  TableRow,
  TableCell,
  TableHead,
  IconButton,
  Dialog,
  FormControlLabel,
  Tooltip,
  InputAdornment,
} from "@material-ui/core";
import cardHeaderBg from "../../../assets/lite_profile_bg.png";
import MuiDialogTitle from "@material-ui/core/DialogTitle";
import MuiDialogContent from "@material-ui/core/DialogContent";
import MuiDialogActions from "@material-ui/core/DialogActions";
import RemoveRedEyeIcon from "@material-ui/icons/RemoveRedEye";
import VisibilityOffIcon from "@material-ui/icons/VisibilityOff";
// import { EditPayment } from '../payment/editPayment.component';
import PanoramaIcon from "@material-ui/icons/Panorama";
// import AddIcon from '@material-ui/icons/Add';
import AddBoxIcon from "@material-ui/icons/AddBox";
import IndeterminateCheckBoxIcon from "@material-ui/icons/IndeterminateCheckBox";
import CloseIcon from "@material-ui/icons/Close";
import validator from "validator";
import classNames from "classnames";
import { grey } from "@material-ui/core/colors";
import { Auth } from "aws-amplify";
import { decryptData, history } from "../../../helpers";
import { jobsActions, loading } from "../../../actions";
import { myAccountActions, authActions } from "../../../actions";
import { sharedActions } from "../../../actions";
// import config from '../../../config/config';
import GetHelp from "../../core/gethelp";
import { toast } from "react-toastify";
// import moment from 'moment';
// import CardContent from '@material-ui/core/CardContent';
import { MESSAGE_FIELD_IS_REQUIRED } from "./../../../constants/constants";
import FormGroup from "@material-ui/core/FormGroup";
// import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from "@material-ui/core/Switch";
import { notAllowWhiteSpaceAtStart, trim } from "../../../helpers";
import { seo } from "../../../helpers";
import { setTitle } from "../../../actions";
import secureLocalStorage from "react-secure-storage";
import moment from "moment";
import ChangePassword from "../../customComponents/changePassword";
import { master_key } from "../../../config/config";
import PhoneNumber from "../../core/phoneNumber";

const styles = (theme) => ({
  root: {
    width: "100%",
    marginTop: "50px",
  },
  table: {
    minWidth: 700,
  },
  paper: {
    border: "1px solid",
    padding: theme.spacing(1),
    backgroundColor: theme.palette.background.paper,
  },
  closeButton: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.default.main,
    padding: "0px",
  },
  appFrame: {
    zIndex: 1,
    overflow: "hidden",
    position: "relative",
    display: "flex",
    alignItems: "center",
    // width: '100%',
    padding: theme.spacing(7, 6),
    [theme.breakpoints.down("md")]: {
      padding: theme.spacing(2, 2),
    },
    [theme.breakpoints.down("sm")]: {
      padding: theme.spacing(2, 2),
    },

    // backgroundColor: '#F7F7F7',
    // paddingBottom: '1.5%',
  },
  content: {
    flexGrow: 1,
    marginTop: "0.2%",
    padding: theme.spacing(0, 6),
    [theme.breakpoints.down("md")]: {
      padding: theme.spacing(0, 2),
    },
    [theme.breakpoints.down("sm")]: {
      padding: theme.spacing(0, 2),
    },
  },
  bullet: {
    display: "inline-block",
    margin: "0 2px",
    transform: "scale(0.8)",
  },
  title: {
    fontSize: 14,
  },
  pos: {
    marginBottom: 12,
  },
  largeAvatar: {
    width: theme.spacing(7),
    height: theme.spacing(7),
  },
  xLargeAvatar: {
    width: theme.spacing(10),
    height: theme.spacing(10),
    backgroundColor: "#000",
  },
  moduleConfig: {
    float: "left",
    fontSize: "26px",
  },
  demo1: {
    marginTop: "5%",
  },
  floatRight: {
    float: "right",
  },
  floatLeft: {
    float: "left",
  },
  largeTopMargin: {
    marginTop: "10%",
  },
  verySmallTopMargin: {
    marginTop: "1%",
  },
  verySmallBottomMargin: {
    marginTop: "1%",
  },
  formControl: {
    margin: theme.spacing(0, 0),
    minWidth: "100%",
  },
  mediumTopMargin: {
    marginTop: "5%",
  },
  displayFlex: {
    display: "flex",
  },
  noPadding: {
    padding: "0 !important",
  },
  fullWidth: {
    width: "100%",
  },
  colorDefault: {
    color: "#000000 !important",
  },
  colorGrey: {
    color: "#6D7781",
  },
  colorCustom: {
    color: "#303030",
  },
  themeColor: {
    color: "#FBC112",
  },
  dialogTitle: {
    color: "#FBC112",
  },
  displayInline: {
    display: "inline",
  },
  noBottomMargin: {
    marginBottom: "0",
  },
  smallTopMargin: {
    marginTop: "3%",
  },
  customSmallTopMargin: {
    marginTop: "2.5%",
  },
  chip: {
    margin: theme.spacing(0.5),
  },
  input: {
    marginLeft: theme.spacing(1),
    flex: 1,
  },
  iconButton: {
    padding: 10,
  },
  noWrap: {
    whiteSpace: "nowrap",
  },
  subHeader: {
    fontSize: "large",
    marginTop: "4%",
    fontWeight: "600",
    color: "#000000",
  },
  header: {
    fontSize: "x-large",
    marginTop: "4%",
    fontWeight: "600",
    color: "#000000",
  },
  jobCardHeader: {
    fontSize: "x-large",
    fontWeight: "500",
    color: "#000000",
    textAlign: "left",
  },
  jobCardSubHeader: {
    fontSize: "large",
    fontWeight: "500",
    textAlign: "left",
  },
  customCard: {
    backgroundColor: theme.palette.basic.main,
  },
  customCardHeader: {
    maxHeight: "38%",
    height: "38%",
    color: "#000",
    backgroundSize: "cover",
    backgroundImage: `url(${cardHeaderBg})`,
    background: "round",
  }, //height: '180px'
  customCardShadow: {
    boxShadow: "none",
  },
  textAlignEnd: {
    textAlign: "end",
  },
  textAlignCenter: {
    textAlign: "center",
  },
  jobCard: {
    boxShadow:
      "0px 1px 1px 0px rgb(0 0 0 / 20%), 0px 1px 0px 0px rgb(0 0 0 / 14%), 0px 0px 2px 1px rgb(0 0 0 / 12%)",
    cursor: "pointer",
  },
  smallBottomMargin: {
    marginBottom: "2%",
  },
  mediumBottomMargin: {
    marginBottom: "5% !important",
  },
  square: {
    color: theme.palette.getContrastText(grey[300]),
    backgroundColor: grey[300],
    border: "2px solid #FBC112",
    width: "7em",
    height: "4em",
  },
  smallFont: {
    fontSize: "14px",
  },

  largeFont: {
    fontSize: "x-large",
  },

  customBadgeGrid: {
    textAlign: "center",
    overflowWrap: "break-word",
    padding: "2%",
  },
  customLink: {
    cursor: "pointer",
    "&:hover": {
      textDecoration: "underline",
    },
    color: "#048475 !important",
  },
  alignSelfCenter: { alignSelf: "center !important" },
  breadcrumbLink: {
    cursor: "pointer",
    "&:hover": {
      textDecoration: "underline",
    },
    // color: '#048475 !important'
  },
  moreIcon: {
    justifyContent: "space-between",
    cursor: "pointer",
    overflowWrap: "anywhere",
  },
  customMoreIcon: {
    fontSize: "2.5rem",
    // '&:hover':{
    //     transform: 'rotate(90deg)',
    //     transition: 'all .3s ease'
    // }
  },
  customDialog: {
    // backgroundColor: '#000000',
    // width: 'unset',
  },
  justifyContentFlexEnd: {
    justifyContent: "flex-end",
  },
  justifyContentCenter: {
    justifyContent: "center !important",
  },
  responsive: {
    maxWidth: "100% !important",
    height: "auto !important",
    width: "auto !important",
    // alignSelf: 'center !important',
    minWidth: "fit-content !important",
  },
  liteProfileCard: {
    // boxShadow: '0px 1px 1px 0px rgb(0 0 0 / 0%), 0px 1px 0px 0px rgb(0 0 0 / 5%), 0px 0px 2px 1px rgb(0 0 0 / 12%)',
    borderBottom: "10px solid #FBC112",
    maxHeight: "47em",
    height: "47em",
    // borderRadius: '8px'
    borderRadius: "0px",
    // backgroundColor: '#F7F7F7',
    // baclgroundImage: 'url(src/assets/lite_profile_bg.png)'
    // cursor: 'pointer'
  },
  maxHeight40P: { maxHeight: "40px" },
  customCardActions: { display: "block", marginTop: "1%" },
  textAlignLeft: { textAlign: "left" },
  textAlignRight: { textAlign: "right" },
  verySmallFont: { fontSize: ".8rem" },
  font10p: { fontSize: "10px" },
  font14p: { fontSize: "12px" },
  alignBaslineMiddle: { verticalAlign: "-webkit-baseline-middle" },
  verySmallMarginLeft: { marginLeft: "3px" },
  smallMarginLeft: { marginLeft: "8px" },
  mediumMarginLeft: { marginLeft: "12px" },
  boldFont: { fontWeight: "bold" },
  noBoxShadow: { boxShadow: "none" },
  noBorderBottom: { borderBottom: "none" },
  viewMore: { color: "#302F2C", cursor: "pointer", fontWeight: "bold" },
  alignWebkitCenter: { textAlign: "-webkit-center" },
  justifyContentSpaceEvenly: { justifyContent: "space-evenly" },
  justifyContentSpaceBetween: { justifyContent: "space-between" },
  // bgChangeOnHover: {'&:hover':{backgroundColor: '#FBC112'}},
  verySmallMarginRight: { marginRight: "2%" },
  // customChip: { width: '100%', marginTop: '3%', borderRadius: '10px', backgroundColor: '#616161' },
  cursorPointer: {
    cursor: "pointer",
  },
  customHighlightsPaper: {
    paddingLeft: "3%",
    paddingRight: "3%",
    paddingBottom: "0",
    boxShadow: "none",
    borderRadius: "10px",
    border: "2px solid #e0e0e0",
    // height: '100%',
    // maxHeight: '100%',
    overflowY: "auto",
  },
  smallLeftPadding: { paddingLeft: "8px" },
  equalButtons: { width: "150px", margin: "3%" },
  // equalButtons: {width: '45vw', margin: '3%'},
  fixedTitleTableHeight: { height: "130px !important", marginTop: "10%" },
  fixedTableHeight: { height: "115px !important" },
  fixedTitleHeight: { height: "80px !important" },
  defaultButton: {
    backgroundColor: "#FFFFFF",
    border: "#000000 solid 1px",
    marginRight: "1%",
  },
  halfWidth: { width: "100%" },
  customBadge: {
    borderRadius: "3px",
    fontSize: "medium",
    backgroundColor: "#616161",
    color: "#FFFFFF",
    height: "auto",
  },
  customAddSeatSection: {
    boxShadow:
      "0px 1px 1px 0px rgb(0 0 0 / 0%), 0px 1px 5px 0px rgb(0 0 0 / 20%), 0px 0px 1px 1px rgb(0 0 0 / 12%)",
    borderRadius: "10px",
    padding: "3%",
  },
  customButton: {
    width: "16em",
  },
  eyeIconStyle: {
    cursor: "pointer",
  },
  inputStyle: {
    width: "50%",
  },
});

const AntTabs = withStyles(() => ({
  root: {
    borderBottom: "1px solid #e8e8e8",
  },
  indicator: {
    backgroundColor: "#FBC112",
  },
}))(Tabs);

const AntTab = withStyles((theme) => ({
  root: {
    textTransform: "none",
    minWidth: 72,
    fontWeight: theme.typography.fontWeightRegular,
    marginRight: theme.spacing(4),
    "&:hover": {
      color: theme.palette.secondary,
      opacity: 1,
    },
    "&$selected": {
      color: theme.palette.secondary,
      fontWeight: theme.typography.fontWeightMedium,
    },
    "&:focus": {
      color: theme.palette.secondary,
    },
  },
  // selected: {
  //     borderBottom: '3px solid #FBC112',
  //     borderRadius: '0px'
  // },
}))((props) => <Tab disableRipple {...props} />);

const DialogTitle = withStyles(styles)((props) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle
      disableTypography
      {...other}
      className={classes.customCardHeader}
      style={{ padding: "3%" }}
    >
      {/* className={classes.root} */}
      <Typography variant="h5" align="center">
        {children}
      </Typography>
      {onClose ? (
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={onClose}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const DialogContent = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
    paddingLeft: theme.spacing(4),
    paddingRight: theme.spacing(4),
  },
}))(MuiDialogContent);

const DialogActions = withStyles((theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
    backgroundColor: "#fff",
    justifyContent: "flex-end !important",
  },
}))(MuiDialogActions);

const CustomSwitch = withStyles((theme) => ({
  root: {
    width: 55,
    height: 26,
    padding: 0,
    margin: theme.spacing(1),
  },
  switchBase: {
    padding: 1,
    "&$checked": {
      transform: "translateX(29px)",
      color: theme.palette.common.white,
      "& + $track": {
        backgroundColor: "#048475",
        opacity: 1,
        border: "none",
      },
    },
    "&$focusVisible $thumb": {
      color: "#048475",
      border: "6px solid #fff",
    },
  },
  thumb: {
    width: 24,
    height: 24,
  },
  track: {
    borderRadius: 26 / 2,
    border: `1px solid ${theme.palette.grey[400]}`,
    backgroundColor: theme.palette.grey[300],
    opacity: 1,
    transition: theme.transitions.create(["background-color", "border"]),
  },
  checked: {},
  focusVisible: {},
}))(({ classes, ...props }) => {
  return (
    <Switch
      focusVisibleClassName={classes.focusVisible}
      disableRipple
      classes={{
        root: classes.root,
        switchBase: classes.switchBase,
        thumb: classes.thumb,
        track: classes.track,
        checked: classes.checked,
      }}
      {...props}
    />
  );
});

const StyledTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: "#616161",
    color: theme.palette.common.white,
  },
}))(TableCell);

const StyledTableRow = withStyles((theme) => ({
  root: {
    "&:nth-of-type(odd)": {
      backgroundColor: theme.palette.action.hover,
    },
  },
}))(TableRow);

let charCount = 50;
let currentTitleCharCount = 100;

class MyAccount extends Component {
  constructor(props) {
    super(props);
    this.state = this.getInitialState();
  }

  getInitialState = () => ({
    value: JSON.parse(secureLocalStorage.getItem("accountTab")) || 0,
    open: false,
    editPayment: false,
    seatFirstName: "",
    seatLastName: "",
    seatEmail: "",
    seatRole: "",
    fields: [
      {
        fNameValue: "",
        lNameValue: "",
        emailValue: "",
        roleValue: "",
        isValidEmail: true,
        isValidFirstName: true,
        isValidLastName: true,
        isValidRole: true,
      },
    ],
    link: "",
    oldLink: "",
    isValidLink: false,
    isRequiredLink: false,
    showDiversityFlag: false,
    oldDiversityFlag: false,
    isPersonalInfoEditable: false,
    isValidFirstName: true,
    isValidLastName: true,
    isValidCurrentTitle: true,
    isValidPhone: true,
    isFirstNameRequired: false,
    isLastNameRequired: false,
    isCurrentTitleRequired: false,
    isPhoneRequired: false,
    passwordVisible: false,
    password: null,
    changePasswordPopup: false,
  });

  componentDidMount() {
    const { dispatch } = this.props;
    const { recruiterStatusDetails } = this.props?.authentication ?? this.props;
    this.getPassword();
    dispatch(
      setTitle(
        `My Account - ${
          this.state.value === 0
            ? `Profile - ${recruiterStatusDetails.first_name} ${recruiterStatusDetails.last_name}`
            : `Plan - ${recruiterStatusDetails.first_name} ${recruiterStatusDetails.last_name}`
        }`
      )
    );

    // ----------- This was used earlier when stripe thing existed ----------
    // dispatch(myAccountActions.getAllBillingData(JSON.parse(secureLocalStorage.getItem('recruiterStatusDetails')).company.id));
    if (recruiterStatusDetails?.is_owner) {
      dispatch(myAccountActions.getSeats());
    }
    let payload = {
      email: recruiterStatusDetails.email,
    };
    dispatch(authActions.getRecruiterOnboardingStatus(payload));
    this.setState({
      link: this.props.shared.calendlyLink,
      oldLink: this.props.shared.calendlyLink,
    });
    this.setState({
      showDiversityFlag:
        this.props.authentication.recruiterStatusDetails.show_diversity,
      oldDiversityFlag:
        this.props.authentication.recruiterStatusDetails.show_diversity,
    });
    document.body.scrollTop = 0; // For Safari
    document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE and Opera
    this.setData(recruiterStatusDetails);
  }

  componentDidUpdate(prevProps) {
    const { recruiterStatusDetails } = this.props?.authentication ?? this.props;
    if (prevProps.payment !== this.props.payment) {
      this.setState({ editPayment: this.props.payment.editPayment });
    }

    if (prevProps.shared.calendlyLink !== this.props.shared.calendlyLink) {
      this.setState({
        link: this.props.shared.calendlyLink,
        oldLink: this.props.shared.calendlyLink,
      });
    }

    if (
      prevProps.authentication.recruiterStatusDetails.show_diversity !==
      this.props.authentication.recruiterStatusDetails.show_diversity
    ) {
      this.setState({
        showDiversityFlag:
          this.props.authentication.recruiterStatusDetails.show_diversity,
        oldDiversityFlag:
          this.props.authentication.recruiterStatusDetails.show_diversity,
      });
    }
    if (
      prevProps.authentication.recruiterStatusDetails !== recruiterStatusDetails
    ) {
      this.setData(recruiterStatusDetails);
    }
    const { pageTitle } = this.props.pageTitle;
    seo({
      title: `${pageTitle}`,
    });
  }

  componentWillUnmount() {
    secureLocalStorage.removeItem("accountTab");
  }

  getPassword = async () => {
    let password = await jobsActions.getCompanyPassword(
      this.props.authentication.recruiterStatusDetails?.company
        ?.recruiter_company_id
    );
    let decryptedPassword = await decryptData(
      master_key,
      password?.data?.data?.password
    );
    this.setState({ password: decryptedPassword });
  };
  capitalize = function (str) {
    if (str !== null && str !== undefined) {
      return str
        .split(" ")
        .map((w) => w.substring(0, 1).toUpperCase() + w.substring(1))
        .join(" ");
    } else return str;
  };

  contactCustomerSupport = () => {
    const { dispatch } = this.props;
    dispatch(authActions.getHelp(true));
  };

  handleTabChange = (event, newValue) => {
    event.preventDefault();
    event.stopPropagation();
    const { dispatch } = this.props;
    const { recruiterStatusDetails } = this.props.authentication;
    this.setState({ value: newValue }, () => {
      secureLocalStorage.setItem("accountTab", newValue);
    });
    switch (newValue) {
      case 0:
        dispatch(
          setTitle(
            `${`My Account`} ${`-`} ${`Profile`} ${`-`} ${
              recruiterStatusDetails.first_name
            } ${recruiterStatusDetails.last_name}`
          )
        );
        break;
      case 1:
        dispatch(
          setTitle(
            `${`My Account`} ${`-`} ${`Plan`} ${`-`} ${
              recruiterStatusDetails.first_name
            } ${recruiterStatusDetails.last_name}`
          )
        );
        break;
      default:
        break;
    }
  };

  handleChangePasswordClick = (event) => {
    this.sendVerificationCode(event);
  };

  sendVerificationCode = async (event) => {
    const { email } = this.state;
    const { dispatch } = this.props;
    dispatch(loading(true));
    event.preventDefault();
    event.stopPropagation();
    if (email) {
      try {
        const forgotPasswordResponse = await Auth.forgotPassword(email);
        console.log(forgotPasswordResponse);
        toast.dark(
          "You will soon receive a verification code on your resgistered email to change your password"
        );
        history.push("/change-password");
        dispatch(loading(false));
      } catch (error) {
        let err = null;
        !error.message ? (err = { message: error }) : (err = error);
        console.log("error in password change:", err);
        this.setState({
          errors: {
            ...this.state.errors,
            cognito: error,
          },
        });
        dispatch(loading(false));
        toast.dark(error.message);
      }
    }
    // else {
    //     dispatch(loading(false));
    //     toast.dark('Please enter Corporate Email Address');
    // }
  };

  handleEditPayment = () => {
    this.setState({ editPayment: true });
  };

  handleCloseEditPayment = () => {
    this.setState({ editPayment: false });
  };

  handleAddSeat = () => {
    this.setState(
      {
        fields: [
          {
            fNameValue: "",
            lNameValue: "",
            emailValue: "",
            roleValue: "",
            isValidEmail: true,
            isValidFirstName: true,
            isValidLastName: true,
            isValidRole: true,
          },
        ],
      },
      () => {
        this.setState({ open: true });
      }
    );
  };

  handleChange = (i, event) => {
    const values = [...this.state.fields];
    const inputValue = notAllowWhiteSpaceAtStart(event.target.value);
    let domain = "";
    let ownerDomain = "";
    switch (event.currentTarget.name) {
      case "seatFirstName":
        values[i].fNameValue = inputValue;
        break;
      case "seatLastName":
        values[i].lNameValue = inputValue;
        break;
      case "seatEmail":
        domain = inputValue
          .toLowerCase()
          .substring(inputValue.lastIndexOf("@") + 1);
        ownerDomain = JSON.parse(secureLocalStorage.getItem("userDetails"))
          .email.toLowerCase()
          .substring(
            JSON.parse(
              secureLocalStorage.getItem("userDetails")
            ).email.lastIndexOf("@") + 1
          );
        values[i].emailValue = inputValue;
        values[i].isValidEmail =
          validator.isEmail(inputValue) && ownerDomain === domain;
        if (
          inputValue.includes("@yahoo.com") ||
          inputValue.includes("@gmail.com") ||
          inputValue.includes("@live.com") ||
          inputValue.includes("@rediffmail.com") ||
          inputValue.includes("@hotmail.com") ||
          inputValue.includes("@ymail.com") ||
          inputValue.includes("@outlook.com") ||
          inputValue.includes("@yopmail.com")
        ) {
          values[i].isValidEmail = false;
        }
        break;
      case "seatRole":
        values[i].roleValue = inputValue;
        // values[i].isValidRole = validator.matches(event.target.value, /^.{2,100}$/);
        break;
      default:
        break;
    }
    this.setState({ fields: values });
  };

  handleAdd = () => {
    const values = [...this.state.fields];
    values.push({
      fNameValue: "",
      lNameValue: "",
      emailValue: "",
      roleValue: "",
      isValidEmail: true,
      isValidFirstName: true,
      isValidLastName: true,
      isValidRole: true,
    });
    this.setState({ fields: values });
  };

  handleRemove(i) {
    const values = [...this.state.fields];
    values.splice(i, 1);
    this.setState({ fields: values });
  }

  handleConfirmSeat = (event) => {
    event.preventDefault();
    event.stopPropagation();
    let checkArray = [];
    for (let item in this.state.fields) {
      if (
        this.state.fields[item].fNameValue === "" ||
        this.state.fields[item].fNameValue === null ||
        this.state.fields[item].lNameValue === "" ||
        this.state.fields[item].lNameValue === null ||
        this.state.fields[item].emailValue === "" ||
        this.state.fields[item].emailValue === null ||
        this.state.fields[item].roleValue === "" ||
        this.state.fields[item].roleValue === null ||
        !this.state.fields[item].isValidFirstName ||
        !this.state.fields[item].isValidLastName ||
        !this.state.fields[item].isValidEmail ||
        !this.state.fields[item].isValidRole
      ) {
        this.setState({ isValidFormData: false });
      } else {
        this.setState({ isValidFormData: true });
        let payloadObject = {
          first_name: trim(this.state.fields[item].fNameValue),
          last_name: trim(this.state.fields[item].lNameValue),
          email: trim(this.state.fields[item].emailValue),
          title: trim(this.state.fields[item].roleValue),
        };
        checkArray.push(payloadObject);
      }
    }
    if (this.state.fields.length === checkArray.length) {
      const { dispatch } = this.props;
      let payload = {
        billing_frequency: "Annual",
        seats: checkArray,
      };
      dispatch(
        myAccountActions.addSeats(
          payload,
          JSON.parse(secureLocalStorage.getItem("recruiterStatusDetails"))
            .recruiter_id
        )
      );
      this.setState({ open: false });
    } else {
      toast.dark("Please fill up all the details with correct information");
      return;
    }
  };

  handleClose = () => {
    this.setState({ open: false });
  };

  handleManageBilling = () => {
    const { dispatch } = this.props;
    dispatch(myAccountActions.redirectToStripePortal());
  };

  onLinkChange = (value) => {
    this.setState({
      link: value,
      isRequiredLink: value.length === 0 ? true : false,
      isValidLink:
        value.length !== 0
          ? !/^((ftp|http|https):\/\/)?(www.)?(?!.*(ftp|http|https|www.))[a-zA-Z0-9_-]+(\.[a-zA-Z]+)+((\/)[\w#-=?!@">`~]+)*(\/\w+\?[a-zA-Z0-9_]+=\w+(&[a-zA-Z0-9_]+=\w+))?$/gs.test(
              value
            )
          : false,
    });
  };

  submitLink = () => {
    const { link, isRequiredLink, isValidLink, oldLink } = this.state;
    this.setState({
      isRequiredLink: link.length === 0 ? true : false,
      isValidLink:
        link.length !== 0
          ? !/^((ftp|http|https):\/\/)?(www.)?(?!.*(ftp|http|https|www.))[a-zA-Z0-9_-]+(\.[a-zA-Z]+)+((\/)[\w#-=?!@">`~]+)*(\/\w+\?[a-zA-Z0-9_]+=\w+(&[a-zA-Z0-9_]+=\w+))?$/gs.test(
              link
            )
          : false,
    });

    if (!isValidLink && !isRequiredLink && link !== "") {
      if (oldLink !== link) {
        const { dispatch } = this.props;
        dispatch(
          sharedActions.addUpdateCalendlyLink(
            JSON.parse(secureLocalStorage.getItem("recruiterId")),
            link
          )
        );
      }
    } else {
      toast.dark("Please add a valid link.");
    }
  };

  handleDiversity = (event) => {
    this.setState({ showDiversityFlag: event.target.checked });
    const { dispatch } = this.props;
    dispatch(
      sharedActions.addUpdateDiversity(
        JSON.parse(secureLocalStorage.getItem("recruiterId")),
        event.target.checked,
        {
          ...this.props.authentication.recruiterStatusDetails,
          show_diversity: event.target.checked,
        }
      )
    );
  };

  handleDiversitySubmit = () => {
    const { dispatch } = this.props;
    dispatch(
      sharedActions.addUpdateDiversity(
        JSON.parse(secureLocalStorage.getItem("recruiterId")),
        this.state.showDiversityFlag,
        {
          ...this.props.authentication.recruiterStatusDetails,
          show_diversity: this.state.showDiversityFlag,
        }
      )
    );
  };

  handlePersonalInfoEditClick = () => {
    this.setState({ isPersonalInfoEditable: true }, () => {
      this.focusField();
      this.highlightEditableFields();
    });
  };

  handlePersonalInfoEditCancel = () => {
    const { recruiterStatusDetails } = this.props?.authentication ?? this.props;
    this.setState({ isPersonalInfoEditable: false }, () => {
      this.setData(recruiterStatusDetails);
      this.removeFieldsHighlight();
    });
  };

  setData = (recruiterStatusDetails) => {
    this.setState({
      firstName: recruiterStatusDetails.first_name,
      lastName: recruiterStatusDetails.last_name,
      email: recruiterStatusDetails.email,
      currentTitle: recruiterStatusDetails.title,
      phone: recruiterStatusDetails.contact,
    });
  };

  focusField = () => {
    let firstNameTextField = document.getElementById("firstName");
    if (firstNameTextField) {
      firstNameTextField.focus();
    }
  };

  highlightEditableFields = () => {
    let firstNameTextField = document.getElementById("firstName");
    if (firstNameTextField) {
      firstNameTextField.classList.add("fade-in-field");
    }
  };

  removeFieldsHighlight = () => {
    let firstNameTextField = document.getElementById("firstName");
    if (firstNameTextField) {
      firstNameTextField.classList.remove("fade-in-field");
    }
  };

  handleInputChange = (prop) => (event) => {
    const inputValue = notAllowWhiteSpaceAtStart(event.target.value);
    this.setState({ [prop]: inputValue }, () => {
      switch (prop) {
        case "firstName":
          this.setState({
            isValidFirstName: inputValue
              ? validator.matches(inputValue, /^.{2,50}$/)
              : true,
            isFirstNameRequired: validator.isEmpty(inputValue),
          });
          break;
        case "lastName":
          this.setState({
            isValidLastName: inputValue
              ? validator.matches(inputValue, /^.{2,50}$/)
              : true,
            isLastNameRequired: validator.isEmpty(inputValue),
          });
          break;
        case "currentTitle":
          this.setState({
            isValidCurrentTitle: inputValue
              ? validator.matches(inputValue, /^.{2,100}$/)
              : true,
            isCurrentTitleRequired: validator.isEmpty(inputValue),
          });
          break;
        default:
          return;
      }
    });
  };

  updateRecruiterDetails = async (event) => {
    event.preventDefault();
    const { dispatch } = this.props;
    let payload = {
      first_name: trim(this.state.firstName),
      last_name: trim(this.state.lastName),
      contact: trim(this.state.phone),
      title: trim(this.state.currentTitle),
    };
    authActions.updateRecruiter(payload).then((response) => {
      if (response && (response.status === 200 || response.status === 201)) {
        let payloadObj = {
          email: this.state.email,
        };
        dispatch(authActions.getRecruiterOnboardingStatus(payloadObj));
        this.handlePersonalInfoEditCancel();
      }
    });
  };

  handlePassword = (value) => {
    this.setState({ password: value });
  };

  handlePasswordVisible = () => {
    this.setState({ passwordVisible: !this.state.passwordVisible });
  };

  handleChangePassword = () => {
    this.setState({ changePasswordPopup: true });
  };
  onCloseChangePassword = () => {
    this.getPassword();
    this.setState({ changePasswordPopup: false });
  };

  onChangePhoneValue = (value, validate, required) => {
    this.setState({
      phone: value,
      isPhoneRequired: required,
      isValidPhone: validate,
    });
  };

  render() {
    const { classes } = this.props;
    const { recruiterStatusDetails } = this.props?.authentication ?? this.props;
    let companyData = JSON.parse(
      secureLocalStorage.getItem("recruiterStatusDetails")
    ).company;
    let billingData = this.props?.myAccount?.billingData;
    return (
      <div className={classes.root}>
        <div className={classes.appFrame}>
          <Typography
            className={classNames(classes.moduleConfig, classes.boldFont)}
          >
            {"Welcome to your account, " + recruiterStatusDetails.first_name}
          </Typography>
          {JSON.parse(secureLocalStorage.getItem("recruiterStatusDetails"))
            .is_owner ? (
            <Chip
              className={classNames(
                classes.customBadge,
                classes.smallMarginLeft
              )}
              label="COMPANY ADMIN"
            />
          ) : null}
        </div>
        <main className={classes.content}>
          <AntTabs
            value={this.state.value}
            onChange={this.handleTabChange}
            aria-label="ant example"
          >
            <AntTab label="Profile" />
            {JSON.parse(secureLocalStorage.getItem("recruiterStatusDetails"))
              .is_owner ? (
              <AntTab label="Plan" />
            ) : null}
          </AntTabs>
          {this.state.value === 0 ? (
            <>
              <Typography
                variant="h5"
                align="left"
                className={classNames(
                  classes.colorDefault,
                  classes.smallTopMargin,
                  classes.boldFont
                )}
              >
                My Details
              </Typography>
              <Typography
                variant="h6"
                align="left"
                className={classNames(
                  classes.colorDefault,
                  classes.smallTopMargin,
                  classes.boldFont
                )}
              >
                Personal Information
              </Typography>
              <Divider
                className={classNames(
                  classes.verySmallTopMargin,
                  classes.verySmallBottomMargin
                )}
              ></Divider>
              <Grid
                container
                spacing={6}
                className={classes.verySmallTopMargin}
              >
                <Grid item xs={12} sm={12} md={5} lg={3} xl={3}>
                  <Typography align="left" color="textSecondary">
                    Your information is saved securely on our platform. We will
                    not give your information to third party vendors.
                  </Typography>
                  {/* Please <Link className={classes.customLink} onClick={event=> {this.contactCustomerSupport(event)}}>client services </Link> to make changes to this information. */}
                </Grid>
                <Grid
                  className={classes.textAlignLeft}
                  item
                  xs={12}
                  sm={12}
                  md={7}
                  lg={5}
                  xl={5}
                >
                  <Typography className={classes.smallBottomMargin}>
                    <TextField
                      label="FIRST NAME"
                      id="firstName"
                      name="firstName"
                      placeholder="Enter first name"
                      className={classes.halfWidth}
                      autoComplete="firstName"
                      required
                      value={this.state.firstName}
                      inputProps={{
                        readOnly: !this.state.isPersonalInfoEditable
                          ? true
                          : false,
                        maxLength: charCount,
                      }}
                      onChange={this.handleInputChange("firstName")}
                      error={
                        !this.state.isValidFirstName ||
                        this.state.isFirstNameRequired
                      }
                      helperText={
                        this.state.isPersonalInfoEditable &&
                        (!this.state.isValidFirstName
                          ? "min 2 characters required."
                          : this.state.isFirstNameRequired
                          ? MESSAGE_FIELD_IS_REQUIRED
                          : "Max 50 characters are allowed.")
                      }
                    />
                  </Typography>
                  <Typography className={classes.smallBottomMargin}>
                    <TextField
                      label="LAST NAME"
                      id="lastName"
                      name="lastName"
                      placeholder="Enter last name"
                      fullWidth
                      className={classes.halfWidth}
                      autoComplete="lastName"
                      required
                      value={this.state.lastName}
                      inputProps={{
                        readOnly: !this.state.isPersonalInfoEditable
                          ? true
                          : false,
                        maxLength: charCount,
                      }}
                      onChange={this.handleInputChange("lastName")}
                      error={
                        !this.state.isValidLastName ||
                        this.state.isLastNameRequired
                      }
                      helperText={
                        this.state.isPersonalInfoEditable &&
                        (!this.state.isValidLastName
                          ? "min 2 characters required."
                          : this.state.isLastNameRequired
                          ? MESSAGE_FIELD_IS_REQUIRED
                          : "Max 50 characters are allowed.")
                      }
                    />
                  </Typography>
                  <Typography className={classes.smallBottomMargin}>
                    {!this.state.isPersonalInfoEditable ? (
                      <TextField
                        label="EMAIL ADDRESS"
                        id="email"
                        name="email"
                        fullWidth
                        className={classes.halfWidth}
                        value={this.state.email}
                        inputProps={{ readOnly: true }}
                      />
                    ) : (
                      <Tooltip arrow title="This field is non-editable">
                        <TextField
                          label="EMAIL ADDRESS"
                          id="email"
                          name="email"
                          fullWidth
                          className={classes.halfWidth}
                          value={this.state.email}
                          inputProps={{ readOnly: true }}
                          disabled={this.state.isPersonalInfoEditable}
                        />
                      </Tooltip>
                    )}
                  </Typography>
                  <Typography className={classes.smallBottomMargin}>
                    <TextField
                      label="CURRENT TITLE"
                      id="title"
                      name="title"
                      fullWidth
                      className={classes.halfWidth}
                      value={this.state.currentTitle}
                      inputProps={{
                        readOnly: !this.state.isPersonalInfoEditable
                          ? true
                          : false,
                        maxLength: currentTitleCharCount,
                      }}
                      onChange={this.handleInputChange("currentTitle")}
                      placeholder="Your current title"
                      error={
                        !this.state.isValidCurrentTitle ||
                        this.state.isCurrentTitleRequired
                      }
                      helperText={
                        this.state.isPersonalInfoEditable &&
                        (!this.state.isValidCurrentTitle
                          ? "min 2 characters required."
                          : this.state.isCurrentTitleRequired
                          ? MESSAGE_FIELD_IS_REQUIRED
                          : "Max 100 characters are allowed.")
                      }
                    />
                  </Typography>

                  <PhoneNumber
                    isPersonalInfoEditable={this.state.isPersonalInfoEditable}
                    value={this.state.phone}
                    onChangeValue={this.onChangePhoneValue}
                  />

                  {!secureLocalStorage.getItem("g_u") &&
                    !this.state.isPersonalInfoEditable && (
                      <Typography
                        component="div"
                        className={classes.mediumTopMargin}
                      >
                        <Button
                          disableElevation
                          size="large"
                          variant="contained"
                          color="primary"
                          onClick={(event) => {
                            event.preventDefault();
                            this.handlePersonalInfoEditClick();
                          }}
                        >
                          Edit Details
                        </Button>
                      </Typography>
                    )}
                  {this.state.isPersonalInfoEditable && (
                    <div className={classNames(classes.displayFlex)}>
                      <Typography
                        component="div"
                        className={classes.smallTopMargin}
                      >
                        <Button
                          disableElevation
                          size="large"
                          variant="contained"
                          color="primary"
                          onClick={(event) => {
                            event.preventDefault();
                            this.handlePersonalInfoEditCancel();
                          }}
                          style={{ background: "#D7D9DB", color: "#000" }}
                        >
                          Cancel
                        </Button>
                      </Typography>
                      &nbsp;&nbsp;&nbsp;&nbsp;
                      <Typography
                        component="div"
                        className={classes.smallTopMargin}
                      >
                        <Button
                          disableElevation
                          size="large"
                          variant="contained"
                          color="primary"
                          disabled={
                            !this.state.isValidFirstName ||
                            this.state.isFirstNameRequired ||
                            !this.state.isValidLastName ||
                            this.state.isLastNameRequired ||
                            !this.state.isValidCurrentTitle ||
                            this.state.isCurrentTitleRequired ||
                            !this.state.isValidPhone ||
                            this.state.isPhoneRequired
                          }
                          onClick={(event) => {
                            this.updateRecruiterDetails(event);
                          }}
                        >
                          Save
                        </Button>
                      </Typography>
                    </div>
                  )}
                </Grid>
              </Grid>
              <Typography
                variant="h6"
                align="left"
                className={classNames(
                  classes.colorDefault,
                  classes.smallTopMargin,
                  classes.boldFont
                )}
              >
                Calendly
              </Typography>
              <Divider
                className={classNames(
                  classes.verySmallTopMargin,
                  classes.verySmallBottomMargin
                )}
              ></Divider>
              <Grid
                container
                spacing={6}
                className={classes.verySmallTopMargin}
              >
                <Grid item xs={12} sm={12} md={5} lg={3} xl={3}>
                  <Typography align="left" color="textSecondary">
                    Using Calendly, member can schedule a meeting with you.
                  </Typography>
                </Grid>
                <Grid
                  className={classes.textAlignLeft}
                  item
                  xs={12}
                  sm={12}
                  md={7}
                  lg={5}
                  xl={5}
                >
                  {/* <Typography className={classNames(classes.smallBottomMargin, classes.halfWidth)}>
                            A verification code will be sent to the email address on file to reset your password.
                            </Typography> */}
                  <Typography className={classes.smallBottomMargin}>
                    <TextField
                      label="Calendly Link"
                      fullWidth
                      className={classes.halfWidth}
                      value={this.state.link}
                      // inputProps={{ maxLength: 10 }}
                      onChange={(event) =>
                        this.onLinkChange(event.target.value)
                      }
                      placeholder="Insert calendly link"
                      error={
                        this.state.isValidLink || this.state.isRequiredLink
                      }
                      helperText={
                        (this.state.isValidLink && "Invalid Link") ||
                        (this.state.isRequiredLink &&
                          MESSAGE_FIELD_IS_REQUIRED) ||
                        ""
                      }
                      // disabled={ secureLocalStorage.getItem('g_u') }
                    />
                  </Typography>
                  <Typography className={classes.mediumTopMargin}>
                    <Button
                      className={classes.floatLeft}
                      disableElevation
                      color="primary"
                      variant="contained"
                      size="large"
                      onClick={() => {
                        this.submitLink();
                      }}
                    >
                      Save
                    </Button>
                  </Typography>
                </Grid>
              </Grid>
              <Typography
                variant="h6"
                align="left"
                className={classNames(
                  classes.colorDefault,
                  classes.smallTopMargin,
                  classes.boldFont
                )}
              >
                Account Password
              </Typography>
              <Divider
                className={classNames(
                  classes.verySmallTopMargin,
                  classes.verySmallBottomMargin
                )}
              ></Divider>
              <Grid
                container
                spacing={6}
                className={classes.verySmallTopMargin}
              >
                <Grid item xs={12} sm={12} md={5} lg={3} xl={3}>
                  <Typography align="left" color="textSecondary">
                    Your information stays secure.
                  </Typography>
                </Grid>
                <Grid
                  className={classes.textAlignLeft}
                  item
                  xs={12}
                  sm={12}
                  md={7}
                  lg={9}
                  xl={9}
                >
                  <Typography className={classes.halfWidth}>
                    A verification code will be sent to the email address on
                    file to reset your password.
                  </Typography>
                  <Typography className={classes.smallTopMargin}>
                    <Button
                      className={classes.floatLeft}
                      disableElevation
                      color="primary"
                      variant="contained"
                      size="large"
                      onClick={(event) => {
                        this.handleChangePasswordClick(event, "abc");
                      }}
                    >
                      Change Password
                    </Button>
                  </Typography>
                </Grid>
              </Grid>
              <Typography
                variant="h6"
                align="left"
                className={classNames(
                  classes.colorDefault,
                  classes.smallTopMargin,
                  classes.boldFont
                )}
              >
                Document Password
              </Typography>
              <Divider
                className={classNames(
                  classes.verySmallTopMargin,
                  classes.verySmallBottomMargin
                )}
              />
              <Grid
                container
                spacing={6}
                className={classes.verySmallTopMargin}
              >
                <Grid item xs={12} sm={12} md={5} lg={3} xl={3}>
                  <Typography align="left" color="textSecondary">
                    Password
                  </Typography>
                </Grid>
                <Grid
                  className={classes.textAlignLeft}
                  item
                  xs={12}
                  sm={12}
                  md={7}
                  lg={9}
                  xl={9}
                >
                  <TextField
                    id="outlined-basic"
                    variant="outlined"
                    type={!this.state.passwordVisible ? "password" : "text"}
                    value={this.state.password}
                    onChange={(e) => this.handlePassword(e.target.value)}
                    className={classes.inputStyle}
                    InputProps={{
                      readOnly: true,
                      endAdornment: (
                        <InputAdornment
                          position="start"
                          className={classes.eyeIconStyle}
                          onClick={() => this.handlePasswordVisible()}
                        >
                          {!this.state.passwordVisible ? (
                            <RemoveRedEyeIcon />
                          ) : (
                            <VisibilityOffIcon />
                          )}
                        </InputAdornment>
                      ),
                    }}
                  />
                  <Typography className={classes.smallTopMargin}>
                    <Button
                      className={classes.floatLeft}
                      disableElevation
                      color="primary"
                      variant="contained"
                      size="large"
                      onClick={() => {
                        this.handleChangePassword();
                      }}
                    >
                      Change Password
                    </Button>
                  </Typography>
                </Grid>
              </Grid>
              <Grid
                container
                spacing={6}
                className={classes.verySmallTopMargin}
              >
                <Grid item xs={12} sm={12} md={5} lg={3} xl={3}>
                  <Typography align="left" color="textSecondary">
                    Last logged in
                  </Typography>
                </Grid>
                <Grid
                  className={classes.textAlignLeft}
                  item
                  xs={12}
                  sm={12}
                  md={7}
                  lg={9}
                  xl={9}
                >
                  <Typography className={classes.halfWidth}>
                    {moment(this.props.authentication.lastLogin).format(
                      "MMMM Do YYYY, h:mm:ss A"
                    )}{" "}
                    PST
                  </Typography>
                </Grid>
              </Grid>
              <Typography
                variant="h6"
                align="left"
                className={classNames(
                  classes.colorDefault,
                  classes.smallTopMargin,
                  classes.boldFont
                )}
              >
                Company Information
              </Typography>
              <Divider
                className={classNames(
                  classes.verySmallTopMargin,
                  classes.verySmallBottomMargin
                )}
              ></Divider>
              <Grid
                container
                spacing={6}
                className={classes.verySmallTopMargin}
              >
                <Grid item xs={12} sm={12} md={5} lg={3} xl={3}>
                  <Typography align="left" color="textSecondary">
                    Candidates will be able to view your corporate name and logo
                    on job requisitions. Please{" "}
                    <Link
                      onClick={(event) => {
                        this.contactCustomerSupport(event);
                      }}
                      className={classes.customLink}
                    >
                      contact client services
                    </Link>{" "}
                    to make changes to this information.
                  </Typography>
                </Grid>
                <Grid
                  className={classes.textAlignLeft}
                  item
                  xs={12}
                  sm={12}
                  md={7}
                  lg={5}
                  xl={5}
                >
                  <Avatar
                    src={this.capitalize(companyData?.logo)}
                    alt={this.capitalize(companyData?.company_name)}
                    variant="square"
                    className={classNames(
                      classes.square,
                      classes.verySmallTopMargin,
                      classes.smallBottomMargin
                    )}
                  >
                    {this.state.logo === "" ? (
                      <PanoramaIcon fontSize="large" />
                    ) : null}
                  </Avatar>
                  <Typography className={classes.smallBottomMargin}>
                    <TextField
                      label="COMPANY NAME"
                      fullWidth
                      className={classNames(classes.halfWidth)}
                      value={this.capitalize(companyData?.company_name)}
                      inputProps={{ readOnly: true }}
                    />
                  </Typography>
                  <Typography className={classes.smallBottomMargin}>
                    <TextField
                      label="COMPANY SIZE"
                      fullWidth
                      className={classes.halfWidth}
                      value={companyData?.employee_count}
                      inputProps={{ readOnly: true }}
                    />
                  </Typography>
                  <Typography className={classes.smallBottomMargin}>
                    <TextField
                      label="INDUSTRY"
                      fullWidth
                      className={classes.halfWidth}
                      value={
                        companyData.industry && companyData.industry !== "Other"
                          ? companyData.industry
                          : companyData.industry &&
                            companyData.industry === "Other"
                          ? companyData?.other_industry_text
                            ? companyData?.other_industry_text
                            : "Other"
                          : null
                      }
                      inputProps={{ readOnly: true }}
                    />
                  </Typography>
                  <Typography className={classes.smallBottomMargin}>
                    <TextField
                      label="LOCATION"
                      fullWidth
                      className={classes.halfWidth}
                      value={
                        (companyData?.location.city
                          ? companyData?.location.city + ", "
                          : "") +
                        (companyData?.location.state
                          ? companyData?.location.state + ", "
                          : "") +
                        (companyData?.location.country
                          ? companyData?.location.country
                          : "")
                      }
                      inputProps={{ readOnly: true }}
                    />
                  </Typography>
                  <Typography className={classes.smallBottomMargin}>
                    <TextField
                      label="WEBSITE"
                      fullWidth
                      className={classes.halfWidth}
                      value={"www." + companyData?.domain}
                      inputProps={{ readOnly: true }}
                    />
                  </Typography>
                  <Grid
                    container
                    spacing={6}
                    className={classes.verySmallTopMargin}
                  >
                    <Grid
                      item
                      xs={9}
                      sm={9}
                      md={7}
                      lg={9}
                      xl={9}
                      className={classes.alignSelfCenter}
                    >
                      <Typography align="left" color="textSecondary">
                        Confirm your interest in being presented diversity
                        candidates for open job requisitions.
                      </Typography>
                    </Grid>
                    <Grid
                      className={classes.textAlignRight}
                      item
                      xs={3}
                      sm={3}
                      md={5}
                      lg={3}
                      xl={3}
                    >
                      {/* <Typography className={classNames(classes.smallBottomMargin, classes.halfWidth)}>
                            A verification code will be sent to the email address on file to reset your password.
                            </Typography> */}
                      <Typography
                        className={classes.smallBottomMargin}
                        align="right"
                      >
                        <FormGroup align="right">
                          <FormControlLabel
                            control={
                              <CustomSwitch
                                // disabled={secureLocalStorage.getItem('g_u')}
                                checked={this.state.showDiversityFlag}
                                onChange={(event) =>
                                  this.handleDiversity(event)
                                }
                                name="show_diversity"
                              />
                            }
                          />
                        </FormGroup>
                      </Typography>
                      {/* <Typography className={classes.halfWidth}>
                                <Button className={classes.floatRight}
                                  disableElevation color='primary'
                                  variant='contained' 
                                  size='large' 
                                  disabled={this.state.oldDiversityFlag === this.state.showDiversityFlag}
                                  onClick={event => {this.handleDiversitySubmit()}}
                                  >Save</Button>
                            </Typography> */}
                    </Grid>
                  </Grid>

                  {/* <Typography className={classNames(classes.smallBottomMargin, classes.halfWidth)}>
                            Contact <Link className={classes.customLink} onClick={event=> {this.contactCustomerSupport(event)}}>customer support </Link> if you need to make changes to any information above.
                            </Typography> */}
                  {/* <Typography className={classes.halfWidth}>
                                <Button className={classes.floatRight} disableElevation color='primary' variant='contained' onClick={event => {this.handleChangePasswordClick(event)}} disabled>Save</Button>
                            </Typography> */}
                </Grid>
              </Grid>
            </>
          ) : (
            <>
              <Typography
                variant="h5"
                align="left"
                className={classNames(
                  classes.colorDefault,
                  classes.smallTopMargin,
                  classes.boldFont
                )}
              >
                Plan Details
              </Typography>
              <Typography
                variant="h6"
                align="left"
                className={classNames(
                  classes.colorDefault,
                  classes.smallTopMargin,
                  classes.boldFont
                )}
              >
                Users
              </Typography>
              <Divider
                className={classNames(classes.verySmallTopMargin)}
              ></Divider>
              <Grid
                container
                spacing={6}
                className={classes.verySmallTopMargin}
              >
                <Grid
                  className={classes.textAlignLeft}
                  item
                  xs={12}
                  sm={12}
                  md={12}
                  lg={12}
                  xl={12}
                >
                  {/* {!JSON.parse(secureLocalStorage.getItem('recruiterStatusDetails')).is_pilot && */}
                  <Typography
                    component="div"
                    align="left"
                    color="textSecondary"
                  >
                    To add a new user, please send full name and email to
                    <Link
                      className={classes.customLink}
                      onClick={(event) => {
                        this.contactCustomerSupport(event);
                      }}
                    >
                      {" "}
                      Customer Support.
                    </Link>
                  </Typography>
                  {/* } */}
                  <TableContainer className={classes.customSmallTopMargin}>
                    <Table
                      className={classes.table}
                      aria-label="customized table"
                    >
                      <TableHead>
                        <TableRow>
                          <StyledTableCell className={classes.boldFont}>
                            Name
                          </StyledTableCell>
                          <StyledTableCell
                            align="center"
                            className={classes.boldFont}
                          >
                            Email
                          </StyledTableCell>
                          <StyledTableCell
                            align="center"
                            className={classes.boldFont}
                          >
                            Title
                          </StyledTableCell>
                          {/* <StyledTableCell align="center" className={classes.boldFont}>Subscription Date</StyledTableCell>
                                  <StyledTableCell align="center" className={classes.boldFont}>Payment Due Date</StyledTableCell> */}
                          <StyledTableCell
                            align="center"
                            className={classes.boldFont}
                          >
                            Account Status
                          </StyledTableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {billingData?.seats.map((seat, index) => (
                          <StyledTableRow key={`${seat.name}-${index}`}>
                            <StyledTableCell
                              component="th"
                              scope="row"
                              className={
                                seat.is_owner ? classes.boldFont : null
                              }
                            >
                              {seat.name}
                            </StyledTableCell>
                            <StyledTableCell
                              align="center"
                              className={
                                seat.is_owner ? classes.boldFont : null
                              }
                            >
                              {seat?.email}
                            </StyledTableCell>
                            <StyledTableCell
                              align="center"
                              className={
                                seat.is_owner ? classes.boldFont : null
                              }
                            >
                              {seat?.title ? seat?.title : "-"}
                            </StyledTableCell>
                            {/* <StyledTableCell align="center" className={seat.is_owner ? classes.boldFont : null}>{moment(seat.start_date).format('MM-DD-YYYY')}</StyledTableCell>
                                    <StyledTableCell align="center" className={seat.is_owner ? classes.boldFont : null}>{moment(seat.end_date).format('MM-DD-YYYY')}</StyledTableCell> */}
                            <StyledTableCell
                              align="center"
                              className={
                                seat.is_owner ? classes.boldFont : null
                              }
                            >
                              {seat?.subscription_status === "trialing" ||
                              seat.subscription_status === "Trialing"
                                ? "Trial"
                                : seat?.subscription_status === "succeeded" ||
                                  seat?.subscription_status === "Succeeded"
                                ? "Active"
                                : this.capitalize(seat?.subscription_status) ||
                                  "Inactive"}
                            </StyledTableCell>
                          </StyledTableRow>
                        ))}
                      </TableBody>
                    </Table>
                  </TableContainer>
                </Grid>
                {/* {(billingData?.quantity-billingData?.seats.length > 0 && billingData?.quantity !== null && secureLocalStorage.getItem('g_u')) &&
                        <Grid item xs={12} sm={12} md={12} lg={12} xl={12} className={classes.textAlignRight}>
                          <Button
                              className={classes.customButton}
                              variant="contained"
                              size='large'
                              color='primary'
                              disableElevation
                              onClick={this.handleAddSeat}
                              disabled = {billingData?.quantity-billingData?.seats.length === 0}
                            >Add Additional Users</Button>
                        </Grid>
                      } */}
              </Grid>
              {/* {((JSON.parse(secureLocalStorage.getItem('recruiterStatusDetails')).is_pilot && secureLocalStorage.getItem('g_u')) || (!JSON.parse(secureLocalStorage.getItem('recruiterStatusDetails')).is_pilot)) && */}
              {/* {(secureLocalStorage.getItem('g_u') || (!JSON.parse(secureLocalStorage.getItem('recruiterStatusDetails')).is_pilot)) && */}
              <>
                <Typography
                  align="right"
                  className={classNames(
                    classes.boldFont,
                    classes.verySmallTopMargin
                  )}
                >
                  {" "}
                  <span style={{ color: "#048475" }}>
                    {billingData?.quantity - billingData?.seats.length <= 0
                      ? `Users Available: [0]`
                      : `Users Available: [` +
                        (billingData?.quantity - billingData?.seats?.length) +
                        `]`}
                  </span>
                </Typography>
                {/* <Divider className={classNames(classes.verySmallTopMargin, classes.verySmallBottomMargin)} />
                        <Typography align='left' color="textSecondary" className={classes.verySmallTopMargin}>To add or remove users, please update your SucceedSmart plan.</Typography>
                        <Grid className={classNames(classes.textAlignCenter, classes.verySmallTopMargin)} item xs={12} sm={12} md={12} lg={12} xl={12}>
                        <Button type="submit" className={classes.customButton} size='large' variant='contained' color='primary' disableElevation onClick={event => {this.handleManageBilling(event)}}>Update Plan</Button>
                          {this.props.myAccount?.billingData?.seats[0].subscription_status === 'trialing' &&
                            <Typography align='center' style={{color: '#EE0E0E'}} className={classes.verySmallTopMargin}>Updating your plan will end your trial period</Typography>
                          }
                        </Grid>
                        <Typography variant='h6' align='left' className={classNames(classes.colorDefault, classes.smallTopMargin, classes.boldFont)}>Payment History</Typography>
                        <Divider className={classNames(classes.verySmallTopMargin, classes.verySmallBottomMargin)}></Divider>
                        <Grid container spacing={6}>
                          <Grid className={classes.textAlignCenter} item xs={12} sm={12} md={12} lg={12} xl={12}>
                            <Button type="submit" className={classNames(classes.verySmallTopMargin, classes.customButton)} size='large' variant='contained' color='primary' disableElevation onClick={event => {this.handleManageBilling(event)}}>Payment History</Button>
                          </Grid>
                        </Grid> */}
              </>
              {/* } */}

              {/* Edit Payment Details */}
              {/* <Grid container className={classes.justifyContentCenter}>
                      <Grid item xs={12} sm={12} md={12} lg={12} xl={12} style={{backgroundColor: '#F7F7F7'}}>
                        {this.state.editPayment
                            ?
                            <>
                            <Typography variant='h6' className={classNames(classes.verySmallTopMargin, classes.displayFlex, classes.justifyContentSpaceEvenly, classes.boldFont)} style={{alignItems: 'center'}}>Edit Payment Details
                            <IconButton style={{float: 'right'}} onClick={event => {this.handleCloseEditPayment(event)}}>
                              <CloseIcon titleAccess='Close'></CloseIcon>
                            </IconButton></Typography>
                            <EditPayment></EditPayment>
                            </>
                            :
                            null
                          }
                      </Grid>
                    </Grid> */}

              {/* <Typography variant='h6' align='left' className={classNames(classes.colorDefault, classes.smallTopMargin, classes.boldFont)}>Billing History</Typography>
                    <Divider className={classNames(classes.verySmallTopMargin, classes.verySmallBottomMargin)}></Divider>
                    <Grid container spacing={6} className={classes.verySmallTopMargin}>
                        <Grid item xs={12} sm={12} md={5} lg={3} xl={3}>
                            <Typography align='left' color='textSecondary'>
                              View all your billing history here.
                            </Typography>
                        </Grid>
                        <Grid className={classes.textAlignLeft} item xs={12} sm={12} md={7} lg={9} xl={9}>
                        <TableContainer className={classes.verySmallBottomMargin}>
                          <Table aria-label="simple table">
                              <TableBody>
                              <TableRow>
                                      <TableCell  width='15%' className={classNames(classes.colorGrey, classes.textAlignLeft)} align="center">DATE</TableCell>
                                      <TableCell width='15%' className={classNames(classes.colorGrey, classes.textAlignLeft)} align="center">PLAN</TableCell>
                                      <TableCell width='15%' className={classNames(classes.colorGrey, classes.textAlignLeft)} align="center">AMOUNT</TableCell>
                                      <TableCell width='15%' className={classNames(classes.colorGrey, classes.textAlignLeft)} align="center">INVOICE</TableCell>
                                  </TableRow>
                                  <TableRow>
                                      <TableCell width='15%' className={classNames(classes.colorDefault, classes.textAlignLeft, classes.boldFont)} align="center">
                                      {billingData['billing history']?.invoice_date ? moment(billingData['billing history']?.invoice_date).format( 'MM-DD-YYYY') : '-'}
                                      </TableCell>
                                      <TableCell width='15%' className={classNames(classes.colorDefault, classes.textAlignLeft, classes.boldFont)} align="center">{billingData['billing history']?.plan_type}</TableCell>
                                      <TableCell width='15%' className={classNames(classes.colorDefault, classes.textAlignLeft, classes.boldFont)} align="center">{'$' + billingData['billing history']?.amount/100}</TableCell>
                                      <TableCell width='15%' className={classNames(classes.colorDefault, classes.textAlignLeft, classes.boldFont)} align="center">
                                      {billingData['billing history']?.invoice_id || '-'}
                                      </TableCell>
                                  </TableRow>
                              </TableBody>
                          </Table>
                        </TableContainer>
                        </Grid>
                    </Grid> */}
            </>
          )}
          <Dialog
            disableBackdropClick
            disableEscapeKeyDown
            aria-labelledby="customized-dialog-title"
            open={this.state.open}
          >
            <DialogTitle onClose={this.handleClose}>
              Add Additional Users
              <Typography color="textSecondary">
                You can add up to{" "}
                {billingData?.quantity - billingData?.seats?.length} additional{" "}
                {billingData?.quantity - billingData?.seats?.length === 1
                  ? "user."
                  : "users."}
              </Typography>
            </DialogTitle>
            <form noValidate>
              <DialogContent>
                {this.state.fields.map((field, idx) => {
                  return (
                    <div key={`${field}-${idx}`}>
                      <Grid
                        container
                        spacing={2}
                        className={classNames(
                          classes.customAddSeatSection,
                          classes.mediumTopMargin,
                          classes.mediumBottomMargin
                        )}
                      >
                        {this.state.fields.length > 1 ? (
                          <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                            <FormControlLabel
                              className={classes.floatRight}
                              control={
                                <IconButton
                                  className={classes.colorDefault}
                                  onClick={() => this.handleRemove(idx)}
                                >
                                  <IndeterminateCheckBoxIcon />
                                </IconButton>
                              }
                              label={<Typography>Remove</Typography>}
                            ></FormControlLabel>
                          </Grid>
                        ) : null}
                        <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
                          <TextField
                            autoFocus
                            fullWidth
                            label="First Name"
                            autoComplete="seatFirstName"
                            margin="dense"
                            required
                            // inputProps={{ maxLength: nameCharCount }}
                            id="seatFirstName"
                            name="seatFirstName"
                            value={field.fNameValue || ""}
                            onChange={(e) => this.handleChange(idx, e)}
                            // error ={field.isValidFirstName ? false : true }
                            // helperText={!field.isValidFirstName ? 'min 2 characters required' : ''}
                          />
                          {/* <Typography className={classNames(classes.floatRight, classes.smallFont, classes.verySmallTopMargin)} color='secondary'>{this.state.fields[idx]?.fNameValue?.length + '/' + nameCharCount} characters</Typography> */}
                        </Grid>
                        <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
                          <TextField
                            fullWidth
                            label="Last Name"
                            autoComplete="seatLastName"
                            margin="dense"
                            required
                            // inputProps={{ maxLength: nameCharCount }}
                            id="seatLastName"
                            name="seatLastName"
                            value={field.lNameValue || ""}
                            onChange={(e) => this.handleChange(idx, e)}
                            // error ={field.isValidLastName ? false : true }
                            // helperText={!field.isValidLastName ? 'min 2 characters required' : ''}
                          />
                          {/* <Typography className={classNames(classes.floatRight, classes.smallFont, classes.verySmallTopMargin)} color='secondary'>{this.state.fields[idx]?.lNameValue?.length + '/' + nameCharCount} characters</Typography> */}
                        </Grid>
                        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                          <TextField
                            fullWidth
                            label="Title"
                            autoComplete="seatRole"
                            margin="dense"
                            required
                            // inputProps={{ maxLength: roleCharCount }}
                            id="seatRole"
                            name="seatRole"
                            value={field.roleValue || ""}
                            onChange={(e) => this.handleChange(idx, e)}
                            // error ={field.isValidRole ? false : true }
                            // helperText={!field.isValidRole ? 'min 2 characters required' : ''}
                          />
                          {/* <Typography className={classNames(classes.floatRight, classes.smallFont, classes.verySmallTopMargin)} color='secondary'>{this.state.fields[idx]?.roleValue?.length + '/' + roleCharCount} characters</Typography> */}
                        </Grid>
                        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                          <TextField
                            fullWidth
                            label="Corporate Email Address"
                            autoComplete="seatEmail"
                            margin="dense"
                            required
                            id="seatEmail"
                            name="seatEmail"
                            value={field.emailValue || ""}
                            onChange={(e) => this.handleChange(idx, e)}
                            error={field.isValidEmail ? false : true}
                            helperText={
                              !field.isValidEmail
                                ? "Only a valid email within your organization is allowed."
                                : ""
                            }
                          />
                        </Grid>
                      </Grid>
                    </div>
                  );
                })}
                {this.state.fields.length <
                billingData?.quantity - billingData?.seats?.length ? (
                  <FormControlLabel
                    control={
                      <IconButton onClick={() => this.handleAdd()}>
                        <AddBoxIcon className={classes.colorDefault} />
                      </IconButton>
                    }
                    label={<Typography>Add User</Typography>}
                    className={classes.verySmallTopMargin}
                  ></FormControlLabel>
                ) : null}
              </DialogContent>
              <DialogActions className={classes.dialogActions}>
                <Button
                  variant="contained"
                  color="primary"
                  className={classes.submit}
                  disableElevation
                  size="large"
                  type="submit"
                  onClick={(event) => {
                    this.handleConfirmSeat(event);
                  }}
                >
                  Confirm User
                  {/* {this.state.fields.length > 1
                            ?
                            'Seats'
                            :
                            'Seat'
                          } */}
                </Button>
              </DialogActions>
            </form>
          </Dialog>
          <GetHelp />
        </main>
        <ChangePassword
          open={this.state.changePasswordPopup}
          onClose={this.onCloseChangePassword}
        />
      </div>
    );
  }
}

MyAccount.propTypes = {
  classes: PropTypes.object.isRequired,
};

function mapStateToProps(state) {
  return state;
}

const connectedMyAccountPage = withRouter(
  connect(mapStateToProps, null, null, {
    pure: false,
  })(withStyles(styles)(MyAccount))
);

export { connectedMyAccountPage as MyAccount };
