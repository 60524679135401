import { Grid, Tooltip, Avatar, Typography } from "@material-ui/core";
import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { openLinkInNewTab } from "../../helpers";

export function EmployesLogo({ logoArray }) {
  const useStyles = makeStyles(() => ({
    companyAvatar: {
      border: "2.5px solid #fcfaf5",
      "&:hover": {
        "-webkit-transform": "scale(1.4)",
        "-ms-transform": "scale(1.4)",
        transform: "scale(1.2)",
      },
      transition: "transform .5s",
      cursor: "pointer",
    },
    marginLeft0: {
      marginLeft: "0px",
    },
    marginLeft15: {
      marginTop: "5px",
      marginRight: "15px",
    },
  }));
  const classes = useStyles();
  return (
    <div className={classes.root} container>
      <Grid container className={classes.logoArrayRoot}>
        {logoArray?.length === 0 ? (
          <span>-</span>
        ) : (
          logoArray?.map((value) => (
            <Grid item className={classes.marginLeft15}>
              <Tooltip
                title={
                  value?.company_size || value?.industry || value?.domain ? (
                    <>
                      {value?.company_size ? (
                        <Typography>
                          Company Size: {value?.company_size}
                        </Typography>
                      ) : (
                        ""
                      )}
                      {value?.industry ? (
                        <Typography>
                          Industry:{" "}
                          {value?.industry === "Other"
                            ? value?.other_industry_text
                            : value?.industry}
                        </Typography>
                      ) : (
                        ""
                      )}
                      {value?.domain ? (
                        <Typography>
                          Domain:
                          {value?.domain}
                        </Typography>
                      ) : (
                        ""
                      )}
                    </>
                  ) : (
                    ""
                  )
                }
              >
                <Avatar
                  className={classes.companyAvatar}
                  src={value?.imageObjUrl}
                  variant="square"
                  onClick={() => {
                    openLinkInNewTab(value?.domain);
                  }}
                  // remove this to show the empty image in case of faulty url
                  onError={(e) => {
                    try {
                      e.currentTarget.style.display = "none";
                    } catch (_) {
                      console.log("error...");
                    }
                  }}
                />
              </Tooltip>
            </Grid>
          ))
        )}{" "}
      </Grid>
    </div>
  );
}
