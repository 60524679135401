const initialState = {
  jobTitles: [],
  activeTab: {},
  schedulingLinkValues: {},
  locationDetails: {},
  allUpdateData: [],
  allUpdateHeaderCard: "All",
};

export function jobs(state = initialState, action) {
  switch (action.type) {
    case "JOB_TITLE_DATA":
      return {
        ...state,
        jobTitles: action.jobTitles,
      };
    case "REPORTS_TO_TITLE_DATA":
      return {
        ...state,
        reportsToTitles: action.reportsToTitles,
      };
    case "MAJORS_DATA":
      return {
        ...state,
        majors: action.majors,
      };
    case "CERTIFICATIONS_DATA":
      return {
        ...state,
        certifications: action.certifications,
      };
    case "LOCATION_DATA":
      return {
        ...state,
        locationsData: action.locationsData,
      };
    case "JOB_POST_SUCCESS":
      return {
        ...state,
        jobPost: action.jobPost,
      };
    case "ADDED_COLLABORATORS_DATA":
      return {
        ...state,
        addedCollaborators: action.addedCollaborators,
      };
    case "ADDED_CANDIDATE_COMMENTS_DATA":
      return {
        ...state,
        addedComments: action.addedComments,
      };

    case "OPEN_JOB_LIST_SUCCESS":
      return {
        ...state,
        openJobList: action.openJobList,
      };
    case "CLOSE_JOB_LIST_SUCCESS":
      return {
        ...state,
        closedJobList: action.closedJobList,
      };
    case "CANDIDATE_COMMENTS":
      return {
        ...state,
        candidateComments: action.candidateComments,
      };
    case "JOB_UPDATE_SUCCESS":
    case "JOB_DETAILS_DATA":
      return {
        ...state,
        jobDetails: action.jobDetails,
      };
    case "JOB_CLOSE_SUCCESS":
      return {
        ...state,
        closeJobDetails: action.closeJobDetails,
      };
    case "SKILLS_DATA":
      return {
        ...state,
        skills: action.skills,
      };
    case "GOT_RECOMMENDED_DATA":
      return {
        ...state,
        recommended: action.recommended,
      };
    case "GOT_PENDING_DATA":
      return {
        ...state,
        pending: action.pending,
      };
    case "GOT_MATCHED_DATA":
      return {
        ...state,
        matched: action.matched,
      };
    case "GOT_ARCHIVED_DATA":
      return {
        ...state,
        archived: action.archived,
      };
    case "LIKE_RESPONSE":
      return {
        ...state,
        likeCandidateResponse: action.likeCandidateResponse,
      };
    case "DISLIKE_RESPONSE":
      return {
        ...state,
        dislikeCandidateResponse: action.dislikeCandidateResponse,
      };
    case "COLLABORATOR_RECRUITERS_DATA":
      return {
        ...state,
        collaboratorRecruiters: action.collaboratorRecruiters,
      };
    case "COMPANY_NAME_DATA":
      return {
        ...state,
        companyNames: action.companyNames,
      };
    case "SET_JOB_ACTIVE_TAB":
      return {
        ...state,
        activeTab: {
          ...state.activeTab,
          ...action.activeTab,
        },
      };
    case "SET_JOB_PAGE_VIEW":
      return {
        ...state,
        view: action.view,
      };
    case "SCHEDULING_LINK_VALUES":
      return {
        ...state,
        schedulingLinkValues: action.schedulingLinkValues,
      };
    case "SET_LOCATION":
      return {
        ...state,
        locationDetails: action.locationData,
      };
    case "ALL_UPDATE_DATA":
      return {
        ...state,
        allUpdateData: action.allUpdateData,
      };
    case "ALL_UPDATE_LITE_PROFILE_DATA":
      return {
        ...state,
        liteProfileData: action.liteProfileData,
      };
    case "ALL_UPDATE_HEADER_CARD":
      return {
        ...state,
        allUpdateHeaderCard: action.allUpdateHeaderCard,
      };
    default:
      return state;
  }
}
