import React, { Component, Fragment } from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import { connect } from "react-redux";
import { history } from "../../../helpers";
import Typography from "@material-ui/core/Typography";
import { withRouter } from "react-router-dom";
import CssBaseline from "@material-ui/core/CssBaseline";
import Link from "@material-ui/core/Link";
import ProductInfoImage from "../../../assets/product_info.svg";
import validator from "validator";
import { Auth } from "aws-amplify";
import { toast } from "react-toastify";
import { authActions, loading } from "../../../actions";
import { Box, Container } from "@material-ui/core";
import OtpInput from "react-otp-input";
import sideImg1 from "../../../assets/sideImg1.png";
import sideImg2 from "../../../assets/sideImg2.png";
import sideImg3 from "../../../assets/sideImg3.png";
import secureLocalStorage from "react-secure-storage";

const styles = (theme) => ({
  root: {
    height: "100vh",
    marginTop: "90px",
  },
  image: {
    backgroundImage: `url(${ProductInfoImage})`, //'url(https://source.unsplash.com/random)',
    backgroundRepeat: "no-repeat",
    backgroundColor:
      theme.palette.type === "light"
        ? theme.palette.grey[50]
        : theme.palette.grey[900],
    backgroundSize: "cover",
  },
  paper: {
    margin: theme.spacing(2, 7),
    display: "flex",
    alignItems: "center",
    marginTop: "8%",
    [theme.breakpoints.down("md")]: {
      margin: theme.spacing(3, 5),
    },
    [theme.breakpoints.down("sm")]: {
      margin: theme.spacing(2, 2),
    },
  },
  customPaper: {
    margin: theme.spacing(1, 10),
    display: "flex",
    alignItems: "center",
    [theme.breakpoints.down("md")]: {
      margin: theme.spacing(3, 5),
    },
    [theme.breakpoints.down("sm")]: {
      margin: theme.spacing(2, 2),
    },
  },
  customForm: {
    margin: theme.spacing(5, 8),
    display: "flex",
    alignItems: "center",
  },
  xxLargeFont: {
    fontSize: "xx-large",
    [theme.breakpoints.down("md")]: {
      fontSize: "22px",
    },
    [theme.breakpoints.down("sm")]: {
      fontSize: "22px",
    },
  },
  bottomSmall: {
    marginBottom: "9%",
  },
  resendLink: {
    cursor: "pointer",
    "&:hover": {
      textDecoration: "underline",
    },
    color: "#048475 !important",
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  smallTopMargin: {
    marginTop: "3%",
  },
  submit: {
    margin: theme.spacing(5, 0, 2),
    borderRadius: "4px !important",
  },
  alignRight: {
    textAlign: "right",
  },
  margin: {
    marginTop: theme.spacing(2),
  },
  withoutLabel: {
    marginTop: theme.spacing(3),
  },
  textField: {
    width: "100%",
  },
  disabledSignIn: {
    cursor: "not-allowed",
    borderRadius: "4px !important",
  },
  toolbar: {
    padding: "1% !important",
    left: "1.5% !important",
  },
  responsive: {
    maxWidth: "100% !important",
    height: "auto !important",
    width: "auto !important",
    alignSelf: "center !important",
    minWidth: "fit-content !important",
  },
  customFlex: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },

  // for side box added by KP
  detailsBox: {
    background: "#FBC112",
    height: "100%",
    padding: "120px 70px",
    [theme.breakpoints.down("md")]: {
      padding: "50px 30px",
    },
    [theme.breakpoints.down("sm")]: {
      padding: "32px 16px",
    },
  },
  listItem: {
    display: "flex",
    alignItems: "center",
    marginTop: "20px",
    marginBottom: "20px",
    [theme.breakpoints.between("xs", "lg")]: {
      marginTop: "10px",
      marginBottom: "10px",
    },
    background: "#FB9012",
    padding: "10px",
    borderRadius: "8px",
    fontSize: "18px",
  },
  itemCircle: {
    height: "64px",
    width: "64px",
    borderRadius: "50%",
    background: "#ffffff",
    padding: 0,
    "& img": {
      height: "64px",
      width: "64px",
    },
  },
  sideTitle: {
    fontSize: "24px",
    [theme.breakpoints.between("xs", "lg")]: {
      fontSize: "18px",
    },
    lineHeight: "185%",
    color: "#000000",
    marginBottom: "10px",
    textAlign: "left",
  },
  otpInput: {
    "&>input": {
      fontSize: "1rem !important",
      width: "2rem !important",
      height: "2rem !important",
      backgroundColor: "#F4F4F5",
    },
    "&:last-child": {
      marginRight: "0 !important",
    },
    width: "3rem",
    height: "3rem",
    marginRight: "1rem",
    fontSize: "1rem",
    textAlign: "center",
    border: "1px solid #B7B6B8",
    borderRadius: "5px",
    backgroundColor: "#F4F4F5",
    display: "flex !important",
    alignItems: "center !important",
    justifyContent: "center !important",
  },
});

class VerifyOtp extends Component {
  constructor(props) {
    super(props);
    this.state = {
      email: "",
      verificationCode: "",
      isValidVerificationCode: true,
      errors: {
        cognito: null,
      },
    };
  }

  handleChange = (prop) => (event) => {
    switch (prop) {
      case "verificationCode":
        this.setState({
          isValidVerificationCode: validator.matches(event, /^[0-9].{0,6}$/),
        });
        this.setState({
          recruiterId: JSON.parse(secureLocalStorage.getItem("recruiterId")),
        });
        break;
      default:
        return;
    }
    if (prop === "verificationCode") this.setState({ [prop]: event });
    else this.setState({ [prop]: event.target.value });
  };

  verifyOtp = async (event) => {
    event.preventDefault();
    event.stopPropagation();
    const { dispatch } = this.props;
    let recruiterDetails = this.props.authentication?.recruiterDetails;
    dispatch(loading(true));
    const { verificationCode, recruiterId } = this.state;
    let username = JSON.parse(secureLocalStorage.getItem("userId"));
    if (verificationCode) {
      try {
        const user = await Auth.confirmSignUp(username, verificationCode);
        console.log(user);
        toast.dark("Email verified successfully");
        secureLocalStorage.setItem("onboardingStatus", "otpVerified");
        dispatch(authActions.setRecruiterOnboardingStatus(recruiterId));
        if (
          recruiterDetails?.domain_exists &&
          recruiterDetails?.is_from_added_seat
        ) {
          history.push("/signin");
        } else {
          if (
            recruiterDetails?.domain_exists &&
            !recruiterDetails?.is_from_added_seat
          ) {
            history.push("/no-access");
          } else {
            history.push("/signup-success");
          }
        }
        dispatch(loading(false));
      } catch (error) {
        let err = null;
        !error.message ? (err = { message: error }) : (err = error);
        console.log("error verifying user:", err);
        this.setState({
          errors: {
            ...this.state.errors,
            cognito: error,
          },
        });
        dispatch(loading(false));
        toast.dark(error.message);
      }
    } else {
      dispatch(loading(false));
      toast.dark("Please enter verification code.");
    }
  };

  resendOtp = async () => {
    const { dispatch } = this.props;
    dispatch(loading(true));
    let username = JSON.parse(secureLocalStorage.getItem("userId"));
    try {
      const user = await Auth.resendSignUp(username);
      console.log(user);
      dispatch(loading(false));
      toast.dark("Please check your registered email for verification code");
    } catch (error) {
      let err = null;
      !error.message ? (err = { message: error }) : (err = error);
      console.log("error resending verification code:", err);
      this.setState({
        errors: {
          ...this.state.errors,
          cognito: error,
        },
      });
      dispatch(loading(false));
      toast.dark(error.message);
    }
  };

  render() {
    const { classes } = this.props;
    return (
      <Fragment>
        {/* <AppBar /> */}
        <Grid container component="main" className={classes.root}>
          <CssBaseline />
          <Grid
            item
            xs={12}
            sm={6}
            md={7}
            lg={7}
            xl={7}
            component={Paper}
            elevation={6}
            square
          >
            <div className={classes.paper}>
              <Typography
                style={{ padding: "8px" }}
                align="left"
                component="span"
                className={classes.xxLargeFont}
              >
                Please enter the verification code that was sent to the email
                address you used to create your SucceedSmart account.
              </Typography>
            </div>
            <form className={classes.customForm} noValidate>
              <Grid
                container
                item
                spacing={2}
                xs={12}
                sm={12}
                md={10}
                lg={10}
                xl={10}
              >
                <Typography align="left" style={{ padding: "8px" }}>
                  {"Your 6-digit verification code"}
                </Typography>
                <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                  <OtpInput
                    isInputNum
                    isNumberInput
                    shouldAutoFocus={true}
                    id="verificationCode"
                    name="verificationCode"
                    value={this.state.verificationCode}
                    onChange={this.handleChange("verificationCode")}
                    numInputs={6}
                    className={classes.otpInput}
                    inputClassName="otpInput"
                    hasErrored={
                      this.state.isValidVerificationCode ? false : true
                    }
                    autoFocus
                  />

                  {!this.state.isValidVerificationCode ? (
                    <Typography
                      align="left"
                      style={{
                        fontSize: "0.75rem",
                        color: "rgb(220, 0, 78)",
                        margin: "8px",
                      }}
                    >
                      The code entered by you is incorrrect. Please try again.{" "}
                    </Typography>
                  ) : null}
                </Grid>
                <Grid
                  className={classes.smallTopMargin}
                  item
                  xs={12}
                  sm={12}
                  md={12}
                  lg={12}
                  xl={12}
                >
                  <div className={classes.customFlex}>
                    <Typography className={classes.toolbarContent} align="left">
                      <Link
                        className={classes.resendLink}
                        onClick={(event) => {
                          this.resendOtp(event);
                        }}
                      >
                        Resend code
                      </Link>
                    </Typography>
                    <Button
                      type="submit"
                      disableElevation
                      variant="contained"
                      color="primary"
                      id="verifyBtn"
                      size="large"
                      disabled={
                        !this.state.isValidVerificationCode ||
                        this.state.isValidVerificationCode === ""
                      }
                      onClick={(event) => {
                        this.verifyOtp(event);
                      }}
                    >
                      Verify
                    </Button>
                  </div>
                </Grid>
              </Grid>
            </form>
          </Grid>
          <Grid item xs={false} sm={6} md={5} lg={5} xl={5}>
            <Box p={1} align="left" className={classes.detailsBox}>
              <div>
                <Typography className={classes.sideTitle}>
                  SucceedSmart offers companies an affordable, transparent, and
                  speedy executive hiring process, featuring three main
                  benefits:
                </Typography>
              </div>
              <Grid container direction="column">
                <Box className={classes.listItem}>
                  <Container component="span" className={classes.itemCircle}>
                    <img
                      src={sideImg1}
                      alt="Leverage diversity through privacy"
                    />
                  </Container>
                  <Container component="div">
                    Leverage diversity through privacy
                  </Container>
                </Box>
                <Box className={classes.listItem}>
                  <Container component="span" className={classes.itemCircle}>
                    <img src={sideImg2} alt="Budget-friendly pricing" />
                  </Container>
                  <Container component="div">Budget-friendly pricing</Container>
                </Box>
                <Box className={classes.listItem}>
                  <Container component="span" className={classes.itemCircle}>
                    <img src={sideImg3} alt="Improved productivity" />
                  </Container>
                  <Container component="div">Improved productivity</Container>
                </Box>
              </Grid>
            </Box>
          </Grid>
        </Grid>
      </Fragment>
    );
  }
}

VerifyOtp.propTypes = {
  classes: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => {
  return state;
};

const connectedVerifyOtpPage = withRouter(
  connect(mapStateToProps, null, null, {
    pure: false,
  })(withStyles(styles)(VerifyOtp))
);

export { connectedVerifyOtpPage as VerifyOtp };
