import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import { withStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import { authActions } from "../../actions";
import { connect } from "react-redux";

const styles = () => ({
  smallTopMargin: {
    marginTop: "2%",
  },
  smallBottomMargin: {
    marginBottom: "2%",
  },
  customLink: {
    color: "#048475 !important",
    cursor: "pointer",
    "&:hover": {
      textDecoration: "underline",
    },
  },
});

class GetHelp extends React.Component {
  state = {
    anchor: "left",
  };

  handleGetHelp = () => {
    const { dispatch } = this.props;
    dispatch(authActions.getHelp());
  };

  render() {
    const { classes } = this.props;
    return (
      <Typography
        className={classNames(
          classes.smallTopMargin,
          classes.smallBottomMargin
        )}
        align="right"
      >
        Having trouble?
        <span
          className={classes.customLink}
          onClick={(event) => {
            this.handleGetHelp(event);
          }}
        >
          {" "}
          Get Help
        </span>
      </Typography>
    );
  }
}

GetHelp.propTypes = {
  classes: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => {
  return state;
};

export default connect(mapStateToProps)(withStyles(styles)(GetHelp));
