import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import { withStyles } from "@material-ui/core/styles";
import { ReactComponent as Logo } from "../../assets/logo_dark.svg";
import { ReactComponent as LightLogo } from "../../assets/logo.svg";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import Link from "@material-ui/core/Link";
import { authActions, loading } from "../../actions";
import { history } from "../../helpers";
import { Auth } from "aws-amplify";
import { connect } from "react-redux";
import { IconButton, Hidden } from "@material-ui/core";
import MenuIcon from "@material-ui/icons/Menu";
import { loggingIn } from "../../actions";
import MuiDialogTitle from "@material-ui/core/DialogTitle";
import MuiDialogContent from "@material-ui/core/DialogContent";
import MuiDialogActions from "@material-ui/core/DialogActions";
import CloseIcon from "@material-ui/icons/Close";
import { Dialog, DialogContentText, Button } from "@material-ui/core";
import cardHeaderBg from "../../assets/lite_profile_bg.png";
import Drawer from "@material-ui/core/Drawer";
import Nav from "../core/nav";
import clsx from "clsx";
import Header from "./header";
import secureLocalStorage from "react-secure-storage";

const drawerWidth = 300;
const styles = (theme) => ({
  root: {
    display: "flex",
    flexGrow: 1,
    padding: "0.6% !important",
  },
  appFrame: {
    zIndex: 1,
    overflow: "hidden",
    position: "relative",
    display: "flex",
    width: "100%",
  },
  appBar: {
    height: "90px",
    backgroundColor: "#ffffff",
    boxShadow:
      "0px 1px 1px -1px rgb(0 0 0 / 20%), 0px 1px 5px 0px rgb(0 0 0 / 14%), 0px 1px 10px 0px rgb(0 0 0 / 12%)",
    [theme.breakpoints.between("md", "xl")]: {
      width: "100%", //`calc(100% - ${drawerWidth}px)`,
      marginLeft: drawerWidth,
    },
  },
  customAppBar: {
    height: "90px",
    backgroundColor: "#000000",
    boxShadow:
      "0px 1px 1px -1px rgb(0 0 0 / 20%), 0px 1px 5px 0px rgb(0 0 0 / 14%), 0px 1px 10px 0px rgb(0 0 0 / 12%)",
    [theme.breakpoints.between("md", "xl")]: {
      width: "100%",
      display: "none",
    },
    position: "fixed !important",
  },
  drawerPaper: {
    position: "relative",
    width: drawerWidth,
  },
  toolbar: theme.mixins.toolbar,
  toolbarContent: {
    flex: "auto",
    padding: "2%",
    color: "black",
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
  },
  smallPaddingLeft: {
    paddingLeft: "1.5% !important",
  },
  responsive: {
    maxWidth: "100% !important",
    height: "auto !important",
    width: "auto !important",
    alignSelf: "center !important",
  },
  customLink: {
    color: "#048475 !important",
    cursor: "pointer",
    "&:hover": {
      textDecoration: "underline",
    },
  },
  drawer: {
    width: 0,
    [theme.breakpoints.between("md", "xl")]: {
      // width: drawerWidth,
      flexShrink: 0,
    },
    whiteSpace: "nowrap",
  },
  menuButton: {
    // marginRight: theme.spacing(2),
    color: "#FBC112",
    [theme.breakpoints.between("md", "xl")]: {
      display: "none",
    },
  },
  //   drawer: {
  //     width: drawerWidth,
  //     flexShrink: 0,
  //     whiteSpace: "nowrap"
  //   },
  drawerOpen: {
    // [theme.breakpoints.between("sm", "xl")]: {
    //   width: drawerWidth
    // },
    // width: theme.spacing(9) + 1,
    width: drawerWidth,
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
    backgroundColor: "black",
    color: "white",
    // overflowX: "hidden",
    overflow: "visible",
    // marginTop: '90px'
  },
  customDialogHeader: {
    maxHeight: "38%",
    height: "38%",
    color: "#000",
    backgroundSize: "cover",
    backgroundImage: `url(${cardHeaderBg})`,
    background: "round",
  },
  closeButton: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.default.main,
    padding: "0px",
  },
  //   drawerClose: {
  //     transition: theme.transitions.create("width", {
  //       easing: theme.transitions.easing.sharp,
  //       duration: theme.transitions.duration.leavingScreen
  //     }),
  //     // overflowX: "hidden",
  //     overflow: "visible",
  //     width: theme.spacing(7) + 1,
  //     [theme.breakpoints.up("sm")]: {
  //       width: theme.spacing(9) + 1
  //     },
  //     backgroundColor: 'black',
  //     color: 'white',
  //   }

  iconLabelWrapper2: {
    flexDirection: "row-reverse",
  },
  labelIcon: {
    minHeight: "52px",
  },
});

const DialogTitle = withStyles(styles)((props) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle
      disableTypography
      {...other}
      className={classes.customDialogHeader}
      style={{ padding: "3%" }}
    >
      {/* className={classes.root} */}
      <Typography variant="h5" align="center">
        {children}
      </Typography>
      {onClose ? (
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={onClose}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const DialogContent = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
    paddingLeft: theme.spacing(4),
    paddingRight: theme.spacing(4),
  },
}))(MuiDialogContent);

const DialogActions = withStyles((theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
    backgroundColor: "#fff",
    justifyContent: "center !important",
  },
}))(MuiDialogActions);

class PermanentDrawer extends React.Component {
  state = {
    anchor: "left",
    mobileOpen: false,
    openConfirmSigOutPopup: false,
    activeTab: 0,
    activeTab2: null,
  };

  handleChange = (event) => {
    this.setState({
      anchor: event.target.value,
    });
  };

  handleSignOutClick = async (event) => {
    event.preventDefault();
    event.stopPropagation();
    const { dispatch } = this.props;
    dispatch(loading(true));
    try {
      await Auth.signOut();
      secureLocalStorage.clear();
      dispatch(authActions.signout());
      history.push("/signin");
      dispatch(loading(false));
      this.handleClose();
    } catch (error) {
      let err = null;
      !error.message ? (err = { message: error }) : (err = error);
      console.log("error signing out:", err);
      this.setState({
        errors: {
          ...this.state.errors,
          cognito: error,
        },
      });
      dispatch(loading(false));
    }
  };

  confirmSignout = () => {
    this.setState({ openConfirmSigOutPopup: true });
  };

  handleClose = () => {
    this.setState({ openConfirmSigOutPopup: false });
  };

  redirectToSignIn = () => {
    history.push("/signin");
  };

  redirectToSignUp = () => {
    history.push("/signup");
  };

  handleDrawerToggle = () => {
    this.setState((prevState) => ({ mobileOpen: !prevState.mobileOpen }));
  };

  render() {
    const { classes } = this.props; //shared, window1
    const { anchor } = this.state;
    // const container = window1 !== undefined ? () => window1().document.body : undefined;
    // const drawer = (
    //     <div>
    //       <div className={classes.toolbar} />
    //       <List>
    //         {["Inbox", "Starred"].map((text, index) => (
    //           <ListItem button key={text}>
    //             <ListItemIcon>
    //               {index % 2 === 0 ? <InboxIcon /> : <MailIcon />}
    //             </ListItemIcon>
    //             <ListItemText primary={text} />
    //           </ListItem>
    //         ))}
    //       </List>
    //     </div>
    //   );
    return (
      <>
        {/* {!this.props.loggedIn && */}
        <AppBar
          position="absolute"
          color="inherit"
          style={{
            height: !this.props.shared?.badRequest ? "90px" : "0px",
          }}
          className={classNames(
            !this.props.loggedIn && !this.props.shared?.badRequest
              ? classes.appBar
              : secureLocalStorage.getItem("recruiterStatusDetails") &&
                this.props.authentication?.recruiterStatusDetails
                  ?.payment_status &&
                this.props.authentication?.recruiterStatusDetails
                  ?.approval_status &&
                !this.props.shared?.badRequest
              ? classes.customAppBar
              : null,
            classes[`appBar-${anchor}`]
          )}
        >
          <Toolbar>
            {secureLocalStorage.getItem("auth") && (
              <IconButton className={classes.menuButton}>
                <MenuIcon onClick={this.handleDrawerToggle} />
              </IconButton>
            )}
            <div variant="subtitle1" className={classes.smallPaddingLeft}>
              {
                // window.location.pathname === '/contract-incomplete'
                // ?
                // <Link href="/contract-incomplete"><Logo width='230' className={classes.responsive} /></Link>
                // :
                //     window.location.pathname === '/application-status'
                //     ?
                //     <Link href="/application-status"><Logo width='230' className={classes.responsive} /></Link>
                //     :
                //     window.location.pathname === '/contract-signed'
                //     ?
                //     <Link href="/contract-signed"><Logo width='230' className={classes.responsive} /></Link>
                //     :
                //     // window.location.pathname === '/sign-contract' && secureLocalStorage.getItem('auth')
                //     window.location.pathname === '/sign-contract'
                //     ?
                //     <Link href="/sign-contract"><Logo width='230' className={classes.responsive} /></Link>
                //     :
                window.location.pathname ===
                "/bad-request" ? null : secureLocalStorage.getItem("auth") &&
                  window.location.pathname !== "/confirm-signup" &&
                  window.location.pathname !== "/signup-success" &&
                  window.location.pathname !== "/company-info" &&
                  window.location.pathname !== "/sign-contract" &&
                  window.location.pathname !== "/no-access" &&
                  window.location.pathname !== "/application-status" &&
                  window.location.pathname !== "/contract-incomplete" &&
                  window.location.pathname !== "/contract-signed" &&
                  window.location.pathname !== "/payment" &&
                  window.location.pathname !== "confirm-payment" ? (
                  <Link href="/">
                    <LightLogo width="230" className={classes.responsive} />
                  </Link>
                ) : (
                  <Logo width="230" className={classes.responsive} />
                )
                // <Link href="/"><Logo width='230' className={classes.responsive} /></Link>
              }
            </div>
            {/* { window.location.pathname === '/'
                            ?
                            <Typography component='div' className={classes.toolbarContent} align='right'>{'Looking to hire?'}&nbsp;
                                <span className={classes.customLink} onClick={event => {this.redirectToSignUp(event)}}>Create Corporate Account</span>&nbsp; &nbsp;
                                <span className={classes.customLink} onClick={event => {this.redirectToSignIn(event)}}>Corporate Sign In</span>
                            </Typography>
                            :
                            null
                        } */}
            {((secureLocalStorage.getItem("auth") &&
              !this.props.authentication?.recruiterStatusDetails
                ?.payment_status) ||
              (this.props.authentication?.recruiterStatusDetails
                ?.payment_status &&
                !this.props.authentication?.recruiterStatusDetails
                  ?.approval_status)) &&
            window.location.pathname !== "/verify-email" ? (
              <Typography className={classes.toolbarContent} align="right">
                <Link
                  className={classes.customLink}
                  onClick={(event) => this.confirmSignout(event)}
                >
                  Sign Out
                </Link>
              </Typography>
            ) : null}
            {/* { window.location.pathname === '/contract-signed'
                            ?
                            <Typography className={classes.toolbarContent} align="right" ><Link href="/signin" className={classes.customLink} >Sign In</Link></Typography>
                            :
                            null
                        } */}
          </Toolbar>
        </AppBar>
        {/* } */}
        {this.props.loggedIn &&
          secureLocalStorage.getItem("recruiterStatusDetails") &&
          this.props.authentication?.recruiterStatusDetails?.payment_status &&
          this.props.authentication?.recruiterStatusDetails
            ?.approval_status && (
            <nav
              className={classes.drawer}
              aria-label="mailbox folders"
              id="navBar"
            >
              {/* The implementation can be swapped with js to avoid SEO duplication of links. */}
              <Hidden smUp implementation="css">
                <Drawer
                  classes={{
                    paper: clsx({
                      [classes.drawerOpen]: true, //shared.toggle
                      //   [classes.drawerClose]: !shared.toggle
                    }),
                  }}
                  // className={clsx(classes.drawer, {
                  // [classes.drawerOpen]: shared.toggle
                  // // [classes.drawerClose]: !shared.toggle
                  // })}
                  // container={container}
                  variant="temporary"
                  anchor={this.state.anchor}
                  open={this.state.mobileOpen}
                  onClose={this.handleDrawerToggle}
                  // classes={{
                  // paper: classes.drawerPaper
                  // }}
                  ModalProps={{
                    keepMounted: true, // Better open performance on mobile.
                  }}
                >
                  <Nav />
                </Drawer>
              </Hidden>
              <Hidden smDown implementation="css">
                <Header />
              </Hidden>
              {/* <Hidden smDown implementation="css">
                            <Drawer
                                // classes={{
                                // paper: classes.drawerPaper
                                // }}
                                classes={{
                                    paper: clsx({
                                        [classes.drawerOpen]: true //shared.toggle
                                        //   [classes.drawerClose]: !shared.toggle
                                    })
                                }}
                                // className={clsx(classes.drawer, {
                                // [classes.drawerOpen]: shared.toggle
                                // // [classes.drawerClose]: !shared.toggle
                                // })}
                                variant="permanent"
                                open
                            >
                                <Nav />
                            </Drawer>
                        </Hidden> */}
            </nav>
          )}
        <Dialog
          open={this.state.openConfirmSigOutPopup}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
          fullWidth
          disableBackdropClick
          disableEscapeKeyDown
        >
          <DialogTitle id="alert-dialog-title" onClose={this.handleClose}>
            Confirm Sign Out
          </DialogTitle>
          <DialogContent>
            <DialogContentText
              id="alert-dialog-description"
              className={classNames(classes.largeFont)}
            >
              <Typography align="center">
                Are you sure you want to sign out?
              </Typography>
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button
              size="large"
              disableElevation
              onClick={this.handleClose}
              style={{ background: "#D7D9DB", color: "#000" }}
              variant="contained"
              color="primary"
            >
              Cancel
            </Button>
            <Button
              size="large"
              disableElevation
              onClick={this.handleSignOutClick}
              variant="contained"
              color="primary"
              autoFocus
            >
              Confirm
            </Button>
          </DialogActions>
        </Dialog>
      </>
    );
  }
}

PermanentDrawer.propTypes = {
  classes: PropTypes.object.isRequired,
  // window1: PropTypes.func
};

const mapStateToProps = (state) => {
  return {
    ...state,
    loggedIn: secureLocalStorage.getItem("auth") ? true : false,
  };
};

// export default withStyles(styles)(PermanentDrawer);
export default connect(mapStateToProps, null, null, { loggingIn })(
  withStyles(styles)(PermanentDrawer)
);
