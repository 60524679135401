import { toast } from "react-toastify";
import { apiService } from "../services";
import { history } from "../helpers";
import { messagesActions } from "./messages.actions";
import { sharedActions } from "./shared.action";
import store from "../store/store";
import { GHOST_TOAST_CONF } from "../constants/constants";
import { showResponseToast } from "../helpers/toastHelpers";
import secureLocalStorage from "react-secure-storage";

export const authActions = {
  signin,
  signout,
  getAllConstants,
  setRecruiter,
  getCompanyData,
  createCompany,
  setContractDetails,
  setRecruiterOnboardingStatus,
  getRecruiterOnboardingStatus,
  getHelp,
  ghostSignIn,
  updateRecruiter,
  MagicLinkSignIn,
};

function MagicLinkSignIn(token) {
  secureLocalStorage.setItem("a_token", JSON.stringify(token));
  return (dispatch) => {
    const apiEndpoint = "/api/v2/recruiter/login/";
    apiService
      .get(apiEndpoint)
      .then((response) => {
        if (
          response &&
          response.data.data &&
          (response.status === 200 || response.status === 201)
        ) {
          secureLocalStorage.clear();
          secureLocalStorage.setItem("auth", true);
          secureLocalStorage.setItem(
            "userDetails",
            JSON.stringify(response.data.user_details)
          );
          secureLocalStorage.setItem(
            "a_token",
            JSON.stringify(response.data.data.access_token)
          );
          secureLocalStorage.setItem(
            "r_token",
            JSON.stringify(response.data.data.refresh_token)
          );
          dispatch(setAuthDetails());
          store.dispatch(
            getRecruiterOnboardingStatus({
              email: response?.data?.user_details?.email,
            })
          );
          store.dispatch(getAllConstants());
        }
        showResponseToast(
          response,
          "Successfully signed in using magic link",
          "Failure while signing in using magic link"
        );
      })
      .catch((err) => {
        console.log(err);
      });
  };
}

function ghostSignIn(email) {
  return (dispatch) => {
    const apiEndpoint = "/api/v2/admin/recruiter_login/";
    const payload = {
      email,
    };
    apiService
      .post(apiEndpoint, payload)
      .then((response) => {
        if (
          response &&
          response.data.data &&
          (response.status === 200 || response.status === 201)
        ) {
          secureLocalStorage.clear();
          secureLocalStorage.setItem("auth", true);
          secureLocalStorage.setItem("g_u", true);
          secureLocalStorage.setItem(
            "userDetails",
            JSON.stringify(response.data.user_details)
          );
          secureLocalStorage.setItem(
            "a_token",
            JSON.stringify(response.data.data.access_token)
          );
          secureLocalStorage.setItem(
            "r_token",
            JSON.stringify(response.data.data.refresh_token)
          );
          dispatch(setAuthDetails());
          store.dispatch(getRecruiterOnboardingStatus(payload));
          store.dispatch(getAllConstants());
          toast.success("Logged in as SucceedSmart admin", GHOST_TOAST_CONF);
        } else if (response.status === 401) {
          history.push("/signin");
        }
        showResponseToast(
          response,
          "Successfully signed in using ghost sign in",
          "Failure while signing in using ghost sign in"
        );
      })
      .catch((err) => {
        console.log(err);
      });
  };
}

function signin(email, deeplink) {
  return (dispatch) => {
    const apiEndpoint = "/api/v1/recruiter/login/";
    const payload = {
      email,
      id_token: JSON.parse(secureLocalStorage.getItem("idToken")),
    };
    apiService
      .post(apiEndpoint, payload)
      .then((response) => {
        if (
          response &&
          response.data &&
          (response.status === 200 || response.status === 201)
        ) {
          dispatch(setLastLogin(response.data.created_at));
          secureLocalStorage.setItem(
            "a_token",
            JSON.stringify(response.data.access_token)
          );
          secureLocalStorage.setItem(
            "r_token",
            JSON.stringify(response.data.refresh_token)
          );
          dispatch(setAuthDetails());
          store.dispatch(getRecruiterOnboardingStatus(payload, deeplink));
          store.dispatch(getAllConstants());
        }
        showResponseToast(
          response,
          "Successfully signed in",
          "Failure while signing in"
        );
      })
      .catch((err) => {
        console.log(err);
      });
  };
}

export function setLastLogin(lastLogin) {
  return {
    type: "SET_LAST_LOGIN",
    lastLogin: lastLogin,
  };
}

function serverLogOut(isPasswordChange) {
  const apiEndpoint = "/api/v1/recruiter/logout/";
  return apiService
    .post(apiEndpoint, {
      refresh_token: JSON.parse(secureLocalStorage.getItem("r_token")),
    })
    .then((response) => {
      secureLocalStorage.removeItem("auth");
      secureLocalStorage.removeItem("token");
      secureLocalStorage.removeItem("state");
      secureLocalStorage.clear();
      history.push("/signin");
      if (!isPasswordChange) {
        showResponseToast(
          response,
          "Successfully logged out",
          "Failure while logging out"
        );
      }
      return response;
    })
    .catch((err) => {
      console.log(err);
    });
}

export function signout(isPasswordChange) {
  return (dispatch) => {
    toast.dismiss("ghost");
    serverLogOut(isPasswordChange);
    dispatch(signoutRecruiter());
    dispatch(deepLink(null));
  };
}

function getAllConstants() {
  return (dispatch) => {
    const apiEndpoint = "/api/v1/master_data/?";
    apiService
      .get(apiEndpoint)
      .then((response) => {
        if (response && (response.status === 200 || response.status === 201)) {
          dispatch(getAllConstantsData(response.data));
        } else if (response.status !== 401) {
          toast.dark("Something went haywire");
          // history.push('/jobs');
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };
}
// + JSON.parse(secureLocalStorage.getItem('recruiterStatusDetails')).company.domain.split('.')[0] + '
function getHelp(myAccount) {
  return () => {
    const apiEndpoint = `/api/v1/redirect_to_zendesk/?return_to=${
      !myAccount
        ? "https://clientservices.succeedsmart.com"
        : "http://support.succeedsmart.com/hc/en-us/requests/new"
    }/&email=${
      JSON.parse(secureLocalStorage.getItem("userDetails")).email
    }&name=${
      JSON.parse(secureLocalStorage.getItem("userDetails"))["custom:first_name"]
    }`;

    apiService
      .get(apiEndpoint)
      .then((response) => {
        if (response && (response.status === 200 || response.status === 201)) {
          window.open(
            decodeURIComponent(response.data.data.zendesk_url),
            "_blank"
          );
          // dispatch(setCompanyData(response.data));
        } else if (response.status !== 401) {
          toast.dark("Error");
          // history.push('/');
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };
}

function redirectToRespectivePage(deeplink) {
  const recruiterStatusDetails = JSON.parse(
    secureLocalStorage.getItem("recruiterStatusDetails")
  );
  switch (recruiterStatusDetails.onboarding_status) {
    case "signed_up":
      secureLocalStorage.setItem("onboardingStatus", "signedUp");
      history.push("/confirm-signup");
      break;
    case "otp_verified":
      secureLocalStorage.setItem("onboardingStatus", "otpVerified");
      history.push("/signup-success");
      break;
    case "company_added":
      secureLocalStorage.setItem("onboardingStatus", "companyAdded");
      history.push("/sign-contract");
      break;
    case "complete":
    case "Complete":
      secureLocalStorage.setItem("onboardingStatus", "complete");
      if (!recruiterStatusDetails.approval_status) {
        history.push("/application-status");
      } else if (recruiterStatusDetails.payment_status === false) {
        history.push("/payment");
      } else if (recruiterStatusDetails.is_disabled) {
        history.push("/no-access");
      } else {
        const recruiterId =
          JSON.parse(secureLocalStorage.getItem("recruiterId")) || null;
        const payload = {};
        if (recruiterId) {
          payload.user_id = recruiterId;
          payload[recruiterId] = "recruiter";
          if (
            !secureLocalStorage.getItem("g_u") &&
            window.location.pathname !== "/my-account"
          ) {
            store.dispatch(
              messagesActions.getCurrentUserGetStreamToken(payload)
            );
          }
          store.dispatch(sharedActions.getCalendlyLink(recruiterId));
        }
        if (window.location.pathname !== "/my-account") {
          if (deeplink) {
            history.push(deeplink);
          } else {
            history.push("/jobs/my-jobs");
          }
        }
      }
      break;
    case "contract_sign":
      if (!recruiterStatusDetails.approval_status) {
        history.push("/contract-incomplete");
      } else {
        const recruiterId =
          JSON.parse(secureLocalStorage.getItem("recruiterId")) || null;
        const payload = {};
        if (recruiterId) {
          payload.user_id = recruiterId;
          payload[recruiterId] = "recruiter";
          if (!secureLocalStorage.getItem("g_u")) {
            store.dispatch(
              messagesActions.getCurrentUserGetStreamToken(payload)
            );
          }
        }
        if (deeplink) {
          history.push(deeplink);
        } else {
          history.push("/dashboard");
        }
      }
      secureLocalStorage.setItem("onboardingStatus", "contractSigned");
      break;
    default:
      history.push("/no-access");
      break;
  }
}

function getCompanyData() {
  return (dispatch) => {
    const apiEndpoint = `/api/v1/clearbit_api/?email=${JSON.parse(
      secureLocalStorage.getItem("userId")
    )}`;
    apiService
      .get(apiEndpoint)
      .then((response) => {
        if (response && (response.status === 200 || response.status === 201)) {
          dispatch(setCompanyData(response.data));
        } else if (response.status !== 401) {
          toast.dark(
            "Unable to fetch company data. Please fill up all the details manually"
          );
          // history.push('/');
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };
}

function createCompany(payload) {
  return (dispatch) => {
    const apiEndpoint = "/api/v1/company/create_profile/";
    apiService
      .post(apiEndpoint, payload)
      .then((response) => {
        if (
          !response.data.error &&
          (response.status === 200 || response.status === 201)
        ) {
          //  dispatch(setAuthDetails(response.data));
          secureLocalStorage.setItem("onboardingStatus", "companyAdded");
          dispatch(setCompanyDetails(response.data));
          // toast.dark('Company Information Updated Successfully');
          history.push("/sign-contract");
        }
        showResponseToast(
          response,
          "Company information updated successfully",
          "Failure while updating company information"
        );
      })
      .catch((err) => {
        console.log(err);
      });
  };
}

function setRecruiter(payload) {
  return (dispatch) => {
    const apiEndpoint = "/api/v1/recruiter/create_profile/";
    apiService
      .post(apiEndpoint, payload)
      .then((response) => {
        if (response && (response.status === 200 || response.status === 201)) {
          secureLocalStorage.setItem(
            "recruiterId",
            JSON.stringify(response.data.recruiter_id)
          );
          dispatch(setRecruiterDetails(response.data));
          toast.dark(
            "Please check your registered email for verification code"
          );
          history.push("/confirm-signup");
          // history.push('/signup-success');
        } else if (response.status !== 401) {
          toast.dark("Something went haywire");
          // history.push('/');
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };
}

function updateRecruiter(payload) {
  const apiEndpoint = "/api/v2/recruiter/update_profile/";
  return apiService
    .put(apiEndpoint, payload)
    .then((r) =>
      showResponseToast(
        r,
        "Profile details updated successfully",
        "Failure while updating profile details"
      )
    );
}

function setRecruiterOnboardingStatus(recruiterId) {
  return (dispatch) => {
    const apiEndpoint = `/api/v1/recruiter/${recruiterId}/set_status/`;
    apiService
      .get(apiEndpoint)
      .then((response) => {
        if (response && (response.status === 200 || response.status === 201)) {
          dispatch(setOnboardingStatus());
        } else if (response.status !== 401) {
          toast.dark("Something went haywire");
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };
}

function getRecruiterOnboardingStatus(payload, deeplink) {
  return (dispatch) => {
    const apiEndpoint = "/api/v1/recruiter/get_status/";
    apiService
      .post(apiEndpoint, payload)
      .then((response) => {
        if (response && (response.status === 200 || response.status === 201)) {
          dispatch(setRecruiterStatusDetails(response.data, deeplink));
        } else if (response.status !== 401) {
          toast.dark("Something went haywire");
          // history.push('/');
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };
}

function setCompanyData(data) {
  return {
    type: "COMPANY_DATA",
    companyData: data,
  };
}

export function setCompanyDetails() {
  return {
    type: "COMPANY_CREATED",
    // companyAddedResponse: data
    // onboardingStatus: 'companyAdded'
  };
}

function setContractDetails(payload) {
  return (dispatch) => {
    const apiEndpoint = "/api/v1/docusign_signature/";
    apiService
      .post(apiEndpoint, payload)
      .then((response) => {
        if (response && (response.status === 200 || response.status === 201)) {
          dispatch(setContractData(response.data));
          secureLocalStorage.setItem("onboardingStatus", "contractSigned");
          if (response.data.docsign_url !== "") {
            toast.dark(
              "Redirecting you to DocuSign which is out of SucceedSmart"
            );
            window.open(response.data.docsign_url, "_self");
          } else if (
            response.data.docsign_url === "" &&
            response.data.envelope_id !== ""
          ) {
            history.push("/contract-signed");
            toast.dark("We've sent contract details to signer's email");
          }
        } else if (response.status !== 401) {
          toast.dark("Something went haywire");
          // history.push('/');
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };
}

function getAllConstantsData(data) {
  return {
    type: "GOT_ALL_CONSTANTS",
    constants: data,
  };
}

export function setAuthDetails() {
  return {
    type: "SIGNIN_SUCCESS",
    auth: secureLocalStorage.getItem("auth"),
    token: secureLocalStorage.getItem("token"),
    a_token: secureLocalStorage.getItem("a_token"),
    r_token: secureLocalStorage.getItem("r_token"),
    loggedIn: !!secureLocalStorage.getItem("auth"),
    userId: secureLocalStorage.getItem("userId"),
  };
}

export function setRecruiterDetails(details) {
  secureLocalStorage.setItem("recruiterDetails", JSON.stringify(details));
  return {
    type: "SET_RECRUITER_SUCCESS",
    recruiterDetails: details,
  };
}

export function setRecruiterStatusDetails(details, deeplink) {
  secureLocalStorage.setItem(
    "recruiterId",
    JSON.stringify(details.recruiter_id)
  );
  secureLocalStorage.setItem("recruiterStatusDetails", JSON.stringify(details));
  redirectToRespectivePage(deeplink);
  return {
    type: "GET_RECRUITER_STATUS",
    recruiterStatusDetails: details,
  };
}

export function setOnboardingStatus() {
  return {
    type: "ONBOARDING_STATUS_SET",
  };
}

export function setContractData(details) {
  return {
    type: "SET_CONTRACT_SUCCESS",
    contractDetails: details,
    // onboardingStatus: 'contractSigned'
  };
}

export function signoutRecruiter() {
  return {
    type: "SIGNOUT_SUCCESS",
    auth: false,
    token: null,
    loggedIn: false,
  };
}

export function getAuthDetails(auth) {
  return {
    type: "AUTH_DETAILS",
    auth,
    token: JSON.parse(secureLocalStorage.getItem("token")),
    loggedIn: !!auth,
    userId: JSON.parse(secureLocalStorage.getItem("userId")),
  };
}

export function loggingIn(auth) {
  return {
    type: "AUTH_DETAILS",
    loggedIn: !!auth,
  };
}

export function gettingRecruiterStatus(details) {
  return {
    type: "GET_RECRUITER_STATUS",
    gotRecruiterStatus: details,
  };
}

export function updateRecruiterStatus(details) {
  return {
    type: "GET_RECRUITER_STATUS",
    recruiterStatusDetails: details,
  };
}

export function deepLink(link) {
  return {
    type: "DEEP_LINK",
    deepLink: link,
  };
}

export function WelcomePopup(value) {
  return {
    type: "WELCOME_POPUP",
    welcomePopup: value,
  };
}
