import React from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import {
  Dialog,
  DialogContentText,
  Button,
  Typography,
  AppBar,
  Toolbar,
  Tabs,
  Tab,
  Menu,
  MenuItem,
} from "@material-ui/core";
import { authActions, loading, setTitle } from "../../actions";
import { connect } from "react-redux";
import MuiDialogTitle from "@material-ui/core/DialogTitle";
import MuiDialogContent from "@material-ui/core/DialogContent";
import MuiDialogActions from "@material-ui/core/DialogActions";
import ExpandMore from "@material-ui/icons/ExpandMore";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import { ReactComponent as Logo } from "../../assets/logo.svg";
import cardHeaderBg from "../../assets/lite_profile_bg.png";
import classNames from "classnames";
import ExitToAppIcon from "@material-ui/icons/ExitToApp";
import { history } from "../../helpers";
import { Auth } from "aws-amplify";
import { StreamChat } from "stream-chat";
import { sharedActions } from "../../actions";

const drawerWidth = 300;
const drawerHeight = "100vh";

const styles = (theme) => ({
  root: { display: "flex" },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: "nowrap",
  },
  drawerOpen: {
    width: drawerWidth,
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
    backgroundColor: "black",
    color: "white",
    overflow: "visible",
  },
  nested: {
    paddingLeft: theme.spacing(14),
    borderTop: "1px solid #303030",
  },
  drawerClose: {
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    overflow: "visible",
    width: theme.spacing(7) + 1,
    [theme.breakpoints.up("sm")]: {
      width: theme.spacing(9) + 1,
    },
    backgroundColor: "black",
    color: "white",
  },
  pointer: {
    cursor: "pointer",
  },
  responsive: {
    maxWidth: "100%",
    height: "auto",
    width: "auto",
    alignSelf: "center !important",
    minWidth: "fit-content !important",
  },
  customResponsive: {
    maxWidth: "100%",
    height: "auto",
    width: "auto",
    alignSelf: "center !important",
    minWidth: "fit-content !important",
  },
  customDivider: {
    border: "none",
    height: "1px",
    margin: "0",
    flexShrink: "0",
    backgroundColor: "#FBC112 !important",
  },
  appFrame: {
    zIndex: 1,
    overflow: "hidden",
    position: "relative",
    display: "flex",
    width: "100%",
  },
  drawerPaper: {
    position: "relative",
    width: drawerWidth,
    minHeight: drawerHeight,
    backgroundColor: "black",
    color: "white",
    borderRight: "none",
  },
  customTopSmall: {
    marginTop: "40%",
  },
  customBottomMedium: {
    marginBottom: "40%",
  },
  marginLeftSmall: {
    marginLeft: "8%",
  },
  sideBar: {
    paddingLeft: "15%",
  },
  customSideBar: {
    paddingLeft: "30%",
  },
  customFontSize: { fontSize: "1.15rem !important" },
  customBadge: {
    borderRadius: "10px",
    fontSize: "x-small",
    fontWeight: "bold",
  },
  customDialogHeader: {
    maxHeight: "38%",
    height: "38%",
    color: "#000",
    backgroundSize: "cover",
    backgroundImage: `url(${cardHeaderBg})`,
    background: "round",
  },
  largeFont: {
    fontSize: "large",
  },
  closeButton: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.default.main,
    padding: "0px",
  },
  // navCustomContainer: {
  //   display: 'flex',
  //   justifyContent: 'space-between',
  //   // width: 'inherit',
  //   /* height: calc(100% - 125px); */
  //   overflow: 'auto',
  //   height: 'calc(100% - 90px)',
  //   flexDirection: 'column',
  //   '&::-webkit-scrollbar': {
  //     display: 'none'
  //   },
  //   [theme.breakpoints.down('md')]: {
  //     height: 'calc(100% - 80px)',
  //   },
  //   [theme.breakpoints.down('sm')]: {
  //     height: 'calc(100% - 80px)',
  //   }
  // }
  navCustomContainer: {
    display: "flex",
    justifyContent: "space-between",
    // width: 'inherit',
    // /* height: calc(100% - 125px); */
    overflow: "auto",
    height: "calc(100% - 10px)", //'calc(100% - 90px)',
    flexDirection: "column",
    "&::-webkit-scrollbar": {
      display: "none",
    },
    [theme.breakpoints.down("md")]: {
      height: "calc(100% - 10px)",
    },
    [theme.breakpoints.down("sm")]: {
      height: "calc(100% - 10px)",
    },
  },
  iconLabelWrapper2: {
    flexDirection: "row-reverse",
  },
  labelIcon: {
    minHeight: "52px",
  },
  toolbar: theme.mixins.toolbar,
  customToolbar: {
    padding: "2.5%",
    // left: '2%'
  },
  toolbarContent: {
    flex: "auto",
    padding: "2%",
    color: "black",
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
  },
  smallPaddingLeft: {
    paddingLeft: "2% !important",
  },
  // responsive: {
  //     maxWidth: '100% !important',
  //     height: 'auto !important',
  //     width: 'auto !important',
  //     alignSelf: 'center !important'
  // },
  customLink: {
    color: "#048475 !important",
    cursor: "pointer",
    "&:hover": {
      textDecoration: "underline",
    },
  },
  logoutBtn: {
    display: "flex",
    alignItems: "center",
    textTransform: "none",
    minWidth: 72,
    opacity: "0.7",
    fontWeight: theme.typography.fontWeightRegular,
    marginRight: theme.spacing(4),
    marginLeft: theme.spacing(4),
    fontFamily: "Public Sans",
    color: "black",
    cursor: "pointer",
    "&:hover": {
      color: "#000",
      opacity: 1,
      // fontWeight: '800'
    },
    "&$selected": {
      color: "#FBC112",
      fontWeight: theme.typography.fontWeightBold,
    },
    "&:focus": {
      color: theme.palette.secondary,
    },
  },
  antJobs: {
    color: "#000000 !important",
    minHeight: "58px !important",
  },
});

const AntTabs = withStyles(() => ({
  // root: {
  //   borderBottom: '1px solid #e8e8e8',
  // },
  indicator: {
    backgroundColor: "#FBC112",
    background: "#FBC112",
    height: "4px",
    borderRadius: "5px 5px 0px 0px",
  },
}))(Tabs);

const AntTab = withStyles((theme) => ({
  root: {
    textTransform: "none",
    minWidth: 72,
    fontWeight: theme.typography.fontWeightRegular,
    marginRight: theme.spacing(4),
    marginLeft: theme.spacing(4),
    fontFamily: "Public Sans",
    "&:hover": {
      color: theme.palette.secondary,
      opacity: 1,
    },
    "&$selected": {
      color: "#FBC112",
      fontWeight: theme.typography.fontWeightBold,
    },
    "&:focus": {
      color: theme.palette.secondary,
    },
  },
  selected: {},
}))((props) => <Tab disableRipple {...props} />);

const DialogTitle = withStyles(styles)((props) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle
      disableTypography
      {...other}
      className={classes.customDialogHeader}
      style={{ padding: "3%" }}
    >
      {/* className={classes.root} */}
      <Typography variant="h5" align="center">
        {children}
      </Typography>
      {onClose ? (
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={onClose}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const DialogContent = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
    paddingLeft: theme.spacing(4),
    paddingRight: theme.spacing(4),
  },
}))(MuiDialogContent);

const DialogActions = withStyles((theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
    backgroundColor: "#fff",
    justifyContent: "center !important",
  },
}))(MuiDialogActions);

class Header extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      anchor: "left",
      selectedIndex: this.props.shared?.selectedNavbarIndex || 0,
      openConfirmSigOutPopup: false,
      open: false,
      isDefaultSubMenu: true,
      errors: {
        cognito: null,
      },

      activeTab: 0,
      activeTab2: null,
      anchorEl: null,
    };
  }

  updateSelectedIndexByUrlState = (path) => {
    const { dispatch } = this.props;
    if (path === "/dashboard") {
      this.setState({ activeTab: 0, activeTab2: null, selectedIndex: 0 });
    } else if (path.match("^/jobs/my-jobs.*$") || path === "/jobs/my-jobs") {
      this.setState(
        { activeTab: 0, activeTab2: null, selectedIndex: 1 },
        () => {
          dispatch(
            sharedActions.setSelectedNavbarIndex(this.state.selectedIndex)
          );
        }
      );
    } else if (
      path.match("^/jobs/shared-jobs.*$") ||
      path === "/jobs/shared-jobs"
    ) {
      this.setState(
        { activeTab: 0, activeTab2: null, selectedIndex: 11 },
        () => {
          dispatch(
            sharedActions.setSelectedNavbarIndex(this.state.selectedIndex)
          );
        }
      );
    } else if (path.match("^/members.*$")) {
      this.setState({ selectedIndex: this.props.shared?.selectedNavbarIndex }); //this.setState({ selectedIndex: 2 });
    } else if (path.match("^/messages.*$") || path === "/messages") {
      this.setState(
        { activeTab: 1, activeTab2: null, selectedIndex: 2 },
        () => {
          dispatch(
            sharedActions.setSelectedNavbarIndex(this.state.selectedIndex)
          );
        }
      );
    } else if (path.match("^/my-account.*$")) {
      this.setState(
        { activeTab: null, activeTab2: 0, selectedIndex: 4 },
        () => {
          dispatch(
            sharedActions.setSelectedNavbarIndex(this.state.selectedIndex)
          );
        }
      );
    }
  };

  componentDidMount() {
    history.listen((location) => {
      this.updateSelectedIndexByUrlState(location.pathname);
    });

    if (window.location.pathname) {
      this.updateSelectedIndexByUrlState(window.location.pathname);
    }
  }

  confirmSignout = () => {
    this.setState({ openConfirmSigOutPopup: true });
  };

  handleSignOutClose = () => {
    this.setState({ openConfirmSigOutPopup: false });
  };

  signout = async (event) => {
    event.preventDefault();
    const { dispatch } = this.props;
    dispatch(loading(true));
    try {
      await Auth.signOut();
      dispatch(authActions.signout());
      const { getStreamUserTokenData } = this.props.messages;
      if (getStreamUserTokenData) {
        const client = StreamChat.getInstance(
          getStreamUserTokenData.data.apiKey,
          { timeout: 6000 }
        );
        client.disconnectUser();
      }
      dispatch(loading(false));
      this.handleClose();
    } catch (error) {
      let err = null;
      !error.message ? (err = { message: error }) : (err = error);
      console.log("error signing out:", err);
      this.setState({
        errors: {
          ...this.state.errors,
          cognito: error,
        },
      });
      dispatch(loading(false));
    }
  };

  goToHome = () => {
    history.push("/");
    this.setState({ selectedIndex: 0 });
  };

  handleTabsAndRedirection = (tabValue, pageValue, dispatch) =>
    new Promise((resolve, reject) => {
      dispatch(sharedActions.jobsPageTabChange(tabValue));
      dispatch(sharedActions.jobsPagePaginationPageChange(pageValue));
      dispatch(sharedActions.closedJobsPage(pageValue));
      resolve();
      reject();
    });

  handleActiveTab = (index) => {
    const { dispatch } = this.props;
    switch (index) {
      // case 0:
      //     this.setState({ activeTab: index, activeTab2: null, selectedIndex: 0 }, () => {
      //         history.push('/dashboard');
      //     });
      //     break;
      case 0:
        this.setState(
          {
            activeTab:
              this.props.shared.selectedNavbarIndex === 1 ||
              this.props.shared.selectedNavbarIndex === 11
                ? index
                : this.props.shared.selectedNavbarIndex,
            activeTab2: null,
          },
          () => {
            dispatch(setTitle(`Jobs`));
          }
        );
        break;
      case 1:
        this.setState(
          { activeTab: index, activeTab2: null, selectedIndex: 2 },
          () => {
            history.push("/messages");
          }
        );
        break;
      default:
        break;
    }
  };

  handleActiveTabSecondary = (index) => {
    switch (index) {
      // case 0:
      //     this.setState({ activeTab: null, activeTab2: index }, () => {
      //         history.push('/dashboard');
      //     });
      //     break;
      case 0:
        this.setState(
          { activeTab: null, activeTab2: index, selectedIndex: 5 },
          () => {
            history.push("/my-account");
          }
        );
        break;
      default:
        break;
    }
  };

  handleListItemClick = (index) => {
    const { dispatch } = this.props;
    switch (index) {
      case 0:
        history.push("/dashboard");
        break;
      case 1:
        dispatch(setTitle(`Jobs`));
        dispatch(
          sharedActions.navBarMenuToggle(!this.props?.shared?.menuToggle)
        );
        break;
      case 2:
        history.push("/members");
        break;
      case 3:
        history.push("/messages");
        break;
      case 4:
        history.push("/my-account");
        break;
      case 10:
        this.setState({ isDefaultSubMenu: true });
        if (window.location.pathname !== "/jobs/my-jobs") {
          this.handleTabsAndRedirection(0, 1, dispatch).then(() => {
            history.push("/jobs/my-jobs");
          });
        }
        break;
      case 11:
        this.setState({ isDefaultSubMenu: false });
        if (window.location.pathname !== "/jobs/shared-jobs") {
          this.handleTabsAndRedirection(0, 1, dispatch).then(() => {
            history.push("/jobs/shared-jobs");
          });
        }
        break;
      default:
        break;
    }
  };

  handleSubMenuClick = (flag) => {
    const { dispatch } = this.props;
    dispatch(sharedActions.navBarMenuToggle(flag));
  };

  handleClick = (event) => {
    this.setState((prevState) => ({ open: !prevState.open }));
    this.setState({ anchorEl: event.currentTarget });
  };

  handleClose = () => {
    this.setState({ anchorEl: null });
  };

  onSelectJobType = (index) => {
    const { dispatch } = this.props;
    dispatch(sharedActions.functionFilter(null));
    this.setState(
      { activeTab: 0, activeTab2: null, selectedIndex: index, anchorEl: null },
      () => {
        dispatch(setTitle(`Jobs`));
        dispatch(
          sharedActions.navBarMenuToggle(!this.props?.shared?.menuToggle)
        );
        if (this.state.selectedIndex === 1) {
          if (window.location.pathname !== "/jobs/my-jobs") {
            this.handleTabsAndRedirection(0, 1, dispatch).then(() => {
              history.push("/jobs/my-jobs");
            });
          }
        } else if (this.state.selectedIndex === 11) {
          if (window.location.pathname !== "/jobs/shared-jobs") {
            this.handleTabsAndRedirection(0, 1, dispatch).then(() => {
              history.push("/jobs/shared-jobs");
            });
          }
        }
      }
    );
  };

  render() {
    const { classes } = this.props;

    return (
      <>
        <AppBar
          position="fixed"
          style={{ backgroundColor: "#F8F6EF", height: "60px" }}
        >
          <Toolbar className={classes.customToolbar}>
            <Logo
              width="200"
              height="36"
              className={classNames(classes.responsive, classes.pointer)}
              onClick={this.goToHome}
            />

            <AntTabs
              style={{ flexGrow: 1, marginLeft: "20px", color: "#000000" }}
              value={this.state.activeTab}
              onChange={(event, value) => this.handleActiveTab(value)}
              aria-label="disabled tabs example"
            >
              {/* <AntTab label="Dashboard" /> */}
              <AntTab
                className={classes.antJobs}
                onClick={this.handleClick}
                classes={{
                  wrapper: classes.iconLabelWrapper2,
                  labelIcon: classes.labelIcon,
                }}
                aria-haspopup="true"
                label="Jobs"
                icon={<ExpandMore style={{ margin: 0 }} />}
              />

              <AntTab label="Messages" />
            </AntTabs>
            <Menu
              style={{ marginTop: "57px", color: "black" }}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "left",
              }}
              transformOrigin={{
                vertical: "top",
                horizontal: "left",
              }}
              anchorPosition={{ left: 500, top: 1000 }}
              id="simple-menu"
              anchorEl={this.state.anchorEl}
              keepMounted
              open={Boolean(this.state.anchorEl)}
              onClose={this.handleClose}
            >
              <MenuItem
                selected={this.state.selectedIndex === 1}
                onClick={() => this.onSelectJobType(1)}
              >
                My Jobs
              </MenuItem>
              <MenuItem
                selected={this.state.selectedIndex === 11}
                onClick={() => this.onSelectJobType(11)}
              >
                Shared Jobs
              </MenuItem>
            </Menu>
            <AntTabs
              style={{ flexGrow: 0, color: "#000000" }}
              value={this.state.activeTab2}
              onChange={(event, value) => this.handleActiveTabSecondary(value)}
              aria-label="disabled tabs example"
            >
              {/* <AntTab label="Help Center" /> */}
              <AntTab label="My Account" />
              <Typography
                variant="button"
                className={classes.logoutBtn}
                onClick={() => {
                  this.confirmSignout();
                }}
              >
                <ExitToAppIcon />
                &nbsp; Sign Out
              </Typography>
            </AntTabs>
          </Toolbar>
        </AppBar>

        <Dialog
          open={this.state.openConfirmSigOutPopup}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
          fullWidth
          disableBackdropClick
          disableEscapeKeyDown
        >
          <DialogTitle
            id="alert-dialog-title"
            onClose={this.handleSignOutClose}
          >
            Confirm Sign Out
          </DialogTitle>
          <DialogContent>
            <DialogContentText
              id="alert-dialog-description"
              className={classNames(classes.largeFont)}
            >
              <Typography align="center">
                Are you sure you want to sign out?
              </Typography>
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button
              size="large"
              disableElevation
              onClick={this.handleSignOutClose}
              style={{ background: "#D7D9DB", color: "#000" }}
              variant="contained"
              color="primary"
            >
              Cancel
            </Button>
            <Button
              size="large"
              disableElevation
              onClick={this.signout}
              variant="contained"
              color="primary"
              autoFocus
            >
              Confirm
            </Button>
          </DialogActions>
        </Dialog>
      </>
    );
  }
}

Header.propTypes = {
  classes: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => {
  return state;
};

export default connect(mapStateToProps)(
  withStyles(styles, { withTheme: true })(Header)
);
