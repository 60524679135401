import { toast } from "react-toastify";
import { apiService } from "../services";
import { showResponseToast } from "../helpers/toastHelpers";
import secureLocalStorage from "react-secure-storage";
import { TOASTER_MESSAGES } from "../constants/constants";

export const membersActions = {
  getJobTitles,
  getBrowseData,
  getPendingData,
  getMatchedData,
  getArchivedData,
  likeCandidateRecommendation,
  dislikeCandidateRecommendation,
  getFullProfile,
  viewHistory,
  shareProfileLink,
  archiveFromFullProfile,
  postSchedulingEmailToCoordinator,
  postSchedulingEmailToCandidate,
  changePassword,
  shareProfileVisible,
  downloadPDF,
};

function downloadPDF(recid, name) {
  let apiEndpoint = `/api/v2/admin/${recid}/generate_pdf/`;
  return apiService
    .getBlob(apiEndpoint)
    .then((res) => {
      const blob = new Blob([res.data], {
        type: "application/pdf;charset=utf-8;",
      });
      if (navigator.msSaveBlob) {
        navigator.msSaveBlob(blob, name);
      } else {
        let link = document.createElement("a");
        if (link.download === "") {
          let url = URL.createObjectURL(blob);
          link.setAttribute("href", url);
          link.setAttribute("download", name);
          link.style.visibility = "hidden";
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
        }
      }
    })
    .catch((err) => {
      console.log(err);
    });
}
async function changePassword(recruiterCompanyId, payload) {
  const apiEndpoint = `/api/v2/admin/recruiter/${recruiterCompanyId}/change_password/`;
  return apiService.put(apiEndpoint, payload).catch((err) => {
    console.log(err);
  });
}

function postSchedulingEmailToCoordinator(job_recommendation_id, payload) {
  return (dispatch) => {
    const apiEndpoint = `/api/v3/recruiter/${job_recommendation_id}/schedule/`;
    apiService
      .post(apiEndpoint, payload)
      .then((response) => {
        if (response && (response.status === 200 || response.status === 201)) {
          dispatch(getSendSchedulingLinkEmailPreview(response.data.data));
        }
        if (response.data.message !== "Email Preview") {
          showResponseToast(
            response,
            "Successfully posted interview link",
            "Failure while posting interview link"
          );
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };
}

function postSchedulingEmailToCandidate(job_recommendation_id, payload) {
  return (dispatch) => {
    const apiEndpoint = `/api/v3/recruiter/${job_recommendation_id}/email_to_candidate/`;
    apiService
      .post(apiEndpoint, payload)
      .then((response) => {
        if (response && (response.status === 200 || response.status === 201)) {
          dispatch(getSendSchedulingLinkEmailPreview(response.data.data));
        }
        if (response.data.message !== "Email Preview") {
          showResponseToast(
            response,
            "Successfully posted interview link",
            "Failure while posting interview link"
          );
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };
}

function getJobTitles(input) {
  return (dispatch) => {
    const apiEndpoint = `/api/v1/search_index/?title=${input}`;
    apiService
      .get(apiEndpoint)
      .then((response) => {
        if (response && (response.status === 200 || response.status === 201)) {
          dispatch(getJobTitlesData(response.data.data));
        } else if (response.status !== 401) {
          toast.dark("Something went haywire");
          // history.push('/jobs/my-jobs');
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };
}

function getBrowseData(payload) {
  return (dispatch) => {
    const apiEndpoint = `/api/v1/job_recommendtion/${JSON.parse(
      secureLocalStorage.getItem("recruiterId")
    )}/recruiter_browse_candidate/`;
    apiService
      .post(apiEndpoint, payload)
      .then((response) => {
        if (response && (response.status === 200 || response.status === 201)) {
          dispatch(getBrowseDetails(response.data));
        } else if (response.status !== 401) {
          toast.dark("Something went haywire");
          // history.push('/members');
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };
}

function getPendingData(recruiterId) {
  return (dispatch) => {
    const apiEndpoint = `/api/v1/job_recommendtion/${recruiterId}/get_recruiter_browse_candidate/`;
    apiService
      .post(apiEndpoint, { flag: "Pending" })
      .then((response) => {
        if (response && (response.status === 200 || response.status === 201)) {
          dispatch(getPendingDetails(response.data));
        } else if (response.status !== 401) {
          toast.dark("Something went haywire");
          // history.push('/jobs/my-jobs');
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };
}

function getMatchedData(recruiterId) {
  return (dispatch) => {
    const apiEndpoint = `/api/v1/job_recommendtion/${recruiterId}/get_recruiter_browse_candidate/`;
    apiService
      .post(apiEndpoint, { flag: "Matched" })
      .then((response) => {
        if (response && (response.status === 200 || response.status === 201)) {
          dispatch(getMatchedDetails(response.data));
        } else if (response.status !== 401) {
          toast.dark("Something went haywire");
          // history.push('/jobs/my-jobs');
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };
}

function getArchivedData(recruiterId) {
  return (dispatch) => {
    const apiEndpoint = `/api/v1/job_recommendtion/${recruiterId}/get_recruiter_browse_candidate/`;
    apiService
      .post(apiEndpoint, { flag: "Archive" })
      .then((response) => {
        if (response && (response.status === 200 || response.status === 201)) {
          dispatch(getArchivedDetails(response.data));
        } else if (response.status !== 401) {
          toast.dark("Something went haywire");
          // history.push('/jobs/my-jobs');
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };
}

function likeCandidateRecommendation(recruiterId, payload) {
  return (dispatch) => {
    const apiEndpoint = `/api/v1/job_recommendtion/${recruiterId}/get_recruiter_browse_candidate/`;
    apiService
      .post(apiEndpoint, payload)
      .then((response) => {
        if (response && (response.status === 200 || response.status === 201)) {
          dispatch(getLikeCandidateRecommendationData(response.data));
          const payload = {
            industry: secureLocalStorage.getItem("industry")
              ? JSON.parse(secureLocalStorage.getItem("industry"))
              : "",
            value: "",
            title: secureLocalStorage.getItem("jobTitle")
              ? secureLocalStorage.getItem("jobTitle")
              : "",
            location: {
              lat: secureLocalStorage.getItem("locationDetails")
                ? JSON.parse(secureLocalStorage.getItem("locationDetails")).lat
                : 0,
              lng: secureLocalStorage.getItem("locationDetails")
                ? JSON.parse(secureLocalStorage.getItem("locationDetails")).lng
                : 0,
            },
          };
          dispatch(getBrowseData(payload));
          dispatch(
            getPendingData(
              JSON.parse(secureLocalStorage.getItem("recruiterId"))
            )
          );
        }
        showResponseToast(
          response,
          "Candidate liked successfully",
          "Failure while liking candidate"
        );
      })
      .catch((err) => {
        console.log(err);
      });
  };
}

function dislikeCandidateRecommendation(recruiterId, payload) {
  return (dispatch) => {
    const apiEndpoint = `/api/v1/job_recommendtion/${recruiterId}/get_recruiter_browse_candidate/`;
    apiService
      .post(apiEndpoint, payload)
      .then((response) => {
        if (response && (response.status === 200 || response.status === 201)) {
          dispatch(getDislikeCandidateRecommendationData(response.data));
          const payload = {
            industry: secureLocalStorage.getItem("industry")
              ? JSON.parse(secureLocalStorage.getItem("industry")).value
              : "",
            title: secureLocalStorage.getItem("jobTitle")
              ? secureLocalStorage.getItem("jobTitle")
              : "",
            location: {
              lat: secureLocalStorage.getItem("locationDetails")
                ? JSON.parse(secureLocalStorage.getItem("locationDetails")).lat
                : 0,
              lng: secureLocalStorage.getItem("locationDetails")
                ? JSON.parse(secureLocalStorage.getItem("locationDetails")).lng
                : 0,
            },
          };
          dispatch(getBrowseData(payload));
          dispatch(
            getArchivedData(
              JSON.parse(secureLocalStorage.getItem("recruiterId"))
            )
          );
        }
        showResponseToast(
          response,
          "Candidate disliked successfully",
          "Failure while disliking candidate"
        );
      })
      .catch((err) => {
        console.log(err);
      });
  };
}

export function archiveFromFullProfile(recommendationId, payload) {
  return (dispatch) => {
    const apiEndpoint = `/api/v1/job_recommendtion/${recommendationId}/archive_candidate_opportunity/`;
    apiService
      .post(apiEndpoint, payload)
      .then((response) => {
        if (response && (response.status === 200 || response.status === 201)) {
          dispatch(getDislikeCandidateRecommendationData(response.data));
        }
        showResponseToast(
          response,
          "Removed from queue successfully",
          "Failure while removing from queue"
        );
      })
      .catch((err) => {
        console.log(err);
      });
  };
}

export async function removeFormQueue(recommendationId, payload, cardValue) {
  const apiEndpointNew = `/api/v2/recruiter/${recommendationId}/archive_recommendation/`;
  const apiEndpoint = `/api/v1/job_recommendtion/${recommendationId}/archive_candidate_opportunity/`;
  await apiService
    .post(cardValue === "3" ? apiEndpointNew : apiEndpoint, payload)
    .then((response) => {
      showResponseToast(
        response,
        TOASTER_MESSAGES.REMOVE_FROM_QUEUE.SUCCESS,
        TOASTER_MESSAGES.REMOVE_FROM_QUEUE.FAIL
      );
    })
    .catch((err) => {
      console.log(err);
    });
}

function getFullProfile(
  candidateId,
  recommendationId,
  isMatchedArchived,
  jobStatus
) {
  return (dispatch) => {
    const apiEndpoint = `/api/v1/job_recommendtion/${candidateId}/candidate_profile/?recommendation_id=${recommendationId}`;
    apiService
      .get(apiEndpoint)
      .then((response) => {
        if (response && (response.status === 200 || response.status === 201)) {
          dispatch(
            getFullProfileData(
              response.data,
              candidateId,
              recommendationId,
              isMatchedArchived,
              jobStatus
            )
          );
        } else if (response.status !== 401) {
          toast.dark("Something went haywire");
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };
}

export function getSharedFullProfile(id, password) {
  return (dispatch) => {
    const apiEndpoint = `/api/v2/candidate/${id}/share_profile/`;
    apiService.shareProfileApiGet(apiEndpoint, password).then((response) => {
      if (response && (response.status === 200 || response.status === 201)) {
        dispatch(getSharedProfile(response.data.data));
        dispatch(shareProfileVisible(false));
      }
    });
  };
}

export function viewHistory(recommendationId) {
  return (dispatch) => {
    const apiEndpoint = `/api/v1/job_recommendtion/${recommendationId}/candidate_status_info/`;
    apiService
      .get(apiEndpoint)
      .then((response) => {
        if (response && (response.status === 200 || response.status === 201)) {
          dispatch(getHistoryData(response.data));
        } else if (response.status !== 401) {
          toast.dark("Something went haywire");
          // history.push('/members');
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };
}

function shareProfileLink(payload, recruiterId) {
  return (dispatch) => {
    const apiEndpoint = `/api/v1/recruiter/${recruiterId}/send_profile_link/`;
    apiService
      .post(apiEndpoint, payload)
      .then((response) => {
        if (response && (response.status === 200 || response.status === 201)) {
          dispatch(getShareProfileLinkData(response.data));
        }
        showResponseToast(
          response,
          "Profile link shared successfully",
          "Failure while sharing profile link"
        );
      })
      .catch((err) => {
        console.log(err);
      });
  };
}

function getSharedProfile(data) {
  return {
    type: "SHARED_PROFILE_DATA",
    sharedProfileData: data,
  };
}

function shareProfileVisible(value) {
  return {
    type: "SHARED_PROFILE_VISIBLE",
    shareProfileVisible: value,
  };
}

function getJobTitlesData(data) {
  return {
    type: "MEMBERS_JOB_TITLE_DATA",
    jobTitles: data,
  };
}

function getBrowseDetails(data) {
  return {
    type: "GOT_BROWSED_DATA",
    browsed: data,
  };
}

function getPendingDetails(data) {
  return {
    type: "GOT_PENDING_BROWSE_DATA",
    pending: data,
  };
}

function getMatchedDetails(data) {
  return {
    type: "GOT_MATCHED_BROWSE_DATA",
    matched: data,
  };
}

function getArchivedDetails(data) {
  return {
    type: "GOT_ARCHIVED_BROWSE_DATA",
    archived: data,
  };
}

function getLikeCandidateRecommendationData(data) {
  return {
    type: "LIKE_BROWSE_RESPONSE",
    likeCandidateResponse: data,
  };
}

function getDislikeCandidateRecommendationData(data) {
  return {
    type: "DISLIKE_BROWSE_RESPONSE",
    dislikeCandidateResponse: data,
  };
}

function getSendSchedulingLinkEmailPreview(data) {
  return {
    type: "SEND_SCHEDULING_LINK_PREVIEW",
    sendSchedulingLinkEmailPreview: data,
  };
}

function getFullProfileData(
  data,
  candidateId,
  recommendationId,
  isMatchedArchived,
  jobStatus
) {
  return {
    type: "FULL_PROFILE_DATA",
    fullProfileData: data,
    fullProfileRecommendationId: recommendationId,
    fullProfileCandidateId: candidateId,
    isMatchedArchived,
    jobStatus,
  };
}

function getHistoryData(data) {
  return {
    type: "HISTORY_DATA",
    historyData: data,
  };
}

function getShareProfileLinkData(data) {
  return {
    type: "SHARE_PROFILE_LINK_DATA",
    shareProfileLinkData: data,
  };
}
