import React from "react";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import "./index.css";
import { Amplify } from "aws-amplify";
import {
  MuiThemeProvider,
  createTheme,
  responsiveFontSizes,
} from "@material-ui/core/styles";
import { grey } from "@material-ui/core/colors";
import createBreakpoints from "@material-ui/core/styles/createBreakpoints";
import App from "./App";
import config from "./config/config";
import reportWebVitals from "./reportWebVitals";
import store from "./store/store";

const breakpoints = createBreakpoints({});

let themeX = createTheme({
  palette: {
    primary: {
      main: grey[700],
    },
    secondary: {
      main: grey[500],
    },
    basic: {
      main: grey[300],
    },
    error: {
      main: "rgb(220, 0, 78)",
    },
    warning: {
      main: "#ffb74d",
    },
    info: {
      main: "#64b5f6",
    },
    success: {
      main: "#81c784",
    },
    default: {
      main: "#303030",
    },
    core: {
      main: "#FBC112",
    },
    type: "light",
  },
  typography: {
    fontFamily: "Public Sans",
    // fontWeightRegular: 900
  },
  overrides: {
    MuiButton: {
      root: {
        color: "rgba(0, 0, 0, 0.87)",
        padding: "6px 8px",
        fontSize: "0.875rem",
        minWidth: "64px",
        boxSizing: "border-box",
        transition:
          "background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,border 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",
        fontFamily: "Public Sans",
        fontWeight: "800 !important",
        lineHeight: 1.75,
        borderRadius: "4px !important",
        letterSpacing: "0.02857em",
        textTransform: "none",
      },
      containedSizeLarge: {
        padding: "14px 22px",
        [breakpoints.down("md")]: {
          padding: "8px 12px",
          minWidth: "98px",
        },
        [breakpoints.down("sm")]: {
          padding: "8px 8px",
          minWidth: "95px",
        },
        [breakpoints.down("xs")]: {
          padding: "8px 8px",
          minWidth: "80px",
        },
        minWidth: "8em",
      },
      containedPrimary: {
        "&:hover": {
          backgroundColor: "#FBC112 !important",
          color: "#000",
          // backgroundColor: '#000000 !important',
          // color: '#FFFFFF'
        },
        backgroundColor: "#000000",
      },
      contained: {
        "&.Mui-disabled:hover": {
          backgroundColor: "rgb(0,0,0,0.12) !important",
          color: "rgb(0,0,0,0.26) !important",
        },
        "&:hover": {
          backgroundColor: "#FBC112",
          color: "#000",
        },
      },
    },
    MuiFab: {
      root: {
        [breakpoints.between("xs", "md")]: {
          width: "35px !important",
          height: "35px !important",
        },
      },
    },
    MuiInputLabel: {
      root: {
        color: "rgba(0, 0, 0, 0.54)",
      },
      formControl: {
        color: "rgba(0, 0, 0, 0.54)",
      },
      animated: {
        color: "rgba(0, 0, 0, 0.54)",
      },
      // shrink: {
      //   color: 'rgba(0, 0, 0, 0.54)'
      // },
      asterisk: {
        color: "#EE0E0E",
      },
    },
    MuiDialog: {
      paper: {
        borderRadius: "5px !important",
      },
    },
    MuiPaper: {
      rounded: {
        borderRadius: "5px !important",
      },
    },
    MuiInputAdornment: {
      positionEnd: {
        padding: "0.5%",
        marginLeft: "-54px",
      },
    },
    MuiOutlinedInput: {
      root: {
        border: "none",
        "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
          // borderColor: '#FBC112 !important',
          // borderColor: '#000 !important',
          borderWidth: "1px",
        },
      },
      notchedOutline: {
        // border: 'none',
        // background: 'rgba(0, 0, 0, 0.05)'
        // boxShadow: '0px 1px 1px 0px rgb(0 0 0 / 0%), 0px 1px 0px 0px rgb(0 0 0 / 0%), 0px 0px 1px 1px rgb(0 0 0 / 12%)'
      },
      adornedEnd: {
        // paddingRight: '10px !important'
        paddingRight: "0px !important",
      },
    },
    MuiInputBase: {
      root: {
        "&.Mui-error": {
          borderBottom: "2px solid rgb(220, 0, 78) !important",
        },
        borderBottom: "1px solid #000000",
      },
      // input: {
      //   borderBottom: '2px solid #FBC112',
      // }
    },
    // MuiDialogActions:{
    //   root: {
    //     backgroundColor: '#000000',
    //     justifyContent: 'center'
    //   }
    // },
    MuiBadge: {
      badge: {
        borderRadius: "3px",
        padding: "0 15px",
      },
      anchorOriginTopRightRectangle: {
        top: "0",
        right: "0",
        // transform: 'scale(1) translate(150%, 28%)',
        transformOrigin: "100% 0%",
      },
    },
    MuiTableCell: {
      root: {
        display: "table-cell",
        // padding: '0 !important',
        // padding: ' 0 0  10px 4px !important',
        // paddingBottom: '0 !important',
        fontSize: "0.875rem",
        textAlign: "left",
        fontFamily: "Public Sans",
        fontWeight: 400,
        lineHeight: 1.43,
        borderBottom: "none !important",
        verticalAlign: "inherit",
        wordBreak: "break-word",
      },
    },
    MuiListItem: {
      root: {
        "&.Mui-selected": {
          backgroundColor: "#FBC112",
          color: "#000 !important",
          // borderTop: '1px solid #303030'
        },
        "&.Mui-selected:hover": {
          backgroundColor: "#FBC112",
          color: "#000 !important",
          // borderTop: '1px solid #303030'
        },
      },
      button: {
        "&:hover": {
          backgroundColor: "#FBC112 !important",
          color: "#000",
          // borderTop: '1px solid #303030'
        },
      },
    },
    MuiListItemIcon: {
      root: {
        color: "unset !important",
        display: "inline-flex",
        minWidth: "34px",
        flexShrink: "0",
      },
    },
    MuiAvatar: {
      img: {
        objectFit: "contain",
        width: "100%",
        // transform: 'scale(0.9)'
      },
    },
    // MuiPaper:{
    //   rounded: {
    //     borderRadius: '0px'
    //   }
    // },
    MuiIconButton: {
      edgeEnd: {
        marginRight: "-12px !imprtant",
      },
    },
    MuiButtonBase: {
      root: {
        "&.Mui-disabled": {
          cursor: "not-allowed",
          pointerEvents: "auto",
        },
      },
    },
    MuiFilledInput: {
      root: {
        borderRadius: "0px",
        borderTopLeftRadius: "0px",
        borderTopRightRadius: "0px",
      },
      input: {
        padding: "11px 12px 50px 10px",
      },
      underline: {
        "&:before": {
          borderBottom: "none",
        },
        "&:after": {
          borderBottom: "none",
        },
      },
    },
    MuiInput: {
      underline: {
        "&:before": {
          borderBottom:
            window.location.pathname === "/confirm-signup" ||
            window.location.pathname === "/reset-password" ||
            window.location.pathname === "/change-password"
              ? "none"
              : "3px solid #FBC112",
        },
        "&:after": {
          borderBottom:
            window.location.pathname === "/confirm-signup" ||
            window.location.pathname === "/reset-password" ||
            window.location.pathname === "/change-password"
              ? "none"
              : "3px solid #FBC112",
        },
      },
    },
    MuiFormLabel: {
      root: {
        color: "#000000",
        "&.Mui-focused": {
          color: "#000000 !important",
          // color: 'rgba(0, 0, 0, 0.54)'
        },
      },
      asterisk: {
        color: "#EE0E0E",
      },
    },
    MuiRadio: {
      root: {
        // color: '#FBC112'
        // color: '#000000',
        color: "#048475",
      },
      colorSecondary: {
        "&.Mui-checked": {
          // color: '#FBC112'
          color: "#048475",
        },
      },
    },
    MuiList: {
      padding: {
        paddingTop: "0px",
        paddingBottom: "0px",
      },
    },
    MuiChip: {
      root: {
        backgroundColor: "#FBC112",
      },
      deleteIcon: {
        color: "#000000",
      },
      label: {
        whiteSpace: "pre-wrap",
      },
      deletable: {
        "&:focus": {
          backgroundColor: "#FBC112",
        },
      },
    },
    // MuiAccordionSummary: {
    //   expandIcon :{
    //     marginTop: '-20px'
    //   }
    // },
    MuiAppBar: {
      root: {
        justifyContent: "space-evenly",
      },
    },
  },
});

themeX = responsiveFontSizes(themeX);

Amplify.configure({
  Auth: {
    mandatorySignIn: true,
    region: config.cognito.REGION,
    userPoolId: config.cognito.USER_POOL_ID,
    userPoolWebClientId: config.cognito.APP_CLIENT_ID,
  },
});

ReactDOM.render(
  <React.StrictMode>
    <MuiThemeProvider theme={themeX}>
      <Provider store={store}>
        <App />
      </Provider>
    </MuiThemeProvider>
  </React.StrictMode>,
  document.getElementById("root")
);

// if(module.hot){
//   module.hot.accept()
// }

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
