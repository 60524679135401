import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import moment from "moment";
import Timeline from "@material-ui/lab/Timeline";
import TimelineItem from "@material-ui/lab/TimelineItem";
import TimelineSeparator from "@material-ui/lab/TimelineSeparator";
import TimelineConnector from "@material-ui/lab/TimelineConnector";
import TimelineOppositeContent from "@material-ui/lab/TimelineOppositeContent";
import TimelineDot from "@material-ui/lab/TimelineDot";
import { Grid, Avatar, Divider, Tooltip, Typography } from "@material-ui/core";
import FlagIcon from "@material-ui/icons/Flag";
import { capitalize, openLinkInNewTab } from "../../helpers";
import EmptyState from "../core/emptyState";

const useStyles = makeStyles(() => ({
  padding20: {
    padding: "20px",
  },
  experienceTopGrid: {
    display: "flex",
    position: "relative",
  },
  headingGrid: {
    textAlign: "left",
    fontWeight: "bolder",
  },
  dividerTopTab: {
    marginTop: "15px",
    marginBottom: "25px",
  },
  gapTopGrid: {
    paddingLeft: "18px",
    display: "flex",
    alignItems: "center",
  },
  falgIconStyle: {
    color: "#FBC112",
    cursor: "pointer",
    height: "30px",
  },
  flagAva: {
    border: "1.5px solid #fcfaf5",
    height: "62px",
    width: "62px",
    padding: "15px",
    background: "#F4F4F5",
  },
  timelineStyle: {
    padding: "0px",
    margin: "0px",
    alignItems: "center",
  },
  timelineItemStyle: {
    height: "calc(100% - 24px)",
  },
  timelineDotStyle: {
    boxShadow: "none",
    backgroundColor: "#048475",
    marginTop: "12px",
  },
  listStyleNone: {
    listStyle: "none",
  },
  paddingMedium: {
    paddingLeft: "15px",
  },
  customFontBold: {
    fontWeight: "bold",
  },
  fontWeight700: {
    fontWeight: 700,
  },
  gapDivider: {
    marginTop: "20px",
    marginBottom: "20px",
  },
  timelineDotVisibile: {
    boxShadow: "none",
    backgroundColor: "#048475",
    marginTop: "12px",
    visibility: "hidden",
  },
  timelineContentStyle: {
    padding: "3px 13px 0px 0px",
    width: "130px",
  },
  TimelineConnectorStyle: {
    height: "inherit",
    backgroundColor: "#048475",
  },
  customFlex: {
    display: "flex",
  },
  paddingLeft42: {
    paddingLeft: "42px",
    paddingTop: 0,
  },
  paddingLeft15: {
    paddingLeft: "15px",
    display: "flex",
    alignItems: "center",
  },
  mainGrid: {
    marginTop: "20px",
    padding: "20px",
  },
}));

function Experience(props) {
  const { experience } = props;
  const EMPTY_ICON_HEIGHT = 120;
  const EMPTY_ICON_WIDTH = 120;
  const classes = useStyles();

  return (
    <Grid container>
      <Grid
        item
        xs={12}
        sm={12}
        md={12}
        lg={12}
        xl={12}
        className={classes.mainGrid}
      >
        <Typography variant="h6" className={classes.headingGrid}>
          Experiences
        </Typography>
        <Divider className={classes.dividerTopTab} />
        {experience?.length === 0 ? (
          <EmptyState
            type="experience"
            height={EMPTY_ICON_HEIGHT}
            width={EMPTY_ICON_WIDTH}
          />
        ) : (
          <Grid container>
            {experience?.map((companyExp, ind) => (
              <>
                {companyExp[0].experience_type === "0" ? (
                  <>
                    {companyExp?.length === 1 ? (
                      <Grid container>
                        <Grid
                          item
                          xs={12}
                          sm={12}
                          md={12}
                          lg={12}
                          xl={12}
                          className={classes.gapTopGrid}
                        >
                          <span>
                            <Tooltip title="Employment Gap" aria-label="Gap">
                              <Avatar
                                variant="square"
                                className={classes.flagAva}
                              >
                                <FlagIcon className={classes.falgIconStyle} />
                              </Avatar>
                            </Tooltip>
                          </span>
                          <span>
                            <ul className={classes.paddingMedium}>
                              <li className={classes.listStyleNone}>
                                <Tooltip title="Title" arrow placement="left">
                                  <Typography
                                    component="div"
                                    align="left"
                                    variant="h6"
                                    className={classes.customFontBold}
                                  >
                                    {capitalize(companyExp[0]?.title)}
                                  </Typography>
                                </Tooltip>
                                <Tooltip title="Reason" arrow placement="left">
                                  <Typography
                                    align="left"
                                    variant="body2"
                                    className={classes.fontWeight700}
                                  >
                                    {companyExp[0]?.reason}
                                  </Typography>
                                </Tooltip>
                                <Typography color="textSecondary" align="left">
                                  Total Gap - {companyExp[0]?.experience}
                                </Typography>
                              </li>
                            </ul>
                          </span>
                        </Grid>
                        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                          <Divider className={classes.gapDivider} />
                        </Grid>
                      </Grid>
                    ) : (
                      companyExp?.map((value) => (
                        <Grid container>
                          <Grid
                            item
                            xs={12}
                            sm={12}
                            md={12}
                            lg={12}
                            xl={12}
                            className={classes.gapTopGrid}
                          >
                            <span>
                              <Tooltip title="Employment Gap" aria-label="Gap">
                                <Avatar
                                  variant="square"
                                  className={classes.flagAva}
                                >
                                  <FlagIcon className={classes.falgIconStyle} />
                                </Avatar>
                              </Tooltip>
                            </span>
                            <span>
                              <ul className={classes.paddingMedium}>
                                <li className={classes.listStyleNone}>
                                  <Tooltip title="Title" arrow placement="left">
                                    <Typography
                                      component="div"
                                      align="left"
                                      variant="h6"
                                      className={classes.customFontBold}
                                    >
                                      {capitalize(value?.title)}
                                    </Typography>
                                  </Tooltip>
                                  <Tooltip
                                    title="Reason"
                                    arrow
                                    placement="left"
                                  >
                                    <Typography
                                      align="left"
                                      variant="body2"
                                      className={classes.fontWeight700}
                                    >
                                      {value?.reason}
                                    </Typography>
                                  </Tooltip>
                                  <Typography
                                    color="textSecondary"
                                    align="left"
                                  >
                                    Total Gap - {value?.experience}
                                  </Typography>
                                </li>
                              </ul>
                            </span>
                          </Grid>
                          <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                            <Divider className={classes.gapDivider} />
                          </Grid>
                        </Grid>
                      ))
                    )}
                  </>
                ) : (
                  <Grid
                    item
                    xs={12}
                    sm={12}
                    md={12}
                    lg={12}
                    xl={12}
                    className={classes.paddingLeft15}
                  >
                    <span>
                      <Tooltip
                        title={
                          companyExp[0]?.domain ? (
                            <>
                              {companyExp[0]?.domain ? (
                                <Typography>
                                  Domain: {companyExp[0]?.domain}
                                </Typography>
                              ) : (
                                ""
                              )}
                            </>
                          ) : (
                            ""
                          )
                        }
                      >
                        <Avatar
                          src={companyExp[0]?.logo || "/"}
                          alt={companyExp[0]?.company_name}
                          variant="square"
                          onClick={() => {
                            openLinkInNewTab(companyExp[0]?.domain);
                          }}
                          className={classes.avatarFlag}
                        />
                      </Tooltip>
                    </span>
                    <span>
                      <ul className={classes.paddingMedium}>
                        <li className={classes.listStyleNone}>
                          <Tooltip title="Company" arrow placement="left">
                            <Typography
                              component="div"
                              align="left"
                              variant="h6"
                              className={classes.customFontBold}
                            >
                              {capitalize(companyExp[0]?.company_name)}
                            </Typography>
                          </Tooltip>
                          <div className={classes.customFlex}>
                            <Typography
                              align="left"
                              variant="body2"
                              className={classes.fontWeight700}
                            >
                              {companyExp[0]?.company_size
                                ? `${companyExp[0]?.company_size}  Employees`
                                : " "}
                              {companyExp[0]?.company_size &&
                              companyExp[0]?.team_managed
                                ? " , "
                                : ""}
                              {companyExp[0]?.team_managed
                                ? ` Team Size Managed : ${companyExp[0]?.team_managed}`
                                : ""}
                            </Typography>
                            <Tooltip title="Location" arrow placement="left">
                              <Typography
                                align="left"
                                variant="body2"
                                className={classes.fontWeight700}
                              >
                                {(companyExp[0]?.team_managed ||
                                  companyExp[0]?.company_size) &&
                                (companyExp[0]?.location?.city ||
                                  companyExp[0]?.location?.state ||
                                  companyExp[0]?.location?.country) ? (
                                  <span className={classes.marginLeftDot}>
                                    {" "}
                                    .{" "}
                                  </span>
                                ) : null}
                                {companyExp[0]?.location?.city
                                  ? companyExp[0]?.location?.city
                                  : ""}
                                {companyExp[0]?.location?.city &&
                                companyExp[0]?.location?.state
                                  ? ", "
                                  : null}
                                {companyExp[0]?.location?.state
                                  ? companyExp[0]?.location?.state
                                  : ""}
                                {companyExp[0]?.location?.country &&
                                companyExp[0]?.location?.state
                                  ? ", "
                                  : null}
                                {companyExp[0]?.location?.country
                                  ? companyExp[0]?.location?.country
                                  : ""}
                              </Typography>
                            </Tooltip>
                          </div>
                          <Typography align="left" color="textSecondary">
                            Total Years of Experience -{" "}
                            {companyExp[0]?.total_experience}
                          </Typography>
                        </li>
                      </ul>
                    </span>
                  </Grid>
                )}
                {companyExp[0].experience_type !== "0" && (
                  <Grid
                    item
                    xs={12}
                    sm={12}
                    md={12}
                    lg={12}
                    xl={12}
                    className={classes.timelineGrid}
                  >
                    {companyExp.length === 1 && (
                      <Timeline align="left" className={classes.marginTopNone}>
                        <TimelineItem>
                          <TimelineDot
                            className={classes.timelineDotVisibile}
                          />
                          <TimelineOppositeContent
                            className={classes.paddingLeft42}
                          >
                            {companyExp.length === 1 &&
                              companyExp.map((exp) => (
                                <>
                                  <div className={classes.customFlex}>
                                    <Tooltip
                                      title="Title"
                                      arrow
                                      placement="left"
                                    >
                                      <Typography
                                        align="left"
                                        variant="body2"
                                        component="span"
                                        className={classes.fontWeight700}
                                      >
                                        {capitalize(exp?.title)}
                                      </Typography>
                                    </Tooltip>
                                    <Tooltip
                                      title="Industries"
                                      arrow
                                      placement="left"
                                    >
                                      <Typography
                                        align="left"
                                        variant="body2"
                                        component="span"
                                        className={classes.fontWeight700}
                                      >
                                        {exp?.industry
                                          ? `, ${
                                              exp?.industry === "Other"
                                                ? exp?.other_industry_text
                                                : capitalize(exp?.industry)
                                            }`
                                          : null}
                                      </Typography>
                                    </Tooltip>
                                  </div>
                                  <Tooltip
                                    title="Duration"
                                    arrow
                                    placement="left"
                                  >
                                    <Typography
                                      align="left"
                                      color="textSecondary"
                                    >
                                      {" "}
                                      {exp?.starts_at === null
                                        ? ""
                                        : moment(exp?.starts_at).format(
                                            "MMMM YYYY"
                                          )}{" "}
                                      -{" "}
                                      {exp?.ends_at === "Present"
                                        ? "Present"
                                        : exp?.ends_at === null
                                        ? ""
                                        : moment(exp?.ends_at).format(
                                            "MMMM YYYY"
                                          )}{" "}
                                    </Typography>
                                  </Tooltip>
                                  <Tooltip
                                    title="Description"
                                    arrow
                                    placement="left"
                                  >
                                    <Typography
                                      align="left"
                                      className={classes.accomplishmentColor}
                                    >
                                      <span
                                        dangerouslySetInnerHTML={{
                                          __html: exp?.description
                                            ? exp?.description
                                            : "",
                                        }}
                                      />
                                    </Typography>
                                  </Tooltip>
                                </>
                              ))}
                          </TimelineOppositeContent>
                        </TimelineItem>
                      </Timeline>
                    )}
                    {companyExp.length > 1 && (
                      <Timeline align="right" className={classes.marginTopNone}>
                        {companyExp.map((exp, ind) => (
                          <TimelineItem>
                            <TimelineOppositeContent
                              className={classes.paddingLeft42}
                            >
                              <div className={classes.customFlex}>
                                <Tooltip title="Title" arrow placement="left">
                                  <Typography
                                    align="left"
                                    variant="body2"
                                    component="span"
                                    className={classes.fontWeight700}
                                  >
                                    {capitalize(exp?.title)}
                                  </Typography>
                                </Tooltip>
                                <Tooltip
                                  title="Industries"
                                  arrow
                                  placement="left"
                                >
                                  <Typography
                                    align="left"
                                    variant="body2"
                                    component="span"
                                    className={classes.fontWeight700}
                                  >
                                    {exp?.industry
                                      ? `, ${
                                          exp?.industry === "Other"
                                            ? exp?.other_industry_text
                                            : capitalize(exp?.industry)
                                        }`
                                      : null}
                                  </Typography>
                                </Tooltip>
                              </div>
                              <div className={classes.customFlex}>
                                <Tooltip
                                  title="Duration"
                                  arrow
                                  placement="left"
                                >
                                  <Typography
                                    color="textSecondary"
                                    align="left"
                                  >
                                    {exp?.starts_at === null
                                      ? ""
                                      : moment(exp?.starts_at).format(
                                          "MMMM YYYY"
                                        )}{" "}
                                    -{" "}
                                    {exp?.ends_at === "Present"
                                      ? "Present"
                                      : exp?.ends_at === null
                                      ? ""
                                      : moment(exp?.ends_at).format(
                                          "MMMM YYYY"
                                        )}{" "}
                                  </Typography>
                                </Tooltip>
                                <Tooltip
                                  title="Years of Experience"
                                  arrow
                                  placement="right"
                                >
                                  <Typography
                                    color="textSecondary"
                                    align="left"
                                  >
                                    {exp?.experience
                                      ? `, ${exp?.experience}`
                                      : ""}
                                  </Typography>
                                </Tooltip>
                              </div>
                              <Tooltip
                                title="Description"
                                arrow
                                placement="left"
                              >
                                <Typography
                                  align="left"
                                  className={classes.accomplishmentColor}
                                >
                                  <span
                                    dangerouslySetInnerHTML={{
                                      __html: exp?.description
                                        ? exp?.description
                                        : "",
                                    }}
                                  />
                                </Typography>
                              </Tooltip>
                            </TimelineOppositeContent>
                            <TimelineSeparator>
                              <TimelineDot
                                className={classes.timelineDotStyle}
                              />
                              {companyExp?.length !== ind + 1 ? (
                                <TimelineConnector
                                  className={classes.TimelineConnectorStyle}
                                />
                              ) : null}
                            </TimelineSeparator>
                          </TimelineItem>
                        ))}
                      </Timeline>
                    )}
                    {experience.length !== ind + 1 && (
                      <Divider className={classes.marginDivider} />
                    )}
                  </Grid>
                )}
              </>
            ))}
          </Grid>
        )}
      </Grid>
    </Grid>
  );
}

export default Experience;
