import React, { Component, Fragment } from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import Footer from "../../core/footer";
import PreLoginContent from "../../core/preLoginContent";
import Paper from "@material-ui/core/Paper";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import { connect } from "react-redux";
import { history } from "../../../helpers";
import Typography from "@material-ui/core/Typography";
import Dialog from "@material-ui/core/Dialog";
import MuiDialogTitle from "@material-ui/core/DialogTitle";
import MuiDialogContent from "@material-ui/core/DialogContent";
import MuiDialogActions from "@material-ui/core/DialogActions";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import { withRouter } from "react-router-dom";
import ProductInfoImage from "../../../assets/product_info.svg";
import validator from "validator";
import { Auth } from "aws-amplify";
import { toast } from "react-toastify";
import { loading } from "../../../actions";
import cardHeaderBg from "../../../assets/lite_profile_bg.png";
import OtpInput from "react-otp-input";
import classNames from "classnames";
import secureLocalStorage from "react-secure-storage";

const styles = (theme) => ({
  root: {
    height: "calc(100vh - 90px)",
    marginTop: "90px",
  },
  image: {
    backgroundImage: `url(${ProductInfoImage})`, //'url(https://source.unsplash.com/random)',
    backgroundRepeat: "no-repeat",
    backgroundColor:
      theme.palette.type === "light"
        ? theme.palette.grey[50]
        : theme.palette.grey[900],
    backgroundSize: "cover",
  },
  paper: {
    margin: theme.spacing(2, 4),
    [theme.breakpoints.down("md")]: {
      margin: theme.spacing(3, 4),
    },
    [theme.breakpoints.down("sm")]: {
      margin: theme.spacing(2, 4),
    },
    alignItems: "flex-start",
    marginTop: "5%",
  },
  boldFont: {
    fontWeight: "bold",
  },
  signInBtn: {
    float: "left",
    width: "14em",
    backgroundColor: "#FBC112",
    color: "#000000",
  },
  spacing: {
    padding: "3% 3% 7% 3%",
    [theme.breakpoints.between("xs", "md")]: {
      padding: "5% 5% 15% 5%",
    },
    // [theme.breakpoints.between('md', 'md')]: {
    //   padding: '25% 5%'
    // },
    backgroundColor: "#F8F6EF", //'#E5E5E5'
  },
  customPaper: {
    margin: theme.spacing(1, 4),
    [theme.breakpoints.down("md")]: {
      margin: theme.spacing(3, 4),
    },
    [theme.breakpoints.down("sm")]: {
      margin: theme.spacing(2, 4),
    },
    display: "flex",
    alignItems: "flex-start",
  },
  newCustomPaper: {
    borderRadius: "4px",
    padding: "1%",
  },
  dialogPaper: {
    margin: theme.spacing(1, 4),
    // display: 'flex',
    alignItems: "center",
  },
  customDialog: {
    borderRadius: "0px !important",
    boxShadow: "none !important",
  },
  customForm: {
    margin: theme.spacing(5, 4),
    // display: 'flex',
    // alignItems: 'center',
    [theme.breakpoints.down("md")]: {
      margin: theme.spacing(3, 4),
    },
    [theme.breakpoints.down("sm")]: {
      margin: theme.spacing(2, 4),
    },
  },
  xxLargeFont: {
    fontSize: "xx-large",
    [theme.breakpoints.down("md")]: {
      fontSize: "22px",
    },
    [theme.breakpoints.down("sm")]: {
      fontSize: "22px",
    },
  },
  bottomSmall: {
    marginBottom: "9%",
  },
  resendLink: {
    cursor: "pointer",
    "&:hover": {
      textDecoration: "underline",
    },
    color: "#048475 !important",
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  smallTopMargin: {
    marginTop: "3%",
  },
  submit: {
    margin: theme.spacing(0, 0, 0),
    borderRadius: "4px !important",
  },
  alignRight: {
    textAlign: "right",
  },
  margin: {
    marginTop: theme.spacing(2),
  },
  withoutLabel: {
    marginTop: theme.spacing(3),
  },
  textField: {
    width: "100%",
  },
  disabledSignIn: {
    cursor: "not-allowed",
    borderRadius: "4px !important",
  },
  toolbar: {
    padding: "1% !important",
    left: "1.5% !important",
  },
  responsive: {
    maxWidth: "100% !important",
    height: "auto !important",
    width: "auto !important",
    alignSelf: "center !important",
    minWidth: "fit-content !important",
  },
  customFlex: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  closeButton: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.default.main,
    padding: "0px",
  },
  customDialogHeader: {
    maxHeight: "38%",
    height: "38%",
    color: "#000",
    backgroundSize: "cover",
    backgroundImage: `url(${cardHeaderBg})`,
    background: "round",
  },
  // for side box added by KP
  detailsBox: {
    background: "#FBC112",
    height: "100%",
    padding: "120px 70px",
    [theme.breakpoints.down("md")]: {
      padding: "50px 30px",
    },
    [theme.breakpoints.down("sm")]: {
      padding: "32px 16px",
    },
  },
  listItem: {
    display: "flex",
    alignItems: "center",
    marginTop: "20px",
    marginBottom: "20px",
    [theme.breakpoints.between("xs", "lg")]: {
      marginTop: "10px",
      marginBottom: "10px",
    },
    background: "#FB9012",
    padding: "10px",
    borderRadius: "8px",
    fontSize: "18px",
  },
  itemCircle: {
    height: "64px",
    width: "64px",
    borderRadius: "50%",
    background: "#ffffff",
    padding: 0,
    "& img": {
      height: "64px",
      width: "64px",
    },
  },
  sideTitle: {
    fontSize: "24px",
    [theme.breakpoints.between("xs", "lg")]: {
      fontSize: "18px",
    },
    lineHeight: "185%",
    color: "#000000",
    marginBottom: "10px",
    textAlign: "left",
  },
  otpInput: {
    "&>input": {
      fontSize: "1rem !important",
      width: "2rem !important",
      height: "2rem !important",
      backgroundColor: "#F4F4F5",
    },
    "&:last-child": {
      marginRight: "0 !important",
    },
    width: "3rem",
    height: "3rem",
    marginRight: "1rem",
    fontSize: "1rem",
    textAlign: "center",
    border: "1px solid #B7B6B8",
    borderRadius: "5px",
    backgroundColor: "#F4F4F5",
    display: "flex !important",
    alignItems: "center !important",
    justifyContent: "center !important",
  },
});

const DialogTitle = withStyles(styles)((props) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle
      disableTypography
      className={classes.customDialogHeader}
      style={{ padding: "3%" }}
      {...other}
    >
      <Typography variant="h5" align="center">
        {children}
      </Typography>
      {onClose ? (
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={onClose}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const DialogContent = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
    textAlign: "center",
  },
}))(MuiDialogContent);

const DialogActions = withStyles((theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
    backgroundColor: "#fff",
    justifyContent: "center !important",
  },
}))(MuiDialogActions);

class VerifyEmail extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isValidVerificationCode: true,
      verificationCode: "",
      open: false,
      errors: {
        cognito: null,
      },
    };
  }

  componentDidMount() {
    document.body.scrollTop = 0; // For Safari
    document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE and Opera
  }

  handleClickOpen = () => {
    this.setState({ open: true });
  };

  handleClose = () => {
    secureLocalStorage.clear();
    this.setState({ open: false });
    history.push("/signin");
  };

  handleChange = (prop) => (event) => {
    switch (prop) {
      case "verificationCode":
        this.setState({
          isValidVerificationCode: validator.matches(
            event,
            /^(\s*\d{6}\s*)(,\s*\d{6}\s*)*,?\s*$/
          ),
        });
        break;
      default:
        return;
    }

    if (prop === "verificationCode") this.setState({ [prop]: event });
    else this.setState({ [prop]: event.target.value });
  };

  goToSignIn = () => {
    this.handleClose();
  };

  verifyEmail = async (event) => {
    event.preventDefault();
    event.stopPropagation();
    const { dispatch } = this.props;
    dispatch(loading(true));
    const { verificationCode } = this.state;
    if (verificationCode) {
      try {
        let result = await Auth.verifyCurrentUserAttributeSubmit(
          "email",
          verificationCode
        );
        console.log(result);
        this.handleClickOpen();
        dispatch(loading(false));
      } catch (error) {
        let err = null;
        !error.message ? (err = { message: error }) : (err = error);
        console.log("error resetting password:", err);
        this.setState({
          errors: {
            ...this.state.errors,
            cognito: error,
          },
        });
        dispatch(loading(false));
        toast.dark(error.message);
      }
    } else {
      dispatch(loading(false));
      toast.dark("Please enter valid verification code");
    }
  };

  render() {
    const { classes } = this.props;
    return (
      <Fragment>
        <Grid container component="main" className={classes.root}>
          <PreLoginContent />
          <Grid
            item
            xs={12}
            sm={12}
            md={6}
            lg={5}
            xl={5}
            className={classes.spacing}
            elevation={6}
            square
          >
            <Grid
              component={Paper}
              elevation={0}
              className={classes.newCustomPaper}
            >
              <div className={classes.paper}>
                <Typography
                  align="left"
                  className={classNames(classes.xxLargeFont, classes.boldFont)}
                >
                  Verify Email
                </Typography>
              </div>
              <div className={classes.customPaper}>
                <Typography component="span" align="left" color="primary">
                  Enter the verification code we just sent to you via email and
                  verify your email.
                </Typography>
              </div>
              <form className={classes.customForm} noValidate>
                <Grid container item xs={12} sm={12} md={12} lg={12} xl={12}>
                  <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                    <OtpInput
                      isInputNum
                      isNumberInput
                      shouldAutoFocus={true}
                      id="verificationCode"
                      name="verificationCode"
                      value={this.state.verificationCode}
                      onChange={this.handleChange("verificationCode")}
                      numInputs={6}
                      className={classes.otpInput}
                      inputClassName="otpInput"
                      hasErrored={
                        this.state.isValidVerificationCode ? false : true
                      }
                      autoFocus
                    />

                    {!this.state.isValidVerificationCode ? (
                      <Typography
                        align="left"
                        style={{
                          fontSize: "0.75rem",
                          color: "rgb(220, 0, 78)",
                          margin: "8px",
                        }}
                      >
                        The code entered by you is incorrrect. Please try again.{" "}
                      </Typography>
                    ) : null}
                  </Grid>

                  <Grid
                    className={classes.smallTopMargin}
                    item
                    xs={12}
                    sm={12}
                    md={12}
                    lg={12}
                    xl={12}
                  >
                    <Button
                      size="large"
                      type="submit"
                      className={classes.signInBtn}
                      disableElevation
                      variant="contained"
                      color="primary"
                      onClick={(event) => {
                        this.verifyEmail(event);
                      }}
                    >
                      Verify
                    </Button>
                  </Grid>
                </Grid>
              </form>
            </Grid>
          </Grid>
          <Footer />
        </Grid>
        <Dialog
          disableBackdropClick
          disableEscapeKeyDown
          aria-labelledby="customized-dialog-title"
          open={this.state.open}
          className={classes.customDialog}
        >
          <DialogTitle onClose={this.handleClose}>
            <div className={classes.dialogPaper}>
              <Typography component="span" className={classes.xxLargeFont}>
                Verification Successful!
              </Typography>
            </div>
          </DialogTitle>
          <DialogContent>
            <div className={classes.dialogPaper}>
              <Typography component="span" color="primary">
                Your have successfully verified your email. Please sign in again
                to continue.
              </Typography>
            </div>
          </DialogContent>
          <DialogActions>
            <Button
              variant="contained"
              color="primary"
              className={classes.submit}
              disableElevation
              size="large"
              style={{ background: "#D7D9DB", color: "#000" }}
              onClick={() => {
                this.goToSignIn();
              }}
            >
              Close
            </Button>
          </DialogActions>
        </Dialog>
      </Fragment>
    );
  }
}

VerifyEmail.propTypes = {
  classes: PropTypes.object.isRequired,
};

const mapStateToProps = () => {
  return {};
};

const connectedVerifyEmailPage = withRouter(
  connect(mapStateToProps, null, null, {
    pure: false,
  })(withStyles(styles)(VerifyEmail))
);

export { connectedVerifyEmailPage as VerifyEmail };
