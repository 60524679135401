export function resetPasswordStep(state = false, action) {
  switch (action.type) {
    case "SET_RESET_PASSWORD_STEP":
      return action.step;
    default:
      return state;
  }
}

export default resetPasswordStep;
