import React, { Component, Fragment } from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import { connect } from "react-redux";
import {
  ClickAwayListener,
  MenuList,
  ListItemIcon,
  MenuItem,
  Button,
  Card,
  CardContent,
  CardHeader,
  Grid,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Tooltip,
  Typography,
  Avatar,
  CardActions,
  IconButton,
  TextareaAutosize,
  Divider,
  Popper,
  Fade,
} from "@material-ui/core";
import { capitalize, history, trim, truncate } from "../../../helpers";
import classNames from "classnames";
import { ReactComponent as MatchedCriteria } from "../../../assets/matched_criteria.svg";
import cardHeaderBg from "../../../assets/lite_profile_bg.png";
import AccountCircleIcon from "@material-ui/icons/AccountCircle";
import PhoneIcon from "@material-ui/icons/Phone";
import LocationOnIcon from "@material-ui/icons/LocationOn";
import AssignmentIndIcon from "@material-ui/icons/AssignmentInd";
import { Check, Close } from "@material-ui/icons";
import { ReactComponent as RecommendedEmpty } from "../../../assets/recommended_empty.svg";
import { ReactComponent as PendingEmpty } from "../../../assets/pending_empty.svg";
import { ReactComponent as MatchedEmpty } from "../../../assets/matched_empty.svg";
import { ReactComponent as ArchivedEmpty } from "../../../assets/archived_empty.svg";
import { ReactComponent as MoreIcon } from "../../../assets/black_dot_menu.svg";
import { jobsActions } from "../../../actions";
import moment from "moment";
import { ViewMore } from "../../core/viewmore";
import Delete from "@material-ui/icons/Delete";
import { toast } from "react-toastify";
import { membersActions } from "../../../actions/members.actions";
// import SchedulingPopup from './scheduling.popup.component'
import PreviewEmaildailog from "../../core/previewEmail.dailog";
import { convertToRaw } from "draft-js";
import draftToHtml from "draftjs-to-html";
import ChatIcon from "@material-ui/icons/Chat";
import ShareIcon from "@material-ui/icons/Share";
import ShareProfile from "../../core/share.profile";
import secureLocalStorage from "react-secure-storage";

const styles = (theme) => ({
  rootBtn: {
    fontWeight: "400 !important",
    borderRadius: "10px !important",
    height: "48px",
    width: "15em",
    "&:hover": {
      backgroundColor: "#FBC112 !important",
      color: "#000000 !important",
    },
  },
  scrollOnHover: {
    overflow: "hidden",
    "&:hover": {
      overflowY: "auto",
    },
  },
  smallTopMargin: {
    marginTop: "2%",
  },
  mediumTopMargin: {
    marginTop: "4%",
  },
  customMinWidth: {
    minWidth: "12em",
    [theme.breakpoints.between("xs", "sm")]: {
      minWidth: "10em",
    },
  },
  filterButtons: {
    width: "100%",
    display: "flex",
    alignItems: "center",
  },
  customPadding: {
    borderBottom: "none",
    padding: "10px",
    [theme.breakpoints.between("xs", "lg")]: {
      padding: "24px",
    },
  },
  customHeight: {
    overflowY: "hidden",
    "&:hover": {
      overflowY: "auto",
    },
    backgroundColor: "#F7F7F7",
    borderRadius: "5px",
    marginTop: "2%",
    padding: "10px",
    height: "100vh",
    [theme.breakpoints.between("xs", "sm")]: {
      height: "auto",
    },
  },
  customButtonDisplayInterested: {
    display: "flex",
    flexDirection: "column",
    marginTop: "8px",
    [theme.breakpoints.up("xl")]: {
      alignItems: "start",
      justifyContent: "left",
      flexDirection: "inherit",
    },
  },
  customButtonDisplay: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-start",
    marginTop: "16px",
  },
  noDataSmall: {
    textAlign: "center",
    color: theme.palette.text.secondary,
  },
  customCollaboratorChip: {
    margin: "5px",
    [theme.breakpoints.between("xs", "md")]: {
      height: "auto",
    },
  },
  customPopper: {
    padding: theme.spacing(2),
    [theme.breakpoints.between("xs", "sm")]: {
      padding: theme.spacing(1),
      width: "min-content !important",
    },
  },
  customBg: { backgroundColor: "#EDEDED" },
  customSection: {
    boxShadow:
      "0px 1px 1px 0px rgb(0 0 0 / 0%), 0px 1px 5px 0px rgb(0 0 0 / 20%), 0px 0px 1px 1px rgb(0 0 0 / 12%)",
    borderRadius: "10px",
    padding: "3%",
  },
  customBottomMargin: { marginBottom: "2%" },
  widthFitContent: { width: "fit-content !important" },
  arrow: {
    position: "absolute",
    fontSize: "7px",
    width: "3em",
    height: "3em",

    "&:before": {
      content: '""',
      margin: "auto",
      display: "block",
      width: 0,
      height: 0,
      borderStyle: "solid",
    },
  },
  heading: {
    fontSize: theme.typography.pxToRem(14),
    flexBasis: "33.33%",
    flexShrink: 0,
  },
  secondaryHeading: {
    fontSize: theme.typography.pxToRem(14),
    color: theme.palette.text.primary,
  },
  actionButtons: {
    position: "absolute",
    bottom: "-20px",
    right: "20px",
    display: "flex",
    justifyContent: "space-around",
    width: "200px",
    alignItems: "center",
    [theme.breakpoints.between("xs", "md")]: {
      marginTop: theme.spacing(7),
    },
    zIndex: "10",
  },
  customActionButtons: {
    position: "absolute",
    bottom: "7px",
    right: "10px",
    display: "flex",
    justifyContent: "space-around",
    gap: "10px",
    // width: '48px',
    alignItems: "center",
    [theme.breakpoints.between("xs", "md")]: {
      marginTop: theme.spacing(7),
    },
    zIndex: "10",
  },
  customPaddingInlineStart: { paddingInlineStart: "24px !important" },
  whiteBg: { backgroundColor: "#FFFFFF !important" },
  bullet: {
    display: "inline-block",
    margin: "0 2px",
    transform: "scale(0.8)",
  },
  pos: {
    marginBottom: 12,
  },
  largeAvatar: {
    width: theme.spacing(7),
    height: theme.spacing(7),
  },
  xLargeAvatar: {
    width: theme.spacing(10),
    height: theme.spacing(10),
    [theme.breakpoints.between("xs", "lg")]: {
      width: theme.spacing(7),
      height: theme.spacing(7),
    },
    backgroundColor: "#000",
  },
  xLargeCompanyLogoAvatar: {
    width: theme.spacing(12),
    height: theme.spacing(12),
  },
  demo1: {
    marginTop: "5%",
  },
  largeTopMargin: {
    marginTop: "10%",
  },
  verySmallTopMargin: {
    marginTop: "1%",
  },
  formControl: {
    margin: theme.spacing(2, 0),
    minWidth: "100%",
  },
  customFormControl: {
    marginBottom: "1.7%", //theme.spacing(1.3, 0),
    minWidth: "100%",
  },
  customTopBottomMargin: {
    marginTop: "1.8%",
    marginBottom: "1.7%",
    minWidth: "100%",
  },
  alignSelfCenter: {
    alignSelf: "center",
  },
  displayFlex: {
    display: "flex",
  },
  customDisplayFlex: {
    display: "flex",
    [theme.breakpoints.between("xs", "sm")]: {
      display: "block",
    },
  },
  noPadding: {
    padding: "0 !important",
    [theme.breakpoints.between("xs", "lg")]: {
      padding: "2px !important",
    },
  },
  customNoPadding: { padding: "4px !important" },
  noMargin: {
    margin: "0 !important",
  },
  fullWidth: {
    width: "100%",
  },
  colorDefault: {
    color: "#000000 !important",
  },
  colorCustom: {
    color: "#303030",
  },
  displayInline: {
    display: "inline",
  },
  noBottomMargin: {
    marginBottom: "0",
  },
  customSmallTopMargin: {
    marginTop: "2%",
  },
  input: {
    marginLeft: theme.spacing(1),
    flex: 1,
  },
  iconButton: {
    padding: 10,
  },
  noWrap: {
    whiteSpace: "nowrap",
  },
  subHeader: {
    fontSize: "large",
    marginTop: "4%",
    fontWeight: "600",
    color: "#000000",
  },
  header: {
    fontSize: "x-large",
    marginTop: "4%",
    fontWeight: "600",
    color: "#000000",
  },
  jobCardHeader: {
    fontSize: "x-large",
    fontWeight: "500",
    color: "#000000",
    textAlign: "left",
  },
  jobCardSubHeader: {
    fontSize: "large",
    fontWeight: "500",
    textAlign: "left",
  },
  customCard: {
    backgroundColor: "#F7F7F7",
    borderRadius: "10px", // theme.palette.basic.main
  },
  customCardForComments: {
    backgroundColor: "#F7F7F7",
    borderRadius: "10px",
    padding: "20px",
  },
  customCardShadow: {
    boxShadow: "none",
  },
  textAlignEnd: {
    textAlign: "end",
  },
  textAlignCenter: {
    textAlign: "center",
  },
  jobCard: {
    boxShadow:
      "0px 1px 1px 0px rgb(0 0 0 / 20%), 0px 1px 0px 0px rgb(0 0 0 / 14%), 0px 0px 2px 1px rgb(0 0 0 / 12%)",
    cursor: "pointer",
  },
  smallBottomMargin: {
    marginBottom: "2%",
  },
  mediumBottomMargin: {
    marginBottom: "5%",
  },
  customBadge: {
    borderRadius: "3px",
    width: "6em",
    fontSize: "smaller",
    height: "24px",
    backgroundColor: "#616161",
    color: "#FFFFFF",
  },
  customBadgeGrid: {
    textAlign: "center",
    overflowWrap: "break-word",
    padding: "2%",
  },
  customLink: {
    cursor: "pointer",
    textDecoration: "underline",
    // '&:hover': {
    //   textDecoration: 'underline'
    // },
    color: "#048475 !important",
  },
  breadcrumbLink: {
    cursor: "pointer",
    "&:hover": {
      textDecoration: "underline",
    },
  },

  customMoreIcon: {
    fontSize: "2.5rem",
  },
  justifyContentFlexEnd: {
    justifyContent: "flex-end",
  },
  justifyContentCenter: {
    justifyContent: "center !important",
  },
  responsive: {
    maxWidth: "100% !important",
    height: "auto !important",
    width: "auto !important",
    minWidth: "fit-content !important",
  },
  liteProfileCard: {
    boxShadow:
      "0px 0px 0px 1px rgb(0 0 0 / 5%), 0px 0px 0px 0px rgb(0 0 0 / 5%), 0px 0px 0px 1px rgb(0 0 0 / 5%)",
    maxHeight: "55em",
    height: "55em",
    borderRadius: "5px",
  },
  expandedLiteProfileCard: {
    boxShadow:
      "0px 0px 0px 1px rgb(0 0 0 / 5%), 0px 0px 0px 0px rgb(0 0 0 / 5%), 0px 0px 0px 1px rgb(0 0 0 / 5%)",
  },
  liteProfileCardForMatched: {
    boxShadow:
      "0px 0px 0px 1px rgb(0 0 0 / 5%), 0px 0px 0px 0px rgb(0 0 0 / 5%), 0px 0px 0px 1px rgb(0 0 0 / 5%)",
    maxHeight: "63em",
    height: "63em",
    borderRadius: "5px",
  },
  expandedLiteProfileCardForMatched: {
    boxShadow:
      "0px 0px 0px 1px rgb(0 0 0 / 5%), 0px 0px 0px 0px rgb(0 0 0 / 5%), 0px 0px 0px 1px rgb(0 0 0 / 5%)",
  },
  maxHeight40P: { maxHeight: "40px" },
  customCardHeader: {
    //   maxHeight: '370px',
    //   height: '370px',
    color: "#000",
    backgroundSize: "cover",
    //   backgroundImage: `url(${cardHeaderBg})`,
    background: "round",
    position: "relative",
    textAlign: "left",
    display: "flex",
    alignItems: "flex-start",
    padding: "0px",
  }, //height: '180px'
  expandedCustomCardHeader: {
    color: "#000",
    backgroundSize: "cover",
    backgroundImage: `url(${cardHeaderBg})`,
    background: "round",
    position: "relative",
    textAlign: "left",
    display: "flex",
    alignItems: "center",
    padding: "24px",
  }, //height: '180px'
  customDiversityCardHeader: {
    color: "#000",
    backgroundSize: "cover",
    //   backgroundImage: `url(${cardHeaderBg})`,
    background: "round",
    position: "relative",
    textAlign: "left",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    padding: "0px",
  }, //height: '180px'
  expandedCustomDiversityCardHeader: {
    color: "#000",
    backgroundSize: "cover",
    backgroundImage: `url(${cardHeaderBg})`,
    background: "round",
    position: "relative",
    textAlign: "left",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    padding: "24px",
  }, //height: '180px'  matchedCustomCardHeader: { maxHeight: '370px', height: '370px', [theme.breakpoints.between('md', 'lg')]: { maxHeight: '450px', height: '450px', padding: '8px' }, [theme.breakpoints.between('xs', 'sm')]: { maxHeight: '450px', height: '450px', padding: '8px' }, color: '#000', backgroundSize: 'cover', backgroundImage: `url(${cardHeaderBg})`, background: 'round', position: 'relative', padding: '16px' },
  expandedMatchedCustomCardHeader: {
    // padding: '24px',
    [theme.breakpoints.between("md", "lg")]: {
      padding: "1px",
    },
    [theme.breakpoints.between("xs", "sm")]: { padding: "4px" },
    color: "#000",
    backgroundSize: "cover",
    backgroundImage: `url(${cardHeaderBg})`,
    background: "round",
    position: "relative",
  },
  customCardActions: { display: "block" },
  textAlignLeft: { textAlign: "left" },
  textAlignRight: { textAlign: "right" },
  verySmallFont: { fontSize: ".8rem" },
  font10p: { fontSize: "10px" },
  font12p: { fontSize: "12px" },
  font14p: { fontSize: "14px" },
  font16p: { fontSize: "16px" },
  alignBaslineMiddle: { verticalAlign: "-webkit-baseline-middle" },
  verySmallMarginLeft: { marginLeft: "3px" },
  smallMarginLeft: { marginLeft: "8px" },
  smallMarginRight: { marginRight: "8px" },
  mediumMarginLeft: { marginLeft: "12px" },
  boldFont: { fontWeight: "bold" },
  customPaddingForCardContent: {
    paddingLeft: "24px",
    paddingRight: "24px",
    overflowWrap: "break-word",
  },
  liteProfileHighlights: {
    backgroundColor: "#F7F7F7",
    padding: "10px",
    height: "100px",
    maxHeight: "14%",
  },
  liteProfileCardContent: {
    backgroundColor: "#FFFFFF",
    paddingTop: 0,
    paddingBottom: 0,
    paddingLeft: "0px",
    paddingRight: "0px",
    height: "29.5em",
    maxHeight: "29.5em",
    "&:hover": { overflowY: "auto" },
    overflowY: "hidden",
    overflowWrap: "break-word",
  },
  expandedLiteProfileCardContent: {
    backgroundColor: "#FFFFFF",
    paddingTop: 0,
    paddingBottom: 0,
    paddingLeft: "0px",
    paddingRight: "0px",
    "&:hover": { overflowY: "auto" },
    overflowY: "hidden",
    overflowWrap: "break-word",
  },
  liteProfileCardContentForMatched: {
    overflowWrap: "break-word",
    backgroundColor: "#FFFFFF",
    paddingTop: 0,
    paddingBottom: 0,
    paddingLeft: "0px",
    paddingRight: "0px",
    height: "34.2em",
    maxHeight: "34.2em",
    "&:hover": { overflowY: "auto" },
    overflowY: "hidden",
    [theme.breakpoints.between("xs", "lg")]: {
      height: "29.2em",
      maxHeight: "29.2em",
    },
  },
  expandedLiteProfileCardContentForMatched: {
    overflowWrap: "break-word",
    backgroundColor: "#FFFFFF",
    paddingTop: 0,
    paddingBottom: 0,
    paddingLeft: "0px",
    paddingRight: "0px",
    "&:hover": { overflowY: "auto" },
    overflowY: "hidden",
  },
  liteProfileCardContentForArchived: {
    overflowWrap: "break-word",
    backgroundColor: "#FFFFFF",
    paddingTop: 0,
    paddingBottom: 0,
    paddingLeft: "0px",
    paddingRight: "0px",
    height: "37.7em",
    maxHeight: "37.7em",
    "&:hover": { overflowY: "auto" },
    overflowY: "hidden",
    [theme.breakpoints.between("xs", "lg")]: {
      height: "32.7em",
      maxHeight: "32.7em",
    },
  },
  expandedLiteProfileCardContentForArchived: {
    overflowWrap: "break-word",
    backgroundColor: "#FFFFFF",
    paddingTop: 0,
    paddingBottom: 0,
    paddingLeft: "0px",
    paddingRight: "0px",
    "&:hover": { overflowY: "auto" },
    overflowY: "hidden",
  },
  customLiteProfileCardContent: {
    overflowWrap: "break-word",
    backgroundColor: "#FFFFFF",
    paddingTop: 0,
    paddingBottom: 0,
    paddingLeft: "0px",
    paddingRight: "0px",
    height: "30em",
    maxHeight: "30em",
    "&:hover": { overflowY: "auto" },
    overflowY: "hidden",
  },
  expandedCustomLiteProfileCardContent: {
    overflowWrap: "break-word",
    backgroundColor: "#FFFFFF",
    paddingTop: 0,
    paddingBottom: 0,
    paddingLeft: "0px",
    paddingRight: "0px",
    "&:hover": { overflowY: "auto" },
    overflowY: "hidden",
  },
  liteProfileContent: {
    maxHeight: "17.5em",
    height: "17.5em",
    overflowY: "auto",
    textAlign: "left",
    fontSize: "smaller",
  },
  customLiteProfileContent: {
    paddingRight: "5%",
    maxHeight: "22.5em",
    height: "22.5em",
    overflowY: "auto",
    paddingLeft: "5%",
    textAlign: "left",
    fontSize: "smaller",
  },
  noBoxShadow: { boxShadow: "none" },
  noBorderBottom: { borderBottom: "none" },
  viewMore: { color: "#302F2C", cursor: "pointer", fontWeight: "bold" },
  matchedCardHeader: { alignItems: "center", justifyContent: "center" },
  alignWebkitCenter: { textAlign: "-webkit-center" },
  justifyContentSpaceEvenly: { justifyContent: "space-evenly" },
  justifyContentSpaceBetween: { justifyContent: "space-between" },
  verySmallMarginRight: { marginRight: "2%" },
  skillsCardPanel: {
    overflowY: "auto",
  },
  filterGoBtn: { width: "-webkit-fill-available" },
  cursorPointer: {
    cursor: "pointer",
  },
  likeButton: {
    backgroundColor: "#000000 !important",
  },
  historyButton: {
    backgroundColor: "#000000", //'#FBC925',
    color: "#ffffff",
    width: "100%",
    borderRadius: "0px 0px 5px 5px !important",
    fontWeight: "normal !important",
    "&:hover": {
      backgroundColor: "#FBC925 !important",
      color: "#000000 !important",
    },
    justifyContent: "center",
    padding: "3%",
    boxShadow:
      "0px 0px 0px 1px rgb(0 0 0 / 5%), 0px 0px 0px 0px rgb(0 0 0 / 5%), 0px 0px 0px 1px rgb(0 0 0 / 5%)",
  },
  customHighlightsPaper: {
    paddingLeft: "3%",
    paddingRight: "3%",
    paddingBottom: "0",
    boxShadow: "none",
    borderRadius: "10px",
    border: "2px solid #e0e0e0",
    overflowY: "auto",
  },
  smallLeftPadding: { paddingLeft: "8px" },
  equalButtons: {
    width: "unset",
    margin: "0 0",
    borderRadius: "3px !important",
  },
  customEqualButtons: {
    width: "8em",
    margin: "10px !important",
    borderRadius: "3px !important",
  },
  fixedTitleTableHeight: {
    // height: '235px',
    minHeight: "130px",
    display: "flex",
    alignItems: "center",
    overflow: "hidden",
  },
  fixedTableHeight: { height: "120px !important" },
  defaultButton: {
    backgroundColor: "#FFFFFF",
    border: "#000000 solid 1px",
    marginRight: "1%",
  },
  customDialogHeader: {
    maxHeight: "38%",
    height: "38%",
    color: "#000",
    backgroundSize: "cover",
    backgroundImage: `url(${cardHeaderBg})`,
    background: "round",
  },
  noImageAvatar: {
    color: "#FFFFFF",
    fontSize: "large",
    backgroundColor: "#000000",
  },
  cardTitlePopUp: {
    whiteSpace: "no-wrap",
    width: "100%",
  },
  customWidth: { width: "60%" },
  cardName: {
    display: "flex",
    alignItems: "flex-start",
    justifyContent: "space-between",
  },
  breadcrumbCustom: {
    [theme.breakpoints.down("md")]: {
      fontSize: "0.8rem",
    },
    [theme.breakpoints.down("sm")]: {
      fontSize: "0.8rem",
    },
  },
  countryList: {
    maxHeight: "60px",
    "&::-webkit-scrollbar": {
      display: "none",
    },
  },
  noDataNote: {
    height: "calc(100vh - 300px)",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "column",
  },
  customDiversityIcon: {
    fontSize: "18px",
    position: "relative",
    bottom: "45px",
    color: "#FFFFFF",
    left: "4px",
  },
  expCompanyCard: {
    paddingTop: "16px",
    paddingBottom: "16px",
    textAlign: "left",
  },

  expCompanyCardHeadValue: {
    fontWeight: "400",
    color: "#000000",
    fontSize: "16px",
  },
  expCompanyCardHead: { fontWeight: "bold", marginBottom: "5%" },
  ratingIconFilled: {
    color: "#048475",
  },
  ratingIconHover: {
    color: "#048475",
  },
  ratingEmptyIcon: {
    color: "#FFFFFF",
  },
  memberChip: {
    color: "#FFFFFF",
    backgroundColor: "#000000",
    height: "30px",
    borderRadius: "5px",
  },
  externalChip: {
    color: "#FFFFFF",
    backgroundColor: "#048475",
    height: "30px",
    borderRadius: "5px",
  },
  avatarBgColor: {
    backgroundColor: "#000 !important",
  },
  cardAvatar: {
    height: "45px",
    width: "45px",
    backgroundColor: "#000",
  },
  diversityIcon: {
    fontSize: "18px",
    position: "relative",
    bottom: "40px",
    color: "#FFFFFF",
    left: "4px",
  },
  noRightMargin: {
    marginRight: "0px",
  },
  commentBtn: {
    backgroundColor: "#000",
    color: "#fff",
    padding: "5px",
    "&:hover": {
      backgroundColor: "#000 !important",
      color: "#fff !important",
    },
  },
  disabledBtn: {
    backgroundColor: "rgba(0, 0, 0, 0.26)",
    color: "#fff !important",
    padding: "5px",
    "&:hover": {
      backgroundColor: "grey !important",
      color: "#fff !important",
    },
  },
  borderBottom: { borderBottom: "1px solid #E5E5E5" },
  moreFont: {
    fontWeight: "bold",
  },
  underline: {
    textDecoration: "underline",
  },
  cursorDefault: {
    cursor: "default",
  },
  viewProfileButton: {
    [theme.breakpoints.between("xs", "sm")]: {
      display: "none",
    },
    "&:hover": {
      backgroundColor: "rgb(215, 217, 219) !important",
      color: "#000000 !important",
    },
    padding: "5px 10px 5px 10px",
  },
  IconProfileButton: {
    [theme.breakpoints.between("md", "xl")]: {
      display: "none",
    },
    color: "#000000",
  },
  tableCellPosition: {
    position: "relative",
  },
  chipsFilters: {
    display: "flex",
    alignItems: "center",
    gap: "8px",
    justifyContent: "flex-start",
    padding: 0,
    [theme.breakpoints.down("sm")]: {
      display: "block",
      textAlign: "left",
      "& .MuiChip-clickable": {
        margin: "4px",
      },
    },
  },
  chipsStyle: {
    height: "auto",
    width: "auto",
    padding: "3px 3px 3px 3px",
    [theme.breakpoints.down("sm")]: {
      marginLeft: "3px !important",
    },
  },
});

class PaneLayout extends Component {
  constructor(props) {
    super(props);
    this.myRef = React.createRef();
    this.state = {
      expandedView: true,
      selectedCandidate: null,
      selectedIndex: 0,
      tabValue: null,
      isCommentAPICalled: false,
      comment: "",
      selectedComment: "",
      arrowRef: null,
      openPopper: false,
      anchorEl: null,
      openScheduleInterview: false,
      scheduleInterviewType: "",
      openMore: false,
      openPreview: false,
      openShareProfile: false,
    };
  }

  componentDidUpdate(prevProps) {
    const { candidates, candidateID, tabState } = this.props;
    const params = new URLSearchParams(window.location.search);
    params.get("candidate");
    if (JSON.stringify(prevProps.candidates) !== JSON.stringify(candidates)) {
      if (candidates?.length === 0) {
        history.push(window.location.pathname);
        this.selectCandidate(candidates?.[0], 0, false);
      }
      if (candidates?.length > 0) {
        if (tabState === true) {
          this.selectCandidate(candidates?.[0], 0, true);
        } else {
          const candidate = candidates?.filter(
            (e) => e.candidate_id === candidateID
          );
          if (candidate?.length === 0) {
            this.selectCandidate(
              candidates?.[0],
              0,
              candidates?.length > 0 ? true : false
            );
            return;
          } else {
            if (candidates?.length > 0) {
              this.selectCandidate(candidates?.[0], 0, true);
            }
          }
          if (candidateID) {
            if (candidate?.length > 0) {
              this.selectCandidate(candidate?.[0], 0, true);
            }
          } else {
            if (candidates?.length > 0) {
              this.selectCandidate(candidates?.[0], 0, true);
            }
          }
        }
      }
    }
  }

  componentDidMount() {
    const { dispatch } = this.props;
    const { candidates, candidateID, tabState } = this.props;
    if (tabState === true) {
      this.selectCandidate(candidates?.[0], 0, true);
    } else {
      if (candidates?.length > 0) {
        if (candidateID) {
          const candidate = candidates?.filter(
            (e) => e.candidate_id === candidateID
          );
          if (candidate?.length > 0) {
            this.selectCandidate(candidate?.[0], 0, false);
          }
        } else if (candidates?.length > 0) {
          this.selectCandidate(candidates?.[0], 0, false);
        }
      }
    }
    dispatch(jobsActions.schedulingLinkValues({}));
  }

  selectCandidate = (candidate, index, getComments) => {
    const { activeTab, candidates } = this.props;
    this.setState(
      {
        selectedCandidate: candidate,
        selectedIndex: index,
        tabValue:
          activeTab === 0
            ? "Recommended"
            : activeTab === 1
            ? "Pending"
            : activeTab === 2
            ? "Matched"
            : activeTab === 3
            ? "Removed from queue"
            : null,
      },
      () => {
        if (getComments) {
          this.getComments(candidate?.recommendation_id);
        }
        var myElement = document.getElementById("card-2");
        if (myElement) {
          var topPos = myElement.offsetTop;
          document.getElementById("scrolling_div").scrollTop = topPos;
        }

        const params = new URLSearchParams(window.location.search);
        params.set("candidate", candidate?.candidate_id);
        history.push({
          pathname: window.location.pathname,
          search: candidates.length > 0 && "?" + params.toString(),
        });
      }
    );
  };

  getComments = (recommendationId) => {
    const { jobDetails, dispatch } = this.props;
    let jobId = jobDetails?.id;
    dispatch(jobsActions.getCandidateComments(recommendationId, jobId));
    this.setState({ isCommentAPICalled: true });
  };
  checkJobStatus = () => {
    switch (this.props?.jobDetails?.job_status) {
      case "Close":
      case "Closed":
      case "close":
      case "closed":
        toast.dark("This job is already closed");
        return 1;
      default:
        return 0;
    }
  };

  handleSubmitComment = () => {
    if (this.checkJobStatus() === 1) {
      this.closeConfirmDeleteCommentPopper();
      return;
    }
    let comment = trim(this.state.comment);
    if (
      comment?.trim()?.length === 0 ||
      comment === undefined ||
      comment === null
    ) {
      this.setState({ comment: "" });
      toast.dark("Please add a note to submit");
      return;
    }
    const { dispatch } = this.props;
    let payload = {
      comment: comment,
      recommendation: this.state.selectedCandidate?.recommendation_id,
    };
    dispatch(
      jobsActions.addCandidateComment(payload, this.props?.jobDetails?.id)
    );
    this.closeConfirmDeleteCommentPopper();
    setTimeout(() => {
      this.getComments(this.state.selectedCandidate?.recommendation_id);
      this.setState({ comment: "" });
    }, 100);
  };

  handleDeleteComment = () => {
    const { dispatch } = this.props;
    dispatch(
      jobsActions.deleteCandidateComment(
        this.state.selectedComment,
        this.props.jobDetails?.id
      )
    );
    this.closeConfirmDeleteCommentPopper();
    setTimeout(() => {
      this.getComments(this.state.selectedCandidate?.recommendation_id);
    }, 100);
  };

  openConfirmDeleteCommentPopper = (commentId, event) => {
    if (this.checkJobStatus() === 1) {
      this.closeConfirmDeleteCommentPopper();
      return;
    }
    this.setState({
      openPopper: true,
      anchorEl: event.currentTarget,
      selectedComment: commentId,
    });
  };

  handleArrowRef = (node) => {
    this.setState({
      arrowRef: node,
    });
  };

  closeConfirmDeleteCommentPopper = () => {
    this.setState({ openPopper: false });
  };

  formatDate(date) {
    let time = moment(date).fromNow(true);
    let strDate = time.split(" ");
    let format = "";
    if (strDate[0] === "un" || strDate[0] === "a") {
      strDate[0] = "1";
      format = strDate[0] + strDate[1][0];
      return format;
    }
    format = strDate[0] + strDate[1][0];
    return format;
  }

  showWarning = () => {
    toast.dark(
      "This operation is not allowed when you are logged in as SucceedSmart admin"
    );
  };

  sendScheduleSchedulingLinkCandidate = (scheduleInterviewType) => {
    this.setState({
      openScheduleInterview: true,
      scheduleInterviewType: scheduleInterviewType,
      openMore: false,
    });
  };

  closeScheduleInterview = () => {
    const { dispatch } = this.props;
    dispatch(jobsActions.schedulingLinkValues({}));
    this.setState({
      openScheduleInterview: false,
      scheduleInterviewType: null,
    });
  };

  handlePreview = (values, note) => {
    const { dispatch } = this.props;
    dispatch(jobsActions.schedulingLinkValues(values));
    if (this.state.scheduleInterviewType === "email to coordinator") {
      let payload = {
        option: this.state.scheduleInterviewType,
        first_name: values?.firstname,
        last_name: values?.lastname,
        email: values?.emailTocoordinator,
        is_preview: true,
      };

      this.setState({ openScheduleInterview: false, openPreview: true }, () => {
        dispatch(
          membersActions.postSchedulingEmailToCoordinator(
            this.state.selectedCandidate?.recommendation_id,
            payload
          )
        );
      });
    } else {
      let payload = {
        note: note,
        is_preview: true,
      };
      this.setState({ openScheduleInterview: false, openPreview: true }, () => {
        dispatch(
          membersActions.postSchedulingEmailToCandidate(
            this.state.selectedCandidate?.recommendation_id,
            payload
          )
        );
      });
    }
  };

  sendEmail = () => {
    const { dispatch } = this.props;
    let sendEmailValues = this.props.jobs?.schedulingLinkValues;
    if (this.state.scheduleInterviewType === "email to coordinator") {
      let payload = {
        option: this.state.scheduleInterviewType,
        first_name: sendEmailValues?.firstname,
        last_name: sendEmailValues?.lastname,
        email: sendEmailValues?.emailTocoordinator,
        is_preview: false,
      };
      dispatch(
        membersActions.postSchedulingEmailToCoordinator(
          this.state.selectedCandidate?.recommendation_id,
          payload
        )
      );
    } else {
      let payload = {
        note: draftToHtml(
          convertToRaw(sendEmailValues.editorNotesState.getCurrentContent())
        ),
        is_preview: false,
      };
      dispatch(
        membersActions.postSchedulingEmailToCandidate(
          this.state.selectedCandidate?.recommendation_id,
          payload
        )
      );
    }
    dispatch(jobsActions.schedulingLinkValues({}));
    this.setState({ openPreview: false });
  };

  editPreview = () => {
    this.setState({ openScheduleInterview: true, openPreview: false });
  };

  handleCloseShareProfile = () => {
    this.setState({ openShareProfile: false });
  };

  onClosePreview = () => {
    const { dispatch } = this.props;
    dispatch(jobsActions.schedulingLinkValues({}));
    this.setState({ openPreview: false });
  };

  render() {
    const {
      classes,
      activeTab,
      candidates,
      ExpandedInnerContent,
      createChannel,
      jobDetails,
      handleFullProfileClick,
      viewHistory,
      handleDislikeCandidateRecommendation,
      handleLikeCandidateRecommendation,
    } = this.props;
    let candidateComments = this.props?.jobs?.candidateComments?.comments;
    return (
      <Fragment>
        <Grid container direction="row" spacing={3}>
          {candidates?.length > 0 && (
            <>
              <Grid item xs={12} sm={12} md={3} lg={3} xl={3}>
                <div className={classes.customHeight}>
                  {candidates?.length > 0 &&
                    candidates?.map((candidate, index) => {
                      return (
                        <Card
                          key={candidate?.candidate_id}
                          elevation={
                            this.state.selectedCandidate?.candidate_id ===
                            candidate?.candidate_id
                              ? 2
                              : 1
                          }
                          style={{
                            padding:
                              activeTab !== 2 && activeTab !== 3
                                ? "16px"
                                : "16px",
                            borderRadius: "4px",
                            marginBottom: "10px",
                            border:
                              this.state.selectedCandidate?.candidate_id ===
                              candidate?.candidate_id
                                ? "3px solid #FBC112"
                                : "",
                            cursor: "pointer",
                            boxShadow:
                              this.state.selectedCandidate?.candidate_id ===
                              candidate?.candidate_id
                                ? "none"
                                : "0px 3px 1px -2px rgb(0 0 0 / 20%), 0px 2px 2px 0px rgb(0 0 0 / 14%), 0px 1px 5px 0px rgb(0 0 0 / 12%)",
                          }}
                          onClick={() =>
                            this.selectCandidate(candidate, index, true)
                          }
                        >
                          {
                            <CardHeader
                              classes={{ avatar: classes.noRightMargin }}
                              avatar={
                                candidate?.profile_image !== undefined && (
                                  <Avatar
                                    src={candidate?.profile_image || "/"}
                                    variant="circular"
                                    alt={capitalize(
                                      candidate?.candidate_fname +
                                        candidate?.candidate_lname
                                    )}
                                    className={classNames(
                                      classes.cardAvatar,
                                      classes.cursorPointer
                                    )}
                                    onClick={() =>
                                      handleFullProfileClick(
                                        jobDetails,
                                        candidate?.candidate_id,
                                        candidate?.recommendation_id,
                                        candidate?.is_interested_to_archive,
                                        candidate?.job_status
                                      )
                                    }
                                    style={{
                                      marginRight: "8px",
                                      marginLeft:
                                        candidate?.is_diversity &&
                                        this.props?.authentication
                                          ?.recruiterStatusDetails
                                          ?.show_diversity
                                          ? "0px"
                                          : "0px",
                                    }}
                                  ></Avatar>
                                )
                              }
                              title={
                                <div style={{ position: "relative" }}>
                                  {candidate?.is_diversity &&
                                    this.props?.authentication
                                      ?.recruiterStatusDetails
                                      ?.show_diversity &&
                                    activeTab !== 2 &&
                                    activeTab !== 3 && (
                                      <span
                                        className="diversity-ribbon"
                                        style={{
                                          top: "-26px",
                                          left:
                                            activeTab !== 2
                                              ? candidate?.profile_image !==
                                                undefined
                                                ? "-70px"
                                                : "-16px"
                                              : "-70px",
                                          position: "absolute",
                                        }}
                                      >
                                        <AssignmentIndIcon
                                          className={
                                            classes.customDiversityIcon
                                          }
                                        ></AssignmentIndIcon>
                                      </span>
                                    )}
                                  <div
                                    className={classNames(
                                      classes.justifyContentSpaceBetween
                                    )}
                                    style={{ alignItems: "center" }}
                                  >
                                    {candidate?.candidate_fname &&
                                      candidate?.candidate_lname && (
                                        <Typography
                                          variant="h5"
                                          className={classNames(
                                            classes.colorDefault,
                                            classes.boldFont,
                                            classes.cardTitlePopUp
                                          )}
                                        >
                                          {capitalize(
                                            candidate?.candidate_fname +
                                              " " +
                                              candidate?.candidate_lname
                                          )}
                                        </Typography>
                                      )}
                                    {(candidate?.match_based_on?.title &&
                                      candidate?.match_based_on?.title.length >
                                        0) ||
                                    (candidate?.match_based_on
                                      ?.previous_titles &&
                                      candidate?.match_based_on?.previous_titles
                                        .length > 0) ? (
                                      <Tooltip
                                        arrow
                                        disableFocusListener
                                        title={
                                          capitalize(candidate.title) || "-"
                                        }
                                        placement="bottom"
                                      >
                                        <Typography
                                          variant={activeTab !== 2 ? "h6" : ""}
                                          style={{
                                            marginLeft:
                                              activeTab !== 2 &&
                                              candidate?.is_diversity &&
                                              this.props?.authentication
                                                ?.recruiterStatusDetails
                                                ?.show_diversity
                                                ? "10px"
                                                : "0px",
                                          }}
                                          className={classes.boldFont}
                                        >
                                          <MatchedCriteria
                                            width="13"
                                            height="13"
                                            title="Profile Match"
                                            style={{ marginRight: "5px" }}
                                          />
                                          {truncate(
                                            capitalize(candidate?.title),
                                            30
                                          ) || "-"}
                                        </Typography>
                                      </Tooltip>
                                    ) : (
                                      <Tooltip
                                        arrow
                                        disableFocusListener
                                        title={
                                          capitalize(candidate?.title) || "-"
                                        }
                                        placement="bottom"
                                      >
                                        <Typography
                                          variant={activeTab !== 2 ? "h6" : ""}
                                          style={{
                                            marginLeft:
                                              activeTab !== 2 ? "8px" : "0px",
                                          }}
                                          className={classes.boldFont}
                                        >
                                          {truncate(
                                            capitalize(candidate.title),
                                            30
                                          ) || "-"}
                                        </Typography>
                                      </Tooltip>
                                    )}
                                  </div>
                                </div>
                              }
                              subheader={
                                <Fragment>
                                  <Grid container>
                                    <Grid
                                      item
                                      xs={12}
                                      sm={12}
                                      md={12}
                                      lg={12}
                                      xl={12}
                                    >
                                      <Typography color="textPrimary">
                                        {candidate?.match_based_on?.location ? (
                                          <MatchedCriteria
                                            color="#00DCAF"
                                            title="Profile Match"
                                            style={{ marginRight: "5px" }}
                                          />
                                        ) : null}
                                        <LocationOnIcon
                                          className={classNames(
                                            classes.largeFont,
                                            classes.alignBaslineMiddle,
                                            classes.colorDefault
                                          )}
                                        ></LocationOnIcon>
                                        <span
                                          className={classNames(
                                            classes.alignBaslineMiddle,
                                            classes.verySmallMarginLeft,
                                            classes.font14p
                                          )}
                                        >
                                          {capitalize(
                                            candidate?.location?.city || "-"
                                          ) +
                                            ", " +
                                            capitalize(
                                              candidate?.location?.state || "-"
                                            ) +
                                            ", " +
                                            capitalize(
                                              candidate?.location?.country ||
                                                "-"
                                            )}
                                        </span>
                                      </Typography>
                                      <Typography
                                        className={classNames(
                                          classes.font14p,
                                          classes.mediumTopMargin,
                                          classes.colorDefault
                                        )}
                                        style={{ marginTop: "2%" }}
                                      >
                                        {candidate?.match_based_on
                                          ?.experience &&
                                          candidate?.match_based_on?.experience
                                            .length > 0 && (
                                            <MatchedCriteria
                                              width="13"
                                              height="13"
                                              title="Profile Match"
                                              style={{ marginRight: "5px" }}
                                            />
                                          )}
                                        <b>Total Years of Experience: </b>{" "}
                                        {candidate?.additional_info
                                          ?.experience || "-"}
                                      </Typography>
                                      <Typography
                                        className={classNames(
                                          classes.smallTopMargin,
                                          classes.font14p,
                                          classes.colorDefault
                                        )}
                                      >
                                        {candidate?.match_based_on?.level &&
                                          candidate?.match_based_on?.level
                                            .length > 0 && (
                                            <MatchedCriteria
                                              width="13"
                                              height="13"
                                              title="Profile Match"
                                              style={{ marginRight: "5px" }}
                                            />
                                          )}
                                        <b>Highest Level: </b>{" "}
                                        {candidate?.additional_info?.level ||
                                          "-"}
                                      </Typography>
                                      <Typography
                                        className={classNames(
                                          classes.smallTopMargin,
                                          classes.font14p,
                                          classes.colorDefault
                                        )}
                                      >
                                        <b>Largest Team Size Managed: </b>{" "}
                                        {candidate?.additional_info
                                          ?.team_size || "-"}
                                      </Typography>
                                      <Typography
                                        className={classNames(
                                          classes.smallTopMargin,
                                          classes.font14p,
                                          classes.colorDefault
                                        )}
                                      >
                                        {candidate?.match_based_on?.education &&
                                          candidate?.match_based_on?.education
                                            .length > 0 && (
                                            <MatchedCriteria
                                              width="13"
                                              height="13"
                                              title="Profile Match"
                                              style={{ marginRight: "5px" }}
                                            />
                                          )}
                                        <b>Highest Education: </b>
                                        <Tooltip
                                          arrow
                                          title={(candidate?.additional_info
                                            ?.education?.degree ?? candidate
                                            ? candidate?.additional_info
                                                ?.education?.degree ?? candidate
                                            : "-" +
                                              (candidate?.additional_info
                                                ?.education?.field_of_study
                                                ? "(" +
                                                  candidate?.additional_info
                                                    ?.education
                                                    ?.field_of_study +
                                                  ")"
                                                : "")
                                          ).toString()}
                                        >
                                          <Typography component="span">
                                            {truncate(
                                              candidate?.additional_info
                                                ?.education?.degree
                                                ? candidate?.additional_info
                                                    ?.education?.degree
                                                : "-" +
                                                    (candidate?.additional_info
                                                      ?.education
                                                      ?.field_of_study
                                                      ? "(" +
                                                        candidate
                                                          ?.additional_info
                                                          ?.education
                                                          ?.field_of_study +
                                                        ")"
                                                      : ""),
                                              30
                                            )}
                                          </Typography>
                                        </Tooltip>
                                      </Typography>
                                      <Typography
                                        className={classNames(
                                          classes.smallTopMargin,
                                          classes.font14p,
                                          classes.colorDefault
                                        )}
                                      >
                                        <b>Countries Worked In: </b>
                                        <Tooltip
                                          arrow
                                          title={
                                            candidate?.additional_info
                                              ?.countries_worked_in || "-"
                                          }
                                        >
                                          <Typography
                                            component="span"
                                            className={classes.font14p}
                                          >
                                            {candidate?.additional_info
                                              ?.countries_worked_in
                                              ? truncate(
                                                  candidate?.additional_info
                                                    ?.countries_worked_in,
                                                  100
                                                )
                                              : "-"}
                                          </Typography>
                                        </Tooltip>
                                      </Typography>
                                    </Grid>
                                  </Grid>
                                </Fragment>
                              }
                              className={classes.customCardHeader}
                              style={{
                                marginTop:
                                  candidate?.is_diversity &&
                                  this.props?.authentication
                                    ?.recruiterStatusDetails?.show_diversity
                                    ? "10px"
                                    : "0px",
                              }}
                            ></CardHeader>
                          }
                        </Card>
                      );
                    })}
                </div>
              </Grid>

              <Grid item xs={12} sm={12} md={5} lg={6} xl={6}>
                {this.state.selectedCandidate && (
                  <div
                    style={{
                      marginTop: "1%",
                    }}
                    className={classes.customHeight}
                    id="scrolling_div"
                  >
                    <Card
                      ref={this.myRef}
                      key={this.state.selectedIndex}
                      className={classes.expandedLiteProfileCardForMatched}
                    >
                      <CardHeader
                        title={
                          <TableContainer
                            className={classes.fixedTitleTableHeight}
                          >
                            {this.state.selectedCandidate?.is_diversity &&
                              this.props?.authentication?.recruiterStatusDetails
                                ?.show_diversity &&
                              activeTab !== 2 &&
                              activeTab !== 3 && (
                                <span
                                  className="diversity-ribbon"
                                  style={{
                                    top: "0px",
                                    left: "0px",
                                    position: "absolute",
                                  }}
                                >
                                  <AssignmentIndIcon
                                    className={classes.customDiversityIcon}
                                  ></AssignmentIndIcon>
                                </span>
                              )}
                            <Table aria-label="simple table" id="card-2">
                              <TableBody>
                                <TableRow>
                                  <TableCell
                                    className={classNames(
                                      classes.colorDefault,
                                      classes.font12p,
                                      classes.customPadding
                                    )}
                                    align="left"
                                  >
                                    <Grid container>
                                      <Grid
                                        item
                                        xs={12}
                                        sm={12}
                                        md={12}
                                        lg={12}
                                        xl={12}
                                      >
                                        <div
                                          className={classes.displayFlex}
                                          style={{ alignItems: "flex-start" }}
                                        >
                                          {this.state.selectedCandidate
                                            ?.profile_image !== undefined && (
                                            <Avatar
                                              src={
                                                this.state.selectedCandidate
                                                  ?.profile_image || "/"
                                              }
                                              variant="circular"
                                              alt={capitalize(
                                                this.state.selectedCandidate
                                                  ?.candidate_fname +
                                                  this.state.selectedCandidate
                                                    ?.candidate_lname
                                              )}
                                              className={classNames(
                                                classes.xLargeAvatar,
                                                classes.cursorPointer
                                              )}
                                              onClick={() =>
                                                handleFullProfileClick(
                                                  jobDetails,
                                                  this.state.selectedCandidate
                                                    ?.candidate_id,
                                                  this.state.selectedCandidate
                                                    ?.recommendation_id,
                                                  this.state.selectedCandidate
                                                    ?.is_interested_to_archive,
                                                  this.state.selectedCandidate
                                                    ?.job_status
                                                )
                                              }
                                              style={{ marginRight: "10px" }}
                                            ></Avatar>
                                          )}
                                          <Typography
                                            variant="h5"
                                            className={classNames(
                                              classes.cardTitlePopUp
                                            )}
                                          >
                                            <Typography
                                              variant="h5"
                                              component="div"
                                              className={classes.cardName}
                                            >
                                              <span
                                                className={classNames(
                                                  classes.underline,
                                                  classes.cursorPointer,
                                                  classes.boldFont,
                                                  classes.colorDefault,
                                                  classes.customWidth
                                                )}
                                                onClick={() =>
                                                  handleFullProfileClick(
                                                    jobDetails,
                                                    this.state.selectedCandidate
                                                      ?.candidate_id,
                                                    this.state.selectedCandidate
                                                      ?.recommendation_id,
                                                    this.state.selectedCandidate
                                                      ?.is_interested_to_archive,
                                                    this.state.selectedCandidate
                                                      ?.job_status
                                                  )
                                                }
                                              >
                                                {this.state.selectedCandidate
                                                  ?.candidate_fname &&
                                                  this.state.selectedCandidate
                                                    ?.candidate_lname &&
                                                  capitalize(
                                                    this.state.selectedCandidate
                                                      ?.candidate_fname +
                                                      " " +
                                                      this.state
                                                        .selectedCandidate
                                                        ?.candidate_lname
                                                  )}
                                              </span>
                                              {(activeTab === 2 ||
                                                (activeTab === 3 &&
                                                  this.state.selectedCandidate
                                                    ?.is_interested_to_archive)) && (
                                                <>
                                                  <Button
                                                    className={
                                                      classes.viewProfileButton
                                                    }
                                                    disableElevation
                                                    variant="contained"
                                                    color="primary"
                                                    onClick={() =>
                                                      handleFullProfileClick(
                                                        jobDetails,
                                                        this.state
                                                          .selectedCandidate
                                                          ?.candidate_id,
                                                        this.state
                                                          .selectedCandidate
                                                          ?.recommendation_id,
                                                        this.state
                                                          .selectedCandidate
                                                          ?.is_interested_to_archive,
                                                        this.state
                                                          .selectedCandidate
                                                          ?.job_status
                                                      )
                                                    }
                                                  >
                                                    View Full Profile
                                                  </Button>
                                                  <Tooltip
                                                    title="View Full Profile"
                                                    arrow
                                                    placement="bottom"
                                                  >
                                                    <IconButton
                                                      onClick={() =>
                                                        handleFullProfileClick(
                                                          jobDetails,
                                                          this.state
                                                            .selectedCandidate
                                                            ?.candidate_id,
                                                          this.state
                                                            .selectedCandidate
                                                            ?.recommendation_id,
                                                          this.state
                                                            .selectedCandidate
                                                            ?.is_interested_to_archive,
                                                          this.state
                                                            .selectedCandidate
                                                            ?.job_status
                                                        )
                                                      }
                                                      className={
                                                        classes.IconProfileButton
                                                      }
                                                    >
                                                      <AccountCircleIcon />
                                                    </IconButton>
                                                  </Tooltip>
                                                </>
                                              )}
                                            </Typography>
                                            <Typography
                                              className={classNames(
                                                classes.colorDefault,
                                                classes.smallTopMargin
                                              )}
                                            >
                                              {(this.state.selectedCandidate
                                                ?.match_based_on?.title &&
                                                this.state.selectedCandidate
                                                  ?.match_based_on?.title
                                                  .length > 0) ||
                                              (this.state.selectedCandidate
                                                ?.match_based_on
                                                ?.previous_titles &&
                                                this.state.selectedCandidate
                                                  ?.match_based_on
                                                  ?.previous_titles.length >
                                                  0) ? (
                                                <Tooltip
                                                  arrow
                                                  disableFocusListener
                                                  title={
                                                    capitalize(
                                                      this.state
                                                        .selectedCandidate
                                                        ?.title
                                                    ) +
                                                      (this.state
                                                        .selectedCandidate
                                                        ?.company_name
                                                        ? " at " +
                                                          this.state
                                                            .selectedCandidate
                                                            ?.company_name
                                                        : "") || "-"
                                                  }
                                                  placement="bottom-start"
                                                >
                                                  <Typography
                                                    variant={
                                                      activeTab !== 2
                                                        ? "h6"
                                                        : ""
                                                    }
                                                    className={classes.boldFont}
                                                    style={
                                                      this.state
                                                        .selectedCandidate
                                                        ?.is_diversity
                                                        ? { marginLeft: "8px" }
                                                        : {}
                                                    }
                                                  >
                                                    <MatchedCriteria
                                                      width="13"
                                                      height="13"
                                                      title="Profile Match"
                                                      style={{
                                                        marginRight: "5px",
                                                      }}
                                                    />
                                                    {truncate(
                                                      capitalize(
                                                        this.state
                                                          .selectedCandidate
                                                          ?.title
                                                      ) +
                                                        (this.state
                                                          .selectedCandidate
                                                          ?.company_name
                                                          ? " at " +
                                                            this.state
                                                              .selectedCandidate
                                                              ?.company_name
                                                          : ""),
                                                      50
                                                    ) || "-"}
                                                  </Typography>
                                                </Tooltip>
                                              ) : (
                                                <Tooltip
                                                  arrow
                                                  disableFocusListener
                                                  title={
                                                    capitalize(
                                                      this.state
                                                        .selectedCandidate
                                                        ?.title
                                                    ) +
                                                      (this.state
                                                        .selectedCandidate
                                                        ?.company_name
                                                        ? " at " +
                                                          this.state
                                                            .selectedCandidate
                                                            ?.company_name
                                                        : "") || "-"
                                                  }
                                                  placement="bottom-start"
                                                >
                                                  <Typography
                                                    variant={
                                                      activeTab !== 2
                                                        ? "h6"
                                                        : ""
                                                    }
                                                    className={classes.boldFont}
                                                  >
                                                    {truncate(
                                                      capitalize(
                                                        this.state
                                                          .selectedCandidate
                                                          ?.title
                                                      ) +
                                                        (this.state
                                                          .selectedCandidate
                                                          ?.company_name
                                                          ? " at " +
                                                            this.state
                                                              .selectedCandidate
                                                              ?.company_name
                                                          : ""),
                                                      50
                                                    ) || "-"}
                                                  </Typography>
                                                </Tooltip>
                                              )}
                                            </Typography>
                                            <Typography color="textPrimary">
                                              {this.state.selectedCandidate
                                                ?.match_based_on?.location ? (
                                                <MatchedCriteria
                                                  color="#00DCAF"
                                                  title="Profile Match"
                                                  style={{ marginRight: "5px" }}
                                                />
                                              ) : null}
                                              <LocationOnIcon
                                                className={classNames(
                                                  classes.largeFont,
                                                  classes.alignBaslineMiddle,
                                                  classes.colorDefault
                                                )}
                                                viewBox="5 0 24 24"
                                              ></LocationOnIcon>
                                              <span
                                                className={classNames(
                                                  classes.alignBaslineMiddle,
                                                  classes.verySmallMarginLeft,
                                                  classes.font14p
                                                )}
                                              >
                                                {capitalize(
                                                  this.state.selectedCandidate
                                                    ?.location?.city || "-"
                                                ) +
                                                  ", " +
                                                  capitalize(
                                                    this.state.selectedCandidate
                                                      ?.location?.state || "-"
                                                  ) +
                                                  ", " +
                                                  capitalize(
                                                    this.state.selectedCandidate
                                                      ?.location?.country || "-"
                                                  )}
                                              </span>
                                            </Typography>

                                            <Typography
                                              className={classNames(
                                                classes.mediumTopMargin,
                                                classes.font14p,
                                                classes.colorDefault
                                              )}
                                            >
                                              {this.state.selectedCandidate
                                                ?.match_based_on?.experience &&
                                                this.state.selectedCandidate
                                                  ?.match_based_on?.experience
                                                  .length > 0 && (
                                                  <MatchedCriteria
                                                    width="13"
                                                    height="13"
                                                    title="Profile Match"
                                                    style={{
                                                      marginRight: "5px",
                                                    }}
                                                  />
                                                )}
                                              <b>Total Years of Experience: </b>{" "}
                                              {this.state.selectedCandidate
                                                ?.additional_info?.experience ||
                                                "-"}
                                            </Typography>
                                            <Typography
                                              className={classNames(
                                                classes.smallTopMargin,
                                                classes.font14p,
                                                classes.colorDefault
                                              )}
                                            >
                                              {this.state.selectedCandidate
                                                ?.match_based_on?.level &&
                                                this.state.selectedCandidate
                                                  ?.match_based_on?.level
                                                  .length > 0 && (
                                                  <MatchedCriteria
                                                    width="13"
                                                    height="13"
                                                    title="Profile Match"
                                                    style={{
                                                      marginRight: "5px",
                                                    }}
                                                  />
                                                )}
                                              <b>Highest Level: </b>{" "}
                                              {this.state.selectedCandidate
                                                ?.additional_info?.level || "-"}
                                            </Typography>
                                            <Typography
                                              className={classNames(
                                                classes.smallTopMargin,
                                                classes.font14p,
                                                classes.colorDefault
                                              )}
                                            >
                                              <b>Largest Team Size Managed: </b>{" "}
                                              {this.state.selectedCandidate
                                                ?.additional_info?.team_size ||
                                                "-"}
                                            </Typography>
                                            <Typography
                                              className={classNames(
                                                classes.smallTopMargin,
                                                classes.font14p,
                                                classes.colorDefault
                                              )}
                                            >
                                              {this.state.selectedCandidate
                                                ?.match_based_on?.education &&
                                                this.state.selectedCandidate
                                                  ?.match_based_on?.education
                                                  .length > 0 && (
                                                  <MatchedCriteria
                                                    width="13"
                                                    height="13"
                                                    title="Profile Match"
                                                    style={{
                                                      marginRight: "5px",
                                                    }}
                                                  />
                                                )}
                                              <b>Highest Education: </b>
                                              <Tooltip
                                                arrow
                                                title={(this.state
                                                  .selectedCandidate
                                                  ?.additional_info?.education
                                                  ?.degree ??
                                                this.state.selectedCandidate
                                                  ? this.state.selectedCandidate
                                                      ?.additional_info
                                                      ?.education?.degree ??
                                                    this.state.selectedCandidate
                                                  : "-" +
                                                    (this.state
                                                      .selectedCandidate
                                                      ?.additional_info
                                                      ?.education
                                                      ?.field_of_study
                                                      ? "(" +
                                                        this.state
                                                          .selectedCandidate
                                                          ?.additional_info
                                                          ?.education
                                                          ?.field_of_study +
                                                        ")"
                                                      : "")
                                                ).toString()}
                                              >
                                                <Typography component="span">
                                                  {truncate(
                                                    this.state.selectedCandidate
                                                      ?.additional_info
                                                      ?.education?.degree
                                                      ? this.state
                                                          .selectedCandidate
                                                          ?.additional_info
                                                          ?.education?.degree
                                                      : "-" +
                                                          (this.state
                                                            .selectedCandidate
                                                            ?.additional_info
                                                            ?.education
                                                            ?.field_of_study
                                                            ? "(" +
                                                              this.state
                                                                .selectedCandidate
                                                                ?.additional_info
                                                                ?.education
                                                                ?.field_of_study +
                                                              ")"
                                                            : ""),
                                                    50
                                                  )}
                                                </Typography>
                                              </Tooltip>
                                            </Typography>
                                            <Typography
                                              className={classNames(
                                                classes.smallTopMargin,
                                                classes.font14p,
                                                classes.colorDefault
                                              )}
                                            >
                                              <b>Countries Worked In: </b>
                                              <Tooltip
                                                arrow
                                                title={
                                                  this.state.selectedCandidate
                                                    ?.additional_info
                                                    ?.countries_worked_in || "-"
                                                }
                                              >
                                                <Typography
                                                  component="span"
                                                  className={classes.font14p}
                                                >
                                                  {this.state.selectedCandidate
                                                    ?.additional_info
                                                    ?.countries_worked_in
                                                    ? truncate(
                                                        this.state
                                                          .selectedCandidate
                                                          ?.additional_info
                                                          ?.countries_worked_in,
                                                        100
                                                      )
                                                    : "-"}
                                                </Typography>
                                              </Tooltip>
                                            </Typography>
                                          </Typography>
                                        </div>
                                      </Grid>
                                    </Grid>
                                  </TableCell>
                                  {/* <TableCell className={classes.tableCellPosition}>
                                                                 </TableCell> */}
                                </TableRow>
                              </TableBody>
                            </Table>
                          </TableContainer>
                        }
                        className={classes.expandedMatchedCustomCardHeader}
                        style={{ position: "relative" }}
                      ></CardHeader>
                      <CardContent
                        className={
                          classes.expandedLiteProfileCardContentForMatched
                        }
                      >
                        <ExpandedInnerContent
                          index={this.state.selectedIndex}
                          tab={this.state.tabValue}
                          candidate={this.state.selectedCandidate}
                        />
                      </CardContent>
                      {(activeTab === 2 ||
                        (activeTab === 3 &&
                          this.state.selectedCandidate
                            ?.is_interested_to_archive)) && (
                        <CardActions className={classes.justifyContentCenter}>
                          <Button
                            className={classes.equalButtons}
                            size="small"
                            variant="contained"
                            color="primary"
                            disableElevation
                            onClick={() =>
                              handleFullProfileClick(
                                jobDetails,
                                this.state.selectedCandidate?.candidate_id,
                                this.state.selectedCandidate?.recommendation_id,
                                this.state.selectedCandidate
                                  ?.is_interested_to_archive,
                                this.state.selectedCandidate?.job_status
                              )
                            }
                          >
                            View Full Profile
                          </Button>
                          {!secureLocalStorage.getItem("g_u") &&
                            jobDetails?.job_type === "Normal" && (
                              <Button
                                className={classNames(
                                  classes.equalButtons,
                                  classes.defaultButton
                                )}
                                size="small"
                                variant="contained"
                                disableElevation
                                onClick={(event) =>
                                  createChannel(
                                    jobDetails,
                                    this.state.selectedCandidate?.candidate_id,
                                    event
                                  )
                                }
                              >
                                Send Message
                              </Button>
                            )}
                        </CardActions>
                      )}
                      <CardActions style={{ paddingTop: "0" }}>
                        <div
                          className={classes.textAlignLeft}
                          style={{
                            display: "flex",
                            alignItems: "center",
                            paddingLeft: "16px",
                          }}
                        >
                          <MatchedCriteria
                            color="#00DCAF"
                            className={classNames(
                              classes.verySmallFont,
                              classes.alignBaslineMiddle
                            )}
                          ></MatchedCriteria>
                          <Typography
                            className={classNames(
                              classes.verySmallMarginLeft,
                              classes.font10p,
                              classes.alignBaslineMiddle
                            )}
                            component="span"
                          >
                            Profile Match
                          </Typography>
                        </div>
                      </CardActions>
                    </Card>
                    <Button
                      className={classNames(
                        classes.floatLeft,
                        classes.historyButton
                      )}
                      onClick={(event) => {
                        viewHistory(
                          this.state.selectedCandidate?.recommendation_id,
                          event
                        );
                      }}
                    >
                      VIEW RECOMMENDATION HISTORY
                    </Button>
                  </div>
                )}
              </Grid>
              <Grid item xs={12} sm={12} md={4} lg={3} xl={3}>
                <div className={classes.customHeight}>
                  {activeTab === 0 && (
                    <>
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "space-between",
                          marginBottom: "10px",
                        }}
                      >
                        <Typography align="left">
                          Interested in this Candidate?
                        </Typography>
                        {/* <Tooltip arrow disableFocusListener title={"Content TBD"} placement="left-start">
                        <Help />
                      </Tooltip> */}
                      </div>
                      <div
                        className={classNames(
                          classes.mediumBottomMargin,
                          classes.customButtonDisplayInterested
                        )}
                      >
                        <Button
                          className={classes.customMinWidth}
                          variant="contained"
                          color="primary"
                          disableElevation
                          startIcon={<Close />}
                          onClick={(event) =>
                            handleDislikeCandidateRecommendation(
                              jobDetails,
                              this.state.selectedCandidate,
                              event
                            )
                          }
                        >
                          Not Interested
                        </Button>{" "}
                        &nbsp;&nbsp;&nbsp;&nbsp;
                        <Button
                          className={classes.customMinWidth}
                          variant="contained"
                          color="primary"
                          disableElevation
                          startIcon={<Check />}
                          onClick={() =>
                            handleLikeCandidateRecommendation(
                              jobDetails,
                              this.state.selectedCandidate
                            )
                          }
                        >
                          Interested
                        </Button>
                      </div>
                      <Divider />
                    </>
                  )}
                  {(activeTab === 2 ||
                    (activeTab === 3 &&
                      this.state.selectedCandidate
                        ?.is_interested_to_archive)) && (
                    <>
                      <div
                        className={classNames(
                          classes.mediumBottomMargin,
                          classes.customButtonDisplay
                        )}
                      >
                        {!secureLocalStorage.getItem("g_u") && (
                          <Button
                            size="small"
                            className={classNames(classes.equalButtons)}
                            color="primary"
                            variant="contained"
                            disableElevation
                            onClick={() =>
                              !secureLocalStorage.getItem("g_u")
                                ? this.sendScheduleSchedulingLinkCandidate(
                                    "email to candidate"
                                  )
                                : this.showWarning()
                            }
                          >
                            Email Candidate
                          </Button>
                        )}
                        <Button
                          disableElevation
                          variant="outlined"
                          size="small"
                          className={classes.smallMarginLeft}
                          color="primary"
                          onClick={() =>
                            !secureLocalStorage.getItem("g_u")
                              ? this.sendScheduleSchedulingLinkCandidate(
                                  "email to coordinator"
                                )
                              : this.showWarning()
                          }
                        >
                          Email Coordinator
                        </Button>
                        <Tooltip title={"More"} arrow placement="bottom">
                          <IconButton
                            className={classes.smallMarginLeft}
                            size="medium"
                            onClick={(e) => {
                              this.setState({
                                openMore: !this.state.openMore,
                                anchorEl: e.currentTarget,
                              });
                            }}
                          >
                            <MoreIcon />
                          </IconButton>
                        </Tooltip>
                      </div>
                      <div style={{ display: "flex", alignItems: "left" }}>
                        <Popper
                          open={this.state.openMore}
                          anchorEl={this.state.anchorEl}
                          placement="bottom"
                          transition
                        >
                          {({ TransitionProps }) => (
                            <Fade {...TransitionProps}>
                              <Paper>
                                <ClickAwayListener
                                  onClickAway={() =>
                                    this.setState({ openMore: false })
                                  }
                                >
                                  <MenuList>
                                    {!secureLocalStorage.getItem("g_u") &&
                                      jobDetails?.job_type === "Normal" && (
                                        <MenuItem
                                          className={classes.borderBottom}
                                          onClick={(event) =>
                                            createChannel(
                                              jobDetails,
                                              this.state.selectedCandidate
                                                ?.candidate_id,
                                              event
                                            )
                                          }
                                        >
                                          <ListItemIcon>
                                            <ChatIcon fontSize="small" />
                                          </ListItemIcon>
                                          <Typography
                                            className={classes.moreFont}
                                          >
                                            Message Candidate
                                          </Typography>
                                        </MenuItem>
                                      )}
                                    {this.state.selectedCandidate
                                      ?.candidate_contact && (
                                      <MenuItem
                                        className={classNames(
                                          classes.borderBottom,
                                          classes.cursorDefault
                                        )}
                                      >
                                        <ListItemIcon>
                                          <PhoneIcon fontSize="small" />
                                        </ListItemIcon>
                                        <Typography
                                          className={classes.moreFont}
                                        >
                                          {
                                            this.state.selectedCandidate
                                              ?.candidate_contact
                                          }
                                        </Typography>
                                      </MenuItem>
                                    )}
                                    <MenuItem
                                      onClick={() =>
                                        handleFullProfileClick(
                                          jobDetails,
                                          this.state.selectedCandidate
                                            ?.candidate_id,
                                          this.state.selectedCandidate
                                            ?.recommendation_id,
                                          this.state.selectedCandidate
                                            ?.is_interested_to_archive,
                                          this.state.selectedCandidate
                                            ?.job_status
                                        )
                                      }
                                    >
                                      <ListItemIcon>
                                        <AccountCircleIcon fontSize="small" />
                                      </ListItemIcon>
                                      <Typography className={classes.moreFont}>
                                        View Profile
                                      </Typography>
                                    </MenuItem>
                                    <MenuItem
                                      onClick={() =>
                                        this.setState({
                                          openShareProfile: true,
                                          openMore: false,
                                        })
                                      }
                                    >
                                      <ListItemIcon>
                                        <ShareIcon fontSize="small" />
                                      </ListItemIcon>
                                      <Typography className={classes.moreFont}>
                                        Share Profile
                                      </Typography>
                                    </MenuItem>
                                  </MenuList>
                                </ClickAwayListener>
                              </Paper>
                            </Fade>
                          )}
                        </Popper>
                      </div>
                      <Divider />
                    </>
                  )}
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "space-between",
                      marginTop: activeTab === 0 ? "0px" : "12px",
                    }}
                  >
                    <Typography>
                      <b>Internal Notes</b>
                    </Typography>
                  </div>
                  <TextareaAutosize
                    style={{
                      height: "20vh",
                      overflow: "hidden",
                      marginTop: "12px",
                      marginBottom: "4px",
                      width: "100%",
                      resize: "none",
                      padding: "8px",
                      fontFamily: "Public Sans",
                      borderRadius: "5px",
                      overflowY: "auto",
                    }}
                    value={this.state.comment}
                    placeholder="Enter your notes here..."
                    onChange={(event) =>
                      this.setState({ comment: event.target.value })
                    }
                  />
                  <div style={{ textAlign: "left", marginTop: "8px" }}>
                    <Button
                      variant="outlined"
                      size="medium"
                      color="primary"
                      disableElevation
                      style={{ textAlign: "left", minWidth: "8em" }}
                      disabled={this.state.comment === ""}
                      onClick={() => this.handleSubmitComment()}
                    >
                      Submit
                    </Button>
                  </div>
                  {/* <Divider style={{ margin: '20px 0px' }}></Divider> */}
                  <div
                    style={{ minHeight: "200px", margin: "32px 0px 0px 0px" }}
                  >
                    {candidateComments?.length > 0 ? (
                      candidateComments?.map((comment, index) => (
                        <Card
                          elevation={1}
                          style={{
                            boxShadow: "none",
                            padding: "8px 8px",
                            marginTop: index === 0 ? "5px" : "20px",
                          }}
                        >
                          <div
                            style={{
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "space-between",
                            }}
                          >
                            <Typography
                              style={{
                                fontWeight: 700,
                                fontSize: 15,
                              }}
                            >
                              {comment?.recruiter?.name || "Unknown Admin"}
                            </Typography>
                            <Tooltip
                              arrow
                              title={moment(comment?.created_at)
                                .format("MM-DD-YYYY hh:mm A")
                                .toString()}
                            >
                              <Typography
                                style={{
                                  fontWeight: 400,
                                  fontSize: 13,
                                }}
                              >
                                {moment(comment?.created_at).fromNow()}
                              </Typography>
                            </Tooltip>
                          </div>
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "space-between",
                              paddingTop: "3px",
                              alignItems: "flex-start",
                            }}
                          >
                            <Typography
                              align="left"
                              style={{
                                fontWeight: 400,
                                fontSize: 13,
                                lineHeight: "150%",
                              }}
                            >
                              <ViewMore length={50}>
                                {comment?.comment}
                              </ViewMore>
                            </Typography>
                            <Tooltip arrow title="Delete note" placement="top">
                              <IconButton
                                onClick={(event) => {
                                  this.openConfirmDeleteCommentPopper(
                                    comment?.id,
                                    event
                                  );
                                }}
                              >
                                <Delete />
                              </IconButton>
                            </Tooltip>
                            <Popper
                              open={this.state.openPopper}
                              anchorEl={this.state.anchorEl}
                              placement="left"
                              transition
                              style={{ zIndex: 9999 }}
                              modifiers={{
                                flip: {
                                  enabled: true,
                                },
                                arrow: {
                                  enabled: true,
                                  element: this.state.arrowRef,
                                },
                                preventOverflow: {
                                  enabled: "true",
                                  boundariesElement: "scrollParent",
                                },
                              }}
                            >
                              {({ TransitionProps }) => (
                                <Fade {...TransitionProps} timeout={350}>
                                  <Paper style={{ borderRadius: "10px" }}>
                                    {true && (
                                      // <span className={classes.arrow} ref={this.handleArrowRef} />
                                      <span ref={this.handleArrowRef} />
                                    )}
                                    <Grid className={classes.customPopper}>
                                      <Grid
                                        items
                                        xs={12}
                                        sm={12}
                                        md={12}
                                        lg={12}
                                        xl={12}
                                        className={classes.smallBottomMargin}
                                      >
                                        <Typography component="div">
                                          Are you sure you want to delete this
                                          note?
                                        </Typography>
                                      </Grid>
                                      <Grid
                                        className={classNames(
                                          classes.displayFlex,
                                          classes.justifyContentCenter,
                                          classes.textAlignCenter
                                        )}
                                        items
                                        xs={12}
                                        sm={12}
                                        md={12}
                                        lg={12}
                                        xl={12}
                                      >
                                        <Button
                                          size="small"
                                          className={classes.customEqualButtons}
                                          onClick={(event) => {
                                            event.preventDefault();
                                            this.closeConfirmDeleteCommentPopper();
                                          }}
                                          style={{
                                            background: "#D7D9DB",
                                            color: "#000",
                                          }}
                                          variant="contained"
                                          color="primary"
                                          disableElevation
                                        >
                                          Cancel
                                        </Button>
                                        <Button
                                          className={classes.customEqualButtons}
                                          size="small"
                                          variant="contained"
                                          color="primary"
                                          onClick={(event) => {
                                            event.preventDefault();
                                            this.handleDeleteComment();
                                          }}
                                          autoFocus
                                          disableElevation
                                        >
                                          Yes, Delete
                                        </Button>
                                      </Grid>
                                    </Grid>
                                  </Paper>
                                </Fade>
                              )}
                            </Popper>
                          </div>
                        </Card>
                      ))
                    ) : (
                      <Typography
                        className={classes.noDataSmall}
                        color="textSecondary"
                      >
                        No notes found
                      </Typography>
                    )}
                  </div>
                </div>
              </Grid>
            </>
          )}
          {candidates?.length === 0 && (
            <Grid item xs={12} sm={12}>
              {candidates?.length === 0 && (
                <div className={classes.noDataNote}>
                  {activeTab === 0 && (
                    <>
                      <RecommendedEmpty
                        width="230"
                        className={classes.responsive}
                      />
                      <Typography variant="h6" className={classes.boldFont}>
                        You have no recommended candidates.
                      </Typography>
                      <Typography color="textSecondary">
                        Post a job to receive new candidate recommendations.
                      </Typography>
                    </>
                  )}
                  {activeTab === 1 && (
                    <>
                      <PendingEmpty
                        width="230"
                        className={classes.responsive}
                      />
                      <Typography variant="h6" className={classes.boldFont}>
                        No pending candidates here.
                      </Typography>
                      <Typography color="textSecondary">
                        Like a candidate from 'Recommended' to see them here.
                      </Typography>
                    </>
                  )}
                  {activeTab === 2 && (
                    <>
                      <MatchedEmpty
                        width="230"
                        className={classes.responsive}
                      />
                      <Typography variant="h6" className={classes.boldFont}>
                        You have no matched candidates yet.
                      </Typography>
                      <Typography color="textSecondary">
                        Once the candidate accepts your invite to connect, you
                        will see them here.
                      </Typography>
                    </>
                  )}
                  {activeTab === 3 && (
                    <>
                      <ArchivedEmpty
                        width="230"
                        className={classes.responsive}
                      />
                      <Typography variant="h6" className={classes.boldFont}>
                        You have nothing to archive yet.
                      </Typography>
                      <Typography color="textSecondary">
                        When you decline a candidate, you will see them here.
                      </Typography>
                    </>
                  )}
                </div>
              )}
            </Grid>
          )}
        </Grid>
        {/* {this.state.openScheduleInterview ? <> <SchedulingPopup handlePreview={this.handlePreview} selectedCandidate={this.state.selectedCandidate} closeScheduleInterview={this.closeScheduleInterview}   openScheduleInterview={this.state.openScheduleInterview} scheduleInterviewType={this.state.scheduleInterviewType}/> </> : null} */}
        {this.state.openPreview ? (
          <PreviewEmaildailog
            open={this.state.openPreview}
            editPreview={this.editPreview}
            onSend={this.sendEmail}
            onClose={this.onClosePreview}
          />
        ) : null}
        <ShareProfile
          candidateId={this.state.selectedCandidate?.candidate_id}
          recommendationId={this.state.selectedCandidate?.recommendation_id}
          open={this.state.openShareProfile}
          onClose={this.handleCloseShareProfile}
        />
      </Fragment>
    );
  }
}

PaneLayout.propTypes = {
  classes: PropTypes.object.isRequired,
};

function mapStateToProps(state) {
  return state;
}

const connectedPaneLayoutPage = connect(mapStateToProps, null, null, {
  pure: false,
})(withStyles(styles)(PaneLayout));

export { connectedPaneLayoutPage as PaneLayout };
