import { Button, Divider, Grid, Typography } from "@material-ui/core";
import React from "react";
import BadRequestIcon from "../../assets/badRequest.svg";
import { ReactComponent as Logo } from "../../assets/logo_dark.svg";
import { useEffect } from "react";
import { badRequest, deepLink, signout, signoutRecruiter } from "../../actions";
import { useDispatch } from "react-redux";
import { makeStyles } from "@material-ui/core/styles";
import { Link } from "react-router-dom";
import { seo } from "../../helpers";
const useStyles = makeStyles(() => ({
  header: {
    display: "flex",
    justifyContent: "space-between",
  },
  logo: { margin: "10px" },
  loginButton: {
    margin: "10px",
    backgroundColor: "#FBC112",
    color: "#000000",
  },
  imgwithContent: {
    marginTop: "20px",
  },
  contentTypo: {
    fontSize: "24px",
    fontWeight: 400,
    lineHeight: "32px",
    letterSpacing: "0.001em",
  },
  footerContent: {
    marginTop: "50px",
    position: "fixed",
    bottom: 0,
    left: "29%",
  },
}));
const BadRequest = () => {
  let classes = useStyles();
  let dispatch = useDispatch();

  useEffect(() => {
    dispatch(signoutRecruiter());
    dispatch(deepLink(null));
    dispatch(badRequest(true));
    seo({
      title: `Bad request`,
    });
  }, []);

  const handleLoginCLick = () => {
    dispatch(badRequest(false));
    dispatch(signout());
  };

  return (
    <Grid container>
      <Grid
        item
        xs={12}
        sm={12}
        md={12}
        xl={12}
        lg={12}
        className={classes.header}
      >
        <Link href="/">
          <Logo height={30} width={200} className={classes.logo} />
        </Link>
        <Button
          disableElevation
          variant="contained"
          className={classes.loginButton}
          onClick={() => {
            handleLoginCLick();
          }}
        >
          Login
        </Button>
      </Grid>
      <Grid item xs={12} sm={12} md={12} xl={12} lg={12}>
        <Divider />
      </Grid>
      <Grid
        item
        xs={12}
        sm={12}
        md={12}
        xl={12}
        lg={12}
        className={classes.imgwithContent}
      >
        <img height={300} width={500} src={BadRequestIcon}></img>
        <Typography className={classes.contentTypo}>
          Too many bad requests. Please try after Something...
        </Typography>
      </Grid>
      <Grid
        item
        xs={12}
        sm={12}
        md={12}
        xl={12}
        lg={12}
        className={classes.footerContent}
      >
        <Typography align="center">
          © Copyright 2023, SucceedSmart, Inc. All Right Reserved. |
          <a href="https://succeedsmart.com/privacypolicy">Privacy Policy</a>
        </Typography>
      </Grid>
    </Grid>
  );
};

export default BadRequest;
