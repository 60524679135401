import React, { useEffect } from "react";
import { Grid, Typography, Chip, Card, CardContent } from "@material-ui/core";
import classNames from "classnames";
import Pagination from "@material-ui/lab/Pagination";
import { makeStyles } from "@material-ui/core/styles";
import moment from "moment";
import EventIcon from "@material-ui/icons/Event";
import LocationOnIcon from "@material-ui/icons/LocationOn";
import UpdateIcon from "@material-ui/icons/Update";
import { useDispatch, useSelector } from "react-redux";
import cardHeaderBg from "../../assets/lite_profile_bg.png";
import CustomTooltip from "../core/CustomTooltip";
import { ReactComponent as InfoIcon } from "../../assets/info.svg";
import HiredIcon from "../../assets/hiredStamp.svg";
import "./componentstyle.css";
import {
  getJobDetailsData,
  setAllUpdateHeaderCard,
} from "../../actions/jobs.actions";

const useStyles = makeStyles((theme) => ({
  paper: {
    border: "1px solid",
    padding: theme.spacing(1),
    backgroundColor: theme.palette.background.paper,
  },
  largerFont: { fontSize: "larger" },
  verySmallTopMargin: {
    marginTop: "1em",
  },
  displayFlex: {
    display: "flex",
    alignItems: "center",
  },
  customDisplayFlex: {
    display: "flex",
    alignItems: "center",
    paddingBottom: "3px",
    [theme.breakpoints.down("xs")]: {
      justifyContent: "space-evenly",
    },
  },

  customDisplayFlexForPermissions: {
    display: "flex",
    alignItems: "center",
    paddingBottom: "3px",
    gap: "2px",
    [theme.breakpoints.down("xs")]: {
      alignItems: "flex-start",
    },
  },
  customDisplayFlexForLocation: {
    display: "flex",
    alignItems: "center",
    paddingBottom: "3px",
  },
  smallTopMargin: {
    marginTop: "3%",
  },
  jobCardHeader: {
    fontSize: "16px",
    fontWeight: "400",
    color: "#000000",
    textAlign: "left",
  },
  jobCardSubHeader: {
    fontSize: "14px",
    fontWeight: "500",
    textAlign: "left",
    marginLeft: "4px",
  },

  jobCard: {
    boxShadow:
      "0px 0px 0px 1px rgb(0 0 0 / 5%), 0px 0px 0px 0px rgb(0 0 0 / 5%), 0px 0px 0px 1px rgb(0 0 0 / 5%)",
    borderRadius: "5px",
    cursor: "pointer",
    backgroundColor: "#F8F6EF",
  },
  smallBottomMargin: {
    marginBottom: "3%",
  },
  verySmallBottomMargin: {
    marginBottom: "1em",
  },

  customBadge: {
    cursor: "pointer",
    height: "50px",
    minWidth: "50px",
    borderRadius: "10px",
    fontSize: "large",
    right: "unset",
    transform: "unset",
    position: "unset",
    fontWeight: "bold",
    backgroundColor: "#FBC112",
  },
  customBadgefor0: {
    cursor: "pointer",
    height: "50px",
    minWidth: "50px",
    borderRadius: "10px",
    fontSize: "large",
    right: "unset",
    transform: "unset",
    position: "unset",
    fontWeight: "bold",
    backgroundColor: "#ffffff",
  },
  customChip: {
    backgroundColor: "#048475",
    color: "#FFFFFF",
    width: "100px",
    [theme.breakpoints.down("xs")]: {
      marginTop: "5px",
    },
  },
  smallFont: {
    fontSize: "14px",
  },
  customBadgeGrid: {
    textAlign: "center",
    overflowWrap: "break-word",
    padding: "2%",
  },
  verySmallMarginRight: { marginRight: "2%" },
  customDialogHeader: {
    maxHeight: "38%",
    height: "38%",
    color: "#000",
    backgroundSize: "cover",
    backgroundImage: `url(${cardHeaderBg})`,
    background: "round",
  },
  noDataNote: {
    height: "calc(100vh - 270px)",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  mainHeaderDiv: {
    display: "grid",
    width: "100%",
    gap: ".40%",
    gridTemplateColumns: "auto auto auto",
    zIndex: 1,
    padding: "10px 0px",
    [theme.breakpoints.down("md")]: {
      gap: "2% !important",
      zIndex: 1,
    },
  },
  divMainSpan: {
    display: "flex",
    paddingLeft: "8px",
    paddingTop: "4px",
    alignItems: "center",
  },
  titleTypo: {
    fontSize: "12px",
    color: "#666666",
    marginLeft: "5px",
    fontWeight: 700,
  },
  countTypo: {
    fontSize: "24px",
    paddingLeft: "26px",
    fontWeight: 700,
    lineHeight: 2.3,
  },
  lastSubtitle: {
    fontSize: "22px",
    paddingLeft: "38px",
    fontWeight: 700,
    marginTop: "10px",
  },
  clickLink: {
    paddingLeft: "20px",
    fontSize: "10px",
    cursor: "pointer",
    position: "absolute",
    bottom: "8px",
  },
  hiredLastTabLink: {
    paddingLeft: "28px",
    fontSize: "12px",
    paddingTop: "12px",
  },
  divMainSpan1: {
    display: "flex",
    paddingLeft: "17px",
    paddingTop: "4px",
  },
  countTypo1: {
    fontSize: "24px",
    paddingLeft: "36px",
    fontWeight: 700,
    lineHeight: 2.3,
  },
  chipStyle: {
    background: "#048475",
    color: "#FFFFFF",
    padding: "1px 4px",
    zIndex: 2,
    position: "absolute",
    top: -8,
    right: 10,
    borderRadius: "5px",
    fontSize: "10px",
  },
  adminNeedsStyle: {
    color: "#DB7804",
    fontSize: "12px",
    fontWeight: 800,
    marginLeft: "3px",
  },
  newCountUpdate: { fontSize: "12px", fontWeight: 800, marginLeft: "3px" },
  textAlignLast: {
    textAlignLast: "right",
  },
  clickLink1: {
    paddingLeft: "8px",
    fontSize: "10px",
    cursor: "pointer",
    position: "absolute",
    bottom: "8px",
  },
  hiredStamp: {
    paddingRight: "30px",
    paddingTop: "10px",
  },
  mainDiv: {
    display: "flex",
    justifyContent: "space-between",
    [theme.breakpoints.down("md")]: {
      justifyContent: "start",
    },
  },
}));

function OpenJobCloseJob({
  JobsValues,
  totalOpenJobs,
  handlePageChangeForOpen,
  handleJobClick,
}) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const pageNo = useSelector((state) =>
    state.shared.jobsPageTab === 0
      ? state.shared.jobsPagePaginationPage
      : state.shared.closedJobPageNo
  );
  const onHandleCardClick = (event, job) => {
    event.preventDefault();
    event.stopPropagation();
    handleJobClick(
      window.location.pathname === "/jobs/my-jobs" ? job?.id : job?.job?.id
    );
  };

  const onChangeHeaderTab = (event, cardValue, job) => {
    // event.preventDefault();
    // event.stopPropagation();
    dispatch(setAllUpdateHeaderCard(cardValue));
    handleJobClick(
      window.location.pathname === "/jobs/my-jobs" ? job?.id : job?.job?.id
    );
    // document.getElementById(id).style.setProperty("--check-primary", "pink");
    // document.getElementById(id).style.setProperty("--check-secondary", "pink");
  };

  useEffect(() => {
    dispatch(getJobDetailsData(null));
  }, []);

  return (
    <Grid container>
      <Grid
        item
        xs={12}
        sm={12}
        md={12}
        lg={12}
        xl={12}
        className={classNames(
          classes.verySmallTopMargin,
          classes.smallBottomMargin
        )}
      >
        {JobsValues?.map((job, index) => (
          <Card
            key={index}
            className={classNames(
              classes.verySmallTopMargin,
              classes.verySmallBottomMargin,
              classes.jobCard
            )}
          >
            <CardContent>
              <Grid container>
                <Grid
                  component="div"
                  item
                  xs={12}
                  sm={12}
                  md={12}
                  lg={5}
                  xl={5}
                  onClick={(event) => {
                    onHandleCardClick(event, job);
                  }}
                >
                  <div className={classes.mainDiv}>
                    <span>
                      <div className={classes.customDisplayFlexForPermissions}>
                        <Typography className={classes.jobCardHeader}>
                          {job?.title
                            ? job?.title
                            : job?.job.title
                            ? job?.job?.title
                            : "-"}
                        </Typography>
                        {job?.permission && (
                          <Chip
                            className={classes.customChip}
                            size="small"
                            label={
                              job?.permission === 1
                                ? "Commenter"
                                : job?.permission === 2
                                ? "Editor"
                                : null
                            }
                          />
                        )}
                        {job?.update_counts?.total_new_updates > 0 && (
                          <>
                            <span className={classes.adminNeedsStyle}>
                              <i>Action Needed</i>
                            </span>
                            <Typography
                              color="secondary"
                              className={classes.newCountUpdate}
                            >
                              {`(${job?.update_counts?.total_new_updates} New Updates)`}
                            </Typography>
                          </>
                        )}
                      </div>

                      <div className={classes.customDisplayFlexForLocation}>
                        <LocationOnIcon className={classes.largerFont} />
                        <Typography
                          className={classes.jobCardSubHeader}
                          color="textSecondary"
                        >
                          {(job?.location?.city
                            ? job?.location?.city
                            : job?.job?.location?.city
                            ? job?.job?.location?.city
                            : "") +
                            (job?.location?.state
                              ? ", "
                              : job?.job?.location?.state
                              ? ", "
                              : "") +
                            (job?.location?.state
                              ? job?.location?.state
                              : job?.job?.location?.state
                              ? job?.job?.location?.state
                              : "") +
                            (job?.location?.country
                              ? ", "
                              : job?.job?.location?.country
                              ? ", "
                              : "") +
                            (job?.location?.country
                              ? job?.location?.country
                              : job?.job?.location?.country
                              ? job?.job?.location?.country
                              : "") +
                            (job?.location?.city ||
                            job?.job?.location?.city ||
                            job?.location?.state ||
                            job?.job?.location?.state ||
                            job?.location?.country ||
                            job?.job?.location?.country
                              ? ""
                              : "-")}
                        </Typography>
                      </div>
                      <div>
                        <div className={classes.customDisplayFlex}>
                          <EventIcon className={classes.largerFont} />
                          <Typography
                            className={classes.jobCardSubHeader}
                            color="textSecondary"
                          >
                            {" "}
                            Posted on{" "}
                            {moment(
                              window.location.pathname === "/jobs/shared-jobs"
                                ? job?.job?.created_at
                                : job?.created_at
                            ).format("MM-DD-YYYY") || "-"}
                          </Typography>
                        </div>
                        <div className={classes.customDisplayFlex}>
                          <UpdateIcon className={classes.largerFont} />
                          <Typography
                            className={classes.jobCardSubHeader}
                            color="textSecondary"
                          >
                            {" "}
                            Last Modified on{" "}
                            {moment(
                              window.location.pathname === "/jobs/shared-jobs"
                                ? job?.job?.updated_at
                                : job?.updated_at
                            ).format("MM-DD-YYYY") || "-"}
                          </Typography>
                        </div>
                      </div>
                    </span>
                    <span className={classes.hiredStamp}>
                      {job?.update_counts?.hired && (
                        <img
                          alt="Hired Icon"
                          height={96}
                          width={96}
                          src={HiredIcon}
                        />
                      )}
                    </span>
                  </div>
                </Grid>
                <Grid item xs={12} sm={12} md={12} lg={7} xl={7}>
                  <div className={classes.mainHeaderDiv}>
                    <div
                      onClick={(event) => onChangeHeaderTab(event, "1", job)}
                      id="first_open_close"
                    >
                      <span className={classes.divMainSpan}>
                        <CustomTooltip
                          title="Talent Pipeline"
                          content="Relevant candidate profiles for your initial review."
                          tooltipOn={<InfoIcon />}
                        />
                        <Typography className={classes.titleTypo}>
                          Talent Pipeline
                        </Typography>
                      </span>
                      <Typography align="left" className={classes.countTypo}>
                        {job?.update_counts?.total_recommendations}
                      </Typography>
                      <Typography align="left" className={classes.clickLink1}>
                        {"Review & Like Anonymous Profiles >"}
                      </Typography>
                      {job?.update_counts?.new_recommended_count > 0 && (
                        <span className={classes.chipStyle}>
                          {`${job?.update_counts?.new_recommended_count} New`}
                        </span>
                      )}
                    </div>

                    <div
                      onClick={(event) => onChangeHeaderTab(event, "2", job)}
                      id="second_open_close"
                    >
                      <span className={classes.divMainSpan1}>
                        <CustomTooltip
                          title="Shortlisted Candidates"
                          content="The most qualified candidates selected by your team."
                          tooltipOn={<InfoIcon />}
                        />
                        <Typography className={classes.titleTypo}>
                          Shortlisted Candidates
                        </Typography>
                      </span>
                      <Typography align="left" className={classes.countTypo1}>
                        {job?.update_counts?.total_matched_count}
                      </Typography>
                      <Typography align="left" className={classes.clickLink}>
                        {"Review Full Profiles >"}{" "}
                      </Typography>
                      {job?.update_counts?.new_matched_count > 0 && (
                        <span className={classes.chipStyle}>
                          {`${job?.update_counts?.new_matched_count} New`}
                        </span>
                      )}
                    </div>

                    <div
                      onClick={(event) => onChangeHeaderTab(event, "3", job)}
                      id="last_open_close"
                    >
                      <span className={classes.divMainSpan1}>
                        <CustomTooltip
                          title="Candidates in Conversation"
                          content="Candidates asked to participate in the interview process."
                          tooltipOn={<InfoIcon />}
                        />
                        <Typography className={classes.titleTypo}>
                          Candidates in Conversation
                        </Typography>
                      </span>
                      <Typography align="left" className={classes.countTypo1}>
                        {job?.update_counts?.total_in_conversation_count}
                      </Typography>
                      <Typography align="left" className={classes.clickLink}>
                        {"Request Interviews / Send Messages >"}{" "}
                      </Typography>
                      {/* <span className={classes.chipStyle}>New 5</span> */}
                    </div>
                  </div>
                </Grid>
              </Grid>
            </CardContent>
          </Card>
        ))}
        <div
          className={classNames(classes.smallTopMargin, classes.textAlignLast)}
        >
          <Pagination
            color="#FBC112"
            count={Math.ceil(totalOpenJobs / 10)}
            size="large"
            page={pageNo}
            onChange={handlePageChangeForOpen}
            showFirstButton
            showLastButton
          />
        </div>
      </Grid>
    </Grid>
  );
}

export default OpenJobCloseJob;
