export const ROLES = [
  {
    text: "Hiring Manager",
    value: "Hiring Manager",
  },
  {
    text: "Owner/CEO",
    value: "Owner/CEO",
  },
  {
    text: "Human Resources Generalist",
    value: "Human Resources Generalist",
  },
  {
    text: "Recruiter or Talent Acquisition",
    value: "Recruiter or Talent Acquisition",
  },
  {
    text: "Assistant or Office Manager",
    value: "Assistant or Office Manager",
  },
  {
    text: "Other",
    value: "Other",
  },
];

export const VERSION = "2.3.3";
export const MESSAGE_FIELD_IS_REQUIRED = "This field is required";
export const MISSING_KEY_SKILLS = "Missing key skill(s)";
export const REMOVE_DASH_REGEX = new RegExp(/[()\\s-]+/g);
export const REDIRECT_TO_COMPONENT = {
  FULL_PROFILE: "full profile",
  LITE_PROFILE: "lite profile",
  ALL_CANDIDATES: "all candidates",
};

export const TOASTER_MESSAGES = {
  REMOVE_FROM_QUEUE: {
    SUCCESS: "Removed from queue successfully",
    FAIL: "Failure while removing from queue",
  },
};

export const DIALOG_BOX = {
  REMOVE_FROM_QUEUE: {
    DIALOG_TITLES: "Why are you passing on this candidate?",
    DIALOG_SUBTITLES:
      "This information will not be shared with the candidate, however is critical in honing the AI so that SucceedSmart can recommend sharper matches.",
  },
  SHARE_PROFILE_PASSWORD: {
    DIALOG_SUBTITLES: "Please enter the password to view this profile",
  },
  CHANGE_PASSWORD: {
    DIALOG_TITLES: "Change Document Password",
  },
};

export const GET_ALL_UPDATE_DATA_FLAG_AND_VALUE = {
  ALL: {
    VALUE: "All",
    CARDVALUE: "All",
  },
  RECOMMENDED: {
    VALUE: "Recommended",
    CARDVALUE: "1",
  },
  MATCHED: {
    VALUE: "Matched",
    CARDVALUE: "2",
  },
  CONVERSATION: {
    VALUE: "Conversation",
    CARDVALUE: "3",
  },
};

export const LABELS = {
  REMOVE_FROM_QUEUE: {
    EXPERIENCE_NOT_RELEVANT: "Experience not relevant",
    INDUSTRY_NOT_ALIGNED: "Industry not aligned",
    OTHER: "Other",
    PLEASE_ELABORATE: "Please elaborate",
  },
  SHARE_PROFILE: {
    COMPANY_NAME: "Company Name",
    JOB_TITLE: "Job Title",
    CANDIDATE_NAME: "Candidate Name",
    CANDIDATE_PROFILE_LINK: "Candidate Profile Link",
    PASSWORD: "Password",
  },
  CHANGE_PASSWORD: {
    NEW_PASSWORD: "New Password",
    CONFIRM_PASSWORD: "Confirm Password",
  },
};

export const BUTTONS = {
  REMOVE_FROM_QUEUE: {
    SUBMIT: "Remove from Queue",
    CANCEL: "Cancel",
  },
  CHANGE_PASSWORD: {
    SUBMIT: "Submit",
    CANCEL: "Cancel",
  },
};

export const VALIDATION_MESSAGES = {
  REMOVE_FROM_QUEUE: {
    MAX_1000_CHAR: "Max 1000 characters",
    PLEASE_SELECT_ONE_OPTION: "Please select one option",
    PLEASE_ADD_VAILD_DATA: "Please add valid data",
  },
  CHANGE_PASSWORD: {
    INCORRECT_PASSWORD: "Incorrect password. Please try again.",
    STRONG_PASSWORD_MESSAGE:
      "Password must contain minimum 8 characters, at least 1 lowercase letter, 1 uppercase letter, 1 numeric digit and 1 special character.",
    PASSWORD_NOT_MATCH: "Passwords didn't match",
    YOU_ARE_NOT_AUTHORIZED: "You are not authorized to view this resource",
  },
};

export const VALIDATION_CHECK = {
  REMOVE_FROM_QUEUE: {
    EXPERIENCE_NOT_RELEVANT: "experienceNotRelevant",
    INDUSTRY_NOT_RELEVANT: "industryNotRelevant",
    OTHER: "other",
    EXPERIENCE_NOT_RELEVENT_OTHER: "experienceNotRelevantOther",
    INDUSTRY_NOT_RELEVANT_OTHER: "industryNotRelevantOther",
    SUBMIT: "submit",
  },
};

export const GHOST_TOAST_CONF = {
  className: "custom-toast-container",
  toastId: "ghost",
  closeButton: false,
  position: "top-center",
  hideProgressBar: true,
  closeOnClick: false,
  progress: undefined,
  draggable: false,
  autoClose: false,
};

export const RECOMMENDED_HEADER = [
  {
    value: "Candidate",
  },
  {
    value: "Current Title",
  },
  {
    value: "Location",
  },
  {
    value: "Time in Queue",
  },
  {
    value: "Industry of Expertise",
  },
  {
    value: "Salary Expectations",
  },
  {
    value: "Open to Relocate",
  },
  {
    value: "Diversity",
  },
  {
    value: "Status",
  },
  {
    value: "Actions",
  },
];

export const MATCHED_HEADER = [
  {
    value: "Candidate",
  },
  {
    value: "Current Title",
  },
  {
    value: "Location",
  },
  {
    value: "Time in Queue",
  },
  {
    value: "Liked back on",
  },
  {
    value: "Industry of Expertise",
  },
  {
    value: "Salary Expectations",
  },
  {
    value: "Open to Relocate",
  },
  {
    value: "Diversity",
  },
  {
    value: "Resume & LinkedIn URL",
  },
  {
    value: "Actions",
  },
];

export const EXPERIENCE_NOT_RELEVANT = [
  {
    value: "Too junior",
  },
  {
    value: "Too senior",
  },
  {
    value: "No leadership experience",
  },
  {
    value: "Needs more leadership experience",
  },
  {
    value: "Needs to have managed larger teams",
  },
  {
    value: "Needs more recognisable brands",
  },
  {
    value: "Needs more similar brands",
  },
  {
    value: "Needs to have worked at a larger company",
  },
  {
    value: "Needs to have worked at a smaller company",
  },
  {
    value: "Needs to have worked at a Fortune 500",
  },
  {
    value: "Needs to have worked in a startup environment",
  },
  {
    value: MISSING_KEY_SKILLS,
  },
];

export const INDUSTRY_NOT_RELEVANT = [
  {
    value: "Unprepared for interview",
  },
  {
    value: "Communication challenges",
  },
  {
    value: "Inability to build rapport quickly",
  },
  {
    value: "Lack of enthusiasm",
  },
  {
    value: "Compensation requirements not in line with budget",
  },
  {
    value: "Possibly seeking a higher level role",
  },
  {
    value: "Unable to relocate",
  },
  {
    value: "Culturally not aligned",
  },
  {
    value: MISSING_KEY_SKILLS,
  },
];

export const MAX_LENGTH_1000 = 1000;

export const somethingWentHaywire = "Something went haywire";
