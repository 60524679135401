import React, { Component, Fragment } from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import { connect } from "react-redux";
import Typography from "@material-ui/core/Typography";
import { withRouter } from "react-router-dom";
import {
  TextField,
  InputLabel,
  Select,
  MenuItem,
  FormControl,
  Radio,
  RadioGroup,
  FormControlLabel,
  Checkbox,
  FormHelperText,
} from "@material-ui/core";
import ProductInfoImage from "../../../assets/product_info.svg";
import classNames from "classnames";
import Avatar from "@material-ui/core/Avatar";
import CancelIcon from "@material-ui/icons/Cancel";
import { authActions, getCloudName } from "../../../actions";
import validator from "validator";
import { grey } from "@material-ui/core/colors";
import { toast } from "react-toastify";
import { GoogleMaps } from "../../core/googleautocomplete";
import config from "../../../config/config";
import { MESSAGE_FIELD_IS_REQUIRED } from "../../../constants/constants";

import { loading } from "../../../actions";
import CircularProgress from "@material-ui/core/CircularProgress";
import { notAllowWhiteSpaceAtStart, trim } from "../../../helpers";
import secureLocalStorage from "react-secure-storage";

const styles = (theme) => ({
  root: {
    marginTop: "90px",
    color: "#000000",
  },
  image: {
    backgroundImage: `url(${ProductInfoImage})`, //'url(https://source.unsplash.com/random)',
    backgroundRepeat: "no-repeat",
    backgroundColor:
      theme.palette.type === "light"
        ? theme.palette.grey[50]
        : theme.palette.grey[900],
    backgroundSize: "cover",
  },
  paper: {
    margin: theme.spacing(0, 10),
    marginTop: "3%",
  },
  customPaper: {
    margin: theme.spacing(3, 10),

    color: theme.palette.default.main,
  },
  customSubPaper: {
    display: "inline-flex",
    width: "28%",
    textAlign: "justify",
  },
  customContent: {
    margin: theme.spacing(3, 10),
    textAlign: "justify",
  },
  customForm: {
    margin: theme.spacing(3, 10),
    alignItems: "center",
  },
  customBadge: {
    backgroundColor: "#FBC112",
    height: "50px",
    minWidth: "50px",
    borderRadius: "50%",
    fontSize: "large",
  },
  mediumLineHeight: {
    lineHeight: "4.5",
  },
  forgotLink: {
    cursor: "pointer",
    "&:hover": {
      textDecoration: "underline",
    },
  },
  verySmallTopMargin: {
    marginTop: "1%",
  },
  smallTopMargin: {
    marginTop: "2%",
  },
  mediumTopMargin: {
    marginTop: "4%",
  },
  basicsHeader: {
    backgroundColor: "#FBC112",
    padding: "1%",
    fontWeight: "bold",
  },
  fontBold: {
    fontWeight: "bold",
  },
  customFlex: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  xxLargeFont: {
    fontSize: "xx-large",
  },
  bottomSmall: {
    marginBottom: "9%",
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(5, 0, 2),
    borderRadius: "4px !important",
  },
  alignRight: {
    textAlign: "right",
  },
  margin: {
    marginTop: theme.spacing(2),
  },
  withoutLabel: {
    marginTop: theme.spacing(3),
  },
  textField: {
    width: "100%",
  },
  disabledSignIn: {
    cursor: "not-allowed",
    borderRadius: "4px !important",
  },
  toolbar: {
    padding: "1% !important",
    left: "1.5% !important",
  },
  responsive: {
    maxWidth: "100% !important",
    height: "auto !important",
    width: "auto !important",
    alignSelf: "center !important",
    minWidth: "fit-content !important",
  },
  customDivider: {
    borderBottom: "1px solid " + theme.palette.secondary.main,
    width: "inherit",
    marginTop: "10%",
    flexShrink: 1,
  },
  alignLeft: {
    textAlign: "left",
  },
  paddedContent: {
    marginLeft: "30vw",
    marginRight: "30vw",
    marginTop: "2%",
    [theme.breakpoints.down("md")]: {
      margin: "20px 16px",
    },
    [theme.breakpoints.down("sm")]: {
      margin: "10px 16px",
    },
  },
  inlineContent: {
    display: "flex",
    alignItems: "center",
    paddingLeft: "1.3vw",
  },
  justifyContent: {
    textAlign: "justify",
  },
  square: {
    color: theme.palette.getContrastText(grey[300]),
    backgroundColor: grey[300],
    border: "3px dotted #FBC112",
    width: "7em",
    height: "4em",
  },
  uploadLink: {
    // marginLeft: '2em',
    cursor: "pointer",
    "&:hover": {
      textDecoration: "underline",
    },
    color: "#048475",
  },
  uploadInstruction: {
    // marginLeft: '2em',
    color: "grey",
  },
  displayFlex: {
    display: "flex",
  },
  formControl: {
    margin: theme.spacing(2, 0),
    minWidth: "100%",
  },
  fullWidth: {
    width: "100%",
  },
  floatRight: {
    float: "right",
  },
  uploadProgress: {
    color: "#048475",
    position: "absolute",
    // top: "5%",
    // left: "5%",
    // marginTop: -1,
    // marginLeft: -1
  },
  requiredLogo: {
    color: "rgb(220, 0, 78)",
    fontSize: "12px",
    marginTop: "3px",
    textAlign: "left",
    fontFamily: "Public Sans",
    fontWeight: "400",
    lineHeight: "1.66",
  },
  customLogoLabel: {
    transform: "translate(0, 1.5px) scale(0.75)",
    transformOrigin: "top left",
  },
});

const CustomCheckBox = withStyles({
  root: {
    color: "#FBC112",
    "&$checked": {
      color: "#048475",
    },
  },
  checked: {},
})((props) => <Checkbox color="default" {...props} />);

let answers = [];

class CompanyInfo extends Component {
  constructor(props) {
    super(props);
    this.state = {
      recruiterId: JSON.parse(secureLocalStorage.getItem("recruiterId")),
      logo: secureLocalStorage.getItem("logoDataUrl")
        ? secureLocalStorage.getItem("logoDataUrl")
        : "",
      // logoData: (secureLocalStorage.getItem('logoDataUrl') ? secureLocalStorage.getItem('logoDataUrl') : ''),
      companyName: "",
      companySize: "",
      industry: "",
      companyHqLocation: "",
      locationData: "",
      website: "",
      growthRate: "",
      platformSupportAnswers: [],
      numberOfRequirements: "",
      checkedA: false,
      checkedB: false,
      checkedC: false,
      checkedD: false,
      checkedE: false,
      answer: "",
      growthRateChecked: false,
      isValidCompanyName: true,
      isValidCompanyHqLocation: true,
      isValidWebsite: true,
      isValidAnswer: true,
      uploading: false,
      // isLogoRequired: false,
      isCompanyNameRequired: false,
      isCompanySizeRequired: false,
      isIndustryRequired: false,
      isLocationRequired: false,
      isWebSiteRequired: false,
      isGrowthRateRequired: false,
    };
  }

  componentDidMount() {
    const { dispatch } = this.props;
    dispatch(authActions.getCompanyData());
    dispatch(authActions.getAllConstants());
    dispatch(getCloudName());
  }

  componentWillUnmount() {
    secureLocalStorage.removeItem("logoDataUrl");
  }

  componentDidUpdate(prevProps) {
    if (
      this.props?.authentication?.companyData?.data &&
      this.props?.authentication?.companyData?.data !==
        prevProps?.authentication?.companyData?.data
    ) {
      const companyData = this.props.authentication.companyData.data;
      secureLocalStorage.setItem(
        "prefetchedLocation",
        companyData.company.geo.city +
          ", " +
          companyData.company.geo.state +
          ", " +
          companyData.company.geo.country
      );
      this.setState({
        logo:
          companyData.company.logo !== null
            ? companyData.company.logo
            : secureLocalStorage.getItem("logoDataUrl"),
        companyName: companyData.company.name,
        companySize: companyData.company.metrics.employeesRange,
        industry: companyData.company.category.industry,
        website: companyData.company.domain,
      });
    }
  }

  clearLogo = () => {
    this.setState({ logo: "" });
    secureLocalStorage.removeItem("logoDataUrl");
  };

  handleSubmitClick = (event) => {
    event.preventDefault();
    event.stopPropagation();
    this.isWebsiteValid();
    if (this.state.isValidWebsite) {
      if (this.state.checkedE && this.state.answer !== "") {
        this.state.platformSupportAnswers.push(this.state.answer);
      }
      this.setState(
        {
          locationData: secureLocalStorage.getItem("locationDetails")
            ? JSON.parse(secureLocalStorage.getItem("locationDetails"))
            : null,
        },
        function () {
          const {
            recruiterId,
            logo,
            companyName,
            companySize,
            industry,
            locationData,
            website,
            growthRate,
            platformSupportAnswers,
            numberOfRequirements,
          } = this.state;
          let payload = {
            recruiter_id: recruiterId,
            company_name: trim(companyName),
            industry: trim(industry),
            website: trim(website),
            logo: secureLocalStorage.getItem("logoDataUrl") || logo,
            location: locationData, //companyDetails.company.geo,
            company_size: trim(companySize),
            annual_growth_rate: trim(growthRate),
            platform_support_reason: trim(platformSupportAnswers),
            hiring_count: trim(numberOfRequirements),
          };
          if (
            companyName &&
            companySize &&
            industry &&
            locationData &&
            website &&
            growthRate
          ) {
            const { dispatch } = this.props;
            dispatch(authActions.createCompany(payload));
          } else {
            toast.dark("Please fill up all the mandatory details");
            this.setState({
              isCompanyNameRequired: validator.isEmpty(companyName),
              isCompanySizeRequired: validator.isEmpty(companySize),
              isIndustryRequired: validator.isEmpty(industry),
              isLocationRequired: locationData
                ? Object.keys(locationData).length === 0
                : true,
              isWebSiteRequired: validator.isEmpty(website),
              isGrowthRateRequired: validator.isEmpty(growthRate),
            });
          }
        }
      );
    } else {
      toast.dark(
        "Please fill up all the mandatory details with correct information"
      );
    }
  };

  handleLogoUpload = (event) => {
    this.setState({ uploading: true });
    const thisInstance = this;
    const uploadedLogo = event.target.files[0];
    if (
      event.target.files[0] &&
      event.target.files[0].type !== "image/png" &&
      event.target.files[0].type !== "image/jpg" &&
      event.target.files[0].type !== "image/jpeg"
    ) {
      toast.dark("Only .png, .jpg and .jpeg files are allowed");
      this.setState({ logo: "", uploading: false });
    } else {
      if (event.target.files[0].size > 500000) {
        toast.dark("Only files upto 500kB are allowed");
        this.setState({ logo: "", uploading: false });
      } else {
        if (uploadedLogo) {
          const reader = new FileReader();
          let logoDataUrl = "";
          reader.readAsDataURL(uploadedLogo);
          reader.onloadend = function () {
            logoDataUrl = reader.result;
            const data = new FormData();
            data.append("file", logoDataUrl);
            data.append(
              "upload_preset",
              this.props.shared.decryptedUploadPreset
            );
            data.append("cloud_name", this.props.shared.decryptedStripeKey);
            fetch(
              "https://api.cloudinary.com/v1_1/" +
                config.cloudName +
                "/image/upload",
              {
                method: "post",
                body: data,
              }
            )
              .then((response) => response.json())
              .then((data) => {
                secureLocalStorage.setItem("logoDataUrl", data.url);
                thisInstance.setState({ logo: data.url, uploading: false });
              });
          };
        }
      }
    }
  };

  isWebsiteValid = () => {
    this.setState({
      isValidWebsite: this.state.website
        ? validator.matches(
            this.state.website,
            /^((ftp|http|https):\/\/)?(www.)?(?!.*(ftp|http|https|www.))[a-zA-Z0-9_-]+(\.[a-zA-Z]+)+((\/)[\w#-=?!@">`~]+)*(\/\w+\?[a-zA-Z0-9_]+=\w+(&[a-zA-Z0-9_]+=\w+))?$/gs
          )
        : true,
    });
  };

  handleInputChange = (prop) => (event) => {
    const inputValue = notAllowWhiteSpaceAtStart(event.target.value);
    this.setState({ [prop]: inputValue }, function () {
      switch (prop) {
        case "companyName":
          this.setState({
            isValidCompanyName: inputValue
              ? validator.matches(
                  inputValue,
                  /^([a-zA-Z\u0080-\u024F]+(?:(\. )|-| |'))*[a-zA-Z0-9 \u0080-\u024F]*.$/,
                  "i"
                )
              : true,
            isCompanyNameRequired: validator.isEmpty(inputValue),
          });
          break;
        case "website":
          this.setState({
            // isValidWebsite: inputValue ? validator.matches(inputValue, /^(http(s)?:\/\/(www\.))?[a-z0-9]+([-.]{1}[a-z0-9]+)*\.[a-z]{2,5}(:[0-9]{1,5})?(\/.*)?$/, "i") : true,
            // isWebSiteRequired: validator.isEmpty(inputValue)
            isValidWebsite: inputValue
              ? validator.matches(
                  inputValue,
                  /^((ftp|http|https):\/\/)?(www.)?(?!.*(ftp|http|https|www.))[a-zA-Z0-9_-]+(\.[a-zA-Z]+)+((\/)[\w#-=?!@">`~]+)*(\/\w+\?[a-zA-Z0-9_]+=\w+(&[a-zA-Z0-9_]+=\w+))?$/gm
                )
              : true,
            isWebSiteRequired: validator.isEmpty(inputValue),
          });
          break;
        case "answer":
          this.setState({
            isValidAnswer: validator.matches(
              inputValue,
              /^([a-zA-Z\u0080-\u024F]+(?:(\. )|-| |'))*[a-zA-Z0-9 \u0080-\u024F]*$/s,
              "i"
            ),
          });
          break;
        case "industry":
          this.setState({ isIndustryRequired: validator.isEmpty(inputValue) });
          break;
        case "growthRate":
          this.setState({
            isGrowthRateRequired: validator.isEmpty(inputValue),
          });
          break;
        case "companySize":
          this.setState({
            isCompanySizeRequired: validator.isEmpty(inputValue),
          });
          break;
        default:
          break;
      }
    });
  };

  handleCheckboxClick = (prop) => (event) => {
    this.setState({ [prop]: event.target.checked }, function () {
      // Anything else than 'Other' is clicked and checked
      if (event.target.checked && prop !== "checkedE") {
        answers.push(event.target.value);
      } else {
        // 'Other' clicked and checked
        // if(event.target.checked && prop === 'checkedE'){
        // }
        // 'Other' is clicked but unchecked
        if (!event.target.checked && prop === "checkedE") {
          this.setState({ answer: "" });
        } else {
          // Anything else than 'Other' is clicked but unchecked
          if (!event.target.checked && prop !== "CheckedE") {
            this.setState({ [prop]: false });
            for (var ans in answers) {
              if (event.target.value === answers[ans]) {
                answers.splice(ans, 1);
              }
            }
          }
        }
      }
      this.setState({ platformSupportAnswers: answers });
    });
  };

  updateLocationRequireState = (value) => {
    this.setState({ isLocationRequired: value });
  };

  render() {
    const { classes } = this.props;
    let constants = this.props.authentication?.constants;
    return (
      <Fragment>
        {/* <AppBar /> */}
        <Grid container component="main" className={classes.root}>
          <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
            <Grid className={classes.alignLeft}>
              <div className={classes.paddedContent}>
                <Typography
                  component="span"
                  align="center"
                  className={classes.xxLargeFont}
                >
                  Create Account
                </Typography>
                <Typography component="div" className={classes.smallTopMargin}>
                  Please complete the information below to create your account
                  and help us better understand your executive talent needs.
                </Typography>
                <Typography component="div">
                  (<span className="required">*</span> Required fields)
                </Typography>
                <Typography
                  component="div"
                  className={classNames(
                    classes.mediumTopMargin,
                    classes.basicsHeader
                  )}
                >
                  {"Company Profile"}
                </Typography>
                <Typography
                  color="textSecondary"
                  component="div"
                  className={classNames(
                    classes.smallTopMargin,
                    classes.customLogoLabel
                  )}
                >
                  {"Logo "}
                  {/* <span className="required">*</span> */}
                </Typography>
                <Grid container>
                  <Grid item xs={12} sm={4} md={4} lg={4} xl={4}>
                    <div className={classes.displayFlex}>
                      <Avatar
                        src={this.state.logo || this.state.companyName}
                        alt={this.state.companyName}
                        variant="square"
                        className={classNames(
                          classes.square,
                          classes.verySmallTopMargin
                        )}
                      >
                        {/* { this.state.logo === '' ? <PanoramaIcon fontSize='large' /> : null } */}
                      </Avatar>
                      {this.state.logo !== "" &&
                      this.state.logo !== null &&
                      this.state.logo !== undefined ? (
                        <CancelIcon
                          titleAccess="Clear"
                          color="action"
                          style={{
                            marginLeft: "1%",
                            cursor: "pointer",
                            fontSize: "large",
                          }}
                          onClick={() => {
                            this.clearLogo();
                          }}
                        />
                      ) : null}
                    </div>
                  </Grid>
                  <Grid item xs={12} sm={8} md={8} lg={8} xl={8}>
                    <Typography
                      component="div"
                      className={classes.smallTopMargin}
                    >
                      <label className={classes.uploadLink} htmlFor="logo">
                        Upload
                      </label>{" "}
                      &nbsp;
                      {this.state.uploading && (
                        <CircularProgress
                          size={24}
                          className={classes.uploadProgress}
                        />
                      )}
                      <div className={classes.uploadInstruction}>
                        <small>Please use a PNG / JPEG file upto 500kB</small>
                      </div>
                      {/* <span>
                                        <label className={classes.uploadLink} htmlFor='clear'>Clear</label> &nbsp;
                                        <CancelIcon />
                                    </span> */}
                      {/* {this.state.isLogoRequired ? <div className={ classNames(classes.uploadInstruction)}>
                                        <small className={classes.requiredLogo} >{MESSAGE_FIELD_IS_REQUIRED}</small>
                                    </div> : null} */}
                      <input
                        id="logo"
                        style={{ visibility: "hidden" }}
                        className={classes.verySmallTopMargin}
                        type="file"
                        accept="image/png,image/jpeg"
                        onChange={(event) => this.handleLogoUpload(event)}
                      />
                    </Typography>
                  </Grid>
                </Grid>
                {/* </div> */}
                <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                  <TextField
                    fullWidth
                    label="Company Name"
                    autoFocus
                    autoComplete="companyName"
                    margin="normal"
                    required
                    id="companyName"
                    name="companyName"
                    value={this.state.companyName}
                    onChange={this.handleInputChange("companyName")}
                    // placeholder='SucceedSmart Pvt. Ltd.'
                    error={
                      !this.state.isValidCompanyName ||
                      this.state.isCompanyNameRequired
                    }
                    helperText={
                      !this.state.isValidCompanyName
                        ? "Invalid Name"
                        : this.state.isCompanyNameRequired
                        ? MESSAGE_FIELD_IS_REQUIRED
                        : ""
                    }
                  />
                </Grid>
                <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                  <FormControl
                    className={classes.formControl}
                    required
                    error={this.state.isCompanySizeRequired}
                  >
                    <InputLabel id="demo-simple-select-label">
                      Company Size
                    </InputLabel>
                    <Select
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      value={this.state.companySize}
                      placeholder="Choose one"
                      onChange={this.handleInputChange("companySize")}
                    >
                      {constants?.company_size.map((obj, index) => (
                        <MenuItem key={index} value={obj}>
                          {obj}
                        </MenuItem>
                      ))}
                    </Select>
                    {this.state.isCompanySizeRequired ? (
                      <FormHelperText>
                        {MESSAGE_FIELD_IS_REQUIRED}
                      </FormHelperText>
                    ) : null}
                  </FormControl>
                </Grid>
                <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                  <FormControl
                    className={classes.formControl}
                    required
                    error={this.state.isIndustryRequired}
                  >
                    <InputLabel id="demo-simple-select-label">
                      Industry
                    </InputLabel>
                    <Select
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      value={this.state.industry}
                      onChange={this.handleInputChange("industry")}
                    >
                      {constants?.industry.map((obj, index) => (
                        <MenuItem key={index} value={obj}>
                          {obj}
                        </MenuItem>
                      ))}
                    </Select>
                    {this.state.isIndustryRequired ? (
                      <FormHelperText>
                        {MESSAGE_FIELD_IS_REQUIRED}
                      </FormHelperText>
                    ) : null}
                  </FormControl>
                </Grid>
                <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                  <GoogleMaps
                    isRequire={this.state.isLocationRequired}
                    updateRequire={this.updateLocationRequireState}
                  ></GoogleMaps>
                </Grid>
                <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                  <TextField
                    fullWidth
                    label="Website"
                    autoComplete="website"
                    margin="normal"
                    required
                    id="website"
                    name="website"
                    value={this.state.website}
                    placeholder="Add your corporate website URL here"
                    onChange={this.handleInputChange("website")}
                    error={
                      !this.state.isValidWebsite || this.state.isWebSiteRequired
                    }
                    helperText={
                      !this.state.isValidWebsite
                        ? "Invalid Website Name"
                        : this.state.isWebSiteRequired
                        ? MESSAGE_FIELD_IS_REQUIRED
                        : ""
                    }
                    onBlur={this.isWebsiteValid}
                  />
                </Grid>
                <Typography component="div" className={classes.mediumTopMargin}>
                  What is your company's estimated annual percentage growth
                  rate? <span className="required"> *</span>
                </Typography>
                <FormControl
                  component="fieldset"
                  className={classNames(
                    classes.fullWidth,
                    classes.mediumTopMargin
                  )}
                  error={this.state.isGrowthRateRequired}
                >
                  <RadioGroup
                    aria-label="companyGrowth"
                    name="companyGrowth"
                    value={this.state.growthRate}
                    onChange={this.handleInputChange("growthRate")}
                  >
                    <Grid container>
                      <Grid
                        item
                        xs={12}
                        sm={12}
                        md={12}
                        lg={6}
                        xl={6}
                        className={classes.displayFlex}
                      >
                        <FormControlLabel
                          control={<Radio />}
                          label="Less than 10%"
                          value="Less than 10%"
                        />
                      </Grid>
                      <Grid
                        item
                        xs={12}
                        sm={12}
                        md={12}
                        lg={6}
                        xl={6}
                        className={classes.displayFlex}
                      >
                        <FormControlLabel
                          value="10%-25%"
                          control={<Radio />}
                          label="10%-25%"
                        />
                      </Grid>
                      <Grid
                        item
                        xs={12}
                        sm={12}
                        md={12}
                        lg={6}
                        xl={6}
                        className={classes.displayFlex}
                      >
                        <FormControlLabel
                          value="25%-50%"
                          control={<Radio />}
                          label="25%-50%"
                        />
                      </Grid>
                      <Grid
                        item
                        xs={12}
                        sm={12}
                        md={12}
                        lg={6}
                        xl={6}
                        className={classes.displayFlex}
                      >
                        <FormControlLabel
                          value="50%-100%"
                          control={<Radio />}
                          label="50%-100%"
                        />
                      </Grid>
                      <Grid
                        item
                        xs={12}
                        sm={12}
                        md={12}
                        lg={6}
                        xl={6}
                        className={classes.displayFlex}
                      >
                        <FormControlLabel
                          value="100%-200%"
                          control={<Radio />}
                          label="100%-200%"
                        />
                      </Grid>
                      <Grid
                        item
                        xs={12}
                        sm={12}
                        md={12}
                        lg={6}
                        xl={6}
                        className={classes.displayFlex}
                      >
                        <FormControlLabel
                          value="Greater than 200%"
                          control={<Radio />}
                          label="Greater than 200%"
                        />
                      </Grid>
                    </Grid>
                  </RadioGroup>
                  {this.state.isGrowthRateRequired ? (
                    <FormHelperText>{MESSAGE_FIELD_IS_REQUIRED}</FormHelperText>
                  ) : null}
                </FormControl>

                <Typography
                  component="div"
                  className={classNames(
                    classes.mediumTopMargin,
                    classes.basicsHeader
                  )}
                >
                  {"Additional Information (optional)"}
                </Typography>
                <Typography component="div" className={classes.mediumTopMargin}>
                  How can SucceedSmart support your business goals?
                </Typography>
                <FormControl
                  component="fieldset"
                  className={classNames(
                    classes.fullWidth,
                    classes.mediumTopMargin
                  )}
                >
                  <Grid container>
                    <Grid
                      item
                      xs={12}
                      sm={12}
                      md={12}
                      lg={6}
                      xl={6}
                      className={classes.displayFlex}
                    >
                      <FormControlLabel
                        control={
                          <CustomCheckBox
                            checked={this.state.checkedA}
                            onChange={this.handleCheckboxClick("checkedA")}
                            name="checkedA"
                          />
                        }
                        label="Try out solution"
                        value="Try out solution"
                      />
                    </Grid>
                    <Grid
                      item
                      xs={12}
                      sm={12}
                      md={12}
                      lg={6}
                      xl={6}
                      className={classes.displayFlex}
                    >
                      <FormControlLabel
                        control={
                          <CustomCheckBox
                            checked={this.state.checkedB}
                            onChange={this.handleCheckboxClick("checkedB")}
                            name="checkedB"
                          />
                        }
                        label="Replace an existing role"
                        value="Replace an existing role"
                      />
                    </Grid>
                    <Grid
                      item
                      xs={12}
                      sm={12}
                      md={12}
                      lg={6}
                      xl={6}
                      className={classes.displayFlex}
                    >
                      <FormControlLabel
                        control={
                          <CustomCheckBox
                            checked={this.state.checkedC}
                            onChange={this.handleCheckboxClick("checkedC")}
                            name="checkedC"
                          />
                        }
                        label="Fill a new requisition"
                        value="Fill a new requisition"
                      />
                    </Grid>
                    <Grid
                      item
                      xs={12}
                      sm={12}
                      md={12}
                      lg={6}
                      xl={6}
                      className={classes.displayFlex}
                    >
                      <FormControlLabel
                        control={
                          <CustomCheckBox
                            checked={this.state.checkedD}
                            onChange={this.handleCheckboxClick("checkedD")}
                            name="checkedD"
                          />
                        }
                        label="Build a pipeline for future hires"
                        value="Build a pipeline for future hires"
                      />
                    </Grid>
                    <Grid
                      item
                      xs={12}
                      sm={12}
                      md={12}
                      lg={6}
                      xl={6}
                      className={classes.displayFlex}
                    >
                      <FormControlLabel
                        control={
                          <CustomCheckBox
                            checked={this.state.checkedE}
                            onChange={this.handleCheckboxClick("checkedE")}
                            name="checkedE"
                          />
                        }
                        label="Other"
                        value={this.state.answer}
                      />
                    </Grid>
                    {this.state.checkedE ? (
                      <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                        <TextField
                          fullWidth
                          label="Answer"
                          margin="normal"
                          id="answer"
                          name="answer"
                          value={this.state.answer}
                          onChange={this.handleInputChange("answer")}
                          error={this.state.isValidAnswer ? false : true}
                          helperText={
                            !this.state.isValidAnswer
                              ? "Answer should contain, letters, numbers and special characters only"
                              : ""
                          }
                        />
                      </Grid>
                    ) : null}
                  </Grid>
                </FormControl>

                <Typography component="div" className={classes.mediumTopMargin}>
                  How many executive roles are you looking to fill in the next
                  12 months?
                </Typography>
                <FormControl
                  component="fieldset"
                  className={classNames(
                    classes.fullWidth,
                    classes.mediumTopMargin
                  )}
                >
                  <RadioGroup
                    aria-label="numberOfRoles"
                    name="numberOfRoles"
                    value={this.state.numberOfRequirements}
                    onChange={this.handleInputChange("numberOfRequirements")}
                  >
                    <Grid container>
                      <Grid
                        item
                        xs={12}
                        sm={12}
                        md={12}
                        lg={6}
                        xl={6}
                        className={classes.displayFlex}
                      >
                        <FormControlLabel
                          value="None"
                          control={<Radio />}
                          label="None"
                        />
                      </Grid>
                      <Grid
                        item
                        xs={12}
                        sm={12}
                        md={12}
                        lg={6}
                        xl={6}
                        className={classes.displayFlex}
                      >
                        <FormControlLabel
                          value="1-5"
                          control={<Radio />}
                          label="1-5"
                        />
                      </Grid>
                      <Grid
                        item
                        xs={12}
                        sm={12}
                        md={12}
                        lg={6}
                        xl={6}
                        className={classes.displayFlex}
                      >
                        <FormControlLabel
                          value="5-10"
                          control={<Radio />}
                          label="5-10"
                        />
                      </Grid>
                      <Grid
                        item
                        xs={12}
                        sm={12}
                        md={12}
                        lg={6}
                        xl={6}
                        className={classes.displayFlex}
                      >
                        <FormControlLabel
                          value="10+"
                          control={<Radio />}
                          label="10+"
                        />
                      </Grid>
                    </Grid>
                  </RadioGroup>
                </FormControl>
                <Button
                  size="large"
                  className={classNames(
                    classes.smallTopMargin,
                    classes.floatRight
                  )}
                  style={{ marginBottom: "15px" }}
                  type="submit"
                  disableElevation
                  variant="contained"
                  color="primary"
                  onClick={(event) => {
                    this.handleSubmitClick(event);
                  }}
                >
                  Submit
                </Button>
              </div>
            </Grid>
          </Grid>
        </Grid>
      </Fragment>
    );
  }
}

CompanyInfo.propTypes = {
  classes: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => {
  return {
    ...state,
    loader: state.loader,
  };
};

const connectedCompanyInfoPage = withRouter(
  connect(mapStateToProps, null, null, {
    pure: false,
    loading,
  })(withStyles(styles)(CompanyInfo))
);

export { connectedCompanyInfoPage as CompanyInfo };
