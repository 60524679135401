import React, { useEffect, useState } from "react";
import {
  Typography,
  Divider,
  Avatar,
  Grid,
  Tooltip,
  Chip,
  makeStyles,
  Button,
} from "@material-ui/core";
import LocationOnIcon from "@material-ui/icons/LocationOn";
import Timeline from "@material-ui/lab/Timeline";
import FlagIcon from "@material-ui/icons/Flag";
import TimelineItem from "@material-ui/lab/TimelineItem";
import TimelineDot from "@material-ui/lab/TimelineDot";
import TimelineSeparator from "@material-ui/lab/TimelineSeparator";
import TimelineConnector from "@material-ui/lab/TimelineConnector";
import TimelineOppositeContent from "@material-ui/lab/TimelineOppositeContent";
import classNames from "classnames";
import moment from "moment";
import AssignmentIndIcon from "@material-ui/icons/AssignmentInd";
import ViewRecommendedhistoryPopup from "./viewRecommendedhistoryPopup";
import { capitalize } from "../../helpers";
import { EmployersLogo } from "./employersLogo";
import { ReactComponent as LiteExpLogo } from "../../assets/liteexp.svg";
import { ReactComponent as MatchedCriteria } from "../../assets/matched_criteria.svg";
import EmptyState from "../core/emptyState";

const useStyles = makeStyles(() => ({
  mainTitle: {
    fontSize: "24px",
    fontWeight: 600,
  },
  EmployersHeading: {
    display: "flex",
    alignItems: "center",
  },
  DailogTitle: {
    background: "#EAE4CF",
  },
  headerContent: {
    background: "#EAE4CF",
    padding: "40px",
    position: "relative",
  },
  smallTopMargin: {
    marginTop: "2%",
  },
  mediumBottomMargin: {
    marginBottom: "5%",
  },
  accomplishmentMainDiv: {
    marginLeft: "10px",
    fontWeight: "bold",
    fontSize: "18px",
  },
  accomplishmentUl: {
    position: "relative",
    paddingLeft: "28px",
  },
  matchedCriteriaIcon: {
    marginRight: "5px",
    position: "absolute",
    marginTop: "7px",
    left: 0,
  },
  gapTopGrid: {
    paddingLeft: "18px",
    display: "flex",
    alignItems: "center",
  },
  customFontBold: {
    fontWeight: "bold",
  },
  industryFontWeight: {
    fontWeight: 500,
  },
  paddingLeft15: {
    paddingLeft: "10px",
    display: "flex",
    alignItems: "center",
  },
  marginLeftDot: {
    marginLeft: "2px",
  },
  avatarFlag: {
    height: "64px",
    width: "64px",
    border: "13px !important",
    // "&:hover": {
    //     "-webkit-transform": "scale(1.5)",
    //     "-ms-transform": "scale(1.5)",
    //     "transform": "scale(1.5)",
    // },
    // transition: "transform .5s",
  },
  falgIconStyle: {
    color: "#FBC112",
    cursor: "pointer",
  },
  flagAva: {
    border: "1.5px solid #fcfaf5",
    height: "100%",
    width: "100%",
    padding: "15px",
    background: "#F4F4F5",
  },
  timelineStyle: {
    padding: "0px",
    margin: "0px",
    alignItems: "center",
  },
  timelineItemStyle: {
    height: "calc(100% - 24px)",
  },
  timelineDotStyle: {
    boxShadow: "none",
    backgroundColor: "#048475",
    marginTop: "12px",
  },
  timelineContentStyle: {
    padding: "3px 13px 0px 0px",
    width: "130px",
  },
  TimelineConnectorStyle: {
    height: "inherit",
    backgroundColor: "#048475",
  },
  padding52: {
    paddingLeft: "52px",
  },
  listStyleNone: {
    listStyle: "none",
  },
  timeLine: {
    marginTop: "0px",
  },
  paddingMedium: {
    paddingLeft: "15px",
  },
  marginDivider: {
    marginBottom: "10px",
  },
  gapDivider: {
    marginTop: "10px",
    marginBottom: "10px",
  },
  meadiumPaddingLeft: {
    paddingLeft: "15px",
  },
  timelineDotVisibile: {
    boxShadow: "none",
    backgroundColor: "#048475",
    marginTop: "12px",
    visibility: "hidden",
  },
  timelineGrid: {
    paddingLeft: "25px",
    marginTop: "0px !important",
  },
  paddingLeft50: {
    paddingLeft: "45px",
    // padding:"10px"
  },
  customBg: { backgroundColor: "#EDEDED" },
  customPaddingForCardContent: {
    paddingLeft: "24px",
    paddingRight: "24px",
    overflowWrap: "break-word",
    textAlign: "justify",
  },
  customMarginTop: {
    marginTop: "15px",
    paddingLeft: "30px",
  },
  divider: {
    marginTop: "10px",
    marginBottom: "10px",
  },
  iconHeightWidth: {
    height: 30,
    width: 30,
  },
  historyButton: {
    backgroundColor: "#000000", // '#FBC925',
    color: "#ffffff",
    width: "100%",
    borderRadius: "0px 0px 5px 5px !important",
    fontWeight: "normal !important",
    "&:hover": {
      backgroundColor: "#FBC925 !important",
      color: "#000000 !important",
    },
    justifyContent: "center",
    padding: "3%",
    boxShadow:
      "0px 0px 0px 1px rgb(0 0 0 / 5%), 0px 0px 0px 0px rgb(0 0 0 / 5%), 0px 0px 0px 1px rgb(0 0 0 / 5%)",
  },
  locationTypo: {
    display: "flex",
    alignItems: "center",
  },
  adminNotedDivider: {
    height: "1.5px",
    background: "#000000",
    marginTop: "3px",
    marginBottom: "3px",
  },
  marginBottom12: {
    marginBottom: "12px",
  },
  compiMainDiv: {
    textAlign: "left",
    paddingLeft: "10px",
  },
  compiChip: {
    marginRight: "5px",
    marginBottom: "3%",
    textIndent: "0",
    overflowY: "auto",
    height: "auto",
    padding: "5px",
  },
  marginRight5: {
    marginRight: "5px",
  },
  marginBottom20: {
    marginBottom: "20px",
  },
  paddingLeft15x: {
    paddingLeft: "15px",
  },
  paddingLeft25: {
    paddingLeft: 25,
  },
  paddingLeft35: {
    paddingLeft: 10,
  },
  eduMainDiv: {
    paddingTop: "16px",
    paddingBottom: "16px",
  },
  diversityIcon: {
    fontSize: "20px",
    position: "relative",
    top: "-40px",
    color: "#FFFFFF",
    left: "3px",
  },
  displayFlex: {
    display: "flex",
  },
}));

function LiteProfile(props) {
  const classes = useStyles();
  let companyIndexTemp = 0;
  const { liteProfileData } = props;
  const [openViewHistory, setOpenViewHistory] = useState(false);
  const [matchedBasesOn, setMatchedBasesOn] = useState({});

  const onCloseHisory = () => {
    setOpenViewHistory(false);
  };

  const openHistoryPopup = () => {
    setOpenViewHistory(true);
  };

  useEffect(() => {
    setMatchedBasesOn(liteProfileData?.match_based_on);
  }, [liteProfileData]);

  const EMPTY_ICON_HEIGHT = 140;
  const EMPTY_ICON_WIDTH = 140;

  return (
    <>
      <Grid container>
        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
          <div className={classes.headerContent}>
            {liteProfileData?.is_diversity ? (
              <span className="MuiCardHeader-root-custom">
                <span
                  className="diversity-ribbon"
                  style={{ position: "absolute", top: 0, left: 0 }}
                >
                  <AssignmentIndIcon className={classes.diversityIcon} />
                </span>
              </span>
            ) : (
              ""
            )}
            <Typography className={classes.mainTitle}>
              {matchedBasesOn?.title === liteProfileData?.title && (
                <MatchedCriteria
                  color="#00DCAF"
                  title="Profile Match"
                  className={classes.marginRight5}
                />
              )}
              {liteProfileData?.title}
            </Typography>
            <Typography component="span" className={classes.locationTypo}>
              <LocationOnIcon />{" "}
              {liteProfileData?.location.city
                ? liteProfileData?.location.city
                : ""}
              {liteProfileData?.location.city && liteProfileData?.location.state
                ? ", "
                : ""}
              {liteProfileData?.location.state
                ? liteProfileData?.location.state
                : ""}
              {(liteProfileData?.location.city ||
                liteProfileData?.location.state) &&
              liteProfileData?.location.country
                ? ", "
                : ""}
              {liteProfileData?.location.country
                ? liteProfileData?.location.country
                : ""}
              {!liteProfileData?.location.city &&
                !liteProfileData?.location.state &&
                !liteProfileData?.location.country &&
                "-"}
            </Typography>
            <Typography>
              {matchedBasesOn?.experience?.[0] ===
                liteProfileData?.additional_info?.experience && (
                <MatchedCriteria
                  color="#00DCAF"
                  title="Profile Match"
                  className={classes.marginRight5}
                />
              )}
              <b>Total Years of Experience</b>:{" "}
              {liteProfileData?.additional_info?.experience || "-"}
            </Typography>
            <Typography>
              {matchedBasesOn?.level?.[0] ===
                liteProfileData?.additional_info?.level && (
                <MatchedCriteria
                  color="#00DCAF"
                  title="Profile Match"
                  className={classes.marginRight5}
                />
              )}
              <b>Highest Level:</b>{" "}
              {liteProfileData?.additional_info?.level || "-"}
            </Typography>
            <Typography>
              <b>Largest Team Size Managed</b>:{" "}
              {liteProfileData?.additional_info?.team_size || "-"}
            </Typography>
            <Typography>
              {matchedBasesOn?.education?.degree &&
                liteProfileData?.highest_education?.degree &&
                matchedBasesOn?.education?.degree ===
                  liteProfileData?.highest_education?.degree && (
                  <MatchedCriteria
                    color="#00DCAF"
                    title="Profile Match"
                    className={classes.marginRight5}
                  />
                )}
              <b>Highest Education:</b>{" "}
              {liteProfileData?.highest_education?.degree
                ? `${liteProfileData?.highest_education?.degree} (${liteProfileData?.highest_education?.field_of_study})`
                : "-"}
            </Typography>
            <Typography>
              <b>Countries Worked In</b>:{" "}
              {liteProfileData?.additional_info?.countries_worked_in || "-"}
            </Typography>
          </div>

          <div
            className={classNames(
              classes.customMarginTop,
              classes.marginBottom12
            )}
          >
            <Typography className={classes.EmployersHeading}>
              <span className={classes.accomplishmentMainDiv}>Employers</span>
            </Typography>
            <Divider className={classes.divider} />
            {liteProfileData?.logo_info.length === 0 ? (
              <EmptyState
                type="employers"
                height={EMPTY_ICON_HEIGHT}
                width={EMPTY_ICON_WIDTH}
              />
            ) : (
              <EmployersLogo companiesLogoArray={liteProfileData?.logo_info} />
            )}
          </div>
          <div
            className={classNames(
              classes.customMarginTop,
              classes.mediumBottomMargin
            )}
          >
            <Typography className={classes.EmployersHeading}>
              <span className={classes.accomplishmentMainDiv}>
                Accomplishments
              </span>
            </Typography>
            <Divider className={classes.divider} />
            {
              liteProfileData?.accomplishments?.length > 0 ? (
                liteProfileData?.accomplishments?.map(
                  (accomplishment, indx) => (
                    <ul
                      key={indx}
                      className={classNames(
                        classes.smallTopMargin,
                        classes.accomplishmentUl
                      )}
                    >
                      <li>
                        {liteProfileData?.match_based_on?.accomplishments?.includes(
                          accomplishment
                        ) && (
                          <MatchedCriteria
                            title="Profile Match"
                            className={classes.matchedCriteriaIcon}
                          />
                        )}
                        <span>{accomplishment}</span>
                      </li>
                    </ul>
                  )
                )
              ) : (
                <EmptyState
                  type="accomplishments"
                  height={EMPTY_ICON_HEIGHT}
                  width={EMPTY_ICON_WIDTH}
                />
              ) // Accomplishments
            }
          </div>
          <div
            className={classNames(
              classes.customMarginTop,
              classes.mediumBottomMargin
            )}
          >
            <Typography className={classes.EmployersHeading}>
              <span className={classes.accomplishmentMainDiv}>
                Competencies
              </span>
            </Typography>
            <Divider className={classes.divider} />
            {
              liteProfileData?.skills?.length > 0 ? (
                <div className={classes.compiMainDiv}>
                  {liteProfileData?.skills?.map((skill, skillIndex) => (
                    <span>
                      <Chip
                        key={`skill ${skillIndex}`}
                        className={classes.compiChip}
                        label={
                          liteProfileData?.match_based_on?.skills?.includes(
                            skill
                          ) ? (
                            <div>
                              <MatchedCriteria
                                color="#00DCAF"
                                title="Profile Match"
                                className={classes.marginRight5}
                              />
                              <span>{skill}</span>
                            </div>
                          ) : (
                            <span>{skill}</span>
                          )
                        }
                        title={skill}
                      />
                    </span>
                  ))}
                </div>
              ) : (
                <EmptyState
                  type="competencies"
                  height={EMPTY_ICON_HEIGHT}
                  width={EMPTY_ICON_WIDTH}
                />
              ) // Competnecies
            }
          </div>
          <div
            className={classNames(
              classes.customMarginTop,
              classes.mediumBottomMargin
            )}
          >
            <Typography className={classes.EmployersHeading}>
              <span className={classes.accomplishmentMainDiv}>
                Work History
              </span>
            </Typography>
            <Divider className={classes.divider} />
            <div>
              {liteProfileData?.anonymous_work_experience.length === 0 ? (
                <EmptyState
                  type="workHistory"
                  height={EMPTY_ICON_HEIGHT}
                  width={EMPTY_ICON_WIDTH}
                />
              ) : (
                <Grid container>
                  {liteProfileData?.anonymous_work_experience?.map(
                    (companyExp, ind) => {
                      if (companyExp[0]?.experience_type !== "0") {
                        companyIndexTemp += 1;
                      }
                      return (
                        <>
                          {companyExp[0].experience_type === "0" ? (
                            <>
                              {companyExp?.length === 1 ? (
                                <Grid container>
                                  <Grid
                                    item
                                    xs={12}
                                    sm={12}
                                    md={12}
                                    lg={12}
                                    xl={12}
                                    className={classes.gapTopGrid}
                                  >
                                    <span>
                                      <Tooltip
                                        title="Employment Gap"
                                        aria-label="Gap"
                                      >
                                        <Avatar
                                          variant="square"
                                          className={classes.flagAva}
                                        >
                                          <FlagIcon
                                            className={classes.falgIconStyle}
                                          />
                                        </Avatar>
                                      </Tooltip>
                                    </span>
                                    <span>
                                      <ul
                                        className={classes.meadiumPaddingLeft}
                                      >
                                        <li className={classes.listStyleNone}>
                                          <Tooltip
                                            title="Title"
                                            arrow
                                            placement="left"
                                          >
                                            <Typography
                                              component="div"
                                              align="left"
                                              variant="h6"
                                              className={classes.customFontBold}
                                            >
                                              {capitalize(companyExp[0]?.title)}
                                            </Typography>
                                          </Tooltip>
                                          <Tooltip
                                            title="Reason"
                                            arrow
                                            placement="left"
                                          >
                                            <Typography
                                              align="left"
                                              variant="body2"
                                              className={classes.fontWeight700}
                                            >
                                              {companyExp[0]?.reason}
                                            </Typography>
                                          </Tooltip>
                                          <Typography
                                            color="textSecondary"
                                            align="left"
                                          >
                                            Total Gap -{" "}
                                            {companyExp[0]?.experience}
                                          </Typography>
                                        </li>
                                      </ul>
                                    </span>
                                  </Grid>
                                  <Grid
                                    item
                                    xs={12}
                                    sm={12}
                                    md={12}
                                    lg={12}
                                    xl={12}
                                  >
                                    <Divider className={classes.gapDivider} />
                                  </Grid>
                                </Grid>
                              ) : (
                                companyExp?.map((value) => (
                                  <Grid container>
                                    <Grid
                                      item
                                      xs={12}
                                      sm={12}
                                      md={12}
                                      lg={12}
                                      xl={12}
                                      className={classes.gapTopGrid}
                                    >
                                      <span>
                                        <Tooltip
                                          title="Employment Gap"
                                          aria-label="Gap"
                                        >
                                          <Avatar
                                            variant="square"
                                            className={classes.flagAva}
                                          >
                                            <FlagIcon
                                              className={classes.falgIconStyle}
                                            />
                                          </Avatar>
                                        </Tooltip>
                                      </span>
                                      <span>
                                        <ul
                                          className={classes.meadiumPaddingLeft}
                                        >
                                          <li className={classes.listStyleNone}>
                                            <Tooltip
                                              title="Title"
                                              arrow
                                              placement="left"
                                            >
                                              <Typography
                                                component="div"
                                                align="left"
                                                variant="h6"
                                                className={
                                                  classes.customFontBold
                                                }
                                              >
                                                {capitalize(value?.title)}
                                              </Typography>
                                            </Tooltip>
                                            <Tooltip
                                              title="Reason"
                                              arrow
                                              placement="left"
                                            >
                                              <Typography
                                                align="left"
                                                variant="body2"
                                                className={
                                                  classes.fontWeight700
                                                }
                                              >
                                                {value?.reason}
                                              </Typography>
                                            </Tooltip>
                                            <Typography
                                              color="textSecondary"
                                              align="left"
                                            >
                                              Total Gap - {value?.experience}
                                            </Typography>
                                          </li>
                                        </ul>
                                      </span>
                                    </Grid>
                                    <Grid
                                      item
                                      xs={12}
                                      sm={12}
                                      md={12}
                                      lg={12}
                                      xl={12}
                                    >
                                      <Divider className={classes.gapDivider} />
                                    </Grid>
                                  </Grid>
                                ))
                              )}
                            </>
                          ) : (
                            <Grid
                              item
                              xs={12}
                              sm={12}
                              md={12}
                              lg={12}
                              xl={12}
                              className={classes.paddingLeft15}
                            >
                              <span>
                                <Avatar
                                  variant="square"
                                  className={classes.avatarFlag}
                                >
                                  <LiteExpLogo />
                                </Avatar>
                              </span>
                              <span>
                                <ul className={classes.paddingMedium}>
                                  <li className={classes.listStyleNone}>
                                    <Tooltip
                                      title="Company"
                                      arrow
                                      placement="left"
                                    >
                                      <Typography
                                        component="div"
                                        align="left"
                                        variant="h6"
                                      >
                                        <span
                                          className={classes.industryFontWeight}
                                        >
                                          {`Company ${companyIndexTemp}`}
                                        </span>
                                        <span
                                          className={classes.customFontBold}
                                        >
                                          {companyExp[0]?.industry === "Other"
                                            ? ` [ Industry: ${capitalize(
                                                companyExp[0]
                                                  ?.other_industry_text
                                              )} ]`
                                            : companyExp[0]?.industry
                                            ? ` [ Industry: ${capitalize(
                                                companyExp[0]?.industry
                                              )} ]`
                                            : ""}
                                        </span>
                                      </Typography>
                                    </Tooltip>
                                    <Typography align="left">
                                      <Typography
                                        align="left"
                                        component="span"
                                        variant="body2"
                                        className={classes.fontWeight700}
                                      >
                                        {companyExp[0]?.company_size
                                          ? `${companyExp[0]?.company_size}  Employees`
                                          : " "}
                                        {companyExp[0]?.company_size &&
                                        companyExp[0]?.team_managed
                                          ? " , "
                                          : ""}
                                        {companyExp[0]?.team_managed
                                          ? ` Team Size Managed : ${companyExp[0]?.team_managed}`
                                          : ""}
                                      </Typography>
                                      <Tooltip
                                        title="Location"
                                        arrow
                                        placement="left"
                                      >
                                        <Typography
                                          align="left"
                                          component="span"
                                          variant="body2"
                                          className={classes.fontWeight700}
                                        >
                                          {(companyExp[0]?.team_managed ||
                                            companyExp[0]?.company_size) &&
                                          (companyExp[0]?.location?.city ||
                                            companyExp[0]?.location?.state ||
                                            companyExp[0]?.location
                                              ?.country) ? (
                                            <span
                                              className={classes.marginLeftDot}
                                            >
                                              {" "}
                                              .{" "}
                                            </span>
                                          ) : null}
                                          {companyExp[0]?.location?.city
                                            ? companyExp[0]?.location?.city
                                            : ""}
                                          {companyExp[0]?.location?.city &&
                                          companyExp[0]?.location?.state
                                            ? ", "
                                            : null}
                                          {companyExp[0]?.location?.state
                                            ? companyExp[0]?.location?.state
                                            : ""}
                                          {companyExp[0]?.location?.country &&
                                          companyExp[0]?.location?.state
                                            ? ", "
                                            : null}
                                          {companyExp[0]?.location?.country
                                            ? companyExp[0]?.location?.country
                                            : ""}
                                        </Typography>
                                      </Tooltip>
                                    </Typography>
                                    <Typography
                                      align="left"
                                      color="textSecondary"
                                    >
                                      Total Years of Experience -{" "}
                                      {companyExp[0]?.total_experience}
                                    </Typography>
                                  </li>
                                </ul>
                              </span>
                            </Grid>
                          )}
                          {companyExp[0].experience_type !== "0" && (
                            <Grid
                              item
                              xs={12}
                              sm={12}
                              md={12}
                              lg={12}
                              xl={12}
                              className={classes.timelineGrid}
                            >
                              {companyExp.length === 1 && (
                                <Timeline
                                  align="left"
                                  className={classes.timeLine}
                                >
                                  <TimelineItem>
                                    <TimelineDot
                                      className={classes.timelineDotVisibile}
                                    />
                                    <TimelineOppositeContent
                                      className={classes.paddingLeft50}
                                    >
                                      {companyExp.length === 1 &&
                                        companyExp.map((exp) => (
                                          <>
                                            <Typography align="left">
                                              <Tooltip
                                                title="Title"
                                                arrow
                                                placement="left"
                                              >
                                                <Typography
                                                  component="span"
                                                  align="left"
                                                  variant="body2"
                                                  className={
                                                    classes.fontWeight700
                                                  }
                                                >
                                                  {matchedBasesOn?.previous_titles?.includes(
                                                    capitalize(exp?.title)
                                                  ) && (
                                                    <MatchedCriteria
                                                      color="#00DCAF"
                                                      title="Profile Match"
                                                      className={
                                                        classes.marginRight5
                                                      }
                                                    />
                                                  )}{" "}
                                                  {capitalize(exp?.title)}
                                                </Typography>
                                              </Tooltip>
                                              {/* <Tooltip title="Industry" arrow placement='left'><Typography align='left' component={'span'} variant='body2' className={classes.fontWeight700}>{exp?.industry ? `, ${exp?.industry === 'Other' ? exp?.other_industry_text : capitalize(exp?.industry)}` : null}</Typography></Tooltip> */}
                                            </Typography>
                                            <Tooltip
                                              title="Duration"
                                              arrow
                                              placement="left"
                                            >
                                              <Typography
                                                align="left"
                                                color="textSecondary"
                                              >
                                                {" "}
                                                {exp?.starts_at === null
                                                  ? ""
                                                  : moment(
                                                      exp?.starts_at
                                                    ).format("MMMM YYYY")}{" "}
                                                -{" "}
                                                {exp?.ends_at === "Present"
                                                  ? "Present"
                                                  : exp?.ends_at === null
                                                  ? ""
                                                  : moment(exp?.ends_at).format(
                                                      "MMMM YYYY"
                                                    )}{" "}
                                              </Typography>
                                            </Tooltip>
                                          </>
                                        ))}
                                    </TimelineOppositeContent>
                                  </TimelineItem>
                                </Timeline>
                              )}
                              {companyExp.length > 1 && (
                                <Timeline
                                  align="right"
                                  className={classes.timeLine}
                                >
                                  {companyExp.map((exp, ind) => (
                                    <TimelineItem>
                                      <TimelineOppositeContent
                                        className={classes.paddingLeft50}
                                      >
                                        <Typography align="left">
                                          <Tooltip
                                            title="Title"
                                            arrow
                                            placement="left"
                                          >
                                            <Typography
                                              component="span"
                                              align="left"
                                              variant="body2"
                                              className={classes.fontWeight700}
                                            >
                                              {matchedBasesOn?.previous_titles?.includes(
                                                capitalize(exp?.title)
                                              ) && (
                                                <MatchedCriteria
                                                  color="#00DCAF"
                                                  title="Profile Match"
                                                  className={
                                                    classes.marginRight5
                                                  }
                                                />
                                              )}{" "}
                                              {capitalize(exp?.title)}
                                            </Typography>
                                          </Tooltip>
                                          {/* <Tooltip title="Industry" arrow placement='left'><Typography align='left' component={'span'} variant='body2' className={classes.fontWeight700}>{exp?.industry ? `, ${exp?.industry === 'Other' ? exp?.other_industry_text : capitalize(exp?.industry)}` : null}</Typography></Tooltip> */}
                                        </Typography>
                                        <Tooltip
                                          title="Duration"
                                          arrow
                                          placement="left"
                                        >
                                          <Typography
                                            component="span"
                                            align="left"
                                            color="textSecondary"
                                          >
                                            {" "}
                                            {exp?.starts_at === null
                                              ? ""
                                              : moment(exp?.starts_at).format(
                                                  "MMMM YYYY"
                                                )}{" "}
                                            -{" "}
                                            {exp?.ends_at === "Present"
                                              ? "Present"
                                              : exp?.ends_at === null
                                              ? ""
                                              : moment(exp?.ends_at).format(
                                                  "MMMM YYYY"
                                                )}{" "}
                                          </Typography>
                                        </Tooltip>
                                        <Tooltip
                                          title="Years of Experience"
                                          arrow
                                          placement="right"
                                        >
                                          <Typography
                                            component="span"
                                            color="textSecondary"
                                            align="left"
                                          >
                                            {exp?.experience
                                              ? `, ${exp?.experience}`
                                              : ""}
                                          </Typography>
                                        </Tooltip>
                                      </TimelineOppositeContent>
                                      <TimelineSeparator>
                                        <TimelineDot
                                          className={classes.timelineDotStyle}
                                        />
                                        {companyExp?.length !== ind + 1 ? (
                                          <TimelineConnector
                                            className={
                                              classes.TimelineConnectorStyle
                                            }
                                          />
                                        ) : null}
                                      </TimelineSeparator>
                                    </TimelineItem>
                                  ))}
                                </Timeline>
                              )}
                              {liteProfileData?.anonymous_work_experience
                                .length < 5 &&
                                liteProfileData?.anonymous_work_experience
                                  .length !==
                                  ind + 1 && (
                                  <Divider className={classes.marginDivider} />
                                )}
                              {liteProfileData?.anonymous_work_experience
                                .length > 5 &&
                                ind + 1 !== 5 && (
                                  <Divider className={classes.marginDivider} />
                                )}
                            </Grid>
                          )}
                        </>
                      );
                    }
                  )}
                </Grid>
              )}
            </div>
          </div>
          <div
            className={classNames(
              classes.customMarginTop,
              classes.mediumBottomMargin
            )}
          >
            <Typography className={classes.EmployersHeading}>
              <span className={classes.accomplishmentMainDiv}>
                Board Advisor Role/Volunteer Experiences
              </span>
            </Typography>
            <Divider className={classes.divider} />
            <div>
              {liteProfileData?.board_advisor_experience.length === 0 ? (
                <EmptyState
                  type="boradAdvisorVolunteer"
                  height={EMPTY_ICON_HEIGHT}
                  width={EMPTY_ICON_WIDTH}
                />
              ) : (
                <Grid container>
                  {liteProfileData?.board_advisor_experience?.map(
                    (companyExp, ind) => (
                      <>
                        {ind < 5 && (
                          <>
                            <Grid
                              item
                              xs={12}
                              sm={12}
                              md={12}
                              lg={12}
                              xl={12}
                              className={classes.paddingLeft15}
                            >
                              <span>
                                <Avatar
                                  variant="square"
                                  className={classes.avatarFlag}
                                >
                                  <LiteExpLogo />
                                </Avatar>
                              </span>
                              <span>
                                <ul className={classes.paddingMedium}>
                                  <li className={classes.listStyleNone}>
                                    <Tooltip
                                      title="Company"
                                      arrow
                                      placement="left"
                                    >
                                      <Typography
                                        component="div"
                                        align="left"
                                        variant="h6"
                                        className={classes.industryFontWeight}
                                      >
                                        <span>{`Company ${ind + 1}`}</span>
                                        <span
                                          className={classes.customFontBold}
                                        >
                                          {companyExp[0]?.industry === "Other"
                                            ? ` [ Industry: ${capitalize(
                                                companyExp[0]
                                                  ?.other_industry_text
                                              )} ]`
                                            : companyExp[0]?.industry
                                            ? ` [ Industry: ${capitalize(
                                                companyExp[0]?.industry
                                              )} ]`
                                            : ""}
                                        </span>
                                      </Typography>
                                    </Tooltip>
                                    <Typography align="left">
                                      <Typography
                                        align="left"
                                        component="span"
                                        variant="body2"
                                        className={classes.fontWeight700}
                                      >
                                        {companyExp[0]?.company_size
                                          ? `${companyExp[0]?.company_size}  Employees`
                                          : " "}
                                        {companyExp[0]?.company_size &&
                                        companyExp[0]?.team_managed
                                          ? " , "
                                          : ""}
                                        {companyExp[0]?.team_managed
                                          ? ` Team Size Managed : ${companyExp[0]?.team_managed}`
                                          : ""}
                                      </Typography>
                                      <Tooltip
                                        title="Location"
                                        arrow
                                        placement="left"
                                      >
                                        <Typography
                                          align="left"
                                          component="span"
                                          variant="body2"
                                          className={classes.fontWeight700}
                                        >
                                          {(companyExp[0]?.team_managed ||
                                            companyExp[0]?.company_size) &&
                                          (companyExp[0]?.location?.city ||
                                            companyExp[0]?.location?.state ||
                                            companyExp[0]?.location
                                              ?.country) ? (
                                            <span
                                              className={classes.marginLeftDot}
                                            >
                                              {" "}
                                              .{" "}
                                            </span>
                                          ) : null}
                                          {companyExp[0]?.location?.city
                                            ? companyExp[0]?.location?.city
                                            : ""}
                                          {companyExp[0]?.location?.city &&
                                          companyExp[0]?.location?.state
                                            ? ", "
                                            : null}
                                          {companyExp[0]?.location?.state
                                            ? companyExp[0]?.location?.state
                                            : ""}
                                          {companyExp[0]?.location?.country &&
                                          companyExp[0]?.location?.state
                                            ? ", "
                                            : null}
                                          {companyExp[0]?.location?.country
                                            ? companyExp[0]?.location?.country
                                            : ""}
                                        </Typography>
                                      </Tooltip>
                                    </Typography>
                                    <Typography
                                      align="left"
                                      color="textSecondary"
                                    >
                                      Total Years of Experience -{" "}
                                      {companyExp[0]?.total_experience}
                                    </Typography>
                                  </li>
                                </ul>
                              </span>
                            </Grid>

                            <Grid
                              item
                              xs={12}
                              sm={12}
                              md={12}
                              lg={12}
                              xl={12}
                              className={classes.timelineGrid}
                            >
                              {companyExp.length === 1 && (
                                <Timeline
                                  align="left"
                                  className={classes.timeLine}
                                >
                                  <TimelineItem>
                                    <TimelineDot
                                      className={classes.timelineDotVisibile}
                                    />
                                    <TimelineOppositeContent
                                      className={classes.paddingLeft50}
                                    >
                                      {companyExp.length === 1 &&
                                        companyExp.map((exp) => (
                                          <>
                                            <Typography align="left">
                                              <Tooltip
                                                title="Title"
                                                arrow
                                                placement="left"
                                              >
                                                <Typography
                                                  component="span"
                                                  align="left"
                                                  variant="body2"
                                                  className={
                                                    classes.fontWeight700
                                                  }
                                                >
                                                  {capitalize(exp?.title)}
                                                </Typography>
                                              </Tooltip>
                                              {/* <Tooltip title="Industry" arrow placement='left'> */}
                                              {/* <Typography align='left' component={'span'} variant='body2' className={classes.fontWeight700}>{exp?.industry ? `, ${exp?.industry === 'Other' ? exp?.other_industry_text : capitalize(exp?.industry)}` : null}</Typography> */}
                                              {/* </Tooltip> */}
                                            </Typography>
                                            <Tooltip
                                              title="Duration"
                                              arrow
                                              placement="left"
                                            >
                                              <Typography
                                                align="left"
                                                color="textSecondary"
                                              >
                                                {" "}
                                                {exp?.starts_at === null
                                                  ? ""
                                                  : moment(
                                                      exp?.starts_at
                                                    ).format("MMMM YYYY")}{" "}
                                                -{" "}
                                                {exp?.ends_at === "Present"
                                                  ? "Present"
                                                  : exp?.ends_at === null
                                                  ? ""
                                                  : moment(exp?.ends_at).format(
                                                      "MMMM YYYY"
                                                    )}{" "}
                                              </Typography>
                                            </Tooltip>
                                          </>
                                        ))}
                                    </TimelineOppositeContent>
                                  </TimelineItem>
                                </Timeline>
                              )}
                              {companyExp.length > 1 && (
                                <Timeline
                                  align="right"
                                  className={classes.timeLine}
                                >
                                  {companyExp.map((exp, ind) => (
                                    <TimelineItem>
                                      <TimelineOppositeContent
                                        className={classes.paddingLeft50}
                                      >
                                        <Typography align="left">
                                          <Tooltip
                                            title="Title"
                                            arrow
                                            placement="left"
                                          >
                                            <Typography
                                              component="span"
                                              align="left"
                                              variant="body2"
                                              className={classes.fontWeight700}
                                            >
                                              {capitalize(exp?.title)}
                                            </Typography>
                                          </Tooltip>
                                          {/* <Tooltip title="Industry" arrow placement='left'>
                                                                                    <Typography align='left' component={'span'} variant='body2' className={classes.fontWeight700}>{exp?.industry ? `, ${exp?.industry === 'Other' ? exp?.other_industry_text : capitalize(exp?.industry)}` : null}</Typography>
                                                                                </Tooltip> */}
                                        </Typography>
                                        <Tooltip
                                          title="Duration"
                                          arrow
                                          placement="left"
                                        >
                                          <Typography
                                            component="span"
                                            align="left"
                                            color="textSecondary"
                                          >
                                            {" "}
                                            {exp?.starts_at === null
                                              ? ""
                                              : moment(exp?.starts_at).format(
                                                  "MMMM YYYY"
                                                )}{" "}
                                            -{" "}
                                            {exp?.ends_at === "Present"
                                              ? "Present"
                                              : exp?.ends_at === null
                                              ? ""
                                              : moment(exp?.ends_at).format(
                                                  "MMMM YYYY"
                                                )}{" "}
                                          </Typography>
                                        </Tooltip>
                                        <Tooltip
                                          title="Years of Experience"
                                          arrow
                                          placement="right"
                                        >
                                          <Typography
                                            component="span"
                                            color="textSecondary"
                                            align="left"
                                          >
                                            {exp?.experience
                                              ? `, ${exp?.experience}`
                                              : ""}
                                          </Typography>
                                        </Tooltip>
                                      </TimelineOppositeContent>
                                      <TimelineSeparator>
                                        <TimelineDot
                                          className={classes.timelineDotStyle}
                                        />
                                        {companyExp?.length !== ind + 1 ? (
                                          <TimelineConnector
                                            className={
                                              classes.TimelineConnectorStyle
                                            }
                                          />
                                        ) : null}
                                      </TimelineSeparator>
                                    </TimelineItem>
                                  ))}
                                </Timeline>
                              )}
                              {liteProfileData?.board_advisor_experience
                                .length < 5 &&
                                liteProfileData?.board_advisor_experience
                                  .length !==
                                  ind + 1 && (
                                  <Divider className={classes.marginDivider} />
                                )}
                              {liteProfileData?.board_advisor_experience
                                .length > 5 &&
                                ind + 1 !== 5 && (
                                  <Divider className={classes.marginDivider} />
                                )}
                            </Grid>
                          </>
                        )}
                      </>
                    )
                  )}
                </Grid>
              )}
            </div>
          </div>
          <div
            className={classNames(
              classes.customMarginTop,
              classes.mediumBottomMargin,
              classes.marginBottom20
            )}
          >
            <Typography className={classes.EmployersHeading}>
              <span className={classes.accomplishmentMainDiv}>Education</span>
            </Typography>
            <Divider className={classes.divider} />
            {liteProfileData?.education?.length === 0 ? (
              <EmptyState
                type="education"
                height={EMPTY_ICON_HEIGHT}
                width={EMPTY_ICON_WIDTH}
              /> // Education
            ) : (
              <>
                {liteProfileData?.education?.map((edu, idx) => (
                  <div
                    key={idx}
                    className={classNames(
                      idx % 2 === 0 ? classes.customBg : null,
                      classes.customPaddingForCardContent,
                      classes.eduMainDiv
                    )}
                  >
                    <Typography
                      color="textSecondary"
                      component="div"
                      align="left"
                      className={classes.paddingLeft15x}
                    >
                      {edu.degree ? edu.degree : ""}
                    </Typography>
                    <Typography
                      component="div"
                      color="textSecondary"
                      align="left"
                      className={classes.paddingLeft15x}
                    >
                      {edu.field_of_study ? edu.field_of_study : ""}
                    </Typography>
                  </div>
                ))}
              </>
            )}
          </div>
          {/* <div className={classNames(classes.customMarginTop, classes.mediumBottomMargin, classes.marginBottom20)}>
                        <Typography className={classes.EmployersHeading}><span className={classes.accomplishmentMainDiv}>Professional Certifications</span></Typography>
                        <Divider className={classes.divider} />
                        {liteProfileData?.certifications?.length === 0
                            ?
                            <Typography className={classNames(classes.mediumTopMargin, classes.smallBottomMargin)} align='center' color='secondary'>No data available</Typography>
                            :
                            <>
                                {liteProfileData?.certifications?.map((edu, idx) => {
                                    return (
                                        <>

                                            <div key={idx} className={classNames((idx % 2 === 0) ? classes.customBg : null, classes.customPaddingForCardContent, classes.eduMainDiv)}>
                                                <Typography color='textSecondary' component='div' align='left' className={classes.paddingLeft15x}>
                                                    {edu.name ? edu.name : ''}
                                                </Typography>
                                                <Typography component='div' color='textSecondary' align='left' className={classes.paddingLeft15x}>
                                                    {edu.orgnization ? edu.orgnization : ''}
                                                </Typography>
                                                <Typography component='div' color='textSecondary' align='left' className={classes.paddingLeft15x}>
                                                    {edu.starts_at ? moment(edu.starts_at).format('MM/YYYY') : ''} -  {edu.ends_at ? moment(edu.ends_at).format('MM/YYYY') : ''}
                                                </Typography>
                                            </div>

                                        </>
                                    )
                                })}

                            </>
                        }
                    </div> */}
          <div
            className={classNames(
              classes.customMarginTop,
              classes.mediumBottomMargin,
              classes.marginBottom20
            )}
          >
            <Typography className={classes.EmployersHeading}>
              <span className={classes.accomplishmentMainDiv}>
                Matched Based On
              </span>
            </Typography>
            <Divider className={classes.divider} />

            {matchedBasesOn && Object.keys(matchedBasesOn)?.length === 0 ? (
              <EmptyState
                type="matchesBasedOn"
                height={EMPTY_ICON_HEIGHT}
                width={EMPTY_ICON_WIDTH}
              /> // Matches Based On
            ) : (
              <Typography className={classes.paddingLeft35}>
                {matchedBasesOn?.accomplishments?.length > 0 && (
                  <Typography>
                    <MatchedCriteria
                      color="#00DCAF"
                      title="Profile Match"
                      className={classes.marginRight5}
                    />
                    <b>Accomplishments</b>
                  </Typography>
                )}
                {matchedBasesOn?.skills?.length > 0 && (
                  <Typography>
                    <MatchedCriteria
                      color="#00DCAF"
                      title="Profile Match"
                      className={classes.marginRight5}
                    />
                    <b>Competencies</b>
                  </Typography>
                )}
                {matchedBasesOn?.title && (
                  <Typography>
                    <MatchedCriteria
                      color="#00DCAF"
                      title="Profile Match"
                      className={classes.marginRight5}
                    />
                    <b>Title :</b> {matchedBasesOn?.title}
                  </Typography>
                )}
                {matchedBasesOn?.previous_titles?.length > 0 && (
                  <Typography>
                    <MatchedCriteria
                      color="#00DCAF"
                      title="Profile Match"
                      className={classes.marginRight5}
                    />
                    <b>Previous Titles:</b>{" "}
                    {matchedBasesOn?.previous_titles?.join(",")}
                  </Typography>
                )}
                {matchedBasesOn?.level?.[0] && (
                  <Typography>
                    <MatchedCriteria
                      color="#00DCAF"
                      title="Profile Match"
                      className={classes.marginRight5}
                    />
                    <b>Highest Level:</b> {matchedBasesOn?.level?.[0]}
                  </Typography>
                )}
                {matchedBasesOn?.experience?.[0] && (
                  <Typography>
                    <MatchedCriteria
                      color="#00DCAF"
                      title="Profile Match"
                      className={classes.marginRight5}
                    />
                    <b>Total Years Of Experience:</b>
                    {matchedBasesOn?.experience?.[0]}
                  </Typography>
                )}
                {matchedBasesOn?.education?.degree && (
                  <Typography>
                    <MatchedCriteria
                      color="#00DCAF"
                      title="Profile Match"
                      className={classes.marginRight5}
                    />
                    <b>Highest Education:</b>
                    {matchedBasesOn?.education?.degree}
                  </Typography>
                )}
              </Typography>
            )}
          </div>
          <div />
        </Grid>
      </Grid>
      <div>
        <Button className={classes.historyButton} onClick={openHistoryPopup}>
          VIEW RECOMMENDATION HISTORY
        </Button>
      </div>
      {openViewHistory && (
        <ViewRecommendedhistoryPopup
          open={openViewHistory}
          onClose={onCloseHisory}
          recommendationId={liteProfileData?.recommendation_id}
        />
      )}
    </>
  );
}

export default LiteProfile;
