import { toast } from "react-toastify";
import { apiService } from "../services";
import { history } from "../helpers";
import { myAccountActions } from "./myAccount.actions";
import { showResponseToast } from "../helpers/toastHelpers";
import secureLocalStorage from "react-secure-storage";

export const paymentActions = {
  payForSubscription,
  savePaymentDetails,
  updatePaymentDetails,
  finalPayment,
};

function payForSubscription(payload) {
  return (dispatch) => {
    const apiEndpoint = "/api/v1/payment/record_payment/";
    apiService
      .post(apiEndpoint, payload)
      .then((response) => {
        if (
          !response.data.error &&
          (response.status === 200 || response.status === 201)
        ) {
          // dispatch(getRecordedPayment(response.data));
          // toast.dark(response.data.message);
          // console.log(response.data);
          if (response.data.status === "requires_action") {
            // dispatch(initiateAuthetication());
            window.open(response.data.redirect_to_url.url, "_self");
          } else {
            dispatch(getRecordedPayment(response.data));
            // toast.dark(response.data.message);
          }
        } else if (response.status !== 401) {
          toast.dark(response.data.message);
          history.push("/payment");
        }
      })
      .catch((err) => {
        console.log(err);
      });
    // .finally(() =>{
    //     dispatch(resetFlags());
    // })
  };
}

function finalPayment(payload) {
  return (dispatch) => {
    const apiEndpoint = "/api/v1/payment/payment_3d/";
    apiService
      .post(apiEndpoint, payload)
      .then((response) => {
        if (
          !response.data.error &&
          (response.status === 200 || response.status === 201)
        ) {
          dispatch(getRecordedPayment(response.data));
        } else if (response.status !== 401) {
          history.push("/payment");
        }
        showResponseToast(response, "Payment successful", "Failure in payment");
      })
      .catch((err) => {
        console.log(err);
      });
    // .finally(() =>{
    //     dispatch(resetFlags());
    // })
  };
}

function savePaymentDetails(paymentDetails, planType, amount) {
  return (dispatch) => {
    dispatch(setPaymentInfo(paymentDetails, planType, amount));
    history.push("/confirm-payment");
  };
}

function updatePaymentDetails(paymentDetails, recruiterId, flag) {
  return (dispatch) => {
    let apiEndpoint = "";
    if (flag === "Bank") {
      apiEndpoint = `/api/v1/payment/${recruiterId}/change_payment_details/?id=${paymentDetails.bank_account.id}&flag=${flag}&stripe_token=${paymentDetails.id}`;
    } else {
      apiEndpoint = `/api/v1/payment/${recruiterId}/change_payment_details/?id=${paymentDetails.id}&flag=${flag}`;
    }
    apiService
      .post(apiEndpoint, {})
      .then((response) => {
        if (response && (response.status === 200 || response.status === 201)) {
          dispatch(getPaymentDetails(recruiterId));
          dispatch(
            myAccountActions.getAllBillingData(
              JSON.parse(secureLocalStorage.getItem("recruiterStatusDetails"))
                .company.id
            )
          );
        }
        showResponseToast(
          response,
          "Payment details updated successfully",
          "Failure while updating payment details"
        );
      })
      .catch((err) => {
        console.log(err);
      });
  };
}

function getPaymentDetails(recruiterId) {
  return (dispatch) => {
    const apiEndpoint = `/api/v1/payment/${recruiterId}/card_detail/`;
    apiService
      .get(apiEndpoint)
      .then((response) => {
        if (response && (response.status === 200 || response.status === 201)) {
          dispatch(getPaymentData(response.data.data));
        } else if (response.status !== 401) {
          toast.dark("Something went haywire");
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };
}

function getRecordedPayment(data) {
  return {
    type: "RECORDED_PAYMENT",
    recordedPayment: data,
    open: true,
    // authenticationInitiated: false
  };
}

// function resetFlags() {
//     return {
//         type: 'RESET_FLAGS',
//         open: false
//     }
// }

// function initiateAuthetication(data){
//     return {
//         type: 'AUTHENTICATION_INITIATED',
//         open: false,
//         authenticationInitiated: true
//     }
// }

function setPaymentInfo(data, planType, amount) {
  return {
    type: "SET_PAYMENT_INFO",
    paymentInfo: data,
    paymentPlan: planType,
    totalAmount: amount,
  };
}

function getPaymentData(data) {
  return {
    type: "UPDATED_PAYMENT_DETAILS",
    updatedPaymentDetails: data,
    editPayment: false,
  };
}
