import React, { Component, Fragment } from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import Footer from "../../core/footer";
import PreLoginContent from "../../core/preLoginContent";
import Paper from "@material-ui/core/Paper";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import { connect } from "react-redux";
import { history, seo } from "../../../helpers";
import Typography from "@material-ui/core/Typography";
import Dialog from "@material-ui/core/Dialog";
import MuiDialogTitle from "@material-ui/core/DialogTitle";
import MuiDialogContent from "@material-ui/core/DialogContent";
import MuiDialogActions from "@material-ui/core/DialogActions";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import { withRouter } from "react-router-dom";
import Link from "@material-ui/core/Link";
import ProductInfoImage from "../../../assets/product_info.svg";
import InputAdornment from "@material-ui/core/InputAdornment";
import Visibility from "@material-ui/icons/Visibility";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import validator from "validator";
import { Auth } from "aws-amplify";
import { toast } from "react-toastify";
import { loading, setStep, saveEmailForPasswordReset } from "../../../actions";
import cardHeaderBg from "../../../assets/lite_profile_bg.png";
import OtpInput from "react-otp-input";
import classNames from "classnames";
import { setTitle } from "../../../actions";
import { FormLabel } from "@material-ui/core";

const styles = (theme) => ({
  root: {
    height: "calc(100vh - 90px)",
    marginTop: "90px",
  },
  image: {
    backgroundImage: `url(${ProductInfoImage})`, //'url(https://source.unsplash.com/random)',
    backgroundRepeat: "no-repeat",
    backgroundColor:
      theme.palette.type === "light"
        ? theme.palette.grey[50]
        : theme.palette.grey[900],
    backgroundSize: "cover",
  },
  paper: {
    margin: theme.spacing(2, 4),
    [theme.breakpoints.down("md")]: {
      margin: theme.spacing(3, 4),
    },
    [theme.breakpoints.down("sm")]: {
      margin: theme.spacing(2, 4),
    },
    [theme.breakpoints.down("xs")]: {
      margin: theme.spacing(2, 1),
    },
    alignItems: "flex-start",
    marginTop: "5%",
  },
  boldFont: {
    fontWeight: "bold",
  },
  signInBtn: {
    float: "left",
    width: "14em",
    backgroundColor: "#FBC112",
    color: "#000000",
  },
  nextBtn: {
    float: "left",
    width: "12em",
    backgroundColor: "#FBC112",
    color: "#000000",
  },
  spacing: {
    padding: "3% 3% 7% 3%",
    [theme.breakpoints.between("xs", "md")]: {
      padding: "5% 5% 15% 5%",
    },
    // [theme.breakpoints.between('md', 'md')]: {
    //   padding: '25% 5%'
    // },
    backgroundColor: "#F8F6EF", //'#E5E5E5'
  },
  customPaper: {
    margin: theme.spacing(1, 4),
    [theme.breakpoints.down("md")]: {
      margin: theme.spacing(3, 4),
    },
    [theme.breakpoints.down("sm")]: {
      margin: theme.spacing(2, 4),
    },
    [theme.breakpoints.down("xs")]: {
      margin: theme.spacing(2, 1),
    },
    display: "flex",
    alignItems: "flex-start",
  },
  newCustomPaper: {
    borderRadius: "4px",
    padding: "1%",
  },
  dialogPaper: {
    margin: theme.spacing(1, 10),
    // display: 'flex',
    alignItems: "center",
  },
  customDialog: {
    borderRadius: "0px !important",
    boxShadow: "none !important",
  },
  customForm: {
    margin: theme.spacing(5, 4),
    display: "flex",
    alignItems: "center",
    [theme.breakpoints.down("md")]: {
      margin: theme.spacing(3, 4),
    },
    [theme.breakpoints.down("sm")]: {
      margin: theme.spacing(2, 4),
    },
    [theme.breakpoints.down("xs")]: {
      margin: theme.spacing(2, 1),
    },
  },
  xxLargeFont: {
    fontSize: "xx-large",
    [theme.breakpoints.down("md")]: {
      fontSize: "22px",
    },
    [theme.breakpoints.down("sm")]: {
      fontSize: "22px",
    },
  },
  bottomSmall: {
    marginBottom: "9%",
  },
  resendLink: {
    cursor: "pointer",
    "&:hover": {
      textDecoration: "underline",
    },
    color: "#048475 !important",
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  smallTopMargin: {
    marginTop: "3%",
  },
  verySmallTopMargin: {
    marginTop: "1%",
  },
  mediumTopMargin: {
    marginTop: "6%",
  },
  submit: {
    margin: theme.spacing(0, 0, 0),
    borderRadius: "4px !important",
  },
  alignRight: {
    textAlign: "right",
  },
  margin: {
    marginTop: theme.spacing(2),
  },
  withoutLabel: {
    marginTop: theme.spacing(3),
  },
  textField: {
    width: "100%",
  },
  disabledSignIn: {
    cursor: "not-allowed",
    borderRadius: "4px !important",
  },
  toolbar: {
    padding: "1% !important",
    left: "1.5% !important",
  },
  responsive: {
    maxWidth: "100% !important",
    height: "auto !important",
    width: "auto !important",
    alignSelf: "center !important",
    minWidth: "fit-content !important",
  },
  customFlex: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  closeButton: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.default.main,
    padding: "0px",
  },
  customDialogHeader: {
    maxHeight: "38%",
    height: "38%",
    color: "#000",
    backgroundSize: "cover",
    backgroundImage: `url(${cardHeaderBg})`,
    background: "round",
  },
  // for side box added by KP
  detailsBox: {
    background: "#FBC112",
    height: "100%",
    padding: "120px 70px",
    [theme.breakpoints.down("md")]: {
      padding: "50px 30px",
    },
    [theme.breakpoints.down("sm")]: {
      padding: "32px 16px",
    },
  },
  listItem: {
    display: "flex",
    alignItems: "center",
    marginTop: "20px",
    marginBottom: "20px",
    [theme.breakpoints.between("xs", "lg")]: {
      marginTop: "10px",
      marginBottom: "10px",
    },
    background: "#FB9012",
    padding: "10px",
    borderRadius: "8px",
    fontSize: "18px",
  },
  itemCircle: {
    height: "64px",
    width: "64px",
    borderRadius: "50%",
    background: "#ffffff",
    padding: 0,
    "& img": {
      height: "64px",
      width: "64px",
    },
  },
  sideTitle: {
    fontSize: "24px",
    [theme.breakpoints.between("xs", "lg")]: {
      fontSize: "18px",
    },
    lineHeight: "185%",
    color: "#000000",
    marginBottom: "10px",
    textAlign: "left",
  },
  otpInput: {
    "&>input": {
      fontSize: "1rem !important",
      width: "2rem !important",
      height: "2rem !important",
      backgroundColor: "#F4F4F5",
    },
    "&:last-child": {
      marginRight: "0 !important",
    },
    width: "3rem",
    height: "3rem",
    marginRight: "1rem",
    fontSize: "1rem",
    textAlign: "center",
    border: "1px solid #B7B6B8",
    borderRadius: "5px",
    backgroundColor: "#F4F4F5",
    display: "flex !important",
    alignItems: "center !important",
    justifyContent: "center !important",
  },
});

const DialogTitle = withStyles(styles)((props) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle
      disableTypography
      className={classes.customDialogHeader}
      style={{ padding: "3%" }}
      {...other}
    >
      <Typography variant="h5" align="center">
        {children}
      </Typography>
      {onClose ? (
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={onClose}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const DialogContent = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
    textAlign: "center",
  },
}))(MuiDialogContent);

const DialogActions = withStyles((theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
    backgroundColor: "#fff",
    justifyContent: "flex-end !important",
  },
}))(MuiDialogActions);

class ResetPassword extends Component {
  constructor(props) {
    super(props);
    this.state = {
      email: props?.emailForPasswordReset
        ? props?.emailForPasswordReset.toLowerCase()
        : "",
      newPassword: "",
      confirmPassword: "",
      showNewPassword: false,
      showConfirmPassword: false,
      isValidNewPassword: true,
      isValidConfirmPassword: true,
      passwordMismatch: false,
      isValidVerificationCode: true,
      isCompleteCode: true,
      verificationCode: "",
      open: false,
      step: props?.resetPasswordStep ? props?.resetPasswordStep : 1,
      errors: {
        cognito: null,
      },
    };
  }

  componentDidMount() {
    document.body.scrollTop = 0; // For Safari
    document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE and Opera
    const urlSearchParams = new URLSearchParams(window.location.search);
    // const params = Object.fromEntries(urlSearchParams.entries());
    const params = Object.fromEntries(urlSearchParams);
    if (
      params.email !== null &&
      params.email !== undefined &&
      params.email !== ""
    ) {
      const { dispatch } = this.props;
      this.setState({ email: params.email?.toLowerCase() });
      dispatch(saveEmailForPasswordReset(params.email?.toLowerCase()));
      this.forgotPassword(params.email?.toLowerCase());
    }
    const { dispatch } = this.props;
    const { pageTitle } = this.props.pageTitle;
    dispatch(setTitle(`Forgot Password - Set Password`));
    seo({
      title: `${pageTitle}`,
    });
  }

  componentDidUpdate() {
    const { pageTitle } = this.props.pageTitle;
    seo({
      title: `${pageTitle}`,
    });
  }

  handleClickOpen = () => {
    this.setState({ open: true });
  };

  handleClickShowNewPassword = () => {
    this.setState({ showNewPassword: !this.state.showNewPassword });
  };

  handleClickShowConfirmPassword = () => {
    this.setState({ showConfirmPassword: !this.state.showConfirmPassword });
  };

  handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  handleClose = () => {
    this.setState({ open: false });
  };

  handleChange = (prop) => (event) => {
    switch (prop) {
      case "verificationCode":
        this.setState({
          isValidVerificationCode: true,
          isCompleteCode: validator.matches(
            event,
            /^(\s*\d{6}\s*)(,\s*\d{6}\s*)*,?\s*$/
          ),
        });
        break;
      case "newPassword":
        this.setState(
          {
            isValidNewPassword: validator.matches(
              event.target.value,
              /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[^a-zA-Z0-9]).{6,15}$/,
              "i"
            ),
          },
          function () {
            this.state.newPassword !== this.state.confirmPassword
              ? this.setState({ passwordMismatch: true })
              : this.setState({ passwordMismatch: false });
          }
        );
        break;
      case "confirmPassword":
        this.setState(
          {
            isValidConfirmPassword: validator.matches(
              event.target.value,
              /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[^a-zA-Z0-9]).{6,15}$/,
              "i"
            ),
          },
          function () {
            this.state.newPassword !== this.state.confirmPassword
              ? this.setState({ passwordMismatch: true })
              : this.setState({ passwordMismatch: false });
          }
        );
        break;
      default:
        return;
    }

    if (prop === "verificationCode") this.setState({ [prop]: event });
    else this.setState({ [prop]: event.target.value });
  };

  goToSignIn = () => {
    this.setState({ open: false });
    history.push("/signin");
  };

  goToNextStep = () => {
    if (this.state.verificationCode.length < 6) {
      this.setState({ isCompleteCode: false });
      toast.dark("6 digits required");
      return;
    }
    const { dispatch } = this.props;
    dispatch(setStep(2));
    this.setState({ step: 2 });
  };

  goToPreviousStep = () => {
    const { dispatch } = this.props;
    dispatch(setStep(1));
    this.setState({ step: 1 });
  };

  resetPassword = async (event) => {
    event.preventDefault();
    event.stopPropagation();
    const { dispatch } = this.props;
    dispatch(loading(true));
    const {
      email,
      verificationCode,
      newPassword,
      confirmPassword,
      passwordMismatch,
    } = this.state;
    if (
      verificationCode &&
      newPassword &&
      confirmPassword &&
      !passwordMismatch
    ) {
      try {
        const ForgotPasswordSubmitResponse = await Auth.forgotPasswordSubmit(
          email,
          verificationCode,
          confirmPassword
        );
        console.log(ForgotPasswordSubmitResponse);
        this.handleClickOpen();
        dispatch(loading(false));
      } catch (error) {
        let err = null;
        !error.message ? (err = { message: error }) : (err = error);
        console.log("error resetting password:", err);
        this.setState({
          errors: {
            ...this.state.errors,
            cognito: error,
          },
          step: 1,
        });
        if (
          error?.message &&
          error?.message ===
            "Invalid verification code provided, please try again." &&
          error.name === "CodeMismatchException"
        ) {
          this.setState({ isValidVerificationCode: false });
        }
        dispatch(setStep(1));
        dispatch(loading(false));
        toast.dark(error.message);
      }
    } else {
      dispatch(loading(false));
      toast.dark("Please fill up all the details with correct information");
    }
  };

  forgotPassword = async (email) => {
    const { dispatch } = this.props;
    dispatch(loading(true));
    if (email) {
      try {
        const forgotPasswordResponse = await Auth.forgotPassword(
          email?.toLowerCase()
        );
        console.log(forgotPasswordResponse);
        toast.dark(
          "A verification code has been sent to your email address. Please verify your email to reset password."
        );
        history.push("/reset-password");
        dispatch(loading(false));
      } catch (error) {
        let err = null;
        !error.message ? (err = { message: error }) : (err = error);
        console.log("error in password reset:", err);
        this.setState({
          errors: {
            ...this.state.errors,
            cognito: error,
          },
        });
        dispatch(loading(false));
        toast.dark(error.message);
      }
    } else {
      dispatch(loading(false));
      toast.dark("Please enter Corporate Email Address");
    }
  };

  resendForgotPasswordOtp = async (event) => {
    event.preventDefault();
    event.stopPropagation();
    if (this.state.email === "") {
      history.push("/forgot-password");
      return;
    }
    const { dispatch } = this.props;
    dispatch(loading(true));
    try {
      const forgotPasswordResponse = await Auth.forgotPassword(
        this.state.email?.toLowerCase()
      );
      console.log(forgotPasswordResponse);
      dispatch(loading(false));
      toast.dark("Please check your registered email for verification code");
    } catch (error) {
      let err = null;
      !error.message ? (err = { message: error }) : (err = error);
      console.log("error in sending verification code:", err);
      this.setState({
        errors: {
          ...this.state.errors,
          cognito: error,
        },
      });
      dispatch(loading(false));
      toast.dark(error.message);
    }
  };

  render() {
    const { classes } = this.props;
    return (
      <Fragment>
        <Grid container component="main" className={classes.root}>
          <PreLoginContent />
          <Grid
            item
            xs={12}
            sm={12}
            md={6}
            lg={5}
            xl={5}
            className={classes.spacing}
            elevation={6}
            square
          >
            <Grid
              component={Paper}
              elevation={0}
              className={classes.newCustomPaper}
            >
              <div className={classes.paper}>
                <Typography
                  align="left"
                  className={classNames(classes.xxLargeFont, classes.boldFont)}
                >
                  {this.state.step === 1
                    ? `Enter Verification Code`
                    : `Set New Password`}
                </Typography>
              </div>
              {this.state.step === 1 ? (
                <div className={classes.customPaper}>
                  <Typography component="span" align="left" color="primary">
                    Enter the password reset code we just sent to you via email
                    and then create a new password. Please check your spam
                    folder if the reset code doesn't appear in your inbox
                  </Typography>
                </div>
              ) : (
                <div className={classes.customPaper}>
                  <Typography component="span" align="left" color="primary">
                    Please create a new password. Password must contain a
                    minimum of 8 and a maximum of 15 characters, at least 1
                    lowercase letter, 1 uppercase letter, 1 numeric digit and 1
                    special character.
                  </Typography>
                </div>
              )}
              <form className={classes.customForm} noValidate>
                <Grid container item xs={12} sm={12} md={12} lg={12} xl={12}>
                  {this.state.step === 1 ? (
                    <>
                      <Grid
                        item
                        xs={12}
                        sm={12}
                        md={12}
                        lg={12}
                        xl={12}
                        style={{ textAlign: "left", paddingBottom: "8px" }}
                      >
                        <FormLabel required>
                          <b>Enter code</b>
                        </FormLabel>
                      </Grid>
                      <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                        <OtpInput
                          isInputNum
                          isNumberInput
                          shouldAutoFocus={true}
                          id="verificationCode"
                          name="verificationCode"
                          value={this.state.verificationCode}
                          onChange={this.handleChange("verificationCode")}
                          numInputs={6}
                          className={classes.otpInput}
                          inputClassName="otpInput"
                          hasErrored={
                            this.state.isValidVerificationCode ? false : true
                          }
                          autoFocus
                        />
                        {!this.state.isValidVerificationCode ? (
                          <Typography
                            align="left"
                            style={{
                              fontSize: "0.75rem",
                              color: "rgb(220, 0, 78)",
                              margin: "8px",
                              marginLeft: "0px !important",
                            }}
                          >
                            The code entered by you is incorrrect. Please try
                            again.
                          </Typography>
                        ) : !this.state.isCompleteCode ? (
                          <Typography
                            align="left"
                            style={{
                              fontSize: "0.75rem",
                              color: "rgb(220, 0, 78)",
                              margin: "8px",
                              marginLeft: "0px !important",
                            }}
                          >
                            6 digits required
                          </Typography>
                        ) : null}
                      </Grid>
                      <Grid
                        className={classes.mediumTopMargin}
                        item
                        xs={12}
                        sm={12}
                        md={12}
                        lg={12}
                        xl={12}
                      >
                        <Button
                          size="large"
                          className={classes.nextBtn}
                          disableElevation
                          variant="contained"
                          color="primary"
                          onClick={(event) => {
                            event.preventDefault();
                            event.stopPropagation();
                            this.goToNextStep(event);
                          }}
                        >
                          Next
                        </Button>
                      </Grid>
                      <Grid
                        className={classes.smallTopMargin}
                        item
                        xs={12}
                        sm={12}
                        md={12}
                        lg={12}
                        xl={12}
                      >
                        <Typography
                          component="div"
                          className={classes.verySmallTopMargin}
                          align="left"
                        >
                          <Link
                            className={classes.resendLink}
                            onClick={(event) => {
                              this.resendForgotPasswordOtp(event);
                            }}
                          >
                            Resend code
                          </Link>
                        </Typography>
                      </Grid>
                    </>
                  ) : (
                    <>
                      <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                        <TextField
                          fullWidth
                          label="New Password"
                          placeholder="Enter new password"
                          // variant='outlined'
                          margin="normal"
                          required
                          name="newPassword"
                          type={
                            this.state.showNewPassword ? "text" : "password"
                          }
                          id="newPassword"
                          value={this.state.password}
                          // autoComplete="current-password"
                          error={this.state.isValidNewPassword ? false : true}
                          helperText={
                            !this.state.isValidNewPassword
                              ? "Password must contain a minimum of 8 and a maximum of 15 characters, at least 1 lowercase letter, 1 uppercase letter, 1 numeric digit and 1 special character."
                              : ""
                          }
                          onChange={this.handleChange("newPassword")}
                          InputProps={{
                            endAdornment: (
                              <InputAdornment
                                position="end"
                                title="Show/Hide Password"
                              >
                                <IconButton
                                  tabIndex={-1}
                                  aria-label="toggle password visibility"
                                  onClick={this.handleClickShowNewPassword}
                                  onMouseDown={this.handleMouseDownPassword}
                                  edge="end"
                                >
                                  {this.state.showNewPassword ? (
                                    <VisibilityOff />
                                  ) : (
                                    <Visibility />
                                  )}
                                </IconButton>
                              </InputAdornment>
                            ),
                          }}
                        />
                      </Grid>
                      <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                        <TextField
                          fullWidth
                          label="Confirm Password"
                          margin="normal"
                          // variant='outlined'
                          required
                          name="confirmPassword"
                          type={
                            this.state.showConfirmPassword ? "text" : "password"
                          }
                          id="confirmPassword"
                          value={this.state.password}
                          placeholder="Retype new password"
                          // autoComplete="current-password"
                          error={
                            this.state.isValidConfirmPassword &&
                            !this.state.passwordMismatch
                              ? false
                              : true
                          }
                          helperText={
                            !this.state.isValidConfirmPassword
                              ? "Password must contain minimum 8 and maximum 15 characters, at least 1 lowercase letter, 1 uppercase letter, 1 numeric digit and 1 special character."
                              : this.state.passwordMismatch
                              ? "Passwords didn't match"
                              : ""
                          }
                          onChange={this.handleChange("confirmPassword")}
                          InputProps={{
                            endAdornment: (
                              <InputAdornment
                                position="end"
                                title="Show/Hide Password"
                              >
                                <IconButton
                                  tabIndex={-1}
                                  aria-label="toggle password visibility"
                                  onClick={this.handleClickShowConfirmPassword}
                                  onMouseDown={this.handleMouseDownPassword}
                                  edge="end"
                                >
                                  {this.state.showConfirmPassword ? (
                                    <VisibilityOff />
                                  ) : (
                                    <Visibility />
                                  )}
                                </IconButton>
                              </InputAdornment>
                            ),
                          }}
                        />
                      </Grid>
                      <Grid
                        className={classes.smallTopMargin}
                        item
                        xs={12}
                        sm={12}
                        md={12}
                        lg={12}
                        xl={12}
                      >
                        <Button
                          size="large"
                          type="submit"
                          className={classes.signInBtn}
                          disableElevation
                          variant="contained"
                          color="primary"
                          onClick={(event) => {
                            this.resetPassword(event);
                          }}
                        >
                          Set Password
                        </Button>
                      </Grid>
                      <Grid
                        className={classes.smallTopMargin}
                        item
                        xs={12}
                        sm={12}
                        md={12}
                        lg={12}
                        xl={12}
                      >
                        <Typography
                          component="div"
                          className={classes.verySmallTopMargin}
                          align="left"
                        >
                          <Link
                            className={classes.resendLink}
                            onClick={(event) => {
                              this.goToPreviousStep(event);
                            }}
                          >
                            Back
                          </Link>
                        </Typography>
                      </Grid>
                    </>
                  )}
                </Grid>
              </form>
            </Grid>
          </Grid>
          <Footer />
        </Grid>
        <Dialog
          disableBackdropClick
          disableEscapeKeyDown
          aria-labelledby="customized-dialog-title"
          open={this.state.open}
          className={classes.customDialog}
        >
          <DialogTitle onClose={this.handleClose}>
            <div className={classes.dialogPaper}>
              <Typography component="span" className={classes.xxLargeFont}>
                Verification Successful!
              </Typography>
            </div>
          </DialogTitle>
          <DialogContent>
            <div className={classes.dialogPaper}>
              <Typography component="span" color="primary">
                Your password has been successfully reset.
              </Typography>
            </div>
          </DialogContent>
          <DialogActions>
            <Button
              variant="contained"
              color="primary"
              className={classes.submit}
              disableElevation
              size="large"
              style={{ background: "#D7D9DB", color: "#000" }}
              onClick={() => {
                this.goToSignIn();
              }}
            >
              Sign In
            </Button>
          </DialogActions>
        </Dialog>
      </Fragment>
    );
  }
}

ResetPassword.propTypes = {
  classes: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => {
  return state;
};

const connectedResetPasswordPage = withRouter(
  connect(mapStateToProps, null, null, {
    pure: false,
  })(withStyles(styles)(ResetPassword))
);

export { connectedResetPasswordPage as ResetPassword };
