import React, { Component } from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import { connect } from "react-redux";
import { authActions, loading } from "../../../actions";
import Typography from "@material-ui/core/Typography";
import { withRouter } from "react-router-dom";
import Link from "@material-ui/core/Link";
import InputAdornment from "@material-ui/core/InputAdornment";
import Visibility from "@material-ui/icons/Visibility";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import IconButton from "@material-ui/core/IconButton";
import { Divider } from "@material-ui/core";
import validator from "validator";
import { Auth } from "aws-amplify";
import { toast } from "react-toastify";
import classNames from "classnames";
import { history, seo } from "../../../helpers";
import Footer from "../../core/footer";
import PreLoginContent from "../../core/preLoginContent";
import { setTitle } from "../../../actions";
import secureLocalStorage from "react-secure-storage";

const styles = (theme) => ({
  root: {
    height: "calc(100vh - 90px)", //'100vh',
    marginTop: "90px",
  },
  boldFont: {
    fontWeight: "bold",
  },
  customLogoSpacing: {
    textAlign: "start",
    marginBottom: "40px",
  },
  paper: {
    margin: theme.spacing(2, 10),

    [theme.breakpoints.down("md")]: {
      margin: theme.spacing(3, 5),
    },
    [theme.breakpoints.down("sm")]: {
      margin: theme.spacing(2, 2),
    },
    display: "flex",
    alignItems: "center",
    marginTop: "8%",
  },
  customPaper: {
    borderRadius: "4px",
    padding: "50px 40px 15px 40px",
  },
  customForm: {
    margin: theme.spacing(2, 0),
    [theme.breakpoints.down("md")]: {
      margin: theme.spacing(2, 0),
    },
    [theme.breakpoints.down("sm")]: {
      margin: theme.spacing(2, 0),
    },
  },
  forgotLink: {
    cursor: "pointer",
    "&:hover": {
      textDecoration: "underline",
    },
    color: "#048475 !important",
  },
  smallTopMargin: {
    marginTop: "2%",
  },
  customFlex: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  xxLargeFont: {
    fontSize: "xx-large",
  },
  bottomSmall: {
    marginBottom: "9%",
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(5, 0, 2),
    borderRadius: "4px !important",
  },
  alignRight: {
    textAlign: "right",
  },
  alignLeft: {
    textAlign: "left",
  },
  margin: {
    marginTop: theme.spacing(2),
  },
  withoutLabel: {
    marginTop: theme.spacing(3),
  },
  customSpacing: {
    padding: "3% 3% 1% 3%",
    [theme.breakpoints.down("xs")]: {
      padding: "5% 5% 25% 5%",
    },
    [theme.breakpoints.down("sm")]: {
      padding: "5% 5% 90px 5%",
    },
    backgroundColor: "#F8F6EF", //'#E5E5E5'
  },
  spacing: {
    padding: "3% 3% 1% 3%",
    [theme.breakpoints.down("xs")]: {
      padding: "5% 5% 25% 5%",
    },
    [theme.breakpoints.down("sm")]: {
      padding: "5% 5% 90px 5%",
    },
    backgroundColor: "#F8F6EF", //'#E5E5E5'
  },
  textField: {
    width: "100%",
  },
  disabledSignIn: {
    cursor: "not-allowed",
    borderRadius: "4px !important",
  },
  toolbar: {
    padding: "1% !important",
    left: "1.5% !important",
  },
  responsive: {
    maxWidth: "100% !important",
    height: "auto !important",
    width: "auto !important",
    alignSelf: "center !important",
    minWidth: "fit-content !important",
  },
  customDivider: {
    borderBottom: "1px solid " + theme.palette.secondary.main,
    width: "inherit",
    marginTop: "3%",
    flexShrink: 1,
  },
  customLink: {
    color: "#048475 !important",
    cursor: "pointer",
    "&:hover": {
      textDecoration: "underline",
    },
  },
  alignSelfCenter: {
    alignSelf: "center",
  },

  // for side box added by KP
  detailsBox: {
    background: "#FBC112",
    height: "100%",
    padding: "120px 70px",
    [theme.breakpoints.down("md")]: {
      padding: "50px 30px",
    },
    [theme.breakpoints.down("sm")]: {
      padding: "32px 16px",
    },
  },
  listItem: {
    display: "flex",
    alignItems: "center",
    marginTop: "20px",
    [theme.breakpoints.between("xs", "lg")]: {
      marginTop: "10px",
      marginBottom: "10px",
    },
    padding: "10px 0px 10px 0px",
    borderRadius: "8px",
    fontSize: "18px",
  },
  itemCircle: {
    width: "auto",
    height: "57px",
    padding: 0,
  },
  sideTitle: {
    fontSize: "24px",
    [theme.breakpoints.between("xs", "lg")]: {
      fontSize: "18px",
    },
    lineHeight: "185%",
    color: "#000000",
    marginBottom: "10px",
    textAlign: "left",
  },
  fsSmall: {
    fontSize: "0.8rem",
  },
  ssBlack: { color: "#14131F" },
  font18p: { fontSize: "18px" },
  signInBtn: {
    float: "left",
    width: "14em",
    backgroundColor: "#FBC112",
    color: "#000000",
  },
});

class SignIn extends Component {
  constructor(props) {
    super(props);
    this.state = {
      email: "",
      password: "",
      showPassword: false,
      isValidEmail: true,
      errors: {
        cognito: null,
      },
    };
  }

  componentDidMount() {
    document.body.scrollTop = 0; // For Safari
    document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE and Opera
    this.deleteAllCookies();
    const { dispatch } = this.props;
    dispatch(setTitle(`Sign In`));
    let params = new URLSearchParams(window.location.search);
    let token = params.get("token");
    let type = params.get("login_type");
    if (token && type === "one_time") {
      dispatch(authActions.MagicLinkSignIn(token));
    }
  }

  componentDidUpdate() {
    const { pageTitle } = this.props.pageTitle;
    seo({
      title: `${pageTitle}`,
    });
  }

  handleClickShowPassword = () => {
    this.setState({ showPassword: !this.state.showPassword });
  };

  handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  handleChange = (prop) => (event) => {
    switch (prop) {
      case "email":
        this.setState({ isValidEmail: validator.isEmail(event.target.value) });
        break;
      default:
        break;
    }
    this.setState({ [prop]: event.target.value });
  };

  redirectToSignUp = () => {
    history.push("/signup");
  };

  deleteAllCookies = () => {
    caches
      .keys()
      .then((keyList) => Promise.all(keyList.map((key) => caches.delete(key))));
    document.cookie.split(";").forEach(function (c) {
      document.cookie =
        c.trim().split("=")[0] + "=;expires=Thu, 01 Jan 1970 00:00:00 UTC;";
    });
  };

  signIn = async (event) => {
    const { dispatch } = this.props;
    const { deepLink } = this.props.authentication;
    dispatch(loading(true));
    event.preventDefault();
    event.stopPropagation();
    const { email, password } = this.state;
    if (email && password) {
      try {
        const user = await Auth.signIn(email?.toLowerCase(), password);
        /* -------------------------DO NOT DELETE------------------------- */
        // if (user.challengeName === "NEW_PASSWORD_REQUIRED") {
        // const requiredAttributes = {
        // email: "vasudev.krishna@codecraft.co.in",
        // 'custom:first_name': 'Linda',
        // 'custom:last_name': 'Holt',
        // 'custom:current_title': 'Human Resources Generalist',
        // 'custom:phone': '8053453535'
        //};
        //Auth.completeNewPassword(
        // user,
        // "Vkrishna@123",
        // requiredAttributes
        //).then((user) => {
        // console.log("----", user);
        // });
        // }
        secureLocalStorage.setItem("auth", true);
        await secureLocalStorage.setItem("user", JSON.stringify(user));
        secureLocalStorage.setItem(
          "token",
          JSON.stringify(user.signInUserSession.accessToken.jwtToken)
        );
        secureLocalStorage.setItem(
          "idToken",
          JSON.stringify(user.signInUserSession.idToken.jwtToken)
        );
        secureLocalStorage.setItem("userId", JSON.stringify(user.username));
        secureLocalStorage.setItem(
          "userSub",
          JSON.stringify(user.attributes.sub)
        );
        secureLocalStorage.setItem(
          "userDetails",
          JSON.stringify(user.attributes)
        );
        if (
          user.attributes.email?.toLowerCase() !== email?.toLowerCase() ||
          !user.attributes.email_verified
        ) {
          let result = await Auth.updateUserAttributes(user, {
            email: email?.toLowerCase(), //'rnere@succeedsmart.com'
          });
          console.log("Attribute update status: ", result); // SUCCESS
          history.push("/verify-email");
          dispatch(loading(false));
          user.attributes.email = email?.toLowerCase();
          secureLocalStorage.setItem(
            "userDetails",
            JSON.stringify(user.attributes)
          );
        } else {
          // await Auth.updateUserAttributes(user, {
          //   'email': 'rnere@succeedsmart.com'
          // });
          dispatch(authActions.signin(email?.toLowerCase(), deepLink));
          dispatch(loading(false));
        }
      } catch (error) {
        let err = null;
        !error.message ? (err = { message: error }) : (err = error);
        console.log("error signing in:", err);
        this.setState({
          errors: {
            ...this.state.errors,
            cognito: error,
          },
        });
        dispatch(loading(false));
        toast.dark(error.message);
      }
    } else {
      dispatch(loading(false));
      toast.dark("Please fill up all the details");
    }
  };

  goToTechSupport = () => {
    window.open(
      " https://support.succeedsmart.com/hc/en-us/requests/new",
      "_blank"
    );
  };

  render() {
    const { classes } = this.props;
    return (
      <Grid container className={classes.root}>
        <PreLoginContent />
        <Grid
          item
          xs={12}
          sm={12}
          md={6}
          lg={5}
          xl={5}
          className={classes.spacing}
        >
          <Grid component={Paper} elevation={0} className={classes.customPaper}>
            <Typography align="left" variant="h4" className={classes.boldFont}>
              Sign In
            </Typography>
            <form className={classes.customForm} noValidate>
              <Grid container spacing={3}>
                <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                  <TextField
                    fullWidth
                    label="Corporate Email Address"
                    autoFocus
                    autoComplete="email"
                    margin="normal"
                    required
                    id="email"
                    name="email"
                    value={this.state.email?.toLowerCase()}
                    onChange={this.handleChange("email")}
                    error={this.state.isValidEmail ? false : true}
                    helperText={!this.state.isValidEmail ? "Invalid Email" : ""}
                  />
                </Grid>
                <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                  <TextField
                    fullWidth
                    label="Password"
                    margin="normal"
                    required
                    name="password"
                    type={this.state.showPassword ? "text" : "password"}
                    id="password"
                    value={this.state.password}
                    autoComplete="current-password"
                    onChange={this.handleChange("password")}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment
                          position="end"
                          title="Show/Hide Password"
                        >
                          <IconButton
                            tabIndex={-1}
                            aria-label="toggle password visibility"
                            onClick={this.handleClickShowPassword}
                            onMouseDown={this.handleMouseDownPassword}
                            edge="end"
                          >
                            {this.state.showPassword ? (
                              <VisibilityOff />
                            ) : (
                              <Visibility />
                            )}
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                  />
                </Grid>
                <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                  <Button
                    className={classes.signInBtn}
                    size="large"
                    type="submit"
                    disableElevation
                    variant="contained"
                    color="primary"
                    disabled={!this.state.isValidEmail}
                    onClick={(event) => {
                      this.signIn(event);
                    }}
                  >
                    Sign In
                  </Button>
                </Grid>
                <Grid
                  container
                  alignItems="center"
                  className={classNames(classes.alignSelfCenter)}
                  item
                  xs={12}
                  sm={12}
                  md={12}
                  lg={12}
                  xl={12}
                >
                  <Typography className={classes.toolbarContent} align="left">
                    <Link
                      tabIndex={-1}
                      href="/forgot-password"
                      className={classNames(
                        classes.forgotLink,
                        classes.fsSmall1
                      )}
                    >
                      Forgot password?
                    </Link>
                  </Typography>
                  {/* <Divider orientation="vertical" style={{ background: '#048475', margin: '4px' }} flexItem />
                            <Typography className={classes.toolbarContent} align='left'>
                                <span style={{display: 'inline-block'}} className={classNames(classes.customLink, classes.fsSmall1)} onClick={event => {this.redirectToSignUp(event)}}>Create corporate account</span>
                            </Typography> */}
                </Grid>
                <Divider className={classes.customDivider} />
                <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                  <Typography
                    className={classNames(
                      classes.customLink,
                      classes.smallTopMargin
                    )}
                    onClick={() => {
                      this.goToTechSupport();
                    }}
                  >
                    {"Have questions or need support?"}
                  </Typography>
                </Grid>
              </Grid>
            </form>
          </Grid>
        </Grid>
        <Footer />
      </Grid>
    );
  }
}

SignIn.propTypes = {
  classes: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => {
  return state;
};

const connectedSignInPage = withRouter(
  connect(mapStateToProps, null, null, {
    pure: false,
  })(withStyles(styles)(SignIn))
);

export { connectedSignInPage as SignIn };
