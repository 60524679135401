import React, { Component } from "react";
import { ToastContainer, Flip } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

export default class Toaster extends Component {
  render() {
    return (
      <ToastContainer
        position="bottom-right"
        autoClose={5000}
        newestOnTop
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        transition={Flip}
        hideProgressBar={false}
      />
      // hideProgressBar autoClose={5000}
    );
  }
}
