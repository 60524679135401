import React, { useEffect, useState } from "react";
import {
  Button,
  Typography,
  IconButton,
  Dialog,
  DialogTitle,
  DialogActions,
  DialogContent,
  DialogContentText,
  TextField,
  Grid,
} from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import { makeStyles } from "@material-ui/core/styles";
import classNames from "classnames";
import validator from "validator";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import { EditorState, convertToRaw } from "draft-js";
import draftToHtml from "draftjs-to-html";
import cardHeaderBg from "../../assets/lite_profile_bg.png";
import { MESSAGE_FIELD_IS_REQUIRED } from "../../constants/constants";
import CustomEditer from "../core/editerinput";

const useStyles = makeStyles((theme) => ({
  closeButton: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.default.main,
    padding: "0px",
  },
  textAlignCenter: {
    textAlign: "center",
  },
  scheduledialogActionsButton: {
    width: "8rem",
    marginTop: "8px",
    marginBottom: "18px",
  },
  scheduledialogActions: {
    justifyContent: "center !important",
  },
  smallTopMargin: {
    marginTop: "2%",
  },
  smallBottomMargin: {
    marginBottom: "2%",
  },
  customCardHeader: {
    maxHeight: "38%",
    height: "38%",
    color: "#000",
    backgroundSize: "cover",
    backgroundImage: `url(${cardHeaderBg})`,
    background: "round",
  },
  smallPadding: {
    padding: "5px",
  },
}));

function SchedulingPopup(props) {
  const classes = useStyles();
  const {
    openScheduleInterview,
    scheduleInterviewType,
    closeScheduleInterview,
    handlePreview,
  } = props;

  const [value, setValue] = useState({
    linkToCandidate: "",
    emailTocandidate: "",
    firstname: "",
    emailTocoordinator: "",
    lastname: "",
    editorNotesState: EditorState.createEmpty(),
  });

  const [editorNotesStateHTML, setEditorNotesStateHTML] = useState(null);
  const schedulingLinkValues = useSelector(
    (state) => state.jobs.schedulingLinkValues
  );
  const [validate, setValidate] = useState({
    isValidLinkRequired: false,
    isValidLinkUrl: true,
    isValidFullNameRequired: false,
    isValidFullName: true,
    isValidLinkEmail: true,
    isValidLastNameRequired: false,
    isValidLastName: true,
  });

  const handleScheduleInterview = (event, type) => {
    let input = "";
    switch (scheduleInterviewType) {
      case "link to candidate":
        input = event.target.value;
        setValue({ ...value, linkToCandidate: input });
        setValidate({
          ...validate,
          isValidLinkRequired: validator.isEmpty(input),
          isValidLinkUrl: validator.matches(
            input,
            /^((ftp|http|https):\/\/)?(www.)?(?!.*(ftp|http|https|www.))[a-zA-Z0-9_-]+(\.[a-zA-Z]+)+((\/)[\w#-=?!@">`~]+)*(\/\w+\?[a-zA-Z0-9_]+=\w+(&[a-zA-Z0-9_]+=\w+))?$/gs
          ),
        });
        break;
      case "email to candidate":
        input = event.target.value;
        setValue({ ...value, emailTocandidate: input });
        setValidate({
          ...validate,
          isValidLinkRequired: validator.isEmpty(input),
          isValidLinkUrl: validator.matches(
            input,
            /^((ftp|http|https):\/\/)?(www.)?(?!.*(ftp|http|https|www.))[a-zA-Z0-9_-]+(\.[a-zA-Z]+)+((\/)[\w#-=?!@">`~]+)*(\/\w+\?[a-zA-Z0-9_]+=\w+(&[a-zA-Z0-9_]+=\w+))?$/gs
          ),
        });
        break;
      case "email to coordinator":
        if (type === "coordinator") {
          input = event.target.value;
          setValue({ ...value, emailTocoordinator: input });
          setValidate({
            ...validate,
            isValidLinkEmail: validator.isEmail(input),
            isValidLinkRequired: validator.isEmpty(input),
          });
          if (
            input.includes("@yahoo.com") ||
            input.includes("@gmail.com") ||
            input.includes("@live.com") ||
            input.includes("@rediffmail.com") ||
            input.includes("@hotmail.com") ||
            input.includes("@ymail.com") ||
            input.includes("@outlook.com") ||
            input.includes("@yopmail.com")
          ) {
            setValidate({ ...validate, isValidLinkEmail: true });
          }
        } else if (type === "lastname") {
          input = event.target.value;
          setValue({ ...value, lastname: input });
          setValidate({
            ...validate,
            isValidLastNameRequired: validator.isEmpty(input),
            isValidLastName: input
              ? validator.matches(input, /^.{2,50}$/)
              : true,
          });
        } else {
          input = event.target.value;
          setValue({ ...value, firstname: input });
          setValidate({
            ...validate,
            isValidFullNameRequired: validator.isEmpty(input),
            isValidFullName: input
              ? validator.matches(input, /^.{2,50}$/)
              : true,
          });
        }
        break;
      default:
        break;
    }
  };

  const handleSendSchedulingLink = () => {
    let input = "";
    let inputFullName = "";
    let inputLastName = "";
    switch (scheduleInterviewType) {
      case "link to candidate":
        input = value.linkToCandidate;
        setValue({ ...value, linkToCandidate: input });
        setValidate({
          ...validate,
          isValidLinkRequired: validator.isEmpty(input),
          isValidLinkUrl: validator.matches(
            input,
            /^((ftp|http|https):\/\/)?(www.)?(?!.*(ftp|http|https|www.))[a-zA-Z0-9_-]+(\.[a-zA-Z]+)+((\/)[\w#-=?!@">`~]+)*(\/\w+\?[a-zA-Z0-9_]+=\w+(&[a-zA-Z0-9_]+=\w+))?$/gs
          ),
        });
        break;
      case "email to candidate":
        input = value.emailTocandidate;
        setValue({ ...value, emailTocandidate: input });
        setValidate({
          ...validate,
          isValidLinkRequired: validator.isEmpty(input),
          isValidLinkUrl: validator.matches(
            input,
            /^((ftp|http|https):\/\/)?(www.)?(?!.*(ftp|http|https|www.))[a-zA-Z0-9_-]+(\.[a-zA-Z]+)+((\/)[\w#-=?!@">`~]+)*(\/\w+\?[a-zA-Z0-9_]+=\w+(&[a-zA-Z0-9_]+=\w+))?$/gs
          ),
        });
        break;
      case "email to coordinator":
        input = value.emailTocoordinator;
        inputFullName = value.firstname;
        inputLastName = value.lastname;
        setValue({ ...value, emailTocoordinator: input });
        setValidate({
          ...validate,
          isValidLinkEmail: validator.isEmail(input),
          isValidLinkRequired: validator.isEmpty(input),
          isValidFullNameRequired: validator.isEmpty(inputFullName),
          isValidLastNameRequired: validator.isEmpty(inputLastName),
        });
        if (
          input.includes("@yahoo.com") ||
          input.includes("@gmail.com") ||
          input.includes("@live.com") ||
          input.includes("@rediffmail.com") ||
          input.includes("@hotmail.com") ||
          input.includes("@ymail.com") ||
          input.includes("@outlook.com") ||
          input.includes("@yopmail.com")
        ) {
          setValidate({ ...validate, isValidLinkEmail: true });
        }
        break;
      default:
        break;
    }

    const isEmpty = convertToRaw(
      value.editorNotesState.getCurrentContent()
    ).blocks.every((b) => b.text.trim() === "");

    if (
      (isEmpty ||
        value.editorNotesState
          .getCurrentContent()
          .getPlainText("\u0001")
          ?.trim()?.length === 0) &&
      (value.emailTocoordinator.length === 0 ||
        validate.isValidLinkRequired ||
        !validate.isValidLinkEmail ||
        value.firstname.length < 2 ||
        value?.firstname.length > 50 ||
        value.lastname < 2 ||
        value.lastname > 50)
    ) {
      toast.dark("Please fill up all the details");
    } else {
      handlePreview(value, editorNotesStateHTML);
    }
  };

  const handleInputChange = () => {
    setEditorNotesStateHTML(
      draftToHtml(convertToRaw(value?.editorNotesState.getCurrentContent()))
    );
  };

  const onEditerNotesChange = (EditerValue) => {
    setValue({ ...value, editorNotesState: EditerValue });
  };

  useEffect(() => {
    if (Object.keys(schedulingLinkValues)?.length !== 0) {
      setValue(schedulingLinkValues);
      setEditorNotesStateHTML(
        draftToHtml(
          convertToRaw(
            schedulingLinkValues?.editorNotesState?.getCurrentContent()
          )
        )
          ? draftToHtml(
              convertToRaw(
                schedulingLinkValues?.editorNotesState?.getCurrentContent()
              )
            )
          : ""
      );
    }
  }, [schedulingLinkValues]);

  return (
    <Dialog
      open={openScheduleInterview}
      fullWidth
      maxWidth="sm"
      aria-labelledby="form-dialog-title"
    >
      <DialogTitle
        id="form-dialog-title"
        className={classNames(
          classes.textAlignCenter,
          classes.customCardHeader
        )}
      >
        {scheduleInterviewType === "link to candidate"
          ? "Send Scheduling Link to Candidate"
          : scheduleInterviewType === "email to candidate"
          ? "Schedule Interview with Candidate"
          : "It's Time to Schedule a Candidate Interview"}
        <IconButton
          aria-label="close"
          onClick={closeScheduleInterview}
          className={classes.closeButton}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        <DialogContentText
          className={classNames(
            classes.textAlignCenter,
            classes.smallTopMargin,
            classes.smallBottomMargin
          )}
        >
          {scheduleInterviewType === "link to candidate" ||
          scheduleInterviewType === "email to candidate"
            ? ""
            : "Enter coordinator’s contact information below"}
        </DialogContentText>
        {scheduleInterviewType === "link to candidate" ? (
          <TextField
            autoFocus
            margin="dense"
            id="link"
            label="Enter scheduling link"
            type="text"
            value={value.linkToCandidate}
            fullWidth
            required
            onChange={handleScheduleInterview}
            error={validate.isValidLinkRequired || !validate.isValidLinkUrl}
            helperText={
              validate.isValidLinkRequired
                ? MESSAGE_FIELD_IS_REQUIRED
                : !validate.isValidLinkUrl
                ? "Invalid Website Name"
                : ""
            }
          />
        ) : scheduleInterviewType === "email to candidate" ? (
          <div>
            <CustomEditer
              placeholder="type here"
              editerState={value.editorNotesState}
              onEditerChange={onEditerNotesChange}
              handleInputChange={handleInputChange}
              maxLength={1000}
            />
          </div>
        ) : (
          <Grid container>
            <Grid
              item
              sm={6}
              xs={6}
              md={6}
              xl={6}
              lg={6}
              className={classes.smallPadding}
            >
              <TextField
                autoFocus
                margin="dense"
                id="name"
                value={value.firstname}
                label="First Name"
                type="text"
                fullWidth
                required
                onChange={handleScheduleInterview}
                inputProps={{ maxLength: 50 }}
                error={
                  validate.isValidFullNameRequired || !validate.isValidFullName
                }
                helperText={
                  validate.isValidFullNameRequired
                    ? MESSAGE_FIELD_IS_REQUIRED
                    : !validate.isValidFullName
                    ? "min 2 and max 50 characters required"
                    : ""
                }
              />
              <Typography
                className={classNames(
                  classes.floatRight,
                  classes.smallFont,
                  classes.verySmallTopMargin
                )}
                color="secondary"
                align="right"
              >
                {`${value.firstname?.length}/${50}`} characters
              </Typography>
            </Grid>
            <Grid
              item
              sm={6}
              xs={6}
              md={6}
              xl={6}
              lg={6}
              className={classes.smallPadding}
            >
              <TextField
                margin="dense"
                id="name"
                value={value.lastname}
                label="Last Name"
                type="text"
                fullWidth
                required
                onChange={(event) => {
                  handleScheduleInterview(event, "lastname");
                }}
                inputProps={{ maxLength: 50 }}
                error={
                  validate.isValidLastNameRequired || !validate.isValidLastName
                }
                helperText={
                  validate.isValidLastNameRequired
                    ? MESSAGE_FIELD_IS_REQUIRED
                    : !validate.isValidLastName
                    ? "min 2 and max 50 characters required"
                    : ""
                }
              />
              <Typography
                className={classNames(
                  classes.floatRight,
                  classes.smallFont,
                  classes.verySmallTopMargin
                )}
                color="secondary"
                align="right"
              >
                {`${value.lastname?.length}/${50}`} characters
              </Typography>
            </Grid>
            <Grid
              item
              sm={12}
              xs={12}
              md={12}
              xl={12}
              lg={12}
              className={classes.smallPadding}
            >
              <TextField
                margin="dense"
                id="email"
                autoComplete="email"
                value={value.emailTocoordinator}
                label="Email"
                type="email"
                fullWidth
                required
                onChange={(event) => {
                  handleScheduleInterview(event, "coordinator");
                }}
                error={
                  validate.isValidLinkRequired || !validate.isValidLinkEmail
                }
                helperText={
                  validate.isValidLinkRequired
                    ? MESSAGE_FIELD_IS_REQUIRED
                    : !validate.isValidLinkEmail
                    ? "Invalid Email"
                    : ""
                }
              />
            </Grid>
          </Grid>
        )}
      </DialogContent>
      <DialogActions className={classes.scheduledialogActions}>
        <Button
          className={classNames(
            classes.scheduledialogActionsButton,
            classes.textAlignCenter
          )}
          disableElevation
          variant="outlined"
          color="primary"
          onClick={closeScheduleInterview}
        >
          Cancel
        </Button>
        <Button
          className={classNames(
            classes.scheduledialogActionsButton,
            classes.textAlignCenter
          )}
          disableElevation
          variant="contained"
          color="primary"
          onClick={handleSendSchedulingLink}
        >
          Preview
        </Button>
      </DialogActions>
    </Dialog>
  );
}
export default SchedulingPopup;
