import React, { Component } from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import { connect } from "react-redux";
import { history } from "../../../helpers";
import Typography from "@material-ui/core/Typography";
import Dialog from "@material-ui/core/Dialog";
import MuiDialogTitle from "@material-ui/core/DialogTitle";
import MuiDialogContent from "@material-ui/core/DialogContent";
import MuiDialogActions from "@material-ui/core/DialogActions";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import { withRouter } from "react-router-dom";
import Link from "@material-ui/core/Link";
import ProductInfoImage from "../../../assets/product_info.svg";
import InputAdornment from "@material-ui/core/InputAdornment";
import Visibility from "@material-ui/icons/Visibility";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import validator from "validator";
import { Auth } from "aws-amplify";
import { toast } from "react-toastify";
import { authActions, loading } from "../../../actions";
import GetHelp from "../../core/gethelp";
import cardHeaderBg from "../../../assets/lite_profile_bg.png";
import OtpInput from "react-otp-input";
import { seo } from "../../../helpers";
import { setTitle } from "../../../actions";
import secureLocalStorage from "react-secure-storage";
// import '../signup/verifyotp.css';

const styles = (theme) => ({
  root: {
    width: "100%",
    marginTop: "90px",
  },
  image: {
    backgroundImage: `url(${ProductInfoImage})`, //'url(https://source.unsplash.com/random)',
    backgroundRepeat: "no-repeat",
    backgroundColor:
      theme.palette.type === "light"
        ? theme.palette.grey[50]
        : theme.palette.grey[900],
    backgroundSize: "cover",
  },
  paper: {
    margin: theme.spacing(2, 8),
    display: "flex",
    alignItems: "center",
    marginTop: "8%",
    justifyContent: "space-evenly",
  },
  customPaper: {
    margin: theme.spacing(5, 10),
    display: "flex",
    alignItems: "center",
    justifyContent: "space-evenly",
    [theme.breakpoints.down("md")]: {
      margin: theme.spacing(3, 5),
    },
    [theme.breakpoints.down("sm")]: {
      margin: theme.spacing(2, 2),
    },
  },
  dialogPaper: {
    margin: theme.spacing(1, 10),
    // display: 'flex',
    alignItems: "center",
  },
  customDialog: {
    borderRadius: "0px !important",
    boxShadow: "none !important",
  },
  customForm: {
    margin: theme.spacing(5, 10),
    marginTop: "8% !important",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-evenly",
    [theme.breakpoints.down("md")]: {
      margin: theme.spacing(3, 5),
    },
    [theme.breakpoints.down("sm")]: {
      margin: theme.spacing(2, 2),
    },
  },
  xxLargeFont: {
    fontSize: "xx-large",
    [theme.breakpoints.down("md")]: {
      fontSize: "22px",
    },
    [theme.breakpoints.down("sm")]: {
      fontSize: "22px",
    },
  },
  bottomSmall: {
    marginBottom: "9%",
  },
  resendLink: {
    cursor: "pointer",
    "&:hover": {
      textDecoration: "underline",
    },
    color: "#048475 !important",
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  smallTopMargin: {
    marginTop: "3%",
  },
  submit: {
    margin: theme.spacing(0, 0, 0),
    borderRadius: "4px !important",
  },
  alignRight: {
    textAlign: "right",
  },
  margin: {
    marginTop: theme.spacing(2),
  },
  withoutLabel: {
    marginTop: theme.spacing(3),
  },
  textField: {
    width: "100%",
  },
  disabledSignIn: {
    cursor: "not-allowed",
    borderRadius: "4px !important",
  },
  toolbar: {
    padding: "1% !important",
    left: "1.5% !important",
  },
  responsive: {
    maxWidth: "100% !important",
    height: "auto !important",
    width: "auto !important",
    alignSelf: "center !important",
    minWidth: "fit-content !important",
  },
  customFlex: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  closeButton: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.default.main,
    padding: "0px",
  },
  customDialogHeader: {
    maxHeight: "38%",
    height: "38%",
    color: "#000",
    backgroundSize: "cover",
    backgroundImage: `url(${cardHeaderBg})`,
    background: "round",
  },
  otpInput: {
    "&>input": {
      fontSize: "1rem !important",
      width: "2rem !important",
      height: "2rem !important",
      backgroundColor: "#F4F4F5",
    },
    "&:last-child": {
      marginRight: "0 !important",
    },
    width: "3rem",
    height: "3rem",
    marginRight: "1rem",
    fontSize: "1rem",
    textAlign: "center",
    border: "1px solid #B7B6B8",
    borderRadius: "5px",
    backgroundColor: "#F4F4F5",
    display: "flex !important",
    alignItems: "center !important",
    justifyContent: "center !important",
  },
});

const DialogTitle = withStyles(styles)((props) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle
      disableTypography
      className={classes.customDialogHeader}
      style={{ padding: "3%" }}
      {...other}
    >
      <Typography variant="h5" align="center">
        {children}
      </Typography>
      {onClose ? (
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={onClose}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const DialogContent = withStyles((theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
    backgroundColor: "#fff",
    justifyContent: "flex-end !important",
  },
}))(MuiDialogContent);

const DialogActions = withStyles((theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
    backgroundColor: "#fff",
    justifyContent: "center !important",
  },
}))(MuiDialogActions);

class ChangePassword extends Component {
  constructor(props) {
    super(props);
    this.state = {
      email: JSON.parse(secureLocalStorage.getItem("userDetails"))["email"]
        ? JSON.parse(secureLocalStorage.getItem("userDetails"))["email"]
        : "",
      newPassword: "",
      confirmPassword: "",
      showNewPassword: false,
      showConfirmPassword: false,
      isValidNewPassword: true,
      isValidConfirmPassword: true,
      passwordMismatch: false,
      isValidVerificationCode: true,
      verificationCode: "",
      open: false,
      errors: {
        cognito: null,
      },
    };
  }

  componentDidMount() {
    document.body.scrollTop = 0; // For Safari
    document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE and Opera
    const { dispatch } = this.props;
    const { pageTitle } = this.props?.pageTitle ?? this.props;
    const { recruiterStatusDetails } = this.props?.authentication ?? this.props;
    dispatch(
      setTitle(
        `${`My Account`} ${`-`} ${`Profile`} ${`-`} ${
          recruiterStatusDetails.first_name
        } ${recruiterStatusDetails.last_name} ${`-`} ${`Change Password`}`
      )
    );
    seo({
      title: `${pageTitle}`,
    });
  }
  componentDidUpdate() {
    const { pageTitle } = this.props?.pageTitle ?? this.props;
    seo({
      title: `${pageTitle}`,
    });
  }

  handleClickOpen = () => {
    this.setState({ open: true });
  };

  handleClickShowNewPassword = () => {
    this.setState({ showNewPassword: !this.state.showNewPassword });
  };

  handleClickShowConfirmPassword = () => {
    this.setState({ showConfirmPassword: !this.state.showConfirmPassword });
  };

  handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  handleClose = () => {
    this.setState({ open: false });
  };

  handleChange = (prop) => (event) => {
    switch (prop) {
      case "verificationCode":
        this.setState({
          isValidVerificationCode: validator.matches(
            event,
            /^(\s*\d{6}\s*)(,\s*\d{6}\s*)*,?\s*$/
          ),
        });
        break;
      case "newPassword":
        this.setState(
          {
            isValidNewPassword: validator.matches(
              event.target.value,
              /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[^a-zA-Z0-9]).{6,15}$/,
              "i"
            ),
          },
          function () {
            this.state.newPassword !== this.state.confirmPassword
              ? this.setState({ passwordMismatch: true })
              : this.setState({ passwordMismatch: false });
          }
        );
        break;
      case "confirmPassword":
        this.setState(
          {
            isValidConfirmPassword: validator.matches(
              event.target.value,
              /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[^a-zA-Z0-9]).{6,15}$/,
              "i"
            ),
          },
          function () {
            this.state.newPassword !== this.state.confirmPassword
              ? this.setState({ passwordMismatch: true })
              : this.setState({ passwordMismatch: false });
          }
        );
        break;
      default:
        return;
    }
    if (prop === "verificationCode") this.setState({ [prop]: event });
    else this.setState({ [prop]: event.target.value });
    // this.setState({ [prop]: event.target.value });
  };

  signout = async () => {
    const { dispatch } = this.props;
    dispatch(loading(true));
    try {
      await Auth.signOut();
      secureLocalStorage.removeItem("state");
      secureLocalStorage.clear();
      dispatch(authActions.signout(true));
      history.push("/signin");
      dispatch(loading(false));
    } catch (error) {
      let err = null;
      !error.message ? (err = { message: error }) : (err = error);
      console.log("error signing out:", err);
      this.setState({
        errors: {
          ...this.state.errors,
          cognito: error,
        },
      });
      dispatch(loading(false));
    }
  };

  goToMyAccount = () => {
    this.setState({ open: false });
    this.signout();
  };

  resetPassword = async (event) => {
    event.preventDefault();
    event.stopPropagation();
    const { dispatch } = this.props;
    dispatch(loading(true));
    const {
      email,
      verificationCode,
      newPassword,
      confirmPassword,
      passwordMismatch,
    } = this.state;
    if (
      verificationCode &&
      newPassword &&
      confirmPassword &&
      !passwordMismatch
    ) {
      try {
        const ForgotPasswordSubmitResponse = await Auth.forgotPasswordSubmit(
          email,
          verificationCode,
          confirmPassword
        );
        console.log(ForgotPasswordSubmitResponse);
        this.handleClickOpen();
        dispatch(loading(false));
      } catch (error) {
        let err = null;
        !error.message ? (err = { message: error }) : (err = error);
        console.log("error resetting password:", err);
        this.setState({
          errors: {
            ...this.state.errors,
            cognito: error,
          },
        });
        dispatch(loading(false));
        toast.dark(error.message);
      }
    } else {
      dispatch(loading(false));
      toast.dark("Please fill up all the details with correct information");
    }
  };

  resendForgotPasswordOtp = async () => {
    const { dispatch } = this.props;
    dispatch(loading(true));
    try {
      const forgotPasswordResponse = await Auth.forgotPassword(
        this.state.email
      );
      console.log(forgotPasswordResponse);
      dispatch(loading(false));
      toast.dark("Please check your registered email for verification code");
    } catch (error) {
      let err = null;
      !error.message ? (err = { message: error }) : (err = error);
      console.log("error in sending verification code:", err);
      this.setState({
        errors: {
          ...this.state.errors,
          cognito: error,
        },
      });
      dispatch(loading(false));
      toast.dark(error.message);
    }
  };

  render() {
    const { classes } = this.props;
    return (
      <div className={classes.root}>
        {/* <AppBar /> */}
        <Grid container component="main">
          {/* <CssBaseline /> */}
          <Grid
            item
            xs={12}
            sm={12}
            md={12}
            lg={12}
            xl={12}
            elevation={6}
            square
          >
            {/* <div className={classes.paper} >
                        <Typography component='span' className={classes.xxLargeFont}>Change Password</Typography>
                    </div>
                    <div className={classes.customPaper} >
                        <Typography component='span' color="primary">Please check your registered email for the verification code.</Typography>
                    </div> */}
            <form className={classes.customForm} noValidate>
              <Grid
                container
                item
                spacing={2}
                xs={12}
                sm={10}
                md={10}
                lg={4}
                xl={4}
              >
                <Grid
                  item
                  xs={12}
                  sm={12}
                  md={12}
                  lg={12}
                  xl={12}
                  style={{ textAlign: "left" }}
                >
                  <Typography component="span" className={classes.xxLargeFont}>
                    Change Password
                  </Typography>
                </Grid>
                <Grid
                  item
                  xs={12}
                  sm={12}
                  md={12}
                  lg={12}
                  xl={12}
                  style={{ textAlign: "left" }}
                >
                  <Typography component="span" color="primary">
                    Please check your registered email for the verification
                    code. <b>Please also check your spam folder.</b>
                  </Typography>
                </Grid>
                <Grid
                  item
                  xs={12}
                  sm={12}
                  md={12}
                  lg={12}
                  xl={12}
                  style={{ textAlign: "left" }}
                >
                  <Typography
                    component="div"
                    color="primary"
                    style={{ paddingBottom: "0 !important" }}
                  >
                    Enter code
                  </Typography>
                </Grid>
                <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                  <OtpInput
                    isInputNum
                    isNumberInput
                    shouldAutoFocus={true}
                    // placeholder='123456'
                    id="verificationCode"
                    name="verificationCode"
                    value={this.state.verificationCode}
                    onChange={this.handleChange("verificationCode")}
                    numInputs={6}
                    className={classes.otpInput}
                    inputClassName="otpInput"
                    hasErrored={
                      this.state.isValidVerificationCode ? false : true
                    }
                    autoFocus
                  />

                  {!this.state.isValidVerificationCode ? (
                    <Typography
                      align="left"
                      style={{
                        fontSize: "0.75rem",
                        color: "rgb(220, 0, 78)",
                        margin: "8px",
                        marginLeft: "0px !important",
                      }}
                    >
                      The code entered by you is incorrrect. Please try again.{" "}
                    </Typography>
                  ) : null}
                </Grid>

                <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                  <TextField
                    fullWidth
                    label="New Password"
                    margin="normal"
                    required
                    name="newPassword"
                    type={this.state.showNewPassword ? "text" : "password"}
                    id="newPassword"
                    value={this.state.password}
                    // autoComplete="current-password"
                    error={this.state.isValidNewPassword ? false : true}
                    helperText={
                      !this.state.isValidNewPassword
                        ? "Password must contain minimum 8 and maximum 15 characters, at least 1 lowercase letter, 1 uppercase letter, 1 numeric digit and 1 special character."
                        : ""
                    }
                    onChange={this.handleChange("newPassword")}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment
                          position="end"
                          title="Show/Hide Password"
                        >
                          <IconButton
                            tabIndex={-1}
                            aria-label="toggle password visibility"
                            onClick={this.handleClickShowNewPassword}
                            onMouseDown={this.handleMouseDownPassword}
                            edge="end"
                          >
                            {this.state.showNewPassword ? (
                              <VisibilityOff />
                            ) : (
                              <Visibility />
                            )}
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                  />
                </Grid>

                <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                  <TextField
                    fullWidth
                    label="Confirm Password"
                    margin="normal"
                    required
                    name="confirmPassword"
                    type={this.state.showConfirmPassword ? "text" : "password"}
                    id="confirmPassword"
                    value={this.state.password}
                    // autoComplete="current-password"
                    error={
                      this.state.isValidConfirmPassword &&
                      !this.state.passwordMismatch
                        ? false
                        : true
                    }
                    helperText={
                      !this.state.isValidConfirmPassword
                        ? "Password must contain minimum 8 and maximum 15 characters, at least 1 lowercase letter, 1 uppercase letter, 1 numeric digit and 1 special character."
                        : this.state.passwordMismatch
                        ? "Passwords didn't match"
                        : ""
                    }
                    onChange={this.handleChange("confirmPassword")}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment
                          position="end"
                          title="Show/Hide Password"
                        >
                          <IconButton
                            tabIndex={-1}
                            aria-label="toggle password visibility"
                            onClick={this.handleClickShowConfirmPassword}
                            onMouseDown={this.handleMouseDownPassword}
                            edge="end"
                          >
                            {this.state.showConfirmPassword ? (
                              <VisibilityOff />
                            ) : (
                              <Visibility />
                            )}
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                  />
                </Grid>

                <Grid
                  className={classes.smallTopMargin}
                  item
                  xs={12}
                  sm={12}
                  md={12}
                  lg={12}
                  xl={12}
                >
                  <div className={classes.customFlex}>
                    <Typography className={classes.toolbarContent} align="left">
                      <Link
                        className={classes.resendLink}
                        onClick={(event) => {
                          this.resendForgotPasswordOtp(event);
                        }}
                      >
                        Resend code
                      </Link>
                    </Typography>
                    <Button
                      size="large"
                      type="submit"
                      disableElevation
                      variant="contained"
                      color="primary"
                      onClick={(event) => {
                        this.resetPassword(event);
                      }}
                    >
                      Change Password
                    </Button>
                  </div>
                  <GetHelp />
                </Grid>
              </Grid>
            </form>
          </Grid>
          {/* <Grid item xs={false} sm={6} md={5} lg={5} xl={5} className={classes.image} /> */}
        </Grid>
        <Dialog
          disableBackdropClick
          disableEscapeKeyDown
          aria-labelledby="customized-dialog-title"
          open={this.state.open}
          className={classes.customDialog}
        >
          <DialogTitle>
            {/* onClose={this.handleClose} */}
            <div className={classes.dialogPaper}>
              <Typography component="span" className={classes.xxLargeFont}>
                Verification Successful!
              </Typography>
            </div>
          </DialogTitle>
          <DialogContent>
            <div className={classes.dialogPaper}>
              <Typography component="span" color="primary">
                Your password has been successfully changed. Please sign in
                again to continue.
              </Typography>
            </div>
          </DialogContent>
          <DialogActions className={classes.dialogActions}>
            <Button
              variant="contained"
              color="primary"
              className={classes.submit}
              disableElevation
              size="large"
              onClick={() => {
                this.goToMyAccount();
              }}
            >
              Ok
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    );
  }
}

ChangePassword.propTypes = {
  classes: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => {
  return state;
};

const connectedChangePasswordPage = withRouter(
  connect(mapStateToProps, null, null, {
    pure: false,
  })(withStyles(styles)(ChangePassword))
);

export { connectedChangePasswordPage as ChangePassword };
