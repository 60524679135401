import React, { useEffect, useState, Fragment } from "react";
import { connect, useDispatch, useSelector } from "react-redux";
import Typography from "@material-ui/core/Typography";
import {
  Card,
  CardContent,
  Avatar,
  Grid,
  Button,
  IconButton,
  Dialog,
  TextField,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Badge,
  Divider,
} from "@material-ui/core";
import {
  Chat,
  Channel,
  ChannelList,
  MessageInput,
  MessageList,
  Window,
  useChannelStateContext,
  useChatContext,
  ChatAutoComplete,
  EmojiIconLarge,
  EmojiPicker,
  SendButton,
  Tooltip,
  useMessageInputContext,
  useTranslationContext,
} from "stream-chat-react";
import { StreamChat } from "stream-chat";
import moment from "moment";
import "./messages.css";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import InsertInvitationIcon from "@material-ui/icons/InsertInvitation";
import MuiDialogTitle from "@material-ui/core/DialogTitle";
import MuiDialogContent from "@material-ui/core/DialogContent";
import MuiDialogActions from "@material-ui/core/DialogActions";
import CloseIcon from "@material-ui/icons/Close";
import { toast } from "react-toastify";
import { MESSAGE_FIELD_IS_REQUIRED } from "../../../constants/constants";
import { sharedActions } from "../../../actions/shared.action";
import { history, truncate, seo } from "../../../helpers";
import cardHeaderBg from "../../../assets/lite_profile_bg.png";
import { setTitle } from "../../../actions";
import secureLocalStorage from "react-secure-storage";

const useStyles = makeStyles({
  root: {
    width: "100%",
  },
  container: {
    maxHeight: 440,
  },
  headerMainText: {
    fontSize: "15px !important",
    lineHeight: "20px",
    // lineHeight: '15px',
    // lineHeight: '20px',
    fontWeight: "600",
    color: "#000000",
  },
  headerSubText: {
    fontSize: "14px !important",
    lineHeight: "12px",
    textAlign: "left",
    display: "inline-block",
    width: "190px",
    whiteSpace: "nowrap",
    overflow: "hidden !important",
    textOverflow: "ellipsis",
    fontWeight: "500",
    color: "#000000",
  },
  headerSubSubText: {
    fontSize: "13px !important",
    lineHeight: "16px",
    whiteSpace: "nowrap",
    overflow: "hidden !important",
    textOverflow: "ellipsis",
    fontWeight: "500",
    // color: '#000000',
  },
  buttonInMsg: {
    // height: '35px !important',
    // marginLeft: '10px',
    // padding: '6px 16px !important'
  },
  floatRight: {
    float: "right",
    marginRight: "10px",
  },
  secListItem: {
    top: "84% !important",
  },
  online: {
    color: "#048475",
  },
  inlineButton: {
    position: "absolute",
    right: "0",
    bottom: "8px",
    margin: "14px",
  },
});

const styles = (theme) => ({
  root: {
    // minWidth: 275,
    width: "100%",
  },
  paper: {
    border: "1px solid",
    padding: theme.spacing(1),
    backgroundColor: theme.palette.background.paper,
  },
  closeButton: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.default.main,
    padding: "0px",
  },
  customDialogHeader: {
    maxHeight: "38%",
    height: "38%",
    color: "#000",
    backgroundSize: "cover",
    backgroundImage: `url(${cardHeaderBg})`,
    background: "round",
  }, // height: '180px'
});

export function HamburgerIcon() {
  return (
    <svg
      width="16"
      height="14"
      viewBox="0 0 16 14"
      fill="black"
      xmlns="http://www.w3.org/2000/svg"
      style={{ cursor: "pointer", margin: "10px" }}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0.5 0.333344H15.5V2.00001H0.5V0.333344ZM0.5 6.16667H15.5V7.83334H0.5V6.16667ZM15.5 12H0.5V13.6667H15.5V12Z"
        fill="black"
      />
    </svg>
  );
}

const DialogTitle = withStyles(styles)((props) => {
  const { children, classes, onClose, ...other } = props;

  return (
    <MuiDialogTitle
      disableTypography
      {...other}
      className={classes.customDialogHeader}
      style={{ padding: "3%" }}
    >
      {/* className={classes.root} */}
      <Typography variant="h5" align="center">
        {children}
      </Typography>
      <Typography style={{ fontSize: "16px" }} align="center">
        Share your Calendly link to schedule a meeting with this candidate.
      </Typography>
      <IconButton
        aria-label="close"
        className={classes.closeButton}
        onClick={onClose}
      >
        <CloseIcon />
      </IconButton>
    </MuiDialogTitle>
  );
});

const DialogContent = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
    paddingLeft: theme.spacing(4),
    paddingRight: theme.spacing(4),
  },
}))(MuiDialogContent);

const DialogActions = withStyles((theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
    backgroundColor: "#fff",
    justifyContent: "center !important",
  },
}))(MuiDialogActions);

const capitalize = function (str) {
  if (str !== null && str !== undefined) {
    return str
      .split(" ")
      .map((w) => w.substring(0, 1).toUpperCase() + w.substring(1))
      .join(" ");
  }
  return str;
};

function MessagingChannelList({ children }) {
  return <div className="messaging__channel-list">{children}</div>;
}

function CustomMessageInput() {
  const { t } = useTranslationContext();
  const {
    closeEmojiPicker,
    emojiPickerIsOpen,
    handleEmojiKeyDown,
    handleSubmit,
    openEmojiPicker,
  } = useMessageInputContext();

  return (
    <div className="str-chat__input-flat str-chat__input-flat--send-button-active">
      <div className="str-chat__input-flat-wrapper">
        <div className="str-chat__input-flat--textarea-wrapper">
          <ChatAutoComplete />
          <div
            className="str-chat__emojiselect-wrapper"
            style={{ cursor: "pointer" }}
          >
            <Tooltip>
              {emojiPickerIsOpen
                ? t("Close emoji picker")
                : t("Open emoji picker")}
            </Tooltip>
            <span
              className="str-chat__input-flat-emojiselect"
              onClick={emojiPickerIsOpen ? closeEmojiPicker : openEmojiPicker}
              onKeyDown={handleEmojiKeyDown}
              role="button"
              tabIndex={0}
            >
              <EmojiIconLarge />
            </span>
          </div>
          <EmojiPicker />
        </div>
        <SendButton sendMessage={handleSubmit} />
      </div>
    </div>
  );
}

function CustomChannelHeader(props) {
  const [meetingPopUp, setMeetingPopUp] = useState(false);
  const [meetingLink, setMeetingLink] = useState({
    value: "",
    required: false,
    isValid: false,
  });
  const [oldLink, setOldLink] = useState("");
  const { pageTitle } = useSelector((state) => state.pageTitle);
  const dispatch = useDispatch();
  const classes = useStyles();
  const { channel } = useChannelStateContext();
  const calendlyLink = useSelector((state) => state.shared.calendlyLink);
  let oppositeUser;

  useEffect(() => {
    setMeetingLink((meetingLink) => ({
      ...meetingLink,
      value: calendlyLink,
    }));
    setOldLink(calendlyLink);
    dispatch(setTitle(`${"Messages"} ${"-"} ${oppositeUser?.user?.name}`));
    seo({
      title: `${pageTitle}`,
    });
  }, [calendlyLink, oppositeUser?.user?.name, dispatch, pageTitle]);

  for (const key in channel.state.members) {
    if (key !== props.currentUserId) {
      oppositeUser = channel.state.members[key];
    }
  }

  if (!oppositeUser) {
    return null;
  }

  // const navigateMemberProfile = (event, memberId) => {
  //   dispatch(membersActions.getFullProfile(memberId));
  //   event.preventDefault();
  // };

  const openPopUp = () => {
    setMeetingPopUp(true);
  };

  const closePopUp = () => {
    setMeetingPopUp(false);
    setMeetingLink((meetingLink) => ({
      ...meetingLink,
      value: meetingLink.value,
      required: false,
      isValid: false,
    }));
    // setIsLinkValid(false);
  };

  const sendLink = (event) => {
    event.preventDefault();
    event.stopPropagation();
    setMeetingLink((meetingLink) => ({
      ...meetingLink,
      value: meetingLink.value,
      required: !meetingLink.value,
      isValid: meetingLink.value
        ? !/^((ftp|http|https):\/\/)?(www.)?(?!.*(ftp|http|https|www.))[a-zA-Z0-9_-]+(\.[a-zA-Z]+)+((\/)[\w#-=?!@">`~]+)*(\/\w+\?[a-zA-Z0-9_]+=\w+(&[a-zA-Z0-9_]+=\w+))?$/gs.test(
            meetingLink.value
          )
        : false,
    }));

    if (
      !meetingLink.isValid &&
      !meetingLink.required &&
      meetingLink.value !== ""
    ) {
      if (oldLink !== meetingLink.value) {
        dispatch(
          sharedActions.addUpdateCalendlyLink(
            JSON.parse(secureLocalStorage.getItem("recruiterId")),
            meetingLink.value
          )
        );
      }
      channel.sendMessage({
        text: `Please click on the link below to schedule a meeting.\n${meetingLink.value}`,
      });
      closePopUp();
    } else {
      toast.dark("Please add a valid link.");
    }
  };

  const onChangeValue = (event) => {
    event.persist();
    event.preventDefault();
    event.stopPropagation();
    setMeetingLink((meetingLink) => ({
      ...meetingLink,
      value: event.target.value,
      required: !event.target.value,
      isValid: event.target.value
        ? !/^((ftp|http|https):\/\/)?(www.)?(?!.*(ftp|http|https|www.))[a-zA-Z0-9_-]+(\.[a-zA-Z]+)+((\/)[\w#-=?!@">`~]+)*(\/\w+\?[a-zA-Z0-9_]+=\w+(&[a-zA-Z0-9_]+=\w+))?$/gs.test(
            event.target.value
          )
        : false,
    }));
  };

  return (
    <>
      {/* Close Confirm Popup */}
      <Dialog
        open={meetingPopUp}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        fullWidth
        disableBackdropClick
        disableEscapeKeyDown
      >
        <DialogTitle
          id="alert-dialog-title"
          onClose={closePopUp}
          // className={classes.customDialog}
        >
          {/* <Logo width='230' className={classes.responsive} /> */}
          Schedule Meeting
        </DialogTitle>
        <DialogContent>
          <TextField
            fullWidth
            // label='Calendly link'
            autoComplete="link"
            margin="normal"
            id="link"
            name="link"
            value={meetingLink.value}
            // inputProps={{ maxLength: 10 }}
            onChange={(event) => onChangeValue(event)}
            placeholder="Insert calendly link "
            error={meetingLink.isValid || meetingLink.required}
            helperText={
              (meetingLink.isValid && "Invalid Link") ||
              (meetingLink.required && MESSAGE_FIELD_IS_REQUIRED) ||
              ""
            }
          />
        </DialogContent>
        <DialogActions>
          <Button
            disableElevation
            variant="contained"
            color="primary"
            size="large"
            onClick={(event) => sendLink(event)}
            disabled={meetingLink.isValid}
          >
            Send
          </Button>
        </DialogActions>
      </Dialog>
      <Card elevation={0} className="card-content">
        <CardContent className="card-content">
          <List className="list--background" aria-label="main folders">
            <ListItem>
              <ListItemAvatar>
                <Avatar
                  style={{ backgroundColor: "#000000" }}
                  alt={
                    oppositeUser?.user?.name
                      ? capitalize(oppositeUser?.user?.name)
                      : capitalize(oppositeUser?.user?.id)
                  }
                  src={
                    oppositeUser?.user?.image ? oppositeUser?.user?.image : "/"
                  }
                />
              </ListItemAvatar>
              <ListItemText
                primary={
                  oppositeUser?.user?.name
                    ? capitalize(oppositeUser?.user?.name)
                    : null || oppositeUser?.user?.id
                }
                secondary={
                  <div className="sub-header">
                    {oppositeUser?.user?.title &&
                      oppositeUser?.user?.company_name && (
                        <div>
                          {oppositeUser?.user?.title},{" "}
                          {oppositeUser?.user?.company_name}
                        </div>
                      )}
                    <div className="sub-sub-header">
                      {oppositeUser?.user?.online ? (
                        <span>
                          <span className={classes.online}>●</span> Active now
                        </span>
                      ) : oppositeUser?.user?.last_active ? (
                        `last active ${moment(
                          oppositeUser?.user?.last_active
                        ).fromNow()}`
                      ) : null}
                    </div>
                  </div>
                }
              />
              {/* <ListItemSecondaryAction classes={{ root: classes.secListItem }}>
                <Button
                  type='submit'
                  disableElevation
                  variant='contained'
                  color='primary'
                  size='large'
                  className={classes.buttonInMsg}
                  onClick={(event) => openPopUp()}
                  startIcon={<InsertInvitationIcon />}
                >
                  Schedule Meeting
                </Button>
                <Button
                  type='submit'
                  disableElevation
                  variant='contained'
                  color='primary'
                  size='large'
                  className={classes.buttonInMsg}
                  onClick={(event) =>
                    navigateMemberProfile(event, oppositeUser.user.id)
                  }
                  // startIcon={<InsertInvitationIcon />}
                >
                  View Profile
                </Button>
              </ListItemSecondaryAction> */}
            </ListItem>
          </List>
          {/* <Typography
            onClick={(event) =>
              navigateMemberProfile(event, oppositeUser.user.id)
            }
            color='primary'
            className='customLink'
            gutterBottom
          >
            View profile
          </Typography> */}
          {props.isMobileNavVisible ? (
            <div className="inlineButton" id="isMobileButton">
              <Button
                type="submit"
                title="Schedule Meeting"
                disableElevation
                variant="contained"
                color="primary"
                size="large"
                className={classes.buttonInMsg}
                onClick={() => openPopUp()}
                startIcon={<InsertInvitationIcon />}
              >
                Schedule Meeting
              </Button>
            </div>
          ) : (
            <div className="inlineButton2">
              <IconButton
                aria-label="close"
                title="Schedule Meeting"
                className={classes.closeButton}
                onClick={() => openPopUp()}
              >
                <InsertInvitationIcon />
              </IconButton>
            </div>
          )}
        </CardContent>
      </Card>
      <Divider />
    </>
  );
}

function CustomChannelPreview(props) {
  const params = new URLSearchParams(window.location.search);
  const cid = params.get("cid") || null;
  const { pageTitle } = useSelector((state) => state.pageTitle);
  const { channel, setActiveChannel, watchers } = props;
  const dispatch = useDispatch();
  useEffect(() => {
    if (cid === channel.id) {
      setTimeout(() => {
        setActiveChannel(channel, watchers);
        history.push("/messages");
      }, 0);
    }
    seo({
      title: `${pageTitle}`,
    });
  }, [cid, pageTitle, setActiveChannel, channel, watchers]);
  const classes = useStyles();
  const { channel: activeChannel } = useChatContext();
  const selected = channel?.id === activeChannel?.id;
  const { messages } = channel.state;
  const messagePreview =
    messages[messages.length - 1]?.type !== "deleted"
      ? truncate(messages[messages.length - 1]?.text, 30)
      : "This message was deleted...";
  const lastMessageDate =
    messages.length > 0
      ? moment(messages[messages.length - 1]?.created_at).format("MM-DD-YYYY")
      : null;
  const today = moment().format("MM-DD-YYYY");
  let messagePreviewTime = null;
  if (messages.length > 0) {
    if (lastMessageDate < today) {
      messagePreviewTime = moment(
        messages[messages.length - 1]?.created_at
      ).format("MM-DD-YYYY");
    } else {
      messagePreviewTime = moment(
        messages[messages.length - 1]?.created_at
      ).format("hh:mm a");
    }
  }
  let oppositeUser;
  for (const key in channel.state.members) {
    if (key !== props.currentUserId) {
      oppositeUser = channel.state.members[key];
    }
  }

  const setPageTitle = (candidateName) => {
    dispatch(setTitle(`${"Messages"} ${"-"} ${candidateName}`));
  };

  if (!oppositeUser) {
    return null;
  }
  return (
    <Card
      elevation={1}
      className={selected ? "selected-card margin-bottom" : "margin-bottom"}
      onClick={() => {
        setActiveChannel(channel, watchers);
        setPageTitle(oppositeUser?.user?.name);
      }}
    >
      <CardContent>
        <Grid container style={{ cursor: "pointer" }} direction="row">
          <Grid item xs={2}>
            <Avatar
              // classNames='large-avatar avatar--background'
              style={{ backgroundColor: "#000000" }}
              alt={
                oppositeUser?.user?.name
                  ? capitalize(oppositeUser?.user?.name)
                  : capitalize(oppositeUser?.user?.id)
              }
              src={oppositeUser?.user?.image ? oppositeUser?.user?.image : "/"}
            />
          </Grid>
          <Grid item xs={7}>
            <Grid
              container
              direction="column"
              justify="flex-start"
              alignItems="flex-start"
            >
              <Grid item>
                <Typography color="primary" className={classes.headerMainText}>
                  {capitalize(oppositeUser?.user?.name) ||
                    oppositeUser?.user?.id}
                </Typography>
              </Grid>
              {oppositeUser?.user?.title &&
                oppositeUser?.user?.company_name && (
                  <Grid item>
                    <Typography
                      className={classes.headerSubText}
                      title={`${oppositeUser?.user?.title},${oppositeUser?.user?.company_name}`}
                    >
                      {`${oppositeUser?.user?.title},${oppositeUser?.user?.company_name}`}
                    </Typography>
                  </Grid>
                )}
              <Grid item>
                <Typography color="textSecondary">
                  <div
                    className={classes.headerSubSubText}
                    dangerouslySetInnerHTML={{
                      __html: messagePreview || "No messages",
                    }}
                  >
                    {/* {messagePreview || 'No messages'} */}
                  </div>
                </Typography>
              </Grid>
            </Grid>
          </Grid>
          <Grid container direction="column" justify="space-between" xs={3}>
            <Grid item>
              <Typography color="secondary" className="sub-sub-header">
                {messagePreviewTime || null}
              </Typography>
            </Grid>
            <Grid
              item
              style={{
                marginBlock: "auto",
                marginTop: "15px",
                marginLeft: "30px",
              }}
            >
              <Badge
                color="error"
                badgeContent={channel.state.unreadCount}
                max={9}
              />
            </Grid>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
}

function SsChat(props) {
  const [chatClient, setChatClient] = useState(null);
  const [isMobileNavVisible, setMobileNav] = useState(false);
  const [showMobileButton, setShowMobileButton] = useState(false);
  const recruiterId =
    JSON.parse(secureLocalStorage.getItem("recruiterId")) || null;
  const filters = { type: "messaging", members: { $in: [recruiterId] } };
  const sort = { last_message_at: -1 };
  const options = {
    state: true,
    watch: true,
    presence: true,
    limit: 10,
  };
  useEffect(() => {
    const initChat = async () => {
      try {
        const { getStreamUserTokenData } = props.messages;
        const client = StreamChat.getInstance(
          getStreamUserTokenData.data.apiKey,
          {
            timeout: 6000,
          }
        );
        // open the WebSocket connection to start receiving events
        await client.connectUser(
          {
            id: recruiterId,
          },

          getStreamUserTokenData.data.token.slice(2, -1)
        );
        setChatClient(client);
      } catch (error) {
        console.log("error", error);
      }
    };

    initChat();
    // close the WebSocket connection when component dismounts
    return () => (chatClient ? chatClient.disconnectUser() : null);
  }, [chatClient, props.messages, recruiterId]);

  useEffect(() => {
    const mobileChannelList = document.querySelector("#mobile-channel-list");
    if (isMobileNavVisible && mobileChannelList) {
      mobileChannelList.classList.add("show");
      document.body.style.overflow = "hidden";
    } else if (!isMobileNavVisible && mobileChannelList) {
      mobileChannelList.classList.remove("show");
      document.body.style.overflow = "auto";
    }
  }, [isMobileNavVisible]);

  useEffect(() => {
    /*
     * Get the actual rendered window height to set the container size properly.
     * In some browsers (like Safari) the nav bar can override the app.
     */
    const setAppHeight = () => {
      const doc = document.documentElement;
      doc.style.setProperty("--app-height", `${window.innerHeight}px`);
      if (window.innerWidth < 1164 || window.innerWidth < 1430) {
        setShowMobileButton(false);
      } else {
        setShowMobileButton(true);
      }
    };

    setAppHeight();

    window.addEventListener("resize", setAppHeight);
    return () => window.removeEventListener("resize", setAppHeight);
  }, []);

  const toggleMobile = () => setMobileNav(!isMobileNavVisible);

  if (!chatClient) {
    return null;
  }

  return (
    <Chat client={chatClient} theme="messaging light">
      <div id="mobile-channel-list" onClick={toggleMobile}>
        <ChannelList
          filters={filters}
          sort={sort}
          options={options}
          List={(props) => <MessagingChannelList {...props} />}
          Preview={(previewProps) => (
            <CustomChannelPreview
              currentUserId={recruiterId}
              {...previewProps}
            />
          )}
        />
      </div>
      <Channel Input={CustomMessageInput}>
        <Window>
          <div className="messaging__channel-header">
            <div id="mobile-nav-icon" onClick={() => toggleMobile()}>
              <HamburgerIcon />
            </div>
            <CustomChannelHeader
              currentUserId={recruiterId}
              isMobileNavVisible={showMobileButton}
            />
            {/* <ChannelHeader/> */}
          </div>
          {/* <ChannelHeader/> */}
          <MessageList messageActions={[]} />
          <MessageInput />
        </Window>
        {/* <Thread />     */}
      </Channel>
    </Chat>
  );
}

function mapStateToProps(state) {
  return state;
}

export default connect(mapStateToProps)(SsChat);
