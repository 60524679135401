import secureLocalStorage from "react-secure-storage";

const token = secureLocalStorage.getItem("token");
const auth = secureLocalStorage.getItem("auth");

const initialState = auth
  ? {
      loggedIn: true,
      auth,
      token,
      deepLink: "",
      welcomePopup: true,
    }
  : {
      loggedIn: false,
      auth,
      token,
      deepLink: "",
      welcomePopup: true,
    };

export function authentication(state = initialState, action) {
  switch (action.type) {
    case "SIGNIN_SUCCESS":
      return {
        ...state,
        loggedIn: !!action.auth,
        auth: action.auth,
        token: action.token,
        userId: action.userId,
      };
    case "SIGNOUT_SUCCESS":
      secureLocalStorage.clear();
      return {
        ...state,
        loggedIn: false,
        auth: null,
        token: null,
      };
    case "GOT_ALL_CONSTANTS":
      return {
        ...state,
        constants: action.constants,
      };
    case "AUTH_DETAILS":
      return {
        ...state,
        loggedIn: !!secureLocalStorage.getItem("auth"),
        auth: !!secureLocalStorage.getItem("auth"),
        token: secureLocalStorage.getItem("token")
          ? secureLocalStorage.getItem("token")
          : null,
        userId: secureLocalStorage.getItem("userId"),
        prevPath: document.referrer,
      };
    case "SET_RECRUITER_SUCCESS":
      return {
        ...state,
        recruiterDetails: action.recruiterDetails,
        //    onboardingStatus: action.onboardingStatus
      };
    case "COMPANY_DATA":
      return {
        ...state,
        companyData: action.companyData,
      };
    case "COMPANY_CREATED":
      return {
        ...state,
        //  companyAddedResponse: action.companyAddedResponse
        // onboardingStatus: action.onboardingStatus
      };
    case "SET_CONTRACT_SUCCESS":
      return {
        ...state,
        contractDetails: action.contractDetails,
        // onboardingStatus: action.onboardingStatus
      };
    case "GET_RECRUITER_STATUS":
      return {
        ...state,
        recruiterStatusDetails: action.recruiterStatusDetails,
      };
    case "DEEP_LINK":
      return {
        ...state,
        deepLink: action.deepLink,
      };
    case "ONBOARDING_STATUS_SET":
      return {
        ...state,
        // onboardingStatus: action.onboardingStatus
      };
    case "WELCOME_POPUP":
      return {
        ...state,
        welcomePopup: action.welcomePopup,
      };
    case "SET_LAST_LOGIN":
      return {
        ...state,
        lastLogin: action.lastLogin,
      };
    default:
      return state;
  }
}

export function loggedIn(state = !!secureLocalStorage.getItem("auth"), action) {
  switch (action.type) {
    case "AUTH_DETAILS":
      return !!secureLocalStorage.getItem("auth");
    default:
      return state;
  }
}

export function gotRecruiterStatus(
  state = !!secureLocalStorage.getItem("auth"),
  action
) {
  let reduxState = {};
  switch (action.type) {
    case "GET_RECRUITER_STATUS":
      reduxState = JSON.parse(secureLocalStorage.getItem("state"));
      return reduxState.authentication.recruiterStatusDetails;
    default:
      return state;
  }
}
