import {
  Dialog,
  DialogContent,
  DialogContentText,
  DialogTitle,
  IconButton,
  DialogActions,
  Button,
} from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import CloseIcon from "@material-ui/icons/Close";
import { useDispatch, useSelector } from "react-redux";
import { movedInConversation } from "../../actions";
import { jobsActions } from "../../actions/jobs.actions";

const useStyles = makeStyles((theme) => ({
  DialogTitle: {
    background: "#FBC112",
    fontSize: "28px",
    fontWeight: 700,
    textAlign: "center",
  },
  dailogText: {
    textAlign: "center",
    fontSize: "16px",
    fontWeight: 400,
  },
  dailogActions: {
    display: "flex",
    justifyContent: "center",
    marginBottom: "15px",
  },
  confirmButton: {
    background: "#000000",
    color: "#FFFFFF",
    width: "8rem",
  },
  cancelButton: {
    width: "8rem",
  },
  closeButton: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(1),
    padding: "0px",
    // background: "#000000",
    color: "#000000",
    // "&:hover": {
    //   background: "#000000",
    //   color: "#FFFFFF",
    // },
  },
}));

function MoveCandidateinConversation({
  open,
  onClose,
  recIdConversation,
  jobId,
  isFullProfile,
  removeConversationButton,
  unmountRemoveFromQueueButton,
}) {
  const dispatch = useDispatch();
  const headerCardValue = useSelector(
    (state) => state.jobs.allUpdateHeaderCard
  );

  const [renderApis, setRenderApi] = useState(false);

  const handleConfirmButton = () => {
    const payload = {
      recommendation_id: recIdConversation,
    };
    movedInConversation(payload);
    setRenderApi(true);
  };

  useEffect(() => {
    if (renderApis) {
      autoClosePopup();
    }
  }, [renderApis]);

  const autoClosePopup = () => {
    if (isFullProfile) {
      removeConversationButton();
      unmountRemoveFromQueueButton();
      window.location.reload();
      onClose();
    } else {
      onClose();
      setTimeout(() => {
        switch (headerCardValue) {
          case "All":
            dispatch(jobsActions.getAllUpdatesData(jobId, "All"));
            break;
          case "1":
            dispatch(jobsActions.getAllUpdatesData(jobId, "Recommended"));
            break;
          case "2":
            dispatch(jobsActions.getAllUpdatesData(jobId, "Matched"));
            break;
          case "3":
            dispatch(jobsActions.getAllUpdatesData(jobId, "Conversation"));
            break;
          default:
            break;
        }
      }, 3000);
    }
    setRenderApi(false);
  };

  const classes = useStyles();
  return (
    <Dialog open={open}>
      <DialogTitle className={classes.DialogTitle}>
        Confirm Move to Interview Stage ?
      </DialogTitle>
      <IconButton
        aria-label="close"
        className={classes.closeButton}
        onClick={onClose}
      >
        <CloseIcon />
      </IconButton>
      <DialogContent>
        <DialogContentText className={classes.dailogText}>
          Please confirm if you want to move this candidate to Interview Stage ?{" "}
        </DialogContentText>
      </DialogContent>
      <DialogActions className={classes.dailogActions}>
        <Button
          disableElevation
          className={classes.cancelButton}
          onClick={onClose}
        >
          Cancel
        </Button>
        <Button
          disableElevation
          className={classes.confirmButton}
          onClick={handleConfirmButton}
          variant="contained"
        >
          Yes, Confirm!
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default MoveCandidateinConversation;
