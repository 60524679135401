const {
  REACT_APP_BACKEND_URL,
  REACT_APP_COGNITO_REGION,
  REACT_APP_COGNITO_USER_POOL_ID,
  REACT_APP_COGNITO_APP_CLIENT_ID,
  REACT_APP_STRIPE_KEY,
  REACT_APP_GOOGLE_PLACES_KEY,
  REACT_APP_CLOUDINARY_CLOUD_NAME,
  REACT_APP_CLOUDINARY_UPLOAD_PRESET,
  REACT_APP_ZENDESK_URL,
  REACT_APP_TERMS_URL,
  REACT_APP_MASTER_KEY,
} = process.env;

module.exports = {
  baseUrl: REACT_APP_BACKEND_URL,
  cognito: {
    REGION: REACT_APP_COGNITO_REGION,
    USER_POOL_ID: REACT_APP_COGNITO_USER_POOL_ID,
    APP_CLIENT_ID: REACT_APP_COGNITO_APP_CLIENT_ID,
  },
  googleKey: REACT_APP_GOOGLE_PLACES_KEY,
  stripePublicationKey: REACT_APP_STRIPE_KEY,
  cloudName: REACT_APP_CLOUDINARY_CLOUD_NAME,
  upload_preset: REACT_APP_CLOUDINARY_UPLOAD_PRESET,
  zendesk_url: REACT_APP_ZENDESK_URL,
  terms_url: REACT_APP_TERMS_URL,
  master_key: REACT_APP_MASTER_KEY,
};
